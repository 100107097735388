<div class="mar-top-sm">
	<h2 class="text-primary text-uppercase mar-bottom-sm">My Offers</h2>
	<div class="row" *ngIf="isLoaded">
	<ng-container *ngFor="let coupon of coupons?.data">
		<div class="col-xs-12 col-sm-6 col-md-6 col-lg-6" *ngIf="coupon.rate?.facility?.display_name && coupon?.rate?.price">
			<div class="well" id="{{ coupon?.id }}" *ngIf="coupon.rate?.facility?.display_name && coupon?.rate?.price">
				<h4>{{ coupon.rate?.facility?.display_name }}<a href="javascript:;" class="pull-right" (click)="removeCoupon(coupon?.id)"><fa name="times"></fa></a></h4>
				<h3 sourceFont>${{ coupon?.rate?.price }}</h3>
				<div class="row">
					<div class="col-xs-5 col-sm-5 col-md-6 col-lg-6">
						<p class="mar-bottom-0 mar-top-0"><small>Description</small><br>
                            {{ coupon?.rate?.description }}
                            <br><small>(Based on Availability)</small>
                        </p>

					</div>
					<div class="col-xs-7 col-sm-7 col-md-6 col-lg-6 text-right pad-left-0 coupon-tab-pad">
						<a class="btn btn-primary btn-fill theme-btn" (click)="viewCoupon(coupon?.rate?.id,vtemplate,0)" [ngClass]= "{'btn-sm': SmallDevice}">View</a><span class="mar-left-xs"></span><a class="btn btn-primary btn-fill theme-btn" (click)="viewCoupon(coupon?.rate?.id,template,1)" [ngClass]= "{'btn-sm': SmallDevice}">Print</a>
					</div>
				</div>
			</div>
		</div>
		</ng-container>
	</div>
	<div class="clearfix" *ngIf="isLoaded && coupons?.total > 20">
		<div class="col-xs-12 col-sm-6 col-md-6 col-lg-6" id="pagination" >
			<p>Showing Results for Page {{ coupons?.current_page }} of {{ coupons?.last_page }}</p>
		</div>
		<div class="col-xs-12 col-sm-6 col-md-6 col-lg-6 text-right" *ngIf="coupons.data.length > 0">
			<ul class="pagination  pagination-lg">
				<li *ngIf="coupons?.prev_page_url != null">
					<button type="submit" class="btn btn-primary theme-btn" (click)="loadPreviousNextCoupons(coupons?.current_page, 'prev')">
					<fa name="angle-left"></fa>
					</button>
				</li>
				<li *ngIf="coupons?.next_page_url != null">
					<button type="submit" class="btn btn-primary theme-btn" (click)="loadPreviousNextCoupons(coupons?.current_page, 'next')">
					<fa name="angle-right"></fa>
					</button>
				</li>
			</ul>
		</div>
	</div>
	<p class="" *ngIf="isLoaded">
		<span *ngIf="coupons?.data.length < 1">You currently have no saved offers. </span>Search for a facility to find and save offers.
	</p>
	<app-lazy-load *ngIf="isLoaded === false" [isLoaded]="isLoaded" [sectionName]="'Offers '"></app-lazy-load>
	<button *ngIf="isLoaded" type="button" class="btn theme-btn btn-primary hidden-xs" [ngClass]="{'btn-fill': coupons?.data.length < 1}" (click)="findSpot()">find offers</button>
	<div class="visible-xs row">
		<div class="col-xs-offset-2 col-xs-8 mar-top-xs">
			<button *ngIf="isLoaded" type="button" class="btn theme-btn btn-primary btn-block" [ngClass]="{'btn-fill': coupons?.data.length < 1}" (click)="findSpot()">find offers</button>
		</div>
	</div>	
</div>
<ng-template #template>
	<div class="modal-body coupons-other-areas" id="couponprintdata">
		<div class=" text-center mar-bottom-sm" id="couponvalid" style="display: none;">
			<button type="button" class="btn btn-primary theme-btn btn-fill btn-dark" (click)="printMedia(template)">Print</button>&nbsp;
			<button type="button" class="btn btn-primary theme-btn btn-fill" (click)="closePopup()">Cancel</button>
		</div>
		<div class="panel-body"id="printableContent">
			<app-lazy-load [isLoaded]="isLoadedp" [sectionName]="'Offers'"></app-lazy-load>
			<img style="display: none;" [src]="sanitizer.bypassSecurityTrustResourceUrl(printData)" id="invoicePrint" class="printable img-responsive full-width" alt="Image">
		</div>
	</div>
</ng-template>
<ng-template #vtemplate>
<div>
<div class="modal-header">
<a href="javascript:;" class="pull-right" (click)="closePopup()"><fa name="times"></fa></a>
</div>
	<div class="modal-body coupons-other-areas" id="couponprintdata">
		<div class="panel-body"id="printableContent">
			<app-lazy-load [isLoaded]="isLoadedp" [sectionName]="'Offers'"></app-lazy-load>
			<img style="display: none;" [src]="sanitizer.bypassSecurityTrustResourceUrl(printData)" id="invoicePrint" class="printable img-responsive full-width" alt="Image">
		</div>
	</div>
	</div>
</ng-template>
<div class="A-B-coupon-1">
<div bsModal #lgModal="bs-modal" id="coupon-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel"
    aria-hidden="true">
    <div class="modal-dialog select-modal-dlg">
        <div class="modal-content modalheightScr">
            <div class="modal-header">



                <h4 id="couponheading" class="coupons-parent-panel modal-title pull-left"></h4>
                <button type="button" id="closeCouponModal" class="close pull-right" (click)="lgModal.hide()" aria-label="Close">
                    <span aria-hidden="true" >
                        <fa name="times"></fa>
                    </span>
                </button>
            </div>
            <div id="couponsErrorMessage" class="hidden">
                <p style="font-size: 14px;">There are no offers available.</p>
            </div>
            <app-lazy-load [isLoaded]="isLoaded" [sectionName]="'Offers'"></app-lazy-load>
            <div class="modal-body coupons-other-areas" class="singleCoupon" class="couponprintdata" id="couponprintdata" style="display:block;">

                <!-- testing modals -->
                <div id="couponCarousel" class="carousel slide couponPageCarousel " data-ride="carousel">

                    <!-- Wrapper for slides -->
                    <div class="carousel-inner">
							<ng-container *ngFor="let couponData of array_data.coupons; let couponIndex = index">
                        <div class="item" [class.active]="couponIndex=='0'" *ngIf="couponData?.id==couponSelectedId">
                            <div class="couponDataSection myCoupon">
                                <article class="ResponsiveCouponsPrint">
                                    <header>
                                        <p>Mobile Code</p>
                                        <h1 class="text-primary text-uppercase mar-top-0">{{couponCodeVaue}}</h1>
                                    </header>
                                    <main class="">
                                        <!-- <div class="flex-row visible-xs mar-top-xs">
                                            <div class="col-10 col-xs-offset-1">
                                                <a href="javascript:;" class="btn btn-block theme-btn btn-primary">Add to mobile wallet</a>
                                            </div>
                                        </div> -->


                                        <div class="clearfix panel-body garage-pad">

                                            <div class="flex-row no-gutters align-items-center ">
                                                <div class="col-9">
                                                    <h2 class="text-primary text-uppercase ">{{array_data?.facility?.entrance_location}}</h2>
                                                    <h4 class="coupon-date-desc"> {{couponData?.description}} </h4>
                                                    <p *ngIf="couponData.details!=''" class="mar-top-sm">{{couponData.details}}.</p>
                                                    <p *ngIf="couponData.details==''" class="mar-top-sm">7 Days a week / Enter any time / Exit any time / {{couponData?.max_stay_details}}.</p>

                                                </div>
                                                <div class="col-3 text-center">
                                                    <h1 class="priceCoupon" sourceFont>${{couponData.split_price[0]}}</h1>
                                                    <p>tax included<br />(Based on Availability) </p>
                                                </div>
                                            </div>
                                            <div class="text-center  " id="couponvalid" *ngIf="showPrintCancel">
                                                <button type="button" class="btn btn-primary theme-btn btn-fill " (click)="printMedia(couponData?.id)">Print</button>
                                                <!-- <div class="visible-xs" style="height:3px;" *ngIf="this.loggedUserName" ></div> -->
                                                <button type="button" class="btn btn-primary theme-btn btn-fill " (click)="lgModal.hide()">Cancel</button>&nbsp;
                                            </div>
                                        </div>
                                        <div class="clearfix">


                                            <div class="flex-row no-gutters ">
                                                <div class="col-12 col-sm-6">
                                                    <div class="panel-body cpn-detal-area-pd">
                                                        <h4 class="text-primary text-uppercase mar-bottom-xs ">Rate Details</h4>
                                                        <p *ngIf="couponData.coupon_restrictions!=''">{{couponData.coupon_restrictions}}</p>
                                                        <p>{{couponData?.used_text}}
                                                        </p>
                                                        <p *ngIf="couponData?.expire_on">Expires {{couponData?.expire_on}}</p>
                                                        <p>Code :  #{{couponData?.coupon_code}}</p>
                                                        <div class="coupon-barcode">
                                                            <ngx-barcode *ngIf="barcodeData!='' && barcodeData!=null" [bc-height]="53" [bc-margin-top]="1" [bc-margin-bottom]="1" [bc-margin-left]="1" [bc-margin-right]="1" [bc-background]="gray"
                                                            [bc-width]="2" [bc-element-type]='svg' [bc-value]="barcodeValue" [bc-display-value]="false"></ngx-barcode>
                                                            <!-- <qrcode *ngIf="qrcodeData!='' && qrcodeData!=null" [colorlight]="'#ffffff'" [qrdata]="barcodeValue" [size]="150" [level]="'M'"></qrcode> -->
                                                        </div>
                                                        <div class="extraContent hidden-xs">

                                                            <hr>
                                                            <div class="clearfix"></div>
                                                            <p>{{couponData?.disclaimer}}</p>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div class="col-12 col-sm-6 darklight">
                                                    <div class="panel-body fac-detal-area-pd">
                                                        <h4 class="text-primary text-uppercase  ">Facility Details</h4>
                                                        <h3> {{ array_data?.facility?.facility_name_modified }} </h3>
                                                        <p>
                                                            <fa name="phone"></fa> &nbsp;
                                                            <a id="garage_phone" style="color: #000;" href="tel:{{array_data?.facility?.phone_number}}">{{array_data?.facility?.phone_number}}</a>
                                                        </p>
                                                        <p class="mar-bottom-xs" *ngIf="array_data?.facility?.between_streets">
                                                            <b>Between:</b> {{ array_data?.facility?.between_streets }}</p>
                                                        <p class="mar-bottom-xs" *ngIf="array_data?.facility?.entrance_location">
                                                            <b>Entrance</b> {{array_data?.facility?.entrance_location}}</p>
                                                        <p class="dailystyles mar-bottom-xs  mar-top-xs ">
                                                            <a (click)="getdirections.show();lgModal.hide()">Get Directions &nbsp;&nbsp;
                                                                <fa name="chevron-right"></fa>
                                                            </a>
                                                        </p>
                                                        <div class="clearfix">
                                                        
                                                            <p *ngIf="array_data.facility?.hours_of_operation?.length == 0" class="mar-bottom-xs">Open: 7 Days a week, 24 hours a day</p>
                                                            <div class="TimeActive table-responsive" *ngIf="array_data.facility?.hours_of_operation?.length > 0">
                                                                <h4>Hours of Operation</h4>
                                                                <table class="table table-condensed ">
                                                                    <tr *ngFor="let wh of array_data.facility?.hours_of_operation;">
                                                                        <td>{{wh.day}}</td>
                                                                        <td>:</td>
                                                                        <td>{{wh.formatted_open_time}}</td>
                                                                        <td>-</td>
                                                                        <td>{{wh.formatted_close_time}}</td>
                                                                    </tr>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="parkImg">
                                                        <img [src]="imgSrcLocation" alt="icon" class='full-width'>
                                                    </div>

                                                </div>
                                                <div class="col-12 visible-xs">
                                                    <div class="extraContent">

                                                        <div class="clearfix panel-body">
                                                            <p>{{couponData?.disclaimer}}</p>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </main>

                                </article>


                            </div>
                        </div>
                            </ng-container>
                    </div>

                    <!-- Indicators & controles -->
                </div>

            </div>


        </div>
    </div>
</div>
</div>
<div class="hide"  *ngFor="let couponData of array_data.coupons; let couponIndexTable = index">
    <div id="table{{couponData?.id}}">
        <!-- <table  width="600" align="center" cellspacing="0" cellpadding="0" style="border:1px solid #ddd;-webkit-print-color-adjust: exact;">
            <tbody>
                <tr bgcolor="#ececec" class="coupan-head-pattern" style="background: #ececec!important;width: 100%;vertical-align: middle;-ms-touch-action: manipulation;touch-action: manipulation;background-image: none;white-space: nowrap;-webkit-user-select: none;-moz-user-select: none;-ms-user-select: none;user-select: none;-webkit-print-color-adjust: exact;">
                   <td align="center" style="padding: 20px; -webkit-print-color-adjust: exact; ">
                    <ngx-barcode [bc-height]="53" [bc-margin-top]="1" [bc-margin-bottom]="1" [bc-margin-left]="1" [bc-margin-right]="1" [bc-background]="gray"
                        [bc-width]="2" [bc-element-type]='svg' [bc-value]="barcodeValue" [bc-display-value]="false"></ngx-barcode>
                        <p style="margin: 0;padding: 10px 0 0 0;font-family: sans-serif;">Coupone
                            code #{{couponData?.coupon_code}}
                        </p>
                    </td>
                </tr>
                <tr>
                    <td>
                        <table width="100%" align="center" cellspacing="0" cellpadding="0" style="-webkit-print-color-adjust: exact;">
                            <tbody>
                                <tr>
                                    <td style="padding: 30px;font-family: sans-serif;width: 75%; -webkit-print-color-adjust: exact;">
                                        <h2 style="font-size: 30px; -webkit-print-color-adjust: exact;color: #ed8222;font-weight: 500;margin: 0;padding: 0;text-transform: uppercase;">

                                            <font color="#ed8222">{{array_data?.facility?.entrance_location}}</font>
                                        </h2>
                                        <h4 style="margin: 10px 0;padding: 0; -webkit-print-color-adjust: exact; font-size: 20px;font-weight: 500;text-transform: uppercase;">
                                           {{couponData?.description}}
                                        </h4>
                                        <p *ngIf="couponData.details!=''" style="font-weight: normal;font-size: 18px;font-weight: 100;">
                                            {{couponData.details}}.
                                        </p>
                                        <p *ngIf="couponData.details==''" style="font-weight: normal;font-size: 18px;font-weight: 100;">
                                            7 Days a week / Enter any time / Exit any time / {{couponData?.max_stay_details}}.
                                        </p>
                                    </td>
                                    <td style="text-align: center;font-family: sans-serif;">
                                        <h1 style="font-size: 55px;font-weight: 400;margin-bottom: 0;">
                                            ${{couponData.split_price[0]}}
                                        </h1>
                                        <p style="margin-top: 0;font-weight: normal;">
                                            tax included
                                        </p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
                <tr>
                    <td>
                        <table width="100%" align="center" cellspacing="0" cellpadding="0" style="-webkit-print-color-adjust: exact;">
                            <tbody>
                                <tr>
                                    <td width="50%" style="padding: 30px;vertical-align: top;padding-bottom: 0;">
                                        <h4 style="font-size: 20px;font-weight: 500;color: #ed8222;margin: 15px 0;text-transform: uppercase;font-family: sans-serif;margin-top: 0; -webkit-print-color-adjust: exact;">
                                            <font color="#ed8222">Coupon Details</font>
                                        </h4>
                                        <p *ngIf="couponData.coupon_restrictions!=''" style="font-size: 14px;font-weight: 500;margin: 15px 0;text-transform: uppercase;font-family: sans-serif;">
                                            {{couponData.coupon_restrictions}}
                                        </p>
                                        <p style="font-size: 14px;font-weight: 500;margin: 15px 0;font-family: sans-serif;">
                                            Coupon Id : {{couponCodeVaue}}
                                        </p>
                                        <p style="font-size: 14px;font-weight: 500;margin: 15px 0;font-family: sans-serif;">
                                            {{couponData?.used_text}}
                                        </p>
                                        <p *ngIf="couponData?.expire_on" style="font-size: 14px;font-weight: 500;margin: 15px 0;font-family: sans-serif;">
                                            {{couponData?.expire_on}}
                                        </p>
                                        <div style="font-size: 14px;font-weight: 500;margin: 15px 0;font-family: sans-serif;line-height: 1.5;margin-top: 30px;border-top: 2px solid #eee;padding: 15px 0;">
                                            <div class="clearfix"></div>
                                                <p>
                                                    {{couponData?.disclaimer}}
                                                </p>
                                        </div>
                                    </td>
                                    <td class="coupan-head-pattern" width="50%" bgcolor="#ececec" style="background: #ececec!important;width: 100%;-ms-touch-action: manipulation;touch-action: manipulation;background-image: none;white-space: nowrap;-webkit-user-select: none;-moz-user-select: none;-ms-user-select: none;user-select: none; vertical-align: top; -webkit-print-color-adjust: exact;">
                                         <div style="padding: 30px;padding-bottom: 0; -webkit-print-color-adjust: exact;">
                                            <h4 class="" style="font-size: 20px;font-weight: 500;color: #ed8222;margin: 15px 0;text-transform: uppercase;font-family: sans-serif;padding: 0;margin-top: 0; -webkit-print-color-adjust: exact;">
                                                <font color="#ed8222">Facility Details</font>
                                            </h4>
                                            <h3 style="font-family: sans-serif;text-decoration: none;text-transform: uppercase;font-weight: 500;margin: 0;padding: 0;font-size: 24px; word-break: break-all;">
                                                {{ array_data?.facility?.full_name }}
                                            </h3>
                                            <p>
                                                <a style="color: #000;font-size: 14px;font-weight: 500;margin: 15px 0;font-family: sans-serif;text-decoration: none;cursor: pointer;"><fa name="phone"></fa> &nbsp;{{array_data?.facility?.phone_number}} </a>
                                            </p>
                                            <p style="font-size: 14px;font-weight: 500;margin: 15px 0;font-family: sans-serif;">
                                                <b>Between:</b> {{ array_data?.facility?.between_streets }}
                                            </p>
                                            <p style="font-size: 14px;font-weight: 500;margin: 15px 0;font-family: sans-serif;">
                                                <b>Entrance</b> {{array_data?.facility?.entrance_location}}
                                            </p>
                                            <p style="font-size: 14px;font-weight: 500;margin: 15px 0;font-family: sans-serif;">
                                                <a (click)="getdirections.show()" style="font-size: 14px;font-weight: 500;margin: 15px 0;font-family: sans-serif;color: #ed8222;cursor: pointer; -webkit-print-color-adjust: exact;">
                                                    <font color="#ed8222">Get Directions &nbsp;&nbsp;<i class=" fa fa-chevron-right"></i></font>
                                                </a>
                                            </p>
                                            <p *ngIf="array_data.facility?.hours_of_operation?.length == 0" style="font-size: 14px;font-weight: 500;margin: 15px 0;font-family: sans-serif;">
                                                Open: 7 Days a week, 24 hours a day
                                            </p>
                                            <div class="TimeActive table-responsive" *ngIf="array_data.facility?.hours_of_operation?.length > 0">
                                            <h4>Hours of Operation</h4>
                                            <table class="table table-condensed ">
                                                <tr *ngFor="let wh of array_data.facility?.hours_of_operation;">
                                                    <td>{{wh.day}}</td>
                                                    <td>:</td>
                                                    <td>{{wh.formatted_open_time}}</td>
                                                    <td>-</td>
                                                    <td>{{wh.formatted_close_time}}</td>
                                                </tr>
                                            </table>
                                        </div>
                                        </div>
                                        <div class="parkImg" style="-webkit-print-color-adjust: exact;">
                                            <img class="" [src]="imgSrcLocation" style="width: 100%; -webkit-print-color-adjust: exact;">
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
            </tbody>
        </table> -->
        <table width="600" align="center" cellspacing="0" cellpadding="0" style="border:1px solid #ddd;-webkit-print-color-adjust: exact;">
            <tbody>
                         <tr bgcolor="#ececec">
                           <td align="center" style="padding: 20px;">
                            <ngx-barcode *ngIf="barcodeData!='' && barcodeData!=null" [bc-height]="53" [bc-margin-top]="1" [bc-margin-bottom]="1" [bc-margin-left]="1" [bc-margin-right]="1" [bc-background]="gray"
                                [bc-width]="2" [bc-element-type]='svg' [bc-value]="barcodeValue" [bc-display-value]="false"></ngx-barcode>
                            <!-- <qrcode *ngIf="qrcodeData!='' && qrcodeData!=null" [colorlight]="'#ffffff'" [qrdata]="barcodeValue" [size]="150" [level]="'M'"></qrcode> -->
                                 <p style="margin: 0;padding: 10px 0 0 0;font-family: sans-serif;">
                                    Code #{{couponData?.coupon_code}}
                                </p>
                                <p style="margin: 0;padding: 10px 0 0 0;font-family: sans-serif;">
                                    Mobile Code #{{couponCodeVaue}}
                                 </p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                 <table width="100%" align="center" cellspacing="0" cellpadding="0">
                                    <tbody>
                                <tr>
                                    <td style="padding:30px;font-family: sans-serif;width: 75%;">
                                                <h2 style="font-size: 30px; -webkit-print-color-adjust: exact;color: #ed8222;font-weight: 500;margin: 0;padding: 0;text-transform: uppercase;">
        
                                                    <font color="#ed8222">{{array_data?.facility?.entrance_location}}</font>
                                                </h2>
                                                <h4 style="margin: 10px 0;padding: 0; -webkit-print-color-adjust: exact; font-size: 24px;font-weight: 500;text-transform: uppercase;">
                                                   {{couponData?.description}}
                                                </h4>
                                                <p *ngIf="couponData.details!=''" style="font-weight: normal;font-size: 16px;font-weight: 100;">
                                                    {{couponData.details}}.
                                                </p>
                                                <p *ngIf="couponData.details==''" style="font-weight: normal;font-size: 16px;font-weight: 100;">
                                                    7 Days a week / Enter any time / Exit any time / {{couponData?.max_stay_details}}.
                                                </p>
                                            </td>
                                            <td style="text-align: center;font-family: sans-serif;">
                                                <h1 style="font-size: 60px;font-weight: 300;margin-bottom: 0;" sourceFont>
                                                    ${{couponData.split_price[0]}}
                                                </h1>
                                                <p style="margin-top: 0;font-weight: normal;">
                                                    tax included
                                                </p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <table style="transform: scale(0.9);
                                transform: translateX(0px);" width="100%" align="center" cellspacing="0" cellpadding="0" style="-webkit-print-color-adjust: exact;">
                                    <tbody>
                                <tr>
                                    <td width="50%" style="padding: 30px;vertical-align: top;padding-bottom: 0;">
                                                <h4 style="font-size: 20px;font-weight: 500;color: #ed8222;margin: 15px 0;text-transform: uppercase;font-family: sans-serif;margin-top: 0; -webkit-print-color-adjust: exact;">
                                                    <font color="#ed8222">Rate Details</font>
                                                </h4>
                                                <p *ngIf="couponData.coupon_restrictions!=''" style="font-size: 14px;font-weight: 500;margin: 15px 0;text-transform: uppercase;font-family: sans-serif;">
                                                    {{couponData.coupon_restrictions}}
                                                </p>
                                            
                                                <p style="font-size: 14px;font-weight: 500;margin: 15px 0;font-family: sans-serif;">
                                                    {{couponData?.used_text}}
                                                </p>
                                                <p *ngIf="couponData?.expire_on" style="font-size: 14px;font-weight: 500;margin: 15px 0;font-family: sans-serif;">
                                                    Expires {{couponData?.expire_on}}
                                                </p>
                                                <div style="font-size: 14px;font-weight: 500;margin: 15px 0;font-family: sans-serif;line-height: 1.5;margin-top: 30px;border-top: 2px solid #eee;padding: 15px 0;">
                                                    <div class="clearfix"></div>
                                                        <p>
                                                            {{couponData?.disclaimer}}
                                                        </p>
                                                </div>
                                            </td>
                                             <td width="50%" style="background: #ececec;vertical-align: top;-webkit-print-color-adjust: exact;">
                                                 <div style="padding: 30px;padding-bottom: 0; -webkit-print-color-adjust: exact;">
                                                    <h4 class="" style="font-size: 20px;font-weight: 500;color: #ed8222;margin: 15px 0;text-transform: uppercase;font-family: sans-serif;padding: 0;margin-top: 0; -webkit-print-color-adjust: exact;">
                                                        <font color="#ed8222">Facility Details</font>
                                                    </h4>
                                                    <h3 style="font-family: sans-serif;text-decoration: none;text-transform: uppercase;font-weight: 500;margin: 0;padding: 0;font-size: 24px;">
                                                        {{ array_data?.facility?.facility_name_modified }}
                                                    </h3>
                                                    <p>
                                                        <a style="color: #000;font-size: 14px;font-weight: 500;margin: 15px 0;font-family: sans-serif;text-decoration: none;cursor: pointer;"><fa name="phone"></fa> &nbsp;{{array_data?.facility?.phone_number}} </a>
                                                    </p>
                                                    <p style="font-size: 14px;font-weight: 500;margin: 15px 0;font-family: sans-serif;" *ngIf="array_data?.facility?.between_streets">
                                                        <b>Between:</b> {{ array_data?.facility?.between_streets }}
                                                    </p>
                                                    <p style="font-size: 14px;font-weight: 500;margin: 15px 0;font-family: sans-serif;" *ngIf="array_data?.facility?.entrance_location">
                                                        <b>Entrance</b> {{array_data?.facility?.entrance_location}}
                                                    </p>
                                                    <p style="font-size: 14px;font-weight: 500;margin: 15px 0;font-family: sans-serif;">
                                                        <a (click)="getdirections.show()" style="font-size: 14px;font-weight: 500;margin: 15px 0;font-family: sans-serif;color: #ed8222;cursor: pointer; -webkit-print-color-adjust: exact;">
                                                            <font color="#ed8222">Get Directions &nbsp;&nbsp;<i class=" fa fa-chevron-right"></i></font>
                                                        </a>
                                                    </p>
                                                    <p *ngIf="array_data.facility?.hours_of_operation?.length == 0" style="font-size: 14px;font-weight: 500;margin: 15px 0;font-family: sans-serif;">
                                                        Open: 7 Days a week, 24 hours a day
                                                    </p> </div>
                                                    <div  style="padding: 0 30px 10px 30px; -webkit-print-color-adjust: exact;" *ngIf="array_data.facility?.hours_of_operation?.length > 0">
                                                        <h4><font face="sans-serif">Hours of Operation</font></h4>
                                                       <table cellspacing="0" cellpadding="0" width="100%" style="font-family: sans-serif;font-size: 10px;vertical-align: top;">
                                                            <tbody>
                                                                <tr *ngFor="let wh of array_data.facility?.hours_of_operation;">
                                                                <td style="width: 20%;vertical-align: top;"><font face="sans-serif">{{wh.day}}</font></td>
                                                                <td style="width: 10%;vertical-align: top;"><font face="sans-serif">:</font></td>
                                                                <td style="width: 20%;vertical-align: top;"><font face="sans-serif">{{wh.formatted_open_time}}</font></td>
                                                                <td style="width: 10%;vertical-align: top;"><font face="sans-serif">-</font>-</td>
                                                                <td style="width: 40%; vertical-align: top;"><font face="sans-serif">{{wh.formatted_close_time}}</font></td>
                                                                </tr>
                                                        </tbody></table>
                                                    </div>
                                                    
                                               
                                             
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        <tr>
                                <td>
                                    <table style="transform: scale(0.9);
                                    transform: translateX(0px);" width="100%" align="center" cellspacing="0" cellpadding="0" style="-webkit-print-color-adjust: exact;">
                                        <tbody>
                                    <tr>
                                        <td width="50%" style="padding: 30px;vertical-align: top;padding-bottom: 0;">
                                          </td>
                                                 <td width="50%" style="background: #ececec;vertical-align: top;-webkit-print-color-adjust: exact;">
                                                    <div class="parkImg" style="-webkit-print-color-adjust: exact;">
                                                        <img class="" [src]="imgSrcLocation" alt="icon" style="width: 100%; -webkit-print-color-adjust: exact;">
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                    </tbody>
                </table>
    </div>
</div>
<button style="display: none;" id="triggercoupon" type="button" class="btn btn-primary" (click)="lgModal.show()"></button>

<!-- Directions Model Starts From Here -->
<div bsModal #getdirections="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel"
  aria-hidden="true">

  <div class="modal-dialog modal-sm">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" aria-label="Close" (click)="getdirections.hide()">
          <fa name="times"></fa>
        </button>
        <h3 class="mar-top-0 text-uppercase text-primary">Get Directions</h3>
      </div>

      <div class="modal-body">
        <div class="form-group">
          <!-- <a *ngIf="allParkings.geolocation" class="btn btn-primary btn-block theme-btn" target="_blank" id="navigatetogoogle"
            href="https://maps.google.com?q={{allParkings.geolocation.latitude}},{{allParkings.geolocation.longitude}}">Navigate
            with Google Maps</a> -->
            <a *ngIf="array_data?.geolocations" class="btn btn-primary btn-block theme-btn" target="_blank" id="navigatetogoogle"
            href="https://maps.google.com/maps?daddr={{array_data?.geolocations?.latitude}},{{array_data?.geolocations?.longitude}}&travelmode=driving&dir_action=navigate">Navigate
            with Google Maps</a>
        </div>
        <div class="floating-label">
          <label class="control-label text-uppercase" for="sendapp">Send to Phone</label>
          <div class="input-group">
            <input type="text" class="form-control" id="sendapp" placeholder="Enter phone number">
            <span class="input-group-btn pad-left-xs">
              <button type="submit" class="btn theme-btn btn-primary" (click)="sendApp()">Send</button>
            </span>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>
<!-- Direction Model Ends Here -->