import { Component, OnInit, Input,TemplateRef} from '@angular/core';
import { MyAccountMonthlyParkingService } from '../../services/my-account-monthly-parking.service';
import { SearchpageserviceService } from '../../services/searchpageservice.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BsModalService } from 'ngx-bootstrap/modal';
import { CustomCode } from '../../shared/CustomCode';
import { NgForm } from '@angular/forms';
import { Crypto } from '../../classes/crypto';
import * as $ from 'jquery';
import { StaticpagesService } from 'app/services/staticpages.service';
@Component({
  selector: 'app-subscription-renewal',
  templateUrl: './subscription-renewal.component.html',
  styleUrls: ['./subscription-renewal.component.scss'],
  providers: [MyAccountMonthlyParkingService,SearchpageserviceService,Crypto]

})

export class SubscriptionRenewalComponent implements OnInit {
  orangestriptdata=""
  bgclr=""
  colorstatus="";
  helpIcon =  '/assets/img/info-icon.png';
  infoIcon =  'assets/img/info-icon.png';
  cardSecurityIcon ='assets/img/cardSecurity.png';
  respdata:any;
  facility_name:any;
  between:any;
  addresss:any;
  hours:any;
  desired_start_date:any;
  desired_start_date_format:any;
  renew_message:any;
  brand:any;
  modal:any;
  color:any;
  plate_number:any;
  rates:any;
  rateDetails:any='00.00';
  rateDetailsNetRate:any='00.00';
  taxForThis:any='00.00';
  selectedvahicletype:any;
  selectedvahicletype_Id:any;
  selected_price=0.00
  selected_text:any=''
  selected_message:any=''
  rate_type:any=''
  tenour:any=''
  monthlytaxFlag:any=0
  monthly_duration_value:any;
  monthlyrats:any=[]
  facilityImage = '/assets/img/park_garage.png';
  tenantData=[]
  llcImage='/assets/img/park_garage.png'
  monthly_rate_id=0
  facilityId=0
  tenant_id
  vehicle_surcharge=0
  textforvehicletype=''
  formData: any = {}
  isCardReq=true;
  cryptoData=''
  is_card_required=0
  is_creditcardshow=0;
  is_payment_id=-2
  isLoaded = false;
  payableAmount = 0.00;
  paymentMethods: any = {};
  paymentProfileToPay: any;
  successPayment = false;
  rate_type_flag=0;
  rateDiscount= 0;
  surchargeAmount= ""
  surcharge_type= "";
  disclaimer="";
  total_prices:any=0
  cardMask: any = [
    /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, ' ',
    /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, ' ',
    /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, ' ',
    /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/
  ];
  dateMask: any = [/[0-9]/, /[0-9]/, '/', /[0-9]/, /[0-9]/];
  public modalRef: BsModalRef;
  @Input() currentActive: string;
  @Input() accountDetails: any;
  @Input() accountNumber: any; 
  staticPageContent: any = {};
  tc_accept_btn_clicked: boolean=false;
  disabled_btn: boolean=true;
  disabled: boolean=true;
  isLoadeds = false;
  page: String = 'monthly-parking-terms-conditions';
  contentApi: any = {};
  showtcextra: boolean=true;

    constructor(private router: Router,private myAccountMonthlyParkingObj: MyAccountMonthlyParkingService,
      private searchPageServiceObj: SearchpageserviceService,
      private modalService: BsModalService,
      private cryptoObj: Crypto,
      private toastr: ToastrService,
      private staticObj: StaticpagesService) { }

    ngOnInit() {

      const myContainer = document.getElementById('tcevnt');

    myContainer.addEventListener('scroll', () => {
      console.log('offsetHeight', myContainer.offsetHeight);
      console.log('scrollTop', myContainer.scrollTop);
      console.log('scrollHeight', myContainer.scrollHeight);
      if (myContainer.offsetHeight + myContainer.scrollTop >= myContainer.scrollHeight) {
        this.disabled = false;
      }
    });

    this.staticObj.getCmsPageContent(this.page).subscribe((data: any) => {
          this.staticPageContent = data.result[0];
          this.isLoadeds = true;
        }, (error) => {
          console.log('Error: ' + JSON.stringify(error));
          this.isLoadeds = true;
        }
    );
    
     let psin= setInterval(()=>{
      if(!sessionStorage.getItem('is_subs') && this.router.url.indexOf('account-subscription') >-1 )
      {
           alert('you are not authorized to view this page.')
           clearInterval(psin);
           this.router.navigate(['/my-account/monthly-parking']);
      }
     },1000)
     
      this.justConsoleSomething();
      this.getpaymentMethods();
  }
  getpaymentMethods() {
    this.myAccountMonthlyParkingObj.paymentMethods(this.accountNumber)
    .then(
      (data:any)=> {
        this.isLoaded = true;
        this.payableAmount = this.accountDetails.curr_month_balance;
        this.paymentMethods = data['data'];
        if (this.paymentMethods.payments.length > 0) {
          this.paymentProfileToPay = this.paymentMethods.payments[this.paymentMethods.payments.length - 1].payment_profile_id;
        }
      },
      error => {
        this.toastr.error(error.errors.message, 'Sorry!');
        this.isLoaded = true;
        this.payableAmount = this.accountDetails.curr_month_balance;
      }
    );
  }
  justConsoleSomething() {
    //console.log("hello");
    this.myAccountMonthlyParkingObj.renewDetails(this.accountNumber).then(
      (res) => {
        this.respdata = res;
        this.respdata = this.respdata.data; 
        for(let p=0;p<this.respdata.renewal_plan_details.tenants_data.length;p++)
        {
          this.respdata.renewal_plan_details.tenants_data[p].yeschecked=false
          if(this.respdata.renewal_plan_details.tenants_data[p].is_renew_applicable==1)
          {
            this.respdata.renewal_plan_details.tenants_data[p].yeschecked=true
            break;
          }
        }
        this.vehicle_surcharge=0;
        this.textforvehicletype=''
        this.is_card_required=this.respdata.renewal_plan_details.is_card_required
        this.tenantData=this.respdata.renewal_plan_details.tenants_data
        let facility_details=this.respdata.renewal_plan_details.facility_details
        this.desired_start_date=this.respdata.renewal_plan_details.desired_start_date
        this.renew_message=this.respdata.renewal_plan_details.renew_message
        
        //this.disclaimer="obversized the data and type"
        //this.monthlytaxFlag=this.respdata.renewal_plan_details.monthlytaxFlag
        this.facility_name=facility_details.facility_name_modified
        this.facilityId=facility_details.id
        this.between=facility_details.between_streets
        this.addresss=facility_details.entrance_location
        this.hours=facility_details.hours_of_operation
        if('photos' in facility_details && 'url' in facility_details.photos)
        {
          this.llcImage=facility_details.photos.url
        }
       
       
       for(let p=0;p<this.tenantData.length;p++)
       {
         if(this.tenantData[p].is_renew_applicable==1)
         {
          let monthly= this.tenantData[p].vehicle_info
          //console.log(monthly);
          this.brand=monthly.make_name
          this.tenantData[p].yeschecked=true
          //alert(this.brand);
          this.modal=monthly.model_name
          this.color=monthly.Color
          this.plate_number=(monthly.License!='')? monthly.License : '-'
          //this.monthly_duration_value=monthly.monthly_duration_value
          this.tenant_id=this.tenantData[p].Tenat_ID
          this.rates=this.tenantData[p]
          
          this.surchargeAmount = this.tenantData[p].surchargeAmount
          this.surcharge_type= this.tenantData[p].surcharge_type
          this.disclaimer=this.tenantData[p].disclaimer
          //alert(this.disclaimer);
          this.monthly_duration_value=this.tenantData[p].Subscription_Term_Months
          //alert(this.monthly_duration_value);
          if(this.monthly_duration_value==12)
          {
            this.monthly_duration_value='12 Months'
          }
          if(this.monthly_duration_value==1)
          {
            this.monthly_duration_value='1  Month'
          }
          if(this.monthly_duration_value==3)
          {
            this.monthly_duration_value='3 Months'
          }
          if(this.monthly_duration_value==6)
          {
            this.monthly_duration_value='6 Months'
          }
          this.monthlytaxFlag=this.respdata.renewal_plan_details.tenants_data[p].monthlytaxFlag
          this.desired_start_date=this.respdata.renewal_plan_details.tenants_data[p].desired_start_date
          let date_formate=this.desired_start_date.split("-");
          this.desired_start_date_format=date_formate[1]+'-'+date_formate[2]+'-'+date_formate[0]
          let k=0;
          let m=0;
          for(let i=0;i<this.rates.facilityMonthlyTaxRates.length;i++)
          {
            //alert(this.rates.facilityMonthlyTaxRates[i].rate_description.name_alias);
            //alert(this.monthly_duration_value)
            if(this.monthly_duration_value==this.rates.facilityMonthlyTaxRates[i].rate_description.name)
            {
              k++;
              m=i;
              this.rateDetails = this.rates.facilityMonthlyTaxRates[i].rate;	
              this.monthly_rate_id=this.rates.facilityMonthlyTaxRates[i].id
              this.rates.facilityMonthlyTaxRates[i].checkedData=true;
              this.rate_type_flag=this.rates.facilityMonthlyTaxRates[i].rate_type_flag
              this.rateDiscount  =this.rates.facilityMonthlyTaxRates[i].rateDiscount
            }else{
              this.rates.facilityMonthlyTaxRates[i].checkedData=false;
            }
          }

          this.fetchTnc(this.monthly_duration_value,this.rateDetails);

          if(k==0)
          {
            m=0;
            this.monthly_rate_id=this.rates.facilityMonthlyTaxRates[0].id
            this.rates.facilityMonthlyTaxRates[0].checkedData=true;
            this.rate_type_flag=this.rates.facilityMonthlyTaxRates[0].rate_type_flag
              this.rateDiscount  =this.rates.facilityMonthlyTaxRates[0].rateDiscount
          }
          this.updatedata(this.rates,m)
          break;
          
         }
       }
      
       //this.checkData()
        //this.rates=this.respdata.renewal_plan_details
        
        
        // this.notoversized=this.respdata.is_oversize_allowed;
        // this.overSizedMessage=this.respdata.oversize_not_allowed_message;
        // this.getFacilityName = this.respdata.facility_name_modified;
        // this.getFacilityId = this.respdata.id;
        // this.taxHideShow=this.respdata.monthlytaxFlag
        
        // if(sessionStorage.getItem('selectedvahicletype'))
        // {
        //   this.selectedvahicletype = sessionStorage.getItem('selectedvahicletype');
        // }else{
        //   this.selectedvahicletype = '';
        // }
                  
        // if(this.respdata.photos != null) {
        //   this.facilityImage = this.respdata.photos.url;
        // } else {
        //   this.facilityImage = '/assets/img/park_garage.png';
        // }
        // if(this.selectedvahicletype=='')
        // {
        //   this.sortArray(this.respdata.facilityMonthlyTaxRates)
        //   for(let data of this.respdata.facilityMonthlyTaxRates ){
        //       this.selectedvahicletype=data.rate_description.name
        //       this.rateDetails=data.rate;
        //       this.rateDetailsNetRate=data.net_rate
        //       this.taxForThis=data.tax_rate
        //       this.selectedvahicletype_Id = data.id;
        //       break;
        //   }
        // }
        // for(let data of this.respdata.facilityMonthlyTaxRates ){
        //  if(data.rate_description.name == this.selectedvahicletype)
        //  {
        //    this.rateDetails=data.rate;
        //    this.rateDetailsNetRate=data.net_rate
        //    this.taxForThis=data.tax_rate
        //    this.selectedvahicletype_Id = data.id;
        //    break;
        //  }
        // }
        // this.redirectToSignUp();

      },
      (error) => { console.log('Error: ' + JSON.stringify(error)); }
    );
  }
  getRate(rateDetails,rateDetailsNetRate,tax,name)
  {
    this.rateDetails=rateDetails
    this.rateDetailsNetRate=rateDetailsNetRate
    this.taxForThis=tax
    this.selectedvahicletype=name
    sessionStorage.setItem('selectedvahicletype',name);
    this.fetchTnc(name,rateDetails);	
    // for(let data of this.respdata.facilityMonthlyTaxRates ){
    //   // if(data.rate_description.name == this.selectedvahicletype)
    //   // {
    //   //   //this.selectedvahicletype_Id = data.id;
    //   //   break;
    //   // }
    //  }
  }
  // showSavingFlexibilityMsg(msg){
  //   if(msg){
  //     let string = msg.toLowerCase()
  //     if(string.includes("6 month") || string.includes("6 months") ){
  //       return true;
  //     }
  //   }
    
    
  // }
  showSavingFlexibilityMsg(msg) {
    if (msg) {
      let string = msg.toLowerCase()
      //alert(string)
      if (string.includes("12 month") || string.includes("12 months")) {
        if ($(window).width() < 768  ) {
          this.orangestriptdata="Book Longer & Save More!"
        }
        else{
          this.orangestriptdata="Book Longer & Save More!"
        }
        
        this.bgclr="flexibility-box-orange"
        return true;
      }
      // if (string.includes("6 month") || string.includes("6 months")) {
      //   if ($(window).width() < 768  ) {
      //     this.orangestriptdata="Lock In Rates + Save!"
      //   }
      //   else{
      //     this.orangestriptdata="Lock In Term Rates + Save More!"
      //   }
      //   this.bgclr="flexibility-box-black"
      //   return true;
      // }
      // if (string.includes("3 month") || string.includes("3 months")) {
      //   if ($(window).width() < 768  ) {
      //     this.orangestriptdata="Term Rates Guaranteed "
      //   }
      //   else{
      //     this.orangestriptdata="Extend Your Terms & Save!"
      //   }
      //   this.bgclr="flexibility-box-black"
      //   return true;
      // }
      if (string.includes("1 month") || string.includes("1  month")) {
        if ($(window).width() < 768  ) {
          this.orangestriptdata="Subject To Rate Changes"
        }
        else{
          this.orangestriptdata="Subject To Rate Changes"
        }
        this.bgclr="flexibility-box-grey"
        return true;
      }
    }

    //
  }
  acceptTermsCondition(){
    this.tc_accept_btn_clicked=true;
    this.formData.terms = true
  }

  closeTermsPopup(){
   
    if(this.tc_accept_btn_clicked!=true){
      document.getElementById('tcevnt').scrollTop=0; 
      this.disabled_btn=true;
      
      this.disabled=true;
      $('#terms_checkbox').prop('checked',false);
      this.formData.terms = false;
    }
    else{
      this.disabled=false;
      this.formData.terms = true;
      document.getElementById('tcevnt').scrollTop=0;
    }
  }
  activate_term_btn(){
    if(this.disabled_btn==true){
      this.disabled_btn=false;
    }
    else{
      this.tc_accept_btn_clicked=false;
      this.disabled_btn=true;
    }
    
  }
  formatChange(val)
  {   
    return val.toFixed(2)
  }
  openpopupsetup(){
    if(this.tc_accept_btn_clicked!=true){
      document.getElementById('tcevnt').scrollTop=0;
      this.disabled_btn=true;
      this.disabled=true;
      $('#terms_checkbox').prop('checked',false);
      this.formData.terms = false;
      document.getElementById('tcevnt').scrollTop=0;
      $("#tcevnt").animate({scrollTop:0}, "smooth");
      setTimeout(()=>{
        this.disabled=true;
      }, 1000);
    }
  }
  checkFormatForList(value)
  {
    if(value.toString().length==7)
    {
      let a = value.toString();
      let b = ",";
      let position = 1;
      let output = [a.slice(0, position), b, a.slice(position)].join('');
      if(output!= undefined && output != null){
        output = output.toString().replace('.00','');
      }
      return output
    }else{
      // console.log(value, '11')
      if(value!= undefined && value != null){
        value = value.toString().replace('.00','');
      }
      return value;
    }
  }
  updatedata(rates,array_number)
  {
   // console.log(rates.facilityMonthlyTaxRates[array_number].facility_id)
    if(this.monthlytaxFlag==1)
    {
      this.selected_price=rates.facilityMonthlyTaxRates[array_number].rate
    }else{
      this.selected_price=rates.facilityMonthlyTaxRates[array_number].net_rate
    }
    this.selected_text=rates.facilityMonthlyTaxRates[array_number].rate_description.description
    this.selected_message=rates.facilityMonthlyTaxRates[array_number].rate_description.termination_text
    this.accountDetails.rate_type=rates.facilityMonthlyTaxRates[array_number].rate_description.hours_description
    this.rate_type=rates.facilityMonthlyTaxRates[array_number].rate_description.hours_description
    this.tenour=rates.facilityMonthlyTaxRates[array_number].rate_description.name
    this.rate_type_flag=rates.facilityMonthlyTaxRates[array_number].rate_type_flag
    this.rateDiscount  =rates.facilityMonthlyTaxRates[array_number].rateDiscount
    this.monthly_rate_id=rates.facilityMonthlyTaxRates[array_number].id
  }
  checkData()
  {
    //alert(2)l
    //console.log(this.monthly_duration_value);
    let k=0;
    for(let i=0;i<this.rates.facilityMonthlyTaxRates.length;i++)
    {
      //alert(this.rates.facilityMonthlyTaxRates[i].rate_description.name_alias);
      //alert(this.monthly_duration_value)
      if(this.monthly_duration_value==this.rates.facilityMonthlyTaxRates[i].rate_description.name)
      {
        k++;
        this.updatedata(this.rates,i)
        return true;
      }
    }
    if(k==0)
    {
      this.updatedata(this.rates,0)
      return true;
    }

  }
  updateText(zi)
  {
    //alert(zi)
    this.updatedata(this.rates,zi)
  }
  public openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }
  test(i){
    let monthly= this.tenantData[i].vehicle_info
       //console.log(monthly);
       this.brand=monthly.model_name
     //  this.tenantData[p].yeschecked=true
     //  alert(this.tenantData[i].Subscription_Flag);
       this.modal=monthly.make_name
       this.color=monthly.Color
       this.plate_number=(monthly.License!='')? monthly.License : '-'
       this.monthlytaxFlag=this.tenantData[i].monthlytaxFlag
       //this.monthly_duration_value=monthly.monthly_duration_value
       //console.log(this.tenantData[i]);
       this.tenant_id=this.tenantData[i].Tenat_ID
       this.rates=this.tenantData[i]
       this.monthly_duration_value=this.tenantData[i].Subscription_Term_Months
       //this.rate_type_flag=this.tenantData[i].rate_type_flag
       // this.rateDiscount= this.tenantData[i].rateDiscount
        this.surchargeAmount= this.tenantData[i].surchargeAmount
        this.surcharge_type= this.tenantData[i].surcharge_type
        this.disclaimer=this.tenantData[i].disclaimer
       if(this.monthly_duration_value==12)
       {
         this.monthly_duration_value='12 Months'
       }
       if(this.monthly_duration_value==1)
       {
         this.monthly_duration_value='1  Month'
       }
       if(this.monthly_duration_value==3)
       {
         this.monthly_duration_value='3 Months'
       }
       if(this.monthly_duration_value==6)
       {
         this.monthly_duration_value='6 Months'
       }
       this.desired_start_date= this.tenantData[i].desired_start_date
       let date_formate=this.desired_start_date.split("-");
       this.desired_start_date_format=date_formate[1]+'-'+date_formate[2]+'-'+date_formate[0]
          let k=0;
          let m=0;
          for(let i=0;i<this.rates.facilityMonthlyTaxRates.length;i++)
          {
            //alert(this.rates.facilityMonthlyTaxRates[i].rate_description.name_alias);
            //alert(this.monthly_duration_value)
            if(this.monthly_duration_value==this.rates.facilityMonthlyTaxRates[i].rate_description.name)
            {
              k++;
              m=i;
              this.monthly_rate_id=this.rates.facilityMonthlyTaxRates[i].id
              this.rates.facilityMonthlyTaxRates[i].checkedData=true;
              this.rate_type_flag=this.rates.facilityMonthlyTaxRates[i].rate_type_flag
              this.rateDiscount  =this.rates.facilityMonthlyTaxRates[i].rateDiscount
            }else{
              this.rates.facilityMonthlyTaxRates[i].checkedData=false;
            }
          }
          if(k==0)
          {
            m=0;
            this.monthly_rate_id=this.rates.facilityMonthlyTaxRates[0].id
            this.rates.facilityMonthlyTaxRates[0].checkedData=true;
            this.rate_type_flag=this.rates.facilityMonthlyTaxRates[0].rate_type_flag
            this.rateDiscount  =this.rates.facilityMonthlyTaxRates[0].rateDiscount
          }
          this.updatedata(this.rates,m)
       //this.updatedata(this.rates,i)
  }
  renewPlan(v)
  {
    if(this.is_card_required)
    {
      if(this.is_payment_id<-1)
      {
        this.toastr.error('Please select atleast one payment method', 'Sorry!');
        return false;
      }
    }
    if(!this.formData.terms || this.formData.terms==undefined)	
    {     	
        this.toastr.error('Please Accept  Monthly Parking Terms and Conditions', 'Sorry!');	
        return false;    	
    }	
    
    let device_type = 'web';
    if ($(window).width() < 767) {  device_type = 'web responsive'; }
        else{ 
          device_type = 'web';
         }
    //alert(this.tenour+' '+this.monthly_rate_id+' '+this.facilityId+' '+this.accountNumber+' '+this.tenant_id);
    //alert(this.tenour);
    let data={
      "desired_start_date": this.desired_start_date,
      "monthly_duration_value": this.tenour,
      "monthly_rate_id": this.monthly_rate_id,
      "monthly_rate": this.selected_price,
      "facility_id": this.facilityId,
      "account_number": this.accountNumber,
      "tenant_id": this.tenant_id,
      "nonce":this.cryptoData,
      "payment_id":this.is_payment_id,
      'rate_type_flag':this.rate_type_flag,
      'rateDiscount': this.rateDiscount,
      'surchargeAmount': this.surchargeAmount,
      'surcharge_type': this.surcharge_type,
      'disclaimer':this.disclaimer,
      'modal':this.modal,
      'brand':this.brand,
      'plate_number':this.plate_number,
      'color':this.color,
      'device_type':device_type


  }
  if(this.is_payment_id!=-1)
  {
    delete data.nonce;
  }
  if(this.is_payment_id<0)
  {
    delete data.payment_id;
  }
  //console.log(data)
  //console.log('renew screen')
    //alert(2)
    this.myAccountMonthlyParkingObj.renewPlan(this.facilityId,data).then(
      (res) => {
        let respdata = res;
         respdata = respdata['data'].tracking_code; 
         let ls_loyality  = '0';
          if(localStorage.getItem('is_loyalty_active')){
          ls_loyality = localStorage.getItem('is_loyalty_active')
          }
          (<any>window).dataLayer.push(
            {
          'event': 'Monthly_Reservation_ Renewal',
            'UserId' :parseInt(localStorage.getItem('userid')),
            'Facility': [{
              'name': this.facility_name,                      // Name or ID is required.
              'id': this.facilityId,
              'price': this.rateDetailsNetRate,          //  need to check
              'category': 'Monthly',
              'confirmationCode': respdata   //// IQMP19585F86U540786
              }]
          })

          this.router.navigate(['/monthly-renewal-confirmation/',respdata,ls_loyality]);
         //alert(respdata);// thi

        },
      (error) => { 
        this.toastr.error(error.errors.message, 'Sorry!');
        (<any>window).dataLayer.push(
          {
        'event': 'Make_monthly_payment_api_failed',
          'UserId' :parseInt(localStorage.getItem('userid')),
          'Facility': [{
            'name': this.facility_name,                      // Name or ID is required.
            'id': this.facilityId,
            'price': this.rateDetailsNetRate,          //  need to check
            'category': 'Monthly',
            'error_message': error.errors.message   //// IQMP19585F86U540786
            }]
        })
       }
    );
  }

  showtimefornxtornot(text)
    {
      var res = text.split(':');
      if(res[0]>24)
      {
        return '(next day)'
      }else{
        return '';
      }
    }
    total_price(val1,va2)
    {
      this.total_prices=val1
      return val1;
    }
    monthlyPayment(f,t)
    {
      //console.log(f);
      //let cryptoData:any;
      this.cryptoData = this.cryptoObj.encrypt({'name_on_card': f.name_on_card, 'card_number': f.card_number.replace(/\D/g, ''), 'expiration_date': f.expiration_date, 'security_code': f.security_code, 'zip_code': f.pzipcode});
      this.renewPlan(2);
      //console.log(this.cryptoData)
    }
    monthlyPayments(f,t)
    {
      //console.log(f);
      //let cryptoData:any;
      this.cryptoData = this.cryptoObj.encrypt({'name_on_card': f.name_on_card, 'card_number': f.card_number.replace(/\D/g, ''), 'expiration_date': f.expiration_date, 'security_code': f.security_code, 'zip_code': f.pzipcode});
      //this.renewPlan(2);
      //console.log(this.cryptoData)
    }
    restrictNumeric(event) {
      CustomCode.restrictNumericInCard(event);
      }
      unmask(val){
        var val1 = val;
        return val.replace(/[/_ ]/g, '');
      }
      setToexpirationField(reference)
    {
      this.formData.exp1=reference.value;
    }
    updatePaymentMethod(id)
    {
     // alert(id);
      this.is_payment_id=id
      //alert(id);
      if(id==-1)
      {
        this.is_creditcardshow=1;
      }else{
        this.is_creditcardshow=0;
      }
    }

    displayNameOnCard(name){
      if( name != null && name !='null'){
        return name
      }else {
        return '';
      }
    }

    fetchTnc(name,ratedetails) {
      
      this.myAccountMonthlyParkingObj.getRenewalTncDetails()
      .then(
        (data:any)=> {
      
            if (name == '1  Month') {
              var getmon = name.replace('  Month','');
              this.colorstatus="black"
            }else{              
              var getmon = name.replace(' Months','');
              this.colorstatus="black"
            }           
           //alert(this.colorstatus)   
          if(getmon){           
          let result = Object.values(data.data)[0][getmon]; 
          this.contentApi = result.replace("||RENEWAL RATE||","<strong >"+ratedetails+"</strong>");
          this.contentApi = this.contentApi.replace("||RENEWAL START DATE||","<strong class='wrap'>"+this.desired_start_date_format+"</strong>");
        //  this.contentApi = this.contentApi.replace("<font color = #ED8222><a href=\\\"https:\\\/\\\/iconparkingsystems.com\\\/\\\" target=\\\"_blank\\\">Read Terms & Conditions<\/a><\/font>",'<a type="button" style="cursor: pointer" > Read Terms & Conditions</a>');
        this.contentApi =   this.contentApi.substring(0, this.contentApi.indexOf("<font color = #ED8222>"));
        this.contentApi += '<a type="button" style="cursor: pointer" > Read Terms & Conditions</a>';
          
          }

        });
        
      }

}
