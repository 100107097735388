import { Component, OnInit, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
//import { Http, Headers, RequestOptions, Response } from '@angular/http';

import { Observable } from 'rxjs/Rx';

// Import RxJs required methods
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';

import { StaticpagesService } from '../../services/staticpages.service';

@Component({
  selector: 'app-icongreen',
  templateUrl: './icongreen.component.html',
  styleUrls: ['./icongreen.component.scss'],
  providers: [StaticpagesService]
})
export class IcongreenComponent implements OnInit {

  page: String = 'icon-green';
  staticPageContent: any = {};
  constructor(private router: Router, private staticObj: StaticpagesService) { }

  ngOnInit() {
    this.staticObj.staticPagesContent(this.page).then(
      (res) => {
        this.staticPageContent = res;
        this.staticPageContent = this.staticPageContent.data;
      },
      (error) => { console.log('Error: ' + JSON.stringify(error)); });
  }

}
