import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CustomCode } from '../../../shared/CustomCode';
import {GoogleAnalyticsEventsService} from "../../../shared/google-analytics-events.service";
import { SafeResourceUrl } from '@angular/platform-browser';
// declare var ga: Function;
// declare var gtag: Function;
// declare var gtag_report_conversion : Function;

@Component({
  selector: 'app-park10-landing',
  templateUrl: './park10-landing.component.html',
  styleUrls: ['./park10-landing.component.scss']
})
export class Park10LandingComponent implements OnInit {

	@Input() promoCode: string;

	@Output() saveEvent = new EventEmitter<boolean>();

  gtmUrl: SafeResourceUrl = window.location.pathname;

	constructor(public googleAnalyticsEventsService: GoogleAnalyticsEventsService) { }

	ngOnInit() {
	}

	savePromocode(loccation) {
		/* commented for ga 16-6-2021 by seo team
		ga('send', 'event', { eventCategory: 'save park10', eventAction: 'click'});
		*/

  /* commented for ga 16-6-2021 by seo team
    gtag_report_conversion(this.gtmUrl);

		gtag('event', 'conversion', {
      		'send_to': 'AW-876742851/sf5WCJDL43sQw5GIogM',
          'eventCallback':window['callbackgtm'] 
  		});
		  */
  		(<any>window).dataLayer.push(
         {
            'promoCode': 'save park10',
            'event': 'Promocode save',
            'eventCallback': window['callbackgtm'] 
         });
		this.saveEvent.emit(loccation);
	}
}
