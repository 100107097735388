import { Component, OnInit } from '@angular/core';

import { Router, ActivatedRoute, Params } from '@angular/router';
import { NgForm } from '@angular/forms';
import * as $ from 'jquery';

import { CustomCode } from '../../shared/CustomCode';
import { MyAccountMonthlyParkingService } from '../../services/my-account-monthly-parking.service';
import { ToastrService } from 'ngx-toastr';
import { UserserviceService } from '../../services/userservice.service';

@Component({
  selector: 'app-create-online-account',
  templateUrl: './create-online-account.component.html',
  styleUrls: ['./create-online-account.component.scss'],
  providers: [MyAccountMonthlyParkingService, UserserviceService]
})
export class CreateOnlineAccountComponent implements OnInit {

	formData: any = {};
  loggedUser:any;
  account_numberIcon = 'assets/img/account_number.png';
  zip_codeIcon = 'assets/img/zip_code.png';
  helpIcon = 'assets/img/help.svg'
  isTierLoaded:boolean=false;
  constructor(
  	private routerObj: Router,
    private activatedRouteObj: ActivatedRoute,
    private toastr: ToastrService,
    private userServiceObj: UserserviceService,
    private myAccountMonthlyParkingObj: MyAccountMonthlyParkingService) { }

  ngOnInit() {
    this.loggedUser = CustomCode.getloggedInUserDetails();
  }

  logOut() {
    CustomCode.userLogout();
    this.routerObj.navigate(['/']);
  }

  addNewMonthlyAccount(formData, form: NgForm) {
    this.fetchUserStatusForTracking();
    this.myAccountMonthlyParkingObj.monthlyParkingRegister(formData)
    .then(
      (data:any) => {
        if (!data['errors']) {
          this.userServiceObj.GetLoyaltyProfile().subscribe(
            (res:any) => {
              let uData = res.data;
              localStorage.setItem('is_loyalty', uData.is_loyalty);
              localStorage.setItem('is_loyalty_active', uData.is_loyalty_active);
              this.toastr.success('Monthly parking account successfully linked to your account', 'Great!');
              this.routerObj.navigate(['/my-account/monthly-parking']);
            },
            error => {
              let errorMessage = error.errors.message;
              if (error.errors.message === 'Validation Errors') {
                errorMessage = '';
                for (const key in error.errors.detail) {
                    errorMessage += error.errors.detail[key];
                }
              }
              this.toastr.error(errorMessage, 'Sorry!');
              this.routerObj.navigate(['/my-account/monthly-parking']);
            }
          );
        } else {
          let errorMessage = data['errors'].message;
          this.toastr.success(errorMessage, 'Great!');
          this.routerObj.navigate(['/my-account/monthly-parking']);
        }
      },
      error => {
        let errorMessage = error.errors.message;
        if (error.errors.message === 'Validation Errors') {
          errorMessage = '';
          for (const key in error.errors.detail) {
              errorMessage += error.errors.detail[key];
          }
        }
        this.toastr.error(errorMessage, 'Sorry!');
      }
    );
  }

  restrictNumeric(event) {
    let input;
    if (event.metaKey || event.ctrlKey) {
      return true;
    }
    if (event.which === 32) {
     return false;
    }
    if (event.which === 0) {
     return true;
    }
    if (event.which < 33) {
      return true;
    }
    input = String.fromCharCode(event.which);
    return !!/[\d\s]/.test(input);
  }

  updateTier(tier){
    this.isTierLoaded = tier;
  }
  addMenuactive()
  {
    sessionStorage.setItem('menuActive','2')
  }


  fetchUserStatusForTracking(){
		let getUserId = '0';
		let getUserEmail = '0';
		let user_login_type = '';
		let isLogin = false;
		if(localStorage.getItem('access_token')){
		 isLogin = true;
		   getUserId = localStorage.getItem('userid');
		   getUserEmail = localStorage.getItem('email');
		   user_login_type = localStorage.getItem('user_login_type');
		}
		const loginStatusTrack = {
		  'event' : 'pageview',
		  'loginStatus' : isLogin? 'logged in': 'Not Logged IN', // 'logged in/Not Logged IN',
		  'authenticationMethod' : user_login_type,
		  'userId' : getUserId,
		  'pageType' : this.routerObj.url,
		  'email': getUserEmail,
	   };
	  (<any>window).dataLayer.push(loginStatusTrack);
	  }
}
