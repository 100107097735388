import { Component, OnInit, NgZone, Input, EventEmitter,ElementRef, Output, ChangeDetectorRef, TemplateRef, ViewChild, AfterViewInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { NgForm } from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { FilterHelper } from '../../../classes/filter-helper';
import { CustomCode } from '../../../shared/CustomCode';
import { MyAccountMonthlyParkingService } from '../../../services/my-account-monthly-parking.service';
import { SearchpageserviceService } from '../../../services/searchpageservice.service';
import { HomepageserviceService } from '../../../services/homepageservice.service';
import { UserserviceService } from '../../../services/userservice.service';
import { GoogleAnalyticsEventsService } from "../../../shared/google-analytics-events.service";
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Ng2DeviceService } from 'ng2-device-detector';
import { StaticpagesService } from '../../../services/staticpages.service';

import * as $ from 'jquery';
declare var google: any;
// declare var ga: Function;
// declare var gtag: Function;
// declare var gtag_report_conversion : Function;
declare var Trustpilot;
//declare var kochava:any;

@Component({
  selector: 'app-facility',
  templateUrl: './facility.component.html',
  styleUrls: ['./facility.component.scss'],
  providers: [MyAccountMonthlyParkingService, SearchpageserviceService, HomepageserviceService,StaticpagesService, UserserviceService]
})
export class FacilityComponent implements OnInit {
	orangestriptdata=""
	bgclr=""
	metaTags:any;
	descriptionElement:any;
	showCoupon = false;
	facility_slug:string;
	facility:any = {};
	startDate;
	endDate;
	startTime;
	endTime;
	fakeUrlAmount:number = 40;
	component_slug:string = "payment";
	facilityId;
	workingHours:any = {};
	isLoaded:boolean = false;
	isLoadedCopon:boolean=false
	startDuration:any;
	endDuration:any;
	boardRate: any = [];
	taxRate:any=0;
	iframeUrl:string = "";
	baseUrl:string = "https://maps.google.com/maps?q=";
	defaultLat:string = "35.856737";
	defaultLng:string = "10.606619";
	park_garage = '/assets/img/park_garage.png';
	quikparkIcon = 'assets/img/quikpark.svg'
	gMapSetting:string = "&z=16&output=embed";
	phoneNumber = 'tel:888-705-7845';
	phoneNumber2 = 'tel:1-877-727-5464';
	crossSvg='assets/img/cross.svg';
    leftNav = 'assets/img/left_nav.svg';
	rightNav = 'assets/img/right_nav.svg';
	arrival_time:any;
	length_of_stay:any;
	bookingPrice:any;

	displayStartTime;
	displayEndTime;
	displayStartDate;
	displayEndDate;
	finalPrice;
	responseData;
	facilityMap;
	firstMapLoad:boolean = false;

	showSelectBox = true;
	loggedUserName;
	activeUser;
	respdata;
	printData;
	searchResult;
	couponId = true;
	rateTypeId:any = 2;
	categoryId:any = 0;
	couponCode:any;
	isCouponLoaded:boolean = false;
	mailFormData: any = {};
	earlyBirdSpecialCategory:any = 1;
	EMAIL_COUPON:string = 'email';
	PRINT_COUPON:string = 'print';
	VIEW_COUPON:string = 'view';
	EarlyBrid:any;
 	earlybirIcon = "fa fa-times";
    gtmUrl:SafeResourceUrl = window.location.pathname;
	showRecord:any=true;
	getUpdatedPrice:boolean;
	activeLoading = false;
	FacilityAvailableFetatures:any;
	availabilityCheck=false;
	enableDefaultMsg = false;
	isPartners:boolean = false;
	selectedvahicletype:any;
	getCouponDisabled: boolean = false
	distance:any;
	ShowCouponListOnLoad: boolean = false;
	barcodeValue: any = 'd37255005006728';
	isLoyaltyMember:boolean = false;
	qrcodeData:any;
	barcodeData:any;
	couponCodeVaue: any = '341fce58'
	isClassVisible: boolean = true;
	ranValue = 0;
	couponMainId:any=0;
	text = '';
	value = '';
	textHideShow='SHOW'
	imgSrcLocation: string = '';
	public customClass: string = 'customClass';

	ev_charge_icon='assets/img/v8-design/icon-small-universalEV.svg';
	  motorcycle_icon='assets/img/v8-design/icon-small-motocycle.svg'
	  tesla_charger_icon='assets/img/v8-design/icon-small-tesla.svg'
	  open_24hr_icon='assets/img/v8-design/icon-small-24hr.svg'
	  monthly_icon='assets/img/v8-design/icon-small-monthly.svg'
	  oversized_icon='assets/img/v8-design/icon-small-oversized.svg'
	  outdoor_icon='assets/img/v8-design/icon-small-outdoorpark-v2.svg'
	  indoor_icon='assets/img/v8-design/icon-small-indoorpark-v2.svg'
	  list_icon='assets/img/list_icon.svg'
    amenityfilter='assets/img/amenity-filter.svg'
    booknow_blackLogo= 'assets/img/v8-design/bookpark-bttn.png'

	array_data: any = {
		"facility": {
			"id": 116,
			"short_name": "ZEN-DAYS",
			"full_name": "ZENITH PARKING LLC           ",
			"facility_name_modified":'',
			"garage_code": "8025",
			"facility_type_id": "1",
			"between_streets": "48th St and 49th St",
			"entrance_location": "790-806 8th Avenue and 254 West 49th Street",
			"phone_number": "(212) 581-8590 ",
			"base_rate": "70.00",
			"reservation_bonus_hours": 1,
			"reservation_bonus_rate": 5,
			"active": "1",
			"base_event_rate": "40.00",
			"oversize_fee": "15.00",
			"processing_fee": "0.00",
			"tax_rate": "0.18375",
			"base_event_rate_max_stay": "13",
			"garage_type_id": "0",
			"service_type_id": "0",
			"vehicle_type_id": "0",
			"garage_located": "",
			"accept_cc": "1",
			"accept_echeck": "1",
			"monthly_rate": "300.00",
			"neighborhood_id": "8",
			"legacy_id": "139",
			"elimiwait_location_id": "299",
			"beacon_status": "0",
			"is_indoor_parking": "1",
			"is_outdoor_parking": "0",
			"is_tesla_charging": "0",
			"is_generic_ev_charging": "0",
			"is_motorcycle_parking": "0",
			"realtime_window": null,
			"coupon_threshold": null,
			"merchant_id": "650000009794770",
			"slug": "zen-days",
			"has_ticketech": true,
			"has_monthly": true,
			"is_elimiwait_active": false,
			"display_name": "790 Eighth Avenue",
			"geolocations": {
				"id": 896,
				"address_1": "790 Eighth Avenue",
				"address_2": "",
				"city": "New York",
				"state": "NY",
				"zip_code": "10019",
				"longitude": "-73.9864679",
				"latitude": "40.7614799"
			}
		},
		"geolocations": {
			"id": 896,
			"address_1": "790 Eighth Avenue",
			"address_2": "",
			"city": "New York",
			"state": "NY",
			"zip_code": "10019",
			"longitude": "-73.9864679",
			"latitude": "40.7614799"
		},
		"coupons": [
			{
				"id": 3531,
				"facility_id": "116",
				"rate_type_id": "2",
				"min_stay": "0.00",
				"max_stay": "3.00",
				"price": "26.00",
				"monday": "1",
				"tuesday": "1",
				"wednesday": "1",
				"thursday": "1",
				"friday": "1",
				"saturday": "1",
				"sunday": "1",
				"active": "1",
				"description": "3 Hours Coupon",
				"entry_time_begin": "00:00:00",
				"entry_time_end": "23:59:59",
				"exit_time_begin": "00:00:00",
				"exit_time_end": "23:59:59",
				"category_id": "27",
				"coupon_code": "dt237",
				"deleted_at": null,
				"details": "",
				"partner_id": null,
				"coupon_restrictions": "NOT VALID ON THANKSGIVING DAY",
				"mobile_coupon_code": "341fce58",
				"split_price": [
					"26",
					"00"
				],
				"open_time_description": "Enter any time",
				"days_open_description": "7 Days a week",
				"time_in": "2:43 AM on Nov 01, 2018",
				"time_out": "Nov 15, 2018",
				"min_stay_details": "",
				"max_stay_details": "Max Stay 3 Hours",
				"exit_time_details": "Exit any time",
				"is_coupon": true,
				"is_partner_coupon": false,
				"is_event": false,
				"can_repeat": false,
				"can_always_enter": true,
				"can_always_exit": true,
				"facility": {
					"id": 116,
					"short_name": "ZEN-DAYS",
					"full_name": "ZENITH PARKING LLC           ",
					"facility_name_modified":'',
					"garage_code": "8025",
					"facility_type_id": "1",
					"between_streets": "48th St and 49th St",
					"entrance_location": "790-806 8th Avenue and 254 West 49th Street",
					"phone_number": "(212) 581-8590 ",
					"base_rate": "70.00",
					"reservation_bonus_hours": 1,
					"reservation_bonus_rate": 5,
					"active": "1",
					"base_event_rate": "40.00",
					"oversize_fee": "15.00",
					"processing_fee": "0.00",
					"tax_rate": "0.18375",
					"base_event_rate_max_stay": "13",
					"garage_type_id": "0",
					"service_type_id": "0",
					"vehicle_type_id": "0",
					"garage_located": "",
					"accept_cc": "1",
					"accept_echeck": "1",
					"monthly_rate": "300.00",
					"neighborhood_id": "8",
					"legacy_id": "139",
					"elimiwait_location_id": "299",
					"beacon_status": "0",
					"is_indoor_parking": "1",
					"is_outdoor_parking": "0",
					"is_tesla_charging": "0",
					"is_generic_ev_charging": "0",
					"is_motorcycle_parking": "0",
					"realtime_window": null,
					"coupon_threshold": null,
					"merchant_id": "650000009794770",
					"slug": "zen-days",
					"has_ticketech": true,
					"has_monthly": true,
					"is_elimiwait_active": false,
					"display_name": "790 Eighth Avenue",
					"geolocations": {
						"id": 896,
						"address_1": "790 Eighth Avenue",
						"address_2": "",
						"city": "New York",
						"state": "NY",
						"zip_code": "10019",
						"longitude": "-73.9864679",
						"latitude": "40.7614799"
					}
				},
				"rate_type": {
					"id": 2,
					"rate_type": "Coupons"
				}
			},
			{
				"id": 2814,
				"facility_id": "116",
				"rate_type_id": "2",
				"min_stay": "0.00",
				"max_stay": "12.00",
				"price": "36.00",
				"monday": "1",
				"tuesday": "1",
				"wednesday": "1",
				"thursday": "1",
				"friday": "1",
				"saturday": "1",
				"sunday": "1",
				"active": "1",
				"description": "12 Hours Coupon",
				"entry_time_begin": "00:00:00",
				"entry_time_end": "23:59:59",
				"exit_time_begin": "00:00:00",
				"exit_time_end": "23:59:59",
				"category_id": "20",
				"coupon_code": "dt237",
				"deleted_at": null,
				"details": "",
				"partner_id": null,
				"coupon_restrictions": "NOT VALID ON THANKSGIVING DAY",
				"split_price": [
					"36",
					"00"
				],
				"open_time_description": "Enter any time",
				"days_open_description": "7 Days a week",
				"time_in": "2:43 AM on Nov 01, 2018",
				"time_out": "Nov 15, 2018",
				"min_stay_details": "",
				"max_stay_details": "Max Stay 12 Hours",
				"exit_time_details": "Exit any time",
				"is_coupon": true,
				"is_partner_coupon": false,
				"is_event": false,
				"can_repeat": false,
				"can_always_enter": true,
				"can_always_exit": true,
				"rate_type": {
					"id": 2,
					"rate_type": "Coupons"
				}
			},
			{
				"id": 2816,
				"facility_id": "116",
				"rate_type_id": "2",
				"min_stay": "0.00",
				"max_stay": "6.00",
				"price": "28.00",
				"monday": "1",
				"tuesday": "1",
				"wednesday": "1",
				"thursday": "1",
				"friday": "1",
				"saturday": "1",
				"sunday": "1",
				"active": "1",
				"description": "6 Hours Coupon",
				"entry_time_begin": "00:00:00",
				"entry_time_end": "23:59:59",
				"exit_time_begin": "00:00:00",
				"exit_time_end": "23:59:59",
				"category_id": "22",
				"coupon_code": "dt237",
				"deleted_at": null,
				"details": "",
				"partner_id": null,
				"coupon_restrictions": "NOT VALID ON THANKSGIVING DAY",
				"split_price": [
					"28",
					"00"
				],
				"open_time_description": "Enter any time",
				"days_open_description": "7 Days a week",
				"time_in": "2:43 AM on Nov 01, 2018",
				"time_out": "Nov 15, 2018",
				"min_stay_details": "",
				"max_stay_details": "Max Stay 6 Hours",
				"exit_time_details": "Exit any time",
				"is_coupon": true,
				"is_partner_coupon": false,
				"is_event": false,
				"can_repeat": false,
				"can_always_enter": true,
				"can_always_exit": true,
				"rate_type": {
					"id": 2,
					"rate_type": "Coupons"
				}
			}
		],
		"mainCoupon": {
			"id": 3531,
			"facility_id": "116",
			"rate_type_id": "2",
			"min_stay": "0.00",
			"max_stay": "3.00",
			"price": "26.00",
			"monday": "1",
			"tuesday": "1",
			"wednesday": "1",
			"thursday": "1",
			"friday": "1",
			"saturday": "1",
			"sunday": "1",
			"active": "1",
			"description": "3 Hours Coupon",
			"entry_time_begin": "00:00:00",
			"entry_time_end": "23:59:59",
			"exit_time_begin": "00:00:00",
			"exit_time_end": "23:59:59",
			"category_id": "27",
			"coupon_code": "dt237",
			"deleted_at": null,
			"details": "",
			"partner_id": null,
			"coupon_restrictions": "NOT VALID ON THANKSGIVING DAY",
			"mobile_coupon_code": "341fce58",
			"split_price": [
				"26",
				"00"
			],
			"open_time_description": "Enter any time",
			"days_open_description": "7 Days a week",
			"time_in": "2:43 AM on Nov 01, 2018",
			"time_out": "Nov 15, 2018",
			"min_stay_details": "",
			"max_stay_details": "Max Stay 3 Hours",
			"exit_time_details": "Exit any time",
			"is_coupon": true,
			"is_partner_coupon": false,
			"is_event": false,
			"can_repeat": false,
			"can_always_enter": true,
			"can_always_exit": true,
			"facility": {
				"id": 116,
				"short_name": "ZEN-DAYS",
				"full_name": "ZENITH PARKING LLC           ",
				"facility_name_modified":'',
				"garage_code": "8025",
				"facility_type_id": "1",
				"between_streets": "48th St and 49th St",
				"entrance_location": "790-806 8th Avenue and 254 West 49th Street",
				"phone_number": "(212) 581-8590 ",
				"base_rate": "70.00",
				"reservation_bonus_hours": 1,
				"reservation_bonus_rate": 5,
				"active": "1",
				"base_event_rate": "40.00",
				"oversize_fee": "15.00",
				"processing_fee": "0.00",
				"tax_rate": "0.18375",
				"base_event_rate_max_stay": "13",
				"garage_type_id": "0",
				"service_type_id": "0",
				"vehicle_type_id": "0",
				"garage_located": "",
				"accept_cc": "1",
				"accept_echeck": "1",
				"monthly_rate": "300.00",
				"neighborhood_id": "8",
				"legacy_id": "139",
				"elimiwait_location_id": "299",
				"beacon_status": "0",
				"is_indoor_parking": "1",
				"is_outdoor_parking": "0",
				"is_tesla_charging": "0",
				"is_generic_ev_charging": "0",
				"is_motorcycle_parking": "0",
				"realtime_window": null,
				"coupon_threshold": null,
				"merchant_id": "650000009794770",
				"slug": "zen-days",
				"has_ticketech": true,
				"has_monthly": true,
				"is_elimiwait_active": false,
				"display_name": "790 Eighth Avenue",
				"geolocations": {
					"id": 896,
					"address_1": "790 Eighth Avenue",
					"address_2": "",
					"city": "New York",
					"state": "NY",
					"zip_code": "10019",
					"longitude": "-73.9864679",
					"latitude": "40.7614799"
				}
			},
			"rate_type": {
				"id": 2,
				"rate_type": "Coupons"
			}
		},
		"couponCode": "341fce58",
		"barcode": "d37255005006728",
		"restriction": "NOT VALID ON THANKSGIVING DAY",
		"couponValidity": false
	};
	isUpdatedEndTimeFlag:boolean = false;
	deviceInfo = null;
	dailyEndDateMore:any;
	MTSMMessage:string;

	userData = {
		first_name: '',
		last_name: '',
		email: '',
		phone: '',
		password: '',
		cpassword: '',
		enrolledIn: false,
		promocode:''
	  }

	isAgreed: boolean = false;
	showEnrollForm = false;
	checkedCheckbox:boolean = false;
	passwordErrMsg = 'Password is required';
	pageSlug: string = 'terms-and-conditions';
	cmsPageContent: any = {};
	isLoadedterms = false;
	hideSendEmailButton:boolean = false;
	hideSendEmailButton2:boolean = false;
	searchType = 'daily';
	  selectedvahicletypeText:any='';
	  selectedvahicletypeText1:any='';
	  selectedvahicletypeText2:any='';
	  selectedMonthlyRate ='';
	showOversizedMsg = false;
	overSizeMsg:any='';
	is_monthly_campaign = false;
	is_tax_include=0;
	FgarageWorkingHours;
	FulldayWrking:any=0;
	baserate:string = '';
	vehicletype:any;
	isIEBrowser = false;
	monthlyRateMans = 0;
	monthlyRateMan = '';
	valetManagerId = false;
	currentFacility = '';
	removeOrangeBar = false;
	search_user_id = '0';
	search_user_email = '0';
	selectedPrice: 0;
	temporarilyClosedUrl = CustomCode.cmsUrl+'cms/news/locations-temporarily-closed-overnight-weekends/';
	public config = { ignoreBackdropClick: true }
	neighborhood_name_for_event = '';
	trrigger_initiol_event = 0
	constructor(
		private deviceService: Ng2DeviceService,
		private activatedRoute: ActivatedRoute,
		private router: Router,
		private modalService: BsModalService,
    	private toastr: ToastrService,
    	private userService: UserserviceService,
    	private myAccountMonthlyParking: MyAccountMonthlyParkingService,
    	private searchPage: SearchpageserviceService,
    	private homePageServiceObj: HomepageserviceService,
		public sanitizer: DomSanitizer,
		private StaticpagesService:StaticpagesService,
		private elementRef: ElementRef,
	) { 

		this.EarlyBrid = true;
		
		   
	}

	btnEarlyBrid(){
	  	if(this.EarlyBrid == true){
			this.EarlyBrid = false;
			this.earlybirIcon = "fa fa-info-circle";
			
	  	}
	  	else{
	  		this.EarlyBrid = true;
	  		this.earlybirIcon = "fa fa-times";
	  		
	  	}
	  }
	  public modalRef: BsModalRef;
	  getsearchtype(v){
		console.log(v);
		this.searchType =v;
		// if(localStorage.getItem('searchType')&&this.searchType!=localStorage.getItem('searchType')){
		// 	 	this.searchType = localStorage.getItem('searchType');
		// }
	  }
	ngOnInit() {
		let todatDt=new Date();
		localStorage.setItem('dedicatedfacilitymonthlydate',todatDt.toDateString())
		if(localStorage.getItem('access_token')){
			this.search_user_id = localStorage.getItem('userid');
			this.search_user_email = localStorage.getItem('email');
		   }
		this.activatedRoute.params.subscribe((params: Params) => {
			if (params['facility_slug']) {
				
		        this.facility_slug = params['facility_slug'];
				this.startDate = FilterHelper.defaultStartDuration();
				this.endDate = FilterHelper.defaultEndDuration();
				this.startDuration = FilterHelper.defaultStartDuration();
				this.endDuration = FilterHelper.defaultEndDuration();
				this.startTime = FilterHelper.getFormattedTime(this.startDate);
				this.endTime = FilterHelper.getFormattedTime(this.endDate);
				this.fetchFacilityDetail();
		    } else {
		    	// this.redirectDefault();
		    }
		  });   
		localStorage.removeItem('searchType');
		localStorage.setItem('Loadfacility', '1');

		if(localStorage.getItem('is_loyalty_active') && localStorage.getItem('is_loyalty_active')=='1')
		{
		  if (localStorage.getItem('is_loyalty') === "1") {
			this.isLoyaltyMember=true;
			
		  }
		}
		

		// setInterval(()=>{ 
		// 	if(localStorage.getItem('searchType')&&this.searchType!=localStorage.getItem('searchType')){
		// 	this.searchType = localStorage.getItem('searchType');
		// 	}
			
		//  }, 10);

		 let currentWindow =  window.navigator.userAgent;
		if(currentWindow.indexOf("MSIE ") > -1 || currentWindow.indexOf("Trident/") > -1){
			this.isIEBrowser = true;
		 }else {
			this.isIEBrowser = false;
		 }
		
		localStorage.removeItem('is_partner_page');
		this.getUpdatedPrice = false;
			window.scrollTo(0,0);
            $("body").animate({ scrollTop: 0 }, 500);
		// this.updateIframeUrl(this.defaultLat, this.defaultLng);
		
		  
		  if (this.router.url.indexOf('partners') > 0) { 
	        this.isPartners = true;
		}
		
		this.StaticpagesService.getCmsPageContent(this.pageSlug).subscribe((data:any) => {
			this.cmsPageContent = data.result[0];
			this.isLoadedterms = true;
		  }, (error) => {
			this.isLoadedterms = true;
		  }
		  );

		//   setInterval(() => {
		// 	if((localStorage.getItem('Loadfacility')) && this.currentFacility ){
		// 		localStorage.setItem('searchLocation',this.currentFacility);
		// 		$('input#auto_suggest_input_field').val(this.currentFacility);
		// 	}
		// 	}, 100);
			this.metaTags = Array.from(document.getElementsByTagName('meta'));
			this.descriptionElement = this.getMetaElement('X-UA-Compatible');
			
	}
	ngAfterViewChecked(){
		if((localStorage.getItem('Loadfacility')) && this.currentFacility ){
			localStorage.setItem('searchLocation',this.currentFacility);
			$('input#auto_suggest_input_field').val(this.currentFacility);
		}	
	}

	ngOnDestroy() {
		setTimeout(() => {
			localStorage.removeItem('Loadfacility');
			localStorage.removeItem('searchLocation');
			$('input#auto_suggest_input_field').val('');
		}, 100);
	  }

	redirectDefault() {
		this.router.navigate([
    		'/search', 
    		CustomCode.timeSquareCoords.lat, 
    		CustomCode.timeSquareCoords.long,
    		CustomCode.getDateForMapSearch(),
    		CustomCode.facilityDefaultRadius,
    		CustomCode.timeSquareCoords.defaultLocation,
    		CustomCode.defaultFacilityType,
    		CustomCode.searchMode()
    	]);
	}

	initializeGoogleMap(lat, long) {
		const googleInit = CustomCode.getGoogleInitData(lat, long);
		
		setTimeout(() => {
			let container = document.getElementById('facility_map_container');
			this.facilityMap = new google.maps.Map(document.getElementById('facility_map_container'), googleInit);
			const centerMark = new google.maps.Marker({
				position: new google.maps.LatLng(lat,long),
				map: this.facilityMap,
			});
		}, 2000);
	}

	fetchFacilityDetail() {
		this.myAccountMonthlyParking.fetchFacility(this.facility_slug)
	    .then(
			(data:any)=> {
				this.facility = data['data'];
				this.currentFacility = this.facility.full_name;
				this.boardRate = [];
				let record = [];
				this.taxRate = this.facility.tax_rate * 100
				record = this.facility.facility_on_board_rate
				let cat: any = 0;
				let m = 0;
				let rate = [];
				let records = []
				let name = '';
				for (let i = 0; i < record.length; i++) {
					rate[m] = record[i]
					m++;
				}
				let p = 0;
				for (let i = 0; i < record.length; i++) {
					name = record[i].category.category
					//alert(this.checkDuplicate(records,name));
					if (!this.checkDuplicate(records, name)) {
						records[p] = { 'name': name, 'rates': rate }
						p++;
					}

				}
				this.boardRate = records
				//console.log(data);
				if(data['data'].active=='1')
				{
					this.showRecord=true
				}else{
					localStorage.setItem('inactiveFacility','1');
					this.updatePageMeta('/search/40.7589/-73.9851/'+CustomCode.getDateForMapSearchSlashFormat()+'/'+CustomCode.facilityDefaultRadius+'/Times Square/daily/'+CustomCode.searchMode());
					//window.location.href='/search/40.7589/-73.9851/'+CustomCode.getDateForMapSearchSlashFormat()+'/'+CustomCode.facilityDefaultRadius+'/Times Square/daily/'+CustomCode.searchMode()
					window.location.href='/search/40.7589/-73.9851/'+this.formonthly()+'/'+CustomCode.facilityDefaultRadius+'/Times Square/daily/'+CustomCode.searchMode()
				}
				// this.initializeGoogleMap(this.facility.geolocations.latitude, this.facility.geolocations.longitude);
				// this.updateIframeUrl(this.facility.geolocations.latitude, this.facility.geolocations.longitude);
				this.updateFacilitySchedule(this.facility.id);
				this.startDuration = FilterHelper.defaultStartDuration();
				this.endDuration = FilterHelper.defaultEndDuration();
      			this.startTime = FilterHelper.getFormattedTime(this.startDuration);
				this.endTime = FilterHelper.getFormattedTime(this.endDuration);
				let currdate = FilterHelper.getArrivalTime(this.startDuration, this.startTime);
				this.arrival_time=currdate
				this.length_of_stay=2
				this.fetchBookingPrice();
				
			},
			error => {
				this.isLoaded = true;
				console.log(error);
				this.toastr.error(error.errors.message, 'Sorry!');
				//window.location.href='/search/40.7589/-73.9851/'+CustomCode.getDateForMapSearchSlashFormat()+'/'+CustomCode.facilityDefaultRadius+'/Times Square/daily/'+CustomCode.searchMode()
				window.location.href='/search/40.7589/-73.9851/'+this.formonthly()+'/'+CustomCode.facilityDefaultRadius+'/Times Square/daily/'+CustomCode.searchMode()
				
		    }
	    );
	}
	formonthly()
  {
    
      let startDuration:any = FilterHelper.defaultStartDuration();
      let startTime = FilterHelper.getFormattedTime(startDuration);
      localStorage.setItem('monthlyDate',startDuration)
      let endDuration = FilterHelper.defaultEndDuration();
      let endTime = FilterHelper.getFormattedTime(endDuration);
      let endTimeDataIfLess = FilterHelper.makeDurationDateObject(endDuration,endTime);

      localStorage.setItem('endttimehome',CustomCode.getDateForMapSearchSlashFormatHome(endDuration,endTime));
      localStorage.setItem('startimehome',CustomCode.getDateForMapSearchSlashFormatHome(startDuration,startTime));
      let stime =localStorage.getItem('startimehome');
      return stime;
      //this.router.navigate(['/search', '40.7782667', '-73.9698797', stime, 1,  'Central Park','daily', CustomCode.searchMode()]);
  }

	sendApp() {
		const appText = parseInt((<HTMLInputElement>document.getElementById('sendapp')).value.trim());
		if (appText) {
		  const phoneNum = ((<HTMLInputElement>document.getElementById('sendapp'))).value;
		  const userLat = ((<HTMLInputElement>document.getElementById('navigatetogoogle'))).getAttribute('href');
		  const text = 'Welcome to icon parking. click on the link below ' + userLat;
		  this.homePageServiceObj.messageToMobile(phoneNum, text)
			  .subscribe(
			  (data:any)=> {
				this.responseData = data;
				this.toastr.success('Facility Location has been sent Successfully', 'success!');
				$('button.close').trigger('click');
			  },
			  error => {
				const errorsList = error.errors;
				if (errorsList.message !== 'Validation Errors') {
				  this.toastr.error(errorsList.message, 'Sorry!');
				} else {
				  for (const key in errorsList.detail) {
					if (errorsList.detail.hasOwnProperty(key)) {
					  if (typeof errorsList.detail === 'undefined') {
						this.toastr.error(errorsList.message, 'Sorry!');
					  } else {
						this.toastr.error(errorsList.detail[key], 'Sorry!');
					  }
					}
				  }
				}
			  }
			  );
		} else {
		  this.toastr.error('Number is required', 'Sorry!');
		}
		(<HTMLInputElement>document.getElementById('sendapp')).value = '';
	}

	updateFacilitySchedule(id) {
		this.searchPage.getFacilitySchedule(id).then(
			(res) => {
			  this.workingHours = res;
			  this.workingHours = this.workingHours.data;
			},
			(error) => { 
				
			}
		);
	}

	updateIframeUrl(lat, long) {
		this.iframeUrl = this.baseUrl + lat + "," + long + this.gMapSetting;
		this.reloadIframeMap();
	}

	reloadIframeMap() {
		setTimeout(() => {
			this.firstMapLoad = true;
			$('iframe',window.parent.document).attr('src', this.iframeUrl);
		}, 200);
		
	}

	initLengthOfStay() {
		if (CustomCode.searchMode() == 'web') {
			let bookingHours = FilterHelper.getBookingHours($('#startdate').val(), $('#startTime').val(), $('#enddate').val(), $('#endTime').val());
	    	this.length_of_stay = bookingHours;
		}
	}

	// displayableStartDateWrapper() {
	// 	if (CustomCode.searchMode() == 'web') {
	// 		let sDate = $('#sdateDaily').val().trim()
	// 		console.log(sDate)
	// 		return $('#sdateDaily').val().trim();
	// 	} else {
	// 		return FilterHelper.formatDate(this.startDuration);
	// 	}
	// }
	displayableStartDateWrapper() {
		
			let date= new Date(this.startDate)
			//return 
			return  (((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1))) + '/' + ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate())) + '/' + date.getFullYear());
		
		

	}

	displayStartDateMonthly() {
		let date= new Date(this.startDate)
		let  months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        let d = new Date();
        let monthName=months[date.getMonth()]; 
			return  ( monthName + ' ' + ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate())));
	}

	// displayableEndDateWrapper() {
	// 	if (this.isUpdatedEndTimeFlag) {
	// 		return this.dailyEndDateMore;
	// 	}else if(CustomCode.searchMode() == 'web'){
	// 		return $('#enddate').val().trim();
	// 	}else{
	// 		return FilterHelper.formatDate(this.endDuration);
	// 	}
	// }
	displayableEndDateWrapper() {
		if(this.isUpdatedEndTimeFlag){
			return this.dailyEndDateMore;
		}else{
			let date= new Date($('#eDateDaily').val().trim());
			//return 
			return  (((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1))) + '/' + ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate())) + '/' + date.getFullYear());
			//return $('#endDateWithID').val().trim();
		}
	}

	displayStartTimeWrapper() {
	    if (CustomCode.searchMode() == 'web') {
	    	return this.displayStartTime;
	    } else {
	    	let tempStartTime = FilterHelper.displayableTimeformat(FilterHelper.displayableTimeformatforfaciliy(this.arrival_time))
	    	if (tempStartTime == "00:00 AM") { tempStartTime = "12:00 AM" }
		    if (tempStartTime == "00:30 AM") { tempStartTime = "12:30 AM" }
	    	return tempStartTime;
	    }
	}

	displayEndTimeWrapper() {
		if (CustomCode.searchMode() == 'web') {
			return this.displayEndTime;
		} else {
			let tempEndTime = FilterHelper.displayableTimeformat(FilterHelper.getFormattedTime(this.endDuration))
		    if (tempEndTime == "00:00 AM") { tempEndTime = "12:00 AM" }
		    if (tempEndTime == "00:30 AM") { tempEndTime = "12:30 AM" }
			return tempEndTime;
		}
	}

	updateDisplayFilters() {
	    this.displayStartDate = new Date($('#startdate').val());
	    this.displayEndDate = new Date($('#enddate').val());
		this.displayStartTime = FilterHelper.displayableTimeformat($('#startTime').val());
		//yha pr arrival time check krna h
	    this.displayEndTime = FilterHelper.displayableTimeformat($('#endTime').val());
	    if (this.displayStartTime == "00:00 AM") { this.displayStartTime = "12:00 AM" }
	    if (this.displayStartTime == "00:30 AM") { this.displayStartTime = "12:30 AM" }
	    if (this.displayEndTime == "00:00 AM") { this.displayEndTime = "12:00 AM" }
	    if (this.displayEndTime == "00:30 AM") { this.displayEndTime = "12:30 AM" }
	}

	fetchBookingPrice() {
		this.activeLoading = true;
	    //this.initLengthOfStay();
	    this.updateDisplayFilters();
	    if (this.facility.id) {
	    	let queryString = this.facility.id + "?";
		    queryString += "arrival_time=" + this.arrival_time + "&";
		    queryString += "length_of_stay=" + this.length_of_stay + "&";
			queryString += "use_bonus=0";
		    this.searchPage.fetchUpdatedBookingRates(queryString).then(
			
		      (res) => {
		        if (res) {
		        	// this.initializeGoogleMap(this.facility.geolocations.latitude, this.facility.geolocations.longitude);
		        	if (!this.firstMapLoad) {
		        		// this.updateIframeUrl(this.facility.geolocations.latitude, this.facility.geolocations.longitude);
		        		this.initializeGoogleMap(this.facility.geolocations.latitude, this.facility.geolocations.longitude);
					}
		        	this.firstMapLoad = true;
					this.isLoaded = true;
					this.activeLoading = false;
					this.FacilityAvailableFetatures  = res['data'];
					this.getUpdatedPrice = true;

					this.isUpdatedEndTimeFlag = false;
					this.deviceInfo = this.deviceService.getDeviceInfo();

					if(this.FacilityAvailableFetatures.availability > 0 && (this.FacilityAvailableFetatures.ticketech_id !=='')){
						this.isUpdatedEndTimeFlag = (this.FacilityAvailableFetatures.isUpdatedEndTimeFlag)?true:false;
					}
					if(this.FacilityAvailableFetatures.facility.is_oversize_allowed=="0" ){
						this.showOversizedMsg = true;
						this.overSizeMsg=this.FacilityAvailableFetatures.facility.oversize_not_allowed_message
					}else {
						this.showOversizedMsg = false;
					}
					if(this.isUpdatedEndTimeFlag){
						let updateDate = this.FacilityAvailableFetatures.updated_end_time;
						if (this.deviceInfo.browser === 'safari' || this.deviceInfo.browser === 'ie') {
							updateDate = FilterHelper.defaultEndDurationUrlForSafariForMoreTime(updateDate);
						}
						let endDate = FilterHelper.formatDate(new Date(updateDate));
						let endTime = FilterHelper.getFormattedTime(new Date(updateDate));
						this.dailyEndDateMore = endDate;
						this.displayEndTime = (FilterHelper.displayableTimeformat(endTime)=='00:00 AM')?'12:00 AM':FilterHelper.displayableTimeformat(endTime);
						this.MTSMMessage = `<table><tr><td><img width="30" src="/assets/img/car-popover.png"></td><td>At this Icon Parking facility, ${this.displayableStartDateWrapper()} ${this.displayStartTimeWrapper()} to ${this.displayableEndDateWrapper()} ${this.displayEndTime} costs the same as the time you selected. The extra time has been added to your booking - stay until ${this.displayableEndDateWrapper()} ${this.displayEndTime}.</td></tr></table>`;
					}else{
						this.displayEndTime = FilterHelper.displayableTimeformat($('#endTime').val());
						let endDate = new Date($('#enddate').val());
						this.displayEndDate = endDate;
					}

					if(res['data'].availability == 0 ){
						this.availabilityCheck = true;
					}
					else{
						this.availabilityCheck = false;
					}
		        	if (res['data'].price == "N/A") {

						$('.dailymonthlybutton').each(function(){
			            	$(this).hide();
			            });
			            $('#base_rate').hide();
			            $('#na_rate').show();
			            $('#special-flag').hide();
			        } else {
			            $('.dailymonthlybutton').each(function(){
			            	$(this).show();
						});
						if((res['data'].coupon_price_applied != '0' ) ){
							this.enableDefaultMsg = false;
						}else{
							this.enableDefaultMsg = true;
						}

			            $('#base_rate').show();
			            $('#special-flag').show();
			            $('#na_rate').hide();
			            let comingPrice = res['data'].price;
			        	comingPrice = comingPrice.replace(/\,/g,'');
			        	comingPrice = parseInt(comingPrice, 10);
			        	if (comingPrice > 0) {
			        		this.finalPrice = comingPrice;
			        	}
			        }
			        this.fetchFacilityData(this.facility.geolocations.latitude, this.facility.geolocations.longitude, 0.2, this.arrival_time, this.length_of_stay);
					setTimeout(()=>{
						const trustboxRef = document.getElementById('trustboxOpenFacilityWeb');
						if(trustboxRef !==null)
						Trustpilot.loadFromElement(trustboxRef);
						const trustboxRefMobile = document.getElementById('trustboxOpenFacilityMob');
						if(trustboxRefMobile !==null)
						Trustpilot.loadFromElement(trustboxRefMobile);
						if(this.trrigger_initiol_event < 1){
						this.dailyCheckoutEvent(this.facility, 'facilityClick');
						//alert("facilityClick1")

						this.trrigger_initiol_event++
					}

					  },1000)



				}
		      },
		      (error) => { 
		        this.isLoaded = true;
		      }
		    );
	    }
	    
	}

	fetchFacilityData(lat, long, radius, length_of_stay, currdate) {	
		this.searchPage.getFacility(lat, long, radius, currdate, length_of_stay)
		.subscribe(
			(data:any)=> {
				this.searchResult = data.data;
				if (this.searchResult) {
					for (var i = this.searchResult.facilities.length - 1; i >= 0; i--) {
						if (this.searchResult.facilities[i].facility_id == this.facility.id) {
							this.couponId = this.searchResult.facilities[i].rate_id;
							this.rateTypeId = this.searchResult.facilities[i].rate_type_id;
							this.categoryId = this.searchResult.facilities[i].category_id;
							this.couponCode = this.searchResult.facilities[i].coupon_code;
							this.distance = this.searchResult.facilities[i].distance;
							let monthly_rate = this.searchResult.facilities[i].monthly_rate;
							let monthly_rates  = this.searchResult.facilities[i].monthly_rates;
							this.vehicletype = this.searchResult.facilities[i].monthly_rates;
							this.is_tax_include = this.searchResult.facilities[i].monthlytaxFlag;
							this.monthlyRateMans = this.searchResult.facilities[i].monthly_rate_mains
							this.monthlyRateMan = this.searchResult.facilities[i].monthly_rate_main
							if(this.searchResult.facilities[i].monthly_campaign ==='1') { this.is_monthly_campaign = true; }else {this.is_monthly_campaign = false; }
								for(let data of monthly_rates ){
									if(data.net_rate == monthly_rate)
										{
											this.selectedvahicletype = data.rate_type;
											sessionStorage.setItem('selectedvahicletype', this.selectedvahicletype);
											this.selectedvahicletypeText=data.hours_description+" "+data.description
											this.selectedvahicletypeText1 = data.hours_description
											this.selectedvahicletypeText2 = data.description
										break;
										}
								}
						} else {
							this.couponId = false;
							this.rateTypeId = false;
							this.categoryId = 0;
							this.couponCode = "";
						}
					}
				} else {
					this.couponId = false;
					this.rateTypeId = false;
					this.categoryId = 0;
					this.couponCode = "";
				}
			},
			error => {
			}
		);
	}

	getFormattedDate(date) {
		let day = date.getDate();
		let month = (date.getMonth() + 1);
		let year = date.getFullYear();
		day = (day).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false});
      	month = (month).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false});
		const formatedDate = month + '-' + day + '-' + year;
		return formatedDate.toString();
	}

	receiveNewSearchFilter($event) {
		this.startDuration=$event.dats
		this.endDuration=$event.enddats
	    this.arrival_time = $event.currdate;
		this.length_of_stay = $event.length_of_stay;
		//alert(2)
	    //this.fetchBookingPrice();
	}
	receiveNewSearchFilterOnSubmit($event) {
		console.log($event)
		//alert($event.length_of_stay);
		this.startDuration=$event.dats
		this.endDuration=$event.enddats
	    this.arrival_time = $event.currdate;
		this.length_of_stay = $event.length_of_stay;
		this.startDate=this.startDuration;
		if($event.type=="daily daily"){
			//this.bookingtype=$event.type;
			this.startDate=this.startDuration;
		}
		if($event.type=="daily"){
			//this.bookingtype=$event.type;
			this.startDate=$event.currdate;
		}
		//alert(2)
	    this.fetchBookingPrice();
	}

	receiveNewMobileNavDailyFilters($event) {
	    this.arrival_time = $event.currdate;
	    this.length_of_stay = $event.length_of_stay;
	    this.startDuration = $event.startTime;
	    this.endDuration = $event.endTime;
	    this.fetchBookingPrice();
	}

	navigateDailyBooking() {
		this.dadicatedFacilityBookBtnClick();
		this.fetchUserStatusForTracking();
		this.dailyCheckoutEvent(this.facility, 'Daily_checkout');
		// (<any>window).dataLayer.push(
		// 	{
		// 	'event': 'Daily_checkout',
		// 					'UserId' :this.search_user_id,
		// 					'email': this.search_user_email,
		// 					'FacilityPageLocation': this.router.url,
		// 					'Facility': [{
		// 					  'name': this.currentFacility,                      // Name or ID is required.
		// 					  'id': this.facility.id,
		// 					  'price': this.FacilityAvailableFetatures.price,
		// 					  'category': 'Daily',
		// 					  'neighborhood': this.neighborhood_name_for_event
		// 					}]
		// 	})
		if(!this.loggedUserName || ( !this.isLoyaltyMember && this.loggedUserName ) ){
			localStorage.setItem('reserveNowClick', '1');
			// sessionStorage.setItem('promoApply', '1');
			// localStorage.setItem('promocode', this.FacilityAvailableFetatures.paynow_promocode);
			// localStorage.setItem('is_landing_page', 'true');
			// localStorage.setItem('action_type', 'apply');
		 }
		if (CustomCode.searchMode() == 'web') {
			
			let date=new Date($('#startDateWithID').val());
			const sDate=   ((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1))) + '-' + ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate())) + '-' + date.getFullYear();
			date=new Date($('#endDateWithID').val());
			const eDate =    ((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1))) + '-' + ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate())) + '-' + date.getFullYear();
			const sTime = $('#startTimeWithID').val().toString().trim();
			const eTime = $('#endTimeWithID').val().toString().trim();
			let redirect = sDate + '&' + sTime + '&&' + eDate + '&' + eTime;
			redirect = redirect.split('/').join('-');
			// console.log(redirect);
			const navURL = "/payment/" + this.facility.id + "/" + redirect + "/" + this.finalPrice;
			//alert(sDate)
			this.router.navigate([navURL]);
		} else {
			let startDate = FilterHelper.formatDate(this.startDuration);
			let startTime = FilterHelper.getFormattedTime(this.startDuration);
			let endDate = FilterHelper.formatDate(this.endDuration);
			let endTime = FilterHelper.getFormattedTime(this.endDuration);
			let redirect = startDate + '&' + startTime + '&&' + endDate + '&' + endTime;
			redirect = redirect.split('/').join('-');
			const navURL = "/payment/" + this.facility.id + "/" + redirect + "/" + this.finalPrice;
			this.router.navigate([navURL]);
		}
	}

	navigateMonthlyBooking() {
		this.fetchUserStatusForTracking();
		this.buyMonthlyClickEvent(this.facility);
		
		this.dadicatedFacilityBookBtnClick();
		//alert($('#monthlydate').val())
		
		let mD=new Date(localStorage.getItem('dedicatedfacilitymonthlydate'));
		
		const fDate = mD.getFullYear()+'-'+((mD.getMonth() > 8) ? (mD.getMonth() + 1) : ('0' + (mD.getMonth() + 1))) + '-' + ((mD.getDate() > 9) ? mD.getDate() : ('0' + mD.getDate()));
		if (CustomCode.searchMode() == 'web') {
			let url = "/buy-monthly/" + this.facility.id + "/" + fDate;
			this.router.navigate([url]);
		} else {
			let url = "/buy-monthly/" + this.facility.id + "/" + fDate;
		this.router.navigate([url]);
		}
	}

	manageCoupons123() {
		$('#couponsErrorMessage').hide();
		$('#couponmail').hide();
		this.isCouponLoaded = false;
		$('#triggercoupon').trigger('click');
		/* commented for ga 16-6-2021 by seo team
		ga('send', 'event', { eventCategory: 'coupon view new', eventAction: 'click'});
		*/
		this.loggedUserName = CustomCode.getloggedInUserDetails();
		CustomCode.displayLoginUser();
		
		if (!this.loggedUserName) {
			$('#mailcoupon').css('display', 'none');
		} else {
			$('#mailcoupon').css('display', 'block');
		}
		const currentFacId = this.facility.id;
		const couponId = this.couponId;
		if (couponId) {
			this.isCouponLoaded = true;
			const date = new Date();
			const todayName = date.toLocaleDateString('en', { weekday: 'long' }).toLowerCase();
			this.printEmailCoupon(couponId);
			//this.printCoupon();
		} else {
			this.isCouponLoaded = true;
			$('#couponsErrorMessage').removeClass('hidden');
			$('#couponsErrorMessage').show();
		}
		$('#couponheading').css('display', 'block');
		$('#couponprintdata').css('display', 'block');
		$('#couponslisting').css('display', 'block');
		$('.coupons-other-areas').css('display', 'none');
	}
	manageCoupons() {
		this.hideSendEmailButton = false;
		this.hideSendEmailButton2 = false;
		this.showEnrollForm = false;
		this.checkedCheckbox = false;
		this.getCouponDisabled = true;
		this.ShowCouponListOnLoad = false;;
		$('#couponsErrorMessage').hide();
		$('#couponmail').hide();
		//this.isLoaded = false;
		/* commented for ga 16-6-2021 by seo team
		ga('send', 'event', { eventCategory: 'coupon view new', eventAction: 'click' });
		*/
		this.loggedUserName = CustomCode.getloggedInUserDetails();
		CustomCode.displayLoginUser();

		if (!this.loggedUserName) {
			$('#mailcoupon').css('display', 'none');
		} else {
			$('#mailcoupon').css('display', 'block');
		}

		const currentFacId = this.facility.id;
		const couponId = this.couponId;

		if (couponId) {
			const date = new Date();
			const todayName = date.toLocaleDateString('en', { weekday: 'long' }).toLowerCase();
			// for (let cd = 0; cd < cData.data.length; cd++) {
			// if (cData.data[cd].rate_type === 'Coupons' && cData.data[cd].rates.length > 0) {
			// this.couponsData = cData.data[cd].rates;

			this.printEmailCoupon(couponId);
			this.printCoupon();
			// }

			// if (cData.data[cd].rate_type === 'Coupons' && cData.data[cd].rates.length == 0) {
			// this.isLoaded = true;
			// $('#couponsErrorMessage').removeClass('hidden').show();
			// }
			// }
		} else {
			//this.isLoaded = true;
			$('#couponsErrorMessage').removeClass('hidden');
			$('#couponsErrorMessage').show();
		}
		// this.searchPageObj.getFacilityTypeCoupon(currentFacId).then(
		//            (couponsRes) => {
		//                            const cData: any = couponsRes;
		//                            const couponCount = 0;
		//                            if (cData.data.length > 0) {
		//                                            const date = new Date();
		//                                            const todayName = date.toLocaleDateString('en', { weekday: 'long' }).toLowerCase();
		//                                            for (let cd = 0; cd < cData.data.length; cd++) {
		//                                                            if (cData.data[cd].rate_type === 'Coupons' && cData.data[cd].rates.length > 0) {
		//                                                                            this.couponsData = cData.data[cd].rates;
		//                                                                            
		//                                                                            this.printEmailCoupon(this.couponsData[0].id);
		//                                                                            this.printCoupon();
		//                                                            }

		//                                                            if (cData.data[cd].rate_type === 'Coupons' && cData.data[cd].rates.length == 0) {
		//                                                                            this.isLoaded = true;
		//                                                                            $('#couponsErrorMessage').removeClass('hidden').show();
		//                                                            }
		//                                            }
		//                            } else {
		//                                            $('#couponsErrorMessage').show();
		//                            }
		//            },
		//            (error) => { 
		//            }
		// );
		// $('#coupon-modal .modal-dialog').removeClass('modal-sm');
		// $('#coupon-modal .modal-dialog').addClass('modal-lg');
		$('#couponheading').css('display', 'block');
		$('#couponprintdata').css('display', 'block');
		// $('#couponheading').text('Coupons Available for this Facility');
		$('#couponslisting').css('display', 'block');
		$('.coupons-other-areas').css('display', 'none');
	}
	printEmailCoupon(couponId) {
		$('#couponprintdata').css('display', 'block');
		this.getVisitorCouponTracking({"couponId":couponId,"type":this.VIEW_COUPON });
		$('#couponslisting').css('display', 'none');
		$('#coupon-mail-print').attr('data-id', couponId);
	}

	// mailPopup() {
	// 	ga('send', 'event', { eventCategory: 'email coupon', eventAction: 'click'});
	// 	var cId = $('#coupon-mail-print').attr('data-id');
	// 	$('#couponslisting').css('display', 'none');
	// 	$('#couponmail').show();
	// 	$('#couponprintdata').hide();
	// }
	mailPopup(id, value, text) {
		this.text = text;
		this.value = value;
		this.couponMainId=id
		var cId = id;// $('#coupon-mail-print').attr('data-id');
		// $('#coupon-mail-print').css('display', 'none');
		$('#couponslisting').css('display', 'none');
		$('#couponmail').show();
		$('#couponmail1').show();
		$('.couponprintdata').hide();
		// $('#couponheading').text('How would you like to receive your coupon?');
		// $('#coupon-modal .modal-dialog').removeClass('modal-lg');
		// $('#coupon-modal .modal-dialog').addClass('modal-sm');
	}
	showCouponbox(id) {
		$(".couponList").addClass('hide');
		$("#couponShow" + id).removeClass('hide');
		this.showSelectBox = false
	}
	// cancelEmail(formValues, formObj: NgForm) {
	// 	$('#couponmail').hide();
	// 	$('#couponprintdata').show();
	// 	formObj.resetForm();
	// }
	cancelEmail(formValues, formObj: NgForm) {
		$('#couponmail').hide();
		$('#couponmail1').hide();
		$('.couponprintdata').show();
		formObj.resetForm();
	}
	saveCouponApi(id) {
		/* commented for ga 16-6-2021 by seo team
		ga('send', 'event', { eventCategory: 'save coupon', eventAction: 'click'});
		*/
		//const cId = $('#coupon-mail-print').attr('data-id');
		const cId=id
		this.myAccountMonthlyParking.fetchUser()
		.then(
			(data:any)=> {
				this.activeUser = data;
				this.activeUser = this.activeUser.data.id;
				this.searchPage.saveCoupon(this.activeUser, cId)
				.subscribe(
					(data:any)=> {
						this.respdata = data;
						if (this.respdata.status === 201) {

							// kochava.activity('save_coupon', {
							// 	coupon_id : cId,
							//   });

							$('#couponmail').css('display', 'block');
							this.router.navigate(['/thanks-coupon-saved']);
						}
					},
					error => {
						const errorsList = error.errors;
						if (errorsList.message !== 'Validation Errors') {
							this.toastr.error(errorsList.message, 'Sorry!');
						} else {
							for (const key in errorsList.detail) {
								if (errorsList.detail.hasOwnProperty(key)) {
									if (typeof errorsList.detail === 'undefined') {
										this.toastr.error(errorsList.message, 'Sorry!');
									} else {
										this.toastr.error(errorsList.detail[key], 'Sorry!');
									}
								}
							}
						}
					}
				);
			},
			error => {

			}
		);
	}

	reset(formValues, formObj: NgForm) {
	    formObj.resetForm();
	}

	sendCoupon(formData, formObj: NgForm) {

		/* commented for ga 16-6-2021 by seo team
	   gtag_report_conversion(this.gtmUrl);
	    
		gtag('event', 'conversion', {
      				'send_to': 'AW-876742851/SMj-CN624n0Qw5GIogM',
      				 'eventCallback':window['callbackgtm'],
  		});
		  */

		//const cId = $('#coupon-mail-print').attr('data-id');
		const cId=this.couponMainId
		this.getVisitorCouponTracking({"couponId":cId,"type":this.EMAIL_COUPON,"to":formData.ucmail});
		this.searchPage.mailCouponNew(formData, cId)
			.subscribe(
			(data:any)=> {
				this.respdata = data;
				if (this.respdata.status === 201) {
					// kochava.activity('email_coupon', {
					// 	coupon_id : cId
					// });

					if( sessionStorage.getItem('enrollFromCoupon') ){

					}else {
						$('#couponmail').css('display', 'block');
						formObj.resetForm();
						this.router.navigate(['/thanks-email']);
					}

					
				}
			},
			error => {
				const errorsList = error.errors;
				if (errorsList.message !== 'Validation Errors') {
					this.toastr.error(errorsList.message, 'Sorry!');
				} else {
					for (const key in errorsList.detail) {
						if (errorsList.detail.hasOwnProperty(key)) {
							if (typeof errorsList.detail === 'undefined') {
								this.toastr.error(errorsList.message, 'Sorry!');
							} else {
								this.toastr.error(errorsList.detail[key], 'Sorry!');
							}
						}
					}
				}
			}
			);

	}

	cancelCoupon() {
		$('#couponmail').css('display', 'none');
	}

	couponOptions() {

	}
	backSelectbox(id) {
		$(".couponList").addClass('hide');
		this.showSelectBox = true;
	}
	printCoupon() {
		// ga('send', 'event', { eventCategory: 'coupon print', eventAction: 'click'});
		const facId = $('#coupon-mail-print').attr('data-id');
		// $('#coupon-modal .modal-dialog').removeClass('modal-sm');
		// $('#couponheading').text('');
		this.isLoadedCopon=false
		$(".couponList").addClass('hide');
		setTimeout(() => {
			var random = Array(1, 2);
			this.ranValue = random[Math.floor(Math.random() * random.length)];
			if (this.ranValue == 1) {
				/* commented for ga 16-6-2021 by seo team
				ga('send', 'event', { eventCategory: 'Get coupon slider-coupon', eventAction: 'click' });
				*/
				$('#triggercoupon').trigger('click');
			} else {
				this.showSelectBox=true;
				/* commented for ga 16-6-2021 by seo team
				ga('send', 'event', { eventCategory: 'Get coupon square coupon box', eventAction: 'click' });
				*/
				$('#triggercoupon1').trigger('click');
			}

			$(window).resize();
		}, 500);
		this.ShowCouponListOnLoad = false;
		this.searchPage.printCouponDetails(facId).subscribe(
			(couponPrint:any) => {
				this.isLoaded = true;
				this.showSelectBox = true;
				this.isLoadedCopon=true;
				this.getCouponDisabled = false;
				this.ShowCouponListOnLoad = true;
				const urlCreator = window.URL;
				this.barcodeValue = couponPrint.data.barcode;
				this.qrcodeData  = couponPrint.data.parkonect_id;
	            this.barcodeData  = couponPrint.data.ticketech_id;
				this.couponCodeVaue = couponPrint.data.couponCode
				this.imgSrcLocation=couponPrint.data.facility.photos.url
				this.array_data = couponPrint.data
				if (this.array_data.coupons.length == 1) {
					this.isClassVisible = false;
				} else {
					this.isClassVisible = true;
				}
				//const blobData = this.sanitizer.bypassSecurityTrustUrl(urlCreator.createObjectURL(couponPrint['_body']));
				//this.printData = blobData['changingThisBreaksApplicationSecurity'];
				$('#invoicePrint').css('display', 'block');
				$('#couponvalid').css('display', 'block');
				// setTimeout(() => {
				$('div#couponprintdata').css('display', 'block');
				// }, 200);
				$(window).resize();

			},
			(error) => {
				$('#couponsErrorMessage').removeClass('hidden').css('display', 'block');
				this.isLoaded = true;
			}
		);
		// this.getVisitorCouponTracking({"couponId":facId,"type":this.PRINT_COUPON});


		$('#coupon-mail-print').css('display', 'none');
		$('#couponprintdata').css('display', 'block');
		$('#invoicePrint').css('display', 'none');
		$('#couponvalid').css('display', 'none');
	}
	printCoupon1() {
		/* commented for ga 16-6-2021 by seo team
		ga('send', 'event', { eventCategory: 'coupon print', eventAction: 'click'});
		*/
		const facId = $('#coupon-mail-print').attr('data-id');
		this.isCouponLoaded = false;
		this.searchPage.printCoupon(facId).then(
			(couponPrint) => {
				const urlCreator = window.URL;
				const blobData = this.sanitizer.bypassSecurityTrustUrl(urlCreator.createObjectURL(couponPrint['_body']));
				this.printData = blobData['changingThisBreaksApplicationSecurity'];
				this.isCouponLoaded = true;
				$('#invoicePrint').css('display', 'block');
				$('#couponvalid').css('display', 'block');
				$('div#couponprintdata').css('display', 'block');
			},
			(error) => {
				$('#couponsErrorMessage').removeClass('hidden').css('display', 'block');
				this.isCouponLoaded = true;
			}
		);
		this.getVisitorCouponTracking({"couponId":facId,"type":this.PRINT_COUPON});

		$('#coupon-mail-print').css('display', 'none');
		$('#couponprintdata').css('display', 'block');
		$('#invoicePrint').css('display', 'none');
		$('#couponvalid').css('display', 'none');
	}

	closePopup() {
		$('button#closeCouponModal').click();
	}

	// printMedia() {
	  
	//    gtag_report_conversion(this.gtmUrl);
	   
	//    gtag('event', 'conversion', {
    //   				'send_to': 'AW-876742851/dPx-CP3G9X0Qw5GIogM',
    //   				 'eventCallback':window['callbackgtm'],
  	// 	});
	// 	$('div#printable').html($('div#printableContent').html()).show();
	// 	$('div#content-Wrapper').hide();
	// 	window.print();
	// 	$('div#printable').html('').hide();
	// 	$('div#content-Wrapper').show();
	// 	this.router.navigate(['/thanks-print']);

	// }
	printMedia(id, value, text) {
		this.text = text
		this.value = value
		this.showSelectBox = false
		const facId = id;//$('#coupon-mail-print').attr('data-id');
		$('div#printable').html($('#table' + id).html()).show();
		$('div#content-Wrapper').hide();
		window.print();
		/* commented for ga 16-6-2021 by seo team
		gtag_report_conversion(this.gtmUrl);
	   
		gtag('event', 'conversion', {
					   'send_to': 'AW-876742851/dPx-CP3G9X0Qw5GIogM',
						'eventCallback':window['callbackgtm'],
		   });
		   */
		$('body').removeClass('noserch-scroll noscroll modal-open');
		/* commented for ga 16-6-2021 by seo team
		ga('send', 'event', { eventCategory: 'coupon print', eventAction: 'click' });
		if (this.ranValue == 1) {
			ga('send', 'event', { eventCategory: 'print slider coupon', eventAction: 'click', label: this.text, value: this.value });
		} else {
			ga('send', 'event', { eventCategory: 'print square coupon', eventAction: 'click', label: this.text, value: this.value });
		}
		*/
		setTimeout(() => {
			$('div#printable').hide();
			$('div#content-Wrapper').show();
			this.router.navigate(['/thanks-print']);
		}, 4000);
	}
	getVisitorCouponTracking(data) {
		this.getReferrerForVisitor();
		this.userService.getVisitorCouponTracked(data)
	      .subscribe(
	      (data:any)=> {
	       },
	      error => {
	      }
    	);
	}

	getReferrerForVisitor() {
		this.userService.getReferrerForVisitor()
	      .subscribe(
	      (data:any)=> {
	       },
	      error => {
	      }
    	);
	}



	public openModal(template: TemplateRef<any>,selectedFacilityId) {
		this.modalRef = this.modalService.show(template);
		if(this.searchType=='monthly'){
			this.updateFacilityScheduleFormatted(selectedFacilityId);
		}
	}

	changeRateUpdated(rate) {
		let facRateNew = '';
		const facRate = (rate != 'N/A') ? '$' + rate : 'N/A';
		if(facRate != 'N/A'){
			facRateNew = facRate.replace('.00', '');
		}else{
			facRateNew = 'N/A';
		}
		return facRateNew;
	}


	redirectTo_RequestPage(){
		this.router.navigate(['/monthly-parking-information']);
	}
	redirectTo_oversized(){
		this.router.navigate(['/oversized']);
	}
	checkDuplicate(arr, name) {
		return arr.some(function (el) {
			return el.name == name;
		});
	}
	showHide()
	{
		if($('.panel-collapse').hasClass('show'))
		{
			this.textHideShow='HIDE'
		}else{
			this.textHideShow='SHOW'
		}
	}
	showpopUpOnmobile()
	{
	  if (navigator.userAgent.match(/(iPod|iPhone|iPad|Android)/i)) {
		localStorage.setItem('headerEvenT','1');
	  }
	}

	receiveFacilityNewSearchFilter(event){

	}


	checkForEnrollNew(event ){
		const pattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
		if(event.target.checked){
			if( 'ucmail' in this.mailFormData && this.mailFormData.ucmail !=''){
				if(pattern.test(this.mailFormData.ucmail)){
					this.showEnrollForm = true;
					this.checkedCheckbox = true;
					this.hideSendEmailButton = true
				}else {
					this.toastr.error('The email must be a valid email address.', 'Error');
				setTimeout(() => {
					document.getElementById('enrolledInCheck').click();
				}, 1000);
				}
				
			}else {
				this.toastr.error('Please enter your Email', 'Error');
				setTimeout(() => {
					document.getElementById('enrolledInCheck').click();
				}, 1000);
			}
		}else {
			this.hideSendEmailButton = false
			this.showEnrollForm = false;
			this.checkedCheckbox = false;
			this.userData.first_name = '';
			this.userData.last_name = '';
			this.userData.phone = '';
			this.userData.password = '';
			this.userData.cpassword = '';
			this.userData.enrolledIn = false
		}
	}
	checkForEnrollNew2(event ){
		const pattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
		if(event.target.checked){
			if( 'ucmail' in this.mailFormData && this.mailFormData.ucmail !=''){
				if(pattern.test(this.mailFormData.ucmail)){
					this.showEnrollForm = true;
					this.checkedCheckbox = true;
					this.hideSendEmailButton2 = true;
				}else {
					this.toastr.error('The email must be a valid email address.', 'Error');
				setTimeout(() => {
					document.getElementById('enrolledInCheck2').click();
				}, 1000);
				}
				
			}else {
				this.toastr.error('Please enter your Email', 'Error');
				setTimeout(() => {
					document.getElementById('enrolledInCheck2').click();
				}, 1000);
			}
		}else {
			this.hideSendEmailButton2 = false
			this.showEnrollForm = false;
			this.checkedCheckbox = false;
			this.userData.first_name = '';
			this.userData.last_name = '';
			this.userData.phone = '';
			this.userData.password = '';
			this.userData.cpassword = '';
			this.userData.enrolledIn = false
				
		}
		
	}

	phoneFormat(event: any) {
		const pattern = /[0-9\)\-\(\.\ ]/;
		let inputChar = String.fromCharCode(event.charCode);
		if (event.keyCode != 8 && event.keyCode != 9 && !pattern.test(inputChar)) {
		  event.preventDefault();
		}
	  }

	moveToNext(userData, formRef: NgForm) {
		// console.log('here  ' , userData)
		window.scrollTo(0, 0);
		$("body").animate({ scrollTop: 0 }, 0);
		let Data = {
		  name: this.userData.first_name + ' ' + this.userData.last_name,
		  email: this.mailFormData.ucmail,
		  password: this.userData.password,
		  cpassword: this.userData.cpassword,
		  phone: this.userData.phone,
		  is_loyalty: this.userData.enrolledIn,
		  invitation_code: this.userData.promocode,
		  iq_code:(sessionStorage.getItem('iq_code')!==null)?sessionStorage.getItem('iq_code'):'',
		}
		if(Data.phone != undefined && Data.phone != ''){
		  let ph = Data.phone.replace(/\D/g, '');
		  if(ph.length !==0 && ph.length < 10){
			formRef.form.controls['phone'].setErrors({'invalid': true});
			return false;
		  }
		}
	
		if (Data.password === undefined || Data.password === '') {
		  this.passwordErrMsg = 'Password is required';
		  formRef.form.controls['password'].setErrors({'invalid': true});
		  return false;
		}else if(Data.password.length < 8){
		  this.passwordErrMsg = 'Password must be atleast 8 characters long';
		  formRef.form.controls['password'].setErrors({'invalid': true});
		  return false;
		}else if(Data.password !== Data.cpassword){
		  formRef.form.controls['cpassword'].setErrors({'invalid': true});
		  return false;
		}else if(!Data.is_loyalty){
		  formRef.form.controls['enrolledIn'].setErrors({'invalid': true});
		  return false;
		}
	
		this.userService.registerLoyaltyUser(Data)
		  .subscribe(
			(data:any) => {
			  let resData = data;
			  localStorage.setItem('isInvAsso', '1');
			  // localStorage.setItem('isFirstRegister', resData.data.is_new_user); // commented for new loyalty changes IPSD-1776
			  /* #start login user after registration */
			  const userVal = {
				user: {
				  name: resData.data.user.name,
				  id: resData.data.user.id,
				  email: resData.data.user.email,
				  phone: resData.data.user.phone,
				  is_loyalty: resData.data.user.is_loyalty,
				  is_loyalty_active: resData.data.user.is_loyalty_active
				},
				access_token: resData.data.session.access_token,
				refresh_token: resData.data.session.refresh_token,
				expires_in: resData.data.session.expires_in,
				token_type: resData.data.session.token_type,
			  }
	
			  CustomCode.markLoginUser(userVal);
			  sessionStorage.setItem('enrollFromCoupon', '1');
			  document.getElementById('submitEmailCoupon').click();
			  /* #end login user after registration */
			  if (sessionStorage.getItem('event_for_loyalty') == '1') {
				let eventlabel = sessionStorage.getItem('eventlabel');
				let eventcategory = sessionStorage.getItem('eventCategory');
				/* commented for ga 16-6-2021 by seo team
				ga('send', 'event', { eventCategory: eventcategory + " Inactive", eventAction: 'Click', eventLabel: eventlabel, eventValue: '10' });
				*/
				sessionStorage.removeItem('event_for_loyalty');
				sessionStorage.removeItem('eventlabel');
				sessionStorage.removeItem('eventCategory');
			  }
			  /* commented for ga 16-6-2021 by seo team
			  ga('send', 'event', { eventCategory: 'Enroll User Successfull', eventAction: 'click' });
			  */
			  (<any>window).dataLayer.push({ 'event': 'rewardsEnrolled-Inactive' });
			  this.router.navigate(['my-account/account']);
			  // window.location.href = '/icon-rewards-register'; // commented for new loyalty changes IPSD-1776
			},
			error => {
			  const errorsList = error.errors;
			  if (errorsList.message !== 'Validation Errors') {
				this.toastr.error(errorsList.message, 'Sorry!', { enableHtml: true, toastClass: 'toast register-toaster' });
			  } else {
				for (const key in errorsList.detail) {
				  if (errorsList.detail.hasOwnProperty(key)) {
					if (typeof errorsList.detail === 'undefined') {
					  this.toastr.error(errorsList.message, 'Sorry!', { enableHtml: true, toastClass: 'toast register-toaster' });
					} else {
					  this.toastr.error(errorsList.detail[key], 'Sorry!', { enableHtml: true, toastClass: 'toast register-toaster' });
					}
				  }
				}
			  }
			}
		  );
	  }

	  restChar(event: any) {
		if (!CustomCode.restricSpecialChar(event)) {
		  event.preventDefault();
		}
	  }
	
	  onPaste(event: any) {
		if (!CustomCode.restricPaste(event)) {
		  event.preventDefault();
		}
	  }
	  restSpace(event: any){
		if (event.keyCode == 32) {
		  event.preventDefault();
		}
	  }

	  confirm(): void {
		this.isAgreed = true;
		this.userData.enrolledIn = true;
		this.modalRef.hide();
	  }
	
	  decline(): void {
		this.userData.enrolledIn = false;
		this.isAgreed = false;
		this.modalRef.hide();
	  }
	
	  openTermModalWithCheck(AcceptTerms: TemplateRef<any>, event) {
		if (event.target.checked) {
		  this.modalRef = this.modalService.show(AcceptTerms, this.config);
		}
		this.userData.enrolledIn = false;
		return true;
	  }
	
	  openTermModal(AcceptTerms: TemplateRef<any>) {
		this.modalRef = this.modalService.show(AcceptTerms, this.config);
		return false;
	  }
	
	  closeModel() {
		this.modalRef.hide();
		if (!this.isAgreed) {
		  this.userData.enrolledIn = false;
		}
	  }

	  cancelEnrollForm(){
		document.getElementById('enrolledInCheck').click();
		document.getElementById('enrolledInCheck2').click();
	}
	chnageText(val)
		  {
			  if(1)
			  {
				  if(val!=undefined)
				  {
					return val.replace("LLC", "LLC.")
				  }else{
					  return val
				  }
			  }else{
				  return val
			  }
		  }

	changeRate(rate, vahicletype,withouttaxrate,hours_des,des)
	{
		this.selectedMonthlyRate = rate;
		const facRate = (withouttaxrate > 0) ? '$' + withouttaxrate : 'N/A';
		this.baserate = facRate;
		this.selectedvahicletype  =  vahicletype;
		this.selectedvahicletypeText=hours_des+" "+des
		this.selectedvahicletypeText1 = hours_des
		this.selectedvahicletypeText2 = des
		sessionStorage.setItem('selectedvahicletype', vahicletype);
		$('.dropdown.selectBoxMap').removeClass('open');
	}

	updateFacilityScheduleFormatted(id) {
		this.searchPage.getFacilityScheduleFormatted(id).then(
			(res) => {
				this.FgarageWorkingHours = res;
				this.FgarageWorkingHours = this.FgarageWorkingHours.data;
				this.FulldayWrking = 0;

			},
			(error) => {
				this.FulldayWrking = 1;
			}
		);
	}
	// showSavingFlexibilityMsg(msg){
	// 	if(msg){
	// 	  let string = msg.toLowerCase()
	// 	  if(string.includes("6 month") || string.includes("6 months") ){
	// 		return true;
	// 	  }
	// 	}
		
		
	//   }
	showSavingFlexibilityMsg(msg) {
		if (msg) {
		  let string = msg.toLowerCase()
		  //alert(string)
		  if (string.includes("12 month") || string.includes("12 months")) {
			if ($(window).width() < 768  ) {
			  this.orangestriptdata="Book Longer & Save More! "
			}
			else{
			  this.orangestriptdata="Book Longer & Save More! "
			}
			
			this.bgclr="flexibilityBadge-orange"
			return true;
		  }
		//   if (string.includes("6 month") || string.includes("6 months")) {
		// 	if ($(window).width() < 768  ) {
		// 	  this.orangestriptdata="Lock In Rates + Save!"
		// 	}
		// 	else{
		// 	  this.orangestriptdata="Lock In Term Rates + Save More!"
		// 	}
		// 	this.bgclr="flexibilityBadge-black"
		// 	return true;
		//   }
		//   if (string.includes("3 month") || string.includes("3 months")) {
		// 	if ($(window).width() < 768  ) {
		// 	  this.orangestriptdata="Term Rates Guaranteed "
		// 	}
		// 	else{
		// 	  this.orangestriptdata="Extend Your Terms & Save!"
		// 	}
		// 	this.bgclr="flexibilityBadge-black"
		// 	return true;
		//   }
		  if (string.includes("1 month") || string.includes("1  month")) {
			if ($(window).width() < 768  ) {
				this.orangestriptdata="Subject To Rate Changes"
			}
			else{
			  this.orangestriptdata="Subject To Rate Changes"
			}
			this.bgclr="flexibilityBadge-grey"
			return true;
		  }
		}
	
		//
	  }
	  makeSelectableMonthyPrice(selectedvahicletype,rate_type){
		if( selectedvahicletype===rate_type ){
			$('.activeMontlyPlan').click();
		}else {}
	  }
	  makeSelectableMonthyPriceMob(selectedvahicletype,rate_type){
		if( selectedvahicletype===rate_type ){
			$('.activeMontlyPlanMob').click();
		}else {}
	  }
	// ngAfterViewInit() {
	// 	let c = setInterval(() => {
	// 		if(this.elementRef.nativeElement.querySelector('.activeMontlyPlanMob')) {
	// 		  this.elementRef.nativeElement.querySelector('.activeMontlyPlanMob').click();
	// 		   //clearInterval(c);
	// 	   }
	// 	}, 0);
		
	// }
	makeSelectableMontheke(selectedvahicletype,rate_type){
		if( selectedvahicletype===rate_type ){
			$('.activeMontlyPlan').click();
		}else {
			
		}
	  }

	  formatPhoneNumber(phoneNumberString) {
		if(phoneNumberString !== '' && phoneNumberString != undefined){
		  phoneNumberString = phoneNumberString.trim().replace('-', '').replace('(', '').replace(')', '');
					var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
					var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
					if (match) {
					//   var intlCode = (match[1] ? '+1 ' : '')
					let fcontact = [ '(', match[2], ') ', match[3], '-', match[4]].join('')
					  return fcontact;
					}
					return null
		}
					
	  }


	  dadicatedFacilityBookBtnClick()
      {
        let text = 'Book button click on dadicated Facility ';
      sessionStorage.setItem('clickType',text)
     }

	 fetchCurentPrice(type ,rate_type, price){
		 if(type == rate_type){
			this.selectedPrice = price;
		}
	 }
	 fetchUserStatusForTracking(){
		let getUserId = '0';
		let getUserEmail = '0';
		let user_login_type = '';
		let isLogin = false;
		if(localStorage.getItem('access_token')){
		 isLogin = true;
		   getUserId = localStorage.getItem('userid');
		   getUserEmail = localStorage.getItem('email');
		   user_login_type = localStorage.getItem('user_login_type');
		}
		const loginStatusTrack = {
		  'event' : 'pageview',
		  'loginStatus' : isLogin? 'logged in': 'Not Logged IN', // 'logged in/Not Logged IN',
		  'authenticationMethod' : user_login_type,
		  'userId' : getUserId,
		  'pageType' : this.router.url,
		  'email': getUserEmail,
	   };
	  //  console.log(loginStatusTrack);
	  (<any>window).dataLayer.push(loginStatusTrack);
	  
	  }

	  dailyCheckoutEvent(facility,eventtype){
		let eventData = {};
		if(this.neighborhood_name_for_event =='' || this.neighborhood_name_for_event == undefined){
		  let neighborhood_name = '';
		  this.searchPage.getFacilityDetails(this.facility.id).then(
			(res) => {
				this.respdata = res;
				this.respdata = this.respdata.data;
				//let update_price=this.respdata.price
				// if(this.respdata.availability==0){
				// 	update_price=this.respdata.price_without_availibility
				// }
				//console.log(this.respdata);
				neighborhood_name = this.respdata.neighborhood.title
				let eventData = {
					                'event': eventtype,
									'UserId' :this.search_user_id,
									'email': this.search_user_email,
									'FacilityPageLocation': this.router.url,
									'Facility': [{
									  'name': this.currentFacility,                      // Name or ID is required.
									  'id': facility.id,
									  'price': this.FacilityAvailableFetatures.price,
									  'category': 'Daily',
									  'neighborhood': neighborhood_name.toString()
									}]
					};

					(<any>window).dataLayer.push(eventData);

					//console.log(eventData)
			});
		}else {
			let eventData = {
				                'event': eventtype,
								'UserId' :this.search_user_id,
								'email': this.search_user_email,
								'FacilityPageLocation': this.router.url,
								'Facility': [{
								  'name': this.currentFacility,                      // Name or ID is required.
								  'id': facility.id,
								  'price': this.FacilityAvailableFetatures.price,
								  'category': 'Daily',
								  'neighborhood': this.neighborhood_name_for_event
								}]
				};
				(<any>window).dataLayer.push(eventData);
				//console.log(eventData)
		}
		
	  }


	  buyMonthlyClickEvent(facility){
		let eventData = {};
		if(this.neighborhood_name_for_event =='' || this.neighborhood_name_for_event == undefined){
		  let neighborhood_name = '';
		  this.searchPage.getFacilityDetails(facility.id).then(
			(res) => {
				this.respdata = res;
				this.respdata = this.respdata.data;
				let neighborhood = this.respdata.neighborhood
				//console.log(neighborhood);
				neighborhood_name = neighborhood.title
				//console.log(neighborhood_name.toString());
				(<any>window).dataLayer.push(
					{
					'event': 'BuyMonthlyClick',
									'UserId' :this.search_user_id,
									'email': this.search_user_email,
									'FacilityPageLocation': this.router.url,
									'Facility': [{
									  'name': this.currentFacility,                      // Name or ID is required.
									  'id': facility.id,
									  'price': this.selectedPrice,
									  'category': 'Monthly',
									  'neighborhood': neighborhood_name
									}]
					});
			});
		}else {
			(<any>window).dataLayer.push(
				{
				'event': 'BuyMonthlyClick',
								'UserId' :this.search_user_id,
								'email': this.search_user_email,
								'FacilityPageLocation': this.router.url,
								'Facility': [{
								  'name': this.currentFacility,                      // Name or ID is required.
								  'id': this.facility.id,
								  'price': this.selectedPrice,
								  'category': 'Monthly',
								  'neighborhood': this.neighborhood_name_for_event
								}]
				});
		}
		
	  }

	  getMetaElement(name){
		return this.metaTags.find((element) => { return element.getAttribute('http-equiv') === name; });
	  }
	  updatePageMeta(new_url) {
		this.descriptionElement.setAttribute('content', "20; url = "+new_url);
		this.descriptionElement.setAttribute('http-equiv', "refresh");
		
	  }
}
