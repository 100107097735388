import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { UserserviceService } from '../../services/userservice.service';
import { CustomCode } from '../../shared/CustomCode';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ChangePasswordService } from '../../services/changepassword.service';
import * as $ from 'jquery';

@Component({
  selector: 'app-update-profile',
  templateUrl: './update-profile.component.html',
  styleUrls: ['./update-profile.component.scss'],
  providers: [UserserviceService, ChangePasswordService]
})
export class UpdateProfileComponent implements OnInit {

  isLoaded: boolean = false;
  user: any = {};
  formData: any = {};
  userEmail = '';
  icon_rewards_logo_wt = 'assets/img/icon-rewards-logo-wt.svg';
  phoneMask: any = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  firstCls: string = 'form-group';
  lastCls: string = 'form-group';
  phoneCls: string = 'form-group';
  emailCls: string = 'form-group';
  zipCls: string = 'form-group';
  monthsList = CustomCode.getMonthList();
  yearsList = CustomCode.getBeforeYearList();
  changePasswordDisabled:boolean = false;
  emailRegex = CustomCode.emailRegex;
  passwordRegex = CustomCode.passwordRegex;
  passwordErrMsg='New password is required';
  oldPasswordErrMsg='Old password is required';
  disableEmailId = false;
  constructor(
    private userServiceObj: UserserviceService,
    private routeObj: Router,
    private toasterObj: ToastrService,
    private changepasswordObj: ChangePasswordService) { }


  ngOnDestroy() {
    $('#menus0').removeClass('active');
  }

  ngOnInit() {
    $('#menus0').addClass('active');
    this.userServiceObj.fetchUser().then(
      (data:any)=> {
        let uData = data['data'];
        let fullName = uData.name;
        let fname = (fullName.substr(0, fullName.indexOf(' ')) != '') ? fullName.substr(0, fullName.indexOf(' ')) : fullName.substr(fullName.indexOf(' ') + 1);
        let lname = (fullName.substr(0, fullName.indexOf(' ')) == '') ? '' : fullName.substr(fullName.indexOf(' ') + 1);
        this.user.first_name = fname;
        this.user.last_name = lname;
        this.user.phone = uData.phone;
        this.user.email = uData.email;
        this.user.zip = uData.pincode;
        if (uData.dob != null && uData.dob != '0000-00-00') {
          uData.dob = uData.dob.replace('01T', '05T')
          let bd = new Date(uData.dob);
          this.user.day = String(bd.getDate());
          this.user.month = String(bd.getMonth() + 1);
          this.user.year = String(bd.getFullYear());
        }else{
          this.user.month = '';
          this.user.year = '';
          this.user.day = '';
        }

        // console.log(uData);
        if (uData.hasOwnProperty('monthly_parking_accounts') && uData['monthly_parking_accounts'].length>0 ){
          for (let index = 0; index < uData['monthly_parking_accounts'].length; index++) {
            const element = uData['monthly_parking_accounts'][index];
            if( element.hasOwnProperty('account_type') && element['account_type'] == "VM" && element['active'] == "1" ) {
              this.disableEmailId = true;
              break;
            }
          }
        }

        if (this.user.first_name !== '') { this.firstCls = 'form-group focused'; }
        if (this.user.last_name !== '') { this.lastCls = 'form-group focused'; }
        if (this.user.phone !== '') { this.phoneCls = 'form-group focused'; }
        if (this.user.email !== '') { this.emailCls = 'form-group focused'; }
        if (this.user.zip !== '') { this.zipCls = 'form-group focused'; }
        this.isLoaded = true;
      },
      err => {
        this.isLoaded = true;
      }
    );
  }

  saveUserInfo(user, formRef) {
    this.fetchUserStatusForTracking();
    this.isLoaded = true;
    let fName = user.first_name;
    let lName = user.last_name;
    let name = fName + ' ' + lName;

    const reg =/<(.|\n)*?>/g; 
    if (reg.test(user.first_name) == true) {
      this.toasterObj.error('Please enter valid First Name.', 'Sorry!');
      return false;
    }else  if (reg.test(user.last_name) == true) {
      this.toasterObj.error('Please enter valid Last Name.', 'Sorry!');
      return false;
    } else {
      if (reg.test(user.phone) == true) {
        this.toasterObj.error('Please enter valid Phone.', 'Sorry!');
        return false;
      }
    }

    let isValid = CustomCode.isValidName(name);
    if (!isValid) {
      this.toasterObj.error('Not a valid input name.', 'Sorry!');
      return false;
    }
    if(user.phone != undefined ){
    let ph = user.phone.replace(/\D/g, '');
      if(ph.length !==0 && ph.length<10){
        formRef.form.controls['phone'].setErrors({'invalid': true});
        this.toasterObj.error('Please enter a valid Phone Number.', 'Sorry!');
        return false;
      }
    }
    
    if(user.year !== '' && user.month == ''){
      this.toasterObj.error('Month is required.', 'Sorry!');
      return false;
    }

    if(user.month !== '' && user.year == ''){
      this.toasterObj.error('Year is required.', 'Sorry!');
      return false;
    }

    let userInfo = {
      name: name.trim(),
      phone: user.phone,
      email: (user.email).trim(),
      pincode: user.zip,
      dob: (user.year !== '' && user.month !== '') ? user.year + '-' + user.month + '-01' : ''
    }


    this.userServiceObj.updateProfile(userInfo)
      .subscribe(
        (data:any) => {
          //console.log(data);
          this.isLoaded = true;
          let respdata = data.data;
          //console.log(respdata);
          localStorage.setItem('username', respdata.name);
          localStorage.setItem('phone_number',user.phone);
          localStorage.setItem('email', respdata.email);
          localStorage.setItem('invalidName', "0");
          this.toasterObj.success('User Information Updated Successfully.', 'Great!');
          setTimeout(function() {window.location.href = '/my-account/account';}, 1000);
          

          // window.location.href = CustomCode.BASEURL+'my-account/account';
        },
        error => {
          this.isLoaded = true;
          const errorsList = error.errors;
          if (errorsList.message !== 'Validation Errors') {
            this.toasterObj.error(errorsList.message, 'Sorry!', {enableHtml:true,  toastClass:'toast register-toaster'});
          } else {
            for (const key in errorsList.detail) {
              if (errorsList.detail.hasOwnProperty(key)) {
                if (typeof errorsList.detail === 'undefined') {
                  this.toasterObj.error(errorsList.message, 'Sorry!', {enableHtml:true,  toastClass:'toast register-toaster'});
                } else {
                  this.toasterObj.error(errorsList.detail[key], 'Sorry!', {enableHtml:true,  toastClass:'toast register-toaster'});
                }
              }
            }
          }
        }
      );
  }

  restChar(event: any) {
    if (!CustomCode.restricSpecialChar(event)) {
      event.preventDefault();
    }
  }

  onPaste(event: any) {
    if (!CustomCode.restricPaste(event)) {
      event.preventDefault();
    }
  }

  phoneFormat(event: any) {
    const pattern = /[0-9\)\-\(\.\ ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && event.keyCode != 9 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  cancelChangePassword() {
    this.routeObj.navigate(['/my-account/account']);
  }

  changepassword(formValues, formObj: NgForm) {
    this.fetchUserStatusForTracking();
    if(formValues.old_password === undefined || formValues.old_password === ''){
      this.oldPasswordErrMsg = 'Old password is required';
      formObj.form.controls['old_password'].setErrors({'invalid': true});
      return false;
    }

    if (formValues.password === undefined || formValues.password === '') {
      this.passwordErrMsg = 'New password is required';
      formObj.form.controls['password'].setErrors({'invalid': true});
      return false;
    }else if(formValues.password.length < 8){
      this.passwordErrMsg = 'Password must be atleast 8 characters long';
      formObj.form.controls['password'].setErrors({'invalid': true});
      return false;
    }else if(formValues.password !== formValues.password_confirmation){
      formObj.form.controls['password_confirmation'].setErrors({'invalid': true});
      return false;
    }

    let loggedUserId = CustomCode.getloggedInUserId();
    this.changepasswordObj.changeuserpassword(formValues, loggedUserId)
      .subscribe(
        (data:any) => {
          if (data.status === 201) {
            this.routeObj.navigate(['/my-account/account']);
            this.toasterObj.success('Password change successfully', 'success!');
          }
        },
        error => {
          const errorsList = error.errors;
          if (errorsList.message !== 'Validation Errors') {
            this.toasterObj.error(errorsList.message, 'Sorry!');
          } else {
            for (const key in errorsList.detail) {
              if (errorsList.detail.hasOwnProperty(key)) {
                if (typeof errorsList.detail === 'undefined') {
                  this.toasterObj.error(errorsList.message, 'Sorry!');
                } else {
                  this.toasterObj.error(errorsList.detail[key], 'Sorry!');
                }
              }
            }
          }
        }
      );
  }

  // validatedPassword(event){
  //   this.changePasswordDisabled = false;
  //   let passVal = event.target.value;
  //   const pattern = /^(?=.*[A-Z])(?=.*[!@#\$%\^&\*])(?=.{6,})/;
  //   if(passVal.length < 6){
  //     this.toasterObj.error('The password must be at least 6 characters', 'Sorry!');
  //     this.changePasswordDisabled = true;
  //   }else if(passVal.match(pattern) == null){
  //     this.toasterObj.error('Password should contain atleast one upppercase and one special character.', 'Sorry!');
  //     this.changePasswordDisabled = true;
  //   }
  // }

  restSpace(event: any){
    if (event.keyCode == 32) {
      event.preventDefault();
    }
  }

  restFirstSpace(event: any){
    if (event.keyCode == 32 && event.target.value=='') {
      event.preventDefault();
    }
  }

  fetchUserStatusForTracking(){
		let getUserId = '0';
		let getUserEmail = '0';
		let user_login_type = '';
		let isLogin = false;
		if(localStorage.getItem('access_token')){
		 isLogin = true;
		   getUserId = localStorage.getItem('userid');
		   getUserEmail = localStorage.getItem('email');
		   user_login_type = localStorage.getItem('user_login_type');
		}
		const loginStatusTrack = {
		  'event' : 'pageview',
		  'loginStatus' : isLogin? 'logged in': 'Not Logged IN', // 'logged in/Not Logged IN',
		  'authenticationMethod' : user_login_type,
		  'userId' : getUserId,
		  'pageType' : this.routeObj.url,
		  'email': getUserEmail,
	   };
	  (<any>window).dataLayer.push(loginStatusTrack);
	  }
}

