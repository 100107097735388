<div  class="form-wrapper"  *ngIf="!hideb2bForm">

                  
    <h4 class="text-primary">
      Looking for the safest way for your team to return to work? Icon can Help!
    </h4>
    <h5>
      Limited spots still available, guarantee yours now! 
    </h5>
    <div class="clearfix mar-top-sm">
      <p>
        Just fill out the form below.
      </p>
      <form name="form" class="formFiller" role="form" id="floating-label-contact-us" (ngSubmit)="f.form.valid && b2bFormSubmit(formData, f)" #f="ngForm" novalidate>
        <div id="errors-container">
        </div>
        <p id="successpara" class="submit-success"></p>
        <div class="row">
          <div class="col-sm-7 col-xs-7 p-right ">
            <div class="form-group"  [ngClass]="{ 'has-error' : f.submitted && !company_name.valid}">
              <input type="text" (change)="enter_valueCompany('company_name')" id="company_name" placeholder="Company Name" name="company_name" [(ngModel)]="formData.company_name" #company_name="ngModel" required class="form-control"/>
              <div *ngIf="f.submitted && !company_name.valid" class="help-block">Company Name is required</div>
            </div>
          </div>
          <div class="col-sm-5 col-xs-5 p-left ">
            <div class="form-group" [ngClass]="{ 'has-error' : f.submitted && !number_of_employees.valid}" [ngClass]=" { 'focused' : f.submitted && number_of_employees.valid} ">
           <select (change)="enter_valueEmployee('number_of_employees')" class="form-control number_of_employees" id="number_of_employees" name="number_of_employees"  title="No. of Employees"  [(ngModel)]="formData.number_of_employees" required #number_of_employees="ngModel">
             <option selected disabled [ngValue]="title">  No. of Parking Spaces </option>
             <option value="tfa_5" id="tfa_5" class="">25-50</option>
              <option value="tfa_6" id="tfa_6" class="">51-75</option>
              <option value="tfa_7" id="tfa_7" class="">76-100</option>
              <option value="tfa_8" id="tfa_8" class="">100+</option>
           </select>
           <div *ngIf="f.submitted && !number_of_employees.valid" class="help-block">Please select  No. of Parking Spaces</div>
           </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6 col-xs-6 p-right">
            <div class="form-group" [ngClass]="{ 'has-error' : f.submitted && !first_name.valid}">
            <input (change)="enter_valueFname('fname')" id="fname" type="text " placeholder="First Name" name="first_name" [(ngModel)]="formData.first_name" #first_name="ngModel" required  class="form-control"/>
            <div *ngIf="f.submitted && !first_name.valid" class="help-block">First Name is required</div>
          </div>
          </div>
          <div class="col-sm-6 col-xs-6 p-left">
            <div class="form-group" [ngClass]="{ 'has-error' : f.submitted && !last_name.valid}">
              <input (change)="enter_valueLname('lname')" id="lname" type="text " placeholder="Last Name" name="last_name" [(ngModel)]="formData.last_name" #last_name="ngModel" required  class="form-control"/>
              <div *ngIf="f.submitted && !last_name.valid" class="help-block">Last Name is required</div>
            </div>

          </div>
        </div>
        <div class="row">
          <div class="col-sm-6 col-xs-6 p-right">
            <div class="form-group" [ngClass]="{ 'has-error' : f.submitted && !last_name.valid}">
              <input (change)="enter_valueEmail('emailc')" id="emailc" class="form-control" placeholder="Email" type="email" maxlength=100 name="email" [(ngModel)]="formData.email" #email="ngModel" required (keydown)="restSpace($event)" pattern="{{emailRegex}}"
              />
              <div *ngIf="f.submitted && !email.valid" class="help-block">Email is required</div>
            </div>
          </div>
          <div class="col-sm-6 col-xs-6 p-left">
            <div class="form-group" [ngClass]="{ 'has-error' : f.submitted && !contact_number.valid}">
            <input (change)="enter_valuePhone('phonec')" id="phonec" class="form-control" placeholder="Contact Number" minlength="10" [textMask]="{mask: phoneMask}" (keyup)="keyPress($event)" type="text" name="contact_number" [(ngModel)]="formData.contact_number" #contact_number="ngModel" required />
            <div *ngIf="f.submitted && !contact_number.valid" class="help-block">Contact Number is required</div>
              </div>
          </div>
        </div>
        <div class="clearfix">
            <div class=" form-group"  >                 
          <button style="text-transform: capitalize; border-radius: 4px;" class="btn btn-primary btn-block   btn-fill">
            <b>Reserve your guaranteed spots now!</b>
          </button>
        </div>  
        </div>
        
      </form>
      
    </div>
  </div>
