
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { NgForm } from '@angular/forms';
import * as $ from 'jquery';
import { ToastrService } from 'ngx-toastr';

import { MyAccountMonthlyParkingService } from '../../services/my-account-monthly-parking.service';

@Component({
  selector: 'app-add-bank-account',
  templateUrl: './add-bank-account.component.html',
  styleUrls: ['./add-bank-account.component.scss'],
  providers: [MyAccountMonthlyParkingService]
})
export class AddBankAccountComponent implements OnInit {

  @Input() currentActive: string;
  @Input() accountNumber: any;

  formData: any = {
    'account_type' : 'checking'
  };

  cancel = true;
  has_valet_manager = false;
  showAccountError = true;
  accountNumberToken = '';
  @Output() cancelEvent = new EventEmitter<boolean>();
  @Output() responseEvent = new EventEmitter<boolean>();

  cardguideIcon = '../../assets/img/cardguide.png';

  constructor(
    private toastr: ToastrService,
    private myAccountMonthlyParkingObj: MyAccountMonthlyParkingService,
    ) { }

  ngOnInit() {
    if( localStorage.getItem('account_type') && localStorage.getItem('account_type') == 'VM' ){
      this.has_valet_manager = true
    } else {
      this.has_valet_manager = false;
    }
    setInterval(()=>{
      if(this.has_valet_manager == true){
        this.accountNumberToken = $('#addNewBankAcToken').val();
        //console.log(this.accountNumberToken);
      if(this.accountNumberToken == 'undefined' || this.accountNumberToken == undefined || this.accountNumberToken == null || this.accountNumberToken == ''){
        this.showAccountError = true;
       } else {
        this.showAccountError = false;
       }
      }
    },100)
  }

  responseReturn() {
    this.responseEvent.emit();
  }

  hitCancel() {
    this.cancel = false;
    this.cancelEvent.emit(this.cancel);
  }

  submitAddBankAccount(formData, form: NgForm) {
    formData.payment_type = 'bank_account';
    if(this.has_valet_manager == true){
      this.accountNumberToken = $('#addNewBankAcToken').val();
      if(this.accountNumberToken == 'undefined' || this.accountNumberToken == undefined || this.accountNumberToken == null || this.accountNumberToken == ''){
        this.showAccountError = true;
         return;
       }else {
        this.showAccountError = false;
        formData.account_number = this.accountNumberToken;
       }
    }
    //console.log(formData);
    this.myAccountMonthlyParkingObj.addBankAccount(formData, this.accountNumber)
    .then(
      (data:any)=> {
        this.toastr.success('Payment Method Added Successfully', 'Great!');
        this.responseReturn();
        this.hitCancel();
      },
      error => {
        let errorMessage = error.errors.message;
        if (errorMessage === 'Validation Errors') {
          errorMessage = '';
          for (const key in error.errors.detail) {
              errorMessage += error.errors.detail[key];
          }
        }
        this.toastr.error(errorMessage, 'Sorry!');
      }
    );
  }

  restrictNumeric(event) {
    let input;
    if (event.metaKey || event.ctrlKey) {
      return true;
    }
    if (event.which === 32) {
     return false;
    }
    if (event.which === 0) {
     return true;
    }
    if (event.which < 33) {
      return true;
    }
    input = String.fromCharCode(event.which);
    return !!/[\d\s]/.test(input);
  }

}

