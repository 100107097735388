<app-header></app-header>
<div *ngIf="!isRegistered">
  <div *ngIf="firstStep" class="container mar-top-md mar-bottom-md commonPage orangeBar ">
    <div class="row">
      <div class="col-xs-12 mar-lr-auto col-sm-8 col-md-6">
        <div class="top-re-icons mar-top-md clearfix pad-top-xs">
          <ul class="top-icon-rewards pull-right pad-top-xs">
            <li>
              <img [src]="icon_rewards_logo_wt" alt="image">
            </li>
          </ul>
        </div>
        <div class="create-account-loyalty mar-top-0">
          <form id="floatForm" name="form" (ngSubmit)="moveToNext(userData, iconR)" role="form"
            #iconR="ngForm">
            <h2 class="text-primary text-uppercase mar-top-0 mar-bottom-sm">Enroll in icon rewards</h2>
            <p>All Icon Rewards members receive at least 10% off posted facility rates and online reservations! Enroll now to unlock immediate discounts!</p>
            <p class="mar-bottom-0">Already have an account? <a class="pad-left-xs" data-toggle="modal" id="loginPopup"
                [href]="loginLink" oncontextmenu="return false;">Sign in here <fa name="angle-right"></fa></a></p>
            <div class="row">
              <div class="col-sm-6">
                <div class="form-group" [ngClass]="{ 'has-error' : iconR.submitted && !firstName.valid}">
                  <label class="control-label" for="fname">First name</label>
                  <input class="form-control" [(ngModel)]="userData.first_name" (keypress)="restChar($event)" (paste)="onPaste($event)" name="first_name" #firstName="ngModel"
                    required type="text" placeholder="" />
                  <div *ngIf="iconR.submitted && !firstName.valid" class="help-block">First Name is required</div>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group" >
                  <label class="control-label" for="lname">Last name</label>
                  <input class="form-control" [(ngModel)]="userData.last_name" (keypress)="restChar($event)" placeholder="" (paste)="onPaste($event)" required name="last_name" #lastName="ngModel"
                    type="text" />
                  <!-- <div *ngIf="iconR.submitted && !lastName.valid" class="help-block">Last Name is required</div> -->
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group" [ngClass]="{ 'has-error' : iconR.submitted && !email.valid}">
                  <label class="control-label" for="email">Email address</label>
                  <input class="form-control" autocomplete="off" [(ngModel)]="userData.email" placeholder="" name="email" #email="ngModel"
                    required type="text" (keydown)="restSpace($event)" pattern="{{emailRegex}}" />
                  <div *ngIf="iconR.submitted && !email.valid" class="help-block">Email is required</div>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group" [ngClass]="{ 'has-error' : iconR.submitted && !phone.valid}">
                  <label class="control-label" for="phone">Phone number (optional)</label>
                  <input class="form-control" autocomplete="off" [(ngModel)]="userData.phone" placeholder="" minlength="10" [textMask]="{mask: phoneMask}" (keypress)="phoneFormat($event)" name="phone"
                     #phone="ngModel" type="text" />
                  <div *ngIf="iconR.submitted && !phone.valid" class="help-block">Not a valid phone number</div>
                </div>
              </div>
              <div class="col-sm-12">
                <h4 class="text-primary text-uppercase">Password</h4>
              </div>
              <div class="col-sm-6">
                <div class="form-group" [ngClass]="{ 'has-error' : iconR.submitted && !password.valid}">
                  <label class="control-label" for="password">Password</label>
                  <input class="form-control" autocomplete="off" placeholder="" [(ngModel)]="userData.password" name="password"
                    #password="ngModel" required reverse="true" validateEqual="cpassword" type="password">
                  <div *ngIf="iconR.submitted && !password.valid" class="help-block">{{passwordErrMsg}}</div>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group" [ngClass]="{ 'has-error' : iconR.submitted && !cpassword.valid}">
                  <label class="control-label" for="re-password">Confirm password</label>
                  <input class="form-control" [(ngModel)]="userData.cpassword" placeholder="" name="cpassword" #cpassword="ngModel"
                    required validateEqual="password" type="password">
                  <div *ngIf="iconR.submitted && !cpassword.valid" class="help-block">Confirm Password and Password
                    should be same</div>
                </div>
              </div>
              <div class="clearfix"></div>
              <div class="col-sm-6">
                <div class="form-group" [ngClass]="{ 'has-error' : iconR.submitted && !promocode.valid}">
                  <label class="control-label" for="promocode">Invitation Code (optional)</label>
                  <input class="form-control" [(ngModel)]="userData.promocode" maxlength="8" placeholder="" name="promocode" #promocode="ngModel" type="text">
                </div>
              </div> 
              <div class="form-group  col-sm-12" [ngClass]="{ 'has-error' : iconR.submitted && !enrolledIn.valid}">
                <div class="checkbox">
                  <label class="enroll-terms-lbl">
                    <input [(ngModel)]="userData.enrolledIn" (click)="openModal(AcceptTerms, $event); " name="enrolledIn"
                      #enrolledIn="ngModel" type="checkbox" required value=""> <span class="tnc-check-pad">I agree to the Icon
                    Rewards &nbsp;<a target="__blank" (click)="openTermModal(AcceptTerms); ">Terms and Conditions</a></span></label>
                  <div *ngIf="iconR.submitted && !enrolledIn.valid" class="help-block">You need to accept terms &
                    conditions.</div>
                </div>
              </div>
              <div class="col-sm-12 form-group mar-top-xs mar-bottom-sm btn-center-xs">
                <button class="btn btn-primary btn-fill">Enroll in Icon Rewards</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

  <!-- <div *ngIf="secondStep" class="container mar-top-md mar-bottom-md">
    <div class="row">
      <div class="col-xs-12 mar-lr-auto col-sm-8 col-md-6">
        <div class="top-re-icons mar-top-md clearfix">
            <ol class="breadcrumb pad-top-0 pad-bottom-0 pad-left-0">
              <li class="active">
                <a (click)="goBack()" class="btn btn-link pad-left-0" >
                  <i class="fa fa-angle-left" style="font-size: 20px; margin-top: -5px;"></i>&nbsp; My Details</a>
              </li>
            </ol>
            <ul class="top-icon-rewards pull-right">
              <li>
                <img [src]="icon_rewards_logo_wt" alt="image">
              </li>
            </ul>
        </div>
        <div class="create-account-loyalty mar-top-0">
          <h2  class="text-uppercase mar-top-0 text-primary">ADD MY CREDIT CARD</h2>
          <div class="well">
              <table><tr>
              <td valign="top" class="pad-right-xs"><img [src]="price_tag_icon" alt="image"></td><td class="pad-left-xs">Enroll the credit card you plan to use for parking in your Icon Rewards profile, and you will automatically earn points even when you park without a reservation.</td>
              <td class="pad-left-xs "><a (click)="enrolledCardInfo(ECardInfo);" href="javascript:void(0)" style="vertical-align:middle;"><img [src]="helpIcon" alt="image"></a></td>
              </tr></table>
          </div>
          <form #sCard="ngForm" id="sCard" (ngSubmit)="sCard.valid && sCard.submitted && enrolledNow(card, sCard)" onsubmit="return GetToken()">
            <div class="row">
              <div class="col-xs-12 col-sm-9 col-md-9">
                <div class="" [ngClass]="{ 'has-error' : sCard.submitted && !card_no.valid}">
                  <label class="control-label" for="card">&nbsp;</label>
                  <input class="form-control" [textMask]="{mask: cardMask}" (keyup)="checkCard(card_no)" (keypress)="restrictNumeric($event)" [(ngModel)]="card.card_no" name="card_no" #card_no="ngModel" required type="text" placeholder="Card number" id="standardCardNumber">
                  <div *ngIf="sCard.submitted && !card_no.valid" class="help-block">Card number is required</div>
                </div>
              </div>
              <div class="clearfix"></div>
              <div class="col-xs-12 col-sm-8 col-md-8">
                  <div class="" [ngClass]="{ 'has-error' : sCard.submitted && !exp.valid}">
                    <label class="control-label" for="card">&nbsp;</label>
                    <input class="form-control" type="text" autocomplete="off" [textMask]="{mask: dateMask}" [(ngModel)]="card.exp" (keyup)="setToexpirationField(exp)" name="exp" #exp="ngModel" required placeholder="Expiration date" id="standardCardExpiration">
                    <input class="form-control" type="hidden" autocomplete="off" [(ngModel)]="card.exp1" name="exp1" #exp1="ngModel" required placeholder="expiration date" id="standardCardExpiration1">
                    <div *ngIf="sCard.submitted && !exp.valid" class="help-block">Card expiration is required</div>
                  </div>
                </div>
              <div class="clearfix"></div>
              <div class="col-xs-8 col-sm-4">
                <div class="" [ngClass]="{ 'has-error' : sCard.submitted && !security_code.valid}">
                  <label class="control-label" for="card">&nbsp;</label>
                  <input class="form-control" maxlength="4" [(ngModel)]="card.security_code" name="security_code" #security_code="ngModel" required type="text" placeholder="Security code" id="standardCardCvv"
                  />
                  <div *ngIf="sCard.submitted && !security_code.valid" class="help-block">Security code is required</div>
                </div>
              </div>
              <div class=" col-xs-7 col-sm-4">
                <div class="" [ngClass]="{ 'has-error' : sCard.submitted && !zip_code.valid}">
                  <label class="control-label" for="card">&nbsp;</label>
                  <input class="form-control" [(ngModel)]="card.zip_code" minlength="5" maxlength="6" name="zip_code" #zip_code="ngModel" required type="text" placeholder="Zip code" id="standardCardZip" />
                  <div *ngIf="sCard.submitted && !zip_code.valid" class="help-block">Zip code is required</div>
                </div>
              </div>
              <div class="clearfix"></div>
              <div *ngIf="!isInvAssosiated" class="col-xs-12 col-sm-8 col-md-8">
                <div class="" [ngClass]="{ 'has-error' : sCard.submitted && !promocode.valid}">
                  <label class="control-label" for="card">&nbsp;</label>
                  <input class="form-control" [(ngModel)]="card.promocode" maxlength="8" name="promocode" #promocode="ngModel" type="text" placeholder="Invitation code (optional)" />
                </div>
              </div>
            </div>
            <div class="row">
                <div class="mar-top-sm col-sm-12">
                    <div class="checkbox">
                      <label class="enroll-terms-lbl">
                          <input type="checkbox" value="" id="addCardIcon"   #addCardIcon="ngModel" [(ngModel)]="card.addCardIcon"
                          name="AddCardIcon">&nbsp;&nbsp;Save card for future reservations</label>
                    </div>
                </div>
              <div class="col-md-12">
                <div class="form-group  mar-top-sm btn-center-xs">
                  <button type="submit" [disabled]="submtDisabled" class="btn btn-primary btn-fill"><i class="fa fa-lock"></i> &nbsp; ADD CARD & ENROLL </button><a routerLink="/my-account/account" class="btn btn-primary mar-left-xs">SKIP NOW</a>
                </div>
              </div>
            </div>
          </form>
          <div class="well">
            <div class="row">
              <div class="col-xs-12 col-md-3 col-sm-3 pad-right-0"><img class="img-responsive" [src]="pcjImg" alt="image"></div> <div class="col-md-9 col-xs-12 col-sm-9"><p class="pad-top-xs visible-xs"></p><p>Our systems are PCI compliant, and use state of the art encryption and security to store your credit card information, because we understand how important it is to protect your data.</p></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> -->

  <!---  #ECardInfo page    -->
  <ng-template class="modal fade" #ECardInfo id="ECardInfo">
      <div class="modal-header">
        <button type="button" class="close pull-right" aria-label="Close" (click)="close()">
          <span style="font-size: 50px; font-weight: normal;" aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div>
          <h2 class="text-uppercase mar-top-0 mar-bottom-sm text-primary">ADD CARD TO ICON REWARDS</h2>
          <div class="icon-rewards-terms mar-top-xs mar-bottom-xs">Enroll the credit card you plan to use for parking in your Icon Rewards profile, and you will earn points even when you park without a reservation.  When you pay with your enrolled credit, our system will identify you as an Icon Rewards member and deposit points into your account after each eligible parking session. If you don't add a credit card, you will only earn points when you book on our website or on the Icon GO app.</div>
        </div>
        <div class="form-group mar-top-sm mar-bottom-0 btn-center-xs">
          <button type="button" class="btn btn-primary" (click)="close()">GOT IT</button>
        </div>
      </div>
    </ng-template>
    <!---  #ECardInfo page    -->
    
  <!---  #Term page    -->
  <ng-template class="modal fade" #AcceptTerms id="AcceptTerms">
    <div class="modal-header">
      <button type="button" class="close pull-right" aria-label="Close" (click)="closeModel()">
        <span style="font-size: 45px; font-weight: normal;" aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body" style="padding-top:0px;">
      <app-lazy-load [isLoaded]="isLoaded"></app-lazy-load>
      <div *ngIf="isLoaded">
        <h2 class="text-uppercase mar-top-0 mar-bottom-xs pad-bottom-xs text-primary">ENROLL IN ICON REWARDS</h2>
        <p class="pad-bottom-xs mar-bottom-xs">{{cmsPageContent.post_title}}</p>
        <div class="icon-rewards-terms mar-top-xs">
          <div [innerHTML]="cmsPageContent.post_content"></div>
        </div>
      </div>
      <div class="form-group mar-top-sm mar-bottom-0 btn-center-xs">
        <button type="button" class="btn btn-primary btn-fill" (click)="confirm()">I AGREE</button>
        <span class="mar-left-xs"></span>
        <button type="button" class="btn btn-primary theme-btn" (click)="decline()">CANCEL</button>
      </div>
    </div>
  </ng-template>
</div>
<!-- #Term Page -->

<!--Thank u page after registration -->
<div *ngIf="isRegistered" class="container pad-top-md mar-top-md mar-bottom-sm">
  <div class="row">
    <div class="col-xs-12 mar-lr-auto col-sm-8 col-md-12 col-lg-12">
      <h2 class="text-primary text-center text-uppercase mar-top-0 mar-bottom-0">Thank You</h2>
      <h4 class="mar-bottom-sm text-center">Your account has been created. Please check your email for a confirmation.</h4>
    </div>
  </div>
</div>
<div *ngIf="isRegistered" class="well mar-bottom-md">
  <div class="container">
    <div class="row">
      <div class="col-sm-10 col-sm-offset-2">
        <div class="top-icon-rewards clearfix">
          <ul>
            <li>
              <img src="assets/img/icon-rewards-logo-wt.svg" alt="image">
            </li>
          </ul>
        </div>
        <h2 class="text-uppercase mar-bottom-xs">DON’T MISS OUT ON AN EXTRA
          <span class="text-primary">${{rewarded_points | number}} ACCOUNT CREDIT </span>
        </h2>
        <p>For a limited time you can fill out a few basic questions about yourself, and we’ll give you ${{rewarded_points | number}} account credit
          towards
          your Icon Rewards balance.</p>
      </div>
      <div class="visible-xs mar-bottom-sm"></div>
      <div class="col-sm-10 col-sm-offset-2">
        <div class="btn-center-xs mar-bottom-sm">
          <div class="mar-top-sm visible-xs"></div>
          <a routerLink="/my-account/manage-icon-rewards-details" class="btn btn-primary btn-fill">LET'S DO IT</a>
          <span class="mar-left-xs"></span>
          <a routerLink="/faq" class="btn theme-btn btn-primary" style="white-space: pre-wrap;">FREQUENTLY ASKED QUESTIONS</a>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="mar-bottom-md" *ngIf="isRegistered">
  <div class="btn-center-xs text-center mar-bottom-sm">
    <a routerLink="/my-account/account" class="btn theme-btn btn-primary">Proceed to My Account</a>
  </div>
</div>
<app-footer></app-footer>
