<h2 class="text-primary text-uppercase mar-top-0">Saved Promo Code</h2>
<br/>
<div class="clearfix" *ngIf="isLoaded">
	<div class="row" *ngIf="!noPromoCode">
	
			<div class="col-xs-12 col-sm-6 col-md-6 col-lg-6" *ngFor="let promocode of promoCodes.promocodedetail">
				<div class="well">
					<a href="javascript:;" class="pull-right" (click)="deletePromoCode(promocode.promocode)">
						<fa name="times"></fa>
					</a>
					<h4 class="text-uppercase">{{ promocode.message }}
					</h4>
					<p *ngIf="promocode.usages > 0">({{ promocode.usages }} REMAINING)</p>
					<div class="row ">
						<div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
							<p><small>Promo code:</small><br>
								<span class="text-uppercase text-primary">{{ promocode.promocode}}</span>
							</p>
						</div>
						<div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
							<p><small>Valid Till:</small><br>
								<span class="text-uppercase text-primary">{{ promocode.validto}}</span>
							</p>
							<!-- <a href="#" type="button" class="btn btn-sm btn-primary pull-right active">Use Code</a> -->
						</div>
						<!-- <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 text-right">
							<a href="javascript:;" class="btn btn-primary btn-fill">use code</a>
						</div> -->
					</div>
				</div>
			</div>
	
	</div>
	<div *ngIf="noPromoCode">
		<h4 class="mar-top-0 mar-bottom-sm">Sorry! Promo Code Not Found, Try Adding First</h4>
	</div>
</div>
<app-lazy-load [isLoaded]="isLoaded" [sectionName]="'saved promo codes'"></app-lazy-load>
<div class="addPromocodeDivSection">
	<div class="addPromoCode" ><!-- *ngIf="showPromocodeForm" -->
		<div class="row">
			<div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
				<form class="mar-top-sm floating-label promocodeForm" action="" (ngSubmit)="f.form.valid && addPromoCode(formData, f)" #f="ngForm" novalidate>
					<div id="txtSendTo" class="form-group hidden-xs" [ngClass]="{ 'has-error' : f.submitted && !promocode.valid }" >
						<div class="input-group">
							<label class="control-label" for="name">Enter Promo Code</label>
							<input  type="text" class="form-control" name="promocode" [(ngModel)]="formData.promocode" #promocode="ngModel" required>
							<span class="input-group-btn pad-left-xs">
								<button id="addpromocode" class="btn-primary theme-btn btn" type="submit">
									Save Promo Code <fa name="circle-o-notch" animation="spin" class="btnloader hidden"></fa>
								</button>
							</span>
							<div *ngIf="f.submitted && !promocode.valid" class="help-block" style="margin-right:205px">Promo Code is required</div>
							<div *ngIf="addPromocodeError != ''" style="color:#a94442;" class="help-block">{{ addPromocodeError }}</div>
						</div>
					</div>
					<div class="form-group visible-xs" [ngClass]="{ 'has-error' : f.submitted && !promocode.valid }" >
						<label class="control-label" for="name">Promo Code</label>
						<input type="text" class="form-control visible-xs" name="promocode" [(ngModel)]="formData.promocode" #promocode="ngModel" required>
						<div *ngIf="f.submitted && !promocode.valid" class="help-block">Promo Code is required</div>
						<div *ngIf="addPromocodeError != ''" style="color:#a94442;" class="help-block">{{ addPromocodeError }}</div>
					</div>
					<div class="row mar-top-xs visible-xs">
						<div class="col-xs-offset-2 col-xs-8">
							<button id="addpromocode" class="btn-primary btn-block theme-btn btn" type="submit">
								Save Promo Code <fa name="circle-o-notch" animation="spin" class="btnloader hidden"></fa>
							</button>
						</div>
					</div>
				</form>
			</div>
		</div>
	</div>
</div>