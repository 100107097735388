import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UserserviceService } from '../../services/userservice.service';
import * as $ from 'jquery';
@Component({
  selector: 'app-loyality-badge',
  templateUrl: './loyality-badge.component.html',
  styleUrls: ['./loyality-badge.component.scss'],
  providers: [UserserviceService]
})
export class LoyalityBadgeComponent implements OnInit {
  basic = 'assets/img/basic.svg';
  elite = 'assets/img/elite.svg';
  elite_plus = 'assets/img/elite_plus.svg';
  diamond = 'assets/img/diamond.svg';
  cross = 'assets/img/cross.svg';
  // discount_badge = 'assets/img/discount-badge.png';
  tierData: any;
  isTierLoaded: boolean = false;
  isTierExist: boolean = false;
  isTierMsg: string;
  @Input() showTies: boolean = true;
  @Input() containerClass: string = '';
  @Output() updateTierToHeader = new EventEmitter();
  @Output() updateTierNeighbour = new EventEmitter();
  constructor(private userServiceObj: UserserviceService) { }

  ngOnInit() {
    if (localStorage.getItem('is_loyalty') === "1" && this.showTies) {
      this.userServiceObj.GetTierInfo().subscribe(
        (res:any) => {
          if (res.data.tier_exists) {
            this.tierData = res.data.tier;
            this.isTierMsg = res.data.tier_description;
            this.isTierLoaded = true;
            this.updateTierToHeader.emit(true);
            this.updateTierNeighbour.emit(true);
            this.isTierExist = true;
          }
        }
      )
    }
  }
  closeBadge(){
    this.updateTierNeighbour.emit(false);
    this.isTierLoaded = false;
    localStorage.removeItem('showingLoyalityStrip');
    $('.campians_list').removeClass('isLoyalyBadge');
  }

  showBadge(){
    this.userServiceObj.GetTierInfo().subscribe(
      (res:any) => {
        if (res.data.tier_exists) {
          this.tierData = res.data.tier;
          this.isTierMsg = res.data.tier_description;
          this.isTierLoaded = true;
          this.updateTierToHeader.emit(true);
          this.updateTierNeighbour.emit(true);
          this.isTierExist = true;
        }
      }
    )
  }
}
