<!-- <div role="tabpanel" class="tab-pane fade active in">
  <div *ngIf="isLoaded">
    <div class="faq-content" [innerHTML]="sanatizeContent" (click)="getRouteforBookBtn($event)"></div>
  </div>  
   <app-lazy-load [isLoaded]="isLoaded" [sectionName]="''"></app-lazy-load>
</div> -->
<div role="tabpanel" class="tab-pane fade active in">
  <div *ngIf="isLoaded">
    <div class="faq-content" [innerHTML]="sanatizeContent" (click)="getRouteforBookBtn($event)"></div>
  </div>  
   <app-lazy-load [isLoaded]="isLoaded" [sectionName]="''"></app-lazy-load>
</div>

<!-- <style>
  .find-title{
    color:#ed8222;
    font-size: 24px !important;
    font-weight: 300;
    text-align: center;
    padding-top: 5px !important;
    margin-bottom: 10px;
  }
  .find-title span{
      font-size: 55px;
     text-transform: uppercase;
     display: block;
     font-weight: 500;
     margin-bottom: 7px;
     font-family: 'Bebas Neue', cursive !important;
    
    }
  </style>
  <div id="sep-offer" class="bg-white">
    <section class="logo bg-dark p-4 px-5 w-100" >
      <a href="../" target="_blank" rel="noopener noreferrer"><img src="../../assets/img/icon_logo.svg" height="40" alt="Icon Parking"/></a>
    </section>
    <section class="header1-sec">
      <div class="octImgFirst-dec">
        <img class="hidden-xs" src="https://staging2.iconparkingsystems.com/cms/wp-content/uploads/2021/12/1.jpg" alt="Iconic Things to do NYC" />
        <a target="_blank" href="https://staging2.iconparkingsystems.com/JAN2022E/apply" rel="noopener noreferrer">
        <img class="visible-xs" src="https://staging2.iconparkingsystems.com/cms/wp-content/uploads/2021/12/M1.jpg" alt="Iconic Things to do NYC" />
        </a>
     
      </div>
      <div class="bg-primary d-none p-3 w-100 "></div>

      <div class="d-none find-box hidden-xs bg-dark py-3 px-4">
        <div class="row align-items-center justify-content-center">
          <div class="col-sm-auto col-6"><a href=""><img src="../../assets/img/icon_logo.svg" class="img-responsive  w-100 hidden-xs" alt="Icon logo"/>
<img src="../../assets/img/icon_logo.svg " class=" visible-xs  " height="50px" alt="Icon logo"/></a></div>
        
          <div class="col-sm-auto col-6 text-white ">
        
              <h5 class="textShift" >BOOK IN <br class="visible-xs"> ADVANCE & SAVE</h5>
              <p class=""><a target="_blank" href="https://staging2.iconparkingsystems.com/JAN2022E/apply" class="btn hidden-xs  main-btn btn-fill btn-primary" style="max-width: 100%;" rel="noopener noreferrer">FIND PARKING</a></p>
           
          </div>
          <div class="col-sm-auto col-12 mt-3 text-white text-center visible-xs">
        
         
            <p class=""><a target="_blank" href="https://staging2.iconparkingsystems.com/JAN2022E/apply" class="btn btn-block  main-btn btn-fill btn-primary" rel="noopener noreferrer">FIND PARKING</a></p>
         
        </div>
        </div>
      </div>
  
      <div class="heading-title mt-4">
        <div class="container container-top">
          <div class="heading-center mb-5 ">
            <h2 class="find-title text-primary text-center" title="START THE NEW YEAR WITH NEW EXPERIENCES!"><span>
              
              START THE NEW YEAR WITH NEW EXPERIENCES!</span>
              Check out the latest & greatest on our <br class="hidden-xs"> "Iconic Things to Do" list for January.
  </h2>
          
           
          </div>
          <div class="row justify-content-center header1-box-2">
            <div class="col-md-5">
              <div class="save-head">
                <img class="img-responsive" src="../../assets/img/landing-pages/julyoffer/save-ahead.png" alt="Save Plan" />
              </div>
            </div>
            <div class="col-md-7">
              <div class="row mar-bottom-sm">
                <div class="col-xs-12 col-md-4 text-center">
                  <h1 class="off-value"><span>22%</span><sup>Off</sup></h1>
                  <div class="btn12"><a href="https://staging2.iconparkingsystems.com/JAN2022E/apply" target="_blank" rel="noopener noreferrer" type="button" class="btn btn-primary btn-fill btm-link12">Book Now</a></div>
                </div>
                <div class="col-xs-12 col-md-auto">
                  <div class="jly-small-box">
                    <h2>DAILY RATES</h2>
                    <h4>PROMO CODE: <span>JAN2022E</span></h4>
                    
                    <p>Book by January 3, 2022</p>
                    <p>Park through March 31, 2022</p>
                  </div>
                </div>
              </div>
              <div class="row mar-bottom-sm">
                <div class="col-xs-12 col-md-4 text-center">
                  <h1 class="off-value2"><small>Lock in Your Monthly<br>
                    Terms + Save</small></h1>
                  <div class="btn12"><a href="https://staging2.iconparkingsystems.com/JANUARY2022E/apply/?coupontype=monthly" target="_blank" rel="noopener noreferrer" type="button" class="btn btn-primary btn-fill btm-link12">Book Now</a></div>
                </div>
                  <div class="col-xs-12 col-md-auto mb-4">
                    <div class="jly-small-box">
                       <h2>MONTHLY RATES</h2>
                      <h4>PROMO CODE: <span>JANUARY2022E</span></h4>
                     
                     <p>Book by January 31, 2022</p>
                      
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section iv class="july-header2">
        <div class="octImgTwo">
          <span class="hidden-xs"><img class="img-responsive w-100" src="https://staging2.iconparkingsystems.com/cms/wp-content/uploads/2021/12/2.jpg"/></span>
          <span class="visible-xs">
            <a target="_blank" href="https://staging2.iconparkingsystems.com/JAN2022E/apply" rel="noopener noreferrer">
              <img class="img-responsive w-100" src="https://staging2.iconparkingsystems.com/cms/wp-content/uploads/2021/12/M2.jpg"/>
            </a>
            </span>
            
          </div>
        <div class="find-box bg-dark py-5 d-none">
          <div class="row align-items-center justify-content-center">
            <div class="col-sm-auto col-12"><a href=""><img src="../../assets/img/icon_logo.svg" class="img-responsive  w-100 hidden-xs" alt="Icon logo"/>
  <img src="../../assets/img/icon_logo.svg " class=" visible-xs  mx-auto " height="40px" alt="Icon logo"/></a></div>
           
           <div class="col-sm-auto col-11 text-white text-center">
               <h5  >BOOK IN ADVANCE & SAVE</h5>
            <p class=""><a target="_blank" href="https://staging2.iconparkingsystems.com/JAN2022E/apply" class="btn btn-round main-btn btn-fill btn-primary" style="max-width: 100%;" rel="noopener noreferrer">FIND PARKING</a></p>
             
            </div>
          </div>
        </div>
  
        <h2 class="find-title  text-primary text-center"><span>TASTE, LISTEN & SEE IT ALL!
        </span>Check out these events at multiple venues!</h2>
  
        <div class="container container-wrap">
          <div class="clearfix yr-20">
            <p class="pb-1 text-center ">
              <b>Winter Restaurant Week Jan. 18 – Feb. 13 </b><br> - Winter Jazz Fest Jan. 13 – Jan. 22</p>
            
          </div>
          <div class="linkSections mb-sm-4 pt-4">
            <h4 class="text-center mb-0">Broadway Week Jan. 18  </h4>
            <p class="text-center leadBox mb-0">Feb. 13</p>
            <div class="text-center">
                <a href="https://staging2.iconparkingsystems.com/JAN2022E/apply" target="_blank" rel="noopener noreferrer" type="button" class="btn btn-primary btn-fill btm-link12">Book Now</a>
            </div>
          
            <br class="visible-xs">
            <div class="row d-none justify-content-center linkRow align-items-center mb-4 ">
              
              <div class="col-lg-5 col-md-5 col-12">
                <div class="d-flex mb-4 mb-sm-0 ">
                  <div>
                    <h5>Crowne Garage </h5>
                    230 W 49th St<br>
                    <a href="https://staging2.iconparkingsystems.com/specials-promo/JAN2022E/apply?redirect=search&lat=40.7605165&long=-73.9852593&coupontype=daily&facility=CROWNE GARAGE LLC" target="_blank" rel="noopener noreferrer"><button class="btn btn-link linkBtnPrimary text-primary p-0">BOOK NOW >></button></a>
                    <br/>
                    <a href="https://staging2.iconparkingsystems.com/specials-promo/JANUARY2022E/apply?rredirect=search&lat=40.7605165&long=-73.9852593&coupontype=daily&facility=CROWNE GARAGE LLC" target="_blank" rel="noopener noreferrer"><button class="btn btn-link linkBtnPrimary text-primary p-0 ">BOOK NIGHT & WEEKEND >></button></a>
                  </div>
                </div>
              </div>
              <div class="col-lg-5 col-md-5 col-12">
                <div class="d-flex mb-4 mb-sm-0 ">
                  <div>
                    <h5>1330 Sixth Parking </h5>
                    65 W 53rd St<br>
                    <a href="https://staging2.iconparkingsystems.com/specials-promo/JAN2022E/apply?redirect=search&lat=40.7617007&long=-73.9785168&coupontype=daily&facility=1330 SIXTH PARKING LLC" target="_blank" rel="noopener noreferrer"><button class="btn btn-link linkBtnPrimary text-primary p-0">BOOK NOW >></button></a><br/>
                    <a href="https://staging2.iconparkingsystems.com/specials-promo/JANUARY2022E/apply?redirect=search&lat=40.7617007&long=-73.9785168&coupontype=daily&facility=1330 SIXTH PARKING LLC" target="_blank" rel="noopener noreferrer"><button class="btn btn-link linkBtnPrimary text-primary p-0 ">BOOK NIGHT & WEEKEND >></button></a>
                  </div>
                </div>
              </div>
            </div>
            
          </div>
          <div class="linkSections mb-4 pt-sm-2 d-none">
            <h4 class="text-center mb-0">Washington Square Park Tree Lighting </h4>
            <p class="text-center leadBox mb-4">Dec. 8 at 6pm</p>
            <br class="visible-xs">
            <div class="row justify-content-center linkRow align-items-center ">
              
              
              <div class="col-lg-5 col-md-5 col-12">
                <div class="d-flex mb-4 mb-sm-0 ">
                  <div>
                    <h5>Brevoort Parking </h5>
                    20 E 9th St<br>
                    <a href="https://staging2.iconparkingsystems.com/specials-promo/JAN2022E/apply?redirect=search&lat=40.732304&long=-73.994773&coupontype=daily&facility=BREVOORT PARKING LLC" target="_blank" rel="noopener noreferrer"><button class="btn btn-link linkBtnPrimary text-primary p-0">BOOK NOW >></button></a>
                    <br/>
                    <a href="https://staging2.iconparkingsystems.com/specials-promo/JANUARY2022E/apply?redirect=search&lat=40.732304&long=-73.994773&coupontype=daily&facility=BREVOORT PARKING LLC" target="_blank" rel="noopener noreferrer"><button class="btn btn-link linkBtnPrimary text-primary p-0 ">BOOK NIGHT & WEEKEND >></button></a>
                  </div>
                </div>
              </div>
              
              <div class="col-lg-5 col-md-5 col-12">
                <div class="d-flex mb-4 mb-sm-0 ">
                  <div>
                    
                    <h5>University 10 Parking </h5>
                    50 E 10th St<br>
                    <a href="https://staging2.iconparkingsystems.com/specials-promo/JAN2022E/apply?redirect=search&lat=40.7321575&long=-73.9924831&coupontype=daily&facility=UNIVERSITY 10 PARKING LLC" target="_blank" rel="noopener noreferrer"><button class="btn btn-link linkBtnPrimary text-primary p-0">BOOK NOW >></button></a>
                    <br/>
                    <a href="https://staging2.iconparkingsystems.com/specials-promo/JANUARY2022E/apply?redirect=search&llat=40.7321575&long=-73.9924831&coupontype=daily&facility=UNIVERSITY 10 PARKING LLC" target="_blank" rel="noopener noreferrer"><button class="btn btn-link linkBtnPrimary text-primary p-0 ">BOOK NIGHT & WEEKEND >></button></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="linkSections mb-sm-4 pt-4 d-none">
            <h4 class="text-center mb-4">Hanukkah Menorah Lightings every evening of the holiday  </h4>
            <p class="text-center leadBox mb-4">at Grand Army Plaza Midtown and Grand Army Plaza</p>
            <br class="visible-xs">
            <div class="row justify-content-center linkRow align-items-center">
              
              <div class="col-lg-5 col-md-5 col-12">
                <div class="d-flex mb-4 mb-sm-0 ">
                  <div>
                    <h5>59 and 5th Parking </h5>
                    26 E 59th St
                    <br>
                    <a href="https://staging2.iconparkingsystems.com/specials-promo/JAN2022E/apply?redirect=search&lat=40.76376&long=-73.972054&coupontype=daily&facility=59 AND 5TH PARKING LLC" target="_blank" rel="noopener noreferrer"><button class="btn btn-link linkBtnPrimary text-primary p-0">BOOK NOW >></button></a>
                    <br/>
                    <a href="https://staging2.iconparkingsystems.com/specials-promo/JANUARY2022E/apply?redirect=search&lat=40.76376&long=-73.972054&coupontype=daily&facility=59 AND 5TH PARKING LLC" target="_blank" rel="noopener noreferrer"><button class="btn btn-link linkBtnPrimary text-primary p-0 ">BOOK NIGHT & WEEKEND >></button></a>
                  </div>
                </div>
              </div>
              <div class="col-lg-5 col-md-5 col-12">
                <div class="d-flex mb-4 mb-sm-0 ">
                  <div>
                    <h5>Quik Park 365 Management</h5>
                    141 2nd St
                    <br>
                    <a href="https://staging2.iconparkingsystems.com/specials-promo/JAN2022E/apply?redirect=search&lat=40.6772628&long=-73.9904669&coupontype=daily&facility=QUIK PARK 365 MANAGEMENT LLC" target="_blank" rel="noopener noreferrer"><button class="btn btn-link linkBtnPrimary text-primary p-0">BOOK NOW >></button></a>
                    <br/>
                    <a href="https://staging2.iconparkingsystems.com/specials-promo/JANUARY2022E/apply?redirect=search&lat=40.6772628&long=-73.9904669&coupontype=daily&facility=QUIK PARK 365 MANAGEMENT LLC" target="_blank" rel="noopener noreferrer"><button class="btn btn-link linkBtnPrimary text-primary p-0 ">BOOK NIGHT & WEEKEND >></button></a>
                  </div>
                </div>
              </div>
              
            </div>
          </div>
          <div class="linkSections mb-5 pb-2 pt-sm-1 d-none">
            <h4 class="text-center mb-4">5th Ave Holiday Window displays</h4>
            
            <br class="visible-xs">
            <div class="row justify-content-center linkRow align-items-center">
              
              
              <div class="col-lg-5 col-md-5 col-12">
                <div class="d-flex mb-4 mb-sm-0 ">
                  <div>
                    <h5>Metropolitan 51 Parking </h5>
                    136-166 E 51st St<br>
                    <a href="https://staging2.iconparkingsystems.com/specials-promo/JAN2022E/apply?redirect=search&lat=40.756566&long=-73.971063&coupontype=daily&facility=METROPOLITAN 51 PARKING LLC" target="_blank" rel="noopener noreferrer"><button class="btn btn-link linkBtnPrimary text-primary p-0">BOOK NOW >></button></a>
                    <br/>
                    <a href="https://staging2.iconparkingsystems.com/specials-promo/JANUARY2022E/apply?redirect=search&lat=40.756566&long=-73.971063&coupontype=daily&facility=METROPOLITAN 51 PARKING LLC" target="_blank" rel="noopener noreferrer"><button class="btn btn-link linkBtnPrimary text-primary p-0 ">BOOK NIGHT & WEEKEND >></button></a>
                  </div>
                </div>
              </div>
          
              
              <div class="col-lg-5 col-md-5 col-12 ">
                <div class="d-flex mb-4 mb-sm-0 ">
                  <div>
                    <h5>575 Lex Garage </h5>
                    165 E 51st St<br>
                    <a href="https://staging2.iconparkingsystems.com/specials-promo/JAN2022E/apply?redirect=search&lat=40.7569377&long=-73.9712207&coupontype=daily&facility=575 LEX GARAGE LLC" target="_blank" rel="noopener noreferrer"><button class="btn btn-link linkBtnPrimary text-primary p-0">BOOK NOW >></button></a>
                    <br/>
                    <a href="https://staging2.iconparkingsystems.com/specials-promo/JANUARY2022E/apply?redirect=search&lat=40.7569377&long=-73.9712207&coupontype=daily&facility=575 LEX GARAGE LLC" target="_blank" rel="noopener noreferrer"><button class="btn btn-link linkBtnPrimary text-primary p-0 ">BOOK NIGHT & WEEKEND >></button></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
        </div>
      </section>
  
      <section class="outdoorShows mb-4">
        <div class="octImgThird">
          <span class="hidden-xs"><img class="img-responsive w-100" src="https://staging2.iconparkingsystems.com/cms/wp-content/uploads/2021/12/3.jpg"/></span>
          <span class="visible-xs">
            <a target="_blank" href="https://staging2.iconparkingsystems.com/JAN2022E/apply" rel="noopener noreferrer">
              <img class="img-responsive w-100" src="https://staging2.iconparkingsystems.com/cms/wp-content/uploads/2021/12/M3.jpg"/>
            </a>
          </span>
        
        </div>
  
        <div class="find-box bg-dark py-5 d-none">
          <div class="row align-items-center justify-content-center">
            <div class="col-sm-auto col-12"><a href=""><img src="../../assets/img/icon_logo.svg" class="img-responsive w-100 hidden-xs" alt="Icon logo"/>
</a></div>
            <div class="col-sm-auto col-11 text-white text-center">   
          
                <h5>BOOK IN ADVANCE & SAVE</h5>
                <p class=""><a target="_blank" href="https://staging2.iconparkingsystems.com/JAN2022E/apply" class="btn btn-round main-btn btn-fill btn-primary" style="max-width: 100%;" rel="noopener noreferrer">FIND PARKING</a></p>
             
            </div>
          </div>
      
       
  </div>
 
 <div class="bg-primary visible-xs p-3 w-100 mb-4 "></div>
  
        <h2  class="find-title text-primary text-center"><span>FUN FOR EVERYONE!
        </span>WHERE TO PLAY & PARK THIS MONTH!</h2>

        <div class="container container-wrap">
          <div class="midSection mb-3 pb-3">
          
            
          </div>
          <div class="clearfix mt-4 mb-0 curtain-box ">
            <h4 class="text-center mb-4">Rockefeller Christmas Tree</h4>
            <p class="text-center leadBox mb-3">Now – Jan. 10</p>
            <br class="visible-xs">
          </div>
          

          <div class="linkSections mb-4 pt-sm-1 pb-1">
           
            <div class="row justify-content-center linkRow align-items-center ">
              
              <div class="col-lg-5 col-md-5 col-12">
                <div class="d-flex mb-4 mb-sm-0 ">
                  <div>
                    <h5 class="nowrap">1330 Sixth Parking </h5>
                    65 W 53rd St<br>
                    <a href="https://staging2.iconparkingsystems.com/specials-promo/JAN2022E/apply?redirect=search&lat=40.7617007&long=-73.9785168&coupontype=daily&facility=1330 SIXTH PARKING LLC" target="_blank" rel="noopener noreferrer"><button class="btn btn-link linkBtnPrimary text-primary p-0">BOOK NOW >></button></a>
                    
                  </div>
                </div>
              </div>
              <div class="col-lg-5 col-md-5 col-12">
                <div class="d-flex mb-4 mb-sm-0 ">
                  <div>
                    <h5>Park-Serv </h5>
                    140-166 W 53rd St<br>
                    <a href="https://staging2.iconparkingsystems.com/specials-promo/JAN2022E/apply?redirect=search&lat=40.762452&long=-73.9808835&coupontype=daily&facility=PARK-SERV LLC" target="_blank" rel="noopener noreferrer"><button class="btn btn-link linkBtnPrimary text-primary p-0">BOOK NOW >></button></a>
                    
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="clearfix mt-4 mb-0 curtain-box ">
            <h4 class="text-center mb-0">Disney on Ice Jan. 13- Jan. 17 at<br> Prudential Center and Jan. 19</h4>
            <p class="text-center leadBox mb-4">Jan. 23 at Barclays Center</p>
            <br class="visible-xs">
          </div>


          <div class="linkSections mb-sm-4 pt-sm-4">
            <div class="row justify-content-center linkRow align-items-center ">
              <div class="col-lg-5 col-md-5 col-12">
                <div class="d-flex mb-4 mb-sm-0 ">
                  <div>
                    <h5>Renaissance Parking  </h5>
                    111 Mulberry St<br>
                    <a href="https://staging2.iconparkingsystems.com/specials-promo/JAN2022E/apply?redirect=search&lat=40.7350269&long=-74.1693008&coupontype=daily&facility=RENAISSANCE PARKING LLC" target="_blank" rel="noopener noreferrer"><button class="btn btn-link linkBtnPrimary text-primary p-0">BOOK NOW >></button></a>
                    
                  </div>
                </div>
              </div>
              <div class="col-lg-5 col-md-5 col-12">
                <div class="d-flex mb-4 mb-sm-0 ">
                  
                  <div>
                    <h5>Quik Park SCH Garage</h5>
                    225 Schermerhorn St<br>
                    <a href="https://staging2.iconparkingsystems.com/specials-promo/JAN2022E/apply?redirect=search&lat=40.6887106&long=-73.9850111&coupontype=daily&facility=QUIK PARK SCH GARAGE LLC" target="_blank" rel="noopener noreferrer"><button class="btn btn-link linkBtnPrimary text-primary p-0">BOOK NOW >></button></a>
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="linkSections mb-4 pt-sm-1 pb-1">
            <h4 class="text-center mb-0">New York National Boat Show Jan. 26  </h4>
            <p class="text-center leadBox mb-4">Jan. 30 at Javitz Center</p>
            <br class="visible-xs">
            <div class="row justify-content-center linkRow align-items-center ">
              
              <div class="col-lg-5 col-md-5 col-12">
                <div class="d-flex mb-4 mb-sm-0 ">
                  <div>
                    <h5 class="nowrap">JDS Parking</h5>
                    501 W 41st St<br>
                    <a href="https://staging2.iconparkingsystems.com/specials-promo/JAN2022E/apply?redirect=search&lat=40.759318&long=-73.996637&coupontype=daily&facility=JDS PARKING LLC" target="_blank" rel="noopener noreferrer"><button class="btn btn-link linkBtnPrimary text-primary p-0">BOOK NOW >></button></a>
                    
                  </div>
                </div>
              </div>
              <div class="col-lg-5 col-md-5 col-12">
                <div class="d-flex mb-4 mb-sm-0 ">
                  <div>
                    <h5>ACCESS 37 Parking</h5>
                    404 W 37th St<br>
                    <a href="https://staging2.iconparkingsystems.com/specials-promo/JAN2022E/apply?redirect=search&lat=40.7553909&long=-73.9951217&coupontype=daily&facility=ACCESS 37 PARKING LLC" target="_blank" rel="noopener noreferrer"><button class="btn btn-link linkBtnPrimary text-primary p-0">BOOK NOW >></button></a>
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="linkSections mb-4 pt-sm-1 pb-1">
            <h4 class="text-center mb-0">Big Apple Circus Now</h4>
            <p class="text-center leadBox mb-4">Jan. 30 at Lincoln Center</p>
            <br class="visible-xs">
            <div class="row justify-content-center linkRow align-items-center ">
              
              <div class="col-lg-5 col-md-5 col-12">
                <div class="d-flex mb-4 mb-sm-0 ">
                  <div>
                    <h5>Broadway & 64th Parking</h5>
                    20 W 64th St<br>
                    <a href="https://staging2.iconparkingsystems.com/specials-promo/JAN2022E/apply?redirect=search&lat=40.750619&long=-73.981704&coupontype=daily&facility=REGAL PARKING LLC" target="_blank" rel="noopener noreferrer"><button class="btn btn-link linkBtnPrimary text-primary p-0">BOOK </button></a>
                    <br/>
                    
                  </div>
                </div>
              </div>
              <div class="col-lg-5 col-md-5 col-12">
                <div class="d-flex mb-4 mb-sm-0 ">
                  <div>
                    <h5>165 W 66th St Parking</h5>
                    165 W 66th St<br>
                    <a href="https://staging2.iconparkingsystems.com/specials-promo/AN2022E/apply?redirect=search&lat=40.7746637&long=-73.9835317&coupontype=daily&facility=165 WEST 66TH STREET PARKING LLC" target="_blank" rel="noopener noreferrer"><button class="btn btn-link linkBtnPrimary text-primary p-0">BOOK NOW >></button></a>
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
  
  
      <section class="musicevents">
   <div class="octImgFourth">
          <span class="hidden-xs"><img class="img-responsive w-100" src="https://staging2.iconparkingsystems.com/cms/wp-content/uploads/2021/12/4.jpg"/></span>
          <span class="visible-xs">
            <a target="_blank" href="https://staging2.iconparkingsystems.com/JAN2022E/apply" rel="noopener noreferrer">
              <img class="img-responsive w-100" src="https://staging2.iconparkingsystems.com/cms/wp-content/uploads/2021/12/M4.jpg"/>
            </a>
          </span>
        </div>

        <div class="find-box bg-dark py-5 d-none">
          <div class="row align-items-center justify-content-center">
            <div class="col-sm-auto col-12"><a href=""><img src="../../assets/img/icon_logo.svg" class="img-responsive  w-100 hidden-xs" alt="Icon logo"/>
  <img src="../../assets/img/icon_logo.svg " class=" visible-xs mx-auto " height="50px" alt="Icon logo"/></a></div>
            <div class="col-sm-auto col-11 text-white text-center">
          
                <h5  >BOOK IN ADVANCE & SAVE</h5>
                <p class=""><a target="_blank" href="https://staging2.iconparkingsystems.com/JAN2022E/apply" class="btn main-btn btn-round btn-fill btn-primary" style="max-width: 100%;" rel="noopener noreferrer">FIND PARKING</a></p>
             
            </div>
          </div>
        </div>
  
       <h2  class="find-title text-primary text-center"><span>ELEVATE YOURSELF TO A HIGHER LEVEL!
        </span>Unmatched & Iconic Views at these locations.</h2>
  
        <div class="container container-wrap">
          <div class="clearfix mt-4 mb-4 curtain-box">
            <h4 class="text-center mb-4">The edge</h4>
            
            <br class="visible-xs">
          </div>
          <div class="linkSections mb-4 pt-sm-2">
            <div class="row justify-content-center linkRow align-items-center ">
              
              
              <div class="col-lg-5 col-md-5 col-12">
                <div class="d-flex mb-4 mb-sm-0 ">
                  <div>
                    <h5>Chelsea 29 Parking </h5>
                    560 W 29th St<br>
                    <a href="https://staging2.iconparkingsystems.com/specials-promo/JAN2022E/apply?redirect=search&lat=40.752384&long=-74.00396&coupontype=daily&facility=CHELSEA 29 PARKING LLC" target="_blank" rel="noopener noreferrer"><button class="btn btn-link linkBtnPrimary text-primary p-0">BOOK NOW >></button></a>
                    
                  </div>
                </div>
              </div>
              <div class="col-lg-5 col-md-5 col-12">
                <div class="d-flex mb-4 mb-sm-0 ">
                  <div>
                    <h5>Quik Park West 23rd St Garage</h5>
                    423 W 23rd St<br>
                    <a href="https://staging2.iconparkingsystems.com/specials-promo/JAN2022E/apply?redirect=search&lat=40.7469153&long=-74.0019797&coupontype=daily&facility=QUIK PARK WEST 23RD STREET GARAGE LLC" target="_blank" rel="noopener noreferrer"><button class="btn btn-link linkBtnPrimary text-primary p-0">BOOK NOW >></button></a>
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="linkSections mb-4 pt-sm-1 ">
            <h4 class="text-center mb-4">One world observatory </h4>
            
            <br class="visible-xs">
            <div class="row justify-content-center linkRow align-items-center ">
              
              <div class="col-lg-5 col-md-5 col-12 ">
                <div class="d-flex mb-4 mb-sm-0 ">
                  
                  <div>
                    <h5>Church St Parking </h5>
                    54 Murray St<br>
                    
                    <a href="https://staging2.iconparkingsystems.com/specials-promo/JAN2022E/apply?redirect=search&lat=40.7142822&long=-74.0099148&coupontype=daily&facility=CHURCH STREET PARKING LLC" target="_blank" rel="noopener noreferrer"><button class="btn btn-link linkBtnPrimary text-primary p-0">BOOK NOW >></button></a>
                    
                  </div>
                </div>
           
              
            </div>
            <div class="col-lg-5 col-md-5 col-12 ">
                <div class="d-flex mb-4 mb-sm-0 ">
                  
                  <div>
                   
                   <h5>Barclay Street Parking</h5>
                   21 Barclay St<br> 
                    <a href="https://staging2.iconparkingsystems.com/specials-promo/JAN2022E/apply?redirect=search&lat=40.712525&long=-74.008754&coupontype=daily&facility=BARCLAY STREET PARKING LLC" target="_blank" rel="noopener noreferrer"><button class="btn btn-link linkBtnPrimary text-primary p-0">BOOK NOW >></button></a>
                    
                  </div>
                </div>
              </div>
              
            </div>
          </div>
          <div class="linkSections mb-4 pt-sm-1">
            <h4 class="text-center mb-4">SUMMIT One Vanderbilt </h4>
            
            <br class="visible-xs">
            <div class="row justify-content-center linkRow align-items-center mb-4 pb-4 ">
              
              
              <div class="col-lg-5 col-md-5 col-12">
                <div class="d-flex mb-4 mb-sm-0 ">
                  
                  <div>
                    <h5>Regal Parking</h5>
                    11 E 38th St<br>
                    <a href="https://staging2.iconparkingsystems.com/specials-promo/JAN2022E/apply?redirect=search&lat=40.750619&long=-73.981704&coupontype=daily&facility=REGAL PARKING LLC" target="_blank" rel="noopener noreferrer"><button class="btn btn-link linkBtnPrimary text-primary p-0">BOOK NOW >></button></a>
                    
                  </div>
                </div>
              </div>
              
              <div class="col-lg-6 col-md-6 col-12">
                <div class="d-flex mb-4 mb-sm-0 ">
                 
                  <div>
                    
                    <h5>Affiliated Parking</h5>
                    20 E 39th St<br>
                    <a href="https://staging2.iconparkingsystems.com/specials-promo/JAN2022E/apply?redirect=search&lat=40.750596&long=-73.980314&coupontype=daily&facility=AFFILIATED PARKING LLC" target="_blank" rel="noopener noreferrer"><button class="btn btn-link linkBtnPrimary text-primary p-0">BOOK NOW >></button></a>
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="linkSections mb-4 pt-sm-1">
            <h4 class="text-center mb-4">Top of the Rock</h4>
            
            <br class="visible-xs">
            <div class="row justify-content-center linkRow align-items-center mb-4 pb-4 ">
              
              
              <div class="col-lg-5 col-md-5 col-12">
                <div class="d-flex mb-4 mb-sm-0 ">
                  
                  <div>
                    <h5>Crowne Garage  </h5>231 W 48th St<br>
                    <a href="https://staging2.iconparkingsystems.com/specials-promo/JAN2022E/apply?redirect=search&lat=40.7605165&long=-73.9852593&coupontype=daily&facility=CROWNE GARAGE LLC" target="_blank" rel="noopener noreferrer"><button class="btn btn-link linkBtnPrimary text-primary p-0">BOOK NOW >></button></a>
                    
                  </div>
                </div>
              </div>
              
              <div class="col-lg-5 col-md-5 col-12">
                <div class="d-flex mb-4 mb-sm-0 ">
                 
                  <div>
                    
                    <h5>250 W 50th Parking</h5>
                    250 W 50th St<br>
                    <a href="https://staging2.iconparkingsystems.com/specials-promo/JAN2022E/apply?redirect=search&lat=40.7618762&long=-73.9854443&coupontype=daily&facility=250 W. 50TH PARKING LL" target="_blank" rel="noopener noreferrer"><button class="btn btn-link linkBtnPrimary text-primary p-0">BOOK NOW >></button></a>
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      
      <section class="OUTDOOREVENTS">
        <div class="octImgFifth">
          <span class="hidden-xs"><img class="img-responsive w-100" src="https://staging2.iconparkingsystems.com/cms/wp-content/uploads/2021/12/5.jpg"/></span>
          <span class="visible-xs">
            <a target="_blank" href="https://staging2.iconparkingsystems.com/JAN2022E/apply" rel="noopener noreferrer">
            <img src="https://staging2.iconparkingsystems.com/cms/wp-content/uploads/2021/12/M5.jpg"/>
          </a>
          </span>
        </div>
        <div class="find-box bg-dark py-5 px-4 hidden-xs">
          <div class="row align-items-center justify-content-center">
            <div class="col-sm-auto col-6"><a href=""><img src="../../assets/img/icon_logo.svg" class="img-responsive mx-auto w-100 hidden-xs" alt="Icon logo"/>
            <img src="../../assets/img/icon_logo.svg " class=" visible-xs  " height="50px" alt="Icon logo"/></a></div>
          
            <div class="col-sm-auto col-6 text-white ">
          
                <h5 class="textShift" >BOOK IN <br class="visible-xs">ADVANCE & SAVE</h5>
                <p class=""><a target="_blank" href="https://staging2.iconparkingsystems.com/JAN2022E/apply" class="btn hidden-xs  main-btn btn-fill btn-primary" style="max-width: 100%;" rel="noopener noreferrer">FIND PARKING</a></p>
             
            </div>
            <div class="col-sm-auto col-12 mt-3 text-white text-center visible-xs">
          
           
              <p class=""><a target="_blank" href="https://staging2.iconparkingsystems.com/JAN2022E/apply" class="btn btn-block  main-btn btn-fill btn-primary" rel="noopener noreferrer">FIND PARKING</a></p>
           
          </div>
          </div>
        </div>
        
  <h2 class="find-title text-primary text-center"><span>INVOLVE ALL YOUR SENSES!
        </span>Make memories & parking easy at these locations.</h2>
        <div class="container container-wrap">
        
          <div class="linkSections mb-4 pt-sm-2">
            <h4 class="text-center mb-4">230 Fifth Igloo Bar</h4>
             
            <br class="visible-xs">
            <div class="row justify-content-center linkRow align-items-center ">
              
              
              <div class="col-lg-5 col-md-5 col-12">
                <div class="d-flex mb-4 mb-sm-0 ">
                  
                  <div>
                    <h5>Floral 26 Parking</h5>
                    38 W 26th St<br>
                    <a href="https://staging2.iconparkingsystems.com/specials-promo/JAN2022E/apply?redirect=search&lat=40.744447&long=-73.990816&coupontype=daily&facility=FLORAL 26 PARKING LLC" target="_blank" rel="noopener noreferrer"><button class="btn btn-link linkBtnPrimary text-primary p-0">BOOK NOW >></button></a>
                    
                  </div>
                </div>
              </div>
              <div class="col-lg-5 col-md-5 col-12">
                <div class="d-flex mb-4 mb-sm-0 ">
                  
                  <div>
                    <h5>22nd and Sixth parking</h5>
                    51 W 22nd St<br>
                    <a href="https://staging2.iconparkingsystems.com/specials-promo/JAN2022E/apply?redirect=search&lat=40.7419875&long=-73.9925069&coupontype=daily&facility=22ND AND SIXTH PARKING LLC" target="_blank" rel="noopener noreferrer"><button class="btn btn-link linkBtnPrimary text-primary p-0">BOOK NOW >></button></a>
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
          
          <div class="linkSections mb-4 pt-sm-1">
            <h4 class="text-center mb-4">Pier 17 Rooftop Cabins</h4>
    
            <br class="visible-xs">
            <div class="row justify-content-center linkRow align-items-center ">
              
              
              <div class="col-lg-5 col-md-5 col-12">
                <div class="d-flex mb-4 mb-sm-0 ">
                  <div class="nowrap">
                    
                    <h5>Seaport Parking A</h5>
                    299 Pearl St<br>
                    <a href="https://staging2.iconparkingsystems.com/specials-promo/JAN2022E/apply?redirect=search&lat=40.708614&long=-74.003038&coupontype=daily&facility=SEAPORT PARKING LLC A" target="_blank" rel="noopener noreferrer"><button class="btn btn-link linkBtnPrimary text-primary p-0">BOOK NOW >></button></a>
                    
                </div>
              </div></div>
              <div class="col-lg-5 col-md-5 col-12">
                <div class="d-flex mb-4 mb-sm-0 ">
                  <div class="nowrap">
                    
                    <h5>Seaport Parking B</h5>
                    70 Gold St<br>
                    <a href="https://staging2.iconparkingsystems.com/specials-promo/JAN2022E/apply?redirect=search&lat=40.709905&long=-74.004288&coupontype=daily&facility=SEAPORT PARKING LLC B" target="_blank" rel="noopener noreferrer"><button class="btn btn-link linkBtnPrimary text-primary p-0">BOOK NOW >></button></a>
                    
                  </div>
                </div>
              </div>
          </div>
        </div>
          <div class="linkSections mb-4 pt-sm-1">
            <h4 class="text-center mb-4">Mr. Purple Winter Chalet</h4>
        
            <br class="visible-xs">
            <div class="row justify-content-center linkRow align-items-center ">
              
              <div class="col-lg-5 col-md-5 col-12">
                <div class="d-flex mb-4 mb-sm-0 ">
                  
                  <div>
                    <h5>Bowery First Parking</h5>
                    11 E 1st St<br>
                    <a href="https://staging2.iconparkingsystems.com/specials-promo/JAN2022E/apply?redirect=search&lat=40.7243242&long=-73.9911912&coupontype=daily&facility=BOWERY FIRST PARKING LLC" target="_blank" rel="noopener noreferrer"><button class="btn btn-link linkBtnPrimary text-primary p-0">BOOK NOW >></button></a>
                    
                  </div>
                </div>
              </div>
              <div class="col-lg-5 col-md-5 col-12">
                <div class="d-flex mb-4 mb-sm-0 ">
                  
                  <div>
                    <h5>East Village Parking</h5>
                    22 E 1st St<br>
                    <a href="https://staging2.iconparkingsystems.com/specials-promo/JAN2022E/apply?redirect=search&lat=40.7245153&long=-73.9910858&coupontype=daily&facility=EAST VILLAGE PARKING LLC" target="_blank" rel="noopener noreferrer"><button class="btn btn-link linkBtnPrimary text-primary p-0">BOOK NOW >></button></a>
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="OUTDOOREVENTS d-none">
        <div class="octImgFifth">
          <span class="hidden-xs"><img class="img-responsive w-100" src="../../../assets/img/landing-pages/decoffer/nyc-holiday-staycation.png"/></span>
          <span class="visible-xs">
            <a target="_blank" href="https://staging2.iconparkingsystems.com/JAN2022E/apply" rel="noopener noreferrer">
            <img src="../../../assets/img/landing-pages/decoffer/nyc-holiday-staycation-mob.png"/>
          </a>
          </span>
        </div>
        <div class="find-box bg-dark py-5 px-4 hidden-xs">
          <div class="row align-items-center justify-content-center">
            <div class="col-sm-auto col-6"><a href=""><img src="../../assets/img/icon_logo.svg" class="img-responsive mx-auto w-100 hidden-xs" alt="Icon logo"/>
            <img src="../../assets/img/icon_logo.svg " class=" visible-xs  " height="50px" alt="Icon logo"/></a></div>
          
            <div class="col-sm-auto col-6 text-white ">
          
                <h5 class="textShift" >BOOK IN <br class="visible-xs">ADVANCE & SAVE</h5>
                <p class=""><a target="_blank" href="https://staging2.iconparkingsystems.com/JAN2022E/apply" class="btn hidden-xs  main-btn btn-fill btn-primary" style="max-width: 100%;" rel="noopener noreferrer">FIND PARKING</a></p>
             
            </div>
            <div class="col-sm-auto col-12 mt-3 text-white text-center visible-xs">
          
           
              <p class=""><a target="_blank" href="https://staging2.iconparkingsystems.com/JAN2022E/apply" class="btn btn-block  main-btn btn-fill btn-primary" rel="noopener noreferrer">FIND PARKING</a></p>
           
          </div>
          </div>
        </div>
        
  <h2 class="find-title text-primary text-center"><span>STAY LOCAL & GET COZY IN NYC!
        </span>Experience all the fun and park yourself at our favorite hotels.</h2>
        <div class="container container-wrap">
        
          <div class="linkSections mb-4 pt-sm-2">
            <h4 class="text-center mb-4">The Westin New York Times Square </h4>
      
            <br class="visible-xs">
            <div class="row justify-content-center linkRow align-items-center ">
              
              
              <div class="col-lg-5 col-md-5 col-12">
                <div class="d-flex mb-4 mb-sm-0 ">
                  
                  <div>
                    <h5>Advance Parking  </h5>
                    249-253 W 43rd St<br>
                    <a href="https://staging2.iconparkingsystems.com/specials-promo/JAN2022E/apply?redirect=search&lat=40.7576488&long=-73.9883074&coupontype=daily&facility=ADVANCE PARKING LLC" target="_blank" rel="noopener noreferrer"><button class="btn btn-link linkBtnPrimary text-primary p-0">BOOK NOW >></button></a>
                    <br/>
                    <a href="https://staging2.iconparkingsystems.com/specials-promo/JANUARY2022E/apply?redirect=search&lat=40.7576488&long=-73.9883074&coupontype=daily&facility=ADVANCE PARKING LLC" target="_blank" rel="noopener noreferrer"><button class="btn btn-link linkBtnPrimary text-primary p-0 ">BOOK NIGHT & WEEKEND >></button></a>
                  </div>
                </div>
              </div>
              <div class="col-lg-5 col-md-5 col-12">
                <div class="d-flex mb-4 mb-sm-0 ">
                  
                  <div>
                    <h5>Crowne Garage  </h5>
                    230 W 49th St<br>
                    <a href="https://staging2.iconparkingsystems.com/specials-promo/JAN2022E/apply?redirect=search&lat=40.7605165&long=-73.9852593&coupontype=daily&facility=CROWNE GARAGE LLC" target="_blank" rel="noopener noreferrer"><button class="btn btn-link linkBtnPrimary text-primary p-0">BOOK NOW >></button></a>
                    <br/>
                    <a href="https://staging2.iconparkingsystems.com/specials-promo/JANUARY2022E/apply?redirect=search&lat=40.7605165&long=-73.9852593&coupontype=daily&facility=CROWNE GARAGE LLC" target="_blank" rel="noopener noreferrer"><button class="btn btn-link linkBtnPrimary text-primary p-0 ">BOOK NIGHT & WEEKEND >></button></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
          <div class="linkSections mb-4 pt-sm-1">
            <h4 class="text-center mb-4">The Peninsula New York </h4>
           
            <br class="visible-xs">
            <div class="row justify-content-center linkRow align-items-center ">
              
              
              <div class="col-lg-5 col-md-5 col-12">
                <div class="d-flex mb-4 mb-sm-0 ">
                  <div class="nowrap">
                    
                    <h5>59 and 5th Parking </h5>
                    26 E 59th St<br>
                    <a href="https://staging2.iconparkingsystems.com/specials-promo/JAN2022E/apply?redirect=search&lat=40.76376&long=-73.972054&coupontype=daily&facility=59 AND 5TH PARKING LLC" target="_blank" rel="noopener noreferrer"><button class="btn btn-link linkBtnPrimary text-primary p-0">BOOK NOW >></button></a>
                    <br/>
                    <a href="https://staging2.iconparkingsystems.com/specials-promo/JANUARY2022E/apply?redirect=search&lat=40.76376&long=-73.972054&coupontype=daily&facility=59 AND 5TH PARKING LLC" target="_blank" rel="noopener noreferrer"><button class="btn btn-link linkBtnPrimary text-primary p-0 ">BOOK NIGHT & WEEKEND >></button></a>
                </div>
              </div></div>
              <div class="col-lg-5 col-md-5 col-12">
                <div class="d-flex mb-4 mb-sm-0 ">
                  <div class="nowrap">
                    
                    <h5>58W58 Parking  </h5>
                    58 W 58th St<br>
                    <a href="https://staging2.iconparkingsystems.com/specials-promo/JAN2022E/apply?redirect=search&lat=40.7646488&long=-73.9759943&coupontype=daily&facility=58W58 PARKING LLC" target="_blank" rel="noopener noreferrer"><button class="btn btn-link linkBtnPrimary text-primary p-0">BOOK NOW >></button></a>
                    <br/>
                    <a href="https://staging2.iconparkingsystems.com/specials-promo/JANUARY2022E/apply?redirect=search&lat=40.7646488&long=-73.9759943&coupontype=daily&facility=58W58 PARKING LLC" target="_blank" rel="noopener noreferrer"><button class="btn btn-link linkBtnPrimary text-primary p-0 ">BOOK NIGHT & WEEKEND >></button></a>
                  </div>
                </div>
              </div>
          </div>
        </div>
          <div class="linkSections mb-4 pt-sm-1">
            <h4 class="text-center mb-4">The Knickerbocker </h4>
            
            <br class="visible-xs">
            <div class="row justify-content-center linkRow align-items-center ">
              
              <div class="col-lg-5 col-md-5 col-12">
                <div class="d-flex mb-4 mb-sm-0 ">
                  
                  <div>
                    <h5>Global Parking  </h5>
                    143-145 W 40th St<br>
                    <a href="https://staging2.iconparkingsystems.com/specials-promo/JAN2022E/apply?redirect=search&lat=40.754682&long=-73.9873031&coupontype=daily&facility=GLOBAL PARKING LLC" target="_blank" rel="noopener noreferrer"><button class="btn btn-link linkBtnPrimary text-primary p-0">BOOK NOW >></button></a>
                    <br/>
                    <a href="https://staging2.iconparkingsystems.com/specials-promo/JANUARY2022E/apply?redirect=search&lat=40.754682&long=-73.9873031&coupontype=daily&facility=GLOBAL PARKING LLC" target="_blank" rel="noopener noreferrer"><button class="btn btn-link linkBtnPrimary text-primary p-0 ">BOOK NIGHT & WEEKEND >></button></a>
                  </div>
                </div>
              </div>
              <div class="col-lg-5 col-md-5 col-12">
                <div class="d-flex mb-4 mb-sm-0 ">
                  
                  <div>
                    <h5>Westside 42 Parking  </h5>
                    350 W 42nd St<br>
                    <a href="https://staging2.iconparkingsystems.com/specials-promo/JAN2022E/apply?redirect=search&lat=40.7581165&long=-73.9921079&coupontype=daily&facility=WESTSIDE 42 PARKING LLC" target="_blank" rel="noopener noreferrer"><button class="btn btn-link linkBtnPrimary text-primary p-0">BOOK NOW >></button></a>
                    <br/>
                    <a href="https://staging2.iconparkingsystems.com/specials-promo/JANUARY2022E/apply?redirect=search&lat=40.7581165&long=-73.9921079&coupontype=daily&facility=WESTSIDE 42 PARKING LLC" target="_blank" rel="noopener noreferrer"><button class="btn btn-link linkBtnPrimary text-primary p-0 ">BOOK NIGHT & WEEKEND >></button></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="linkSections mb-4 pt-sm-1">
            <h4 class="text-center mb-4">The Standard Highline </h4>
           
            <br class="visible-xs">
            <div class="row justify-content-center linkRow align-items-center ">
              
              <div class="col-lg-5 col-md-5 col-12">
                <div class="d-flex mb-4 mb-sm-0 justify-content-center">
                  
                  <div>
                    <h5>111 Eighth Ave   </h5>
                    385 W 15th St<br>
                    <a href="https://staging2.iconparkingsystems.com/specials-promo/JAN2022E/apply?redirect=search&lat=40.741584&long=-74.004537&coupontype=daily&facility=111 EIGHTH AVENUE PARKING LLC" target="_blank" rel="noopener noreferrer"><button class="btn btn-link linkBtnPrimary text-primary p-0">BOOK NOW >></button></a>
                    <br/>
                    <a href="https://staging2.iconparkingsystems.com/specials-promo/JANUARY2022E/apply?redirect=search&lat=40.741584&long=-74.004537&coupontype=daily&facility=111 EIGHTH AVENUE PARKING LLC" target="_blank" rel="noopener noreferrer"><button class="btn btn-link linkBtnPrimary text-primary p-0 ">BOOK NIGHT & WEEKEND >></button></a>
                  </div>
                </div>
              </div>
              
            </div>
          </div>
        </div>
      </section>
  
      <section class="appStoreSections mt-0 mb-5 mt-sm-4  ">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-sm-5 col-7">
              <div class="row">
                <div class="col-auto icon-go-img pr-1">
                  <img class="img-responsive" src="../../assets/img/landing-pages/sepoffer/sep-icon-go.png" alt="app-icon">
                </div>
                <div class="col pl-0"><p><i>Let’s Journey<br>Together</i></p></div>
              </div>
              <div class="row">
                <div class="col-12 icon-go-text">
                  <h4>THE ICON GO APP</h4>
                  <h6>BOOK, PAY, PRE <br>CHECK-IN & PLAY</h6>
                  <a class="pr-sm-4 pr-1" href="https://itunes.apple.com/us/app/icon-parking/id394377820?mt=8" target="_blank" rel="noopener noreferrer"><img src="../../assets/img/landing-pages/sepoffer/to-do-app-icon.svg" alt="app_store"></a>  <a class="p-0" href="https://play.google.com/store/apps/details?id=com.iconparking&hl=en" target="_blank" rel="noopener noreferrer"><img src="../../assets/img/landing-pages/sepoffer/to-do-google-icon.svg" alt="play_store"></a>
                </div>
              </div>
            </div>
            <div class="col-sm-5 col-5 to-do-mob">
              <img class="img-responsive mob-image" src="../../assets/img/landing-pages/sepoffer/to-do-mob.png" alt="Mob-icon">
            </div>
          </div>
        </div>
      </section>
      <section class="bg-dark  text-white footerJulyOffer ">
        <div class="container">
          <p class="visible-xs"><br><br></p>
          <div class="row justify-content-center">
            <div class="col-sm-6 col-12 text-center">
              <div class="mb-0 mb-sm-4">
                <a class="btn p-1 btn-link" href="https://www.facebook.com/IconParkingNYC" target="_blank" rel="noopener noreferrer"><img alt="Facebook" src="./../assets/img/landing-pages/augustoffer/fb.svg" alt="facebook"></a>
                <a class="btn p-1 btn-link" href="https://www.instagram.com/iconparkingnyc/?hl=en" target="_blank" rel="noopener noreferrer"><img src="./../assets/img/landing-pages/augustoffer/insta.svg" alt="instagram"></a>
                <a href="https://twitter.com/IconParkingNYC" target="_blank" class="btn p-1 btn-link" rel="noopener noreferrer"><img src="./../assets/img/landing-pages/augustoffer/twitter.svg" alt="twitter"></a>

<a href="https://www.linkedin.com/company/icon-parking/" target="_blank" class="btn p-1 btn-link" rel="noopener noreferrer"><img height="24px" class="linkedin-img" src="https://staging2.iconparkingsystems.com/cms/wp-content/uploads/2021/10/linkedin.png" alt="twitter"></a>
              </div>
              <p class="mb-0 mb-sm-3">&nbsp;</p>
              <p>&nbsp;</p>
              <p>
              Disclaimer:
              </p>
              <p>&nbsp;</p>
               <p>Icon Parking Reserves All Rights. Online Reservations ONLY. Offer may not be combined with any other offers or discounts. Discount valid per 24 Hour Daily Parking Reservation $20 or more! Maximum value of a $100 discount per reservation. 22% OFF Daily Rates Offer | Promo Code JAN2022E | Valid Parking Dates Now – March 31, 2022. | Book by January 31, 2022, at 11:59pm. Monthly promo code JANUARY2022E | Book by 1/31/2022 at 11:59pm | Booking code valid for 1 month, 3 months, 6 months, or 12 months reservations | Rates for new customers only | Standard Monthly Parking terms apply. Book online and save more! Online Rates are already discounted</p>
            </div>
          </div>
        </div>
      </section>
    </div>   
 
  -->
