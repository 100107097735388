<app-header></app-header>
<div class="hidden" [innerHTML]="staticPageContent.content"></div>
<!-- Terms & Conditions Section -->
<div class="termsSection orangeBar pad-top-lg pad-bottom-md">
    <div class="container pad-top-md">
        <app-lazy-load [isLoaded]="isLoaded" [sectionName]></app-lazy-load>
        <div *ngIf="isLoaded">
            <h1 class="text-uppercase text-primary mar-top-0 mar-bottom-sm">{{cmsPageContentTerms.post_title}}</h1>
            <div class="p-page-content" [innerHTML]="cmsPageContentTerms.post_content"></div>
        </div>
    </div>
</div>

<app-footer></app-footer>