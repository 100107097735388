<div role="tabpanel" class="tab-pane fade active in" id="makepayment" *ngIf="isLoaded">
    <h4 class="text-primary hidden-print">Make Payment</h4>
    <div class="row hidden-print">
        <div class="col-xs-8 col-sm-9 col-md-5 col-lg-9 pad-right-0">
            Current Balance Due:
        </div>
        <div class="col-xs-4 col-sm-3 col-md-3 col-lg-3 text-right" sourceFont>
            ${{ accountDetails.curr_month_balance }}
        </div>
    </div>
    <div class="row hidden-print">
        <div class="col-xs-8 col-sm-9 col-md-5 col-lg-9 pad-right-0">
            Total Account Balance:
        </div>
        <div class="col-xs-4 col-sm-3 col-md-3 col-lg-3 text-right" sourceFont>
            ${{ accountDetails.balance }}
        </div>
    </div>
    <p class="hidden-print">&nbsp;</p>
    <div *ngIf="successPayment === false">
        <div class="currentCarD radio mar-top-0" *ngFor="let paymentMethod of paymentMethods?.payments">
            <label *ngIf="paymentMethod?.card !== null">
          <input type="radio" class="radio" name="selectedMethod" id="card-{{paymentMethod?.payment_profile_id}}" value="{{ paymentMethod?.payment_profile_id }}" (change)="updatePaymentMethod(paymentMethod?.payment_profile_id)" checked="" />
          <fa name="credit-card" class="mar-right-xs"></fa>
          Card Ending In ({{ paymentMethod?.card?.card_number}}) for {{ displayNameOnCard(paymentMethod?.address?.first_name) }} {{ displayNameOnCard(paymentMethod?.address?.last_name) }}, Expires on 
          <span *ngIf="paymentMethod?.expiration_month && paymentMethod?.expiration_year">
            {{ paymentMethod?.expiration_month }}/{{ paymentMethod?.expiration_year }} 
          </span>
          <span *ngIf="!paymentMethod?.expiration_month && !paymentMethod?.expiration_year">
            --/--
          </span>
      </label>
            <label *ngIf="paymentMethod?.bank !== null">
          <input  type="radio" class="radio" name="selectedMethod" id="card-{{paymentMethod?.payment_profile_id}}" value="{{ paymentMethod?.payment_profile_id }}" (change)="updatePaymentMethod(paymentMethod?.payment_profile_id)" checked="" />
          <fa name="bank" class="mar-right-xs"></fa>
          Bank Ending In ({{ paymentMethod?.bank?.bank_account}}) for {{ displayNameOnCard(paymentMethod?.address?.first_name) }} {{ displayNameOnCard(paymentMethod?.address?.last_name) }}
      </label>
        </div>
        <p class=" currentCarD radio hasCard mar-bottom-0">
            <label>
        <input  type="radio" class="radio" name="selectedMethod" (change)="updatePaymentMethod('new')" id="card-{{paymentMethod?.payment_profile_id}}" value="" />
        <fa name="money " class="mar-right-xs"></fa>
        Use A New Payment Method
      </label>
        </p>
    </div>
    <div class="clearfix">
        <form *ngIf="successPayment === false" action="" method="POST" class="floating-label2 mar-top-sm" role="form" (ngSubmit)="f.form.valid && makePayment(formData, f)" #f="ngForm" novalidate>
            <input type="hidden" name="payment_profile_id" id="payment_profile_id" [(ngModel)]="formData.payment_profile_id" #payment_profile_id="ngModel" value="{{ paymentProfileToPay }}" />

            <!-- <div class="clearfix mar-top-xs">
          <button type="button" class="btn btn-visacard"  aria-expanded="false" aria-controls="dataAccount">
          <img [src]="visaIcon"/> &nbsp;&nbsp; My Visa Credit card (...1234)&nbsp; <fa name="pencil" size="1x"></fa>
          </button>
          <p></p>
        </div> -->

            <div class="clearfix" *ngIf="newPayment === true">
                <button class="btn btn-payment" type="button" (click)="togglePaymentForms('CC')" data-toggle="collapse" data-target="#acardExpand" aria-expanded="false" aria-controls="cardExpand">
          <fa name="credit-card" size="3x"></fa>&nbsp;&nbsp; New Credit/Debit Card &nbsp;&nbsp;&nbsp; <fa name="angle-up" size="2x"></fa>
          </button>
                <p></p>
            </div>
            <div id="cardAccountForm" *ngIf="newCreditCard === true">
                <div class="row">
                    <div *ngIf="has_valet_manager == false" class="col-sm-8">
                        <div class="form-group focused" [ngClass]="{ 'has-error' : f.submitted && !card_number.valid }">
                            <label class="control-label">Card Number</label>
                            <input [textMask]="{mask: cardMask}" type="text" (keypress)="restrictNumeric($event)" class="form-control" id="card_number" name="card_number" [(ngModel)]="formData.card_number" #card_number="ngModel" required>
                            <div *ngIf="f.submitted && !card_number.valid" class="help-block ">Card Number is required</div>
                        </div>
                    </div>
                    <div *ngIf="has_valet_manager" class="col-sm-8">
                        <div class="form-group focused" [ngClass]="{ 'has-error' : f.submitted && showCardError}">
                            <label class="control-label">Card Number</label>
                            <!-- <iframe  id="tokenFrame" height="40px" name="tokenFrame" src="https://valetmanager.cardconnect.com/itoke/ajax-tokenizer.html?formatinput=true&invalidinputevent=true&placeholder=&cardinputmaxlength=19&invalidcreditcardevent=true&css=body%7Bfont-family%3Ainherit%3Bpadding%3A0px%3Bmargin%3A0px%3Bcolor%3A%23000%3B%7D%3A%3A-webkit-input-placeholder%7Bcolor%3A%23999%3Bfont-weight%3A300%3B%7D%3A-ms-input-placeholder%7Bcolor%3A%23999%3Bfont-weight%3A300%3B%7D%3A%3Aplaceholder%20%7Bcolor%3A%23999%3Bfont-weight%3A300%3B%7Dinput%3Afocus%20%7Bborder%3A1px%20solid%20%23ed8222!important%3Bbackground-color%3A%23fff!important%3B-webkit-box-shadow%3Ainset%200%201px%201px%20rgba(0%2C0%2C0%2C.075)!important%3Bbox-shadow%3Ainset%200%201px%201px%20rgba(0%2C0%2C0%2C.075)!important%3B%7Dinput%7Bborder%3A1px%20solid%20%23d8d8d8%3Bbox-sizing%3Aborder-box%3Bborder-radius%3A4px%3Bbackground-color%3Atransparent%3B-webkit-box-shadow%3Anone%3Bbox-shadow%3Anone%3Bcolor%3A%23000%3Bheight%3A40px%3Bwidth%3A100%25%3Bbackground-position%3A96%25center%3Bpadding-left%3A15px%3Bpadding-right%3A15px%7D"  scrolling="no" width="100%"  frameborder="0"></iframe>  -->
                            <iframe  id="tokenFrame" height="40px" name="tokenFrame" src="https://valetmanager.cardconnect.com/itoke/ajax-tokenizer.html?formatinput=true&invalidinputevent=true&placeholder=&cardinputmaxlength=19&invalidcreditcardevent=true&css=body%7Bmargin%3A0px%3Bpadding%3A0px%3Bfont-family%3Aarial%3B%7D+++input+%7Bborder-bottom-width%3A+2px%3Bborder-top%3A+0px%3Bborder-left%3A+0px%3Bborder-right%3A+0px%3Bborder-bottom-color%3A+%23ed8222%3Bborder-radius%3A+0%3Bbackground-color%3A+transparent%3B-webkit-box-shadow%3A+none%3Bbox-shadow%3A+none%3Bcolor%3A+%23000000%3Bheight%3A+36px%3Bwidth%3A+100.0%25%3Bpadding-left%3A15px%3Boutline%3A+none%3B%7D"  scrolling="no" width="100%"  frameborder="0"></iframe>
                            <input style="float: left;visibility: hidden; padding: 0; height: 0; margin: 0; width: 0;" type="text" name="makePaymentToken" id="makePaymentToken"/>                            
                            <div *ngIf="f.submitted && showCardError" class="help-block">Card Number is required</div>
                        </div>
                    </div>
                   
                    <div class="col-sm-4">
                        <div class="form-group focused" [ngClass]="{ 'has-error' : f.submitted && !expiration_date.valid }">
                            <label class="control-label">Expiration Date</label>
                            <input [textMask]="{mask: dateMask}" [minlength]="4" type="text" id="expiration_date" class="form-control" name="expiration_date" [(ngModel)]="formData.expiration_date" #expiration_date="ngModel" required>
                            <div *ngIf="f.submitted && !expiration_date.valid" class="help-block ">Expiration Date is required</div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-8">
                        <div class="form-group focused" [ngClass]="{ 'has-error' : f.submitted && !name_on_card.valid }">
                            <label class="control-label">Name On Card</label>
                            <input type="text" class="form-control" name="name_on_card" id="name_on_card" [(ngModel)]="formData.name_on_card" required #name_on_card="ngModel">
                            <div *ngIf="f.submitted && !name_on_card.valid" class="help-block ">Name On Card is required</div>
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <div class="form-group focused" [ngClass]="{ 'has-error' : f.submitted && !security_code.valid }">
                            <label class="control-label">Security Code</label>
                            <div class="input-group">
                                <input maxlength="4" class="form-control" type="text" id="security_code" name="security_code" [(ngModel)]="formData.security_code" #security_code="ngModel" required />
                                <div *showItBootstrap="['md','lg']" class="input-group-addon pad-left-xs pad-right-xs  bgTransparent" container="body" containerClass="PaymentCustome" placement="left" [tooltip]="popTemplate"><img [src]="infoIcon" alt="i"></div>
                                <!-- fix for mobile device tooltip -->
                                <div *showItBootstrap="['xs','sm']" class="input-group-addon pad-left-xs pad-right-xs  bgTransparent" container="body" containerClass="PaymentCustome" placement="left" [tooltip]="popTemplate"><img [src]="infoIcon" alt="i"></div>
                                <!-- <div class="input-group-addon pad-left-xs pad-right-xs bgTransparent" container="body" containerClass="PaymentCustome" [tooltip]="popTemplate"><img [src]="infoIcon" alt="image"></div> -->
                            </div>
                            <div *ngIf="f.submitted && !security_code.valid" class="help-block ">Security code is required</div>
                        </div>
                    </div>

                    <div class="col-sm-4" >
                        <div class="form-group zip-width focused" [ngClass]="{ 'has-error' : f.submitted && !zip_code.valid }">
                            <label class="control-label">Zip Code</label>
                            <div class="input-group zip-width">
                                <input autocomplete="off" maxlength="6" minlength="5" id="zip_code" class="form-control" type="text" name="zip_code" [(ngModel)]="formData.zip_code" #zip_code="ngModel" required />
                            </div>
                            <div *ngIf="f.submitted && !zip_code.valid" class="help-block zip-error">Zip code is required</div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="clearfix mar-top-xs" *ngIf="newPayment === true">
                <button type="button" class="btn btn-payment" (click)="togglePaymentForms('BA')" data-toggle="collapse" data-target="#adataAccount" aria-expanded="false" aria-controls="dataAccount">
          <fa name="id-card" size="3x"></fa>&nbsp;&nbsp; New Bank Account &nbsp;&nbsp;&nbsp; <fa name="angle-up" size="2x"></fa>
          </button>
                <p></p>
            </div>
            <div id="bankAccountForm" *ngIf="newBankAccount === true">
                <div class="row">
                    <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6 mar-top-sm mar-bottom-xs">
                        <img [src]="cardguideIcon " alt="icon" class="img-responsive">
                    </div>
                    <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                        <div class="form-group focused" [ngClass]="{ 'has-error' : f.submitted && !name.valid }">
                            <label class="control-label">Bank Account Name</label>
                            <input type="text" class="form-control" name="name" id="name" [(ngModel)]="formData.name" #name="ngModel" required>
                            <div *ngIf="f.submitted && !name.valid" class="help-block ">Bank Account Name is required</div>
                        </div>
                        
                        <div *ngIf="has_valet_manager == false">
                            <div class="form-group focused" [ngClass]="{ 'has-error' : f.submitted && !account_type.valid }">
                                <label class="control-label">Account Type</label>
                                <select class="form-control" name="account_type" id="account_type" [(ngModel)]="formData.account_type" #account_type="ngModel">
                                    <option value="checking">Checking Account</option>
                                    <option value="savings">Saving Account</option>
                                    <option value="businessChecking">Business Checking Account</option>
                                </select>
                                <div class="help-block hidden">Account Type is required</div>
                            </div>
                            <div class="form-group focused" [ngClass]="{ 'has-error' : f.submitted && !account_number.valid }">
                                <label class="control-label">Account Number</label>
                                <input type="text" class="form-control" id="account_number" [minlength]="5" [maxlength]="17" (keypress)="restrictNumeric($event)" name="account_number" [(ngModel)]="formData.account_number" #account_number="ngModel" required>
                                <div *ngIf="f.submitted && !account_number.valid" class="help-block ">Account Number is Required (5 - 17 Digits)</div>
                            </div>   
                            <div class="form-group focused" [ngClass]="{ 'has-error' : f.submitted && !routing.valid }">
                                <label class="control-label">Routing</label>
                                <input type="text" class="form-control" id="routing" [minlength]="9" [maxlength]="9" (keypress)="restrictNumeric($event)" name="routing" [(ngModel)]="formData.routing" #routing="ngModel" required>
                                <div  *ngIf="f.submitted && !routing.valid" class="help-block ">Routing is Required (9 Digits)</div>
                            </div> 
                        </div>
                     <div *ngIf="has_valet_manager">
                        <div class="form-group focused" [ngClass]="{ 'has-error' : f.submitted && !account_type.valid }">
                            <label class="control-label">Account Type</label>
                            <select class="form-control" name="account_type" id="account_type" [(ngModel)]="formData.account_type" #account_type="ngModel">
                                <option value="checking">Checking Account</option>
                                <option value="savings">Saving Account</option>
                                <!-- <option value="businessChecking">Business Checking Account</option> -->
                            </select>
                            <div class="help-block hidden">Account Type is required</div>
                        </div>
                            <div class="form-group focused" [ngClass]="{ 'has-error' : f.submitted && showAccountError }">
                                <label class="control-label">Routing & Account Number ( Format - Routing/Account Number )</label>
                                <!-- <input type="text" class="form-control" id="account_number" [minlength]="5" [maxlength]="17" (keypress)="restrictNumeric($event)" name="account_number" [(ngModel)]="formData.account_number" #account_number="ngModel"> -->
                                <!-- <div class="help-block hidden">Account Number is Required (5 - 17 Digits)</div> -->
                                <iframe id="tokenFrame" height="40px" name="tokenFrame" src="https://valetmanager.cardconnect.com/itoke/ajax-tokenizer.html?&invalidinputevent=true&placeholder=&invalidcreditcardevent=false&css=body%7Bmargin%3A0px%3Bpadding%3A0px%3Bfont-family%3Aarial%3B%7D+++input+%7Bborder-bottom-width%3A+2px%3Bborder-top%3A+0px%3Bborder-left%3A+0px%3Bborder-right%3A+0px%3Bborder-bottom-color%3A+%23ed8222%3Bborder-radius%3A+0%3Bbackground-color%3A+transparent%3B-webkit-box-shadow%3A+none%3Bbox-shadow%3A+none%3Bcolor%3A+%23000000%3Bheight%3A+36px%3Bwidth%3A+100.0%25%3Bpadding-left%3A15px%3Boutline%3A+none%3B%7D"  scrolling="no" width="100%"  frameborder="0"></iframe>

                                <!-- <iframe  id="tokenFrame" height="40px" name="tokenFrame" src="https://valetmanager.cardconnect.com/itoke/ajax-tokenizer.html?formatinput=true&invalidinputevent=true&placeholder=&cardinputmaxlength=19&invalidcreditcardevent=true&css=body%7Bmargin%3A0px%3Bpadding%3A0px%3Bfont-family%3Aarial%3B%7D+++input+%7Bborder-bottom-width%3A+2px%3Bborder-top%3A+0px%3Bborder-left%3A+0px%3Bborder-right%3A+0px%3Bborder-bottom-color%3A+%23ed8222%3Bborder-radius%3A+0%3Bbackground-color%3A+transparent%3B-webkit-box-shadow%3A+none%3Bbox-shadow%3A+none%3Bcolor%3A+%23000000%3Bheight%3A+36px%3Bwidth%3A+100.0%25%3Bpadding-left%3A15px%3Boutline%3A+none%3B%7D"  scrolling="no" width="100%"  frameborder="0"></iframe> -->
                                <input style="float: left;visibility: hidden; padding: 0; height: 0; margin: 0; width: 0;" type="text" name="makePaymentAcToken" id="makePaymentAcToken"/>                            
                                <div *ngIf="f.submitted && showAccountError" class="help-block">Routing Number (9 Digits) and Account Number (5 - 17 Digits) is Required</div>
                            </div>
                        </div>
                        
                        
                        
                    </div>

                </div>
            </div>
            <div class="form-group focused" *ngIf="newPayment === true">
                <div class="checkbox">
                    <label class=" ">

              <input type="checkbox" name="saveAccount" id="saveAccount" value="">
              Save to my account
               
            </label>
                </div>
            </div>
            <hr class="hidden-print mar-top-sm mar-bottom-sm">
            <div class="form-group focused">

                <div class="row">
                    <div class="col-xs-8 col-sm-9 col-md-5 col-lg-9 pad-right-0">
                        <div class="radio mar-top-0 mar-bottom-0">
                            <label class=" ">
                 
                  <input  type="radio" class="radio" name="paymentAmount"  id="cur_bal" value="{{ accountDetails.curr_month_balance }}" (click)="checkForOtherAmount()" checked="checked">
                  Current Balance : 
                </label>
                        </div>
                    </div>
                    <div class="col-xs-4 col-sm-3 col-md-3 col-lg-3 text-right" sourceFont>
                        ${{ accountDetails.curr_month_balance }}
                    </div>
                </div>
            </div>
            <div class="form-group focused">
                <div class="row">
                    <div class="col-xs-8 col-sm-9 col-md-5 col-lg-9 pad-right-0">
                        <div class="radio mar-top-0 mar-bottom-0">
                            <label class=" ">
               
                  <input  type="radio" class="radio" name="paymentAmount" id="tot_bal" value="{{ accountDetails.balance }}" (click)="checkForOtherAmount()">
                  Total Balance :
                </label>
                        </div>
                    </div>
                    <div class="col-xs-4 col-sm-3 col-md-3 col-lg-3 text-right" sourceFont>
                        ${{ accountDetails.balance }}
                    </div>
                </div>
            </div>
            <div class="form-group focused">
                <div class="row">
                    <div class="col-xs-7 col-sm-9 col-md-5 col-lg-9 pad-right-0">
                        <div class="radio mar-top-0 mar-bottom-0">
                            <label>
                  <input  type="radio" class="radio" name="paymentAmount" id="oth_bal" value="" (click)="checkForOtherAmount()">
                  Other Amount
                </label>
                        </div>
                    </div>
                    <div class="col-xs-5 col-sm-3 col-md-3 col-lg-3 text-right" *ngIf="otherAmount">
                        <div class="oa-input" sourceFont>
                            <input type="text"  class="form-control" (keypress)="restrictNumericDecimal($event)" (keyup)="updatePrice()" name="otherAmount" id="oa" value="0" maxlength="6">
                        </div>
                    </div>
                </div>
            </div>
            <div class="clearfix">
            </div>
            <div class="row">
                <div class="col-xs-8 col-sm-9 col-md-5 col-lg-9 pad-right-0">
                    <b>Amount to be charged</b>
                </div>
                <div class="col-xs-4 col-sm-3 col-md-3 col-lg-3 text-right">
                    <p sourceFont>$ {{ payableAmount | number:'.2-5' }}</p>
                </div>
            </div>
            <p>&nbsp;</p>
            <p>By submitting this transaction, you agree that {{ accountDetails?.facility?.facility_name_modified }} may debit this account for the amount of the transaction. In the event that an Electronic Funds Transfer (EFT) is returned unpaid, a service fee, as allowable
                by law, will be charged to this account.
            </p>
            <div class="btn-center-xs">
                <button type="submit" class="btn btn-primary btn-fill theme-btn ">Submit Payment</button>
            </div>
            <!-- </form>
        <div *ngIf="successPayment === true" id="printableContent">
            <h4 class="text-primary">Thanks for making your payment</h4>
            <p>Save the following information for your records.</p>
            <p><b>Total : </b> $ {{ successPaymentDetails?.transaction?.total }}</p>
            <p><b>Transaction ID : </b> {{ successPaymentDetails?.transaction?.anet_trans_id }}</p>
            <p><b>Payment method: </b> {{ successPaymentDetails?.transaction?.payment_details }}, for {{ successPaymentDetails?.transaction?.name }}</p>
            <p><b>Payment date: </b> {{ successPaymentDetails?.created_at }} </p>
        </div> -->
        </form>
        <div *ngIf="successPayment === true" id="printableContent">
            <h4 class="text-primary">Thank you for making your payment.</h4>
            <p>Please save the following information for your records.</p>
            <p><b>Total : </b><span sourceFont>$</span> {{ successPaymentDetails?.transaction?.total }}</p>
            <p *ngIf="has_valet_manager == false"><b>Transaction ID : </b> {{ successPaymentDetails?.transaction?.anet_trans_id }}</p>
            <p *ngIf="has_valet_manager == true"><b>Transaction ID : </b> {{ successPaymentDetails?.transaction?.vm_trans_id }}</p>
            <p><b>Payment method : </b> {{ successPaymentDetails?.transaction?.payment_details }} for {{ successPaymentDetails?.transaction?.name }}</p>
            <p><b>Payment date : </b> {{ successPaymentDetails?.created_at }} </p>
            <p *ngIf="has_valet_manager == false && successPaymentDetails?.transaction?.auth_net_status?.category"><b>Payment status : </b>{{ capitalize(successPaymentDetails?.transaction?.auth_net_status?.category) }} </p>
            <p *ngIf="has_valet_manager == false && !successPaymentDetails?.transaction?.auth_net_status?.category"><b>Payment status : </b> {{ (successPaymentDetails?.transaction?.status != '') ? capitalize(successPaymentDetails?.transaction?.status) : 'Pending' }} </p>
            
            <p *ngIf="has_valet_manager == true && successPaymentDetails?.transaction?.response_message != ''"><b>Payment status : </b>{{ capitalize(successPaymentDetails?.transaction?.response_message) }} </p>
            <p *ngIf="has_valet_manager == true && successPaymentDetails?.transaction?.response_message == ''"><b>Payment status : </b> Pending </p>
            

            <p style="padding-top:10px">We appreciate your business and look forward to continuing to serve you.</p>
        </div>
        <div *ngIf="successPayment === true" class="visible-xs mt-4 w-75 mx-auto" id="">
            <a href="javascript:;" class="btn btn-fill btn-primary hidden-print mb-2 w-100" (click)="printReceipt()">
                <fa name="print"></fa>&nbsp; Print this page
            </a>
            <a href="javascript:;" class="btn btn-fill btn-primary hidden-print w-100" (click)="returnToHome()">Return to your account home</a>

        </div>
    </div>

    <app-lazy-load [isLoaded]="isLoaded" [sectionName]="'payments'"></app-lazy-load>
    <ng-template #popTemplate>
        <img [src]="cardSecurityIcon" alt="icon">
    </ng-template>