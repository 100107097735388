<app-header></app-header>
<div class="payment-section orangeBar  ">
  <div class="container">
		<ol class="breadcrumb pad-top-0 pad-bottom-0 pad-left-0" *ngIf="isFrame" >
				<li class="active"><a href="javascript:;" onclick="history.go(-1);" class="btn btn-link pad-left-0"><fa name="angle-left"></fa> &nbsp; Back</a></li>
	    </ol>
  <div class="hidden-xs mar-top-md clearfix"></div>
  <h1 class="text-primary mar-top-0 mar-bottom-sm">Thank you for your monthly parking purchase</h1>
  <div class="clearfix reserItem">
    <div class="row">
    <div class="col-xs-12 col-sm-9 col-md-9 col-lg-9">
      <p class="lead">Your application is being processed. Once completed you will receive a welcome email package containing your completed registration forms.</p>
	  <p>Applications are processed 7 days a week between the hours of 9am and 7pm.</p>
	  <p>Your confirmation code for this request is <strong>{{ CuTrackingCode }}</strong>.</p>
	  <p>If you have any questions concerning your pending application, please call our office at 888-705-7845 or email us at {{ monthlyEmailAddress }}</p>
    
    <a *ngIf="!isFrame" class="btn btn-black theme-btn  " routerLink="/"> <fa name="home"></fa> Return to home page</a>
    <a *ngIf="isFrame" class="btn btn-black theme-btn " routerLink="/search"> <fa name="home"></fa> Return to home page</a>
    
    
      <!--<h2 class="mar-top-0 text-primary mar-top-sm text-uppercase">Reservation details</h2>
      <p>09/15/2017</p>
      <h4 class="mar-top-sm">Michael Smith</h4>
      <p>michael.smith@gmail.com</p>
      <p>(971) 123-4567</p>
      <br>
      <p class="accent mar-bottom-sm">Standard size vehicle</p>-->
      <!-- <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-10 col-lg-8">
          <div class="well mar-bottom-sm">
            <table width="100%" cellpadding="10">
              <tbody>
              <tr class="visible-xs">
                <td class="text-uppercase" colspan="2">
                  <b>Enter after</b>
                </td>
              </tr>
              <tr>
                <td class="text-uppercase hidden-xs">
                  <b>Enter after</b>
                </td>
                <td>
                  <i class="fa text-primary fa-calendar-o"></i> &nbsp; &nbsp; Monday, Aug 23
                </td>
                <td>
                  <i class="fa text-primary fa-clock-o"></i> &nbsp; &nbsp; 10:30 AM
                </td>
              </tr>
              <tr>
                <td colspan="2">&nbsp;</td>
              </tr>
              <tr class="visible-xs">
                <td class="text-uppercase" colspan="2">
                  <b>Enter Before</b>
                </td>
              </tr>
              <tr>
                <td class="text-uppercase hidden-xs">
                  <b>Exit before</b>
                </td>
                <td>
                  <i class="fa text-primary fa-calendar-o"></i> &nbsp; &nbsp;  Monday, Aug 23
                </td>
                <td>
                  <i class="fa text-primary fa-clock-o"></i> &nbsp; &nbsp;  8:30 PM
                </td>
              </tr>
            </tbody></table>
          </div>
        </div>
      </div> -->
      <!--<p class="cc"><img src="assets/img/visa.svg" alt="" height="40"><span class="mar-left-xs">Visa Credit Card (..1234)</span></p>
      <p class="mar-top-sm">Amount charged to card</p>
      <h2 class="mar-top-xs">$41</h2>
      <div class="clearfix mar-top-sm">
          <a class="btn btn-primary btn-fill theme-btn hidden-xs" href="#">Print Reservation</a><span class="mar-left-xs"></span><a class="btn theme-btn btn-primary theme-btn hidden-xs" href="#">Cancel</a>
          <div class="row visible-xs">
            <div class="col-xs-offset-1 col-xs-10">
              <a class="btn btn-primary btn-fill btn-block theme-btn" href="#">Print Reservation</a><div class="mar-top-xs"></div><a class="btn btn-primary btn-block theme-btn" href="#">Cancel</a>
            </div>
          </div>
      </div>
      <div class="hidden-xs">
        <hr class="mar-top-sm mar-bottom-sm">
        <p class="text-uppercase"><b>Reservation Policy</b></p>
        <small>
        No in/out (One Entry/Exit) No Partial Refunds for Unused Time<br>
        To cancel for a full refund click the CANCEL BUTTON in the email or on this page ANYTIME PRIOR TO THE ARRIVAL TIME indicated on the reservation.<br>
        If you encounter any issues you can email us at customerservice@iconparking.com<br>
        No refunds will be issued for unused reservations not canceled prior to the arrival time.<br>
        Oversized vehicle surcharge (from $10 to $15) will be collected at the location if the vehicle does not meet the size requirements, unless paid for at time of reservation.
        </small>
        <div class="clearfix mar-top-md">
          <p class="text-primary">Thank you for choosing Icon Parking.</p>
          <p>Questions? Contact our customer service team Monday-Friday, 9am-5pm<br>
            <a href="tel:(877) 727-5464">(877) 727-5464</a> or at <a href="mailto:customerservice@iconparking.com">customerservice@iconparking.com</a>
          </p>
        </div>
      </div>-->
    </div>
    <div class="col-xs-12 col-sm-3 col-md-3 col-lg-3">
      <p class="visible-xs mar-top-sm"></p>
      <div class="well mar-bottom-0">
        <h4 class="text-primary mar-top-0 text-uppercase">Facility details</h4>
        <p><b>{{ respdata?.entrance_location }}</b></p>
        <p>{{ respdata?.short_name }}</p>
        <p *ngIf="respdata?.phone_number.length > 0"><a href="tel:{{ respdata?.phone_number }}"><fa name="phone"></fa> {{ respdata?.phone_number }}</a></p>
        <p *ngIf="respdata?.between_streets.length > 0">Between: {{ respdata?.between_streets }}</p>
        <!-- <p>Entrance: {{ respdata?.entrance_location }}</p> -->
        <a target="_blank" href="https://maps.google.com?q={{respdata?.geolocations?.latitude}},{{respdata?.geolocations?.longitude}}" class="btn btn-link pad-left-0">get directions &nbsp;&nbsp; <fa name="chevron-right"></fa></a>
        <p class="mar-top-xs cc">
          <fa name="calendar" class="ismall text-primary"></fa> &nbsp; 7 days a week
        </p>
        <p class="cc">
          <fa name="clock-o" class="ismall text-primary" ></fa> &nbsp; 24 hours a day
        </p>
      </div>
      <div class="well pad-0 webSection">
        <div class="clearfix slideShow">
          <div id="carousel-id" class="carousel slide" data-ride="carousel">
            
            <div class="carousel-inner">
              <!-- <div class="item">
                <img src="/assets/img/park-garage-2.jpg">
              </div> -->
              <div class="item active" alt="image">
                
                <img [src]="park_garage" alt="image">
              </div>
              <!-- <div class="item">
                <img src="/assets/img/park-garage-2.jpg">
                
              </div> -->
            </div>
            <!-- <a class="left carousel-control" href="#carousel-id" data-slide="prev"><fa name="angle-left"></fa></a>
            <a class="right carousel-control" href="#carousel-id" data-slide="next"><fa name="angle-right"></fa></a> -->
          </div>
          
        </div>
        <div class="googleMap" id="map-wrapper">
          <img [src]="garage_map" alt="image" class="img-responsive">
        </div>
      </div>
      <div class="visible-xs">
        <p class="text-uppercase"><b>Reservation Policy</b></p>
        <small>
        No in/out (One Entry/Exit) No Partial Refunds for Unused Time<br>
        To cancel for a full refund click the CANCEL BUTTON in the email or on this page ANYTIME PRIOR TO THE ARRIVAL TIME indicated on the reservation.<br>
        If you encounter any issues you can email us at customerservice@iconparking.com<br>
        No refunds will be issued for unused reservations not canceled prior to the arrival time.<br>
        Oversized vehicle surcharge (from <span sourceFont>$</span>10 to <span sourceFont>$</span>15) will be collected at the location if the vehicle does not meet the size requirements, unless paid for at time of reservation.
        </small>
        <div class="clearfix mar-top-md">
          <p class="text-primary">Thank you for choosing Icon Parking.</p>
          <p>Questions? Contact our customer service team Monday-Friday, 9am-5pm<br>
            <a [href]="customer_phone">(877) 727-5464</a> or at <a href="mailto:customerservice@iconparking.com">customerservice@iconparking.com</a>
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
</div>
</div>
<app-footer></app-footer>