import { Component, OnInit,ViewEncapsulation } from '@angular/core';
import { StaticpagesService } from '../../services/staticpages.service';
import { Router} from '@angular/router';
import { WebMetaService } from '../../shared/web-meta.service';
@Component({
  selector: 'app-hudsonyards',
  templateUrl: './hudsonyards.component.html',
  styleUrls: ['./hudsonyards.component.scss'],
  providers: [StaticpagesService,WebMetaService],
  encapsulation: ViewEncapsulation.None
})
export class HudsonyardsComponent implements OnInit {

  constructor( private staticObj:StaticpagesService,private router: Router , private webMetaServiceObj: WebMetaService) { }
  page: String = 'hudsonyards';
  staticPageContent: any = {};
  isLoadeds = false;
  webdata:any;
  descriptionElement:any;
  path = window.location.pathname;
  metaTags: any;

  ngOnInit() {
 
    this.staticObj.getCmsPageContent(this.page).subscribe((data:any) => {
      this.staticPageContent = data.result[0];
      this.isLoadeds = true;
    }, (error) => { console.log('Error: ' + JSON.stringify(error));
      this.isLoadeds = true;
      }
  ); 
  // this.metaTags = Array.from(document.getElementsByTagName('meta'));
  // this.descriptionElement = this.getMetaElement('description');
  // this.updatePageMeta();
  }

  // getMetaElement(name){
  //   return this.metaTags.find((element) => { return element.getAttribute('name') === name; });
  // }
  // updatePageMeta() {
  //   this.webdata = this.webMetaServiceObj.getSlugMeta(this.path).then((data:any)=> {
  //     document.title = data['data']['meta']['title'];
  //     this.descriptionElement.setAttribute('content', data['data']['meta']['description']);
  //   });
  // }

}
