
<div class="clearfix" #searchFilterHeight>
    <a #myDiv (click)="openModal(dateTime2)" id="datetime2" data-backdrop="static" data-keyboard="false" class="hidden">{{startDuration}}</a>
    <div [class.hidden]="isfacility==1">
        <section [class.hidden]="!isSearch" *showItBootstrap="['xs','sm']">
            <div class="SearchPanelMobile" SearchPanelMobile *ngIf="isSearch">
                <form role="search">
                    <app-google-autosuggest (iconClick)="updateData()"></app-google-autosuggest>
                </form>
            </div>
        </section>
    </div>
    
    <input type="hidden" value="" name= "dummy" id="endDateWithID"/>
    <input type="hidden" value="" name= "dummy" id="startDateWithID"/>
    <input type="hidden" value="" name= "dummy" id="endTimeWithID"/>
    <input type="hidden" value="" name= "dummy" id="startTimeWithID"/>
    
    <input type="hidden" value="" name= "dummy" id="lenth_stay"/>
    <input type="hidden" value="" name= "dummy" id="time_search"/>
    
    <div mobileFilter *showItBootstrap="['xs','sm']" [class.hidden]="!isSearch">
        <div class="row  align-items-center  ">
    
            <div class="col">
             <p (click)="openModal(dateTime)" class="m-0"><span *ngIf="typeOfSearchDate!='monthly'">{{startDuration|date:	'MMM dd'}} </span><span *ngIf="typeOfSearchDate=='monthly'">{{startDurationMonthly|date: 'MMM dd'}} </span><span *ngIf="typeOfSearchDate!='monthly'">{{toTime(startTime)}}-<span  *ngIf="toDate(endDuration)!=todayDateFunction()">{{endDuration|date:	'MMM dd'}}</span> {{toTime(endTime)}} </span> <button type="button" class="btn p-0 btn-link btn-xs datetimefilterbtn "><fa name="pencil" size="2x"></fa></button> </p>
            </div>
            <div class="col-auto hidden" *ngIf="dealsBtn " >
                   <button  style="padding: 0;" type="button" class=" dealsButton mobile-deal-btn" (click)="showDeals()"
                    [attr.aria-expanded]="!isCollapsed" aria-controls="collapseBasic"><img height="39px" src="../../../../assets/img/deals/active_btn.svg" 
                    onmouseover="this.src='../../../../assets/img/deals/hover_btn.svg'"
                    onmouseout="this.src='../../../../assets/img/deals/active_btn.svg'"
                    border="0" alt=""/>
                </button>
            </div>
            <div class="col-auto">
                        <div  [class.hidden]="isfacility==1" class="  text-right" order1><a (click)="openModal(searchfilter)" filterbtnmobile class="text-capitalize filterBtnMobile">Filters</a></div>
    
            </div>
            <div class="col-auto pl-0" [class.hidden]="isDedicatedFacility || monthlyParkingNbCheck">
                <div *ngIf="typeOfSearchDate=='monthly' || monthlyParkingNbCheck" class="row no-gutters align-items-center  monthlyParkingNbCheck">
                    <div class="col" style="min-width: 68px;">
                      Deals Only
                    </div>
                    <div class="col">
                         <label class="toggleSwitch">
                    <input (change)="check()" type="checkbox" [(ngModel)]="dealstoggle" name="parking-features45">
                    <span class="slider round"></span>
                  </label>
                    </div>
                </div>
            </div>
            <div class="col-auto hidden" *ngIf="dealsBtn">
                <button  style="padding: 0;" type="button" class=" dealsButton mobile-deal-btn" (click)="showDeals()"
                 [attr.aria-expanded]="!isCollapsed" aria-controls="collapseBasic"><img height="39px" src="../../../../assets/img/deals/active_btn.svg" 
                 onmouseover="this.src='../../../../assets/img/deals/hover_btn.svg'"
                 onmouseout="this.src='../../../../assets/img/deals/active_btn.svg'"
                 border="0" alt=""/>
             </button>
         </div>
        </div>
    
    </div>
    
    
    
    
    
    <section class="bgWhite" id="searchFilterHeight" *ngIf="showonhomepage && isFrame === false">
        <div [ngClass]="{'hidden-xs':!bookingView, 'hidden-sm':!bookingView, 'hidden-sm-max':!bookingView, 'booking-view-filters':bookingView}">
            <div class="container flex-container ">
                <div class="row align-items-center " style=" display: -webkit-box;
                display: -webkit-flex;
                display: -ms-flexbox;">
                <div class="col-auto slideLeft daily-monthly-selector border-right">
                    <div class="filterBtn" data-toggle="buttons">
                        <label [class.disabled]="ispartner" (click)="updateRecordsOnLabelChange('daily')" id="daily" name="monthlytype" class="btn  dailymonthlybuttons ">
                    DAILY
                </label>
                        <label [class.disabled]="ispartner" (click)="updateRecordsOnLabelChange('monthly')" id="monthly" name="monthlytype" class="btn  dailymonthlybuttons " [ngClass]="{'disabled':yesdo }">
                    MONTHLY
                </label>
                    </div>
                </div>
                <div class="col-auto dropdown pr-0  border-right" id="featureFilter">
                
    
                    <!-- <a type="button" class="btn btn-primary"  [ngClass]="{'dropdown-toggle':filterRoute }"  >
                Filters
            </a> -->
                    <a id="filter-drps" type="button"  [class.disabled]="ispartner" class="btn text-dark btn-link hide dropdown-toggle " data-toggle="dropdown">
          <table>
            <tr><td style="font-size: 16px;">
          <img [src]="filter_icon" height="16" >Filters </td>  
          <td style="pointer-events: none; padding-top: 0px;" class="closeBtn" (click)=close() id="closeBtn" type="button">
                    &nbsp;<span class="fa fa-times"></span>
          </td> 
            </tr>
            </table>
            </a>
                    <a id="filter-drpp" type="button" class="btn btn-link text-dark disabled" style="cursor:not-allowed">
                      <table>
                        <tr><td style="font-size: 16px;"><img [src]="filter_icon" height="16">Filters </td>
                        <td class="closeBtn" style="pointer-events: none; padding-top: 5px;" (click)=close() id="closeBtn" type="button">
                          &nbsp;<span class="fa fa-times"></span>
                        </td>
                        </tr>
                        </table>
            </a>
    
                    <!-- <a type="button" class="btn btn-primary dropdown-toggle" >
                Filters
            </a> -->
                    <ul class="dropdown-menu" role="menu">
                        <!-- <a class="closeBtn" (click)=close() id="closeBtn" type="button">
                            <fa name="times"></fa>
                        </a> -->
    
                        <li role="menuitem">
                            <div class="checkbox">
                                <label for="charge-feature1">
                            <input (change)="tryPrintScaleValue()" [(ngModel)]="twentyhour" type="checkbox" name="parking-features1" id="charge-feature1" value=""/><img [src]="open_24hr_icon" alt="image"> Open 24 Hours
                        </label>
                            </div>
                        </li>
                        <li role="menuitem">
                            <div class="checkbox">
                                <label for="charge-feature2">
                            <input (change)="tryPrintScaleValue()" [(ngModel)]="indoor" type="checkbox" name="parking-features2" id="charge-feature2" value=""/><img [src]="indoor_icon" alt="image"> Indoor Parking
                        </label>
                            </div>
                        </li>
                        <li role="menuitem">
                            <div class="checkbox">
                                <label for="charge-feature3">
                            <input (change)="tryPrintScaleValue()" [(ngModel)]="outdoor" type="checkbox" name="parking-features3" id="charge-feature3" value=""/><img [src]="outdoor_icon" alt="image"> Outdoor Parking
                        </label>
                            </div>
                        </li>
                        <li role="menuitem">
                            <div class="checkbox">
                                <label for="charge-feature4">
                            <input (change)="tryPrintScaleValue()" [(ngModel)]="tesla" type="checkbox" name="parking-features4" id="charge-feature4" value=""/><img [src]="tesla_charger_icon" alt="image"> Tesla Charger
                        </label>
                            </div>
                        </li>
                        <li role="menuitem">
                            <div class="checkbox">
                                <label for="charge-feature5">
                            <input (change)="tryPrintScaleValue()" [(ngModel)]="evcharging" type="checkbox" name="parking-features5" id="charge-feature5" value=""/><img [src]="ev_charge_icon" alt="image"> Universal EV Charger
                        </label>
                            </div>
                        </li>
                        <li role="menuitem">
                            <div class="checkbox">
                                <label for="monthly-feature6">
                            <input (change)="tryPrintScaleValue()" [(ngModel)]="oversized" type="checkbox" name="parking-features6" id="monthly-feature6" value=""/><img [src]="oversized_icon" alt="image"> Oversized Vehicles
                        </label>
                            </div>
                        </li>
                        <li role="menuitem">
                            <div class="checkbox">
                                <label for="monthly-feature7">
                            <input (change)="tryPrintScaleValue()" [(ngModel)]="motorcycle" type="checkbox" name="parking-features7" id="monthly-feature7" value=""/><img [src]="motorcycle_icon" alt="image"> Motorcycle Parking
                        </label>
                            </div>
                        </li>
                        <li role="menuitem">
                            <div class="checkbox">
                                <label for="monthly-feature8">
                            <input (change)="tryPrintScaleValue()" [(ngModel)]="monthlypark" type="checkbox" name="parking-features8" id="monthly-feature8" value=""/><img [src]="monthly_icon" alt="image"> Monthly Parking
                        </label>
                            </div>
                        </li>
                        <li role="menuitem" class="hide">
                                <div class="checkbox">
                                    <label for="monthly-feature8">
                                <input (change)="tryPrintScaleValue()" [(ngModel)]="is_deal" [checked]="is_deal" type="checkbox" name="parking-features8" id="deals" value=""/><img [src]="monthly_icon" alt="image"> Deals
                            </label>
                                </div>
                        </li>
                    </ul>
                </div>
                <div class="col-auto  pl-4 pr-3" [class.hidden]="ispartner" >
                    <div class="searchWrapperFilterTab">
                    <!-- <app-google-autosuggest [isSideNav]="false"></app-google-autosuggest> -->
                    <app-google-autosuggest [isSideNav]="false" (iconClick)="updateData()"></app-google-autosuggest>
    
    
    
                    </div>
                </div>
    
                <!-- Normal Banner Daily Filter -->
                <div *ngIf="!bookingView" [class.ml-3]="ispartner" class="col-auto pl-0 border-0" id="dailyFilter">
                    <form id="dailyFilterForm" name="dailyFilterForm">
                        <div class="row no-gutters filterDateTime align-items-center">
                            <div class="col-auto">
                                <b class="mr-3 ml-2">FROM </b>
                            </div>
                            <div class="col-auto">
                                <img height="12" class="cal-icon" [src]="date_icon">
                            </div>
                            <div class="col">
                                <input  [attr.disabled]="ispartner ? '' : null" (onShown)="onShowPicker($event)"   readonly  style="width: 50px;"  role="button" aria-haspopup="true" aria-expanded="false" type="text" class="timeFrame dateTriggers" id="startdate" name="startdate" [minDate]="minDate" [(ngModel)]='startDuration' [maxDate]="maxDate"
                                #dp="bsDatepicker" [bsConfig]="{ dateInputFormat: 'MMM D' }" (ngModelChange)="updateStartDuration();updateenddate()" bsDatepicker [(bsValue)]="startDuration">
                                <input *ngIf="isDedicatedFacility" type="hidden" name="sDate" value="{{startDuration}}" id="sdateDaily">
                            </div>
                            <div class="col-auto">
                            <img height="12" class="cal-icon"  [src]="time_icon">
                            </div>
                            <div class="col ">
                                <select  [attr.disabled]="ispartner ? '' : null" name="" [(ngModel)]="startTime" [ngModelOptions]="{standalone: true}" id="startTime" required="required"  class="timeFrame" (change)="updateStartTime()">
                                    <option [value]="slot.value" *ngFor="let slot of timeSlots" [selected]="slot.value == startTime">{{ slot.time }}</option>
                                </select>
                            </div>
                            <div class="col-auto border-right floatBorderDiv mr-3"></div>
                            <div class="col-auto">
                               <b class="mr-3 ml-3">TO</b>
                            </div>
                            <div class="col-auto">
                                <img height="12" class="cal-icon" [src]="date_icon">
                            </div>
                            <div class="col">
                         
                                <input  [attr.disabled]="ispartner ? '' : null" (onShown)="onShowPicker($event)"   readonly   style="width: 50px;" class="timeFrame dateTriggers" type="text" id="enddate" name="enddate" [minDate]="minDate" [maxDate]="maxEndDate" (onShown)="onShowPicker($event)" [(ngModel)]='endDuration' #dp="bsDatepicker"
                                (ngModelChange)="updateEndDuration()" [bsConfig]="{ dateInputFormat: 'MMM D' }" bsDatepicker [(bsValue)]="endDuration">
                                
                                <input *ngIf="isDedicatedFacility" type="hidden" name="sDate" value="{{endDuration}}" id="eDateDaily">
                            </div>
                            <div class="col-auto">
                            <img height="12" class="cal-icon" [src]="time_icon">
                            </div>
                            <div class="col">
                                <select  [attr.disabled]="ispartner ? '' : null" name="" [(ngModel)]="endTime" [ngModelOptions]="{standalone: true}" id="endTime" required="required"  class="timeFrame" (change)="updateEndTime()">
                                    <option [value]="slot.value" *ngFor="let slot of timeSlots" [selected]="slot.value == endTime" data-slot="{{ slot }}" data-value="{{ endTime }}">{{ slot.time }}</option>
                                    </select>
                            </div>
                            
                        </div>
                        <!-- <table class="dateTime">
                            <tr>
                                <td ><b>FROM</b></td>
                                <td width="20%">
                                    <img class="filterImg" [src]="date_icon">
                                    <input readonly   role="button" aria-haspopup="true" aria-expanded="false" type="text" class="sdate dateTriggers" id="startdate" name="startdate" [minDate]="minDate" [(ngModel)]='startDuration' [maxDate]="maxDate"
                                        #dp="bsDatepicker" [bsConfig]="{ dateInputFormat: 'MMM Do' }" (ngModelChange)="updateStartDuration()" bsDatepicker [(bsValue)]="startDuration">
                                </td>
                                <td width="20%" class="dropdown filterBtn">
                                  <img  class="filterImg" [src]="time_icon">
                                    <select name="" [(ngModel)]="startTime" [ngModelOptions]="{standalone: true}" id="startTime" class="form-control" required="required"  class="sdate" (change)="updateStartTime()">
                                <option [value]="slot.value" *ngFor="let slot of timeSlots" [selected]="slot.value == startTime">{{ slot.time }}</option>
                            </select>
                                </td>
                                <td class="linebox" width="1%"> </td>
                                <td ><b>TO</b></td>
                                <td width="20%">
                                    <img class="filterImg" [src]="date_icon">
                                    <input readonly   class="sdate dateTriggers" type="text" id="enddate" name="enddate" [minDate]="minDate" [maxDate]="maxEndDate" (onShown)="onShowPicker($event)" [(ngModel)]='endDuration' #dp="bsDatepicker"
                                        (ngModelChange)="updateEndDuration()" [bsConfig]="{ dateInputFormat: 'MMM Do' }" bsDatepicker [(bsValue)]="endDuration">
                                </td>
                                <td width="20%" class="dropdown filterBtn">
                                  <img class="filterImg" [src]="time_icon">
                                    <select name="" [(ngModel)]="endTime" [ngModelOptions]="{standalone: true}" id="endTime" class="form-control" required="required"  class="sdate" (change)="updateEndTime()">
                                <option [value]="slot.value" *ngFor="let slot of timeSlots" [selected]="slot.value == endTime" data-slot="{{ slot }}" data-value="{{ endTime }}">{{ slot.time }}</option>
                            </select>
                                </td>
                            </tr>
                            <p>{{ calculateLengthOfStay() }}</p>
                        </table> -->
                    </form>
                </div>
    
                <!-- Booking View (Payment Page) Daily Filter -->
                <div *ngIf="bookingView" class="col " id="daily-filter-booking-view">
                    <form id="dailyFilterForm" name="dailyFilterForm">
                        <div class="dateTime">
                            <div class="row row-start-time">
                                <div class="col-xs-2 date-label"><b>FROM </b></div>
                                <div class="col-xs-5 col-md-4 col-lg-3 dropdown-container">
                                    <fa floatIcon class="text-primary" name="calendar-o" (click)="iconClick('startdate')"></fa>
                                    <input readonly   role="button" aria-haspopup="true" aria-expanded="false" type="text" class="sdate dateTriggers" id="startdate" name="startdate" [minDate]="minDate" [(ngModel)]='startDuration' [maxDate]="maxDate"
                                    (onShown)="onShowPicker($event)"   #dp="bsDatepicker" (ngModelChange)="updateStartDuration();updateenddate()" bsDatepicker [(bsValue)]="startDuration">
                                </div>
                                <div class="col-xs-5 col-md-4 col-lg-3 dropdown  dropdown-container">
                                    <fa floatIcon name="clock-o" class="text-primary"></fa>
                                    <select name="" [(ngModel)]="startTime" [ngModelOptions]="{standalone: true}" id="startTime" class="form-control" required="required"  class="sdate" (change)="updateStartTime()">
                                <option [value]="slot.value" *ngFor="let slot of timeSlots" [selected]="slot.value == startTime">{{ slot.time }}</option>
                            </select>
                                </div>
                            </div>
                            <div class="row row-end-time">
                                <div class="col-xs-2 date-label"><b>TO</b></div>
                                <div class="col-xs-5 col-md-4 col-lg-3 dropdown-container">
                                 
                                    <fa floatIcon class="text-primary" name="calendar-o" (click)="iconClick('enddate')"></fa>
                                    <input readonly   class="sdate dateTriggers" type="text" id="enddate" name="enddate" [minDate]="minDate" [maxDate]="maxEndDate" (onShown)="onShowPicker($event)"   [(ngModel)]='endDuration' #dp="bsDatepicker"
                                        (ngModelChange)="updateEndDuration()" bsDatepicker [(bsValue)]="endDuration">
                                </div>
                                <div class="col-xs-5 col-md-4 col-lg-3 dropdown  dropdown-container">
                                    <fa floatIcon name="clock-o" class="text-primary"></fa>
                                    <select name="" [(ngModel)]="endTime" [ngModelOptions]="{standalone: true}" id="endTime" class="form-control" required="required"  class="sdate" (change)="updateEndTime()">
                                <option [value]="slot.value" *ngFor="let slot of timeSlots" [selected]="slot.value == endTime" data-slot="{{ slot }}" data-value="{{ endTime }}">{{ slot.time }}</option>
                            </select>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
    
                <div class="col-auto " id="monthlyFilter">
                    <div class="row align-items-center no-gutters month-ie">
                        <div class="col-auto">
                         <b>MONTHLY PARKING STARTING  &nbsp;&nbsp;</b>
                        </div>
                        <div class="col-auto">
                            &nbsp;&nbsp;<img height="14" [src]="date_icon" style="margin-top: -4px;">
                        </div>
                        <div class="col">
                           <input (onShown)="onShowPicker($event)" style="max-width: 80px; padding-right: 0; padding-left: 8px;"   readonly type="text" id="monthlydate" class="sdate" name="monthlydate" [minDate]="minDate" [bsConfig]="{ dateInputFormat: 'MMM D YYYY' }"  [(ngModel)]='monthlyDate' #dp="bsDatepicker" (ngModelChange)="getMonthlyDate()"
                            bsDatepicker [(bsValue)]="monthlyDate">
                        </div>
                    </div>
                    <!-- <table width="100%" class="dateTime">
                        <tr>
                          
                            <td align="right"></td>
                            <td>
                                <fa class="text-primary" name="calendar-o"></fa>
                                <input (onShown)="onShowPicker($event)"   readonly type="text" id="monthlydate" class="sdate" name="monthlydate" [minDate]="minDate" [(ngModel)]='monthlyDate' #dp="bsDatepicker" (ngModelChange)="getMonthlyDate()"
                                    bsDatepicker [(bsValue)]="monthlyDate">
                            </td>
                        </tr>
                    </table> -->
                </div>
                <div class="col-auto p-0 hidden" *ngIf="dealsBtn"  [class.hidden]="isDedicatedFacility">
                        <button  type="button" class=" dealsButton p-0" (click)="showDeals()"
                    [attr.aria-expanded]="!isCollapsed" aria-controls="collapseBasic"> <img height="39px" src="../../../../assets/img/deals/active_btn.svg" 
                    onmouseover="this.src='../../../../assets/img/deals/hover_btn.svg'"
                    onmouseout="this.src='../../../../assets/img/deals/active_btn.svg'"
                    border="0" alt=""/>
                </button>
                </div>
                <div class="col-auto border-right pl-0 pad-border-month" [class.hidden]="ispartner" style="min-width:115px">
                
    
                    <!-- <a type="button" class="btn btn-primary"  [ngClass]="{'dropdown-toggle':filterRoute }"  >
                Filters
            </a> -->
                    <a (click)="resetSidebarFilter();searchDate()" id="btn-submits" type="button" class="btn btn-primary btn-fill" >
                SUBMIT
            </a>
            
            
                    
                    <!-- <a type="button" class="btn btn-primary dropdown-toggle" >
                Filters
            </a> -->
                  
                </div>
    
            </div>
            </div>
        </div>
    </section>
    <!-- <ng-container *ngIf="showonhomepage && isFrame === false && !bookingView">
        <app-loyality-badge (updateTierNeighbour)='updateTier($event)' [containerClass]="filterBadge" [showTies]="true"></app-loyality-badge>
    </ng-container> -->
    
    <div  *ngIf="is_home_page" class="infoBlock" style="padding: 0px;margin-bottom: 2px;background-color:#ec7d19!important">
            <div class="container " style="margin: 0;width: 100%;margin: 0 auto; ">
        
                <table class="content" cellpadding="0" style="vertical-align:middle" width="100%" cellspacing="0">
                    <tr>
                        <td style="font-size: 16px; padding: 0 10px;"><span ><a class="link_text_corona" href="https://iconparkingsystems.com/cms/daily-updates/">Icon Parking is committed to keeping our customers, team members and community updated on all developments on the evolving situation with Covid-19. Please <a class="click_here" style="color:white">click here</a> for the latest updates.</a></span></td>
                    </tr>
                </table>
        
                <div class="col-xs-1 text-right pad-right-0">
                </div>
            </div>
        </div>
    <div *ngIf="is_show_landing_strip && is_landing_page && show_strip_search" class="infoBlock orangeBar" style="padding: 11px;" [style.top.px]='navHeight' [class.paymentPageOnly]="blueBar"  [class.withLoyaltyBar]='isLoyality'>
        <div class="container " style="margin: 0;width: 100%;margin: 0 auto; ">
    
            <table class="content" cellpadding="0" style="vertical-align:middle" width="100%" cellspacing="0">
                <tr>
                    <td width="20px">
                        <fa name="{{sign}}" style="font-size: 1.7rem;" size="2x"></fa>
                    </td>
                    <td style="font-size: 16px; padding: 0 10px;"><span [innerHTML]="Couponmessage"></span>&nbsp;<a *ngIf="is_landing_page_save" (click)="launchLoginPopup()"><b>click here and save it for later. </b><i class="fa fa-angle-right"></i></a></td>
                     <!-- *ngIf="hideOnPayment"  -->
                    <td  class="text-right" width="25px">
                        <a href="javascript:void(0)" (click)="hideStripe();" class="btn-link">
                            <i class="fa fa-times text-white"></i>
                        </a>
                    </td>
                </tr>
            </table>
    
            <div class="col-xs-1 text-right pad-right-0">
            </div>
        </div>
    </div>
    
    <div *ngIf="showOnMothly" class="infoBlock" style="padding: 11px;" [style.top.px]='navHeight' [class.paymentPageOnly]='blueBar' [class.withLoyaltyBar]='isLoyality'>
        <div class="container " style="margin: 0;width: 100%;margin: 0 auto; ">
    
            <table class="content" cellpadding="0" style="vertical-align:middle" width="100%" cellspacing="0">
                <tr>
                     <td width="20px">
                        <fa name="{{sign}}" style="font-size: 1.7rem;" size="2x"></fa>
                    </td> 
                    <td style="font-size: 16px; padding: 0 10px;text-align: left;"><span [innerHTML]="Couponmessages"></span>&nbsp;</td>
                    <td  class="text-right" width="25px">
                        <a href="javascript:void(0)" (click)="hideStripeMonhthly();" class="btn-link">
                            <i class="fa fa-times text-white"></i>
                        </a>
                    </td>
                </tr>
            </table>
    
            <div class="col-xs-1 text-right pad-right-0">
            </div>
        </div>
    </div>
    <div *ngIf="inactiveFacility" class="infoBlock">
        <div class="container ">
            <div class="col-sm-10 content">
                Sorry! The facility you are searching for is no longer active.
            </div>
            <div class="col-sm-2">
                <a href="javascript:void(0)" (click)="hidestripfacility();" class="btn-link">
                    <i class="fa fa-times text-white"></i>
                </a>
            </div>
        </div>
    </div>
    </div>
    
    <ng-template #dateTime>
        <div class="modal-header">
            <h4 class="modal-title pull-left"><img width="90" [src]="logoIconDark" alt="image"></h4>
            <button id="closeButton" type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <img   [src]="crossSvg" alt="image" width="20" style="margin-top: 10px;">
          </button>
        </div>
    
    
        <div class="modal-body m-4">
            <!--  regular searchpage  -->
            <!-- <form  role="search" >
                <app-google-autosuggest></app-google-autosuggest>
           </form> -->
    
            <div class="mobileDateTime " id="dailyFilter" *ngIf="dailyFilter">
                <form id="dailyFilterForm" name="dailyFilterForm">
    
                    <div class="row mb-3 dateTimeFilter align-items-center align-items-center">
                        <div class="col">
                            <b class="mr-3 ml-2">From </b>
                        </div>
                        <div class="col-auto">
                            <img height="12" class="cal-icon" [src]="date_icon">
                        </div>
                        <div class="col">
                            <input [attr.disabled]="ispartner ? '' : null" (onShown)="onShowPicker($event)" readonly class="dateTriggers"    style="width: 50px;" role="button" aria-haspopup="true" aria-expanded="false" type="text" id="startdate" name="startdate" [minDate]="minDate" [(ngModel)]='startDuration'
                            [maxDate]="maxDate" #dp="bsDatepicker" [bsConfig]="{ dateInputFormat: 'MMM D' }" (ngModelChange)="updateStartDurationMobile();updateenddate()" bsDatepicker [(bsValue)]="startDuration">
                        </div>
                        <div class="col-auto">
                            <img height="12" class="cal-icon" [src]="time_icon">
                        </div>
                        <div class="col">
                            <select name="" [attr.disabled]="ispartner ? '' : null" [(ngModel)]="startTime" [ngModelOptions]="{standalone: true}" id="startTime_mob" required="required"  style="width: 50px;"  (change)="updateStartTimeMobile()">
                                <option [value]="slot.value" *ngFor="let slot of timeSlots" [selected]="slot.value == startTime">{{ slot.time }}</option>
                            </select>
                        </div>
                    </div>
                    <div class="row mb-3 align-items-center dateTimeFilter">
                        <div class="col">
                            <b class="mr-3 ml-2">To</b>
                        </div>
                        <div class="col-auto">
                            <img height="12" class="cal-icon" [src]="date_icon">
                        
                        </div>
                        <div class="col">
                            <input  [attr.disabled]="ispartner ? '' : null" (onShown)="onShowPicker($event)" [bsConfig]="{ dateInputFormat: 'MMM D' }" readonly class=" dateTriggers"  style="width: 50px;" type="text" id="enddate" name="enddate" [minDate]="minDate" [maxDate]="maxEndDate" [(ngModel)]='endDuration' #dp="bsDatepicker" (ngModelChange)="updateEndDurationMobile()"
                            bsDatepicker [(bsValue)]="endDuration">
                        </div>
                        <div class="col-auto">
                            <img height="12" class="cal-icon" [src]="time_icon">
                        </div>
                        <div class="col">
                            <select [attr.disabled]="ispartner ? '' : null" name="" [(ngModel)]="endTime" [ngModelOptions]="{standalone: true}" id="endTime_mob" required="required"  style="width: 50px;" class="" (change)="updateEndTimeMobile()">
                                <option [value]="slot.value" *ngFor="let slot of timeSlots" [selected]="slot.value == endTime" data-slot="{{ slot }}" data-value="{{ endTime }}">{{ slot.time }}</option>
                                </select>
                        </div>
                    </div>
                 
                </form>
            </div>
    
    
            <div id="monthlyFilter" *ngIf="monthlyFilter">
                <table width="100%" class=" table my-4">
                    <tr>
                        <td class="text-center"><b>MONTHLY PARKING STARTING  &nbsp;</b></td>
                    </tr>
                    <tr>
                        <td class="text-center border-bottom-orange">
                            
                          <img height="16" [src]="date_icon" style="margin-top: -3px;">
                            <input [attr.disabled]="ispartner ? '' : null" style="max-width: 100px; outline: none;" (onShown)="onShowPicker($event)" class="monthlyDatePciker border-0"  readonly type="text" id="monthlydate" name="monthlydate" [minDate]="minDate" [(ngModel)]='monthlyDate' #dp="bsDatepicker" (ngModelChange)="getMonthlyDateMobile()"
                                bsDatepicker [(bsValue)]="monthlyDate">
                        </td>
                    </tr>
                </table>
            </div>
            <div class="clearfix text-center">
                <div class="btn-group filterBtn btn-group-justified" data-toggle="buttons">
                    <label [class.disabled]="ispartner" (click)="updateRecordsOnLabelChangeMobile('daily')" id="daily" name="monthlytype" [ngClass]="{'active': dailyFilters}" class="btn  dailymonthlybuttonsMobile ">
                    DAILY 
                </label>
                    <label [class.disabled]="ispartner" (click)="updateRecordsOnLabelChangeMobile('monthly')" id="monthly" name="monthlytype" [ngClass]="{'active': !dailyFilters}" class="btn dailymonthlybuttonsMobile ">
                    MONTHLY 
                </label>
                </div>
            </div>
            <div class="clearfix mar-top-sm"><a aria-label="Close" (click)="searchFacilityOnSearch()"  class="btn btn-primary btn-fill theme-btn btn-block">SEARCH</a>
    
              
            </div>
        </div>
    </ng-template>
    <ng-template #dateTime2>
        <div class="modal-header">
    
            <button id="closeButton1" type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <img   [src]="crossSvg" alt="image" width="20" style="margin-top: 10px;">
          </button>
        </div>
    
    
        <div class="modal-body m4-4 ">
            <h4  class="modal-title" style="font-size: 25px;margin-top: -30px;">EDIT TIMES</h4>
            <!--  regular searchpage  -->
            <!-- <form  role="search" >
                <app-google-autosuggest></app-google-autosuggest>
           </form> -->
    
            <form class="mobileDateTime">
                <div class="row mb-3 align-items-center dateTimeFilter">
                    <div class="col">
                        <b>Form</b>
                    </div>
                    <div class="col-auto">
                        <img height="12" [src]="date_icon">
                    </div>
                    <div class="col">
                        <input readonly class="dateTriggers" (onShown)="onShowPicker($event)"  role="button" aria-haspopup="true" aria-expanded="false" type="text" id="startdate" name="startdate" [minDate]="minDate" [(ngModel)]='startDuration'
                        [maxDate]="maxDate" #dp="bsDatepicker" [bsConfig]="{ dateInputFormat: 'MMM D' }" (ngModelChange)="updateStartDurationMobile();updateenddate()" bsDatepicker [(bsValue)]="startDuration">
                    </div>
                    <div class="col-auto">
                        <img height="12" [src]="time_icon">
                    </div>
                    <div class="col">
                        <select name="" [(ngModel)]="startTime" [ngModelOptions]="{standalone: true}" id="startTime" required="required"  class=" " (change)="updateStartTimeMobile()">
                            <option [value]="slot.value" *ngFor="let slot of timeSlots" [selected]="slot.value == startTime">{{ slot.time }}</option>
                        </select>
                    </div>
                </div>
                <div class="row mb-3 align-items-center dateTimeFilter">
                    <div class="col">
                        <b>To</b>
                    </div>
                    <div class="col-auto">
                        <img height="12" [src]="date_icon">
                    
                    </div>
                    <div class="col">
                        <input readonly (onShown)="onShowPicker($event)" [bsConfig]="{ dateInputFormat: 'MMM D' }" class="dateTriggers"  type="text" id="enddate" name="enddate" [minDate]="minDate" [maxDate]="maxEndDate" [(ngModel)]='endDuration' #dp="bsDatepicker" (ngModelChange)="updateEndDurationMobile()"
                        bsDatepicker [(bsValue)]="endDuration">
                    </div>
                    <div class="col-auto">
                        <img height="12" [src]="time_icon">
                    </div>
                    <div class="col">
                        <select name="" [(ngModel)]="endTime" [ngModelOptions]="{standalone: true}" id="endTime" required="required"  class="" (change)="updateEndTimeMobile()">
                            <option [value]="slot.value" *ngFor="let slot of timeSlots" [selected]="slot.value == endTime" data-slot="{{ slot }}" data-value="{{ endTime }}">{{ slot.time }}</option>
                        </select>
                    </div>
                </div>
           
    
    
            </form>
    
            <div class="clearfix mar-top-sm"> 
                <a (click)="searchFacilityOnSearch()" class="btn btn-primary btn-fill theme-btn btn-block">Find Parking</a>
            </div>
        </div>
    </ng-template>
    
    
    
    
    
    
    <ng-template #searchfilter>
        <div class="modal-header">
            <h4 class="modal-title pull-left"><img alt="image" width="90" [src]="logoIconDark"></h4>
            <button type="button" class="close pull-right" aria-label="Close" (click)="closeFilter(applyfilterform)">
                      <img   [src]="crossSvg" alt="image" width="20" style="margin-top: 10px;">
                    </button>
        </div>
    
        <div class="modal-body">
            <div class="amenity-filter-mobile newcheckbox-d">
                <form [formGroup]="applyfilterform" (ngSubmit)="submitFilter()">
                <ul>
                    <li role="menuitem">
                        <div class="checkbox">
                            <label for="charge-feature1">
                                    <input  type="checkbox" id="charge-feature1" value="is_twentyhour" (change)="onCheckboxChange($event)" [checked]="checkFilterFormValue('is_twentyhour')"/>
                                    <img [src]="open_24hr_icon" alt="image"> Open 24 Hours
                                </label>
                        </div>
                    </li>
                    <li role="menuitem">
                        <div class="checkbox">
                            <label for="charge-feature2">
                                    <input type="checkbox" id="charge-feature2" value="is_indoor_parking" (change)="onCheckboxChange($event)" [checked]="checkFilterFormValue('is_indoor_parking')"/>
                                    <img [src]="indoor_icon" alt="image"> Indoor Parking
                                </label>
                        </div>
                    </li>
                    <li role="menuitem">
                        <div class="checkbox">
                            <label for="charge-feature3">
                                    <input (change)="onCheckboxChange($event)" value="is_outdoor_parking" type="checkbox" id="charge-feature3" [checked]="checkFilterFormValue('is_outdoor_parking')"/>
                                    <img [src]="outdoor_icon" alt="image"> Outdoor Parking
                                </label>
                        </div>
                    </li>
                    <li role="menuitem">
                        <div class="checkbox">
                            <label for="charge-feature4">
                                    <input (change)="onCheckboxChange($event)" value="is_tesla_charging" type="checkbox"  id="charge-feature4" [checked]="checkFilterFormValue('is_tesla_charging')"/>
                                    <img [src]="tesla_charger_icon" alt="image"> Tesla Charger
                                </label>
                        </div>
                    </li>
                    <li role="menuitem">
                        <div class="checkbox">
                            <label for="charge-feature5">
                                    <input (change)="onCheckboxChange($event)" value="is_generic_ev_charging" type="checkbox"  id="charge-feature5" [checked]="checkFilterFormValue('is_generic_ev_charging')"/>
                                    <img [src]="ev_charge_icon" alt="image"> Universal EV Charger
                                </label>
                        </div>
                    </li>
                    <li role="menuitem">
                        <div class="checkbox">
                            <label for="monthly-feature6">
                                    <input (change)="onCheckboxChange($event)" value="is_oversized" type="checkbox"  id="monthly-feature6" [checked]="checkFilterFormValue('is_oversized')"/>
                                    <img [src]="oversized_icon" alt="image"> Oversized Vehicles
                                </label>
                        </div>
                    </li>
                    <li role="menuitem">
                        <div class="checkbox">
                            <label for="monthly-feature7">
                                    <input (change)="onCheckboxChange($event)" value="is_motorcycle_parking" type="checkbox"  id="monthly-feature7" [checked]="checkFilterFormValue('is_motorcycle_parking')"/>
                                    <img [src]="motorcycle_icon" alt="image"> Motorcycle Parking
                                </label>
                        </div>
                    </li>
                    <li role="menuitem">
                        <div class="checkbox">
                            <label for="monthly-feature8">
                                    <input (change)="onCheckboxChange($event)" value="is_monthlypark" type="checkbox"  id="monthly-feature8" [checked]="checkFilterFormValue('is_monthlypark')" />
                                    <img [src]="monthly_icon" alt="image"> Monthly Parking
                                </label>
                        </div>
                    </li>
                    <li role="menuitem" class="hide">
                            <div class="checkbox">
                                <label for="monthly-feature8">
                            <input (change)="onCheckboxChange($event)" value="is_deal" type="checkbox"  id="dealss" [checked]="checkFilterFormValue('is_deal')"/>
                            <img [src]="monthly_icon" alt="image"> Deals
                        </label>
                            </div>
                    </li>
                   
                </ul>
                <div class="clearfix"></div>
                <div class="row">
                    <div class="col-xs-10 col-xs-offset-1">
                        <button (click)="cancel()"  type="submit" aria-label="Close"  class="btn btn-fill btn-primary btn-block text-uppercase">Apply Filters</button>
                    </div>
                </div>
                </form>
            </div>
        </div>
    
    </ng-template>