<app-lazy-load [isLoaded]="isLoaded" [sectionName]></app-lazy-load>
<div *ngIf="isLoaded" class="row">
  <div class="col-md-12">
    <div class="top-re-icons clearfix">
      <ol class="breadcrumb pad-top-0 pad-bottom-0 pad-left-0">
        <li class="active">
          <a routerLink="/my-account/icon-rewards" class="btn btn-link pad-left-0">
            <i class="fa fa-angle-left" style="font-size: 20px; margin-top: -5px;"></i> &nbsp; &nbsp; icon Rewards</a>
        </li>
      </ol>
      <ul class="top-icon-rewards pull-right">
        <li>
          <img [src]="icon_rewards_logo_wt" alt="price_tag">
        </li>
      </ul>
    </div>
    <div class="vissible-xs margin-top-sm"></div>
  </div>
</div>
<div *ngIf="isLoaded" class="magage-details">
  <div class="row">
    <div class="col-md-6 col-md-offset-3">
      <h2 class="text-uppercase mar-bottom-sm text-primary">KNOCK, KNOCK. WHO’S THERE?</h2>
      <p class="font-14">Add your name to your account to continue. This will let us give you a more personalized Icon Rewards
        experience.</p>
      <p class="mar-top-sm"><b class="text-uppercase">SIGNED IN AS {{userEmail}}</b> &nbsp; <a href="javascript:void();" (click)= "logOut()">Not you? Sign out &nbsp; <fa
            name="angle-right"></fa></a></p>
      <form id="floatForm" *ngIf="isLoaded" #saveDetails="ngForm"  name="form" (ngSubmit)="saveDetails.form.valid && saveUserInfo(user, saveDetails)">
        <div class="row">
          <div class="col-xs-12 col-sm-6">
            <div class="form-group" [ngClass]="{ 'has-error' : saveDetails.submitted && !first_name.valid}">
              <label class="control-label" for="first name">First name</label>
              <input class="form-control" [(ngModel)]="user.first_name" (keypress)="restChar($event)" (paste)="onPaste($event)"
                required name="first_name" #first_name="ngModel" type="text" placeholder="">
              <div *ngIf="saveDetails.submitted && !first_name.valid" class="help-block">First name is required.</div>
            </div>
          </div>
          <div class="col-xs-12 col-sm-6">
            <div class="form-group" [ngClass]="{ 'has-error' : saveDetails.submitted && !last_name.valid}">
              <label class="control-label" for="last name">Last name</label>
              <input class="form-control" [(ngModel)]="user.last_name" (keypress)="restChar($event)" (paste)="onPaste($event)"
                name="last_name" required #last_name="ngModel" type="text" placeholder="">
              <div *ngIf="saveDetails.submitted && !last_name.valid" class="help-block">Last name is required.</div>
            </div>
          </div>
          <div class="clearfix"></div>
          <div class="col-md-12 mar-top-xs">
            <div class="form-group btn-center-xs">
              <button class="btn btn-primary btn-fill">Next</button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
