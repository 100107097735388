import { Component, OnInit, Input } from '@angular/core';
import { MyAccountMonthlyParkingService } from '../../services/my-account-monthly-parking.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Crypto } from '../../classes/crypto';

import { NgForm } from '@angular/forms';
import * as $ from 'jquery';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-make-payment',
  templateUrl: './make-payment.component.html',
  styleUrls: ['./make-payment.component.scss'],
  providers: [MyAccountMonthlyParkingService, Crypto]
})
export class MakePaymentComponent implements OnInit {

  @Input() currentActive: string;
  @Input() accountNumber: any;
  @Input() accountDetails: any;

  visaIcon = 'assets/img/visa.svg';
  infoIcon = 'assets/img/info.svg';
  cardguideIcon =  '../assets/img/cardguide.png';
  cardSecurityIcon = 'assets/img/cardSecurity.png'
  newPayment = false;
  paymentProfileToPay: any;

  dateMask: any = [/[0-9]/, /[0-9]/, '/' , /[0-9]/, /[0-9]/];
  cardMask: any = [
          /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, ' ',
          /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, ' ',
          /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, ' ',
          /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/
          ];

  newCreditCard = false;
  newBankAccount = false;

  paymentMethods: any = {};

  formData: any = {
    'account_type' : 'checking'
  };

  isLoaded = false;
  otherAmount = false;
  payableAmount = 0.00;

  newlyAddedProfileID: any;
  successPayment = false;
  successPaymentDetails: any = {};

  has_valet_manager = true;
  showCardError = true;
  showAccountError = true;
  creditCardToken = '';
  accountNumberToken = '';
  constructor(
    private toastr: ToastrService,
    private crypto: Crypto,
    private router: Router,
    private myAccountMonthlyParkingObj: MyAccountMonthlyParkingService) { }

  ngOnInit() {
    this.fetchUserStatusForTracking();
    this.newPayment = false;
    this.isLoaded = false;
    this.successPayment = false;
    this.getpaymentMethods();

    if( localStorage.getItem('account_type') && localStorage.getItem('account_type') == 'VM' ){
      this.has_valet_manager = true
    } else {
      this.has_valet_manager = false;
    }
    setInterval(()=>{
      if(this.has_valet_manager == true && this.newCreditCard === true){
       let token = $('#makePaymentToken').val();
      if(token == 'undefined' || token == undefined || token == null || token == ''){
        this.showCardError = true;
       } else {
        this.showCardError = false;
       }
      }
      if(this.has_valet_manager == true && this.newBankAccount === true){
        let token = $('#makePaymentAcToken').val();
       if(token == 'undefined' || token == undefined || token == null || token == ''){
         this.showAccountError = true;
        } else {
         this.showAccountError = false;
        }
       }
    },100)
  }

  getpaymentMethods() {
    this.myAccountMonthlyParkingObj.paymentMethods(this.accountNumber)
    .then(
      (data:any)=> {
        this.isLoaded = true;
        this.payableAmount = this.accountDetails.curr_month_balance;
        this.paymentMethods = data['data'];
        if (this.paymentMethods.payments.length > 0) {
          this.paymentProfileToPay = this.paymentMethods.payments[this.paymentMethods.payments.length - 1].payment_profile_id;
        }
      },
      error => {
        this.toastr.error(error.errors.message, 'Sorry!');
        this.isLoaded = true;
        this.payableAmount = this.accountDetails.curr_month_balance;
      }
    );
  }

  updatePaymentMethod(paymentProfile) {
    if (paymentProfile === 'new') {
      this.newPayment = true;
      this.paymentProfileToPay = false;
      this.newCreditCard = true;
    } else {
      this.newPayment = false;
      this.newCreditCard = false;
      this.newBankAccount = false;
      this.paymentProfileToPay = paymentProfile;
    }
  }


  restrictNumericDecimal(evt) {
    let element = 'input#oa';
    let charCode = (evt.which) ? evt.which : evt.keyCode
    if (charCode > 31 && (charCode < 48 || charCode > 57) && !(charCode == 46 || charCode == 8))
      return false;
    else {
      let len = $(element).val().toString().length;
      let index = $(element).val().toString().indexOf('.');
      if (len > 9) {
        return false;
      }

      if (index > 0 && charCode == 46) {
        return false;
      }
      if (index > 0) {
        let CharAfterdot = (len + 1) - index;
        if (CharAfterdot > 3) {
          return false;
        }
      }

    }
    setTimeout( () => {
        this.payableAmount = parseFloat($('input#oa').val().toString());
    }, 200);
    return true;
  }

  restrictNumeric(event) {
    let input;
    if (event.metaKey || event.ctrlKey) {
      return true;
    }
    if (event.which === 32) {
     return false;
    }
    if (event.which === 0) {
     return true;
    }
    if (event.which < 33) {
      return true;
    }
    input = String.fromCharCode(event.which);
    // setTimeout( () => {
        // this.payableAmount = parseFloat($('input#oa').val().toString());
    // }, 200);
    return !!/[\d\s]/.test(input);
  }

  updatePayableAmount(amount) {
    this.payableAmount = amount;
  }

  togglePaymentForms(section) {
    if (section === 'BA') {
      if (this.newBankAccount == true) {
        this.newBankAccount = false;
        this.newCreditCard = true;
      } else {
        this.newBankAccount = true;
        this.newCreditCard = false;
      }
    } else {
      if (this.newCreditCard == true) {
        this.newCreditCard = false;
        this.newBankAccount = true;
      } else {
        this.newCreditCard = true;
        this.newBankAccount = false;
      }
    }
  }

  addPaymentProfile() {
    if (this.newCreditCard === true && this.newBankAccount === false) {
      this.addNewCreditCard();
    }

    if (this.newBankAccount === true && this.newCreditCard === false) {
      this.addNewBankAccount();
    }
  }

  addNewCreditCard() {
    this.fetchUserStatusForTracking();
    if (this.validateNewCreditCard()) {
      let card_number = '';
      if(this.has_valet_manager == true){
        card_number = this.creditCardToken;
      } else {
        card_number= $('#card_number').val().toString().replace(/\D/g, '');
      }
      const CardData = {
        'card_number' : card_number,
        'expiration_date' : $('#expiration_date').val().toString().replace(/\D/g, ''),
        'name_on_card' : $('#name_on_card').val(),
        'security_code' : $('#security_code').val(),
        'zip_code' : $('#zip_code').val()
      }
      const nonce = this.crypto.encrypt(CardData);
      const data = {
        'nonce' : nonce,
        'payment_type' : 'credit_card'
      };
      if (!this.getPayableAmount()) {
        return;
      } else {
        this.submitAddCreditCard(data);
      }
    }
  }

  submitAddCreditCard(data) {
    this.fetchUserStatusForTracking();
    this.myAccountMonthlyParkingObj.addCreditCard(data, this.accountNumber)
    .then(
      (data:any)=> {
        this.toastr.info('Credit Card Added, Please Wait While We Make Payment ', 'Almost Done!');
        this.paymentProfileToPay = data.data.payment_profile_id;
        this.payWithProfile();
      },
      error => {
        let errorMessage = error.errors.message;
        if (error.errors.message === 'Validation Errors') {
          errorMessage = '';
          for (const key in error.errors.detail) {
              errorMessage += error.errors.detail[key];
          }
        }
        this.toastr.error(errorMessage, 'Sorry!');
      }
    );
  }

  addNewBankAccount() {
    this.fetchUserStatusForTracking();
    if (this.validateNewBankAccount()) {
      let account_number = '';
      let data = {};
      if(this.has_valet_manager == true){
        account_number = this.accountNumberToken;
         data = {
          'name' : $('#name').val(),
          'account_type' : $('#account_type').val(),
          'account_number' : account_number,
          'payment_type': 'bank_account'
        };
      } else {
         data = {
          'name' : $('#name').val(),
          'account_type' : $('#account_type').val(),
          'routing' : $('#routing').val(),
          'account_number' : $('#account_number').val(),
          'payment_type': 'bank_account'
        };
      }
      
      if (!this.getPayableAmount()) {
        return;
      } else {
        this.submitAddBankAccount(data);
      }
    }
  }

  submitAddBankAccount(data) {
    this.fetchUserStatusForTracking();
    this.myAccountMonthlyParkingObj.addBankAccount(data, this.accountNumber)
    .then(
      (data:any)=> {
        this.toastr.info('Bank Account Added, Please Wait While We Make Payment ', 'Almost Done!');
        this.paymentProfileToPay = data['data'].payment_profile_id;
        this.payWithProfile();
      },
      error => {
        let errorMessage = error.errors.message;
        if (error.errors.message === 'Validation Errors') {
          errorMessage = '';
          for (const key in error.errors.detail) {
              errorMessage += error.errors.detail[key];
          }
        }
        this.toastr.error(errorMessage, 'Sorry!');
      }
    );
  }

  getPayableAmount() {
    this.fetchUserStatusForTracking();
    if ($('#cur_bal').prop('checked')) {
      if (this.accountDetails.curr_month_balance < 1 || this.accountDetails.curr_month_balance == 0 ) {
        this.toastr.error('Invalid Amount Selected, Try Again', 'Sorry!');
        return false;
      } else {
        return this.accountDetails.curr_month_balance;
      }
    } else if ($('#tot_bal').prop('checked')) {
      if (this.accountDetails.balance < 1 || this.accountDetails.balance == 0 ) {
        this.toastr.error('Invalid Amount Selected, Try Again', 'Sorry!');
        return false;
      } else {
        return this.accountDetails.balance;
      }
    } else if ($('#oth_bal').prop('checked')) {
      if (parseFloat($('input#oa').val().toString()) < 1 || parseFloat($('input#oa').val().toString()) == 0) {
        this.toastr.error('Invalid Amount Selected, Try Again', 'Sorry!');
        return false;
      } 
      // else if (parseFloat($('input#oa').val().toString()) > this.accountDetails.balance) {
      //   this.toastr.error('Amount Can Not Be Greater Than Totel Balance, Please Select Lower Amount', 'Sorry!');
      //   return false;
      // }
       else {
        return parseFloat($('input#oa').val().toString());
      }
    } else {
      return false;
    }
  }

  payWithProfile() {
    if (!this.getPayableAmount()) {
      return;
    }
    const PayableAmount = this.getPayableAmount();
    const data = {
      'amount': PayableAmount,
      'payment_profile_id': this.paymentProfileToPay,
      'card': false,
      'bank': false
    };
    this.pay(data);
  }

  pay(data) {
    this.myAccountMonthlyParkingObj.makePayment(data, this.accountNumber)
    .then(
      (data:any)=> {
        this.toastr.success('Payment Received Successfully', 'Great!');
        this.successPayment = true;
        this.successPaymentDetails = data.data;
      },
      error => {
        let errorMessage = error.errors.message;
        if (error.errors.message === 'Validation Errors') {
          errorMessage = '';
          for (const key in error.errors.detail) {
              errorMessage += error.errors.detail[key];
          }
        }
        this.toastr.error(errorMessage, 'Sorry!');
      }
    );
  }

  payWithCreditCard() {
    this.fetchUserStatusForTracking();
    if (this.validateNewCreditCard()) {
      let card_number = '';
      if(this.has_valet_manager == true){
        card_number = this.creditCardToken;
      } else {
        card_number= $('#card_number').val().toString().replace(/\D/g, '');
      }
      const CardData = {
        'card_number' : card_number,
        'expiration_date' : $('#expiration_date').val().toString().replace(/\D/g, ''),
        'name_on_card' : $('#name_on_card').val(),
        'security_code' : $('#security_code').val(),
        'zip_code' : $('#zip_code').val()
      }
      const nonce = this.crypto.encrypt(CardData);

      if (!this.getPayableAmount()) {
        return;
      } else {
        const amount = this.getPayableAmount();
        const data = {
          'amount': amount,
          'payment_profile_id': false,
          'card': {
              'nonce': nonce
          },
          'save_payment_method': $('#saveAccount').prop('checked'),
          'bank': false
        };
        this.pay(data);
      }
    }
  }

  payWithBankAccount() {
    this.fetchUserStatusForTracking();
    if (this.validateNewBankAccount()) {
      if (!this.getPayableAmount()) {
        return;
      } else {
        const amount = this.getPayableAmount();
        let account_details = {};
        if(this.has_valet_manager == true){
          account_details =  {
            'name' : $('#name').val(),
            'account_type' : $('#account_type').val(),
            'account_number' : this.accountNumberToken,
          }
        } else {
          account_details =  {
            'name' : $('#name').val(),
            'account_type' : $('#account_type').val(),
            'routing' : $('#routing').val(),
            'account_number' : $('#account_number').val(),
          }
        }
        const data = {
          'amount': amount,
          'payment_profile_id': false,
          'card': false,
          'bank': account_details,
          'save_payment_method': $('#saveAccount').prop('checked'),
        };
        this.pay(data);
      }
    }
  }

  payDirectly() {
    if (this.newCreditCard === true && this.newBankAccount === false) {
      this.payWithCreditCard();
    }

    if (this.newBankAccount === true && this.newCreditCard === false) {
      this.payWithBankAccount();
    }
  }

  makePayment(formData, form: NgForm) {
    this.fetchUserStatusForTracking();
    window.scrollTo(0,0);
      if(this.has_valet_manager == true && this.newCreditCard === true){
        this.creditCardToken = $('#makePaymentToken').val();
        if(this.creditCardToken == 'undefined' || this.creditCardToken == undefined || this.creditCardToken == null || this.creditCardToken == ''){
          this.showCardError = true;
          return;
        }else {
          this.showCardError = false;
        }
      }
      if(this.has_valet_manager == true && this.newBankAccount === true){
        this.accountNumberToken = $('#makePaymentAcToken').val();
        if(this.accountNumberToken == 'undefined' || this.accountNumberToken == undefined || this.accountNumberToken == null || this.accountNumberToken == ''){
          this.showAccountError = true;
          return;
        }else {
          this.showAccountError = false;
        }
      }

    if (this.paymentProfileToPay) {
      this.payWithProfile();
    } else {
      if (!this.newBankAccount && !this.newCreditCard) {
        this.toastr.error('Add Payment Profile First Or Pay with Valid Payment Mode', 'Sorry!');
      } else {
        if ($('#saveAccount').prop('checked') && this.has_valet_manager == false ) {
            this.addPaymentProfile();
        } else {
          this.payDirectly();
        }
      }
    }
  }

  validateNewCreditCard() {
    let validationFields = []
    if(this.has_valet_manager == true){
       validationFields = ['expiration_date', 'name_on_card', 'security_code', 'zip_code'];
    }else {
       validationFields = ['card_number', 'expiration_date', 'name_on_card', 'security_code', 'zip_code'];
    }
    let returnType = true;
    for (let i = 0; i < validationFields.length; i++) {
      if ($('#' + validationFields[i]).val().toString().length < 1) {
        $('#' + validationFields[i]).parents('div.form-group').addClass('has-error');
        $('#' + validationFields[i]).parents('div.form-group').find('div.help-block').removeClass('hidden');
        returnType = false;
      } else {
        $('#' + validationFields[i]).parents('div.form-group').removeClass('has-error');
        $('#' + validationFields[i]).parents('div.form-group').find('div.help-block').addClass('hidden');
      }
    }
    return returnType;
  }

  validateNewBankAccount() {
    let validationFields = [];
    if(this.has_valet_manager == true){
      validationFields = ['name'];
   }else {
    validationFields = ['name', 'account_number', 'routing'];
   }
    // const validationFields = ['name', 'account_number', 'routing'];
    let returnType = true;
    for (let i = 0; i < validationFields.length; i++) {
      if ($('#' + validationFields[i]).val().toString().length < 1) {
        $('#' + validationFields[i]).parents('div.form-group').addClass('has-error');
        $('#' + validationFields[i]).parents('div.form-group').find('div.help-block').removeClass('hidden');
        returnType = false;
      } else {
        $('#' + validationFields[i]).parents('div.form-group').removeClass('has-error');
        $('#' + validationFields[i]).parents('div.form-group').find('div.help-block').addClass('hidden');
      }
    }
    return returnType;
  }

  checkForOtherAmount(event) {
    this.fetchUserStatusForTracking();
    const selectedAmountOption = $('input[name="paymentAmount"]:checked').attr('id');
    if (selectedAmountOption === 'oth_bal') {
      this.otherAmount = true;
      setTimeout( () => {
          this.payableAmount = parseFloat($('input#oa').val().toString());
      }, 200);
    } else {
      this.otherAmount = false;
      if (selectedAmountOption === 'cur_bal') {
        this.payableAmount = this.accountDetails.curr_month_balance;
      } else {
         this.payableAmount = this.accountDetails.balance;
      }
    }
  }

  updatePrice() {
    if ($('input#oa').val().toString().length < 1) {
      // $('input#oa').val('0');
      this.payableAmount = 0;
    } else {
      this.payableAmount = parseFloat($('input#oa').val().toString());  
    }
  }

  printReceipt() {
    this.fetchUserStatusForTracking();
    // window.print();
    $('div#printable').html("<br /><br /><br /><br /><br /><br />" + $('#monthlyAccountSection').html() + '<br /><br /><br /><br />' + $('div#printableContent').html()).show();
    $('div#content-Wrapper').hide();
    $('.overlay-container').hide();
    window.print();
   // $('div#printable').html('').hide();
    //$('div#content-Wrapper').show();
    var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    if (isMobile) {
      setTimeout(()=>{
        $('div#printable').html('').hide();
        $('div#content-Wrapper').show();
        $('.overlay-container').show();
      },8000)
    }else{
      $('div#printable').html('').hide();
      $('div#content-Wrapper').show();
      $('.overlay-container').show();
    }
  }

  returnToHome() {
    this.router.navigate(['/my-account/monthly-parking/']);
  }
  capitalize(s){
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
  }

  fetchUserStatusForTracking(){
    let getUserId = '0';
    let getUserEmail = '0';
    let user_login_type = '';
    let isLogin = false;
    if(localStorage.getItem('access_token')){
     isLogin = true;
       getUserId = localStorage.getItem('userid');
       getUserEmail = localStorage.getItem('email');
       user_login_type = localStorage.getItem('user_login_type');
    }
    const loginStatusTrack = {
      'event' : 'pageview',
      'loginStatus' : isLogin? 'logged in': 'Not Logged IN', // 'logged in/Not Logged IN',
      'authenticationMethod' : user_login_type,
      'userId' : getUserId,
      'pageType' : this.router.url,
      'email': getUserEmail,
     };
    (<any>window).dataLayer.push(loginStatusTrack);
    }

    displayNameOnCard(name){
      if( name != null && name !='null'){
        return name
      }else {
        return '';
      }
    }
}
