import { Component, OnInit } from '@angular/core';
import { StaticpagesService } from '../../../services/staticpages.service';
import { UserserviceService } from '../../../services/userservice.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { Crypto } from '../../../classes/crypto';


// declare var ga: Function;
import * as $ from 'jquery';
@Component({
  selector: 'app-enroll-in-icon-rewards',
  templateUrl: './enroll-in-icon-rewards.component.html',
  styleUrls: ['./enroll-in-icon-rewards.component.scss'],
  providers: [StaticpagesService, UserserviceService, Crypto]
})
export class EnrollInIconRewardsComponent implements OnInit {
  pageSlug:string = 'terms-and-conditions';
  cmsPageContent : any = {};
  isLoaded = false;
  enrollDisable:boolean = true;
  maxLimit:number = 100;
  icon_rewards_logo_wt = 'assets/img/icon-rewards-logo-wt.svg';
  addToMyAccount:boolean=false;
  //progressRef: NgProgressRef;
  constructor(
    private staticObj: StaticpagesService,
    private routerObj: Router,
    private userServiceObj: UserserviceService,
    private toasterObj: ToastrService,
    private cryptoObj: Crypto,
   // private progress: NgProgress
    ) { }

  ngOnInit() {
    if(localStorage.getItem('invalidName')==="1"){
      this.routerObj.navigate(['/my-account/manage-details']);
      return false;
    }

    if(localStorage.getItem('is_loyalty_active') && localStorage.getItem('is_loyalty_active')=='1')
    {
      this.routerObj.navigate(['/my-account/account']);
    }

    this.staticObj.getCmsPageContent(this.pageSlug).subscribe((data:any)=> {
        this.cmsPageContent = data.result[0];
        this.isLoaded = true;
      }, (error) => { console.log('Error: ' + JSON.stringify(error));
        this.isLoaded = true;
      }
    );
    
  }
  acceptTerm(event){
    this.enrollDisable = ! event.target.checked;
  }
  
  enrolledNow(cardDetails){
    this.enrollDisable = true;
   // this.progressRef = this.progress.ref();
 
    // Start the progress
   // this.progressRef.start();
    //this.progress.start();
    let UserDetails = { card : {}, userInfo : {} };
    setTimeout(()=>{
      //this.progress.done();
     // this.progressRef.destroy();
      let card_token = localStorage.getItem('card_token');
      let card_type = localStorage.getItem('card_type');
      if(cardDetails.card_no !=='' || cardDetails.exp !== '' || cardDetails.security_code !== ''){
        if(card_token!==null){
          UserDetails = { card : cardDetails, userInfo : {} };
          var tokenizationData = {
            "token":card_token,
            "bin":cardDetails.card_no.replace(/\D/g, '').substr(0,6),
            "last_four":(cardDetails.card_no.replace(/\D/g, '')).substr(-4),
            "length":(cardDetails.card_no.replace(/\D/g, '')).length,
            "expiration":cardDetails.exp,
            "zip":cardDetails.zip_code,
            "type":card_type
          }
          localStorage.removeItem('card_token');
          localStorage.removeItem('card_type');

          UserDetails = { card : this.cryptoObj.encryptCard(tokenizationData), userInfo : {} };
          this.registerToRoyalty(UserDetails);
        }
        this.enrollDisable = false;
      }else{
        this.registerToRoyalty(UserDetails);
        return true;
      }
    }, 5000);
  }

  enrolledNowProf(userDetails){
      this.enrollDisable = true;
     // this.progressRef = this.progress.ref();
     // this.progressRef.start();
      let UserDetails = { card : {}, userInfo : {} };
      let time =1;
      var interval = setInterval(()=>{
        time++;
        if(time>10){
          let card_no_Validation = $('#standardCardNumber').val();
          let exp_Validation = $('#standardCardExpiration').val();
          let security_code_Validation = $('#standardCardCvv').val();
          let zip_code_Validation= $('#standardCardZip').val();
          let cardDetailsValidation = {
              card_no:card_no_Validation,
              exp:exp_Validation,
              security_code:security_code_Validation,
              zip_code:zip_code_Validation
            }
          if(cardDetailsValidation.card_no!='' && cardDetailsValidation.exp!='' && cardDetailsValidation.security_code!='' && cardDetailsValidation.zip_code!='' ){
            this.toasterObj.error('Network Problem', 'sorry!');
          }
          clearInterval(interval);
        }
        
        if(localStorage.getItem('card_tokenHard')){
          localStorage.removeItem('card_tokenHard')
          clearInterval(interval);
         // this.progressRef = this.progress.ref();
         // this.progressRef.destroy();
          let card_token = localStorage.getItem('card_token');
          let card_type = localStorage.getItem('card_type');
          let card_no = $('#standardCardNumber').val();
          let exp = $('#standardCardExpiration').val();
          let security_code = $('#standardCardCvv').val();
          let zip_code = $('#standardCardZip').val();
          let cardDetails = {
            card_no:card_no,
            exp:exp,
            security_code:security_code,
            zip_code:zip_code
          }
          UserDetails = { card : cardDetails, userInfo : {} };
    
          var tokenizationData = {
            "token":card_token,
            "bin":cardDetails.card_no.replace(/\D/g, '').substr(0,6),
            "last_four":(cardDetails.card_no.replace(/\D/g, '')).substr(-4),
            "length":(cardDetails.card_no.replace(/\D/g, '')).length,
            "expiration":cardDetails.exp,
            "zip":cardDetails.zip_code,
            "type":card_type,
            "name_on_card":'',
            "card_number": cardDetails.card_no.replace(/\D/g, ''),
            "security_code": cardDetails.security_code
          }

          localStorage.removeItem('card_token');
          localStorage.removeItem('card_type');
          UserDetails = { card : this.cryptoObj.encryptCard(tokenizationData), userInfo : {} };
  
          if(userDetails.phone !== '' || userDetails.zip !== '' || userDetails.birth_date !=='--' || userDetails.custom_fields[0].value !=='' || userDetails.custom_fields[1].value !==''){
            if(parseInt(userDetails.custom_fields[0].value)>this.maxLimit || parseInt(userDetails.custom_fields[0].value)<0){
              this.enrollDisable = false;
              this.toasterObj.error(`parking count should be between 0 to ${this.maxLimit}`, 'Sorry!');
              return false;
            }else if( (parseInt(userDetails.custom_fields[1].value)>this.maxLimit || parseInt(userDetails.custom_fields[1].value)<0) && userDetails.custom_fields[1].value != -1 ){
              this.enrollDisable = false;
              this.toasterObj.error(`parking % should be between 0 to ${this.maxLimit}.`, 'Sorry!');
              return false;
            }else{
              UserDetails.userInfo = userDetails;
            }
          }

          this.registerToRoyalty(UserDetails);

        }else if(time==10){
          clearInterval(interval);
       //   this.progressRef = this.progress.ref();
        //  this.progressRef.destroy();
          UserDetails = { card : {}, userInfo : {} };

          if(userDetails.phone !== '' || userDetails.zip !== '' || userDetails.birth_date !=='--' || userDetails.custom_fields[0].value !=='' || userDetails.custom_fields[1].value !==''){
            if(parseInt(userDetails.custom_fields[0].value)>this.maxLimit || parseInt(userDetails.custom_fields[0].value)<0){
              this.enrollDisable = false;
              this.toasterObj.error(`parking count should be between 0 to ${this.maxLimit}`, 'Sorry!');
              return false;
            }else if( (parseInt(userDetails.custom_fields[1].value)>this.maxLimit || parseInt(userDetails.custom_fields[1].value)<0) && userDetails.custom_fields[1].value != -1){
              this.enrollDisable = false;
              this.toasterObj.error(`parking % should be between 0 to ${this.maxLimit}.`, 'Sorry!');
              return false;
            }else{
              UserDetails.userInfo = userDetails;
            }
          }

          this.registerToRoyalty(UserDetails);
        }
      }, 1000);
  }

  registerToRoyalty(UserDetails){
    if($("#addCardIconReservation").prop("checked") == true){
      this.addToMyAccount=true;
    }else if($("#addCardIconReservation").prop("checked") == false){
      this.addToMyAccount=false;
    }
    let promoCode = $('#promocode').val();
    UserDetails.invitation_code = promoCode;
    UserDetails.iq_code = (sessionStorage.getItem('iq_code')!==null)?sessionStorage.getItem('iq_code'):'';
    UserDetails.add_to_account = this.addToMyAccount;
    this.userServiceObj.RegisterToLoyalty(UserDetails)
      .subscribe(
        (data:any) => {
          //console.log(data);
          this.enrollDisable = false;
          if(data.status === 201){
            /* commented for ga 16-6-2021 by seo team
            ga('send', 'event',{ eventCategory: 'Enroll User Successfull', eventAction:'click'});
            */
            localStorage.setItem('is_loyalty',data.data.is_loyalty.toString());
            localStorage.setItem('is_loyalty_active', data.data.is_loyalty_active.toString());
            if(data.data.success){
              if(localStorage.getItem('is_loyalty_active')=='0'){
                (<any>window).dataLayer.push({'event': 'rewardsEnrolled'});
                if( sessionStorage.getItem('event_for_loyalty')=='1' ){
                    let eventlabel = sessionStorage.getItem('eventlabel');
                    let eventcategory = sessionStorage.getItem('eventCategory');
                    /* commented for ga 16-6-2021 by seo team
                    ga('send', 'event', { eventCategory: eventcategory+" With card", eventAction: 'Click', eventLabel: eventlabel, eventValue: '10' });
                    */
                    sessionStorage.removeItem('event_for_loyalty');
                    sessionStorage.removeItem('eventlabel');
                    sessionStorage.removeItem('eventCategory');
                }
              }
              this.toasterObj.success(data.data.message, 'Great!');
              /*********** #Start card toasters messages ***************/
              if("account_card" in data.data){
                if(data.data.account_card.success){
                  this.toasterObj.success(data.data.account_card.message);
                }else{
                  this.toasterObj.error(data.data.account_card.message, 'Sorry!');
                }
              }

              if("invitation_code" in data.data){
                if(data.data.invitation_code.success){
                  this.toasterObj.success(data.data.invitation_code.message);
                }else{
                  this.toasterObj.error(data.data.invitation_code.message, 'Sorry!');
                }
              }

              if("loyalty_card" in data.data){
                if(data.data.loyalty_card.success){
                  this.toasterObj.success(data.data.loyalty_card.message);
                }else{
                  this.toasterObj.error(data.data.loyalty_card.message, 'Sorry!');
                }
              }
              /*********** #End card toasters messages ***************/
              sessionStorage.removeItem('iq_code');
              this.routerObj.navigate(['/my-account/account']);
              // window.location.href = '/my-account/account';
            }else{
              this.userServiceObj.GetLoyaltyProfile().subscribe(
                (res:any) => {
                  let uData = res.data;
                  localStorage.setItem('is_loyalty_active', uData.is_loyalty_active.toString());
                  if(uData.is_loyalty_active==1 && uData.is_loyalty==1 && localStorage.getItem('is_loyalty_active')=='0'){
                    (<any>window).dataLayer.push({'event': 'rewardsEnrolled'});
                    if( sessionStorage.getItem('event_for_loyalty')=='1' ){
                      let eventlabel = sessionStorage.getItem('eventlabel');
                      let eventcategory = sessionStorage.getItem('eventCategory');
                      /* commented for ga 16-6-2021 by seo team
                      ga('send', 'event', { eventCategory: eventcategory+"With card", eventAction: 'Click', eventLabel: eventlabel, eventValue: '10' });
                      */
                      sessionStorage.removeItem('event_for_loyalty');
                       sessionStorage.removeItem('eventlabel');
                       sessionStorage.removeItem('eventCategory');
                    }
                  }
                  if(uData.is_loyalty_active==0 && uData.is_loyalty==1 &&  localStorage.getItem('is_loyalty_active')=='0'){
                    (<any>window).dataLayer.push({'event': 'rewardsEnrolled-Inactive'});
                    if( sessionStorage.getItem('event_for_loyalty')=='1' ){
                      let eventlabel = sessionStorage.getItem('eventlabel');
                      let eventcategory = sessionStorage.getItem('eventCategory');
                      /* commented for ga 16-6-2021 by seo team
                      ga('send', 'event', { eventCategory: eventcategory+" Inactive", eventAction: 'Click', eventLabel: eventlabel, eventValue: '10' });
                      */
                      sessionStorage.removeItem('event_for_loyalty');
                      sessionStorage.removeItem('eventlabel');
                      sessionStorage.removeItem('eventCategory');
                      }
                  }
                }
              );
              this.toasterObj.error(data.data.message, 'Sorry!');
            }
          }
      },
      error => {
        this.enrollDisable = false;
        const errorsList = error.errors;
        if (errorsList.message !== '') {
          this.toasterObj.error(errorsList.message, 'Sorry!');
        }
      }
    );
  }
}
