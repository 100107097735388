<app-header></app-header>
<!-- <div class="intro-wrapper" [innerHTML]="staticPageContent.content"></div>
<iframe style="min-height: 600px; border: none;" src="https://citizens.fasthr.us/Career_Portal/IconParking_Iframe.aspx" width="100%"></iframe> -->
<!-- <div class="page-header orangeBar mar-bottom-md" id="career"></div> -->
<img src="../../assets/img/career-new.jpg" class="img-responsive careerNewImg">
<div class="container mar-bottom-lg">
  <ol class="breadcrumb pad-top-xs pad-bottom-xs pad-bottom-0 pad-left-0">
    <li class="active"><a routerLink="/careers" class="btn btn-link pad-left-0"><fa name="angle-left"></fa> &nbsp; Back</a></li>
  </ol>
  <h1 class="text-primary text-uppercase mar-top-0 mar-bottom-xs">EMPLOYMENT APPLICATION</h1>
  <div class="clearfix pad-top-xs">
    <iframe style="min-height: 600px; border: none;" src="https://citizens.fasthr.us/Career_Portal/IconParking_Iframe.aspx"
    width="100%" class="mar-top-0"></iframe>
  </div>
</div>
<app-footer></app-footer>