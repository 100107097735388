<app-header [showTies]="false"></app-header>
<div class="Account orangeBar " >
  <div class="container pad-top-lg pad-bottom-lg">
    <div class="account-nav clearfix text-uppercase">
		<ul class="nav nav-tabs nav-justified hidden-xs">
			<li><a routerLink="/my-account/account">My Account</a></li>
			<!-- <li><a (click)="addMenuactive();" routerLink="/my-account/icon-rewards">Icon Rewards</a></li> -->
			<li class="active"><a routerLink="/my-account/monthly-parking" >My Monthly Parking</a></li>
			<!-- <li><a routerLink="/my-account/coupons">My Offers</a></li> -->
			<li><a routerLink="/my-account/reservations">My Reservations</a></li>
			<li><a routerLink="/my-account/vehicle-request">Vehicle Request</a></li>
			<li><a routerLink="/my-account/brand-partnership">Brand Partnerships</a></li>
			<li><a routerLink="//my-account/promo-codes">My Promo Codes</a></li>
		</ul>
		<div class="clearfix">
			<div class="dropdown visible-xs selectBox">
				<a class="btn btn-default btn-block dropdown-toggle linkLabel" data-toggle="dropdown" ><div>MY MONTHLY PARKING</div><i aria-hidden="true" class="fa fa-angle-down"></i></a>
				<ul style="width:70%;" class="catValue dropdown-menu">
					<li><a routerLink="/my-account/account"  aria-hidden="true" >My Account</a></li>
					<!-- <li><a routerLink="/my-account/icon-rewards" aria-hidden="true">Icon Rewards</a></li> -->
					<li class="active"><a routerLink="/my-account/monthly-parking" aria-hidden="true" >My Monthly Parking</a></li>
					<!-- <li><a routerLink="/my-account/coupons"  aria-hidden="true" >My Offers</a></li> -->
					<li><a routerLink="/my-account/reservations"  aria-hidden="true" >My Reservations</a></li>
					<li><a routerLink="/my-account/vehicle-request"  aria-hidden="true" >My Vehicle Request</a></li>
					<li><a routerLink="/my-account/brand-partnership"  aria-hidden="true" >Brand Partnership</a></li>
					<li><a routerLink="/my-account/promo-codes"  aria-hidden="true" >My Promo Codes</a></li>
					<li>
                     <div class="btn-group btn-group-justified" role="group" aria-label="...">
                     <a class="logoutBtn" *ngIf="loggedUser" (click)="logOut()">Sign out <fa name="sign-out"></fa></a>
                    </div>       
                    </li>
				</ul>
			</div>
		</div>
		<app-loyality-badge (updateTierToHeader)='updateTier($event)'  [showTies]="true"></app-loyality-badge>
    </div>
    <div [ngClass]="isTierLoaded?'is-tiered-user-account':''" class="tab-content mar-top-sm">
  		<ol class="breadcrumb pad-top-0 pad-bottom-0 pad-left-0">
	        <li class="active"><a routerLink="/my-account/monthly-parking" class="btn btn-link pad-left-0"><fa name="angle-left"></fa> &nbsp; My Monthly Parking</a></li>
	    </ol>
		<div role="tabpanel" class="tab-pane fade active in Account" id="monthlyparking">
			<div class="registermonthlyaccount">
				<div class="row">
					<div class="col-xs-12 col-sm-8 col-md-8 col-lg-8">
						<h2 class="text-primary mar-top-0">REGISTER MONTHLY PARKING</h2>
						<h4 class="mar-top-sm">Icon Parking Account Number</h4>
						<p>7 or 8 digits <b>AFTER</b> the dash as found on your invoice. Please <b>DO NOT</b> enter any spaces.</p>
						<form class="mar-top-sm floating-label" action="" (ngSubmit)="f.form.valid && addNewMonthlyAccount(formData, f)" #f="ngForm" novalidate>
							<div class="form-group" style="position: relative;" [ngClass]="{ 'has-error' : f.submitted && !account_id.valid }" >
								<label class="control-label">Account Number</label>
								<input type="text" class="form-control" (keypress)="restrictNumeric($event)" name="account_id" [(ngModel)]="formData.account_id" #account_id="ngModel" required>
								<a class="floatBtn btn btn-link" triggers="mouseenter:mouseleave" [popover]="accountPop" placement="auto"><img [src]="helpIcon" alt="?"></a>
								<ng-template #accountPop><img [src]="account_numberIcon" alt="icon" width="180"></ng-template>
								<div *ngIf="f.submitted && !account_id.valid" class="help-block">Account Number is required</div>
							</div>
							<div class="form-group" style="position: relative;" [ngClass]="{ 'has-error' : f.submitted && !zip.valid }" >
								<label class="control-label">Billing Zip Code </label>
								<input type="text" class="form-control" [maxlength]="6" name="zip" [(ngModel)]="formData.zip" #zip="ngModel" required>
								<a class="floatBtn btn btn-link" triggers="mouseenter:mouseleave" [popover]="zipPop" placement="auto"><img [src]="helpIcon" alt="?"></a>
								<ng-template #zipPop><img [src]="zip_codeIcon" alt="icon" width="140"></ng-template>
								<div *ngIf="f.submitted && !zip.valid" class="help-block">Billing Zip Code is required</div>
							</div>
							<div class="form-group hidden-xs">
								<button id="addacount" class="btn-primary btn-fill theme-btn btn" type="submit">
									register <fa name="circle-o-notch" animation="spin" class="btnloader hidden"></fa>
								</button>
							</div>
							<div class="form-group visible-xs">
								<div class="row">
									<div class="col-xs-offset-1 col-xs-10">
										<button id="addacount" class="btn-primary btn-fill theme-btn btn btn-block" type="submit">
											register <fa name="circle-o-notch" animation="spin" class="btnloader hidden"></fa>
										</button>
									</div>
								</div>
							</div>
						</form>
					</div>
					<div class="col-xs-12 col-sm-4 col-md-4 col-lg-4">
						<div class="well">
							<h4 class="text-primary text-uppercase">WEB ACCOUNT BENEFITS</h4>
							<ul>
								<li>Review monthly payments</li>
								<li>Pay bills online</li>
								<li>See your online payments history</li>
								<li>Automatic monthly payments</li>
							</ul>
						</div>
						<div class="well">
							<h4 class="text-primary">NEED HELP</h4>
							<ul class="nav">
								<li>Contact customer support</li>
								<li>(855) 426-6692</li>
								<li>customerservice@iconparking.com</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
  </div>
</div>
<div class="clearfix">
</div>
<app-footer></app-footer>