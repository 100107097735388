<app-lazy-load [isLoaded]="isLoaded" [sectionName]></app-lazy-load>
<div *ngIf="isLoaded" class="row">
  <div class="col-md-12">
    <div class="top-re-icons clearfix">
      <ol class="breadcrumb pad-top-0 pad-bottom-0 pad-left-0">
        <li class="active">
          <a routerLink="/my-account/account" class="btn btn-link pad-left-0">
            <i class="fa fa-angle-left" style="font-size: 20px; margin-top: -5px;"></i> &nbsp; &nbsp; My Account</a>
        </li>
      </ol>
    </div>
    <div class="vissible-xs margin-top-sm"></div>
  </div>
</div>
<div *ngIf="isLoaded" class="magage-details">
  <div class="row">
    <div class="col-md-12">
      <h2 class="text-uppercase mar-bottom-sm text-primary">Edit Account Information</h2>
      <form id="floatForm" *ngIf="isLoaded" #saveDetails="ngForm" name="form" (ngSubmit)="saveDetails.form.valid && saveUserInfo(user, saveDetails)">
        <div class="row">
          <div class="col-xs-12 col-sm-6 col-md-4">
            <div class="{{firstCls}}" [ngClass]="{ 'has-error' : saveDetails.submitted && !first_name.valid}">
              <label class="control-label" for="first name">First Name</label>
              <input class="form-control" [(ngModel)]="user.first_name" (keydown)="restFirstSpace($event)" (keypress)="restChar($event)" (paste)="onPaste($event)"
                required name="first_name" #first_name="ngModel" type="text" placeholder="">
              <div *ngIf="saveDetails.submitted && !first_name.valid" class="help-block">First name is required.</div>
            </div>
          </div>
          <div class="clearfix" style="width: 0px;"></div>
          <div class="col-xs-12 col-sm-6 col-md-4">
            <div class="{{lastCls}}" [ngClass]="{ 'has-error' : saveDetails.submitted && !last_name.valid}">
              <label class="control-label" for="last name">Last Name</label>
              <input class="form-control" [(ngModel)]="user.last_name" (keydown)="restFirstSpace($event)" (keypress)="restChar($event)" (paste)="onPaste($event)"
                name="last_name" required #last_name="ngModel" type="text" placeholder="">
              <div *ngIf="saveDetails.submitted && !last_name.valid" class="help-block">Last name is required.</div>
            </div>
          </div>
          <div class="clearfix" style="width: 0px;"></div>
          <div class="col-xs-12 col-sm-6 col-md-4">
            <div class="{{emailCls}}" [ngClass]="{ 'has-error' : saveDetails.submitted && !email.valid}">
              <label class="control-label" for="email address">E-mail Address</label>
              <input class="form-control" [readonly]="disableEmailId" [ngClass]="{ 'disabled' : disableEmailId}" [(ngModel)]="user.email" (keypress)="restSpace($event)" pattern="{{emailRegex}}" name="email" required #email="ngModel" type="text"
                placeholder="">
              <div *ngIf="saveDetails.submitted && !email.valid" class="help-block">Email address is required.</div>
            </div>
          </div>
          <div class="clearfix"></div>
          <div class="col-xs-12 col-sm-4 col-md-3">
            <div class="{{phoneCls}}" [ngClass]="{ 'has-error' : saveDetails.submitted && !phone.valid}">
              <label class="control-label" for="phone">Phone Number</label>
              <input class="form-control" [(ngModel)]="user.phone" [textMask]="{mask: phoneMask}" minlength="10"
                (keypress)="phoneFormat($event)" name="phone" #phone="ngModel" type="text" placeholder="">
                <div *ngIf="saveDetails.submitted && !phone.valid" class="help-block">Not a valid number.</div>
            </div>
          </div>
          <div class="clearfix"></div>
          <div class="col-xs-12 col-sm-4 col-md-3">
            <div class="{{zipCls}}" [ngClass]="{ 'has-error' : saveDetails.submitted && !zip.valid}">
              <label class="control-label" for="zip">Home Zip Code</label>
              <input class="form-control" [(ngModel)]="user.zip" minlength="5" maxlength="6" name="zip" #zip="ngModel"
                type="text" placeholder="">
              <div *ngIf="saveDetails.submitted && !zip.valid" class="help-block">Home zip code is required.</div>
            </div>
          </div>
          <div class="clearfix"></div>
          <div class="col-sm-3 col-md-2 col-xs-5">
            <div class="{{dobCls}} form-group" [ngClass]="{ 'has-error' : saveDetails.submitted && !month.valid}">
              <label class="month-label" for="month">Birth Date</label>
              <select class="form-control" [(ngModel)]="user.month" name="month" #month="ngModel" type="text">
                <option value="">Month</option>
                <option *ngFor="let month of monthsList;" value={{month.id}}>{{month.value}}</option>
              </select>
              <div *ngIf="saveDetails.submitted && !month.valid" class="help-block">Month is required</div>
            </div>
          </div>
          <div class="col-sm-2 col-md-2 col-xs-4">
            <div class="{{dobCls}} form-group" [ngClass]="{ 'has-error' : saveDetails.submitted && !year.valid}">
              <label class="control-label" for="yaer">&nbsp;</label>
              <select class="form-control" [(ngModel)]="user.year" name="year" #year="ngModel" type="text">
                <option value="">Year</option>
                <option *ngFor="let year of yearsList" value={{year}}>{{year}}</option>
              </select>
              <div *ngIf="saveDetails.submitted && !year.valid" class="help-block">Year is required</div>
            </div>
          </div>
          <div class="clearfix"></div>
          <div class="col-md-12 mar-top-sm">
            <div class="mar-bottom-xs btn-center-xs">
              <button type="submit" class="btn btn-primary btn-fill">Save Details</button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
<div *ngIf="isLoaded" class="change-password">
  <div class="row">
    <div class="col-md-12">
      <h4 class="text-uppercase mar-bottom-0 mar-top-sm">Change Password</h4>
      <form id="floatForm" *ngIf="isLoaded" #updatePassword="ngForm" name="form" (ngSubmit)="changepassword(formData, updatePassword)">
        <div class="row">
          <div class="col-xs-12 col-sm-12 col-md-4">
            <div class="row">
              <div class="col-sm-12">
                <div class="form-group" [ngClass]="{ 'focused' : formData.old_password !='' }"  [ngClass]="{ 'has-error' : updatePassword.submitted && !old_password.valid }">
                  <label class="control-label">Old Password</label>
                  <input type="password" name="old_password" class="form-control" id="" [(ngModel)]="formData.old_password"
                    #old_password="ngModel" required>
                  <div *ngIf="updatePassword.submitted && !old_password.valid" class="help-block">{{oldPasswordErrMsg}}</div>
                    <p class="text-right mar-top-xs"><a routerLink="/reset-password" class="btn-link forget-btn">Forgot Password? &nbsp; &nbsp; &nbsp; <fa _ngcontent-c2=""
                      name="chevron-right">
                      <i aria-hidden="true" class="fa fa-chevron-right"></i>
                    </fa></a></p>
                </div>
              </div>
              <div class="col-xs-6 col-sm-6 col-md-12 col-lg-6">
                <h4 class="mar-bottom-0 mar-top-sm">New Password</h4>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12">
                <div class="form-group" [ngClass]="{ 'has-error' : updatePassword.submitted && !password.valid }">
                  <label class="control-label">Password</label>
                  <input type="password" name="password" class="form-control" [(ngModel)]="formData.password"
                    #password="ngModel" required>
                  <div *ngIf="updatePassword.submitted && !password.valid" class="help-block">{{passwordErrMsg}}</div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12">
                <div class="form-group" [ngClass]="{ 'has-error' : updatePassword.submitted && !password_confirmation.valid }">
                  <label class="control-label">Confirm Password</label>
                  <input type="password" name="password_confirmation" class="form-control" [(ngModel)]="formData.password_confirmation"
                    #password_confirmation="ngModel" required>
                  <div *ngIf="updatePassword.submitted && !password_confirmation.valid" class="help-block">Confirm
                    password is
                    required</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="form-group btn-center-xs">
          <button [(disabled)]="changePasswordDisabled" class="btn btn-fill btn-primary theme-btn">Change Password</button><span class="mar-left-xs"></span>
          <button type="cancel" class="btn btn-primary theme-btn" (click)="cancelChangePassword()">Cancel</button>
        </div>
      </form>
    </div>
  </div>
</div>
