<div role="tabpanel" class="tab-pane fade active in" id="faq">
          
        <div *ngIf="isLoaded">
            <h4 class="text-primary text-uppercase mar-top-0">{{cmsPageContent.post_title}}</h4>
            <div class="faq-content" [innerHTML]="sanatizeContent" (click)="getRouteWithAccountNumber($event)"></div>
         </div> 

          <app-lazy-load [isLoaded]="isLoaded" [sectionName]="'FAQ'"></app-lazy-load>
          <!-- <ol>
            
           <li>
            <strong>What are the advantages of paying online?</strong>

            It's convenient, easy and always secure. No more checks, waiting in lines, or stamps. Avoid late fees and Account Maintenance fees by activating Auto Pay. Our Website payment processor is owned by VISA and is the industry standard for secure online processing.
           </li>
           <li>
            <strong>Are there any fees associated with online accounts?</strong>

            An online account can help save you money by reducing your account maintenance fee to $0 when your payments are successfully processed by Auto Pay. If you decide to make a one-time payment online, the fee will be $5.
           </li>
           <li>
            <strong>When will my online payment be credited to my account?</strong>

            Online payments take 1-2 business days to post.  Payments being processed will appear immediately on your Online Payment History page and will be reflected in your account balance the next business day.
           </li>
           <li>
            <strong>Why did my account balance not change after I made my payment?</strong>

            Account balances are updated within 1-2 business days. You can view your recent online payment on the Online Payment History page. In the unlikely event that a payment is not accepted you will be notified by email.
           </li>
           <li>
            <strong>Why does my payment history not show all my payments?</strong>

            Your online account will only show online payments in the payment history section.  However, your account balance will reflect all payments when it is updated on a daily basis.
           </li>
           <li>
            <strong>What if I overpaid?</strong>

            The overpaid amount will automatically be credited against future invoices.
           </li>
           <li>
           <strong> How do I turn off Auto Pay?</strong>

            You can turn Auto Pay on or off at any time. <a routerLink="/my-account/monthly-parking/account/{{ accountNumber }}/auto-pay">Click Here</a> to Manage Auto Pay. You can also click on Manage Auto Pay from the right side menu bar.
           </li>
           <li>
            <strong>What day of the month does Auto Pay deduct payments from my bank account?</strong>

            The first day of the month.
           </li>
           <li>
            <strong>If I am on Auto Pay and I cancel my space, will you automatically deactivate my account?</strong>

            No. After you have given 30 days’ notice of cancellation notification to your garage manager or contacted us via email, you must log in to your online account and <a routerLink="/my-account/monthly-parking/account/{{ accountNumber }}/auto-pay">turn off Auto Pay</a> before the last business day of the last month in which you plan to park. <a routerLink="/my-account/monthly-parking/account/{{ accountNumber }}/cancellation-notice">Click Here</a> to send notice of cancellation.
           </li>
           <li>
            <strong>Can I schedule a one-time payment at a future date?</strong>

            Not at this time.
           </li>
           <li>
            <strong>When are my invoices due every month?</strong>

            Invoices are due the first of the month.  If payment is not received by the tenth day of the month, your vehicle will not be released without full payment plus a late fee.
           </li>
           <li>
            <strong>How much is the late fee amount?</strong>

            $25.
           </li>
           <li>
           <strong> Will I still receive a paper invoice if I pay online?</strong>

            You can choose to receive invoices via mail or email. <a routerLink="/my-account/monthly-parking/account/{{ accountNumber }}/billing-information">Click Here</a> to change your billing method.
           </li>
           <li>
            <strong>If I am transferring to another Icon Parking facility, can I pay my bill using the same online account and login?</strong>

            Yes, you can add additional Icon Accounts to your existing online account. You will need the new account number in order to sign up for online account access for your new garage.  You will need to reenter any saved payment information and activate Auto Pay under the new account. Logon to your existing online account and click Add A Monthly Account or <a routerLink="/my-account/monthly-parking/create-online-account">Click Here</a> to add an account.
           </li>
           <li>
            <strong>Can I cancel my monthly space online?</strong>

            Yes. 30 days advance notice is required. <a routerLink="/my-account/monthly-parking/account/{{ accountNumber }}/cancellation-notice">Click Here</a> to send notice of cancellation
           </li>
           <li>
            <strong>How can I change my address?</strong>

            <a routerLink="/my-account/monthly-parking/account/{{ accountNumber }}/billing-information">Click Here</a> to update your billing address.
           </li>
           <li>
            <strong>What is the Manhattan Resident tax exemption?</strong>

            The purpose of the Exemption Certificate is to provide proof to parking facility operators that the owner or lessee of a motor vehicle is not required to pay the additional New York City 8% Parking Tax. For those who qualify, the tax on parking services will be at a rate of 10.375% instead of 18.375% that is imposed under State law. Any individual who fulfills ALL of the following requirements is eligible for a Parking Tax Exemption Certificate:<br>
            <ol type="a">
             <li>
              has his or her primary residence in Manhattan

             </li>
             <li>
              owns a motor vehicle or leases it for a term of one year or more

             </li>
             <li>
              registers the vehicle pursuant to the vehicle and traffic law at his or her primary Manhattan address, or, in the case of a leased vehicle that is registered pursuant to the vehicle and traffic law, leases the vehicle at such address, and does not use the vehicle for business purposes.<br>
             </li>
            </ol>
            Where do I get the form for the Manhattan Resident tax exemption? <a href="https://www1.nyc.gov/site/finance/benefits/vehicles-manhattan-resident-parking-tax-exemption.page" target="_blank">Click Here</a>  to complete an online application for exemption.
           </li>
           <li>
           <strong> How do I change my monthly parking location?</strong>
Call the monthly sales office at 1 (888) 705-7845
           </li>
           <li>
            <strong>What if my question is not listed above?</strong>

            <a routerLink="/contact">Click Here</a> to contact us or call us at 1 (888) 705-7845
           </li>
          </ol> -->
         </div>