import { Component, OnInit,AfterViewInit,ViewChild,ElementRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { CustomCode } from '../shared/CustomCode';
import { FormValidationErrors } from '../shared/formValidationErrors';
import * as $ from 'jquery';
declare var google: any;
import { UserserviceService } from '../services/userservice.service';
import { MyAccountMonthlyParkingService } from '../services/my-account-monthly-parking.service';
import { ToastrService } from 'ngx-toastr';
import { FacebookService, InitParams, LoginResponse,LoginOptions } from 'ngx-facebook';
import { FacebookAuthService } from '../services/facebook.service';
import * as jwt_decode from 'jwt-decode';
//import { NAMESPACE_URIS } from '../../../node_modules123/@angular/platform-browser/src/dom/dom_renderer';
//declare var kochava:any;
//import { parse } from 'path';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  providers: [UserserviceService, MyAccountMonthlyParkingService]
})
export class LoginComponent implements OnInit {
formData: any = {};
  private respdata;
  private parseData;
  public currentRoute = '';
  facilityRadius = CustomCode.facilityDefaultRadius;
  userDetails;
  promoCode;
  email:any;
  isLandingPage:boolean = false;
  loginForm: NgForm;
  bigApple;
  routeUrl:any;
  emailRegex = CustomCode.emailRegex;
  landingLocations:any = {};
  redirect:any=''
  fbBtnIcon = 'assets/img/fb-square.svg'
  showButton=0;
  showMonthlyRedirectionMsg = false
  currDate = CustomCode.getDateForMapSearchSlashFormat();
  constructor(
      private routerObj: Router, 
      private userServiceObj: UserserviceService,
      private activatedRouteObj: ActivatedRoute,
      private toasterObj: ToastrService,
      private myAccountMonthlyParkingObj: MyAccountMonthlyParkingService,
      private fb: FacebookService,
      private facebookAuthService: FacebookAuthService
  ) { 
      let initParams: InitParams = {
      appId: CustomCode.Fbkey,
      xfbml: true,
      version: 'v2.8'
      };
       fb.init(initParams);
  }
  @ViewChild('myDiv',{ static: true }) myDiv: ElementRef;
  ngAfterViewInit() {
    
  }
  ngOnInit() {

    setInterval( ()=> {
      if(localStorage.getItem('clickBuyMonthly')){
        this.showMonthlyRedirectionMsg = true;
      }else {
        this.showMonthlyRedirectionMsg = false;
      }
    },10 )
    
//console.log(this.showMonthlyRedirectionMsg);
    if( /iPhone|iPad|iPod/i.test(navigator.userAgent) ) {
      this.showButton=1
      //alert(this.showButton)
     }else{
       this.showButton=0
     }
    //  if(navigator.platform.indexOf('Mac') > -1)
    //  {
    //   this.showButton=1 
    //  }else{

    //  }
    //CustomCode.manageFormLabelsPosition();
    this.initializeLocationData();
    if (this.routerObj.url.indexOf('offer') > -1) {
      this.isLandingPage = true;
    }
    this.getVisitorCode();
    this.appleLogin()
    
  }
  appleLogin()
  {
    let type = setInterval(() => {
        //alert(el.getAttribute('href'));
        if(localStorage.getItem('appleLogin'))
        {
          let names=''
          let email=''
          let parseData=JSON.parse(localStorage.getItem('appleLogin'))
          let decoded = jwt_decode(parseData.authorization.id_token); 
          //alert(parseData.hasOwnProperty('user'))
          if (parseData.hasOwnProperty('user'))
          {
            names=parseData.user.name.firstName+' '+parseData.user.name.lastName
            email=parseData.user.email
            //alert(names+' '+email)
          }else{
             names=''
             email=''
          }
         // alert(names)
          this.formData = {'for_register':0,'social_type':'4', 'social_id':decoded.sub, 'name':names}
          this.formData['email']=email
          localStorage.removeItem('appleLogin')
          this.userSignInWithSocialapple(this.formData);
          //alert(decoded.sub);
          clearInterval(type);
        }
        
      }, 2000);
  }
  fblogin()
  {
      let options: LoginOptions = {
          scope: 'public_profile,email',
          return_scopes: true,
          enable_profile_selector: true
          };

      this.fb.login(options)
      .then((res: LoginResponse) =>
      {
       if(res.status == 'connected') 
         {
           this.facebookAuthService.getUserFacebookProfileService(res.authResponse.accessToken)
           .subscribe( (data:any) => {
             
             this.formData = {'for_register':0,'social_type':'0', 'social_id':data.id, 'name':data.name,'picture':data.picture.data.url}
             if (data != '' && data.email != undefined)
             {
                this.email = data.email;
                this.formData['email']=this.email
                this.userSignInWithSocial(this.formData);
             }
             else{
               this.userServiceObj.loginCheckSocialUser(data.id).subscribe((data:any)=>{
                 if(data.data.is_email_found==1)
                 {
                   this.email = data.data.email;
                   this.formData['email']=this.email
                   this.userSignInWithSocial(this.formData);

                 }else{
                   localStorage.setItem('loginwithfb','1');
                   $('.normallogin').addClass('hide');
                    $('.fblogin').removeClass('hide');
                 }
               })
               
              }
              

           })
         }
       
    })
      .catch((error: any) => console.error(error));
  }
  loginFbAfterEmail(formValues)
  {
    localStorage.removeItem('loginwithfb');
    this.userSignInWithSocial(formValues);
  }
  fbloginafter()
  {
    var fbemail=$('#emailfb').val();
    if(fbemail=='')
    {
      $('#validEmail').removeClass('hide')
    }else if(!this.validateEmail(fbemail))
    {
      $('#validEmail').removeClass('hide')
    }else{
      this.loginFbAfterEmail({'for_register':1,'social_type':0,'social_id':this.formData.social_id,'email':fbemail,'name':this.formData.name,'client_id':CustomCode.clientId,'client_secret':CustomCode.clientSecret,'grant_type':'social_password'})
      $('#validEmail').addClass('hide')
    }

  }
   validateEmail(email) 
  {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  }
  initializeLocationData() {
      this.landingLocations['default'] = {
        'lat': CustomCode.timeSquareCoords.lat,
        'long': CustomCode.timeSquareCoords.long,
        'label': CustomCode.timeSquareCoords.defaultLocation,
        'type' : 'location'
      };

      this.landingLocations['all-nbh'] = {
        'slug': "central-park",
        'type' : 'neighborhood'
     };

      this.landingLocations['bryant-park'] = {
        'lat': 40.753597,
        'long': -73.983233,
        'label': "Bryant Park Winter Village, NY, United States",
        'type' : 'location'
      };
      this.landingLocations['rockefeller'] = {
        'lat': 40.758701,
        'long': -73.978595,
        'label': "Rockefeller Center Christmas Tree, NY, United States",
        'type' : 'location'
      };
      this.landingLocations['5th-ave'] = {
        'lat': 40.758074,
        'long': -73.977059,
        'label': "5th Ave Window Displays, NY, United States",
        'type' : 'location'
      };
      this.landingLocations['union-square'] = {
        'lat': 40.735863,
        'long': -73.991084,
        'label': "Union Square, NY, United States",
        'type' : 'location'
      };

      this.landingLocations['madison-square'] = {
        'lat': 40.7505085,
        'long': -73.9956274,
        'label': "Madison Square Garden Towers, New York, NY, United States",
        'type' : 'location'
      };

      this.landingLocations['times-square'] = {
        'lat': 40.7589,
        'long': -73.9851,
        'label': "Times Square, New York, NY, United States",
        'type' : 'location'
      };

      this.landingLocations['timessquare'] = {
        // 'lat': 40.7589,
        // 'long': -73.9851,
        // 'label': "Times Square, New York, NY, United States",
        'type' : 'neighborhood',
        'slug' : 'times-square'
      };

      this.landingLocations['midtown-west'] = {
        // 'lat': 40.7637581,
        // 'long': -73.99181809999999,
        // 'label': "Midtown West, New York, NY, United States",
        'type' : 'neighborhood',
        'slug' : 'midtown-west',
      };

      this.landingLocations['upper-west-side'] = {
        // 'lat': 40.7870106,
        // 'long': -73.97536760000003,
        // 'label': "Upper West Side, New York, NY, United States",
        'type' : 'neighborhood',
        'slug' : 'upper-west-side'
      };

      this.landingLocations['midtown-east'] = {
        // 'lat': 40.75403689999999,
        // 'long': -73.9668408,
        // 'label': "Midtown East, New York, NY, United States",
        'type' : 'neighborhood',
        'slug' : 'midtown-east'
      };
    }

  reset(formValues, formObj: NgForm) {

    if(localStorage.getItem('loginwithfb')  && ( !localStorage.getItem('clickBuyMonthlyFacebook') ))
    {
      // this.cancelUsingFb()
    }

    if(localStorage.getItem('notRemove')){

    }else {
      localStorage.removeItem('monthlyParkingId')
      localStorage.removeItem('monthlyParkingDate')
      localStorage.removeItem('clickBuyMonthly');
    }
    localStorage.removeItem('notRemove');
    $('.fblogin').addClass('hide');
    $('.normallogin').removeClass('hide');
    formObj.resetForm();
    
  }
  cancelUsingFb()
  {
    let currentUrl= window.location.href;
    let fbl=0
    let gml=0
    if(localStorage.getItem('loginwithfb'))
    {
      fbl=1
    }
    if(localStorage.getItem('loginwithgm'))
    {
      gml=1
    }
   CustomCode.userLogout();
   CustomCode.displayLoginUser();
     if(fbl)
      {
       
        this.fb.logout().then((res: LoginResponse)=> console.log('Logged out!'));
      }
    
    if (this.routerObj.url.indexOf('payment') > -1 || this.routerObj.url.indexOf('buy-monthly') > -1) {
      if(gml)
      {
        document.location.href = "https://www.google.com/accounts/Logout?continue=https://appengine.google.com/_ah/logout?continue="+CustomCode.BASEURL;
      }else{
          window.location.reload();
      }
     
    } else {

      if(gml)
      {
        document.location.href = "https://www.google.com/accounts/Logout?continue=https://appengine.google.com/_ah/logout?continue="+CustomCode.BASEURL;
      }else{
        window.location.href=CustomCode.BASEURL;
        //window.location.reload();
      }
      
    }
  }
  userSignIn(formValues, formObj: NgForm) {
    
    
    this.userServiceObj.loginUser(formValues)
      .subscribe(
      (data:any)=> {
        //console.log(data);
        this.parseData = data;
        if (this.parseData.status === 201) {

          // kochava.activity('user_login', {
          //   user_name : this.parseData.data.user.name,
          //   email : formValues.email
          //   });

          formObj.resetForm();
          CustomCode.markLoginUser(this.parseData.data);
          //this.toasterObj.success("Login Successfull","Great");
          /************** #Start If name contain email *********************/
          if(!CustomCode.isValidName(this.parseData.data.user.name)){
            localStorage.setItem('invalidName', '1');
          }else{
            localStorage.setItem('invalidName', '0');
          }
          /************** #End If name contain email *********************/
          if(this.parseData.data.user.isAdmin || this.parseData.data.user.isAdminGroup || this.parseData.data.user.roles[0]=='mp_sales' || this.parseData.data.user.roles[0]=='accounts_receivable' || this.parseData.data.user.roles[0]=='customer_service' )
          {
            
            localStorage.setItem('is_admin','true');
            let role = 'admin';
            if(!this.parseData.data.user.isAdmin){
              role = this.parseData.data.user.roles[0];
            }
            //console.log(CustomCode.adminUrlLogin+'/redirectto/'+localStorage.getItem('access_token')+'/'+localStorage.getItem('refresh_token'))+'/0'
           // window.location.href=CustomCode.adminUrlLogin+'/redirectto/'+localStorage.getItem('access_token')+'/'+localStorage.getItem('refresh_token');
            let locate= this.redirect=CustomCode.adminUrlLogin+'/redirectto/'+localStorage.getItem('access_token')+'/'+localStorage.getItem('refresh_token')+'/0';
            //locate='https://google.com'
            let el: HTMLElement = this.myDiv.nativeElement as HTMLElement;
            // setInterval(() => {
            //   //alert(el.getAttribute('href'));
            //   if(el.getAttribute('href')!='')
            //   {
            //     //el.click();
            //    // $('button.close').trigger('click');
            //   }
            // }, 2000);
           // 
            //
            this.redirecttofunction(locate)
            
          }else{
          const userLat = CustomCode.timeSquareCoords.lat;
          const userLong = CustomCode.timeSquareCoords.long;
          const userLocation = CustomCode.timeSquareCoords.defaultLocation;
          $('button.close').trigger('click');
          if (this.routerObj.url.indexOf('buy-monthly') > -1 ) {
            $('#loginPopup').hide();
            $('#guestuser').hide();
            $('#loggeduser').show();
           // console.log(localStorage.getItem('monthlyParkingId')+'11111111111111111111111111111111111')
          $('button.close').trigger('click');
          }else if (this.routerObj.url.indexOf('payment') > -1 || this.routerObj.url.indexOf('buy-monthly') > -1) {
            window.location.reload();
          } else {
            if (localStorage.getItem('is_landing_page') || this.routerObj.url.indexOf('offer') > -1 ) {

              let action_type = localStorage.getItem('action_type');
              let promocode = localStorage.getItem('promocode');
              this.promoCode = promocode
              let routeFullurl =localStorage.getItem('routeUrl');
              let promotion_id = localStorage.getItem('promotion_id');
              let template_slug = localStorage.getItem('template_slug');
              let landing_page_url = "/"+routeFullurl+"/" + template_slug + "/" + promotion_id + "/" + promocode + "/" + action_type;
              if (1) {
                if (localStorage.getItem('save')) {
                  if (action_type == 'save' && this.routerObj.url.indexOf(routeFullurl) > -1) {
                    this.fetchUserDetails();
                  } else {
                    if(localStorage.getItem('savebylogin')=='true')
                    {
                      this.fetchUserDetails();
                    }else{
                      window.location.reload();
                    }
                    
                  }
                } else {
                  window.location.reload();
                }
              }
            }else if(this.routerObj.url.indexOf('icon-rewards-register') > -1 && localStorage.getItem('is_loyalty') == '1'){
              if(this.parseData.data.user.is_loyalty_active){
                this.routerObj.navigate(['my-account/manage-icon-rewards-details']);
              }else{
                this.routerObj.navigate(['my-account/manage-icon-rewards-card']);
              }
            } else {
              if(sessionStorage.getItem('email_ref'))
              {
                sessionStorage.removeItem('email_ref');
                this.routerObj.navigate(['/my-account/icon-rewards']);
              }else{
                if(this.parseData.data.user.is_loyalty == 1){
                  if(this.parseData.data.user.is_loyalty_active == 1){
                    this.routerObj.navigate(['my-account/account']);
                    return false;
                    // if(this.parseData.data.user.is_loyalty_profile_completed == 1){
                    //   this.routerObj.navigate(['my-account/account']);
                    //   return false;
                    // }else{
                    //   this.routerObj.navigate(['my-account/manage-icon-rewards-details']);
                    //   return false;
                    // }
                  }else{
                    this.routerObj.navigate(['my-account/manage-icon-rewards-card']);
                    return false;
                  }
                }
                this.routerObj.navigate(['/my-account/account']);
              }
            }
            // this.routerObj.navigate(['/search', userLat, userLong, this.currDate,
            // this.facilityRadius, userLocation, CustomCode.defaultFacilityType, CustomCode.searchMode()]);
          }
        }
        }
      },
      error => {
        //FormValidationErrors.displayErrors(error.errors);
        console.log(error);
        const errorsList = error.errors;
        if (errorsList.message !== 'Validation Errors') {
          this.toasterObj.error(errorsList.message, 'Sorry!');
        } else {
          for (const key in errorsList.detail) {
            if (errorsList.detail.hasOwnProperty(key)) {
              if (typeof errorsList.detail === 'undefined') {
                this.toasterObj.error(errorsList.message, 'Sorry!');
              } else {
                this.toasterObj.error(errorsList.detail[key], 'Sorry!');
              }
            }
          }
        }
      }
      );
  }
  redirecttofunction(text)
  {
    window.top.location.href=text;
  }
  userSignInWithSocialapple(formValues) {
    this.userServiceObj.loginSocialUserapple(formValues)
      .subscribe(
        (data:any)=> {
         // alert(2)
          this.parseData = data;
          if (this.parseData.status === 201) {
            
          // kochava.activity('Login_with_facebook', {
          //     coupon_id : formValues.name,
          //     email : formValues.email
          //     });
           //alert(3)
           //console.log(4);
           //console.log(this.parseData.data);
          //  if(this.parseData.data.is_email_found==0)
          //  {
          //   //this.toasterObj.error('You have not given permission to use this account', 'Sorry!');
          //   return 
          //  }
            CustomCode.markSocilaLoginUser(this.parseData.data);
            const userLat = CustomCode.timeSquareCoords.lat;
            const userLong = CustomCode.timeSquareCoords.long;
            const userLocation = CustomCode.timeSquareCoords.defaultLocation;
            localStorage.setItem('user_login_type', 'Apple');
            if(!localStorage.getItem('monthlyParkingId'))
            {
              $('button.close').trigger('click');
            }
           // localStorage.setItem('loginwithfb','1');
            if(this.parseData.data.user.isAdmin || this.parseData.data.user.isAdminGroup || this.parseData.data.user.roles[0]=='mp_sales' || this.parseData.data.user.roles[0]=='accounts_receivable' || this.parseData.data.user.roles[0]=='customer_service' )
          {
             // alert(4)
              //$('button.close').trigger('click');
              localStorage.setItem('is_admin','true');
              window.location.href=CustomCode.adminUrlLogin+'/redirectto/'+localStorage.getItem('access_token')+'/'+localStorage.getItem('refresh_token')+'/0'
            }else{
            const userLat = CustomCode.timeSquareCoords.lat;
            const userLong = CustomCode.timeSquareCoords.long;
            const userLocation = CustomCode.timeSquareCoords.defaultLocation;
           // $('button.close').trigger('click');
  
           
          //  if ((this.routerObj.url.indexOf('search') > -1 && this.routerObj.url.indexOf('monthly') > -1) || localStorage.getItem('clickBuyMonthly') ) {
          //   $('#loginPopup').hide();
          //   $('#loggeduser').show();
          //  // console.log(localStorage.getItem('monthlyParkingId')+'11111111111111111111111111111111111')
          //   if(localStorage.getItem('monthlyParkingId'))
          //   {
          //     let routeNavigate = '/buy-monthly';
          //     let facId=localStorage.getItem('monthlyParkingId')
          //     let fDate=localStorage.getItem('monthlyParkingDate')
          //     localStorage.removeItem('monthlyParkingId')
          //     localStorage.removeItem('monthlyParkingDate')
          //     localStorage.removeItem('clickBuyMonthly');
          //     //console.log('1111111111111111111');
          //     this.routerObj.navigate([routeNavigate, facId, fDate]);
          //   }
          // $('button.close').trigger('click');
          // }

          // $('button.close').trigger('click');
          if (this.routerObj.url.indexOf('buy-monthly') > -1 ) {
            $('#loginPopup').hide();
            $('#guestuser').hide();
            $('#loggeduser').show();
           // console.log(localStorage.getItem('monthlyParkingId')+'11111111111111111111111111111111111')
          $('button.close').trigger('click');
          }
          
          else if (this.routerObj.url.indexOf('payment') > -1 || this.routerObj.url.indexOf('buy-monthly') > -1) {
            // alert(3333)
              window.location.reload();
              
            } else {
              if (localStorage.getItem('is_landing_page') || this.routerObj.url.indexOf('offer') > -1 ) {
  
                let action_type = localStorage.getItem('action_type');
                let promocode = localStorage.getItem('promocode');
                this.promoCode = promocode
                let routeFullurl =localStorage.getItem('routeUrl');
                let promotion_id = localStorage.getItem('promotion_id');
                let template_slug = localStorage.getItem('template_slug');
                let landing_page_url = "/"+routeFullurl+"/" + template_slug + "/" + promotion_id + "/" + promocode + "/" + action_type;
                if (1) {
                  if (localStorage.getItem('save')) {
                    if (action_type == 'save' && this.routerObj.url.indexOf(routeFullurl) > -1) {
                      //alert(44)
                      this.fetchUserDetails();
                    } else {
                      if(localStorage.getItem('savebylogin')=='true')
                      {
                       // alert(47)
                        this.fetchUserDetails();
                      }else{
                       // alert(48)
                        window.location.reload();
                      }
                      
                    }
                  } else {
                   // alert(38)
                    window.location.reload();
                  }
                }
              }else if(this.routerObj.url.indexOf('icon-rewards-register') > -1 && localStorage.getItem('is_loyalty') == '1'){
                if(this.parseData.data.user.is_loyalty_active){
                 // alert(35)
                  this.routerObj.navigate(['my-account/manage-icon-rewards-details']);
                }else{
                 // alert(34)
                  this.routerObj.navigate(['my-account/manage-icon-rewards-card']);
                }
              } else {
                if(sessionStorage.getItem('email_ref'))
                {
                  sessionStorage.removeItem('email_ref');
                  //alert(8)
                  this.routerObj.navigate(['/my-account/icon-rewards']);
                }else{
                  if(this.parseData.data.user.is_loyalty == 1){
                    if(this.parseData.data.user.is_loyalty_active == 1){
                     // alert(3)
                      this.routerObj.navigate(['my-account/account']);
                      return false;
                      // if(this.parseData.data.user.is_loyalty_profile_completed == 1){
                      //   this.routerObj.navigate(['my-account/account']);
                      //   return false;
                      // }else{
                      //   this.routerObj.navigate(['my-account/manage-icon-rewards-details']);
                      //   return false;
                      // }
                    }else{
                      this.routerObj.navigate(['my-account/manage-icon-rewards-card']);
                      return false;
                    }
                  }
                  //alert(4)
                  this.routerObj.navigate(['/my-account/account']);
                }
              }
              // this.routerObj.navigate(['/search', userLat, userLong, this.currDate,
              // this.facilityRadius, userLocation, CustomCode.defaultFacilityType, CustomCode.searchMode()]);
            }
          }
          }
        },
      error => {
        //FormValidationErrors.displayErrors(error.errors);
        const errorsList = error.errors;
        if(error.status !== 439) {
          if (errorsList.message !== 'Validation Errors') {
            this.toasterObj.error(errorsList.message, 'Sorry!');
          } else {
            for (const key in errorsList.detail) {
              if (errorsList.detail.hasOwnProperty(key)) {
                if (typeof errorsList.detail === 'undefined') {
                  this.toasterObj.error(errorsList.message, 'Sorry!');
                } else {
                  this.toasterObj.error(errorsList.detail[key], 'Sorry!');
                }
              }
            }
          }
        }
      }
      );
  }
  userSignInWithSocial(formValues) {
    //alert(1)
    this.userServiceObj.loginSocialUser(formValues)
      .subscribe(
        (data:any)=> {
         // alert(2)
          this.parseData = data;
          if (this.parseData.status === 201) {
            
          // kochava.activity('Login_with_facebook', {
          //     coupon_id : formValues.name,
          //     email : formValues.email
          //     });
         // alert(3)
            CustomCode.markSocilaLoginUser(this.parseData.data);
            const userLat = CustomCode.timeSquareCoords.lat;
            const userLong = CustomCode.timeSquareCoords.long;
            const userLocation = CustomCode.timeSquareCoords.defaultLocation;
            localStorage.setItem('user_login_type', 'Facebook');
            if(!localStorage.getItem('monthlyParkingId'))
            {
              $('button.close').trigger('click');
            }
            localStorage.setItem('loginwithfb','1');
            if(this.parseData.data.user.isAdmin || this.parseData.data.user.isAdminGroup || this.parseData.data.user.roles[0]=='mp_sales' || this.parseData.data.user.roles[0]=='accounts_receivable' || this.parseData.data.user.roles[0]=='customer_service' )
          {
             // alert(4)
              //$('button.close').trigger('click');
              localStorage.setItem('is_admin','true');
              window.location.href=CustomCode.adminUrlLogin+'/redirectto/'+localStorage.getItem('access_token')+'/'+localStorage.getItem('refresh_token')+'/0'
            }else{
            const userLat = CustomCode.timeSquareCoords.lat;
            const userLong = CustomCode.timeSquareCoords.long;
            const userLocation = CustomCode.timeSquareCoords.defaultLocation;
           // $('button.close').trigger('click');
  
          //  if ((this.routerObj.url.indexOf('search') > -1 && this.routerObj.url.indexOf('monthly') > -1) || localStorage.getItem('clickBuyMonthly') ) {
          //   $('#loginPopup').hide();
          //   $('#loggeduser').show();
          //  // console.log(localStorage.getItem('monthlyParkingId')+'11111111111111111111111111111111111')
          //   if(localStorage.getItem('monthlyParkingId'))
          //   {
          //     let routeNavigate = '/buy-monthly';
          //     let facId=localStorage.getItem('monthlyParkingId')
          //     let fDate=localStorage.getItem('monthlyParkingDate')
          //     localStorage.removeItem('monthlyParkingId')
          //     localStorage.removeItem('monthlyParkingDate')
          //     localStorage.removeItem('clickBuyMonthly');
          //     localStorage.setItem('clickBuyMonthlyFacebook', '1');
          //     //console.log('1111111111111111111');
          //     this.routerObj.navigate([routeNavigate, facId, fDate]);
          //   }
          // $('button.close').trigger('click');
          // }
          
          // $('button.close').trigger('click');
          if (this.routerObj.url.indexOf('buy-monthly') > -1 ) {
            $('#loginPopup').hide();
            $('#guestuser').hide();
            $('#loggeduser').show();
           // console.log(localStorage.getItem('monthlyParkingId')+'11111111111111111111111111111111111')
          $('button.close').trigger('click');
          }
          else if (this.routerObj.url.indexOf('payment') > -1 || this.routerObj.url.indexOf('buy-monthly') > -1) {
              window.location.reload();
              
            } else {
              if (localStorage.getItem('is_landing_page') || this.routerObj.url.indexOf('offer') > -1 ) {
  
                let action_type = localStorage.getItem('action_type');
                let promocode = localStorage.getItem('promocode');
                this.promoCode = promocode
                let routeFullurl =localStorage.getItem('routeUrl');
                let promotion_id = localStorage.getItem('promotion_id');
                let template_slug = localStorage.getItem('template_slug');
                let landing_page_url = "/"+routeFullurl+"/" + template_slug + "/" + promotion_id + "/" + promocode + "/" + action_type;
                if (1) {
                  if (localStorage.getItem('save')) {
                    if (action_type == 'save' && this.routerObj.url.indexOf(routeFullurl) > -1) {
                      this.fetchUserDetails();
                    } else {
                      if(localStorage.getItem('savebylogin')=='true')
                      {
                        this.fetchUserDetails();
                      }else{
                        window.location.reload();
                      }
                      
                    }
                  } else {
                    window.location.reload();
                  }
                }
              }else if(this.routerObj.url.indexOf('icon-rewards-register') > -1 && localStorage.getItem('is_loyalty') == '1'){
                if(this.parseData.data.user.is_loyalty_active){
                  this.routerObj.navigate(['my-account/manage-icon-rewards-details']);
                }else{
                  this.routerObj.navigate(['my-account/manage-icon-rewards-card']);
                }
              } else {
                if(sessionStorage.getItem('email_ref'))
                {
                  sessionStorage.removeItem('email_ref');
                  this.routerObj.navigate(['/my-account/icon-rewards']);
                }else{
                  if(this.parseData.data.user.is_loyalty == 1){
                    if(this.parseData.data.user.is_loyalty_active == 1){
                      
                      this.routerObj.navigate(['my-account/account']);
                      return false;
                      // if(this.parseData.data.user.is_loyalty_profile_completed == 1){
                      //   this.routerObj.navigate(['my-account/account']);
                      //   return false;
                      // }else{
                      //   this.routerObj.navigate(['my-account/manage-icon-rewards-details']);
                      //   return false;
                      // }
                    }else{
                      this.routerObj.navigate(['my-account/manage-icon-rewards-card']);
                      return false;
                    }
                  }
                  this.routerObj.navigate(['/my-account/account']);
                }
              }
              // this.routerObj.navigate(['/search', userLat, userLong, this.currDate,
              // this.facilityRadius, userLocation, CustomCode.defaultFacilityType, CustomCode.searchMode()]);
            }
          }
          }
        },
      error => {
        //FormValidationErrors.displayErrors(error.errors);
        const errorsList = error.errors;
        if (errorsList.message !== 'Validation Errors') {
          this.toasterObj.error(errorsList.message, 'Sorry!');
        } else {
          for (const key in errorsList.detail) {
            if (errorsList.detail.hasOwnProperty(key)) {
              if (typeof errorsList.detail === 'undefined') {
                this.toasterObj.error(errorsList.message, 'Sorry!');
              } else {
                this.toasterObj.error(errorsList.detail[key], 'Sorry!');
              }
            }
          }
        }
      }
      );
  }
  fetchUserDetails() {
    this.myAccountMonthlyParkingObj.fetchUser()
    .then(
      (data:any)=> {
        this.userDetails = data['data'];
        this.validatePromoCode('save');
      },
      error => {
      }
    );
  }

  validatePromoCode(mode) {
    let data = {
        'promocode': this.promoCode,
        'user_id' : this.userDetails.id
      };
    this.userServiceObj.validatePromoCode(data)
    .then(
      (data:any)=> {
        if (data['data'].is_promocode_valid) {
          this.savePromocode();
        } else {
          this.toasterObj.error('Promocode has been expired', 'Sorry!');
          window.location.reload();
        }
      },
      error => {
        if (error.errors.message == "Please log in or create an account to redeem this promo code.") {
          this.toasterObj.error('Please login to your existing account or create a new one in order to save and redeem this promotion.', 'Sorry!');
        } else {
          this.toasterObj.error(error.errors.message, 'Sorry!');
          window.location.reload();
        }
      }
    );
  }

  savePromocode() {
    
    const data = {'promocode' : this.promoCode, 'user_id' : this.userDetails.id};
    this.userServiceObj.addPromoCode(data)
    .then(
      (data:any)=> {

        localStorage.setItem('is_show_landing_strip','false')
        if (!data['errors']) {
            
            if(localStorage.getItem('savebylogin')=='true')
            {
              localStorage.setItem('showEroor','1');
              localStorage.setItem('showEroorMsg','Promocode successfully added, Please make a reservation to redeem this offer.');
              window.location.reload();
            }else{
              this.toasterObj.success('Promocode successfully added, Please make a reservation to redeem this offer.', 'Great!');
              this.navidateToSearch();
            }
            localStorage.removeItem('savebylogin')
        } else {
          
          if(localStorage.getItem('savebylogin')=='true')
          {
              localStorage.removeItem('savebylogin')
              localStorage.setItem('showEroor','2');
              localStorage.setItem('showEroorMsg','Something wrong happened, try again later');
              window.location.reload();
          }
          this.toasterObj.error('Something wrong happened, try again later', 'Sorry!');
        }
      },
      error => {
        localStorage.setItem('is_show_landing_strip','false')
        if(localStorage.getItem('savebylogin')=='true')
          {
              localStorage.setItem('showEroor','2');
              localStorage.setItem('showEroorMsg',error.errors.message);
              window.location.reload();
          }
          else{
             this.toasterObj.error(error.errors.message, 'Sorry!');
             this.navidateToSearch();
          }
        
        
      }
    );
  }

  navidateToSearch() {
    let location = "times-square";
    if(localStorage.getItem('by_cms'))
    {
        location = localStorage.getItem('neighborhood');
        localStorage.removeItem('by_cms');
        this.navigateToLocation_test(location);
    }else
    {
      if (localStorage.getItem('neighborhood')) {
        location = localStorage.getItem('neighborhood');
      }
      if (this.landingLocations[location]) {
        if (this.landingLocations[location].type == "location") {
          this.navigateToLocation(this.landingLocations[location]);
        } else {
          this.navigateToNeighborhood(this.landingLocations[location]);
        }
      } else {
        this.navigateToLocation(this.landingLocations['default']);
      }
     }
  }

  navigateToLocation(details) {
      this.routerObj.navigate([
          '/search', 
          details.lat, 
          details.long, 
          CustomCode.getDateForMapSearchSlashFormat(), 
          CustomCode.facilityDefaultRadius, 
          details.label, 
          CustomCode.defaultFacilityType, 
          CustomCode.searchMode()
      ]);
    }
    
    navigateToLocation_test(details) {
      let details_location=JSON.parse(details);
      this.routerObj.navigate([
          '/search', 
          details_location.Lat, 
          details_location.Long, 
          CustomCode.getDateForMapSearchSlashFormat(), 
          CustomCode.facilityDefaultRadius, 
          details_location.label, 
          CustomCode.defaultFacilityType, 
          CustomCode.searchMode()
      ]);
    }

    navigateToNeighborhood(details) {
      this.routerObj.navigate(['/neighborhoods/', details.slug]);
    }

  showUserLogin() {

  }

  redirectToSignUp() {
    if(localStorage.getItem('monthlyParkingId')){
      localStorage.setItem('notRemove', '1')
    }
    $('button.close').trigger('click');
    this.routerObj.navigate(['/create-account']);
  }
  
  navigateresetPassword() {
      this.routerObj.navigate(['/reset-password']);
      $('button.close').trigger('click');
      $('.sidenav').removeClass('open');
      $('body').removeClass('noscroll');
      $('.sidenavBackDrop').removeClass('open');
    }

  continueAsGuest() {
    $('button.close').trigger('click');
    const userLat = CustomCode.timeSquareCoords.lat;
    const userLong = CustomCode.timeSquareCoords.long;
    const locationUser = CustomCode.timeSquareCoords.defaultLocation;
    this.routerObj.navigate(['/search', userLat, userLong, this.currDate, this.facilityRadius, locationUser, CustomCode.defaultFacilityType, CustomCode.searchMode()]);
  }
  getVisitorCode() {
   this.userServiceObj.getVisitorCode()
      .subscribe(
      (data:any) => {
        //console.log("getVisitorCode data",data);
        // var test = JSON.parse(data);
        
         localStorage.setItem('X-Big-Apple', data.data.visitor_code);
       },
      error => {
        let errorMessage = error.errors.message;
      }
    );
  }
  
}
