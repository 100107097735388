import { Component, OnInit } from '@angular/core';

import { StaticpagesService } from '../../services/staticpages.service';

@Component({
  selector: 'app-oversized',
  templateUrl: './oversized.component.html',
  styleUrls: ['./oversized.component.scss'],
  providers : [StaticpagesService]
})
export class OversizedComponent implements OnInit {

  oversizesContent:any = {};
  private pageId = 'oversized-vehicles';
  isLoaded = false;
  isFrame = false;

  constructor( private staticObj: StaticpagesService ) { }

  ngOnInit() {

    if(window.self!==window.top)
    {
      this.isFrame = true;
    }else{
      this.isFrame = false;
    }


      this.staticObj.getCmsPageContent(this.pageId).subscribe( (data:any)=> {
        this.oversizesContent = data.result[0];
        this.isLoaded = true;
      },(error) => { console.log('Error: ' + JSON.stringify(error)); 
        this.isLoaded = true;
    }
     )



  }



}
