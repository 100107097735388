import { Injectable } from '@angular/core';
// import { AppModule } from ''
//import { Http, Headers, RequestOptions, Response, Request, RequestMethod, ResponseContentType } from '@angular/http';
import { HttpClient,HttpHeaders } from '@angular/common/http';

import { Router } from '@angular/router';
import { HttpService } from './http.service';
import { Observable } from 'rxjs/Rx';
import { CustomCode } from '../shared/CustomCode';
import { ToastrService } from 'ngx-toastr';

// Import RxJs required methods
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { DomSanitizer } from '@angular/platform-browser';
import { StaticpagesService } from './staticpages.service';
@Injectable({
  providedIn: 'root'
})
export class UserserviceService {

  constructor(private sanitizer: DomSanitizer,private http: HttpService,private https?:HttpClient, toastr?: ToastrService, router?: Router,private staticPageService?: StaticpagesService, ) { 

  }

  private addUser = CustomCode.APIURL + 'user';
  private loginUrl = CustomCode.APIURL + 'oauth/access_token';
  private loginSocialUrl = CustomCode.APIURL + 'user/social';
  private loginCheckApi=CustomCode.APIURL + 'check-social-user-email';

  // Monthly Parking Internal Pages API URL's
  private getUsersDetails = CustomCode.APIURL + 'api';
  private getUsersPromocodeDetails = CustomCode.APIURL + 'userPromoUsage';
  private addNewPromoCode = CustomCode.APIURL + 'adduserpromocode';
  private deleteUserPromoCode = CustomCode.APIURL + 'deleteUserPromoCode';
  private getUsersMonthlyAccountURL = CustomCode.APIURL + 'monthly-parking/account/';
  private monthlyParkingRegisterURL = CustomCode.APIURL + 'monthly-parking/register/';
  private getVisitorCodeURL = CustomCode.APIURL + 'visitor/';
  private decryptURL = CustomCode.APIURL + 'decrypt/user';
  private regLoyaltyURL = CustomCode.APIURL + 'loyalty/register';
  private getWalletURL = CustomCode.APIURL + 'user/wallets';
  private getWalletConfigURL = CustomCode.APIURL + 'wallet/configs';
  private getProfileURL = CustomCode.APIURL + 'loyalty';
  private updateProfileURL = CustomCode.APIURL + 'loyalty/update-profile';
  private getCardUrl = CustomCode.APIURL + 'loyalty/cards';
  private getCardUrlDetails = CustomCode.APIURL + 'loyalty/card';
  private addCardUrl = CustomCode.APIURL + 'loyalty/card';
  private getmantinanceMgsUrl = CustomCode.APIURL+ 'web-notifications';
  private getLoyatlyHistoryUrl = CustomCode.APIURL+ 'loyalty/history';
  private emailExistUrl = CustomCode.APIURL+ 'validate-user';
  private deleteCard=CustomCode.APIURL+ 'user/authorizenet/payment';
  private updateUserProfileURL = CustomCode.APIURL+ 'update-profile';
  private addCorporateUserURL = CustomCode.APIURL+ 'add-referred-corporate-user';
  private getCorporatePartnerURL = CustomCode.APIURL+ 'corporate-partner';
  private getTierInfoURL = CustomCode.APIURL + 'loyalty/get-user-tier';
  private loginSocialUrlapple = CustomCode.APIURL + 'user/social-apple';
  private versionUrl = CustomCode.APIURL + 'mobile-device-version/web';

  registerUser(userData) {

    const options = CustomCode.returnHeaders();
    const formDetails = {'username':userData.email,'grant_type': CustomCode.loginGrantType, 'client_id': CustomCode.clientId,
    'client_secret': CustomCode.clientSecret, 'name': userData.name, 'email': userData.email, 'password': userData.password, 'phone': userData.phone};

    return this.http.post(this.addUser, formDetails, options)
      .map((res: any) => res)
      .catch((error: any) => Observable.throw(error.error));
  }

  registerLoyaltyUser(userData) {
    const options = CustomCode.returnHeaders();
    const formDetails = {'iq_code':userData.iq_code,'username':userData.email,'grant_type': CustomCode.loginGrantType, 'client_id': CustomCode.clientId,
    'client_secret': CustomCode.clientSecret, 'name': userData.name, 'email': userData.email, 'password': userData.password, 'phone': userData.phone, 'is_loyalty': userData.is_loyalty,'invitation_code': userData.invitation_code};
    return this.http.post(this.addUser, formDetails, options)
      .map((res: Response) => res)
      .catch((error: any) => Observable.throw(error.error));
  }

  LoyaltyUpdateProfile(userData) {
    const options = CustomCode.returnHeaders();
    return this.http.put(this.updateProfileURL, userData, options)
      .map((res: Response) => res)
      .catch((error: any) => Observable.throw(error.error));
  }

  LoyaltyAddCard(userData) {
    const cardHeader = CustomCode.returnHeaders();
    return this.https.post(this.addCardUrl, userData, {headers:cardHeader})
      .map((res: Response) => res)
      .catch((error: any) => Observable.throw(error.error));
  }
  
  LoyaltyGetCards() {
    const options = CustomCode.returnHeaders();
    return this.http.get(this.getCardUrl, options)
      .map((res: Response) => res)
      .catch((error: any) => Observable.throw(error.error));
  }

  LoyaltyGetCard(cardId) {
    const options = CustomCode.returnHeaders();
    return this.http.get(this.getCardUrlDetails+"/"+cardId, options)
      .map((res: Response) => res)
      .catch((error: any) => Observable.throw(error.error));
  }

  LoyaltyRemvoeCard(cardId){
    const options = CustomCode.returnHeaders();
    return this.http.delete(this.getCardUrlDetails+"/"+cardId, options)
      .map((res: Response) => res)
      .catch((error: any) => Observable.throw(error.error));
  }

  EncrypInformation(encrptedData) {
    const options = CustomCode.returnHeaders();
    return this.http.post(this.decryptURL, encrptedData, options)
      .map((res: Response) => res)
      .catch((error: any) => Observable.throw(error.error));
  }

  RegisterToLoyalty(userData) {
    const options = CustomCode.returnHeaders();
    return this.http.put(this.regLoyaltyURL, userData, options)
      .map((res: Response) => res)
      .catch((error: any) => Observable.throw(error.error));
  }

  isEmailExist(userData) {
    const options = CustomCode.returnHeaders();
    return this.http.post(this.emailExistUrl, userData, options)
      .map((res: Response) => res)
      .catch((error: any) => Observable.throw(error.error));
  }

  GetLoyaltyWallet(){
    const options = CustomCode.returnHeaders();
    return this.http.getwithheader(this.getWalletURL, options)
      .map((res: Response) => res)
      .catch((error: any) => Observable.throw(error.error));
  }

  GetLoyaltyWalletConfig(){
    const options = CustomCode.returnHeaders();
    return this.http.get(this.getWalletConfigURL, options)
      .map((res: Response) => res)
      .catch((error: any) => Observable.throw(error.error));
  }

  GetLoyaltyProfile(){
    const options = CustomCode.returnHeaders();
    return this.http.getwithheader(this.getProfileURL, options)
      .map((res: Response) => res)
      .catch((error: any) => Observable.throw(error.error));
  }
  
  GetLoyaltyHistory(){
    const options = CustomCode.returnHeaders();
    return this.http.get(this.getLoyatlyHistoryUrl, options)
      .map((res: Response) => res)
      .catch((error: any) => Observable.throw(error.error));
  }

  handleSessionRefresh() {
    const refTokenData = {
      'grant_type': 'refresh_token', 
      'client_id': CustomCode.clientId,
      'client_secret': CustomCode.clientSecret, 
      'refresh_token': localStorage.getItem('refresh_token'), 
      'remember_me': null
    };
    this.refreshToken(refTokenData)
      .subscribe(
      (data:any) => {
        let responseData = data.data;
        localStorage.setItem('user', responseData.user);
        localStorage.setItem('access_token', responseData.access_token);
        localStorage.setItem('refresh_token', responseData.refresh_token);
        localStorage.setItem('expires_in', responseData.expires_in);
        localStorage.setItem('token_type', responseData.token_type);
        window.location.reload();
      },
      error => {
        return false;
      }
    );
  }

  refreshToken(data) {
    return this.http.post(this.loginUrl, data)
    .map(
      (res: Response) => res
    )
    .catch(
      (error: any) => Observable.throw(
        error.error
      )
    );
  }

  loginUser(userData) {
    const options = CustomCode.returnHeaders();

    const formDetails = {'grant_type': CustomCode.loginGrantType, 'client_id': CustomCode.clientId,
    'client_secret': CustomCode.clientSecret, 'username': userData.email, 'password': userData.password};

    return this.http.postnew(this.loginUrl, formDetails, options)
    .map((res: Response) => res)
    .catch((error: any) => Observable.throw(error.error));
  }
  loginUserWithUserId(userData) {
    const options = CustomCode.returnHeaders();

    const formDetails = {'grant_type':'user_id_login', 'client_id': CustomCode.clientId,
    'client_secret': CustomCode.clientSecret, 'username': userData.id, 'password': ''};

    return this.http.post(this.loginUrl, formDetails, options)
    .map((res: Response) => res)
    .catch((error: any) => Observable.throw(error.error));
  }
  loginSocialUser(userData) {
    const options = CustomCode.returnHeaders();

    const formDetails = {'for_register':userData.for_register,'social_id':userData.social_id, 'social_type':userData.social_type, 'grant_type': CustomCode.loginGrantTypeSocial, 'client_id': CustomCode.clientId,
    'client_secret': CustomCode.clientSecret, 'email': userData.email,'name':userData.name};

    return this.http.post(this.loginSocialUrl, formDetails, options)
    .map((res: Response) => res)
    .catch((error: any) => Observable.throw(error.error));
  }
  loginCheckSocialUser(userData) {
    const options = CustomCode.returnHeaders();

    const formDetails = {'social_id':userData, 'social_type':0};

    return this.http.post(this.loginCheckApi, formDetails, options)
    .map((res: Response) => res)
    .catch((error: any) => Observable.throw(error.error));
  }
  fetchUser() {
    return new Promise((resolve, reject) => {
      this.http.getwithheader(this.getUsersDetails).subscribe(
        (res:any) => {
          resolve(res);
        },
        error => {
          reject(error.error);
        }
      );
    });
  }

  fetchMyPromoCodes(data) {
    return new Promise((resolve, reject) => {
      this.http.post(this.getUsersPromocodeDetails, data)
      .map(data => {
        return data
      })
      .subscribe(
        (res:any) => {
          //console.log("fetchMyPromoCodes",res);
          resolve(res);
        },
        error => {
          reject(error.error);
        }
      );
    });
  }

  fetchWalletConfig() {
    return new Promise((resolve, reject) => {
      this.http.get(CustomCode.APIURL + 'icongo/walletconfig').subscribe(
        (res:any) => {
          resolve(res);
        },
        error => {
          reject(error.error);
        }
      );
    });
  }

  fetchUserCoupons(userId) {
    return new Promise((resolve, reject) => {
      this.http.getwithheader(CustomCode.APIURL + 'user/' + userId + '/coupon/page/1').subscribe(
        (res:any) => {
          resolve(res);
        },
        error => {
          reject(error.error);
        }
      );
    });
  }

  fetchUserSavedCards() {
    return new Promise((resolve, reject) => {
      this.http.getwithheader(CustomCode.APIURL + 'user/authorizenet/cim').retry(2).subscribe(
        (res:any) => {
          resolve(res);
        },
        error => {
          reject(error.error);
        }
      );
    });
  }

  fetchNextPrevCoupons(userId, pageNo) {
    return new Promise((resolve, reject) => {
      this.http.get(CustomCode.APIURL + 'user/' + userId + '/coupon/page/' + pageNo).subscribe(
        (res:any) => {
          resolve(res);
        },
        error => {
          reject(error.error);
        }
      );
    });
  }

  fetchUserReservations(userId) {
    return new Promise((resolve, reject) => {
      this.http.getwithheader(CustomCode.APIURL + 'user/' + userId + '/reservation/page/1').subscribe(
        (res:any) => {
          resolve(res);
        },
        error => {
          reject(error.error);
        }
      );
    });
  }

  fetchNextPrevReservations(userId, url) {
    return new Promise((resolve, reject) => {
      this.http.getwithheader(CustomCode.APIURL + 'user/' + userId + '/reservation/page/' + url).subscribe(
        (res:any) => {
          resolve(res);
        },
        error => {
          reject(error.error);
        }
      );
    });
  }

  cancelReservation(id) {
    return new Promise((resolve, reject) => {
      this.http.postnew(CustomCode.APIURL + 'reservation/' + id + '/cancel', {}).subscribe(
        (res:any) => {
          resolve(res);
        },
        error => {
          reject(error.error);
        }
      );
    });
  }
  downloadPaymentReceipts(id,account)
  {
    const headers = new HttpHeaders();
    const access_token = localStorage.getItem('access_token');
    headers.append('Authorization', 'Bearer ' + access_token);
    //const options = new RequestOptions({ headers: headers, responseType: ResponseContentType.Blob });
    return new Promise((resolve, reject) => {
      this.http.getimage(CustomCode.APIURL + 'monthly-parking/account/' + account + '/' +id+ '/payment/pdf', headers).subscribe(
        (res:any) => {
          //console.log(res);
          resolve(res);
        },
        error => {
          //console.log(error);
          reject(error.error);
        }
      );
    });
  }
  printReservation(id) {
    const headers = new HttpHeaders();
    const access_token = localStorage.getItem('access_token');
    headers.append('Authorization', 'Bearer ' + access_token);
    //const options = new RequestOptions({ headers: headers, responseType: ResponseContentType.Blob });
    return new Promise((resolve, reject) => {
      this.http.getimage(CustomCode.APIURL + 'reservation/' + id + '/jpg', headers).subscribe(
        (res:any) => {
          //console.log("printReservation",res);
          resolve(res);
          
        },
        error => {
          //console.log("printReservation error",error);
          reject(error.error);
        }
      );
    });
    // return new Promise((resolve, reject) => {
    //   this.http.get(CustomCode.APIURL + 'reservation/' + id + '/jpg').subscribe(
    //     (res:any)=> {
    //       resolve(res.json());
    //     },
    //     error => {
    //       reject(JSON.parse(error['_body']));
    //     }
    //   );
    // });
  }
  
  printReservationByEmail(resCode,ticketechCode){
    const headers = new HttpHeaders();
    //const access_token = localStorage.getItem('access_token');
    //headers.append('Authorization', 'Bearer ' + access_token);
    //const options = new RequestOptions({ headers: headers, responseType: ResponseContentType.Blob });
	//https://test-api.iconparkingsystems.com/reservation/19c87f0f864f6fc/IQ216853/jpg
    return new Promise((resolve, reject) => {
      this.http.getimage(CustomCode.APIURL + 'reservation/' + resCode + '/' + ticketechCode + '/jpg', headers).subscribe(
        (res:any) => {
          resolve(res);
        },
        error => {
          reject(error.error);
        }
      );
    });
  }
  
   fetchUserReservationsByCode(canResCode,canTicketechCode) {
	   //https://test-api.iconparkingsystems.com/reservation/19c87f0f864f6fc/IQ216853
	   //CustomCode.APIURL + 'reservation/' + canResCode + '/'+canTicketechCode
    return new Promise((resolve, reject) => {
      this.http.get(CustomCode.APIURL + 'reservation/' + canResCode + '/'+canTicketechCode).subscribe(
        (res:any) => {
          resolve(res);
        },
        error => {
          reject(error.error);
        }
      );
    });
  }
  
  cancelReservationByEmail(resCode,ticketechCode,reservationUserDetailsid){
	  ///reservation/cancel/19c87f0f864f6fc/IQ216853
    return new Promise((resolve, reject) => {
      this.http.post(CustomCode.APIURL + 'reservation/cancel/' + resCode + '/'+ticketechCode, reservationUserDetailsid).subscribe(
        (res:any) => {
          resolve(res);
        },
        error => {
          reject(error.error);
        }
      );
    });
  }

  userReferral(data) {
    return new Promise((resolve, reject) => {
      this.http.post(CustomCode.APIURL + 'user/getreferralcode', data).subscribe(
        (res:any) => {
          resolve(res);
        },
        error => {
          reject(error.error);
        }
      );
    });
  }

  fetchUserWallet(userId) {
    let data = { 'user_id' : userId };
    return new Promise((resolve, reject) => {
      this.http.post(CustomCode.APIURL + 'user/mywallet', data)
      .map(data => data)
      .subscribe(
        (res:any) => {
          //console.log("fetchUserWallet",res);
          resolve(res);
        },
        error => {
          reject(error.error);
        }
      );
    });
  }

  deleteUserCoupon(couponId) {
    return new Promise((resolve, reject) => {
      this.http.delete(CustomCode.APIURL + 'user/coupon/' + couponId).subscribe(
        (res:any) => {
          resolve(res);
        },
        error => {
          reject(error.error);
        }
      );
    });
  }
  deleteUserCard(id) {
    //let data ={"payment_profile_id": id}
    return this.http.delete(CustomCode.APIURL + 'user/authorizenet/payment?payment_profile_id='+id)
   .map((res: Response) => res)
   .catch((error: any) => Observable.throw(error.error));

  }

  addPromoCode(data) {
    return new Promise((resolve, reject) => {
      this.http.postnew(this.addNewPromoCode, data).subscribe(
        (res:any) => {
          //console.log(res);
          resolve(res);
        },
        error => {
          //console.log(error);
          reject(error.error);
        }
      );
    });
  }

  deletePromoCode(data) {
    return new Promise((resolve, reject) => {
      this.http.postnew(this.deleteUserPromoCode, data).subscribe(
        (res:any) => {
          resolve(res);
        },
        error => {
          reject(error.error);
        }
      );
    });
  }

  validatePromoCode(data) {
    
    return new Promise((resolve, reject) => {
      console.log(CustomCode.APIURL + 'checkpromo')
      console.log(data)
      this.http.post(CustomCode.APIURL + 'checkpromo', data).subscribe(
        (res:any) => {
          resolve(res);
        },
        error => {
          console.log("validatePromoCode",error);
          reject(error.error);
        }
      );
    });
  }

  getAccountDetails(accountNumber) {
    return new Promise((resolve, reject) => {
      this.http.get(this.getUsersMonthlyAccountURL + accountNumber).subscribe(
        (res:any) => {
          resolve(res);
        },
        error => {
          reject(error.error);
        }
      );
    });
  }

  monthlyParkingRegister(data) {
    return new Promise((resolve, reject) => {
      this.http.post(this.monthlyParkingRegisterURL, data).subscribe(
        (res:any) => {
          resolve(res);
        },
        error => {
          reject(error.error);
        }
      );
    });
  }

  autoPay(accountNumber) {
    return new Promise((resolve, reject) => {
      this.http.get(this.getUsersMonthlyAccountURL + accountNumber + '/autopay').subscribe(
        (res:any) => {
          resolve(res);
        },
        error => {
          reject(error.error);
        }
      );
    });
  }

  deleteAccount(accountNumber) {
    return new Promise((resolve, reject) => {
      this.http.delete(this.getUsersMonthlyAccountURL + accountNumber).subscribe(
        (res:any) => {
          resolve(res);
        },
        error => {
          reject(error.error);
        }
      );
    });
  }

  addCreditCard(data, accountNumber) {
    return new Promise((resolve, reject) => {
      this.http.post(this.getUsersMonthlyAccountURL + accountNumber + '/payment-method', data).subscribe(
        (res:any) => {
          resolve(res);
        },
        error => {
          reject(error.error);
        }
      );
    });
  }

  addBankAccount(data, accountNumber) {
    return new Promise((resolve, reject) => {
      this.http.post(this.getUsersMonthlyAccountURL + accountNumber + '/payment-method', data).subscribe(
        (res:any) => {
          resolve(res);
        },
        error => {
          reject(error.error);
        }
      );
    });
  }

  currentInvoice(accountNumber) {
    return new Promise((resolve, reject) => {
      this.http.get(this.getUsersMonthlyAccountURL + accountNumber + '/current-invoice').subscribe(
        (res:any) => {
          resolve(res);
        },
        error => {
          reject(error.error);
        }
      );
    });
  }

  previousInvoices(accountNumber, pageNum) {
    return new Promise((resolve, reject) => {
      this.http.get(this.getUsersMonthlyAccountURL + accountNumber + '/past-invoice?page=' + pageNum).subscribe(
        (res:any) => {
          resolve(res);
        },
        error => {
          reject(error.error);
        }
      );
    });
  }

  getVisitorCode(){
    const options = CustomCode.returnHeaders();

     return this.http.post(CustomCode.APIURL+'visitor',  {})
    .map((res: Response) => {
      //console.log("getVisitorCode",res);
      return res;
    })
    .catch((error: any) => Observable.throw(error.error));
  }

  getVisitorCouponTracked(data)
  {  
     var couponId = data['couponId'];
     const options = CustomCode.returnHeaders();

     return this.http.post(CustomCode.APIURL+'visitor/coupon/'+couponId,  data)
    .map((res: Response) => res)
    .catch((error: any) => Observable.throw(error.error));

  }

  getReferrerForVisitor()
  {
    let referrerUrl = localStorage.getItem('referrerUrl');
    let data =  {'referrer' :referrerUrl };             
    return this.http.postnew(CustomCode.APIURL+'visitor/referral', data)
      .map((res: Response) => res)
      .catch((error: any) => Observable.throw(error.error));
  }
  
  
   getMantinanceMsg(){
    return this.http.get(this.getmantinanceMgsUrl)
    .map( (res:Response)=> res )
    .catch((error:any)=> Observable.throw(error) );
  }

  updateProfile(userData){
    const options = CustomCode.returnHeaders();
    return this.http.put(this.updateUserProfileURL, userData, options)
      .map((res: Response) => res)
      .catch((error: any) => Observable.throw(error.error));
  }

  AddCorporateUser(userData){
    const options = CustomCode.returnHeaders();
    return this.http.postnew(this.addCorporateUserURL, userData, options)
      .map((res: Response) => res)
      .catch((error: any) => Observable.throw(error.error));
  }

  getCorporatePartner(userData){
    const options = CustomCode.returnHeaders();
    return this.http.getwithheader(this.getCorporatePartnerURL+'/'+userData)
    .map( (res:Response)=> res )
    .catch((error:any)=> Observable.throw(error.error) );
  }

  GetTierInfo(){
    const options = CustomCode.returnHeaders();
    return this.http.getwithheader(this.getTierInfoURL, options)
      .map((res: Response) => res)
      .catch((error: any) => Observable.throw(error));
  }

  loginSocialUserapple(userData) {
    const options = CustomCode.returnHeaders();

    const formDetails = {'for_register':userData.for_register,'social_id':userData.social_id, 'social_type':userData.social_type, 'grant_type': CustomCode.loginGrantTypeSocial, 'client_id': CustomCode.clientId,
    'client_secret': CustomCode.clientSecret, 'email': userData.email,'name':userData.name};

    return this.http.postnew(this.loginSocialUrlapple, formDetails, options)
    .map((res: Response) => res)
    .catch((error: any) => Observable.throw(error.error));
  }
  checkVersion()
  {
    const options = CustomCode.returnHeaders();
    return this.http.get(this.versionUrl, options)
      .map((res: Response) => res)
      .catch((error: any) => Observable.throw(error.error));
  }

  validatePromoCodeMonthly(data) {
    return new Promise((resolve, reject) => {
      this.http.post(CustomCode.APIURL + 'checkpromo-monthly', data).subscribe(
        (res:any) => {
          resolve(res);
        },
        error => {
          reject(error.error);
        }
      );
    });
  }
  
  

  
}

