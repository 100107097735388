import { Component, OnInit } from '@angular/core';
import { UserserviceService } from '../../services/userservice.service';
import { CustomCode } from '../../shared/CustomCode';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { FacebookService, InitParams, LoginResponse,LoginOptions } from 'ngx-facebook';
import * as $ from 'jquery';
@Component({
  selector: 'app-manage-details',
  templateUrl: './manage-details.component.html',
  styleUrls: ['./manage-details.component.scss'],
  providers: [UserserviceService]
})
export class ManageDetailsComponent implements OnInit {
  isLoaded: boolean = false;
  user = {
    first_name: '',
    last_name: '',
  }
  userEmail = '';
  icon_rewards_logo_wt = 'assets/img/icon-rewards-logo-wt.svg';
  constructor(private userServiceObj: UserserviceService, private routeObj: Router,
    private toasterObj: ToastrService, private activatedRouteObj: ActivatedRoute, private fb: FacebookService) { }
  
  ngOnDestroy() {
    $('#menus1').removeClass('active');
  }

  ngOnInit() {
    $('#menus1').addClass('active');
    this.userServiceObj.fetchUser().then(
      (data:any)=> {
        let uData = data['data'];
        // let fullName = uData.name;
        // let fname = (fullName.substr(0,fullName.indexOf(' '))!='')?fullName.substr(0,fullName.indexOf(' ')):fullName.substr(fullName.indexOf(' ')+1);
        // let lname = (fullName.substr(0,fullName.indexOf(' '))=='')?'':fullName.substr(fullName.indexOf(' ')+1);
        // this.user.first_name = fname;
        // this.user.last_name = lname;
        this.userEmail = uData.email;
        this.isLoaded = true;
      },
      err => {
        this.isLoaded = true;
      }
    );
  }

  saveUserInfo(user, formRef) {
    this.isLoaded = true;
    let fName = user.first_name;
    let lName = user.last_name;
    let name = fName + ' ' + lName;
    let userInfo = { name: name }

    let isValid = CustomCode.isValidName(userInfo.name);
    if (!isValid) {
      this.toasterObj.error('Not a valid input value.', 'Sorry!');
      return false;
    }

    this.userServiceObj.updateProfile(userInfo)
      .subscribe(
        (data:any) => {
          this.isLoaded = true;
          let respdata = data.data;
          localStorage.setItem('username', respdata.name);
          localStorage.setItem('invalidName', "0");
          this.toasterObj.success('User Information Updated Successfully.', 'Great!');
          if(localStorage.getItem('is_loyalty') === "0" && localStorage.getItem('is_loyalty_active') === "0"){
            window.location.href = '/my-account/enroll-in-icon-rewards';
            // this.routeObj.navigate(['/my-account/enroll-in-icon-rewards']);
            return false;
          }else if(localStorage.getItem('is_loyalty') === "1" && localStorage.getItem('is_loyalty_active') === "0"){
            window.location.href = '/my-account/manage-icon-rewards-card';
            // this.routeObj.navigate(['/my-account/manage-icon-rewards-card']);
            return false;
          }else{
            // this.routeObj.navigate(['my-account/icon-rewards']);
            window.location.href = '/my-account/icon-rewards';
            return false;
          }
        },
        error => {
          this.toasterObj.error(error.errors.message, 'Sorry!');
          this.isLoaded = true;
        }
      );
  }

  restChar(event: any) {
    if (!CustomCode.restricSpecialChar(event)) {
      event.preventDefault();
    }
  }

  // restChar(event: any) {
  //   if (!CustomCode.notAllowChar(event)) {
  //     event.preventDefault();
  //   }
  // }

  onPaste(event: any) {
    if (!CustomCode.restricPaste(event)) {
      event.preventDefault();
    }
  }

  logOut() {
    let currentUrl = window.location.href;
    let fbl = 0
    let gml = 0
    if (localStorage.getItem('loginwithfb')) {
      fbl = 1
    }
    if (localStorage.getItem('loginwithgm')) {
      gml = 1
    }
    CustomCode.userLogout();
    CustomCode.displayLoginUser();
    if (fbl) {
      this.fb.logout().then((res: LoginResponse) => console.log('Logged out!'));
    }

    if (this.routeObj.url.indexOf('payment') > -1 || this.routeObj.url.indexOf('buy-monthly') > -1) {
      if (gml) {
        window.open("https://www.google.com/accounts/Logout?continue=https://appengine.google.com/_ah/logout?continue=https://gmail.com", '_blank')
        document.location.href = "https://www.google.com/accounts/Logout?continue=https://appengine.google.com/_ah/logout?continue=" + CustomCode.BASEURL;
      } else {
        window.location.reload();
      }
    } else {
      if (gml) {
        window.open("https://www.google.com/accounts/Logout?continue=https://appengine.google.com/_ah/logout?continue=https://gmail.com", '_blank')
        document.location.href = CustomCode.BASEURL;
      } else {
        window.location.href = CustomCode.BASEURL;
      }
    }
  }
}
