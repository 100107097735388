<app-header></app-header>
<!-- for desktop payment page -->
<div class="payment-section orangeBar mainPaymentPage  ">
    <div *ngIf="isShowingStrip" class="swowingString" [class.isLoyaltyMember]="isLoyaltyMember"></div>
    <div *ngIf="isLoyaltyMember" class="hidden-xs " style="margin-top:95px"></div>
    <div *ngIf="!isLoyaltyMember" class="hidden-xs " style="margin-top:95px"></div>
    <div *ngIf="isLoyaltyMember" class="visible-xs " style="margin-top:50px"></div>
    <div *ngIf="!isLoyaltyMember" class="visible-xs " style="margin-top:90px"></div>
    <div *ngIf="!isLoyaltyMember" class="visible-xs"></div>
    <div class="clearfix"></div>
    <div class="container" *ngIf="isLoaded">
        <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-12 col-md-offset-1 col-lg-offset-1 col-lg-10">
                <!-- <ol class="breadcrumb pad-top-0 pad-bottom-0 pad-left-0">
                    <li class="active">
                        <a href="javascript:void(0)" onclick="history.go(-1);" class="btn btn-link pad-left-0">
                            <fa name="angle-left"></fa> &nbsp; Back</a>
                    </li>
                </ol> -->
                <!-- <div class="paymentImgWrap mar-bottom-sm">
                    <img *ngIf="respdata?.photos" [src]="respdata?.photos.url" class="img-responsive full-width" height="234" alt="image">
                    <img *ngIf="!respdata?.photos" [src]="park_garageIcon" class="img-responsive full-width" height="234" alt="image">
                </div> -->
                <form (ngSubmit)="f.form.valid && dailyPayment(formData, f)" #f="ngForm" novalidate method="POST" role="form" name="paymentForm" id="floating-label-payment-comp" class="floating-label">
                    <div id="server-errors" class="alert" style="display: none;">
                    </div>
                    <div class="top-tab-in-payment">
                        <div class="wizard">
                            <div class="wizard-inner">
                                <div class="connecting-line"></div>
                                <div class="first-line-active"></div>
                                <!-- <div class="first-line-success"></div> -->
                                <ul class="nav nav-tabs" role="tablist">
                
                                    <li role="presentation" class="active1">
                                        <a>
                                            <span class="round-tab">
                                                <i class="fa fa-chevron-left"></i>
                                            </span>
                                            <b>Search Results</b>
                                        </a>
                                    </li>
                
                                    <li role="presentation" class="active1 active">
                                        <a  data-toggle="tab" aria-controls="dailypaymenttab" role="tab">
                                            <span class="round-tab">
                                                <i class="fa fa-check"></i>
                                            </span>
                                            <b style="left: -44px;">Payment & Checkout</b>
                                        </a>
                                    </li>
                                    <li role="presentation" class="disabled">
                                        <a >
                                        <!-- <a href="#complete" data-toggle="tab" aria-controls="complete" role="tab"> -->
                                            <span class="round-tab">
                                                <i class="fa fa-check"></i>
                                            </span>
                                            <b>Booking Confirmed</b>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                
                                <div class="tab-content">
                                    <!-- <div class="tab-pane" role="tabpanel" id="dailysearch">
                                        <h3>Step 1</h3>
                                        <p>This is step 1</p>
                                        <ul class="list-inline pull-right">
                                            <li><button type="button" class="btn btn-primary next-step">Save and continue</button></li>
                                        </ul>
                                    </div> -->
                                    <div class="tab-pane active" role="tabpanel" id="dailypaymenttab"></div>
                                </div>
                            </div>
                    </div>

                    
                    <div class="row">
                        <div class="col-sm-12" *ngIf="showMsg">
                            <div class="month-box-top">
                              <table><tr><td><img src="/assets/img/v8-design/time-icon-red.svg"></td><td>{{msg}} at this rate in this location at {{availDate}}. <a >Lock in your price now.</a></td></tr></table>
                            </div>              
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-12 col-sm-8 col-md-8 col-lg-8">
                            <div class="white-box-sdw srink-box">
                            <h1 class="mar-top-0 mar-bottom-xs top-text-details">{{respdata?.facility_name_modified}}</h1>
                            <h3 class="mar-top-0 top-text-details-2" *ngIf="respdata?.geolocations?.address_1">Located at {{respdata?.geolocations?.address_1}}</h3>
                            <h3 class="mar-top-0 top-text-details-2" *ngIf="respdata?.between_streets"><span>Between {{respdata?.between_streets}}</span></h3>
                            <input type="hidden" id="display_name" value="{{respdata?.display_name}}" />
                            <p class="mar-top-sm mar-bottom-sm daily-phone"><b><i class=" fa text-primary fa-phone "></i><span> {{phone_number}}</span></b></p>
                            <div class="payment-time-search">
                                <ng-template #MTSPTemplate>
                                    <div class="more-time-same-price-box" [innerHtml]="MTSMMessage"></div>
                                </ng-template>
                                <!-- <p *ngIf="isUpdatedEndTimeFlag" class="accent mar-bottom-xs text-center"><span [popover]="MTSPTemplate" container="body" [outsideClick]="true" placement="bottom" containerClass="more-time-same-price-box mob-mts-rt more-space-reservation-pay" placement="bottom" class="label label-primary more-space-reservation moretime-res-border-btn">More Time Same Price</span></p> -->
                                <app-search-filters [showTies]="false" [ngClass]="(isUpdatedEndTimeFlag)?'moretime-res-border-payment':''" [bookingView]="true" (updateFilters)="receiveNewSearchFilter($event)"></app-search-filters>
                            </div>

                            <div class="clearfix mar-top-xs">
                                <table class="blockTable" style="width:100%">
                                    <tr *ngIf="!this.loyalty.payRewards">
                                        <td colspan="6">
                                            <div  class="add-rounded-box lable-text" [ngClass]="{'active': this.is_bonus_hour_applied}" *ngIf="bonus_rate > 0 && bonus_hours > 0" id="selectBonusHour">
                                                <div class="checkbox">
                                                        <label>
                                                    <input class="radio radio_alt" type="checkbox" [(ngModel)]="is_bonus_hour_applied" (ngModelChange)="updateBonusHour()"  name="selectBonusHour" id="bonusHour" value="" />
                                                    <strong *ngIf="(bonus_hours % 1 == 0)">Add {{removeDotInHour(bonus_hours)}} extra {{bonus_hours>1 ?'hours' : 'hour'}} for <ng-container *ngIf="(bonus_rate % 1 == 0)">${{ bonus_rate | number }}</ng-container><ng-container *ngIf="(bonus_rate % 1 != 0)">${{ bonus_rate | number:'1.2-2' }}</ng-container></strong>
                                                    <strong *ngIf="(bonus_hours % 1 != 0)">Add {{bonus_hours}} extra {{bonus_hours>1 ?'hours' : 'hour'}} for <ng-container *ngIf="(bonus_rate % 1 == 0)">${{ bonus_rate | number }}</ng-container><ng-container *ngIf="(bonus_rate % 1 != 0)">${{ bonus_rate | number:'1.2-2' }}</ng-container></strong>
                                                    
                                                </label>
                                            </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr class="hide">
                                        <div class="add-rounded-box lable-text" ><td colspan="5">
                                        
                                            <div class="checkbox mar-bottom-0">
                                                <label>
                                            <input class="radio radio_alt"  type="checkbox"  name="vihecleradio" id="" value=""> <strong class="mx-2">Oversize Vehicles</strong>&nbsp;&nbsp;<span>Is your vehicle oversized? <a href="javascript:void(0)" class="mx-3
                                        "><u>Learn More</u></a></span></label></div>
                                        
                                    
                                    </td>
                                    <td><a href="javascript:;" class="pull-right darop"><fa name="chevron-down text-primary"></fa></a></td>
                                </div>
                                </tr>
                                </table>
                                <h4 class="mar-top-xs mar-bottom-xs pad-bottom-xs font-weight-bold hide">EXTRA OPTIONS, EXTRA SAVINGS</h4>
                                <table class="blockTable hide" style="width:100%">
                                    <tr>
                                        <td colspan="6">
                                            <div class="add-rounded-box lable-text">
                                                    <div class="checkbox">
                                                        <label>
                                                    <input class="radio radio_alt"  type="checkbox"  name="selectBonusHour" id="bonusHour" value=""> 
                                                    <strong>ALL-ACCESS PASS</strong><br>
                                                    <span>Park at any Icon Garage in the city. <a href="javascript:void(0)" class="mx-3
                                                        "><u>Learn More</u></a></span>
                                                </label>
                                                    </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr><td colspan="6">
                                        <div class="add-rounded-box lable-text">
                                            <div class="checkbox">
                                                <label>
                                            <input class="radio radio_alt"  type="checkbox"  name="vihecleradio" id="" value=""> 
                                            <strong class="mx-2">WEEKEND PACKAGE</strong><br>
                                            <span>Special Weekend parking options.  <a href="javascript:void(0)" class="mx-3
                                        "><u>Learn More</u></a></span></label></div>
                                        </div>
                                    </td></tr>
                                </table>
                            </div>
                                <p *ngIf="FacilityAvailableFetatures?.availability_msg" class="smallTextAdded">{{FacilityAvailableFetatures?.availability_msg}}</p>
                                <!-- <p *ngIf="facId == facIdToShowOversize" class="smallTextAdded">This garage cannot accommodate oversized vehicles.</p> -->
                                <p  style="margin-top: 7px;" *ngIf="facIdToShowOversize==0 && (bonus_rate == 0 || bonus_hours == 0)" class="smallTextAdded">{{facIdToShowOversizeMessage}}</p>
                                <p  *ngIf="facIdToShowOversize==0 && bonus_rate > 0 && bonus_hours > 0" class="smallTextAdded">{{facIdToShowOversizeMessage}}</p>
                                <p *ngIf="showMsg_early_bird_flag" class="smallTextAdded">{{msg_early_bird_flag}}</p>
                            </div>
                            <!-- Park safe box for responsive -->
                            <!-- <div class="white-box-sdw your-purchage-box  mar-top-sm srink-box visible-xs">
                              <div class="clearfix pad-top-xs pad-bottom-xs" style="font-size: 14px;">
                                <div class="flex-row align-items-center justify-content-center">
                                     <div class="col-5 col pad-left-0">
                                       <img class="img-responsive" src="/assets/img/logo-parksafe-1.png"> 
                                    </div>
                                    <div class="col-7 pad-left-0 ">
                                        <h4 style="font-weight: 600;">Park with Peace of Mind.</h4>
                                    </div>
                                </div>
                                <div class="flex-row parksafe-with-img-mob pad-top-xs">
                                    <div class="col pad-left-0 pad-right-0">
                                            <div class="clearfix mar-top-xs">
                                                <h4 class="text-primary">Service</h4>
                                                <p>Fully contactless mobile reservations, payments, check-in & check-out</p>
                                            </div>
                                            <div class="clearfix mar-top-xs">
                                            <h4 class="text-primary">Sanitize</h4> 
                                                <p> Designated Viking Pure® natural cleaning stations at every location</p>
                                            </div>
                                            <div class="clearfix mar-top-xs">
                                                <h4 class="text-primary">Safety</h4>
                                                <p>Trained team members with masks & all practicing social distancing</p>
                                            </div>
                                        </div>
                  
                                </div>
                                </div>
                              </div> -->

                            <div class="white-box-sdw srink-box mar-top-sm">
                            <h4 class="mar-top-xs mar-bottom-0 pad-top-xs font-weight-bold">ACCOUNT INFORMATION</h4>
                            <!-- <div class="clearfix" *ngIf="!isLoggedIn">
                                <a class="btn btn-primary theme-btn" id="secondloginbtn" type="button" data-toggle="modal" href='#login'>Log in</a><span class="mar-left-sm hidden-xs"></span>
                                <div class="mar-top-xs visible-xs"></div><span class="btn theme-btn fromGuide" type="button">OR, ENTER YOUR INFO BELOW <fa name="chevron-down"></fa></span>
                            </div> -->
                            <div class="login-daily-payment payment-login-info hidden-xs" *ngIf="!isLoggedIn">
                                <div class="flex-row no-gutters">
                                    <div class="col-sm-12"><h4 class="mar-bottom-0 mar-top-sm">SIGN IN</h4></div>
                                    <div class="col-12 col-sm mar-r-0">
                                        <div class="form-group focusedFix">
                                            <input class="form-control" id="email" type="text" name="" placeholder="E-mail Address*" />
                                        </div>
                                        <div *ngIf="email_error" class="help-block d-login-error">Email is required</div>
                                    </div>
                                    <div class="col-12 col-sm mar-r-0">
                                        <div class="form-group focusedFix">
                                            <input class="form-control" id="password" type="password" name="" placeholder="Password*" style="height: 40px !important;" />
                                        </div>
                                        <div *ngIf="password_error" class="help-block d-login-error">Password is required</div>
                                    </div>
                                    <div class="col-sm-3" style="max-width: 112px;">
                                        <div class="form-group focusedFix">
                                            <a class="btn btn-outline-primary px-5 theme-btn btn-rounded" id="" type="button" (click)="login()">LOG IN</a>
                                        </div>
                                    </div>
                                    <div class="col-sm-12">
                                        <p class="mar-bottom-xs pad-top-xs visible-xs"></p>
                                        <!-- <p style="font-size: 14px;">Not a member? <a style="cursor: pointer;" (click)="signUpWithRewardForm()" >Sign Up</a> for Icon Rewards and Save <span sourceFont>$</span>10</p> -->
                                        <p style="font-size: 14px;">Not a member? <a style="cursor: pointer;" (click)="signUpWithRewardForm()" >Click here to create an account</a> </p>
                                        <p class="mar-top-sm"><strong>OR,  ENTER YOUR INFORMATION BELOW</strong></p>
                                    </div>
                                </div>
                            </div>
                            <div class="visible-xs mar-bottom-xs mar-top-sm" *ngIf="!isLoggedIn">
                                <div class="flex-row no-gutters">
                                   <div class="col-auto"><a  class="btn btn-outline-primary px-5 theme-btn btn-rounded" type="button" data-toggle="modal" href='#login'>Log in</a></div>
                                    <div class="col">
                                     <!-- <p style="font-size: 14px; padding-left: 12px;">Not a member? <a style="cursor: pointer;" href="/create-account">Sign Up</a> for Icon Rewards and Save <span sourceFont>$</span>10</p> -->
                                     <p style="font-size: 14px; padding-left: 12px;">Not a member? <a style="cursor: pointer;" (click)="signUpWithRewardForm()">Click here to create an account</a></p> 
                                    </div>
                                </div>
                                <!-- <a class="btnExpand-2 text-dark " type="button" (click)="isCollapsed = !isCollapsed">Or, CREATE AN ACCOUNT BELOW &nbsp;<fa name="angle-down"></fa></a> -->
                            </div>
                            
                            <p *ngIf="isLoggedIn" class="mar-top-xs">
                                <span class="text-uppercase"><b>Welcome back, {{ activeUser?.data?.name }}.</b></span>
                                <a (click)="logOutUser()" href="javascript:void(0)">Not you? Log out <fa name="angle-right"></fa></a>
                            </p>
                            <!-- <div class="ac-info">
                        <div class="row">
                            <div class="col-sm-6">
                                <div class="form-group focusedFix">
                                    <input class="form-control" type="text" name="" placeholder="First Name(Optional)" />
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="form-group focusedFix">
                                    <input class="form-control" type="text" name="" placeholder="Last Name(Optional)" />
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-6">
                                <div class="form-group focusedFix">
                                    <input class="form-control" type="text" name="" placeholder="E-mail Address"/>
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="form-group focusedFix">
                                    <input class="form-control" type="text" name=""  placeholder="Phone Number(Optional)"/>
                                </div>
                            </div>
                        </div>
                    </div> -->


                            <!-- <hr class="clearfix mr-bottom-sm mar-top-sm"/>
                    <div class="row">
                                         <div class="col-xs-12 col-sm-12">
                      <div class="vehicle-info">
                        <h3 class="text-primary mar-top-0 mar-bottom-sm">VEHICLE INFORMATION</h3>
                        <div class="clearfix">
                        <div class="row ">
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            <div class="radio">
                                <input type="radio" class="radio" name="vehicle-info" />
                                <label>Standard size</label>
                    
                            </div>
                                        
                        </div>
                        <div class="col-xs-12 col-sm-6 col-md-5 col-lg-5">
                            <div class="radio">
                            <input type="radio" class="radio" name="vehicle-info" />
                            <label>Oversize (SUV, Truck)</label>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-6 col-md-7 col-lg-7 vehicle-info-text">
                            <a href="#">IS MY CAR OVERSIZED?</a>
                        </div>
                        </div>
                        </div>
                        <p>If you're unsure of what your car is classified as, you can get help from the garage attendant, and pay there if needed.</p>
                    </div>
                                       </div>
                                     </div> -->
                            <!-- <div *ngIf="isLoyaltyMember" class="loyalty-wallet">
										<hr class="mar-top-sm mar-bottom-sm" />
										<h3 class="text-primary text-uppercase mar-top-0">REWARDS POINTS (OPTIONAL)</h3>
										<span *ngIf="pointAmt>=amt">
										  <div class="checkbox">
											<label>
											  <input type="checkbox" class="mar-checkbox" value="" [(ngModel)]="loyalty.payRewards" name="payRewards"
												#payRewards="ngModel" (click)="payWthRewards(AcceptTerms, $event);">
											  <h5 class="mar-top-0 mar-bottom-0">Pay with Icon Rewards points</h5>
											  <small *ngIf="!loyalty.payRewards">Balance: {{loyalty_points | number}} points</small>
											  <small *ngIf="loyalty.payRewards">Use: {{finalPrice*loyaltyData?.loyalty_config.POINTS_REDEEM_RATIO |
												number}} of {{loyalty_points | number}} available points</small>
											</label>
										  </div>
										</span>
										<span *ngIf="pointAmt<amt">
										  <div class="checkbox">
											<label>
											  <input disabled type="checkbox" class="mar-checkbox">
											  <small>You don’t have enough Icon Rewards points to pay for this reservation. &nbsp;&nbsp;<a
												  class="floatBtn btn-link" href="javascript:void(0)" (click)="insufficientReward(insReward, $event);"><img src="assets/img/help.svg"
													alt="?">&nbsp;&nbsp;&nbsp;&nbsp;</a>
												<ng-template #accountPop><img src="assets/img/account_number.png" width="180" alt="image"></ng-template>
											  </small>
											  <br />
											  <h5 class="mar-top-0 mar-bottom-0">{{amt*loyaltyData?.loyalty_config.POINTS_REDEEM_RATIO | number}}
												points required. Balance is {{loyalty_points | number}} points </h5>
											</label>
										  </div>
										</span>
									  </div> -->

                            <!---  #Term page    -->

                            <!-- #Start commented for new loyalty changes IPSD-1776 -->
                            <!-- <ng-template class="modal fade" #AcceptTerms id="AcceptTerms">
										<div class="modal-header">
										  <button type="button" class="close pull-right" aria-label="Close" (click)="decline('icon')">
											<span style="font-size: 50px; font-weight: normal;" aria-hidden="true">&times;</span>
										  </button>
										</div>
										<div class="modal-body">
										  <div>
											<h2 class="text-uppercase mar-top-0 mar-bottom-sm text-primary">PAY WITH REWARDS POINTS?</h2>
											<div class="icon-rewards-terms mar-top-xs mar-bottom-xs">When you redeem your points for free
											  parking, you can currently not add any extras to your reservation.</div>
											<b *ngIf="messageBoxTxt">If you continue, your [{{messageBoxTxt}}] will be removed.</b>
										  </div>
										  <div class="form-group mar-top-sm mar-bottom-0 btn-center-xs">
											<button type="button" class="btn btn-primary btn-fill" (click)="confirm('icon')">CONTINUE</button>
											<div class="mar-top-xs visible-xs"></div>
											<button type="button" class="btn btn-primary theme-btn mar-left-xs" (click)="decline('icon')">CANCEL</button>
										  </div>
										</div>
									  </ng-template> -->
                            <!-- #End commented for new loyalty changes IPSD-1776 -->
                            <!---  #Term page    -->
                            <!---  #insufficient Reward page    -->

                            <!-- #Start commented for new loyalty changes IPSD-1776 -->
                            <!-- <ng-template class="modal fade" #insReward id="insReward">
										<div class="modal-header">
										  <button type="button" class="close pull-right" aria-label="Close" (click)="close()">
											<span style="font-size: 50px; font-weight: normal;" aria-hidden="true">&times;</span>
										  </button>
										</div>
										<div class="modal-body">
										  <div>
											<h2 class="text-uppercase mar-top-0 mar-bottom-sm text-primary">NOT ENOUGH ICON REWARDS POINTS</h2>
											<div class="icon-rewards-terms mar-top-xs">Thank you for using Icon Rewards. It seems you do not
											  have enough points available in your account to get free parking yet. You need to have enough
											  points to cover the entire reservation to be able to redeem.</div>
										  </div>
										  <div class="form-group mar-top-sm mar-bottom-0 btn-center-xs">
											<button type="button" class="btn btn-primary theme-btn" (click)="confirm('card')">GOT IT</button>
										  </div>
										</div>
									  </ng-template> -->
                            <!-- #End commented for new loyalty changes IPSD-1776 -->

                            <!---  #insufficient Reward page    -->
                            <!---  #Add Card page    -->

                            <!-- #Start commented for new loyalty changes IPSD-1776 -->

                            <!-- <ng-template class="modal fade" #AcceptCard id="AcceptCard">
										<div class="modal-header">
										  <button type="button" class="close pull-right" aria-label="Close" (click)="close()">
											<span style="font-size: 50px; font-weight: normal;" aria-hidden="true">&times;</span>
										  </button>
										</div>
										<div class="modal-body">
										  <div>
											<h2 class="text-uppercase mar-top-0 mar-bottom-sm text-primary">ADD CARD TO ICON REWARDS</h2>
											<div class="icon-rewards-terms mar-top-xs">Enroll the credit card you plan to use for parking in your Icon Rewards profile, and you will earn points even when you park without a reservation.  When you pay with your enrolled credit, our system will identify you as an Icon Rewards member and deposit points into your account after each eligible parking session. If you don't add a credit card, you will only earn points when you book on our website or on the Icon GO app.</div>
										  </div>
										  <div class="form-group mar-top-sm mar-bottom-0 btn-center-xs">
											<button type="button" class="btn btn-primary theme-btn" (click)="confirm('card')">GOT IT</button>
										  </div>
										</div>
									  </ng-template> -->

                            <!-- #End commented for new loyalty changes IPSD-1776 -->
</div>
                            <!---  #Term page    -->
                    <div [class.disable-payment]="checkAvailability">
                            <div class="white-box-sdw srink-box mar-top-sm newcheckbox-d">
                                
                            <div *ngIf="!loyalty.payRewards">
                                <h4 class="mar-top-xs mar-bottom-xs pad-bottom-xs pad-top-xs font-weight-bold text-uppercase">Payment Details</h4>
                                <div class="red-box-payment" *ngIf='showerrorMsg'>
                                    <table _ngcontent-c14="" class="hidden-xs">
                                        <tbody _ngcontent-c14=""><tr _ngcontent-c14=""><td _ngcontent-c14=""><img _ngcontent-c14="" src="/assets/img/redbox-strip.svg"></td>
                                            <td>
                                                <p><b>We were unable to authorize the credit card you provided.</b></p>
                                                <p>Please confirm the card info or use another card and try again.</p>
                                                </td></tr>
                                    </tbody></table>
                                    <p _ngcontent-c14="" class="text-center visible-xs">
                                      <b _ngcontent-c14="">We were unable to authorize the credit card you provided. Please confirm the card info or use another card and try again.</b>
                                    </p>
                                </div>
                                <h5 *ngIf="showMethodHeading" class="mar-bottom-xs text-uppercase mar-top-xs">Payment Methods</h5>
                                <div *ngIf="loggedUserName">
                                    <div id="existingcard" class="currentCarD radio mar-top-0" *ngFor="let paymentMethod of fetchUserSavedCards?.data?.payments; let m = index">
                                        <label *ngIf="paymentMethod?.card !== null" class="cardReservation" id="cards{{m}}">
								<!-- condition check -->
								<input  type="radio" class="radio"  name="selectedMethod" id="card-{{paymentMethod?.payment_profile_id}}" value="{{ paymentMethod?.payment_profile_id }}" (change)="updatePaymentMethod(paymentMethod?.payment_profile_id, paymentMethod?.address?.zip)" checked="" />
								<img *ngIf=" paymentMethod?.card?.card_type=='Visa'" [src]="visa_Icon" alt="icon" width="45">
								<img *ngIf=" paymentMethod?.card?.card_type=='MasterCard'" [src]="mastercardIcon" alt="icon" width="45">
								<img *ngIf=" paymentMethod?.card?.card_type=='Discover'" [src]="discovercardIcon" alt="icon" width="45">
								<img *ngIf=" paymentMethod?.card?.card_type=='AmericanExpress'" [src]="amexIcon" alt="icon" width="45">
								<img *ngIf=" paymentMethod?.card?.card_type=='JCB'" [src]="jcbIcon" alt="icon" width="45">
								<img *ngIf=" paymentMethod?.card?.card_type=='DinersClub'" [src]="dinersIcon" alt="icon" width="45">
								<img *ngIf=" paymentMethod?.card?.card_type!='Discover' && paymentMethod?.card?.card_type!='MasterCard' && paymentMethod?.card?.card_type!='AmericanExpress' && paymentMethod?.card?.card_type!='Visa' && paymentMethod?.card?.card_type!='JCB' && paymentMethod?.card?.card_type!='DinersClub'" [src]="card_grey_Icon" width="45" alt="icon">
								<span	*ngIf=" paymentMethod?.card?.card_type=='Visa'"> My Visa Card </span> 
								<span	*ngIf=" paymentMethod?.card?.card_type=='MasterCard'"> My Master Card </span>
								<span	*ngIf=" paymentMethod?.card?.card_type=='Discover'"> My Discover Card</span>
								<span	*ngIf=" paymentMethod?.card?.card_type=='AmericanExpress'"> My American Express Card </span>
								<span	*ngIf=" paymentMethod?.card?.card_type=='JCB'"> My JCB Card </span>
								<span	*ngIf=" paymentMethod?.card?.card_type=='DinersClub'"> My Diners Club Card </span>
								<span *ngIf=" paymentMethod?.card?.card_type!='Discover' && paymentMethod?.card?.card_type!='MasterCard' && paymentMethod?.card?.card_type!='AmericanExpress' && paymentMethod?.card?.card_type!='Visa' && paymentMethod?.card?.card_type!='JCB' && paymentMethod?.card?.card_type!='DinersClub'" >My card </span>
								({{ paymentMethod?.card?.card_number.replace('XXXX', '..')}}) 
								<a (click)="removeCard(paymentMethod?.payment_profile_id,m,paymentMethod?.card?.card_number.replace('XXXX', '..'))" class="pull-right" style="margin-top:5px">
									&nbsp;&nbsp;<fa name="trash" size="1x"></fa>
								  </a>
								<!-- <span *ngIf="paymentMethod?.expiration_month && paymentMethod?.expiration_year">
								 for {{ paymentMethod?.address?.first_name }} {{ paymentMethod?.address?.last_name }}	{{ paymentMethod?.expiration_month }}/{{ paymentMethod?.expiration_year }}
								</span> -->
								<!-- <span *ngIf="!paymentMethod?.expiration_month && !paymentMethod?.expiration_year">
									
								</span> -->
							</label>
                                        <label *ngIf="paymentMethod?.bank !== null">
								<input  type="radio" class="radio"  (change)="updatePaymentMethod('old', paymentMethod?.address?.zip)" name="selectedMethod" id="card-{{paymentMethod?.payment_profile_id}}" value="{{ paymentMethod?.payment_profile_id }}" (change)="updatePaymentMethod(paymentMethod?.payment_profile_id, paymentMethod?.address?.zip)" checked="" />
								<fa name="bank" class="mar-right-xs"></fa>
								Bank Ending In ({{ paymentMethod?.bank?.bank_account}}) for {{ displayNameOnCard(paymentMethod?.address?.first_name) }} {{ displayNameOnCard(paymentMethod?.address?.last_name) }}
							</label>
                                    </div>
                                    <!-- <p class="currentCarD">
									<input  type="radio" class="radio"  name="selectedMethod" (change)="updatePaymentMethod('new')" id="card-{{paymentMethod?.payment_profile_id}}" value="" />
									&nbsp;&nbsp;
									<i class="fa fa-money fa-2x"></i>
									&nbsp;&nbsp; &nbsp; &nbsp;Use A New Payment Method
						    </p> -->
                                </div>

                                <div class="clearfix">
                                    <div class="clearfix" id="accountExpand">
                                        <button id="toogleForm" class="currentCarD currentCarD-2 collapsed pull-left pad-right-xs" type="button" data-toggle="collapse" data-target="#cardExpand" aria-expanded="false" aria-controls="cardExpand" (click)="updatePaymentMethodMode()">
							<fa name="credit-card text-primary" size="2x" class="mar-right-xs"></fa><span class="font-weight-bold">New Credit / Debit Card</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<fa name="chevron-down text-primary"></fa>&nbsp;&nbsp;
							</button>
                                    </div>
                                    <div *ngIf="newPayment" class="collapse payment-login-info" [ngClass]="{'in': expandCardForm == true}" id="cardExpand">
                                        <div class="row">
                                            <div class="col-sm-6 col-md-5">
                                                <div class="form-group nameOnCardDiv focusedFix mar-bottom-0" [ngClass]="{ 'has-error' : f.submitted && !name_on_card.valid}">
                                                    <!-- <label class="control-label boxLabel" for="name_on_card">Name on Card</label> -->
                                                    <input [disabled]="checkAvailability" id="nameOnCard" class="form-control inputBoxStyling" type="text" autocomplete="off" placeholder="Name on Card" name="name_on_card" [(ngModel)]="formData.name_on_card" #name_on_card="ngModel" [required]="isCardReq" />
                                                    <div *ngIf="f.submitted && !name_on_card.valid" class="help-block">Name on Card is required</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-sm-6 col-md-5">
                                                <div class="form-group paymentDiv focusedFix mar-bottom-0" [ngClass]="{ 'has-error' : f.submitted && !card_number.valid}">
                                                    <!-- <label class="control-label boxLabel" for="card_number">Card Number</label> -->
                                                    <input [disabled]="checkAvailability" type="tel" autocomplete="off" placeholder="Card Number" [textMask]="{mask: cardMask}" (keypress)="restrictNumeric($event)" class="form-control inputBoxStyling" name="card_number" id="cardNumber" [(ngModel)]="formData.card_number" (keyup)="checkCard($event)" #card_number="ngModel"
                                                        (ngModelChange)="formData.card_number = unmask($event)" [required]="isCardReq" />
                                                        <img height="22px" class="visible-xs" style="height: 22px;margin-top: 8px;position: absolute;right: 10px;top: 0;" src="../../../assets/img/card-list.png">
                                                    <div *ngIf="f.submitted && !card_number.valid" class="help-block">Card Number is required</div>
                                                </div>
                                            </div>
                                            <div class="col-sm-6 col-md-5 hidden-xs">
                        
                                                <div class="form-group paymentDiv mar-bottom-xs " >
            
                                                        <img height="22px" style="height: 22px;margin-top: 8px;" src="../../../assets/img/card-list.png">
            
                                                </div>
            
                                            </div>
                                        </div>
                                         <div class="row">
                                            <div class="col-sm-6 col-md-5">
                                                <div class="form-group paymentDiv focusedFix mar-bottom-0" [ngClass]="{ 'has-error' : f.submitted && !expiration_date.valid}">
                                                    <!-- <label class="control-label boxLabel" for="expiration_date">Expiration Date **/**</label> -->
                                                    <input [disabled]="checkAvailability" type="tel" autocomplete="off" placeholder="Expiration Date  (mm/yy)" (keyup)="setToexpirationField(expiration_date)" id="standardCardExpiration" [textMask]="{mask: dateMask}" minlength="4" class="form-control inputBoxStyling" name="expiration_date" [(ngModel)]="formData.expiration_date"
                                                        #expiration_date="ngModel" (ngModelChange)="formData.expiration_date = unmask($event)" [required]="isCardReq" />
                                                    <input [disabled]="checkAvailability" class="form-control" type="hidden" autocomplete="off" [(ngModel)]="formData.exp1" name="exp1" #exp1="ngModel" id="standardCardExpiration1">
                                                    <div *ngIf="f.submitted && !expiration_date.valid" class="help-block">Expiration Date is required</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-sm-6 col-md-5">
                                                <div class="form-group paymentDiv mar-bottom-0 focusedFix" [ngClass]="{ 'has-error' : f.submitted && !security_code.valid}">
                                                    <!-- <label class="control-label boxLabel" for="security_code">Security Code</label> -->
                                                    <div class="input-group  input-group-1">
                                                        <input [disabled]="checkAvailability" type="tel" autocomplete="off" placeholder="Security Code" maxlength="4" class="form-control inputBoxStyling securityCodeInput" name="security_code" id="standardCardCvv" (keypress)="allowNumbersOnly($event)" [(ngModel)]="formData.security_code" #security_code="ngModel" [required]="isCardReq" />
                                                        <div *showItBootstrap="['md','lg','sm']" class="input-group-append ml-2 d-none d-sm-block my-2" container="body" containerClass="PaymentCustome" placement="top" [tooltip]="popTemplate"><img [src]="infoIcon" alt="i" width="20"></div>
                                                        <!-- fix for mobile device tooltip -->
                                                        <div *showItBootstrap="['xs']" class="input-group-append ml-2 d-block d-sm-none my-2" container="body" containerClass="PaymentCustome" placement="left" [tooltip]="popTemplate"><img [src]="infoIcon" width="20" alt="i"></div>
                                                        <!-- tooltip fif ended -->
                                                    </div>
                                                    <div *ngIf="f.submitted && !security_code.valid" class="help-block zip-error">Security Code is required</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-sm-6 col-md-5">
                                                <div class="form-group focusedFix mar-bottom-0" [ngClass]="{ 'has-error' : f.submitted && !zip_code.valid}">
                                                    <!-- <label class="control-label boxLabel" for="zip_code">Zip Code</label> -->
                                                    <div class="input-group zipCodeDiv">
                                                        <input [disabled]="checkAvailability" autocomplete="off" maxlength="6" placeholder="Zip Code" (keypress)="allowNumbersOnly($event)" minlength="5" class="form-control inputBoxStyling" type="text" name="zip_code" id="standardCardZip" [(ngModel)]="formData.zip_code" #zip_code="ngModel" [required]="isCardReq" />

                                                    </div>

                                                    <div *ngIf="f.submitted && !zip_code.valid" class="help-block">Zip Code is required</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div *ngIf="!loggedUserName" class="col-sm-6 col-md-5" id="guestmail">
                                                <div class="form-group emailDiv focusedFix mar-bottom-xs" [ngClass]="{ 'has-error' : f.submitted && !eemail.valid}">
                                                    <!-- <label class="control-label boxLabel" for="eemail">Email</label> -->
                                                    <input [disabled]="checkAvailability" inputmode="email" autocomplete="off" placeholder="Email" id="eemail" class="form-control inputBoxStyling" type="email" name="eemail" (keydown)="restSpace($event)" pattern="{{emailRegex}}" [(ngModel)]="formData.eemail" #eemail="ngModel" />
                                                    <div *ngIf="f.submitted && !eemail.valid" class="help-block">Email is required</div>
                                                </div>
                                            </div>

                                            <div class="clearfix"></div>
                                            <div class="col-sm-6 col-md-5 mar-top-xs pad-top-xs newcheckbox-d" *ngIf="loggedUserName && isCardReq">
                                                <div class="checkbox">
                                                    <label>
														<input [disabled]="checkAvailability" type="checkbox"  (click)="checkCardInserted($event)" name="savePaymentMethod" [(ngModel)]="formData.savePaymentMethod" #savePaymentMethod="ngModel" />&nbsp;
														Save to my account
												</label>
                                                </div>
                                            </div>
                                            <!-- <div *ngIf="loggedUserName && isLoyaltyMember " class="loyalty-card-info col-sm-4 col-md-5">
													<div class="checkbox">
                                        <label>
                                            <input type="checkbox" value="" id="AddCardIcon" (click)="checkCardInserted($event)" onclick="return GetPaymentCardToken();" #AddCardIcon="ngModel" [(ngModel)]="loyalty.AddCardIcon"
                                            name="AddCardIcon">&nbsp;Add card to Icon Rewards &nbsp;&nbsp;
                                            <a href="javascript:void(0)" class="floatBtn btn-link" (click)="addCardToIcon(AcceptCard, $event)"><img src="assets/img/help.svg"
                                                alt="?"></a>
                                        </label>
                                        </div>
                                        <ng-template #accountPop><img src="assets/img/account_number.png" alt="icon" width="180"></ng-template>
                                    </div> -->
                                            <div class="clearfix"></div>
                                            <div *ngIf="checkShow && notShow" class="col-sm-12">
                                                <div class="checkbox">
                                                    <label>
											<input type="checkbox" name="opst" #opts="ngModel" id="opts" [(ngModel)]="opt" />&nbsp;
											Click here to pass on future Icon deals coming to your inbox! 
										</label>
                                                </div>
                                            </div>

                                        </div>
                                        <!-- </div> -->
                                    </div>
                                </div>
                                <div class="clearfix"></div>
                                <div class="row">
                                    <div class="mar-top-sm mar-bottom-sm col-sm-6">
                                        <h5 *ngIf="applyDotInPrice(amt)!=='N/A' && isLoggedIn" class="text-uppercase mar-top-0" >Use Account credit</h5>
                                        <div *ngIf="applyDotInPrice(amt)!=='N/A'" class="" style="margin-bottom:10px;">
                                            <p *ngIf="isLoggedIn" class="mar-bottom-0" sourceFont>
                                                <!-- <span *ngIf="wallet.balance > 0"> -->
                                                Account credit available :{{wallet?.balance | currency:'USD':true:'.0'}}
                                                <!-- </span> -->
                                            </p>
                                            <table width="100%" class="range-slider" *ngIf="isLoggedIn">
                                                <tr>
                                                    <td>
                                                        <input [disabled]="checkAvailability" id="range" class="range-slider_range" (change)="changeZoom(ranger.value)" type="range" value="{{rangerValue}}" min="0" max="{{maxrange}}" #ranger>
                                                    </td>
                                                    <td align="right" width="70px">
                                                        <div rangeWrapper><input [disabled]="checkAvailability" inputValueRange (input)="changerange(inputrangevalue.value)" type="number" (focus)="changerange(inputrangevalue.value)" min="0" max="{{maxrange}}" value='0' id="rangerValue" #inputrangevalue></div>
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>



                                        <h4 *ngIf="1" class=" mar-top-0">Add promo code</h4>
                                        <div id="selectPromocode" *ngIf="1" class="payment-login-info">
                                            <div class="form-group mar-top-0 mar-bottom-0">

                                                <div class="input-group coupon-input promoCodeDiv" *ngIf="1" style="margin-bottom:10px;">
                                                    <input [disabled]="checkAvailability" #promocodeValueText type="text" placeholder="Promo Code" class="form-control" id="testing" name="promocode">
                                                    <span class="input-group-btn applyPromoSpan"><button [disabled]="checkAvailability" type="button" class="btn text-primary couponApplyButton" (click)="applyCouponOnClick()" style="font-size: 14px; font-weight: 700;">APPLY</button></span>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <div *ngIf="1" class="promoCodeListDiv">
                                                    <select class="select" name="userPromocodes" id="userPromocodes" class="form-control ng-pristine ng-invalid ng-touched" style="margin-bottom:10px" *ngIf="showDropdown" (change)="onChangePromocodeDropdown($event)">
								</select>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- <h4  class="mar-top-0">Icon Parking Loyalty No.</h4>
                                        <div class="payment-login-info">
                                            <div class="form-group mar-top-0 mar-bottom-0">

                                                <div class="input-group coupon-input promoCodeDiv"  style="margin-bottom:10px;">
                                                    <input  type="text" placeholder="" class="form-control" name="promocode">
                                                </div>
                                            </div>
                                        </div> -->

                                        <!-- <button #bookwithcard *ngIf="newPayment && !loyalty.payRewards && disableBookButton" type="submit" class="hidden-xs btn btn-primary btn-block btn-fill bookItButton" id="paymentBtn">BOOK IT</button> -->
                                        <!-- <button #bookwithreward *ngIf="loyalty.payRewards && disableBookButton" type="button" class="hidden-xs btn btn-primary btn-block btn-fill bookItButton" [disabled]="rewardDisabled" (click)="paymentWithRewards()">BOOK IT</button> -->
                                        <!-- <a #bookwithsavecard *ngIf="!newPayment && !loyalty.payRewards && disableBookButton" href="javascript:void(0)" (click)="makeCardPayment()" class="hidden-xs btn btn-primary btn-block btn-fill bookItButton" id="userCard">BOOK IT</a> -->
                                    </div>
                                </div>
                            </div>
                            </div>
                            <!-- <div  class="terms-checkbox white-box-sdw your-purchage-box mar-top-sm srink-box mob-top-0 box-hd"> -->
                        
                            <!-- <div class="form-check mar-top-xs mar-bottom-xs" [ngClass]="{ 'has-error' : f.submitted && !offerTerms.valid}">
                            <span>
                                    <input id="offerTerms" class="form-check-input" type="checkbox" name="offerTerms" [(ngModel)]="formData.offerTerms" #offerTerms="ngModel">
                                    <label for="offerTerms" class="form-check-label mar-left-sm">
                                    Click here to receive exclusive offers from Icon straight to your inbox!
                                    </label>
                                </span>
                            </div> -->
                            <div *ngIf="newPayment" class="terms-checkbox white-box-sdw your-purchage-box mar-top-sm srink-box mob-top-0 box-hd form-check " [ngClass]="{ 'has-error' : (f.submitted && !terms.valid) || showTermsError}">
                                <span id="ModalovernightPopup" (click)="displayTermsPopup(1);lgModalovernightPopup.show()">
                                        <input id="termsConditionCheck" class="form-check-input" type="checkbox" name="terms" name="terms" [(ngModel)]="formData.terms" #terms="ngModel"  required>
                                        <label for="termsConditionCheck" class="form-check-label mar-left-sm ">
                                            I understand and agree with Icon Parking’s  <a style="cursor: pointer;" > Reservation Policy</a>
                                        </label>
                                    </span>
                                    <div *ngIf="f.submitted && !terms.valid || showTermsError" class="help-block">Please accept terms & conditions</div>
                                </div>

                                <div *ngIf="!newPayment && !loyalty.payRewards && disableBookButton" class="terms-checkbox white-box-sdw your-purchage-box mar-top-sm srink-box mob-top-0 box-hd form-check " [ngClass]="{ 'has-error' : showTermError}">
                                    <span id="ModalovernightPopup" (click)="displayTermsPopup(1);lgModalovernightPopup.show()">
                                            <input id="termsConditionCheck" class="form-check-input" type="checkbox" name="terms" name="terms" [ngClass]="(termsValid)?'checked':'unChecked'" [value]="termsValid" [checked]="termsValid" required>
                                            <label for="termsConditionCheck" class="form-check-label mar-left-sm ">
                                            I understand and agree with Icon Parking’s  <a style="cursor: pointer;" > Reservation Policy</a>
                                            </label>
                                        </span>
                                        <div *ngIf="showTermError" class="help-block">Please accept terms & conditions</div>
                                    </div>
                            <!-- </div> -->
                        </div>          
                            <div class="my-3 visible-xs">&nbsp;</div>

                 <div class="row mar-top-sm hidden-xs">
					<div class="col-sm-6 col-md-5">
					  <button *ngIf="newPayment && !loyalty.payRewards && disableBookButton" type="submit"  class="hide showeroor btn btn-primary btn-block book-it"
						id="paymentBtn" [ngClass]="{'disable-btn-gray': checkAvailability == true, 'btn-fill' : checkAvailability != true, 'disable-btn-gray' : checksubmit == true }">BOOK IT </button>
						<button *ngIf="newPayment && !loyalty.payRewards && disableBookButton" type="button" (click)="showCardFillForm()" class="btn btn-primary btn-block book-it"
						id="paymentBtns" [ngClass]="{'disable-btn-gray': checkAvailability == true, 'btn-fill' : checkAvailability != true, 'disable-btn-gray' : checksubmit == true }" >BOOK IT </button>
					  <button [disabled]="rewardDisabled" *ngIf="loyalty.payRewards && disableBookButton" type="button" class="btn btn-primary btn-block book-it" (click)="paymentWithRewards()" [ngClass]="{'disable-btn-gray': checkAvailability == true, 'btn-fill' : checkAvailability != true, 'disable-btn-gray' : checksubmit == true }">BOOK IT </button>
					  <a *ngIf="!newPayment && !loyalty.payRewards && disableBookButton" href="javascript:void(0)" (click)="makeCardPayment()" class="btn btn-primary btn-block book-it"
						id="userCard" [ngClass]="{'disable-btn-gray': checkAvailability == true, 'btn-fill' : checkAvailability != true, 'disable-btn-gray' : checksubmit == true }">BOOK IT </a>
                    </div></div>
                    <div class="row mar-top-xs hidden-xs">
                    <div class="col-sm-6 col-md-5">
                       <p class="mar-top-xs mar-bottom-sm w-100 text-center"><a role="button" font-12 class="text-dark mt-4 text-center" style="cursor: pointer;"><img width="15px" class="mar-right-xs" src="/assets/img/best_guaranteed_icon.png">Our Best Deals Guaranteed </a></p>
                    </div>
                    <div class="mar-bottom-lg visible-xs"></div>
                  </div>
                        </div>
                        <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4 mobView ">
                            <div class="paymentImgWrap hidden-xs">
                                <img *ngIf="respdata?.photos" [src]="respdata?.photos.url" class="img-responsive full-width" height="234" alt="image">
                                <img *ngIf="!respdata?.photos" [src]="park_garageIcon" class="img-responsive full-width" height="234" alt="image">
                            </div>
                            <div class="white-box-sdw">
                                <h3>Reservation Summary</h3> 
                                <div class="time-box-enter mar-top-xs pad-top-xs">
                                    <p class="mar-bottom-0"><img width="16" src="/assets/img/enter_after_icon.png"> <b class="mar-left-xs">ENTER AFTER</b></p>
                                    <p class="time-date">{{dayname}}</p>
                                </div>
                                <div class="time-box-exit mar-top-xs pad-top-xs">
                                    <p class="mar-bottom-0"><img width="16" src="/assets/img/exit_before_icon.png"> <b class="mar-left-xs">EXIT BEFORE</b></p>
                                    <p class="time-date">{{edayname}}</p>
                                </div>
                                <!-- <p *ngIf="issamepriceMoretime" class="mar-top-xs pad-top-xs"><a href="javascript:;"  triggers="mouseenter:mouseleave" container="body" containerClass="more-time-same-price-box mob-mts-rt" popover="At this Icon Parking facility, {{updatedStartDate}} to {{updatedEndDate}} costs the same as the time you selected. The extra time has been added to your booking - stay until {{updatedEndDate}}.">Why is my time extended for free?</a></p> -->
                                <p  *ngIf="issamepriceMoretime" class="mar-top-xs pad-top-xs hidden-xs"><a href="javascript:;" triggers="mouseenter:mouseleave"   container="body" containerClass="more-time-same-price-box2" [popover]="MTSPTemplate" popover="At this Icon Parking facility, {{updatedStartDate}} to {{updatedEndDate}} costs the same as the time you selected. The extra time has been added to your booking - stay until {{updatedEndDate}}.">Why is my time extended for free?</a></p>
                                <ng-template #MTSPTemplate>
                                    <div class="more-time-same-price-box">
                                        <table><tr><td><img width="30" src="/assets/img/car-popover.png"></td><td style="padding-left:5px;">At this Icon Parking facility, {{updatedStartDate}} to {{updatedEndDate}} costs the same as the time you selected. The extra time has been added to your booking - stay until {{updatedEndDate}}.</td></tr></table>

                                    </div>
                                </ng-template>

                                <table class="pad-top-xs">
                                    <tr><td style="vertical-align: top;"><img src="/assets/img/alert-red.png" width="18" class="mar-right-xs"></td>  <td>
                                        <span *ngIf="showMsg_early_bird_flag">You must enter before {{entry_time_end}} to receive this price or additional fees will automatically be charged.</span>
                                        <span *ngIf="showMsg_early_bird_flag==false">Enter between {{daynames}} - {{edaynames}} to receive this price</span>
                                        <!-- <a id="ModalovernightPopup2" (click)="displayTermsPopup(0);lgModalovernightPopup.show()" href="javascript:;">Details</a> -->
                                    </td></tr>
                                </table>
                            </div>
                            <div class="mar-top-xs pad-top-xs hidden-xs"></div>
                            <div class="white-box-sdw">
                                <!-- <p *ngIf="isLoggedIn" class="mar-bottom-0">
                            <span *ngIf="wallet.balance > 0">
                            Account Credit Available - {{wallet?.balance | currency:'USD':true:'.0'}}
                            </span>
                        </p>
                        <table width="100%" class="range-slider" *ngIf="isLoggedIn">
                            <tr *ngIf="walletBalance">
                                <td>
                                    <input id="range" (change)="changeZoom(ranger.value)" class="range-slider_range" type="range"  value="{{rangerValue}}" min="0" max="{{maxrange}}" #ranger>
                                </td>
                                <td align="right" width="70px"><div rangeWrapper><input inputValueRange (input)="changerange(inputrangevalue.value)" type="number" (focus)="changerange(inputrangevalue.value)" min="0" max="{{maxrange}}" value='0' id="rangerValue" #inputrangevalue ></div>
                            </td>
                        </tr>
                                            </table> -->
                                <!-- <hr class="whiteHr" *ngIf="isLoggedIn"> -->
                                <!-- <div *ngIf="isLoggedIn && wallet?.balance > 0">
                            <div id="selectWallet">
                                    <div class="radio">
                                            <label >
                                                    <input  type="radio" class="radio"  name="selectDiscountMethod" id="" value="" (change)="selectDiscountMethod('wallet')" />Redeem ${{ walletConfig?.WALLET_REDEEM_LIMIT }} from your credits
                                            </label>
                                    </div>
                            </div>
                            <hr class="whiteHr">
                    </div> -->
                                <!-- <div id="selectPromocode" *ngIf="!is_promocode_applied">
                        <div class="form-group mar-top-0 mar-bottom-0">
                            <div class="checkbox mar-top-0">
                                <label>
                                    <input  type="checkbox" class="checkbox" [(ngModel)]="is_promocode_redeemed" [class.on]="isOn" (ngModelChange)="selectDiscountMethod('promocode')" name="selectDiscountMethod" id="promocode" value="" />Have a Promo Code?
                                </label>
                            </div>
                            <div class="input-group coupon-input" *ngIf="is_promocode_redeemed" style="margin-bottom:10px;">
                                <input type="text" placeholder="Enter Promo Code" class="form-control" name="promocode">
                                <span class="input-group-btn"><button type="button" class="btn text-primary couponApplyButton" (click)="applyCoupon($event)">APPLY</button></span>
                            </div>
                        </div>
                        <div class="form-group">
                            <div *ngIf="is_promocode_redeemed">
                                <select class="select" name="userPromocodes" id="userPromocodes" class="form-control ng-pristine ng-invalid ng-touched" style="margin-bottom:10px" *ngIf="showDropdown" (change)="onChangePromocodeDropdown($event)">
                                </select>
                            </div>
                        </div>
                    </div> -->
                                <!-- <hr class="whiteHr">
                    <div id="selectBonusHour">
                        <div class="form-group" >
                            <div class="checkbox">
                                <label>
                                    <input type="checkbox" [(ngModel)]="is_bonus_hour_applied" (ngModelChange)="updateBonusHour()"  name="selectBonusHour" id="bonusHour" value="" />Add an extra hour to your reservation for ${{ bonus_rate }}?<br>
                                    Extend your stay to {{ displayExtraTime }}
                                </label>
                            </div>
                        </div>
                    </div> -->

                                <!-- <hr class="whiteHr"> -->
                                <div *ngIf="is_promocode_applied && applyDotInPrice(amt)!=='N/A'">
                                    <h4 class="text-center text-primary text-uppercase" style="font-weight: 700;">{{ promocode?.promocode?.promocode }} &nbsp;&nbsp;&nbsp;
                                        <a href="javascript:void(0)" #removePromocodeLink id="removePromocodeLink" class="removePromocodeLink" (click)="removePromocode(true,2,promocode?.promocode?.promocode)">
                                            <fa name="times" style="vertical-align: sub;"></fa>
                                        </a>
                                    </h4>
                                </div>
                                <div style="visibility: hidden; height: 0;font-size: 0;">
                                    SelfAmount: {{gettotalUpdatedAmount()}}
                                </div>
                                <table width="100%" class="price-section" sourceFont>
                                    <tr>
                                        <td><b>{{ length_of_stay_text }}</b></td>
                                        <!-- <td align="right">{{ amt | currency:'USD':true:'.0' }}</td> -->
                                        <td *ngIf="!loyalty.payRewards" align="right"><b> {{applyDotInPrice(amt)}}</b></td>
                                        <td *ngIf="loyalty.payRewards" align="right"><b>{{ amt*loyaltyData.loyalty_config.POINTS_REDEEM_RATIO | number }}</b></td>
                                    </tr>
                                    <tr *ngIf="is_promocode_applied && applyDotInPrice(amt)!=='N/A' && notflatrate" class="">
                                        <td> <b>{{(promocode?.promocode?.promocode.includes("ICONREWARDS"))?'Rewards Discount':'Promo code'}}</b></td>
                                        <td *ngIf="(actualPromocodeApplied % 1 == 0)" align="right"> <b>- ${{ actualPromocodeApplied | number:'1.2-2' }}</b></td>
                                        <td *ngIf="(actualPromocodeApplied % 1 != 0)" align="right"> <b>- ${{ actualPromocodeApplied | number:'1.2-2' }}</b></td>
                                    </tr>
                                    <tr *ngIf="is_wallet_applied" class="">
                                        <td> <b>iconGo credit used</b></td>
                                        <td align="right"> <b>- {{ walletConfig?.WALLET_REDEEM_LIMIT | currency:'USD':true:'1.2-2' }} </b></td>
                                    </tr>
                                    <tr *ngIf="is_bonus_hour_applied && applyDotInPrice(amt)!=='N/A' " class="">
                                        <td> <b>{{ bonus_hours }} Extra Hour</b></td>
                                        <td *ngIf="(bonus_rate % 1 == 0)" align="right"> <b>+ ${{ applyDotInBonus(bonus_rate) }} </b></td>
                                        <td *ngIf="(bonus_rate % 1 != 0)" align="right"> <b>+ ${{ bonus_rate | number:'1.2-2' }} </b></td>
                                    </tr>
                                    <tr *ngIf="showSliderDescription && applyDotInPrice(amt)!=='N/A' && notflatrate" class="">
                                        <td> <b>Account Credit </b></td>
                                        <td *ngIf="(showSliderDescriptionValue % 1 == 0)" align="right"> <b>- ${{showSliderDescriptionValue | number:'1.2-2'}} </b></td>
                                        <td *ngIf="(showSliderDescriptionValue % 1 != 0)" align="right"> <b>- ${{showSliderDescriptionValue | number:'1.2-2'}} </b></td>
                                    </tr>
                                    <tr *ngIf="processFess > 0 && applyDotInPrice(amt)!=='N/A' && notflatrate" class="">
                                        <td> Activation Fee <span containerClass="processingFeesClass"  [tooltip]="processingFeesTooltip"
                                            placement="top"><img width="12px" [src]="infoIcon"/></span> </td>
                                        <td align="right"> + ${{processFess | number:'1.2-2'}} </td>
                    
                                    </tr>
                                </table>
                                <ng-template #processingFeesTooltip><p>The activation fee is a one-time charge for the account set-up, access tag, individual account administrative access, secure billing connectivity, and service from the start date for the duration of the agreement.</p></ng-template>
                                <hr>
                                <table width="100%" id="actualPrice" *ngIf="disableBookButton" sourceFont>
                                    <tr>
                                        <td class="font-weight-bold pad-bottom-xs" style="font-size: 20px;">Total </td>
                                        <input type="hidden" id="total" value="{{ amt }}" />
                                        <td align="right">
                                            <!-- <h2 *ngIf="!loyalty.payRewards && (finalPrice % 1 == 0)" style="font-size: 18px;" class="mar-top-0 mar-bottom-xs font-weight-bold">{{applyDotInPrice(finalPrice)}}</h2>
                                            <h2 *ngIf="!loyalty.payRewards && (finalPrice % 1 != 0)" style="font-size: 18px;" class="mar-top-0 mar-bottom-xs font-weight-bold">${{finalPrice | number:'1.2-2' }}</h2> -->
                                            <h2 style="font-size: 20px;" class="mar-top-0 mar-bottom-xs font-weight-bold">{{applyDotInPriceWithProcessing(finalPrice) }}</h2>
                                            


                                            <h2 *ngIf="loyalty.payRewards" style="font-size: 20px;" class="mar-top-0 mar-bottom-xs font-weight-bold">{{ finalPrice*loyaltyData.loyalty_config.POINTS_REDEEM_RATIO | number }} points</h2>
                                        </td>
                                    </tr>
                                </table>
                                <table width="100%" *ngIf="!disableBookButton" id="naPrice" sourceFont>
                                    <tr>
                                        <td class="font-weight-bold pad-bottom-xs" style="font-size: 20px;">Total</td>
                                        <input type="hidden" id="total" value="{{ amt }}" />
                                        <td align="right">
                                           
                                                <!-- <h2 *ngIf="finalPrice =='N/A'" style="font-size: 18px;" class="mar-top-0 mar-bottom-xs font-weight-bold">N/A</h2>
                                                <h2 *ngIf="finalPrice !='N/A' && (finalPrice % 1 == 0)" style="font-size: 18px;" class="mar-top-0 mar-bottom-xs font-weight-bold">${{finalPrice }}</h2>
                                                <h2 *ngIf="finalPrice !='N/A' && (finalPrice % 1 != 0)" style="font-size: 18px;" class="mar-top-0 mar-bottom-xs font-weight-bold">${{finalPrice | number:'.2-2'}}</h2> -->
                                            <h2 *ngIf="finalPrice =='N/A'" style="font-size: 20px;" class="mar-top-0 mar-bottom-xs font-weight-bold">N/A</h2>
                                            <h2 *ngIf="finalPrice !='N/A'" style="font-size: 20px;" class="mar-top-0 mar-bottom-xs font-weight-bold">{{applyDotInPriceWithProcessing(finalPrice)  }}</h2>
                                        </td>
                                    </tr>
                                </table>
                                <button #bookwithcard *ngIf="newPayment && !loyalty.payRewards && disableBookButton" type="submit" class="btn btn-primary btn-block book-it mar-top-xs" id="paymentBtn" [ngClass]="{'disable-btn-gray': checkAvailability == true, 'btn-fill' : checkAvailability != true, 'disable-btn-gray' : checksubmit == true }">BOOK IT </button>
                            <!-- <button #bookwithreward *ngIf="loyalty.payRewards && disableBookButton" type="button" class="btn btn-primary btn-block btn-fill bookItButtonSide" [disabled]="rewardDisabled" (click)="paymentWithRewards()">Book
					  It</button> -->
                            <a #bookwithsavecard *ngIf="!newPayment && !loyalty.payRewards && disableBookButton" href="javascript:void(0)" (click)="makeCardPayment()" class="btn btn-primary btn-block book-it mar-top-xs" id="userCard" [ngClass]="{'disable-btn-gray': checkAvailability == true, 'btn-fill' : checkAvailability != true, 'disable-btn-gray' : checksubmit == true }">BOOK IT </a>
                            <div class="pad-top-xs ">
                                   <p class="mar-top-xs mar-bottom-xs w-100  hidden-xs text-center"><a role="button" font-12 class="text-dark mt-4 text-center" style="cursor: pointer;"><img width="15px" class="mar-right-xs" src="/assets/img/best_guaranteed_icon.png">Our Best Deals Guaranteed </a></p>
                            </div>
                            </div>

                            <!-- <div class="white-box-sdw mar-top-sm hidden-xs">
                               
                                <div  class="flex-row parksafe-with-img hidden-xs">
                                    <div  class="col-xs-12 text-center">
                                            <img style="max-width: 170px;" src="/assets/img/logo-parksafe-1.png">
                                    </div>
                                    <div  class="col-xs-12">
                                        <h3 class="text-center mar-bottom-sm">Park with Peace of Mind.</h3>
                                        <ul  class="dotsUl">
                                            <li><h4 class="text-primary">Service</h4>
                                                <p>Fully contactless mobile reservations, payments, check-in & check-out</p>
                                            </li>
                                            <li>
                                                <h4 class=" text-primary"> Sanitize</h4>
                                                <p>Designated Viking Pure® natural cleaning stations at every location</p>
                                            </li>
                                            <li>
                                                <h4 class="text-primary">Safety</h4>
                                                <p> Trained team members with masks & all practicing social distancing</p>
                                            </li>
                                            </ul>
                                    </div>
                                </div>
                            </div> -->
                            
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <app-lazy-load [isLoaded]="isLoaded" [sectionName]="'booking information'"></app-lazy-load>
</div>
<div class="modal fade" id="updatetime">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true">
<fa name="times"></fa>
</button>
                <h3 class="text-uppercase mar-top-0 mar-bottom-0">Edit Times</h3>
            </div>
            <div class="modal-body">
                <!-- <div class="form-group">
        <table width="100%" class="tableTime">
                <tr>
                        <td width="10%"><b>FROM</b></td>
                        <td><fa name="calendar-o" class="text-primary"></fa></td>
                        <td>
                                <input readonly  type="text" id="pstartdate" name="pstartdate" [minDate]="minDate" class="sdate" [maxDate]="maxDate" [(ngModel)]='startDate' #dp="bsDatepicker"
                                bsDatepicker [(bsValue)]="startDate">
                        </td>
                        <td><i class="fa text-primary fa-clock-o"></i></td>
                        <td><div class="dropdown filterBtn"><input readonly class="dropdown-toggle sdate" id="pstime" type="text" data-toggle="dropdown" value="{{ startTime | paymenttime }}"><ul class="dropdown-menu dropdown-menu-right"><li><timepicker [mousewheel]="false" [arrowkeys]="false"  id="pstarttime" name="pstarttime" [(ngModel)]="startTime" [minuteStep]="30"></timepicker></li></ul></div>
                    </td>
            </tr>
            <tr>
                    <td width="10%"><b>TO</b></td>
                    <td><fa name="calendar-o" class="text-primary"></fa></td>
                    <td>
                            <input class="sdate" readonly type="text" id="penddate" name="penddate" [minDate]="minDate" [maxDate]="maxDate" [(ngModel)]='endDate' #dp="bsDatepicker"
                            bsDatepicker [(bsValue)]="endDate">
                    </td>
                    
                    <td class="dropdown filterBtn">
                            
                            <i class="fa text-primary fa-clock-o"></i>
                    </td>
                    <td>
                            <div class="dropdown filterBtn">
                                    <input readonly class="dropdown-toggle sdate" id="petime" type="text" data-toggle="dropdown" value="{{ endTime | paymenttime }}" />
                                    <ul class="dropdown-menu dropdown-menu-right">
                                            <li>
                                                    <timepicker [mousewheel]="false" [arrowkeys]="false" id="pendtime" name="pendtime" [(ngModel)]="endTime"  [minuteStep]="30"></timepicker>
                                            </li>
                                    </ul>
                            </div>
                    </td>
            </tr>
    </table>
</div> -->
                <!-- <span class="form-group">
    <button type="submit" class="btn theme-btn btn-primary" (click)="checkAvailability()">Update Time</button>
</span> -->
            </div>
        </div>
    </div>
</div>
<app-footer></app-footer>

<ng-template #popTemplate>
    <img [src]="cardSecurityIcon" alt="icon">
</ng-template>



<a #myDiv (click)="overnightPopupPayment.show()" class="hide"></a>
<div bsModal #overnightPopupPayment="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">

    <div class="modal-dialog modal-md">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" #bookerrormsgpopupclose class="close" aria-label="Close" (click)="overnightPopupPayment.hide()">
          <fa name="times"></fa>
        </button>
            </div>

            <div class="modal-body">
                <h4 _ngcontent-c7="" class="modal-title mar-top-sm text-primary" style="font-size: 16px;">
                    {{isPpopupShowMessage}}
                </h4>
                <br>
                <div *ngIf="!loadWorkingHours">Please wait..</div>
                <div *ngIf="loadWorkingHours">
                    <div class="TimeActive table-responsive" *ngIf="garageWorkingHours?.length > 0">
                        <h4 class="text-uppercase">Hours of Operation</h4>

                        <table class="table table-condensed ">
                            <tr *ngFor="let wh of garageWorkingHours;">
                                <td>{{wh.day}}</td>
                                <td>:</td>
                                <td>{{wh.formatted_open_time}}</td>
                                <td>-</td>
                                <td>{{wh.formatted_close_time}}</td>
                            </tr>
                        </table>
                    </div>
                </div>
                <button type="button" class="btn btn-center-xs  btn-primary theme-btn mar-top-xs active" aria-label="Close" (click)="redirectTopaymentfromPopup()">Yes</button>
                <span class="hidden-xs" style="margin-left: 5px;"></span>
                <button type="button" class="btn btn-center-xs  btn-primary theme-btn mar-top-xs" aria-label="Close" (click)="hideModel()">No</button>
            </div>
        </div>
    </div>
</div>

<div bsModal #lgModalovernightPopup="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">

    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" #bookerrormsgpopupclose class="close" aria-label="Close" (click)="lgModalovernightPopup.hide();closeTermsPopup()">
          <fa name="times"></fa>
        </button>
            </div>

            <div class="modal-body" style="padding-top:0px;">
                <div class="">
                    <h2 class="text-primary mt-0">{{staticPageContent.section_title}}</h2>
                    <div [innerHTML]="staticPageContent.post_content"></div>
                </div>


              <div [ngClass]="{'hide': hideTermsPopupBtns}" class="form-group mar-top-sm mar-bottom-0 btn-center-xs">
                <button (click)="acceptTermsCondition();lgModalovernightPopup.hide();" [scrollTo]="'#ModalovernightPopup'" [scrollDuration]="1000" [scrollOffset]="-200" type="button" class="btn btn-primary btn-fill">ACCEPT</button>
                <span class="mar-left-xs"></span>
                <button (click)="lgModalovernightPopup.hide();closeTermsPopup()" [scrollTo]="'#ModalovernightPopup'" [scrollDuration]="1000" [scrollOffset]="-200" type="button" class="btn btn-primary theme-btn">CANCEL</button>
              </div>
        </div>
    </div>
</div>
</div>