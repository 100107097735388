<app-header></app-header>
<div class="page-header orangeBar mar-bottom-md" id="rewards-benifit"></div>
<app-lazy-load [isLoaded]="isLoaded" [sectionName]></app-lazy-load>
<div *ngIf="isLoaded" class="mar-top-md mar-bottom-md">
  <div class="container">
    <div class="page-content-wrapper">
      <h1 class="text-primary text-uppercase mar-top-0 mar-bottom-0">
        <span>{{cmsPageContent.post_title}}</span>
      </h1>
      <h2 class="text-uppercase mar-bottom-sm">{{cmsPageContent.page_sub_title}}</h2>
      <div class="row">
        <div class="col-sm-12">
          <div [innerHTML]="cmsPageContent.post_content"></div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>