<!-- 
<footer class="footerWidgetHide">
	<div class="container">
		<div class="row">

			<div class="col-xs-12 col-sm-5 col-md-4">

				<img [src]="icon_logo" alt="Icon Logo" class="mar-right-sm">
				<img [src]="qp_Logo" alt="icon">
				<p class="mar-bottom-md"></p>
				<a class="socialIcon" target="_blank" [href]="instagramUrl"><img [src]="instagramLogo" alt="icon"></a>
				<a class="socialIcon" target="_blank" [href]="facebookUrl"><img [src]="fbLogo" alt="icon"></a>
				<a class="socialIcon" target="_blank" [href]="twitterUrl"><img [src]="twiterLogo" alt="icon"></a>
				<br/><span>PARKING IS JUST THE START&trade;</span>
			</div>

			<div class="col-xs-12 col-sm-3 col-md-4 quicklinks">

				<ul class="list-unstyled">
					<li>
						<a routerLink="/monthly-parking">monthly parking</a>
					</li>
					
					<li>
						<a routerLink="/contact">contact us</a>
					</li>
					<li>
						<a *ngIf="!isLoyaltyMember" routerLink="/rewards">icon rewards</a>
						<a *ngIf="isLoyaltyMember" routerLink="/my-account/icon-rewards">icon rewards</a>
					</li>
					<li>
						<a [href]="newsTips">news + tips</a>
					</li>
					<li>
						<a routerLink="/neighborhoods/central-park">neighborhoods</a>
					</li>
					<li *ngIf="!isLoggedIn">
						<a data-toggle="modal" href='#login'>customer login</a>
					</li>
					<li *ngIf="isLoggedIn">
						<a routerLink="/my-account/account" >my account</a>
					</li>
					<li>
						<a routerLink="/about-us">About Us</a>
					</li>
					<li>
						<a routerLink="/evcharging">EV Charging</a>
					</li>
					<li>
						<a routerLink="/careers">careers</a>
					</li>
					<li>
						<a routerLink="/faq">faq</a>
					</li>
				</ul>
			</div>

			<div class="col-xs-12 col-sm-4 col-md-4">

				<p><a (click)="callcustomer()" [href]="phone" class="text-white"> 1 (877) 727-5464</a></p>
				<p id="copyright">&copy; 2020 Icon Parking - All Rights Reserved.</p>
				<a routerLink="/terms-of-service">Terms of Service &amp; Privacy Policy</a>
			</div>
		</div>
	</div>
</footer>
 -->

 <footer class="sec-padding-tb-40 ">
    <div class="container pt-4 pb-4 ">
        <div class="row ">
            <div class="col-sm-4 ">
                <div class="logo-part ">
                    <img [src]="icon_logo " width="100 " class="ftr-logo ">
                    <!-- <img [src]="qp_Logo " width="50 " class="ml-4 "> -->

                    <div class="trusted-rate-footer">
                        <!-- <div id="trustboxOpenFooter" class="trustpilot-widget ratingStar" data-locale="en-US" data-template-id="53aa8807dec7e10d38f59f32" data-businessunit-id="5feb84d1e2aa300001625337" data-style-height="115px" data-style-width="100%" data-theme="light" data-location="" data-text-color="#ffffff">
                            <a href="https://www.trustpilot.com/review/iconparkingsystems.com" style="display: none;" target="_blank" rel="noopener">Trustpilot</a>
                          </div> -->
                          <div id="trustboxOpenFooter" class="trustpilot-widget ratingStar" data-locale="en-US" data-template-id="53aa8807dec7e10d38f59f32" data-businessunit-id="5feb84d1e2aa300001625337" data-style-height="115px" data-style-width="250px" data-theme="dark" data-location="">
                            <a href="https://www.trustpilot.com/review/iconparkingsystems.com" style="display: none;" target="_blank" rel="noopener">Trustpilot</a>
                          </div>
                    </div>

                    <div class="footer-social ">
                        <p class=" ">
                            <a class="socialIcon " target="_blank" [href]="instagramUrl"><img [src]="instagramLogo" alt="icon "></a>
                            <a class="socialIcon " target="_blank" [href]="facebookUrl"><img [src]="fbLogo" alt="icon "></a>
                            <a class="socialIcon " target="_blank" [href]="twitterUrl"><img [src]="twiterLogo" alt="icon "></a>
                        </p>
                        <h4>PARKING IS JUST THE START<sup>TM</sup></h4>
                    </div>
                </div>
            </div>
            <div class="col-sm-8 ">
                <div class="row ">
                    <div class="col-md-7 ">
                        <div class="row ">

                            <div class="col-md-4 visible-xs mar-bottom-sm ">
                                <div class="footerAppButton">
                                    <div class="flex-row no-gutters">
                                        <div class="col-auto">
                                            <img class="alterImg" src="../../../assets/img/new-homepage/arrow-list-icongo-wt.svg">
                                        </div>
                                        <div class="col">
                                            <h4>
                                             PARK LIKE A VIP,<br>
                                             <span class="text-primary">DOWNLOAD THE APP</span>
                                            </h4>
                                        </div>
         
                                    </div>
                                    <p>
                                     Icon GO app offers a level of service 
                                     you just won't find anywhere else. 
                                    </p>
                                    <ul>
                                        <li>
                                            <img src="../../../assets/img/new-homepage/b2b-img/dots.png" height="6px"> &nbsp; Direct booking discounts
                                        </li>
                                        <li>
                                         <img src="../../../assets/img/new-homepage/b2b-img/dots.png" height="6px"> &nbsp; Seamless check in and check out
                                     </li>
                                     <li>
                                         <img src="../../../assets/img/new-homepage/b2b-img/dots.png" height="6px"> &nbsp; Exclusive services and features
                                     </li>
                                    </ul>
                                    <p>
                                        Download the app
                                    </p>
                                    <div class="flex-row no-gutters">
                                        <div class="col">
                                            <a target="_blank"   [href]="appStoreUrl" class="appstore-img  "><img alt="App Store " [src]="appstore" class="img-responsive" ></a>
        
                                        </div>
                                        <div class="col">
                                            <a target="_blank"    [href]="googlePlayUrl"><img alt="Google play " [src]="gstore " class="img-responsive"></a>
        
                                        </div>
                                  
                                    </div>
                                 </div>
        
                                <!-- <ul>
                                    <li> <a (click)="callcustomer()" [href]="phone" class="text-white"> 1 (877) 727-5464</a></li>
                                </ul> -->
                            </div>
                            <div class="col-sm-6 col-md-6 ">
                                <ul>
                                    <li *ngIf="showRenewalBtn" class="visible-xs"> <a (click)="setmenu();" routerLink="/my-account/monthly-parking/account/{{ accountNumber }}/account-subscription-renewal" aria-controls="subscriptionrenewal" role="tab" data-toggle="tab" class="btn btn-fill btn-primary side-btn" style="text-transform: capitalize!important; border-radius: 0px; width: 100%; padding: 8px 15px; margin-bottom: 5px;">Renew My Subscription</a></li>
									<!-- <li> <a routerLink="/b2bsolutions">Business to Business Sales</a></li> -->
                                    <!-- <li> <a *ngIf="!isLoyaltyMember" (click)="triggerEvent('ICON REWARDS')" routerLink="/rewards">icon rewards</a>
                                        <a *ngIf="isLoyaltyMember" (click)="triggerEvent('ICON REWARDS')" routerLink="/my-account/icon-rewards">icon rewards</a> </li> -->
                                    <li> <a [href]="newsTips">news + tips</a> </li>
                                    <li> <a style="cursor: pointer;" (click)="triggerEvent('MONTHLY PARKING SPECIALS');formonthly();">monthly parking</a></li>
                                    <li> <a routerLink="/contact">contact us</a></li>
									<!-- <li> <a routerLink="/neighborhoods/central-park/monthly">neighborhoods</a></li> -->
                                    <li><a routerLink="/faq">faq</a></li>
                                </ul>
                            </div>
                            <div class="col-sm-6 col-md-6">
                                <ul>
                                    <li *ngIf="!isLoggedIn">
                                        <a [class.active]="logoutmenu()" data-toggle="modal" id="loginPopup" href='#login'>login</a>
                                    </li>
                                    <li *ngIf="isLoggedIn">
                                        <a routerLink="/my-account/account">my account</a>
                                    </li>
                                    <li>
                                        <a routerLink="/about-us">About Us</a>
                                    </li>
                                    <li>
                                        <a routerLink="/evcharging">EV Charging</a>
                                    </li>
                                    <li>
                                        <a routerLink="/careers">Careers/Jobs </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-5 hidden-xs ">
                        <div class="footerAppButton">
                            <div class="flex-row no-gutters">
                                <div class="col-auto">
                                    <img class="alterImg" src="../../../assets/img/new-homepage/arrow-list-icongo-wt.svg">
                                </div>
                                <div class="col">
                                    <h4>
                                     PARK LIKE A VIP,<br>
                                     <span class="text-primary">DOWNLOAD THE APP</span>
                                    </h4>
                                </div>
 
                            </div>
                            <p>
                             Icon GO app offers a level of service 
                             you just won't find anywhere else. 
                            </p>
                            <ul>
                                <li>
                                    <img src="../../../assets/img/new-homepage/b2b-img/dots.png" height="6px"> &nbsp; Direct booking discounts
                                </li>
                                <li>
                                 <img src="../../../assets/img/new-homepage/b2b-img/dots.png" height="6px"> &nbsp; Seamless check in and check out
                             </li>
                             <li>
                                 <img src="../../../assets/img/new-homepage/b2b-img/dots.png" height="6px"> &nbsp; Exclusive services and features
                             </li>
                            </ul>
                            <p>
                                Download the app
                            </p>
                            <div class="flex-row no-gutters">
                                <div class="col">
                                    <a target="_blank"   [href]="appStoreUrl" class="appstore-img  "><img alt="App Store " [src]="appstore" class="img-responsive" ></a>

                                </div>
                                <div class="col">
                                    <a target="_blank"    [href]="googlePlayUrl"><img alt="Google play " [src]="gstore " class="img-responsive"></a>

                                </div>
                          
                            </div>
                         </div>
                        <!-- <ul>
                            <li> <a (click)="callcustomer()" [href]="phone" class="text-white"> 1 (877) 727-5464</a></li>
                        </ul> -->
                    </div>
                    <!-- <div class="col-md-4 hidden-xs ">
                        <ul>
                            <li> <a (click)="callcustomer()" [href]="phone" class="text-white"> 1 (877) 727-5464</a></li>
                        </ul>
                    </div> -->
                    
                </div>
                <div class="row">
                    <div class="col-md-12 ">
                        <!-- <h3 class="visible-xs mob-ftr-bottom "><a (click)="callcustomer() " [href]="phone " class="text-white ">1 (877) 727-5464</a></h3> -->
                        <p class="ftr-copy ">
                            <small>© {{copyrightyear}} Icon Parking - All Rights Reserved.  <br class="visible-xs" ><a  routerLink="/terms-of-service">Terms of Service</a> & <a  routerLink="/terms-of-service">Privacy Policy</a></small></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>
