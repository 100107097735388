<div class="collapse in active" id="cardExpand">
	<form action="" method="POST" role="form" id="" class="floating-label2" (ngSubmit)="f.form.valid && submitAddCreditCard(formData, f)" #f="ngForm" novalidate>
		<div class="row">
			<div *ngIf="has_valet_manager == false" class="col-sm-8">
				<div class="form-group focused" [ngClass]="{ 'has-error' : f.submitted && !card_number.valid }">
					<label class="control-label">Card Number</label>
					<input autocomplete="off" [textMask]="{mask: cardMask}" type="text" (keypress)="restrictNumeric($event)" class="form-control" id="" name="card_number" [(ngModel)]="formData.card_number" #card_number="ngModel" required>
					<div *ngIf="f.submitted && !card_number.valid" class="help-block">Card Number is required</div>
				</div>
			</div>
			<div *ngIf="has_valet_manager" class="col-sm-8">
				<div class="form-group focused" style="margin-bottom: -10px;" [ngClass]="{ 'has-error' : f.submitted && showCardError}">
					<label class="control-label">Card Number</label>
					<!-- <iframe  id="tokenFrame" height="40px" name="tokenFrame" src="https://valetmanager.cardconnect.com/itoke/ajax-tokenizer.html?formatinput=true&invalidinputevent=true&placeholder=&cardinputmaxlength=19&invalidcreditcardevent=true&css=body%7Bfont-family%3Ainherit%3Bpadding%3A0px%3Bmargin%3A0px%3Bcolor%3A%23000%3B%7D%3A%3A-webkit-input-placeholder%7Bcolor%3A%23999%3Bfont-weight%3A300%3B%7D%3A-ms-input-placeholder%7Bcolor%3A%23999%3Bfont-weight%3A300%3B%7D%3A%3Aplaceholder%20%7Bcolor%3A%23999%3Bfont-weight%3A300%3B%7Dinput%3Afocus%20%7Bborder%3A1px%20solid%20%23ed8222!important%3Bbackground-color%3A%23fff!important%3B-webkit-box-shadow%3Ainset%200%201px%201px%20rgba(0%2C0%2C0%2C.075)!important%3Bbox-shadow%3Ainset%200%201px%201px%20rgba(0%2C0%2C0%2C.075)!important%3B%7Dinput%7Bborder%3A1px%20solid%20%23d8d8d8%3Bbox-sizing%3Aborder-box%3Bborder-radius%3A4px%3Bbackground-color%3Atransparent%3B-webkit-box-shadow%3Anone%3Bbox-shadow%3Anone%3Bcolor%3A%23000%3Bheight%3A40px%3Bwidth%3A100%25%3Bbackground-position%3A96%25center%3Bpadding-left%3A15px%3Bpadding-right%3A15px%7D"  scrolling="no" width="100%"  frameborder="0"></iframe>  -->
                    <iframe  id="tokenFrame" height="40px" name="tokenFrame" src="https://valetmanager.cardconnect.com/itoke/ajax-tokenizer.html?formatinput=true&invalidinputevent=true&placeholder=&cardinputmaxlength=19&invalidcreditcardevent=true&css=body%7Bmargin%3A0px%3Bpadding%3A0px%3Bfont-family%3Aarial%3B%7D+++input+%7Bborder-bottom-width%3A+2px%3Bborder-top%3A+0px%3Bborder-left%3A+0px%3Bborder-right%3A+0px%3Bborder-bottom-color%3A+%23ed8222%3Bborder-radius%3A+0%3Bbackground-color%3A+transparent%3B-webkit-box-shadow%3A+none%3Bbox-shadow%3A+none%3Bcolor%3A+%23000000%3Bheight%3A+36px%3Bwidth%3A+100.0%25%3Bpadding-left%3A15px%3Boutline%3A+none%3B%7D"  scrolling="no" width="100%"  frameborder="0"></iframe>
					<input style="float: left;visibility: hidden; padding: 0; height: 0; margin: 0; width: 0;" type="text" name="accountcreditCardToken" id="accountcreditCardToken"/>
					<div *ngIf="f.submitted && showCardError" class="help-block">Card Number is required</div>
				</div>
			</div>
			<div class="col-sm-4">
				<div class="form-group focused" [ngClass]="{ 'has-error' : f.submitted && !expiration_date.valid }">
					<label class="control-label">Expiration Date</label>
					<input autocomplete="off" [textMask]="{mask: dateMask}" [minlength]="4" type="text" class="form-control" name="expiration_date" [(ngModel)]="formData.expiration_date" #expiration_date="ngModel" required>
					<div *ngIf="f.submitted && !expiration_date.valid" class="help-block">Expiration Date is required</div>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-sm-8">
				<div class="form-group focused" [ngClass]="{ 'has-error' : f.submitted && !name_on_card.valid }">
					<label class="control-label">Name On Card</label>
					<input autocomplete="off" type="text" class="form-control" name="name_on_card" [(ngModel)]="formData.name_on_card" #name_on_card="ngModel" required>
					<div *ngIf="f.submitted && !name_on_card.valid" class="help-block">Name On Card is required</div>
				</div>
			</div>
			<div class="col-sm-4">
				<div class="form-group focused" [ngClass]="{ 'has-error' : f.submitted && !security_code.valid }">
					<label class="control-label">Security Code</label>
					<div class="input-group">
						<input autocomplete="off" maxlength="4" class="form-control" type="text" name="security_code" [(ngModel)]="formData.security_code" #security_code="ngModel" required />
            <!-- <div class="input-group-addon bgTransparent"><img [src]="infoIcon" alt="image"></div> -->
            <div *showItBootstrap="['md','lg']" class="input-group-addon pad-left-xs pad-right-xs  bgTransparent" container="body" containerClass="PaymentCustome" placement="left" [tooltip]="popTemplate"><img [src]="infoIcon" alt="i"></div>
            <div *showItBootstrap="['xs','sm']" class="input-group-addon pad-left-xs pad-right-xs  bgTransparent" container="body" containerClass="PaymentCustome" placement="left" [tooltip]="popTemplate"><img [src]="infoIcon" alt="i"></div>
					</div>
					<div *ngIf="f.submitted && !security_code.valid" class="help-block">Security code is required</div>
				</div>
			</div>
			<div class="col-sm-4">
				<div class="form-group focused zip-width" [ngClass]="{ 'has-error' : f.submitted && !zip_code.valid }">
					<label class="control-label">Zip Code</label>
					<div class="input-group zip-width">
						<input autocomplete="off" maxlength="6" minlength="5" class="form-control" type="text" name="zip_code" [(ngModel)]="formData.zip_code" #zip_code="ngModel" required />
					</div>
					<div *ngIf="f.submitted && !zip_code.valid" class="help-block zip-error">Zip code is required</div>
				</div>
			</div>
		</div>
		<div class="form-group focused visible-xs clearfix">
			<div class="col-xs-offset-1 col-xs-10">
				<button type="submit" class="btn btn-primary theme-btn full-width mar-bottom-xs pad-left-0 pad-right-0">Add Payment Method</button>
				<button type="button" class="btn btn-error btn-primary theme-btn full-width" (click)="hitCancel()">cancel</button>
			</div>
		</div>
		<div class="form-group focused hidden-xs">
			<button type="submit" class="btn btn-primary theme-btn">Add Payment Method</button><span class="mar-right-xs"></span><button type="button" class="btn btn-error theme-btn btn-primary" (click)="hitCancel()">cancel</button>
		</div>
	</form>
</div>

<ng-template #popTemplate>
  <img [src]="cardSecurityIcon" alt="icon">
</ng-template>