import { Component, OnInit } from '@angular/core';
import { CustomCode } from '../../shared/CustomCode';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { FilterHelper } from '../../classes/filter-helper';
// declare var ga: Function;
declare var Trustpilot;

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  copyrightyear:any;
  contactTelNo = CustomCode.contactTelephoneNumber;
  isLoggedIn = false;
  isLoyaltyMember:boolean = false;

  
  qp_Logo:string = 'assets/img/qp_logo.svg';
  fbLogo:string = 'assets/img/new-homepage/facebook.png';
  twiterLogo:string = 'assets/img/new-homepage/twitter.png';
  instagramLogo = 'assets/img/new-homepage/instagram.png';
  appStoreUrl = 'https://itunes.apple.com/us/app/icon-parking/id394377820?mt=8';
  googlePlayUrl = 'https://play.google.com/store/apps/details?id=com.iconparking&hl=en';

  gstore:string = 'assets/img/new-homepage/google-play.svg';
  appstore:string = 'assets/img/new-homepage/AppStore.svg';

  icon_logo:string ='assets/img/icon_logo.svg';




  instagramUrl = 'https://www.instagram.com/iconparkingnyc/?hl=en';
  facebookUrl = 'https://www.facebook.com/IconParkingNYC';
  twitterUrl = 'https://twitter.com/IconParkingNYC';
  newsTips = '/cms/news/';
  phone = 'tel:+1(877)727-5464'
  startDuration:any;
  endDuration:any;
  startTime:any;
  endTime:any;
  showRenewalBtn = false;
  accountNumber = 0;
  
  constructor( private router: Router){

  }
  ngOnInit() {
    this.copyrightyear=new Date();
    this.copyrightyear=this.copyrightyear.getFullYear();
    let psin= setInterval(()=>{
      if(sessionStorage.getItem('monthlyXyz') && sessionStorage.getItem('is_subs') && this.router.url.indexOf('account')){
        this.accountNumber = parseInt(sessionStorage.getItem('monthlyXyz'));
        this.showRenewalBtn = true
      }else {
        this.showRenewalBtn = false
      }
     },10)
    setTimeout(()=>{
      const trustboxRef = document.getElementById('trustboxOpenFooter');
      if(trustboxRef !==null)
      Trustpilot.loadFromElement(trustboxRef);

    },1000)
    
    if(!sessionStorage.getItem('loadFirstimeagainText2')) {
      sessionStorage.setItem('loadFirstimeagainText2','1')
      // window.location.reload();
    }
  	if (localStorage.getItem('access_token')) { this.isLoggedIn = true; }
    if(localStorage.getItem('is_loyalty')==="1"){ this.isLoyaltyMember = true; }
  }
   callcustomer(){
      (function(a,e,c,f,g,h,b,d){var k={ak:"876742851",cl:"BjciCOKo-nsQw5GIogM",autoreplace:"(877) 727-5464"};a[c]=a[c]||function(){(a[c].q=a[c].q||[]).push(arguments)};a[g]||(a[g]=k.ak);b=e.createElement(h);b.async=1;b.src="//www.gstatic.com/wcm/loader.js";d=e.getElementsByTagName(h)[0];d.parentNode.insertBefore(b,d);a[f]=function(b,d,e){a[c](2,b,k,d,null,new Date,e)};a[f]()})(window,document,"_googWcmImpl","_googWcmGet","_googWcmAk","script");
    }

    logoutmenu()
  {
    if(this.router.url.indexOf('create-account') > -1)
    {
      return true;
    }else{
      return false;
    }
  }

  triggerEvent(label){
    /* commented for ga 16-6-2021 by seo team
    ga('send', 'event', { eventCategory: label, eventLabel:CustomCode.BASEURL, eventAction: 'click'});
    */
  }

  formonthly()
  {
      this.startDuration = FilterHelper.defaultStartDuration();
      this.startTime = FilterHelper.getFormattedTime(this.startDuration);
      localStorage.setItem('monthlyDate',this.startDuration);
      this.endDuration = FilterHelper.defaultEndDuration();
      this.endTime = FilterHelper.getFormattedTime(this.endDuration);
      let endTimeDataIfLess = FilterHelper.makeDurationDateObject(this.endDuration, this.endTime);      
      localStorage.setItem('endttimehome',CustomCode.getDateForMapSearchSlashFormatHome(this.endDuration,this.endTime));
      localStorage.setItem('startimehome',CustomCode.getDateForMapSearchSlashFormatHome(this.startDuration,this.startTime));
      let stime =localStorage.getItem('startimehome');
      localStorage.setItem('showNeighbordood','1');
      localStorage.setItem('specialParking','1');
      localStorage.setItem('sortBy', "Neighborhood");
      this.router.navigate(['/search', '40.7782667', '-73.9698797', stime, 1,  'central-park','monthly', CustomCode.searchMode()]);
  }

  setmenu()
  {
    localStorage.setItem('menuSet','1')
  }
}
