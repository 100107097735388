<div class="mar-top-sm" id="account">
    <div class="myAcountDetails" id="accountDetails_div" *ngIf="accountDetails === true">
        <h2 class="mar-top-0 text-uppercase text-primary mar-bottom-sm">My Account</h2>
        <div class="flex-row row" *ngIf="isLoaded">
            <div class="col-xs col-sm-4 col-md-4 col-lg-3" *hideItBootstrap="['xs']">
                <div class="well">
                    <h4 class="mar-top-0 text-primary text-uppercase">Account Information
                        <a style="cursor:pointer" (click)="navigateUpdateProfile()" class="pull-right">
                            <i class="fa fa-pencil" size="2x"></i>
                        </a>
                    </h4>
                    <p class="wordBreak-all">{{ activeUser.name }}</p>
                    <p>
                        <span class="wordBreak-all">{{ activeUser.email }}</span>
                        <!-- <a *ngIf="(isLoyaltyActive && isLoyaltyMember) || (!isLoyaltyActive && !isLoyaltyMember)" (click)="navigateResetEmail()" style="cursor:pointer;">Change&nbsp;Email&nbsp;&nbsp;
              <fa name="chevron-right"></fa>
            </a> -->
                    </p>
                    <p class="wordBreak-all" *ngIf="activeUser.phone != null">{{phoneFormat(activeUser.phone) }}</p>
                    <p *ngIf="activeUser?.pincode" class="wordBreak-all mar-top-sm">Zip code : {{activeUser?.pincode}}</p>
                    <p *ngIf="activeUser?.dob && activeUser?.dob!=='0000-00-00'" class="wordBreak-all">Birth date : {{activeUser?.dob.replace('01T','05T') | date:"MMMM, yyyy" }}</p>
                    <!-- <p>
            <a (click)="navigateChangePassword()" style="cursor:pointer;">Change Password&nbsp;&nbsp;
              <fa name="chevron-right"></fa>
            </a>
          </p> -->
                    <div class="clearfix"></div>
                    <div class="enrolle-card-aacount mar-top-xs pad-top-xs clearfix">
                        <h4 *ngIf="showMethodHeading" class="mar-bottom-xs text-uppercase text-primary">Payment Methods</h4>
                        <ul class="nav">
                            <ng-container *ngFor="let card of fetchUserSavedCards?.data?.payments; let i = index">
                                <li class="btn-visacard">
                                    <ng-container [ngSwitch]="card.card.card_type">
                                        <span *ngSwitchCase="'Visa'" class="cardReservation card-blue" id="cards{{i}}">
                      <a (click)="removeCard(card.payment_profile_id,i,card.card.card_number)" class="pull-right mar-top-2">
                        <fa name="trash" size="1x"></fa>
                      </a>
                      <img [src]="visa_Icon" alt="icon"> &nbsp;&nbsp; Visa ({{card.card.card_number.replace('XXXX', '..')}})
                      &nbsp;&nbsp;</span>
                                        <span class="cardReservation card-blue" *ngSwitchCase="'MasterCard'" id="cards{{i}}">
                      <a (click)="removeCard(card.payment_profile_id,i,card.card.card_number)" class="pull-right mar-top-2">
                        <fa name="trash" size="1x"></fa>
                      </a>
                      <img [src]="mastercardIcon" alt="icon"> &nbsp;&nbsp; Master Card ({{card.card.card_number.replace('XXXX',
                      '..')}}) &nbsp;&nbsp;</span>
                                        <span class="cardReservation card-blue" *ngSwitchCase="'AmericanExpress'" id="cards{{i}}">
                      <a (click)="removeCard(card.payment_profile_id,i,card.card.card_number)" class="pull-right mar-top-2">
                        <fa name="trash" size="1x"></fa>
                      </a>
                      <img [src]="amexIcon" alt="icon"> &nbsp;&nbsp; American Express ({{card.card.card_number.replace('XXXX',
                      '..')}}) &nbsp;&nbsp;</span>
                                        <span class="cardReservation card-blue" *ngSwitchCase="'Discover'" id="cards{{i}}">
                      <a (click)="removeCard(card.payment_profile_id,i,card.card.card_number)" class="pull-right mar-top-2">
                        <fa name="trash" size="1x"></fa>
                      </a>
                      <img [src]="discovercardIcon" alt="icon"> &nbsp;&nbsp; Discover ({{card.card.card_number.replace('XXXX',
                      '..')}}) &nbsp;&nbsp;</span>
                                        <span class="cardReservation card-blue" *ngSwitchCase="'JCB'" id="cards{{i}}">
                      <a (click)="removeCard(card.payment_profile_id,i,card.card.card_number)" class="pull-right mar-top-2">
                        <fa name="trash" size="1x"></fa>
                      </a>
                      <img [src]="jcbIcon" alt="icon"> &nbsp;&nbsp; JCB ({{card?.card?.card_number?.replace('XXXX',
                      '..')}}) &nbsp;&nbsp;</span>
                                        <span class="cardReservation card-blue" *ngSwitchCase="'DinersClub'" id="cards{{i}}">
                      <a (click)="removeCard(card.payment_profile_id,i,card.card.card_number)" class="pull-right mar-top-2">
                        <fa name="trash" size="1x"></fa>
                      </a>
                      <img [src]="dinersIcon" alt="icon"> &nbsp;&nbsp; Diners Club ({{card.card.card_number.replace('XXXX',
                      '..')}}) &nbsp;&nbsp;</span>
                                        <span class="cardReservation card-blue" *ngSwitchDefault id="cards{{i}}">
                      <a (click)="removeCard(card.payment_profile_id,i,card.card.card_number)" class="pull-right mar-top-2">
                        <fa name="trash" size="1x"></fa>
                      </a>
                      <img [src]="card_grey_Icon" alt="icon"> &nbsp;&nbsp; Other ({{card.card.card_number.replace('XXXX', '..')}})
                      &nbsp;&nbsp;</span>
                                    </ng-container>
                                </li>
                            </ng-container>
                        </ul>
                    </div>

                </div>
            </div>
            <div class="col-xs col-sm-8 col-md-8 col-lg-9 ">
                    <!--   hide icon reward
                <div class="clearfix">
                    <h2 class="text-uppercase mar-top-0">Icon Rewards </h2>
                    

                     <div class="wrapper reward-bar mar-bottom-sm pad-bottom-xs">

                        <div *ngIf="isLoyaltyMember && isLoyaltyActive" class="well">

                            <div class="clearfix visible-xs">
                                <p>Current Time Status : JAN 01 2020 - DEC 31 2020</p>
                                <h2>Elite+ <small><a href="javascript:;">View Benefits</a></small></h2>
                            </div>

                            <hr class="visible-xs ">
                            <div class="clearfix mar-bottom-xs">
                                <div class="row ">
                                    <div class="col-sm-6 col-md-6 col-lg-4 hidden-xs">
                                        <p>Current Time Status : JAN 01 2020 - DEC 31 2020</p>
                                        <h2>Elite+ <small><a href="javascript:;">View Benefits</a></small></h2>
                                    </div>


                                    <div class="col-sm-6 col-md-6 col-lg-4 col-md-offset-4">
                                        <p>Current Time Status : JAN 01 2020 - DEC 31 2020</p>
                                        <h2>Rewards Member</h2>
                                    </div>

                                </div>

                                <hr class="hidden-xs">
                            </div>
                            <div class="progressBarArea">

                                <div class="row flex-row">
                                    <div class="col ">
                                        <div class="progress">
                                            <div class="progress-bar" role="progressbar" aria-valuenow="70" aria-valuemin="0" aria-valuemax="100" style="width:24%">
                                                <span>Daily Spend : $3475.00</span>
                                            </div>
                                            <div class="progress-bar bg-dark" role="progressbar" aria-valuenow="30" aria-valuemin="0" aria-valuemax="100" style="width:78%">
                                                <span>Spend $25.00 more to reach <b>Elite+</b> Status!</span>
                                            </div>

                                        </div>

                                    </div>
                                </div>
                                <div class="row flex-row">
                                    <div class="col text-center text-primary ">Rewards Member</div>
                                    <div class="col text-center text-primary">Elite</div>
                                    <div class="col text-center text-primary">Elite+</div>
                                    <div class="col text-center text-primary">Diamond Elite</div>
                                </div>
                                <div class="text-center mar-top-sm">
                                    <p>
                                        Congratulations! You are being upgraded o Elite+ Status for the being a monthly customer.
                                    </p>
                                </div>
                            </div>

                        </div>
                    </div> 

                   


                    <div class="wrapper mar-bottom-sm pad-bottom-xs">
                        <div *ngIf="!isLoyaltyMember || !isLoyaltyActive" class="well pad-grey-box">
                            <p>You are not enrolled in Icon Rewards yet. All Icon Rewards members receive at least 10% off posted facility rates and online reservations! Enroll now to unlock immediate discounts!</p>
                            <div class="mar-top-sm btn-center-xs">
                                <a *ngIf="!isLoyaltyMember" routerLink="/my-account/enroll-in-icon-rewards" class="btn btn-primary btn-fill">ENROLL
                  IN ICON REWARDS</a>
                                <a *ngIf="!isLoyaltyActive && isLoyaltyMember" routerLink="/my-account/manage-icon-rewards-card" class="btn btn-primary btn-fill">ENROLL
                  IN ICON REWARDS</a>
                                <span class="mar-left-xs"></span>
                                <a routerLink="/rewards" class="btn theme-btn btn-primary">Read More</a>
                            </div>
                        </div>
                        <div *ngIf="isLoyaltyMember && isLoyaltyActive">
                            <div class="row">
                                <div class="col-sm-8 col-xs-5">
                                    <p>View more details and manage your rewards on your Icon Rewards page</p>
                                </div>
                            </div>
                            <a routerLink="/my-account/icon-rewards" class="btn btn-primary btn-fill pull-left">VIEW REWARDS</a>
                        </div>
                    </div>
                </div>-->
                <div class="clearfix">
                    <h2 class="text-uppercase mar-top-0 mar-bottom-xs" style="margin-top: 0;">My Monthly Parking</h2>
                    <div *ngIf="showornot">
                        <div class="wrapper" *ngFor="let monthlyParking of activeUser.monthly_parking_facilities">
                            <div class="well" id="active{{monthlyParking.id}}">
                                <h4 class="text-uppercase">{{ monthlyParking?.facility_name_modified}}</h4>
                                <ADDRESS>{{ monthlyParking?.entrance_location }}</ADDRESS>
                                <div class="hide-last" *ngFor="let monthlyParkingNum of monthlyParking.accounts">
                                    <div id="accountM{{monthlyParkingNum.account_number}}" class="hidden-xs dualAccount" *ngIf="monthlyParkingNum.active == '1'">

                                        <div class="row">
                                        <div class="col-xs-12 col-sm-3 col-md-2 col-lg-2">
                                            <p class="mar-bottom-0"><small>Account Number</small><br> {{ monthlyParkingNum.account_number }}
                                            </p>
                                        </div>

                                        <div class="col-xs-12 col-sm-3 col-md-2 col-lg-2" *ngIf="monthlyParkingNum.active == '1'">
                                            <p class="mar-bottom-0">
                                                <small>Status</small><br> Active
                                            </p>
                                        </div>
                                        <div class="col-xs-12 col-sm-3 col-md-2 col-lg-2" *ngIf="monthlyParkingNum.active == '0'">
                                            <p class="mar-bottom-0">
                                                <small>Status</small><br> Inactive
                                            </p>
                                        </div>
                                        <div *ngIf="monthlyParkingNum?.is_valid_ar_account==1" class="col-xs-12 col-sm-6 col-md-8 col-lg-8 text-right">
                                            <a routerLink="/my-account/monthly-parking/account/{{ monthlyParkingNum.account_number }}/current-balance" class="btn viewAccount btn-primary btn-fill">View Account</a>
                                        </div>
                                        <div *ngIf="monthlyParkingNum?.is_valid_ar_account==0" class="col-xs-12 col-sm-6 col-md-8 col-lg-8 text-right">
                                            <p style="color:red">Error retrieving data</p>
                                        </div>

</div>

                                    </div>
                                    <div id="accountD{{monthlyParkingNum.account_number}}" class="visible-xs dualAccount " *ngIf="monthlyParkingNum.active == '1'">

                                        <div class="row">
                                            
                                        
                                        <div class="col-xs-5">
                                            <p class="mar-bottom-0"><small>Account Number</small><br> {{ monthlyParkingNum.account_number }}
                                            </p>
                                            <p class="mar-bottom-0 mar-top-xs" *ngIf="monthlyParkingNum.active == '1'">
                                                <small>Status</small><br> Active
                                            </p>
                                            <p class="mar-bottom-0 mar-top-xs" *ngIf="monthlyParkingNum.active == '0'">
                                                <small>Status</small><br> Inactive
                                            </p>
                                        </div>
                                        <div *ngIf="monthlyParkingNum?.is_valid_ar_account==1" class="col-xs-7 text-right pad-top-sm pad-left-0">
                                            <a routerLink="/my-account/monthly-parking/account/{{ monthlyParkingNum.account_number }}/current-balance" class="mar-top-xs btn viewAccount btn-primary btn-fill">View Account</a>
                                        </div>
                                        <div *ngIf="monthlyParkingNum?.is_valid_ar_account==0" class="col-xs-7 text-right pad-top-sm pad-left-0">
                                            <p style="color:red">Error retrieving data</p>
                                        </div>

</div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="wrapper" *ngFor="let monthlyParking of activeUser.monthly_parking_facilities">
                            <div class="well" id="inactive{{monthlyParking.id}}">
                                <h4 class="text-uppercase">{{ monthlyParking?.facility_name_modified}}</h4>
                                <ADDRESS>{{ monthlyParking?.entrance_location }}</ADDRESS>
                                <div *ngFor="let monthlyParkingNum of monthlyParking.accounts">
                                    <div id="inaccountM{{monthlyParkingNum.account_number}}" class="hidden-xs dualAccount  hide-last" *ngIf="monthlyParkingNum.active == '0'">
                                       
                                       <div class="row">
                                       
                                        <div class="col-xs-12 col-sm-3 col-md-2 col-lg-2">
                                            <p class="mar-bottom-0"><small>Account Number</small><br> {{ monthlyParkingNum.account_number }}
                                            </p>
                                        </div>

                                        <div class="col-xs-12 col-sm-3 col-md-2 col-lg-2" *ngIf="monthlyParkingNum.active == '1'">
                                            <p class="mar-bottom-0">
                                                <small>Status</small><br> Active
                                            </p>
                                        </div>
                                        <div class="col-xs-12 col-sm-3 col-md-2 col-lg-2" *ngIf="monthlyParkingNum.active == '0'">
                                            <p class="mar-bottom-0">
                                                <small>Status</small><br> Inactive
                                            </p>
                                        </div>
                                        <div *ngIf="monthlyParkingNum?.is_valid_ar_account==1" class="col-xs-12 col-sm-6 col-md-8 col-lg-8 text-right">
                                            <a routerLink="/my-account/monthly-parking/account/{{ monthlyParkingNum.account_number }}/current-balance" class="btn viewAccount btn-primary btn-fill">View Account</a>
                                        </div>
                                        <div *ngIf="monthlyParkingNum?.is_valid_ar_account==0" class="col-xs-12 col-sm-6 col-md-8 col-lg-8 text-right">
                                            <p style="color:red">Error retrieving data</p>
                                        </div>

</div>

                                    </div>
                                    <div id="inaccountD{{monthlyParkingNum.account_number}}" class="visible-xs dualAccount hide-last" *ngIf="monthlyParkingNum.active == '0'">
                                        
                                        <div class="row">


                                        
                                        <div class="col-xs-5">
                                            <p class="mar-bottom-0"><small>Account Number</small><br> {{ monthlyParkingNum.account_number }}
                                            </p>
                                            <p class="mar-bottom-0 mar-top-xs" *ngIf="monthlyParkingNum.active == '1'">
                                                <small>Status</small><br> Active
                                            </p>
                                            <p class="mar-bottom-0 mar-top-xs" *ngIf="monthlyParkingNum.active == '0'">
                                                <small>Status</small><br> Inactive
                                            </p>
                                        </div>
                                        <div *ngIf="monthlyParkingNum?.is_valid_ar_account==1" class="col-xs-7 text-right pad-top-sm pad-left-0">
                                            <a routerLink="/my-account/monthly-parking/account/{{ monthlyParkingNum.account_number }}/current-balance" class="mar-top-xs btn viewAccount btn-primary btn-fill">View Account</a>
                                        </div>
                                        <div *ngIf="monthlyParkingNum?.is_valid_ar_account==0" class="col-xs-7 text-right pad-top-sm pad-left-0">
                                            <p style="color:red">Error retrieving data</p>
                                        </div>


</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="show_msg">
                    <p *ngIf="activeUser?.monthly_parking_accounts?.length < 1" class="mar-bottom-xs">No accounts registered.</p>
                    <button type="button" class="btn theme-btn btn-primary" [ngClass]="{'btn-fill': activeUser?.monthly_parking_accounts?.length < 1}" (click)="navigateMonthlyParking()">add monthly account</button>
                    </div>
                </div>
                <!-- <div *ngIf="showoffer" class="clearfix mar-top-sm">
                    <h2 class="text-uppercase">My Offers</h2>
                    <div class="row">
                        <ng-container *ngFor="let coupon of coupons">
                            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6" *ngIf="coupon?.rate?.facility?.display_name.length > 0 && coupon?.rate?.price.length > 0">
                                <div class="well" id="{{ coupon?.id }}" *ngIf="coupon?.rate?.facility?.display_name.length > 0 && coupon?.rate?.price.length > 0">
                                    <h4>{{ coupon?.rate?.facility?.display_name }}
                                        <a href="javascript:;" class="pull-right" (click)="removeCoupon(coupon?.id)">
                                            <fa name="times"></fa>
                                        </a>
                                    </h4>
                                    <h3 sourceFont>${{ coupon?.rate?.price }}</h3>
                                    <div class="row">
                                        <div class="col-xs-5 col-sm-6 col-md-4 col-lg-4">
                                            <p class="mar-bottom-xs">
                                                <small>Description</small>
                                                <br> {{ coupon?.rate?.description }}
                                                <br><small>(Based on Availability)</small>
                                            </p>
                                        </div>
                                        <div class="col-xs-7 col-sm-6 col-md-8 col-lg-8 text-right pad-left-0">
                                            <a class="btn btn-primary btn-fill theme-btn" (click)="viewCoupon(coupon?.rate?.id,vtemplate,0)" [ngClass]="{'btn-sm': SmallDevice}">View</a>
                                            <span class="mar-left-xs"></span>
                                            <a class="btn btn-primary btn-fill theme-btn" (click)="viewCoupon(coupon?.rate?.id,template,1)" [ngClass]="{'btn-sm': SmallDevice}">Print</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                    <p class="">
                        <span *ngIf="coupons.length < 1">You currently have no saved offers. </span>Search for a facility to find and save offers.
                    </p>
                    <a href="javascript:;" (click)="findSpot()" class="btn theme-btn btn-primary" [ngClass]="{'btn-fill': coupons.length < 1}">find
            offers</a>
                </div> -->
                <div class="clearfix mar-top-sm">
                    <h2 class="text-uppercase">My Reservations</h2>

                    <div class="well" *ngFor="let reservation of reservations">
                        <div class="row">
                            <div class="col-xs-12 col-sm-8 col-md-6 col-lg-9">
                                <h4>{{ reservation?.facility?.facility_name_modified }}</h4>
                            </div>
                            <div sourceFont class="col-xs-12 col-sm-4 col-md-6 col-lg-3 text-right">
                                <h3 ><span *ngIf="reservation?.total > 0 && reservation?.loyalty_point_used == 0" class="text-primary">${{ reservation?.total }}</span><span class="text-primary" *ngIf="(reservation?.total > 0 && reservation?.loyalty_point_used == 0) && (reservation?.credit_used > 0)"> | </span>
                                    <span *ngIf="reservation?.credit_used > 0" class="text-primary">CREDIT ${{ reservation?.credit_used | number }}</span><span class="text-primary" *ngIf="(reservation?.loyalty_point_used > 0) && (reservation?.credit_used > 0)"> | </span>
                                    <span *ngIf="reservation?.loyalty_point_used > 0" class="text-primary">POINTS {{ reservation?.loyalty_point_used | number }}</span>
                                </h3>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                                <p>{{ reservation?.formatted_start_date}} {{ reservation?.formatted_start_time }} - {{ reservation?.formatted_end_date}} {{ reservation?.formatted_end_time}}
                                    <br> {{ reservation?.length }} HOURS
                                </p>
                            </div>
                            <div class="col-xs-4 col-sm-2 col-md-2 col-lg-2">
                                <p>
                                    <small>Status</small>
                                    <br>
                                    <span class="text-success">{{ reservation?.status }}</span>
                                </p>
                            </div>
                            <div class="col-xs-8 col-sm-4 col-md-4 col-lg-4 text-right pad-left-0">
                                <a href="javascript:;" (click)="redirectToDetails(reservation?.reservation_code,reservation?.ticketech_code)" class="btn btn-primary btn-fill btn-disabled">Details</a>
                            </div>

                        </div>

                    </div>
                    <p class="">
                        <span *ngIf="reservations?.length < 1">You currently have no reservations. Search for a facility to make
              your first reservation.</span>
                        <span *ngIf="reservations?.length >= 1">Search for a facility to make your reservation.</span>
                    </p>
                    <a href="javascript:;" (click)="findSpot()" class="btn theme-btn btn-primary" [ngClass]="{'btn-fill': reservations?.length < 1}">Find
            Your spot</a>
                </div>
                <div class="clearfix mar-top-sm">
                    <h2 class="text-uppercase">Account credit</h2>
                    <p *ngIf="wallet?.length == 0">
                        Couldn't find you wallet details.
                    </p>
                    <p class="" *ngIf="wallet.balance == 0">
                        <span *ngIf="reservations?.length < 1">You currently have no account credit. </span>Please update your account credit.
                    </p>
                    <div class="well" *ngIf="wallet.balance > 0">
                        <div class="row">
                            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                <h2 class="text-primary" style="margin-top: 0;" sourceFont>${{ wallet?.balance | number : '1.2' }}</h2>
                                <small>Available Balance</small>
                            </div>
                            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 text-right">
                                <p>Last Updated on {{ wallet.updated_at | slice:0:10 }}</p>
                                <!-- <p>$ 10 expires on 12/15/2017</p> -->
                            </div>
                        </div>
                    </div>
                    <button [ngClass]="{'btn-fill': wallet.balance < 1}" (click)="showWalletTransactions()" type="button" class="btn theme-btn btn-primary">View
            Credit history</button>

                </div>
            </div>
            <div class="col-xs col-sm-4 col-md-4 col-lg-3 mar-top-sm" *showItBootstrap="['xs']">
                <div class="well">
                    <h4 class="mar-top-0 text-primary text-uppercase">Account Information
                        <a style="cursor:pointer" (click)="navigateUpdateProfile()" class="pull-right">
                            <i class="fa fa-pencil" size="2x"></i>
                        </a>
                    </h4>
                    <p class="wordBreak-all">{{ activeUser.name }}</p>
                    <p>
                        <span class="wordBreak-all">{{ activeUser.email }}</span>
                    </p>
                    <p class="wordBreak-all" *ngIf="activeUser.phone != null">{{phoneFormat(activeUser.phone) }}</p>
                    <p *ngIf="activeUser?.pincode" class="wordBreak-all mar-top-sm">Zip code : {{activeUser?.pincode}}</p>
                    <p *ngIf="activeUser?.dob && activeUser?.dob!=='0000-00-00'" class="wordBreak-all">Birth date : {{activeUser?.dob.replace('01T','05T') | date:"MMMM, yyyy" }}</p>
                    <div class="enrolle-card-aacount mar-top-xs pad-top-xs clearfix">
                        <h4 *ngIf="showMethodHeading" class="mar-bottom-xs text-uppercase text-primary">Payment Methods</h4>
                        <ul class="nav">
                            <ng-container *ngFor="let card of fetchUserSavedCards?.data?.payments; let i = index">
                                <li class="btn-visacard">
                                    <ng-container [ngSwitch]="card.card.card_type">
                                        <span *ngSwitchCase="'Visa'" class="cardReservation" id="cards{{i}}">
                      <a (click)="removeCard(card.payment_profile_id,i,card.card.card_number)" class="pull-right mar-top-2">
                        <fa name="trash" size="1x"></fa>
                      </a>
                      <img [src]="visa_Icon" alt="icon"> &nbsp;&nbsp; Visa ({{card.card.card_number.replace('XXXX', '..')}})
                      &nbsp;&nbsp;</span>
                                        <span class="cardReservation" *ngSwitchCase="'MasterCard'" id="cards{{i}}">
                      <a (click)="removeCard(card.payment_profile_id,i)" class="pull-right mar-top-2">
                        <fa name="trash" size="1x"></fa>
                      </a>
                      <img [src]="mastercardIcon" alt="icon"> &nbsp;&nbsp; Master Card ({{card.card.card_number.replace('XXXX',
                      '..')}}) &nbsp;&nbsp;</span>
                                        <span class="cardReservation" *ngSwitchCase="'AmericanExpress'" id="cards{{i}}">
                      <a (click)="removeCard(card.payment_profile_id,i,card.card.card_number)" class="pull-right mar-top-2">
                        <fa name="trash" size="1x"></fa>
                      </a>
                      <img [src]="amexIcon" alt="icon"> &nbsp;&nbsp; American Express ({{card.card.card_number.replace('XXXX',
                      '..')}}) &nbsp;&nbsp;</span>
                                        <span class="cardReservation" *ngSwitchCase="'Discover'" id="cards{{i}}">
                      <a (click)="removeCard(card.payment_profile_id,i,card.card.card_number)" class="pull-right mar-top-2">
                        <fa name="trash" size="1x"></fa>
                      </a>
                      <img [src]="discovercardIcon" alt="icon"> &nbsp;&nbsp; Discover ({{card.card.card_number.replace('XXXX',
                      '..')}}) &nbsp;&nbsp;</span>
                                        <span class="cardReservation card-blue" *ngSwitchCase="'JCB'" id="cards{{i}}">
                        <a (click)="removeCard(card.payment_profile_id,i,card.card.card_number)" class="pull-right mar-top-2">
                          <fa name="trash" size="1x"></fa>
                        </a>
                        <img [src]="jcbIcon" alt="icon"> &nbsp;&nbsp; JCB ({{card.card.card_number.replace('XXXX',
                        '..')}}) &nbsp;&nbsp;</span>
                                        <span class="cardReservation card-blue" *ngSwitchCase="'DinersClub'" id="cards{{i}}">
                        <a (click)="removeCard(card.payment_profile_id,i,card.card.card_number)" class="pull-right mar-top-2">
                          <fa name="trash" size="1x"></fa>
                        </a>
                        <img [src]="dinersIcon" alt="icon"> &nbsp;&nbsp; Diners Club ({{card.card.card_number.replace('XXXX',
                        '..')}}) &nbsp;&nbsp;</span>
                                        <span class="cardReservation" *ngSwitchDefault id="cards{{i}}">
                      <a (click)="removeCard(card.payment_profile_id,i,card.card.card_number)" class="pull-right mar-top-2">
                        <fa name="trash" size="1x"></fa>
                      </a>
                      <img [src]="card_grey_Icon" alt="icon"> &nbsp;&nbsp; Other ({{card.card.card_number.replace('XXXX', '..')}})
                      &nbsp;&nbsp;</span>
                                    </ng-container>
                                </li>
                            </ng-container>
                        </ul>
                    </div>
                    <div class="clearfix mar-top-sm">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="myCreditDetails" *ngIf="creditDetails === true">
        <!-- <a href="javascript:;" (click)="showMyAccountDetails()" class="btn btn-link text-uppercase"><fa name="chevron-left"></fa> &nbsp; &nbsp; &nbsp; My Account</a> -->
        <a href="javascript:;" (click)="showMyAccountDetails()" class="btn btn-link pad-left-0 pad-right-xs scrollTop">
            <fa name="angle-left"></fa> &nbsp; My Account
        </a>
        <h2 class="text-primary mar-bottm-sm text-uppercase">Account Credit</h2>
        <div class="well">
            <div class="row">
                <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                    <h2 class="text-primary" style="margin-top: 0;" sourceFont>${{ wallet.balance | number : '2.2' }}</h2>
                    <small>Available Balance</small>
                </div>
                <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 text-right">
                    <p>Last Updated on {{ wallet.updated_at | slice:0:10 }}</p>
                    <!-- <p>$ 10 expires on 12/15/2017</p> -->
                </div>
            </div>
        </div>
        <div class="row mar-top-xs">
            <!-- <div class="col-xs-12 col-sm-3 col-md-3 col-lg-3">
                <div class="well well-lg">
                    <h4 class="text-primary text-uppercase">refer a friend</h4>
                    <p class="mar-bottom-0">Get ${{ walletConfig.SIGNUP_DISCOUNT | number : '1.2' }} in free account credit when you refer a friend to try Icon Parking with your personalized sign up link.</p>
                     <p><a href="javascript:;">https://iconparkingsystems.com/invite/<br class="hidden-xs">{{ referralCode }}</a></p> -->
            <!-- <p><a href="javascript:;" (click)="copyLink('https://iconparkingsystems.com/invite/' + referralCode)" class="btn btn-sm btn-fill btn-primary" id="copyText">Copy Link</a></p> -->
            <!-- </div>
            </div> -->
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <h4 class="text-primary mar-top-0">History</h4>
                <table width="100%" [ngClass]="transactions?.length === 0?'':'table-hover'" class="table">
                    <ng-container *ngIf="transactions?.length === 0">
                        <tr>
                            <td colspan="3" class="text-center">No records found</td>
                        </tr>
                    </ng-container>
                    <ng-container *ngIf="transactions?.length !== 0">
                        <tr *ngFor="let transaction of transactions">
                            <td>{{ transaction?.created_at | slice:0:10 }}</td>
                            <td *ngIf="transaction?.mode === 'CANCEL'">{{ walletMessages.CANCEL }} - {{ transaction.reservation_id }}</td>
                            <td *ngIf="transaction?.mode === 'REDEEM'">{{ walletMessages.REDEEM }} - {{ transaction.reservation_id }}</td>
                            <td *ngIf="transaction?.mode === 'REFERRAL'">{{ walletMessages.REFERRAL }}</td>
                            <td *ngIf="transaction?.mode === 'SIGNUP'">{{ walletMessages.SIGNUP }}</td>
                            <td *ngIf="transaction?.mode === 'ICON-REWARDS-ANNUAL-BONUS'">{{ walletMessages['ICON-REWARDS-ANNUAL-BONUS'] }}</td>
                            <td *ngIf="transaction?.mode === 'LOYALTY-CREDITS-EXPIRED'">{{ walletMessages['LOYALTY-CREDITS-EXPIRED'] }}</td>
                            <td *ngIf="transaction?.mode === 'PROFILE-COMPLETION-BONUS'">{{ walletMessages['PROFILE-COMPLETION-BONUS'] }}</td>
                            <td *ngIf="transaction?.mode === 'POINTS-TO-CREDIT-CONVERSION'">{{ walletMessages['POINTS-TO-CREDIT-CONVERSION'] }}</td>
                            <td *ngIf="transaction?.mode != 'CANCEL' && transaction?.mode != 'ICON-REWARDS-ANNUAL-BONUS' && transaction?.mode != 'REDEEM' && transaction?.mode != 'SIGNUP' && transaction?.mode != 'REFERRAL' && transaction?.mode != 'PROFILE-COMPLETION-BONUS' && transaction?.mode != 'POINTS-TO-CREDIT-CONVERSION' && transaction?.mode != 'LOYALTY-CREDITS-EXPIRED'">Added by Customer Care</td>
                            <td align="right">
                                <span *ngIf="transaction?.mode === 'CANCEL'" >+</span>
                                <span *ngIf="transaction?.mode === 'REDEEM'" >-</span>
                                <span *ngIf="transaction?.mode === 'REFERRAL'" >+</span>
                                <span *ngIf="transaction?.mode === 'SIGNUP'" >+</span>
                                <span *ngIf="transaction?.mode === 'LOYALTY-CREDITS-EXPIRED'" >-</span>
                                <span *ngIf="transaction?.mode === 'PROFILE-COMPLETION-BONUS'" >+</span>
                                <span *ngIf="transaction?.mode === 'POINTS-TO-CREDIT-CONVERSION'" >+</span>
                                <span *ngIf="transaction?.mode === 'ICON-REWARDS-ANNUAL-BONUS'" >+</span>
                                <span *ngIf="transaction?.mode != 'CANCEL' && transaction?.mode != 'ICON-REWARDS-ANNUAL-BONUS' && transaction?.mode != 'REDEEM' && transaction?.mode != 'SIGNUP' && transaction?.mode != 'REFERRAL' && transaction?.mode != 'PROFILE-COMPLETION-BONUS' && transaction?.mode != 'POINTS-TO-CREDIT-CONVERSION' && transaction?.mode != 'LOYALTY-CREDITS-EXPIRED'" >+</span>
                                $ {{ transaction?.amount | number : '1.2'}}</td>
                        </tr>
                    </ng-container>
                </table>
            </div>
        </div>
    </div>
</div>
<app-lazy-load *ngIf="isLoaded === false" [isLoaded]="isLoaded" [sectionName]="'account information '"></app-lazy-load>
<ng-template #template>
    <div class="modal-body coupons-other-areas" id="couponprintdata">
        <div class=" text-center mar-bottom-sm" id="couponvalid" style="display: none;">
            <button type="button" class="btn btn-primary theme-btn btn-fill btn-dark" (click)="printMedia(template)">Print</button>&nbsp;
            <button type="button" class="btn btn-primary theme-btn btn-fill" (click)="closePopup()">Cancel</button>
        </div>
        <div class="panel-body" id="printableContent">
            <app-lazy-load [isLoaded]="isLoadedp" [sectionName]="'Offers'"></app-lazy-load>
            <img style="display: none;" [src]="sanitizer.bypassSecurityTrustResourceUrl(printData)" id="invoicePrint" class="printable img-responsive full-width" alt="Image">
        </div>
    </div>
</ng-template>
<ng-template #vtemplate>
    <div>
        <div class="modal-header">
            <a href="javascript:;" class="pull-right" (click)="closePopup()">
                <fa name="times"></fa>
            </a>
        </div>
        <div class="modal-body coupons-other-areas" id="couponprintdata">
            <div class="panel-body" id="printableContent">
                <app-lazy-load [isLoaded]="isLoadedp" [sectionName]="'Offers'"></app-lazy-load>
                <img style="display: none;" [src]="sanitizer.bypassSecurityTrustResourceUrl(printData)" id="invoicePrint" class="printable img-responsive full-width" alt="Image">
            </div>
        </div>
    </div>
</ng-template>
<div bsModal #lgModal="bs-modal" id="coupon-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-md customeModal">
        <div class="modal-content modalheightScr" style="padding:20px 60px 50px!important">
            <div class="modal-header">



                <h4 id="couponheading" class="coupons-parent-panel modal-title pull-left"></h4>
                <button type="button" id="closeCouponModal" class="close pull-right" (click)="lgModal.hide()" aria-label="Close">
                    <span aria-hidden="true" >
                        <fa name="times"></fa>
                    </span>
                </button>
            </div>
            <div id="couponsErrorMessage" class="hidden">
                <p style="font-size: 14px;">There are no offers available.</p>
            </div>
            <app-lazy-load [isLoaded]="isLoaded" [sectionName]="'Offers'"></app-lazy-load>
            <div class="modal-body coupons-other-areas" class="singleCoupon" class="couponprintdata" id="couponprintdata" style="display:block;">

                <!-- testing modals -->
                <div id="couponCarousel" class="carousel slide couponPageCarousel " data-ride="carousel">



                    <!-- Wrapper for slides -->
                    <div class="carousel-inner">
                        <ng-container *ngFor="let couponData of array_data.coupons; let couponIndex = index">
                            <div class="item" [class.active]="couponIndex=='0'" *ngIf="couponData?.id==couponSelectedId">
                                <div class="couponDataSection myCoupon">
                                    <article class="ResponsiveCouponsPrint">
                                        <header>
                                            <p>Mobile Code</p>
                                            <h1 class="text-primary text-uppercase mar-top-0">{{couponCodeVaue}}</h1>
                                        </header>
                                        <main class="">
                                            <!-- <div class="flex-row visible-xs mar-top-xs">
                                            <div class="col-10 col-xs-offset-1">
                                                <a href="javascript:;" class="btn btn-block theme-btn btn-primary">Add to mobile wallet</a>
                                            </div>
                                        </div> -->


                                            <div class="clearfix panel-body">

                                                <div class="flex-row no-gutters align-items-center ">
                                                    <div class="col-9">
                                                        <h2 class="text-primary text-uppercase ">{{array_data?.facility?.entrance_location}}</h2>
                                                        <h4> {{couponData?.description}} </h4>
                                                        <p *ngIf="couponData.details!=''" class="mar-top-sm">{{couponData.details}}.</p>
                                                        <p *ngIf="couponData.details==''" class="mar-top-sm">7 Days a week / Enter any time / Exit any time / {{couponData?.max_stay_details}}.</p>

                                                    </div>
                                                    <div class="col-3 text-center">
                                                        <h1 class="priceCoupon" sourceFont>${{couponData.split_price[0]}}</h1>
                                                        <p>tax included<br />(Based on Availability) </p>                                                    </div>
                                                </div>
                                                <div class="text-center  " id="couponvalid" *ngIf="showPrintCancel">
                                                    <button type="button" class="btn btn-primary theme-btn btn-fill " (click)="printMedia(couponData?.id)">Print</button>
                                                    <!-- <div class="visible-xs" style="height:3px;" *ngIf="this.loggedUserName" ></div> -->
                                                    <button type="button" class="btn btn-primary theme-btn btn-fill " (click)="lgModal.hide()">Cancel</button>&nbsp;
                                                </div>
                                            </div>
                                            <div class="clearfix">


                                                <div class="flex-row no-gutters ">
                                                    <div class="col-12 col-sm-6">
                                                        <div class="panel-body">
                                                            <h4 class="text-primary text-uppercase mar-bottom-xs ">Rate Details</h4>
                                                            <p *ngIf="couponData.coupon_restrictions!=''">{{couponData.coupon_restrictions}}</p>
                                                            <p>{{couponData?.used_text}}</p>
                                                            <p *ngIf="couponData?.expire_on">Expires {{couponData?.expire_on}}</p>
                                                            <p>Code : #{{couponData?.coupon_code}}</p>
                                                            <div class="coupon-barcode">
                                                                <ngx-barcode *ngIf="barcodeData!='' && barcodeData!=null" [bc-height]="53" [bc-margin-top]="1" [bc-margin-bottom]="1" [bc-margin-left]="1" [bc-margin-right]="1" [bc-background]="gray" [bc-width]="2" [bc-element-type]='svg' [bc-value]="barcodeValue" [bc-display-value]="false"></ngx-barcode>
                                                                <!-- <qrcode *ngIf="qrcodeData!='' && qrcodeData!=null" [colorlight]="'#ffffff'" [qrdata]="barcodeValue" [size]="150" [level]="'M'"></qrcode> -->
                                                            </div>
                                                            <div class="extraContent hidden-xs">

                                                                <hr>
                                                                <div class="clearfix"></div>
                                                                <p>{{couponData?.disclaimer}}</p>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div class="col-12 col-sm-6 darklight">
                                                        <div class="panel-body">
                                                            <h4 class="text-primary text-uppercase  ">Facility Details</h4>
                                                            <h3> {{ array_data?.facility?.facility_name_modified }} </h3>
                                                            <p>
                                                                <fa name="phone"></fa> &nbsp;
                                                                <a id="garage_phone" style="color: #000;" href="tel:{{array_data?.facility?.phone_number}}">{{array_data?.facility?.phone_number}}</a>
                                                            </p>
                                                            <p class="mar-bottom-xs" *ngIf="array_data?.facility?.between_streets">
                                                                <b>Between:</b> {{ array_data?.facility?.between_streets }}</p>
                                                            <p class="mar-bottom-xs" *ngIf="array_data?.facility?.entrance_location">
                                                                <b>Entrance</b> {{array_data?.facility?.entrance_location}}</p>
                                                            <p class="dailystyles mar-bottom-xs  mar-top-xs">
                                                                <a (click)="getdirections.show();lgModal.hide()">Get Directions &nbsp;&nbsp;
                                                                <fa name="chevron-right"></fa>
                                                            </a>
                                                            </p>
                                                            <div class="clearfix">

                                                                <p *ngIf="array_data.facility?.hours_of_operation?.length == 0" class="mar-bottom-xs">Open: 7 Days a week, 24 hours a day</p>
                                                                <div class="TimeActive table-responsive" *ngIf="array_data.facility?.hours_of_operation?.length > 0">
                                                                    <h4>Hours of Operation</h4>
                                                                    <table class="table table-condensed ">
                                                                        <tr *ngFor="let wh of array_data.facility?.hours_of_operation;">
                                                                            <td>{{wh.day}}</td>
                                                                            <td>:</td>
                                                                            <td>{{wh.formatted_open_time}}</td>
                                                                            <td>-</td>
                                                                            <td>{{wh.formatted_close_time}}</td>
                                                                        </tr>
                                                                    </table>
                                                                </div>

                                                                <hr class="whiteHr">
                                                            </div>
                                                        </div>
                                                        <div class="parkImg">
                                                            <img [src]="imgSrcLocation" alt="icon" class='full-width'>
                                                        </div>

                                                    </div>
                                                    <div class="col-12 visible-xs">
                                                        <div class="extraContent">

                                                            <div class="clearfix panel-body">
                                                                <p>{{couponData?.disclaimer}}</p>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </main>

                                    </article>


                                </div>
                            </div>
                        </ng-container>
                    </div>

                    <!-- Indicators & controles -->
                </div>

            </div>


        </div>
    </div>
</div>
<div class="hide" *ngFor="let couponData of array_data.coupons; let couponIndexTable = index">
    <div id="table{{couponData?.id}}">
        <!-- <table  width="600" align="center" cellspacing="0" cellpadding="0" style="border:1px solid #ddd;-webkit-print-color-adjust: exact;">
            <tbody>
                <tr bgcolor="#ececec" class="coupan-head-pattern" style="background: #ececec!important;width: 100%;vertical-align: middle;-ms-touch-action: manipulation;touch-action: manipulation;background-image: none;white-space: nowrap;-webkit-user-select: none;-moz-user-select: none;-ms-user-select: none;user-select: none;-webkit-print-color-adjust: exact;">
                   <td align="center" style="padding: 20px; -webkit-print-color-adjust: exact; ">
                    <ngx-barcode [bc-height]="53" [bc-margin-top]="1" [bc-margin-bottom]="1" [bc-margin-left]="1" [bc-margin-right]="1" [bc-background]="gray"
                        [bc-width]="2" [bc-element-type]='svg' [bc-value]="barcodeValue" [bc-display-value]="false"></ngx-barcode>
                        <p style="margin: 0;padding: 10px 0 0 0;font-family: sans-serif;">Coupon
                            code #{{couponData?.coupon_code}}
                        </p>
                    </td>
                </tr>
                <tr>
                    <td>
                        <table width="100%" align="center" cellspacing="0" cellpadding="0" style="-webkit-print-color-adjust: exact;">
                            <tbody>
                                <tr>
                                    <td style="padding: 30px;font-family: sans-serif;width: 75%; -webkit-print-color-adjust: exact;">
                                        <h2 style="font-size: 30px; -webkit-print-color-adjust: exact;color: #ed8222;font-weight: 500;margin: 0;padding: 0;text-transform: uppercase;">

                                            <font color="#ed8222">{{array_data?.facility?.entrance_location}}</font>
                                        </h2>
                                        <h4 style="margin: 10px 0;padding: 0; -webkit-print-color-adjust: exact; font-size: 24px;font-weight: 500;text-transform: uppercase;">
                                           {{couponData?.description}}
                                        </h4>
                                        <p *ngIf="couponData.details!=''" style="font-weight: normal;font-size: 16px;font-weight: 100;">
                                            {{couponData.details}}.
                                        </p>
                                        <p *ngIf="couponData.details==''" style="font-weight: normal;font-size: 16px;font-weight: 100;">
                                            7 Days a week / Enter any time / Exit any time / {{couponData?.max_stay_details}}.
                                        </p>
                                    </td>
                                    <td style="text-align: center;font-family: sans-serif;">
                                        <h1 style="font-size: 60px;font-weight: 300;margin-bottom: 0;">
                                            ${{couponData.split_price[0]}}
                                        </h1>
                                        <p style="margin-top: 0;font-weight: normal;">
                                            tax included
                                        </p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
                <tr>
                    <td>
                        <table width="100%" align="center" cellspacing="0" cellpadding="0" style="-webkit-print-color-adjust: exact;">
                            <tbody>
                                <tr>
                                    <td width="50%" style="padding: 30px;vertical-align: top;padding-bottom: 0;">
                                        <h4 style="font-size: 20px;font-weight: 500;color: #ed8222;margin: 15px 0;text-transform: uppercase;font-family: sans-serif;margin-top: 0; -webkit-print-color-adjust: exact;">
                                            <font color="#ed8222">Coupon Details</font>
                                        </h4>
                                        <p *ngIf="couponData.coupon_restrictions!=''" style="font-size: 14px;font-weight: 500;margin: 15px 0;text-transform: uppercase;font-family: sans-serif;">
                                            {{couponData.coupon_restrictions}}
                                        </p>
                                        <p style="font-size: 14px;font-weight: 500;margin: 15px 0;font-family: sans-serif;">
                                            Coupon Id : {{couponCodeVaue}}
                                        </p>
                                        <p style="font-size: 14px;font-weight: 500;margin: 15px 0;font-family: sans-serif;">
                                            {{couponData?.used_text}}
                                        </p>
                                        <p *ngIf="couponData?.expire_on" style="font-size: 14px;font-weight: 500;margin: 15px 0;font-family: sans-serif;">
                                            {{couponData?.expire_on}}
                                        </p>
                                        <div style="font-size: 14px;font-weight: 500;margin: 15px 0;font-family: sans-serif;line-height: 1.5;margin-top: 30px;border-top: 2px solid #eee;padding: 15px 0;">
                                            <div class="clearfix"></div>
                                                <p>
                                                    {{couponData?.disclaimer}}
                                                </p>
                                        </div>
                                    </td>
                                    <td class="coupan-head-pattern" width="50%" bgcolor="#ececec" style="background: #ececec!important;width: 100%;-ms-touch-action: manipulation;touch-action: manipulation;background-image: none;white-space: nowrap;-webkit-user-select: none;-moz-user-select: none;-ms-user-select: none;user-select: none; vertical-align: top; -webkit-print-color-adjust: exact;">
                                         <div style="padding: 30px;padding-bottom: 0; -webkit-print-color-adjust: exact;">
                                            <h4 class="" style="font-size: 20px;font-weight: 500;color: #ed8222;margin: 15px 0;text-transform: uppercase;font-family: sans-serif;padding: 0;margin-top: 0; -webkit-print-color-adjust: exact;">
                                                <font color="#ed8222">Facility Details</font>
                                            </h4>
                                            <h3 style="font-family: sans-serif;text-decoration: none;text-transform: uppercase;font-weight: 500;margin: 0;padding: 0;font-size: 24px;">
                                                {{ array_data?.facility?.full_name }}
                                            </h3>
                                            <p>
                                                <a style="color: #000;font-size: 14px;font-weight: 500;margin: 15px 0;font-family: sans-serif;text-decoration: none;cursor: pointer;"><fa name="phone"></fa> &nbsp;{{array_data?.facility?.phone_number}} </a>
                                            </p>
                                            <p style="font-size: 14px;font-weight: 500;margin: 15px 0;font-family: sans-serif;">
                                                <b>Between:</b> {{ array_data?.facility?.between_streets }}
                                            </p>
                                            <p style="font-size: 14px;font-weight: 500;margin: 15px 0;font-family: sans-serif;">
                                                <b>Entrance</b> {{array_data?.facility?.entrance_location}}
                                            </p>
                                            <p style="font-size: 14px;font-weight: 500;margin: 15px 0;font-family: sans-serif;">
                                                <a (click)="getdirections.show()" style="font-size: 14px;font-weight: 500;margin: 15px 0;font-family: sans-serif;color: #ed8222;cursor: pointer; -webkit-print-color-adjust: exact;">
                                                    <font color="#ed8222">Get Directions &nbsp;&nbsp;<i class=" fa fa-chevron-right"></i></font>
                                                </a>
                                            </p>
                                            <p *ngIf="array_data.facility?.hours_of_operation?.length == 0" style="font-size: 14px;font-weight: 500;margin: 15px 0;font-family: sans-serif;">
                                                Open: 7 Days a week, 24 hours a day
                                            </p>
                                            <div class="TimeActive table-responsive" *ngIf="array_data.facility?.hours_of_operation?.length > 0">
                                            <h4>Hours of Operation</h4>
                                            <table class="table table-condensed ">
                                                <tr *ngFor="let wh of array_data.facility?.hours_of_operation;">
                                                    <td>{{wh.day}}</td>
                                                    <td>:</td>
                                                    <td>{{wh.formatted_open_time}}</td>
                                                    <td>-</td>
                                                    <td>{{wh.formatted_close_time}}</td>
                                                </tr>
                                            </table>
                                        </div>
                    
                                        <hr class="whiteHr">
                                        </div>
                                        <div class="parkImg" style="-webkit-print-color-adjust: exact;">
                                            <img class="" [src]="imgSrcLocation" style="width: 100%; -webkit-print-color-adjust: exact;">
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
            </tbody>
        </table> -->
        <table width="600" align="center" cellspacing="0" cellpadding="0" style="border:1px solid #ddd;-webkit-print-color-adjust: exact;">
            <tbody>
                <tr bgcolor="#ececec">
                    <td align="center" style="padding: 20px;">
                        <ngx-barcode *ngIf="barcodeData!='' && barcodeData!=null" [bc-height]="53" [bc-margin-top]="1" [bc-margin-bottom]="1" [bc-margin-left]="1" [bc-margin-right]="1" [bc-background]="gray" [bc-width]="2" [bc-element-type]='svg' [bc-value]="barcodeValue" [bc-display-value]="false"></ngx-barcode>
                        <!-- <qrcode *ngIf="qrcodeData!='' && qrcodeData!=null" [colorlight]="'#ffffff'" [qrdata]="barcodeValue" [size]="150" [level]="'M'"></qrcode> -->
                        <p style="margin: 0;padding: 10px 0 0 0;font-family: sans-serif;">Code #{{couponData?.coupon_code}}
                        </p>
                        <p style="margin: 0;padding: 10px 0 0 0;font-family: sans-serif;">
                            Mobile Code #{{couponCodeVaue}}
                        </p>
                    </td>
                </tr>
                <tr>
                    <td>
                        <table width="100%" align="center" cellspacing="0" cellpadding="0">
                            <tbody>
                                <tr>
                                    <td style="padding:30px;font-family: sans-serif;width: 75%;">
                                        <h2 style="font-size: 30px; -webkit-print-color-adjust: exact;color: #ed8222;font-weight: 500;margin: 0;padding: 0;text-transform: uppercase;">

                                            <font color="#ed8222">{{array_data?.facility?.entrance_location}}</font>
                                        </h2>
                                        <h4 style="margin: 10px 0;padding: 0; -webkit-print-color-adjust: exact; font-size: 24px;font-weight: 500;text-transform: uppercase;">
                                            {{couponData?.description}}
                                        </h4>
                                        <p *ngIf="couponData.details!=''" style="font-weight: normal;font-size: 16px;font-weight: 100;">
                                            {{couponData.details}}.
                                        </p>
                                        <p *ngIf="couponData.details==''" style="font-weight: normal;font-size: 16px;font-weight: 100;">
                                            7 Days a week / Enter any time / Exit any time / {{couponData?.max_stay_details}}.
                                        </p>
                                    </td>
                                    <td style="text-align: center;font-family: sans-serif;">
                                        <h1 style="font-size: 60px;font-weight: 300;margin-bottom: 0;" sourceFont>
                                            ${{couponData.split_price[0]}}
                                        </h1>
                                        <p style="margin-top: 0;font-weight: normal;">
                                            tax included
                                        </p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
                <tr>
                    <td>
                        <table style="transform: scale(0.9);
                              transform: translateX(0px);" width="100%" align="center" cellspacing="0" cellpadding="0" style="-webkit-print-color-adjust: exact;">
                            <tbody>
                                <tr>
                                    <td width="50%" style="padding: 30px;vertical-align: top;padding-bottom: 0;">
                                        <h4 style="font-size: 20px;font-weight: 500;color: #ed8222;margin: 15px 0;text-transform: uppercase;font-family: sans-serif;margin-top: 0; -webkit-print-color-adjust: exact;">
                                            <font color="#ed8222">Rate Details</font>
                                        </h4>
                                        <p *ngIf="couponData.coupon_restrictions!=''" style="font-size: 14px;font-weight: 500;margin: 15px 0;text-transform: uppercase;font-family: sans-serif;">
                                            {{couponData.coupon_restrictions}}
                                        </p>

                                        <p style="font-size: 14px;font-weight: 500;margin: 15px 0;font-family: sans-serif;">
                                            {{couponData?.used_text}}
                                        </p>
                                        <p *ngIf="couponData?.expire_on" style="font-size: 14px;font-weight: 500;margin: 15px 0;font-family: sans-serif;">
                                            Expires {{couponData?.expire_on}}
                                        </p>
                                        <div style="font-size: 14px;font-weight: 500;margin: 15px 0;font-family: sans-serif;line-height: 1.5;margin-top: 30px;border-top: 2px solid #eee;padding: 15px 0;">
                                            <div class="clearfix"></div>
                                            <p>
                                                {{couponData?.disclaimer}}
                                            </p>
                                        </div>
                                    </td>
                                    <td width="50%" style="background: #ececec;vertical-align: top;-webkit-print-color-adjust: exact;">
                                        <div style="padding: 30px;padding-bottom: 0; -webkit-print-color-adjust: exact;">
                                            <h4 class="" style="font-size: 20px;font-weight: 500;color: #ed8222;margin: 15px 0;text-transform: uppercase;font-family: sans-serif;padding: 0;margin-top: 0; -webkit-print-color-adjust: exact;">
                                                <font color="#ed8222">Facility Details</font>
                                            </h4>
                                            <h3 style="font-family: sans-serif;text-decoration: none;text-transform: uppercase;font-weight: 500;margin: 0;padding: 0;font-size: 24px;">
                                                {{ array_data?.facility?.facility_name_modified }}
                                            </h3>
                                            <p>
                                                <a style="color: #000;font-size: 14px;font-weight: 500;margin: 15px 0;font-family: sans-serif;text-decoration: none;cursor: pointer;">
                                                    <fa name="phone"></fa> &nbsp;{{array_data?.facility?.phone_number}} </a>
                                            </p>
                                            <p style="font-size: 14px;font-weight: 500;margin: 15px 0;font-family: sans-serif;">
                                                <b>Between:</b> {{ array_data?.facility?.between_streets }}
                                            </p>
                                            <p style="font-size: 14px;font-weight: 500;margin: 15px 0;font-family: sans-serif;">
                                                <b>Entrance</b> {{array_data?.facility?.entrance_location}}
                                            </p>
                                            <p style="font-size: 14px;font-weight: 500;margin: 15px 0;font-family: sans-serif;">
                                                <a (click)="getdirections.show()" style="font-size: 14px;font-weight: 500;margin: 15px 0;font-family: sans-serif;color: #ed8222;cursor: pointer; -webkit-print-color-adjust: exact;">
                                                    <font color="#ed8222">Get Directions &nbsp;&nbsp;<i class=" fa fa-chevron-right"></i></font>
                                                </a>
                                            </p>
                                            <p *ngIf="array_data.facility?.hours_of_operation?.length == 0" style="font-size: 14px;font-weight: 500;margin: 15px 0;font-family: sans-serif;">
                                                Open: 7 Days a week, 24 hours a day
                                            </p>
                                        </div>
                                        <div style="padding: 0 30px 10px 30px; -webkit-print-color-adjust: exact;" *ngIf="array_data.facility?.hours_of_operation?.length > 0">
                                            <h4>
                                                <font face="sans-serif">Hours of Operation</font>
                                            </h4>
                                            <table cellspacing="0" cellpadding="0" width="100%" style="font-family: sans-serif;font-size: 10px;vertical-align: top;">
                                                <tbody>
                                                    <tr *ngFor="let wh of array_data.facility?.hours_of_operation;">
                                                        <td style="width: 20%;vertical-align: top;">
                                                            <font face="sans-serif">{{wh.day}}</font>
                                                        </td>
                                                        <td style="width: 10%;vertical-align: top;">
                                                            <font face="sans-serif">:</font>
                                                        </td>
                                                        <td style="width: 20%;vertical-align: top;">
                                                            <font face="sans-serif">{{wh.formatted_open_time}}</font>
                                                        </td>
                                                        <td style="width: 10%;vertical-align: top;">
                                                            <font face="sans-serif">-</font>-</td>
                                                        <td style="width: 40%; vertical-align: top;">
                                                            <font face="sans-serif">{{wh.formatted_close_time}}</font>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>



                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
                <tr>
                    <td>
                        <table style="transform: scale(0.9);
                                  transform: translateX(0px);" width="100%" align="center" cellspacing="0" cellpadding="0" style="-webkit-print-color-adjust: exact;">
                            <tbody>
                                <tr>
                                    <td width="50%" style="padding: 30px;vertical-align: top;padding-bottom: 0;">
                                    </td>
                                    <td width="50%" style="background: #ececec;vertical-align: top;-webkit-print-color-adjust: exact;">
                                        <div class="parkImg" style="-webkit-print-color-adjust: exact;">
                                            <img class="" [src]="imgSrcLocation" alt="icon" style="width: 100%; -webkit-print-color-adjust: exact;">
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
<button style="display: none;" id="triggercoupon" type="button" class="btn btn-primary" (click)="lgModal.show()"></button>


<!-- Directions Model Starts From Here -->
<div bsModal #getdirections="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">

    <div class="modal-dialog modal-sm">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" aria-label="Close" (click)="getdirections.hide()">
          <fa name="times"></fa>
        </button>
                <h3 class="mar-top-0 text-uppercase text-primary">Get Directions</h3>
            </div>

            <div class="modal-body">
                <div class="form-group">
                    <!-- <a *ngIf="allParkings.geolocation" class="btn btn-primary btn-block theme-btn" target="_blank" id="navigatetogoogle"
            href="https://maps.google.com?q={{allParkings.geolocation.latitude}},{{allParkings.geolocation.longitude}}">Navigate
            with Google Maps</a> -->
                    <a *ngIf="array_data?.geolocations" class="btn btn-primary btn-block theme-btn" target="_blank" id="navigatetogoogle" href="https://maps.google.com/maps?daddr={{array_data?.geolocations?.latitude}},{{array_data?.geolocations?.longitude}}&travelmode=driving&dir_action=navigate">Navigate
            with Google Maps</a>
                </div>
                <div class="floating-label">
                    <label class="control-label text-uppercase" for="sendapp">Send to Phone</label>
                    <div class="input-group">
                        <input type="text" class="form-control" id="sendapp" placeholder="Enter phone number">
                        <span class="input-group-btn pad-left-xs">
              <button type="submit" class="btn theme-btn btn-primary" (click)="sendApp()">Send</button>
            </span>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
<!-- Direction Model Ends Here -->