import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { CustomCode } from '../../shared/CustomCode';
import { FilterHelper } from '../../classes/filter-helper';
import { StaticpagesService } from './../../services/staticpages.service';
import * as $ from 'jquery';
// declare var ga: Function;

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})

export class SidenavComponent implements OnInit {
  
  



  loggedUser;
  mantinanceMsgContent:any;
  showMantinancepopup= false;
  mantinanceMsgContentData:any;
  mantinanceMsg= false;
  sidenavbtn:any = $('.sideBarNavMob li a');
  sideNavOpen:any;
  isFrame:boolean = false; 
  isLoyaltyMember:boolean = false;
  loyalit_member=0;
   homeIcon:string ='assets/img/sidebarIcons/home.svg'; 
   monthlyParkingIcon:string ='assets/img/sidebarIcons/monthlyParking.svg';
   contactUsIcon:string = 'assets/img/sidebarIcons/contactUs.svg';
   newsIcon:string ='assets/img/sidebarIcons/news.svg';
   neighborhoodsIcon:string ='assets/img/sidebarIcons/neighborhoods.svg';
   managementIcon:string ='assets/img/sidebarIcons/management.svg';
   yankeeStadiumIcon:string = 'assets/img/sidebarIcons/yankeeStadium.svg';
   aboutIcon:string = 'assets/img/sidebarIcons/about.svg';
   evChargingIcon:string = 'assets/img/sidebarIcons/evCharging.svg';
   careersIcon : string ='assets/img/sidebarIcons/careers.svg';
   faqIcon :string ='assets/img/sidebarIcons/faq.svg';
   newsTips = '/cms/news/';
   phone = 'tel:+1877727-5464';


  constructor(
    
    private routerObj: Router
  ) { 
    this.sideNavOpen = false;
  }
  close_side()
  {
    $('.sidenav').removeClass('open');
    $('.sidenavBackDrop').removeClass('open');
    $('body').removeClass('sidebar-noscroll');
    localStorage.removeItem('is_ham')
  }
  ngOnInit() {
    
    let times_runhide_check = setInterval( ()=> {
      if(localStorage.getItem('userid'))
      {
        //alert(2)
        $('#loginPopup').hide();
        $('#guestuser').hide();
        $('#loggeduser').show();
        this.loggedUser = CustomCode.getloggedInUserDetails();
        if(localStorage.getItem('is_loyalty_active') && localStorage.getItem('is_loyalty_active')=='1')
        {
          this.loyalit_member=1
        }else{
          this.loyalit_member=0;
        }
        clearInterval(times_runhide_check);
      }
    }, 100);

    if(localStorage.getItem('is_loyalty')==="1"){ this.isLoyaltyMember = true; }
      if(window.self!==window.top){this.isFrame = true;} 
    $('.selectAuto').focus(function(){
      $(this).select();
    });
    $('.sideBarNavMob a').click(function(){
      $('.sidenav').removeClass('open');
      $('body').removeClass('sidebar-noscroll');
      // $('.sidenavBackDrop').removeClass('open');
    });
    // alert(CustomCode.detectMobileOrDesktopView()); //
    this.loggedUser = CustomCode.getloggedInUserDetails();
   
    $('.sidenavBackDrop, .closeBtnSidenav').on('click',function(e) {
      $('.sidenav').removeClass('open');
      // $('.sidenavBackDrop').removeClass('open');
      $('body').removeClass('sidebar-noscroll');
    });
    
  }

closeBtnSidenav(){
  $('.sidenav').removeClass('open');
  // $('.sidenavBackDrop').removeClass('open');
  $('body').removeClass('sidebar-noscroll');
}

  logOut() {
    CustomCode.userLogout();
    this.routerObj.navigate(['/']);
    window.location.reload();
  }

  formonthly()
{
    let startDuration = FilterHelper.defaultStartDuration();
    let startTime = FilterHelper.getFormattedTime(startDuration);
    localStorage.setItem('monthlyDate',startDuration.toString())
    let endDuration = FilterHelper.defaultEndDuration();
    let endTime = FilterHelper.getFormattedTime(endDuration);
    let endTimeDataIfLess = FilterHelper.makeDurationDateObject(endDuration, endTime);
    localStorage.setItem('endttimehome',CustomCode.getDateForMapSearchSlashFormatHome(endDuration, endTime));
    localStorage.setItem('startimehome',CustomCode.getDateForMapSearchSlashFormatHome( startDuration, startTime));
    let stime =localStorage.getItem('startimehome');
    localStorage.setItem('specialParking','1');
    localStorage.setItem('showNeighbordood','1');
    localStorage.setItem('sortBy', "Neighborhood");
    this.routerObj.navigate(['/search', '40.7782667', '-73.9698797', stime, 1,  'central-park','monthly', CustomCode.searchMode()]);
}
formonthly2()
{
  let startDuration = FilterHelper.defaultStartDuration();
  let startTime = FilterHelper.getFormattedTime(startDuration);
    localStorage.setItem('monthlyDate',startDuration.toString())
    let endDuration = FilterHelper.defaultEndDuration();
    let endTime = FilterHelper.getFormattedTime(endDuration);
    let endTimeDataIfLess = FilterHelper.makeDurationDateObject(endDuration, endTime);
    localStorage.setItem('endttimehome',CustomCode.getDateForMapSearchSlashFormatHome(endDuration,endTime));
    localStorage.setItem('startimehome',CustomCode.getDateForMapSearchSlashFormatHome(startDuration,startTime));
    let stime =localStorage.getItem('startimehome');
    localStorage.setItem('specialParking','1');
    localStorage.setItem('showNeighbordood','1');
    localStorage.setItem('sortBy', "Neighborhood");
    localStorage.setItem('fromhomelanding','1');
    this.routerObj.navigate(['/monthly-parking']);
    //this.routerObj.navigate(['/search', '40.7782667', '-73.9698797', stime, 1,  'central-park','monthly', CustomCode.searchMode()]);
}

triggerEvent(label){
  /* commented for ga 16-6-2021 by seo team
  ga('send', 'event', { eventCategory: label, eventLabel:'redirect to Icon Specials', eventAction: 'Hrefclick'});
*/
}



}