<app-header></app-header>
<app-lazy-load [isLoaded]="isLoadeds" [sectionName]="'content'"></app-lazy-load>
<div class="jocoWrapper mar-top-lg" [innerHTML]="staticPageContent.post_content"></div>


<!-- <div class="wrapper hudsondata" id="hudsonData">
  <div class="banner  headerImg">  
    <div class="row align-items-center justify-content-center">
      <div class="col-sm-4 col-9 text-center  bg-white">
          <img class="p-5 img-responsive" src="../../../assets/img/landing-pages/hudson/hudson_logo_top.svg">
      </div>
    </div>
  </div>
  <div class="container mar-top-md  mar-bottom-md"> 
    <div class="row align-items-center justify-content-center">
      <div class="col-sm-6  text-center" >
 <h2 class="text-center hudsonTitle  text-uppercase">
      Time to explore the city!
    </h2>
    <h3 class="mt-4">You’ve unlocked over 35<br class="visible-xs"> exclusive offers at The Shops<br class="visible-xs"> & <br class="hidden-xs">Restaurants at Hudson Yards!</h3>
    <p class="mt-4 fontTitleUnder">
      From discounts to special promotions, <br class="visible-xs">come discover Hudson Yards today.<br>Located at 20 Hudson Yards, New York,<br class="visible-xs"> NY 10001.
    </p>
      </div>
    </div>
    <div class="row no-gutters  mt-5 justify-content-center">
      <div class="col-sm-6 col-md-6 col-lg-3">
        <div class="logoHudson hidden-xs">
        </div>
    <img class="img-responsive visible-xs" src="../../../assets/img/landing-pages/hudson/logo_hudsonyard.png"> 
      </div>
      <div class="col-sm-6 col-md-6 col-lg-3">
        <div class="text-center cardSection ">
                  <h4 class="">Claim Exclusive VIP Offers and <br > Discounts at The Shops &<br class="visible-xs"> Restaurants<br class="hidden-xs"> at Hudson Yards</h4>
                  <a target="_blank" href="https://www.hudsonyardsnewyork.com/shopping-dining-pass/" class="btn  btn-block btn-fill btn-primary btn-hudson">VIEW EXCLUSIVE OFFERS</a>
                  <p>
                    Offers only valid at Hudson Yards location through January 31,<br class="hidden-xs"> 2022. Nontransferable. Not valid on discount, sale or clearance<br class="hidden-xs"> items. Limit one per customer/purchase per day. See stores and<br class="hidden-xs"> restaurants for details.
                  </p>
        </div>
      </div>
    </div>
  </div>
  <div class="row no-gutters">
    <div class="col-12 col-sm"><img class="img-responsive" src="../../../assets/img/landing-pages/hudson/slide1.png"></div>
    <div class="col-12 col-sm"><img class="img-responsive" src="../../../assets/img/landing-pages/hudson/slide2.png"></div>
    <div class="col-12 col-sm"><img class="img-responsive" src="../../../assets/img/landing-pages/hudson/slide3.png"></div>
  </div>
  <div class="banner  bottomImg">  
    <div class="row align-items-center justify-content-center">
      <div class="col-sm-3 col-12 text-center  ">
        <h3>Hudson Yards New York</h3>
        <p>20 Hudson Yards, New York, NY 10001.</p>
        <p class="mb-0">(646) 954-3155 | hello@hudsonyardsnewyork.com</p>
      <a name="" id="" target="_blank" class="btn btn-primary " href="https://www.hudsonyardsnewyork.com/icon-parking-perks" role="button">VISIT SITE</a>
      </div>
    </div>
  </div>
</div> -->

<app-footer></app-footer>