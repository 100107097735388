import { Component, OnInit, Output, EventEmitter, Input, TemplateRef } from '@angular/core';
import { RouterExtService } from '../../../services/router-ext.service';
import { UserserviceService } from '../../../services/userservice.service';
import { Router, ActivatedRoute } from '@angular/router';
import { CustomCode } from '../../../shared/CustomCode';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';
import { Crypto } from '../../../classes/crypto';
import * as $ from 'jquery';


import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';
// declare var ga: Function;
@Component({
  selector: 'app-icon-rewards-card',
  templateUrl: './icon-rewards-card.component.html',
  styleUrls: ['./icon-rewards-card.component.scss'],
  providers: [Crypto]
})
export class IconRewardsCardComponent implements OnInit {
  @Output() enrolledNow = new EventEmitter<any>();
  @Input() isBtnDisabled: boolean;
  expYears = CustomCode.getYearList();
  expMonths = CustomCode.getMonthList();
  dateMask: any = [/[0-1]/, /[0-9]/, '/', /[0-9]/, /[0-9]/];
  cardMask: any = [
    /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, ' ',
    /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, ' ',
    /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, ' ',
    /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/
  ];
  card = {
    card_no:'',
    exp:'',
	  exp1:'',
    security_code:'',
    zip_code:'',
    addCardIcon:false
  }
  isLoaded:boolean = false;
  pageTitle:string = 'ADD MY CREDIT CARD';
  btnText:string = 'ADD CARD';
  isEdit:boolean = false;
  
  isReferal:boolean = false;
  currentUrl:string;
  cardDetails:any;
  confirmationMsg:string = 'Are you sure to delete?';
  modalRef: BsModalRef;
  public config = { ignoreBackdropClick:true }
  icon_rewards_logo_wt ='assets/img/icon-rewards-logo-wt.svg';
  price_tag_icon = 'assets/img/price-tag.svg';
  helpIcon = 'assets/img/help.svg';
  pcjImg = 'assets/img/badge_pci_dss.svg';
   void =  'javascript:void(0)';
   btnTextCancel:any;
   isCardId:any;
   submtDisabled:boolean = false;
   showInvitation:boolean = false;
   rewarded_points:number = CustomCode.rewarded_points;
   isInvAssosiated:boolean = false;
 //  progressRef: NgProgressRef;
  constructor(
    private RouterExtService: RouterExtService, 
    private userServiceObj: UserserviceService, 
    private routeObj: Router, 
    private ActivatedRouteObj:ActivatedRoute,
    private toasterObj: ToastrService,
    private _location: Location,
    private cryptoObj: Crypto,
   // private progress: NgProgress,
    private modalService: BsModalService
  ) {  
    this.currentUrl = this.RouterExtService.getcurrentUrl(); 
    if(this.currentUrl === '/my-account/manage-icon-rewards-card'){
      this.isReferal = true;
    }
  }
  
  ngOnInit() {
    if(localStorage.getItem('invalidName')==="1"){
      this.routeObj.navigate(['/my-account/manage-details']);
      return false;
    }

    this.userServiceObj.GetLoyaltyProfile().subscribe(
      (res:any) => {
        let uData = res.data;
        sessionStorage.setItem('is_loyalty_profile_completed', uData.is_loyalty_profile_completed.toString());
        sessionStorage.setItem('is_loyalty_active', uData.is_loyalty_active.toString());
        if(uData.is_loyalty_active.toString() == '1'){
          this.showInvitation = false;
        }else{
          this.showInvitation = true;
        }
      }
    );

    this.btnTextCancel = 'CANCEL';
    this.ActivatedRouteObj.params.subscribe(params => {
      let cardId = params.card_id;
      this.isCardId = params.card_id;
      if(cardId !== undefined){
        this.isReferal = true;
        this.userServiceObj.LoyaltyGetCard(cardId)
        .subscribe(
          (res:any) => {
            if (res.status === 200) {
              let respdata = res.data;
              this.cardDetails = respdata;
                  if(this.cardDetails.type == 'amax'){
                    this.checkCardByvalue('3');
                  }else if(this.cardDetails.type == 'discover'){
                    this.checkCardByvalue('6');
                  }else{
                    this.checkCardByvalue('5');
                  }
              let exp = this.cardDetails.expiration.split('/');
              this.card.card_no = '____________'+this.cardDetails.last_four;
              this.card.zip_code = (this.cardDetails.zip != 'undefined')?this.cardDetails.zip:'';
              this.card.exp = this.cardDetails.expiration;
              this.card.exp1 = this.cardDetails.expiration;
              this.pageTitle = 'EDIT CREDIT CARD';
              this.btnText = 'SAVE CARD';
              if(sessionStorage.getItem('firstEntryProfileCard'))
              {
                this.btnTextCancel = 'SKIP';
                sessionStorage.removeItem('firstEntryProfileCard')
              }else{
                this.btnTextCancel = 'CANCEL';
              }
              this.isEdit = true;
              this.isLoaded = true;
            }else{
              this.routeObj.navigate(['/my-account/icon-rewards']);
            }
        });
      }else{
        this.isLoaded = true;
      }
    });
    if(localStorage.getItem('access_token')) {
      this.userServiceObj.GetLoyaltyWallet().subscribe(
        (response:any) => {
          this.rewarded_points = response.data.loyalty_config.POINTS_AT_REGISTRATION;
          this.isInvAssosiated = response.data.loyalty_config.is_invitation_code;
        }
      );
    }
  }

  removeCard(cardId){
    if(confirm(this.confirmationMsg)) {
      this.userServiceObj.LoyaltyRemvoeCard(cardId)
      .subscribe(
        (res:any) => {
          if (res.status === 200 || res.status === 202) {
            this.toasterObj.success('You have successfully removed your card.', 'Great!');
            this.routeObj.navigate(['/my-account/icon-rewards']);
          }
      },
      error =>{
        this.toasterObj.error('Could not remove card.', 'Sorry!');
      }
      );
    }
  }

  goBack(){
    this.routeObj.navigate(['/my-account/icon-rewards']);
  }

  restrictNumeric(event) {
    CustomCode.restrictNumericInCard(event);
  }

  setCardFieldPointer(cardTotalnumber){
    let flag = 0;
    //SET CURSOR POSITION
      $.fn.setCursorPosition = function(pos) {
        this.each(function(index, elem) {
          if (elem.setSelectionRange) {
            elem.setSelectionRange(pos, pos);
          } else if (elem.createTextRange) {
            var range = elem.createTextRange();
            range.collapse(true);
            range.moveEnd('character', pos);
            range.moveStart('character', pos);
            range.select();
          }
        });
        return this;
      };
    if(cardTotalnumber[0]=='3'){
      if(cardTotalnumber[1] == '_' && flag ==0 ){
        $('#standardCardNumber').setCursorPosition(1);
        flag = 1;
        }
    }
  }

  checkCardByvalue(card_no){
    //alert(card_no);
    if(card_no=='3'){
      this.cardMask = [
        /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, ' ',
        /[0-9]/, /[0-9]/, /[0-9]/, ' ',
        /[0-9]/, /[0-9]/, /[0-9]/, ' ',
        /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/ ,/[0-9]/
      ];
    }else{
     this.cardMask = [
        /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, ' ',
        /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, ' ',
        /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, ' ',
        /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/
      ];
    }
  }
  
  checkCard(card_no){
    this.checkCardByvalue(card_no.value[0]);
    this.setCardFieldPointer(card_no.value);
  }

  saveCard(card, formRef){
    this.submtDisabled = true;
    //this.progress.start();
   // this.progressRef = this.progress.ref();
  //  this.progressRef.start();

    let time =1;
    var interval = setInterval(()=>{
      time++;
      if(time>10){
        this.toasterObj.error('Network Problem', 'sorry!');
        this.submtDisabled = false;
      }

      if(localStorage.getItem('card_tokenHard')){
        localStorage.removeItem('card_tokenHard');
        clearInterval(interval);
     //   this.progressRef = this.progress.ref();
      //  this.progressRef.destroy();
        //this.progress.done();
        let card_token = localStorage.getItem('card_token');
        let card_type = localStorage.getItem('card_type');
        if(card.card_no !=='' || card.exp !== '' || card.security_code !== ''){
          if(card_token!==null){
            var tokenizationData = {
              "token":card_token,
              "type":card_type,
              "length":(card.card_no.replace(/\D/g, '')).length,
              "bin":card.card_no.replace(/\D/g, '').substr(0,6),
              "last_four":(card.card_no.replace(/\D/g, '')).substr(-4),
              "expiration":card.exp,
              "zip":card.zip_code,
              "name_on_card":'',
              "card_number": card.card_no.replace(/\D/g, ''),
              "security_code": card.security_code
            }
            
            localStorage.removeItem('card_token');
            localStorage.removeItem('card_type');

            let postData = {
              'card':this.cryptoObj.encryptCard(tokenizationData),
              'iq_code':(sessionStorage.getItem('iq_code')!==null)?sessionStorage.getItem('iq_code'):'',
              'add_to_account':card.addCardIcon,
              'invitation_code':$('#promocode').val()
            }
            
            this.userServiceObj.LoyaltyAddCard(postData)
            .subscribe(
              (data:any)=> {
                this.submtDisabled = false;
                let respdata = data;
                if (respdata.status < 400) {
                  if(this.isCardId>0){
                    this.userServiceObj.LoyaltyRemvoeCard(this.isCardId)
                      .subscribe((res:any)=> {});
                      this.toasterObj.success('Card updated successfully.', 'Great!');
                      this.routeObj.navigate(['/my-account/icon-rewards']);
                  }else{
                    if(localStorage.getItem('is_loyalty_active')=='0'|| !localStorage.getItem('is_loyalty_active')){
                      (<any>window).dataLayer.push({'event': 'rewardsEnrolled'});
                     if( sessionStorage.getItem('event_for_loyalty')=='1' ){
                        let eventlabel = sessionStorage.getItem('eventlabel');
                        let eventcategory = sessionStorage.getItem('eventCategory');
                        /* commented for ga 16-6-2021 by seo team
                         ga('send', 'event', { eventCategory: eventcategory+"With card", eventAction: 'Click', eventLabel: eventlabel, eventValue: '10' });
                         */
                         sessionStorage.removeItem('event_for_loyalty');
                         sessionStorage.removeItem('eventlabel');
                         sessionStorage.removeItem('eventCategory');
                      }
                    }
                    sessionStorage.removeItem('iq_code');
                    /*********** #Start card toasters messages ***************/

                    if("account_card" in respdata.data){
                      if(respdata.data.account_card.success){
                        this.toasterObj.success(respdata.data.account_card.message);
                      }else{
                        this.toasterObj.error(respdata.data.account_card.message, 'Sorry!');
                      }
                    }

                    if("invitation_code" in respdata.data){
                      if(respdata.data.invitation_code.success){
                        this.toasterObj.success(respdata.data.invitation_code.message);
                      }else{
                        this.toasterObj.error(respdata.data.invitation_code.message, 'Sorry!');
                      }
                    }

                    if(respdata.data.success){
                      this.toasterObj.success(respdata.data.message);
                      formRef.resetForm();
                      localStorage.setItem('is_loyalty_active', respdata.data.is_loyalty_active.toString());
                      sessionStorage.setItem('is_loyalty_active', respdata.data.is_loyalty_active.toString());
                    }else{
                      this.toasterObj.error(respdata.data.message, 'Sorry!');
                    }
                    /*********** #End card toasters messages ***************/
                    if(sessionStorage.getItem('is_loyalty_active') === '1'){
                      if(respdata.data.success){
                        this.routeObj.navigate(['/my-account/icon-rewards']);
                      }
                    }
                  }

                }else{
                  this.toasterObj.error(respdata.errors.message, 'Sorry!');
                }
            },
            error =>{
              this.submtDisabled = false;
              this.toasterObj.error(error.errors.message, 'Sorry!');
            }
            );
          }
          this.submtDisabled=false;
        }
      }
    }, 1000);
  }

  enrollNow(cardDetails, formRef){
    if(cardDetails.card_no === "" && cardDetails.exp === "" && cardDetails.security_code === "" && cardDetails.zip_code === ""){
      this.toasterObj.error('Card details should not be blank', 'Sorry!');
    }else if(cardDetails.card_no === "" || cardDetails.card_no === undefined){
      this.toasterObj.error('Card number is required', 'Sorry!');
    }else if(cardDetails.exp === "" || cardDetails.exp === undefined){
      this.toasterObj.error('Expiration date is required', 'Sorry!');
    }else if(cardDetails.security_code === "" || cardDetails.security_code === undefined){
      this.toasterObj.error('Security code is required', 'Sorry!');
    }else if(cardDetails.zip_code === "" || cardDetails.zip_code === undefined){
      this.toasterObj.error('Zip code is required', 'Sorry!');
    }else{
      this.enrolledNow.emit(cardDetails);
    }
  }
  triggerProfileBtn(){
    $('#profileBtn').trigger('click');
  }
  
   setToexpirationField(reference)
  {
    this.card.exp1=reference.value;
  }

  enrolledCardInfo(ECardInfo: TemplateRef<any>){
    this.modalRef = this.modalService.show(ECardInfo, this.config);
    return false;
  }

  close(){
    this.modalRef.hide();
  }
}
