import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { NgModel } from '@angular/forms';
declare var google: any;
import { CustomCode } from '../../shared/CustomCode';
import { FormValidationErrors } from '../../shared/formValidationErrors';
import { StaticpagesService } from '../../services/staticpages.service';
import * as $ from 'jquery';
import { ToastrService } from 'ngx-toastr';
import { PromocodeCmsService } from '../../services/promocode-cms.service';
import { FilterHelper } from '../../classes/filter-helper';
@Component({
selector: 'app-widget-form',
templateUrl: './widget-form.component.html',
styleUrls: ['./widget-form.component.scss'],
providers: [StaticpagesService]
})
export class WidgetFormComponent implements OnInit {
formErrorsCont = FormValidationErrors.formErrorsContainer;
contactTelNo = CustomCode.contactTelephoneNumber;
contactInfoMail = CustomCode.contactInfoMail;
emailRegex = CustomCode.emailRegex;
formData: any = {};
private respdata;
private parseData;
mobileLenght = 0;
startDuration = FilterHelper.defaultStartDuration();
endDuration = FilterHelper.defaultEndDuration();
timeSlots:any = FilterHelper.getObjectBasedTimeSlots();
startTime = FilterHelper.getFormattedTimeWidget(this.startDuration);
endTime = FilterHelper.getFormattedTimeWidget(this.endDuration);
minDate="";
maxDate="";
rangerwValue:any=500;
rangerhValue:any=500;
rangeEmbedWidthValue:any=50;
rangeEmbedHeightValue:any=800;
widgetLabel:any
customParkTime:boolean = false;
activeTab=true;
activeWidget=false;
widget=1;
widthType='px';
heightType='%'
showColor=0;
widthValue:any=500;
heightValue:any=500;
widthStylevalue:any=500;
widthStylevaluePreview:any=50;
heightStylevalue:any=500;
expand_tabIcon = 'assets/img/expand-tab.png';
embed_mapIcon = 'assets/img/embed-map.png';
map_widgetIcon = 'assets/img/map_widget.png';
widgetpreviewIcon = 'assets/img/widget-preview-tab.png';
map_widget_large_Icon = 'assets/img/map_widget_large.png'
//  for slider events
changeFor:any;
changeVal:any;
sliderVal= 50;
constructor(private procodecms:PromocodeCmsService,private router: Router, private staticObj: StaticpagesService, private toasterObj: ToastrService) {}
ngOnInit() {
var	positionvalue = $('#position').val();
// $( '#rangewidthembed.range-slider_range').css('background', 'linear-gradient(to right, #ed8222 0%,#ed8222 50%,#d7dcdf 50%,#d7dcdf 48%)');
// $( '#rangeheightembed.range-slider_range').css( 'background', 'linear-gradient(to right, #ed8222 0%,#ed8222 50%,#d7dcdf 50%,#d7dcdf 48%)' );
//$( '#.range-slider_range').css( 'background', 'linear-gradient(to right, #ED8222 0%, #ED8222 '+totalval +'%, #d7dcdf ' +totalval + '%, #d7dcdf 100%)' );
//$( '#heightw.range-slider_range').css( 'background', 'linear-gradient(to right, #ED8222 0%, #ED8222 '+totalval +'%, #d7dcdf ' +totalval + '%, #d7dcdf 100%)' );
}
onColorUpdated(data,id){
$('#'+id).val(data)
if(id=="background-colo")
{
$('.title-bar').css("background-color",data);
$('.previewExpand').css("border-color",data);
$('#background-color').val(data)
}else{
$('.icon-arrow, .logo-element, .icon-cross').css("fill",data);
$('.title-content').css("color",data);
$('#label-color').val(data)
}

}
setType(val,changeFor)
{

this.changeFor = changeFor;
this.changeVal = val;
if(changeFor=='width')
{
if(val=='px')
{
$("#widthw").attr('max',1000)
$("#width").val(500)
this.changerangew(500,0,'widthw',1000)
$('#width').attr('max',1000)
this.widthType='px';
this.widthValue=500;
}else
{
$("#widthw").attr('max',100)
$("#width").val(50)
this.changerangeembedwidth(50,0,'widthw','100')
$('#width').attr('max',100)
this.widthType='%';
this.widthValue=50;
}
}else{
if(val=='px')
{
$("#heightw").attr('max',1000)
$("#height").val(500)
this.changerangeembedheight(500,0,'heightw',1000)
$('#height').attr('max',1000)
this.heightType='px';
}else
{
$("#heightw").attr('max',100)
$("#height").val(50)
this.changerangeh(50,0,'heightw','100')
$('#height').attr('max',100)
this.heightType='%';
}
}

}
rangeSliderChangeupdate(data){
//this.inputrangevaluew.value
this.sliderVal = data;
this.setType(this.changeFor, this.changeVal);
}
inputChangeupdate(event){
this.sliderVal = event.target.value;
}
changeZoom(val,id,rangeid,max)
{
let totalval=0;
if(max>100)
{
totalval = (parseInt(val)*100)/parseInt(max);
}else{
totalval=val
}
if(rangeid=='widthw')
{
this.widthValue = val;
if(this.widthType=='px')
{
totalval = (parseInt(val)*100)/parseInt(max);
}else{
totalval=val
}

this.widthStylevalue=totalval
this.widthStylevaluePreview=totalval
}
if(rangeid=='heightw')
{
this.heightValue = val;
if(this.heightType=='px')
{
totalval = (parseInt(val)*100)/parseInt(max);
}else{
totalval=val
}

this.heightStylevalue=totalval
}
$('#'+id).val(val)
// $( '#'+rangeid+'.range-slider_range').css( 'background', 'linear-gradient(to right, #ED8222 0%, #ED8222 '+totalval +'%, #d7dcdf ' +totalval + '%, #d7dcdf 100%)' );

}
changeText()
{
this.widgetLabel=$('#label').val();
}
setColor(val)
{
if(val=='custom')
this.showColor=1;
else
this.showColor=0;
if($('#colorSelect').val() == 'orange') {
$('.title-bar').css("background-color","#ED8222");
$('.icon-arrow, .logo-element, .icon-cross').css("fill","#fff");
$('.title-content').css("color","#fff");
$('#background-color').val("#ED8222")
$('.previewExpand').css("border-color","#ed8222");
$('#label-color').val("#fff")
}
if($('#colorSelect').val() == 'light-grey'){
$('.title-bar').css("background-color","#ddd");
$('.icon-arrow, .logo-element, .icon-cross').css("fill","#000");
$('.title-content').css("color","#000");
$('.previewExpand').css("border-color","#ddd");
$('#background-color').val("#ddd")
$('#label-color').val("#000")
}
if($('#colorSelect').val() == 'dark-sign'){
$('.title-bar').css("background-color","#333333");
$('.icon-arrow, .icon-cross, .logo-element').css("fill","#ED8222");
$('.park-text').css("fill","#fff");
$('.title-content').css("color","#ffffff");
$('.previewExpand').css("border-color","#333333");
$('#background-color').val("dark-sign")
$('#label-color').val("dark-sign")
}
if($('#colorSelect').val() == 'custom'){
$('#background-color').val("#ED8222")
$('#label-color').val("#ffffff")
$('.previewExpand').css("border-color","#ed8222");
}
}
//  for position data
setPosition(val){
if($('#position').val() == 'bottom-right') {
$('#title-bar.title-bar').removeClass().addClass("bottom-right title-bar");
$('.previewExpand').removeClass().addClass("bottom-right previewExpand");
} else if($('#position').val() == 'bottom-left'){
$('#title-bar.title-bar').removeClass().addClass("bottom-left title-bar");
$('.previewExpand').removeClass().addClass("bottom-left previewExpand");

} else if($('#position').val() == 'top-right'){
$('#title-bar.title-bar').removeClass().addClass("top-right title-bar");
$('.previewExpand').removeClass().addClass("top-right previewExpand");
}else if($('#position').val() == 'top-left'){
$('#title-bar.title-bar').removeClass().addClass("top-left title-bar");
$('.previewExpand').removeClass().addClass("top-left previewExpand");
}
else{
$('#title-bar.title-bar').removeClass().addClass('title-bar');
$('.previewExpand').removeClass().addClass("previewExpand bottom-right");
}
}
//  code for tab select
private selectedLink: string="tab";
setradio(e: string): void {
this.selectedLink = e;
if(this.selectedLink === "tab"){
this.activeTab = true;
this.activeWidget = false;
}else{
this.activeTab = false;
this.activeWidget = true;
}
}
isSelected(name: string): boolean
{
if (!this.selectedLink) { return false; }
return (this.selectedLink === name);
}
//  code for image select ends here
initContactMap() {
const uluru = {
lat: 40.759880,
lng: -73.998682
};
const map = new google.maps.Map(document.getElementById('map'), {
zoom: 16,
center: uluru
});
const marker = new google.maps.Marker({
position: uluru,
map: map,
icon: 'assets/img/icon_pointer.png'
});
}
getLatLongFun($event)
{
$('#lat').val($event.lat);
$('#long').val($event.long);
$('#searchTextField').val($event.location);
}
updateStartDuration()
{
if(this.startDuration<FilterHelper.changeNyTime())
{
this.startDuration = FilterHelper.defaultStartDuration();
$('#dateselect').addClass('has-error');
$('#dateselect .help-block').removeClass('hidden');
}else{
$('#dateselect').removeClass('has-error');
$('#dateselect .help-block').addClass('hidden');
}
var time = this.startTime
var now = new Date(this.startDuration);
var dt = (now.getMonth()+1) + "/" + now.getDate() + "/" + now.getFullYear() + " " + time;
var userval = new Date(dt);
if(userval<FilterHelper.changeNyTime())
{
//this.startTime = FilterHelper.getFormattedTime(this.startDuration)
$('#dateselect').addClass('has-error');
$('#dateselect .help-block').removeClass('hidden');
}else{
$('#dateselect').removeClass('has-error');
$('#dateselect .help-block').addClass('hidden');
}
}
updateEndDuration()
{

if(this.endDuration<FilterHelper.changeNyTime())
{
this.endDuration = FilterHelper.defaultEndDuration();
$('#dateselect').addClass('has-error');
$('#dateselect .help-block').removeClass('hidden');
}else{
$('#dateselect').removeClass('has-error');
$('#dateselect .help-block').addClass('hidden');
}
var stime = this.startTime
var snow = new Date(this.startDuration);
let sdt = (snow.getMonth()+1) + "/" + snow.getDate() + "/" + snow.getFullYear() + " " + stime;
var time = this.endTime
var now = new Date(this.endDuration);
var dt = (now.getMonth()+1) + "/" + now.getDate() + "/" + now.getFullYear() + " " + time;
var userval = new Date(dt);
if(userval<FilterHelper.changeNyTime())
{
//this.endTime = FilterHelper.getFormattedTime(this.endDuration);
$('#dateselect').addClass('has-error');
$('#dateselect .help-block').removeClass('hidden');
}else{
$('#dateselect').removeClass('has-error');
$('#dateselect .help-block').addClass('hidden');
}
if(userval<new Date(sdt))
{
//this.endTime = FilterHelper.getFormattedTime(this.endDuration);
$('#dateselect').addClass('has-error');
$('#dateselect .help-block').removeClass('hidden');
}else{
$('#dateselect').removeClass('has-error');
$('#dateselect .help-block').addClass('hidden');
}
}
updateStartTime()
{
var time = this.startTime
var now = new Date(this.startDuration);
var dt = (now.getMonth()+1) + "/" + now.getDate() + "/" + now.getFullYear() + " " + time;
var userval = new Date(dt);
if(userval<FilterHelper.changeNyTime())
{
//this.startTime = FilterHelper.getFormattedTime(this.startDuration);
$('#dateselect').addClass('has-error');
$('#dateselect .help-block').removeClass('hidden');
}else{
$('#dateselect').removeClass('has-error');
$('#dateselect .help-block').addClass('hidden');
}

}
updateEndTime()
{
var stime = this.startTime
var snow = new Date(this.startDuration);
let sdt = (snow.getMonth()+1) + "/" + snow.getDate() + "/" + snow.getFullYear() + " " + stime;
var time = this.endTime
var now = new Date(this.endDuration);
var dt = (now.getMonth()+1) + "/" + now.getDate() + "/" + now.getFullYear() + " " + time;
var userval = new Date(dt);
if(userval<FilterHelper.changeNyTime())
{
//this.endTime = FilterHelper.getFormattedTime(this.endDuration);
$('#dateselect').addClass('has-error');
$('#dateselect .help-block').removeClass('hidden');
}else{
$('#dateselect').removeClass('has-error');
$('#dateselect .help-block').addClass('hidden');
}
if(userval<new Date(sdt))
{
//this.endTime = FilterHelper.getFormattedTime(this.endDuration);
$('#dateselect').addClass('has-error');
$('#dateselect .help-block').removeClass('hidden');
}else{
$('#dateselect').removeClass('has-error');
$('#dateselect .help-block').addClass('hidden');
}

}
gencode()
{
$("#codegen").val("");;
$('#cpy260571').text('Copy Script');
let error=1;
var stime = this.startTime
var snow = new Date(this.startDuration);
let sdt = (snow.getMonth()+1) + "/" + snow.getDate() + "/" + snow.getFullYear() + " " + stime;
var time = this.endTime
var now = new Date(this.endDuration);
var dt = (now.getMonth()+1) + "/" + now.getDate() + "/" + now.getFullYear() + " " + time;
var userval = new Date(dt);
if(userval<new Date(sdt))
{
//this.endTime = FilterHelper.getFormattedTime(this.endDuration);
error=0;
//this.toasterObj.error('You can not select back Time', 'Sorry!');
}
if(userval<FilterHelper.changeNyTime())
{
//this.startTime = FilterHelper.getFormattedTime(this.startDuration);
error=0;

}
if(error==0 && this.customParkTime==true)
{
$('#dateselect').addClass('has-error');
$('#dateselect .help-block').removeClass('hidden');
}
else{
$('#dateselect').removeClass('has-error');
$('#dateselect .help-block').addClass('hidden');
if(this.customParkTime)
{
var time = this.startTime
var now = new Date(this.startDuration);
var dt = (now.getMonth()+1) + "/" + now.getDate() + "/" + now.getFullYear() + " " + time;
var userval = new Date(dt);
var urlDate = CustomCode.getDateForMapSearchSlashFormatForUrl(userval)
var etime = this.endTime
var enow = new Date(this.endDuration);
var edt = (enow.getMonth()+1) + "/" + enow.getDate() + "/" + enow.getFullYear() + " " + etime;
var euserval = new Date(edt);
var urlEndDate = CustomCode.getDateForMapSearchSlashFormatForUrl(euserval)
}
else{

var now = FilterHelper.changeNyTime();
var time =("0" + (now.getHours())).slice(-2)+':'+("0" + (now.getMinutes())).slice(-2)
var dt = (now.getMonth()+1) + "/" + now.getDate() + "/" + now.getFullYear() + " " + time;
var userval = new Date(dt);
var urlDate = CustomCode.getDateForMapSearchSlashFormatForUrl(userval)
}

if($('#company').val().replace(/ /g,'')=='')
{
$('#companyField').addClass('has-error');
$('#companyField .help-block').removeClass('hidden');
//this.toasterObj.error('Company Name is required', 'Sorry!');
}
else{
$('#companyField').removeClass('has-error');
$('#companyField .help-block').addClass('hidden');
let widgetWidth='';
let widgetHeight='';
let selectwidth=$('#widthSelect').val();
let selectheight=$('#heightSelect').val();
if(selectwidth=='px')
{
	widgetWidth=$('#width').val()+'px';
}else{
	widgetWidth=$('#width').val()+'%';
}
if(selectheight=='px')
{
	widgetHeight=$('#height').val()+'px';
}else{
	widgetHeight=$('#height').val()+'%';
}

$('.show_pop').trigger('click');
this.procodecms.checkPartner($('#company').val()).subscribe((data:any)=>{
let labelTextForWidget='FIND PARKING NEAR ';
if($('#label').val()!=undefined)
{
labelTextForWidget='FIND PARKING NEAR '+$('#label').val()
}

var script = "icon-tab-color='"+$('#background-color').val()+"' icon-reservationEndDate='"+urlEndDate+"' icon-reservationDate='"+urlDate+"'  icon-colortext='"+$('#label-color').val()+"'  icon-textlabel='"+labelTextForWidget+"'  icon-width='"+widgetWidth+"' icon-height='"+widgetHeight+"' icon-position='"+$('#position').val()+"' icon-type='"+this.selectedLink+"' icon-lat='"+$('#lat').val()+"' icon-long='"+$('#long').val()+"' icon-name='"+$('#auto_suggest_input').val()+"' icon-text='"+data.data.slug+"' src='"+CustomCode.BASEURL+"widget/widget.js' "
var datas='/'
$("#codegen").val("<script "+script+"><"+datas+"script>");;
$('#cpy260571').text('Copy Script');
},error=>{
this.toasterObj.error('There is some error to create code. Please try again', 'Sorry!');
});
}
}
}
forvalidate()
{
if($('#company').val().replace(/ /g,'')=='')
{
$('#companyField').addClass('has-error');
$('#companyField .help-block').removeClass('hidden');
//this.toasterObj.error('Company Name is required', 'Sorry!');
}
else{
$('#companyField').removeClass('has-error');
$('#companyField .help-block').addClass('hidden');
}
}
keyPress(event: any) {
const pattern = /[0-9\)\-\(\.\ ]/;
//const pattern = /[0-9\-\\-\ ]/;
let inputChar = String.fromCharCode(event.charCode);
if (event.keyCode != 8 && !pattern.test(inputChar)) {
event.preventDefault();
}
}
changerangeembedwidth(value: any,val,id,max)
{
if(value=='')
{
value=0;
}
if(value<0)
{
value=0;
}
if(value>100)
{
value=100;
}
$('#width').val(value)
$('#widthw').val(value)
$('#rangerwValue').val(value)
let totalval=0;
if(max>100)
{
totalval = (parseInt(val)*100)/parseInt(max);
}else{
totalval=value
}
// $( '#'+id+'.range-slider_range').css( 'background', 'linear-gradient(to right, #ED8222 0%, #ED8222 '+totalval +'%, #d7dcdf ' +totalval + '%, #d7dcdf 100%)' );

}
changerangeembedheight(value: any,val,id,max)
{
if(value=='')
{
value=0;
}
if(value<0)
{
value=0;
}
if(value>1000)
{
value=1000;
}
$("#heightw").attr('max',1000)
$("#height").val(500)
$('#heightw').val(500)
$('#rangerhValue').val(500)
let totalval=0;
if(max>100)
{
totalval = (parseInt(val)*100)/parseInt(max);
}else{
totalval=value
}
// $( '#'+id+'.range-slider_range').css( 'background', 'linear-gradient(to right, #ED8222 0%, #ED8222 '+totalval +'%, #d7dcdf ' +totalval + '%, #d7dcdf 100%)' );

}
changerangew(value: any,val,id,max)
{
if(value=='')
{
value=0;
}
if(value<0)
{
value=0;
}
let drpdown=$("#widthSelect").val()
if(drpdown == 'px'){
	if(value>1000)
	{
	value=1000;
	}
}else{
	if(value>100)
	{
	value=100;
	}	
}
$('#width').val(value)
$('#widthw').val(value)
$('#rangerwValue').val(value)
let totalval=0;
if(max>100)
{
totalval = (parseInt(val)*100)/parseInt(max);
}else{
totalval=value
}
this.widthValue = val;
if(this.widthType=='px')
{
totalval = (parseInt(val)*100)/parseInt(max);
}else{
totalval=val
}


this.widthStylevalue=totalval
this.widthStylevaluePreview=totalval
// $( '#'+id+'.range-slider_range').css( 'background', 'linear-gradient(to right, #ED8222 0%, #ED8222 '+totalval +'%, #d7dcdf ' +totalval + '%, #d7dcdf 100%)' );


}
changerangeh(value: any,val,id,max)
{
if(value=='')
{
value=0;
}
if(value<0)
{
value=0;
}
let drpdown=$("#heightSelect").val()
if(drpdown == 'px'){
	if(value>1000)
	{
	value=1000;
	}
}else{
	if(value>100)
	{
	value=100;
	}	
}
$('#height').val(value)
$('#heightw').val(value)
$('#rangerhValue').val(value)
let totalval=0;
if(max>100)
{
totalval = (parseInt(val)*100)/parseInt(max);
}else{
totalval=value
}
// $( '#'+id+'.range-slider_range').css( 'background', 'linear-gradient(to right, #ED8222 0%, #ED8222 '+totalval +'%, #d7dcdf ' +totalval + '%, #d7dcdf 100%)' );

}
contactUsSubmit(formValue, formObj: NgForm) {
if (typeof formValue.phone_number === "undefined" || formValue.phone_number === "") {
this.mobileLenght = 1;
} else {
let number = formValue.phone_number;
formValue.phone = formValue.phone_number;
formValue.phone = formValue.phone.replace(/[&\/\\# ,+()$~%.'":*?<>{}]/g, '');
formValue.phone = formValue.phone.replace(/-/g, '');
//formValue.phone = formValue.phone.replace(/./g,'');
if (formValue.phone.length >= 9 && formValue.phone.length <= 12) {
this.mobileLenght = 1;
} else {
this.mobileLenght = 0;
formValue.phone_number = number;
}
}
if (this.mobileLenght == 1) {
formValue.phone = formValue.phone_number;
this.staticObj.contactUs(formValue)
.subscribe(
	(data:any) => {
		this.respdata = JSON.stringify(data);
		this.parseData = JSON.parse(this.respdata);
		if (this.parseData.status === 201) {
			formObj.resetForm();
			this.toasterObj.success('Thanks for your enquiry. We will get in touch with you.', 'Success!');
			this.router.navigate(['/contact/thanks']);
		}
	},
	error => {
		const errorsList = error.errors;
		if (errorsList.message !== 'Validation Errors') {
			this.toasterObj.error(errorsList.message, 'Sorry!');
		} else {
			for (const key in errorsList.detail) {
				if (errorsList.detail.hasOwnProperty(key)) {
					if (typeof errorsList.detail === 'undefined') {
						this.toasterObj.error(errorsList.message, 'Sorry!');
					} else {
						this.toasterObj.error(errorsList.detail[key], 'Sorry!');
					}
				}
			}
		}
	}
);
} else {
this.toasterObj.error('Phone number must contain 9 to 12 digit.', 'Error!');
}
}
}