<!-- <div class="modal fade active in show" id="pdf" *ngIf="showPopup">
	<div class="modal-dialog modal-lg">


		<div class="modal-content">
			<div cla
			<div class="modal-body">
			<button type="button" class="close pull-right" data-dismiss="modal" aria-hidden="true" (click)="closePopup()"><fa name="times"></fa></button>

			<div class=" text-center mar-bottom-sm">
				<button type="button" class="btn btn-primary theme-btn btn-fill btn-dark" (click)="printMedia()">Print</button>&nbsp;
				<button type="button" class="btn btn-primary theme-btn btn-fill" (click)="closePopup()">Cancel</button>
			</div>
			<div class="mar-to-sm" id="printableContent">
				<img [src]="sanitizer.bypassSecurityTrustResourceUrl(imageUrl)" id="invoicePrint" class="printable full-width" alt="Image">
			</div>
		</div>
	</div>
</div>
</div> -->


<div class="modal fade active in show" id="pdf" *ngIf="showPopup">
	<div class="modal-dialog modal-lg">
		<div class="modal-content">
			<div class="modal-header mar-bottom-sm">

				<button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="closePopup()"><fa name="times"></fa></button>
					<div class="text-center">
				
				<button *ngIf="isValidReservation" type="button" class="btn btn-primary theme-btn btn-fill btn-dark" (click)="printMedia()">Print</button>&nbsp;
				<button *ngIf="isValidReservation" type="button" class="btn btn-primary theme-btn btn-fill" (click)="closePopup()">Cancel</button>
				</div>
			</div>
			<div class="modal-body">
				<div class="mar-to-sm" id="printableContent">
				<img  [src]="sanitizer.bypassSecurityTrustResourceUrl(imageUrl)" id="invoicePrint" class="printable full-width" alt="Image">
				<!-- <embed *ngIf="is_image_pdf" [src]="sanitizer.bypassSecurityTrustResourceUrl(imageUrl)" width="60%" height="2100px" /> -->
			</div>
			</div>
			
		</div>
	</div>
</div>