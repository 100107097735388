<ng-container *ngIf="!isPayment">
    <div *ngIf="isIEBrowser" class="ie-browser-box">
        <div class="col-sm-custom mar-top-xs mar-bottom-xs">
            <div class="flex">
                <div class="logo ">
                    <img class="icon-img-lt" width="" [src]="icon_rewards_troffy" alt="icon" />
                </div>
                <div class="content ">
                    <h4 class="mar-top-0 mar-bottom-0 mar-bottom-0">IMMEDIATE PARKING DISCOUNTS</h4>
                    <p>Receive member discounts on most direct hourly or daily parking purchases.</p>
                </div>
            </div>
        </div>
        <div class="col-sm-custom mar-top-xs mar-bottom-xs">
            <div class="flex">
                <div class="logo ">
                    <img class="icon-img-lt" [src]="icon_rewards_gift" alt="icon" />
                </div>
                <div class="content ">
                    <h4 class="mar-top-0 mar-bottom-0">ANNUAL BONUS</h4>
                    <p>Be rewarded for parking based on your tier level.</p>
                </div>
            </div>
        </div>
        <div class="col-sm-custom mar-top-xs mar-bottom-xs">
            <div class="flex">
                <div class="logo ">
                    <img class="icon-img-lt" [src]="doller_tag" height="37" alt="icon" />
                </div>
                <div class="content ">
                    <h4 class="mar-top-0 mar-bottom-0">BEST PRICING</h4>
                    <p>Always park for the lowest price available through Icon and Quik Park.</p>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="!isIEBrowser" class="row" >
        <div class="col-sm-4 mar-top-xs mar-bottom-xs">
            <div class="flex">
                <div class="logo ">
                    <img class="icon-img-lt" width="" [src]="icon_rewards_troffy" alt="icon" />
                </div>
                <div class="content ">
                    <h4 class="mar-top-0 mar-bottom-0 mar-bottom-0">IMMEDIATE PARKING DISCOUNTS</h4>
                    <p>Receive member discounts on most direct hourly or daily parking purchases.</p>
                </div>
            </div>
        </div>
        <div class="col-sm-4 mar-top-xs mar-bottom-xs">
            <div class="flex">
                <div class="logo ">
                    <img class="icon-img-lt" [src]="icon_rewards_gift" alt="icon" />
                </div>
                <div class="content ">
                    <h4 class="mar-top-0 mar-bottom-0">ANNUAL BONUS</h4>
                    <p>Be rewarded for parking based on your tier level.</p>
                </div>
            </div>
        </div>
        <div class="col-sm-4 mar-top-xs mar-bottom-xs">
            <div class="flex">
                <div class="logo ">
                    <img class="icon-img-lt" [src]="doller_tag" height="37" alt="icon" />
                </div>
                <div class="content ">
                    <h4 class="mar-top-0 mar-bottom-0">BEST PRICING</h4>
                    <p>Always park for the lowest price available through Icon and Quik Park.</p>
                </div>
            </div>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="isPayment">
    <div class="row mainPage">
        <div class="col-sm-12 col-sm-4  mar-bottom-xs">
            <div class="flex">
                <div class="logo ">
                    <img class="icon-img-lt" [src]="icon_rewards_troffy" alt="price_tag" />
                </div>
                <div class="content ">
                    <h4 class="mar-top-0 mar-bottom-0">IMMEDIATE PARKING DISCOUNTS</h4>
                    <p>Receive member discounts on most direct hourly or daily parking purchases.</p>
                </div>
            </div>
        </div>
        <div class="col-sm-12  col-sm-4 mar-top-xs mar-bottom-xs">
            <div class="flex">
                <div class="logo ">
                    <img class="icon-img-lt" [src]="icon_rewards_gift" alt="price_tag" />
                </div>
                <div class="content ">
                    <h4 class="mar-top-0 mar-bottom-0">ANNUAL BONUS</h4>
                    <p>Be rewarded for parking based on your tier level.</p>
                </div>
            </div>
        </div>
        <div class="col-sm-12 col-sm-4  mar-top-xs">
            <div class="flex">
                <div class="logo ">
                    <img class="icon-img-lt" [src]="doller_tag" alt="price_tag" />
                </div>
                <div class="content ">
                    <h4 class="mar-top-0 mar-bottom-0">BEST PRICING</h4>
                    <p>Always park for the lowest price available through Icon and Quik Park.</p>
                </div>
            </div>
        </div>
    </div>
</ng-container>