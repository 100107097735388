import { Component, OnInit, ViewEncapsulation,ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
declare var google: any;
import { CustomCode } from '../../shared/CustomCode';
import { FormValidationErrors } from '../../shared/formValidationErrors';
import { StaticpagesService } from '../../services/staticpages.service';
import * as $ from 'jquery';
import { ToastrService } from 'ngx-toastr';
import { FilterHelper } from '../../classes/filter-helper';
import { UtilityService } from '../../services/utility.service';

@Component({
  selector: 'app-contact-reviews',
  templateUrl: './contact-reviews.component.html',
  styleUrls: ['./contact-reviews.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [StaticpagesService,UtilityService]
})
export class ContactReviewsComponent implements OnInit {

  private pageId = 'contact-reviews';
  contactDetails:any = { };
  isLoaded = false;
 
  formErrorsCont = FormValidationErrors.formErrorsContainer;
  contactTelNo = CustomCode.contactTelephoneNumber;
  contactInfoMail = CustomCode.contactInfoMail;
  emailRegex = CustomCode.emailRegex;
  phoneMask: any = CustomCode.phoneMask;
  //formData: any = {};
  private respdata;
  private parseData;
  mobileLenght=0;
  facilities:any;
  date:any;
  constructor(private router: Router, private staticObj: StaticpagesService, private toasterObj: ToastrService, private elementRef: ElementRef,private utilityService:UtilityService) { }

  ngOnInit() {
    // to get data of cmd page
      this.staticObj.getCmsPageContent(this.pageId).subscribe(
        (data:any)=> {
          this.contactDetails  = data.result[0];
          //console.log(this.contactDetails);
          this.isLoaded = true;
          this.bindElement();
        }, (error) => { console.log('Error: ' + JSON.stringify(error)); 
        this.isLoaded = true;
      }
      )
      this.loadfacility();
  }
formData: any = {
    'daily_or_monthly' : 'Daily_Reservation',
    'incidentdate': new Date()
    
  };
  initContactMap() {
    const uluru = { lat: 40.759880, lng: -73.998682 };
    const map = new google.maps.Map(document.getElementById('map'), {
      zoom: 16,
      center: uluru
    });
    const marker = new google.maps.Marker({
      position: uluru,
      map: map,
      icon: 'assets/img/icon_pointer.png'
    });
  }
  loadfacility(){
      this.utilityService.writeFacilities().subscribe(
        (data:any) => {
          this.facilities = data.data.facilities;

          //console.log(this.facilities)
          //let tmp = [];
          // facilities.forEach(element => {
          //   if(element.active == '1'){
          //     let fc = {
          //       full_name:element.full_name.toLowerCase(),
          //       longitude:element.geolocations.longitude,
          //       latitude:element.geolocations.latitude
          //     }
          //     tmp.push(fc);
          //   }
          // });
          // CustomCode.facilityListSearch = tmp;
          // this.facilities = tmp;
        }
      );
    }
  keyPress(event: any) {
    const pattern = /[0-9\)\-\(\.\ ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && event.keyCode != 9 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }
  contactUsSubmit(formValue, formObj: NgForm) {
    if (typeof formValue.phone_number === "undefined" || formValue.phone_number === "") {
        this.mobileLenght=1;
    }
    else{
      let number= formValue.phone_number;
       formValue.phone=formValue.phone_number;
       formValue.phone = formValue.phone.replace(/[&\/\\# ,+()$~%.'":*?<>{}]/g, '');
       formValue.phone = formValue.phone.replace(/-/g,'');
       //formValue.phone = formValue.phone.replace(/./g,'');
       if(formValue.phone.length >=9 && formValue.phone.length <=12 ) {
         this.mobileLenght=1;
       }else{
         this.mobileLenght=0;
         formValue.phone_number=number;
       }
    }



    const reg =/<(.|\n)*?>/g; 
    if (reg.test(formValue.first_name) == true) {
      this.toasterObj.error('Please enter valid First name.', 'Sorry!');
      return false;
    } else {
        if (reg.test(formValue.email) == true) {
          this.toasterObj.error('Please enter valid Email.', 'Sorry!');
          return false;
        } else{
          if (reg.test(formValue.monthly_account_number) == true) {
            this.toasterObj.error('Please enter valid City.', 'Sorry!');
            return false;
          } else {
            if (reg.test(formValue.reservation_number) == true) {
              this.toasterObj.error('Please enter valid State.', 'Sorry!');
              return false;
            }else{
              if (reg.test(formValue.comments) == true) {
                this.toasterObj.error('Please enter valid Comments.', 'Sorry!');
                return false;
              }
              else{
                //return true;
              }
            }
          }
        }
    }
    if(formValue.phone != undefined && formValue.phone != ''){
      let ph = formValue.phone.replace(/\D/g, '');
      if(ph.length !==0 && ph.length < 10){
        formObj.form.controls['phone_number'].setErrors({'invalid': true});
        return false;
      }
    }

     if(this.mobileLenght==1) { 
    formValue.phone=formValue.phone_number;
    let UserId='0';
    let isLogin = false;
    if(localStorage.getItem('access_token')){
     isLogin = true;
       UserId = localStorage.getItem('userid');
       
    }
    formValue.userId=UserId;
   // console.log(formValue.userId)
   //this.date=formValue.incidentdate;
    formValue.incidentDate=formValue.incidentdate.format("dd-mm-yyyy");
    //formValue.incidentDate = moment(formValue.incidentdate).format('YYYY-MM-DD');
    this.staticObj.contactReviews(formValue)
      .subscribe(
      (data:any)=> {
        //console.log(data);
        this.fetchUserStatusForTracking();
        this.respdata = data;
        this.parseData = this.respdata;
        if (this.parseData.status === 201) {
          formObj.resetForm();
          this.toasterObj.success('Thanks for your enquiry. We will get in touch with you.', 'Success!');
		  this.router.navigate(['/contact/thanks']);
        }
      },
      error => {
        //console.log(error);
        const errorsList = error.errors;
        if (errorsList.message !== 'Validation Errors') {
          this.toasterObj.error(errorsList.message, 'Sorry!');
        } else {
          for (const key in errorsList.detail) {
            if (errorsList.detail.hasOwnProperty(key)) {
              if (typeof errorsList.detail === 'undefined') {
                this.toasterObj.error(errorsList.message, 'Sorry!');
              } else {
                this.toasterObj.error(errorsList.detail[key], 'Sorry!');
              }
            }
          }
        }
      }
      );
    }
    else{
          
          this.toasterObj.error('Phone number must contain 9 to 12 digit.', 'Error!');
    }

  }

  restSpace(event: any){
    if (event.keyCode == 32) {
      event.preventDefault();
    }
  }

  restDigit(event: any){
    if (event.target.value.length > 1 && event.keyCode != 8 && event.keyCode != 9) {
      event.preventDefault();
      this.toasterObj.error('Please enter valid two digit state code.', 'Sorry!');
    }
  }

restrictNumericDecimal(event) {
  
   var k;  
   k = event.charCode;  //         k = event.keyCode;  (Both can be used)
   return((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57)); 

  }





restrictNumeric(event) {
    let input;
        if (event.metaKey || event.ctrlKey) {
            return true;
        }
        if (event.which === 32) {
            return false;
        }
        if (event.which === 0) {
            return true;
        }
        if (event.which < 33) {
            return true;
        }
        input = String.fromCharCode(event.which);
        return !!/[\d\s]/.test(input);
  }
  disableDate(){
      return false;
  } 
  bindElement(){
    setTimeout(()=> {
      //console.log(this.elementRef.nativeElement);
      if(this.elementRef.nativeElement.querySelector('.sendToMonthlyParking') ){
        this.elementRef.nativeElement.querySelector('.sendToMonthlyParking').addEventListener('click', this.redirectTomonthlySearch.bind(this) );
      }
      if(this.elementRef.nativeElement.querySelector('.sendToMonthlyParking2') ){
        this.elementRef.nativeElement.querySelector('.sendToMonthlyParking2').addEventListener('click', this.redirectTomonthlySearch2.bind(this) );
      }
      if(this.elementRef.nativeElement.querySelector('.faqBtnRedirect') ){
        this.elementRef.nativeElement.querySelector('.faqBtnRedirect').addEventListener('click', this.redirectToFaq.bind(this) );
      }
      if(this.elementRef.nativeElement.querySelector('.faqBtnRedirect2') ){
        this.elementRef.nativeElement.querySelector('.faqBtnRedirect2').addEventListener('click', this.redirectToFaq2.bind(this) );
      }
      if(this.elementRef.nativeElement.querySelector('.faqBtnRedirect3') ){
        this.elementRef.nativeElement.querySelector('.faqBtnRedirect3').addEventListener('click', this.redirectToFaq3.bind(this) );
      }
     },1000)
  }

  redirectTomonthlySearch(){
    this.formonthly();
  }
  redirectTomonthlySearch2(){
    this.formonthly();
  }
  redirectToFaq(){
    this.router.navigate(['faq']);
  }
  redirectToFaq2(){
    this.router.navigate(['faq']);
  }
  redirectToFaq3(){
    this.router.navigate(['faq']);
  }

  formonthly()
  {
      let startDuration:any = FilterHelper.defaultStartDuration();
      let startTime = FilterHelper.getFormattedTime(startDuration);
      localStorage.setItem('monthlyDate',startDuration)
      let endDuration = FilterHelper.defaultEndDuration();
      let endTime = FilterHelper.getFormattedTime(endDuration);
      let endTimeDataIfLess = FilterHelper.makeDurationDateObject(endDuration,endTime);
      localStorage.setItem('endttimehome',CustomCode.getDateForMapSearchSlashFormatHome(endDuration,endTime));
      localStorage.setItem('startimehome',CustomCode.getDateForMapSearchSlashFormatHome(startDuration,startTime));
      let stime =localStorage.getItem('startimehome');
      this.router.navigate(['/search', '40.7589', '-73.9851', stime, 2,  'Times Square','monthly', CustomCode.searchMode()]);
  }
  fetchUserStatusForTracking(){
    let getUserId = '0';
    let getUserEmail = '0';
    let user_login_type = '';
    let isLogin = false;
    if(localStorage.getItem('access_token')){
     isLogin = true;
       getUserId = localStorage.getItem('userid');
       getUserEmail = localStorage.getItem('email');
       user_login_type = localStorage.getItem('user_login_type');
    }
    const loginStatusTrack = {
      'event' : 'pageview',
      'loginStatus' : isLogin? 'logged in': 'Not Logged IN', // 'logged in/Not Logged IN',
      'authenticationMethod' : user_login_type,
      'userId' : getUserId,
      'pageType' : this.router.url,
      'email': getUserEmail,
   };
  (<any>window).dataLayer.push(loginStatusTrack);
  
  }

}
