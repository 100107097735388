<div role="tabpanel" class="tab-pane fade active in" id="notice">
	<h4 class="text-primary text-uppercase mar-top-0">Cancellation notice</h4>
	<div *ngIf="showForm">
		<p class="lead">
			Please fill out the form below to cancel one or more parking space reservations for account #{{
			accountNumber }}.
		</p>
		<p>
			Customer will be required to check at least ONE box next to a tenant number in order to submit the
			cancellation request.
		</p>
		<p>
			Please note: 30 day's advance notice is required to cancel your reserved monthly space. Monthly rates are
			not adjusted for days parked. You must pay the full month.
		</p>
		<form action="" method="POST" role="form" id="" class="floating-label monthly-cancel-form"
			(ngSubmit)="f.form.valid && submitCancellation(formData, f)" #f="ngForm" novalidate>
			<div class="row form-group-full-row">
				<div *ngIf="isAccontLoaded" class="col-sm-10 col-md-6 col-lg-6">
					<div class="canc-notic-location">
						<h3>Location</h3>
						<h2>{{facility}}</h2>
						<p>{{addFacility}}</p>
					</div>

					<div *ngIf="isSubscriptionBasedUser" class="form-group row">
						<div class="col-sm-12 tenant-details-title">
							<label class="mar-bottom-xs">Tenant Number(s)<span class="red">*</span></label>
						</div>
						<div class="col-sm-12 tenant-details-wrapper tanant-2">
							<div *ngIf="isSubscriberBasedUserLoaded">
								<ul *ngIf="tenantLists.length > 0" class="cancel-options">
									<li class="tenant_number" *ngFor="let tenant of tenantLists">
										<!-- <ng-container *ngIf="tenant.Subscription_Flag == 1"> -->
											<input type="checkbox" class="form-control"
												[ngClass]="(tenant.Status == '0' || tenant.Status == '1')?'disable-input-checkbox':'enable-input-checkbox'"
												id="{{tenant.Tenat_ID}}-{{tenant.Account_ID}}"
												name="{{tenant.Account_Code}}" value="{{tenant.Tenant_Spot}}"
												[(ngModel)]="tenant.checked" #{{tenant.Tenant_Spot}}="ngModel"
												(ngModelChange)="updateDisclaimer()">
											<label for="{{tenant.Tenat_ID}}-{{tenant.Account_ID}}"
												class="tenant-label mt-0 pt-2" sourcefont>
												<span class="slot">{{tenant.Tenant_Spot}}</span>
												<span *ngIf="tenant.Subscription_Flag == 1 || tenant.is_subscription_expired==1" class="month">
                          Current Plan<br>
                          {{tenant.Subscription_Term_Months}}
													{{((tenant.Subscription_Term_Months) > 1) ? "Months Plan":"Month
													Plan"}} : <span
													*ngIf="tenant.Subscription_Flag == 1 || tenant.is_subscription_expired==1" class="price">${{tenant.Full_Gross_Amount}}/month</span></span>
												<span *ngIf="tenant.Subscription_Flag == 1 || tenant.is_subscription_expired==1" class="validity">( {{tenant.formatted_from_date}} Through
													{{tenant.formatted_guarantee_date}} )</span>
												<span class="cancel-msg"
													*ngIf="tenant.Status == '0' || tenant.Status == '1'">(A cancellation
													request has already been submitted for this tenant)</span>
											</label>
										<!-- </ng-container> -->
									</li>
								</ul>
								<div *ngIf="tenantLists.length == 0">
									<h4 class="no-tenant-number">No tenant found.</h4>
								</div>
							</div>
							<app-lazy-load [isLoaded]="isSubscriberBasedUserLoaded" [sectionName]="'Tenant Numbers'">
							</app-lazy-load>
						</div>
					</div>

					<div class="form-group termination_date_label">
						<label class="control-label">Termination Date (Please enter final day of parking)<span
								class="red">*</span></label>
						<input readonly type="text" class="form-control" id="termination_date" name="termination_date"
							[minDate]="minDate" [maxDate]="maxDate" [(ngModel)]='defaultTerminationDate'
							#dp="bsDatepicker" (ngModelChange)="updateLabel()" bsDatepicker
							[(bsValue)]="termination_date">
						<i class="fa text-primary fa-calendar-o"
							style="position: absolute;right: 10px;top: 15px;font-size: 14px;z-index:-1;"></i>
					</div>

					<div *ngIf="!isSubscriptionBasedUser" class="form-group row">
						<div class="col-sm-4 tenant-details-title">
							<label>Tenant Number(s)<span class="red">*</span></label>
						</div>
						<div class="col-sm-8 tenant-details-wrapper">
							<div *ngIf="isNonSubscriberBasedUserLoaded">
								<ul *ngIf="successRes==true" class="cancel-options">
									<li class="tenant_number" *ngFor="let tenant of tenantDetails['data']">
										<span *ngIf="tenant.Status == '0' || tenant.Status == '1'"
											class="disable-input"></span>
										<input
											[ngClass]="(tenant.Status == '0' || tenant.Status == '1')?'disable-input-checkbox':'enable-input-checkbox'"
											type="checkbox" class="form-control"
											id="{{tenant.Tenat_ID}}-{{tenant.Account_ID}}-{{tenant.Status}}"
											name="{{tenant.Account_Code}}" value="{{tenant.Tenant_Spot}}"
											[(ngModel)]="tenant.checked" #{{tenant.Tenant_Spot}}="ngModel">
										<label
											[ngClass]="(tenant.Status == '0' || tenant.Status == '1')?'disable-input':'enable-input'"
											for="{{tenant.Tenat_ID}}-{{tenant.Account_ID}}-{{tenant.Status}}"
											class="tenant-label"><span class="number">{{tenant.Tenant_Spot}}</span>
											<span class="cancel-msg"
												*ngIf="tenant.Status == '0' || tenant.Status == '1'">(A cancellation
												request has already been submitted for this tenant)</span> </label>
									</li>
								</ul>
								<div *ngIf="successRes==false">
									<h4 class="no-tenant-number">No tenant found.</h4>
								</div>
							</div>
							<app-lazy-load [isLoaded]="isNonSubscriberBasedUserLoaded" [sectionName]="'Tenant Numbers'">
							</app-lazy-load>
						</div>
					</div>
					<div class="form-group canc-notic-declaration"
						[ngClass]="{ 'has-error' : f.submitted && !tnc.valid}" *ngIf="showDisclaimer">
						<input type="checkbox" class="form-control" id="dec-tnc" name="tnc" [(ngModel)]="formData.tnc"
							#tnc="ngModel" required="showDisclaimer">
						<div *ngIf="f.submitted && !tnc.valid" class="help-block">Please accept terms and conditions.</div>
						<label for="" class="err-label-new" [innerHTML]="disclaimer" sourceFont></label>
					</div>
				</div>
				<app-lazy-load [isLoaded]="isAccontLoaded" [sectionName]="'Account Details'"></app-lazy-load>
				<div class="col-sm-10 col-md-6 col-lg-6">
					<div class="form-group termination_reson_label">
						<label class="control-label">Reason for cancelling:<span class="red">*</span></label>
					</div>
					<div class="form-group">
						<div class="col-sm-12">
							<ul class="form-group cancel-reason-fields">
								<li *ngFor="let reason of cancel_reason; let i = index">
									<input type="checkbox" class="form-control" id="{{reason.id}}"
										name="{{reason.reason_name}}" [ngModel]="reason.active"
										#{{reason.reason_name}}="ngModel">
									<label for="{{reason.id}}" class="cancel-reason-label">{{reason.reason_name
										}}</label>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-sm-12">
					<div class="form-group  comment-wrapper">
						<label class="control-label">Comments</label>
						<br>
						<textarea name="" id="input" name="comments" class="form-control" rows="7"
							[(ngModel)]="formData.comments" #comments="ngModel"></textarea>
					</div>
				</div>
			</div>
			<div class="form-group">
				<div id="modal" (click)="openModal(template)"></div>
				<button id="submitbutton" type="submit" [ngClass]="{'disable-button': disableBtn == true}"
					[disabled]="(disableBtn)?true:false" class="btn btn-primary theme-btn btn-fill">Submit
				</button>
			</div>
		</form>
	</div>
	<div *ngIf="isCancelled">
		<p class="lead">
			Your cancellation request has been submitted
		</p>
		<div *ngIf="autopay==1">
			<!-- <div class="success" >
				AutoPay is still active for this account
		  </div> -->
			<!-- <a routerLink="/my-account/monthly-parking/account/{{ accountNumber }}/auto-pay"  class="btn viewAccount btn-primary btn-fill">Disable Autopay</a> -->
			<a routerLink="/my-account/monthly-parking/account/{{ accountNumber }}/current-balance"
				class="btn viewAccount btn-primary btn-fill">Back To My Account Home</a>
		</div>
		<div *ngIf="autopay==0">
			<a routerLink="/my-account/monthly-parking/account/{{ accountNumber }}/current-balance"
				class="btn viewAccount btn-primary btn-fill">Back To My Account Home</a>
		</div>
	</div>
</div>

<ng-template class="rounded" #template>
	<div class="modal-body " style="font-size: 16px;">
		<p>Are you sure you want to cancel your current monthly plan? </p>

	</div>
	<div class="modal-footer actionModal  border-top pt-2">
		<button type="button" class="btn btn-link text-info" (click)="confirm()">Yes</button>
		<button type="button" class="btn btn-link text-info" (click)="decline()">No</button>
	</div>
</ng-template>