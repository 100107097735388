import { Component, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { NgForm } from '@angular/forms';
import * as $ from 'jquery';
import { GoogleMapComponent } from '../../Global/google-map/google-map.component';
import { CustomCode } from '../../../shared/CustomCode';
import { SearchPageScript } from '../../../shared/searchPageScript';
import { FormValidationErrors } from '../../../shared/formValidationErrors';
import { SearchpageserviceService } from '../../../services/searchpageservice.service';
import { ToastrService } from 'ngx-toastr';
import { FilterHelper } from '../../../classes/filter-helper';
import { SearchFiltersComponent } from '../../../Components/Global/search-filters/search-filters.component';
import { Location } from '@angular/common';
@Component({
	selector: 'app-search-facility',
	templateUrl: './search-facility.component.html',
	styleUrls: ['./search-facility.component.scss'],
	providers: [SearchpageserviceService]
})
export class SearchFacilityComponent implements OnInit {

	@ViewChild(GoogleMapComponent,{ static: true }) googleMap: GoogleMapComponent;
	@ViewChild(SearchFiltersComponent,{ static: true }) searchItem: SearchFiltersComponent;

	dSD:any = SearchPageScript.defaultDateForDailyMonthlyFacility();
	dailyEndDate: any = SearchPageScript.defaultDateForDailyMonthlyFacility();

	searchResult: any = {};
	currDateObj = new Date();
	bsValue:any;
	maxDate:any;
	alreadyReload=0;
    isFrame:boolean=true;
	coeff = 1000 * 60 * 30;
	sstartTime = new Date(Math.round(this.currDateObj.getTime() / this.coeff) * this.coeff);

	restrictRequestOnInit = 'stop';
	monthlyDate = SearchPageScript.defaultDateForDailyMonthlyFacility();
	
	endTime = new Date(Math.round(this.currDateObj.getTime() / this.coeff) * this.coeff);
	allfacility:any=''
	mobileStartTime:any='';
	stime;
	etime;
	minDate: Date;
	preventUnusedAjax = false;
	constructor(
		private router: Router,
		private activatedRouteObj: ActivatedRoute,
		private toastr: ToastrService,
		private searchPageObj: SearchpageserviceService,
		private location:Location
		
	) { }

	firstLoad = false;

	searchCriteria: any = {
		'type': CustomCode.defaultFacilityType,
		'device': 'web',
		'lat': CustomCode.timeSquareCoords.lat,
		'long': CustomCode.timeSquareCoords.long,
		'currdate': CustomCode.getDateForMapSearch(),
		'radius': CustomCode.facilityDefaultRadius,
		'label': CustomCode.timeSquareCoords.defaultLocation,
		'length_of_stay': 2,
		'firstLoad' : this.firstLoad
	};
	showPop()
	{
		if(localStorage.getItem('mapEvent'))
		{
			localStorage.setItem('headerEvenT','1')
			
		}
		
	}
	showpopUpOnmobile()
	{
	  if (navigator.userAgent.match(/(iPod|iPhone|iPad|Android)/i)) {
		localStorage.setItem('headerEvenT','1');
	  }
	}
	ngOnInit() {
		//alert(2);
		// this.activatedRouteObj.params.subscribe((params: Params) => {
			
		// 	if (params['currdate'].indexOf("NAN")) {
		// 		let setDate =FilterHelper.defaultStartDuration();
		// 		let sttime=FilterHelper.getFormattedTime(setDate);
		// 		let totalsttime= CustomCode.getDateForMapSearchSlashFormatHome(setDate,sttime);
		// 		this.router.navigate(['/search/40.7589/-73.9851/'+totalsttime+'/2/Times Square/daily/web']);		
		// 	}
		// });
		localStorage.removeItem('showNeigborMarker2');
		localStorage.removeItem('loadFirstTime')
		localStorage.removeItem('neighTypeformob')
		sessionStorage.removeItem('openByClickNeigh');
		localStorage.setItem('searcgPage', '1');
		if(window.self!==window.top){this.isFrame = false;}
		$('.dropdown-menu-right').on( 'click', 'a', function() {
			var text = $(this).html();
			$(this).closest('.dropdown').find('.dropdown-toggle').val(text).parent().find('td').removeClass('open');
		});

		localStorage.removeItem('is_partner_page');
		localStorage.setItem('ischecked','0')
		this.sstartTime = new Date(this.sstartTime.setMinutes(this.sstartTime.getMinutes() + 30));
		let setDate =FilterHelper.defaultStartDuration();
		let sttime=FilterHelper.getFormattedTime(setDate);
		let totalsttime= CustomCode.getDateForMapSearchSlashFormatHome(setDate,sttime)
		//alert(totalsttime);
		//let currentDate = FilterHelper.changeNyTime()
		//alert()
		this.endTime = new Date(this.endTime.setMinutes(this.endTime.getMinutes() + 150));
		this.minDate = new Date();

		this.stime = CustomCode.getUserTime(this.sstartTime);
		this.etime = CustomCode.getUserTime(this.endTime);
		this.dSD = new Date();
		this.dailyEndDate = new Date();
		const splitDate = this.stime.split(' ');
		const getHour = splitDate[0].split(':');
		const amPm = splitDate[1];
		if(amPm === 'PM' && getHour[0] >= 10) {
			const dateObj = new Date();
			const nDate = new Date(dateObj.setDate(dateObj.getDate() + 1));
			const newMonth = nDate.getMonth() + 1;
			//this.dailyEndDate = newMonth + '/' + nDate.getDate() + '/' + nDate.getFullYear();
			$('#enddate').val(newMonth + '/' + nDate.getDate() + '/' + nDate.getFullYear());
			// this.dailyEndDate = new Date(newMonth + '/' + nDate.getDate() + '/' + nDate.getFullYear());
			// this.dailyEndDate = SearchPageScript.nextDayDate(nDate);
			this.dailyEndDate = nDate;

		}

		$('#stime').val(this.stime);
		$('#etime').val(this.etime);
	
		this.activatedRouteObj.params.subscribe((params: Params) => {
			
			if (params['lat'] && params['long'] && params['currdate'] && params['radius'] && params['label'] && params['type'] && params['device']) {
				
				this.searchCriteria.type = params['type'];
				if(this.searchCriteria.type =='monthly')
				{
					// console.log(344444444);
					// setTimeout(() => {
					// 	$('body').addClass('showChat');
					//   },1000);
					// let times_run= setInterval(function () {
					// 	$('body').addClass('showChat');
					// }, 4000);
					// setTimeout(function () {
					// 	clearInterval(times_run);
					// }, 10000);
					$('body').addClass('showChat');
					
				}else{
					$('body').removeClass('showChat');
				}
				localStorage.setItem('latBuyMonthly',params['lat']);
				localStorage.setItem('longBuyMonthly',params['long']);
				localStorage.setItem('locationBuyMonthly',params['label']);
				localStorage.setItem('latNew',params['lat']);
				localStorage.setItem('longNew',params['long']);
				localStorage.setItem('locationNew',params['label']);
				this.searchCriteria.device = params['device'];
				this.searchCriteria.lat = +params['lat'];
				this.searchCriteria.long = +params['long'];
				
				this.searchCriteria.currdate = params['currdate'];
				
				this.searchCriteria.radius = params['radius'];
				this.searchCriteria.label = params['label'];
				//this.searchCriteria.currdate=CustomCode.getDateForMapSearchSlashFormatForUrl(FilterHelper.defaultStartDurationUrl(this.searchCriteria.currdate))
			}
			this.googleMap.loadMap(this.searchCriteria);
			//this.fetchFacilityData(this.searchCriteria);
			if(params['radius']==3 || localStorage.getItem('centerClickLocation'))
			{
				//alert(8);
				localStorage.removeItem('centerClickLocation')
				this.fetchFacilityData(this.searchCriteria);
			}
			
			if(params['lat']==undefined)
			{
				let endDuration = FilterHelper.defaultEndDuration();
   				let endTime = FilterHelper.getFormattedTime(endDuration);
    			localStorage.setItem('endttimehome',CustomCode.getDateForMapSearchSlashFormatHome(endDuration,endTime));
				this.router.navigate(['/search/40.7589/-73.9851/'+totalsttime+'/2/Times Square/daily/web']);
			}
		});
		this.updateRecordsOnLabelChange(this.searchCriteria.type);
		setTimeout(() => {
          $('label#' + this.searchCriteria.type).addClass('active');
		}, 100);
		//alert(6);
	}

	flushLocalStorage() {		
		localStorage.removeItem('recent_search');		
		localStorage.removeItem('userLat');		
		localStorage.removeItem('userLong');		
		localStorage.removeItem('currDate');		
		localStorage.removeItem('facilityRadius');		
		localStorage.removeItem('locationUser');		
		localStorage.removeItem('searchType');		
		localStorage.removeItem('searchMode');		
	}

	manageDatePicker(val) {
		$('#' + val).trigger('click');
	}
	receiveNewFilters($event) {
		//alert('1')
		this.searchCriteria = $event;
		//alert(2);
		//this.mobileStartTime=this.searchCriteria.mobStarttime
		this.fetchFacilityData(this.searchCriteria);
	}

	neighborhoodSearchFilter($event) {
		//alert('2')
		this.searchCriteria = $event;
		//alert(2);
		//this.mobileStartTime=this.searchCriteria.mobStarttime
		this.fetchFacilityData(this.searchCriteria);
	}

	receiveFacilityNewSearchFilter($event) {
		//alert(232)
		let facility =JSON.parse(JSON.stringify(this.allfacility))
		this.searchResult =facility;
		let fac=[];
		let selectFacility=[]
		let k=0;
		let exit=1;
		var is_monthlypark='';
		var is_motorcycle_parking='';
		var is_oversized='';
		var is_generic_ev_charging='';
		var is_tesla_charging='';
		var is_outdoor_parking='';
		var is_indoor_parking='';
		var is_twentyhour='';
		let isselected=0;
		if($event.is_monthlypark)
		{
     	  is_monthlypark='is_monthly'
     	  selectFacility.push('is_monthly')
     	  localStorage.setItem('is_monthlypark','true')
     	  isselected=1;
		}
		else{
			localStorage.setItem('is_monthlypark','false')
		}
     	if( $event.is_motorcycle_parking)
     	{
     	  is_motorcycle_parking='is_motorcycle_parking'
     	  selectFacility.push('is_motorcycle_parking')
     	  localStorage.setItem('is_motorcycle_parking','true')
     	  isselected=1;
     	}
     	else{
			localStorage.setItem('is_motorcycle_parking','false')
		}
     	if($event.is_oversized)
     	{
     	  is_oversized='is_oversized'
     	  selectFacility.push('is_oversize')
     	  localStorage.setItem('is_oversized','true')
     	  isselected=1;
     	}
     	else{
			localStorage.setItem('is_oversized','false')
		}
     	if($event.is_generic_ev_charging)
     	{
     	  is_generic_ev_charging='is_generic_ev_charging'
     	  selectFacility.push('is_generic_ev_charging')
     	  localStorage.setItem('is_generic_ev_charging','true')
     	  isselected=1;
     	}
     	else{
     		localStorage.setItem('is_generic_ev_charging','false')
     	}
     	if($event.is_tesla_charging)
     	{
     	  is_tesla_charging='is_tesla_charging'
     	  selectFacility.push('is_tesla_charging')
     	  localStorage.setItem('is_tesla_charging','true')
     	  isselected=1;
     	}
     	else{
			localStorage.setItem('is_tesla_charging','false')
		}
     	if($event.is_outdoor_parking)
     	{
     	  is_outdoor_parking='is_outdoor_parking'
     	  selectFacility.push('is_outdoor_parking')
     	  localStorage.setItem('is_outdoor_parking','true')
     	  isselected=1;
     	}
     	else{
			localStorage.setItem('is_outdoor_parking','false')
		}
     	if($event.is_indoor_parking)
     	{
     	  is_indoor_parking='is_indoor_parking'
     	  selectFacility.push('is_indoor_parking')
     	  localStorage.setItem('is_indoor_parking','true')
     	  isselected=1;
     	}
     	else{
			localStorage.setItem('is_indoor_parking','false')
		}
     	if($event.is_twentyhour)
     	{
     	  is_twentyhour='is_24hour_open'
     	  selectFacility.push('is_24hour_open')
     	  localStorage.setItem('is_twentyhour','true')
     	  isselected=1;
     	}
     	else{
			localStorage.setItem('is_twentyhour','false')
		}
		if($event.monthly_campaign)
     	{
     	  selectFacility.push('monthly_campaign')
     	  localStorage.setItem('monthly_campaign','true')
     	  isselected=1;
     	}
     	else{
			localStorage.setItem('monthly_campaign','false')
		}
   		if(isselected)
   		{
   			localStorage.setItem('ischecked','1')
   		}else{
   			localStorage.setItem('ischecked','0')
   			localStorage.removeItem('is_twentyhour')
   			localStorage.removeItem('is_indoor_parking')
   			localStorage.removeItem('is_outdoor_parking')
   			localStorage.removeItem('is_tesla_charging')
   			localStorage.removeItem('is_generic_ev_charging')
   			localStorage.removeItem('is_oversized')
   			localStorage.removeItem('is_motorcycle_parking')
			localStorage.removeItem('is_motorcycle_parking')
			localStorage.removeItem('monthly_campaign')
   		}
		for(let i=0; i<this.searchResult.facilities.length;i++)
		{
				let facilityCount=0;
				let z=0;
				for(;z<selectFacility.length;z++)
				{
					exit=0
					if(this.searchResult.facilities[i][selectFacility[z]]==1)
					{
						facilityCount++;
					}
				}
				if(z==facilityCount)
				{
					exit=0
					fac[k++]=this.searchResult.facilities[i]
				}
				
				//if(is_monthlypark!='')
				// if(this.searchResult.facilities[i][is_monthlypark]==1)
				// {
				// 	exit=0
				// 	fac[k++]=this.searchResult.facilities[i]
				// }
				// if(is_motorcycle_parking!='')
				// if(this.searchResult.facilities[i][is_motorcycle_parking]==1)
				// {
				// 	exit=0
				// 	fac[k++]=this.searchResult.facilities[i]
				// }
				// if(is_indoor_parking!='')
				// if(this.searchResult.facilities[i][is_indoor_parking]==1)
				// {
				// 	exit=0
				// 	fac[k++]=this.searchResult.facilities[i]
				// }
				// if(is_outdoor_parking!='')
				// if(this.searchResult.facilities[i][is_outdoor_parking]==1)
				// {
				// 	exit=0
				// 	fac[k++]=this.searchResult.facilities[i]
				// }
				// if(is_oversized!='')
				// if(this.searchResult.facilities[i][is_oversized]==1)
				// {
				// 	exit=0
				// 	fac[k++]=this.searchResult.facilities[i]
				// }
				// if(is_tesla_charging!='')
				// if(this.searchResult.facilities[i][is_tesla_charging]==1)
				// {
				// 	exit=0
				// 	fac[k++]=this.searchResult.facilities[i]
				// }
				// if(is_generic_ev_charging!='') 
				// if(this.searchResult.facilities[i][is_generic_ev_charging]==1)
				// {
				// 	exit=0
				// 	fac[k++]=this.searchResult.facilities[i]
				// }

		}
		if(exit)
		{
			this.searchResult=JSON.parse(JSON.stringify(this.allfacility))
		}else{
			this.searchResult.facilities=fac;
		}
		this.firstLoad = true;
		this.googleMap.loadView();
		localStorage.setItem('facilityResponse','1')
		this.loadGoogleMapMarkers();
		this.preventUnusedAjax = true;
		this.searchCriteria.firstLoad = true;
	}
	// receiveNewSearchFilter($event) {
	// 	this.searchCriteria.length_of_stay = $event.length_of_stay;
	// 	//alert(this.searchCriteria.length_of_stay)
	// 	this.searchCriteria.currdate = $event.currdate;
	// 	//alert(3);
	// 	//this.fetchFacilityData(this.searchCriteria);
	// }

	receiveNewSearchFilter($event) {
		this.searchCriteria.length_of_stay = $event.length_of_stay;
		//alert(this.searchCriteria.length_of_stay)
		this.searchCriteria.currdate = $event.currdate;
		//alert(this.searchCriteria.currdate)
		//alert(3);
		if(sessionStorage.getItem('tabclick')) //ye kiya h
		{	
			this.fetchFacilityData(this.searchCriteria);
			sessionStorage.removeItem('tabclick')
		}
		//
	}
	receiveNewSearchFilterMobile($event)
	{
		this.searchCriteria.length_of_stay = $event.length_of_stay;
		this.searchCriteria.currdate = $event.currdate;
		this.searchCriteria.lat = $event.lat;
		this.searchCriteria.long = $event.long;
		this.searchCriteria.label = $event.label;
		this.fetchFacilityData(this.searchCriteria);
	}
	receiveNewSearchFilterOnSubmit($event) {
		//alert($event.length_of_stay);
		if(parseFloat($event.length_of_stay)<2 && this.searchCriteria.type == 'daily')
		{
			//his.toastr.error('stay time should be 2 hours or ore','Sorry'); 
			//this.toastr.error('In order to book a reservation, minimum booking time must be at least 2 hours', 'Error');
			this.toastr.error(FilterHelper.TWO_HOUR_ERROR_MESSAGE, FilterHelper.MESSAGE_TITLE);
			//return false;
		}
		this.searchCriteria.length_of_stay = $event.length_of_stay;
		this.searchCriteria.currdate = $event.currdate;
		//alert(3);
		this.fetchFacilityData(this.searchCriteria);
	}
	receiveMobileFilters($event) {
		//alert(3);
		this.searchCriteria = $event;
		this.fetchFacilityData(this.searchCriteria);
	}

	updateRecordsOnLabelChange(val) {
		SearchPageScript.markLabelActive(val);
		this.searchCriteria.type = val;

		if (val === 'daily') {
			this.getEndTime(this.endTime);
		} else {
			this.getMonthlyDate();
		}
    	//this.router.navigate(['/search', this.searchCriteria.lat, this.searchCriteria.long, this.searchCriteria.currdate, this.searchCriteria.radius, this.searchCriteria.label, val, CustomCode.searchMode()]);
		const searchType = $('[name="monthlytype"].active').attr("id");
	}

	loadGoogleMapMarkers() {
		this.googleMap.loadMarkers(this.searchResult);
	}

	setTime()
	{    
		if(this.searchCriteria.currdate.indexOf('-')>-1)
		{
			let time=this.searchCriteria.currdate.split('-') //12/14/2020 06:30:00   //2020,12,14 06:30:00
			let times=time[2].split(' ');
			let newTime=time[1]+'/'+times[0]+'/'+time[0]+' '+times[1]
			//alert(this.searchCriteria.currdate)
			$('#lenth_stay').val(this.searchCriteria.length_of_stay)
			$('#time_search').val(newTime)
			
		}else{
			$('#lenth_stay').val(this.searchCriteria.length_of_stay)
			$('#time_search').val(this.searchCriteria.currdate)
			//alert(this.searchCriteria.currdate)
		}	
		
	}
// for fix current location issue for length of stay
	setTimeNew()
	{
		if(this.searchCriteria.currdate.indexOf('-')>-1)
		{
			let time=this.searchCriteria.currdate.split('-') //12/14/2020 06:30:00   //2020,12,14 06:30:00
			let times=time[2].split(' ');
			let newTime=time[1]+'/'+times[0]+'/'+time[0]+' '+times[1]
			if(localStorage.getItem('cureentlocationsearch')){
			localStorage.removeItem('cureentlocationsearch')
			this.searchCriteria.length_of_stay=$('#lenth_stay').val()
		}else{
			$('#lenth_stay').val(this.searchCriteria.length_of_stay)
		}

			$('#time_search').val(newTime)
		}else{
			if(localStorage.getItem('cureentlocationsearch')) {
				localStorage.removeItem('cureentlocationsearch')
				this.searchCriteria.length_of_stay=$('#lenth_stay').val()
			}else{
				$('#lenth_stay').val(this.searchCriteria.length_of_stay)
			}
			//$('#lenth_stay').val(this.searchCriteria.length_of_stay)
			$('#time_search').val(this.searchCriteria.currdate)
		}	
		
	}

	fetchFacilityData(searchCriteria) {
	  let showNeighbourhoodTab=0;
	  if(localStorage.getItem('neighbourhoodActive'))
	  {
		showNeighbourhoodTab=1;
	  }
	  
	  localStorage.removeItem('seachLat')
      localStorage.removeItem('seachLong')
	  localStorage.removeItem('seachLocation')
	  localStorage.removeItem('startimehome')
	  //localStorage.removeItem('endttimehome')
		if(sessionStorage.getItem('openByClick'))
      {
		setTimeout(function(){
			sessionStorage.removeItem('openByClick')	
		},100)
      }else{
		if(this.alreadyReload==0)
		{	
			localStorage.removeItem('searchType');
			setTimeout(function(){
				localStorage.removeItem('searchbygoogleclickEvent')	
			},4000)
			this.alreadyReload=1;
			this.setTime();
			// $('#lenth_stay').val(this.searchCriteria.length_of_stay)
			// $('#time_search').val(this.searchCriteria.currdate)
			
			this.searchPageObj.getFacility(this.searchCriteria.lat, this.searchCriteria.long,1, this.searchCriteria.length_of_stay, this.searchCriteria.currdate)
			.subscribe(
				(data:any)=> {
					if(showNeighbourhoodTab)
					{
						setTimeout(() => {
							localStorage.setItem('neighbourhoodActiveDropDOwn','1')
						}, 1000);
					}
					$('#filter-drpp').addClass('hide');
					$('#filter-drps').removeClass('hide');
					this.firstLoad = true;
					this.allfacility =JSON.parse(JSON.stringify(data.data));
					this.searchResult = data.data;
					this.alreadyReload=0;
					let ischecked=localStorage.getItem('ischecked')
					if(ischecked=='1')
					{
						let is_generic_ev_charging:any=localStorage.getItem('is_generic_ev_charging')
						let is_indoor_parking:any=localStorage.getItem('is_indoor_parking')
						let is_monthlypark:any=localStorage.getItem('is_monthlypark')
						let is_motorcycle_parking:any=localStorage.getItem('is_motorcycle_parking')
						let is_outdoor_parking:any=localStorage.getItem('is_outdoor_parking')
						let is_tesla_charging:any=localStorage.getItem('is_tesla_charging')
						let is_oversized:any=localStorage.getItem('is_oversized')
						let is_twentyhour:any=localStorage.getItem('is_twentyhour')
						let monthly_campaign:any=localStorage.getItem('monthly_campaign')
						if(monthly_campaign=='true')
						{
							monthly_campaign=true;
						}else{
							monthly_campaign=false;
						}
						if(is_generic_ev_charging=='true')
						{
							is_generic_ev_charging=true;
						}else{
							is_generic_ev_charging=false;
						}
						if(is_indoor_parking=='true')
						{
							is_indoor_parking=true
						}else{
							is_indoor_parking=false
						}
						if(is_monthlypark=='true')
						{
							is_monthlypark=true
						}else{
							is_monthlypark=false;
						}
						if(is_motorcycle_parking=='true')
						{
							is_motorcycle_parking=true
						}else{
							is_motorcycle_parking=false;
						}
						if(is_outdoor_parking=='true')
						{
							is_outdoor_parking=true
						}else{
							is_outdoor_parking=false;
						}
						if(is_tesla_charging=='true')
						{
							is_tesla_charging=true
						}else{
							is_tesla_charging=false;
						}
						if(is_oversized=='true')
						{
							is_oversized=true
						}else{
							is_oversized=false;
						}
						if(is_twentyhour=='true')
						{
							is_twentyhour=true
						}else{
							is_twentyhour=false;
						}

						let event= {
						'is_generic_ev_charging':is_generic_ev_charging,
						'is_indoor_parking':is_indoor_parking,
						'is_monthlypark':is_monthlypark,
						'is_motorcycle_parking':is_motorcycle_parking,
						'is_outdoor_parking':is_outdoor_parking,
						'is_oversized':is_oversized,
						'is_tesla_charging':is_tesla_charging,
						'is_twentyhour':is_twentyhour,
						'monthly_campaign':monthly_campaign
					}
						this.receiveFacilityNewSearchFilter(event)
					}else{
						this.loadGoogleMapMarkers();
						this.preventUnusedAjax = true;
					    this.searchCriteria.firstLoad = true;
					}
					//alert('response aa gya')
					this.googleMap.loadView();
					localStorage.setItem('facilityResponse','1')
					
				},
				error => {

				}
			);
			
		}
      }
		
		
	}

	getMonthlyDate() {
		if (this.preventUnusedAjax === true) {
			if ($('#monthlydate').val().toString().trim() !== '') {
				const mD = $('#monthlydate').val().toString().split('/');
				const fDate = mD[2] + '-' + mD[0] + '-' + mD[1];
				this.searchCriteria.currdate = fDate;
				this.fetchFacilityData(this.searchCriteria);
			}
		}
	}

	getStartDate() {
		
	}

	getEndDate(event) {
	
	}

	updateEndTime() {
		let ssTime = this.sstartTime;
		let newsTime = new Date(ssTime);
		this.endTime = new Date(newsTime.setMinutes(newsTime.getMinutes() + 120));
		
		const stime = CustomCode.getUserTime(ssTime);

		const splitDate = stime.split(' ');
		const getHour = splitDate[0].split(':');
		const amPm = splitDate[1];
		if(amPm === 'PM' && parseInt(getHour[0]) >= 10) {
			const dateObj = new Date();
			const nDate = new Date(dateObj.setDate(dateObj.getDate() + 1));
			const newMonth = nDate.getMonth() + 1;
			$('#enddate').val(newMonth + '/' + nDate.getDate() + '/' + nDate.getFullYear());
			this.dailyEndDate = new Date(newMonth + '/' + nDate.getDate() + '/' + nDate.getFullYear());
		}
	}

	testFunc(run) {

	}

	getStartTime($event) {
		// let CurrentStartTime = new Date(this.sstartTime);
		// let sDate = new Date(this.dSD);
		// let sTime = new Date($event);
		// let eDate = new Date(this.dailyEndDate);
		// let eTime = new Date(this.endTime);

		// eTime.setDate(eDate.getDate());
		// eTime.setMonth(eDate.getMonth());
		// eTime.setFullYear(eDate.getFullYear());
	
		// sTime.setDate(sDate.getDate());
		// sTime.setMonth(sDate.getMonth());
		// sTime.setFullYear(sDate.getFullYear());
		// let cDate = new Date();
		// const updatedDate = new Date();
		// cDate.setDate(updatedDate.getDate());
		// cDate.setMonth(updatedDate.getMonth());
		// cDate.setFullYear(updatedDate.getFullYear());

		// if (sTime < this.sstartTime) {
		// 	setTimeout(() => {
		// 		this.sstartTime = CurrentStartTime;
		// 		this.toastr.error('In order to book a reservation, the date/time selected must be at least 30 minutes in the future', 'Error');
		// 	}, 50);
		// 	return false;
		// // } 

		// // let bookingHours = this.getLengthOfStayStartTime($event);
		// // if (bookingHours < 2) {
		// // 	setTimeout(() => {
		// // 		this.sstartTime = CurrentStartTime;
		// // 		this.toastr.error('In order to book a reservation, the date/time selected must be at least 30 minutes in the future', 'Error');
		// // 	}, 100);
		// // 	return;
		// } else {
		// 	if(sTime.getTime() < updatedDate.getTime()) {
		// 		this.searchCriteria.length_of_stay = 0;
		// 		$('#stime').val(CustomCode.getUserTime($event));
		// 		this.googleMap.updateSearchLengthOfStay(0);
		// 		this.toastr.error('In order to book a reservation, the date/time selected must be at least 30 minutes in the future', 'Error');
		// 	}
		// 	else if(eTime.getTime() > sTime.getTime()) {
		// 		this.sstartTime = $event;

		// 		const sTime = CustomCode.getUserTime(this.sstartTime);
		// 		this.updateEndTime();

		// 		$('#stime').val(CustomCode.getUserTime(this.sstartTime));
		// 		$('#pstime').val(CustomCode.getUserTime(this.sstartTime));

		// 		const sDate = $('#startdate').val().toString();
		// 		const eDate = $('#enddate').val().toString();
		// 		let facType = 'daily';
		// 		if (typeof $('label.active').attr('id') !== 'undefined') {
		// 			facType = $('label.active').attr('id').toLowerCase().trim();
		// 		}
		// 		this.stime = sTime;
		// 		if (facType == 'daily') {
		// 			const lengthOfStay = SearchPageScript.getLengthOfStay(sDate, this.stime, eDate, this.etime);
		// 			if (lengthOfStay > 0) {
		// 				const reservationTime = sDate.toString().split('/');
		// 				const m = reservationTime[1];
		// 				const d = reservationTime[0];
		// 				const y = reservationTime[2];
		// 				const rTime = y + '-' + d + '-' + m + '' + this.changeToFullTime(this.stime);
		// 				this.searchCriteria.currdate = rTime;
		// 				this.searchCriteria.length_of_stay = lengthOfStay;
		// 				if (this.firstLoad) {
		// 					this.fetchFacilityData(this.searchCriteria);
		// 				}
		// 			} else {
		// 				if (this.firstLoad) {
		// 					this.toastr.error('End time should be greater than start time', 'error');
		// 				}
		// 			}
		// 		}
		// 	}
		// }
	}

	// getLengthOfStayStartDate(event) {
	// 	let startTime:any = new Date(this.returnFormattedDate(event) + ' ' + this.returnFormattedTime(this.sstartTime));
	// 	let endTime:any = new Date(this.returnFormattedDate(this.dailyEndDate) + ' ' + this.returnFormattedTime(this.endTime));
	// 	const bookingHours:number = ((endTime - startTime) / 60000) / 60;
	// 	return bookingHours;
	// }

	// getLengthOfStayStartTime(event) {
	// 	let startTime:any = new Date(this.returnFormattedDate(this.dSD) + ' ' + this.returnFormattedTime(event));
	// 	let endTime:any = new Date(this.returnFormattedDate(this.dailyEndDate) + ' ' + this.returnFormattedTime(this.endTime));
	// 	const bookingHours:number = ((endTime - startTime) / 60000) / 60;
	// 	return bookingHours;
	// }

	// getLengthOfStayEndTime(event) {
	// 	let startTime:any = new Date(this.returnFormattedDate(this.dSD) + ' ' + this.returnFormattedTime(this.sstartTime));
	// 	let endTime:any = new Date(this.returnFormattedDate(this.dailyEndDate) + ' ' + this.returnFormattedTime(event));
	// 	const bookingHours:number = ((endTime - startTime) / 60000) / 60;
	// 	return bookingHours;
	// }

	// getLengthOfStayEndDate(event) {
	// 	let startTime:any = new Date(this.returnFormattedDate(this.dSD) + ' ' + this.returnFormattedTime(this.sstartTime));
	// 	let endTime:any = new Date(this.returnFormattedDate(event) + ' ' + this.returnFormattedTime(this.endTime));
	// 	const bookingHours:number = ((endTime - startTime) / 60000) / 60;
	// 	return bookingHours;
	// }

	// returnFormattedDate(date) {
	// 	let dateNew = date.toLocaleString('en-US', { hour12:false } ).split(" ");
	//     let mdy = dateNew[0];
	//     let mdy2 = mdy.split('/');
	//     let month = mdy2[0];
	//     let day = mdy2[1];
	//     let year = mdy2[2];
	//     let yearFix = year.split(',');
	//     let finalDate = month + '/' + day + '/' + yearFix[0];
	//     return finalDate;
	// }

	// returnFormattedTime(date) {
	// 	let dateNew = date.toLocaleString('en-US', { hour12:false } ).split(" ");
	// 	return dateNew[1];
	// }
	getEndTime($event) {
		// if (this.firstLoad) {
		// 	let tempEndTime = new Date(this.endTime);
		// 	let bookingHours = this.getLengthOfStayEndTime($event);
		// 	if (bookingHours < 2) {
		// 		setTimeout(() => {
		// 			this.endTime = new Date(tempEndTime);
		// 			this.toastr.error('In order to book a reservation, minimum booking time must be at least 2 hours', 'Error');
		// 		}, 100);
		// 		return;
		// 	}
		// 	let thisEndTime = new Date(this.endTime);
		// 	this.endTime = $event;	
		// 	const sDate = $('#startdate').val();
		// 	const eDate = $('#enddate').val();

		// 	const tTime = CustomCode.getUserTime(this.endTime);

		// 	$('#etime').val(tTime);
		// 	$('#petime').val(tTime);

		// 	let facType = 'daily';
		// 	if (typeof $('label.active').attr('id') !== 'undefined') {
		// 		facType = $('label.active').attr('id').toLowerCase().trim();
		// 	}
		// 	this.etime = tTime;
		// 	if (facType == 'daily') {
		// 		const lengthOfStay = SearchPageScript.getLengthOfStay(sDate, this.stime, eDate, this.etime);
		// 		if (lengthOfStay > 0) {
		// 			if (lengthOfStay < 2) {
		// 				this.endTime = new Date(thisEndTime);
		// 				return;
		// 			} else {
		// 				const reservationTime = sDate.toString().split('/');
		// 				const m = reservationTime[1];
		// 				const d = reservationTime[0];
		// 				const y = reservationTime[2];
		// 				const rTime = y + '-' + d + '-' + m + '' + this.changeToFullTime(this.stime);
		// 				this.searchCriteria.currdate = rTime;
		// 				this.searchCriteria.length_of_stay = lengthOfStay;
		// 				if (this.firstLoad) {
		// 					this.fetchFacilityData(this.searchCriteria);
		// 				}
		// 			}
		// 		} else {
		// 			this.toastr.error('End time should be greater than start time', 'error');
		// 		}
		// 	}
		// }
	}

	changeToFullTime(time) {
		const date = new Date();
		let hours;
		let minutes;
		let am_pm;
		const newTime = time.split(':');
		hours = newTime[0];
		const newT = newTime[1].split(' ');
		minutes = newT[0];
		am_pm = newT[1];
		if (am_pm == 'PM') {
			const convertToFullDate = ['12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '12'];
			hours = convertToFullDate[hours];
		}
		let returnVal = hours + ':' + minutes + ':00';
		returnVal.split('â').join('');
		return returnVal;
	}

	tempAlert(data) {
		
	}

	findDatas()
	{
		this.searchItem.searchDate();
	}

	// receiveNewFiltersdrag($event)
	// {
	// 	this.searchCriteria = $event;
	// 	//alert(2);
	// 	let type='daily';
	// 	let p=this.router.url.split('/')
	// 	if(this.router.url.indexOf('monthly')>-1)
	// 	{
	// 		type='monthly'
	// 	}else{
	// 		type='daily'
	// 	}
	// 	//this.searchCriteria.currdate
	// 	this.router.navigate(['/search', this.searchCriteria.lat, this.searchCriteria.long, p[4], 2, 'unknown-location',type,CustomCode.searchMode()]);
	// 	//this.mobileStartTime=this.searchCriteria.mobStarttime
	// 	this.fetchFacilityData(this.searchCriteria);
	// }

	receiveNewFiltersdrag($event)
	{	//alert(4)
		this.searchCriteria = $event;
		let type='daily';
		let p=this.router.url.split('/')
		if(this.router.url.indexOf('monthly')>-1)
		{
			type='monthly'
		}else{
			type='daily'
		}
		if(this.router.url.indexOf('monthly-parking')>-1)
		{
		}else{
			//this.location.replaceState('/search/'+this.searchCriteria.lat+'/'+this.searchCriteria.long+'/'+p[4]+'/2/new-york/'+type+'/'+CustomCode.searchMode()); 
		 	//this.router.navigate(['/search', this.searchCriteria.lat, this.searchCriteria.long, p[4], 2, 'new-york',type,CustomCode.searchMode()]);
		}
		//this.searchCriteria.currdate
		
		//this.mobileStartTime=this.searchCriteria.mobStarttime
		this.fetchFacilityData(this.searchCriteria);
	}
	
}

