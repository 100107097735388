<div class="campaignPage pad-top-lg winter-monthly-landing">
	<div class="container"> 
		<div class="row">
			<div class="col-xs-12 col-sm-12 col-md-8 col-lg-8 col-md-offset-2">
				<div class="text-center">
					<h3 class="text-uppercase text-white mar-top-0">Winter Special</h3>
					<h2 class="text-uppercase text-primary mar-top-0 customesize">Monthly Rates as low as <span sourceFont>$</span>168 plus tax.</h2>
					<h2 class="text-uppercase text-primary mar-top-0 customesize">12 month rate guarantees available.</h2>
					<h3 class="text-uppercase text-white mar-top-0">100 nyc locations to choose from</h3>
					<h3 class="text-uppercase text-white mar-top-sm">
						Contact us now to reserve your monthly spot.<br/>
						mention this promotion to redeem.
					</h3>
					<div class="clearfix mar-top-md mar-bottom-md support-section">
						<div class="row">
							<div class="col-sm-6 text-right">
								<a [href]="phoneNumber" class="mail-btn" (click)="callMonthlyCustomer()">
									<img [src]="phoneIcon" alt="Image" width="50">
									<span class="mar-left-xs">
										Call 1 (888) 705-7845<img class="mar-left-xs" [src]="back_chevron_white" alt=" ">
										<small>9am to 7pm</small>
									</span>
								</a>
								<p class="visible-xs pad-0 mar-0"></p>
							</div>
							<div class="col-sm-6 text-left">
								<a routerLink="/monthly-parking-information" class="mail-btn">
									<img [src]="mailIcon" alt="Image" width="50">
									<span class="mar-left-xs">
										Request Information<img class="mar-left-xs" [src]="back_chevron_white" alt="image">
									</span>
								</a>
							</div>
						</div>
					</div>
					<div class="clearfix mar-tom-md ">
						<small class=" text-white">Promotional offer is valid thru 4/30/18. Rate does not include any applicable tax. Offer valid for new customers only.</small>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<div class="pad-bottom-md pad-top-md text-center text-uppercase">
	<div class="container">
		<h3 class="mar-top-0 mar-bottom-sm">park monthly with icon and get special privileges</h3>
		<div class="row">
			<div class="col-xs-12 col-sm-12 col-md-8 col-lg-8 col-md-offset-2">
				<div class="row mar-bottom-sm">
					<div class="col-xs-12 col-sm-4 col-md-4 col-lg-4">
						<img [src]="arrowsIcon" alt="image" width="50" height="50">
						<h4>Unlimited in/out privileges</h4>
						<p class="visible-xs pad-bottom-xs"></p>
					</div>
					<div class="col-xs-12 col-sm-4 col-md-4 col-lg-4 mar-bottom-xs">
						<img [src]="map_markersIcon" alt="image" width="50" height="50">
						<h4>50% off daily parking</h4>
						<p class="visible-xs pad-bottom-xs"></p>
					</div>
					<div class="col-xs-12 col-sm-4 col-md-4 col-lg-4 mar-bottom-xs">
						<img [src]="vehicleIcon" alt="image" width="50" height="50">
						<h4>Vehicle request service</h4>
					</div>
				</div>
				<div class="text-center">
					<a routerLink="/monthly-parking" class="btn btn-primary theme-btn">learn more about monthly parking</a>
				</div>
			</div>
		</div>
	</div>
</div>