
<div class="clearfix"></div>


<!-- Google Map Starts Here -->
<a floatingLocationBtn class="btn " [class.hidden-xs]="sidebarOn || showLoader || sidebarOn1" (click)="centerLocation()" [class.partnerLocation]="partnerLocationOn">
    <img [src]="locationTrack">
</a>
<a floatingBtnBackParkList [class.daily]="searchCriteria.type=='daily'" [class.monthly]="searchCriteria.type=='monthly'" [class.hidden]="!parkList || hideListView || !(sidebarOn1 || sidebarOn)" *showItBootstrap="['xs']" class="btn" (click)="back(1);">
   <img [src]="mapListIcon" height="25px">
</a>
<!-- <a floatingBtnBackParkList   *ngIf="activeNeighborhoodFilter"  class="btn visible-xs" (click)="back(1);">
    <img [src]="mapListIcon" height="25px">
</a> -->


  
<div class="accordianBlock visible-xs floatColBlock p-3" id="accordianBlock" style="z-index: 3;"  *ngIf="searchCriteria.type=='daily' && sidebarOn && parkList ">
    <accordion [closeOthers]="oneAtATime" >
        <accordion-group heading="{{CMSsidebarContent?.your_convenient_nyc_title}}">
            {{CMSsidebarContent?.your_convenient_nyc_content}}
            <!-- Whether you’re looking for Bronx monthly parking or monthly parking in Manhattan, Icon Parking has you covered. Our parking facilities are located throughout New York City, providing New York drivers with the ability to park in any one of our safe and secure parking areas. In collaboration with Quik Park, Icon Parking offers over 250 parking facilities in the city so you can feel confident you can find a parking space when you need it. -->
        </accordion-group>
        <accordion-group heading="{{CMSsidebarContent?.why_choose_icon_parking_title}}">
            {{CMSsidebarContent?.why_choose_icon_parking_content}}
          <!-- <p>Content 1</p> -->
        </accordion-group>
      
      </accordion>


      <!--  boxx no 2 -->
      
    </div>
    <div class="accordianBlock visible-xs floatColBlock p-3 " style="z-index: 3;" *ngIf="searchCriteria.type=='monthly' && sidebarOn && parkList ">
        <accordion [closeOthers]="oneAtATime" >
            <accordion-group heading="{{CMSsidebarContent?.your_convenient_nyc_title}}">
                {{CMSsidebarContent?.your_convenient_nyc_content}}
                <!-- Whether you’re looking for Bronx monthly parking or monthly parking in Manhattan, Icon Parking has you covered. Our parking facilities are located throughout New York City, providing New York drivers with the ability to park in any one of our safe and secure parking areas. In collaboration with Quik Park, Icon Parking offers over 250 parking facilities in the city so you can feel confident you can find a parking space when you need it. -->
            </accordion-group>
            <accordion-group heading="{{CMSsidebarContent?.why_choose_icon_parking_title}}">
              {{CMSsidebarContent?.why_choose_icon_parking_content}}
            <!-- <p>Content 1</p> -->
          </accordion-group>
          
          </accordion>
    
    
          <!--  boxx no 2 -->
          
        </div>

        <div class="accordianBlock  visible-xs floatColBlock p-3 " style="z-index: 3;"  *ngIf="!parkDetail && activeNeighborhoodFilter && (sidebarOn || sidebarOn1)">
            <accordion [closeOthers]="oneAtATime" *ngIf="!parkDetail" >
                <accordion-group heading="{{CMSsidebarContent?.your_convenient_nyc_title}}">
                    {{CMSsidebarContent?.your_convenient_nyc_content}}
                    <!-- Whether you’re looking for Bronx monthly parking or monthly parking in Manhattan, Icon Parking has you covered. Our parking facilities are located throughout New York City, providing New York drivers with the ability to park in any one of our safe and secure parking areas. In collaboration with Quik Park, Icon Parking offers over 250 parking facilities in the city so you can feel confident you can find a parking space when you need it. -->
                </accordion-group>
              </accordion>
    
    
              <!--  boxx no 2 -->
              
            </div>


<div class="row no-gutters overflowBox" id="mainWrapper" style=" position: relative; z-index: 2; height:100%;" [class.z-doown]="parkDetail" [class.partnerZindex]="partnerZindexOn">
  
    <div id="sidebar" class="col-sm-4 tempFix"  [class.partnerPageFix]="partnerPageOn" style="max-width: width 405px;"  [ngClass]="{'active':sidebarOn1,'parkDetail':parkDetail, 'topSlide':!parkList}">
         
        <article class="hidden-xs" *ngIf="!activeLoading && !availabilityCheck && ((FacilityAvailableFetatures?.category_id == earlyBirdSpecialCategory || FacilityAvailableFetatures?.coupon_code != '') && searchCriteria.type == 'daily'  && FacilityAvailableFetatures?.ticketech_id != '')"
                flagWrapper [style.top]="yscroll" [ngClass]="{'flagIos':isIphone,'flagAndroid':isAndroid }" [class.hidden]="!EarlyBrid">
                <div class=" " flagSearchPage *ngIf="(FacilityAvailableFetatures?.category_id == earlyBirdSpecialCategory || FacilityAvailableFetatures?.coupon_code != '') && searchCriteria.type == 'daily' && FacilityAvailableFetatures?.ticketech_id != ''">
                    <div class="erlybirdDate ">
                        <h3 class="text-white text-uppercase">Web special</h3>
                        <p *ngIf="isPartners == true && (FacilityAvailableFetatures?.category_id == earlyBirdSpecialCategory || FacilityAvailableFetatures?.coupon_code != '')">Paid Reservation Required.</p>
                        <p *ngIf="isPartners != true && (FacilityAvailableFetatures?.category_id == earlyBirdSpecialCategory || FacilityAvailableFetatures?.coupon_code != '')">Paid Reservation Required.</p>
                        <a href="javascript:void(0)" class="btn btn-link btn-xs" (click)="modalClose()"><i class="fa fa-times"></i> Dismiss</a>
                        <p class="text"></p>
                    </div>
                </div>
            </article>
        <aside class="contentBar desktopView "  >
            <!-- <div class="sidebarWidget visible-xs " *ngIf="!parkList" [ngClass]="{'mar-top-iphone':isIphone, 'mar-top-android':isAndroid}"></div> -->
            <!-- <div sidebarHeadBar class=" clearfix visible-xs" *ngIf="!parkList">
                <h4 class="modal-title pull-left"><img width="90" src="assets/img/icon_logo-dark.svg" alt="image"></h4>
                <button type="button" class="close pull-right" (click)="back()">
              <img [src]="crossSvg" alt="image" width="20" style="margin-top: 10px;">
            </button>
            </div> -->
        
            <!--  for tablet and desktop -->
        
           
        
            <!--  for cloasing the sidebar -->
            <!-- <a href="javascript:;" class="btn text-white  btn-sm btn-fill floatClose " (click)="updateIsActiveFacility()" >
                                <fa name="times "></fa>
                    </a> -->
            <!-- <div *ngIf="searchCriteria.type == 'monthly'"  class="month-cross hidden-xs"></div> -->
            <!-- <a href="javascript:;" class="btn text-white  btn-sm btn-fill floatClose hidden-xs " (click)="back()">
                <fa name="times "></fa>
            </a> -->
            
            <!--  for flaoting buttons -->
            
            <div btnGroupMobile class="book-btns-wrapper btn-group btn-group-justified hidden" *ngIf="parkDetail" [ngClass]="{'floatAndroid':isAndroid, 'floatIphone':isIphone}">
        
                <a class="btn btn-primary btn-fill tagRibbon" id="dailymonthlybutton" *ngIf="isPartners === false && !activeLoading && !getUpdatedPrice && !availabilityCheck && ( allParkings.price!='N/A' ) && (allParkings.ticketech_id != '' || ( FacilityAvailableFetatures?.parkonect_id != '' && FacilityAvailableFetatures?.parkonect_id != null ) || (FacilityAvailableFetatures?.valet_manager_id != '' && FacilityAvailableFetatures?.valet_manager_id != null) ) && searchCriteria.type == 'daily'"
                    (click)="buyDailyMonthly();">BOOK NOW <small>spot guaranteed</small> <span *ngIf="FacilityAvailableFetatures?.paynow_promocode_discount" class="tagoffer">Save {{FacilityAvailableFetatures?.paynow_promocode_discount}} </span></a>
                <a class="btn btn-primary btn-fill tagRibbon" [ngClass]="{'singleButton':couponShow==0}" id="dailymonthlybutton" *ngIf="isPartners === false && !activeLoading && getUpdatedPrice && !availabilityCheck && (FacilityAvailableFetatures?.price!='N/A' ) && (allParkings.ticketech_id != '' || ( FacilityAvailableFetatures?.parkonect_id != '' && FacilityAvailableFetatures?.parkonect_id != null ) || (FacilityAvailableFetatures?.valet_manager_id != '' && FacilityAvailableFetatures?.valet_manager_id != null) ) && searchCriteria.type == 'daily'"
                    (click)="buyDailyMonthly()">BOOK NOW  {{ checkBookShow() }} <small>spot guaranteed</small> <span *ngIf="FacilityAvailableFetatures?.paynow_promocode_discount" class="tagoffer">Save {{FacilityAvailableFetatures?.paynow_promocode_discount}} </span></a>
                <a class="btn mobile-disable-price btn-fill custom-padding" *ngIf="isPartners === false && FacilityAvailableFetatures?.price_without_availibility !='N/A' && availabilityCheck && !activeLoading && searchCriteria.type == 'daily'" id="dailymonthlybutton">BOOK NOW </a>
        
                <a  [class.disabled]="getCouponDisabled" [ngClass]="{'singleButton':bookShow==0}" class="btn btn-primary btn-sm btnCoupon theme-btn text-left searchCoupon" id="checkcoupon" (click)="manageCoupons();" *ngIf="showcoupon && isPartners === false && allParkings.rate_id && allParkings.rate_type_id == 2 &&  searchCriteria.type == 'daily' || allParkings.rate_type_id == 4 && searchCriteria.type == 'monthly'">Pay At Garage {{ checkCouponShow() }}<small>Spot not guaranteed</small></a>
                <a  [class.disabled]="getCouponDisabled" class="btn btn-primary btn-sm btnCoupon theme-btn text-center partnerCoupon" id="checkcoupon" (click)="manageCoupons();" *ngIf="showcouponp && isPartners">Pay At Garage  <small >Spot not guaranteed</small></a>
                <!-- hide coupon functionality -->
                <a class="btn btn-primary btn-fill btn-block " id="dailymonthlybutton" (click)="buyDailyMonthly()" *ngIf="baserate!='N/A' && searchCriteria.type == 'monthly'">BOOK NOW</a>
        
            </div>
        
            <!-- Garage Detail Page Web -->
            <div id="parkDetail" class="webSection orangeBar "  *ngIf="parkDetail" [ngClass]="{'scrolldevice':isIphone || isAndroid && allParkings.price!='N/A' }">
        
                <div id="parkDetailsWrapper1" [class.iOS]="isIphone" class="parkDetailsWrapper" (scroll)="getPositionBanner()" > 
                    <a href="javascript:;" (click)="back(2);showSideBar();" class="btn btn-link p-0 text-dark mb-3"> <span class="text-primary fa fa-angle-left return-l-icon"></span> &nbsp; &nbsp;Return to Listing</a>
                <div *ngIf="searchCriteria.type == 'monthly'" class="booknow-mont-serch monthly-Details booknow-top text-right">
                    <img width="100%" src="assets/img/search-monthlybook.png"></div>
                <div class="parkImg">
                    
                    <img [src]="imgSrcLocation" [alt]="imgSrcAlt" class='full-width ' height='320'>
                    <!-- <span *ngIf="isUpdatedEndTimeFlag" [popover]="MTSPTemplate" container="body" [outsideClick]="true" containerClass="more-time-same-price-box" placement="bottom" class="label more-space-reservation-web hidden-xs">More Time Same Price</span> -->
                </div>
                <!--   flag for coupon discount -->
                <div class="clearfix">
        
                </div>
                <!--  main data wrapper -->
                <div class="contentWrap">
        
                    <!--  parking name and address -->
                    <div class="clearfix panel-body">
                    
                    
                        <article class="maping_roll" [class.hidden]="!EarlyBrid">

                            <article class="visible-xs" *ngIf="!activeLoading && !availabilityCheck && ((FacilityAvailableFetatures?.category_id == earlyBirdSpecialCategory || FacilityAvailableFetatures?.coupon_code != '') && searchCriteria.type == 'daily'  && FacilityAvailableFetatures?.ticketech_id != '')"
                                flagWrapper [ngClass]="{'flagIos':isIphone,'flagAndroid':isAndroid }" [class.hidden]="!EarlyBrid">
                                <div class=" " flagSearchPage *ngIf="(FacilityAvailableFetatures?.category_id == earlyBirdSpecialCategory || FacilityAvailableFetatures?.coupon_code != '') && searchCriteria.type == 'daily' && FacilityAvailableFetatures?.ticketech_id != ''">
                                    <div class="erlybirdDate ">
                                        <h3 class="text-white text-uppercase">Web special</h3>
                                        <p *ngIf="isPartners == true && (FacilityAvailableFetatures?.category_id == earlyBirdSpecialCategory || FacilityAvailableFetatures?.coupon_code != '')">Paid Reservation Required.</p>
                                        <p *ngIf="isPartners != true && (FacilityAvailableFetatures?.category_id == earlyBirdSpecialCategory || FacilityAvailableFetatures?.coupon_code != '')">Paid Reservation Required.</p>

                                        <a href="javascript:void(0)" class="btn btn-link btn-xs" (click)="modalClose()"><i class="fa fa-times"></i>
                          Dismiss</a>
                                        <p class="text"></p>
                                    </div>
                                </div>
        
        
                            </article>
                          
                        </article>
                        <div class="row no-gutters">
                            <div class="col">
                                <div class="customeFontSetup mt-2">
                                <h3 class=" text-uppercase mar-bottom-xs font-weight-bold mar-top-0" id="parkfull_name" parkTitle>
                                    {{allParkings?.geolocation?.address_1}}
                                </h3>
                            
                                <!-- <span><b>Garage Name :</b> {{allParkings?.facility_name_modified}}</span> -->
                                <table class="custm-table"><tr><td><span><b>Garage Name:&nbsp;</b></span></td><td><span>{{allParkings?.facility_name_modified}}</span></td></tr></table>
                            </div>
                                <!-- <div class="clearfix mar-top-xs" *ngIf="searchCriteria.type == 'monthly' && searchCriteria.device == 'app'">
                                    <a [href]="monthlyParking" class="btn-dark "><b>More Info <fa name="angle-right"></fa></b></a>
                                </div> -->
                                <!--  content hide for mobile view Monthly Page -->
                                <div>
                                    <!-- <p class="mar-bottom-xs typeBullets customeFontSetup">
                                        <span><b>Between:</b> {{ allParkings?.between_streets }}</span>
                                        <br />
                                        <span><b>Entrance:</b> {{allParkings.entrance_location}}</span>
                                    </p> -->
                                    <table class="mar-bottom-xs typeBullets customeFontSetup custm-table">
                                      <tr *ngIf="allParkings?.between_streets"><td><span><b>Between:&nbsp;</b></span></td><td><span>{{ allParkings?.between_streets }}</span></td></tr>
                                      <tr *ngIf="allParkings?.entrance_location"><td><span><b>Entrance:&nbsp;</b></span></td><td><span>{{allParkings.entrance_location}}</span></td></tr>
                                    </table>
                                    <p class="" id="between_entrance"></p>
                                    <div class="row ">
                                        <div class="col-auto ">
                                            <img [src]="map_market_location_d" height="13" style="margin-top:-4px">&nbsp; <span id="locDistance">{{allParkings.distance |
                            distanceconversion}}</span>
                                            <br class="visible-xs">
                                        </div>
                                        <div class="col-auto" *ngIf="allParkings?.phone_number">
                                          <img [src]="phone_icon_d" height="11" style="margin-top:-2px">&nbsp; <a id="garage_phone" style="color: #000;" href="tel:{{formatPhoneNumber(allParkings?.phone_number)}}">{{formatPhoneNumber(allParkings?.phone_number)}}</a>
        
                                        </div>
                                    </div>
        
                                    
                                  
                                </div>
                            </div>
                            <!-- address and rate data section -->
                            <div class="col-auto text-right "> 



                                <div  *ngIf="searchCriteria.type == 'daily'">
                                    <!--  wrapper div -->
                                                <div [class.hidden]="!EarlyBrid" id="webSpecialButton" *ngIf="!activeLoading && !availabilityCheck && ((FacilityAvailableFetatures?.category_id == earlyBirdSpecialCategory || FacilityAvailableFetatures?.coupon_code != '') && searchCriteria.type == 'daily'  && FacilityAvailableFetatures?.ticketech_id != '')">
                                    <!-- <ng-template #popTemplate    >
                                        <p *ngIf="isPartners == true && (FacilityAvailableFetatures?.category_id == earlyBirdSpecialCategory || FacilityAvailableFetatures?.coupon_code != '')">Paid Reservation Required.</p>
                                        <p *ngIf="isPartners != true && (FacilityAvailableFetatures?.category_id == earlyBirdSpecialCategory || FacilityAvailableFetatures?.coupon_code != '')">Paid Reservation Required.</p>
        
                                        <a href="javascript:void(0)" class="btn btn-link btn-xs p-0" (click)="modalClose()"><i class="fa fa-times"></i>
                          Dismiss</a>
                                       
                                
                                </ng-template> -->
                                
                                     <!-- <span class="hidden-xs" *ngIf="(FacilityAvailableFetatures?.category_id == earlyBirdSpecialCategory || FacilityAvailableFetatures?.coupon_code != '') && searchCriteria.type == 'daily' && FacilityAvailableFetatures?.ticketech_id != ''"  popoverTitle="Web special"
                                          triggers=""placement="right"  [popover]="popTemplate"  containerClass="webSpeicalWrap" container="body" [isOpen]="isOpen" >
                                   
                                    </span> -->

                                    <!-- <article class="hidden-xs" *ngIf="!activeLoading && !availabilityCheck && ((FacilityAvailableFetatures?.category_id == earlyBirdSpecialCategory || FacilityAvailableFetatures?.coupon_code != '') && searchCriteria.type == 'daily'  && FacilityAvailableFetatures?.ticketech_id != '')"
    flagWrapper [style.top]="yscroll" [ngClass]="{'flagIos':isIphone,'flagAndroid':isAndroid }" [class.hidden]="!EarlyBrid">
    <div class=" text-uppercase " flagSearchPage *ngIf="(FacilityAvailableFetatures?.category_id == earlyBirdSpecialCategory || FacilityAvailableFetatures?.coupon_code != '') && searchCriteria.type == 'daily' && FacilityAvailableFetatures?.ticketech_id != ''">
      <div class="erlybirdDate ">
        <h3 class="text-white">Web special.</h3>
        <p *ngIf="FacilityAvailableFetatures?.category_id == earlyBirdSpecialCategory || (FacilityAvailableFetatures?.category_id == earlyBirdSpecialCategory && FacilityAvailableFetatures?.coupon_code != '')">prepaid
          reservation required.</p>
        <p *ngIf="FacilityAvailableFetatures?.coupon_code != ''">Prepaid reservation or coupon required.</p><a href="javascript:void(0)"
          class="btn btn-link btn-xs" (click)="modalClose()"><i class="fa fa-times"></i> Dismiss</a>
        <p class="text"></p>
      </div>
    </div>
  </article> -->
                                    
                                    

                                </div>
                                    <!-- wrapper ends -->

                                
                           

                               
                               
                                <button class="btnEarlybirdWeb p-0" (click)="btnEarlyBrid()" *ngIf="!activeLoading && !availabilityCheck && ((FacilityAvailableFetatures?.category_id == earlyBirdSpecialCategory || FacilityAvailableFetatures?.coupon_code != '') && searchCriteria.type == 'daily' && FacilityAvailableFetatures?.ticketech_id != '')"><i class="fa fa-2x fa-info-circle"></i></button>
                                
                            </div>
                                <small *ngIf="searchCriteria.type=='daily'">Rates from</small>
                                <h3 class="font-weight-bold m-0 facilityRates" >
                                    <!-- base rate -->
                                    <div *ngIf="searchCriteria.type == 'daily'">
                                        <a *ngIf="activeLoading">
                                            <fa animation="spin" name="circle-o-notch">
                                                <i aria-hidden="true" class="fa fa-circle-o-notch fa-spin"></i>
                                            </fa>
                                        </a>
                                        <ng-container *ngIf="(allParkings.ticketech_id == '' && (FacilityAvailableFetatures?.parkonect_id =='' || FacilityAvailableFetatures?.parkonect_id ==null ) && (FacilityAvailableFetatures?.valet_manager_id == '' || FacilityAvailableFetatures?.valet_manager_id == null) )">
                                       
                                            <span *ngIf="!activeLoading && checkupdatedPrice && !availabilityCheck">
                                                    <span *ngIf="FacilityAvailableFetatures?.price" id="base_rate" baseRate >{{changeRateUpdated(FacilityAvailableFetatures?.price)}} </span>
                                            </span>
                                            <span *ngIf="!checkupdatedPrice && !activeLoading">
                                                    <span id="base_rate" baseRate > {{checkFormat(baserate)}}</span>
                                            </span>
                                            <span *ngIf="!activeLoading && availabilityCheck"> {{changeRateUpdated(FacilityAvailableFetatures?.price_without_availibility)}}</span>
                                        </ng-container>
                                        <ng-container *ngIf="!(allParkings.ticketech_id == '' && (FacilityAvailableFetatures?.parkonect_id =='' || FacilityAvailableFetatures?.parkonect_id ==null ) && (FacilityAvailableFetatures?.valet_manager_id == '' || FacilityAvailableFetatures?.valet_manager_id == null) )">
                                         
                                            <span *ngIf="!activeLoading && checkupdatedPrice && !availabilityCheck">
                                                      <span *ngIf="FacilityAvailableFetatures?.price" id="base_rate" baseRate >{{changeRateUpdated(FacilityAvailableFetatures?.price)}} </span>
                                            </span>
                                            <span *ngIf="!checkupdatedPrice && !activeLoading">
                                                      <span id="base_rate" baseRate > {{checkFormat(baserate)}}</span>
                                            </span>
                                            <span *ngIf="!activeLoading && availabilityCheck"> {{changeRateUpdated(FacilityAvailableFetatures?.price_without_availibility)}}</span>
                                        </ng-container>
                                    </div>
                                    <!-- <span *ngIf="searchCriteria.type == 'monthly'" id="base_rate" baseRate> {{checkFormat(baserate)}} </span> -->
                                    <!-- flat trigger -->
                                 
                                    <!-- <small class="pmn" *ngIf="searchCriteria.type == 'monthly'" >Per month</small> -->
                              
                                  
                                </h3>
                                <!-- date and time display div -->
                         
                                <!-- <ng-template  #MTSPTemplate>
                                    <div class="more-time-same-price-box" [innerHtml]="MTSMMessage"></div>
                                </ng-template> -->
                                <!-- <span *ngIf="isUpdatedEndTimeFlag && searchCriteria.type == 'daily'" [popover]="MTSPTemplate" container="body" [outsideClick]="true" containerClass="more-time-same-price-box mob-mts-rt" placement="bottom" class="label mar-bottom-xs label-primary more-space-reservation">More Time Same Price</span> -->
                                <!-- for desktop view -->
                                <div class="clearfix"></div>
                                <!-- <div *ngIf="searchCriteria.type == 'daily' && (allParkings.ticketech_id != '' || (FacilityAvailableFetatures?.parkonect_id != '' && FacilityAvailableFetatures?.parkonect_id != null))">
                                    <article class="floatRateEngine search-alert-bx" *ngIf="getUpdatedPrice && !enableDefaultMsg && !activeLoading">
                                        {{FacilityAvailableFetatures?.coupon_price_applied}}
                                        <a class="btn btn-xs btn-link" (click)="openModal(rateEngine)"> <img src="assets/img/rateengileicon.svg" alt="Image"></a>
                                    </article>
                                 </div> -->
                              
                                
                                <!--  coupon trigger block -->
                                <button style="display: none;" id="triggercoupon" type="button" class="btn btn-primary" (click)="lgModal.show()"></button>
                                <button style="display: none;" id="triggercoupon1" type="button" class="btn btn-primary" (click)="lgModal1.show()"></button>
                                <!-- <small id="couponcontent" class="spsutoava text-center" *ngIf="allParkings.rate_id && allParkings.rate_type_id == 2 && searchCriteria.type == 'daily' || allParkings.rate_type_id == 4 && searchCriteria.type == 'daily'" ></small> -->
                            </div>
                        </div>
                        <div class="row no-gutters">
                           <div class="col">
                            <p class="dailystyles mb-4 mb-sm-0 mt-4  dailystyles-mo">
                                <a class="btn btn-link py-0 px-0 mr-1" (click)="getdirections.show()">Get Directions </a>  
                                <a *ngIf="searchCriteria.type == 'daily'" style="cursor: pointer;" (click)="openModal(postedfacilityrats)" class="btn btn-link py-0 px-0 mr-1">See Posted Rates</a>
                                <a style="cursor: pointer;" (click)="toViewScroll()" class="btn hidden-xs btn-link py-2 px-0">Hours of Operations</a>
                                <a style="cursor: pointer; vertical-align: middle;"  *xs href="javascript:;" (click)="scrollToId('elId')" >Hours of Operations</a>


                             </p>

                         
                            <p *ngIf="searchCriteria.type == 'daily' && !activeLoading && (allParkings.ticketech_id == '' && ( FacilityAvailableFetatures?.parkonect_id == '' || FacilityAvailableFetatures?.parkonect_id == null ) && (FacilityAvailableFetatures?.valet_manager_id == '' || FacilityAvailableFetatures?.valet_manager_id == null) )" class="text-uppercase mt-2">
                                <strong [ngClass]="{ 'text-danger' : 1 }">This facility is not available for reservations</strong>
                            </p>
                            <!--  for details page alternaative garages -->
                            
                            <p *ngIf="allParkings.is_24hour_open == '0' && allParkings?.is_alternative_facility> 0 && searchCriteria.type == 'monthly'" >*Please be advised of amended hours at this location.<br class="hidden-xs"> <a target="_blank" [href]="temporarilyClosedUrl">Click Here</a> for alternative garage information*</p>
                            <p *hideItBootstrap="['xs']">
&nbsp;
                            </p>
                            <div id="trustboxOpen" *ngIf="isPartners === false && showRating" class="trustpilot-widget trustpilotSidebar " data-locale="en-US" data-template-id="53aa8807dec7e10d38f59f32" data-businessunit-id="5feb84d1e2aa300001625337" data-style-height="110px" data-style-width="240px" data-theme="light" [attr.data-location]="FacilityAvailableFetatures?.facility.garage_code">
                                <a href="https://www.trustpilot.com/review/iconparkingsystems.com" target="_blank" style="display: none;" rel="noopener">Trustpilot</a>
                            </div>
                            <div id="PartnerTrustboxOpen" *ngIf="isPartners && showRatingPartner" class="trustpilot-widget trustpilotSidebar " data-locale="en-US" data-template-id="53aa8807dec7e10d38f59f32" data-businessunit-id="5feb84d1e2aa300001625337" data-style-height="110px" data-style-width="240px" data-theme="light" [attr.data-location]="partnerGarageCode">
                                <a href="https://www.trustpilot.com/review/iconparkingsystems.com" target="_blank" style="display: none;" rel="noopener">Trustpilot</a>
                            </div>
                           </div>
                        </div>
        
                        <div class="clearfix border-top monthlyRatesSection" *ngIf="baserate!='N/A' && searchCriteria.type == 'monthly'">
                            <div *ngIf="is_monthly_campaign" class="specialPriceBadgeTop">SPECIAL PRICE</div>
                            <div class="row">
                                <div class="col">
                                <h4 class="mb-0 mt-3 font-weight-bold"> {{selectedvahicletypeText2 }}  <img (click)="openModal(monthlyRateDesc, allParkings.facility_id)" src="/assets/img/help.svg" alt="image" />  </h4>
                                 
                                 <p><b>Starting {{displayStartDateMonthly()}} </b></p>                                       
                                                                        
                                                                
                                </div>

                                
                            </div>
        
        
                            <div  class="dropdown selectBoxMap">
        
                                <ul *ngIf="is_tax_include==0 && vehicletype?.length >0" class="select-list-map ">
                                    <li  [class.active]="vhc.rate==baserate.replace('$','') " (click)="changeRate(vhc.rate, vhc.rate_type,vhc.net_rate,vhc.hours_description,vhc.description)" *ngFor="let vhc of vehicletype; index as index">
                                        
                                        <div class="radio linkLabel" *ngIf="vhc.net_rate != '0.00' && vhc.active==1" >
                                          <table style="width:100%"><tr><td style="width:159px">
                                            <label htmlFor={{index}} [ngClass]="{'font-weight-bold': selectedvahicletype==vhc.rate_type}" >
                                                <input id="{{index}}" [ngClass]="{'activeMontlyPlan': selectedvahicletype==vhc.rate_type}" [checked]="selectedvahicletype==vhc.rate_type" type="radio" name="price" value={{vhc.rate_type}} class="radio radio_alt" sourceFont />${{vhc.net_rate}} - {{vhc.rate_type}} </label></td>
                                                
                                                <td><div *ngIf="showSavingFlexibilityMsg(vhc.rate_type)" [class]="bgclr" class=" pull-left ml-3" >
                                                    {{orangestriptdata}}
                                            </div>
                                          </td></tr></table>
                                          <!-- {{makeSelectableMontheke(selectedvahicletype,vhc.rate_type)}} -->
                                        </div>
                                    </li>
                                </ul>
                                <ul *ngIf="is_tax_include==1 && vehicletype.length >0" class="select-list-map ">
                                        <li  (click)="changeRate(vhc.rate, vhc.rate_type,vhc.rate,vhc.hours_description,vhc.description)" *ngFor="let vhc of vehicletype; index as index">
                                            
                                            <div class="radio linkLabel" *ngIf="vhc.net_rate != '0.00' && vhc.active==1" >
                                              <table style="width:100%"><tr><td style="width:159px">
                                                  <label htmlFor={{index}} [ngClass]="{'font-weight-bold': selectedvahicletype==vhc.rate_type}" >
                                                    <input id="{{index}}" [ngClass]="{'activeMontlyPlan': selectedvahicletype==vhc.rate_type}" [checked]="selectedvahicletype==vhc.rate_type" type="radio" name="prices" value={{vhc.rate_type}} class="radio radio_alt"  sourceFont />${{vhc.rate}} - {{vhc.name_alias}} </label></td>
                                                    
                                                <td><div *ngIf="showSavingFlexibilityMsg(vhc.rate_type)" [class]="bgclr" class=" pull-left ml-3" >
                                                    {{orangestriptdata}}
                                            </div>
                                              </td></tr></table>
                                              <!-- {{makeSelectableMontheke(selectedvahicletype,vhc.rate_type)}} -->
                                            </div></li>
                                    </ul>
                                    
                                    
                                <!-- <div class="flex-row hidden ">
                                    
                                    <div class="col pad-left-0 pad-right-0">
        
        
                               
                             
                                
                                <ul *ngIf="is_tax_include==0" class="select-list-map ">
                                    <li  [class.active]="vhc.rate==baserate.replace('$','') " (click)="changeRate(vhc.rate, vhc.rate_type,vhc.net_rate,vhc.hours_description,vhc.description)" *ngFor="let vhc of vehicletype;">
                                        
                                        <div class="radio linkLabel" *ngIf="vhc.net_rate != '0.00' && vhc.active==1" >
                                            <label [ngClass]="{'font-weight-bold': selectedvahicletype==vhc.rate_type}" ><input type="radio" name="price" [checked]="selectedvahicletype==vhc.rate_type" value={{vhc.rate_type}} class="radio radio_alt" sourceFont />${{vhc.net_rate}} - {{vhc.rate_type}} </label>
                                        </div>
                                    </li>
                                </ul>
                                <ul *ngIf="is_tax_include==1" class="select-list-map ">
                                        <li [class.active]="vhc.rate==baserate.replace('$','') " (click)="changeRate(vhc.rate, vhc.rate_type,vhc.rate,vhc.hours_description,vhc.description)" *ngFor="let vhc of vehicletype;">
                                            
                                            <div class="radio linkLabel" *ngIf="vhc.net_rate != '0.00' && vhc.active==1" >
                                                <label sourceFont [ngClass]="{'font-weight-bold': selectedvahicletype==vhc.rate_type}" ><input type="radio" name="prices" [checked]="selectedvahicletype==vhc.rate_type" value={{vhc.rate_type}} class="radio radio_alt" />${{vhc.rate}} - {{vhc.rate_type}} </label>
                                            </div></li>
                                    </ul>
                                </div>
                            </div> -->
                           
                             
                               
                            </div>
                        </div>
                        <div class="clearfix">
                            <h3 class="m-0" *ngIf="searchCriteria.type == 'daily'">
                                <small class="text-uppercase timeDateEdit" id="monthdaily">
                                {{ displayableStartDateWrapper() }} - {{ displayableEndDateWrapper() }}  &nbsp;&nbsp;
                                 {{ dStartTime }} - {{ dEndTime }} 
                                 <!--   <a *isMobile class="btn btn-xs btn-link" (click)="dateTimeMobile()">
                                 <fa name="pencil" size="2x"></fa>
                                </a> -->
                                </small>
                            </h3>
                        </div>
                        <div class="clearfix" *ngIf="searchCriteria.type == 'daily' && isUpdatedEndTimeFlag">
                        <p>
                           <i> Your reservation was extended at no extra charge!</i>
                        </p>  </div>
                        <p *ngIf="searchCriteria.type == 'daily' && !activeLoading && (allParkings.ticketech_id != '' || ( FacilityAvailableFetatures?.parkonect_id != '' && FacilityAvailableFetatures?.parkonect_id != null ) || (FacilityAvailableFetatures?.valet_manager_id != '' && FacilityAvailableFetatures?.valet_manager_id != null) )" class="  text-left mt-2 mb-4 pb-2">
                            <strong [ngClass]="{ 'text-danger' : (FacilityAvailableFetatures?.availability_msg_some_space =='') }">{{FacilityAvailableFetatures?.availability_msg}}</strong>
                        </p>
                        <div class="clearfix hidden" *ngIf="searchCriteria.type == 'daily'"> 
                            <div class="row align-items-center my-3">
                                <div class="col">
                                    <select class="form-control customeSelectForBlock">
                                        <option selected>Select Vehicle Size/Type</option>
                                        <option >Select Vehicle Size/Type</option>
                                    </select>
                                </div>
                                <div class="col-auto pl-0">
                                    <ng-template #popTemplate>
                                        <p>
                                            Vehicles over 181″ in Length and over 70″ in Height (or 75” high regardless of length) are subject to an additional fee.
                                        </p>    
                                        <a  class="btn btn-link btn-xs" href="javascript:void(0)"><i  class="fa fa-times"></i> Dismiss</a>
                                    </ng-template>
        
        
                                    <i  [popover]="popTemplate"  [outsideClick]="true" containerClass="msgClassOrange"
                                   
                                    placement="right" container="body" class="fa fa-info-circle text-primary fa-2x" aria-hidden="true"></i>
                                </div>
                            </div>
                        </div>
        
        
        
                        <div class="clearfix">
        
                        <div class="book-btns-wrapper " >
                            <span  *ngIf="isPartners === false && !getUpdatedPrice && !availabilityCheck && !activeLoading && ( allParkings.price!='N/A' ) && (allParkings.ticketech_id != '' || (FacilityAvailableFetatures?.parkonect_id != '' && FacilityAvailableFetatures?.parkonect_id != null) || (FacilityAvailableFetatures?.valet_manager_id != '' && FacilityAvailableFetatures?.valet_manager_id != null) ) && searchCriteria.type == 'daily'">
                                    <a class="btn btn-primary    btn-fill btn-block" id="dailymonthlybutton" *ngIf="( allParkings.price!='N/A' ) && (allParkings.ticketech_id != '' || (FacilityAvailableFetatures?.parkonect_id != '' && FacilityAvailableFetatures?.parkonect_id != null) || (FacilityAvailableFetatures?.valet_manager_id != '' && FacilityAvailableFetatures?.valet_manager_id != null) ) && searchCriteria.type == 'daily'" (click)="buyDailyMonthly();detailsSidebarClick();" > BOOK NOW 
                                        <!-- <small>spot guaranteed</small> <span *ngIf="FacilityAvailableFetatures?.paynow_promocode_discount" class="tagoffer">Save {{FacilityAvailableFetatures?.paynow_promocode_discount}} </span>  -->
                                    </a>
                                </span>
                            <span  *ngIf="isPartners === false && getUpdatedPrice && !availabilityCheck && !activeLoading && ( FacilityAvailableFetatures?.price!='N/A' ) && (allParkings.ticketech_id != '' || (FacilityAvailableFetatures?.parkonect_id != '' && FacilityAvailableFetatures?.parkonect_id != null) || (FacilityAvailableFetatures?.valet_manager_id != '' && FacilityAvailableFetatures?.valet_manager_id != null) ) && searchCriteria.type == 'daily' ">
                                    <a class="btn btn-primary    btn-fill btn-block" id="dailymonthlybutton" *ngIf="( FacilityAvailableFetatures?.price!='N/A' ) && (allParkings.ticketech_id != '' || (FacilityAvailableFetatures?.parkonect_id != '' && FacilityAvailableFetatures?.parkonect_id != null) || (FacilityAvailableFetatures?.valet_manager_id != '' && FacilityAvailableFetatures?.valet_manager_id != null) ) && searchCriteria.type == 'daily'" (click)="buyDailyMonthly();detailsSidebarClick();" > BOOK NOW 
                                        <!-- <small>spot guaranteed</small> <span *ngIf="FacilityAvailableFetatures?.paynow_promocode_discount" class="tagoffer">Save {{FacilityAvailableFetatures?.paynow_promocode_discount}} </span>  -->
                                    </a>
                                </span>
                            <span *ngIf="isPartners === false && availabilityCheck && !activeLoading && ( FacilityAvailableFetatures?.price_without_availibility !='N/A' ) && (allParkings.ticketech_id != '' || (FacilityAvailableFetatures?.parkonect_id != '' && FacilityAvailableFetatures?.parkonect_id != null) || (FacilityAvailableFetatures?.valet_manager_id != '' && FacilityAvailableFetatures?.valet_manager_id != null) ) && searchCriteria.type == 'daily'">
                                    <a class="btn disable-price btn-primary pad-left-0 btn-block" id="dailymonthlybutton" *ngIf="( FacilityAvailableFetatures?.price_without_availibility !='N/A' ) && (allParkings.ticketech_id != '' || (FacilityAvailableFetatures?.parkonect_id != '' && FacilityAvailableFetatures?.parkonect_id != null) || (FacilityAvailableFetatures?.valet_manager_id != '' && FacilityAvailableFetatures?.valet_manager_id != null) ) && searchCriteria.type == 'daily'" > BOOK NOW 
                                        <!-- <small>spot guaranteed</small> <span *ngIf="FacilityAvailableFetatures?.paynow_promocode_discount" class="tagoffer">Save {{FacilityAvailableFetatures?.paynow_promocode_discount}} </span> -->
                                    
                                    </a>
                                </span>
        
                            <a class="btn btn-primary pad-left-0 pad-right-0 btn-fill btn-block" id="dailymonthlybutton" style="padding: 10px 15px;" *ngIf="baserate!='N/A' && searchCriteria.type == 'monthly'" (click)="buyDailyMonthly();detailsSidebarClick();">BUY MONTHLY</a>
                            <a  [class.disabled]="getCouponDisabled" class="btn btn-primary btn-sm btnCoupon theme-btn btn-block searchCoupon text-left" id="checkcoupon" (click)="manageCoupons();" *ngIf="showcoupon && isPartners === false && allParkings.rate_id && allParkings.rate_type_id == 2 && searchCriteria.type == 'daily' || allParkings.rate_type_id == 4 && searchCriteria.type == 'monthly'">Pay At Garage <small >Spot not guaranteed</small></a>
                            <a [class.disabled]="getCouponDisabled" class="btn btn-primary btn-sm btnCoupon theme-btn btn-block partnerCoupon text-center" id="checkcoupon" (click)="manageCoupons();" *ngIf="showcouponp && isPartners">Pay At Garage <small >Spot not guaranteed</small></a>
                            <!-- hide coupon functionality -->
                            <small *ngIf="showOversizedMsg" class="smallTextAdded" [ngClass]="{'monthly ' : searchCriteria.type == 'monthly'}" > 
                                {{overSizeMsg}}
                            </small>
                        </div>
        
                        <p  class="text-center my-4" *ngIf="searchCriteria.type == 'monthly'" >
                            <img [src]="booknowBadge" width="90%" class="">
                        </p>
                        
                        <div class="row bestDealsContent our-best-d" style="padding-top: 15px; font-size: 10px; text-align: center;" *ngIf="searchCriteria.type == 'daily'">
                            <div class="col pad-right-0" >
                                <img class="mr-2" height="15" [src]="seal_best_deals">Our Best Deals Guaranteed
                            </div>
                            <div class="col pl-2" >
                                <img class="mr-2" height="15" [src]="risk_free_cancellation">Risk-free, Easy Cancellation
                            </div>
                        </div>
                        <div class="row row justify-content-center" *ngIf="searchCriteria.type == 'monthly'">
                            <div class="col-auto" >
                                <img class="mr-2" height="15" [src]="seal_best_deals">Our Best Deals Guaranteed
                            </div>
                          
                        </div>
        
                    </div> 
                    </div>
        
                   
                    <!-- <div class="panel-body border-top"> -->
                       
                        <!-- <div style="display: none;" couponBtnContainer class="clearfix ">
                            <p class="text-uppercase text-primary">Monthly Parking </p>
                            <p>Submit an online monthly parking request form or call us to speak to a representative. Our monthly parking specialist will research our inventory and respond to your request.</p>
                            <div class="customeRow rowInline" *ngIf=" searchCriteria.type == 'monthly'">
                                <div class="row_item">
                                    <a (click)="navigateRequestInfoPage()" class="btn btn-primary theme-btn btn-sm">Request info</a>
                                </div>
                                <div class="row_item">
                                    <p><a [href]="telephone">Call (888)-705-7845</a>
                                        <br>
                                        <small>9am to 7pm</small>
                                    </p>
                                </div>
                            </div>
                            <div class="customeRow" *ngIf=" searchCriteria.type == 'daily'">
                                <div class="row_item" *hideItBootstrap="['xs']">
                                    <a *ngIf="searchCriteria.type=='daily'&& activeBuyOnline" (click)="navigateBuyMonthlyPage()" href="javascript:void(0)" class="btn btn-primary  theme-btn btn-sm">Buy Monthly Parking</a>
                                    <a (click)="navigateRequestInfoPage()" class="btn btn-primary theme-btn btn-sm">Request info</a>
                                </div>
                                <div class="row_item" *hideItBootstrap="['xs']">
                                    <p><a [href]="telephone">Call (888)-705-7845</a>
                                        <br>
                                        <small>9am to 7pm</small>
                                    </p>
                                </div>
                                <div class="row_item text-center" *showItBootstrap="['xs']">
                                    <p><a [href]="telephone">Call (888)-705-7845</a>
                                        <br>
                                        <small>9am to 7pm</small>
                                    </p>
                                </div>
        
                                <div class="row_item text-center" *showItBootstrap="['xs']">
                                    <a *ngIf="searchCriteria.type=='daily' && activeBuyOnline" (click)="navigateBuyMonthlyPage()" [href]="javascriptCode" class="btn btn-primary btn-block theme-btn btn-sm">Buy Monthly parking</a>
                                    <a (click)="navigateRequestInfoPage()" class="btn btn-primary btn-block theme-btn btn-sm">Request
                        info</a>
                                </div>
                            </div>
                        </div> -->
        
                        <!-- <a monthlyBookingBtn class="btn btn-primary btn-fill btn-block visible-xs none" id="dailymonthlybutton" (click)="buyDailyMonthly()"
                  *ngIf="searchCriteria.type == 'monthly'">
                  Buy Monthly <small>spot guaranteed</small>
                </a> -->
                        <!-- mobnthly view adress -->            
                        <!-- <div class="clearfix visible-xs " *ngIf="searchCriteria.type == 'monthly'"> -->
                            <!-- <h4 class="">{{chnageText(allParkings?.full_name)}}</h4>
                            <span><b>Between:</b> {{ allParkings?.between_streets }}</span>
                            <p class="mar-bottom-xs">
                                <span>Building Address: {{allParkings?.geolocation?.address_1}}</span>
                            </p>
                            <p id="between_entrance"></p>
                            <div class="row DateBlock">
                                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                                    <img [src]="map_market_location" height="12"> &nbsp; <span id="locDistance">{{allParkings.distance | distanceconversion}}</span>
                                    <br class="visible-xs">
                                </div>
                                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                                    <fa name="phone"></fa> &nbsp; <a id="garage_phone" style="color: #000;" href="tel:{{allParkings.phone_number}}">{{allParkings.phone_number}}</a>
        
                                </div>
                            </div>
        
        
        
                            <p class="dailystyles"><a (click)="getdirections.show()">Get Directions &nbsp;&nbsp;<fa name="chevron-right"></fa></a></p> -->
                            <!-- <div class="clearfix mar-top-sm flyimg"></div> -->
                            <!-- <div class="clearfix mar-top-xs">
                                <a style="cursor: pointer;" (click)="openModal(postedfacilityrats)">See Posted Rates</a>
                            </div> -->
                            
                        <!-- </div> -->
                        <!-- <hr class="whiteHr" *ngIf="searchCriteria.type == 'monthly'"> -->               
                        <!-- <hr class="whiteHr" *ngIf="searchCriteria.type == 'monthly'"> -->
                        <!-- ameneties for desktop -->             
                        <!-- <div class="clearfix"> -->
        
                            <!-- <p class="text-uppercase text-primary">Monthly Parking </p> -->
                            <!-- <p class="mar-top-sm" style="margin-bottom:20px">
                                <img style="margin-top: -16px;" height="50px" [src]="parkSafesmall"> Park with Peace of Mind.
                            </p>
            
                            <p>
                                <img src="../../../../assets/img/Rectangle.svg" height="5px">&nbsp;&nbsp;<span *ngIf="searchCriteria.type == 'monthly'">NYC's Only </span>Book & Park Instantly
            
                            </p>
                            <p>
                                <img src="../../../../assets/img/Rectangle.svg" height="5px">&nbsp;&nbsp;Save with Multi-Month Subscriptions
            
            
                            </p>
                            <p>
                                <img src="../../../../assets/img/Rectangle.svg" height="5px">&nbsp;&nbsp;Hassle-Free Guaranteed Spot
            
                            </p>
                            <p>
                                <img src="../../../../assets/img/Rectangle.svg" height="5px">&nbsp;&nbsp;Contactless Check In/Check Out
                            </p> -->
                         
                            <!-- <hr class="whiteHr" *ngIf=" searchCriteria.type == 'monthly'"> -->
                        <!-- <p class="monthly-phone-number" style="margin-top:20px" *ngIf=" searchCriteria.type == 'monthly'">
                                <strong>Need help with a reservation?</strong> 
                            <br>
                            <a [href]="telephone">Call (888) 705-7845</a> (9am to 7pm)
                        </p> -->
            
                            <!-- </div> -->
                    <!-- </div> -->
                    <div id="ModalworkingHoursPopup" #workingHours class="panel-body border-top">
                        <p class="mar-top-0"><strong>Hours of Operation </strong></p>
                        
                        <div class="clearfix" *ngIf="searchCriteria.type == 'daily'">
                            <div class="TimeTab" *ngIf="garageWorkingHours?.length == 0">
                                <table width="100%">
                                    <tr>
                                        <td>
                                            Monday - Sunday
                                        </td>
                                        <td class="text-right">
                                            Open 24 Hours
                                        </td>
                                    </tr>
                                </table>
                            </div>
        
                            <div class="TimeActive table-responsive" *ngIf="garageWorkingHours?.length > 0">
                                <table class="table table-condensed ">
                                    <tr *ngFor="let wh of garageWorkingHours;">
                                        <td>{{wh.day}}</td>
                                        <td>:</td>
                                        <td width="90px">{{wh.formatted_open_time}}</td>
                                        <td>-</td>
                                        <!-- <td>{{wh.formatted_close_time}} {{showtimefornxtornot(wh.close_time)}}</td> -->
                                        <td>{{wh.formatted_close_time}}</td>
                                    </tr>
                                </table>
                            </div>
                        </div>
        
                        <div class="clearfix" *ngIf="searchCriteria.type == 'monthly'">
                            <!-- <hr class="whiteHr" *ngIf="searchCriteria.type == 'daily'"> -->
                          
                            <div class="TimeTab" *ngIf="garageWorkingHours?.length == 0 && searchCriteria.type == 'monthly'">
                                <table width="100%">
                                    <tr>
                                        <td>
                                            Monday - Sunday
                                        </td>
                                        <td class="text-right">
                                            Open 24 Hours
                                        </td>
                                    </tr>
                                </table>
                            </div>
            
                            <div class="TimeActive table-responsive" *ngIf="garageWorkingHours?.length > 0 && searchCriteria.type == 'monthly'">
                                
                                <table class="table table-condensed ">
                                    <tr *ngFor="let wh of garageWorkingHours;">
                                        <td>{{wh.day}}</td>
                                        <td>:</td>
                                        <td width="90px">{{wh.formatted_open_time}}</td>
                                        <td>-</td>
                                        <!-- <td>{{wh.formatted_close_time}} {{showtimefornxtornot(wh.close_time)}}</td> -->
                                        <td>{{wh.formatted_close_time}} {{showtimefornxtornot(wh.close_time)}}</td>
                                    </tr>
                                </table>
                            </div>
                            </div>
        
        
                    </div>
                    <div id="elId"></div>

                    <div class="panel-body border-top">
                            <div class="clearfix" *ngIf="searchCriteria.type == 'daily'">
                                  
                                <p class="mar-top-0"><strong>Amenities</strong></p>
                             
                             
                                <ul class="amenities-list">
            
                                    <li *ngIf="allParkings.is_generic_ev_charging =='1'">
                                        <img [src]="ev_charge_icon" alt="image">
                                        <span>
                            EV Charging
                          </span>
                                    </li>
                                    <li *ngIf="allParkings.is_motorcycle_parking =='1'">
                                        <img [src]="motorcycle_icon" alt="image">
                                        <span>
                            Motorcycle Parking
                          </span>
                                    </li>
                                    <li *ngIf="allParkings.is_tesla_charging =='1'">
                                        <img [src]="tesla_charger_icon" alt="image">
                                        <span>
                            Tesla Charger
                          </span>
                                    </li>
                                    <li *ngIf="allParkings.is_24hour_open =='1'">
                                        <img [src]="open_24hr_icon" alt="image">
                                        <span>
                            Open 24 Hours
                          </span>
                                    </li>
                                    <li *ngIf="allParkings.is_indoor_parking =='1'">
                                        <img [src]="indoor_icon" alt="image">
                                        <span>
                            Indoor Parking
                          </span>
                                    </li>
                                    <li *ngIf="allParkings.is_outdoor_parking =='1'">
                                        <img [src]="outdoor_icon" alt="image">
                                        <span>
                            Outdoor Parking
                          </span>
                                    </li>
                                    <li *ngIf="!showOversizedMsg">
                                        <img [src]="oversized_icon" alt="image">
                                        <span>
                            Oversized Vehicles
                          </span>
                                    </li>
                                    <li *ngIf="allParkings.is_monthly =='1'">
                                        <img [src]="monthly_icon" alt="image">
                                        <span>
                            Monthly Parking
                          </span>
                                    </li>
            
                                </ul>
                                <!-- <hr class="whiteHr"> -->
                            </div>
                        <div class="clearfix" *ngIf="searchCriteria.type == 'monthly'">
                      
                            <p class="font-weight-bold mar-top-0">Amenities</p>
                            <ul class="amenities-list">
        
                                <li *ngIf="allParkings.is_generic_ev_charging =='1'">
                                    <img [src]="ev_charge_icon" alt="image">
                                    <span>
                        EV Charging
                      </span>
                                </li>
                                <li *ngIf="allParkings.is_motorcycle_parking =='1'">
                                    <img [src]="motorcycle_icon" alt="image">
                                    <span>
                        Motorcycle Parking
                      </span>
                                </li>
                                <li *ngIf="allParkings.is_tesla_charging =='1'">
                                    <img [src]="tesla_charger_icon" alt="image">
                                    <span>
                        Tesla Charger
                      </span>
                                </li>
                                <li *ngIf="allParkings.is_24hour_open =='1'">
                                    <img [src]="open_24hr_icon" alt="image">
                                    <span>
                        Open 24 Hours
                      </span>
                                </li>
                                <li *ngIf="allParkings.is_indoor_parking =='1'">
                                    <img [src]="indoor_icon" alt="image">
                                    <span>
                        Indoor Parking
                      </span>
                                </li>
                                <li *ngIf="allParkings.is_outdoor_parking =='1'">
                                    <img [src]="outdoor_icon" alt="image">
                                    <span>
                        Outdoor Parking
                      </span>
                                </li>
                                <li *ngIf="!showOversizedMsg">
                                    <img [src]="oversized_icon" alt="image">
                                    <span>
                        Oversized Vehicles
                      </span>
                                </li>
                                <li *ngIf="allParkings.is_monthly =='1'">
                                    <img [src]="monthly_icon" alt="image">
                                    <span>
                        Monthly Parking
                      </span>
                                </li>
        
                            </ul>
                            <!-- <hr class="whiteHr"> -->
                        </div>
                        
                        
                        <!-- <div class="clearfix mar-top-sm visible-xs" *ngIf="searchCriteria.type == 'daily'"></div> -->
                        <!-- <hr class="whiteHr visible-xs" *ngIf="searchCriteria.type == 'daily'"> -->
                        <!-- <div class="flex-row no-gutters ">
                            <div class="col-1 col-sm-1 col-md-1 col-lg-1">
                                <fa name="lightbulb-o" class="text-primary" size="2x"></fa>
                            </div>
                            <div class="col-11 col-sm-11 col-md-11 col-lg-11">
                                <p class="text-primary text-uppercase">Did you know? </p>
                                <ul class="dashList list-unstyled">
                                    <li>Many garages don't accept <a routerLink="/oversized"> oversized vehicles</a>, or they often charge between $5 - $15 extra.
                                    </li>
                                    <li>If you plan to visit for the day, arriving before 10:00am will almost always result in a lower fee and a flexible departure time.
                                    </li>
                                    <li>
                                        Don't see what you want? <a [href]="telephone2"> Call 1-877-PARKING (727-5464)</a> for expert advice.
                                        <br>Monday - Friday, 9:00am to 5:00pm
                                    </li>
                                    <li><a style="cursor: pointer;" (click)="openModal(postedfacilityrats)">See Posted Rates</a><br/><br/></li>
        
        
                                </ul>
                                <div class="transparentunderli"></div>
                            </div>
                        </div> -->
        
        
                        <!-- <div class="clearfix posted-facilty-box visible-xs">
                             <p class="text-uppercase text-primary">Posted Facility Rates</p>
                             <p class="hidden-xs">All rates shown below include a {{taxRate}}% parking tax.</p>
                             <accordion>
                                <accordion-group #group  [panelClass]="customClass" (click)="showHide();">
                                  <div accordion-heading class="text-center">
                                    CLICK TO {{textHideShow}} POSTED FACILITY RATES &nbsp; <i class="float-xs-right glyphicon"
                                       [ngClass]="{'glyphicon-chevron-up': group?.isOpen, 'glyphicon-chevron-down': !group?.isOpen}"></i>                          </div>
                               
                            
                             <table class="table tbl-border" *ngFor="let rate of boardRate;">
                               <thead>
                                    <tr><th colspan="2">{{rate.name}}</th><th>Total</th></tr>
                                </thead>
                               <tbody>
                                        <ng-container *ngFor="let rates of rate.rates;">
                                           <tr *ngIf="rates.category.category==rate.name">
                                                <td >{{rates.description=='' ? '7 Days a week / Enter any time / Exit any time' : rates.description}}</td>
                                                <td >{{rates.details=='' ? '7 Days a week / Enter any time / Exit any time' : rates.details}}</td>
                                                <td >${{rates.price=='' ? '7 Days a week / Enter any time / Exit any time' : rates.price}}</td>                                    </tr>
                                        </ng-container>
                                   
                               </tbody>
                            </table>
                            <table  class="table" *ngIf="FacilityAvailableFetatures?.facility?.oversize_fee">
                              <thead>
                                   <tr><th colspan="2">Additional Fees</th><th>Total</th></tr>
                               </thead>
                              <tbody>
                                       <ng-container>
                                          <tr>
                                               <td colspan="2">Oversized vehicle fee</td>
                                               <td >${{FacilityAvailableFetatures.facility.oversize_fee}}</td>                                    
                                          </tr>
                                       </ng-container>
                                  
                              </tbody>
                           </table>
                       </accordion-group>
                   </accordion>
                            </div> -->
                    </div>
                   
                       <div *ngIf="searchCriteria.type == 'daily'"  class="panel-body border-top">  
                        <div class="reserBox" >

                            <div style="cursor: pointer;" class="row no-gutters align-items-center"(click)="shouldShow = !shouldShow">
                                <div class="col">
                                    <b>{{cmsPolicyContent.section_title}}</b>
                                </div>
                                <div clss="col-auto">
                                    <i *ngIf="!shouldShow" class="fa  fa-2x text-primary fa-angle-down"></i>
                                    <i *ngIf="shouldShow" class="fa fa-2x text-primary fa-angle-up"></i>
                                </div>
                            </div>
                            <div class="content gmp-policy mt-3" [ngClass]="{show: shouldShow, hide: !shouldShow}">
                                <!-- <ul>
                                    <li>You will not be allowed to come and go throughout the duration of your reservation. Only one entry/exit is permitted.</li>
                                    <li>To cancel for a full refund please click the cancel reservation button in this email any time prior to the arrival time indicated on the reservation.</li>
                                    <li>No refunds will be issued for unused reservations not canceled prior to the arrival time. There are no partial refunds for unused time.</li>
                                    <li>Reservations cannot be extended or combined; only one reservation can be applied per parking stay. If you arrive before the reservation time, or, exit after the reservation time, you will be subject to pay for the time parked outside of your reservation. This is calculated automatically, and the payment will be required at the time of check-out.</li>
                                    <li>An oversize vehicle surcharge ($15.00 per 24 hours) will be collected at the location if the vehicle does not meet the standard size requirement. Please visit iconparking.com/oversized for more information.</li>
                               </ul> -->
                               <div [innerHTML]="cmsPolicyContent.post_content"></div>
                            </div>
                           </div>
                       </div>
                </div>
           

           

            <!-- <div *isAndroidMobile style="height:100px;" class="visible-xs"></div> -->

            <!-- <div style="height:100px;" class="visible-xs"></div> -->
           
                <div *isIphone style="height:30px;"  class="visible-xs"></div>
            </div>
            
        </div>

        <div class="loaderforListing" *ngIf="showLoader && notMonthly">
            <div class="WidthLoader"></div>
            <div class="SelectLoader"></div>
            <div class="loader">
                <div class="loaderImg">

                </div>
                <div class="Content">
                    <div class="loderTitle">

                    </div>
                    <div class="loaderContent">

                    </div>
                </div>
            </div>
            <div class="loader">
                <div class="loaderImg">

                </div>
                <div class="Content">
                    <div class="loderTitle">

                    </div>
                    <div class="loaderContent">

                    </div>
                </div>
            </div>
            <div class="loader">
                <div class="loaderImg">

                </div>
                <div class="Content">
                    <div class="loderTitle">

                    </div>
                    <div class="loaderContent">

                    </div>
                </div>
            </div>
        </div>
        
            <!--  for sidebar list tab -->
        
            <div id="parkList" class="listTab pb-0 row no-gutters flex-column" [class.add-min-height]="!checkForNoFacility(facilityData)" [class.hidden]="(checkForNoFacility(facilityData) && (!parkList || hideListView))|| activeNeighborhoodFilter">
                <div class="col-auto align-self-start w-100 ">
                <!-- info-well segments -->
                <div class="headerTopToggleFiler visible-xs">
                    <div class=" daily-monthly-selector border-right">
                        <div class="filterBtn" data-toggle="buttons">
                            <label   name="monthlytype" class="btn  dailymonthlybuttons 3" (click)="tabRouting('daily')" [class.active]="isType">
                        DAILY
                    </label>
                            <label   name="monthlytype" class="btn  dailymonthlybuttons 4" (click)="tabRouting('monthly')" [class.active]="!isType">
                        MONTHLY
                    </label>
                        </div>
                    </div>
                </div>
                <div class="infowell font-italic"  >
                    <p  *ngIf="searchCriteria.type == 'daily'"> 
                           <!-- Save Over 50% off On Monthly Parking Limited Time Only. Call 888-705-7845
                    7 days a week 8:30am to 7pm Or click BOOK NOW to purchase 24 hours a day. -->
                    <!-- Reserve Online and Save More on Daily Parking. -->
                    {{CMSsidebarContent?.sidebar_top_description_for_daily}}
                    </p>
                    <p *ngIf="searchCriteria.type == 'monthly'" >
                           <!-- Rates displayed are web specials and require a reservation -->
                    <!-- Reserve Online and Save More on Monthly Parking. -->
                    {{CMSsidebarContent?.sidebar_top_description_for_monthly}}
                    </p>
                 
                </div>
             
                <div *ngIf="searchCriteria.type == 'monthly'" class="booknow-mont-serch text-right"><a href="javascript:;"><img src="assets/img/search-monthlybook.png"></a></div>
                <header>
                     <div class="row no-gutters align-items-center">
                        <div class="col">

                            <div class="btn-group filterDropdownMenu" dropdown>
                                <button *ngIf="checkLenght(facilityData)" id="button-basic" dropdownToggle type="button" class="btn  btn-link text-dark dropdown-toggle"
                                        aria-controls="dropdown-basic">
                                        Sort by <span class="uppercase-text">{{selectedSortType}}</span> &nbsp; <img width="25px" [src]="dropdownArrow">
                                </button>
                                <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu filterDropDown"
                                    role="menu" aria-labelledby="button-basic">
                                    <li role="menuitem"><a class="dropdown-item" [class.hidden]="selectedSortType == 'Distance' || selectedSortType == 'distance'" (click)="sortBy('distance')" href="javascript:;">Distance</a></li>
                                    <!-- <li class="divider dropdown-divider" [class.hidden]="selectedSortType == 'Distance' || selectedSortType == 'distance'"></li> -->
                                   <li role="menuitem"><a class="dropdown-item" (click)="sortBy('price')" [class.hidden]="selectedSortType == 'price' || selectedSortType == 'Price'" href="javascript:;">Price</a></li>
                                    <!-- <li class="divider dropdown-divider" [class.hidden]="selectedSortType == 'price' || selectedSortType == 'Price'"></li> -->
                                    <li *ngIf="!partnerPageOn" role="menuitem"><a class="dropdown-item" (click)="sortBy('Neighborhood')" [class.hidden]="(selectedSortType == 'Neighborhood')" href="javascript:;">Neighborhood</a></li>
                                </ul>
                              </div>


                            <!-- <select id="sortingFilterList" class="sortingFilter 1" (change)="sortBy($event.target.value)">
                            <option value="distance" selected> Sort byDistance</option>
                            <option value="price">Price</option>
                            <option value="Neighborhood">Neighborhood </option>
                            </select> -->
                        </div>
                        <div *ngIf="searchCriteria.type == 'monthly' && checkLenght(facilityData)" class="col-auto mr-2 111 " [class.hidden-xs]="!monthlyParkingNb && searchCriteria.type == 'monthly'">
                            Deals Only
                        </div>
                        <div *ngIf="searchCriteria.type == 'monthly' && checkLenght(facilityData)" class="col-auto p-0 222 " [class.hidden-xs]="!monthlyParkingNb && searchCriteria.type == 'monthly'">
                            
                            <label class="toggleSwitch">
                                <input type="checkbox" [checked]="is_deal" (change)="check()">
                                <span class="slider round"></span>
                              </label>
                        </div>
                    </div>
                   
                </header>
                <header tabList class="panel-body">
                   
                    <table width="100%" class="hide">
                        <tr>
                            <td width="20%" class="text-uppercase"> <b>Sort By:</b></td>
        
                            <td>
                                <div data-toggle="buttons" class="shortingBtns 2">
                                    <label #distancesort *ngIf="isPartners == false" id="distancesort" name="distance" (click)="sortBy('distance')" class="btn btn-xs text-uppercase active sortByDistance distancesort ">
                        <input type="radio" class="radio sortByDistance" name="options" id="option1" autocomplete="off" checked>
                        Distance
                      </label>
                                    <label *ngIf="isPartners" id="distancesort" name="distance" (click)="sortBy('distance')" class="btn btn-xs text-uppercase active sortByDistance distancesort ">
                        <input type="radio" class="radio sortByDistance" name="options" id="option1" autocomplete="off" checked>
                        Distance
                      </label>
                                    <label *ngIf="isPartners == false" name="price" (click)="sortBy('price')" class="btn btn-xs text-uppercase sortByPrice distancesort">
                        <input type="radio" class="radio sortByPrice" name="options" id="option2" autocomplete="off"> Price
                      </label>
                                    <label *ngIf="isPartners" name="price" (click)="sortBy('price')" class="btn btn-xs text-uppercase sortByPrice distancesort">
                        <input type="radio" class="radio sortByPrice" name="options" id="option2" autocomplete="off"> Price
                      </label>
                      <!-- <button *ngIf="existCampaignData" type="button" class="btn btn-link dealsButton" (click)="showDeals()"
                      [attr.aria-expanded]="!isCollapsed" aria-controls="collapseBasic"> <span>$</span>Deals
                  </button> -->
                      
                                </div>
                    
                            </td>
                            <td *ngIf="searchCriteria.type == 'monthly'" ><a href="javascript:;" class="btn text-white  btn-sm btn-fill floatClose overlayClose month-search-cross"  (click)="back(1)">
                                 <fa name="times " ></fa>
                            </a>
                            </td>
                        </tr>
                    </table>
                  
                </header>
            </div>

                <section class="facScroll col align-self-center w-100   clearfix">
                    <div class="loader" style="text-align: center;padding: 20px 10px;" *ngIf="pricesortingdone && ( pricesorting || distancesortingloader) && searchCriteria.type=='daily'">
                        <a>
                            <fa style="font-size: 20px;" animation="spin" name="circle-o-notch">
                                <i aria-hidden="true" class="fa fa-circle-o-notch fa-spin"></i>
                            </fa>
                        </a>
                    </div>
                    <div class="" style="text-align: center;padding: 20px 10px;" *ngIf="!checkLenght(facilityData) && !loaderdata">
                         <a> <!--No Facility Available -->
                            <i class="fa fa-circle-o-notch fa-spin  load_icon"></i><br/>Please Wait 
                        </a>
                    </div>
                    <div class="" style="text-align: center;padding: 20px 10px;" *ngIf="!checkLenght(facilityData) && loaderdata">
                        <a> <!--No Facility Available -->
                            No Facility Available  
                       </a>
                   </div>
                    <ul class="facilityList facilityList1 1 orangeBar"  *ngIf="checkLenght(facilityData)" [class.ios]="isIphone" [class.android]="isAndroid" [class.padDaily]="searchType == 'daily'" [class.padMomthly]="searchType == 'monthly'">
                        
                        <ng-container *ngIf="distancesorting && searchCriteria.type=='daily'">
                            <li *ngFor="let facilityD of facilityData.facilities | sorting:sortType; let couponIndexSelectCoupon = index" class="facility 5555" data-id="{{facilityD.facility_id}}">
                                <div class="row">
                                    <div class="col-4 clipImage">
                                            <img *ngIf="facilityD.photo_url" [src]="facilityD.photo_url" class="img-responsive rounded"  alt="image">
                                            <img *ngIf="!facilityD.photo_url" [src]="park_garage" class="img-responsive notImg rounded" alt="image">
                                    </div>
                                <div class="col">  
                                    <div class="row">

                                
                                    <!-- left content data -->
                                    <div class="col p-0">
                                        <h4 class="text-uppercase mar-bottom-0 mar-top-0" parkTitle>
                                            {{facilityD.entrance_location}}
                                        </h4>
                                        <p class="mb-0 ">{{facilityD?.facility_name_modified}}</p>
                                        <p class="map-mark">
                                            <img [src]="map_market_location" height="14">&nbsp;&nbsp;{{facilityD.distance | distanceconversion}}</p>
        
                                        <p class="facDistance hidden">{{ facilityD.distance }}</p>
                                    </div>
                                    <!--  price listing and date -->
                                    <div class="col-auto text-center">
                                        <small>Rates from </small>
                                        <div *ngIf="searchCriteria.type=='daily'" class=" onfacilityload_{{facilityD.facility_id}} hide">
                                            <a class="loadingprice">
                                                <fa animation="spin" name="circle-o-notch">
                                                    <i aria-hidden="true" class="fa fa-circle-o-notch fa-spin"></i>
                                                </fa>
                                            </a>
                                        </div>
                                        <div *ngIf="searchCriteria.type=='daily'" class="onfacilityloadshow_{{facilityD.facility_id}}">
                                            <h3 sourceFont  *ngIf="isPartners == false" id="sorting_{{facilityD.facility_id}}" class="sorting_{{facilityD.facility_id}} mt-0 font-weight-bold" >
                                                {{priceReturn(facilityD.price)}}
                                            </h3>
                             
                                            <h3 sourceFont *ngIf="isPartners == true && facilityD.price!='N/A'" id="sorting_{{facilityD.facility_id}}" class="partner-price mt-0 font-weight-bold">
                                                {{priceReturn(facilityD.price)}}
                                            </h3>
                                            <h3 sourceFont *ngIf="isPartners == true && facilityD.price=='N/A'" id="sorting_{{facilityD.facility_id}}" class="partner-price mt-0 font-weight-bold">
                                                {{priceReturn(facilityD.price)}} 
                                            </h3>
                                            <!-- <p class="facPrice hidden_Price_{{facilityD.facility_id}}"> {{ priceReturn(facilityD.price) }}</p> -->
                                            <span class="hide  baserate narecord_{{facilityD.facility_id}}"><h2 class=" mt-0 font-weight-bold"> NA</h2></span>
                                        </div>
        
                                        <div *ngIf="searchCriteria.type=='monthly'" class="">
                                            <h3 sourceFont class="mt-0 font-weight-bold" *ngIf="(facilityD.monthly_rate_main>0 && facilityD.monthly_rate_main < 2500000) && isPartners == false &&  searchCriteria.type=='monthly' && facilityD.monthly_rate_main!='N/A*'" id="sorting_{{facilityD.facility_id}}"  (click)="moveToReservation(facilityD.facility_id, facilityD.price, facilityD.monthly_rate_main,facilityD.warning_on_reservation,facilityD.warning_on_reservation_msg);">
                                               ${{facilityD.monthly_rate_main}}
                                            </h3>
                                            <!-- <h3 class="mt-0 font-weight-bold"   style="cursor: default;" *ngIf="(facilityD.monthly_rate_main < 1 || facilityD.monthly_rate_main > 2400000) && isPartners == false &&  searchCriteria.type=='monthly' && facilityD.monthly_rate_main!='N/A*'" id="sorting_{{facilityD.facility_id}}" >
                                                   N/A 
                                            </h3> -->
                                            <p class="facPrice hidden">{{ facilityD.monthly_rate_main }}</p>
                                            <!-- <span class="baserate" *ngIf="isPartners == false &&  searchCriteria.type=='monthly' && facilityD.monthly_rate_main=='N/A*'"><h2 class=" mt-0 font-weight-bold"> NA</h2></span> -->
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="row ">
                                    <div class="col p-0 f-10 mt-3">
                                       *Please be advised of amended hours at this location.<br class="hidden-xs"> <a [href]="temporarilyClosedUrl" target="_blank">
                                             Click Here
                                        </a>for alternative garage information
                                    </div>
                                </div> -->
                            </div>
                                
                                </div>
                                <div class="row">
                                    <div class="col trustpilot-rate  "  >
                                           <!-- TrustBox widget - Micro Combo -->
                                    <div  class="trustpilot-widget ratingStar"  id="trustboxD4{{couponIndexSelectCoupon}}" data-locale="en-US" data-template-id="5419b6ffb0d04a076446a9af" data-businessunit-id="5feb84d1e2aa300001625337" data-style-height="25px" data-style-width="420px" data-theme="light" [attr.data-location]="facilityD.garage_code">
                                        <a href="https://www.trustpilot.com/review/iconparkingsystems.com" target="_blank" rel="noopener"></a>
                                    </div>
  <!-- End TrustBox widget -->
                                    </div>
                                </div>
                                <div class="row mt-2 align-items-center">
                              
                                    <div class="col">
                                        
                                        <ul class="amenity">
                                            <li (mouseenter) ="mouseEnter('mobile',facilityD.facility_id)" *ngIf="facilityD.is_mobile_pay_enabled =='1'" triggers="mouseenter:mouseleave" containerClass="parkSafeContentBlock"   [popover]="mobileData"  placement="top left" [outsideClick]="true"  ><img style="width: 45px;"[src]="payNow_btn" alt="image"></li>
                                            <li (mouseenter) ="mouseEnter('parksafe',facilityD.facility_id)" *ngIf="facilityD.is_parksafe =='1'"  containerClass="parkSafeContentBlock"  triggers="mouseenter:mouseleave"  [popover]="parksafeData"  placement="top left" [outsideClick]="true"  ><img style="width: 45px;" [src]="parkSafesmall" alt="image"></li>
                                            <li (mouseenter) ="mouseEnter('parksafe',facilityD.facility_id)" *ngIf="facilityD.is_24hour_open =='1'"><img [src]="open_24hr_icon" alt="image"></li>
                                            <li (mouseenter) ="mouseEnter('indoor',facilityD.facility_id)" *ngIf="facilityD.is_indoor_parking =='1'"><img [src]="indoor_icon" alt="image"></li>
                                            <li (mouseenter) ="mouseEnter('outdoor',facilityD.facility_id)"*ngIf="facilityD.is_outdoor_parking =='1'"><img [src]="outdoor_icon" alt="image"></li>
                                            <li (mouseenter) ="mouseEnter('tesla',facilityD.facility_id)" *ngIf="facilityD.is_tesla_charging =='1'"><img [src]="tesla_charger_icon" alt="image"></li>
                                            <li (mouseenter) ="mouseEnter('generic',facilityD.facility_id)" *ngIf="aminitiesCounter(facilityD) <= count && facilityD.is_generic_ev_charging =='1'"><img [src]="ev_charge_icon" alt="image"></li>
                                            <li (mouseenter) ="mouseEnter('oversize',facilityD.facility_id)" *ngIf="aminitiesCounter(facilityD) <= count &&  facilityD.is_oversize =='1'"><img [src]="oversized_icon" alt="image"></li>
                                            <li (mouseenter) ="mouseEnter('motorcycle',facilityD.facility_id)" *ngIf="aminitiesCounter(facilityD) <= count && facilityD.is_motorcycle_parking =='1'"><img [src]="motorcycle_icon" alt="image"></li>
                                            <li (mouseenter) ="mouseEnter('monthly',facilityD.facility_id)" *ngIf="aminitiesCounter(facilityD) <= count && facilityD.is_monthly =='1'"><img [src]="monthly_icon" alt="image"></li>
                                             <li *ngIf="aminitiesCounter(facilityD) > count" ><p class="mar-bottom-0"><b>{{aminitiesCounter(facilityD) - count}}+</b></p></li>
                                        </ul>
                                            <input type="hidden" id="mobile{{facilityD.facility_id}}" name="amenities{{facilityD.facility_id}}" [(ngModel)]="facilityD.is_mobile_pay_enabled_desc">
                                            <input type="hidden" id="parksafe{{facilityD.facility_id}}" name="amenities{{facilityD.facility_id}}" [(ngModel)]="facilityD.is_parksafe_desc">
                                            <input type="hidden" id="24open{{facilityD.facility_id}}" name="amenities{{facilityD.facility_id}}" [(ngModel)]="facilityD.is_24hour_open_desc">
                                            <input type="hidden" id="indoor{{facilityD.facility_id}}" name="amenities{{facilityD.facility_id}}" [(ngModel)]="facilityD.is_indoor_parking_desc">
                                            <input type="hidden" id="outdoor{{facilityD.facility_id}}" name="amenities{{facilityD.facility_id}}" [(ngModel)]="facilityD.is_outdoor_parking_desc">
                                            <input type="hidden" id="tesla{{facilityD.facility_id}}" name="amenities{{facilityD.facility_id}}" [(ngModel)]="facilityD.is_tesla_charging_desc">
                                            <input type="hidden" id="generic{{facilityD.facility_id}}" name="amenities{{facilityD.facility_id}}" [(ngModel)]="facilityD.is_generic_ev_charging_desc">
                                            <input type="hidden" id="oversize{{facilityD.facility_id}}" name="amenities{{facilityD.facility_id}}" [(ngModel)]="facilityD.is_oversize_desc">
                                            <input type="hidden" id="motorcycle{{facilityD.facility_id}}" name="amenities{{facilityD.facility_id}}" [(ngModel)]="facilityD.is_motorcycle_parking_desc">
                                            <input type="hidden" id="monthly{{facilityD.facility_id}}" name="amenities{{facilityD.facility_id}}" [(ngModel)]="facilityD.is_monthly_desc">
                                    </div>
                                    <div  *ngIf="searchCriteria.type=='daily'" class=" col-auto onfacilityloadshow_{{facilityD.facility_id}}">
                                            <button *ngIf="isPartners == false && facilityD.price!='N/A' && facilityD.price != 0 && facilityD.price > 0 && facilityD.price < 2500000 && (facilityD.ticketech_id != '' || (facilityD?.parkonect_id !='' && facilityD?.parkonect_id !=null) || (facilityD?.valet_manager_id != '' && facilityD?.valet_manager_id != null) )" id="sorting_{{facilityD.facility_id}}" class="btn btn-primary btn-fill sorting_{{facilityD.facility_id}}" (click)="moveToReservation(facilityD.facility_id, facilityD.price, facilityD.monthly_rate_main,facilityD.warning_on_reservation,facilityD.warning_on_reservation_msg);sideBarClick()">
                                                <span > BOOK NOW</span>
                                            </button>
                                            <!-- <h3 *ngIf="isPartners == true && facilityD.price!='N/A'" id="sorting_{{facilityD.facility_id}}" class="partner-price">
                                                <span> ${{facilityD.price}} </span>
                                            </h3> -->
                                            <!-- <h3 *ngIf="isPartners == true && facilityD.price=='N/A'" id="sorting_{{facilityD.facility_id}}" class="partner-price">
                                                <span> {{facilityD.price}} </span>
                                            </h3> -->
                                            <!-- <p class="facPrice hidden_Price_{{facilityD.facility_id}} hidden"> {{ facilityD.price }}</p> -->
                                            <!-- <span class="hide baserate narecord_{{facilityD.facility_id}}"><h2 class="text-primary"> NA</h2></span> -->
                                        </div>
                                        <div class="col" *ngIf="searchCriteria.type=='monthly'">
                                            <button *ngIf="(facilityD.monthly_rate_main>0 && facilityD.monthly_rate_main < 2500000) && isPartners == false &&  searchCriteria.type=='monthly' && facilityD.monthly_rate_main!='N/A*'" id="sorting_{{facilityD.facility_id}}" class="btn btn-primary btn-fill " (click)="moveToReservation(facilityD.facility_id, facilityD.price, facilityD.monthly_rate_main,facilityD.warning_on_reservation,facilityD.warning_on_reservation_msg);sideBarClick()">
                                                <span>BOOK NOW </span>
                                            </button>
                                            <!-- <button style="cursor: default;" *ngIf="(facilityD.monthly_rate_main < 1 || facilityD.monthly_rate_main > 2400000) && isPartners == false &&  searchCriteria.type=='monthly' && facilityD.monthly_rate_main!='N/A*'" id="sorting_{{facilityD.facility_id}}" class="btn btn-primary btn-fill ">
                                                    <span> N/A </span>
                                            </button> -->
                                            <p class="facPrice hidden">{{ facilityD.monthly_rate_main }}</p>
                                            <!-- <span class="baserate" *ngIf="isPartners == false &&  searchCriteria.type=='monthly' && facilityD.monthly_rate_main=='N/A*'"><h2 class="text-primary"> NA</h2></span> -->
                                        </div>
                                  
                                </div>
                               
                                <div class="listBody  hidden">
                                    <div class="row">
                                        <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8">
                                            <h4 class="text-uppercase mar-bottom-0 mar-top-0" parkTitle>
                                                {{facilityD.entrance_location}}
                                            </h4>
                                            <p class="mar-bottom-xs ">{{facilityD?.facility_name_modified}}</p>
                                        </div>
                                        <div class="col-xs-4 col-sm-4 col-md-4 text-right col-lg-4">
                                            <a style="cursor:pointer" id="details_{{facilityD.facility_id}}" class="btn btn-xs btn-link" (click)="openDetails(facilityD, searchCriteria.type)">Details &nbsp; &nbsp; <fa name="angle-right"></fa></a>
                                        </div>
                                    </div>
                                    <div class="flex-row no-gutters bottom-xs">
                                        <div class="col">
                                            <p class="map-mark">
                                                <img [src]="map_market_location" height="14"> &nbsp; {{facilityD.distance | distanceconversion}}</p>
        
                                            <p class="facDistance hidden">{{ facilityD.distance }}</p>
                                            <ul class="amenity">
                                                <li *ngIf="facilityD.is_24hour_open =='1'"><img [src]="open_24hr_icon" alt="image"></li>
                                                <li *ngIf="facilityD.is_indoor_parking =='1'"><img [src]="indoor_icon" alt="image"></li>
                                                <li *ngIf="facilityD.is_outdoor_parking =='1'"><img [src]="outdoor_icon" alt="image"></li>
                                                <li *ngIf="facilityD.is_tesla_charging =='1'"><img [src]="tesla_charger_icon" alt="image"></li>
                                                <li *ngIf="facilityD.is_generic_ev_charging =='1'"><img [src]="ev_charge_icon" alt="image"></li>
                                                <li *ngIf="facilityD.is_oversize =='1'"><img [src]="oversized_icon" alt="image"></li>
                                                <li *ngIf="facilityD.is_motorcycle_parking =='1'"><img [src]="motorcycle_icon" alt="image"></li>
                                                <li *ngIf="facilityD.is_monthly =='1'"><img [src]="monthly_icon" alt="image"></li>
                                            </ul>
        
                                        </div>
                                        <div *ngIf="searchCriteria.type=='daily'" class="col align-self-end text-right onfacilityload_{{facilityD.facility_id}}">
                                            <a class="loadingprice">
                                                <fa animation="spin" name="circle-o-notch">
                                                    <i aria-hidden="true" class="fa fa-circle-o-notch fa-spin"></i>
                                                </fa>
                                            </a>
                                        </div>
        
                                        <div *ngIf="searchCriteria.type=='daily'" class="col align-self-end text-right onfacilityloadshow_{{facilityD.facility_id}} hide">
                                            <button *ngIf="isPartners == false" id="sorting_{{facilityD.facility_id}}" class="btn btn-primary btn-fill sorting_{{facilityD.facility_id}}" (click)="moveToReservation(facilityD.facility_id, facilityD.price, facilityD.monthly_rate_main,facilityD.warning_on_reservation,facilityD.warning_on_reservation_msg);">
                               <span > ${{facilityD.price}} | BOOK NOW</span>
                               </button>
                                            <h3 sourceFont *ngIf="isPartners == true && facilityD.price!='N/A'" id="sorting_{{facilityD.facility_id}}" class="partner-price">
                                                <span> ${{facilityD.price}} </span>
                                            </h3>
                                            <h3 sourceFont *ngIf="isPartners == true && facilityD.price=='N/A'" id="sorting_{{facilityD.facility_id}}" class="partner-price">
                                                <span> {{facilityD.price}} </span>
                                            </h3>
                                            <p class="facPrice hidden_Price_{{facilityD.facility_id}} hidden"> {{ facilityD.price }}</p>
                                            <span class="hide baserate narecord_{{facilityD.facility_id}}"><h2 class="text-primary"> N/A</h2></span>
                                        </div>
        
                                        <div *ngIf="searchCriteria.type=='monthly'" class="col align-self-end text-right">
                                            <button *ngIf="(facilityD.monthly_rate_main>0 && facilityD.monthly_rate_main < 2500000) && isPartners == false &&  searchCriteria.type=='monthly' && facilityD.monthly_rate_main!='N/A*'" id="sorting_{{facilityD.facility_id}}" class="btn btn-primary btn-fill " (click)="moveToReservation(facilityD.facility_id, facilityD.price, facilityD.monthly_rate_main,facilityD.warning_on_reservation,facilityD.warning_on_reservation_msg);">
                                                <span> ${{facilityD.monthly_rate_main}} | BOOK NOW </span>
                                            </button>
                                            <button style="cursor: default;" *ngIf="(facilityD.monthly_rate_main < 1 || facilityD.monthly_rate_main > 2400000) && isPartners == false &&  searchCriteria.type=='monthly' && facilityD.monthly_rate_main!='N/A*'" id="sorting_{{facilityD.facility_id}}" class="btn btn-primary btn-fill ">
                                                    <span> N/A </span>
                                            </button>
                                            <p class="facPrice hidden">{{ facilityD.monthly_rate_main }}</p>
                                            <span class="baserate" *ngIf="isPartners == false &&  searchCriteria.type=='monthly' && facilityD.monthly_rate_main=='N/A*'"><h2 class="text-primary"> NA</h2></span>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ng-container>
                        <ng-container *ngIf="distancesortingmonthly && searchCriteria.type=='monthly'">
                            <li *ngFor="let facilityD of facilityData.facilities | sorting:sortTypes; let couponIndexSelectCoupon = index"  class="facility ererereeerer" data-id="{{facilityD.facility_id}}">
                                <div class="row">   
                                    <div class="col-4 clipImage">
                                        <img *ngIf="facilityD.photo_url" [src]="facilityD.photo_url" class="img-responsive rounded" height="234" alt="image">
                                        <img *ngIf="!facilityD.photo_url" [src]="park_garage" class="img-responsive notImg rounded" alt="image">
                                    </div>
                                    <div class="col">
                                        <div class="row">


                                        
                                    <div class="col p-0">
                                        <h4 class="text-uppercase mar-bottom-0 mar-top-0" parkTitle>
                                            {{facilityD.entrance_location}}
                                        </h4>
                                        <p class="mb-0 ">{{facilityD?.facility_name_modified}}</p>
                                        <p class="map-mark">
                                            <img [src]="map_market_location" height="14">&nbsp;&nbsp;{{facilityD.distance | distanceconversion}}</p>
        
                                        <p class="facDistance hidden">{{ facilityD.distance }}</p>
                                    </div>
                                    <div class="col-auto text-center" *ngIf="searchCriteria.type=='monthly'"> 
                                        <div *ngIf="facilityD.monthly_campaign =='1'" class="specialPriceBadgeTop">SPECIAL PRICE</div> 
                                        <small>Rates from</small>
                                       
                                            <h3 sourceFont class="font-weight-bold m-0" *ngIf="(facilityD.monthly_rate_main>0 && facilityD.monthly_rate_main < 2500000) && isPartners == false &&  searchCriteria.type=='monthly' && facilityD.monthly_rate_main!='N/A*'" >
                                                ${{facilityD.monthly_rate_main}}
                                            </h3>
                                            <h3 sourceFont class="font-weight-bold m-0 sold-out-price"  style="cursor: default;" *ngIf="(facilityD.monthly_rate_main < 1 || facilityD.monthly_rate_main > 2400000) && isPartners == false &&  searchCriteria.type=='monthly' && facilityD.monthly_rate_main!='N/A*'">
                                               Sold Out 
                                            </h3>
                                            <p class="facPrice hidden">{{ facilityD.monthly_rate_main }}</p>
                                            <h3 class="font-weight-bold m-0 sold-out-price"  *ngIf="isPartners == false &&  searchCriteria.type=='monthly' && facilityD.monthly_rate_main=='N/A*'"> Sold Out</h3>
                                        </div>
                                    </div>
                                        <div class="row " *ngIf="facilityD?.is_alternative_facility> 0 && facilityD?.is_24hour_open == '0' && searchCriteria.type == 'monthly'">
                                            <div class="col p-0 f-10 mt-2">
                                               *Please be advised of amended hours at this location.<br class="hidden-xs"> <a [href]="temporarilyClosedUrl" target="_blank">
                                                     Click Here
                                                </a>for alternative garage information*
                                            </div>
                                        </div>
                                    </div>

                                    
                                </div>
                                <div class="row">
                                    <div class="col trustpilot-rate  "  >
                                        <!-- TrustBox widget - Micro Combo -->
                                    <div  class="trustpilot-widget ratingStarMonthly" id="trustboxM3{{couponIndexSelectCoupon}}" data-locale="en-US" data-template-id="5419b6ffb0d04a076446a9af" data-businessunit-id="5feb84d1e2aa300001625337" data-style-height="25px" data-style-width="420px" data-theme="light" [attr.data-location]="facilityD.garage_code">
                                        <a href="https://www.trustpilot.com/review/iconparkingsystems.com" target="_blank" rel="noopener"></a>
                                    </div>
  <!-- End TrustBox widget -->
                                    </div>
                                </div>
                                <div class="row mt-2 align-items-center ">
                                    <div class="col">
                                        <ul class="amenity">
                                            <li (mouseenter) ="mouseEnter('mobile1',facilityD.facility_id)" *ngIf="facilityD.is_mobile_pay_enabled =='1'" triggers="mouseenter:mouseleave" containerClass="parkSafeContentBlock"   [popover]="mobileData"><img style="width: 45px;"[src]="payNow_btn" alt="image"></li>
                                            <li (mouseenter) ="mouseEnter('parksafe1',facilityD.facility_id)" *ngIf="facilityD.is_parksafe =='1'" triggers="mouseenter:mouseleave" containerClass="parkSafeContentBlock"    [popover]="parksafeData"  placement="top left" [outsideClick]="true"  ><img style="width: 45px;" [src]="parkSafesmall" alt="image"></li>
                                            <li (mouseenter) ="mouseEnter('24open1',facilityD.facility_id)" *ngIf="facilityD.is_24hour_open =='1'"><img [src]="open_24hr_icon" alt="image"></li>
                                            <li (mouseenter) ="mouseEnter('indoor1',facilityD.facility_id)" *ngIf="facilityD.is_indoor_parking =='1'"><img [src]="indoor_icon" alt="image"></li>
                                            <li (mouseenter) ="mouseEnter('outdoor1',facilityD.facility_id)" *ngIf="facilityD.is_outdoor_parking =='1'"><img [src]="outdoor_icon" alt="image"></li>
                                            <li (mouseenter) ="mouseEnter('tesla1',facilityD.facility_id)" *ngIf="facilityD.is_tesla_charging =='1'"><img [src]="tesla_charger_icon" alt="image"></li>
                                            <li (mouseenter) ="mouseEnter('generic1',facilityD.facility_id)" *ngIf="aminitiesCounter(facilityD) <= count && facilityD.is_generic_ev_charging =='1'"><img [src]="ev_charge_icon" alt="image"></li>
                                            <li (mouseenter) ="mouseEnter('oversize1',facilityD.facility_id)" *ngIf="aminitiesCounter(facilityD) <= count && facilityD.is_oversize =='1'"><img [src]="oversized_icon" alt="image"></li>
                                            <li (mouseenter) ="mouseEnter('motorcycle1',facilityD.facility_id)" *ngIf="aminitiesCounter(facilityD) <= count && facilityD.is_motorcycle_parking =='1'"><img [src]="motorcycle_icon" alt="image"></li>
                                            <li (mouseenter) ="mouseEnter('monthly1',facilityD.facility_id)" *ngIf="aminitiesCounter(facilityD) <= count && facilityD.is_monthly =='1'"><img [src]="monthly_icon" alt="image"></li>
                                            <li *ngIf="aminitiesCounter(facilityD) > count"><p class="mar-bottom-0"><b>{{aminitiesCounter(facilityD) - count}}+</b></p></li>
                                        </ul>
                                        <input type="hidden" id="mobile1{{facilityD.facility_id}}" name="amenities{{facilityD.facility_id}}" [(ngModel)]="facilityD.is_mobile_pay_enabled_desc">
                                        <input type="hidden" id="parksafe1{{facilityD.facility_id}}" name="amenities{{facilityD.facility_id}}" [(ngModel)]="facilityD.is_parksafe_desc">
                                        <input type="hidden" id="24open1{{facilityD.facility_id}}" name="amenities{{facilityD.facility_id}}" [(ngModel)]="facilityD.is_24hour_open_desc">
                                        <input type="hidden" id="indoor1{{facilityD.facility_id}}" name="amenities{{facilityD.facility_id}}" [(ngModel)]="facilityD.is_indoor_parking_desc">
                                        <input type="hidden" id="outdoor1{{facilityD.facility_id}}" name="amenities{{facilityD.facility_id}}" [(ngModel)]="facilityD.is_outdoor_parking_desc">
                                        <input type="hidden" id="tesla1{{facilityD.facility_id}}" name="amenities{{facilityD.facility_id}}" [(ngModel)]="facilityD.is_tesla_charging_desc">
                                        <input type="hidden" id="generic1{{facilityD.facility_id}}" name="amenities{{facilityD.facility_id}}" [(ngModel)]="facilityD.is_generic_ev_charging_desc">
                                        <input type="hidden" id="oversize1{{facilityD.facility_id}}" name="amenities{{facilityD.facility_id}}" [(ngModel)]="facilityD.is_oversize_desc">
                                        <input type="hidden" id="motorcycle1{{facilityD.facility_id}}" name="amenities{{facilityD.facility_id}}" [(ngModel)]="facilityD.is_motorcycle_parking_desc">
                                        <input type="hidden" id="monthly1{{facilityD.facility_id}}" name="amenities{{facilityD.facility_id}}" [(ngModel)]="facilityD.is_monthly_desc">
                                    </div>
        
                                    <div *ngIf="searchCriteria.type=='daily'" class=" col-auto text-cetner onfacilityload_{{facilityD.facility_id}}">
                                      
                                        <button *ngIf="searchCriteria.type=='daily' && facilityD.price!='N/A'" id="sorting_{{facilityD.facility_id}}" class="btn btn-primary btn-fill " (click)="moveToReservation(facilityD.facility_id, facilityD.price, facilityD.monthly_rate_main,facilityD.warning_on_reservation,facilityD.warning_on_reservation_msg);sideBarClick()">
                                  <span >  BOOK NOW</span>
                                  </button>
                                        <p class="coupon_threshold_price hidden">{{ facilityD.coupon_threshold_price }}</p>
                                        <p class="facPrice hidden_Price_{{facilityD.facility_id}} hidden">{{ facilityD.price }}</p>
                                        <!-- <span class="baserate" *ngIf="searchCriteria.type=='daily' && facilityD.price=='N/A'"><h2 class="text-primary"> NA</h2></span> -->
                                    </div>
                                    <div *ngIf="searchCriteria.type=='daily'" class=" col-auto text-center onfacilityloadshow_{{facilityD.facility_id}} hide">
                                      
                                        <button class="btn btn-primary btn-fill sorting_{{facilityD.facility_id}}" (click)="moveToReservation(facilityD.facility_id, facilityD.price, facilityD.monthly_rate_main,facilityD.warning_on_reservation,facilityD.warning_on_reservation_msg);sideBarClick()">
                             <span > BOOK NOW</span>
                             </button>
        
                                        <p class="facPrice hidden_Price_{{facilityD.facility_id}} hidden">{{ facilityD.price }}</p>
                                        <!-- <span class="hide baserate narecord_{{facilityD.facility_id}}"><h2 class="text-primary"> NA</h2></span> -->
                                    </div>
        
                                    <div *ngIf="searchCriteria.type=='monthly'" class="col-auto texxt-cetner">
                                    
                                        <button *ngIf="(facilityD.monthly_rate_main>0 && facilityD.monthly_rate_main < 2500000) && isPartners == false &&  searchCriteria.type=='monthly' && facilityD.monthly_rate_main!='N/A*'" class="btn btn-primary btn-fill " (click)="moveToReservation(facilityD.facility_id, facilityD.price, facilityD.monthly_rate_main,facilityD.warning_on_reservation,facilityD.warning_on_reservation_msg);sideBarClick()">
                                            <span > BUY MONTHLY</span>
                                            </button>
                                        <!-- <button style="cursor: default;" *ngIf="(facilityD.monthly_rate_main < 1 || facilityD.monthly_rate_main > 2400000) && isPartners == false &&  searchCriteria.type=='monthly' && facilityD.monthly_rate_main!='N/A*'" class="btn btn-primary btn-fill " >
                                              <span> N/A </span>
                                        </button> -->
                                        <p class="facPrice hidden">{{ facilityD.monthly_rate_main }}</p>
                                        <!-- <span class="baserate" *ngIf="isPartners == false &&  searchCriteria.type=='monthly' && facilityD.monthly_rate_main=='N/A*'"><h2 class="text-primary"> NA</h2></span> -->
                                    </div>
                                </div>
                            
                                
                            </li>
                        </ng-container>
                        <ng-container *ngIf="pricesorting && searchCriteria.type=='monthly'">
                            <li *ngFor="let facilityD of facilityDataMonthly.facilities | sorting:'monthlynew'; let couponIndexSelectCoupon = index" class="facility bbbbbb" data-id="{{facilityD.facility_id}}">
                                <div class="row">
                                    <div class="col-4 clipImage">
                                    <img *ngIf="facilityD.photo_url" [src]="facilityD.photo_url" class="img-responsive rounded" height="234" alt="image">
                                    <img *ngIf="!facilityD.photo_url" [src]="park_garage" class="img-responsive notImg rounded" alt="image">
                                    </div>
                                    <div class="col">
                                        <div class="row">
                                            
                                        
                                    
                                    <div class="col p-0">
                                        <h4 class="text-uppercase mar-bottom-0 mar-top-0" parkTitle>
                                            {{facilityD.entrance_location}}
                                        </h4>
                                        <p class="mb-0 ">{{facilityD?.facility_name_modified}}</p>
                                        <p class="map-mark">
                                            <img [src]="map_market_location" height="14">&nbsp;&nbsp;{{facilityD.distance | distanceconversion}}</p>
        
                                        <p class="facDistance hidden">{{ facilityD.distance }}</p>
                                    </div>
                                    <div class="col-auto text-center ">
                                        <small *ngIf="searchCriteria.type=='daily'">Rates from</small>
                                        <div *ngIf="searchCriteria.type=='daily'" class=" onfacilityload_{{facilityD.facility_id}}">
                                            <h3 sourceFont class="font-wight-bold m-0" *ngIf=" searchCriteria.type=='daily' && facilityD.price!='N/A'" id="sorting_{{facilityD.facility_id}}"  (click)="moveToReservation(facilityD.facility_id, facilityD.price, facilityD.monthly_rate_main,facilityD.warning_on_reservation,facilityD.warning_on_reservation_msg);">
                                     ${{facilityD.price}}
                                            </h3>
                                            <p id="threshold_{{facilityD.facility_id}}" class="coupon_threshold_price hidden">{{ facilityD.coupon_threshold_price }}</p>
                                            <p class="facPrice hidden_Price_{{facilityD.facility_id}} hidden">{{ facilityD.price }}</p>
                                            <h3 *ngIf=" searchCriteria.type=='daily' && facilityD.price=='N/A'">NA</h3>
                                        </div>
                                        <div *ngIf="searchCriteria.type=='daily'" class=" onfacilityloadshow_{{facilityD.facility_id}} hide">
                                            <h3 sourceFont class="font-wight-bold m-0" id="sorting_{{facilityD.facility_id}}" class="btn btn-primary btn-fill sorting_{{facilityD.facility_id}}" (click)="moveToReservation(facilityD.facility_id, facilityD.price, facilityD.monthly_rate_main,facilityD.warning_on_reservation,facilityD.warning_on_reservation_msg);">
                              ${{facilityD.price}} 
                                            </h3>
        
                                            <p class="facPrice hidden_Price_{{facilityD.facility_id}} hidden">{{ facilityD.price }}</p>
                                            <h3 class=" font-weight-bold m-0 narecord_{{facilityD.facility_id}}">NA</h3>
                                        </div>
        
                                        <div *ngIf="searchCriteria.type=='monthly'" >
                                            <h3 sourceFont class="font-wight-bold m-0"  *ngIf="(facilityD.monthly_rate_mains >0 && facilityD.monthly_rate_mains < 2500000) && isPartners == false &&  searchCriteria.type=='monthly' && facilityD.monthly_rate_main!='N/A*'" id="sorting_{{facilityD.facility_id}}"  (click)="moveToReservation(facilityD.facility_id, facilityD.price, facilityD.monthly_rate_main,facilityD.warning_on_reservation,facilityD.warning_on_reservation_msg);">
                                               ${{facilityD.monthly_rate_main}}
                                            </h3>
                                            <h3 sourceFont class="font-wight-bold m-0" style="cursor: default;" *ngIf="(facilityD.monthly_rate_main < 1 || facilityD.monthly_rate_main > 2400000) && isPartners == false &&  searchCriteria.type=='monthly' && facilityD.monthly_rate_main!='N/A*'" id="sorting_{{facilityD.facility_id}}"  >
                                                N/A 
                                            </h3>
                                            <p class="facPrice hidden">{{ facilityD.monthly_rate_main }}</p>
                                            <h3 class="font-wight-bold m-0" *ngIf="isPartners == false &&  searchCriteria.type=='monthly' && facilityD.monthly_rate_main=='N/A*'"> NA</h3>
                                        </div>
                                    </div>
                                </div>
                                <div class="row " *ngIf="facilityD?.is_alternative_facility> 0 && facilityD?.is_24hour_open == '0' && searchCriteria.type == 'monthly'">
                                    <div class="col p-0 f-10 mt-2">
                                       *Please be advised of amended hours at this location.<br class="hidden-xs"> <a [href]="temporarilyClosedUrl" target="_blank">
                                             Click Here
                                        </a>for alternative garage information*
                                    </div>
                                </div>
                            </div>
                                    
                                </div>
                                <div class="row">
                                    <div class="col trustpilot-rate  "  >
                                        <!-- TrustBox widget - Micro Combo -->
                                    <div  class="trustpilot-widget ratingStarMonthly" id="trustboxM4{{couponIndexSelectCoupon}}" data-locale="en-US" data-template-id="5419b6ffb0d04a076446a9af" data-businessunit-id="5feb84d1e2aa300001625337" data-style-height="25px" data-style-width="420px" data-theme="light" [attr.data-location]="facilityD.garage_code">
                                        <a href="https://www.trustpilot.com/review/iconparkingsystems.com" target="_blank" rel="noopener"></a>
                                    </div>
  <!-- End TrustBox widget -->
                                    </div>
                                </div>
                                    <div class="row mt-2 align-items-center">
                                        <div class="col">
                                          
                                            <ul class="amenity">
                                                <li (mouseenter) ="mouseEnter('mobile2',facilityD.facility_id)" *ngIf="facilityD.is_mobile_pay_enabled =='1'" triggers="mouseenter:mouseleave" containerClass="parkSafeContentBlock"   [popover]="mobileData"><img style="width: 45px;"[src]="payNow_btn" alt="image"></li>
                                                <li (mouseenter) ="mouseEnter('parksafe2',facilityD.facility_id)" *ngIf="facilityD.is_parksafe =='1'" triggers="mouseenter:mouseleave" containerClass="parkSafeContentBlock"   [popover]="parksafeData"  placement="top left" [outsideClick]="true"  ><img style="width: 45px;" [src]="parkSafesmall" alt="image"></li>
                                                <li (mouseenter) ="mouseEnter('24open2',facilityD.facility_id)" *ngIf="facilityD.is_24hour_open =='1'"><img [src]="open_24hr_icon" alt="image"></li>
                                                <li (mouseenter) ="mouseEnter('indoor2',facilityD.facility_id)" *ngIf="facilityD.is_indoor_parking =='1'"><img [src]="indoor_icon" alt="image"></li>
                                                <li (mouseenter) ="mouseEnter('outdoor2',facilityD.facility_id)" *ngIf="facilityD.is_outdoor_parking =='1'"><img [src]="outdoor_icon" alt="image"></li>
                                                <li (mouseenter) ="mouseEnter('tesla2',facilityD.facility_id)" *ngIf="facilityD.is_tesla_charging =='1'"><img [src]="tesla_charger_icon" alt="image"></li>
                                                <li (mouseenter) ="mouseEnter('generic2',facilityD.facility_id)" *ngIf="aminitiesCounter(facilityD) <= count && facilityD.is_generic_ev_charging =='1'"><img [src]="ev_charge_icon" alt="image"></li>
                                                <li (mouseenter) ="mouseEnter('oversize2',facilityD.facility_id)" *ngIf="aminitiesCounter(facilityD) <= count && facilityD.is_oversize =='1'"><img [src]="oversized_icon" alt="image"></li>
                                                <li (mouseenter) ="mouseEnter('motorcycle2',facilityD.facility_id)" *ngIf="aminitiesCounter(facilityD) <= count && facilityD.is_motorcycle_parking =='1'"><img [src]="motorcycle_icon" alt="image"></li>
                                                <li (mouseenter) ="mouseEnter('monthly2',facilityD.facility_id)" *ngIf="aminitiesCounter(facilityD) <= count && facilityD.is_monthly =='1'"><img [src]="monthly_icon" alt="image"></li>
                                                <li *ngIf="aminitiesCounter(facilityD) > count"><p class="mar-bottom-0"><b>{{aminitiesCounter(facilityD) - count}}+</b></p></li>
                                            </ul>
                                            <input type="hidden" id="mobile2{{facilityD.facility_id}}" name="amenities{{facilityD.facility_id}}" [(ngModel)]="facilityD.is_mobile_pay_enabled_desc">
                                            <input type="hidden" id="parksafe2{{facilityD.facility_id}}" name="amenities{{facilityD.facility_id}}" [(ngModel)]="facilityD.is_parksafe_desc">
                                            <input type="hidden" id="24open2{{facilityD.facility_id}}" name="amenities{{facilityD.facility_id}}" [(ngModel)]="facilityD.is_24hour_open_desc">
                                            <input type="hidden" id="indoor2{{facilityD.facility_id}}" name="amenities{{facilityD.facility_id}}" [(ngModel)]="facilityD.is_indoor_parking_desc">
                                            <input type="hidden" id="outdoor2{{facilityD.facility_id}}" name="amenities{{facilityD.facility_id}}" [(ngModel)]="facilityD.is_outdoor_parking_desc">
                                            <input type="hidden" id="tesla2{{facilityD.facility_id}}" name="amenities{{facilityD.facility_id}}" [(ngModel)]="facilityD.is_tesla_charging_desc">
                                            <input type="hidden" id="generic2{{facilityD.facility_id}}" name="amenities{{facilityD.facility_id}}" [(ngModel)]="facilityD.is_generic_ev_charging_desc">
                                            <input type="hidden" id="oversize2{{facilityD.facility_id}}" name="amenities{{facilityD.facility_id}}" [(ngModel)]="facilityD.is_oversize_desc">
                                            <input type="hidden" id="motorcycle2{{facilityD.facility_id}}" name="amenities{{facilityD.facility_id}}" [(ngModel)]="facilityD.is_motorcycle_parking_desc">
                                            <input type="hidden" id="monthly2{{facilityD.facility_id}}" name="amenities{{facilityD.facility_id}}" [(ngModel)]="facilityD.is_monthly_desc">
                                        </div>
        
                                        <div *ngIf="searchCriteria.type=='daily'" class="col-auto onfacilityload_{{facilityD.facility_id}}">
                                            <button *ngIf=" searchCriteria.type=='daily' && facilityD.price!='N/A'" id="sorting_{{facilityD.facility_id}}" class="btn btn-primary btn-fill " (click)="moveToReservation(facilityD.facility_id, facilityD.price, facilityD.monthly_rate_main,facilityD.warning_on_reservation,facilityD.warning_on_reservation_msg);sideBarClick()">
                                      <span > BOOK NOW</span>
                                      </button>
                                            <p id="threshold_{{facilityD.facility_id}}" class="coupon_threshold_price hidden">{{ facilityD.coupon_threshold_price }}</p>
                                            <p class="facPrice hidden_Price_{{facilityD.facility_id}} hidden">{{ facilityD.price }}</p>
                                            <span class="baserate" *ngIf=" searchCriteria.type=='daily' && facilityD.price=='N/A'"><h2 > NA</h2></span>
                                        </div>
                                        <div *ngIf="searchCriteria.type=='daily'" class="col-auto onfacilityloadshow_{{facilityD.facility_id}} hide">
                                            <button *ngIf="(facilityD.ticketech_id != '' || (facilityD?.parkonect_id !='' && facilityD?.parkonect_id !=null) || (facilityD?.valet_manager_id != '' && facilityD?.valet_manager_id != null) )" id="sorting_{{facilityD.facility_id}}" class="btn btn-primary btn-fill sorting_{{facilityD.facility_id}}" (click)="moveToReservation(facilityD.facility_id, facilityD.price, facilityD.monthly_rate_main,facilityD.warning_on_reservation,facilityD.warning_on_reservation_msg);sideBarClick()">
                                 <span > BOOK NOW</span>
                                 </button>
        
                                            <p class="facPrice hidden_Price_{{facilityD.facility_id}} hidden">{{ facilityD.price }}</p>
                                            <span class="hide baserate narecord_{{facilityD.facility_id}}"><h2> NA</h2></span>
                                        </div>
        
                                        <div *ngIf="searchCriteria.type=='monthly'" class="col-auto ">
                                            <button *ngIf="(facilityD.monthly_rate_mains >0 && facilityD.monthly_rate_mains < 2500000) && isPartners == false &&  searchCriteria.type=='monthly' && facilityD.monthly_rate_main!='N/A*'" id="sorting_{{facilityD.facility_id}}" class="btn btn-primary btn-fill " (click)="moveToReservation(facilityD.facility_id, facilityD.price, facilityD.monthly_rate_main,facilityD.warning_on_reservation,facilityD.warning_on_reservation_msg);sideBarClick()">
                                                <span> BOOK NOW </span>
                                                </button>
                                            <button style="cursor: default;" *ngIf="(facilityD.monthly_rate_main < 1 || facilityD.monthly_rate_main > 2400000) && isPartners == false &&  searchCriteria.type=='monthly' && facilityD.monthly_rate_main!='N/A*'" id="sorting_{{facilityD.facility_id}}" class="btn btn-primary btn-fill " >
                                                  <span> N/A </span>
                                            </button>
                                            <p class="facPrice hidden">{{ facilityD.monthly_rate_main }}</p>
                                            <span class="baserate" *ngIf="isPartners == false &&  searchCriteria.type=='monthly' && facilityD.monthly_rate_main=='N/A*'"><h2 > NA</h2></span>
                                        </div>
                                    </div>
                            </li>
                        </ng-container>
                        <ng-container *ngIf="distancelatestsort &&  searchCriteria.type=='daily'">
                            <li *ngFor="let facilityD of facilitylistprice | sorting:'distancedaily'; let couponIndexSelectCoupon = index" class="facility dfdddfd" data-id="{{facilityD.facility.facility_id}}">
                                <div class="row">
                                    <div class="col-sm-4 clipImage">
                                        <img *ngIf="facilityD.facility.photo_url" [src]="facilityD.facility.photo_url" class="img-responsive rounded"  alt="image">
                                    <img *ngIf="!facilityD.facility.photo_url" [src]="park_garage" class="img-responsive notImg rounded" alt="image">
                                    </div>
        
                                    <div class="col">
                                        <div class="row">

                                      
                                      
                                    <div class="col p-0">
                                        <h4 class="text-uppercase mar-bottom-0 mar-top-0" parkTitle>
                                            {{facilityD.facility.entrance_location}}
                                        </h4>
                                        <p class="mb-0 ">{{facilityD.facility.facility_name_modified}}</p>
                                            <p>
                                                <img [src]="map_market_location" height="12"> &nbsp; {{facilityD.facility.distance | distanceconversion}}</p>
                                    </div>
                                        <div class="col-auto text-center" *ngIf="searchCriteria.type=='daily'" >
                                            <small>Rates from</small>
                                            <h3 sourceFont class="font-weight-bold m-0" *ngIf=" searchCriteria.type=='daily' && !isPartners && facilityD.inventory.showprice!='N/A' && facilityD.inventory.is_gray==0 && (facilityD.ticketech_id != '' || (facilityD?.parkonect_id !='' && facilityD?.parkonect_id !=null) || (facilityD?.valet_manager_id != '' && facilityD?.valet_manager_id != null) )">
                                                ${{facilityD.inventory.showprice}}
                                            </h3>
        
                                            <h3 sourceFont *ngIf="searchCriteria.type=='daily' && isPartners && facilityD.inventory.showprice!='N/A' && facilityD.inventory.is_gray==0 && (facilityD.ticketech_id != '' || (facilityD?.parkonect_id !='' && facilityD?.parkonect_id !=null) || (facilityD?.valet_manager_id != '' && facilityD?.valet_manager_id != null) )" id="sorting_{{facilityD.facility_id}}"
                                               >
                                               ${{facilityD.inventory.showprice}} 
                                            </h3>
                                            <h3 class="font-weight-bold m-0 mobile-disable-price"   *ngIf=" searchCriteria.type=='daily' && facilityD.inventory.showprice!='N/A' && facilityD.inventory.is_gray==1 && (facilityD.ticketech_id != '' || (facilityD?.parkonect_id !='' && facilityD?.parkonect_id !=null) || (facilityD?.valet_manager_id != '' && facilityD?.valet_manager_id != null) )" 
                                                (click)="moveToReservation(facilityD.facility.facility_id, facilityD.facility.price, facilityD.facility.monthly_rate_main,facilityD.facility.warning_on_reservation,facilityD.facility.warning_on_reservation_msg);">
                                        ${{facilityD.inventory.showprice}} 
                                        </h3>
                                        <div   *ngIf="searchCriteria.type=='daily' && facilityD.inventory.showprice!='N/A'  && (facilityD.ticketech_id == '' && (facilityD?.parkonect_id == '' || facilityD?.parkonect_id ==null) && (facilityD?.valet_manager_id == '' || facilityD?.valet_manager_id == null) )" >
                                      {{changeRateUpdatedforsort(facilityD.inventory.showprice)}}
                                            <p id="threshold_{{facilityD.facility.facility_id}}" class="coupon_threshold_price hidden">{{ facilityD.facility.coupon_threshold_price }}</p>
                                            <p class="facPrice hidden_Price_{{facilityD.facility.facility_id}} hidden">{{ facilityD.inventory.showprice }}</p>
                                            <h3 class="baserate" *ngIf="  searchCriteria.type=='daily' && !isPartners && facilityD.inventory.showprice=='N/A'">NA</h3>
                                            <h3 *ngIf="searchCriteria.type=='daily' && isPartners && facilityD.inventory.showprice=='N/A' && facilityD.inventory.is_gray==0 && (facilityD.ticketech_id != '' || (facilityD?.parkonect_id !='' && facilityD?.parkonect_id !=null) || (facilityD?.valet_manager_id != '' && facilityD?.valet_manager_id != null) )" id="sorting_{{facilityD.facility_id}}"
                                                class="partner-price font-weight-bold m-0 ">
                                                {{facilityD.inventory.showprice}} 
                                            </h3>
                                            </div>
                                        </div>
          </div>
                                 <!-- <div class="row ">
                                            <div class="col p-0 f-10 mt-3">
                                               *Please be advised of amended hours at this location.<br class="hidden-xs"> <a [href]="temporarilyClosedUrl" target="_blank">
                                                     Click Here
                                                </a>for alternative garage information
                                            </div>
                                        </div> -->
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col trustpilot-rate  "  >
                                        <!-- TrustBox widget - Micro Combo -->
                                    <div  class="trustpilot-widget ratingStar" id="trustboxD5{{couponIndexSelectCoupon}}" data-locale="en-US" data-template-id="5419b6ffb0d04a076446a9af" data-businessunit-id="5feb84d1e2aa300001625337" data-style-height="25px" data-style-width="420px" data-theme="light" [attr.data-location]="facilityD.garage_code">
                                        <a href="https://www.trustpilot.com/review/iconparkingsystems.com" target="_blank" rel="noopener"></a>
                                    </div>
  <!-- End TrustBox widget -->
                                    </div>
                                </div>
                                <div class="row mt-2 align-items-center">
                                    <div class="col">
        
                                        <ul class="amenity">
                                            <li (mouseenter) ="mouseEnter('mobile3',facilityD.facility_id)" *ngIf="facilityD.is_mobile_pay_enabled =='1'" triggers="mouseenter:mouseleave" containerClass="parkSafeContentBlock"   [popover]="mobileData"><img style="width: 45px;"[src]="payNow_btn" alt="image"></li>
                                            <li (mouseenter) ="mouseEnter('parksafe3',facilityD.facility_id)" *ngIf="facilityD.is_parksafe =='1'" triggers="mouseenter:mouseleave" containerClass="parkSafeContentBlock"   [popover]="parksafeData"  placement="top left" [outsideClick]="true"  ><img style="width: 45px;" [src]="parkSafesmall" alt="image"></li>
                                            <li (mouseenter) ="mouseEnter('24open3',facilityD.facility_id)" *ngIf="facilityD.facility.is_24hour_open =='1'"><img [src]="open_24hr_icon" alt="image"></li>
                                            <li (mouseenter) ="mouseEnter('indoor3',facilityD.facility_id)" *ngIf="facilityD.facility.is_indoor_parking =='1'"><img [src]="indoor_icon" alt="image"></li>
                                            <li (mouseenter) ="mouseEnter('outdoor3',facilityD.facility_id)" *ngIf="facilityD.facility.is_outdoor_parking =='1'"><img [src]="outdoor_icon" alt="image"></li>
                                            <li (mouseenter) ="mouseEnter('tesla3',facilityD.facility_id)" *ngIf="facilityD.facility.is_tesla_charging =='1'"><img [src]="tesla_charger_icon" alt="image"></li>
                                            <li (mouseenter) ="mouseEnter('generic3',facilityD.facility_id)" *ngIf="aminitiesCounter(facilityD) <= count && facilityD.facility.is_generic_ev_charging =='1'"><img [src]="ev_charge_icon" alt="image"></li>
                                            <li (mouseenter) ="mouseEnter('oversize3',facilityD.facility_id)" *ngIf="aminitiesCounter(facilityD) <= count && facilityD.facility.is_oversize =='1'"><img [src]="oversized_icon" alt="image"></li>
                                            <li (mouseenter) ="mouseEnter('motorcycle3',facilityD.facility_id)" *ngIf="aminitiesCounter(facilityD) <= count && facilityD.facility.is_motorcycle_parking =='1'"><img [src]="motorcycle_icon" alt="image"></li>
                                            <li (mouseenter) ="mouseEnter('monthly3',facilityD.facility_id)" *ngIf="aminitiesCounter(facilityD) <= count && facilityD.facility.is_monthly =='1'"><img [src]="monthly_icon" alt="image"></li>
                                            <li *ngIf="aminitiesCounter(facilityD) > count"><p class="mar-bottom-0"><b>{{aminitiesCounter(facilityD) - count}}+</b></p></li>
                                        </ul>
                                        <input type="hidden" id="mobile3{{facilityD.facility_id}}" name="amenities{{facilityD.facility_id}}" [(ngModel)]="facilityD.is_mobile_pay_enabled_desc">
                                            <input type="hidden" id="parksafe3{{facilityD.facility_id}}" name="amenities{{facilityD.facility_id}}" [(ngModel)]="facilityD.is_parksafe_desc">
                                            <input type="hidden" id="24open3{{facilityD.facility_id}}" name="amenities{{facilityD.facility_id}}" [(ngModel)]="facilityD.is_24hour_open_desc">
                                            <input type="hidden" id="indoor3{{facilityD.facility_id}}" name="amenities{{facilityD.facility_id}}" [(ngModel)]="facilityD.is_indoor_parking_desc">
                                            <input type="hidden" id="outdoor3{{facilityD.facility_id}}" name="amenities{{facilityD.facility_id}}" [(ngModel)]="facilityD.is_outdoor_parking_desc">
                                            <input type="hidden" id="tesla3{{facilityD.facility_id}}" name="amenities{{facilityD.facility_id}}" [(ngModel)]="facilityD.is_tesla_charging_desc">
                                            <input type="hidden" id="generic3{{facilityD.facility_id}}" name="amenities{{facilityD.facility_id}}" [(ngModel)]="facilityD.is_generic_ev_charging_desc">
                                            <input type="hidden" id="oversize3{{facilityD.facility_id}}" name="amenities{{facilityD.facility_id}}" [(ngModel)]="facilityD.is_oversize_desc">
                                            <input type="hidden" id="motorcycle3{{facilityD.facility_id}}" name="amenities{{facilityD.facility_id}}" [(ngModel)]="facilityD.is_motorcycle_parking_desc">
                                            <input type="hidden" id="monthly3{{facilityD.facility_id}}" name="amenities{{facilityD.facility_id}}" [(ngModel)]="facilityD.is_monthly_desc">
                                </div>
                                     <div *ngIf="searchCriteria.type=='daily'" class="col-auto">
                                        <button *ngIf=" searchCriteria.type=='daily' && !isPartners && facilityD.inventory.showprice!='N/A' && facilityD.inventory.is_gray==0 && (facilityD.ticketech_id != '' || (facilityD?.parkonect_id !='' && facilityD?.parkonect_id !=null) || (facilityD?.valet_manager_id != '' && facilityD?.valet_manager_id != null) )" class="btn btn-primary btn-fill "
                                            (click)="moveToReservation(facilityD.facility.facility_id, facilityD.facility.price, facilityD.facility.monthly_rate_main,facilityD.facility.warning_on_reservation,facilityD.facility.warning_on_reservation_msg);sideBarClick()">
                                   <span > Book NOW</span>
                                   </button>
        
                                        <h3 sourceFont *ngIf="searchCriteria.type=='daily' && isPartners && facilityD.inventory.showprice!='N/A' && facilityD.inventory.is_gray==0 && (facilityD.ticketech_id != '' || (facilityD?.parkonect_id !='' && facilityD?.parkonect_id !=null) || (facilityD?.valet_manager_id != '' && facilityD?.valet_manager_id != null) )" id="sorting_{{facilityD.facility_id}}"
                                            class="partner-price font-weight-bold m-0">
                                            <span> ${{facilityD.inventory.showprice}} </span>
                                        </h3>
                                        <button *ngIf=" searchCriteria.type=='daily' && facilityD.inventory.showprice!='N/A' && facilityD.inventory.is_gray==1 && (facilityD.ticketech_id != '' || (facilityD?.parkonect_id !='' && facilityD?.parkonect_id !=null) || (facilityD?.valet_manager_id != '' && facilityD?.valet_manager_id != null) )" class="mobile-disable-price btn btn-primary btn-fill "
                                            (click)="moveToReservation(facilityD.facility.facility_id, facilityD.facility.price, facilityD.facility.monthly_rate_main,facilityD.facility.warning_on_reservation,facilityD.facility.warning_on_reservation_msg);sideBarClick()">
                                     Book NOW
                                     </button>
                                        <button *ngIf="searchCriteria.type=='daily' && facilityD.inventory.showprice!='N/A'  && (facilityD.ticketech_id == '' && (facilityD?.parkonect_id == '' || facilityD?.parkonect_id ==null) && (facilityD?.valet_manager_id == '' || facilityD?.valet_manager_id == null) )" class="btn btn-primary btn-fill">
                                     <span > {{changeRateUpdatedforsort(facilityD.inventory.showprice)}} </span>
                                     </button>
                                        <p id="threshold_{{facilityD.facility.facility_id}}" class="coupon_threshold_price hidden">{{ facilityD.facility.coupon_threshold_price }}</p>
                                        <p class="facPrice hidden_Price_{{facilityD.facility.facility_id}} hidden">{{ facilityD.inventory.showprice }}</p>
                                        <span class="baserate" *ngIf="  searchCriteria.type=='daily' && !isPartners && facilityD.inventory.showprice=='N/A'"><h2 > NA</h2></span>
                                        <h3  sourceFont *ngIf="searchCriteria.type=='daily' && isPartners && facilityD.inventory.showprice=='N/A' && facilityD.inventory.is_gray==0 && (facilityD.ticketech_id != '' || (facilityD?.parkonect_id !='' && facilityD?.parkonect_id !=null) || (facilityD?.valet_manager_id != '' && facilityD?.valet_manager_id != null) )" id="sorting_{{facilityD.facility_id}}"
                                            class="partner-price">
                                            <span> {{facilityD.inventory.showprice}} </span>
                                        </h3>
                                    </div>
                                    <div *ngIf="searchCriteria.type=='monthly'" class="col-auto">
                                        <button *ngIf="isPartners == false &&  searchCriteria.type=='monthly' && facilityD.facility.monthly_rate_main!='N/A*'" id="sorting_{{facilityD.facility.facility_id}}" class="btn btn-primary btn-fill " (click)="moveToReservation(facilityD.facility.facility_id, facilityD.facility.facilityD.facility.price, facilityD.facility.monthly_rate_main,facilityD.facility.warning_on_reservation,facilityD.facility.warning_on_reservation_msg);sideBarClick()">
                                  <span> ${{facilityD.facility.monthly_rate_main}} </span>
                                  </button>
                                        <p class="facPrice hidden">{{ facilityD.facility.monthly_rate_main }}</p>
                                        <span class="baserate" *ngIf="isPartners == false &&  searchCriteria.type=='monthly' && facilityD.facility.monthly_rate_main=='N/A*'"><h2 > NA</h2></span>
                                    </div>
                                    </div>
        
                                  
        
        
        
        
                                    <!-- <div  *ngIf="searchCriteria.type=='daily'"  class="col align-self-end text-right" >
                                   <button    *ngIf=" searchCriteria.type=='daily' && facilityD.inventory.showprice!='N/A' && facilityD.inventory.is_gray==0"  class="btn btn-primary btn-fill " (click)="moveToReservation(facilityD.facility.facility_id, facilityD.facility.price, facilityD.facility.monthly_rate_main,facilityD.facility.warning_on_reservation,facilityD.facility.warning_on_reservation_msg);">
                                  <span > ${{facilityD.inventory.showprice}} | Book Now</span>
                                  </button> 
                                  <button    *ngIf=" searchCriteria.type=='daily' && facilityD.inventory.showprice!='N/A' && facilityD.inventory.is_gray==1 && (facilityD.ticketech_id != '' || (facilityD?.parkonect_id !='' && facilityD?.parkonect_id !=null) )"  class="mobile-disable-price btn btn-primary btn-fill " (click)="moveToReservation(facilityD.facility.facility_id, facilityD.facility.price, facilityD.facility.monthly_rate_main,facilityD.facility.warning_on_reservation,facilityD.facility.warning_on_reservation_msg);">
                                    <span > ${{facilityD.inventory.showprice}} | Book NOW</span>
                                    </button> 
                                  <button    *ngIf="searchCriteria.type=='daily' && facilityD.inventory.showprice!='N/A'  && (facilityD.ticketech_id == '' && (facilityD?.parkonect_id == '' || facilityD?.parkonect_id ==null) )"  class="btn btn-primary btn-fill " (click)="moveToReservation(facilityD.facility.facility_id, facilityD.facility.price, facilityD.facility.monthly_rate_main,facilityD.facility.warning_on_reservation,facilityD.facility.warning_on_reservation_msg);">
                                    <span > ${{facilityD.inventory.showprice}} </span>
                                    </button> 
                                  <p id="threshold_{{facilityD.facility.facility_id}}" class="coupon_threshold_price hidden" >{{ facilityD.facility.coupon_threshold_price }}</p>
                                 <p class="facPrice hidden_Price_{{facilityD.facility.facility_id}} hidden">{{ facilityD.inventory.showprice }}</p>
                                  <span  class="baserate" *ngIf="  searchCriteria.type=='daily' && facilityD.inventory.showprice=='N/A'"><h2 class="text-primary"> NA</h2></span>      
                            </div> -->
                                    <!-- <div  *ngIf="searchCriteria.type=='daily'" class="col align-self-end text-right onfacilityloadshow_{{facilityD.facility.facility_id}} hide" >
                              <button  id="sorting_{{facilityD.facility.facility_id}}" class="btn btn-primary btn-fill sorting_{{facilityD.facility.facility_id}}" (click)="moveToReservation(facilityD.facility.facility_id, facilityD.facility.price, facilityD.facility.monthly_rate_main,facilityD.facility.warning_on_reservation,facilityD.facility.warning_on_reservation_msg);">
                             <span > ${{facilityD.inventory.showprice}} | Book Now2</span>A2
                             </button> 
                             
                              <p class="facPrice hidden_Price_{{facilityD.facility.facility_id}} hidden">{{ facilityD.facility.price }}</p>
                             <span  class="hide baserate narecord_{{facilityD.facility.facility_id}}"><h2 class="text-primary"> NA</h2></span>   
                            </div> -->
        
                                    <!-- <div *ngIf="searchCriteria.type=='monthly'" class="col align-self-end text-right">
                                        <button *ngIf="isPartners == false &&  searchCriteria.type=='monthly' && facilityD.facility.monthly_rate_main!='N/A*'" id="sorting_{{facilityD.facility.facility_id}}" class="btn btn-primary btn-fill " (click)="moveToReservation(facilityD.facility.facility_id, facilityD.facility.facilityD.facility.price, facilityD.facility.monthly_rate_main,facilityD.facility.warning_on_reservation,facilityD.facility.warning_on_reservation_msg);">
                                  <span> ${{facilityD.facility.monthly_rate_main}} | Buy Monthly </span>
                                  </button>
                                        <p class="facPrice hidden">{{ facilityD.facility.monthly_rate_main }}</p>
                                        <span class="baserate" *ngIf="isPartners == false &&  searchCriteria.type=='monthly' && facilityD.facility.monthly_rate_main=='N/A*'"><h2 class="text-primary"> NA</h2></span>
                                    </div>
                                </div>
                              -->
                                <div class="listBody panel-body hidden">
                                    <div class="row">
                                        <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8">
                                            <h4 class="text-uppercase mar-bottom-0 mar-top-0" parkTitle>
                                                {{facilityD.facility.entrance_location}}
                                            </h4>
                                            <p class="mar-bottom-xs ">{{facilityD.facility.facility_name_modified}}</p>
                                        </div>
                                        <div class="col-xs-4 col-sm-4 col-md-4 text-right col-lg-4">
                                            <a style="cursor:pointer" class="btn btn-xs btn-link" (click)="openDetails(facilityD.facility, searchCriteria.type)">Details &nbsp; &nbsp; <fa name="angle-right"></fa></a>
                                        </div>
                                    </div>
                                    <div class="flex-row no-gutters bottom-xs">
                                        <div class="col">
                                            <p class="map-mark">
                                                <img [src]="map_market_location" height="14"> &nbsp; {{facilityD.facility.distance | distanceconversion}}</p>
        
                                            <p class="facDistance hidden">{{ facilityD.facility.distance }}</p>
                                            <ul class="amenity">
                                                <li *ngIf="facilityD.facility.is_24hour_open =='1'"><img [src]="open_24hr_icon" alt="image"></li>
                                                <li *ngIf="facilityD.facility.is_indoor_parking =='1'"><img [src]="indoor_icon" alt="image"></li>
                                                <li *ngIf="facilityD.facility.is_outdoor_parking =='1'"><img [src]="outdoor_icon" alt="image"></li>
                                                <li *ngIf="facilityD.facility.is_tesla_charging =='1'"><img [src]="tesla_charger_icon" alt="image"></li>
                                                <li *ngIf="facilityD.facility.is_generic_ev_charging =='1'"><img [src]="ev_charge_icon" alt="image"></li>
                                                <li *ngIf="facilityD.facility.is_oversize =='1'"><img [src]="oversized_icon" alt="image"></li>
                                                <li *ngIf="facilityD.facility.is_motorcycle_parking =='1'"><img [src]="motorcycle_icon" alt="image"></li>
                                                <li *ngIf="facilityD.facility.is_monthly =='1'"><img [src]="monthly_icon" alt="image"></li>
                                            </ul>
        
                                        </div>
        
                                        <div *ngIf="searchCriteria.type=='daily'" class="col align-self-end text-right">
                                            <button *ngIf=" searchCriteria.type=='daily' && !isPartners && facilityD.inventory.showprice!='N/A' && facilityD.inventory.is_gray==0 && (facilityD.ticketech_id != '' || (facilityD?.parkonect_id !='' && facilityD?.parkonect_id !=null) || (facilityD?.valet_manager_id != '' && facilityD?.valet_manager_id != null) )" class="btn btn-primary btn-fill "
                                                (click)="moveToReservation(facilityD.facility.facility_id, facilityD.facility.price, facilityD.facility.monthly_rate_main,facilityD.facility.warning_on_reservation,facilityD.facility.warning_on_reservation_msg);">
                                       <span > ${{facilityD.inventory.showprice}} | Book NOW</span>
                                       </button>
        
                                            <h3 *ngIf="searchCriteria.type=='daily' && isPartners && facilityD.inventory.showprice!='N/A' && facilityD.inventory.is_gray==0 && (facilityD.ticketech_id != '' || (facilityD?.parkonect_id !='' && facilityD?.parkonect_id !=null) || (facilityD?.valet_manager_id != '' && facilityD?.valet_manager_id != null) )" id="sorting_{{facilityD.facility_id}}"
                                                class="partner-price">
                                                <span> ${{facilityD.inventory.showprice}} </span>
                                            </h3>
                                            <button *ngIf=" searchCriteria.type=='daily' && facilityD.inventory.showprice!='N/A' && facilityD.inventory.is_gray==1 && (facilityD.ticketech_id != '' || (facilityD?.parkonect_id !='' && facilityD?.parkonect_id !=null) || (facilityD?.valet_manager_id != '' && facilityD?.valet_manager_id != null) )" class="mobile-disable-price btn btn-primary btn-fill "
                                                (click)="moveToReservation(facilityD.facility.facility_id, facilityD.facility.price, facilityD.facility.monthly_rate_main,facilityD.facility.warning_on_reservation,facilityD.facility.warning_on_reservation_msg);">
                                         <span > ${{facilityD.inventory.showprice}} | Book NOW</span>
                                         </button>
                                            <button *ngIf="searchCriteria.type=='daily' && facilityD.inventory.showprice!='N/A'  && (facilityD.ticketech_id == '' && (facilityD?.parkonect_id == '' || facilityD?.parkonect_id ==null) && (facilityD?.valet_manager_id == '' || facilityD?.valet_manager_id == null) )" class="btn btn-primary btn-fill">
                                         <span > {{changeRateUpdatedforsort(facilityD.inventory.showprice)}} </span>
                                         </button>
                                            <p id="threshold_{{facilityD.facility.facility_id}}" class="coupon_threshold_price hidden">{{ facilityD.facility.coupon_threshold_price }}</p>
                                            <p class="facPrice hidden_Price_{{facilityD.facility.facility_id}} hidden">{{ facilityD.inventory.showprice }}</p>
                                            <span class="baserate" *ngIf="  searchCriteria.type=='daily' && !isPartners && facilityD.inventory.showprice=='N/A'"><h2 class="text-primary"> N/A</h2></span>
                                            <h3 *ngIf="searchCriteria.type=='daily' && isPartners && facilityD.inventory.showprice=='N/A' && facilityD.inventory.is_gray==0 && (facilityD.ticketech_id != '' || (facilityD?.parkonect_id !='' && facilityD?.parkonect_id !=null) || (facilityD?.valet_manager_id != '' && facilityD?.valet_manager_id != null) )" id="sorting_{{facilityD.facility_id}}"
                                                class="partner-price">
                                                <span> {{facilityD.inventory.showprice}} </span>
                                            </h3>
                                        </div>
        
        
        
        
                                        <!-- <div  *ngIf="searchCriteria.type=='daily'"  class="col align-self-end text-right" >
                                       <button    *ngIf=" searchCriteria.type=='daily' && facilityD.inventory.showprice!='N/A' && facilityD.inventory.is_gray==0"  class="btn btn-primary btn-fill " (click)="moveToReservation(facilityD.facility.facility_id, facilityD.facility.price, facilityD.facility.monthly_rate_main,facilityD.facility.warning_on_reservation,facilityD.facility.warning_on_reservation_msg);">
                                      <span > ${{facilityD.inventory.showprice}} | Book Now</span>
                                      </button> 
                                      <button    *ngIf=" searchCriteria.type=='daily' && facilityD.inventory.showprice!='N/A' && facilityD.inventory.is_gray==1 && (facilityD.ticketech_id != '' || (facilityD?.parkonect_id !='' && facilityD?.parkonect_id !=null) )"  class="mobile-disable-price btn btn-primary btn-fill " (click)="moveToReservation(facilityD.facility.facility_id, facilityD.facility.price, facilityD.facility.monthly_rate_main,facilityD.facility.warning_on_reservation,facilityD.facility.warning_on_reservation_msg);">
                                        <span > ${{facilityD.inventory.showprice}} | Book NOW</span>
                                        </button> 
                                      <button    *ngIf="searchCriteria.type=='daily' && facilityD.inventory.showprice!='N/A'  && (facilityD.ticketech_id == '' && (facilityD?.parkonect_id == '' || facilityD?.parkonect_id ==null) )"  class="btn btn-primary btn-fill " (click)="moveToReservation(facilityD.facility.facility_id, facilityD.facility.price, facilityD.facility.monthly_rate_main,facilityD.facility.warning_on_reservation,facilityD.facility.warning_on_reservation_msg);">
                                        <span > ${{facilityD.inventory.showprice}} </span>
                                        </button> 
                                      <p id="threshold_{{facilityD.facility.facility_id}}" class="coupon_threshold_price hidden" >{{ facilityD.facility.coupon_threshold_price }}</p>
                                     <p class="facPrice hidden_Price_{{facilityD.facility.facility_id}} hidden">{{ facilityD.inventory.showprice }}</p>
                                      <span  class="baserate" *ngIf="  searchCriteria.type=='daily' && facilityD.inventory.showprice=='N/A'"><h2 class="text-primary"> NA</h2></span>      
                                </div> -->
                                        <!-- <div  *ngIf="searchCriteria.type=='daily'" class="col align-self-end text-right onfacilityloadshow_{{facilityD.facility.facility_id}} hide" >
                                  <button  id="sorting_{{facilityD.facility.facility_id}}" class="btn btn-primary btn-fill sorting_{{facilityD.facility.facility_id}}" (click)="moveToReservation(facilityD.facility.facility_id, facilityD.facility.price, facilityD.facility.monthly_rate_main,facilityD.facility.warning_on_reservation,facilityD.facility.warning_on_reservation_msg);">
                                 <span > ${{facilityD.inventory.showprice}} | Book Now2</span>A2
                                 </button> 
                                 
                                  <p class="facPrice hidden_Price_{{facilityD.facility.facility_id}} hidden">{{ facilityD.facility.price }}</p>
                                 <span  class="hide baserate narecord_{{facilityD.facility.facility_id}}"><h2 class="text-primary"> NA</h2></span>   
                                </div> -->
        
                                        <div *ngIf="searchCriteria.type=='monthly'" class="col align-self-end text-right">
                                            <button *ngIf="isPartners == false &&  searchCriteria.type=='monthly' && facilityD.facility.monthly_rate_main!='N/A*'" id="sorting_{{facilityD.facility.facility_id}}" class="btn btn-primary btn-fill " (click)="moveToReservation(facilityD.facility.facility_id, facilityD.facility.facilityD.facility.price, facilityD.facility.monthly_rate_main,facilityD.facility.warning_on_reservation,facilityD.facility.warning_on_reservation_msg);">
                                      <span> ${{facilityD.facility.monthly_rate_main}} | BOOK NOW </span>
                                      </button>
                                            <p class="facPrice hidden">{{ facilityD.facility.monthly_rate_main }}</p>
                                            <span class="baserate" *ngIf="isPartners == false &&  searchCriteria.type=='monthly' && facilityD.facility.monthly_rate_main=='N/A*'"><h2 class="text-primary"> N/A</h2></span>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ng-container>
                        <ng-container *ngIf="pricesorting &&  searchCriteria.type=='daily'">
                            <li *ngFor="let facilityD of facilitylistprice | sorting:'daily'; let couponIndexSelectCoupon = index" class="facility gggggg" data-id="{{facilityD.facility.facility_id}}">
                               <div class="row">
                                   <div class="col-4 clipImage">
                                        <img *ngIf="facilityD.facility.photo_url" [src]="facilityD.facility.photo_url" class="img-responsive rounded"  alt="image">
                                        <img *ngIf="!facilityD.facility.photo_url" [src]="park_garage" class="img-responsive notImg  rounded" alt="image">
                                   </div>
                                   <div class="col">
                                       <div class="row">

                                 
                                   <div class="col p-0">
                                            <h4 class="text-uppercase mar-bottom-0 mar-top-0" parkTitle>
                                                {{facilityD.facility.entrance_location}}
                                            </h4>
                                            <p class="mar-bottom-xs ">{{ facilityD.facility.facility_name_modified }}</p>
                                            <p>
                                                <img [src]="map_market_location" height="12">&nbsp;&nbsp;{{facilityD.facility.distance | distanceconversion}}</p>
        
                                   </div>
                                   <div class="col-auto text-right" *ngIf="searchCriteria.type=='daily'">
                                  
                                        <small> Rates from </small>
                                        <h3 class="font-wiight-bold m-0" *ngIf=" searchCriteria.type=='daily' && !isPartners && facilityD.inventory.showprice!='N/A' && facilityD.inventory.is_gray==0 && (facilityD.ticketech_id != '' || (facilityD?.parkonect_id !='' && facilityD?.parkonect_id !=null) || (facilityD?.valet_manager_id != '' && facilityD?.valet_manager_id != null) )" 
                                            (click)="moveToReservation(facilityD.facility.facility_id, facilityD.facility.price, facilityD.facility.monthly_rate_main,facilityD.facility.warning_on_reservation,facilityD.facility.warning_on_reservation_msg);">
                                ${{facilityD.inventory.showprice}}
                                   </h3>
                                        <h3 *ngIf="searchCriteria.type=='daily' && isPartners && facilityD.inventory.showprice!='N/A' && facilityD.inventory.is_gray==0 && (facilityD.ticketech_id != '' || (facilityD?.parkonect_id !='' && facilityD?.parkonect_id !=null) || (facilityD?.valet_manager_id != '' && facilityD?.valet_manager_id != null) )" id="sorting_{{facilityD.facility_id}}"
                                            class="partner-price font-wiight-bold m-0">
                                            ${{facilityD.inventory.showprice}}
                                        </h3>
                                        <h3  *ngIf=" searchCriteria.type=='daily' && facilityD.inventory.showprice!='N/A' && facilityD.inventory.is_gray==1 && (facilityD.ticketech_id != '' || (facilityD?.parkonect_id !='' && facilityD?.parkonect_id !=null) || (facilityD?.valet_manager_id != '' && facilityD?.valet_manager_id != null) )" class="mobile-disable-price font-wiight-bold m-0 "
                                            (click)="moveToReservation(facilityD.facility.facility_id, facilityD.facility.price, facilityD.facility.monthly_rate_main,facilityD.facility.warning_on_reservation,facilityD.facility.warning_on_reservation_msg);">
                                 ${{facilityD.inventory.showprice}}
                                    </h3>
                                        <button *ngIf="searchCriteria.type=='daily' && facilityD.inventory.showprice!='N/A'  && (facilityD.ticketech_id == '' && (facilityD?.parkonect_id == '' || facilityD?.parkonect_id ==null) && (facilityD?.valet_manager_id == '' || facilityD?.valet_manager_id == null) )" class="btn btn-primary btn-fill">
                                  <span >  {{changeRateUpdatedforsort(facilityD.inventory.showprice)}}</span>
                                  </button>
                                        <p id="threshold_{{facilityD.facility.facility_id}}" class="coupon_threshold_price hidden">{{ facilityD.facility.coupon_threshold_price }}</p>
                                        <p class="facPrice hidden_Price_{{facilityD.facility.facility_id}} hidden">{{ facilityD.inventory.showprice }}</p>
                                        <span class="baserate" *ngIf="searchCriteria.type=='daily' && !isPartners && facilityD.inventory.showprice=='N/A'"><h2 class="text-primary"> NA</h2></span>
                                        <h3 *ngIf="searchCriteria.type=='daily' && isPartners && facilityD.inventory.showprice=='N/A' && facilityD.inventory.is_gray==0 && (facilityD.ticketech_id != '' || (facilityD?.parkonect_id !='' && facilityD?.parkonect_id !=null) || (facilityD?.valet_manager_id != '' && facilityD?.valet_manager_id != null) )" id="sorting_{{facilityD.facility_id}}"
                                            class="partner-price">
                                            <span> {{facilityD.inventory.showprice}} </span>
                                        </h3>
                                    </div>
                                    <div *ngIf="searchCriteria.type=='monthly'" class=" col text-right">
                                        <h3 class="font-weight-bold m-0" *ngIf="isPartners == false &&  searchCriteria.type=='monthly' && facilityD.facility.monthly_rate_main!='N/A*'" id="sorting_{{facilityD.facility.facility_id}}">
                                            ${{facilityD.facility.monthly_rate_main}} 
                                        </h3>
                                        <p class="facPrice hidden">{{ facilityD.facility.monthly_rate_main }}</p>
                                        <span class="baserate" *ngIf="isPartners == false &&  searchCriteria.type=='monthly' && facilityD.facility.monthly_rate_main=='N/A*'"><h2 > NA</h2></span>
                                    </div>
                                </div>
                                <!-- <div class="row ">
                                    <div class="col p-0 f-10 mt-3">
                                       *Please be advised of amended hours at this location.<br class="hidden-xs"> <a [href]="temporarilyClosedUrl" target="_blank">
                                             Click Here
                                        </a>for alternative garage information
                                    </div>
                                </div> -->

                            </div>
                             
                               </div>
                               <div class="row">
                                <div class="col trustpilot-rate  "  >
                                    <!-- TrustBox widget - Micro Combo -->
                                <div  class="trustpilot-widget ratingStar" id="trustboxD6{{couponIndexSelectCoupon}}" data-locale="en-US" data-template-id="5419b6ffb0d04a076446a9af" data-businessunit-id="5feb84d1e2aa300001625337" data-style-height="25px" data-style-width="420px" data-theme="light" [attr.data-location]="facilityD.garage_code">
                                    <a href="https://www.trustpilot.com/review/iconparkingsystems.com" target="_blank" rel="noopener"></a>
                                </div>
<!-- End TrustBox widget -->
                                </div>
                            </div>
                               <div class="row mt-2 align-items-center">
                                <div class="col">
                                  
                                    <ul class="amenity">
                                        <li (mouseenter) ="mouseEnter('mobile4',facilityD.facility_id)" *ngIf="facilityD.is_mobile_pay_enabled =='1'" triggers="mouseenter:mouseleave" containerClass="parkSafeContentBlock"   [popover]="mobileData"><img style="width: 45px;"[src]="payNow_btn" alt="image"></li>
                                        <li (mouseenter) ="mouseEnter('parksafe4',facilityD.facility_id)" *ngIf="facilityD.is_parksafe =='1'" triggers="mouseenter:mouseleave" containerClass="parkSafeContentBlock"   [popover]="parksafeData"  placement="top left" [outsideClick]="true"  ><img style="width: 45px;" [src]="parkSafesmall" alt="image"></li>

                                        <li (mouseenter) ="mouseEnter('24open4',facilityD.facility_id)" *ngIf="facilityD.facility.is_24hour_open =='1'"><img [src]="open_24hr_icon" alt="image"></li>
                                        <li (mouseenter) ="mouseEnter('indoor4',facilityD.facility_id)" *ngIf="facilityD.facility.is_indoor_parking =='1'"><img [src]="indoor_icon" alt="image"></li>
                                        <li (mouseenter) ="mouseEnter('outdoor4',facilityD.facility_id)" *ngIf="facilityD.facility.is_outdoor_parking =='1'"><img [src]="outdoor_icon" alt="image"></li>
                                        <li (mouseenter) ="mouseEnter('tesla4',facilityD.facility_id)" *ngIf="facilityD.facility.is_tesla_charging =='1'"><img [src]="tesla_charger_icon" alt="image"></li>
                                        <li (mouseenter) ="mouseEnter('generic4',facilityD.facility_id)" *ngIf="aminitiesCounter(facilityD) <= count && facilityD.facility.is_generic_ev_charging =='1'"><img [src]="ev_charge_icon" alt="image"></li>
                                        <li (mouseenter) ="mouseEnter('oversize4',facilityD.facility_id)" *ngIf="aminitiesCounter(facilityD) <= count && facilityD.facility.is_oversize =='1'"><img [src]="oversized_icon" alt="image"></li>
                                        <li (mouseenter) ="mouseEnter('motorcycle4',facilityD.facility_id)" *ngIf="aminitiesCounter(facilityD) <= count && facilityD.facility.is_motorcycle_parking =='1'"><img [src]="motorcycle_icon" alt="image"></li>
                                        <li (mouseenter) ="mouseEnter('monthly4',facilityD.facility_id)" *ngIf="aminitiesCounter(facilityD) <= count && facilityD.facility.is_monthly =='1'"><img [src]="monthly_icon" alt="image"></li>
                                        <li *ngIf="aminitiesCounter(facilityD) > count"><p class="mar-bottom-0"><b>{{aminitiesCounter(facilityD) - count}}+</b></p></li>
                                    </ul>
                                    <input type="hidden" id="mobile4{{facilityD.facility_id}}" name="amenities{{facilityD.facility_id}}" [(ngModel)]="facilityD.is_mobile_pay_enabled_desc">
                                    <input type="hidden" id="parksafe4{{facilityD.facility_id}}" name="amenities{{facilityD.facility_id}}" [(ngModel)]="facilityD.is_parksafe_desc">
                                    <input type="hidden" id="24open4{{facilityD.facility_id}}" name="amenities{{facilityD.facility_id}}" [(ngModel)]="facilityD.is_24hour_open_desc">
                                    <input type="hidden" id="indoor4{{facilityD.facility_id}}" name="amenities{{facilityD.facility_id}}" [(ngModel)]="facilityD.is_indoor_parking_desc">
                                    <input type="hidden" id="outdoor4{{facilityD.facility_id}}" name="amenities{{facilityD.facility_id}}" [(ngModel)]="facilityD.is_outdoor_parking_desc">
                                    <input type="hidden" id="tesla4{{facilityD.facility_id}}" name="amenities{{facilityD.facility_id}}" [(ngModel)]="facilityD.is_tesla_charging_desc">
                                    <input type="hidden" id="generic4{{facilityD.facility_id}}" name="amenities{{facilityD.facility_id}}" [(ngModel)]="facilityD.is_generic_ev_charging_desc">
                                    <input type="hidden" id="oversize4{{facilityD.facility_id}}" name="amenities{{facilityD.facility_id}}" [(ngModel)]="facilityD.is_oversize_desc">
                                    <input type="hidden" id="motorcycle4{{facilityD.facility_id}}" name="amenities{{facilityD.facility_id}}" [(ngModel)]="facilityD.is_motorcycle_parking_desc">
                                    <input type="hidden" id="monthly4{{facilityD.facility_id}}" name="amenities{{facilityD.facility_id}}" [(ngModel)]="facilityD.is_monthly_desc">
                                </div>

                                <div *ngIf="searchCriteria.type=='daily'" class="col-auto onfacilityload_{{facilityD.facility_id}}">
                                    <button *ngIf=" searchCriteria.type=='daily' && facilityD.price!='N/A'" id="sorting_{{facilityD.facility_id}}" class="btn btn-primary btn-fill " (click)="moveToReservation(facilityD.facility_id, facilityD.price, facilityD.monthly_rate_main,facilityD.warning_on_reservation,facilityD.warning_on_reservation_msg);sideBarClick()">
                              <span > BOOK NOW</span>
                              </button>
                                    <p id="threshold_{{facilityD.facility_id}}" class="coupon_threshold_price hidden">{{ facilityD.coupon_threshold_price }}</p>
                                    <p class="facPrice hidden_Price_{{facilityD.facility_id}} hidden">{{ facilityD.price }}</p>
                                    <span class="baserate" *ngIf=" searchCriteria.type=='daily' && facilityD.price=='N/A'"><h2 > NA</h2></span>
                                </div>
                                <div *ngIf="searchCriteria.type=='daily'" class="col-auto onfacilityloadshow_{{facilityD.facility_id}} hide">
                                    <button *ngIf="(facilityD.ticketech_id != '' || (facilityD?.parkonect_id !='' && facilityD?.parkonect_id !=null) || (facilityD?.valet_manager_id != '' && facilityD?.valet_manager_id != null) )" id="sorting_{{facilityD.facility_id}}" class="btn btn-primary btn-fill sorting_{{facilityD.facility_id}}" (click)="moveToReservation(facilityD.facility_id, facilityD.price, facilityD.monthly_rate_main,facilityD.warning_on_reservation,facilityD.warning_on_reservation_msg);sideBarClick()">
                         <span > ${{facilityD.price}} | BOOK NOW</span>
                         </button>

                                    <p class="facPrice hidden_Price_{{facilityD.facility_id}} hidden">{{ facilityD.price }}</p>
                                    <span class="hide baserate narecord_{{facilityD.facility_id}}"><h2> NA</h2></span>
                                </div>

                                <div *ngIf="searchCriteria.type=='monthly'" class="col-auto ">
                                    <button *ngIf="(facilityD.monthly_rate_mains >0 && facilityD.monthly_rate_mains < 2500000) && isPartners == false &&  searchCriteria.type=='monthly' && facilityD.monthly_rate_main!='N/A*'" id="sorting_{{facilityD.facility_id}}" class="btn btn-primary btn-fill " (click)="moveToReservation(facilityD.facility_id, facilityD.price, facilityD.monthly_rate_main,facilityD.warning_on_reservation,facilityD.warning_on_reservation_msg);sideBarClick()">
                                        <span> BOOK NOW </span>
                                        </button>
                                    <button style="cursor: default;" *ngIf="(facilityD.monthly_rate_main < 1 || facilityD.monthly_rate_main > 2400000) && isPartners == false &&  searchCriteria.type=='monthly' && facilityD.monthly_rate_main!='N/A*'" id="sorting_{{facilityD.facility_id}}" class="btn btn-primary btn-fill " >
                                          <span> N/A </span>
                                    </button>
                                    <p class="facPrice hidden">{{ facilityD.monthly_rate_main }}</p>
                                    <span class="baserate" *ngIf="isPartners == false &&  searchCriteria.type=='monthly' && facilityD.monthly_rate_main=='N/A*'"><h2 > NA</h2></span>
                                </div>
                            </div>
                            
                                <div class="listBody panel-body hidden">
                                    <div class="row">
                                        <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8">
                                            <h4 class="text-uppercase mar-bottom-0 mar-top-0" parkTitle>
                                                {{facilityD.facility.entrance_location}}
                                            </h4>
                                            <p class="mar-bottom-xs ">{{ facilityD.facility.facility_name_modified }}</p>
                                        </div>
                                        <div class="col-xs-4 col-sm-4 col-md-4 text-right col-lg-4">
                                            <a style="cursor:pointer" class="btn btn-xs btn-link" (click)="openDetails(facilityD.facility, searchCriteria.type)">Details &nbsp; &nbsp; <fa name="angle-right"></fa></a>
                                        </div>
                                    </div>
                                    <div class="flex-row no-gutters bottom-xs">
                                        <div class="col">
                                            <p class="map-mark">
                                               <img [src]="map_market_location" height="14"> &nbsp; {{facilityD.facility.distance | distanceconversion}}</p>
        
                                            <p class="facDistance hidden">{{ facilityD.facility.distance }}</p>
                                            <ul class="amenity">
                                                <li *ngIf="facilityD.facility.is_24hour_open =='1'"><img [src]="open_24hr_icon" alt="image"></li>
                                                <li *ngIf="facilityD.facility.is_indoor_parking =='1'"><img [src]="indoor_icon" alt="image"></li>
                                                <li *ngIf="facilityD.facility.is_outdoor_parking =='1'"><img [src]="outdoor_icon" alt="image"></li>
                                                <li *ngIf="facilityD.facility.is_tesla_charging =='1'"><img [src]="tesla_charger_icon" alt="image"></li>
                                                <li *ngIf="facilityD.facility.is_generic_ev_charging =='1'"><img [src]="ev_charge_icon" alt="image"></li>
                                                <li *ngIf="facilityD.facility.is_oversize =='1'"><img [src]="oversized_icon" alt="image"></li>
                                                <li *ngIf="facilityD.facility.is_motorcycle_parking =='1'"><img [src]="motorcycle_icon" alt="image"></li>
                                                <li *ngIf="facilityD.facility.is_monthly =='1'"><img [src]="monthly_icon" alt="image"></li>
                                            </ul>
        
                                        </div>
        
                                        <div *ngIf="searchCriteria.type=='daily'" class="col align-self-end text-right">
                                            <button *ngIf=" searchCriteria.type=='daily' && !isPartners && facilityD.inventory.showprice!='N/A' && facilityD.inventory.is_gray==0 && (facilityD.ticketech_id != '' || (facilityD?.parkonect_id !='' && facilityD?.parkonect_id !=null) || (facilityD?.valet_manager_id != '' && facilityD?.valet_manager_id != null) )" class="btn btn-primary btn-fill "
                                                (click)="moveToReservation(facilityD.facility.facility_id, facilityD.facility.price, facilityD.facility.monthly_rate_main,facilityD.facility.warning_on_reservation,facilityD.facility.warning_on_reservation_msg);">
                                    <span > ${{facilityD.inventory.showprice}} | Book NOW</span>
                                    </button>
                                            <h3 *ngIf="searchCriteria.type=='daily' && isPartners && facilityD.inventory.showprice!='N/A' && facilityD.inventory.is_gray==0 && (facilityD.ticketech_id != '' || (facilityD?.parkonect_id !='' && facilityD?.parkonect_id !=null) || (facilityD?.valet_manager_id != '' && facilityD?.valet_manager_id != null) )" id="sorting_{{facilityD.facility_id}}"
                                                class="partner-price">
                                                <span> ${{facilityD.inventory.showprice}} </span>
                                            </h3>
                                            <button *ngIf=" searchCriteria.type=='daily' && facilityD.inventory.showprice!='N/A' && facilityD.inventory.is_gray==1 && (facilityD.ticketech_id != '' || (facilityD?.parkonect_id !='' && facilityD?.parkonect_id !=null) || (facilityD?.valet_manager_id != '' && facilityD?.valet_manager_id != null) )" class="mobile-disable-price btn btn-primary btn-fill "
                                                (click)="moveToReservation(facilityD.facility.facility_id, facilityD.facility.price, facilityD.facility.monthly_rate_main,facilityD.facility.warning_on_reservation,facilityD.facility.warning_on_reservation_msg);">
                                      <span > ${{facilityD.inventory.showprice}} | Book NOW</span>
                                      </button>
                                            <button *ngIf="searchCriteria.type=='daily' && facilityD.inventory.showprice!='N/A'  && (facilityD.ticketech_id == '' && (facilityD?.parkonect_id == '' || facilityD?.parkonect_id ==null) && (facilityD?.valet_manager_id == '' || facilityD?.valet_manager_id == null) )" class="btn btn-primary btn-fill">
                                      <span >  {{changeRateUpdatedforsort(facilityD.inventory.showprice)}}</span>
                                      </button>
                                            <p id="threshold_{{facilityD.facility.facility_id}}" class="coupon_threshold_price hidden">{{ facilityD.facility.coupon_threshold_price }}</p>
                                            <p class="facPrice hidden_Price_{{facilityD.facility.facility_id}} hidden">{{ facilityD.inventory.showprice }}</p>
                                            <span class="baserate" *ngIf="searchCriteria.type=='daily' && !isPartners && facilityD.inventory.showprice=='N/A'"><h2 class="text-primary"> N/A</h2></span>
                                            <h3 *ngIf="searchCriteria.type=='daily' && isPartners && facilityD.inventory.showprice=='N/A' && facilityD.inventory.is_gray==0 && (facilityD.ticketech_id != '' || (facilityD?.parkonect_id !='' && facilityD?.parkonect_id !=null) || (facilityD?.valet_manager_id != '' && facilityD?.valet_manager_id != null) )" id="sorting_{{facilityD.facility_id}}"
                                                class="partner-price">
                                                <span> {{facilityD.inventory.showprice}} </span>
                                            </h3>
                                        </div>
        
                                        <!-- <div  *ngIf="searchCriteria.type=='daily'"  class="col align-self-end text-right" >
                                     <button    *ngIf="searchCriteria.type=='daily' && facilityD.inventory.showprice!='N/A' && facilityD.inventory.is_gray==0"  class="btn btn-primary btn-fill " (click)="moveToReservation(facilityD.facility.facility_id, facilityD.facility.price, facilityD.facility.monthly_rate_main,facilityD.facility.warning_on_reservation,facilityD.facility.warning_on_reservation_msg);">
                                    <span > ${{facilityD.inventory.showprice}} | Book Now</span>
                                    </button> 
                                    <button    *ngIf=" searchCriteria.type=='daily' && facilityD.inventory.showprice!='N/A' && facilityD.inventory.is_gray==1 && (facilityD.ticketech_id != '' || (facilityD?.parkonect_id !='' && facilityD?.parkonect_id !=null) )"  class="mobile-disable-price btn btn-primary btn-fill " (click)="moveToReservation(facilityD.facility.facility_id, facilityD.facility.price, facilityD.facility.monthly_rate_main,facilityD.facility.warning_on_reservation,facilityD.facility.warning_on_reservation_msg);">
                                      <span > ${{facilityD.inventory.showprice}} | Book NOW</span>
                                      </button> 
                                    <button    *ngIf="searchCriteria.type=='daily' && facilityD.inventory.showprice!='N/A'  && (facilityD.ticketech_id == '' && (facilityD?.parkonect_id == '' || facilityD?.parkonect_id ==null) )"  class="btn btn-primary btn-fill " (click)="moveToReservation(facilityD.facility.facility_id, facilityD.facility.price, facilityD.facility.monthly_rate_main,facilityD.facility.warning_on_reservation,facilityD.facility.warning_on_reservation_msg);">
                                      <span > ${{facilityD.inventory.showprice}} </span>
                                      </button>
                                    <p id="threshold_{{facilityD.facility.facility_id}}" class="coupon_threshold_price hidden" >{{ facilityD.facility.coupon_threshold_price }}</p>
                                   <p class="facPrice hidden_Price_{{facilityD.facility.facility_id}} hidden">{{ facilityD.inventory.showprice }}</p>
                                    <span  class="baserate" *ngIf="searchCriteria.type=='daily' && facilityD.inventory.showprice=='N/A'"><h2 class="text-primary"> NA</h2></span>      
                              </div> -->
                                        <!-- <div  *ngIf="searchCriteria.type=='daily'" class="col align-self-end text-right onfacilityloadshow_{{facilityD.facility.facility_id}} hide" >
                                <button  id="sorting_{{facilityD.facility.facility_id}}" class="btn btn-primary btn-fill sorting_{{facilityD.facility.facility_id}}" (click)="moveToReservation(facilityD.facility.facility_id, facilityD.facility.price, facilityD.facility.monthly_rate_main,facilityD.facility.warning_on_reservation,facilityD.facility.warning_on_reservation_msg);">
                               <span > ${{facilityD.inventory.showprice}} | Book Now2</span>A2
                               </button> 
                               
                                <p class="facPrice hidden_Price_{{facilityD.facility.facility_id}} hidden">{{ facilityD.facility.price }}</p>
                               <span  class="hide baserate narecord_{{facilityD.facility.facility_id}}"><h2 class="text-primary"> NA</h2></span>   
                              </div> -->
        
                                        <div *ngIf="searchCriteria.type=='monthly'" class="col align-self-end text-right">
                                            <button *ngIf="isPartners == false &&  searchCriteria.type=='monthly' && facilityD.facility.monthly_rate_main!='N/A*'" id="sorting_{{facilityD.facility.facility_id}}" class="btn btn-primary btn-fill " (click)="moveToReservation(facilityD.facility.facility_id, facilityD.facility.facilityD.facility.price, facilityD.facility.monthly_rate_main,facilityD.facility.warning_on_reservation,facilityD.facility.warning_on_reservation_msg);">
                                    <span> ${{facilityD.facility.monthly_rate_main}} | BOOK NOW </span>
                                    </button>
                                            <p class="facPrice hidden">{{ facilityD.facility.monthly_rate_main }}</p>
                                            <span class="baserate" *ngIf="isPartners == false &&  searchCriteria.type=='monthly' && facilityD.facility.monthly_rate_main=='N/A*'"><h2 class="text-primary"> N/A</h2></span>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ng-container>
                    </ul>
        
                </section>
                <div class=" col-auto align-self-end w-100 hidden-xs">
                <div class="accordianBlock">
                    <accordion [closeOthers]="oneAtATime" >
                        <accordion-group heading="{{CMSsidebarContent?.your_convenient_nyc_title}}">
                            {{CMSsidebarContent?.your_convenient_nyc_content}}
                            <!-- Whether you’re looking for Bronx monthly parking or monthly parking in Manhattan, Icon Parking has you covered. Our parking facilities are located throughout New York City, providing New York drivers with the ability to park in any one of our safe and secure parking areas. In collaboration with Quik Park, Icon Parking offers over 250 parking facilities in the city so you can feel confident you can find a parking space when you need it. -->
                        </accordion-group>
                        <accordion-group heading="{{CMSsidebarContent?.why_choose_icon_parking_title}}">
                            {{CMSsidebarContent?.why_choose_icon_parking_content}}
                          <!-- <p>Content 1</p> -->
                        </accordion-group>
                      
                      </accordion>
            </div>
            
                      <!--  boxx no 2 -->
                      
                    </div>
            
            </div>
        
            <!-- map toggle buttons -->
            <div *ngIf="activeNeighborhoodFilter && !parkDetail" class="mapNearby"  id="neighbourhoodMap">
                <div class="row  flex-column no-gutters" >

                
                <!-- <a floatingBtnBackParkList *showItBootstrap="['xs']" class="btn" (click)="back();">
                   <img [src]="mapListIcon" height="25px">
                </a> -->
                <div class="col-auto align-self-start w-100">
                <div class="headerTopToggleFiler visible-xs">
                    <div class=" daily-monthly-selector m-0 ">
                        <div class="filterBtn" data-toggle="buttons">
                            <label  id="daily" name="monthlytype" [class.active]="activeBtnDailyMonthly == 'daily'" (click)="tabRouting('daily')" class="btn  dailymonthlybuttons 5">
                      DAILY
                    </label>
                            <label  id="monthly" name="monthlytype" [class.active]="activeBtnDailyMonthly == 'monthly'" (click)="tabRouting('monthly')" class="btn  dailymonthlybuttons 6" >
                        MONTHLY
                    </label>
                        </div>
                    </div>
                </div>
                <header class="col-auto align-items-start w-100">
                   <div class="row  align-items-center">
                       <div class="col">
                        <div class="btn-group filterDropdownMenu" dropdown>
                            <button id="button-basic" dropdownToggle type="button" class="btn  btn-link text-dark dropdown-toggle"
                                    aria-controls="dropdown-basic">
                                    Sort by {{selectedSortType}} &nbsp; <img width="25px" [src]="dropdownArrow">
                            </button>
                            <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu filterDropDown"
                                role="menu" aria-labelledby="button-basic">
                                <li role="menuitem"><a class="dropdown-item" [class.hidden]="selectedSortType == 'Distance' || selectedSortType == 'distance'" (click)="sortBy('distance')" href="javascript:;">Distance</a></li>
                                <!-- <li class="divider dropdown-divider" [class.hidden]="selectedSortType == 'Distance' || selectedSortType == 'distance'"></li> -->
                                <li role="menuitem"><a class="dropdown-item" (click)="sortBy('price')" [class.hidden]="selectedSortType == 'price' || selectedSortType == 'Price'" href="javascript:;">Price</a></li>
                                <!-- <li class="divider dropdown-divider" [class.hidden]="selectedSortType == 'price' || selectedSortType == 'Price'"></li> -->
                                <li role="menuitem"><a class="dropdown-item" (click)="sortBy('Neighborhood')" [class.hidden]="(selectedSortType == 'Neighborhood') && !partnerPageOn" href="javascript:;">Neighborhood</a></li>
                            </ul>
                          </div>
                         <!-- <select id="sortingFilterNeighbor"  class="sortingFilter 3"  (change)="updateSortSelected($event.target.value);sortBy($event.target.value)">
                             <option value="distance">Sort by Distance</option>
                               <option value="price" >Sort by Price</option>
                               <option value="Neighborhood" selected>Sort by Neighborhood </option>
                           </select> -->
                       </div>
                    
                     
                   </div>
                   <!-- <h3 *ngIf="searchCriteria.type == 'daily'" class="mb-0 mt-2">Find Daily Parking Deals</h3> -->
                   <h3 class="mb-0 mt-2">Find {{searchTypeText}} Parking Deals</h3>
                   <div class="row  align-items-center">
                    <div class="col">
                       
                        <p class="m-0 " style="text-align: left;
                        padding-left: 0;">Select a Neighborhood</p>
                    </div>
                    <ng-container *ngIf="monthlyParkingNb">
                        <div *ngIf="(activeBtnDailyMonthly == 'monthly' && monthlyParkingNb) && checkLenght(facilityData)" class="col-auto pr-0 333" [class.hidden-xs]="!monthlyParkingNb">
                            <b> Deals Only </b>
                         </div>
                         <div *ngIf="(activeBtnDailyMonthly == 'monthly' && monthlyParkingNb) && checkLenght(facilityData)" class="col-auto pl-2 444" [class.hidden-xs]="!monthlyParkingNb">
                             <label class="toggleSwitch">
                                     
                                 <input type="checkbox" [checked]="is_deal" (change)="check()">
                                 <span class="slider round"></span>
                               </label>
                         </div>
                    </ng-container>
                    <ng-container *ngIf="ngMapSearch">
                        <div *ngIf="searchTypeText == 'Monthly' && checkLenght(facilityData)" class="col-auto pr-0 hidden-xs">
                            <b> Deals Only </b>
                         </div>
                         <div *ngIf="searchTypeText == 'Monthly' && checkLenght(facilityData)" class="col-auto pl-2 hidden-xs">
                             <label class="toggleSwitch">
                                     
                                 <input type="checkbox" [checked]="is_deal" (change)="check()">
                                 <span class="slider round"></span>
                               </label>
                         </div>
                    </ng-container>
                    
                   </div>
                  
               </header>
               </div>
               <div class="wrpperMapSection col align-self-center " [class.heighFix]="ngMapSearch" [class.iOS]="isIphone" >
                 
                <div class="neighborhoodSVG" >
                    <svg xmlns="http://www.w3.org/2000/svg" class="ie-width-svg" style="max-width:90%;" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 378 838" onClick={this.onClick}>
                        <g id="Map-neighbourhood" class="cls-1"  id='morningside-heights'>

                         
                            <g id="Path_454-2" transform="translate(77.000000, 739.000000)" fill="#FFFFFF" fill-rule="nonzero">
                                <path d="M0,5.274 C0.0539075759,4.35242648 0.268821709,3.44741799 0.635,2.6 C1.39223938,1.63873639 2.25609741,0.766491395 3.21,0 C3.995,0.891 5.166,1.656 5.503,2.694 C8.241,11.112 10.832,19.577 13.449,28.034 C15.897,35.946 18.37,43.849 20.715,51.792 C20.9955948,53.2749427 21.120938,54.7830824 21.089,56.292 C19.306,56.421 16.868,57.343 15.872,56.519 C13.505,54.561 10.782,52.055 10.036,49.277 C6.636,36.613 3.846,23.783 0.885,11.002 C0.467,9.201 0.312,7.339 0,5.274 Z" id="Path_454"></path>
                            </g>
                          <!-- <path id="Path_452" data-name="Path 452" class="cls-white" [ngClass]="{'cls-active': activeNeighbourhood=='kips-bay'}" d="M-1267.636-678.132c-11.549,0-22.673.071-33.79-.138-.885-.016-2.473-1.969-2.486-3.038-.173-13.606-.1-27.216-.139-40.825-.007-2.329.97-3.42,3.321-3.408,6.724.035,13.453-.107,20.169.136a4.384,4.384,0,0,1,3.226,2.552c3.364,14.052,6.507,28.156,9.683,42.253A15.139,15.139,0,0,1-1267.636-678.132Z" transform="translate(1530.458 1115.939)"/>
                          <path id="Path_453" data-name="Path 453" class="cls-white"  d="M-1253.254-653.042c3.293,4.672,6.193,9.434,9.771,13.614a19.631,19.631,0,0,0,7.921,5.622c8.454,2.992,12.048,10.368,15.84,17.246,2.717,4.928,3.791,10.758,5.659,16.164.844,2.444.01,3.543-2.5,3.542-11.437,0-22.876.076-34.31-.142-1.074-.02-3.031-1.909-3.033-2.934-.027-17.595.189-35.191.363-52.786C-1253.542-652.816-1253.374-652.912-1253.254-653.042Z" transform="translate(1523.483 1105.853)"/>
                          <path id="Path_454" data-name="Path 454" class="cls-white" d="M-1476.448-319.118a7.905,7.905,0,0,1,.635-2.674,16.178,16.178,0,0,1,2.575-2.6c.785.891,1.956,1.656,2.293,2.694,2.738,8.418,5.329,16.883,7.946,25.34,2.448,7.912,4.921,15.815,7.266,23.758a21.731,21.731,0,0,1,.374,4.5c-1.783.129-4.221,1.051-5.217.227-2.367-1.958-5.09-4.464-5.836-7.242-3.4-12.664-6.19-25.494-9.151-38.275C-1475.981-315.191-1476.136-317.053-1476.448-319.118Z" transform="translate(1554.439 1060.136)"/> -->
                          <g id="Group_484" data-name="Group 484" transform="translate(9.047 8.634)" (click)="reloadMap('morningside-heights')" id='morningside-heights'>
                            <path id="Path_445" data-name="Path 445" class="cls-2" [ngClass]="{'cls-active': activeNeighbourhood=='morningside-heights'}" d="M-1517.195-1110.5c-11.448,0-22.9-.115-34.344.066-3.7.058-5.031-1.1-4.994-4.907q.243-24.5,0-49c-.033-3.66,1.379-4.66,4.8-4.646q34.988.14,69.977,0c3.439-.014,4.812,1.032,4.78,4.668q-.223,24.5,0,49c.038,3.832-1.349,4.938-5.021,4.884C-1493.724-1110.613-1505.46-1110.5-1517.195-1110.5Z" transform="translate(1556.533 1168.99)"/>
                            <text id="MORNINGSIDE_HEIGHTS" data-name="MORNINGSIDE
                      HEIGHTS" class="cls-3" transform="translate(38.953 26.365)"><tspan x="-35.024" y="0">MORNINGSIDE</tspan><tspan x="-21.571" y="13">HEIGHTS</tspan></text>
                          </g>
                          <g id="Group_512" data-name="Group 512" transform="translate(148.35 7.774)"  id="east-harlem" (click)="reloadMap('east-harlem')">
                            <g id="Group_486" data-name="Group 486" transform="translate(0 0)">
                              <path id="Path_443" data-name="Path 443" class="cls-2" [ngClass]="{'cls-active': activeNeighbourhood=='east-harlem'}" d="M-1342.782-1116.63c-15.586,0-31.173-.1-46.756.076-3.9.046-5.233-1.141-5.181-5.111q.287-21.686,0-43.375c-.047-3.768,1.193-4.963,4.923-4.948q43.754.17,87.509.007c3.656-.012,5.3,1.015,5.752,4.942,1.687,14.632,3.685,29.229,5.705,43.82.458,3.3-.322,4.674-3.912,4.645C-1310.753-1116.707-1326.768-1116.632-1342.782-1116.63Z" transform="translate(1394.721 1169.989)"/>
                            </g>
                            <text id="EAST_HARLEM" data-name="EAST
                      HARLEM" class="cls-3" transform="translate(49.65 23.226)"><tspan x="-12.171" y="0">EAST</tspan><tspan x="-20.284" y="13">HARLEM</tspan></text>
                          </g>
                          <g id="Group_487" data-name="Group 487" transform="translate(148.149 66.834)" id="east-90s" (click)="reloadMap('east-90s')">
                            <path id="Path_439" data-name="Path 439" class="cls-2" [ngClass]="{'cls-active': activeNeighbourhood=='east-90s'}" d="M-1333.669-1045.267q-27.682,0-55.365,0c-5.816,0-5.844-.027-5.849-5.614-.015-15.183.09-30.366-.071-45.547-.04-3.719,1.117-4.969,4.9-4.954q48.283.183,96.566.014c3.1-.009,4.394,1.189,5.552,4.013q5.919,14.442,12.537,28.6a37.4,37.4,0,0,1,3.28,18.78c-.24,3.617-1.661,4.835-5.334,4.792-15.448-.183-30.9-.076-46.351-.076Z" transform="translate(1394.954 1101.387)"/>
                            <text id="EAST_90s" data-name="EAST 90s" class="cls-3" transform="translate(55.851 31.167)"><tspan x="-21.516" y="0">EAST 90s</tspan></text>
                          </g>
                          <g id="Group_490" data-name="Group 490" transform="translate(148.152 128.023)" id="east-80s" (click)="reloadMap('east-80s')">
                            <path id="Path_440" data-name="Path 440" class="cls-2" [ngClass]="{'cls-active': activeNeighbourhood=='east-80s'}" d="M-1277.635-1030.31c4.09-.038,5.458,1.893,5.139,6.105-1.058,13.992-1.922,28-2.75,42.006-.164,2.766-1.353,3.692-4.02,3.688q-55.818-.07-111.637.006c-2.982.005-4.026-1.139-4.011-4.057.078-14.473.139-28.948-.036-43.418-.048-3.975,1.953-4.344,5.084-4.331C-1371.123-1030.235-1296.24-1030.141-1277.635-1030.31Z" transform="translate(1394.951 1030.311)"/>
                            <text id="EAST_80s" data-name="EAST 80s" class="cls-3" transform="translate(55.848 28.977)"><tspan x="-21.516" y="0">EAST 80s</tspan></text>
                          </g>
                          <g id="Group_491" data-name="Group 491" transform="translate(148.331 184.919)" id="east-70s" (click)="reloadMap('east-70s')">
                            <path id="Path_448" data-name="Path 448" class="cls-2" [ngClass]="{'cls-active': activeNeighbourhood=='east-70s'}" d="M-1280.52-964.156c5.364.005,5.448.172,4.433,5.266-1.426,7.153-2.954,14.292-4.135,21.486-.585,3.562-1.9,5.059-5.8,5.042q-51.891-.221-103.784-.016c-3.757.013-5.03-1.156-4.906-4.92.239-7.292.286-14.606-.015-21.893-.172-4.158,1.559-5.056,5.279-5.03C-1371.3-964.092-1298.676-964.174-1280.52-964.156Z" transform="translate(1394.742 964.221)"/>
                            <text id="EAST_70s" data-name="EAST 70s" class="cls-3" transform="translate(55.669 19.081)"><tspan x="-21.516" y="0">EAST 70s</tspan></text>
                          </g>
                          <g id="Group_492" data-name="Group 492" transform="translate(148.364 221.78)" id="east-60s" (click)="reloadMap('east-60s')">
                            <path id="Path_451" data-name="Path 451" class="cls-2" [ngClass]="{'cls-active': activeNeighbourhood=='east-60s'}" d="M-1288.864-920.433c5.168.01,5.534.473,4.959,5.354-.82,6.962-1.74,13.915-2.406,20.891-.325,3.406-1.479,4.981-5.276,4.966q-49.138-.195-98.278-.022c-3.4.009-4.928-.928-4.833-4.6q.3-11.594,0-23.2c-.09-3.5,1.341-4.381,4.571-4.361C-1373.111-921.3-1305.6-920.464-1288.864-920.433Z" transform="translate(1394.703 921.404)"/>
                            <text id="EAST_60s" data-name="EAST 60s" class="cls-3" transform="translate(55.635 19.22)"><tspan x="-21.516" y="0">EAST 60s</tspan></text>
                          </g>
                          <g id="Group_488" data-name="Group 488" transform="translate(8.16 72.831)" id="upper-west-side" (click)="reloadMap('upper-west-side')">
                            <path id="Path_431" data-name="Path 431" class="cls-2" [ngClass]="{'cls-active': activeNeighbourhood=='upper-west-side'}" d="M-1477.351-1043.93c0,15.049-.109,30.1.075,45.148.048,3.9-1.144,5.226-5.112,5.2q-35-.224-70-.008c-3.89.022-5.19-1.2-5.172-5.17q.207-45.362.009-90.726c-.015-3.736,1.119-4.959,4.9-4.936q35.216.217,70.432,0c3.748-.022,4.98,1.136,4.938,4.911C-1477.453-1074.317-1477.352-1059.123-1477.351-1043.93Z" transform="translate(1557.563 1094.421)"/>
                            <text id="UPPER_WEST_SIDE" data-name="UPPER
                      WEST
                      SIDE" class="cls-3" transform="translate(39.84 41.169)"><tspan x="-16.599" y="0">UPPER</tspan><tspan x="-13.491" y="13">WEST</tspan><tspan x="-11.22" y="26">SIDE</tspan></text>
                          </g>
                          <g id="Group_489" data-name="Group 489" transform="translate(7.409 178.051)" id="lincoln-square" (click)="reloadMap('lincoln-square')">
                            <path id="Path_441" data-name="Path 441" class="cls-2" [ngClass]="{'cls-active': activeNeighbourhood=='lincoln-square'}" d="M-1517.861-896.36c-11.872,0-23.746-.11-35.615.064-3.728.054-4.982-1.192-4.958-4.954q.2-33.08,0-66.162c-.019-3.492,1.045-4.8,4.63-4.785q35.831.161,71.661,0c3.491-.016,4.749,1.112,4.728,4.695q-.186,33.08.018,66.162c.032,4.1-1.509,5.1-5.282,5.046C-1494.4-896.471-1506.132-896.359-1517.861-896.36Z" transform="translate(1558.435 972.198)"/>
                            <text id="LINCOLN_SQUARE" data-name="LINCOLN
                      SQUARE" class="cls-3" transform="translate(39.591 35.948)"><tspan x="-21.428" y="0">LINCOLN</tspan><tspan x="-19.948" y="13">SQUARE</tspan></text>
                          </g>
                          <g id="Group_493" data-name="Group 493" transform="translate(7.388 258.64)" id="midtown-west" (click)="reloadMap('midtown-west')">
                            <path id="Path_437" data-name="Path 437" class="cls-2" [ngClass]="{'cls-active': activeNeighbourhood=='midtown-west'}" d="M-1490.82-827.547c-20.744,0-41.488-.091-62.231.079-4.086.034-5.473-1.181-5.406-5.351.222-13.6.189-27.215.016-40.819-.047-3.753,1.187-4.959,4.937-4.949q62.661.154,125.322.005c3.545-.008,4.766,1.174,4.732,4.7q-.2,20.84,0,41.68c.033,3.514-1.143,4.737-4.709,4.715C-1449.045-827.614-1469.933-827.548-1490.82-827.547Z" transform="translate(1558.46 878.588)"/>
                            <text id="MIDTOWN_WEST" data-name="MIDTOWN WEST" class="cls-3" transform="translate(65.612 29.36)"><tspan x="-38.571" y="0">MIDTOWN WEST</tspan></text>
                          </g>
                          <g id="Group_494" data-name="Group 494" transform="translate(148.349 258.617)" id="midtown-east" (click)="reloadMap('midtown-east')">
                            <path id="Path_430" data-name="Path 430" class="cls-1" [ngClass]="{'cls-active': activeNeighbourhood=='midtown-east'}" d="M-1340.89-878.529q24.25,0,48.5.007c5.254.006,5.454.285,4.839,5.323-2.756,22.587-5.554,45.17-8.195,67.77-.458,3.917-1.757,5.664-6.112,5.636q-43.779-.271-87.56-.014c-4.224.022-5.326-1.464-5.3-5.482q.226-33.948,0-67.9c-.029-4.067,1.142-5.48,5.33-5.427C-1373.226-878.406-1357.058-878.529-1340.89-878.529Z" transform="translate(1394.721 878.615)"/>
                            <text id="MIDTOWN_EAST" data-name="MIDTOWN EAST" class="cls-3" transform="translate(51.651 40.383)"><tspan x="-37.251" y="0">MIDTOWN EAST</tspan></text>
                          </g>
                          <g id="Group_497" data-name="Group 497" transform="translate(148.346 343.918)" id="murray-hill" (click)="reloadMap('murray-hill')">
                            <path id="Path_429" data-name="Path 429" class="cls-2" [ngClass]="{'cls-active': activeNeighbourhood=='murray-hill'}" d="M-1345.71-779.45c14.74,0,29.48.118,44.217-.079,3.846-.052,5.281,1.352,5.76,5.034,1.442,11.069,3.092,22.114,4.818,33.144.466,2.98-.278,4.261-3.432,4.217-7.44-.1-14.887.139-22.321-.091-3.705-.114-5.014,1.025-4.961,4.862.191,14.181-.021,28.367.139,42.55.04,3.638-.936,5.048-4.817,5.019q-31.766-.239-63.534,0c-3.816.028-4.886-1.262-4.869-4.966q.183-42.335-.014-84.672c-.022-4.061,1.42-5.122,5.228-5.08C-1374.9-779.354-1360.306-779.45-1345.71-779.45Z" transform="translate(1394.725 779.531)"/>
                            <text id="MURRAY_HILL" data-name="MURRAY
                      HILL" class="cls-3" transform="translate(37.654 41.082)"><tspan x="-20.488" y="0">MURRAY</tspan><tspan x="-11.061" y="13">HILL</tspan></text>
                          </g>
                          <g id="Group_485" data-name="Group 485" transform="translate(95.88 8.377)" id="central-park" (click)="reloadMap('central-park')">
                            <path id="Path_427" data-name="Path 427" [ngClass]="{'cls-active': activeNeighbourhood=='central-park'}" d="M-1409.925-1046.581q0,57.812,0,115.625c0,6.167-.017,6.181-6.158,6.187q-16.744.016-33.486,0c-5.978-.007-6-.025-6-5.941q-.01-80.378,0-160.757c0-24.071.1-48.142-.087-72.211-.033-4.362,1.322-5.7,5.639-5.6,11.442.257,22.895.077,34.344.091,5.727.007,5.754.028,5.756,5.7Q-1409.917-1105.037-1409.925-1046.581Z" transform="translate(1455.669 1169.289)"/>
                            <text id="CENTRAL_PARK" data-name="CENTRAL PARK" class="cls-3" transform="translate(27.782 142.952) rotate(-90)"><tspan x="-36.261" y="0">CENTRAL PARK</tspan></text>
                          </g>
                          <g id="Group_495" data-name="Group 495" transform="translate(7.379 315.502)" id="times-square" (click)="reloadMap('times-square')">
                            <path id="Path_442" data-name="Path 442" class="cls-2" [ngClass]="{'cls-active': activeNeighbourhood=='times-square'}" d="M-1491.05-771.1c-20.611,0-41.222-.112-61.831.09-4.372.042-5.7-1.327-5.583-5.648.273-10.454.188-20.921.034-31.38-.049-3.361,1.127-4.5,4.47-4.5q63.121.112,126.241,0c3.187-.005,4.28,1.181,4.25,4.289-.1,10.89-.147,21.783.02,32.672.057,3.722-1.557,4.542-4.91,4.526C-1449.256-771.154-1470.153-771.1-1491.05-771.1Z" transform="translate(1558.47 812.538)"/>
                            <text id="TIMES_SQUARE" data-name="TIMES SQUARE" class="cls-3" transform="translate(64.621 25.498)"><tspan x="-36.025" y="0">TIMES SQUARE</tspan></text>
                          </g>
                          <g id="Group_496" data-name="Group 496" transform="translate(6.789 361.734)" id="fashion-center" (click)="reloadMap('fashion-center')">
                            <path id="Path_444" data-name="Path 444" class="cls-2" [ngClass]="{'cls-active': activeNeighbourhood=='fashion-center'}" d="M-1491.535-721.552c-20.736,0-41.474-.1-62.21.081-4.106.035-5.518-1.206-5.4-5.355.253-9.156.162-18.326.041-27.488-.043-3.271,1.072-4.528,4.475-4.521q63.283.128,126.567,0c3.369-.007,4.549,1.187,4.5,4.494-.126,9.3-.175,18.616.027,27.919.081,3.738-1.164,4.966-4.933,4.94C-1449.488-721.63-1470.512-721.553-1491.535-721.552Z" transform="translate(1559.155 758.836)"/>
                            <text id="FASHION_CENTER" data-name="FASHION CENTER" class="cls-3" transform="translate(65.211 22.267)"><tspan x="-42.075" y="0">FASHION CENTER</tspan></text>
                          </g>
                          <g id="Group_498" data-name="Group 498" transform="translate(5 404.065)" id="chelsea" (click)="reloadMap('chelsea')">
                            <path id="Path_426" data-name="Path 426" class="cls-2" [ngClass]="{'cls-active': activeNeighbourhood=='chelsea'}" d="M-1492.51-709.572c21.033,0,42.067.111,63.1-.091,4.351-.041,5.631,1.287,5.61,5.616q-.236,47.272,0,94.546c.021,4.372-1.424,5.643-5.69,5.628q-54.084-.2-108.169,0c-3.815.012-5.524-1.207-6.492-4.976-5.309-20.659-10.9-41.245-16.326-61.873a24.287,24.287,0,0,1-.705-5.926c-.074-8.881-.038-17.763-.031-26.645.005-6.262.014-6.271,6.469-6.273Q-1523.629-709.577-1492.51-709.572Z" transform="translate(1561.233 709.664)"/>
                            <text id="CHELSEA" class="cls-3" transform="translate(71 57.935)"><tspan x="-21.84" y="0">CHELSEA</tspan></text>
                          </g>
                          <g id="Group_499" data-name="Group 499" transform="translate(148.346 443.63)" id="gramercy" (click)="reloadMap('gramercy')">
                            <path id="Path_433" data-name="Path 433" class="cls-2" [ngClass]="{'cls-active': activeNeighbourhood=='gramercy'}" d="M-1337.08-598.289c-17.449,0-34.9-.116-52.347.084-4.2.048-5.338-1.386-5.3-5.439q.273-27.277,0-54.557c-.037-3.971.947-5.523,5.227-5.5q52.99.222,105.982.013c3.98-.014,5.123,1.33,5.089,5.211q-.248,27.493.014,54.987c.047,4.26-1.47,5.319-5.467,5.28C-1301.608-598.386-1319.344-598.289-1337.08-598.289Z" transform="translate(1394.725 663.706)"/>
                            <text id="GRAMERCY" class="cls-3" transform="translate(57.654 37.37)"><tspan x="-26.565" y="0">GRAMERCY</tspan></text>
                          </g>
                          <g id="Group_502" data-name="Group 502" transform="translate(166.198 514.106)" id="east-village" (click)="reloadMap('east-village')">
                            <path id="Path_428" data-name="Path 428" class="cls-2" [ngClass]="{'cls-active': activeNeighbourhood=='east-village'}" d="M-1294.049-517.816c-24.745,0-49.49-.081-74.234.079-4.19.028-5.764-1.034-5.7-5.511q.35-26.633.012-53.271c-.047-4.2,1.384-5.33,5.432-5.319q68.012.174,136.025,0c3.877-.01,5.407,1.165,6.218,5.045,3.748,17.929,7.889,35.776,11.812,53.669,1.116,5.09.875,5.3-4.467,5.307Q-1256.5-517.8-1294.049-517.816Z" transform="translate(1373.989 581.843)"/>
                            <text id="EAST_VILLAGE" data-name="EAST VILLAGE" class="cls-3" transform="translate(71.802 39.894)"><tspan x="-33.27" y="0">EAST VILLAGE</tspan></text>
                          </g>
                          <g id="Group_504" data-name="Group 504" transform="translate(198.286 583.625)" id="lower-east-side" (click)="reloadMap('lower-east-side')">
                            <path id="Path_435" data-name="Path 435" class="cls-2" [ngClass]="{'cls-active': activeNeighbourhood=='lower-east-side'}" d="M-1197.123-447.394h-5.213q-64.169,0-128.339,0c-5.949,0-5.97-.016-5.975-5.906-.013-14.182.089-28.364-.064-42.544-.042-3.791,1.007-5.258,5.078-5.245q59.875.2,119.753.021c3.323-.008,4.721.978,5.358,4.383,2.791,14.914,5.9,29.768,8.864,44.649C-1197.414-450.8-1197.363-449.519-1197.123-447.394Z" transform="translate(1336.715 501.091)"/>
                            <text id="LOWER_EAST_SIDE" data-name="LOWER EAST SIDE" class="cls-3" transform="translate(64.714 32.375)"><tspan x="-42.861" y="0">LOWER EAST SIDE</tspan></text>
                          </g>
                          <g id="Group_506" data-name="Group 506" transform="translate(160.574 642.445)" id="chinatown" (click)="reloadMap('chinatown')">
                            <path id="Path_449" data-name="Path 449" class="cls-2" [ngClass]="{'cls-active': activeNeighbourhood=='chinatown'}" d="M-1300.653-432.766a3.813,3.813,0,0,1,3.816,3.8c.023,5.029,1.361,17.331-1.4,25.195-4.739,13.525-6.183,17.082-8.484,22.771-.386.956-2.815,1.928-3.938,1.613q-33.421-9.34-66.708-19.151a4.677,4.677,0,0,1-3.154-4.163c-.054-8.867.417-17.735.535-26.605.045-3.439,2.187-3.464,4.6-3.462C-1364.612-432.758-1314.788-432.764-1300.653-432.766Z" transform="translate(1380.521 432.766)"/>
                            <text id="CHINATOWN" class="cls-3" transform="translate(42.426 28.555)"><tspan x="-29.766" y="0">CHINATOWN</tspan></text>
                          </g>
                          <g id="Group_508" data-name="Group 508" transform="translate(161.152 682.661)" id="civic-center" (click)="reloadMap('civic-center')">
                            <path id="Path_450" data-name="Path 450" class="cls-2" [ngClass]="{'cls-active': activeNeighbourhood=='civic-center'}" d="M-1379.207-386.052l68.618,21.207c-2.813,6.765-5.4,13.057-8.043,19.325-3.112,7.383-6.349,14.715-9.37,22.135-1.043,2.561-2.428,3.869-5.371,3.846-14.157-.11-28.316-.08-42.473-.026-2.58.01-4.012-.705-4-3.617.062-20.044.038-40.089.067-60.134A16.834,16.834,0,0,1-1379.207-386.052Z" transform="translate(1379.85 386.052)"/>
                            <text id="CIVIC_CENTER" data-name="CIVIC
                      CENTER" class="cls-3" transform="translate(27.848 39.339)"><tspan x="-12.771" y="0">CIVIC</tspan><tspan x="-19.316" y="13">CENTER</tspan></text>
                          </g>
                          <g id="Group_505" data-name="Group 505" transform="translate(70.426 642.433)" id="tribeca" (click)="reloadMap('tribeca')">
                            <path id="Path_434" data-name="Path 434" class="cls-2" [ngClass]="{'cls-active': activeNeighbourhood=='tribeca'}" d="M-1485.236-432.428c2.027-.14,3.549-.335,5.07-.336q36.492-.03,72.983-.009c5.7,0,5.863.153,5.865,5.714q.022,47.711,0,95.422c0,5.525-.117,5.605-5.461,5.527-16.166-.238-32.331-.605-48.5-.634-4.147-.008-5.258-1.449-5.457-5.458-.54-10.863-.772-21.851-2.716-32.5-1.655-9.065-5.48-17.74-8.415-26.564-4.2-12.636-8.476-25.25-12.7-37.88C-1484.824-429.936-1484.9-430.789-1485.236-432.428Z" transform="translate(1485.236 432.781)"/>
                            <text id="TRIBECA" class="cls-3" transform="translate(50.574 54.567)"><tspan x="-20.779" y="0">TRIBECA</tspan></text>
                          </g>
                          <g id="Group_509" data-name="Group 509" transform="translate(95.414 754.87)" id="financial-district" (click)="reloadMap('financial-district')">
                            <path id="Path_432" data-name="Path 432" class="cls-2" [ngClass]="{'cls-active': activeNeighbourhood=='financial-district'}" d="M-1456.21-302.175h157.3c-4.745,7.286-8.936,14.029-13.43,20.563-13.188,19.18-31.545,32.542-50.811,44.842-3.014,1.924-6.206,3.581-9.143,5.611-11.214,7.751-22.576,6.823-34.023.717-11.385-6.072-20.62-14.761-29.458-24.029a47.782,47.782,0,0,1-11.641-20.64C-1449.88-283.878-1453.019-292.456-1456.21-302.175Z" transform="translate(1456.21 302.175)"/>
                            <text id="FINANCIAL_DISTRICT" data-name="FINANCIAL
                      DISTRICT" class="cls-3" transform="translate(69.586 33.13)"><tspan x="-25.861" y="0">FINANCIAL</tspan><tspan x="-22.374" y="13">DISTRICT</tspan></text>
                          </g>
                          <g id="Group_507" data-name="Group 507" transform="translate(216.834 642.413)" id="two-bridges" (click)="reloadMap('two-bridges')">
                            <path id="Path_436" data-name="Path 436" class="cls-2" [ngClass]="{'cls-active': activeNeighbourhood=='two-bridges'}" d="M-1315.17-326.085c2.93-6.6,5.381-12.183,7.884-17.746,6.408-14.232,18.324-42.586,23.732-55.515a38.443,38.443,0,0,0,2.97-14.18c.087-4.973.118-10.01.075-14.857-.032-3.565,1.177-4.435,6.254-4.273l75.9-.071c3.878-.409,5.646.773,6.451,3.96,1.9,7.517-.464,14.076-5.168,19.568-7.814,9.122-16.015,17.926-24.309,26.619-16.991,17.811-34.186,35.427-51.2,53.215a9.886,9.886,0,0,1-7.9,3.335C-1291.615-326.175-1302.749-326.085-1315.17-326.085Z" transform="translate(1315.17 432.804)"/>
                            <text id="TWO_BRIDGES" data-name="TWO
                      BRIDGES" class="cls-3" transform="translate(62.166 40.587)"><tspan x="-11.292" y="0">TWO</tspan><tspan x="-21.428" y="13">BRIDGES</tspan></text>
                          </g>
                          <g id="Group_503" data-name="Group 503" transform="translate(57.002 583.608)" id="soho" (click)="reloadMap('soho')">
                            <path id="Path_438" data-name="Path 438" class="cls-2" [ngClass]="{'cls-active': activeNeighbourhood=='soho'}" d="M-1427.043-447.4c-19.325,0-38.651-.1-57.975.087-3.862.038-5.651-1.272-6.6-4.935q-4.206-16.219-8.871-32.319c-.948-3.3.071-4.518,3.283-5.357,12.043-3.145,24-6.632,36.019-9.857a31.711,31.711,0,0,1,8.012-1.183c27.914-.084,55.83.041,83.743-.148,4.432-.03,5.629,1.414,5.566,5.66-.211,14.33-.185,28.665-.014,43,.047,3.925-1.243,5.171-5.188,5.135C-1388.391-447.494-1407.718-447.4-1427.043-447.4Z" transform="translate(1500.829 501.11)"/>
                            <text id="SOHO" class="cls-3" transform="translate(71.999 32.392)"><tspan x="-14.289" y="0">SOHO</tspan></text>
                          </g>
                          <g id="Group_501" data-name="Group 501" transform="translate(101.75 514.115)" id="greenwich-village" (click)="reloadMap('greenwich-village')">
                            <path id="Path_447" data-name="Path 447" class="cls-2" [ngClass]="{'cls-active': activeNeighbourhood=='greenwich-village'}" d="M-1448.784-549.685c0-9.027.126-18.056-.062-27.079-.075-3.622.947-5.108,4.826-5.068,16.456.168,32.916.137,49.372.018,3.414-.025,4.847.934,4.818,4.608q-.212,27.294.008,54.59c.034,3.86-1.394,4.892-5.03,4.861-16.313-.141-32.63-.174-48.942.017-4.084.047-5.135-1.522-5.052-5.3C-1448.652-531.915-1448.784-540.8-1448.784-549.685Z" transform="translate(1448.85 581.833)"/>
                            <text id="GREEN-_WICH_VILLAGE" data-name="GREEN-
                      WICH
                     VILLAGE" class="cls-3" transform="translate(30.25 25.885)"><tspan x="-18.392" y="0">GREEN-</tspan><tspan x="-13.035" y="13">WICH</tspan><tspan x="-19.998" y="26">VILLAGE</tspan></text>
                          </g>
                          <g id="Group_500" data-name="Group 500" transform="translate(24.748 514.11)" id="west-village" (click)="reloadMap('west-village')">
                            <path id="Path_446" data-name="Path 446" class="cls-2" [ngClass]="{'cls-active': activeNeighbourhood=='west-village'}" d="M-1538.294-581.42c2.107-.157,3.363-.331,4.617-.332,20.3-.016,40.6.07,60.9-.086,3.729-.028,5.06,1.172,5.053,4.879q-.061,27.91.2,55.82c.026,2.919-1.383,3.975-3.875,4.683-11.133,3.166-22.266,6.338-33.325,9.747-3.184.981-4.347-.261-5.385-2.924q-11.453-29.388-23.044-58.722C-1534.764-572.453-1536.377-576.55-1538.294-581.42Z" transform="translate(1538.294 581.839)"/>
                            <text id="WEST_VILLAGE" data-name="WEST
                      VILLAGE" class="cls-3" transform="translate(42.252 30.89)"><tspan x="-13.491" y="0">WEST</tspan><tspan x="-19.998" y="13">VILLAGE</tspan></text>
                          </g>
                          <!-- <g id="Group_510" data-name="Group 510" [ngClass]="{'cls-active': activeNeighbourhood=='kips-bay'}" transform="translate(247.566 398.036)" id="kips-bay" (click)="reloadMap('kips-bay')">
                            <text id="KIPS_BAY" data-name="KIPS
                      BAY" class="cls-3" transform="translate(46.434 10.964)"><tspan x="-11.313" y="0">KIPS</tspan><tspan x="-9.267" y="13">BAY</tspan></text>
                            <g id="Group_228" data-name="Group 228" transform="translate(0 13.192)">
                              <line id="Line_13" data-name="Line 13" class="cls-6" x2="27.11" transform="translate(0 2.646)"/>
                              <circle id="Ellipse_18" data-name="Ellipse 18" class="cls-4" cx="3" cy="3" r="3" transform="translate(24.434 -0.229)"/>
                            </g>
                          </g>
                          <g id="Group_511" data-name="Group 511" [ngClass]="{'cls-active': activeNeighbourhood=='stuyvesant'}" transform="translate(288.4 469.024)"  id="stuyvesant" (click)="reloadMap('stuyvesant')">
                            <text id="STUY_VESANT" data-name="STUY
                      VESANT" class="cls-3" transform="translate(53.6 10.976)"><tspan x="-12.452" y="0">STUY</tspan><tspan x="-18.997" y="13">VESANT</tspan></text>
                            <g id="Group_229" data-name="Group 229" transform="translate(0 10.051)">
                              <line id="Line_13-2" data-name="Line 13" class="cls-6" x2="27.11" transform="translate(0 2.646)"/>
                              <ellipse id="Ellipse_18-2" data-name="Ellipse 18" class="cls-4" cx="2.5" cy="3" rx="2.5" ry="3" transform="translate(24.6 -0.076)"/>
                            </g>
                          </g> -->
                          <g    id="kips-bay" (click)="reloadMap('kips-bay')" transform="translate(228.000000, 390.000000)" >
                            <path class="cls-1" [ngClass]="{'cls-active': activeNeighbourhood=='kips-bay'}" d="M36.4150373,47.419016 C24.8660373,47.419016 13.7420373,47.490016 2.62503733,47.281016 C1.74003733,47.265016 0.15203733,45.312016 0.13903733,44.243016 C-0.03396267,30.637016 0.03903733,17.027016 0,3.41801602 C-0.00696266997,1.08901602 0.97003733,-0.00198397888 3.32103733,0.0100160211 C10.0450373,0.0450160211 16.7740373,-0.0969839789 23.4900373,0.146016021 C24.9224766,0.408102878 26.1312872,1.36435979 26.7160373,2.69801602 C30.0800373,16.750016 33.2230373,30.854016 36.3990373,44.951016 C36.4715316,45.7718761 36.4768827,46.597285 36.4150373,47.419016 L36.4150373,47.419016 Z" id="Path_452"  fill-rule="nonzero"></path>
                            <g id="Group_510" transform="translate(21.159037, 6.648016)">
                                <g id="KIPS_BAY" transform="translate(34.434000, 0.964000)" fill="#000000" fill-rule="nonzero" font-family="SourceSansPro-Black, Source Sans Pro" font-size="11" font-weight="700">
                                    <text id="KIPS">
                                        <tspan x="0.687" y="11">KIPS</tspan>
                                    </text>
                                    <text id="BAY">
                                        <tspan x="2.733" y="24">BAY</tspan>
                                    </text>
                                </g>
                                <g id="Group_228" transform="translate(0.000000, 13.192000)">
                                    <line x1="0" y1="3.646" x2="27.11" y2="3.646" id="Line_13" stroke="#ED8222" stroke-width="2" stroke-linecap="round"></line>
                                    <circle id="Ellipse_18" fill="#ED8222" fill-rule="nonzero" cx="27.434" cy="3.771" r="3"></circle>
                                </g>
                            </g>
                        </g>
                        <g id="Group" transform="translate(270.000000, 453.000000)"  id="stuyvesant" (click)="reloadMap('stuyvesant')" >
                            <path class="cls-1"  [ngClass]="{'cls-active': activeNeighbourhood=='stuyvesant'}" d="M0.654301425,-5.68434189e-14 C3.94730142,4.672 6.84730142,9.434 10.4253014,13.614 C12.5530039,16.1219394 15.2767108,18.0551145 18.3463014,19.236 C26.8003014,22.228 30.3943014,29.604 34.1863014,36.482 C36.9033014,41.41 37.9773014,47.24 39.8453014,52.646 C40.6893014,55.09 39.8553014,56.189 37.3453014,56.188 C25.9083014,56.188 14.4693014,56.264 3.03530142,56.046 C1.96130142,56.026 0.00430142495,54.137 0.00230142495,53.112 C-0.0246985751,35.517 0.191301425,17.921 0.365301425,0.326 C0.366301425,0.226 0.534301425,0.13 0.654301425,-5.68434189e-14 Z" id="Path_453" fill-rule="nonzero"></path>
                            <g id="Group_511" transform="translate(18.825301, 15.213000)">
                                <g id="STUY_VESANT" transform="translate(34.600000, 0.976000)" fill="#000000" fill-rule="nonzero" font-family="SourceSansPro-Black, Source Sans Pro" font-size="11" font-weight="700">
                                    <text id="STUY">
                                        <tspan x="6.548" y="11">STUY</tspan>
                                    </text>
                                    <text id="VESANT">
                                        <tspan x="0.003" y="24">VESANT</tspan>
                                    </text>
                                </g>
                                <g id="Group_229" transform="translate(0.000000, 10.051000)">
                                    <line x1="0" y1="3.646" x2="27.11" y2="3.646" id="Line_13-2" stroke="#ED8222" stroke-width="2" stroke-linecap="round"></line>
                                    <ellipse id="Ellipse_18-2" fill="#ED8222" fill-rule="nonzero" cx="27.1" cy="3.924" rx="2.5" ry="3"></ellipse>
                                </g>
                            </g>
                        </g>
                        </g>
                      </svg>
                      
                  <!--  content on btttom center -->
                  <!-- <p class="tex-cetner">Search daily and/or monthly offers<br> for NYC neighborhoods by selecting the area<br> in the map above.</p> -->
                      <!-- <div class="hidden-xs" [innerHtml]="CMSsidebarContent.content_bellow_neighborhood_map | safeHtmlNeighborhood"></div>
                      <div class="visible-xs text-center" style="padding-right: 40px;"><p>{{CMSsidebarContent?.content_bellow_neighborhood_map_res}}</p></div> -->
                  </div>
                  <div class="hidden-xs fixPadding" [innerHtml]="CMSsidebarContent.content_bellow_neighborhood_map | safeHtmlNeighborhood"></div>
                      <div class="visible-xs text-center fixPadding2" style="padding:10px 40px;" [class.heighFix2]="ngMapSearch" [class.iOSfp2]="isIphone"><p>{{CMSsidebarContent?.content_bellow_neighborhood_map_res}}</p></div>


                                        <!-- <div *isIphone style="display: block; height: 150px;"></div>
                                          <div *isFirefox class="hidden-xs" style="display: block; height: 120px;"></div>
                                          <div *isFirefox class="visible-xs" style="display: block; height: 130px;"></div>
                                           <div *isChrome style="display: block; height: 40px;"></div>
                                           <div *isIE style="display: block; height: 100px;"></div> -->

                </div>
                  <div class="hidden-xs col-auto align-self-end w-100 " *ngIf="!parkDetail || !parkList"   [class.neighbourhood]="activeNeighborhoodFilter">
                    <div class="accordianBlock NeighSec hidden-xs ">

                    <accordion [closeOthers]="oneAtATime" *ngIf="!parkDetail || !parkList" >
                        <accordion-group heading="{{CMSsidebarContent?.your_convenient_nyc_title}}">
                            {{CMSsidebarContent?.your_convenient_nyc_content}}
                            <!-- Whether you’re looking for Bronx monthly parking or monthly parking in Manhattan, Icon Parking has you covered. Our parking facilities are located throughout New York City, providing New York drivers with the ability to park in any one of our safe and secure parking areas. In collaboration with Quik Park, Icon Parking offers over 250 parking facilities in the city so you can feel confident you can find a parking space when you need it. -->
                        </accordion-group>
                      </accordion>
            
            
                      <!--  boxx no 2 -->
                      
                    </div>
                    </div>
                </div>

            </div>
        
        
        
    
        
        
        </aside>
        <div class="toogleSidebar hidden-xs" [class.hidden]="(selectedSortType == 'Neighborhood') && !partnerPageOn">
            <a href="javascript:;" (click)="hideFromSideIcon();back(1)" class="btn btn-primary btn-outline-primary">
             <i class="fa fa-angle-left"></i>
            </a>
        </div>
    

    
    
    </div>
    <div id="MapContainer" class="col" [class.mob-hidden]="parkDetail">

        <ng-container  >
            <app-loyality-badge class="SearchPageDesign" [class.activeOnSearchPage]="sidebarOn || sidebarOn1"  [class.partnerPage]="partnerPageOn" (updateTierNeighbour)='updateTier($event)' [containerClass]="filterBadge" [showTies]="true"></app-loyality-badge>
        </ng-container>
        <div [hidden]="!isOverlayActive" [class.specialPrice]="is_monthly_campaign" class="active-garage-overlay-container hidden-xs">
    
            <a href="javascript:;" class="btn text-white  btn-sm btn-fill floatClose overlayClose" (click)="closeActiveGarageOverlay()">
                <i class="fa fa-times"></i>
            </a>
            <div class="overlay " [ngClass]="{'overlay-monthly' : searchCriteria.type == 'monthly', 'specialPricEenable':is_monthly_campaign && searchCriteria.type == 'monthly'}">
                <div *ngIf="is_monthly_campaign && searchCriteria.type == 'monthly'" class="specialPrice">SPECIAL PRICE </div>
        
                <div class="content">
                    <h2 class="title">{{ allParkings?.facility_name_modified}}</h2>
                      <p *ngIf="allParkings?.between_streets" class="sub-title">Between {{allParkings?.between_streets}}</p>
                      <p><img [src]="map_market_location" height="15" style="margin-top:-4px">&nbsp;<span  id="locDistance">{{allParkings?.distance | distanceconversion}}</span></p>
                      <div class="showRatingPopupWrapper">
                       <div *ngIf="isPartners === false && showRating" id="trustboxOpenPopup" class="trustpilot-widget " data-locale="en-US" data-template-id="5419b6ffb0d04a076446a9af" data-businessunit-id="5feb84d1e2aa300001625337" data-style-height="25px" data-style-width="420px" data-theme="light" [attr.data-location]="allParkings.garage_code">
                            <a href="https://www.trustpilot.com/review/iconparkingsystems.com" target="_blank" style="display: none;" rel="noopener"></a>
                        </div>
                        <div *ngIf="isPartners && showRatingPartner" id="partnerTrustboxOpenPopup" class="trustpilot-widget " data-locale="en-US" data-template-id="5419b6ffb0d04a076446a9af" data-businessunit-id="5feb84d1e2aa300001625337" data-style-height="25px" data-style-width="420px" data-theme="light" [attr.data-location]="partnerGarageCode">
                            <a href="https://www.trustpilot.com/review/iconparkingsystems.com" target="_blank" style="display: none;" rel="noopener"></a>
                        </div>
                    </div>
                      <div class="row no-gutters mt-3 align-items-center ">
                                               
                          <div class="col" *ngIf="searchCriteria.type == 'daily' && (allParkings.ticketech_id == '' && (FacilityAvailableFetatures?.parkonect_id =='' || FacilityAvailableFetatures?.parkonect_id ==null ) && (FacilityAvailableFetatures?.valet_manager_id == '' || FacilityAvailableFetatures?.valet_manager_id == null) )" >
                            <small>Rates from</small>
                        <h3  class="mar-top-0 facilityRates" *ngIf="isPartners === false && ((searchCriteria.type == 'daily' && ( allParkings.price!='N/A' ) &&  (allParkings.ticketech_id != '' || (FacilityAvailableFetatures?.parkonect_id != '' && FacilityAvailableFetatures?.parkonect_id != null) || (FacilityAvailableFetatures?.valet_manager_id != '' && FacilityAvailableFetatures?.valet_manager_id != null) ) ) || (baserate!='N/A' && searchCriteria.type == 'monthly')) && (!activeLoading) && availabilityCheck">
                            {{searchCriteria.type == 'daily' ? changeRateUpdated(FacilityAvailableFetatures?.price_without_availibility) : '' }}
                        </h3>
                        <h3 class="mar-top-0 facilityRates"  *ngIf="isPartners === false && ((searchCriteria.type == 'daily' && ( allParkings.price!='N/A' ) &&  (allParkings.ticketech_id != '' || (FacilityAvailableFetatures?.parkonect_id != '' && FacilityAvailableFetatures?.parkonect_id != null) || (FacilityAvailableFetatures?.valet_manager_id != '' && FacilityAvailableFetatures?.valet_manager_id != null) ) ) || (baserate!='N/A' && searchCriteria.type == 'monthly')) && (!activeLoading) && !availabilityCheck">
                            {{searchCriteria.type == 'daily' ? changeRateUpdated(FacilityAvailableFetatures?.price) : ''}}
                        </h3>
                        <h3 class="mar-top-0 facilityRates" *ngIf="isPartners === false && (!activeLoading) && !availabilityCheck && ((searchCriteria.type == 'daily' && ( allParkings.price!='N/A' ) && (allParkings.ticketech_id == '' && (FacilityAvailableFetatures?.parkonect_id =='' || FacilityAvailableFetatures?.parkonect_id ==null ) && (FacilityAvailableFetatures?.valet_manager_id == '' || FacilityAvailableFetatures?.valet_manager_id == null) ) ) )">
                            {{searchCriteria.type == 'daily' ? changeRateUpdated(FacilityAvailableFetatures?.price) : ''}}
                        </h3>
                        <h3 class="mar-top-0 facilityRates" *ngIf=" isPartners === false && (!activeLoading) && availabilityCheck && ((searchCriteria.type == 'daily' && ( allParkings.price!='N/A' ) && (allParkings.ticketech_id == '' && (FacilityAvailableFetatures?.parkonect_id =='' || FacilityAvailableFetatures?.parkonect_id ==null ) && (FacilityAvailableFetatures?.valet_manager_id == '' || FacilityAvailableFetatures?.valet_manager_id == null) ) ) )">
                            {{searchCriteria.type == 'daily' ? changeRateUpdated(FacilityAvailableFetatures?.price_without_availibility) + ' ' : ''}}
                        </h3>
                        <h3 class="mar-top-0 facilityRates" *ngIf="!isPartners && allParkings.price=='N/A' && checkupdatedPrice">
                            {{'N/A'}}
                        </h3>
                        <h3 class="mar-top-0 facilityRates" *ngIf="isPartners && allParkings.price=='N/A' && checkupdatedPriceForPartner">
                            {{'N/A'}}
                        </h3>
                    </div>
                    <div class="col" *ngIf="searchCriteria.type == 'daily' && !(allParkings.ticketech_id == '' && (FacilityAvailableFetatures?.parkonect_id =='' || FacilityAvailableFetatures?.parkonect_id ==null ) && (FacilityAvailableFetatures?.valet_manager_id == '' || FacilityAvailableFetatures?.valet_manager_id == null) )" >
                        <small>Rates from</small>
                        <h3 class="mar-top-0 facilityRates" *ngIf="isPartners === false && ((searchCriteria.type == 'daily' && ( allParkings.price!='N/A' ) &&  (allParkings.ticketech_id != '' || (FacilityAvailableFetatures?.parkonect_id != '' && FacilityAvailableFetatures?.parkonect_id != null) || (FacilityAvailableFetatures?.valet_manager_id != '' && FacilityAvailableFetatures?.valet_manager_id != null) ) ) || (baserate!='N/A' && searchCriteria.type == 'monthly')) && (!activeLoading) && availabilityCheck">
                            {{changeRateUpdated(FacilityAvailableFetatures?.price_without_availibility)}}
                        </h3>
                        <h3 class="mar-top-0 facilityRates" *ngIf="isPartners === false && ((searchCriteria.type == 'daily' && ( allParkings.price!='N/A' ) &&  (allParkings.ticketech_id != '' || (FacilityAvailableFetatures?.parkonect_id != '' && FacilityAvailableFetatures?.parkonect_id != null) || (FacilityAvailableFetatures?.valet_manager_id != '' && FacilityAvailableFetatures?.valet_manager_id != null) ) ) || (baserate!='N/A' && searchCriteria.type == 'monthly')) && (!activeLoading) && !availabilityCheck">
                            {{changeRateUpdated(FacilityAvailableFetatures?.price)}}
                        </h3>
        
                        <h3 class="mar-top-0 facilityRates" *ngIf="isPartners && ((searchCriteria.type == 'daily' && ( allParkings.price!='N/A' ) && (allParkings.ticketech_id != '' || (FacilityAvailableFetatures?.parkonect_id != '' && FacilityAvailableFetatures?.parkonect_id != null) || (FacilityAvailableFetatures?.valet_manager_id != '' && FacilityAvailableFetatures?.valet_manager_id != null) ) ) )">
                            {{searchCriteria.type == 'daily' ? checkFormat(baserate) + ' ' : ''}}
                        </h3>
                        <h3 class="mar-top-0 facilityRates" *ngIf="isPartners && searchCriteria.type == 'daily' && ( allParkings.price!='N/A' ) && (allParkings.ticketech_id == '' && (FacilityAvailableFetatures?.parkonect_id =='' || FacilityAvailableFetatures?.parkonect_id ==null ) && (FacilityAvailableFetatures?.valet_manager_id == '' || FacilityAvailableFetatures?.valet_manager_id == null) ) ">
                            {{searchCriteria.type == 'daily' ? checkFormat(baserate) + ' ' : ''}}
                        </h3>
                        <h3 class="mar-top-0 facilityRates" *ngIf="!isPartners && allParkings.price=='N/A' && checkupdatedPrice">
                            {{'N/A'}}
                        </h3>
                        <h3 class="mar-top-0 facilityRates" *ngIf="isPartners && allParkings.price=='N/A' && checkupdatedPriceForPartner">
                           {{'N/A'}}
                        </h3>
                    </div>
                    <div class="col" *ngIf="searchCriteria.type == 'monthly'">
                        <small>Rates from</small>
                        <h3 *ngIf="selectedMonthlyRate && checkupdatedPrice" class="mar-top-0 facilityRates" [ngClass]="{'sold-out-rate': formatRateMonthly(selectedMonthlyRate) =='Sold Out' }">
                            {{formatRateMonthly(selectedMonthlyRate)}}<small *ngIf="formatRate(selectedMonthlyRate) !== 'N/A'">/mo</small>
                        </h3>
                    </div>
                    <div class="col hide"  *ngIf="isPartners && ((searchCriteria.type == 'daily' && ( allParkings.price!='N/A' ) && (allParkings.ticketech_id != '' || (FacilityAvailableFetatures?.parkonect_id != '' && FacilityAvailableFetatures?.parkonect_id != null) || (FacilityAvailableFetatures?.valet_manager_id != '' && FacilityAvailableFetatures?.valet_manager_id != null) ) ) || (baserate!='N/A' && searchCriteria.type == 'monthly') )">
                  
                            <button class="btn btn-primary text-center btn-fill priceButtonDisable hide" >
                                {{searchCriteria.type == 'daily' ? changeRateUpdated(FacilityAvailableFetatures?.price) + ' ' : 'BUY MONTHLY'}}</button>
                       
                    </div>
                     
                     
        
                        <div class="col-auto">
                            <ng-container *ngIf="(allParkings.ticketech_id == '' && (FacilityAvailableFetatures?.parkonect_id =='' || FacilityAvailableFetatures?.parkonect_id ==null ) && (FacilityAvailableFetatures?.valet_manager_id == '' || FacilityAvailableFetatures?.valet_manager_id == null) )">
                             
                                    <div  *ngIf="isPartners === false && ((searchCriteria.type == 'daily' && ( allParkings.price!='N/A' ) &&  (allParkings.ticketech_id != '' || (FacilityAvailableFetatures?.parkonect_id != '' && FacilityAvailableFetatures?.parkonect_id != null) || (FacilityAvailableFetatures?.valet_manager_id != '' && FacilityAvailableFetatures?.valet_manager_id != null) ) ) || (baserate!='N/A' && searchCriteria.type == 'monthly')) && (!activeLoading) && availabilityCheck">
                    
                                    <button class="btn btn-primary btn-fill" 
                                    (click)="buyDailyMonthly();ovelay();"> {{searchCriteria.type == 'daily' ? ' BOOK NOW ' : 'BUY MONTHLY'}}
                                    <!-- <small>Spot guaranteed</small><span *ngIf="searchCriteria.type == 'daily' && FacilityAvailableFetatures?.paynow_promocode_discount" class="tagoffer">Save {{FacilityAvailableFetatures?.paynow_promocode_discount}} </span> -->
                                    </button>
                                 </div>
                                 <div  *ngIf="isPartners === false && ((searchCriteria.type == 'daily' && ( allParkings.price!='N/A' ) &&  (allParkings.ticketech_id != '' || (FacilityAvailableFetatures?.parkonect_id != '' && FacilityAvailableFetatures?.parkonect_id != null) || (FacilityAvailableFetatures?.valet_manager_id != '' && FacilityAvailableFetatures?.valet_manager_id != null) ) ) || (baserate!='N/A' && searchCriteria.type == 'monthly')) && (!activeLoading) && !availabilityCheck">
                    
                                 
                                <button   class="btn btn-primary btn-fill" 
                                    (click)="buyDailyMonthly();ovelay();"> {{searchCriteria.type == 'daily' ? ' BOOK NOW ' : 'BUY MONTHLY'}}
                                    <!-- <small *ngIf="searchCriteria.type == 'daily'">Spot guaranteed</small> <span *ngIf="searchCriteria.type == 'daily' && FacilityAvailableFetatures?.paynow_promocode_discount" class="tagoffer">Save {{FacilityAvailableFetatures?.paynow_promocode_discount}} </span> -->
                                 </button>
                            </div>
                            <!-- <div class="col" *ngIf=" isPartners === false && (!activeLoading) && availabilityCheck && ((searchCriteria.type == 'daily' && ( allParkings.price!='N/A' ) && (allParkings.ticketech_id == '' && (FacilityAvailableFetatures?.parkonect_id =='' || FacilityAvailableFetatures?.parkonect_id ==null )) ) )">
                    
                            
                                <div class="btn btn-primary btn-fill text-center priceButtonDisable" >{{searchCriteria.type == 'daily' ? changeRateUpdated(FacilityAvailableFetatures?.price_without_availibility) + ' ' : formatRate(allParkings?.monthly_rate_main) + ' | Buy Monthly'}}</div>
                            </div> -->
                            <!-- <div class="col" *ngIf="isPartners === false && (!activeLoading) && !availabilityCheck && ((searchCriteria.type == 'daily' && ( allParkings.price!='N/A' ) && (allParkings.ticketech_id == '' && (FacilityAvailableFetatures?.parkonect_id =='' || FacilityAvailableFetatures?.parkonect_id ==null )) ) )">
                    
                          
                                <div class="btn btn-primary text-center btn-fill priceButtonDisable hide" >{{searchCriteria.type == 'daily' ? changeRateUpdated(FacilityAvailableFetatures?.price) : formatRate(allParkings?.monthly_rate_main) + ' | Buy Monthly'}}</div>
                            </div> -->
                                <!--- monthly btn -->
                                <div  *ngIf="isPartners && ((searchCriteria.type == 'daily' && ( allParkings.price!='N/A' ) && (allParkings.ticketech_id != '' || (FacilityAvailableFetatures?.parkonect_id != '' && FacilityAvailableFetatures?.parkonect_id != null) || (FacilityAvailableFetatures?.valet_manager_id != '' && FacilityAvailableFetatures?.valet_manager_id != null) ) ) || (baserate!='N/A' && searchCriteria.type == 'monthly') )">
                                <button class="btn btn-primary text-center btn-fill priceButtonDisable hide" >
                                {{searchCriteria.type == 'daily' ? changeRateUpdated(FacilityAvailableFetatures?.price) + ' ' : formatRate(allParkings?.monthly_rate_main)}}</button>
                                </div>
                    
                                    <div [class.disabled]="getCouponDisabled" class="col-auto col-12" style="width: 0px;" *ngIf="isPartners === false && allParkings.rate_id && allParkings.rate_type_id == 2 &&  searchCriteria.type == 'daily' || allParkings.rate_type_id == 4 && searchCriteria.type == 'monthly'" >
                                    <div class="col-auto text-center" *ngIf="showcoupon && isPartners === false && allParkings.rate_id && allParkings.rate_type_id == 2 &&  searchCriteria.type == 'daily' || allParkings.rate_type_id == 4 && searchCriteria.type == 'monthly'">
                                        <div [class.disabled]="getCouponDisabled" (click)="manageCoupons();"  style="max-width: 180px;" class="btn btn-primary">
                                            Pay At Garage 
                                        <small>Spot Not Guaranteed</small>
                                        </div>
                                    
                                    </div>
                                </div>
                                <div [class.disabled]="getCouponDisabled" class="col col text-center" *ngIf="showcouponp && isPartners">
                                    <div [class.disabled]="getCouponDisabled" (click)="manageCoupons();"  style="max-width: 180px;" class="btn btn-primary">
                                        Pay At Garage 
                                      <small>Spot Not Guaranteed</small>
                                    </div>
                                </div>
                               
                          
                            </ng-container>
                    
                            <ng-container *ngIf="!(allParkings.ticketech_id == '' && (FacilityAvailableFetatures?.parkonect_id =='' || FacilityAvailableFetatures?.parkonect_id ==null ) && (FacilityAvailableFetatures?.valet_manager_id == '' || FacilityAvailableFetatures?.valet_manager_id == null) )">
                                
                                    <div   *ngIf="isPartners === false && ((searchCriteria.type == 'daily' && ( allParkings.price!='N/A' ) &&  (allParkings.ticketech_id != '' || (FacilityAvailableFetatures?.parkonect_id != '' && FacilityAvailableFetatures?.parkonect_id != null) || (FacilityAvailableFetatures?.valet_manager_id != '' && FacilityAvailableFetatures?.valet_manager_id != null) ) ) || (baserate!='N/A' && searchCriteria.type == 'monthly')) && (!activeLoading) && availabilityCheck">
                                <button  [ngClass]="{'disable-price': (FacilityAvailableFetatures?.price =='N/A' && searchCriteria.type == 'daily') || (baserate =='N/A' && searchCriteria.type == 'monthly'),  'btn btn-primary  btn-fill':true}" 
                                    (click)="buyDailyMonthly();ovelay();"> {{searchCriteria.type == 'daily' ? ' BOOK NOW ' : 'BUY MONTHLY'}}
                                    <!-- <small *ngIf="searchCriteria.type == 'daily'">Spot guaranteed</small> <span *ngIf="searchCriteria.type == 'daily' && FacilityAvailableFetatures?.paynow_promocode_discount" class="tagoffer">Save {{FacilityAvailableFetatures?.paynow_promocode_discount}} </span> -->
                                </button>
                                </div>
                                <div *ngIf="isPartners === false && ((searchCriteria.type == 'daily' && ( allParkings.price!='N/A' ) &&  (allParkings.ticketech_id != '' || (FacilityAvailableFetatures?.parkonect_id != '' && FacilityAvailableFetatures?.parkonect_id != null) || (FacilityAvailableFetatures?.valet_manager_id != '' && FacilityAvailableFetatures?.valet_manager_id != null) ) ) || (baserate!='N/A' && searchCriteria.type == 'monthly')) && (!activeLoading) && !availabilityCheck">
                                <button   [ngClass]="{'disable-price': (FacilityAvailableFetatures?.price =='N/A' && searchCriteria.type == 'daily') || (baserate =='N/A' && searchCriteria.type == 'monthly'), 'btn btn-primary btn-fill':true}" 
                                    (click)="buyDailyMonthly();ovelay();"> {{searchCriteria.type == 'daily' ? ' BOOK NOW ' : 'BUY MONTHLY'}}
                                <!-- <small *ngIf="searchCriteria.type == 'daily'">Spot guaranteed</small><span *ngIf="searchCriteria.type == 'daily' && FacilityAvailableFetatures?.paynow_promocode_discount" class="tagoffer">Save {{FacilityAvailableFetatures?.paynow_promocode_discount}} </span> -->
                                </button>
                            </div>
                            <div *ngIf=" isPartners === false && (!activeLoading) && availabilityCheck && ((searchCriteria.type == 'daily' && ( allParkings.price!='N/A' ) && (allParkings.ticketech_id == '' && (FacilityAvailableFetatures?.parkonect_id =='' || FacilityAvailableFetatures?.parkonect_id ==null ) && (FacilityAvailableFetatures?.valet_manager_id == '' || FacilityAvailableFetatures?.valet_manager_id == null) ) ) )">
                    
                           
                                <button [ngClass]="{'disable-price': (FacilityAvailableFetatures?.price =='N/A' && searchCriteria.type == 'daily') || (baserate =='N/A' && searchCriteria.type == 'monthly'), 'btn btn-primary btn-fill text-center priceButtonDisable':true}" >{{searchCriteria.type == 'daily' ? changeRateUpdated(FacilityAvailableFetatures?.price_without_availibility) + ' ' : 'BUY MONTHLY'}}</button>
                            </div>
                            <div *ngIf="isPartners === false && (!activeLoading) && !availabilityCheck && ((searchCriteria.type == 'daily' && ( allParkings.price!='N/A' ) && (allParkings.ticketech_id == '' && (FacilityAvailableFetatures?.parkonect_id =='' || FacilityAvailableFetatures?.parkonect_id ==null ) && (FacilityAvailableFetatures?.valet_manager_id == '' || FacilityAvailableFetatures?.valet_manager_id == null) ) ) )">
                    
                        
                                <button [ngClass]="{'disable-price': (FacilityAvailableFetatures?.price =='N/A' && searchCriteria.type == 'daily') || (baserate =='N/A' && searchCriteria.type == 'monthly'), 'btn btn-primary btn-fill text-center priceButtonDisable':true}" >{{searchCriteria.type == 'daily' ? changeRateUpdated(FacilityAvailableFetatures?.price) + ' ' : 'BUY MONTHLY'}}</button>
                            </div>
                                <!--- monthly btn -->
                                <div *ngIf="isPartners && ((searchCriteria.type == 'daily' && ( allParkings.price!='N/A' ) && (allParkings.ticketech_id != '' || (FacilityAvailableFetatures?.parkonect_id != '' && FacilityAvailableFetatures?.parkonect_id != null) || (FacilityAvailableFetatures?.valet_manager_id != '' && FacilityAvailableFetatures?.valet_manager_id != null) ) ) || (baserate!='N/A' && searchCriteria.type == 'monthly') )" [ngClass]="{'hide':searchCriteria.type == 'daily'}">
                    
                            
                                <button [ngClass]="{'disable-price': (FacilityAvailableFetatures?.price =='N/A' && searchCriteria.type == 'daily') || (baserate =='N/A' && searchCriteria.type == 'monthly'), 'btn btn-primary btn-fill text-center hide priceButtonDisable':true}" >
                                    {{searchCriteria.type == 'daily' ? checkFormat(baserate) + ' ' : 'BUY MONTHLY'}}</button>
                                </div>
                              
                          <!-- <div class="col-auto" style="width: 0px;" *ngIf="isPartners === false && allParkings.rate_id && allParkings.rate_type_id == 2 &&  searchCriteria.type == 'daily' || allParkings.rate_type_id == 4 && searchCriteria.type == 'monthly'" ></div> -->
                            <div  class="col text-center pr-0" *ngIf="showcoupon && isPartners === false && allParkings.rate_id && allParkings.rate_type_id == 2 &&  searchCriteria.type == 'daily' || allParkings.rate_type_id == 4 && searchCriteria.type == 'monthly'" >
                                <div [class.disabled]="getCouponDisabled" (click)="manageCoupons();" style="max-width: 180px;" class="btn btn-primary" [ngClass]="{'btn btn-primary text-left':true}" >
                                    Pay At Garage 
                                <small>Spot Not Guaranteed</small>
                                </div>
                                
                            </div>
                            <div  class="col text-center pr-0" *ngIf="showcouponp && isPartners">
                                <div [class.disabled]="getCouponDisabled" (click)="manageCoupons();" style="max-width: 180px;" class="btn btn-primary" [ngClass]="{ 'btn btn-primary text-left':true}"   >
                                    Pay At Garage 
                                <small>Spot Not Guaranteed</small>
                                </div>
                            </div>
                             
                                </ng-container>
                    
                            
                        </div>
                       
                    </div>
                    
        
                    
                  
                </div>
              
        
            </div>
        </div>
        <div id="map_canvas" (mouseenter)="showPopenter()" class="negiMap r" [class.blueBarOn]="is_show_bluebar && searchType=='daily'" [style.height.px]="calcHightMobile" [style.margin-top.px]="sideBarValue"   [class.topblack]="isFrame"></div>

    </div>
</div>

<div class="mapVewToggle" *ngIf="!isNeighbourHood" [class.mobileOpen]="sidebarOn" [class.hidden]="showLoader">
        
        
    <ul>
        <li>
            <a *ngIf="!sidebarOn1" class="btn listViewShow visible-xs" (click)="showNeighborhood(); tabList('plist','pview')">
                <img [src]="list_icon" alt="image">
            </a>
            <a *ngIf="!sidebarOn1" class="btn btn-primary btn-outline-primary listViewShow desktogle hidden-xs" (click)="showNeighborhood(); tabList('plist','pview')">
              <i class="fa fa-angle-right"></i>
            </a>
        </li>

    </ul>
</div>

<!-- Google Map Ends Here -->

<!-- aside for desktop  -->

<aside class="contentBar desktopView  hidden" *ngIf="hideTemp" [ngClass]="{'active':sidebarOn,'parkDetail':parkDetail, 'topSlide':!parkList}">
    <!-- <div class="sidebarWidget visible-xs " *ngIf="!parkList" [ngClass]="{'mar-top-iphone':isIphone, 'mar-top-android':isAndroid}"></div> -->
    <div sidebarHeadBar class=" clearfix visible-xs" *ngIf="!parkList">
        <h4 class="modal-title pull-left"><img width="90" src="assets/img/icon_logo-dark.svg" alt="image"></h4>
        <button type="button" class="close pull-right" (click)="back(1)">
      <img [src]="crossSvg" alt="image" width="20" style="margin-top: 10px;">
    </button>
    </div>

    <!--  for tablet and desktop -->

    <article class="hidden-xs" *ngIf="!activeLoading && !availabilityCheck && ((FacilityAvailableFetatures?.category_id == earlyBirdSpecialCategory || FacilityAvailableFetatures?.coupon_code != '') && searchCriteria.type == 'daily'  && FacilityAvailableFetatures?.ticketech_id != '')"
        flagWrapper [style.top]="yscroll" [ngClass]="{'flagIos':isIphone,'flagAndroid':isAndroid }" [class.hidden]="!EarlyBrid">
        <div class=" " flagSearchPage *ngIf="(FacilityAvailableFetatures?.category_id == earlyBirdSpecialCategory || FacilityAvailableFetatures?.coupon_code != '') && searchCriteria.type == 'daily' && FacilityAvailableFetatures?.ticketech_id != ''">
            <div class="erlybirdDate ">
                <h3 class="text-white text-uppercase">Web special</h3>
                <p *ngIf="isPartners == true && (FacilityAvailableFetatures?.category_id == earlyBirdSpecialCategory || FacilityAvailableFetatures?.coupon_code != '')">Paid Reservation Required.</p>
                <p *ngIf="isPartners != true && (FacilityAvailableFetatures?.category_id == earlyBirdSpecialCategory || FacilityAvailableFetatures?.coupon_code != '')">Paid Reservation Required.</p>
                <a href="javascript:void(0)" class="btn btn-link btn-xs" (click)="modalClose()"><i class="fa fa-times"></i> Dismiss</a>
                <p class="text"></p>
            </div>
        </div>
    </article>

    <!--  for cloasing the sidebar -->
    <!-- <a href="javascript:;" class="btn text-white  btn-sm btn-fill floatClose " (click)="updateIsActiveFacility()" >
                        <fa name="times "></fa>
            </a> -->
    <div *ngIf="searchCriteria.type == 'monthly'"  class="month-cross hidden-xs"></div>
    <a href="javascript:;" class="btn text-white  btn-sm btn-fill floatClose hidden-xs " (click)="back(1)">
        <fa name="times "></fa>
    </a>

    <!--  for flaoting buttons -->
    <div btnGroupMobile class="book-btns-wrapper btn-group btn-group-justified visible-xs" *ngIf="parkDetail" [ngClass]="{'floatAndroid':isAndroid, 'floatIphone':isIphone}">

        <a class="btn btn-primary btn-fill tagRibbon" id="dailymonthlybutton" *ngIf="isPartners === false && !activeLoading && !getUpdatedPrice && !availabilityCheck && ( allParkings.price!='N/A' ) && (allParkings.ticketech_id != '' || ( FacilityAvailableFetatures?.parkonect_id != '' && FacilityAvailableFetatures?.parkonect_id != null ) || (FacilityAvailableFetatures?.valet_manager_id != '' && FacilityAvailableFetatures?.valet_manager_id != null) ) && searchCriteria.type == 'daily'"
            (click)="buyDailyMonthly();detailsSidebarClick();">BOOK NOW <small>spot guaranteed</small> <span *ngIf="FacilityAvailableFetatures?.paynow_promocode_discount" class="tagoffer">Save {{FacilityAvailableFetatures?.paynow_promocode_discount}} </span></a>
        <a class="btn btn-primary btn-fill tagRibbon" [ngClass]="{'singleButton':couponShow==0}" id="dailymonthlybutton" *ngIf="isPartners === false && !activeLoading && getUpdatedPrice && !availabilityCheck && (FacilityAvailableFetatures?.price!='N/A' ) && (allParkings.ticketech_id != '' || ( FacilityAvailableFetatures?.parkonect_id != '' && FacilityAvailableFetatures?.parkonect_id != null ) || (FacilityAvailableFetatures?.valet_manager_id != '' && FacilityAvailableFetatures?.valet_manager_id != null) ) && searchCriteria.type == 'daily'"
            (click)="buyDailyMonthly();detailsSidebarClick();">BOOK NOW  {{ checkBookShow() }} <small>spot guaranteed</small> <span *ngIf="FacilityAvailableFetatures?.paynow_promocode_discount" class="tagoffer">Save {{FacilityAvailableFetatures?.paynow_promocode_discount}} </span></a>
        <a class="btn mobile-disable-price btn-fill custom-padding" *ngIf="isPartners === false && FacilityAvailableFetatures?.price_without_availibility !='N/A' && availabilityCheck && !activeLoading && searchCriteria.type == 'daily'" id="dailymonthlybutton">BOOK NOW </a>

        <a  [class.disabled]="getCouponDisabled" [ngClass]="{'singleButton':bookShow==0}" class="btn btn-primary btn-sm btnCoupon theme-btn text-left searchCoupon" id="checkcoupon" (click)="manageCoupons();" *ngIf="showcoupon && isPartners === false && allParkings.rate_id && allParkings.rate_type_id == 2 &&  searchCriteria.type == 'daily' || allParkings.rate_type_id == 4 && searchCriteria.type == 'monthly'">Pay At Garage {{ checkCouponShow() }}<small>Spot not guaranteed</small></a>
        <a  [class.disabled]="getCouponDisabled" class="btn btn-primary btn-sm btnCoupon theme-btn text-center partnerCoupon" id="checkcoupon" (click)="manageCoupons();" *ngIf="showcouponp && isPartners">Pay At Garage  <small >Spot not guaranteed</small></a>
        <!-- hide coupon functionality -->
        <a class="btn btn-primary btn-fill btn-block " id="dailymonthlybutton" (click)="buyDailyMonthly();detailsSidebarClick();" *ngIf="baserate!='N/A' && searchCriteria.type == 'monthly'">BOOK NOW</a>

    </div>  

    <!-- Garage Detail Page Web -->
    <div  class="webSection orangeBar " (scroll)="getPosition()" *ngIf="parkDetail" [ngClass]="{'scrolldevice':isIphone || isAndroid && allParkings.price!='N/A' }">

        <div class="parkDetailsWrapper"  >
            <a href="javascript:;" id="backs" (click)="returnToList();showSideBar();" class="btn btn-link p-0 text-dark mb-3"> <span class="text-primary fa fa-angle-left return-l-icon"></span> &nbsp; &nbsp;Return to Listing</a>
        <div *ngIf="searchCriteria.type == 'monthly'" class="booknow-mont-serch monthly-Details booknow-top text-right">
            <img width="100%" src="assets/img/search-monthlybook.png"></div>
        <div class="parkImg">
            
            <img [src]="imgSrcLocation" [alt]="imgSrcAlt" class='full-width ' height='320'>
            <!-- <span *ngIf="isUpdatedEndTimeFlag" [popover]="MTSPTemplate" container="body" [outsideClick]="true" containerClass="more-time-same-price-box" placement="bottom" class="label more-space-reservation-web hidden-xs">More Time Same Price</span> -->
        </div>
        <!--   flag for coupon discount -->
        <div class="clearfix">

        </div>
        <!--  main data wrapper -->
        <div class="contentWrap">

            <!--  parking name and address -->
            <div class="clearfix panel-body">

                <article class="maping_roll" [class.hidden]="!EarlyBrid">
                    <article class="visible-xs" *ngIf="!activeLoading && !availabilityCheck && ((FacilityAvailableFetatures?.category_id == earlyBirdSpecialCategory || FacilityAvailableFetatures?.coupon_code != '') && searchCriteria.type == 'daily'  && FacilityAvailableFetatures?.ticketech_id != '')"
                        flagWrapper [ngClass]="{'flagIos':isIphone,'flagAndroid':isAndroid }" [class.hidden]="!EarlyBrid">
                        <div class=" " flagSearchPage *ngIf="(FacilityAvailableFetatures?.category_id == earlyBirdSpecialCategory || FacilityAvailableFetatures?.coupon_code != '') && searchCriteria.type == 'daily' && FacilityAvailableFetatures?.ticketech_id != ''">
                            <div class="erlybirdDate ">
                                <h3 class="text-white text-uppercase">Web special</h3>
                                <p *ngIf="isPartners == true && (FacilityAvailableFetatures?.category_id == earlyBirdSpecialCategory || FacilityAvailableFetatures?.coupon_code != '')">Paid Reservation Required.</p>
                                <p *ngIf="isPartners != true && (FacilityAvailableFetatures?.category_id == earlyBirdSpecialCategory || FacilityAvailableFetatures?.coupon_code != '')">Paid Reservation Required.</p>
                                <a href="javascript:void(0)" class="btn btn-link btn-xs" (click)="modalClose()"><i class="fa fa-times"></i>
                  Dismiss</a>
                                <p class="text"></p>
                            </div>
                        </div>


                    </article>
                </article>
                <div class="row no-gutters">
                    <div class="col">
                        <div class="customeFontSetup mt-2">
                        <h3 class=" text-uppercase mar-bottom-xs font-weight-bold mar-top-0" id="parkfull_name" parkTitle>
                            {{allParkings?.geolocation?.address_1}}
                        </h3>
                        <table class="custm-table"><tr><td><span><b>Garage Name:&nbsp;</b></span></td><td><span>{{allParkings?.facility_name_modified}}</span></td></tr></table>
                    </div>
                        <div class="clearfix mar-top-xs" *ngIf="searchCriteria.type == 'monthly' && searchCriteria.device == 'app'">
                            <a [href]="monthlyParking" class="btn-dark "><b>More Info <fa name="angle-right"></fa></b></a>
                        </div>
                        <!--  content hide for mobile view Monthly Page -->
                        <div *ngIf="searchCriteria.type != 'monthly' || searchCriteria.device == 'web' ">
                            <table class="mar-bottom-xs typeBullets customeFontSetup custm-table">
                                <tr *ngIf="allParkings?.between_streets"><td><span><b>Between:&nbsp;</b></span></td><td><span>{{ allParkings?.between_streets }}</span></td></tr>
                                <tr *ngIf="allParkings?.entrance_location"><td><span><b>Entrance:&nbsp;</b></span></td><td><span>{{allParkings.entrance_location}}</span></td></tr>
                            </table>
                            <p class="" id="between_entrance"></p>
                            <div class="row ">
                                <div class="col-auto ">
                                  <img [src]="map_market_location_d" height="13" style="margin-top:-4px">&nbsp; <span id="locDistance">{{allParkings.distance |
                    distanceconversion}}</span>
                                    <br class="visible-xs">
                                </div>
                                <div class="col-auto" *ngIf="allParkings?.phone_number">
                                  <img [src]="phone_icon_d" height="11" style="margin-top:-2px">&nbsp; <a id="garage_phone" style="color: #000;" href="tel:{{formatPhoneNumber(allParkings?.phone_number)}}">{{formatPhoneNumber(allParkings?.phone_number)}}</a>

                                </div>
                            </div>

                            <p class="dailystyles mb-0 mt-3">
                                <a class="btn btn-link py-0 px-0 mr-1" (click)="getdirections.show()">Get Directions </a>  
                                <a *ngIf="searchCriteria.type == 'daily'" style="cursor: pointer;" (click)="openModal(postedfacilityrats)" class="btn btn-link py-0 px-0 mr-1">See Posted Rates</a> </p>
                                <a style="cursor: pointer;" (click)="toViewScroll()"  class="btn hidden-xs btn-link py-2 px-0">Hours of Operations</a>

                                <a style="cursor: pointer; vertical-align: middle;"  *xs href="javascript:;" (click)="scrollToId('elId')" >Hours of Operations</a>



                         
                            <p *ngIf="searchCriteria.type == 'daily' && !activeLoading && (allParkings.ticketech_id == '' && ( FacilityAvailableFetatures?.parkonect_id == '' || FacilityAvailableFetatures?.parkonect_id == null ) && (FacilityAvailableFetatures?.valet_manager_id == '' || FacilityAvailableFetatures?.valet_manager_id == null) )" class="text-uppercase mt-2">
                                <strong [ngClass]="{ 'text-danger' : 1 }">This facility is not available for reservations</strong>
                            </p>
                          
                        </div>
                    </div>
                    <!-- address and rate data section -->
                    <div class="col-auto text-right "> 
                        <div *ngIf="searchCriteria.type == 'daily'">

                       
                        <button class="btnEarlybirdWeb p-0" (click)="btnEarlyBrid()" *ngIf="!activeLoading && !availabilityCheck && ((FacilityAvailableFetatures?.category_id == earlyBirdSpecialCategory || FacilityAvailableFetatures?.coupon_code != '') && searchCriteria.type == 'daily' && FacilityAvailableFetatures?.ticketech_id != '')"><i class="fa fa-2x fa-info-circle"></i></button>
                    </div>
                        <small *ngIf="searchCriteria.type=='daily'">Rates from</small>
                        <h3 class="font-weight-bold m-0 facilityRates" >
                            <!-- base rate -->
                            <div *ngIf="searchCriteria.type == 'daily'">
                                <a *ngIf="activeLoading">
                                    <fa animation="spin" name="circle-o-notch">
                                        <i aria-hidden="true" class="fa fa-circle-o-notch fa-spin"></i>
                                    </fa>
                                </a>
                                <ng-container *ngIf="(allParkings.ticketech_id == '' && (FacilityAvailableFetatures?.parkonect_id =='' || FacilityAvailableFetatures?.parkonect_id ==null ) && (FacilityAvailableFetatures?.valet_manager_id == '' || FacilityAvailableFetatures?.valet_manager_id == null) )">
                               
                                    <span *ngIf="!activeLoading && checkupdatedPrice && !availabilityCheck">
                                            <span *ngIf="FacilityAvailableFetatures?.price" id="base_rate" baseRate >{{changeRateUpdated(FacilityAvailableFetatures?.price)}} </span>
                                    </span>
                                    <span *ngIf="!checkupdatedPrice && !activeLoading">
                                            <span id="base_rate" baseRate > {{checkFormat(baserate)}}</span>
                                    </span>
                                    <span *ngIf="!activeLoading && availabilityCheck"> {{changeRateUpdated(FacilityAvailableFetatures?.price_without_availibility)}}</span>
                                </ng-container>
                                <ng-container *ngIf="!(allParkings.ticketech_id == '' && (FacilityAvailableFetatures?.parkonect_id =='' || FacilityAvailableFetatures?.parkonect_id ==null ) && (FacilityAvailableFetatures?.valet_manager_id == '' || FacilityAvailableFetatures?.valet_manager_id == null) )">
                                 
                                    <span *ngIf="!activeLoading && checkupdatedPrice && !availabilityCheck">
                                              <span *ngIf="FacilityAvailableFetatures?.price" id="base_rate" baseRate >{{changeRateUpdated(FacilityAvailableFetatures?.price)}} </span>
                                    </span>
                                    <span *ngIf="!checkupdatedPrice && !activeLoading">
                                              <span id="base_rate" baseRate > {{checkFormat(baserate)}}</span>
                                    </span>
                                    <span *ngIf="!activeLoading && availabilityCheck"> {{changeRateUpdated(FacilityAvailableFetatures?.price_without_availibility)}}</span>
                                </ng-container>
                            </div>
                            <!-- <span *ngIf="searchCriteria.type == 'monthly'" id="base_rate" baseRate> {{checkFormat(baserate)}} </span> -->
                            <!-- flat trigger -->
                         
                            <!-- <small class="pmn" *ngIf="searchCriteria.type == 'monthly'" >Per month</small> -->
                      
                          
                        </h3>
                        <!-- date and time display div -->
                 
                        <!-- <ng-template  #MTSPTemplate>
                            <div class="more-time-same-price-box" [innerHtml]="MTSMMessage"></div>
                        </ng-template> -->
                        <!-- <span *ngIf="isUpdatedEndTimeFlag && searchCriteria.type == 'daily'" [popover]="MTSPTemplate" container="body" [outsideClick]="true" containerClass="more-time-same-price-box mob-mts-rt" placement="bottom" class="label mar-bottom-xs label-primary more-space-reservation">More Time Same Price</span> -->
                       <!-- for desktop view -->
                        <div class="clearfix"></div>
                        <!-- <div *ngIf="searchCriteria.type == 'daily' && (allParkings.ticketech_id != '' || (FacilityAvailableFetatures?.parkonect_id != '' && FacilityAvailableFetatures?.parkonect_id != null))">
                            <article class="floatRateEngine search-alert-bx" *ngIf="getUpdatedPrice && !enableDefaultMsg && !activeLoading">
                                {{FacilityAvailableFetatures?.coupon_price_applied}}
                                <a class="btn btn-xs btn-link" (click)="openModal(rateEngine)"> <img src="assets/img/rateengileicon.svg" alt="Image"></a>
                            </article>
                        </div> -->
                      
                        <!--  coupon trigger block -->
                        <button style="display: none;" id="triggercoupon" type="button" class="btn btn-primary" (click)="lgModal.show()"></button>
                        <button style="display: none;" id="triggercoupon1" type="button" class="btn btn-primary" (click)="lgModal1.show()"></button>
                        <!-- <small id="couponcontent" class="spsutoava text-center" *ngIf="allParkings.rate_id && allParkings.rate_type_id == 2 && searchCriteria.type == 'daily' || allParkings.rate_type_id == 4 && searchCriteria.type == 'daily'" ></small> -->
                    </div>
                </div>

                <!-- <div class="clearfix border-top monthlyRatesSection" *ngIf="baserate!='N/A' && searchCriteria.type == 'monthly'">
                    <div *ngIf="is_monthly_campaign" class="specialPriceBadgeTop">SPECIAL PRICE</div>
                    <div class="row">
                        <div class="col">
                        <h4 class="mb-0 mt-3 font-weight-bold"> {{selectedvahicletypeText2 }}  <img (click)="openModal(monthlyRateDesc, allParkings.facility_id)" src="/assets/img/help.svg" alt="image" />  </h4>
                         
                         <p><b>Starting {{displayStartDateMonthly()}}</b></p>                                       
                                                                
                                                        
                        </div>
                        
                    </div>


                    <div  class="dropdown selectBoxMap">

                        <ul *ngIf="is_tax_include==0" class="select-list-map ">
                            <li  [class.active]="vhc.rate==baserate.replace('$','') " (click)="changeRate(vhc.rate, vhc.rate_type,vhc.net_rate,vhc.hours_description,vhc.description)" *ngFor="let vhc of vehicletype;">
                                
                                <div class="radio linkLabel" *ngIf="vhc.net_rate != '0.00' && vhc.active==1" >
                                  <table style="width:100%"><tr><td style="width:159px">
                                    <label sourceFont [ngClass]="{'font-weight-bold': selectedvahicletype==vhc.rate_type}" ><input type="radio" name="price" [checked]="selectedvahicletype==vhc.rate_type" value={{vhc.rate_type}} class="radio radio_alt" />${{vhc.net_rate}} - {{vhc.rate_type}} </label></td>
                                    <td><div *ngIf="showSavingFlexibilityMsg(vhc.rate_type)" class="flexibilityBadge pull-left">
                                        SAVINGS + FLEXIBILITY
                                    </div>
                                    </td>
                                    </tr></table>
                                  </div>
                            </li>
                        </ul>
                        <ul *ngIf="is_tax_include==1" class="select-list-map ">
                                <li [class.active]="vhc.rate==baserate.replace('$','') " (click)="changeRate(vhc.rate, vhc.rate_type,vhc.rate,vhc.hours_description,vhc.description)" *ngFor="let vhc of vehicletype;">
                                    
                                    <div class="radio linkLabel" *ngIf="vhc.net_rate != '0.00' && vhc.active==1" >
                                      <table style="width:100%"><tr><td style="width:159px">
                                        <label sourceFont [ngClass]="{'font-weight-bold': selectedvahicletype==vhc.rate_type}" ><input type="radio" name="prices" [checked]="selectedvahicletype==vhc.rate_type" value={{vhc.rate_type}} class="radio radio_alt" />${{vhc.rate}} - {{vhc.rate_type}} </label></td>
                                      <td>
                                        <div *ngIf="showSavingFlexibilityMsg(vhc.rate_type)" class="flexibilityBadge pull-left">
                                            SAVINGS + FLEXIBILITY
                                        </div>
                                      </td></tr></table>
                                    </div></li>
                            </ul>
                        <div class="flex-row hidden ">
                           
                            <div class="col pad-left-0 pad-right-0">


                       
                     
                        
                        <ul *ngIf="is_tax_include==0" class="select-list-map ">
                            <li  [class.active]="vhc.rate==baserate.replace('$','') " (click)="changeRate(vhc.rate, vhc.rate_type,vhc.net_rate,vhc.hours_description,vhc.description)" *ngFor="let vhc of vehicletype;">
                                
                                <div class="radio linkLabel" *ngIf="vhc.net_rate != '0.00' && vhc.active==1" >
                                    <label  sourceFont  [ngClass]="{'font-weight-bold': selectedvahicletype==vhc.rate_type}" ><input type="radio" name="price" [checked]="selectedvahicletype==vhc.rate_type" value={{vhc.rate_type}} class="radio radio_alt" />${{vhc.net_rate}} - {{vhc.rate_type}} </label>
                                </div>
                            </li>
                        </ul>
                        <ul *ngIf="is_tax_include==1" class="select-list-map ">
                                <li [class.active]="vhc.rate==baserate.replace('$','') " (click)="changeRate(vhc.rate, vhc.rate_type,vhc.rate,vhc.hours_description,vhc.description)" *ngFor="let vhc of vehicletype;">
                                    
                                    <div class="radio linkLabel" *ngIf="vhc.net_rate != '0.00' && vhc.active==1" >
                                        <label [ngClass]="{'font-weight-bold': selectedvahicletype==vhc.rate_type}" ><input type="radio" name="prices" [checked]="selectedvahicletype==vhc.rate_type" value={{vhc.rate_type}} class="radio radio_alt" />${{vhc.rate}} - {{vhc.rate_type}} </label>
                                    </div></li>
                            </ul>
                        </div>
                    </div>
                   
                     
                        
                    </div>
                </div> -->
                <div class="clearfix">
                    <h3 class="m-0" *ngIf="searchCriteria.type == 'daily'">
                        <small class="text-uppercase timeDateEdit" id="monthdaily">
                        {{ displayableStartDateWrapper() }} - {{ displayableEndDateWrapper() }}
                         {{ dStartTime }} - {{ dEndTime }} 
                         <!-- <a *isMobile class="btn btn-xs btn-link" (click)="dateTimeMobile()">
                         <fa name="pencil" size="2x"></fa>
                        </a> -->
                        </small>
                    </h3>
                </div>
                <div class="clearfix" *ngIf="searchCriteria.type == 'daily' && isUpdatedEndTimeFlag">
                <p>
                   <i> Your reservation was extended at no extra charge!</i>
                </p>  </div>
                <p *ngIf="searchCriteria.type == 'daily' && !activeLoading && (allParkings.ticketech_id != '' || ( FacilityAvailableFetatures?.parkonect_id != '' && FacilityAvailableFetatures?.parkonect_id != null ) || (FacilityAvailableFetatures?.valet_manager_id != '' && FacilityAvailableFetatures?.valet_manager_id != null) )" class=" text-left mt-2 mb-4 pb-2">
                    <strong [ngClass]="{ 'text-danger' : (FacilityAvailableFetatures?.availability_msg_some_space =='') }">{{FacilityAvailableFetatures?.availability_msg}}</strong>
                </p>
                <div class="clearfix hidden" *ngIf="searchCriteria.type == 'daily'"> 
                    <div class="row align-items-center my-3">
                        <div class="col">
                            <select class="form-control customeSelectForBlock">
                                <option selected>Select Vehicle Size/Type</option>
                                <option >Select Vehicle Size/Type</option>
                            </select>
                        </div>
                        <div class="col-auto pl-0">
                            <ng-template #popTemplate>
                                <p>
                                    Vehicles over 181″ in Length and over 70″ in Height (or 75” high regardless of length) are subject to an additional fee.
                                </p>    
                                <a  class="btn btn-link btn-xs" href="javascript:void(0)"><i  class="fa fa-times"></i> Dismiss</a>
                            </ng-template>


                            <i  [popover]="popTemplate"  [outsideClick]="true" containerClass="msgClassOrange"
                           
                            placement="right" container="body" class="fa fa-info-circle text-primary fa-2x" aria-hidden="true"></i>
                        </div>
                    </div>
                </div>



                <div class="clearfix">

                <div class="book-btns-wrapper " >
                    <span  *ngIf="isPartners === false && !getUpdatedPrice && !availabilityCheck && !activeLoading && ( allParkings.price!='N/A' ) && (allParkings.ticketech_id != '' || (FacilityAvailableFetatures?.parkonect_id != '' && FacilityAvailableFetatures?.parkonect_id != null) || (FacilityAvailableFetatures?.valet_manager_id != '' && FacilityAvailableFetatures?.valet_manager_id != null) ) && searchCriteria.type == 'daily'">
                            <a class="btn btn-primary    btn-fill btn-block" id="dailymonthlybutton" *ngIf="( allParkings.price!='N/A' ) && (allParkings.ticketech_id != '' || (FacilityAvailableFetatures?.parkonect_id != '' && FacilityAvailableFetatures?.parkonect_id != null) || (FacilityAvailableFetatures?.valet_manager_id != '' && FacilityAvailableFetatures?.valet_manager_id != null) ) && searchCriteria.type == 'daily'" (click)="buyDailyMonthly();detailsSidebarClick();" > BOOK NOW 
                                <!-- <small>spot guaranteed</small> <span *ngIf="FacilityAvailableFetatures?.paynow_promocode_discount" class="tagoffer">Save {{FacilityAvailableFetatures?.paynow_promocode_discount}} </span>  -->
                            </a>
                        </span>
                    <span  *ngIf="isPartners === false && getUpdatedPrice && !availabilityCheck && !activeLoading && ( FacilityAvailableFetatures?.price!='N/A' ) && (allParkings.ticketech_id != '' || (FacilityAvailableFetatures?.parkonect_id != '' && FacilityAvailableFetatures?.parkonect_id != null) || (FacilityAvailableFetatures?.valet_manager_id != '' && FacilityAvailableFetatures?.valet_manager_id != null) ) && searchCriteria.type == 'daily' ">
                            <a class="btn btn-primary    btn-fill btn-block" id="dailymonthlybutton" *ngIf="( FacilityAvailableFetatures?.price!='N/A' ) && (allParkings.ticketech_id != '' || (FacilityAvailableFetatures?.parkonect_id != '' && FacilityAvailableFetatures?.parkonect_id != null) || (FacilityAvailableFetatures?.valet_manager_id != '' && FacilityAvailableFetatures?.valet_manager_id != null) ) && searchCriteria.type == 'daily'" (click)="buyDailyMonthly();detailsSidebarClick();" > BOOK NOW 
                                <!-- <small>spot guaranteed</small> <span *ngIf="FacilityAvailableFetatures?.paynow_promocode_discount" class="tagoffer">Save {{FacilityAvailableFetatures?.paynow_promocode_discount}} </span>  -->
                            </a>
                        </span>
                    <span *ngIf="isPartners === false && availabilityCheck && !activeLoading && ( FacilityAvailableFetatures?.price_without_availibility !='N/A' ) && (allParkings.ticketech_id != '' || (FacilityAvailableFetatures?.parkonect_id != '' && FacilityAvailableFetatures?.parkonect_id != null) || (FacilityAvailableFetatures?.valet_manager_id != '' && FacilityAvailableFetatures?.valet_manager_id != null) ) && searchCriteria.type == 'daily'">
                            <a class="btn disable-price btn-primary pad-left-0 btn-block" id="dailymonthlybutton" *ngIf="( FacilityAvailableFetatures?.price_without_availibility !='N/A' ) && (allParkings.ticketech_id != '' || (FacilityAvailableFetatures?.parkonect_id != '' && FacilityAvailableFetatures?.parkonect_id != null) || (FacilityAvailableFetatures?.valet_manager_id != '' && FacilityAvailableFetatures?.valet_manager_id != null) ) && searchCriteria.type == 'daily'" > BOOK NOW 
                                <!-- <small>spot guaranteed</small> <span *ngIf="FacilityAvailableFetatures?.paynow_promocode_discount" class="tagoffer">Save {{FacilityAvailableFetatures?.paynow_promocode_discount}} </span> -->
                            
                            </a>
                        </span>

                    <a class="btn btn-primary pad-left-0 pad-right-0 btn-fill btn-block" id="dailymonthlybutton" style="padding: 10px 15px;" *ngIf="baserate!='N/A' && searchCriteria.type == 'monthly'" (click)="buyDailyMonthly();detailsSidebarClick();">BUY MONTHLY</a>
                    <a  [class.disabled]="getCouponDisabled" class="btn btn-primary btn-sm btnCoupon theme-btn btn-block searchCoupon text-left" id="checkcoupon" (click)="manageCoupons();" *ngIf="showcoupon && isPartners === false && allParkings.rate_id && allParkings.rate_type_id == 2 && searchCriteria.type == 'daily' || allParkings.rate_type_id == 4 && searchCriteria.type == 'monthly'">Pay At Garage <small >Spot not guaranteed</small></a>
                    <a [class.disabled]="getCouponDisabled" class="btn btn-primary btn-sm btnCoupon theme-btn btn-block partnerCoupon text-center" id="checkcoupon" (click)="manageCoupons();" *ngIf="showcouponp && isPartners">Pay At Garage <small >Spot not guaranteed</small></a>
                    <!-- hide coupon functionality -->

                    <small *ngIf="showOversizedMsg" class="smallTextAdded" [ngClass]="{'monthly ' : searchCriteria.type == 'monthly'}" > 
                        {{overSizeMsg}}
                    </small>
                </div>
                
                <p *ngIf="searchCriteria.type == 'daily' && !activeLoading && (allParkings.ticketech_id != '' || ( FacilityAvailableFetatures?.parkonect_id != '' && FacilityAvailableFetatures?.parkonect_id != null ) || (FacilityAvailableFetatures?.valet_manager_id != '' && FacilityAvailableFetatures?.valet_manager_id != null) )" class=" text-left mt-2 mb-4 pb-2">
                  <strong [ngClass]="{ 'text-danger' : (FacilityAvailableFetatures?.availability_msg_some_space =='') }">{{FacilityAvailableFetatures?.availability_msg}}</strong>
               </p>
                <p  class="text-center my-4" *ngIf="searchCriteria.type == 'monthly'" >
                    <img [src]="booknowBadge" width="90%" class="">
                </p>
                <div class="row our-best-d" style="padding-top: 15px; text-align: center; font-size: 10px;" *ngIf="searchCriteria.type == 'daily'">
                    <div class="col pad-right-0" >
                        <img class="mr-2" height="14" [src]="seal_best_deals">Our Best Deals Guaranteed
                    </div>
                    <div class="col pl-2" >
                        <img class="mr-2" height="14" [src]="risk_free_cancellation">Risk-free, Easy Cancellation
                    </div>
                </div>
                

            </div> 
            </div>

           
            <div class="panel-body border-top">
               
                <!-- <div style="display: none;" couponBtnContainer class="clearfix ">
                    <p class="text-uppercase text-primary">Monthly Parking </p>
                    <p>Submit an online monthly parking request form or call us to speak to a representative. Our monthly parking specialist will research our inventory and respond to your request.</p>
                    <div class="customeRow rowInline" *ngIf=" searchCriteria.type == 'monthly'">
                        <div class="row_item">
                            <a (click)="navigateRequestInfoPage()" class="btn btn-primary theme-btn btn-sm">Request info</a>
                        </div>
                        <div class="row_item">
                            <p><a [href]="telephone">Call (888)-705-7845</a>
                                <br>
                                <small>9am to 7pm</small>
                            </p>
                        </div>
                    </div>
                    <div class="customeRow" *ngIf=" searchCriteria.type == 'daily'">
                        <div class="row_item" *hideItBootstrap="['xs']">
                            <a *ngIf="searchCriteria.type=='daily'&& activeBuyOnline" (click)="navigateBuyMonthlyPage()" href="javascript:void(0)" class="btn btn-primary  theme-btn btn-sm">Buy Monthly Parking</a>
                            <a (click)="navigateRequestInfoPage()" class="btn btn-primary theme-btn btn-sm">Request info</a>
                        </div>
                        <div class="row_item" *hideItBootstrap="['xs']">
                            <p><a [href]="telephone">Call (888)-705-7845</a>
                                <br>
                                <small>9am to 7pm</small>
                            </p>
                        </div>
                        <div class="row_item text-center" *showItBootstrap="['xs']">
                            <p><a [href]="telephone">Call (888)-705-7845</a>
                                <br>
                                <small>9am to 7pm</small>
                            </p>
                        </div>

                        <div class="row_item text-center" *showItBootstrap="['xs']">
                            <a *ngIf="searchCriteria.type=='daily' && activeBuyOnline" (click)="navigateBuyMonthlyPage()" [href]="javascriptCode" class="btn btn-primary btn-block theme-btn btn-sm">Buy Monthly parking</a>
                            <a (click)="navigateRequestInfoPage()" class="btn btn-primary btn-block theme-btn btn-sm">Request
                info</a>
                        </div>
                    </div>
                </div> -->

                <!-- <a monthlyBookingBtn class="btn btn-primary btn-fill btn-block visible-xs none" id="dailymonthlybutton" (click)="buyDailyMonthly()"
          *ngIf="searchCriteria.type == 'monthly'">
          Buy Monthly <small>spot guaranteed</small>
        </a> -->
                <!-- mobnthly view adress -->            
                <!-- <div class="clearfix visible-xs " *ngIf="searchCriteria.type == 'monthly'"> -->
                    <!-- <h4 class="">{{chnageText(allParkings?.full_name)}}</h4>
                    <span><b>Between:</b> {{ allParkings?.between_streets }}</span>
                    <p class="mar-bottom-xs">
                        <span>Building Address: {{allParkings?.geolocation?.address_1}}</span>
                    </p>
                    <p id="between_entrance"></p>
                    <div class="row DateBlock">
                        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                            <img [src]="map_market_location" height="12"> &nbsp; <span id="locDistance">{{allParkings.distance | distanceconversion}}</span>
                            <br class="visible-xs">
                        </div>
                        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                            <fa name="phone"></fa> &nbsp; <a id="garage_phone" style="color: #000;" href="tel:{{allParkings.phone_number}}">{{allParkings.phone_number}}</a>

                        </div>
                    </div>



                    <p class="dailystyles"><a (click)="getdirections.show()">Get Directions &nbsp;&nbsp;<fa name="chevron-right"></fa></a></p> -->
                    <!-- <div class="clearfix mar-top-sm flyimg"></div> -->
                    <!-- <div class="clearfix mar-top-xs">
                        <a style="cursor: pointer;" (click)="openModal(postedfacilityrats)">See Posted Rates</a>
                    </div> -->
                    
                <!-- </div> -->
                <!-- <hr class="whiteHr" *ngIf="searchCriteria.type == 'monthly'"> -->               
                <!-- <hr class="whiteHr" *ngIf="searchCriteria.type == 'monthly'"> -->
                <!-- ameneties for desktop -->             
                <div class="clearfix">

                    <!-- <p class="text-uppercase text-primary">Monthly Parking </p> -->
                    <!-- <p class="mar-top-sm">
                        <img style="margin-top: -16px;" height="50px" [src]="parkSafesmall"> Park with Peace of Mind.
                    </p>
    
                    <p>
                        <img src="../../../../assets/img/Rectangle.svg" height="5px">&nbsp;&nbsp;<span *ngIf="searchCriteria.type == 'monthly'">NYC's Only </span>Book & Park Instantly
                    </p>
                    <p>
                        <img src="../../../../assets/img/Rectangle.svg" height="5px">&nbsp;&nbsp;Save with Multi-Month Subscriptions
                    </p>
                    <p>
                        <img src="../../../../assets/img/Rectangle.svg" height="5px">&nbsp;&nbsp;Hassle-Free Guaranteed Spot
                    </p>
                    <p>
                        <img src="../../../../assets/img/Rectangle.svg" height="5px">&nbsp;&nbsp;Contactless Check In/Check Out
                    </p> -->
                 
                    <!-- <hr class="whiteHr" *ngIf=" searchCriteria.type == 'monthly'"> -->
                <p class="monthly-phone-number" *ngIf=" searchCriteria.type == 'monthly'">
                        <strong>Need help with a reservation? </strong>
                    <br>
                    <a [href]="telephone">Call (888) 705-7845</a> (9am to 7pm)
                </p>
    
                    </div>
            </div>
            <div class="panel-body border-top">
                <p class="mar-top-0"><strong>Hours of Operation</strong></p>
                
                <div class="clearfix" *ngIf="searchCriteria.type == 'daily'">
                    <div class="TimeTab" *ngIf="garageWorkingHours?.length == 0">
                        <table width="100%">
                            <tr>
                                <td>
                                    Monday - Sunday
                                </td>
                                <td class="text-right">
                                    Open 24 Hours
                                </td>
                            </tr>
                        </table>
                    </div>

                    <div class="TimeActive table-responsive" *ngIf="garageWorkingHours?.length > 0">
                        <table class="table table-condensed ">
                            <tr *ngFor="let wh of garageWorkingHours;">
                                <td>{{wh.day}}</td>
                                <td>:</td>
                                <td width="90px">{{wh.formatted_open_time}}</td>
                                <td>-</td>
                                <!-- <td>{{wh.formatted_close_time}} {{showtimefornxtornot(wh.close_time)}}</td> -->
                                <td>{{wh.formatted_close_time}}</td>
                            </tr>
                        </table>
                    </div>
                </div>

                <div class="clearfix" *ngIf="searchCriteria.type == 'monthly'">
                    <!-- <hr class="whiteHr" *ngIf="searchCriteria.type == 'daily'"> -->
                  
                    <div class="TimeTab" *ngIf="garageWorkingHours?.length == 0 && searchCriteria.type == 'monthly'">
                        <table width="100%">
                            <tr>
                                <td>
                                    Monday - Sunday
                                </td>
                                <td class="text-right">
                                    Open 24 Hours
                                </td>
                            </tr>
                        </table>
                    </div>
    
                    <div class="TimeActive table-responsive" *ngIf="garageWorkingHours?.length > 0 && searchCriteria.type == 'monthly'">
                        
                        <table class="table table-condensed ">
                            <tr *ngFor="let wh of garageWorkingHours;">
                                <td>{{wh.day}}</td>
                                <td>:</td>
                                <td width="90px">{{wh.formatted_open_time}}</td>
                                <td>-</td>
                                <!-- <td>{{wh.formatted_close_time}} {{showtimefornxtornot(wh.close_time)}}</td> -->
                                <td>{{wh.formatted_close_time}} {{showtimefornxtornot(wh.close_time)}}</td>
                            </tr>
                        </table>
                    </div>
                    </div>


            </div>
            <div class="panel-body border-top">
                    <div class="clearfix" *ngIf="searchCriteria.type == 'daily'">
                          
                        <p class="mar-top-0"><strong>Amenities</strong></p>
                     
                     
                        <ul class="amenities-list">
    
                            <li *ngIf="allParkings.is_generic_ev_charging =='1'">
                                <img [src]="ev_charge_icon" alt="image">
                                <span>
                    EV Charging
                  </span>
                            </li>
                            <li *ngIf="allParkings.is_motorcycle_parking =='1'">
                                <img [src]="motorcycle_icon" alt="image">
                                <span>
                    Motorcycle Parking
                  </span>
                            </li>
                            <li *ngIf="allParkings.is_tesla_charging =='1'">
                                <img [src]="tesla_charger_icon" alt="image">
                                <span>
                    Tesla Charger
                  </span>
                            </li>
                            <li *ngIf="allParkings.is_24hour_open =='1'">
                                <img [src]="open_24hr_icon" alt="image">
                                <span>
                    Open 24 Hours
                  </span>
                            </li>
                            <li *ngIf="allParkings.is_indoor_parking =='1'">
                                <img [src]="indoor_icon" alt="image">
                                <span>
                    Indoor Parking
                  </span>
                            </li>
                            <li *ngIf="allParkings.is_outdoor_parking =='1'">
                                <img [src]="outdoor_icon" alt="image">
                                <span>
                    Outdoor Parking
                  </span>
                            </li>
                            <li *ngIf="!showOversizedMsg">
                                <img [src]="oversized_icon" alt="image">
                                <span>
                    Oversized Vehicles
                  </span>
                            </li>
                            <li *ngIf="allParkings.is_monthly =='1'">
                                <img [src]="monthly_icon" alt="image">
                                <span>
                    Monthly Parking
                  </span>
                            </li>
    
                        </ul>
                        <!-- <hr class="whiteHr"> -->
                    </div>
                <div class="clearfix" *ngIf="searchCriteria.type == 'monthly'">
              
                    <p class="text-uppercase  font-weight-bold mar-top-0">Amenities</p>
                    <ul class="amenities-list">

                        <li *ngIf="allParkings.is_generic_ev_charging =='1'">
                            <img [src]="ev_charge_icon" alt="image">
                            <span>
                EV Charging
              </span>
                        </li>
                        <li *ngIf="allParkings.is_motorcycle_parking =='1'">
                            <img [src]="motorcycle_icon" alt="image">
                            <span>
                Motorcycle Parking
              </span>
                        </li>
                        <li *ngIf="allParkings.is_tesla_charging =='1'">
                            <img [src]="tesla_charger_icon" alt="image">
                            <span>
                Tesla Charger
              </span>
                        </li>
                        <li *ngIf="allParkings.is_24hour_open =='1'">
                            <img [src]="open_24hr_icon" alt="image">
                            <span>
                Open 24 Hours
              </span>
                        </li>
                        <li *ngIf="allParkings.is_indoor_parking =='1'">
                            <img [src]="indoor_icon" alt="image">
                            <span>
                Indoor Parking
              </span>
                        </li>
                        <li *ngIf="allParkings.is_outdoor_parking =='1'">
                            <img [src]="outdoor_icon" alt="image">
                            <span>
                Outdoor Parking
              </span>
                        </li>
                        <li *ngIf="!showOversizedMsg">
                            <img [src]="oversized_icon" alt="image">
                            <span>
                Oversized Vehicles
              </span>
                        </li>
                        <li *ngIf="allParkings.is_monthly =='1'">
                            <img [src]="monthly_icon" alt="image">
                            <span>
                Monthly Parking
              </span>
                        </li>

                    </ul>
                    <!-- <hr class="whiteHr"> -->
                </div>

                
                
                <!-- <div class="clearfix mar-top-sm visible-xs" *ngIf="searchCriteria.type == 'daily'"></div> -->
                <!-- <hr class="whiteHr visible-xs" *ngIf="searchCriteria.type == 'daily'"> -->
                <!-- <div class="flex-row no-gutters ">
                    <div class="col-1 col-sm-1 col-md-1 col-lg-1">
                        <fa name="lightbulb-o" class="text-primary" size="2x"></fa>
                    </div>
                    <div class="col-11 col-sm-11 col-md-11 col-lg-11">
                        <p class="text-primary text-uppercase">Did you know? </p>
                        <ul class="dashList list-unstyled">
                            <li>Many garages don't accept <a routerLink="/oversized"> oversized vehicles</a>, or they often charge between $5 - $15 extra.
                            </li>
                            <li>If you plan to visit for the day, arriving before 10:00am will almost always result in a lower fee and a flexible departure time.
                            </li>
                            <li>
                                Don't see what you want? <a [href]="telephone2"> Call 1-877-PARKING (727-5464)</a> for expert advice.
                                <br>Monday - Friday, 9:00am to 5:00pm
                            </li>
                            <li><a style="cursor: pointer;" (click)="openModal(postedfacilityrats)">See Posted Rates</a><br/><br/></li>


                        </ul>
                        <div class="transparentunderli"></div>
                    </div>
                </div> -->


                <!-- <div class="clearfix posted-facilty-box visible-xs">
                     <p class="text-uppercase text-primary">Posted Facility Rates</p>
                     <p class="hidden-xs">All rates shown below include a {{taxRate}}% parking tax.</p>
                     <accordion>
                        <accordion-group #group  [panelClass]="customClass" (click)="showHide();">
                          <div accordion-heading class="text-center">
                            CLICK TO {{textHideShow}} POSTED FACILITY RATES &nbsp; <i class="float-xs-right glyphicon"
                              [ngClass]="{'glyphicon-chevron-up': group?.isOpen, 'glyphicon-chevron-down': !group?.isOpen}"></i>                          </div>
                       
                    
                     <table class="table tbl-border" *ngFor="let rate of boardRate;">
                       <thead>
                            <tr><th colspan="2">{{rate.name}}</th><th>Total</th></tr>
                        </thead>
                       <tbody>
                                <ng-container *ngFor="let rates of rate.rates;">
                                   <tr *ngIf="rates.category.category==rate.name">
                                        <td >{{rates.description=='' ? '7 Days a week / Enter any time / Exit any time' : rates.description}}</td>
                                        <td >{{rates.details=='' ? '7 Days a week / Enter any time / Exit any time' : rates.details}}</td>
                                        <td >${{rates.price=='' ? '7 Days a week / Enter any time / Exit any time' : rates.price}}</td>                                    </tr>
                                </ng-container>
                           
                       </tbody>
                    </table>
                    <table  class="table" *ngIf="FacilityAvailableFetatures?.facility?.oversize_fee">
                      <thead>
                           <tr><th colspan="2">Additional Fees</th><th>Total</th></tr>
                       </thead>
                      <tbody>
                               <ng-container>
                                  <tr>
                                       <td colspan="2">Oversized vehicle fee</td>
                                       <td >${{FacilityAvailableFetatures.facility.oversize_fee}}</td>                                    
                                  </tr>
                               </ng-container>
                          
                      </tbody>
                   </table>
               </accordion-group>
           </accordion>
                    </div> -->
            </div>
            <div class="panel-body border-top"> 

                <div class="reserBox" >

                    <div style="cursor: pointer;" class="row align-items-center"(click)="shouldShow = !shouldShow">
                        <div class="col">
                            <b>{{cmsPolicyContent.section_title}}</b>
                        </div>
                        <div clss="col-auto">
                            <i *ngIf="!shouldShow" class="fa fa-2x text-primarya-angle-down"></i>
                            <i *ngIf="shouldShow" class="fa fa-2x text-primaryfa-angle-up"></i>
                        </div>
                    </div>
                    <div class="content mt-3" [ngClass]="{show: shouldShow, hide: !shouldShow}">
                        <!-- <ul>
                            <li>You will not be allowed to come and go throughout the duration of your reservation. Only one entry/exit is permitted.</li>
                            <li>To cancel for a full refund please click the cancel reservation button in this email any time prior to the arrival time indicated on the reservation.</li>
                            <li>No refunds will be issued for unused reservations not canceled prior to the arrival time. There are no partial refunds for unused time.</li>
                            <li>Reservations cannot be extended or combined; only one reservation can be applied per parking stay. If you arrive before the reservation time, or, exit after the reservation time, you will be subject to pay for the time parked outside of your reservation. This is calculated automatically, and the payment will be required at the time of check-out.</li>
                            <li>An oversize vehicle surcharge (up to $15.00 per 24 hours) will be collected at the location if the vehicle does not meet the standard size requirement. The general rule that determines if a vehicle is oversize requires the vehicle to be 181 inches in length or longer, or, 65 inches in height or higher.</li>
                       </ul> -->
                       <div [innerHTML]="cmsPolicyContent.post_content"></div>
                    </div>
                   </div>
            </div>

        </div>
   
   
  
    </div> </div>


    <!--  for sidebar list tab -->

    <div id="parkList" class="listTab hhd" [class.hidden]="checkLenght(facilityData) && ( !parkList || hideListView )">
        <div id="headerWrapSideBar">
       
        <a floatingBtnBackParkList *showItBootstrap="['xs']" class="btn" (click)="back(1); ">
           <img [src]="mapListIcon" height="25px">
        </a>
        <!-- info-well segments -->
        <div class="infowell font-italic"  >
            <p  *ngIf="searchCriteria.type == 'daily'"> 
                {{CMSsidebarContent?.sidebar_top_description_for_daily}}
                   <!-- Save Over 50% off On Monthly Parking Limited Time Only. Call 888-705-7845
            7 days a week 8:30am to 7pm Or click BOOK NOW to purchase 24 hours a day. -->
            </p>
            <p *ngIf="searchCriteria.type == 'monthly'" >
                {{CMSsidebarContent?.sidebar_top_description_for_monthly}}
                   <!-- Rates displayed are web specials and require a reservation -->
            </p>
         
        </div>
     
        <div *ngIf="searchCriteria.type == 'monthly'" class="booknow-mont-serch text-right"><a href="javascript:;"><img src="assets/img/search-monthlybook.png"></a></div>
        <header>
             <div class="row no-gutters align-items-center">
                <div class="col">
                    <div class="btn-group filterDropdownMenu" dropdown>
                        <button id="button-basic" dropdownToggle type="button" class="btn  btn-link text-dark dropdown-toggle"
                                aria-controls="dropdown-basic">
                                Sort by <span class="uppercase-text">{{selectedSortType}}</span> &nbsp; <img width="25px" [src]="dropdownArrow">
                        </button>
                        <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu filterDropDown"
                            role="menu" aria-labelledby="button-basic">
                          <li role="menuitem"><a class="dropdown-item" [class.hidden]="selectedSortType == 'Distance' || selectedSortType == 'distance'" (click)="sortBy('distance')" href="javascript:;">Distance</a></li>
                          <!-- <li class="divider dropdown-divider" [class.hidden]="selectedSortType == 'Distance' || selectedSortType == 'distance'"></li> -->
                          <li role="menuitem"><a class="dropdown-item" (click)="sortBy('price')" [class.hidden]="selectedSortType == 'price' || selectedSortType == 'Price'" href="javascript:;">Price</a></li>
                          <!-- <li class="divider dropdown-divider" [class.hidden]="selectedSortType == 'price' || selectedSortType == 'Price'"></li> -->
                          <li role="menuitem"><a class="dropdown-item" (click)="sortBy('Neighborhood')" [class.hidden]="(selectedSortType == 'Neighborhood') && !partnerPageOn" href="javascript:;">Neighborhood</a></li>
                        </ul>
                      </div>
                    <!-- <select id="sortingFilterList" class="sortingFilter 2" (change)="sortBy($event.target.value)">
                        <option value="distance" selected>Sort by Distance</option>
                        <option value="price">Sort by Price</option>
                        <option value="Neighborhood">Neighborhood </option>
                    </select> -->
                </div>
                <div *ngIf="searchCriteria.type == 'monthly' && checkLenght(facilityData)" class="col-auto mr-2 555">
                    <b>Deals Only  </b>
                </div>
                <div *ngIf="searchCriteria.type == 'monthly' && checkLenght(facilityData)" class="col-auto p-0 666">
                    
                    <label class="toggleSwitch">
                        <input type="checkbox" [checked]="is_deal" (change)="check()">
                        <span class="slider round"></span>
                      </label>
                </div>
            </div>
           
        </header>
        <header tabList class="panel-body" >
           
            <table width="100%" class="hide">
                <tr>
                    <td width="20%" class="text-uppercase"> <b>Sort By:</b></td>

                    <td>
                        <div data-toggle="buttons" class="shortingBtns 1">
                            <label #distancesort *ngIf="isPartners == false" id="distancesort" name="distance" (click)="sortBy('distance')" class="btn btn-xs text-uppercase active sortByDistance distancesort ">
                <input type="radio" class="radio sortByDistance" name="options" id="option1" autocomplete="off" checked>
                Distance
              </label>
                            <label *ngIf="isPartners" id="distancesort" name="distance" (click)="sortBy('distance')" class="btn btn-xs text-uppercase active sortByDistance distancesort ">
                <input type="radio" class="radio sortByDistance" name="options" id="option1" autocomplete="off" checked>
                Distance
              </label>
                            <label *ngIf="isPartners == false" name="price" (click)="sortBy('price')" class="btn btn-xs text-uppercase sortByPrice distancesort">
                <input type="radio" class="radio sortByPrice" name="options" id="option2" autocomplete="off"> Price
              </label>
                            <label *ngIf="isPartners" name="price" (click)="sortBy('price')" class="btn btn-xs text-uppercase sortByPrice distancesort">
                <input type="radio" class="radio sortByPrice" name="options" id="option2" autocomplete="off"> Price
              </label>
              <!-- <button *ngIf="existCampaignData" type="button" class="btn btn-link dealsButton" (click)="showDeals()"
              [attr.aria-expanded]="!isCollapsed" aria-controls="collapseBasic"> <span>$</span>Deals
          </button> -->
              
                        </div>
            
                    </td>
                    <td *ngIf="searchCriteria.type == 'monthly'" ><a href="javascript:;" class="btn text-white  btn-sm btn-fill floatClose overlayClose month-search-cross"  (click)="back(1)">
                         <fa name="times " ></fa>
                    </a>
                    </td>
                </tr>
            </table>
          
        </header>

    </div>
        <section class="facScroll clearfix">
            <div class="loader" style="text-align: center;padding: 20px 10px;" *ngIf="pricesortingdone && ( pricesorting || distancesortingloader) && searchCriteria.type=='daily'">
                <a>
                    <fa style="font-size: 20px;" animation="spin" name="circle-o-notch">
                        <i aria-hidden="true" class="fa fa-circle-o-notch fa-spin"></i>
                    </fa>
                </a>
            </div>
            <div class="" style="text-align: center;padding: 20px 10px;" *ngIf="noFacility">
                <a> No Facility Available
            </a>
            </div>
            <ul class="facilityList facilityList1 222" *ngIf="checkLenght(facilityData)">

                <ng-container *ngIf="distancesorting && searchCriteria.type=='daily'">
                    <li *ngFor="let facilityD of facilityData.facilities; let couponIndexSelectCoupon = index" class="facility 5555" data-id="{{facilityD.facility_id}}">
                        <div class="row">
                            <div class="col-4 clipImage">
                                    <img *ngIf="facilityD.photo_url" [src]="facilityD.photo_url" class="img-responsive rounded"  alt="image">
                                    <img *ngIf="!facilityD.photo_url" [src]="park_garage" class="img-responsive notImg rounded" alt="image">
                            </div>
                            <!-- left content data -->
                            <div class="col p-0">
                                <h4 class="text-uppercase mar-bottom-0 mar-top-0" parkTitle>
                                    {{facilityD.entrance_location}}
                                </h4>
                                <p class="mb-0 ">{{facilityD?.facility_name_modified}}</p>
                                <p class="map-mark">
                                    <img [src]="map_market_location" height="14">&nbsp;&nbsp;{{facilityD.distance | distanceconversion}}</p>

                                <p class="facDistance hidden">{{ facilityD.distance }}</p>
                            </div>
                            <!--  price listing and date -->
                            <div class="col-auto text-center">
                                <small *ngIf="searchCriteria.type=='daily'">Rates from </small>
                                <div *ngIf="searchCriteria.type=='daily'" class=" onfacilityload_{{facilityD.facility_id}}">
                                    <a class="loadingprice">
                                        <fa animation="spin" name="circle-o-notch">
                                            <i aria-hidden="true" class="fa fa-circle-o-notch fa-spin"></i>
                                        </fa>
                                    </a>
                                </div>
                                <div *ngIf="searchCriteria.type=='daily'" class="onfacilityloadshow_{{facilityD.facility_id}} hide">
                                    <h3  *ngIf="isPartners == false" id="sorting_{{facilityD.facility_id}}" class="sorting_{{facilityD.facility_id}} mt-0 font-weight-bold">
                                        ${{facilityD.price}}
                                    </h3>
                     
                                    <h3 *ngIf="isPartners == true && facilityD.price!='N/A'" id="sorting_{{facilityD.facility_id}}" class="partner-price mt-0 font-weight-bold">
                                        ${{facilityD.price}}
                                    </h3>
                                    <h3 *ngIf="isPartners == true && facilityD.price=='N/A'" id="sorting_{{facilityD.facility_id}}" class="partner-price mt-0 font-weight-bold">
                                        ${{facilityD.price}} 
                                    </h3>
                                    <!-- <p class="facPrice hidden_Price_{{facilityD.facility_id}} hidden"> ${{ facilityD.price }}</p> -->
                                    <span class="hide  baserate narecord_{{facilityD.facility_id}}"><h2 class=" mt-0 font-weight-bold"> NA</h2></span>
                                </div>

                                <div *ngIf="searchCriteria.type=='monthly'" class="">
                                    <h3 class="mt-0 font-weight-bold" *ngIf="(facilityD.monthly_rate_main>0 && facilityD.monthly_rate_main < 2500000) && isPartners == false &&  searchCriteria.type=='monthly' && facilityD.monthly_rate_main!='N/A*'" id="sorting_{{facilityD.facility_id}}"  (click)="moveToReservation(facilityD.facility_id, facilityD.price, facilityD.monthly_rate_main,facilityD.warning_on_reservation,facilityD.warning_on_reservation_msg);">
                                       ${{facilityD.monthly_rate_main}}
                                    </h3>
                                    <!-- <h3 class="mt-0 font-weight-bold"   style="cursor: default;" *ngIf="(facilityD.monthly_rate_main < 1 || facilityD.monthly_rate_main > 2400000) && isPartners == false &&  searchCriteria.type=='monthly' && facilityD.monthly_rate_main!='N/A*'" id="sorting_{{facilityD.facility_id}}" >
                                           N/A 
                                    </h3> -->
                                    <p class="facPrice hidden">{{ facilityD.monthly_rate_main }}</p>
                                    <!-- <span class="baserate" *ngIf="isPartners == false &&  searchCriteria.type=='monthly' && facilityD.monthly_rate_main=='N/A*'"><h2 class=" mt-0 font-weight-bold"> NA</h2></span> -->
                                </div>
                                
                              
                            </div>
                        </div>
                        <div class="row">
                            <div class="col trustpilot-rate  "  >
                                <!-- TrustBox widget - Micro Combo -->
                            <div class="trustpilot-widget ratingStar"  id="trustboxD1{{couponIndexSelectCoupon}}" data-locale="en-US" data-template-id="5419b6ffb0d04a076446a9af" data-businessunit-id="5feb84d1e2aa300001625337" data-style-height="25px" data-style-width="420px" data-theme="light" [attr.data-location]="facilityD.garage_code">
                                <a href="https://www.trustpilot.com/review/iconparkingsystems.com" target="_blank" rel="noopener"></a>
                            </div>
<!-- End TrustBox widget -->
                            </div>
                        </div>
                        <div class="row mt-2 align-items-center">
                        
                            <div class="col">
                                <ul class="amenity">
                                    <li *ngIf="facilityD.is_mobile_pay_enabled =='1'"><img style="width: 50px;"[src]="payNow_btn" alt="image"></li>
                                    <li *ngIf="facilityD.is_parksafe =='1'"  popover="Vivamus sagittis lacus vel augue laoreet rutrum faucibus."   placement="top" container="body" ><img style="width: 45px;" [src]="parkSafesmall" alt="image"></li>
                                    <li *ngIf="facilityD.is_24hour_open =='1'"><img [src]="open_24hr_icon" alt="image"></li>
                                    <li *ngIf="facilityD.is_indoor_parking =='1'"><img [src]="indoor_icon" alt="image"></li>
                                    <li *ngIf="facilityD.is_outdoor_parking =='1'"><img [src]="outdoor_icon" alt="image"></li>
                                    <li *ngIf="facilityD.is_tesla_charging =='1'"><img [src]="tesla_charger_icon" alt="image"></li>
                                    <li *ngIf="facilityD.is_generic_ev_charging =='1'"><img [src]="ev_charge_icon" alt="image"></li>
                                    <li *ngIf="facilityD.is_oversize =='1'"><img [src]="oversized_icon" alt="image"></li>
                                    <li *ngIf="facilityD.is_motorcycle_parking =='1'"><img [src]="motorcycle_icon" alt="image"></li>
                                    <li *ngIf="facilityD.is_monthly =='1'"><img [src]="monthly_icon" alt="image"></li>
                                </ul>
                            </div>
                            <div  *ngIf="searchCriteria.type=='daily'" class=" col-auto onfacilityloadshow_{{facilityD.facility_id}} hide">
                                <button *ngIf="isPartners == false && facilityD.price!='N/A' && facilityD.price != 0 && facilityD.price > 0 && facilityD.price < 2500000 && (facilityD.ticketech_id != '' || (facilityD?.parkonect_id !='' && facilityD?.parkonect_id !=null) || (facilityD?.valet_manager_id != '' && facilityD?.valet_manager_id != null) )" id="sorting_{{facilityD.facility_id}}" class="btn btn-primary btn-fill sorting_{{facilityD.facility_id}}" (click)="moveToReservation(facilityD.facility_id, facilityD.price, facilityD.monthly_rate_main,facilityD.warning_on_reservation,facilityD.warning_on_reservation_msg);sideBarClick();">
                                    <span > BOOK NOW</span>
                                </button>
                                <!-- <button *ngIf="isPartners == false && facilityD.price!='N/A' && facilityD.price != 0 && facilityD.price > 0 && facilityD.price < 2500000" id="sorting_{{facilityD.facility_id}}" class="btn btn-primary btn-fill sorting_{{facilityD.facility_id}}" (click)="moveToReservation(facilityD.facility_id, facilityD.price, facilityD.monthly_rate_main,facilityD.warning_on_reservation,facilityD.warning_on_reservation_msg);sideBarClick();">
                                    <span > BOOK NOW</span>
                                </button> -->
                                    <!-- <h3 *ngIf="isPartners == true && facilityD.price!='N/A'" id="sorting_{{facilityD.facility_id}}" class="partner-price">
                                        <span> ${{facilityD.price}} </span>
                                    </h3>
                                    <h3 *ngIf="isPartners == true && facilityD.price=='N/A'" id="sorting_{{facilityD.facility_id}}" class="partner-price">
                                        <span> {{facilityD.price}} </span>
                                    </h3> -->
                                    <!-- <p class="facPrice hidden_Price_{{facilityD.facility_id}} hidden"> {{ facilityD.price }}</p> -->
                                    <!-- <span class="hide baserate narecord_{{facilityD.facility_id}}"><h2 class="text-primary"> NA</h2></span> -->
                                </div>
                                <div class="col" *ngIf="searchCriteria.type=='monthly'">
                                    <button *ngIf="(facilityD.monthly_rate_main>0 && facilityD.monthly_rate_main < 2500000) && isPartners == false &&  searchCriteria.type=='monthly' && facilityD.monthly_rate_main!='N/A*'" id="sorting_{{facilityD.facility_id}}" class="btn btn-primary btn-fill " (click)="moveToReservation(facilityD.facility_id, facilityD.price, facilityD.monthly_rate_main,facilityD.warning_on_reservation,facilityD.warning_on_reservation_msg);sideBarClick();">
                                        <span>BOOK NOW </span>
                                    </button>
                                    <!-- <button style="cursor: default;" *ngIf="(facilityD.monthly_rate_main < 1 || facilityD.monthly_rate_main > 2400000) && isPartners == false &&  searchCriteria.type=='monthly' && facilityD.monthly_rate_main!='N/A*'" id="sorting_{{facilityD.facility_id}}" class="btn btn-primary btn-fill ">
                                            <span> N/A </span>
                                    </button> -->
                                    <p class="facPrice hidden">{{ facilityD.monthly_rate_main }}</p>
                                    <!-- <span class="baserate" *ngIf="isPartners == false &&  searchCriteria.type=='monthly' && facilityD.monthly_rate_main=='N/A*'"><h2 class="text-primary"> NA</h2></span> -->
                                </div>
                          
                        </div>
                       
                        <div class="listBody  hidden">
                            <div class="row">
                                <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8">
                                    <h4 class="text-uppercase mar-bottom-0 mar-top-0" parkTitle>
                                        {{facilityD.entrance_location}}
                                    </h4>
                                    <p class="mar-bottom-xs ">{{facilityD?.facility_name_modified}}</p>
                                </div>
                                <div class="col-xs-4 col-sm-4 col-md-4 text-right col-lg-4">
                                    <a style="cursor:pointer" id="details_{{facilityD.facility_id}}" class="btn btn-xs btn-link" (click)="openDetails(facilityD, searchCriteria.type)">Details &nbsp; &nbsp; <fa name="angle-right"></fa></a>
                                </div>
                            </div>
                            <div class="flex-row no-gutters bottom-xs">
                                <div class="col">
                                    <p class="map-mark">
                                        <img [src]="map_market_location" height="14"> &nbsp; {{facilityD.distance | distanceconversion}}</p>

                                    <p class="facDistance hidden">{{ facilityD.distance }}</p>
                                    <ul class="amenity">
                                        <li *ngIf="facilityD.is_24hour_open =='1'"><img [src]="open_24hr_icon" alt="image"></li>
                                        <li *ngIf="facilityD.is_indoor_parking =='1'"><img [src]="indoor_icon" alt="image"></li>
                                        <li *ngIf="facilityD.is_outdoor_parking =='1'"><img [src]="outdoor_icon" alt="image"></li>
                                        <li *ngIf="facilityD.is_tesla_charging =='1'"><img [src]="tesla_charger_icon" alt="image"></li>
                                        <li *ngIf="facilityD.is_generic_ev_charging =='1'"><img [src]="ev_charge_icon" alt="image"></li>
                                        <li *ngIf="facilityD.is_oversize =='1'"><img [src]="oversized_icon" alt="image"></li>
                                        <li *ngIf="facilityD.is_motorcycle_parking =='1'"><img [src]="motorcycle_icon" alt="image"></li>
                                        <li *ngIf="facilityD.is_monthly =='1'"><img [src]="monthly_icon" alt="image"></li>
                                    </ul>

                                </div>
                                <div *ngIf="searchCriteria.type=='daily'" class="col align-self-end text-right onfacilityload_{{facilityD.facility_id}}">
                                    <a class="loadingprice">
                                        <fa animation="spin" name="circle-o-notch">
                                            <i aria-hidden="true" class="fa fa-circle-o-notch fa-spin"></i>
                                        </fa>
                                    </a>
                                </div>

                                <div *ngIf="searchCriteria.type=='daily'" class="col align-self-end text-right onfacilityloadshow_{{facilityD.facility_id}} hide">
                                    <button *ngIf="isPartners == false && (facilityD.ticketech_id != '' || (facilityD?.parkonect_id !='' && facilityD?.parkonect_id !=null) || (facilityD?.valet_manager_id != '' && facilityD?.valet_manager_id != null) )" id="sorting_{{facilityD.facility_id}}" class="btn btn-primary btn-fill sorting_{{facilityD.facility_id}}" (click)="moveToReservation(facilityD.facility_id, facilityD.price, facilityD.monthly_rate_main,facilityD.warning_on_reservation,facilityD.warning_on_reservation_msg);">
                       <span > ${{facilityD.price}} | BOOK NOW</span>
                       </button>
                                    <h3 *ngIf="isPartners == true && facilityD.price!='N/A'" id="sorting_{{facilityD.facility_id}}" class="partner-price">
                                        <span> ${{facilityD.price}} </span>
                                    </h3>
                                    <h3 *ngIf="isPartners == true && facilityD.price=='N/A'" id="sorting_{{facilityD.facility_id}}" class="partner-price">
                                        <span> {{facilityD.price}} </span>
                                    </h3>
                                    <p class="facPrice hidden_Price_{{facilityD.facility_id}} hidden"> {{ facilityD.price }}</p>
                                    <span class="hide baserate narecord_{{facilityD.facility_id}}"><h2 class="text-primary"> N/A</h2></span>
                                </div>

                                <div *ngIf="searchCriteria.type=='monthly'" class="col align-self-end text-right">
                                    <button *ngIf="(facilityD.monthly_rate_main>0 && facilityD.monthly_rate_main < 2500000) && isPartners == false &&  searchCriteria.type=='monthly' && facilityD.monthly_rate_main!='N/A*'" id="sorting_{{facilityD.facility_id}}" class="btn btn-primary btn-fill " (click)="moveToReservation(facilityD.facility_id, facilityD.price, facilityD.monthly_rate_main,facilityD.warning_on_reservation,facilityD.warning_on_reservation_msg);">
                                        <span> ${{facilityD.monthly_rate_main}} | BOOK NOW </span>
                                    </button>
                                    <button style="cursor: default;" *ngIf="(facilityD.monthly_rate_main < 1 || facilityD.monthly_rate_main > 2400000) && isPartners == false &&  searchCriteria.type=='monthly' && facilityD.monthly_rate_main!='N/A*'" id="sorting_{{facilityD.facility_id}}" class="btn btn-primary btn-fill ">
                                            <span> N/A </span>
                                    </button>
                                    <p class="facPrice hidden">{{ facilityD.monthly_rate_main }}</p>
                                    <span class="baserate" *ngIf="isPartners == false &&  searchCriteria.type=='monthly' && facilityD.monthly_rate_main=='N/A*'"><h2 class="text-primary"> NA</h2></span>
                                </div>
                            </div>
                        </div>
                    </li>
                </ng-container>
                <ng-container *ngIf="distancesortingmonthly && searchCriteria.type=='monthly'">
                    <li *ngFor="let facilityD of facilityData.facilities; let couponIndexSelectCoupon = index" class="facility ererereeerer 2" data-id="{{facilityD.facility_id}}">
                        <div class="row">   
                            <div class="col-4 clipImage">
                                <img *ngIf="facilityD.photo_url" [src]="facilityD.photo_url" class="img-responsive rounded" height="234" alt="image">
                                <img *ngIf="!facilityD.photo_url" [src]="park_garage" class="img-responsive notImg rounded" alt="image">
                            </div>

                                <div class="col">
                                        <div class="row">

                                 
                            <div class="col p-0">
                                <h4 class="text-uppercase mar-bottom-0 mar-top-0" parkTitle>
                                    {{facilityD.entrance_location}}
                                </h4>
                                <p class="mb-0 ">{{facilityD?.facility_name_modified}}</p>
                                <p class="map-mark">
                                    <img [src]="map_market_location" height="14">&nbsp;&nbsp;{{facilityD.distance | distanceconversion}}</p>

                                <p class="facDistance hidden">{{ facilityD.distance }}</p>
                            </div>
                            <div class="col-auto text-center" *ngIf="searchCriteria.type=='monthly'">  
                                <div *ngIf="facilityD.monthly_campaign =='1'" class="specialPriceBadgeTop">SPECIAL PRICE</div>
                                <small>Rates from</small>
                               
                                    <h3 class="font-weight-bold m-0" *ngIf="(facilityD.monthly_rate_main>0 && facilityD.monthly_rate_main < 2500000) && isPartners == false &&  searchCriteria.type=='monthly' && facilityD.monthly_rate_main!='N/A*'">
                                        ${{facilityD.monthly_rate_main}}
                                    </h3>
                                    <h3 class="font-weight-bold m-0 sold-out-price"  style="cursor: default;" *ngIf="(facilityD.monthly_rate_main < 1 || facilityD.monthly_rate_main > 2400000) && isPartners == false &&  searchCriteria.type=='monthly' && facilityD.monthly_rate_main!='N/A*'"  >
                                       Sold Out 
                                    </h3>
                                    <p class="facPrice hidden">{{ facilityD.monthly_rate_main }}</p>
                                    <h3 class="font-weight-bold m-0 sold-out-price"  *ngIf="isPartners == false &&  searchCriteria.type=='monthly' && facilityD.monthly_rate_main=='N/A*'"> Sold Out</h3>
                                </div>
                            </div>
                            <div class="row mt-2 f-10 "*ngIf="facilityD?.is_alternative_facility> 0 && facilityD?.is_24hour_open == '0' && searchCriteria.type == 'monthly'" >
                                <div class="col">
                                   *Please be advised of amended hours at this location. <br><a target="_blank" [href]="temporarilyClosedUrl">Click Here </a>for alternative garage information*
 
                                </div>
                            </div>

                        </div>
                                
                        
                        </div>
                        <div class="row">
                                        <div class="col trustpilot-rate  "  >
                                            <!-- TrustBox widget - Micro Combo -->
                                        <div  class="trustpilot-widget ratingStarMonthly" id="trustboxM1{{couponIndexSelectCoupon}}" data-locale="en-US" data-template-id="5419b6ffb0d04a076446a9af" data-businessunit-id="5feb84d1e2aa300001625337" data-style-height="25px" data-style-width="420px" data-theme="light" [attr.data-location]="facilityD.garage_code">
                                            <a href="https://www.trustpilot.com/review/iconparkingsystems.com" target="_blank" rel="noopener"></a>
                                        </div>
      <!-- End TrustBox widget -->
                                        </div>
                                    </div>
                        <div class="row mt-2 align-items-center ">
                            <div class="col">
                                <ul class="amenity">
                                    <li *ngIf="facilityD.is_mobile_pay_enabled =='1'"><img style="width: 50px;"[src]="payNow_btn" alt="image"></li>
                                    <li *ngIf="facilityD.is_parksafe =='1'" popover="Vivamus sagittis lacus vel augue laoreet rutrum faucibus."   placement="top" container="body" ><img style="width: 50px;" [src]="parkSafesmall" alt="image"></li>
                                    <li *ngIf="facilityD.is_24hour_open =='1'"><img [src]="open_24hr_icon" alt="image"></li>
                                    <li *ngIf="facilityD.is_indoor_parking =='1'"><img [src]="indoor_icon" alt="image"></li>
                                    <li *ngIf="facilityD.is_outdoor_parking =='1'"><img [src]="outdoor_icon" alt="image"></li>
                                    <li *ngIf="facilityD.is_tesla_charging =='1'"><img [src]="tesla_charger_icon" alt="image"></li>
                                    <li *ngIf="aminitiesCounter(facilityD) <= count && facilityD.is_generic_ev_charging =='1'"><img [src]="ev_charge_icon" alt="image"></li>
                                    <li *ngIf="aminitiesCounter(facilityD) <= count && facilityD.is_oversize =='1'"><img [src]="oversized_icon" alt="image"></li>
                                    <li *ngIf="aminitiesCounter(facilityD) <= count && facilityD.is_motorcycle_parking =='1'"><img [src]="motorcycle_icon" alt="image"></li>
                                    <li *ngIf="aminitiesCounter(facilityD) <= count && facilityD.is_monthly =='1'"><img [src]="monthly_icon" alt="image"></li>
                                    <li *ngIf="aminitiesCounter(facilityD) > count"><p class="mar-bottom-0"><b>{{aminitiesCounter(facilityD) - count}}+</b></p></li>
                                  </ul>

                            </div>

                            <div *ngIf="searchCriteria.type=='daily'" class=" col-auto text-cetner onfacilityload_{{facilityD.facility_id}}">
                              
                                <button *ngIf="searchCriteria.type=='daily' && facilityD.price!='N/A' && (facilityD.ticketech_id != '' || (facilityD?.parkonect_id !='' && facilityD?.parkonect_id !=null) || (facilityD?.valet_manager_id != '' && facilityD?.valet_manager_id != null) )" id="sorting_{{facilityD.facility_id}}" class="btn btn-primary btn-fill " (click)="moveToReservation(facilityD.facility_id, facilityD.price, facilityD.monthly_rate_main,facilityD.warning_on_reservation,facilityD.warning_on_reservation_msg);sideBarClick()">
                          <span >  BOOK NOW</span>
                          </button>
                                <p class="coupon_threshold_price hidden">{{ facilityD.coupon_threshold_price }}</p>
                                <p class="facPrice hidden_Price_{{facilityD.facility_id}} hidden">{{ facilityD.price }}</p>
                                <!-- <span class="baserate" *ngIf="searchCriteria.type=='daily' && facilityD.price=='N/A'"><h2 class="text-primary"> NA</h2></span> -->
                            </div>
                            <div *ngIf="searchCriteria.type=='daily'" class=" col-auto text-center onfacilityloadshow_{{facilityD.facility_id}} hide">
                              
                                <button *ngIf="(facilityD.ticketech_id != '' || (facilityD?.parkonect_id !='' && facilityD?.parkonect_id !=null) || (facilityD?.valet_manager_id != '' && facilityD?.valet_manager_id != null) )" class="btn btn-primary btn-fill sorting_{{facilityD.facility_id}}" (click)="moveToReservation(facilityD.facility_id, facilityD.price, facilityD.monthly_rate_main,facilityD.warning_on_reservation,facilityD.warning_on_reservation_msg);sideBarClick()">
                     <span > BOOK NOW</span>
                     </button>

                                <p class="facPrice hidden_Price_{{facilityD.facility_id}} hidden">{{ facilityD.price }}</p>
                                <!-- <span class="hide baserate narecord_{{facilityD.facility_id}}"><h2 class="text-primary"> NA</h2></span> -->
                            </div>

                            <div *ngIf="searchCriteria.type=='monthly'" class="col-auto texxt-cetner">
                            
                                <button *ngIf="(facilityD.monthly_rate_main>0 && facilityD.monthly_rate_main < 2500000) && isPartners == false &&  searchCriteria.type=='monthly' && facilityD.monthly_rate_main!='N/A*'" class="btn btn-primary btn-fill " (click)="moveToReservation(facilityD.facility_id, facilityD.price, facilityD.monthly_rate_main,facilityD.warning_on_reservation,facilityD.warning_on_reservation_msg);sideBarClick()">
                                    <span > {{facilityD.monthly_campaign =='1' ? 'BUY MONTHLY' : 'BOOK NOW'}}</span>
                                    </button>
                                <!-- <button style="cursor: default;" *ngIf="(facilityD.monthly_rate_main < 1 || facilityD.monthly_rate_main > 2400000) && isPartners == false &&  searchCriteria.type=='monthly' && facilityD.monthly_rate_main!='N/A*'" class="btn btn-primary btn-fill " >
                                      <span> N/A </span>
                                </button> -->
                                <p class="facPrice hidden">{{ facilityD.monthly_rate_main }}</p>
                                <!-- <span class="baserate" *ngIf="isPartners == false &&  searchCriteria.type=='monthly' && facilityD.monthly_rate_main=='N/A*'"><h2 class="text-primary"> NA</h2></span> -->
                            </div>
                        </div>
                    
                        
                    </li>
                </ng-container>
                <ng-container *ngIf="pricesorting && searchCriteria.type=='monthly'">
                    <li *ngFor="let facilityD of facilityDataMonthly.facilities | sorting:'monthlynew'; let couponIndexSelectCoupon = index" class="facility bbbbbb" data-id="{{facilityD.facility_id}}">
                        <div class="row">
                           <div class="col-4 clipImage">
                            <img *ngIf="facilityD.photo_url" [src]="facilityD.photo_url" class="img-responsive rounded" height="234" alt="image">
                            <img *ngIf="!facilityD.photo_url" [src]="park_garage" class="img-responsive notImg rounded" alt="image">
                            </div>
                            <div class="col">
                                <div class="row">
                                    
                             
                            <div class="col p-0">
                                <h4 class="text-uppercase mar-bottom-0 mar-top-0" parkTitle>
                                    {{facilityD.entrance_location}}
                                </h4>
                                <p class="mb-0 ">{{facilityD?.facility_name_modified}}</p>
                                <p class="map-mark">
                                    <img [src]="map_market_location" height="14">&nbsp;&nbsp;{{facilityD.distance | distanceconversion}}</p>

                                <p class="facDistance hidden">{{ facilityD.distance }}</p>
                            </div>
                            <div class="col-auto text-center ">
                                <small *ngIf="searchCriteria.type=='daily'">Rates from</small>
                                <div *ngIf="searchCriteria.type=='daily'" class=" onfacilityload_{{facilityD.facility_id}}">
                                    <h3 class="font-wight-bold m-0" *ngIf=" searchCriteria.type=='daily' && facilityD.price!='N/A'" id="sorting_{{facilityD.facility_id}}"  (click)="moveToReservation(facilityD.facility_id, facilityD.price, facilityD.monthly_rate_main,facilityD.warning_on_reservation,facilityD.warning_on_reservation_msg);">
                             ${{facilityD.price}}
                                    </h3>
                                    <p id="threshold_{{facilityD.facility_id}}" class="coupon_threshold_price hidden">{{ facilityD.coupon_threshold_price }}</p>
                                    <p class="facPrice hidden_Price_{{facilityD.facility_id}} hidden">{{ facilityD.price }}</p>
                                    <h3 *ngIf=" searchCriteria.type=='daily' && facilityD.price=='N/A'">NA</h3>
                                </div>
                                <div *ngIf="searchCriteria.type=='daily'" class=" onfacilityloadshow_{{facilityD.facility_id}} hide">
                                    <h3 class="font-wight-bold m-0" id="sorting_{{facilityD.facility_id}}" class="btn btn-primary btn-fill sorting_{{facilityD.facility_id}}" (click)="moveToReservation(facilityD.facility_id, facilityD.price, facilityD.monthly_rate_main,facilityD.warning_on_reservation,facilityD.warning_on_reservation_msg);">
                      ${{facilityD.price}} 
                                    </h3>

                                    <p class="facPrice hidden_Price_{{facilityD.facility_id}} hidden">{{ facilityD.price }}</p>
                                    <h3 class=" font-weight-bold m-0 narecord_{{facilityD.facility_id}}">NA</h3>
                                </div>

                                <div *ngIf="searchCriteria.type=='monthly'" >
                                    <h3 class="font-wight-bold m-0"  *ngIf="(facilityD.monthly_rate_mains >0 && facilityD.monthly_rate_mains < 2500000) && isPartners == false &&  searchCriteria.type=='monthly' && facilityD.monthly_rate_main!='N/A*'" id="sorting_{{facilityD.facility_id}}"  (click)="moveToReservation(facilityD.facility_id, facilityD.price, facilityD.monthly_rate_main,facilityD.warning_on_reservation,facilityD.warning_on_reservation_msg);">
                                       ${{facilityD.monthly_rate_main}}
                                    </h3>
                                    <h3 class="font-wight-bold m-0" style="cursor: default;" *ngIf="(facilityD.monthly_rate_main < 1 || facilityD.monthly_rate_main > 2400000) && isPartners == false &&  searchCriteria.type=='monthly' && facilityD.monthly_rate_main!='N/A*'" id="sorting_{{facilityD.facility_id}}"  >
                                        N/A 
                                    </h3>
                                    <p class="facPrice hidden">{{ facilityD.monthly_rate_main }}</p>
                                    <h3 class="font-wight-bold m-0" *ngIf="isPartners == false &&  searchCriteria.type=='monthly' && facilityD.monthly_rate_main=='N/A*'"> NA</h3>
                                </div>
                            </div>
                               </div>
                               <div class="row f-10 mt-2"*ngIf="facilityD?.is_alternative_facility> 0 && facilityD?.is_24hour_open == '0' && searchCriteria.type == 'monthly'">
                                   <div class="col">
                                *Please be advised of amended hours at this location.<br class="hidden-xs"> <a target="_blank" [href]="temporarilyClosedUrl">Click Here </a>for alternative garage information*

                                   </div>
                               </div>

                            </div>
                        </div>
                        <div class="row">
                            <div class="col trustpilot-rate  "  >
                                <!-- TrustBox widget - Micro Combo -->
                            <div  class="trustpilot-widget ratingStarMonthly" id="trustboxM2{{couponIndexSelectCoupon}}" data-locale="en-US" data-template-id="5419b6ffb0d04a076446a9af" data-businessunit-id="5feb84d1e2aa300001625337" data-style-height="25px" data-style-width="420px" data-theme="light" [attr.data-location]="facilityD.garage_code">
                                <a href="https://www.trustpilot.com/review/iconparkingsystems.com" target="_blank" rel="noopener"></a>
                            </div>
<!-- End TrustBox widget -->
                            </div>
                        </div>
                            <div class="row mt-2 align-items-center">
                                <div class="col">
                                  
                                  
                                    <ul class="amenity">
                                        <li *ngIf="facilityD.is_mobile_pay_enabled =='1'"><img style="width: 50px;"[src]="payNow_btn" alt="image"></li>
                                        <li *ngIf="facilityD.is_parksafe =='1'"  popover="Vivamus sagittis lacus vel augue laoreet rutrum faucibus."   placement="top" container="body" ><img style="width: 50px;" [src]="parkSafesmall" alt="image"></li>

                                        <li *ngIf="facilityD.is_24hour_open =='1'"><img [src]="open_24hr_icon" alt="image"></li>
                                        <li *ngIf="facilityD.is_indoor_parking =='1'"><img [src]="indoor_icon" alt="image"></li>
                                        <li *ngIf="facilityD.is_outdoor_parking =='1'"><img [src]="outdoor_icon" alt="image"></li>
                                        <li *ngIf="facilityD.is_tesla_charging =='1'"><img [src]="tesla_charger_icon" alt="image"></li>
                                        <li *ngIf="facilityD.is_generic_ev_charging =='1'"><img [src]="ev_charge_icon" alt="image"></li>
                                        <li *ngIf="facilityD.is_oversize =='1'"><img [src]="oversized_icon" alt="image"></li>
                                        <li *ngIf="facilityD.is_motorcycle_parking =='1'"><img [src]="motorcycle_icon" alt="image"></li>
                                        <li *ngIf="facilityD.is_monthly =='1'"><img [src]="monthly_icon" alt="image"></li>
                                    </ul>

                                </div>

                               <div *ngIf="searchCriteria.type=='daily'" class="col-auto onfacilityload_{{facilityD.facility_id}}">
                                    <button *ngIf=" searchCriteria.type=='daily' && facilityD.price!='N/A'" id="sorting_{{facilityD.facility_id}}" class="btn btn-primary btn-fill " (click)="moveToReservation(facilityD.facility_id, facilityD.price, facilityD.monthly_rate_main,facilityD.warning_on_reservation,facilityD.warning_on_reservation_msg);sideBarClick()">
                              <span > BOOK NOW</span>
                              </button>
                                    <p id="threshold_{{facilityD.facility_id}}" class="coupon_threshold_price hidden">{{ facilityD.coupon_threshold_price }}</p>
                                    <p class="facPrice hidden_Price_{{facilityD.facility_id}} hidden">{{ facilityD.price }}</p>
                                    <span class="baserate" *ngIf=" searchCriteria.type=='daily' && facilityD.price=='N/A'"><h2 > NA</h2></span>
                                </div>
                                <div *ngIf="searchCriteria.type=='daily'" class="col-auto onfacilityloadshow_{{facilityD.facility_id}} hide">
                                    <button *ngIf="(facilityD.ticketech_id != '' || (facilityD?.parkonect_id !='' && facilityD?.parkonect_id !=null) || (facilityD?.valet_manager_id != '' && facilityD?.valet_manager_id != null) )" id="sorting_{{facilityD.facility_id}}" class="btn btn-primary btn-fill sorting_{{facilityD.facility_id}}" (click)="moveToReservation(facilityD.facility_id, facilityD.price, facilityD.monthly_rate_main,facilityD.warning_on_reservation,facilityD.warning_on_reservation_msg);sideBarClick()">
                         <span > BOOK NOW</span>
                         </button>

                                    <p class="facPrice hidden_Price_{{facilityD.facility_id}} hidden">{{ facilityD.price }}</p>
                                    <span class="hide baserate narecord_{{facilityD.facility_id}}"><h2> NA</h2></span>
                                </div>

                                <div *ngIf="searchCriteria.type=='monthly'" class="col-auto ">
                                    <button *ngIf="(facilityD.monthly_rate_mains >0 && facilityD.monthly_rate_mains < 2500000) && isPartners == false &&  searchCriteria.type=='monthly' && facilityD.monthly_rate_main!='N/A*'" id="sorting_{{facilityD.facility_id}}" class="btn btn-primary btn-fill " (click)="moveToReservation(facilityD.facility_id, facilityD.price, facilityD.monthly_rate_main,facilityD.warning_on_reservation,facilityD.warning_on_reservation_msg);sideBarClick()">
                                        <span> BOOK NOW </span>
                                        </button>
                                    <button style="cursor: default;" *ngIf="(facilityD.monthly_rate_main < 1 || facilityD.monthly_rate_main > 2400000) && isPartners == false &&  searchCriteria.type=='monthly' && facilityD.monthly_rate_main!='N/A*'" id="sorting_{{facilityD.facility_id}}" class="btn btn-primary btn-fill " >
                                          <span> N/A </span>
                                    </button>
                                    <p class="facPrice hidden">{{ facilityD.monthly_rate_main }}</p>
                                    <span class="baserate" *ngIf="isPartners == false &&  searchCriteria.type=='monthly' && facilityD.monthly_rate_main=='N/A*'"><h2 > NA</h2></span>
                                </div>
                            </div>
                    </li>
                </ng-container>
                <ng-container *ngIf="distancelatestsort &&  searchCriteria.type=='daily'">
                    <li *ngFor="let facilityD of facilitylistprice | sorting:'distancedaily'; let couponIndexSelectCoupon = index" class="facility dfdddfd" data-id="{{facilityD.facility.facility_id}}">
                        <div class="row">
                            <div class="col-sm-4 clipImage">
                                <img *ngIf="facilityD.facility.photo_url" [src]="facilityD.facility.photo_url" class="img-responsive rounded"  alt="image">
                            <img *ngIf="!facilityD.facility.photo_url" [src]="park_garage" class="img-responsive notImg rounded" alt="image">
                            </div>

                            <div class="col p-0">
                                <h4 class="text-uppercase mar-bottom-0 mar-top-0" parkTitle>
                                    {{facilityD.facility.entrance_location}}
                                </h4>
                                <p class="mb-0 ">{{facilityD.facility.facility_name_modified}}</p>
                                    <p>
                                        <img [src]="map_market_location" height="12"> &nbsp; {{facilityD.facility.distance | distanceconversion}}</p>
                            </div>
                                <div class="col-auto text-center" *ngIf="searchCriteria.type=='daily'" >
                                    <small>Rates from</small>
                                    <h3 class="font-weight-bold m-0" *ngIf=" searchCriteria.type=='daily' && !isPartners && facilityD.inventory.showprice!='N/A' && facilityD.inventory.is_gray==0 && (facilityD.ticketech_id != '' || (facilityD?.parkonect_id !='' && facilityD?.parkonect_id !=null) || (facilityD?.valet_manager_id != '' && facilityD?.valet_manager_id != null) )" 
                                        (click)="moveToReservation(facilityD.facility.facility_id, facilityD.facility.price, facilityD.facility.monthly_rate_main,facilityD.facility.warning_on_reservation,facilityD.facility.warning_on_reservation_msg);">
                                        ${{facilityD.inventory.showprice}}
                                    </h3>

                                    <h3 *ngIf="searchCriteria.type=='daily' && isPartners && facilityD.inventory.showprice!='N/A' && facilityD.inventory.is_gray==0 && (facilityD.ticketech_id != '' || (facilityD?.parkonect_id !='' && facilityD?.parkonect_id !=null) || (facilityD?.valet_manager_id != '' && facilityD?.valet_manager_id != null) )" id="sorting_{{facilityD.facility_id}}"
                                       >
                                       ${{facilityD.inventory.showprice}} 
                                    </h3>
                                    <h3 class="font-weight-bold m-0 mobile-disable-price"   *ngIf=" searchCriteria.type=='daily' && facilityD.inventory.showprice!='N/A' && facilityD.inventory.is_gray==1 && (facilityD.ticketech_id != '' || (facilityD?.parkonect_id !='' && facilityD?.parkonect_id !=null) || (facilityD?.valet_manager_id != '' && facilityD?.valet_manager_id != null) )" 
                                        (click)="moveToReservation(facilityD.facility.facility_id, facilityD.facility.price, facilityD.facility.monthly_rate_main,facilityD.facility.warning_on_reservation,facilityD.facility.warning_on_reservation_msg);">
                                ${{facilityD.inventory.showprice}} 
                                </h3>
                                <div   *ngIf="searchCriteria.type=='daily' && facilityD.inventory.showprice!='N/A'  && (facilityD.ticketech_id == '' && (facilityD?.parkonect_id == '' || facilityD?.parkonect_id ==null) && (facilityD?.valet_manager_id == '' || facilityD?.valet_manager_id == null) )" >
                              {{changeRateUpdatedforsort(facilityD.inventory.showprice)}}
                                    <p id="threshold_{{facilityD.facility.facility_id}}" class="coupon_threshold_price hidden">{{ facilityD.facility.coupon_threshold_price }}</p>
                                    <p class="facPrice hidden_Price_{{facilityD.facility.facility_id}} hidden">{{ facilityD.inventory.showprice }}</p>
                                    <h3 class="baserate" *ngIf="  searchCriteria.type=='daily' && !isPartners && facilityD.inventory.showprice=='N/A'">NA</h3>
                                    <h3 *ngIf="searchCriteria.type=='daily' && isPartners && facilityD.inventory.showprice=='N/A' && facilityD.inventory.is_gray==0 && (facilityD.ticketech_id != '' || (facilityD?.parkonect_id !='' && facilityD?.parkonect_id !=null) || (facilityD?.valet_manager_id != '' && facilityD?.valet_manager_id != null) )" id="sorting_{{facilityD.facility_id}}"
                                        class="partner-price font-weight-bold m-0 ">
                                        {{facilityD.inventory.showprice}} 
                                    </h3>
                                    </div>
                                </div>

                       
                        </div>
                        <div class="row">
                            <div class="col trustpilot-rate  "  >
                                <!-- TrustBox widget - Micro Combo -->
                            <div  class="trustpilot-widget ratingStar" id="trustboxD2{{couponIndexSelectCoupon}}" data-locale="en-US" data-template-id="5419b6ffb0d04a076446a9af" data-businessunit-id="5feb84d1e2aa300001625337" data-style-height="25px" data-style-width="420px" data-theme="light" [attr.data-location]="facilityD.garage_code">
                                <a href="https://www.trustpilot.com/review/iconparkingsystems.com" target="_blank" rel="noopener"></a>
                            </div>
<!-- End TrustBox widget -->
                            </div>
                        </div>
                       <div class="row mt-2 align-items-center">
                            <div class="col">

                           
                                <ul class="amenity">
                                    <li *ngIf="facilityD.is_mobile_pay_enabled =='1'"><img style="width: 50px;"[src]="payNow_btn" alt="image"></li>
                                    <li *ngIf="facilityD.is_parksafe =='1'"  popover="Vivamus sagittis lacus vel augue laoreet rutrum faucibus."   placement="top" container="body" ><img style="width: 50px;" [src]="parkSafesmall" alt="image"></li>

                                    <li *ngIf="facilityD.facility.is_24hour_open =='1'"><img [src]="open_24hr_icon" alt="image"></li>
                                    <li *ngIf="facilityD.facility.is_indoor_parking =='1'"><img [src]="indoor_icon" alt="image"></li>
                                    <li *ngIf="facilityD.facility.is_outdoor_parking =='1'"><img [src]="outdoor_icon" alt="image"></li>
                                    <li *ngIf="facilityD.facility.is_tesla_charging =='1'"><img [src]="tesla_charger_icon" alt="image"></li>
                                    <li *ngIf="facilityD.facility.is_generic_ev_charging =='1'"><img [src]="ev_charge_icon" alt="image"></li>
                                    <li *ngIf="facilityD.facility.is_oversize =='1'"><img [src]="oversized_icon" alt="image"></li>
                                    <li *ngIf="facilityD.facility.is_motorcycle_parking =='1'"><img [src]="motorcycle_icon" alt="image"></li>
                                    <li *ngIf="facilityD.facility.is_monthly =='1'"><img [src]="monthly_icon" alt="image"></li>
                                </ul>
                        </div>
                             <div *ngIf="searchCriteria.type=='daily'" class="col-auto">
                                <button *ngIf=" searchCriteria.type=='daily' && !isPartners && facilityD.inventory.showprice!='N/A' && facilityD.inventory.is_gray==0 && (facilityD.ticketech_id != '' || (facilityD?.parkonect_id !='' && facilityD?.parkonect_id !=null) || (facilityD?.valet_manager_id != '' && facilityD?.valet_manager_id != null) )" class="btn btn-primary btn-fill "
                                    (click)="moveToReservation(facilityD.facility.facility_id, facilityD.facility.price, facilityD.facility.monthly_rate_main,facilityD.facility.warning_on_reservation,facilityD.facility.warning_on_reservation_msg);sideBarClick()">
                           <span > Book NOW</span>
                           </button>

                                <h3 *ngIf="searchCriteria.type=='daily' && isPartners && facilityD.inventory.showprice!='N/A' && facilityD.inventory.is_gray==0 && (facilityD.ticketech_id != '' || (facilityD?.parkonect_id !='' && facilityD?.parkonect_id !=null) || (facilityD?.valet_manager_id != '' && facilityD?.valet_manager_id != null) )" id="sorting_{{facilityD.facility_id}}"
                                    class="partner-price font-weight-bold m-0">
                                    <span> ${{facilityD.inventory.showprice}} </span>
                                </h3>
                                <button *ngIf=" searchCriteria.type=='daily' && facilityD.inventory.showprice!='N/A' && facilityD.inventory.is_gray==1 && (facilityD.ticketech_id != '' || (facilityD?.parkonect_id !='' && facilityD?.parkonect_id !=null) || (facilityD?.valet_manager_id != '' && facilityD?.valet_manager_id != null) )" class="mobile-disable-price btn btn-primary btn-fill "
                                    (click)="moveToReservation(facilityD.facility.facility_id, facilityD.facility.price, facilityD.facility.monthly_rate_main,facilityD.facility.warning_on_reservation,facilityD.facility.warning_on_reservation_msg);sideBarClick()">
                             Book NOW
                             </button>
                                <button *ngIf="searchCriteria.type=='daily' && facilityD.inventory.showprice!='N/A'  && (facilityD.ticketech_id == '' && (facilityD?.parkonect_id == '' || facilityD?.parkonect_id ==null) && (facilityD?.valet_manager_id == '' || facilityD?.valet_manager_id == null) )" class="btn btn-primary btn-fill">
                             <span > {{changeRateUpdatedforsort(facilityD.inventory.showprice)}} </span>
                             </button>
                                <p id="threshold_{{facilityD.facility.facility_id}}" class="coupon_threshold_price hidden">{{ facilityD.facility.coupon_threshold_price }}</p>
                                <p class="facPrice hidden_Price_{{facilityD.facility.facility_id}} hidden">{{ facilityD.inventory.showprice }}</p>
                                <span class="baserate" *ngIf="  searchCriteria.type=='daily' && !isPartners && facilityD.inventory.showprice=='N/A'"><h2 > NA</h2></span>
                                <h3 *ngIf="searchCriteria.type=='daily' && isPartners && facilityD.inventory.showprice=='N/A' && facilityD.inventory.is_gray==0 && (facilityD.ticketech_id != '' || (facilityD?.parkonect_id !='' && facilityD?.parkonect_id !=null) || (facilityD?.valet_manager_id != '' && facilityD?.valet_manager_id != null) )" id="sorting_{{facilityD.facility_id}}"
                                    class="partner-price">
                                    <span> {{facilityD.inventory.showprice}} </span>
                                </h3>
                            </div>
                            <div *ngIf="searchCriteria.type=='monthly'" class="col-auto">
                                <button *ngIf="isPartners == false &&  searchCriteria.type=='monthly' && facilityD.facility.monthly_rate_main!='N/A*'" id="sorting_{{facilityD.facility.facility_id}}" class="btn btn-primary btn-fill " (click)="moveToReservation(facilityD.facility.facility_id, facilityD.facility.facilityD.facility.price, facilityD.facility.monthly_rate_main,facilityD.facility.warning_on_reservation,facilityD.facility.warning_on_reservation_msg);sideBarClick()">
                          <span> ${{facilityD.facility.monthly_rate_main}} </span>
                          </button>
                                <p class="facPrice hidden">{{ facilityD.facility.monthly_rate_main }}</p>
                                <span class="baserate" *ngIf="isPartners == false &&  searchCriteria.type=='monthly' && facilityD.facility.monthly_rate_main=='N/A*'"><h2 > NA</h2></span>
                            </div>
                            </div>

                          




                            <!-- <div  *ngIf="searchCriteria.type=='daily'"  class="col align-self-end text-right" >
                           <button    *ngIf=" searchCriteria.type=='daily' && facilityD.inventory.showprice!='N/A' && facilityD.inventory.is_gray==0"  class="btn btn-primary btn-fill " (click)="moveToReservation(facilityD.facility.facility_id, facilityD.facility.price, facilityD.facility.monthly_rate_main,facilityD.facility.warning_on_reservation,facilityD.facility.warning_on_reservation_msg);">
                          <span > ${{facilityD.inventory.showprice}} | Book Now</span>
                          </button> 
                          <button    *ngIf=" searchCriteria.type=='daily' && facilityD.inventory.showprice!='N/A' && facilityD.inventory.is_gray==1 && (facilityD.ticketech_id != '' || (facilityD?.parkonect_id !='' && facilityD?.parkonect_id !=null) )"  class="mobile-disable-price btn btn-primary btn-fill " (click)="moveToReservation(facilityD.facility.facility_id, facilityD.facility.price, facilityD.facility.monthly_rate_main,facilityD.facility.warning_on_reservation,facilityD.facility.warning_on_reservation_msg);">
                            <span > ${{facilityD.inventory.showprice}} | Book NOW</span>
                            </button> 
                          <button    *ngIf="searchCriteria.type=='daily' && facilityD.inventory.showprice!='N/A'  && (facilityD.ticketech_id == '' && (facilityD?.parkonect_id == '' || facilityD?.parkonect_id ==null) )"  class="btn btn-primary btn-fill " (click)="moveToReservation(facilityD.facility.facility_id, facilityD.facility.price, facilityD.facility.monthly_rate_main,facilityD.facility.warning_on_reservation,facilityD.facility.warning_on_reservation_msg);">
                            <span > ${{facilityD.inventory.showprice}} </span>
                            </button> 
                          <p id="threshold_{{facilityD.facility.facility_id}}" class="coupon_threshold_price hidden" >{{ facilityD.facility.coupon_threshold_price }}</p>
                         <p class="facPrice hidden_Price_{{facilityD.facility.facility_id}} hidden">{{ facilityD.inventory.showprice }}</p>
                          <span  class="baserate" *ngIf="  searchCriteria.type=='daily' && facilityD.inventory.showprice=='N/A'"><h2 class="text-primary"> NA</h2></span>      
                    </div> -->
                            <!-- <div  *ngIf="searchCriteria.type=='daily'" class="col align-self-end text-right onfacilityloadshow_{{facilityD.facility.facility_id}} hide" >
                      <button  id="sorting_{{facilityD.facility.facility_id}}" class="btn btn-primary btn-fill sorting_{{facilityD.facility.facility_id}}" (click)="moveToReservation(facilityD.facility.facility_id, facilityD.facility.price, facilityD.facility.monthly_rate_main,facilityD.facility.warning_on_reservation,facilityD.facility.warning_on_reservation_msg);">
                     <span > ${{facilityD.inventory.showprice}} | Book Now2</span>A2
                     </button> 
                     
                      <p class="facPrice hidden_Price_{{facilityD.facility.facility_id}} hidden">{{ facilityD.facility.price }}</p>
                     <span  class="hide baserate narecord_{{facilityD.facility.facility_id}}"><h2 class="text-primary"> NA</h2></span>   
                    </div> -->

                            <!-- <div *ngIf="searchCriteria.type=='monthly'" class="col align-self-end text-right">
                                <button *ngIf="isPartners == false &&  searchCriteria.type=='monthly' && facilityD.facility.monthly_rate_main!='N/A*'" id="sorting_{{facilityD.facility.facility_id}}" class="btn btn-primary btn-fill " (click)="moveToReservation(facilityD.facility.facility_id, facilityD.facility.facilityD.facility.price, facilityD.facility.monthly_rate_main,facilityD.facility.warning_on_reservation,facilityD.facility.warning_on_reservation_msg);">
                          <span> ${{facilityD.facility.monthly_rate_main}} | Buy Monthly </span>
                          </button>
                                <p class="facPrice hidden">{{ facilityD.facility.monthly_rate_main }}</p>
                                <span class="baserate" *ngIf="isPartners == false &&  searchCriteria.type=='monthly' && facilityD.facility.monthly_rate_main=='N/A*'"><h2 class="text-primary"> NA</h2></span>
                            </div>
                        </div>
                      -->
                        <div class="listBody panel-body hidden">
                            <div class="row">
                                <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8">
                                    <h4 class="text-uppercase mar-bottom-0 mar-top-0" parkTitle>
                                        {{facilityD.facility.entrance_location}}
                                    </h4>
                                    <p class="mar-bottom-xs ">{{facilityD.facility.facility_name_modified}}</p>
                                </div>
                                <div class="col-xs-4 col-sm-4 col-md-4 text-right col-lg-4">
                                    <a style="cursor:pointer" class="btn btn-xs btn-link" (click)="openDetails(facilityD.facility, searchCriteria.type)">Details &nbsp; &nbsp; <fa name="angle-right"></fa></a>
                                </div>
                            </div>
                            <div class="flex-row no-gutters bottom-xs">
                                <div class="col">
                                    <p class="map-mark">
                                        <img [src]="map_market_location" height="14"> &nbsp; {{facilityD.facility.distance | distanceconversion}}</p>

                                    <p class="facDistance hidden">{{ facilityD.facility.distance }}</p>
                                    <ul class="amenity">
                                        <li *ngIf="facilityD.facility.is_24hour_open =='1'"><img [src]="open_24hr_icon" alt="image"></li>
                                        <li *ngIf="facilityD.facility.is_indoor_parking =='1'"><img [src]="indoor_icon" alt="image"></li>
                                        <li *ngIf="facilityD.facility.is_outdoor_parking =='1'"><img [src]="outdoor_icon" alt="image"></li>
                                        <li *ngIf="facilityD.facility.is_tesla_charging =='1'"><img [src]="tesla_charger_icon" alt="image"></li>
                                        <li *ngIf="facilityD.facility.is_generic_ev_charging =='1'"><img [src]="ev_charge_icon" alt="image"></li>
                                        <li *ngIf="facilityD.facility.is_oversize =='1'"><img [src]="oversized_icon" alt="image"></li>
                                        <li *ngIf="facilityD.facility.is_motorcycle_parking =='1'"><img [src]="motorcycle_icon" alt="image"></li>
                                        <li *ngIf="facilityD.facility.is_monthly =='1'"><img [src]="monthly_icon" alt="image"></li>
                                    </ul>

                                </div>

                                <div *ngIf="searchCriteria.type=='daily'" class="col align-self-end text-right">
                                    <button *ngIf=" searchCriteria.type=='daily' && !isPartners && facilityD.inventory.showprice!='N/A' && facilityD.inventory.is_gray==0 && (facilityD.ticketech_id != '' || (facilityD?.parkonect_id !='' && facilityD?.parkonect_id !=null) || (facilityD?.valet_manager_id != '' && facilityD?.valet_manager_id != null) )" class="btn btn-primary btn-fill "
                                        (click)="moveToReservation(facilityD.facility.facility_id, facilityD.facility.price, facilityD.facility.monthly_rate_main,facilityD.facility.warning_on_reservation,facilityD.facility.warning_on_reservation_msg);">
                               <span > ${{facilityD.inventory.showprice}} | Book NOW</span>
                               </button>

                                    <h3 *ngIf="searchCriteria.type=='daily' && isPartners && facilityD.inventory.showprice!='N/A' && facilityD.inventory.is_gray==0 && (facilityD.ticketech_id != '' || (facilityD?.parkonect_id !='' && facilityD?.parkonect_id !=null) || (facilityD?.valet_manager_id != '' && facilityD?.valet_manager_id != null) )" id="sorting_{{facilityD.facility_id}}"
                                        class="partner-price">
                                        <span> ${{facilityD.inventory.showprice}} </span>
                                    </h3>
                                    <button *ngIf=" searchCriteria.type=='daily' && facilityD.inventory.showprice!='N/A' && facilityD.inventory.is_gray==1 && (facilityD.ticketech_id != '' || (facilityD?.parkonect_id !='' && facilityD?.parkonect_id !=null) || (facilityD?.valet_manager_id != '' && facilityD?.valet_manager_id != null) )" class="mobile-disable-price btn btn-primary btn-fill "
                                        (click)="moveToReservation(facilityD.facility.facility_id, facilityD.facility.price, facilityD.facility.monthly_rate_main,facilityD.facility.warning_on_reservation,facilityD.facility.warning_on_reservation_msg);">
                                 <span > ${{facilityD.inventory.showprice}} | Book NOW</span>
                                 </button>
                                    <button *ngIf="searchCriteria.type=='daily' && facilityD.inventory.showprice!='N/A'  && (facilityD.ticketech_id == '' && (facilityD?.parkonect_id == '' || facilityD?.parkonect_id ==null) && (facilityD?.valet_manager_id == '' || facilityD?.valet_manager_id == null) )" class="btn btn-primary btn-fill">
                                 <span > {{changeRateUpdatedforsort(facilityD.inventory.showprice)}} </span>
                                 </button>
                                    <p id="threshold_{{facilityD.facility.facility_id}}" class="coupon_threshold_price hidden">{{ facilityD.facility.coupon_threshold_price }}</p>
                                    <p class="facPrice hidden_Price_{{facilityD.facility.facility_id}} hidden">{{ facilityD.inventory.showprice }}</p>
                                    <span class="baserate" *ngIf="  searchCriteria.type=='daily' && !isPartners && facilityD.inventory.showprice=='N/A'"><h2 class="text-primary"> N/A</h2></span>
                                    <h3 *ngIf="searchCriteria.type=='daily' && isPartners && facilityD.inventory.showprice=='N/A' && facilityD.inventory.is_gray==0 && (facilityD.ticketech_id != '' || (facilityD?.parkonect_id !='' && facilityD?.parkonect_id !=null) || (facilityD?.valet_manager_id != '' && facilityD?.valet_manager_id != null) )" id="sorting_{{facilityD.facility_id}}"
                                        class="partner-price">
                                        <span> {{facilityD.inventory.showprice}} </span>
                                    </h3>
                                </div>




                                <!-- <div  *ngIf="searchCriteria.type=='daily'"  class="col align-self-end text-right" >
                               <button    *ngIf=" searchCriteria.type=='daily' && facilityD.inventory.showprice!='N/A' && facilityD.inventory.is_gray==0"  class="btn btn-primary btn-fill " (click)="moveToReservation(facilityD.facility.facility_id, facilityD.facility.price, facilityD.facility.monthly_rate_main,facilityD.facility.warning_on_reservation,facilityD.facility.warning_on_reservation_msg);">
                              <span > ${{facilityD.inventory.showprice}} | Book Now</span>
                              </button> 
                              <button    *ngIf=" searchCriteria.type=='daily' && facilityD.inventory.showprice!='N/A' && facilityD.inventory.is_gray==1 && (facilityD.ticketech_id != '' || (facilityD?.parkonect_id !='' && facilityD?.parkonect_id !=null) )"  class="mobile-disable-price btn btn-primary btn-fill " (click)="moveToReservation(facilityD.facility.facility_id, facilityD.facility.price, facilityD.facility.monthly_rate_main,facilityD.facility.warning_on_reservation,facilityD.facility.warning_on_reservation_msg);">
                                <span > ${{facilityD.inventory.showprice}} | Book NOW</span>
                                </button> 
                              <button    *ngIf="searchCriteria.type=='daily' && facilityD.inventory.showprice!='N/A'  && (facilityD.ticketech_id == '' && (facilityD?.parkonect_id == '' || facilityD?.parkonect_id ==null) )"  class="btn btn-primary btn-fill " (click)="moveToReservation(facilityD.facility.facility_id, facilityD.facility.price, facilityD.facility.monthly_rate_main,facilityD.facility.warning_on_reservation,facilityD.facility.warning_on_reservation_msg);">
                                <span > ${{facilityD.inventory.showprice}} </span>
                                </button> 
                              <p id="threshold_{{facilityD.facility.facility_id}}" class="coupon_threshold_price hidden" >{{ facilityD.facility.coupon_threshold_price }}</p>
                             <p class="facPrice hidden_Price_{{facilityD.facility.facility_id}} hidden">{{ facilityD.inventory.showprice }}</p>
                              <span  class="baserate" *ngIf="  searchCriteria.type=='daily' && facilityD.inventory.showprice=='N/A'"><h2 class="text-primary"> NA</h2></span>      
                        </div> -->
                                <!-- <div  *ngIf="searchCriteria.type=='daily'" class="col align-self-end text-right onfacilityloadshow_{{facilityD.facility.facility_id}} hide" >
                          <button  id="sorting_{{facilityD.facility.facility_id}}" class="btn btn-primary btn-fill sorting_{{facilityD.facility.facility_id}}" (click)="moveToReservation(facilityD.facility.facility_id, facilityD.facility.price, facilityD.facility.monthly_rate_main,facilityD.facility.warning_on_reservation,facilityD.facility.warning_on_reservation_msg);">
                         <span > ${{facilityD.inventory.showprice}} | Book Now2</span>A2
                         </button> 
                         
                          <p class="facPrice hidden_Price_{{facilityD.facility.facility_id}} hidden">{{ facilityD.facility.price }}</p>
                         <span  class="hide baserate narecord_{{facilityD.facility.facility_id}}"><h2 class="text-primary"> NA</h2></span>   
                        </div> -->

                                <div *ngIf="searchCriteria.type=='monthly'" class="col align-self-end text-right">
                                    <button *ngIf="isPartners == false &&  searchCriteria.type=='monthly' && facilityD.facility.monthly_rate_main!='N/A*'" id="sorting_{{facilityD.facility.facility_id}}" class="btn btn-primary btn-fill " (click)="moveToReservation(facilityD.facility.facility_id, facilityD.facility.facilityD.facility.price, facilityD.facility.monthly_rate_main,facilityD.facility.warning_on_reservation,facilityD.facility.warning_on_reservation_msg);">
                              <span> ${{facilityD.facility.monthly_rate_main}} | BOOK NOW </span>
                              </button>
                                    <p class="facPrice hidden">{{ facilityD.facility.monthly_rate_main }}</p>
                                    <span class="baserate" *ngIf="isPartners == false &&  searchCriteria.type=='monthly' && facilityD.facility.monthly_rate_main=='N/A*'"><h2 class="text-primary"> N/A</h2></span>
                                </div>
                            </div>
                        </div>
                    </li>
                </ng-container>
                <ng-container *ngIf="pricesorting &&  searchCriteria.type=='daily'">
                    <li *ngFor="let facilityD of facilitylistprice | sorting:'daily' let couponIndexSelectCoupon = index;" class="facility gggggg" data-id="{{facilityD.facility.facility_id}}">
                       <div class="row">
                           <div class="col-4 clipImage">
                                <img *ngIf="facilityD.facility.photo_url" [src]="facilityD.facility.photo_url" class="img-responsive rounded"  alt="image">
                                <img *ngIf="!facilityD.facility.photo_url" [src]="park_garage" class="img-responsive notImg  rounded" alt="image">
                           </div>
                           <div class="col p-0">
                                    <h4 class="text-uppercase mar-bottom-0 mar-top-0" parkTitle>
                                        {{facilityD.facility.entrance_location}}
                                    </h4>
                                    <p class="mar-bottom-xs ">{{ facilityD.facility.facility_name_modified }}</p>
                                    <p>
                                        <img [src]="map_market_location" height="12">&nbsp;&nbsp;{{facilityD.facility.distance | distanceconversion}}</p>

                           </div>
                           <div class="col-auto text-right" *ngIf="searchCriteria.type=='daily'">
                          
                                <small> Rates from</small>
                                <h3 class="font-wiight-bold m-0" *ngIf=" searchCriteria.type=='daily' && !isPartners && facilityD.inventory.showprice!='N/A' && facilityD.inventory.is_gray==0 && (facilityD.ticketech_id != '' || (facilityD?.parkonect_id !='' && facilityD?.parkonect_id !=null) || (facilityD?.valet_manager_id != '' && facilityD?.valet_manager_id != null) )" 
                                    (click)="moveToReservation(facilityD.facility.facility_id, facilityD.facility.price, facilityD.facility.monthly_rate_main,facilityD.facility.warning_on_reservation,facilityD.facility.warning_on_reservation_msg);">
                        ${{facilityD.inventory.showprice}}
                           </h3>
                                <h3 *ngIf="searchCriteria.type=='daily' && isPartners && facilityD.inventory.showprice!='N/A' && facilityD.inventory.is_gray==0 && (facilityD.ticketech_id != '' || (facilityD?.parkonect_id !='' && facilityD?.parkonect_id !=null) || (facilityD?.valet_manager_id != '' && facilityD?.valet_manager_id != null) )" id="sorting_{{facilityD.facility_id}}"
                                    class="partner-price font-wiight-bold m-0">
                                    ${{facilityD.inventory.showprice}}
                                </h3>
                                <h3  *ngIf=" searchCriteria.type=='daily' && facilityD.inventory.showprice!='N/A' && facilityD.inventory.is_gray==1 && (facilityD.ticketech_id != '' || (facilityD?.parkonect_id !='' && facilityD?.parkonect_id !=null) || (facilityD?.valet_manager_id != '' && facilityD?.valet_manager_id != null) )" class="mobile-disable-price font-wiight-bold m-0 "
                                    (click)="moveToReservation(facilityD.facility.facility_id, facilityD.facility.price, facilityD.facility.monthly_rate_main,facilityD.facility.warning_on_reservation,facilityD.facility.warning_on_reservation_msg);">
                         ${{facilityD.inventory.showprice}}
                            </h3>
                                <button *ngIf="searchCriteria.type=='daily' && facilityD.inventory.showprice!='N/A'  && (facilityD.ticketech_id == '' && (facilityD?.parkonect_id == '' || facilityD?.parkonect_id ==null) && (facilityD?.valet_manager_id == '' || facilityD?.valet_manager_id == null) )" class="btn btn-primary btn-fill">
                          <span >  {{changeRateUpdatedforsort(facilityD.inventory.showprice)}}</span>
                          </button>
                                <p id="threshold_{{facilityD.facility.facility_id}}" class="coupon_threshold_price hidden">{{ facilityD.facility.coupon_threshold_price }}</p>
                                <p class="facPrice hidden_Price_{{facilityD.facility.facility_id}} hidden">{{ facilityD.inventory.showprice }}</p>
                                <span class="baserate" *ngIf="searchCriteria.type=='daily' && !isPartners && facilityD.inventory.showprice=='N/A'"><h2 class="text-primary"> NA</h2></span>
                                <h3 *ngIf="searchCriteria.type=='daily' && isPartners && facilityD.inventory.showprice=='N/A' && facilityD.inventory.is_gray==0 && (facilityD.ticketech_id != '' || (facilityD?.parkonect_id !='' && facilityD?.parkonect_id !=null) || (facilityD?.valet_manager_id != '' && facilityD?.valet_manager_id != null) )" id="sorting_{{facilityD.facility_id}}"
                                    class="partner-price">
                                    <span> {{facilityD.inventory.showprice}} </span>
                                </h3>
                            </div>
                            <div *ngIf="searchCriteria.type=='monthly'" class=" col text-right">
                                <h3 class="font-weight-bold m-0" *ngIf="isPartners == false &&  searchCriteria.type=='monthly' && facilityD.facility.monthly_rate_main!='N/A*'" id="sorting_{{facilityD.facility.facility_id}}">
                                    ${{facilityD.facility.monthly_rate_main}} 
                                </h3>
                                <p class="facPrice hidden">{{ facilityD.facility.monthly_rate_main }}</p>
                                <span class="baserate" *ngIf="isPartners == false &&  searchCriteria.type=='monthly' && facilityD.facility.monthly_rate_main=='N/A*'"><h2 > NA</h2></span>
                            </div>
                    
                       </div>
                       <div class="row">
                        <div class="col trustpilot-rate  "  >
                            <!-- TrustBox widget - Micro Combo -->
                        <div  class="trustpilot-widget ratingStar" id="trustboxD3{{couponIndexSelectCoupon}}" data-locale="en-US" data-template-id="5419b6ffb0d04a076446a9af" data-businessunit-id="5feb84d1e2aa300001625337" data-style-height="25px" data-style-width="420px" data-theme="light" [attr.data-location]="facilityD.garage_code">
                            <a href="https://www.trustpilot.com/review/iconparkingsystems.com" target="_blank" rel="noopener"></a>
                        </div>
<!-- End TrustBox widget -->
                        </div>
                    </div>
                        <div class="listBody panel-body hidden">
                            <div class="row">
                                <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8">
                                    <h4 class="text-uppercase mar-bottom-0 mar-top-0" parkTitle>
                                        {{facilityD.facility.entrance_location}}
                                    </h4>
                                    <p class="mar-bottom-xs ">{{ facilityD.facility.facility_name_modified }}</p>
                                </div>
                                <div class="col-xs-4 col-sm-4 col-md-4 text-right col-lg-4">
                                    <a style="cursor:pointer" class="btn btn-xs btn-link" (click)="openDetails(facilityD.facility, searchCriteria.type)">Details &nbsp; &nbsp; <fa name="angle-right"></fa></a>
                                </div>
                            </div>
                            <div class="flex-row no-gutters bottom-xs">
                                <div class="col">
                                    <p class="map-mark">
                                        <img [src]="map_market_location" height="14"> &nbsp; {{facilityD.facility.distance | distanceconversion}}</p>

                                    <p class="facDistance hidden">{{ facilityD.facility.distance }}</p>
                                    <ul class="amenity">
                                        <li *ngIf="facilityD.facility.is_24hour_open =='1'"><img [src]="open_24hr_icon" alt="image"></li>
                                        <li *ngIf="facilityD.facility.is_indoor_parking =='1'"><img [src]="indoor_icon" alt="image"></li>
                                        <li *ngIf="facilityD.facility.is_outdoor_parking =='1'"><img [src]="outdoor_icon" alt="image"></li>
                                        <li *ngIf="facilityD.facility.is_tesla_charging =='1'"><img [src]="tesla_charger_icon" alt="image"></li>
                                        <li *ngIf="facilityD.facility.is_generic_ev_charging =='1'"><img [src]="ev_charge_icon" alt="image"></li>
                                        <li *ngIf="facilityD.facility.is_oversize =='1'"><img [src]="oversized_icon" alt="image"></li>
                                        <li *ngIf="facilityD.facility.is_motorcycle_parking =='1'"><img [src]="motorcycle_icon" alt="image"></li>
                                        <li *ngIf="facilityD.facility.is_monthly =='1'"><img [src]="monthly_icon" alt="image"></li>
                                    </ul>

                                </div>

                                <div *ngIf="searchCriteria.type=='daily'" class="col align-self-end text-right">
                                    <button *ngIf=" searchCriteria.type=='daily' && !isPartners && facilityD.inventory.showprice!='N/A' && facilityD.inventory.is_gray==0 && (facilityD.ticketech_id != '' || (facilityD?.parkonect_id !='' && facilityD?.parkonect_id !=null) || (facilityD?.valet_manager_id != '' && facilityD?.valet_manager_id != null) )" class="btn btn-primary btn-fill "
                                        (click)="moveToReservation(facilityD.facility.facility_id, facilityD.facility.price, facilityD.facility.monthly_rate_main,facilityD.facility.warning_on_reservation,facilityD.facility.warning_on_reservation_msg);">
                            <span > ${{facilityD.inventory.showprice}} | Book NOW</span>
                            </button>
                                    <h3 *ngIf="searchCriteria.type=='daily' && isPartners && facilityD.inventory.showprice!='N/A' && facilityD.inventory.is_gray==0 && (facilityD.ticketech_id != '' || (facilityD?.parkonect_id !='' && facilityD?.parkonect_id !=null) || (facilityD?.valet_manager_id != '' && facilityD?.valet_manager_id != null) )" id="sorting_{{facilityD.facility_id}}"
                                        class="partner-price">
                                        <span> ${{facilityD.inventory.showprice}} </span>
                                    </h3>
                                    <button *ngIf=" searchCriteria.type=='daily' && facilityD.inventory.showprice!='N/A' && facilityD.inventory.is_gray==1 && (facilityD.ticketech_id != '' || (facilityD?.parkonect_id !='' && facilityD?.parkonect_id !=null) || (facilityD?.valet_manager_id != '' && facilityD?.valet_manager_id != null) )" class="mobile-disable-price btn btn-primary btn-fill "
                                        (click)="moveToReservation(facilityD.facility.facility_id, facilityD.facility.price, facilityD.facility.monthly_rate_main,facilityD.facility.warning_on_reservation,facilityD.facility.warning_on_reservation_msg);">
                              <span > ${{facilityD.inventory.showprice}} | Book NOW</span>
                              </button>
                                    <button *ngIf="searchCriteria.type=='daily' && facilityD.inventory.showprice!='N/A'  && (facilityD.ticketech_id == '' && (facilityD?.parkonect_id == '' || facilityD?.parkonect_id ==null) && (facilityD?.valet_manager_id == '' || facilityD?.valet_manager_id == null) )" class="btn btn-primary btn-fill">
                              <span >  {{changeRateUpdatedforsort(facilityD.inventory.showprice)}}</span>
                              </button>
                                    <p id="threshold_{{facilityD.facility.facility_id}}" class="coupon_threshold_price hidden">{{ facilityD.facility.coupon_threshold_price }}</p>
                                    <p class="facPrice hidden_Price_{{facilityD.facility.facility_id}} hidden">{{ facilityD.inventory.showprice }}</p>
                                    <span class="baserate" *ngIf="searchCriteria.type=='daily' && !isPartners && facilityD.inventory.showprice=='N/A'"><h2 class="text-primary"> N/A</h2></span>
                                    <h3 *ngIf="searchCriteria.type=='daily' && isPartners && facilityD.inventory.showprice=='N/A' && facilityD.inventory.is_gray==0 && (facilityD.ticketech_id != '' || (facilityD?.parkonect_id !='' && facilityD?.parkonect_id !=null) || (facilityD?.valet_manager_id != '' && facilityD?.valet_manager_id != null) )" id="sorting_{{facilityD.facility_id}}"
                                        class="partner-price">
                                        <span> {{facilityD.inventory.showprice}} </span>
                                    </h3>
                                </div>

                                <!-- <div  *ngIf="searchCriteria.type=='daily'"  class="col align-self-end text-right" >
                             <button    *ngIf="searchCriteria.type=='daily' && facilityD.inventory.showprice!='N/A' && facilityD.inventory.is_gray==0"  class="btn btn-primary btn-fill " (click)="moveToReservation(facilityD.facility.facility_id, facilityD.facility.price, facilityD.facility.monthly_rate_main,facilityD.facility.warning_on_reservation,facilityD.facility.warning_on_reservation_msg);">
                            <span > ${{facilityD.inventory.showprice}} | Book Now</span>
                            </button> 
                            <button    *ngIf=" searchCriteria.type=='daily' && facilityD.inventory.showprice!='N/A' && facilityD.inventory.is_gray==1 && (facilityD.ticketech_id != '' || (facilityD?.parkonect_id !='' && facilityD?.parkonect_id !=null) )"  class="mobile-disable-price btn btn-primary btn-fill " (click)="moveToReservation(facilityD.facility.facility_id, facilityD.facility.price, facilityD.facility.monthly_rate_main,facilityD.facility.warning_on_reservation,facilityD.facility.warning_on_reservation_msg);">
                              <span > ${{facilityD.inventory.showprice}} | Book NOW</span>
                              </button> 
                            <button    *ngIf="searchCriteria.type=='daily' && facilityD.inventory.showprice!='N/A'  && (facilityD.ticketech_id == '' && (facilityD?.parkonect_id == '' || facilityD?.parkonect_id ==null) )"  class="btn btn-primary btn-fill " (click)="moveToReservation(facilityD.facility.facility_id, facilityD.facility.price, facilityD.facility.monthly_rate_main,facilityD.facility.warning_on_reservation,facilityD.facility.warning_on_reservation_msg);">
                              <span > ${{facilityD.inventory.showprice}} </span>
                              </button>
                            <p id="threshold_{{facilityD.facility.facility_id}}" class="coupon_threshold_price hidden" >{{ facilityD.facility.coupon_threshold_price }}</p>
                           <p class="facPrice hidden_Price_{{facilityD.facility.facility_id}} hidden">{{ facilityD.inventory.showprice }}</p>
                            <span  class="baserate" *ngIf="searchCriteria.type=='daily' && facilityD.inventory.showprice=='N/A'"><h2 class="text-primary"> NA</h2></span>      
                      </div> -->
                                <!-- <div  *ngIf="searchCriteria.type=='daily'" class="col align-self-end text-right onfacilityloadshow_{{facilityD.facility.facility_id}} hide" >
                        <button  id="sorting_{{facilityD.facility.facility_id}}" class="btn btn-primary btn-fill sorting_{{facilityD.facility.facility_id}}" (click)="moveToReservation(facilityD.facility.facility_id, facilityD.facility.price, facilityD.facility.monthly_rate_main,facilityD.facility.warning_on_reservation,facilityD.facility.warning_on_reservation_msg);">
                       <span > ${{facilityD.inventory.showprice}} | Book Now2</span>A2
                       </button> 
                       
                        <p class="facPrice hidden_Price_{{facilityD.facility.facility_id}} hidden">{{ facilityD.facility.price }}</p>
                       <span  class="hide baserate narecord_{{facilityD.facility.facility_id}}"><h2 class="text-primary"> NA</h2></span>   
                      </div> -->

                                <div *ngIf="searchCriteria.type=='monthly'" class="col align-self-end text-right">
                                    <button *ngIf="isPartners == false &&  searchCriteria.type=='monthly' && facilityD.facility.monthly_rate_main!='N/A*'" id="sorting_{{facilityD.facility.facility_id}}" class="btn btn-primary btn-fill " (click)="moveToReservation(facilityD.facility.facility_id, facilityD.facility.facilityD.facility.price, facilityD.facility.monthly_rate_main,facilityD.facility.warning_on_reservation,facilityD.facility.warning_on_reservation_msg);">
                            <span> ${{facilityD.facility.monthly_rate_main}} | BOOK NOW </span>
                            </button>
                                    <p class="facPrice hidden">{{ facilityD.facility.monthly_rate_main }}</p>
                                    <span class="baserate" *ngIf="isPartners == false &&  searchCriteria.type=='monthly' && facilityD.facility.monthly_rate_main=='N/A*'"><h2 class="text-primary"> N/A</h2></span>
                                </div>
                            </div>
                        </div>
                    </li>
                </ng-container>
            </ul>

        </section>
        <div class="accordianBlock hidden-xs">
        <accordion [closeOthers]="oneAtATime" >
            <accordion-group heading="{{CMSsidebarContent?.your_convenient_nyc_title}}">
                {{CMSsidebarContent?.your_convenient_nyc_content}}
                <!-- Whether you’re looking for Bronx monthly parking or monthly parking in Manhattan, Icon Parking has you covered. Our parking facilities are located throughout New York City, providing New York drivers with the ability to park in any one of our safe and secure parking areas. In collaboration with Quik Park, Icon Parking offers over 250 parking facilities in the city so you can feel confident you can find a parking space when you need it. -->
            </accordion-group>
            <accordion-group heading="{{CMSsidebarContent?.why_choose_icon_parking_title}}">
                {{CMSsidebarContent?.why_choose_icon_parking_content}}
              <!-- <p>Content 1</p> -->
            </accordion-group>
          
          </accordion>


          <!--  boxx no 2 -->
          
        </div>
    </div>

    <!-- map toggle buttons -->

   




  


</aside>
<div class="mapVewToggle neighToggleButtons" *ngIf="isNeighbourHood">


    <ul>

        <!-- <li *showItBootstrap="['sm','xs']">
            <a class="btn" (click)="openModal(searchfilter)">
                <img [src]="amenityfilter" alt="image">
            </a>
        </li> -->

        <!-- <li>
      <a (click)="mapView(mapType)" class=" btn mapBtnToggle">
        <img [src]="satView">
      </a>
    </li> -->

        <li>
            <a class="btn" (click)="tabList('plist','pview')">
                <img [src]="list_icon" alt="image">
            </a>
        </li>

    </ul>
</div>

<!--  complete page list view -->

<!-- <div class="campians_list" *ngIf="showMonthlyCampaign && existData" id="collapseBasic" [collapse]="isCollapsed"> -->
    <div class="campians_list" *ngIf="isSearchPage && searchCriteria.type == 'monthly' && showCampaignDeals && existCampaignData" id="collapseBasic">
     <header>
  
      <div class="row">
        <div class="col-xs-10">
              <table >
                  <tr>
                      <td><label>SORT BY</label></td>
                      <td><a class="btn btn-link btn-sm text-primary campaignSorting campaignSortingDistance active" (click)="sort('distance')">DISTANCE </a></td>
                      <td><a class="btn btn-link btn-sm text-dark campaignSortingPrice campaignSorting"(click)="sort('price')">PRICE </a></td>
                  </tr>
              </table>
              
          </div>
          <div class="col-xs-2 text-right"><i
            [attr.aria-expanded]="!isCollapsed" aria-controls="collapseBasic" class="fa fa-times btn btn-link pad-0 btn-sm"></i></div>
      </div>
    </header>

    <div *ngIf="searchCriteria.type == 'monthly'" class="booknow-mont-serch text-right"><a href="javascript:;"><img src="assets/img/search-monthlybook.png"></a></div>
    <!-- <div *ngIf="searchCriteria.type == 'monthly'" class="booknow-mont-serch-mar campList"></div> -->

          <div class="campaign-description text-center mar-bottom-sm">
            <div [innerHtml]="campaignDescription"></div>
        </div>
        <div class="clearfix"></div>
        <div class="mar-top-sm mar-bottom-sm" *ngIf="!LoadCampaignFacilities">
          <p class="Loader text-center " style="font-size: 14px;">
              <fa name="circle-o-notch" animation="spin"></fa>
              Please wait while we load facilities ...
          </p>
      </div>
          <div class="listCampaignFacility " *ngIf="LoadCampaignFacilities" >
          <div class="row clearfix">
            <div *ngFor="let facilities of searchResultCampaign  | sorting:sortingType" class="col-md-12 col-lg-12 col-sm-12">
  
                <div class="listBody panel ">
                   <div class="panel-body">
  
                        <h4 class="text-uppercase mar-bottom-0 mar-top-0" parkTitle>
                            {{facilities?.entrance_location}}
                        </h4>
                        <p class="mar-bottom-sm ">{{facilities?.facility_name_modified}}</p>
  
                        <div class="flex-row no-gutters bottom-xs" style="min-height: 40px;">
                            <div class="col-auto" [ngStyle]={width:checkData(facilities?.monthly_campaign_availability?.total_availability,facilities?.monthly_campaign_availability?.used_availability)}>
                                <ul class="nav nav-pills">
                                    <li *ngIf="facilities.is_24hour_open =='1'"><img [src]="open_24hr_icon" width="20" alt="image"></li>
                                    <li *ngIf="facilities.is_indoor_parking =='1'"><img [src]="indoor_icon" width="20" alt="image"></li>
                                    <li *ngIf="facilities.is_outdoor_parking =='1'"><img [src]="outdoor_icon" width="20" alt="image"></li>
                                    <li *ngIf="facilities.is_tesla_charging =='1'"><img [src]="tesla_charger_icon" width="20" alt="image"></li>
                                    <li *ngIf="facilities.is_generic_ev_charging =='1'"><img [src]="ev_charge_icon" width="20" alt="image"></li>
                                    <li *ngIf="facilities.is_oversize =='1'"><img [src]="oversized_icon" width="20" alt="image"></li>
                                    <li *ngIf="facilities.is_motorcycle_parking =='1'"><img [src]="motorcycle_icon" width="20" alt="image"></li>
                                    <li *ngIf="facilities.is_monthly =='1'"><img [src]="monthly_icon" width="20" alt="image"></li>
                                </ul>
  
                            </div>
  
                            <div class="col-7 align-self-end text-right">
                                <span class="text-danger" style="top: -29px;position: absolute;width: 100%;left: 0;" *ngIf="checkAvaillity_campaign_remaining(facilities?.monthly_campaign_availability?.total_availability, facilities?.monthly_campaign_availability?.used_availability) <= convertIntoInteger(thresold) && checkAvaillity_campaign_remaining(facilities?.monthly_campaign_availability?.total_availability, facilities?.monthly_campaign_availability?.used_availability) >0">{{checkAvaillity_campaign_remaining_text(facilities?.monthly_campaign_availability?.total_availability, facilities?.monthly_campaign_availability?.used_availability,spaceLeftMessage)}}</span>
                                
                        <button *ngIf="facilities.monthly_rate_main !='N/A*' && (facilities.monthly_rate_main>0 && facilities.monthly_rate_main < 2500000) && checkAvaillity_campaign_remaining(facilities?.monthly_campaign_availability?.total_availability,facilities?.monthly_campaign_availability?.used_availability) > 0" class="btn btn-primary btn-fill" style="padding: 8px 10px;" style="padding: 8px 10px;"
                                    (click)="buyMonthlyCampaign(facilities.monthlyNetPriceCampaign, facilities.facility_id,facilities.monthly_campaign_price, facilities.monthlyTaxPriceCampaign, facilities.monthly_campaign)">
                          <span> ${{facilities?.monthly_rate_main}} | BOOK NOW </span>
                        </button>

                        <button style="cursor: default;" *ngIf="( facilities.monthly_rate_main =='N/A*' || facilities.monthly_rate_main < 1 || facilities.monthly_rate_main > 2400000) && checkAvaillity_campaign_remaining(facilities?.monthly_campaign_availability?.total_availability,facilities?.monthly_campaign_availability?.used_availability) > 0" class="btn btn-primary btn-fill" style="padding: 8px 10px;" style="padding: 8px 10px;">
                          <span> N/A </span>
                        </button>
                                <!-- <button *ngIf="checkAvaillity_campaign_remaining(facilities?.monthly_campaign_availability?.total_availability,facilities?.monthly_campaign_availability?.used_availability) > 0" class="btn btn-primary btn-fill" style="padding: 8px 10px;" style="padding: 8px 10px;"
                                    (click)="buyMonthlyCampaign(facilities.monthlyNetPriceCampaign, facilities.facility_id,facilities.monthly_campaign_price, facilities.monthlyTaxPriceCampaign, facilities.monthly_campaign)">
                          <span> ${{facilities?.monthly_rate_main}} | Book Now </span>
                        </button> -->
                
                                <button style="padding: 8px 8px;" class="btn btn-secondary btn-fill" disabled *ngIf="checkAvaillity_campaign_remaining(facilities?.monthly_campaign_availability?.total_availability, facilities?.monthly_campaign_availability?.used_availability) <= 0">
                          <span>Call for Availability </span>
                        </button>
  
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div style="padding-bottom: 60px;" class="campaign-footer mar-bottom-sm" [innerHtml]="campaignFooter"></div>
      </div>
  
    </div>


<!-- Directions Model Starts From Here -->
<div bsModal #getdirections="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">

    <div class="modal-dialog modal-sm">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" aria-label="Close" (click)="getdirections.hide()">
          <fa name="times"></fa>
        </button>
                <h3 class="mar-top-0  text-primary">Get Directions</h3>
            </div>

            <div class="modal-body">
                <div class="form-group">
                    <!-- <a *ngIf="allParkings.geolocation" class="btn btn-primary btn-block theme-btn" target="_blank" id="navigatetogoogle"
            href="https://maps.google.com?q={{allParkings.geolocation.latitude}},{{allParkings.geolocation.longitude}}">Navigate
            with Google Maps</a> -->
                    <a *ngIf="allParkings.geolocation" class="btn btn-primary btn-block theme-btn" target="_blank" id="navigatetogoogle" href="https://maps.google.com/maps?daddr={{allParkings.geolocation.latitude}},{{allParkings.geolocation.longitude}}&travelmode=driving&dir_action=navigate">Navigate
            with Google Maps</a>
                </div>
                <div class="floating-label">
                    <label class="control-label text-uppercase" for="sendapp">Send to Phone</label>
                    <div class="input-group">
                        <input type="text" class="form-control" id="sendapp" placeholder="Enter phone number">
                        <span class="input-group-btn pad-left-xs">
              <button type="submit" class="btn theme-btn btn-primary" (click)="sendApp()">Send</button>
            </span>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
<!-- Direction Model Ends Here -->

<!-- Coupon Model Starts From Here -->
<!-- <div bsModal #lgModal="bs-modal" id="coupon-modal" class="modal fade"tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h4 id="couponheading" class="coupons-parent-panel modal-title pull-left">Coupons Available for this Facility</h4>
                <button type="button"class="close pull-right" (click)="lgModal.hide()" aria-label="Close">
              <span aria-hidden="true"><i class="fa fa-times"></i></span>
                </button>
            </div>
            <div class="modal-body coupons-parent-panel" id="couponslisting">
                <p class="mar-bottm-sm">Click on a preview below to view full coupon details and print a coupon This page is NOT a coupon.</p>
                <div class="row">
                            <div (click)="printEmailCoupon(singleCoupon.id)" style="cursor:pointer" class="col-md-4 col-sm-4 col-lg-4 col-xs-12" *ngFor="let singleCoupon of couponsData; let i = index"
                             id="{{singleCoupon.id}}">
                        <div class="panel panel-default coupons">
                            <div class="panel-heading text-center">
                                <h3 class="panel-title">{{singleCoupon.description}}</h3>
                            </div>
                            <input type="hidden"value="{{singleCoupon.coupon_code}}"class="couponcode"/>
                            <div class="panel-body">
                                <table>
                                    <tr>
                                        <td align="center "><span class="couponPrice">${{singleCoupon.price | roundprice}}</span></td>
                                        <td align="center">
                                            <span class="couponPriceAddon">00</span><br>
                                            <span class="couponPriceTax">Tax Included</span>
                                        </td>
                                    </tr>
                                </table>
                                <div class="clearfix text-center pad-top-xs">
                                    <p>{{singleCoupon.days_open_description}}/{{singleCoupon.open_time_description}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
           </div>
            <div class="modal-body coupons-other-areas"id="coupon-mail-print">
                <button (click)="mailPopup();" class="btn btn-primary btn-block btn-fill" type="submit">EMAIL COUPON</button>
                <button (click)="printCoupon();" class="btn btn-primary btn-block btn-fill" type="submit">PRINT COUPON</button>
                <button id="mailcoupon"(click)="saveCouponApi()" class="btn btn-primary btn-block btn-fill"type="submit">SAVE COUPON</button>
            </div>
            <div class="modal-body coupons-other-areas"id="couponmail">
                <div class="servererrors"id="signuperrors-container">
                </div>
                <div class="form-group">
                            <form name="form" role="form" id="floatForm" (ngSubmit)="cm.form.valid && sendCoupon(mailFormData, cm)" #cm="ngForm" novalidate>
                        <div class="form-group">
                                    <input placeholder="Enter Your Email" class="form-control" type="mail" name="ucmail" [(ngModel)]="mailFormData.ucmail" #ucmail="ngModel"
                                     required />
                            <div *ngIf="cm.submitted && !ucmail.valid"class="help-block">Email is required</div>
                     </div>
                        <div class="form-group">
                            <button class="btn btn-primary btn-block btn-fill"type="submit">EMAIL COUPON</button>
                        </div>
                        <div class="form-group">
                            <button class="btn btn-primary btn-block btn-fill"type="cancel"(click)="cancelCoupon()">CANCEL</button>
                        </div>
                    </form>
                </div>
            </div>
            <div class="modal-body coupons-other-areas"id="couponprintdata">
                <div class=" text-center mar-bottom-sm"id="couponvalid"style="display: none;">
                            <button type="button" class="btn btn-primary theme-btn btn-fill btn-dark" (click)="printMedia()">Print</button>&nbsp;
                            <button type="button" class="btn btn-primary theme-btn btn-fill" (click)="closePopup()">Cancel</button>
                </div>
                <div class="panel-body"id="printableContent">
                    <app-lazy-load [isLoaded]="isLoaded" [sectionName]="'coupons'"></app-lazy-load>
                    <img style="display: none;"class="img-responsive"[src]="sanitizer.bypassSecurityTrustResourceUrl(printData)" id="invoicePrint" 
                    class="printable img-responsive full-width"alt="Image">
                </div>
            </div>
        </div>
    </div>
</div> -->



<!-- Coupon Model Ends Here -->

<!--coupon fir multiple-->
<div class="A-B-coupon-1">
    <div bsModal #lgModal1="bs-modal" id="coupon-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
        <div class="modal-dialog select-modal-dlg">
            <div class="modal-content">
               <div class="modal-header mobileView">
                    <button type="button" class="close closeModelpoup pull-right" (click)="lgModal1.hide()" aria-label="Close">
          <span aria-hidden="true">
            <i class="fa fa-times"></i>
          </span>
        </button>
                </div>
                <div id="couponsErrorMessage" class="hidden couponsErrorMessage"></div>
                <div class="modal-body coupons-other-areas" id="couponmail1">
                    <div class="servererrors" id="signuperrors-container">
                    </div>
                    <div class="form-group">

                        <form id="floating-label-google-map1" name="form" role="form" class="formFix" (ngSubmit)="cm.form.valid && sendCoupon(mailFormData, cm)" #cm="ngForm" novalidate>
                            <div class="form-group" [ngClass]="{ 'has-error' : cm.submitted && !ucmail.valid || mailFormData.ucmail ==''}">

                                <input placeholder="Enter Your Email" class="form-control" type="mail" name="ucmail" [(ngModel)]="mailFormData.ucmail" #ucmail="ngModel" required />
                                <div *ngIf="cm.submitted && !ucmail.valid || mailFormData.ucmail ==''" class="help-block emailErrorHelpBlock">Email is required</div>
                            </div>
                            <div class="form-group " [ngClass]="{ 'hide' : hideSendEmailButton}">
                                <div class="row">
                                    <div class="col-sm-6">
                                        <button class="btn btn-primary btn-block btn-fill" type="submit">EMAIL OFFER</button>
                                        <div class="mar-bottom-xs visible-xs"></div>
                                    </div>
                                    <div class="col-sm-6">
                                        <!-- <p *showItDevice="['mobile','tablet']"></p> -->
                                        <button class="btn btn-primary btn-block btn-fill" type="button" (click)="cancelEmail(mailFormData, cm)">CANCEL</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                   
                        <div *ngIf="!loggedUserName" class="form-group">

                            <div class="checkbox newcheckbox-d">
                                <label class="enroll-terms-lbl top-checkbox">
                                    <input id="enrolledInCheck" style="font-size: 16px;" [checked]="checkedCheckbox" name="enrolledIn"
                                    type="checkbox" required style="margin-top: 3px;" (click)="checkForEnrollNew($event)" value=""> &nbsp;&nbsp;&nbsp;Enroll in Icon Rewards and receive instant discounts on
                                    daily parking.</label>
                        </div>
                        </div>
                        <form *ngIf="showEnrollForm && !loggedUserName" id="floatForm" class="formFix" name="form" (ngSubmit)="moveToNext(userData, iconR)" role="form"
            #iconR="ngForm">
            
            
            <p>All Icon Rewards members receive at least 10% off posted facility rates and online reservations!<br class="hidden-sm hidden-xs"> Enroll now to unlock immediate discounts!</p>
            <p class="mar-bottom-0">Already have an account? <a class="pad-left-xs" data-toggle="modal" href='#login' id="loginPopup">Sign in here <fa name="angle-right"></fa></a></p>
            
            <div class="row">
              <div class="col-sm-6">
                <div class="form-group" [ngClass]="{ 'has-error' : iconR.submitted && !first_name.valid}">
                  <label class="control-label" for="fname">First name</label>
                  <input class="form-control" [(ngModel)]="userData.first_name" (keypress)="restChar($event)" (paste)="onPaste($event)" name="first_name" #first_name="ngModel"
                    required type="text" placeholder="" />
                  <div *ngIf="iconR.submitted && !first_name.valid" class="help-block">First Name is required</div>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group" >
                  <label class="control-label" for="lname">Last name</label>
                  <input class="form-control" [(ngModel)]="userData.last_name" (keypress)="restChar($event)" placeholder="" (paste)="onPaste($event)"  name="last_name" #last_name="ngModel"
                    type="text" />
                  
                </div>
              </div>
              <!-- <div style="display: none;" class="col-sm-6">
                <div class="form-group" [ngClass]="{ 'has-error' : iconR.submitted && !email.valid}">
                  <label class="control-label" for="email">Email address</label>
                  <input class="form-control" autocomplete="off" [(ngModel)]="userData.email" placeholder="" name="email" #email="ngModel"
                    required type="text" (keydown)="restSpace($event)" pattern="{{emailRegex}}" />
                  <div *ngIf="iconR.submitted && !email.valid" class="help-block">Email is required</div>
                </div>
              </div> -->
              <div class="col-sm-6">
                <div class="form-group" [ngClass]="{ 'has-error' : iconR.submitted && !phone.valid}">
                  <label class="control-label" for="phone">Phone number (optional)</label>
                  <input class="form-control" [(ngModel)]="userData.phone" placeholder="" minlength="10" [textMask]="{mask: phoneMask}" (keypress)="phoneFormat($event)" name="phone"
                     #phone="ngModel" type="text" />
                  <div *ngIf="iconR.submitted && !phone.valid" class="help-block">Not a valid phone number</div>
                </div>
              </div>
              <div class="col-sm-12">
                <h4 class="text-primary text-uppercase">Password</h4>
              </div>
              <div class="col-sm-6">
                <div class="form-group" [ngClass]="{ 'has-error' : iconR.submitted && !password.valid}">
                  <label class="control-label" for="password">Password</label>
                  <input class="form-control" autocomplete="off" placeholder="" [(ngModel)]="userData.password" name="password"
                    #password="ngModel" required reverse="true" validateEqual="cpassword" type="password">
                  <div *ngIf="iconR.submitted && !password.valid" class="help-block">{{passwordErrMsg}}</div>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group" [ngClass]="{ 'has-error' : iconR.submitted && !cpassword.valid}">
                  <label class="control-label" for="re-password">Confirm password</label>
                  <input class="form-control" [(ngModel)]="userData.cpassword" placeholder="" name="cpassword" #cpassword="ngModel"
                    required validateEqual="password" type="password">
                  <div *ngIf="iconR.submitted && !cpassword.valid" class="help-block">Confirm Password and Password
                    should be same</div>
                </div>
              </div>
              <div class="clearfix"></div>
              <!-- <div  class="col-sm-6">
                <div class="form-group" [ngClass]="{ 'has-error' : iconR.submitted && !promocode.valid}">
                  <label class="control-label" for="promocode">Invitation Code (optional)</label>
                  <input class="form-control" [(ngModel)]="userData.promocode" maxlength="8" placeholder="" name="promocode" #promocode="ngModel" type="text">
                </div>
              </div>  -->
              <div class="form-group  col-sm-12" [ngClass]="{ 'has-error' : iconR.submitted && !enrolledIn.valid}">
                <div class="checkbox newcheckbox-d">
                  <label class="enroll-terms-lbl">
                    <input [(ngModel)]="userData.enrolledIn" (click)="openTermModalWithCheck(AcceptTerms, $event); " name="enrolledIn"
                      #enrolledIn="ngModel" type="checkbox" required value=""> &nbsp;&nbsp;&nbsp;I agree to the Icon
                    Rewards &nbsp;<a target="__blank" (click)="openTermModal(AcceptTerms); ">Terms and Conditions</a></label>
                  <div *ngIf="iconR.submitted && !enrolledIn.valid" class="help-block">You need to accept terms &
                    conditions.</div>
                </div>
              </div>
              <div class="col-sm-6 form-group mar-top-xs mar-bottom-sm btn-center-xs">
                <button class="btn btn-primary btn-fill">Enroll in Icon Rewards</button>
              </div>
              <div class="col-sm-6 form-group mar-top-xs mar-bottom-sm btn-center-xs cancelButtonFixIE">
                <!-- <p *showItDevice="['mobile','tablet']"></p> -->
                <button class="btn btn-primary btn-block btn-fill" type="button" (click)="cancelEnrollForm()">CANCEL</button>
              </div>
            </div>
          </form>
              
                </div>
                <div class="modal-body coupons-other-areas" class="couponprintdata" id="couponprintdata" style="display:block;">
                    <div class="panel-body" id="printableContent" style="padding:0px;">
                        <div class="mar-top-0 four-cpn-box" *ngIf="showSelectBox">
                            <h2 *ngIf="ShowCouponListOnLoad" [class.partnerPageHeaadingFix]="partnerPageOn" class="text-primary mobile  text-uppercase mar-top-0 mar-bottom-sm">{{array_data?.facility?.entrance_location}} </h2>
                            <app-lazy-load [isLoaded]="isLoaded" [sectionName]="'Rates'"></app-lazy-load>
                            <div class="row" *ngIf="ShowCouponListOnLoad">
                                <div [ngClass]="{'col-md-6': isClassVisible,'col-md-12':!isClassVisible }" class="single_record col-xs-12 col-sm-6  col-lg-6" *ngFor="let couponData of array_data.coupons; let couponIndexSelect = index">
                                    <div class="well">
                                        <h4>{{couponData?.description}}</h4>
                                        <h3 sourceFont>${{couponData.split_price[0]}}</h3>
                                        <div class="flex-row">
                                            <div class="col pad-0">
                                                <p class="mar-bottom-0 mar-top-0">
                                                    <small>Description</small>
                                                    <br>
                                                    <ng-container *ngIf="couponData?.details!=''">
                                                        {{couponData?.details}}
                                                    </ng-container>
                                                    <ng-container *ngIf="couponData?.details==''">
                                                        7 Days a week / Enter any time / Exit any time / {{couponData?.max_stay_details}}.
                                                    </ng-container>
                                                </p>
                                            </div>
                                            <div class="col align-self-end text-right pad-left-10 pad-right-0 coupon-tab-pad">
                                                <a (click)="showCouponbox(couponIndexSelect)" class="btn btn-primary btn-fill theme-btn">Select</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="single-coupon-box couponList hide" id="couponShow{{couponIndexSelectCoupon}}" *ngFor="let couponData of array_data.coupons; let couponIndexSelectCoupon = index">
                            <a type="button" href="javascript:;" class="change-coupon" (click)="backSelectbox(couponIndexSelectCoupon)">
                                <fa name="angle-left"></fa>&nbsp; Back
                            </a>
                            <div class="couponDataSection mar-top-sm" id="couponprint{{couponData?.id}}">
                                <article [ngClass]="{'scrollIos':isIphone,'scrollAndroid':isAndroid }" class="ResponsiveCouponsPrint">
                                    <header>
                                        <!-- <p>Reservation Code (based on availability)</p> -->
                                        <p>Mobile Code</p>
                                        <h1 class="text-primary text-uppercase mar-top-0">{{couponCodeVaue}}</h1>
                                    </header>
                                    <main class="">
                                        <!-- <div class="flex-row visible-xs mar-top-xs">
                    <div class="col-10 col-xs-offset-1">
                      <a href="javascript:;" class="btn btn-block theme-btn btn-primary">Add to mobile wallet</a>
                    </div>
                  </div> -->


                                        <div class="clearfix panel-body garage-pad">

                                            <div class="flex-row no-gutters align-items-center ">
                                                <div class="col-9">
                                                    <h2 class="text-primary text-uppercase ">{{array_data?.facility?.entrance_location}}</h2>
                                                    <h4 class="coupon-date-desc"> {{couponData?.description}} </h4>
                                                    <p *ngIf="couponData.details!=''" class="mar-top-sm">{{couponData.details}}.</p>
                                                    <p *ngIf="couponData.details==''" class="mar-top-sm">7 Days a week / Enter any time / Exit any time / {{couponData?.max_stay_details}}.</p>
                                                </div>
                                                <div class="col-3 text-center">
                                                    <h1 class="priceCoupon" sourceFont>${{couponData.split_price[0]}}</h1>
                                                    <p>tax included <br />(Based on Availability)</p>
                                                </div>
                                            </div>
                                            <div class=" text-center  " id="couponvalid">
                                                <button  type="button" class="btn btn-primary theme-btn btn-fill " id="mailcoupon" *ngIf="this.loggedUserName && !isPartners" (click)="saveCouponApi(couponData?.id)">Save
                        <span class="hidden-xs">Offer</span>
                      </button>

                                                <button type="button" class="btn btn-primary theme-btn btn-fill " (click)="mailPopup(couponData?.id,couponData.split_price[0],array_data?.facility?.entrance_location);">Email</button>
                                                <!-- <div class="visible-xs" style="height:3px;" *ngIf="this.loggedUserName" ></div> -->
                                                <button style="display: none !important;" type="button" class="btn btn-primary hidden theme-btn btn-fill " (click)="printMedia(couponData?.id,couponData.split_price[0],array_data?.facility?.entrance_location)">Print</button>&nbsp;
                                            </div>
                                        </div>
                                        <div class="clearfix">


                                            <div class="flex-row no-gutters ">
                                                <div class="col-12 col-sm-6">
                                                    <div class="panel-body cpn-detal-area-pd">
                                                        <!-- <h4 class="text-primary text-uppercase mar-bottom-xs ">Reservation Details</h4> -->
                                                        <h4 class="text-primary text-uppercase mar-bottom-xs ">Rate Details</h4>
                                                        <p *ngIf="couponData.coupon_restrictions!=''">{{couponData.coupon_restrictions}}</p>
                                                        <p>{{couponData?.used_text}}
                                                        </p>
                                                        <p *ngIf="couponData?.expire_on">Expires {{couponData?.expire_on}}</p>
                                                        <p>Code : #{{couponData?.coupon_code}}</p>
                                                        <div class="coupon-barcode">
                                                            <ngx-barcode *ngIf="barcodeData!='' && barcodeData!=null" [bc-height]="53" [bc-margin-top]="1" [bc-margin-bottom]="1" [bc-margin-left]="1" [bc-margin-right]="1" [bc-background]="gray" [bc-width]="2" [bc-element-type]='svg' [bc-value]="barcodeValue" [bc-display-value]="false"></ngx-barcode>
                                                            <!-- <qrcode *ngIf="qrcodeData!='' && qrcodeData!=null" [colorlight]="'#ffffff'" [qrdata]="barcodeValue" [size]="150" [level]="'M'"></qrcode> -->
                                                        </div>
                                                        <div class="extraContent hidden-xs">

                                                            <hr>
                                                            <div class="clearfix"></div>
                                                            <p>{{couponData?.disclaimer}}</p>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div class="col-12 col-sm-6 darklight">
                                                    <div class="panel-body fac-detal-area-pd">
                                                        <h4 class="text-primary text-uppercase  ">Facility Details</h4>
                                                        <h3>{{ array_data?.facility?.facility_name_modified }} </h3>
                                                        <p *ngIf="array_data?.facility?.phone_number">
                                                            <fa name="phone"></fa> &nbsp;
                                                            <a id="garage_phone 1" style="color: #000;" href="tel:{{array_data.facility.phone_number}}">
                                                                {{formatPhoneNumber(array_data?.facility?.phone_number)}}
                                                            </a>
                                                        </p>
                                                        <p class="mar-bottom-xs" *ngIf="array_data?.facility?.between_streets">
                                                            <b>Between:</b> {{ array_data?.facility?.between_streets }}</p>
                                                        <p class="mar-bottom-xs" *ngIf="array_data?.facility?.entrance_location">
                                                            <b>Entrance</b> {{array_data?.facility?.entrance_location}}</p>
                                                        <p class="dailystyles mar-bottom-xs  mar-top-xs ">
                                                            <a (click)="getdirections.show();lgModal1.hide()">Get Directions &nbsp;&nbsp;
                              <fa name="chevron-right"></fa>
                            </a>
                                                        </p>
                                                        <div class="clearfix">

                                                            <p *ngIf="array_data.facility?.hours_of_operation?.length == 0" class="mar-bottom-xs">Open: 7 Days a week, 24 hours a day</p>
                                                            <div class="TimeActive table-responsive" *ngIf="array_data.facility?.hours_of_operation?.length > 0">
                                                                <h4>Hours of Operation</h4>
                                                                <table class="table table-condensed ">
                                                                    <tr *ngFor="let wh of array_data.facility?.hours_of_operation;">
                                                                        <td>{{wh.day}}</td>
                                                                        <td>:</td>
                                                                        <td width="90px">{{wh.formatted_open_time}}</td>
                                                                        <td>-</td>
                                                                        <td>{{wh.formatted_close_time}} {{showtimefornxtornot(wh.close_time)}}</td>
                                                                    </tr>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="parkImg">
                                                        <img [src]="imgSrcLocation" class='full-width' [alt]="imgSrcAlt">
                                                    </div>

                                                </div>
                                                <div class="col-12 visible-xs">
                                                    <div class="extraContent">

                                                        <div class="clearfix panel-body">
                                                            <p>{{couponData?.disclaimer}}</p>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </main>
                                </article>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!--coupon end multiple-->
<!-- Coupon Model Starts From Here LATEST CODE -->
<div class="A-B-coupon">
    <div bsModal #lgModal="bs-modal" id="coupon-modal" class="modal mobileModal fade" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
        <div class="modal-dialog mobileModal-dialog">
            <div class="modal-content">
                <div class="modal-header">



                    <h4 id="couponheading" class="coupons-parent-panel modal-title pull-left"></h4>
                    <button type="button" id="closeCouponModal" class="close pull-right" (click)="lgModal.hide()" aria-label="Close">
          <span aria-hidden="true" (click)="reset(mailFormData, cm)">
            <fa name="times"></fa>
          </span>
        </button>
                </div>
                <div id="couponsErrorMessage" class="hidden couponsErrorMessage"></div>
                <app-lazy-load [isLoaded]="isLoaded" [sectionName]="'Rates'"></app-lazy-load>
                <div class="modal-body coupons-other-areas hidden" id="coupon-mail-print">
                    <!-- <button (click)="mailPopup();" class="btn btn-primary btn-block btn-fill" type="submit">EMAIL COUPON</button>
                <button (click)="printCoupon();" class="btn btn-primary btn-block btn-fill" type="submit">PRINT COUPON</button>
                <div class="visible-xs" style="height:3px;"></div>
                <button id="mailcoupon" (click)="saveCouponApi()" class="btn btn-primary btn-block btn-fill" type="submit">SAVE COUPON</button> -->
                </div>
                <div class="modal-body coupons-other-areas" id="couponmail">
                    <div class="servererrors" id="signuperrors-container">
                    </div>
                    <div class="form-group">

                        <form id="floating-label-google-map2" name="form" role="form" class="formFix" (ngSubmit)="cm.form.valid && sendCoupon(mailFormData, cm)" #cm="ngForm" novalidate>
                            <div class="form-group" [ngClass]="{ 'has-error' : cm.submitted && !ucmail.valid || mailFormData.ucmail ==''}">

                                <input placeholder="Enter Your Email" class="form-control" type="mail" name="ucmail" [(ngModel)]="mailFormData.ucmail" #ucmail="ngModel" required />
                                <div *ngIf="cm.submitted && !ucmail.valid || mailFormData.ucmail ==''" class="help-block emailErrorHelpBlock">Email is required</div>
                            </div>
                            <div class="form-group" [ngClass]="{ 'hide' :hideSendEmailButton2}">
                                <div class="row">
                                    <div class="col-sm-6">
                                        <button id="submitEmailCoupon" class="btn btn-primary btn-block btn-fill" type="submit">EMAIL OFFER</button>
                                        <div class="mar-bottom-xs visible-xs"></div>
                                    </div>
                                    <div class="col-sm-6">
                                        <!-- <p *showItDevice="['mobile','tablet']"></p> -->
                                        <button class="btn btn-primary btn-block btn-fill" type="button" (click)="cancelEmail(mailFormData, cm)">CANCEL</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="form-group" *ngIf="!loggedUserName">

                        <div class="checkbox newcheckbox-d">
                            <label class="enroll-terms-lbl top-checkbox">
                                <input id="enrolledInCheck2" [checked]="checkedCheckbox" name="enrolledIn"
                                type="checkbox" required (click)="checkForEnrollNew2($event)" value=""> &nbsp;&nbsp;&nbsp;Enroll in Icon Rewards and receive instant discounts on
                                daily parking.</label>
                    </div>
                    </div>
                    <form *ngIf="showEnrollForm  && !loggedUserName" id="floatForm" class="formFix" name="form" (ngSubmit)="moveToNext(userData, iconR)" role="form"
        #iconR="ngForm">
        
        
        <p>All Icon Rewards members receive at least 10% off posted facility rates and online reservations! Enroll now to unlock immediate discounts!</p>
        <p class="mar-bottom-0">Already have an account? <a class="pad-left-xs" data-toggle="modal" href='#login' id="loginPopup">Sign in here <fa name="angle-right"></fa></a></p>
        
        <div class="row">
          <div class="col-sm-6">
            <div class="form-group" [ngClass]="{ 'has-error' : iconR.submitted && !first_name.valid}">
              <label class="control-label" for="fname">First name</label>
              <input class="form-control" [(ngModel)]="userData.first_name" (keypress)="restChar($event)" (paste)="onPaste($event)" name="first_name" #first_name="ngModel"
                required type="text" placeholder="" />
              <div *ngIf="iconR.submitted && !first_name.valid" class="help-block">First Name is required</div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-group" [ngClass]="{ 'has-error' : iconR.submitted && !last_name.valid}">
              <label class="control-label" for="lname">Last name</label>
              <input class="form-control" [(ngModel)]="userData.last_name" (keypress)="restChar($event)" placeholder="" (paste)="onPaste($event)" required name="last_name" #last_name="ngModel"
                type="text" />
              <div *ngIf="iconR.submitted && !last_name.valid" class="help-block">Last Name is required</div>
            </div>
          </div>
          <!-- <div style="display: none;" class="col-sm-6">
            <div class="form-group" [ngClass]="{ 'has-error' : iconR.submitted && !email.valid}">
              <label class="control-label" for="email">Email address</label>
              <input class="form-control" autocomplete="off" [(ngModel)]="userData.email" placeholder="" name="email" #email="ngModel"
                required type="text" (keydown)="restSpace($event)" pattern="{{emailRegex}}" />
              <div *ngIf="iconR.submitted && !email.valid" class="help-block">Email is required</div>
            </div>
          </div> -->
          <div class="col-sm-6">
            <div class="form-group" [ngClass]="{ 'has-error' : iconR.submitted && !phone.valid}">
              <label class="control-label" for="phone">Phone number (optional)</label>
              <input class="form-control" [(ngModel)]="userData.phone" placeholder="" minlength="10" [textMask]="{mask: phoneMask}" (keypress)="phoneFormat($event)" name="phone"
                 #phone="ngModel" type="text" />
              <div *ngIf="iconR.submitted && !phone.valid" class="help-block">Not a valid phone number</div>
            </div>
          </div>
          <div class="col-sm-12">
            <h4 class="text-primary text-uppercase">Password</h4>
          </div>
          <div class="col-sm-6">
            <div class="form-group" [ngClass]="{ 'has-error' : iconR.submitted && !password.valid}">
              <label class="control-label" for="password">Password</label>
              <input class="form-control" autocomplete="off" placeholder="" [(ngModel)]="userData.password" name="password"
                #password="ngModel" required reverse="true" validateEqual="cpassword" type="password">
              <div *ngIf="iconR.submitted && !password.valid" class="help-block">{{passwordErrMsg}}</div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-group" [ngClass]="{ 'has-error' : iconR.submitted && !cpassword.valid}">
              <label class="control-label" for="re-password">Confirm password</label>
              <input class="form-control" [(ngModel)]="userData.cpassword" placeholder="" name="cpassword" #cpassword="ngModel"
                required validateEqual="password" type="password">
              <div *ngIf="iconR.submitted && !cpassword.valid" class="help-block">Confirm Password and Password
                should be same</div>
            </div>
          </div>
          <div class="clearfix"></div>
          <!-- <div  class="col-sm-6">
            <div class="form-group" [ngClass]="{ 'has-error' : iconR.submitted && !promocode.valid}">
              <label class="control-label" for="promocode">Invitation Code (optional)</label>
              <input class="form-control" [(ngModel)]="userData.promocode" maxlength="8" placeholder="" name="promocode" #promocode="ngModel" type="text">
            </div>
          </div>  -->
          <div class="form-group  col-sm-12" [ngClass]="{ 'has-error' : iconR.submitted && !enrolledIn.valid}">
            <div class="checkbox newcheckbox-d">
              <label class="enroll-terms-lbl">
                <input [(ngModel)]="userData.enrolledIn" (click)="openTermModalWithCheck(AcceptTerms, $event); " name="enrolledIn"
                  #enrolledIn="ngModel" type="checkbox" required value=""> &nbsp;&nbsp;&nbsp;I agree to the Icon
                Rewards &nbsp;<a target="__blank" (click)="openTermModal(AcceptTerms); ">Terms and Conditions</a></label>
              <div *ngIf="iconR.submitted && !enrolledIn.valid" class="help-block">You need to accept terms &
                conditions.</div>
            </div>
          </div>
          <div class="col-sm-6 form-group mar-top-xs mar-bottom-sm btn-center-xs">
            <button class="btn btn-primary btn-fill">Enroll in Icon Rewards</button>
          </div>
          <div class="col-sm-6 form-group mar-top-xs mar-bottom-sm btn-center-xs cancelButtonFixIE">
            <!-- <p *showItDevice="['mobile','tablet']"></p> -->
            <button class="btn btn-primary btn-block btn-fill" type="button" (click)="cancelEnrollForm()">CANCEL</button>
          </div>
        </div>
      </form>
                </div>


                <div class="modal-body coupons-other-areas" class="singleCoupon" class="couponprintdata" id="couponprintdata" style="display:block;">







                    <!-- testing modals -->
                    <div class=" slide couponPageCarousel " *ngIf="ShowCouponListOnLoad">

                        <carousel class="customeSliderMovement" [noPause]="true" [(activeSlide)]="activeSlide" [interval]="false"  >
                            <slide [class.active]="couponIndex=='0'" *ngFor="let couponData of array_data.coupons; let couponIndex = index">
                                <div class="couponDataSection">
                                    <article [ngClass]="{'scrollIos':isIphone,'scrollAndroid':isAndroid }" class="ResponsiveCouponsPrint">
                                        <header>
                                            <p>Mobile Code</p>
                                            <h1 class="text-primary text-uppercase mar-top-0">{{couponCodeVaue}}</h1>
                                        </header>
                                        <main class="">
                                            <!-- <div class="flex-row visible-xs mar-top-xs">
                      <div class="col-10 col-xs-offset-1">
                        <a href="javascript:;" class="btn btn-block theme-btn btn-primary">Add to mobile wallet</a>
                      </div>
                    </div> -->


                                            <div class="clearfix panel-body garage-pad">

                                                <div class="flex-row no-gutters align-items-center ">
                                                    <div class="col-9">
                                                        <h2 class="text-primary text-uppercase ">{{array_data?.facility?.entrance_location}}</h2>
                                                        <h4 class="coupon-date-desc"> {{couponData?.description}} </h4>
                                                        <p *ngIf="couponData.details!=''" class="mar-top-sm">{{couponData.details}}.</p>
                                                        <p *ngIf="couponData.details==''" class="mar-top-sm">7 Days a week / Enter any time / Exit any time / {{couponData?.max_stay_details}}.</p>

                                                    </div>
                                                    <div class="col-3 text-center">
                                                        <h1 class="priceCoupon" sourceFont>${{couponData.split_price[0]}}</h1>
                                                        <p>tax included  <br >(Based on Availability)</p>
                                                    </div>
                                                </div>
                                                <div class=" text-center  " id="couponvalid">
                                                    <button type="button" class="btn btn-primary theme-btn btn-fill " id="mailcoupon" *ngIf="this.loggedUserName && !isPartners" (click)="saveCouponApi(couponData?.id)">Save
                          <span class="hidden-xs">Offer</span>
                        </button>

                                                    <button type="button" class="btn btn-primary theme-btn btn-fill " (click)="mailPopup(couponData?.id,couponData.split_price[0],array_data?.facility?.entrance_location);">Email</button>
                                                    <!-- <div class="visible-xs" style="height:3px;" *ngIf="this.loggedUserName" ></div> -->
                                                    <button style="display:none !important" type="button" class="btn btn-primary theme-btn btn-fill " (click)="printMedia(couponData?.id,couponData.split_price[0],array_data?.facility?.entrance_location)">Print</button>&nbsp;
                                                </div>
                                            </div>
                                            <div class="clearfix">


                                                <div class="flex-row no-gutters ">
                                                    <div class="col-12 col-sm-6">
                                                        <div class="panel-body cpn-detal-area-pd">
                                                            <h4 class="text-primary text-uppercase mar-bottom-xs ">Rate Details</h4>
                                                            <p *ngIf="couponData.coupon_restrictions!=''">{{couponData.coupon_restrictions}}</p>
                                                            <p>{{couponData?.used_text}}
                                                            </p>
                                                            <p *ngIf="couponData?.expire_on">Expires {{couponData?.expire_on}}</p>
                                                            <p>Code : #{{couponData?.coupon_code}}</p>
                                                            <div class="coupon-barcode">
                                                                <ngx-barcode *ngIf="barcodeData!='' && barcodeData!=null" [bc-height]="53" [bc-margin-top]="1" [bc-margin-bottom]="1" [bc-margin-left]="1" [bc-margin-right]="1" [bc-background]="gray" [bc-width]="2" [bc-element-type]='svg' [bc-value]="barcodeValue" [bc-display-value]="false"></ngx-barcode>
                                                                <!-- <qrcode *ngIf="qrcodeData!='' && qrcodeData!=null" [colorlight]="'#ffffff'" [qrdata]="barcodeValue" [size]="150" [level]="'M'"></qrcode> -->
                                                            </div>
                                                            <div class="extraContent hidden-xs">

                                                                <hr>
                                                                <div class="clearfix"></div>
                                                                <p>{{couponData?.disclaimer}}</p>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div class="col-12 col-sm-6 darklight">
                                                        <div class="panel-body fac-detal-area-pd">
                                                            <h4 class="text-primary text-uppercase  ">Facility Details</h4>
                                                            <h3> {{array_data?.facility?.facility_name_modified}} </h3>
                                                            <p *ngIf="array_data?.facility?.phone_number">
                                                                <fa name="phone"></fa> &nbsp;
                                                                <a id="garage_phone 2" style="color: #000;" href="tel:{{array_data.facility.phone_number}}">
                                                                    {{formatPhoneNumber(array_data?.facility?.phone_number)}}
                                                                </a>
                                                            </p>
                                                           <p class="mar-bottom-xs" *ngIf="array_data?.facility?.between_streets">
                                                                <b>Between:</b> {{ array_data?.facility?.between_streets }}</p>
                                                            <p class="mar-bottom-xs" *ngIf="array_data?.facility?.entrance_location">
                                                                <b>Entrance</b> {{array_data?.facility?.entrance_location}}</p>
                                                            <p class="dailystyles mar-bottom-xs  mar-top-xs ">
                                                                <a (click)="getdirections.show();lgModal.hide()">Get Directions &nbsp;&nbsp;
                                <fa name="chevron-right"></fa>
                              </a>
                                                            </p>
                                                            <div class="clearfix">

                                                                <p *ngIf="array_data.facility?.hours_of_operation?.length == 0" class="mar-bottom-xs">Open: 7 Days a week, 24 hours a day</p>
                                                                <div class="TimeActive table-responsive" *ngIf="array_data.facility?.hours_of_operation?.length > 0">
                                                                    <h4>Hours of Operation</h4>
                                                                    <table class="table table-condensed ">
                                                                        <tr *ngFor="let wh of array_data.facility?.hours_of_operation;">
                                                                            <td>{{wh.day}}</td>
                                                                            <td>:</td>
                                                                            <td width="90px">{{wh.formatted_open_time}}</td>
                                                                            <td>-</td>
                                                                            <td>{{wh.formatted_close_time}} {{showtimefornxtornot(wh.close_time)}}</td>
                                                                        </tr>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="parkImg">
                                                            <img [src]="imgSrcLocation" class='full-width' [alt]="imgSrcAlt">
                                                        </div>

                                                    </div>
                                                    <div class="col-12 visible-xs">
                                                        <div class="extraContent">

                                                            <div class="clearfix panel-body">
                                                                <p>{{couponData?.disclaimer}}</p>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </main>

                                    </article>


                                </div>
                            </slide>
                        <!-- add this -->
                        <div class="indicators" id="customDots">
                          <!-- <button *ngFor="let item of items; let i = index" type="button" class="indicator"
                                  [class.active]="i === activeSlide" [attr.aria-pressed]="i === activeSlide"
                                  (click)="switchSlide(i)"></button> -->
                                  <button type="button" class="indicator" *ngFor="let couponDataSlider of array_data.coupons; let couponIndexSlider = index"
                                  [class.active]="couponIndexSlider === activeSlide" [attr.aria-pressed]="couponIndexSlider === activeSlide"
                                  (click)="switchSlide(activeSlide)"><span sourceFont>${{couponDataSlider.split_price[0]}}</span></button>
                        </div>
                        <!-- /add this -->
                        </carousel>
                        <!-- Wrapper for slides -->
                        <div owl-slider class="owl-carousel owl-theme hidden">
                            <div class="item" [class.active]="couponIndex=='0'" *ngFor="let couponData of array_data.coupons; let couponIndex = index">
                                <div class="couponDataSection">
                                    <article [ngClass]="{'scrollIos':isIphone,'scrollAndroid':isAndroid }" class="ResponsiveCouponsPrint">
                                        <header>
                                            <p>Mobile Code</p>
                                            <h1 class="text-primary text-uppercase mar-top-0">{{couponCodeVaue}}</h1>
                                        </header>
                                        <main class="">
                                            <!-- <div class="flex-row visible-xs mar-top-xs">
                      <div class="col-10 col-xs-offset-1">
                        <a href="javascript:;" class="btn btn-block theme-btn btn-primary">Add to mobile wallet</a>
                      </div>
                    </div> -->


                                            <div class="clearfix panel-body garage-pad">

                                                <div class="flex-row no-gutters align-items-center ">
                                                    <div class="col-9">
                                                        <h2 class="text-primary text-uppercase ">{{array_data?.facility?.entrance_location}}</h2>
                                                        <h4 class="coupon-date-desc"> {{couponData?.description}} </h4>
                                                        <p *ngIf="couponData.details!=''" class="mar-top-sm">{{couponData.details}}.</p>
                                                        <p *ngIf="couponData.details==''" class="mar-top-sm">7 Days a week / Enter any time / Exit any time / {{couponData?.max_stay_details}}.</p>

                                                    </div>
                                                    <div class="col-3 text-center">
                                                        <h1 class="priceCoupon" sourceFont>${{couponData.split_price[0]}}</h1>
                                                        <p>tax included  <br >(Based on Availability)</p>
                                                    </div>
                                                </div>
                                                <div class=" text-center  " id="couponvalid">
                                                    <button type="button" class="btn btn-primary theme-btn btn-fill " id="mailcoupon" *ngIf="this.loggedUserName && !isPartners" (click)="saveCouponApi(couponData?.id)">Save
                          <span class="hidden-xs">Offer</span>
                        </button>

                                                    <button type="button" class="btn btn-primary theme-btn btn-fill " (click)="mailPopup(couponData?.id,couponData.split_price[0],array_data?.facility?.entrance_location);">Email</button>
                                                    <!-- <div class="visible-xs" style="height:3px;" *ngIf="this.loggedUserName" ></div> -->
                                                    <button style="display:none !important" type="button" class="btn btn-primary theme-btn btn-fill " (click)="printMedia(couponData?.id,couponData.split_price[0],array_data?.facility?.entrance_location)">Print</button>&nbsp;
                                                </div>
                                            </div>
                                            <div class="clearfix">


                                                <div class="flex-row no-gutters ">
                                                    <div class="col-12 col-sm-6">
                                                        <div class="panel-body cpn-detal-area-pd">
                                                            <h4 class="text-primary text-uppercase mar-bottom-xs ">Rate Details</h4>
                                                            <p *ngIf="couponData.coupon_restrictions!=''">{{couponData.coupon_restrictions}}</p>
                                                            <p>{{couponData?.used_text}}
                                                            </p>
                                                            <p *ngIf="couponData?.expire_on">Expires {{couponData?.expire_on}}</p>
                                                            <p>Code : #{{couponData?.coupon_code}}</p>
                                                            <div class="coupon-barcode">
                                                                <ngx-barcode *ngIf="barcodeData!='' && barcodeData!=null" [bc-height]="53" [bc-margin-top]="1" [bc-margin-bottom]="1" [bc-margin-left]="1" [bc-margin-right]="1" [bc-background]="gray" [bc-width]="2" [bc-element-type]='svg' [bc-value]="barcodeValue" [bc-display-value]="false"></ngx-barcode>
                                                                <!-- <qrcode *ngIf="qrcodeData!='' && qrcodeData!=null" [colorlight]="'#ffffff'" [qrdata]="barcodeValue" [size]="150" [level]="'M'"></qrcode> -->
                                                            </div>
                                                            <div class="extraContent hidden-xs">

                                                                <hr>
                                                                <div class="clearfix"></div>
                                                                <p>{{couponData?.disclaimer}}</p>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div class="col-12 col-sm-6 darklight">
                                                        <div class="panel-body fac-detal-area-pd">
                                                            <h4 class="text-primary text-uppercase  ">Facility Details</h4>
                                                            <h3> {{ array_data?.facility?.facility_name_modified}} </h3>
                                                            <p *ngIf="array_data?.facility?.phone_number">
                                                                <fa name="phone"></fa> &nbsp;
                                                                <a id="garage_phone 3" style="color: #000;" href="tel:{{array_data.facility.phone_number}}">
                                                                    {{formatPhoneNumber(array_data?.facility?.phone_number)}}
                                                                </a>
                                                            </p>
                                                            <p class="mar-bottom-xs" *ngIf="array_data?.facility?.between_streets">
                                                                <b>Between:</b> {{ array_data?.facility?.between_streets }}</p>
                                                            <p class="mar-bottom-xs" *ngIf="array_data?.facility?.entrance_location">
                                                                <b>Entrance</b> {{array_data?.facility?.entrance_location}}</p>
                                                            <p class="dailystyles mar-bottom-xs  mar-top-xs">
                                                                <a (click)="getdirections.show();lgModal.hide()">Get Directions &nbsp;&nbsp;
                                <fa name="chevron-right"></fa>
                              </a>
                                                            </p>
                                                            <div class="clearfix">

                                                                <p *ngIf="array_data.facility?.hours_of_operation?.length == 0" class="mar-bottom-xs">Open: 7 Days a week, 24 hours a day</p>
                                                                <div class="TimeActive table-responsive" *ngIf="array_data.facility?.hours_of_operation?.length > 0">
                                                                    <h4>Hours of Operation</h4>
                                                                    <table class="table table-condensed ">
                                                                        <tr *ngFor="let wh of array_data.facility?.hours_of_operation;">
                                                                            <td>{{wh.day}}</td>
                                                                            <td>:</td>
                                                                            <td width="90px">{{wh.formatted_open_time}}</td>
                                                                            <td>-</td>
                                                                            <td>{{wh.formatted_close_time}} {{showtimefornxtornot(wh.close_time)}}</td>
                                                                        </tr>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="parkImg">
                                                            <img [src]="imgSrcLocation" class='full-width' [alt]="imgSrcAlt">
                                                        </div>

                                                    </div>
                                                    <div class="col-12 visible-xs">
                                                        <div class="extraContent">

                                                            <div class="clearfix panel-body">
                                                                <p>{{couponData?.disclaimer}}</p>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </main>

                                    </article>


                                </div>
                            </div>

                        </div>
                        <div class="owl-controls hidden">
                            <div id="customNav" class="owl-nav"></div>
                            <div id="customDots" class="owl-dots">
                                <button role="button" class="owl-dot" *ngFor="let couponDataSlider of array_data.coupons; let couponIndexSlider = index"><span sourceFont>${{couponDataSlider.split_price[0]}}</span></button>
                            </div>
                        </div>
                        <div class="hide" *ngFor="let couponData of array_data.coupons; let couponIndexTable = index">
                            <div id="table{{couponData?.id}}">
                                <table width="600" align="center" cellspacing="0" cellpadding="0" style="border:1px solid #ddd;-webkit-print-color-adjust: exact;">
                                    <tbody>
                                        <tr bgcolor="#ececec">
                                            <td align="center" style="padding: 20px;">
                                                <ngx-barcode *ngIf="barcodeData!='' && barcodeData!=null" [bc-height]="53" [bc-margin-top]="1" [bc-margin-bottom]="1" [bc-margin-left]="1" [bc-margin-right]="1" [bc-background]="gray" [bc-width]="2" [bc-element-type]='svg' [bc-value]="barcodeValue" [bc-display-value]="false"></ngx-barcode>
                                                <!-- <qrcode *ngIf="qrcodeData!='' && qrcodeData!=null" [colorlight]="'#ffffff'" [qrdata]="barcodeValue" [size]="150" [level]="'M'"></qrcode> -->
                                                <p style="margin: 0;padding: 10px 0 0 0;font-family: sans-serif;">Coupon Code #{{couponData?.coupon_code}}
                                                </p>
                                                <p style="margin: 0;padding: 10px 0 0 0;font-family: sans-serif;">
                                                    Mobile Code #{{couponCodeVaue}}
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <table width="100%" align="center" cellspacing="0" cellpadding="0">
                                                    <tbody>
                                                        <tr>
                                                            <td style="padding:30px;font-family: sans-serif;width: 75%;">
                                                                <h2 style="font-size: 30px; -webkit-print-color-adjust: exact;color: #ed8222;font-weight: 500;margin: 0;padding: 0;text-transform: uppercase;">

                                                                    <font color="#ed8222">{{array_data?.facility?.entrance_location}}</font>
                                                                </h2>
                                                                <h4 style="margin: 10px 0;padding: 0; -webkit-print-color-adjust: exact; font-size: 24px;font-weight: 500;text-transform: uppercase;">
                                                                    {{couponData?.description}}
                                                                </h4>
                                                                <p *ngIf="couponData.details!=''" style="font-weight: normal;font-size: 16px;font-weight: 100;">
                                                                    {{couponData.details}}.
                                                                </p>
                                                                <p *ngIf="couponData.details==''" style="font-weight: normal;font-size: 16px;font-weight: 100;">
                                                                    7 Days a week / Enter any time / Exit any time / {{couponData?.max_stay_details}}.
                                                                </p>
                                                            </td>
                                                            <td style="text-align: center;font-family: sans-serif;">
                                                                <h1  sourceFontstyle="font-size: 60px;font-weight: 300;margin-bottom: 0;">
                                                                    ${{couponData.split_price[0]}}
                                                                </h1>
                                                                <p style="margin-top: 0;font-weight: normal;">
                                                                    tax included
                                                                </p>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <table style="transform: scale(0.9);
                                      transform: translateX(0px);" width="100%" align="center" cellspacing="0" cellpadding="0" style="-webkit-print-color-adjust: exact;">
                                                    <tbody>
                                                        <tr>
                                                            <td width="50%" style="padding: 30px;vertical-align: top;padding-bottom: 0;">
                                                                <h4 style="font-size: 20px;font-weight: 500;color: #ed8222;margin: 15px 0;text-transform: uppercase;font-family: sans-serif;margin-top: 0; -webkit-print-color-adjust: exact;">
                                                                    <font color="#ed8222">Coupon Details</font>
                                                                </h4>
                                                                <p *ngIf="couponData.coupon_restrictions!=''" style="font-size: 14px;font-weight: 500;margin: 15px 0;text-transform: uppercase;font-family: sans-serif;">
                                                                    {{couponData.coupon_restrictions}}
                                                                </p>

                                                                <p style="font-size: 14px;font-weight: 500;margin: 15px 0;font-family: sans-serif;">
                                                                    {{couponData?.used_text}}
                                                                </p>
                                                                <p *ngIf="couponData?.expire_on" style="font-size: 14px;font-weight: 500;margin: 15px 0;font-family: sans-serif;">
                                                                    Expires {{couponData?.expire_on}}
                                                                </p>
                                                                <div style="font-size: 14px;font-weight: 500;margin: 15px 0;font-family: sans-serif;line-height: 1.5;margin-top: 30px;border-top: 2px solid #eee;padding: 15px 0;">
                                                                    <div class="clearfix"></div>
                                                                    <p>
                                                                        {{couponData?.disclaimer}}
                                                                    </p>
                                                                </div>
                                                            </td>
                                                            <td width="50%" style="background: #ececec;vertical-align: top;-webkit-print-color-adjust: exact;">
                                                                <div style="padding: 30px;padding-bottom: 0; -webkit-print-color-adjust: exact;">
                                                                    <h4 class="" style="font-size: 20px;font-weight: 500;color: #ed8222;margin: 15px 0;text-transform: uppercase;font-family: sans-serif;padding: 0;margin-top: 0; -webkit-print-color-adjust: exact;">
                                                                        <font color="#ed8222">Facility Details</font>
                                                                    </h4>
                                                                    <h3 style="font-family: sans-serif;text-decoration: none;text-transform: uppercase;font-weight: 500;margin: 0;padding: 0;font-size: 24px;">
                                                                        {{ array_data?.facility?.facility_name_modified}}
                                                                    </h3>
                                                                    <p *ngIf="array_data?.facility?.phone_number">
                                                                        <fa name="phone"></fa> &nbsp;
                                                                        <a style="color: #000;font-size: 14px;font-weight: 500;margin: 15px 0;font-family: sans-serif;text-decoration: none;cursor: pointer;" href="tel:{{array_data.facility.phone_number}}">
                                                                            {{formatPhoneNumber(array_data?.facility?.phone_number)}}
                                                                        </a>
                                                                    </p>
                                                                    <p style="font-size: 14px;font-weight: 500;margin: 15px 0;font-family: sans-serif;" >
                                                                        <b>Between:</b> {{ array_data?.facility?.between_streets }}
                                                                    </p>
                                                                    <p style="font-size: 14px;font-weight: 500;margin: 15px 0;font-family: sans-serif;" >
                                                                        <b>Entrance</b> {{array_data?.facility?.entrance_location}}
                                                                    </p>
                                                                    <p style="font-size: 14px;font-weight: 500;margin: 15px 0;font-family: sans-serif;">
                                                                       <a (click)="getdirections.show()" style="font-size: 14px;font-weight: 500;margin: 15px 0;font-family: sans-serif;color: #ed8222;cursor: pointer; -webkit-print-color-adjust: exact;">
                                                                            <font color="#ed8222">Get Directions &nbsp;&nbsp;<i class=" fa fa-chevron-right"></i></font>
                                                                        </a>
                                                                    </p>
                                                                    <p *ngIf="array_data.facility?.hours_of_operation?.length == 0" style="font-size: 14px;font-weight: 500;margin: 15px 0;font-family: sans-serif;">
                                                                        Open: 7 Days a week, 24 hours a day
                                                                    </p>
                                                                </div>
                                                                <div style="padding: 0 30px 10px 30px; -webkit-print-color-adjust: exact;" *ngIf="array_data.facility?.hours_of_operation?.length > 0">
                                                                    <h4>
                                                                        <font face="sans-serif">Hours of Operation</font>
                                                                    </h4>
                                                                    <table cellspacing="0" cellpadding="0" width="100%" style="font-family: sans-serif;font-size: 10px;vertical-align: top;">
                                                                        <tbody>
                                                                            <tr *ngFor="let wh of array_data.facility?.hours_of_operation;">
                                                                                <td style="width: 20%;vertical-align: top;">
                                                                                    <font face="sans-serif">{{wh.day}}</font>
                                                                                </td>
                                                                                <td style="width: 10%;vertical-align: top;">
                                                                                    <font face="sans-serif">:</font>
                                                                                </td>
                                                                                <td style="width: 20%;vertical-align: top;">
                                                                                    <font face="sans-serif">{{wh.formatted_open_time}}</font>
                                                                                </td>
                                                                                <td style="width: 10%;vertical-align: top;">
                                                                                    <font face="sans-serif">-</font>-</td>
                                                                                <td style="width: 40%; vertical-align: top;">
                                                                                    <font face="sans-serif">{{wh.formatted_close_time}} {{showtimefornxtornot(wh.close_time)}}</font>
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>



                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <table style="transform: scale(0.9);
                                          transform: translateX(0px);" width="100%" align="center" cellspacing="0" cellpadding="0" style="-webkit-print-color-adjust: exact;">
                                                    <tbody>
                                                        <tr>
                                                            <td width="50%" style="padding: 30px;vertical-align: top;padding-bottom: 0;">
                                                            </td>
                                                            <td width="50%" style="background: #ececec;vertical-align: top;-webkit-print-color-adjust: exact;">
                                                                <div class="parkImg" style="-webkit-print-color-adjust: exact;">
                                                                    <img class="" [src]="imgSrcLocation" style="width: 100%; -webkit-print-color-adjust: exact;" [alt]="imgSrcAlt">
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <!-- Indicators & controles -->
                            <div class="controles mar-top-sm">
                                <a class="left carousel-control" href="#couponCarousel" data-slide="prev">
                                    <img [src]="leftNav" alt="image">
                                    <span class="sr-only">Previous</span>
                                </a>
                                <ol class="carousel-indicators">
                                    <li data-target="#couponCarousel" [attr.data-slide-to]="couponIndexSlider" class="test" [class.active]="couponIndexSlider=='0'" *ngFor="let couponDataSlider of array_data.coupons; let couponIndexSlider = index" sourceFont>${{couponDataSlider.split_price[0]}}</li>
                                    <!-- <li data-target="#couponCarousel" data-slide-to="1">$39</li>
                        <li data-target="#couponCarousel" data-slide-to="2">$60</li> -->


                                </ol>
                                <a class="right carousel-control" href="#couponCarousel" data-slide="next">
                                    <img [src]="rightNav" alt="image">
                                    <span class="sr-only">Next</span>
                                </a>

                            </div>
                        </div>

                    </div>


                </div>
            </div>
        </div>
    </div>
    <!-- Coupon Model Ends Here LATEST CODE-->
    <div class="full-pop-search" *ngIf="alreadyEnroll">
        <div class="pop-search-rewards">
            <div class="pop-search-head">
                <a href="javascript:void(0)" class="pull-right" (click)="close()">
                    <span style="font-size:40px; font-weight:100;">&times;</span>
                </a>
            </div>
            <div class="clearfix"></div>
            <div class="search-rewards-pop">
                <div class="img-rewards-pop"><img [src]="rewaredlogodark" /></div>
                <h2 class="mar-top-0 text-uppercase text-primary" *ngIf="iconMessageRandom==1">Your {{rewarded_points | number}} points bonus for free parking.</h2>
                <h2 class="mar-top-0 text-uppercase text-primary" *ngIf="iconMessageRandom==2">Don’t miss out - get free parking.</h2>
                <table>
                    <tr>
                        <td><img [src]="trophy" alt="image" /></td>
                        <td>Get 10 points for every dollar you spend on daily parking.</td>
                    </tr>
                    <tr>
                        <td><img [src]="box" alt="image" /></td>
                        <td>Redeem your points for free daily parking at Icon or Quik Park.</td>
                    </tr>
                </table>
                <h4 class="mar-top-xs text-uppercase">For a limited time you can get an extra <span class="text-primary">{{rewarded_points | number}} points</span> when enrolling.</h4>
                <div class="mar-top-xs btn-center-xs">
                    <a class="btn btn-primary btn-fill" *ngIf="iconMessageRandom==1 && alreadyEnroll" (click)="redirectToLoyal('Your 1,000 points(search)');">ENROLL NOW</a>
                    <a class="btn btn-primary btn-fill" *ngIf="iconMessageRandom==2 && alreadyEnroll" (click)="redirectToLoyal('Don’t miss out(search)');">ENROLL NOW</a>
                    <span class="mar-left-xs"></span>
                    <a *ngIf="iconMessageRandom==1" class="btn theme-btn btn-primary" (click)="gtmTrackingEventMoreBtnSearchReadMore('Your 1,000 points bonus(search)')">Read More</a>
                    <a *ngIf="iconMessageRandom==2" class="btn theme-btn btn-primary" (click)="gtmTrackingEventMoreBtnSearchReadMore('Don’t miss out(search)')">Read More</a>
                </div>
            </div>
        </div>
        <div class="clearfix"></div>
        <div class="search-rewards-pop">
            <div class="img-rewards-pop">
                <img [src]="rewaredlogodark" alt="image" />
            </div>
            <h2 class="mar-top-0 text-uppercase text-primary" *ngIf="iconMessageRandom==1">Your {{rewarded_points | number}} points bonus for free parking.
            </h2>
            <h2 class="mar-top-0 text-uppercase text-primary" *ngIf="iconMessageRandom==2">Don’t miss out - get free parking.</h2>
            <table>
                <tr>
                    <td>
                        <img [src]="trophy" alt="image" />
                    </td>
                    <td>Get 10 points for every dollar you spend on daily parking.</td>
                </tr>
                <tr>
                    <td>
                        <img [src]="box" alt="image" />
                    </td>
                    <td>Redeem your points for free daily parking at Icon or Quik Park.</td>
                </tr>
            </table>
            <h4 class="mar-top-xs text-uppercase">For a limited time you can get an extra
                <span class="text-primary">{{rewarded_points | number}} points</span> when enrolling.</h4>
            <div class="mar-top-xs btn-center-xs">
                <a class="btn btn-primary btn-fill" *ngIf="iconMessageRandom==1 && alreadyEnroll" (click)="redirectToLoyal('Your 1,000 points(search)');">ENROLL
          NOW</a>
                <a class="btn btn-primary btn-fill" *ngIf="iconMessageRandom==2 && alreadyEnroll" (click)="redirectToLoyal('Don’t miss out(search)');">ENROLL
          NOW</a>
                <span class="mar-left-xs"></span>
                <a *ngIf="iconMessageRandom==1" class="btn theme-btn btn-primary" (click)="gtmTrackingEventMoreBtnSearchReadMore('Your 1,000 points bonus(search)')">Read
          More</a>
                <a *ngIf="iconMessageRandom==2" class="btn theme-btn btn-primary" (click)="gtmTrackingEventMoreBtnSearchReadMore('Don’t miss out(search)')">Read
          More</a>
            </div>
        </div>
    </div>
    <!-- <h3 (click)="close()" class="mar-top-xs mar-bottom-xs text-uppercase text-white text-center" style="font-weight:300; font-size: 16px; cursor: pointer;">No
    thanks, Not Right Now.</h3> -->
</div>

<!-- Coupon Model Ends Here -->
<ng-template #monthlyRateDesc>
    <div class="modal-header" style="margin-top:-10px;">
        <h4 class="modal-title pull-left text-primary"></h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true" style="font-size: 50px; font-weight: 400;">&times;</span>
    </button>
    </div>
    <div class="modal-body" style="padding: 10px 20px">
        <h3 class="text-uppercase text-primary">MONTHLY RATES</h3>
        <p>Our rates vary by vehicle type and what service you require.</p>
        <h5 class="mar-bottom-xs">ACCESS HOURS</h5>
        <!-- <div *ngIf="FgarageWorkingHours?.length <= 0">
        <p>
            This facility is open 24 hours a day, 7 days a week. For access hours per rate
            tier, see the ‘Buy Monthly’ page.
        </p>
     </div> -->
        <div *ngIf="FulldayWrking == 1">
            <p>
                This facility is open 24 hours a day, 7 days a week. For access hours per rate tier, see the ‘Buy Monthly’ page.
            </p>
        </div>
        <div *ngIf="FgarageWorkingHours?.length > 0 && FulldayWrking == 0">
            <p>
                This facility is open
                <span class="pHours" *ngFor="let wh of FgarageWorkingHours; let Hoursindex of index">
          {{wh.day}}: {{wh.formatted_open_time}} - {{wh.formatted_close_time}}
          <span class="comma">,</span>
                </span>For access hours per rate tier, see the ‘Buy Monthly’.
            </p>
        </div>
        <h5 class="mar-bottom-xs">VEHICLE CLASSIFICATIONS</h5>
        <p>
            Oversized vehicles include SUV’s, Trucks, and similar. You can find more info and a list of vehicles
            <a href="javascript:;" (click)="redirecttoList()">here</a>.
        </p>
        <p> Exotic vehicles are considered luxury/high-performance/ sports cars.</p>
        <p>
            For assistance with your vehicle, contact us at (888) 705-7845 from 9 AM to 7 PM EST
        </p>
        <div class="btn-center-xs mar-top-sm mar-bottom-xs">
            <a class="btn btn-primary theme-btn custom-popup-close" href="javascript:;" (click)="modalRef.hide()">Got it</a>
        </div>
        <div class="mar-bottom-sm visible-xs"></div>
    </div>
</ng-template>


<ng-template #rateEngine>
    <div class="modal-header" style="z-index: 1; position: relative;">
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <img  (click)="back(1)" [src]="crossSvg" alt="image" width="20" style="margin-top: 10px;">
    </button>
    </div>
    <div class="modal-body" style="z-index: 0;">
        <h4 _ngcontent-c7="" class="mar-bottom-sm modal-title text-uppercase text-primary" style="font-size: 25px;margin-top: -30px;">how to secure your spot</h4>
        <p>If your dates and times are flexible, you can save a offer for future use. However, if the garage is full, spots may not be available. If you need a guaranteed spot, always book a reservation.</p>
        <button type="button" class="btn btn-center-xs  btn-primary theme-btn mar-top-xs" aria-label="Close" (click)="modalRef.hide()">Got
      it</button>
    </div>
</ng-template>

<ng-template #postedfacilityrats>
    <div class="modal-header" style="z-index: 1; position: relative;">
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <img [src]="crossSvg" alt="image" width="20" style="margin-top: 10px;">
    </button>
    </div>
    <div class="modal-body postedfacility-th-bg">
        <div class="clearfix posted-facilty-box">
            <h2 class="text-uppercase">{{facilitynameforpostal}}</h2>
            <h4 class="text-uppercase">{{facilitynameforpostaladdress}}</h4>
            <h3 class="text-uppercase text-primary">Posted Facility Rates</h3>
            <p class="hidden-xs">All rates shown below include a {{taxRate}}% parking tax.</p>

            <table class="table tbl-border" *ngFor="let rate of boardRate;">
                <thead>
                    <tr>
                        <th colspan="2">{{rate.name}}</th>
                        <th>Total</th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container *ngFor="let rates of rate.rates;">
                        <tr *ngIf="rates.category.category==rate.name">
                            <td>{{rates.description=='' ? '7 Days a week / Enter any time / Exit any time' : rates.description}}</td>
                            <td>{{rates.details=='' ? '7 Days a week / Enter any time / Exit any time' : rates.details}}</td>
                            <td sourceFont>${{rates.price=='' ? '7 Days a week / Enter any time / Exit any time' : rates.price}}</td>
                        </tr>
                    </ng-container>

                </tbody>
            </table>
            <table class="table" *ngIf="FacilityAvailableFetatures?.facility?.oversize_fee && this.showOversizedMsg==false">
                <thead>
                    <tr>
                        <th colspan="2">Additional Fees</th>
                        <th>Total</th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container>
                        <tr>
                            <td colspan="2">Oversized vehicle fee</td>
                            <td sourceFont>${{FacilityAvailableFetatures.facility.oversize_fee}}</td>
                        </tr>
                    </ng-container>

                </tbody>
            </table>
        </div>
    </div>
</ng-template>
<!-- Directions Model Starts From Here -->

<a id="ModalovernightPopup" #myDiv (click)="lgModalovernightPopup.show()" class="hide"></a>
<div bsModal #lgModalovernightPopup="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">

    <div class="modal-dialog modal-md">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" #bookerrormsgpopupclose class="close" aria-label="Close" (click)="lgModalovernightPopup.hide()">
          <fa name="times"></fa>
        </button>
            </div>

            <div class="modal-body">
                <h4 _ngcontent-c7="" class="modal-title mar-top-sm text-primary" style="font-size: 16px;">
                    {{isPpopupShowMessage}}
                </h4>
                <br>
                <div *ngIf="!loadWorkingHours">Please wait..</div>
                <div *ngIf="loadWorkingHours">
                    <div class="TimeActive table-responsive" *ngIf="garageWorkingHours?.length > 0">
                        <h4 class="text-uppercase">Hours of Operation</h4>

                        <table class="table table-condensed ">
                            <tr *ngFor="let wh of garageWorkingHours;">
                                <td>{{wh.day}}</td>
                                <td>:</td>
                                <td width="90px">{{wh.formatted_open_time}}</td>
                                <td>-</td>
                                <td>{{wh.formatted_close_time}}</td>
                            </tr>
                        </table>
                    </div>
                </div>
                <button type="button" *ngIf="bydetails" class="btn btn-center-xs  btn-primary theme-btn mar-top-xs active" aria-label="Close" (click)="redirectTobuyNowFromPopup()">Yes</button>
                <button type="button" *ngIf="bylisting" class="btn btn-center-xs  btn-primary theme-btn mar-top-xs active" aria-label="Close" (click)="redirectTobuyNowFromPopuplisting()">Yes</button>
                <span class="hidden-xs" style="margin-left: 5px;"></span>
                <button type="button" *ngIf="bydetails" class="btn btn-center-xs  btn-primary theme-btn mar-top-xs" aria-label="Close" (click)="backToFacilities()">No</button>
                <button type="button" *ngIf="bylisting" class="btn btn-center-xs  btn-primary theme-btn mar-top-xs" aria-label="Close" (click)="backToFacilitieslistPop()">No</button>
            </div>
        </div>
    </div>
</div>

<!---  #Term page    -->
<ng-template class="modal fade" #AcceptTerms id="AcceptTerms">
    <div class="modal-header">
      <button type="button" class="close pull-right" aria-label="Close" (click)="closeModel()">
        <span style="font-size: 45px; font-weight: normal;" aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body" style="padding-top:0px;">
      <app-lazy-load [isLoaded]="isLoaded"></app-lazy-load>
      <div *ngIf="isLoaded">
        <h2 class="text-uppercase mar-top-0 mar-bottom-xs pad-bottom-xs text-primary">ENROLL IN ICON REWARDS</h2>
        <p class="pad-bottom-xs mar-bottom-xs">{{cmsPageContent.post_title}}</p>
        <div class="icon-rewards-terms mar-top-xs">
          <div [innerHTML]="cmsPageContent.post_content"></div>
        </div>
      </div>
      <div class="form-group mar-top-sm mar-bottom-0 btn-center-xs">
        <button type="button" class="btn btn-primary btn-fill" (click)="confirm()">I AGREE</button>
        <span class="mar-left-xs"></span>
        <button type="button" class="btn btn-primary theme-btn" (click)="decline()">CANCEL</button>
      </div>
    </div>
  </ng-template>

  <ng-template #mobileData1 > 
       <div [innerHtml]='popUP'></div>
</ng-template>
<ng-template #parksafeData > 
        <!-- <div [innerHtml]='popUP'></div> -->
        <svg width="100%"  viewBox="0 0 295 116" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="Group-3">
                    <g id="parksafe_img" transform="translate(122.000000, 0.000000)" fill-rule="nonzero" font-size="10.3846154">
                        <text id="Service" fill="#ED7400" font-family="SourceSansPro-Bold, Source Sans Pro" font-weight="bold">
                            <tspan x="0.780384615" y="10">Service</tspan>
                        </text>
                        <g id="Group" transform="translate(0.780385, 10.384615)" fill="#000000" font-family="SourceSansPro-Regular, Source Sans Pro" font-weight="normal">
                            <text id="Fully-contactless-mobile-reservations,">
                                <tspan x="0" y="10">Fully contactless mobile reservations,</tspan>
                            </text>
                            <text id="payments,-check-in-&amp;-check-out">
                                <tspan x="0" y="20.3846154">payments, check-in &amp; check-out</tspan>
                            </text>
                        </g>
                        <text id="Sanitize" fill="#ED7400" font-family="SourceSansPro-Bold, Source Sans Pro" font-weight="bold">
                            <tspan x="0.780384615" y="51.5384615">Sanitize</tspan>
                        </text>
                        <g id="Group" transform="translate(0.780385, 51.923077)" fill="#000000" font-family="SourceSansPro-Regular, Source Sans Pro" font-weight="normal">
                            <text id="Designated-Viking-Pure-natural-cleaning">
                                <tspan x="0" y="10">Designated Viking Pure natural cleaning</tspan>
                            </text>
                            <text id="stations-at-every-location">
                                <tspan x="0" y="20.3846154">stations at every location</tspan>
                            </text>
                        </g>
                        <text id="Safety" fill="#ED7400" font-family="SourceSansPro-Bold, Source Sans Pro" font-weight="bold">
                            <tspan x="0.780384615" y="93.0769231">Safety</tspan>
                        </text>
                        <g id="Group" transform="translate(0.780385, 93.461538)" fill="#000000" font-family="SourceSansPro-Regular, Source Sans Pro" font-weight="normal">
                            <text id="Trained-team-members-with-gloves">
                                <tspan x="0" y="10">Trained team members with </tspan>
                            </text>
                            <text id="masks,-all-practicing-social-distancing">
                                <tspan x="0" y="20.3846154">masks & all practicing social distancing</tspan>
                            </text>
                        </g>
                    </g>
                    <image id="Group-610-image" x="0" y="-10" width="110" height="145" xlink:href="../../../../assets/img/v8-design/parksafe_logo_only.svg"></image>
                </g>
            </g>
        </svg>
  </ng-template>
