import { Renderer2,Component, OnInit,Directive } from '@angular/core';
import { WebMetaService } from '../shared/web-meta.service';
import { Router } from '@angular/router';
import { Meta } from '@angular/platform-browser';
@Component({
  selector: 'app-winningticket',
  templateUrl: './winningticket.component.html',
  styleUrls: ['./winningticket.component.scss']
})

export class WinningticketComponent implements OnInit {
  ogDescription:any;
  ogTitle:any;
  ogUrl:any;
  webdata:any;
  descriptionElement:any;
  metaTags: any;
  robotselement:any;
  path = window.location.pathname;
  constructor(private router:Router,  private metaService: Meta,private webMetaServiceObj: WebMetaService) { }
  myScriptElement: HTMLScriptElement;
  ngOnInit() {
    this.metaTags = Array.from(document.getElementsByTagName('meta'));
    this.descriptionElement = this.getMetaElement('description');
    this.ogDescription = this.getMetaElement1('og:description');
    this.ogTitle = this.getMetaElement1('og:title');
    this.ogUrl = this.getMetaElement1('og:url');
    this.robotselement = this.getMetaElement('robots');
    this.updatePageMeta();
  }
  ngAfterViewInit(){
    this.myScriptElement = document.createElement("script");
    this.myScriptElement.src = "https://fancompass.iconparkingsystems.com/embed-script";
    document.body.appendChild(this.myScriptElement);
    
    

  }
  getMetaElement(name){
    return this.metaTags.find((element) => { return element.getAttribute('name') === name; });
  }
  getMetaElement1(name){
    return this.metaTags.find((element) => { return element.getAttribute('property') === name; });
  }
  updatePageMeta() {
    this.webdata = this.webMetaServiceObj.getSlugMeta(this.path).then((data:any)=> {
      document.title = data['data']['meta']['title'];
      this.descriptionElement.setAttribute('content', data['data']['meta']['description']);
      this.ogTitle.setAttribute('content', data['data']['meta']['ogTitle']);
      this.ogDescription.setAttribute('content', data['data']['meta']['ogDescription']);
      this.ogUrl.setAttribute('content', window.location.href)
      this.metaService.addTag({ name: 'robots', content: 'noindex' });
      this.robotselement.setAttribute('content', 'noindex');
    });
  }
 

}
