<app-header></app-header>
<app-lazy-load [isLoaded]="isLoaded" [sectionName]></app-lazy-load>
<div *ngIf="isLoaded" class="confirmation-details" id="confirmation-details-web">
<div class="payment-section orangeBar renConfirm " style="min-height: 0vh !important;">
<div *ngIf="isLoyaltyMember" class="hidden-xs 1" style="margin-top:60px"></div>
<div *ngIf="isLoyaltyMember" class="visible-xs 3" style="margin-top:60px"></div>
<div *ngIf="!isresponsive" class="bg-dark hidden-xs"  style="background-color: black;margin-top: -59px;" ><img [src]="buymonthlybannerimage" style="width:50%;"></div>

<div *isIphone [class]='margin_height' ><img [src]="buymonthlybannerimage" style="width:100%;"></div>
<div *isAndroidMobile [class]='margin_height' ><img [src]="buymonthlybannerimage" style="width:100%;"></div>

<div class="container">
  <div class="top-tab-in-payment">
      <div class="wizard">
          <!-- <div class="wizard-inner">
              <div class="connecting-line"></div>
              <div class="first-line-success"></div>
              <ul class="nav nav-tabs" role="tablist">

                  <li role="presentation" class="active1">
                      <a role="tab">
                          <span class="round-tab">
                              <i class="fa fa-chevron-left"></i>
                          </span>
                          <b>Search Results</b>
                      </a>
                  </li>

                  <li role="presentation" class="active1">
                      <a role="tab">
                          <span class="round-tab">
                              <i class="fa fa-check"></i>
                          </span>
                          <b style="left: -44px;">Payment & Checkout</b>
                      </a>
                  </li>
                  <li role="presentation" class="active1 active">
                      <a>
                          <span class="round-tab">
                              <i class="fa fa-check"></i>
                          </span>
                          <b>Booking Confirmed</b>
                      </a>
                  </li>
              </ul>
          </div> -->

          </div>
  </div>
</div></div></div>
<div *ngIf="isLoaded" id="confirmation-details-web" class="confirmation-details printData" [innerHTML]="cmsData | safeHtml"></div>

<app-footer></app-footer>



