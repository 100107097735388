<div role="tabpanel" class="tab-pane fade active in" id="autopay" *ngIf="isLoaded">
  <h4 class="text-primary mar-top-0">MANAGE AUTOPAY</h4>
<!--   <p>FIRST PAYMENT METHOD : {{ selectedPaymentProfile }}</p>
  <p>SECOND PAYMENT METHOD : {{ secondSelectedPaymentProfile }}</p> -->
  <div *ngIf="autopay=='0' || showUpdateOptions === true">
    <div class="error" *ngIf="autopay=='0' || showUpdateOptions === false">
      AutoPay is currently inactive for this account
    </div>
    <h4 class="mar-top-0 mar-top-sm" *ngIf="showUpdateOptions === true">Update Your Autopay Preferences</h4>
    <div class="clearfix mar-top-sm">
      <p>Choose one or more payment methods.</p>
      <div class="hidden-xs mar-bottom-xs">
        <button type="button" class="btn btn-primary theme-btn" *ngIf="showAddCreditCardBtn" (click)="showAddCreditCard($event)">add credit card</button> &nbsp; &nbsp;<span *ngIf="showAddCreditCardBtn" class="mar-right-xs"></span><button type="button" class="btn btn-primary theme-btn" *ngIf="showAddBankAccountBtn" (click)="showAddBankAccount($event)">add bank account</button>
      </div>
      <div class="row visible-xs">
        <div class="col-xs-10 col-xs-offset-1">
          <button type="button" class="btn btn-primary w-100 theme-btn mb-2" *ngIf="showAddCreditCardBtn" (click)="showAddCreditCard($event)">add credit card</button>
          <button type="button" class="btn btn-primary w-100 theme-btn" *ngIf="showAddBankAccountBtn" (click)="showAddBankAccount($event)">add bank account</button><div *ngIf="showAddBankAccountBtn" class="mar-bottom-xs"></div>
        </div>
      </div>
      <div *ngIf="addingPaymentMethods" class="mar-top-xs">
        <div class="add-credit-card" *ngIf="addCreditCard">
          <app-add-credit-card [currentActive]="currentActive" [accountNumber]="accountNumber" (cancelEvent)="receiveCancel($event)" (responseEvent)="afterAdd($event)"></app-add-credit-card>
        </div>
        <div class="add-bank-account" *ngIf="addBankAccount">
          <app-add-bank-account [currentActive]="currentActive" [accountNumber]="accountNumber" (cancelEvent)="receiveCancel($event)" (responseEvent)="afterAdd($event)"></app-add-bank-account>
        </div>
      </div>
      <div *ngIf="showPaymentMethods">
        <h4 class="text-primary mar-top-sm">Payment Method One</h4>
        <p class="currentCarD" *ngFor="let paymentMethod of paymentMethods?.payments">
          <span *ngIf="paymentMethod?.card !== null && paymentMethod?.payment_profile_id !== secondSelectedPaymentProfile">
            <div class="radio">
            <label >
            <div class="row align-items-center">
            <div class="col-auto pr-2">
            <input  type="radio" class="radio mar-top-8" name="selectedMethod" id="{{paymentMethod?.payment_profile_id.replace('::','')}}" value="{{ paymentMethod?.payment_profile_id }}" (change)="updatePaymentMethod(paymentMethod?.payment_profile_id)" />
            </div>
            <div class="col-auto pl-0 pr-0">
            <fa name="credit-card" size="2x"></fa>
            </div>
            <div class="col">Card Ending In ({{ paymentMethod?.card?.card_number}}) for {{ displayNameOnCard(paymentMethod?.address?.first_name) }} {{ displayNameOnCard(paymentMethod?.address?.last_name) }}, Expires on 
              <span *ngIf="paymentMethod?.expiration_month && paymentMethod?.expiration_year">
                {{ paymentMethod?.expiration_month }}/{{ paymentMethod?.expiration_year }} 
              </span>
              <span *ngIf="!paymentMethod?.expiration_month && !paymentMethod?.expiration_year">
                --/--
              </span>
              </div>
            </div>
               
            </label>
          </div>
          </span>
          <span *ngIf="paymentMethod?.bank !== null && paymentMethod?.payment_profile_id !== secondSelectedPaymentProfile">
            <div class="radio">
             <label class=" ">
              <div class="row align-items-center">
                <div class="col-auto pr-2">
            <input  type="radio" class="radio mar-top-8" name="selectedMethod" id="{{paymentMethod?.payment_profile_id.replace('::','')}}" value="{{ paymentMethod?.payment_profile_id }}" (change)="updatePaymentMethod(paymentMethod?.payment_profile_id)" />
                </div>
                <div class="col-auto pl-0 pr-0">
                <fa name="bank" size="2x"></fa> 
                </div>
                <div class="col">Bank Ending In ({{ paymentMethod?.bank?.bank_account}}) for {{ displayNameOnCard(paymentMethod?.address?.first_name) }} {{ displayNameOnCard(paymentMethod?.address?.last_name) }} 
                 </div>
                </div>
            </label>
            </div>
          </span>

        </p>
        <div *ngIf="showSecondaryOptions === true" class="mar-top-sm mar-bottom-sm">
          <h5>Maximum amount to charge on this payment method</h5>
          <div class="row">
            <div class="col-sm-6 col-md-4 col-lg-3 floating-label">
              <div class="input-group input-group-sm">
                  <span class="input-group-addon bgTransparent"><fa name="dollar"></fa></span>
                  <input class="form-control" type="text" name="paymentOneLimit" id="paymentOneLimit" value="{{ paymentMethodOneLimit }}" (keypress)="setPaymentOneLimit($event)"/>
              </div>
            </div>
          </div>
        </div>
      </div>
      <p *ngIf="paymentMethods?.payments?.length > 0 && has_valet_manager == false">Add an additional payment method if you'd like to use more than one payment method for autopay. </p>
      <div *ngIf="paymentMethods?.payments?.length > 1 && has_valet_manager == false">
        <div class="checkbox">
          <label for="addDualPaymentMethod" class="  ">
            <input type="checkbox" name="addDualPaymentMethod" id="addDualPaymentMethod" (click)="secondaryPayment()" />
            <span>Add a Second Payment Method</span>
             
          </label>
        </div>
        <div *ngIf="showSecondaryOptions === true" class="mar-top-sm">
          <h4 class="text-primary">Payment Method Two</h4>
          <p>
            You may specify a maximum amount to be charged on payment method one. If the first payment method does not cover the entirety of the transaction, the remaining amount will be charged on your second payment method.
          </p>
          <p class="currentCarD" *ngFor="let paymentMethod of paymentMethods?.payments">
          <span *ngIf="paymentMethod?.card !== null && paymentMethod?.payment_profile_id !== selectedPaymentProfile">
            <div class="radio">
             <label class=" ">
              <div class="row align-items-center">
                <div class="col-auto pr-2">
            <input  type="radio" class="radio mar-top-8" name="selectedMethodSecond" id="second-{{paymentMethod?.payment_profile_id}}" value="{{ paymentMethod?.payment_profile_id }}" (change)="updatePaymentMethodSecondary(paymentMethod?.payment_profile_id)" />
            </div>
            <div class="col-auto pl-0 pr-0">
            <fa name="credit-card" size="2x"></fa> 
            </div>
            <div class="col">Card Ending In ({{ paymentMethod?.card?.card_number}}) for {{ displayNameOnCard(paymentMethod?.address?.first_name) }} {{ displayNameOnCard(paymentMethod?.address?.last_name) }}, Expires on 
              <span *ngIf="paymentMethod?.expiration_month && paymentMethod?.expiration_year">
                {{ paymentMethod?.expiration_month }}/{{ paymentMethod?.expiration_year }} 
              </span>
              <span *ngIf="!paymentMethod?.expiration_month && !paymentMethod?.expiration_year">
                --/--
              </span>
              <span class="inputBox"></span>
              </div>
            </div>
            </label>
          </div>
          </span>
          <span *ngIf="paymentMethod?.bank !== null && paymentMethod?.payment_profile_id !== selectedPaymentProfile">
            <div class="radio">
             <label class=" ">
              <div class="row align-items-center">
                <div class="col-auto pr-2">
            <input  type="radio" class="radio mar-top-8" name="selectedMethodSecond" id="second-{{paymentMethod?.payment_profile_id}}" value="{{ paymentMethod?.payment_profile_id }}" (change)="updatePaymentMethodSecondary(paymentMethod?.payment_profile_id)" />
                </div>
                <div class="col-auto pl-0 pr-0">
            <fa name="bank" size="2x"></fa> 
             </div>
             <div class="col">Bank Ending In ({{ paymentMethod?.bank?.bank_account}}) for {{ displayNameOnCard(paymentMethod?.address?.first_name) }} {{ displayNameOnCard(paymentMethod?.address?.last_name) }} 
              <span class="inputBox"></span>
              </div>
            </div>
            </label>
          </div>
          </span>
        </p>
        </div>
      </div>
    </div>
  </div>
  <p></p>
  <p></p>
  <div *ngIf="autopay=='1' && showUpdateOptions === false">
    <div class="success">
      AutoPay is currently active for this account
     </div>
     <div class="clearfix mar-top-sm">
      <h4 class="mar-top-0 mar-top-sm mar-bottom-xs">PAYMENTS</h4>
      <table class="table table-hover mar-bottom-sm">
       <thead>
        <tr>
         <th>Payment</th>
         <th>Pays</th>
        </tr>
       </thead>
       <tbody>
        <tr *ngFor="let autopayMethod of autopayMethods">
         <td> {{ findMethodDetail(autopayMethod) }} </td>
         <td> {{ findHowMuchPay(autopayMethod) }} </td>
        </tr>
       </tbody>
      </table>
     </div>
  </div>
  <div *ngIf="autopay=='1' && showUpdateOptions === false">
    <p>If you would like to make a payment for your current balance at this time, click the button below.</p>
    <a href="javascript:;" class="btn btn-primary btn-fill hidden-xs " (click)="makeOneTimePayment()">Make One Time Payment</a>
    <div class="row visible-xs">
      <div class="col-xs-offset-1 col-xs-10">
        <a href="javascript:;" class="btn btn-primary btn-fill btn-block " (click)="makeOneTimePayment()">Make One Time Payment</a>
      </div>
    </div>
    <p class="mar-top-sm">You may update your AutoPay preferences by clicking the button below.</p>
    <a href="javascript:;" class="btn btn-primary btn-fill hidden-xs " (click)="updateAutoPayPrefs()">Update autopay preferences</a>
    <div class="row visible-xs">
      <div class="col-xs-offset-1 col-xs-10">
        <a href="javascript:;" class="btn btn-primary btn-fill btn-block " (click)="updateAutoPayPrefs()">Update autopay preferences</a>
      </div>
    </div>
    <p *ngIf="showdeactivate" class="mar-top-sm">You may deactivate AutoPay by clicking the button below.</p>
    <button  *ngIf="showdeactivate" type="button" (click)="deactivateAutopay()" class="btn btn-primary btn-fill hidden-xs">Deactivate autopay</button>
    <div class="row visible-xs" *ngIf="showdeactivate">
      <div class="col-xs-offset-1 col-xs-10">
        <button type="button" (click)="deactivateAutopay()" class="btn btn-primary btn-fill btn-block">Deactivate autopay</button>
      </div>
    </div>
  </div>
  <div *ngIf="showUpdateOptions === true">
    <button type="button" (click)="updateAutoPayNow()" class="btn btn-primary btn-fill">Update autopay preferences</button><br>
  </div>
  <div *ngIf="autopay=='0'" class="mar-top-sm">
    <p *ngIf="paymentMethods?.payments?.length > 0" class="well">By submitting this transaction, you agree that {{ accountDetails?.facility?.facility_name_modified }} may debit this account for the amount of the transaction. In the event that an Electronic Funds Transfer (EFT) is returned unpaid, a service fee, as allowable by law, will be charged to this account.
    </p>
    <button type="button" (click)="activateAutopay()" *ngIf="paymentMethods?.payments?.length > 0" class="btn btn-primary theme-btn hidden-xs">Activate Autopay</button>
    <div class="row visible-xs">
      <div class="col-xs-offset-1 col-xs-10">
        <button type="button" (click)="activateAutopay()" *ngIf="paymentMethods?.payments?.length > 0" class="btn btn-primary theme-btn btn-block">Activate Autopay</button>
      </div>
    </div>
  </div>
  <!-- <div class="add-credit-card" *ngIf="addCreditCard">
    <app-add-credit-card [currentActive]="currentActive" [accountNumber]="accountNumber" (cancelEvent)="receiveCancel($event)" (responseEvent)="afterAdd($event)"></app-add-credit-card>
  </div>
  <div class="add-bank-account" *ngIf="addBankAccount">
    <app-add-bank-account [currentActive]="currentActive" [accountNumber]="accountNumber" (cancelEvent)="receiveCancel($event)" (responseEvent)="afterAdd($event)"></app-add-bank-account>
  </div> -->
</div>
<app-lazy-load [isLoaded]="isLoaded" [sectionName]="'auto pay information'"></app-lazy-load>