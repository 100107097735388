<app-header></app-header>

<div class="main mar-top-md commonPage orangeBar  mar-bottom-lg">
		
	<div class="container ">
    <div *ngIf="isLoyaltyMember" class="1" style="padding-top:0px"></div>
    <div *ngIf="!isLoyaltyMember" class=" 2" style="margin-top:50px"></div>
		<div class="mar-top-lg mar-bottom-sm text-center">
			<button (click)="printCoupon();" class="btn btn-primary btn-fill" type="submit">PRINT COUPON</button>
		</div>


		<div class="clearfix text-center" id="printableContent">
				
			<img alt="image" class="printable img-responsive full-width mar-lr-auto" style="max-width: 800px;" src="https://api.iconparkingsystems.com/globalcoupon/morgan-stanley/jpg">

		</div>
		
	</div>

</div>
<app-footer></app-footer>