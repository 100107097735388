<app-header></app-header>
<app-lazy-load [isLoaded]="isLoaded" [sectionName]></app-lazy-load>
<div *ngIf="isLoaded" class="mar-top-lg pad-top-md mar-bottom-md nonUserLogin">
  <div class="container">
    <div class="how-it-works pad-bottom-xs">
      <!-- <app-how-it-works></app-how-it-works> -->
    </div>
    <div class="page-content-wrapper commonPage orangeBar">
      <h1 class="text-uppercase mar-top-0 mar-bottom-0">
        <span>{{cmsPageContent.post_title}}</span>
      </h1>
      <h2 class="text-uppercase mar-bottom-xs text-primary">{{cmsPageContent.page_sub_title}}</h2>
      <div class="row">
        <div class="col-sm-8">
          <div [innerHTML]="cmsPageContent.post_content"></div>
          <div class="clearfix">
            <div *ngIf="!isLoyaltyMember" class="clearfix pad-top-sm pad-bottom-xs btn-center-xs">
              <a (click)="funCheckLoyalityMember()" class="btn btn-primary theme-btn btn-fill">ENROLL IN ICON REWARDS</a>
            </div>
            <div class="clearfix pad-top-xs pad-bottom-xs btn-center-xs">
              <a href="javascript:void(0)" (click)="moveToFaq()" class="text-uppercase btn-link">FREQUENTLY ASKED QUESTIONS &nbsp; &nbsp;
                <i class="fa fa-angle-right"></i>
              </a>
            </div>
          </div>
        </div>
        <div class="col-sm-4">
          <div class="well">
            <h4 class="mar-top-xs limited-time" [innerHtml]="cmsPageContent.reward_message"></h4>
          </div>
          <div class="clearfix pad-top-xs">
            <h4 class="mar-top-0">ALREADY A REWARDS CUSTOMER?</h4>
          </div>
          <div class=" clearfix mar-bottom-sm btn-center-xs pad-top-xs">
            <a *ngIf="isLoggedIn" routerLink="/my-account/account" class="btn btn-primary theme-btn">GO TO MY ACCOUNT</a>
            <a *ngIf="!isLoggedIn" data-toggle="modal" id="loginPopup" [href]="loginLink" class="btn btn-primary theme-btn" oncontextmenu="return false;">GO TO MY ACCOUNT</a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-icon-rewards-tabs></app-icon-rewards-tabs>
</div>
<app-footer></app-footer>
