<!-- app header -->
<app-header></app-header>
<!-- page content -->
<!--oversized vehicle  -->
<div class="container commonPage orangeBar   pad-bottom-md" [ngClass]="{'mar-top-lg pad-top-lg':!isFrame }" >
	<div class="commonPage orangeBar">		<ol class="breadcrumb pad-top-0 pad-bottom-0 pad-left-0" *ngIf="isFrame" >
				<li class="active"><a href="javascript:;" onclick="history.go(-1);" class="btn btn-link pad-left-0"><fa name="angle-left"></fa> &nbsp; Back</a></li>
			</ol>
<h2 class="text-uppercase text-primary mar-top-0 mar-bottom-sm">
		{{oversizesContent.post_title}}	</h2>
<div class="mar-top-sm accent content-wrapper" [innerHTML]="oversizesContent.post_content" ></div>

<div class="clearfix mar-top-sm">
	<app-lazy-load [isLoaded]="isLoaded" [sectionName]></app-lazy-load>
	<div class="row">
			
		<div class="col-xs-12 col-sm-8 col-md-8 col-lg-8">
	
			<div *ngIf="isLoaded" [innerHTML] ="oversizesContent.oversized_vehicles_table"></div>
		</div>
	</div>
	
</div>
</div></div>
<!-- app footer -->
<app-footer></app-footer>