<section class="loyalty">
    <div class="container">
        <div class="flex-row row ">
            <div class="col-sm-7">
                <img [src]="rewaredlogo" alt="image" class="img-responsive">

                <!-- <img [src]="rewaredlogo" alt="image" class="img-responsive hidden-xs">
                <img [src]="rewaredLogoMobile" alt="image" class="img-responsive visible-xs"> -->
                <h1 class="text-uppercase text-white mar-bottom-sm">RECEIVE IMMEDIATE DISCOUNTED DAILY RATES AS A REWARDS MEMBER!</h1>
                <div class="flexgrow">
                    <div class="cols">
                        <img height="55px" [src]="piggybank" alt="image">
                    </div>
                    <div class="cols">
                        <p>
                            Receive member discounts on most direct hourly or daily parking purchases.
                        </p>
                    </div>
                </div>
                <div class="flexgrow mar-bottom-xs">
                    <div class="cols">
                        <img [src]="credit" alt="image">
                    </div>
                    <div class="cols">
                        <p>
                            Always park for the lowest price available through Icon and Quik Park.
                        </p>
                    </div>
                </div>
                <div class="clearfix mar-top-sm mar-bottom-sm  customBtn ">
                    <a (click)="redirectToLoyal();" *ngIf="alredyEnroll" class="btn btn-primary btn-fill">
            Enroll Now
        </a>
                    <a (click)="redirectToReward();" class="btn bgWhite text-primary">
            Learn More
        </a>
                </div>
            </div>
            <div class="col-sm-5 align-self-end">
                <img [src]="phoneimg" alt="image" class="img-responsive phoneimg">
            </div>
        </div>
    </div>

</section>