<app-header [showTies]="false"></app-header>
	<!-- account section -->
	<div class="Account orangeBar">
			<!--<div class="account-nav clearfix text-uppercase" [style.top.px]="barHeight">-->
			<div class="account-nav clearfix text-uppercase"  [style.top.px]="barHeight">
			
				<div class="container">
					<ul class="nav nav-tabs nav-justified 222" [ngClass]="{'showMonthlyActive':shomyAccountActive}" *hideItBootstrap="['xs','sm']" >
						<li *ngFor="let menues of menuUser ; let i = index" routerLinkActive="active" id="menus{{i}}" [ngClass]="{'disableActive':menues.menuPath=='monthly-parking' && shomyAccountActive}">
							<!-- <a routerLink="{{ menues.menuPath }}">{{ menues.menuUrl }}</a> -->
							<a (click)="removeHeader();" *ngIf="menues.menuPath!='icon-rewards'" [ngClass]="{'showActive':menues.menuPath=='account' && shomyAccountActive}"  routerLink="{{ menues.menuPath }}">{{ menues.menuUrl }}</a>
							<a (click)="addMenuactive();" *ngIf="menues.menuPath=='icon-rewards';" routerLink="{{ menues.menuPath }}">{{ menues.menuUrl }}</a>
						</li>
					</ul>
					<div class="clearfix">
						<div class="dropdown  selectBox  visible-xs"  >
							<a class="btn btn-default btn-block dropdown-toggle linkLabel" type="button" data-toggle="dropdown">
								<div>{{menName}} </div><i aria-hidden="true" class="fa fa-angle-down"></i>
							</a>
							<ul style="width:70%;" class="catValue dropdown-menu">
								<li (click)="changeMenu('My Account');" routerLinkActive="active"><a (click)="removeHeader();" routerLink="/my-account/account" aria-hidden="true">My Account</a></li>
								<!-- <li (click)="changeMenu('Icon Rewards');">
									<a (click)="addMenuactive();" *ngIf="!isLoyaltyMember" routerLink="/rewards" aria-hidden="true">Icon Rewards</a>
									<a (click)="addMenuactive();" *ngIf="isLoyaltyMember" routerLink="/my-account/icon-rewards" aria-hidden="true">Icon Rewards</a>
								</li> -->
								<li (click)="changeMenu('My Monthly Parking');" routerLinkActive="active"><a (click)="removeHeader();" routerLink="/my-account/monthly-parking" aria-hidden="true">My Monthly Parking</a></li>
								<!-- <li (click)="changeMenu('My Offers');" routerLinkActive="active"><a (click)="removeHeader();" routerLink="/my-account/coupons" aria-hidden="true">My Offers</a></li> -->
								<li (click)="changeMenu('My Reservations');" routerLinkActive="active"><a (click)="removeHeader();" routerLink="/my-account/reservations" aria-hidden="true">My Reservations</a></li>
								<li (click)="changeMenu('Vehicle Request');" routerLinkActive="active"><a (click)="removeHeader();" routerLink="/my-account/vehicle-request" aria-hidden="true">Vehicle Request</a></li>
								<li (click)="changeMenu('Brand Partnerships');" routerLinkActive="active"><a (click)="removeHeader();" routerLink="/my-account/brand-partnership" aria-hidden="true">Brand Partnerships</a></li>
								<li (click)="changeMenu('My Promo Codes');" routerLinkActive="active"><a (click)="removeHeader();" routerLink="/my-account/promo-codes" aria-hidden="true">My Promo Codes</a></li>
								<li >
							<div class="btn-group btn-group-justified" role="group" aria-label="...">
					<a class="logoutBtn" *ngIf="loggedUser" (click)="logOut()">Sign out <fa name="sign-out"></fa></a>
					</div>        </li>
							</ul>
						</div>
					</div>
				</div>
				<app-loyality-badge (updateTierToHeader)='updateTier($event)'  [showTies]="true"></app-loyality-badge>
			</div>
		<div [ngClass]="isTierLoaded?'is-tiered-user':''" class="container pad-top-lg pad-bottom-lg">
			<div class="mar-top-sm">
				<router-outlet (activate)="showBadgeHome($event)"></router-outlet>
			</div>
		</div>
	</div>
<app-footer></app-footer>