<app-header *ngIf="!isPromoCodeExpired && isLoaded && is_save_page"></app-header>
<div *ngIf="htmltext && htmltext !=''" class="campaignPage commonPage orangeBar ">
	<div [innerHtml]="htmltext | safeHtml" *ngIf="!isPromoCodeExpired && isLoaded && is_save_page"></div>
</div>
<app-lazy-load *ngIf="isLoaded === false && is_save_page" [isLoaded]="isLoaded" [sectionName]="'campaign'"></app-lazy-load>
<app-lazy-load *ngIf="isLoaded === false && !is_save_page" [isLoaded]="isLoaded" [sectionName]="'the page'"></app-lazy-load>
<app-footer *ngIf="!isPromoCodeExpired && isLoaded && is_save_page"></app-footer>
<!--  for email coupons pages model -->

<!--  for email coupons pages model -->

<div class="modal fade" bsModal #staticModal="bs-modal" [config]="{backdrop: 'static'}"
	tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">
	<div class="modal-dialog modal-sm">
		<div class="modal-content">
			<div class="modal-header">
				<h4 class="modal-title pull-left" style="padding-left:15px;">Email Coupon</h4>
				<button type="button" class="close pull-right" (click)="onClickCancelEmailForm();" aria-label="Close">
					<span aria-hidden="true"><fa name="times"></fa></span>
				</button>
			</div>

			
			<div class="modal-body">

				<form name="form" role="form" id="floatForm" (ngSubmit)="cm.form.valid && sendCoupon(mailFormData, cm)" #cm="ngForm" novalidate>
					<div class="form-group mar-top-0">
						<input placeholder="Enter Your Email" class="form-control" type="mail" name="ucmail" [(ngModel)]="mailFormData.ucmail" #ucmail="ngModel"
							 required />
					<div *ngIf="cm.submitted && !ucmail.valid"class="help-block error-msg">Email is required</div>
					</div>
					<div class="form-group">
						<button class="btn btn-primary btn-block btn-fill" [ngClass]="{'btn-pink':isValineTine, 'btn-dark':!isValineTine }" type="submit">EMAIL COUPON</button>
					</div>
					<div class="form-group">
						<button class="btn btn-primary btn-block btn-fill" type="button" [ngClass]="{'btn-pink':isValineTine, 'btn-dark':!isValineTine }" (click)="onClickCancelEmailForm();" >CANCEL</button>
					</div>
				</form>
				
			</div>
		</div>
	</div>
</div>

<!--  for print coupons pages model -->

<div  *ngIf="!specialsCoupon" class="modal fade" bsModal #printModal="bs-modal" [config]="{backdrop: 'static'}"
	tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">
	<div class="modal-dialog">
		<div class="modal-content">
			<div class="modal-header">
				<button type="button" class="close pull-right" (click)="printModal.hide();cancelPrintPopUp();" aria-label="Close">
					<span aria-hidden="true"><fa name="times"></fa></span>
				</button>
			</div>
			<div class="modal-body">
				<div class="text-center mar-bottom-sm">
					<ng-container *ngIf="isValineTine">
						<button *ngIf="isLoadedPrint == true" class="btn btn-primary theme-btn btn-fill " [ngClass]="{'btn-pink':isValineTine, 'btn-dark':!isValineTine }" type="button" (click)="onClickEmail()">Email Coupon </button>&nbsp;
					</ng-container>
					<button *ngIf="isLoadedPrint == true" class="btn btn-primary theme-btn btn-fill "  [ngClass]="{'btn-pink':isValineTine, 'btn-dark':!isValineTine }" type="button" (click)="printMedia();printMediaGaEvent();">Print Coupon</button>&nbsp;
					<ng-container *ngIf="!isValineTine">
						<button *ngIf="isLoadedPrint == true" class="btn btn-primary theme-btn btn-fill" (click)="printModal.hide();cancelPrintPopUp();" type="button">Cancel</button>
					</ng-container>
					
				</div>
				<div class="panel-body"id="printableContent">
					<app-lazy-load *ngIf="isLoadedPrint === false" [isLoaded]="isLoadedPrint" [sectionName]="'the Coupon'"></app-lazy-load>
					<img  style="display:none;"class="img-responsive" [src]="sanitizer.bypassSecurityTrustResourceUrl(printData)"  id="invoicePrint" 
					class="printable img-responsive full-width" alt="Image"> 
				</div>
			</div>
		</div>
	</div>
</div>

