import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
// declare var ga: Function;

@Component({
  selector: 'app-march-madness',
  templateUrl: './march-madness.component.html',
  styleUrls: ['./march-madness.component.scss']
})
export class MarchMadnessComponent implements OnInit {

	@Input() promoCode: string;

	@Output() saveEvent = new EventEmitter<boolean>();
	gameday_parking_desktop = '../assets/img/campaign/march-madness/gameday-parking-desktop.jpg';
	gameday_parking_mobile ='../assets/img/campaign/march-madness/gameday-parking-mobile.jpg'

	constructor() { }

	ngOnInit() {
	}

	savePromocode(location) {
		/* commented for ga 16-6-2021 by seo team
		ga('send', 'event', { eventCategory: 'BALL18', eventAction: 'click'});
		*/
		(<any>window).dataLayer.push(
         {
            'promoCode': 'BALL18',
            'event': 'Promocode Save',
         });
		this.saveEvent.emit(location);
	}

}
