<div class="intro-wrapper">
	<div class="intro text-left">
		<div class="container text-uppercase">
			<h1 class="text-primary text-left mar-top-0">
			Introducing the icon go app.<br/>
			Book, Pay, and play.
			</h1>
			<h3 class="text-left">
			Download Now and get 20% off on your first reservation*
			</h3>
			<div class="coupon-wrap mar-top-md mar-bottom-md">
				<span class="mar-top-0 mar-bottom-xs h3 text-primary">PROMO CODE:&nbsp;&nbsp;</span>
				<span class="mar-top-0 mar-bottom-xs h3">{{ promoCode }}</span><span class="hidden-xs">&nbsp;&nbsp;&nbsp;&nbsp;</span>
				<div class="visible-xs clearfix"></div>
				<button type="button" class="btn theme-btn btn-primary text-white" (click)="savePromocode('times-square')">SAVE CODE</button>
			</div>
			<div class="row">
				<div class="appLink-wrapper">
					<div class="col-xs-12 hidden-xs col-sm-6 col-md-6 col-lg-6">
						<p>
							Enter your phone number below and we will send you a link to download icon go.
						</p>
						<div class="input-group">
							<input type="text" class="form-control text-white" name="sendapp" id="sendapp" placeholder="Enter phone number">
							<span class="input-group-btn pad-left-xs">
								<button type="button" class="btn theme-btn btn-primary text-white" (click)="sendApp()">Send Link</button>
							</span>
						</div>
					</div>
					<div class="col-xs-12 col-sm-6 col-md-6 col-lg-6 text-center">
						<div class="hidden-xs">
							<img alt="icon" [src]="app_icon" width="47px" height="47px" class="mar-right-xs">
							<a [href]="appleAppUrl" class="mar-right-xs">
								<img class="mar-top-xs" alt="" [src]="appstoreIcon" alt="image" height="37px">
							</a>
							<a [href]="googlePlayAppUrl">
								<img class="mar-top-xs" alt="image" [src]="googlePlayIcon" height="37px">
							</a>
						</div>
						<div class="row visible-xs">
							<div class="col-xs-12 mar-bottom-xs"><img alt="icon" alt="image" [src]="app_icon" width="50px" height="50px"></div>
							
						</div>
						<div class="row visible-xs">
							<div class="col-xs-6 text-right">
								<a [href]="appleAppUrl" class="btn btn-primary btn-sm theme-btn">
									<img alt="image" [src]="appstoreIcon" height="40px">
								</a>
							</div>
							<div class="col-xs-6 text-left">
								<a [href]="googlePlayAppUrl">
									<img alt="image" [src]="googlePlayIcon" height="40px">
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="intro-wrapper-footer">
		<div class="container text-center">
			<p>*Promotion valid only for logged-in users.</p>
		</div>
	</div>
</div>
<div class="iconFeatures pad-bottom-md pad-top-md">
	<div class="container">
		<div class="row">
			<div class="col-xs-12 col-offset-1 col-sm-6 col-md-6 col-lg-6">
				<ul class="list-unstyled iconic-list mar-bottom-0 mar-top-0">
					<li>
						<img [src]="dollar_icon" alt="image"> <span>Unique coupons and promotions</span>
					</li>
					<li>
						<img [src]="discount_icon" alt="image"> <span>Direct booking discounts</span>
					</li>
				</ul>
				<p class="visible-xs pad-bottom-xs"></p>
			</div>
			<div class="col-xs-12 col-sm-6 col-md-6 col-lg-6 mar-bottom-xs">
				<ul class="list-unstyled iconic-list mar-bottom-0 mar-top-0">
					<li>
						<img  [src]="arrowsIcon" alt="image"> <span>Seamless check-in and check-out</span>
						<img [src]="arrowsIcon" alt="image"> <span>Seamless check-in and check-out</span>
					</li>
					<li>
						<img [src]="starIcon" alt="image"> <span>Exclusive services and features</span>
					</li>
				</ul>
			</div>
		</div>
		<div class="text-center">
			<a href="javascript:;" class="btn">Read More <fa name="angle-right"></fa></a>
		</div>
	</div>
</div>