import { Location } from '@angular/common';
import { Component, OnInit, ViewChild, TemplateRef, ElementRef, ViewEncapsulation } from '@angular/core';

import { Router, ActivatedRoute, Params } from '@angular/router';
import { NgForm } from '@angular/forms';
declare var google: any;
import { CustomCode } from '../shared/CustomCode';
import { FormValidationErrors } from '../shared/formValidationErrors';
import { SearchPageScript } from '../shared/searchPageScript';
import * as $ from 'jquery';
import { UserserviceService } from '../services/userservice.service';
import { ReservationService } from '../services/reservation.service';
import { SearchpageserviceService } from '../services/searchpageservice.service';
import { MyAccountMonthlyParkingService } from '../services/my-account-monthly-parking.service';
import { StaticpagesService } from '../services/staticpages.service';
import { Crypto } from '../classes/crypto';
import { FilterHelper } from '../classes/filter-helper';
import { SafeResourceUrl } from '@angular/platform-browser';
import { LogsService } from '../services/logs.service';
//import { Http } from '@angular/http';
import { ToastrService } from 'ngx-toastr';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Ng2DeviceService } from 'ng2-device-detector';
//import { timingSafeEqual } from 'crypto';
//declare var gtag: Function;
// declare var gtag_report_conversion : Function;
declare var ga: Function;

// declare var _vis_opt_revenue_conversion: any;
// declare var window:any;

//declare var kochava:any;

import { RoutesRecognized } from '@angular/router';
import { filter, pairwise } from 'rxjs/operators';
@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss'],
  providers: [UserserviceService, ReservationService, SearchpageserviceService, MyAccountMonthlyParkingService, Crypto, LogsService, StaticpagesService],
  encapsulation: ViewEncapsulation.None
})
export class PaymentComponent implements OnInit {
  checksubmit=false;
  phone_number:any;
  bonushourappliedstatus = false;
  flatapplyorginalprice = 0;
  flatrateappliedstatus = false;
  storedarivalprice = 0;
  notflatrate = true;
  firsttimepromocodeload = 0;
  nastatus = 0;
  selectedenddate: any
  entry_time_end: any;
  modalRef: BsModalRef;
  gtmUrl: SafeResourceUrl = window.location.pathname;
  formData: any = {};
  opt: boolean = false;
  loginData: any = {};
  respdata;
  parseData;
  showMsg_early_bird_flag = false;
  msg_early_bird_flag: any;
  facDetails: any = {};
  emailRegex = CustomCode.emailRegex;
  loggedUserName;
  facilityRadius = 2;
  walletBalance: any = 0;
  is_paying: boolean = false;
  starttimeForWidget: any
  endtimeForWidget: any
  starttimeforwidget: any
  endtimeforwidget: any
  facId;
  minDate: Date;
  maxDate: Date;
  amt;
  startDate;
  startTime;
  endDate;
  endTime;
  lengthOfStay;
  length_of_stay_text;
  fetchUserSavedCards;
  newPayment = true;
  paymentProfileToPay: any = {};
  selectedCard;
  newCard = true;
  activeUser;
  currDate = CustomCode.getDateForMapSearch();
  park_garageIcon = '/assets/img/park_garage.png';
  cardSecurityIcon = 'assets/img/cardSecurity.png';
  infoIcon = 'assets/img/info-icon.png';

  walletConfig: any = {};
  wallet: any = {};
  promocode: any = {};
  showSliderDescription: any = false
  showSliderDescriptionValue: any = 0
  is_wallet_redeemed: boolean = false;
  is_promocode_redeemed: boolean = false;
  checkboxValue: boolean = false;
  bonusclick: any = 0;
  is_wallet_applied: boolean = false;
  is_promocode_applied: boolean = false;
  is_bonus_hour_applied: boolean = false;
  reservationData_get: any;
  bonus_hours: any;
  bonus_rate: any;
  firstLoad = false;
  reservationData: any;
  reservationDataEventLog: any;

  discountAmount: number = 0;
  finalPrice: any;
  isLoggedIn: boolean = false;
  extraTime: any;
  coupon_threshold_price: any;
  is_coupon_threshold_price_percentage: any;
  warn_status: any = 0;
  warn_statusToSend: any = 0;
  warn_msg: any = '';
  garageWorkingHours: any
  is_promocode_applied_already: boolean = false;
  promocode_text: any = ''
  promocodeIconReward = '';
  dateMask: any = [/[0-1]/, /[0-9]/, '/', /[0-9]/, /[0-9]/];
  cardMask: any = [
    /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, ' ',
    /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, ' ',
    /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, ' ',
    /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/
  ];

  isLoaded: boolean = false;
  userPromocodes: any = {};
  showDropdown: boolean = false;
  hasPaymentMethods = false;
  expandCardForm = true;

  arrival_time: any;
  length_of_stay: any;
  bookingPrice: any;

  displayStartTime;
  displayEndTime;
  displayStartDate;
  displayEndDate;
  displayExtraTime;
  isOn: any;
  visa_Icon = 'assets/img/visa.svg';
  mastercardIcon = 'assets/img/mastercard.svg';
  discovercardIcon = 'assets/img/discovercard.svg';
  amexIcon = 'assets/img/amex.svg';
  card_grey_Icon = 'assets/img/card-grey.svg';
  jcbIcon = 'assets/img/jcbcard.svg';
  dinersIcon = 'assets/img/dinersclub-icon.svg';
  selectedCredit: any = 0;
  finalStoreValue: any;
  rangerValue: any = 0;
  actualPrice: any = 0;
  maxrange = 0;
  promocodeValue = 0;
  totalrange = 0;
  receiveFilterChange = false;
  checkShow: any = false;
  loyalty = {
    payRewards: false,
    AddCardIcon: false
  }
  isLoyaltyMember: boolean = false;
  isLoyaltyMemberActive: boolean = false;
  loyalty_points: number = 0;
  loyaltyData: any;
  pointAmt: number = 0;
  public config = { ignoreBackdropClick: true }
  messageBoxTxt: string;
  isFrame: boolean = false;
  FacilityAvailableFetatures: any;
  disableBookButton: boolean;
  checkAvailability = false;
  arrival_timeData: any;
  rewardDisabled = false;
  is_filter: number = 0;
  // use_bonus:any = 0;
  // is_promocode_applied:boolean = false;
  updatetimeLink = '#updatetime';
  minTransactionAmount: any;
  percentage_maximum_discount: any;
  GlobalThreshold: any;
  showMethodHeading: boolean = false;
  reservationDate: any;
  currentUrl: any;
  isPpopupShowMessage: any = '';
  loadWorkingHours: boolean = false;
  isUpdatedEndTimeFlag: boolean = false;
  MTSMMessage: string = '<div class="text-center">Please wait... </div>';
  deviceInfo = null;
  menuUser = [
    new CustomCode('My Account', 'account'),
    new CustomCode('My Monthly Parking', 'monthly-parking'),
    //new CustomCode('My Offers', 'coupons'),
    new CustomCode('My Reservations', 'reservations'),
    new CustomCode('Vehicle Request', 'vehicle-request'),
    new CustomCode('Brand Partnerships', 'brand-partnership'),
    new CustomCode('My Promo Codes', 'promo-codes'),
  ];
  showCardFillFormButton: boolean = false;
  paywithcardPopup: any = 0;
  paywithsavecardPopup: any = 0;
  paywithrewardPopup: any = 0;
  showWarningPopup = 0;
  issamepriceMoretime = 0;
  defaultLengthOfStay = 0;
  isCardReq: boolean = true;
  isDefaultPromoCode: boolean = false;
  showNotification: boolean = true;
  isRemovedPromoCode: boolean = false;
  getUserId = '0';
  search_user_email = '0'
  getZipCode = '';
  getSavedZipcode = '';
  isRemovePromocodeDone = false
  defaultNotset = true;
  prmcdNme: any = ''
  creditapplyremove = 0;
  notShow: any = false;
  ErrorInPromoCode: boolean;
  page: String = 'daily-parking-terms-conditions';
  staticPageContent: any = {};
  isLoadeds: boolean = false;
  showTermsError: boolean = false;
  facIdToShowOversize: any = 1;
  // facIdToShowOversize = '376';
  facIdToShowOversizeMessage = ''
  arrayOfWeekdays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
  mlist = ["Jan", "Feb", "March", "Apr", "May", "Jun", "July", "Aug", "Sep", "Oct", "Nov", "Dec"];
  dayname: any = 'Sunday';
  edayname: any = 'Sunday';
  daynames: any = '';
  edaynames: any = '';
  updatedStartDate: any;
  updatedEndDate: any;
  email_error = 0
  password_error = 0;
  showMsg = 0;
  OldPromocode = ''
  showerrorMsg = 0;
  messageErro: any = '';
  hideTermsPopupBtns = false;
  msg = ''
  availDate = ''
  isLoyality = false;
  blueBarActive = false;
  totalUpdatedAmount = 0;
  //facIdToShowOversize:any = 1;
  // facIdToShowOversize = '376';
  //facIdToShowOversizeMessage='';
  processFess = 0.00;
  //OldPromocode=''
  is_showing_strip = 0;
  updatedAmountFinal = 0;
  isShowingStrip = false;
  thisActualFinalPrice: any = 0;
  actualPromocodeApplied: any = 0;
  arrival_time_new = 0;
  facilityFullName = '';
  facilityPrice = '';
  userEmailForLog = '';
  fromSearch = '';
  termsValid: boolean;
  showTermError = false;
  reffrel_page = 'search';
  neighborhood_name_for_event = '';
  @ViewChild('myDiv', { static: true }) myDiv: ElementRef;
  @ViewChild('removePromocodeLink', { static: false }) removePromocodeLink: ElementRef;
  @ViewChild('bookerrormsgpopupclose', { static: true }) bookerrormsgpopupclose: ElementRef;
  @ViewChild('bookwithcard', { static: true }) bookwithcard: ElementRef;
  @ViewChild('bookwithreward', { static: true }) bookwithreward: ElementRef;
  @ViewChild('bookwithsavecard', { static: true }) bookwithsavecard: ElementRef;
  @ViewChild('promocodeValueText', { static: true }) promocodeValueText: ElementRef;

  constructor(private activatedRouteObj: ActivatedRoute,
    private routerObj: Router,
    private userServiceObj: UserserviceService,
    private reservationServObj: ReservationService,
    private searchPageServiceObj: SearchpageserviceService,
    private cryptoObj: Crypto,
    private toastr: ToastrService,
    private myAccountMonthlyParkingObj: MyAccountMonthlyParkingService,
    private logsService: LogsService,
    private modalService: BsModalService,
    private deviceService: Ng2DeviceService,
    private staticObj: StaticpagesService,
  ) {
  }
  ngAfterViewInit(){
    let cnt=0;
    const y = document.getElementsByTagName("link");
    for(let i=0;i<y.length;i++){
      if(y[i].hasAttribute("rel") && y[i].getAttribute("rel")=="canonical"){
        y[i].setAttribute("href", "https://iconparkingsystems.com/payment");
        
        cnt++;
       // alert(cnt);
      }
    }
    if(cnt==0){
      let para = document.createElement("link");
      para.setAttribute("rel", "canonical");
      para.setAttribute("href", "https://iconparkingsystems.com/payment");
      document.head.appendChild(para);
    }

    
  }
  ngOnDestroy(){
    const y = document.getElementsByTagName("link");
        console.log("link",y.length)
        for(let i=0;i<y.length;i++){
          if(y[i].hasAttribute("rel") && y[i].getAttribute("rel")=="canonical"){
            y[i].remove();
            
          
            
          }
       }
  }
  ngOnInit() {
    this.routerObj.events
      .pipe(filter((evt: any) => evt instanceof RoutesRecognized), pairwise())
      .subscribe((events: RoutesRecognized[]) => {
        let prevUrl = events[0].urlAfterRedirects;
        this.reffrel_page = prevUrl;
      });



    setInterval(() => {
      $('#mainBody').removeClass('noscroll');
      if (localStorage.getItem('is_show_landing_strip') == 'true') {
        this.isShowingStrip = true;
      } else {
        this.isShowingStrip = false;
      }
    }, 10)


    let stripShow = setInterval(() => {

      if (localStorage.getItem('showingStrip')) {
        this.is_showing_strip = 1;
        // clearInterval(stripShow);
      } else {
        this.is_showing_strip = 0;
      }
    }, 10)
    if (window.self !== window.top) { this.isFrame = true; }

    if (!(localStorage.getItem('currentLatitude') && localStorage.getItem('currentLongitude'))) {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(function (position) {
          localStorage.setItem('currentLatitude', position.coords.latitude.toString());
          localStorage.setItem('currentLongitude', position.coords.longitude.toString());
        });
      } else {  //at("Sorry, your browser does not support geolocation services.");
      }
    }
    this.isOn = false;
    this.minDate = new Date();
    this.minDate.setDate(this.minDate.getDate());
    SearchPageScript.openTimePicker();
    this.activatedRouteObj.params.subscribe((params: Params) => {
      this.currentUrl = params;
      this.facId = +params['facId'];
      let d = params['facType'];
      d = d.split('-').join('/');
      this.reservationDate = d;
      this.amt = params['facPay'];
      this.finalStoreValue = this.finalPrice = parseInt(this.amt);

      this.searchPageServiceObj.getFacilityDetails(this.facId).then(
        (res:any) => {
          this.respdata = res;
          console.log('res',res.data.phone_number);
          this.phone_number= this.phoneFormat(this.respdata.data.phone_number);
          this.respdata = this.respdata.data;
          this.facIdToShowOversize = this.respdata.is_oversize_allowed;
          this.facIdToShowOversizeMessage = this.respdata.oversize_not_allowed_message;
          this.bonus_hours = this.respdata.reservation_bonus_hours;
          console.log('this.bonus_hours', this.bonus_hours)
          this.bonus_rate = this.respdata.reservation_bonus_rate;
          this.firstLoad = true;
          this.facilityFullName = this.respdata.full_name;
          this.facilityPrice = this.respdata.price;
          this.neighborhood_name_for_event = this.respdata.neighborhood.title;
          // this.lengthOfStay = this.returnAvailability();
          setTimeout(() => {
            this.updateLengthOfStayText();
          }, 500);
          //this.fetchBookingPrice();
        },
        (error) => {
        }
      );
      this.displayHideElements();
    });



    if (localStorage.getItem('access_token')) {
      this.isLoggedIn = true;
    }

    CustomCode.loadMapOnInit();
    CustomCode.manageFormLabelsPosition();
    CustomCode.displayLoginUser();
    this.fetchWalletConfig();

    if (this.isLoggedIn) {
      this.fetchUserCards();
      this.loggedUserName = CustomCode.getloggedInUserDetails();
      this.fetchUserDetails();
      // this.updatePromocodeDropdown();

    }
    if (localStorage.getItem('is_landing_page')) {
      this.isRemovePromocodeDone = true;
    } else {
      this.isRemovePromocodeDone = false;
    }
    if (localStorage.getItem('is_loyalty') === "1" && localStorage.getItem('is_loyalty_active') == '1') {
      this.isLoyaltyMember = true;
      this.userServiceObj.GetLoyaltyWallet().subscribe(
        (response: any) => {
          this.loyaltyData = response.data;
          let pointRatio = response.data.loyalty_config.POINTS_REDEEM_RATIO;
          let loyaltyPoint = response.data.loyalty_points;
          this.pointAmt = loyaltyPoint / pointRatio;
          this.loyalty_points = loyaltyPoint;
          this.promocodeIconReward = response.data.promoCodeApply

          // this.OldPromocode = response.data.promoCodeApply
          if (this.loyaltyData.promoCodeApply && !localStorage.getItem('is_landing_page')) {
            this.isDefaultPromoCode = true;
            let checkData = setInterval(() => {
              //(33)
              let p = $('input[name="promocode"]').val()

              if (p != undefined) {

                this.OldPromocode = this.promocodeIconReward
                $('input[name="promocode"]').val(this.promocodeIconReward)
                $('.couponApplyButton').trigger('click')

                clearInterval(checkData)
              }
            }, 3000)
            //this.applyCoupon();
          }
        },
        err => {

        }
      );
    }



    this.staticObj.getCmsPageContent(this.page).subscribe((data: any) => {
      this.staticPageContent = data.result[0];
      this.isLoadeds = true;
    }, (error) => {
      console.log('Error: ' + JSON.stringify(error));
      this.isLoadeds = true;
    }
    );
    // this.startDateforRightwindow=$('#startdate').val(),('#startTime').val()
    // this.endDateforRightwindow$ =  $('#enddate').val(), $('#endTime').val()

    if (sessionStorage.getItem('clickType')) {
      this.fromSearch = sessionStorage.getItem('clickType')
    }

    setTimeout(() => {
      let device_type = 'web';
      if ($(window).width() < 767) {
        device_type = 'responsive';
      } else { device_type = 'web'; }


      //   ga("Daily-Book-button-click", {
      //     "id": this.facId,
      //     "from" : this.fromSearch,
      //     "name": this.facilityFullName,
      //     "category": "Daily parking",
      //     "device_details": device_type
      // });
      let currentUserid = '';
      if (localStorage.getItem('userid')) {
        currentUserid = localStorage.getItem('userid');
        this.userEmailForLog = localStorage.getItem('email');
      } else {
        currentUserid = '';
        this.userEmailForLog = localStorage.getItem('email');
      }
      this.ReservationEventLogStatusForBookBtnClick(currentUserid, this.facId, this.facilityFullName, '', '', '', this.userEmailForLog);
    }, 10000);
  }
  phoneFormat(phonenumber) {
    const pattern = /[0-9\)\-\(\.\ ]/;
    // let inputChar = String.fromCharCode(event.charCode);
    // if (event.keyCode != 8 && !pattern.test(inputChar) && event.keyCode != 9) {
    //   event.preventDefault();
    // }
    phonenumber=phonenumber.replaceAll('-', '');
    phonenumber=phonenumber.replaceAll('(', '');
    phonenumber=phonenumber.replaceAll(')', '');
    
    var cleaned = ('' + phonenumber).replace(/\D/g, '');
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      var intlCode = (match[1] ? '+1 ' : '');
      //alert( [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join(''));
      return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
    }
    //alert(phonenumber)
    return '-';
    //return phonenumber;
  }
  // payWthRewards(AcceptTerms: TemplateRef<any>, event){ // commented for new loyalty changes IPSD-1776
  //   if (event.target.checked) {
  //     let tempArr = [];
  //     if(this.is_promocode_applied){
  //       tempArr.push('promo code');
  //     }

  //     if(this.is_bonus_hour_applied){
  //       tempArr.push('1 extra hour');
  //     }

  //     if(parseInt(this.selectedCredit)>0){
  //       tempArr.push('account credit');
  //     }

  //     this.messageBoxTxt =  tempArr.join('/');
  //     if(this.messageBoxTxt !==''){
  //       this.modalRef = this.modalService.show(AcceptTerms, this.config);
  //     }

  //     this.loyalty.payRewards = true;
  //     return true;

  //   }else{
  //     this.loyalty.payRewards = false;
  //     return true;
  //   }
  // }

  checkCardInserted(event) {
    if (event.target.checked) {
      if (this.formData.zip_code == undefined || this.formData.card_number == undefined || this.formData.expiration_date == undefined || this.formData.security_code == undefined || this.formData == '') {
        this.toastr.error('Not a valid card details.', 'sorry');
        event.target.checked = false;
      }
    } else {
      event.target.checked = false;
    }
  }

  // addCardToIcon(AcceptCard: TemplateRef<any>, event){ // commented for new loyalty changes IPSD-1776
  //   this.modalRef = this.modalService.show(AcceptCard, this.config);
  //   return false;
  // }

  // insufficientReward(insReward: TemplateRef<any>, event){ // commented for new loyalty changes IPSD-1776
  //   this.modalRef = this.modalService.show(insReward, this.config);
  //   return false;
  // }

  // confirm(formName): void { // commented for new loyalty changes IPSD-1776
  //   if(formName === 'icon'){
  //     if(this.is_promocode_applied){
  //       this.removePromocode(true);
  //      }

  //     this.loyalty.payRewards = true;

  //     if(this.is_promocode_applied){
  //       this.finalPrice = this.finalPrice + this.discountAmount;
  //       this.is_promocode_applied = false;
  //     }

  //     if(this.is_bonus_hour_applied){
  //       this.finalPrice = this.finalPrice - this.bonus_rate;
  //       this.is_bonus_hour_applied = false;
  //     }

  //     if(this.is_wallet_applied){
  //       this.discountAmount = parseInt(this.walletConfig.WALLET_REDEEM_LIMIT);
  //       this.finalPrice = this.finalPrice - this.discountAmount;
  //       this.is_wallet_applied = false;
  //     }

  //     if(parseInt(this.selectedCredit)>0){
  //       this.finalPrice = parseInt(this.finalPrice) + parseInt(this.selectedCredit);
  //       this.selectedCredit = 0;
  //       this.showSliderDescription = false;
  //     }
  //   }
  //   this.modalRef.hide();
  // }

  // decline(formName): void { // commented for new loyalty changes IPSD-1776
  //   if(formName === 'icon'){ this.loyalty.payRewards = false; }
  //   this.modalRef.hide();
  // }

  // close(){ // commented for new loyalty changes IPSD-1776
  //   this.modalRef.hide();
  // }

  // paymentWithRewards(){ // commented for new loyalty changes IPSD-1776
  //   /* click book it button */
  //   kochava.activity('click_book_it_button', {
  //     book_button : 'paymentWithRewards'
  //   });

  //   // this.finalPrice = this.finalPrice*this.loyaltyData.loyalty_config.POINTS_RATIO;
  //   let opt_value:any='';
  //   let formDetail: any = {};
  //   if(this.checkShow)
  //   {
  //     if(this.opt)
  //     {
  //       opt_value=0;
  //     }else{
  //       opt_value=1;
  //     }
  //   }
  //   let companyName='';
  //   if(localStorage.getItem('companyName'))
  //   {
  //     companyName=localStorage.getItem('companyName')
  //   }  

  //   const beginningDate = this.arrivalTime();
  //   const description = 'Reservation for ' + $('#display_name').val() + ' - A beginning at ' + beginningDate + ' lasting for ' + this.length_of_stay;
  //   let startDate = new Date($('#startdate').val());
  //   let currentDate = new Date();
  //   let maxDate=new Date(currentDate);
  //   let maxRangeDate=new Date(startDate);
  //   maxDate.setDate(maxDate.getDate()+89);
  //   maxRangeDate.setMonth(maxRangeDate.getMonth()+1);
  //   maxRangeDate.setDate(maxRangeDate.getDate() - 1);
  //   let endDate = new Date($('#enddate').val());

  //   if(this.length_of_stay == 0) {
  //     this.toastr.error('You cannot book the reservation in the past', 'error');
  //   } else if (this.length_of_stay < 2) {
  //     this.toastr.error('Minimum booking should be 2 hours', 'error');
  //   } else if (this.length_of_stay < 0 ) {
  //     this.toastr.error('End time should be greater than start time', 'error');
  //   } 
  //   else if (startDate > maxDate ) {
  //     this.toastr.error('You cannot book the reservation over 90 days', 'error');
  //   }  
  //   else if (endDate > maxRangeDate ) {
  //     this.toastr.error('Date range should be less than one month', 'error');
  //   } else {

  //     if(this.warn_status == 1 && this.showWarningPopup==1){
  //       this.paywithcardPopup=0;
  //       this.paywithsavecardPopup=0;
  //       this.paywithrewardPopup=1;
  //       let el: HTMLElement = this.myDiv.nativeElement as HTMLElement;
  //       el.click();
  //       return false;  
  //     }
  //     else{
  //       this.paywithcardPopup=0;
  //       this.paywithsavecardPopup=0;
  //       this.paywithrewardPopup=0;
  //       this.isPpopupShowMessage = '';
  //     }

  //     formDetail = {
  //       'arrival': beginningDate, 
  //       'length': this.length_of_stay,
  //       'facility_id': this.facId, 
  //       'description': description, 
  //       // 'savePaymentMethod': formValue.savePaymentMethod, 
  //       'use_bonus': 0, 
  //       'nonce': '',
  //       'companyName':companyName,
  //       'is_opt_out':opt_value,
  //       'coupon_threshold_price' : this.coupon_threshold_price,
  //       'is_loyalty_redeemed': this.loyalty.payRewards, 
  //       'loyalty_points': this.finalPrice*this.loyaltyData.loyalty_config.POINTS_REDEEM_RATIO,
  //       'total': 0,
  //       'warning_on_reservation' : this.warn_statusToSend,
  //       'warning_on_reservation_msg_email': this.warn_msg,
  //       'is_end_time_updated' : (this.isUpdatedEndTimeFlag)?1:0,
  //       'original_length' : this.defaultLengthOfStay
  //     };

  //     if (this.isLoggedIn) {
  //       formDetail.user_id = this.activeUser.data.id;
  //     }

  //     this.rewardDisabled = true;
  //     this.reservationServObj.makeDailyReservation(formDetail)
  //       .subscribe(
  //       data => {
  //         this.parseData = data;
  //         this.flushLandingPagePromoCodeSession();
  //         this.is_paying = false;
  //         this.rewardDisabled = false;
  //         let reservation_status = '1';
  //         this.updateReservationStatus(this.parseData.data.reservation.id, reservation_status);
  //         let formEvent = {
  //           'event':'reservation',
  //           'reservationCode':this.parseData.data.reservation.ticketech_code,
  //           'value':this.parseData.data.reservation.total,
  //           'location':this.parseData.data.reservation.facility.short_name
  //         }
  //         this.navigateConfirmation(this.parseData.data.reservation.id,formEvent);
  //       },
  //       error => {
  //         this.is_paying = false;
  //         this.rewardDisabled = false;
  //         const errorsList = error.errors;
  //         if (errorsList.message !== 'Validation Errors') {
  //           this.toastr.error(errorsList.message, 'Sorry!');
  //         } else {
  //           for (const key in errorsList.detail) {
  //             if (errorsList.detail.hasOwnProperty(key)) {
  //               if (typeof errorsList.detail === 'undefined') {
  //                 this.toastr.error(errorsList.message, 'Sorry!');
  //               } else {
  //                 this.toastr.error(errorsList.detail[key], 'Sorry!');
  //               }
  //             }
  //           }
  //         }
  //       }
  //     )
  //   }
  // }

  applyLandingPagePromocode() {

    let promocode = '';
    if (localStorage.getItem('is_loyalty') === "1" && localStorage.getItem('is_loyalty_active') == '1') {
      promocode = this.promocodeIconReward
    } else {
      promocode = localStorage.getItem('promocode');
    }

    this.is_promocode_redeemed = true;
    console.log('applyPromoCode 1')
    this.applyPromoCode(promocode);
    // this.applyCoupon()
    // $('input#promocode').click();
    // $('input[name="promocode"]').val(promocode);
    // $('button.couponApplyButton').click();

  }

  initLengthOfStay() {
    let bookingHours: any;
    if (window.self !== window.top) {
      bookingHours = FilterHelper.getBookingHours(this.starttimeForWidget, this.starttimeforwidget, this.endtimeForWidget, this.endtimeforwidget);
    } else {
      bookingHours = FilterHelper.getBookingHours($('#startdate').val(), $('#startTime').val(), $('#enddate').val(), $('#endTime').val());
    }
    if (bookingHours < 0) {
      bookingHours = 0.5;
    }
    // bookingHours = FilterHelper.getBookingHours($('#startdate').val(), $('#startTime').val(), $('#enddate').val(), $('#endTime').val());
    this.length_of_stay = bookingHours;
    this.lengthOfStay = bookingHours;
  }

  updateDisplayFilters() {
    if (window.self !== window.top) {
      if ($('#startdate').val() != undefined) {
        this.starttimeForWidget = $('#startdate').val()
        this.endtimeForWidget = $('#enddate').val()
        this.displayStartDate = new Date($('#startdate').val());
        this.displayEndDate = new Date($('#enddate').val());
        this.starttimeforwidget = $('#startTime').val();
        this.endtimeforwidget = $('#endTime').val();
        this.displayStartTime = FilterHelper.displayableTimeformat($('#startTime').val());
        this.displayEndTime = FilterHelper.displayableTimeformat($('#endTime').val());
      } else {
        this.displayStartDate = this.starttimeForWidget
        this.displayEndDate = this.endtimeForWidget
        this.displayStartTime = FilterHelper.displayableTimeformat(this.starttimeforwidget);
        this.displayEndTime = FilterHelper.displayableTimeformat(this.endtimeforwidget);
      }
    } else {
      this.displayStartDate = new Date($('#startdate').val());
      this.displayEndDate = new Date($('#enddate').val());
      this.displayStartTime = FilterHelper.displayableTimeformat($('#startTime').val());
      this.displayEndTime = FilterHelper.displayableTimeformat($('#endTime').val());
    }

    if (this.displayStartTime == "00:00 AM") { this.displayStartTime = "12:00 AM" }
    if (this.displayStartTime == "00:30 AM") { this.displayStartTime = "12:30 AM" }
    if (this.displayEndTime == "00:00 AM") { this.displayEndTime = "12:00 AM" }
    if (this.displayEndTime == "00:30 AM") { this.displayEndTime = "12:30 AM" }
    this.updateExtraTime();
  }

  updateExtraTime() {
    this.extraTime = new Date($('#enddate').val() + ' ' + $('#endTime').val());
    this.extraTime = this.extraTime.setMinutes(this.extraTime.getMinutes() + 60);
    this.extraTime = new Date(this.extraTime);

    let extrahour = this.extraTime.getHours();
    let extraminutes = this.extraTime.getMinutes();
    let extraampm = 'AM';
    extrahour = (extrahour).toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false });
    if (extrahour > 12) {
      extrahour = (extrahour - 12).toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false });
      extraampm = 'PM';
    }
    if (extrahour == 12) {
      extraampm = 'PM';
    }
    extraminutes = (extraminutes).toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false });
    this.displayExtraTime = extrahour + ':' + extraminutes + ' ' + extraampm;
    if (this.displayExtraTime == "00:00 AM") { this.displayExtraTime = "12:00 AM" }
    if (this.displayExtraTime == "00:30 AM") { this.displayExtraTime = "12:30 AM" }
  }

  debugDuration() {

  }

  fetchBookingPrice() {
    //alert('g');
    // setTimeout(()=>{
    //   let sDateRightWindow=new Date($('#startdate').val()) ;

    //   this.updatedStartDate = new Date($('#startdate').val().trim()) ;
    //   this.updatedEndDate = new Date($('#enddate').val()) ;
    //   this.updatedStartDate  = this.getFormatedDate(this.updatedStartDate, $('#startTime option:selected').text())
    //   this.updatedEndDate  =  this.getFormatedDate(this.updatedEndDate, $('#endTime option:selected').text())

    //       console.log(this.updatedStartDate);
    //       console.log(this.updatedStartDate);
    //   // asa(this.updatedStartDate );
    //   // asa(this.updatedEndDate );

    //  // saa($('#startTime').val());
    //   let a ={'01':'1st','02':'2nd','03':'3rd','04':'4th','05':'5th','06':'6th','07':'7th','08':'8th','09':'9th','10':'10th',
    //   '11':'11th','12':'12th','13':'13rd','14':'14th','15':'15th','16':'16th','17':'17th','18':'18th','19':'19th','20':'20th',
    //   '21':'21st','22':'22nd','23':'23rd','24':'24th','25':'25th','26':'26th','27':'27th','28':'28th','29':'29th','30':'30th','31':'31st'}; 
    //   let eDateRightWindow=new Date($('#enddate').val()) ;
    //   this.availDate=this.mlist[sDateRightWindow.getMonth()]+' '+a[((sDateRightWindow.getDate() > 9) ? sDateRightWindow.getDate() : ('0' + sDateRightWindow.getDate()))]
    //   this.dayname =this.arrayOfWeekdays[sDateRightWindow.getDay()]+', '+this.mlist[sDateRightWindow.getMonth()]+' '+((sDateRightWindow.getDate() > 9) ? sDateRightWindow.getDate() : ('0' + sDateRightWindow.getDate()))+' at '+ $('#startTime option:selected').text()

    //   this.edayname=this.arrayOfWeekdays[eDateRightWindow.getDay()]+', '+this.mlist[eDateRightWindow.getMonth()]+' '+((eDateRightWindow.getDate() > 9) ? eDateRightWindow.getDate() : ('0' + eDateRightWindow.getDate()))+' at '+ $('#endTime option:selected').text()
    //   let month=''
    //   if(this.mlist[sDateRightWindow.getMonth()]+' '+((sDateRightWindow.getDate() > 9) ? sDateRightWindow.getDate() : ('0' + sDateRightWindow.getDate()))!=this.mlist[eDateRightWindow.getMonth()]+' '+((eDateRightWindow.getDate() > 9) ? eDateRightWindow.getDate() : ('0' + eDateRightWindow.getDate())))
    //   {
    //     month=this.mlist[eDateRightWindow.getMonth()]+' '+((eDateRightWindow.getDate() > 9) ? eDateRightWindow.getDate() : ('0' + eDateRightWindow.getDate()))+' at '
    //   }
    //   this.daynames =this.mlist[sDateRightWindow.getMonth()]+' '+((sDateRightWindow.getDate() > 9) ? sDateRightWindow.getDate() : ('0' + sDateRightWindow.getDate()))+' at '+ $('#startTime option:selected').text()
    //   this.edaynames=month+''+$('#endTime option:selected').text()
    //   //asa(eDateRightWindow)
    // },2000)
    if (sessionStorage.getItem('alreadyShowPopup') == '1') {
      this.showWarningPopup = 0;
      sessionStorage.removeItem('alreadyShowPopup');
    } else {
      this.showWarningPopup = 1;
    }

    this.updateDisplayFilters();
    this.initLengthOfStay();
    this.updateLengthOfStayText();
    this.debugDuration();
    if (this.is_filter == 1) {
      this.is_bonus_hour_applied = false;
    }

    let lengthStay = 0;
    if (this.is_bonus_hour_applied) {
      lengthStay = this.length_of_stay;
    } else {
      lengthStay = this.defaultLengthOfStay
    }
    // added for IPSD-4229
    if(lengthStay==0){
      //let arvldur=$('#startdate').val();
      //alert(arvldur);
      let startDuration = FilterHelper.makeDurationDateObject($('#startdate').val(),  $('#startTime').val());
      let endDuration = new Date(startDuration);
      endDuration.setMinutes(endDuration.getMinutes() + 120);
      this.endTime = FilterHelper.getFormattedTime(endDuration);
      
      $('#endTime').val(this.endTime);
      lengthStay=2;
      this.toastr.error(FilterHelper.TWO_HOUR_ERROR_MESSAGE, FilterHelper.MESSAGE_TITLE);
    }
    this.arrival_time_new = this.length_of_stay;
    let bonus = this.is_bonus_hour_applied ? 1 : 0;
    this.GlobalThreshold = sessionStorage.getItem('coupon_threshold_price');
    let queryString = this.facId + "?";
    queryString += "arrival_time=" + this.arrival_time + "&";
    queryString += "length_of_stay=" + lengthStay + "&";
    queryString += "is_filter=" + this.is_filter + "&";
    queryString += "price=" + this.actualPrice + "&";
    queryString += "use_bonus=" + bonus + "&";
    queryString += "coupon_threshold_price=" + this.GlobalThreshold + "&";
    queryString += "device_type=" + 'web';
    this.searchPageServiceObj.fetchBookingRates(queryString).then(
      (res) => {
        if (res) {
          this.changeDate();
          let moreDisplayEndTime = '';
          let moreDisplayEndDate = '';
          let moreDisplayStartDate = '';
          this.FacilityAvailableFetatures = res['data'];
          if (this.FacilityAvailableFetatures.availability > 0) {
            this.checkAvailability = false;
          } else {
            this.checkAvailability = true;
          }
          
          if (this.FacilityAvailableFetatures.availability_msg == 'Some spots are available.' || this.FacilityAvailableFetatures.availability_msg == 'Limited spots are available.') {
            this.showMsg = 1;
            this.msg = this.FacilityAvailableFetatures.availability_msg.replace('.', '');
          }
          else if (this.FacilityAvailableFetatures.availability_msg == "Only 5 Spots Left!") {
            this.showMsg = 1;
            this.msg = 'Some spots are available';
          }
          else {
            this.showMsg = 0;
          }
          if (this.FacilityAvailableFetatures.availability_msg == 'Facility is closed at the time and duration specified') {
            //alert('1')
            this.toastr.error('Facility will be closed at this time.', 'Sorry!');
          }
          if (this.FacilityAvailableFetatures.availability_msg == "Only 5 Spots Left!") {
            this.showMsg = 1;
            this.msg = 'Some spots are available';
          }
          if (this.FacilityAvailableFetatures.early_bird_flag == "1") {
            if (this.FacilityAvailableFetatures.entry_time_end == '23:59:59') {
              //alert('h');
              this.showMsg_early_bird_flag = false;
              this.msg_early_bird_flag = this.FacilityAvailableFetatures.early_bird_message;
            }
            else {
              this.showMsg_early_bird_flag = true;
              let entry_time_end_temp = this.FacilityAvailableFetatures.entry_time_end;
              let tm_arr = entry_time_end_temp.split(":");
              if (parseInt(tm_arr[0]) < 12) {
                this.entry_time_end = tm_arr[0] + ":" + tm_arr[1] + " AM";
              }
              else {
                this.entry_time_end = tm_arr[0] + ":" + tm_arr[1] + " PM";
              }
              //this.entry_time_end=new Date(this.FacilityAvailableFetatures.entry_time_end);
              this.msg_early_bird_flag = this.FacilityAvailableFetatures.early_bird_message;
            }

          }
          else {
            this.showMsg_early_bird_flag = false;
            this.msg_early_bird_flag = this.FacilityAvailableFetatures.early_bird_message;
          }
          let percentage = 0;
          let percentageOnBonus: any = 0
          if (this.FacilityAvailableFetatures.facility.processing_fee_type == 2 && this.is_bonus_hour_applied) {
            //alert(this.amt)
            let new_amt=this.amt.replace(',',"");
            percentage = this.FacilityAvailableFetatures.facility.processing_fee_percentage
            percentageOnBonus = ((parseFloat(new_amt) + parseFloat(this.FacilityAvailableFetatures.facility.reservation_bonus_rate)) * percentage) / 100;
            //this.processFess=parseFloat(this.FacilityAvailableFetatures.facility.processing_fee)+parseFloat(percentageOnBonus)
            this.processFess = parseFloat(percentageOnBonus)

            //  percentage = this.FacilityAvailableFetatures.facility.processing_fee_percentage
            //  percentageOnBonus=(this.FacilityAvailableFetatures.facility.reservation_bonus_rate * percentage)/100;
            //  this.processFess=parseFloat(this.FacilityAvailableFetatures.facility.processing_fee)+parseFloat(percentageOnBonus)

          } else {
            //alert('2')

            this.processFess = parseFloat(this.FacilityAvailableFetatures.facility.processing_fee)
          }
          //alert(res['data'].price);


          if (res['data'].price <= 0) {
            res['data'].price = 'N/A'
          }
          this.finalPrice = this.amt = res['data'].price;
          this.storedarivalprice = res['data'].price;
          if (this.finalPrice != 'N/A') {
            this.finalPrice = this.finalPrice + this.processFess; //ye krna h

          } else {
            this.processFess = 0
          }

          // this.processFess=parseFloat(this.FacilityAvailableFetatures.facility.processing_fee);
          this.isUpdatedEndTimeFlag = (this.FacilityAvailableFetatures.isUpdatedEndTimeFlag) ? true : false;
          if (this.isUpdatedEndTimeFlag) {
            let updateDate = this.FacilityAvailableFetatures.updated_end_time;
            this.deviceInfo = this.deviceService.getDeviceInfo();
            if (this.deviceService.browser === 'safari' || this.deviceInfo.browser === 'ie') {
              updateDate = FilterHelper.defaultEndDurationUrlForSafariForMoreTime(updateDate);
            }

            let endDate = this.FacilityAvailableFetatures.updatedDateWeb//FilterHelper.formatDate(new Date(updateDate));
            let endTime = this.FacilityAvailableFetatures.updatedTime//FilterHelper.getFormattedTime(new Date(updateDate));
            console.log('endTime', endTime)
            //alert(FilterHelper.defaultEndstartDate());
            if (this.toTimestamp(FilterHelper.defaultEndstartDate()) > this.toTimestamp(endDate)) {
              sessionStorage.setItem("enddatefromapi",endDate);
              $('#enddate').val(endDate);
              $('#endTime').val(endTime);
            }
            else {
              sessionStorage.setItem("enddatefromapi",endDate);
              //alert('1');
              //$("#enddate").datepicker({maxDate: new Date(endDate) });
              //localStorage.setItem("paymentmaxdate",this.FacilityAvailableFetatures.updated_end_time);
              setTimeout(() => {
                
                $('#enddate').val(endDate);
                $('#endTime').val(endTime);  
              }, 500);
              
            }

            moreDisplayStartDate = $('#startdate').val().trim();
            moreDisplayEndDate = $('#enddate').val().trim();
            moreDisplayEndTime = (FilterHelper.displayableTimeformat(endTime) == '00:00 AM') ? '12:00 AM' : FilterHelper.displayableTimeformat(endTime);
            this.initLengthOfStay();
            this.updateLengthOfStayText();
          }

          if (this.isUpdatedEndTimeFlag) {
            this.issamepriceMoretime = 1
            this.MTSMMessage = `<table><tr><td><img width="30" src="/assets/img/car-popover.png"></td><td>At this Icon Parking facility, ${moreDisplayStartDate} ${this.displayStartTime} to ${moreDisplayEndDate} ${moreDisplayEndTime} costs the same as the time you selected. The extra time has been added to your booking - stay until ${moreDisplayEndDate} ${moreDisplayEndTime}.</td></tr></table>`;
          }

          if (this.issamepriceMoretime == 1) {
            this.isUpdatedEndTimeFlag = true;
            this.MTSMMessage = `<table><tr><td><img width="30" src="/assets/img/car-popover.png"></td><td>At this Icon Parking facility, ${moreDisplayStartDate} ${this.displayStartTime} to ${moreDisplayEndDate} ${moreDisplayEndTime} costs the same as the time you selected. The extra time has been added to your booking - stay until ${moreDisplayEndDate} ${moreDisplayEndTime}.</td></tr></table>`;
          }

          this.is_coupon_threshold_price_percentage = res['data'].is_coupon_threshold_price_percentage;
          if (res['data'].price == "N/A") {
            $('#paymentBtn').hide();
            $('#userCard').hide();
            $('#actualPrice').hide();
            $('#naPrice').show();
            this.disableBookButton = false;
            $('#bonusHour').prop("disabled", false);
            if (this.is_bonus_hour_applied == true && res['data'].isExceededAllowedDays==false) {
              this.toastr.error('Facility will be closed at this time.', 'Sorry!');
              //this.is_bonus_hour_applied=false
              //this.fetchBookingPrice();
            }
            if (this.defaultLengthOfStay < 0) {
              $('#enddate').val(this.selectedenddate);
              this.initLengthOfStay();
              this.updateLengthOfStayText();
              this.defaultLengthOfStay = this.length_of_stay;
            }
            if (this.nastatus == 0) {
              this.nastatus = 1;
              this.fetchBookingPrice();
              setTimeout(() => {
                this.nastatus = 0;
              }, 4000);
            }
            //this.fetchBookingPrice();
          } else {
            this.disableBookButton = true;
            // if (res['data'].availability > 0) {
            //   this.checkAvailability = true;
            // }
            $('#paymentBtn').show();
            $('#userCard').show();
            $('#actualPrice').show();
            $('#naPrice').hide();
            $('#bonusHour').prop("disabled", false);

            this.warn_status = this.warn_statusToSend = res['data'].warning_on_reservation;

            this.isPpopupShowMessage = res['data'].warning_on_reservation_msg;
            this.warn_msg = res['data'].warning_on_reservation_msg_email;
            if (this.warn_status == 1) {
              this.searchPageServiceObj.getFacilitySchedule(this.facId).then(
                (res) => {
                  this.garageWorkingHours = res;
                  this.loadWorkingHours = true;
                  this.garageWorkingHours = this.garageWorkingHours.data;
                },
                (error) => {
                  this.loadWorkingHours = true;
                }
              );
            }

            if (1) {

              this.receiveFilterChange = false;
              this.actualPrice = (res['data'].price).toString().split(',').join('');
              let comingPrice: any = (res['data'].price).toString().split(',').join('');
              this.coupon_threshold_price = res['data'].coupon_threshold_price;

              if (this.loggedUserName) {
                if (parseFloat(this.actualPrice) < parseFloat(this.wallet.balance)) {
                  this.maxrange = this.actualPrice
                } else {
                  this.maxrange = this.wallet.balance;
                }
                this.changeZoom(this.selectedCredit);
              }
              comingPrice = comingPrice.replace(/\,/g, '');
              comingPrice = parseFloat(comingPrice);

              if (comingPrice > 0) {
                this.finalStoreValue = this.finalPrice = comingPrice;

                this.amt = this.finalPrice;

                if (this.finalPrice != 'N/A') {

                  this.finalPrice = this.finalPrice + this.processFess; //ye krna h

                } else {
                  this.processFess = 0
                }
                if (this.is_bonus_hour_applied) {

                  this.finalStoreValue = this.finalPrice = this.finalPrice + this.bonus_rate - this.actualPromocodeApplied - this.selectedCredit;


                } else {

                  this.finalStoreValue = this.finalPrice = this.finalPrice - this.actualPromocodeApplied - this.selectedCredit;

                }


                if (this.is_promocode_applied) {
                  //alert('hh');
                  //this.removePromocode(false);
                }

                if (this.promocode_text != '' && this.promocode_text != undefined) {
                  this.reapplyCoupon();
                }


                if (localStorage.getItem('is_landing_page')) {
                  let actionType = localStorage.getItem('action_type');
                  if (actionType == 'apply') {
                    setTimeout(() => {
                      this.applyLandingPagePromocode();
                    }, 300);
                  }
                }

              }
            }
          }

        }
      },
      (error) => {
      }
    );
  }
  toTimestamp(strDate) {
    var datum = Date.parse(strDate);
    return datum / 1000;
  }
  updateBonusHour() {
    this.is_filter = 0;
    this.bonusclick = 1;
    $('#bonusHour').prop("disabled", true);
    if (this.is_bonus_hour_applied) {
      this.bonushourappliedstatus = true;
      this.fetchBookingPrice();
      this.finalStoreValue = this.finalPrice = this.finalPrice + this.bonus_rate;

      this.changerange(this.selectedCredit)
      this.changeafterZoom(this.selectedCredit);
    } else {
      this.bonushourappliedstatus = false;
      this.fetchBookingPrice();
      this.finalStoreValue = this.finalPrice = this.finalPrice - this.bonus_rate;

      this.changerange(this.selectedCredit)
    }
  }

  receiveNewSearchFilter($event) {

    // let mon = "";
    // if (($event.enddats.getMonth() + 1) < 10) {
    //   mon = $event.enddats.getMonth() + 1;
    //   mon = "0" + mon;
    // }
    // else {
    //   mon = $event.enddats.getMonth() + 1;
    // }
    // let nxtdate:any;
    
    // if($event.enddats.getDate()==31){
    //    let temp_enddats=$event.enddats;
    //    temp_enddats.setDate($event.enddats.getDate() + 1);
    //    nxtdate = temp_enddats.getDate();
    // }
    // else{
    //   nxtdate =$event.enddats.getDate() + 1
    // }
    
    
    // this.selectedenddate = mon + "/" + nxtdate + "/" + $event.enddats.getFullYear();

    this.issamepriceMoretime = 0;
    localStorage.setItem('priceChangedUsingfilter', '1')
    this.is_filter = 1;
    this.loyalty.payRewards = false;
    this.receiveFilterChange = true;

    this.arrival_time = $event.currdate;
    if ($event.length_of_stay < 0) {
      //$('#enddate').val(this.selectedenddate);
      this.initLengthOfStay();
      this.updateLengthOfStayText();
      this.defaultLengthOfStay = this.length_of_stay;
    }
    else {
      this.defaultLengthOfStay = $event.length_of_stay;

      this.length_of_stay = $event.length_of_stay;
    }

    if ($event.type == "daily") {
      setTimeout(() => {
        this.fetchBookingPrice();
      }, 300);

    }
  }

  updateLengthOfStayText() {
    var LOT = this.length_of_stay;

    if (LOT) {
      if (LOT.toString().indexOf('.') > 0) {
        let temp = LOT.toString().split('.');
        this.length_of_stay_text = temp[0] + ' hours ';
        if (temp[1] == "5") {
          this.length_of_stay_text += ' and 30 minutes';
        }
      } else {
        this.length_of_stay_text = LOT + ' hours ';
      }
    }

    this.updateEndTime();
  }

  fetchWalletConfig() {
    this.userServiceObj.fetchWalletConfig()
      .then(
        data => {
          if (!this.isLoggedIn) {
            this.isLoaded = true;
          }
          this.walletConfig = data['data'].wallet_config;
        },
        error => {
          this.isLoaded = true;
          this.toastr.error('Couldn\'t Fetch Wallet Configurations', 'Sorry!');
        }
      );
  }

  unselectPromocodeRadio() {
    $('input[id="promocode"]').prop('checked', false);
    //this.is_promocode_redeemed = false;
    // }
  }

  fetchUserWalletDetails() {
    let userId = this.activeUser.data.id;
    this.getUserId = this.activeUser.data.id;
    this.search_user_email = this.activeUser.data.email
    this.userServiceObj.fetchUserWallet(userId)
      .then(
        data => {
          this.isLoaded = true;
          this.wallet = data['data'].wallet;
          let balance: any = this.wallet.balance;
          this.walletBalance = parseFloat(balance)
          //  if(parseFloat(this.actualPrice)<parseFloat(this.wallet.balance))
          //  {
          //     this.maxrange=this.actualPrice
          //  }else{
          //     this.maxrange=this.wallet.balance;
          //  }
        },
        error => {
          this.isLoaded = true;
          this.toastr.error(error.errors.message, 'Sorry!');
        }
      );
  }
  checkapply() {
    if (this.isLoyaltyMember && localStorage.getItem('is_landing_page')) {
      this.defaultNotset = false;
    } else {
      this.defaultNotset = true;
    }
  }
  applyCouponOnClick() {
    //alert('clicked');
    if (this.is_promocode_applied) {
      if (this.promocode.promocode.promocode.toLowerCase() == $('input[name="promocode"]').val().toLowerCase()) {
        return false
      }

    }
    if (sessionStorage.getItem('promoApplyPriority') && $('input[name="promocode"]').val().toLowerCase() !== sessionStorage.getItem('promoApplyPriority').toLowerCase()) {
      sessionStorage.removeItem('promoApplyPriority');
    }
    let ifYes = 0;
    this.ErrorInPromoCode = false
    let promocode = $('input[name="promocode"]').val();

    //alert(localStorage.getItem('couponSave'));
    //alert(localStorage.getItem('couponSave'));
    if (localStorage.getItem('couponSave') != promocode) {
      //alert('nx');
      localStorage.setItem('couponSave', promocode);
    } else {
      //alert('nn');
      return false;
    }

    if (promocode.toString().length < 1) {
      if (this.bonushourappliedstatus == false) {
        this.toastr.error('Please Enter Valid Promo Code', 'Validation Error!');
      }

      return false;
    }

    if (this.is_promocode_applied) {

      if (promocode != '' && promocode != undefined) {
        ifYes = 1;
        let e2: HTMLElement = this.removePromocodeLink.nativeElement as HTMLElement;
        //e2.click();
      }

      //$('.removePromocodeLink').trigger('click');
    }
    //this.showNotification=true;
    //alert(ifYes);
    if (ifYes) {
      setTimeout(() => {
        $('input[name="promocode"]').val(promocode)

        this.applyCoupon(4);

      }, 3000)
    } else {
      //alert('step 1');
      this.applyCoupon(5);
    }

  }
  applyCouponError(v) {
    let promocode;
    this.isRemovedPromoCode = true;
    if (this.isDefaultPromoCode) {
      this.showNotification = false;

      if (localStorage.getItem('is_loyalty') === "1" && localStorage.getItem('is_loyalty_active') == '1') {
        promocode = $('input[name="promocode"]').val();
      } else {
        promocode = localStorage.getItem('appliedPromocode');
      }
    } else {
      this.showNotification = true;
      promocode = $('input[name="promocode"]').val();
    }

    if (this.creditapplyremove) {
      promocode = this.prmcdNme
    }
    if (localStorage.getItem('is_loyalty') === "1" && localStorage.getItem('is_loyalty_active') == '1') {
      promocode = this.promocodeIconReward;
    }

    this.promocode_text = promocode

    let email_value = $('#eemail').val()
    if (email_value != '' && email_value != undefined) {
      var regrex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!regrex.test(String(email_value).toLowerCase())) {
        this.toastr.error('Please enter a valid email address. ', 'Sorry!');
        return false;
      }
    }

    if (promocode.toString().length < 1) {
      if (!this.ErrorInPromoCode) {
        this.toastr.error('Please Enter Valid Promo Code', 'Validation Error!');
      }

    } else {
      console.log('applyPromoCode 2')
      this.applyPromoCode(promocode);
    }
  }
  applyCoupon(v) {
    //alert('ac');
    let promocode;
    this.isRemovedPromoCode = true;
    if (this.isDefaultPromoCode) {
      //alert(2)
      this.showNotification = false;

      if (localStorage.getItem('is_loyalty') === "1" && localStorage.getItem('is_loyalty_active') == '1') {
        promocode = $('input[name="promocode"]').val();
      } else {
        this.showNotification = true;
        promocode = localStorage.getItem('appliedPromocode');
      }
    } else {
      //alert(5)
      this.showNotification = true;
      promocode = $('input[name="promocode"]').val();
    }
    if (this.creditapplyremove) {
      promocode = this.prmcdNme
    }
    //promocode='lucky17'
    if (1) {
      try {

        if (localStorage.getItem('appliedPromocode')) {
          promocode = localStorage.getItem('appliedPromocode')
        } else {
          promocode = $('input[name="promocode"]').val();
        }
        promocode = $('input[name="promocode"]').val();
      } catch (e) {

        promocode = $('input[name="promocode"]').val();
      }


    }
    //yha krna h sbkucj aut update bonus wale me //vishal wale account me point add krake test krna h
    // promocode = $('input[name="promocode"]').val();
    this.promocode_text = promocode

    let email_value = $('#eemail').val()
    if (email_value != '' && email_value != undefined) {
      var regrex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!regrex.test(String(email_value).toLowerCase())) {
        this.toastr.error('Please enter a valid email address. ', 'Sorry!');
        return false;
      }
    }

    if (promocode.toString().length < 1) {
      if (!this.ErrorInPromoCode) {
        this.toastr.error('Please Enter Valid Promo Code', 'Validation Error!');
      }

    } else {
      console.log('applyPromoCode 3')
      this.showNotification = true;
      //alert('step 2');
      this.applyPromoCode(promocode);
    }
  }


  fetchUserCards() {
    this.userServiceObj.fetchUserSavedCards().then(
      (res) => {
        this.fetchUserSavedCards = res;
        if (typeof this.fetchUserSavedCards.data !== undefined && this.fetchUserSavedCards.data.payments.length > 0) {
          this.newPayment = false;
          this.hasPaymentMethods = true;
          this.expandCardForm = false;
          this.showMethodHeading = true;
          this.getSavedZipcode = this.fetchUserSavedCards.data.payments[this.fetchUserSavedCards.data.payments.length - 1].address.zip;
          // console.log(this.fetchUserSavedCards.data.payments.length-1, this.getSavedZipcode) ;
        } else {
          this.newPayment = true;
          this.showMethodHeading = false;
        }
      },
      (error) => {
      }
    );
  }

  displayHideElements() {
    if (this.loggedUserName) {
      $('#secondloginbtn').css('display', 'none');
      $('#internaluser').css('display', 'none');
      $('#guestmail').css('display', 'none');
    } else {
      $('#secondloginbtn').css('display', 'block');
      $('#internaluser').css('display', 'block');
      $('#guestmail').css('display', 'block');
    }
    CustomCode.displayLoginUser();
  }

  updatePaymentMethodMode() {

    this.newPayment = true;
    $('input[name="selectedMethod"]').each(function () {
      $(this).prop('checked', false);
    });
  }

  signInUser(formValues, formObj: NgForm) {
    this.userServiceObj.loginUser(formValues)
      .subscribe(
        data => {
          this.parseData = data;
          if (this.parseData.status === 201) {
            formObj.resetForm();
            CustomCode.markLoginUser(this.parseData.data);
            this.loggedUserName = CustomCode.getloggedInUserDetails();
            this.displayHideElements();
            $('button.close').trigger('click');
            $('#secondloginbtn').css('display', 'none');
            this.fetchUserCards();
          }
        },
        error => {
          this.isLoaded = true;
          const errorsList = error.errors;
          if (errorsList.message !== 'Validation Errors') {
            this.toastr.error(errorsList.message, 'Sorry!');
          } else {
            for (const key in errorsList.detail) {
              if (errorsList.detail.hasOwnProperty(key)) {
                if (typeof errorsList.detail === 'undefined') {
                  this.toastr.error(errorsList.message, 'Sorry!');
                } else {
                  this.toastr.error(errorsList.detail[key], 'Sorry!');
                }
              }
            }
          }
        }
      );
  }

  searchLocation(type) {
    let userLat = (<HTMLInputElement>document.getElementById('userlat')).value;
    let userLong = (<HTMLInputElement>document.getElementById('userlong')).value;
    let locationUser = (<HTMLInputElement>document.getElementById('locationname')).value;

    if (!userLat || !userLong || !locationUser) {
      userLat = CustomCode.timeSquareCoords.lat;
      userLong = CustomCode.timeSquareCoords.long;
      locationUser = CustomCode.timeSquareCoords.defaultLocation;
    }
    this.routerObj.navigate(['/search', userLat, userLong, this.currDate, this.facilityRadius, locationUser, CustomCode.defaultFacilityType, CustomCode.searchMode()]);
  }

  logOut() {
    this.loggedUserName = CustomCode.getloggedInUserDetails();
    CustomCode.userLogout();
    CustomCode.displayLoginUser();
    $('#secondloginbtn').css('display', 'block');
    $('#guestmail').css('display', 'block');
  }

  checkLengthOfStay() {
    const sDate = $('#pstartdate').val();
    const eDate = $('#penddate').val();
    const psTime = $('#pstime').val();
    const peTime = $('#petime').val();
    return SearchPageScript.getLengthOfStay(sDate, psTime, eDate, peTime);
  }

  updateEndTime() {
    let ssTime = this.startTime;
    let newsTime = new Date(ssTime);
    this.endTime = new Date(newsTime.setMinutes(newsTime.getMinutes() + 120));
    this.updateExtraTime();
  }

  changeToFullTime(time) {
    const date = new Date();
    let hours;
    let minutes;
    let am_pm;
    const newTime = time.split(':');
    hours = newTime[0];
    const newT = newTime[1].split(' ');
    minutes = newT[0];
    am_pm = newT[1];
    if (am_pm == 'PM') {
      const convertToFullDate = ['12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'];
      hours = convertToFullDate[hours];
    }
    if (minutes < 10) {
      minutes = parseInt(minutes);
      minutes = '0' + minutes;
    }
    return hours + ':' + minutes + ':00';
  }

  getValidDate(userDate) {
    const reservationTime = userDate.split('/');
    const m = reservationTime[1];
    const d = reservationTime[0];
    const y = reservationTime[2];
    return y + '-' + d + '-' + m;
  }

  getValidDateTime(uDate, uTime) {
    return uDate + ' ' + this.changeToFullTime(uTime);
  }

  fetchLengthOfStay(startdate, enddate, starttime, endtime) {
    let StartTime: any = new Date(this.returnFormattedDate(startdate) + ' ' + this.returnFormattedTime(starttime));
    let EndTime: any = new Date(this.returnFormattedDate(enddate) + ' ' + this.returnFormattedTime(endtime));
    const bookingHours: number = ((EndTime - StartTime) / 60000) / 60;
    return bookingHours;
  }

  returnFormattedDate(date) {
    let dateNew = date.toLocaleString('en-US', { hour12: false }).split(" ");
    let mdy = dateNew[0];
    let mdy2 = mdy.split('/');
    let month = mdy2[0];
    let day = mdy2[1];
    let year = mdy2[2];
    let yearFix = year.split(',');
    let finalDate = month + '/' + day + '/' + yearFix[0];
    return finalDate;
  }

  returnFormattedTime(date) {
    let dateNew = date.toLocaleString('en-US', { hour12: false }).split(" ");
    return dateNew[1];
  }

  returnAvailability() {
    if (this.lengthOfStay == 0) {
      this.lengthOfStay = this.lengthOfStay;
    } else {
      const startDate = (<HTMLInputElement>document.getElementById('pstartdate')).value;
      const startTime = (<HTMLInputElement>document.getElementById('pstime')).value;

      const endDate = (<HTMLInputElement>document.getElementById('penddate')).value;
      const endTime = (<HTMLInputElement>document.getElementById('petime')).value;

      const lengthOfStay = SearchPageScript.getLengthOfStay(startDate, startTime, endDate, endTime);
      this.lengthOfStay = lengthOfStay;
      return lengthOfStay;

    }
  }

  returnBeginningDate() {
    const startDate = (<HTMLInputElement>document.getElementById('pstartdate')).value;
    const startTime = (<HTMLInputElement>document.getElementById('pstime')).value;
    const reservationTime = startDate.split('/');
    const m = reservationTime[1];
    const d = reservationTime[0];
    const y = reservationTime[2];
    return y + '-' + d + '-' + m + '' + ' ' + this.changeToFullTime(startTime);
  }

  restrictNumeric(event) {
    CustomCode.restrictNumericInCard(event);
  }



  checkCardByvalue(card_no, totalnumber) {
    let flag = 0;
    //SET CURSOR POSITION
    $.fn.setCursorPosition = function (pos) {
      this.each(function (index, elem) {
        if (elem.setSelectionRange) {
          elem.setSelectionRange(pos, pos);
        } else if (elem.createTextRange) {
          var range = elem.createTextRange();
          range.collapse(true);
          range.moveEnd('character', pos);
          range.moveStart('character', pos);
          range.select();
        }
      });
      return this;
    };
    if (card_no == '3') {
      if (totalnumber[1] == '_' && flag == 0) {
        $('#cardNumber').setCursorPosition(1);
        flag = 1;
      }
      this.cardMask = [
        /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, ' ',
        /[0-9]/, /[0-9]/, /[0-9]/, ' ',
        /[0-9]/, /[0-9]/, /[0-9]/, ' ',
        /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/
      ];
    } else {
      this.cardMask = [
        /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, ' ',
        /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, ' ',
        /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, ' ',
        /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/
      ];
    }
  }

  checkCard(card_no) {
    card_no = document.getElementById('cardNumber')
    //console.log('card_no',card_no.value);
    this.checkCardByvalue(card_no.value[0], card_no.value);
  }
  arrivalTime() {
    let startDate: any;
    let startTime: any
    if (window.self !== window.top) {
      startDate = new Date(this.starttimeForWidget);
      startTime = this.starttimeforwidget
      // bookingHours  = FilterHelper.getBookingHours(this.starttimeForWidget, this.starttimeforwidget, this.endtimeForWidget, this.endtimeforwidget);
    } else {
      startDate = new Date($('#startdate').val());
      startTime = $('#startTime').val();
      // bookingHours  = FilterHelper.getBookingHours($('#startdate').val(), $('#startTime').val(), $('#enddate').val(), $('#endTime').val());
    }
    //let startDate = new Date($('#startdate').val());

    return FilterHelper.getArrivalTime(startDate, startTime);
  }

  navigateConfirmation(reservation_id, action, code) {
    this.fetchUserStatusForTracking();
    (<any>window).dataLayer.push(action);

    /* added test event for ga testing 9-7-2021 by seo team,-- removed test event on 30-9-2021 */
    // let actionTest = action;
    // actionTest.event = 'Daily_Reservation_test';
    // (<any>window).dataLayer.push(actionTest);

    /* #Start Add GTM logged in db */
    let logData = {
      'iq_code': action.reservationCode,
      'coupon_code': null,
      'bar_code': null,
      'event_type': 1
    }

    this.logsService.addLog(logData).subscribe((result) => { }, (err) => { });
    /* #End GTM logged in db */
    /* commented for ga 16-6-2021 by seo team
        gtag_report_conversion(this.gtmUrl);
    
         gtag('event', 'conversion', {
          'send_to': 'AW-876742851/4MGmCMSms3UQw5GIogM',
          'value': 20.0,
          'currency': 'USD',
          'transaction_id': '',
           'eventCallback':window['callbackgtm']
        });
        */
    if (this.isLoggedIn) {
      this.routerObj.navigate(['/reservations/', code, action.reservationCode]);
    } else {
      this.routerObj.navigate(['/guest-reservation/', code, action.reservationCode]);
    }
  }
  dailyPayment(formValue, formObj: NgForm) {
    
    //this.disableBookButton=true;
    this.showerrorMsg = 0
    const reg = /<(.|\n)*?>/g;
    if (reg.test(formValue.name_on_card) == true) {
      this.toastr.error('HTML Tags are not allowed in Name on card.', 'Sorry!');
      return false;
    } else {
      if (reg.test(formValue.eemail) == true) {
        this.toastr.error('HTML Tags are not allowed in Email.', 'Sorry!');
        return false;
      }
      else {
        //return true;
      }
    }
    let opt_value: any = ''
    if (this.checkShow) {
      if (this.opt) {
        opt_value = 0;
      } else {
        opt_value = 1;
      }
    }
    if (this.is_paying) {
      return;
    }
    let startDate = new Date($('#startdate').val());
    let currentDate = new Date();
    let maxDate = new Date(currentDate);
    let maxRangeDate = new Date(startDate);
    maxDate.setDate(maxDate.getDate() + 89);
    maxRangeDate.setMonth(maxRangeDate.getMonth() + 1);
    maxRangeDate.setDate(maxRangeDate.getDate() - 1);
    let endDate = new Date($('#enddate').val());

    if (this.length_of_stay == 0) {
      this.toastr.error('You cannot book the reservation in the past', 'error');
    } else if (this.length_of_stay < 2) {
      //this.toastr.error('Minimum booking should be 2 hours', 'error');
      this.toastr.error("In order to book a reservation, booking duration must be at least 2 hours in the future", 'error');
    } else if (this.length_of_stay < 0) {
      this.toastr.error('End time should be greater than start time', 'error');
    }
    else if (startDate > maxDate) {
      this.toastr.error('You cannot book the reservation over 90 days', 'error');
    }
    else if (endDate > maxRangeDate) {
      this.toastr.error('Date range should be less than one month', 'error');
    } else {
      let cardEnc;
      if (this.isCardReq) {
        this.getZipCode = formValue.zip_code;
        cardEnc = this.cryptoObj.encryptAuth({ 'name_on_card': formValue.name_on_card, 'card_number': formValue.card_number.replace(/\D/g, ''), 'expiration_date': formValue.expiration_date.replace(/\D/g, ''), 'security_code': formValue.security_code, 'zip_code': formValue.zip_code });
      } else {
        cardEnc = {}
      }
      const cryptoData = cardEnc;

      let formDetail: any = {};
      const beginningDate = this.arrivalTime();
      // if(new Date(beginningDate)<FilterHelper.changeNyTime())
      // {
      //  // this.toastr.error('You cannot book the reservation in the past', 'error');
      // }
      const description = 'Reservation for ' + $('#display_name').val() + ' - A beginning at ' + beginningDate + ' lasting for ' + this.length_of_stay;
      let isError = false;
      let companyName = '';
      let p = 0
      //let total_price=this.finalPrice // ye kal ka
      let finalPrice_data= this.finalPrice.toString().replace(",", "");
      finalPrice_data=finalPrice_data.replace("$", "");
      finalPrice_data=Math.round(finalPrice_data * 100) / 100
      //let total_price = this.changeFormatdata(this.formatter.format(this.finalPrice));
      let total_price =finalPrice_data
      //alert(this.finalPrice);
      let coupon_threshold_price_percentage = this.is_coupon_threshold_price_percentage;
      if (localStorage.getItem('companyName')) {
        companyName = localStorage.getItem('companyName')
      }
      if (this.length_of_stay > 0) {

        if (this.warn_status == 1 && this.showWarningPopup == 1) {
          this.paywithcardPopup = 1;
          this.paywithsavecardPopup = 0;
          this.paywithrewardPopup = 0;
          let el: HTMLElement = this.myDiv.nativeElement as HTMLElement;
          el.click();
          return false;
        }
        else {
          this.paywithcardPopup = 0;
          this.paywithsavecardPopup = 0;
          this.paywithrewardPopup = 0;
          this.isPpopupShowMessage = '';
        }
        let device_type = 'web';
        if ($(window).width() < 767) {
          device_type = 'web responsive';
        } else { device_type = 'web'; }

        if (this.loggedUserName) {
          this.checksubmit = true;
          formDetail = {
            'arrival': beginningDate,
            'daytimesaverimpact': sessionStorage.getItem('is_extra'),
            'length': this.length_of_stay,
            'facility_id': this.facId,
            'description': description,
            'total': total_price,
            //'total': total_price.toFixed(2), ye kal ka,
            'availability': this.FacilityAvailableFetatures.availability,
            'total1': total_price,
            'savePaymentMethod': formValue.savePaymentMethod,
            'use_bonus': 0,
            'nonce': cryptoData,
            'companyName': companyName,
            'is_opt_out': opt_value,
            'coupon_threshold_price': this.coupon_threshold_price,
            'is_coupon_threshold_price_percentage': coupon_threshold_price_percentage,
            'warning_on_reservation': this.warn_statusToSend,
            'warning_on_reservation_msg_email': this.warn_msg,
            'is_end_time_updated': (this.isUpdatedEndTimeFlag) ? 1 : 0,
            'original_length': this.defaultLengthOfStay,
            'is_card_req': this.isCardReq,
            'processing_fee': this.processFess,
            'device_type': device_type,
            'category_id': this.FacilityAvailableFetatures.category_id,
            'rate_type_id': this.FacilityAvailableFetatures.rate_type_id,
            'early_bird_flag': this.FacilityAvailableFetatures.early_bird_flag,
            'entry_time_end': this.FacilityAvailableFetatures.entry_time_end
          };

        } else if (!this.loggedUserName) {
          this.checksubmit = true;
          this.userEmailForLog = formValue.eemail;
          formDetail = {
            'arrival': beginningDate,
            'daytimesaverimpact': sessionStorage.getItem('is_extra'),
            'length': this.length_of_stay,
            'facility_id': this.facId,
            'description': description,
            'total': total_price,
            //'total': total_price.toFixed(2), ye kal ka,
            'availability': this.FacilityAvailableFetatures.availability,
            'total1': total_price,
            'savePaymentMethod': formValue.savePaymentMethod,
            'use_bonus': 0,
            'nonce': cryptoData,
            'email': formValue.eemail,
            'companyName': companyName,
            'is_opt_out': opt_value,
            'coupon_threshold_price': this.coupon_threshold_price,
            'is_coupon_threshold_price_percentage': coupon_threshold_price_percentage,
            'warning_on_reservation': this.warn_statusToSend,
            'warning_on_reservation_msg_email': this.warn_msg,
            'is_end_time_updated': (this.isUpdatedEndTimeFlag) ? 1 : 0,
            'original_length': this.defaultLengthOfStay,
            'is_card_req': this.isCardReq,
            'processing_fee': this.processFess,
            'device_type': device_type,
            'category_id': this.FacilityAvailableFetatures.category_id,
            'rate_type_id': this.FacilityAvailableFetatures.rate_type_id,
            'early_bird_flag': this.FacilityAvailableFetatures.early_bird_flag,
            'entry_time_end': this.FacilityAvailableFetatures.entry_time_end
          };
        }
        if (this.notflatrate == false) {
          //alert('1');
          //alert(this.flatapplyorginalprice);
          let actual_amount= this.flatapplyorginalprice.toString().replace(",", "");
          actual_amount=actual_amount.replace("$", "");
          formDetail['actual_amount'] = actual_amount;
        }
        else {
          //alert('2');
          //alert(this.amt);
          let actual_amount= this.amt.toString().replace(",", "");
          actual_amount=actual_amount.replace("$", "");
          formDetail['actual_amount'] = actual_amount;
        }

        console.log('formDetail', formDetail);
        let total_price_apply = 0;

        if (this.is_promocode_applied) {
          total_price_apply = this.processFess + parseFloat((this.applyDotInPrice(this.amt).replace('$', '')))
        }
        if (this.is_bonus_hour_applied) {
          total_price_apply = total_price + parseFloat((this.applyDotInBonus(this.bonus_rate).replace('$', '')))
        }

        if (this.is_promocode_applied) {
          formDetail.is_promocode_redeemed = true;
          formDetail.promocode = this.promocode.promocode.promocode;
          formDetail.discount_type = this.promocode.promocode.discount_type;
          formDetail.redeemed_amount = this.discountAmount;
          if (total_price_apply < this.discountAmount) {
            p = 1
            formDetail.redeemed_amount = this.processFess + parseFloat((this.applyDotInPrice(this.amt).replace('$', '')))
          }
          formDetail.redeemed_amount = this.actualPromocodeApplied
        }

        if (parseInt(this.selectedCredit) > 0) {
          formDetail.is_wallet_redeemed = true;
          formDetail.redeemed_amount_credit = this.selectedCredit;
        }

        if (this.is_wallet_applied) {
          formDetail.is_wallet_redeemed = true;
          formDetail.redeemed_amount = this.discountAmount;
        }

        if (this.isLoggedIn) {
          formDetail.user_id = this.activeUser.data.id;
        }

        if (this.is_bonus_hour_applied) {
          formDetail.use_bonus = 1;
          if (total_price_apply < this.discountAmount) {
            p = 1
            //formDetail.redeemed_amount=formDetail.redeemed_amount+parseFloat((this.applyDotInBonus(this.bonus_rate).replace('$','')))
          }

        }
      } else {
        isError = true;
      }
      if (p == 1) {
        //formDetail.redeemed_amount=formDetail.redeemed_amount-total_price;
      }
      console.log('formDetail', formDetail);
      if (isError === false) {
        this.is_paying = true;
        this.reservationServObj.makeDailyReservation(formDetail)
          .subscribe(
            data => {
              this.parseData = data;
              this.flushLandingPagePromoCodeSession();
              this.is_paying = false;
              let getStart_date = this.arrival_time.split(' ');
              let get_Start_date = getStart_date[0];
              let formEvent = {
                'event': 'daily reservation',
                'reservationCode': this.parseData.data.reservation.ticketech_code,
                'value': this.parseData.data.reservation.total,
                'location': this.parseData.data.reservation.facility.full_name,
                'id': this.facId,
                'userId': this.getUserId,
                'category': 'Daily',
                'email': this.search_user_email,
                'FacilityPageLocation': this.routerObj.url,
                'startDate': get_Start_date,
                'duration': this.parseData.data.reservation.original_length + ' Hours',
                'neighborhood': this.neighborhood_name_for_event
              }

              let reservation_id = this.parseData.data.reservation.id;
              /* commented on 15-6-2021 as discussed with jitender  */
              //   ga("ec:addProduct", {
              //     "id": reservation_id,
              //     "name": this.parseData.data.reservation.facility.full_name,
              //     "price": this.parseData.data.reservation.original_rate,
              //     "brand": "BookIT",
              //     "category": "Daily parking",
              //     "variant": "Parking",
              //     "quantity": 1
              // });
              let device_type = 'web';
              if ($(window).width() < 767) {
                device_type = 'responsive';
              } else { device_type = 'web'; }
              let gtag_promocode = "";
              if (this.is_promocode_applied) {

                gtag_promocode = this.promocode.promocode.promocode;
              }
              // gtag("event", "purchase", {
              //   "language": "EN",
              //   "page_location": window.location.href,
              //   "page_title": "NYC Parking - Book Daily +  Monthly Parking Online and Save More! | IconParkingSystems.com",
              //   "transaction_id": this.parseData.data.reservation.ticketech_code,
              //   "currency": "USD",
              //   "tax": 5,
              //   "value": this.parseData.data.reservation.total,
              //   "coupon": gtag_promocode,
              //   "neighborhood": this.neighborhood_name_for_event,
              //   "FacilityPageLocation": this.routerObj.url,
              //   "startDate": get_Start_date,
              //   "duration": this.parseData.data.reservation.original_length + ' Hours',
              //   "user_id": this.getUserId,
              //   "items": [{
              //     "item_id": this.parseData.data.reservation.facility_id,
              //     "item_name": this.parseData.data.reservation.facility.full_name,
              //     "price": this.parseData.data.reservation.total,
              //     "quantity": 1,
              //     "item_brand": "Icon-Parking",
              //     "item_category": "Daily",
              //     "item_variant": "NA",
              //     "index": 0,
              //     "size": "M"
              //   }]
              // });




              /* commented for ga 16-6-2021 by seo team
              ga("Daily-payment-success", {
                "id": reservation_id,
                "name": this.parseData.data.reservation.facility.full_name,
                "category": "Daily parking",
                "quantity": 1,
                "device_details":device_type
              });
              */
              // ga('ec:setAction', 'purchase', {         
              //   'id': reservation_id,                      
              //   'affiliation': 'Icon Parking', 
              //   'revenue':this.parseData.data.reservation.original_rate,                                 
              // });
              /* uncommented for ga 16-6-2021 by seo team  for ga */
              // ga('send', 'event', { 
              //   eventCategory: "Daily_Payment_Success ",
              //   eventAction: 'user id : '+this.getUserId + ', facility iD: '+this.facId + ' Click',
              //   eventLabel: this.parseData.data.reservation.ticketech_code,
              //   eventValue: this.parseData.data.reservation.original_rate
              // });

              // kochava.activity('complete_reservation', {
              //   reservationCode: this.parseData.data.reservation.ticketech_code,
              //   reservation_id: this.parseData.data.reservation.id,
              //   facility_name : this.parseData.data.reservation.facility.full_name,
              //   price : this.parseData.data.reservation.original_rate,
              //   Location  :this.parseData.data.reservation.facility.short_name
              // }); 

              let reservation_status = '1';
              let errorMsg = ''
              this.updateReservationStatus(reservation_id, reservation_status);
              this.updateReservationEventLogStatus(this.getUserId, this.facId, this.respdata.full_name, this.getZipCode, errorMsg, this.parseData.data.reservation.ticketech_code);
              if ("account_card" in this.parseData.data) {
                this.toastr.error(this.parseData.data.account_card.message, 'Sorry!');
              }
              this.navigateConfirmation(this.parseData.data.reservation.id, formEvent, this.parseData.data.reservation.reservation_code);
            },
            error => {
              this.checksubmit = false;
              let errorMsg = '';
              this.is_paying = false;
              //this.resetDiscounts('reset');
              if (localStorage.getItem('is_landing_page')) {
                let actionType = localStorage.getItem('action_type');
                if (actionType == 'apply') {
                  setTimeout(() => {
                    //this.applyLandingPagePromocode();
                  }, 300);
                }
              }
              const errorsList = error.errors;
              if (errorsList.message !== 'Validation Errors') {
                if (errorsList.message == 'Please validate your email to use this promocode by using link we have sent on your email.') {
                  $('#showPop').trigger('click');
                } else {
                  this.messageErro = errorsList.message
                  if (this.messageErro.indexOf('authorize the credit card') > -1) {
                    this.showerrorMsg = 1;
                  } else {
                    this.showerrorMsg = 0;
                    this.toastr.error(errorsList.message, 'Sorry!');
                  }


                  // 
                }
                errorMsg = errorsList.message;
              } else {
                for (const key in errorsList.detail) {
                  if (errorsList.detail.hasOwnProperty(key)) {
                    if (typeof errorsList.detail === 'undefined') {
                      this.toastr.error(errorsList.message, 'Sorry!');
                      errorMsg = errorsList.message;
                    } else {
                      this.toastr.error(errorsList.detail[key], 'Sorry!');
                      errorMsg = errorsList.detail[key];
                      errorMsg = errorMsg[0];
                    }
                  }
                }
              }
             // alert('1')
              let getStart_date = this.arrival_time.split(' ');
              let get_Start_date = getStart_date[0];
              let formEvent = {
                'event': 'Daily_Payment_failed',
                'error_message': errorsList.message,
                'value': '',
                'location': '',
                'id': this.facId,
                'userId': this.getUserId,
                'category': 'Daily',
                'email': this.search_user_email,
                'FacilityPageLocation': this.routerObj.url,
                'startDate': get_Start_date,
                'duration': '',
                'neighborhood': this.neighborhood_name_for_event
              };
              (<any>window).dataLayer.push(formEvent);
              this.updateReservationEventLogStatus(this.getUserId, this.facId, this.respdata.full_name, this.getZipCode, errorMsg, '');
            }
          );
      } else {
        this.isLoaded = true;
        this.toastr.error(CustomCode.dailyFilterAlert, 'Sorry!');
      }
    }
  }

  flushLandingPagePromoCodeSession() {
    localStorage.removeItem('is_landing_page');
    localStorage.removeItem('template_slug');
    localStorage.removeItem('promotion_id');
    localStorage.removeItem('promocode');
    localStorage.removeItem('action_type');
    localStorage.removeItem('reservation_apply');
  }

  fetchUserDetails() {

    this.myAccountMonthlyParkingObj.fetchUser()
      .then(
        data => {
          this.activeUser = data;
          this.fetchUserWalletDetails();
          this.fetchUserPromocodes();
        },
        error => {
          this.isLoaded = true;
          this.toastr.error(error.errors.message, 'Sorry!');
        }
      );
  }

  logOutUser() {
    CustomCode.userLogout();
    CustomCode.displayLoginUser();
    window.location.reload();
  }

  makeCardPayment() {
    if (this.termsValid !== true || this.termsValid == undefined) {
      this.termsValid == false;
      this.showTermError = true;
      return;
    }
    // kochava.activity('click_book_it_button', {
    //   book_button : 'paywithSavedCard'
    // });
    if (this.is_paying) {
      return;
    }

    let startDate = new Date($('#startdate').val());
    let currentDate = new Date();
    let maxDate = new Date(currentDate);
    let maxRangeDate = new Date(startDate);
    maxDate.setDate(maxDate.getDate() + 89);
    maxRangeDate.setMonth(maxRangeDate.getMonth() + 1);
    maxRangeDate.setDate(maxRangeDate.getDate() - 1);
    let endDate = new Date($('#enddate').val());
    if (this.length_of_stay == 0) {
      this.toastr.error('You cannot book the reservation in the past', 'error');
    } else if (this.length_of_stay < 2) {
      //this.toastr.error('Minimum booking should be 2 hours', 'error');
      this.toastr.error("In order to book a reservation, booking duration must be at least 2 hours in the future", 'error');
    } else if (this.length_of_stay < 0) {
      this.toastr.error('End time should be greater than start time', 'error');
    }
    else if (startDate > maxDate) {
      this.toastr.error('You cannot book the reservation over 90 days', 'error');
    }
    else if (endDate > maxRangeDate) {
      this.toastr.error('Date range should be less than one month', 'error');
    }
    else {
      const selectedRadio = $('#existingcard input[name=selectedMethod]:checked').val();
      let formDetail: any = {};
      const beginningDate = this.arrivalTime();
      const description = 'Reservation for ' + $('#display_name').val() + ' - A beginning at ' + beginningDate + ' lasting for ' + this.length_of_stay;
      let isError = false;

      if (this.length_of_stay > 0) {

        if (this.warn_status == 1 && this.showWarningPopup == 1) {
          this.paywithcardPopup = 0;
          this.paywithsavecardPopup = 1;
          this.paywithrewardPopup = 0;
          let el: HTMLElement = this.myDiv.nativeElement as HTMLElement;
          el.click();
          return false;
        }
        else {
          this.paywithcardPopup = 0;
          this.paywithrewardPopup = 0;
          this.paywithsavecardPopup = 0;
          this.isPpopupShowMessage = '';
        }

        if (this.loggedUserName) {
          this.fetchUserDetails();
          let companyName = '';
          if (localStorage.getItem('companyName')) {
            companyName = localStorage.getItem('companyName')
          }
          let coupon_threshold_price_percentage = this.is_coupon_threshold_price_percentage;
          // let total_price=this.finalPrice // ye kal ka
          let finalPrice_data= this.finalPrice.toString().replace(",", "");
          finalPrice_data=finalPrice_data.replace("$", "");
          finalPrice_data=Math.round(finalPrice_data * 100) / 100
          //let total_price = this.changeFormatdata(this.formatter.format(this.finalPrice));
          let total_price =finalPrice_data;
          let device_type = 'web';
          if ($(window).width() < 767) {
            device_type = 'web responsive';
          } else { device_type = 'web'; }
          formDetail = {
            'arrival': beginningDate,
            'daytimesaverimpact': sessionStorage.getItem('is_extra'),
            'length': this.length_of_stay,
            'user_id': this.activeUser,
            'payment_profile_id': selectedRadio,
            'facility_id': this.facId,
            'description': description,
            'total': total_price,
            //'total': total_price.toFixed(2), ye kal ka,
            'availability': this.FacilityAvailableFetatures.availability,
            'total1': total_price,
            'savePaymentMethod': false,
            'use_bonus': 0,
            'companyName': companyName,
            'coupon_threshold_price': this.coupon_threshold_price,
            'is_coupon_threshold_price_percentage': coupon_threshold_price_percentage,
            'warning_on_reservation': this.warn_statusToSend,
            'warning_on_reservation_msg_email': this.warn_msg,
            'is_end_time_updated': (this.isUpdatedEndTimeFlag) ? 1 : 0,
            'original_length': this.defaultLengthOfStay,
            'is_card_req': this.isCardReq,
            'processing_fee': this.processFess,
            'device_type': device_type,
            'category_id': this.FacilityAvailableFetatures.category_id,
            'rate_type_id': this.FacilityAvailableFetatures.rate_type_id,
            'early_bird_flag': this.FacilityAvailableFetatures.early_bird_flag,
            'entry_time_end': this.FacilityAvailableFetatures.entry_time_end
          };
          if (this.notflatrate == false) {
            //alert('1');
            //alert(this.flatapplyorginalprice);
            let actual_amount= this.flatapplyorginalprice.toString().replace(",", "");
            actual_amount=actual_amount.replace("$", "");
            formDetail['actual_amount'] = actual_amount;
          }
          else {
            //alert('2');
            //alert(this.amt);
            let actual_amount= this.amt.toString().replace(",", "");
            actual_amount=actual_amount.replace("$", "");
            formDetail['actual_amount'] = actual_amount;
          }
          let total_price_apply = 0;
          let p = 0
          if (this.is_promocode_applied) {
            total_price_apply = this.processFess + parseFloat((this.applyDotInPrice(this.amt).replace('$', '')))
          }
          if (this.is_bonus_hour_applied) {
            total_price_apply = total_price + parseFloat((this.applyDotInBonus(this.bonus_rate).replace('$', '')))
          }
          if (parseInt(this.selectedCredit) > 0) {
            formDetail.is_wallet_redeemed = true;
            formDetail.redeemed_amount_credit = this.selectedCredit;
          }

          if (this.is_promocode_applied) {
            formDetail.is_promocode_redeemed = true;
            formDetail.promocode = this.promocode.promocode.promocode;
            formDetail.discount_type = this.promocode.promocode.discount_type;
            formDetail.redeemed_amount = this.discountAmount;
            if (total_price_apply < this.discountAmount) {
              p = 1;
              formDetail.redeemed_amount = this.processFess + parseFloat((this.applyDotInPrice(this.amt).replace('$', '')))
            }
            formDetail.redeemed_amount = this.actualPromocodeApplied
          }

          if (this.is_wallet_applied) {
            formDetail.is_wallet_redeemed = true;
            formDetail.redeemed_amount = this.discountAmount;
          }

          if (this.isLoggedIn) {
            formDetail.user_id = this.activeUser.data.id;
          }

          if (this.is_bonus_hour_applied) {
            // formDetail.use_bonus = this.bonus_hours;
            formDetail.use_bonus = 1;
            if (total_price_apply < this.discountAmount) {
              p = 1
              //formDetail.redeemed_amount=formDetail.redeemed_amount+parseFloat((this.applyDotInBonus(this.bonus_rate).replace('$','')))
            }
          }
          if (p == 1) {
            //formDetail.redeemed_amount=formDetail.redeemed_amount-total_price;
          }
          if (isError === false) {
            this.is_paying = true;
            this.reservationServObj.paymentFromSaveCard(formDetail)
              .subscribe(
                data => {
                  this.parseData = data;
                  this.flushLandingPagePromoCodeSession();
                  this.is_paying = false;
                  let getStart_date = this.arrival_time.split(' ');
                  let get_Start_date = getStart_date[0];
                  let formEvent = {
                    'event': 'daily reservation',
                    'reservationCode': this.parseData.data.reservation.ticketech_code,
                    'value': this.parseData.data.reservation.total,
                    'location': this.parseData.data.reservation.facility.full_name,
                    'id': this.facId,
                    'userId': this.getUserId,
                    'category': 'Daily',
                    'email': this.search_user_email,
                    'FacilityPageLocation': this.routerObj.url,
                    'startDate': get_Start_date,
                    'duration': this.parseData.data.reservation.original_length + ' Hours',
                    'neighborhood': this.neighborhood_name_for_event
                  }
                  let gtag_promocode = "";
                  if (this.is_promocode_applied) {
                    gtag_promocode = this.promocode.promocode.promocode;
                  }

                  // gtag("event", "purchase", {
                  //   "language": "EN",
                  //   "page_location": window.location.href,
                  //   "page_title": "NYC Parking - Book Daily +  Monthly Parking Online and Save More! | IconParkingSystems.com",
                  //   "transaction_id": this.parseData.data.reservation.ticketech_code,
                  //   "currency": "USD",
                  //   "tax": 5,
                  //   "value": this.parseData.data.reservation.total,
                  //   "coupon": gtag_promocode,
                  //   "neighborhood": this.neighborhood_name_for_event,
                  //   "FacilityPageLocation": this.routerObj.url,
                  //   "startDate": get_Start_date,
                  //   "duration": this.parseData.data.reservation.original_length + ' Hours',
                  //   "user_id": this.getUserId,
                  //   "items": [{
                  //     "item_id": this.parseData.data.reservation.facility_id,
                  //     "item_name": this.parseData.data.reservation.facility.full_name,
                  //     "price": this.parseData.data.reservation.total,
                  //     "quantity": 1,
                  //     "item_brand": "Icon-Parking",
                  //     "item_category": "Daily",
                  //     "item_variant": "NA",
                  //     "index": 0,
                  //     "size": "M"
                  //   }]
                  // });

                  let reservation_id = this.parseData.data.reservation.id;
                  /* commented on 15-6-2021 as discussed with jitender  */
                  // ga("ec:addProduct", {
                  //   "id": reservation_id,
                  //   "name": this.parseData.data.reservation.facility.full_name,
                  //   "price": this.parseData.data.reservation.original_rate,
                  //   "brand": "BookIT",
                  //   "category": "Daily parking",
                  //   "variant": "Parking",
                  //   "quantity": 1
                  // });
                  let device_type = 'web';
                  if ($(window).width() < 767) {
                    device_type = 'responsive';
                  } else { device_type = 'web'; }
                  /* commented for ga 16-6-2021 by seo team
                  ga("Daily-payment-success", {
                    "id": reservation_id,
                    "name": this.parseData.data.reservation.facility.full_name,
                    "category": "Daily parking",
                    "quantity": 1,
                    "device_details":device_type
                  });
                  */
                  // ga('ec:setAction', 'purchase', {         
                  //   'id': reservation_id,                      
                  //   'affiliation': 'Icon Parking', 
                  //   'revenue':this.parseData.data.reservation.original_rate,                                 
                  // });
                  /* commented for ga 16-6-2021 by seo team
                                ga('send', 'event', { eventCategory: "Daily payment Success", eventLabel:CustomCode.BASEURL+'payment' +' -- user-type:'+ this.getUserId, eventAction: 'click'});
                  */
                  let reservation_status = '1';
                  let errorMsg = '';
                  this.updateReservationStatus(this.parseData.data.reservation.id, reservation_status);
                  this.updateReservationEventLogStatus(this.getUserId, this.facId, this.respdata.full_name, this.getSavedZipcode, errorMsg, this.parseData.data.reservation.ticketech_code);
                  this.navigateConfirmation(this.parseData.data.reservation.id, formEvent, this.parseData.data.reservation.reservation_code);
                },
                error => {
                  let errorMsg = '';
                  this.is_paying = false;
                  this.resetDiscounts('reset');
                  if (localStorage.getItem('is_landing_page')) {
                    let actionType = localStorage.getItem('action_type');
                    if (actionType == 'apply') {
                      setTimeout(() => {
                        this.applyLandingPagePromocode();
                      }, 300);
                    }
                  }
                  const errorsList = error.errors;
                  if (errorsList.message !== 'Validation Errors') {
                    this.toastr.error(errorsList.message, 'Sorry!');
                    errorMsg = errorsList.message;
                  } else {
                    for (const key in errorsList.detail) {
                      if (errorsList.detail.hasOwnProperty(key)) {
                        if (typeof errorsList.detail === 'undefined') {
                          this.toastr.error(errorsList.message, 'Sorry!');
                          errorMsg = errorsList.message;
                        } else {
                          this.toastr.error(errorsList.detail[key], 'Sorry!');
                          errorMsg = errorsList.detail[key];
                          errorMsg = errorMsg[0];
                        }
                      }
                    }
                  }
                  
                  let getStart_date = this.arrival_time.split(' ');
                  let get_Start_date = getStart_date[0];
                  let formEvent = {
                    'event': 'Daily_Payment_failed',
                    'error_message': errorsList.message,
                    'value': '',
                    'location': '',
                    'id': this.facId,
                    'userId': this.getUserId,
                    'category': 'Daily',
                    'email': this.search_user_email,
                    'FacilityPageLocation': this.routerObj.url,
                    'startDate': get_Start_date,
                    'duration': '',
                    'neighborhood': this.neighborhood_name_for_event
                  };
              (<any>window).dataLayer.push(formEvent);
                  this.updateReservationEventLogStatus(this.getUserId, this.facId, this.respdata.full_name, this.getSavedZipcode, errorMsg, '');
                }
              );
          } else {
            this.isLoaded = true;
            this.toastr.error(CustomCode.dailyFilterAlert, 'Sorry!');
          }
        }
      } else {
        isError = true;
      }
    }
  }

  updatePaymentMethod(paymentProfile, fetchzipcode) {
    // console.log(fetchzipcode);
    this.getSavedZipcode = fetchzipcode;
    // if (paymentProfile === 'new') {
    //   this.newPayment = true;
    //   this.paymentProfileToPay = false;
    // } else {
    this.showCardFillFormButton = false
    this.newPayment = false;
    this.paymentProfileToPay = paymentProfile;
    this.expandCardForm = false;
    // }
  }

  // resetForm() {
  //   $('form[name="paymentForm"]')[0].reset();
  // }

  applyPromoCode(promocode) {
    //alert('apc');
    let data = {};
    let is_auto_apply = 0;
    let extra_hours = 0;
    if (localStorage.getItem('is_landing_page')) {
      is_auto_apply = 1;
    } else {
      is_auto_apply = 0;
    }
    if (this.is_bonus_hour_applied) { extra_hours = this.bonus_hours }
    if (this.isLoggedIn) {

      if (localStorage.getItem('action_type') == 'apply' && localStorage.getItem('is_show_landing_strip') == 'true' && sessionStorage.getItem('promoApplyPriority')) {
        promocode = sessionStorage.getItem('promoApplyPriority');
      }
      data = {
        'promocode': promocode,
        'user_id': this.activeUser.data.id,
        'is_auto_apply': is_auto_apply,
        'reservation_start_date': this.arrivalTime(),
        'reservation_end_date': this.endlTime(),
        'is_payment_page': 1,
        'deviceType': 'web',
        'extra_hours': extra_hours,
        'total': this.amt
      }
    } else {
      data = {
        'promocode': promocode,
        'email': $('#eemail').val(),
        'is_auto_apply': is_auto_apply,
        'reservation_start_date': this.arrivalTime(),
        'reservation_end_date': this.endlTime(),
        'is_payment_page': 1,
        'deviceType': 'web',
        'extra_hours': extra_hours,
        'total': this.amt

      }
    }
    console.log(this.endlTime());
    let appliedPromocode = promocode
    
    this.userServiceObj.validatePromoCode(data)
      .then(
        data => {
          if (!data['errors']) {
            localStorage.setItem('appliedPromocode', appliedPromocode)
            this.promocode = data['data'];
            if (this.promocode.is_promocode_valid) {
              //this.toastr.success('Your promo code was successfully applied.', 'Great!');

              // kochava.activity('Coupon_Applied', {
              //   promocode: promocode,
              // });

              $('#testing').val(promocode)
              console.log('applyPromoCode 4')
              //alert('step 4');
              this.applyPromocode(data['data']);
              this.checkShow = false;
              //this.checkShow=true;
              if (!this.isLoggedIn && this.promocode.is_opt_out == 1) {
                this.checkShow = true;
              }
              if (!this.isLoggedIn && this.promocode.is_promocode_success && this.promocode.is_opt_out == 1) {
                this.checkShow = true;
                $('#showPop').trigger('click');
              }

            } else {
              this.promocode_text = '';
              this.toastr.error(this.promocode.message.replace("Sorry!", ""), 'Sorry!');

              if (this.isLoyaltyMember && this.loyaltyData.promoCodeApply) {  // Changes for new promoCode implementation
                this.isDefaultPromoCode = true;
                if (this.OldPromocode != '') {
                  $('input[name="promocode"]').val(this.promocodeIconReward)

                  $('.couponApplyButton').trigger('click')
                }
              } else {

                localStorage.removeItem('couponSave'); // ye htana h if any issue
              }
            }
          } else {


            this.promocode_text = '';
            $('input[name="promocode"]').val('')
            this.toastr.error(data['errors'].message, 'Sorry!');
            if (this.loyaltyData.promoCodeApply && this.isLoyaltyMember) {  // Changes for new promoCode implementation
              this.isDefaultPromoCode = true;
              if (this.OldPromocode != '') {
                $('input[name="promocode"]').val(this.promocodeIconReward)
                $('.couponApplyButton').trigger('click')

              }
            }
            localStorage.removeItem('couponSave');
          }
        },
        error => {
          
          this.promocode_text = '';
          this.isLoaded = true;
          $('input[name="promocode"]').val('')
          this.toastr.error(error.errors.message, 'Sorry!');
          if (this.isLoyaltyMember && this.loyaltyData.promoCodeApply) {  // Changes for new promoCode implementation
            this.isDefaultPromoCode = true;
            this.ErrorInPromoCode = true;
            if (this.OldPromocode != '') {
              $('input[name="promocode"]').val(this.promocodeIconReward)
              $('.couponApplyButton').trigger('click')

            }
            //this.applyCoupon(8);
          } else {
            this.ErrorInPromoCode = true;
            this.isDefaultPromoCode = true;
            if (this.OldPromocode != '') {
              $('input[name="promocode"]').val(this.promocodeIconReward)
              $('.couponApplyButton').trigger('click')

            }
          }

          localStorage.removeItem('couponSave');
        }
      );
  }

  selectDiscountMethod(method) {
    if (method === 'wallet') {
      this.resetDiscounts('wallet');
    } else if (method === 'promocode') {

      if (this.is_promocode_redeemed)
        this.resetDiscounts('promocode');
      else
        this.isOn = false;
    } else {
      this.is_wallet_redeemed = false;
      this.is_promocode_redeemed = false;
    }


    if (this.is_promocode_redeemed == true) {
      this.isOn = true;
    } else {
      this.isOn = false;
    }
  }

  applyWallet() {
    if (parseInt(this.wallet.balance) < parseInt(this.walletConfig.WALLET_REDEEM_LIMIT)) {
      this.toastr.error('You do not have enough credits', 'Sorry!');
    } else {

      this.is_wallet_applied = true;
      this.discountAmount = parseInt(this.walletConfig.WALLET_REDEEM_LIMIT);
      this.finalStoreValue = this.finalPrice = this.finalPrice - this.discountAmount;


    }
  }

  // removeWallet() {
  //     this.discountAmount = parseInt(this.walletConfig.WALLET_REDEEM_LIMIT);
  //     this.finalPrice = this.finalPrice + this.discountAmount;

  // }



  applyPromocode(promocode) {
    $('#range').val('0');
    $('#rangerValue').val('0');
    this.selectedCredit = 0;

    //alert('gg');
    console.log('promocode', promocode);
    //if (!this.is_promocode_applied) {
    //alert('1');
    this.is_promocode_applied = true;
    this.is_promocode_applied_already = true;
    let discountType = this.promocode.promocode.discount_type;
    let discountValue = this.promocode.promocode.discount_value;
    this.minTransactionAmount = this.promocode.dollar_min_discount;
    if (discountType === 'value') {
      if ((this.minTransactionAmount > 0 && this.finalPrice >= this.minTransactionAmount) || (this.minTransactionAmount <= 0)) {
        this.amt = this.storedarivalprice;
        this.promocodeValue = this.discountAmount = parseFloat(discountValue);
        this.notflatrate = true;
        this.finalStoreValue = this.finalPrice = this.finalPrice - this.discountAmount;


        this.changeZoom(0)
        if (this.showNotification) {
          //alert('1')
          this.toastr.success('Your promo code was successfully applied.', 'Great!');
          this.showNotification = false;
        }
      } else {
        this.is_promocode_applied = false;
        this.toastr.error(this.promocode.minimun_amount_error_msg, 'Sorry!');
        localStorage.removeItem('couponSave');
        if (this.loyaltyData.promoCodeApply && this.isLoyaltyMember) {  // Changes for new promoCode implementation
          this.isDefaultPromoCode = true;
          this.applyCouponError(10);
        }

      }
    }

    if (discountType === 'percentage') {
      this.amt = this.storedarivalprice;
      this.percentage_maximum_discount = this.promocode.percentage_max_discount;
      let amt_temp_fordiscount=this.amt.replace(',','');
      let couponValue = (parseFloat(amt_temp_fordiscount) * parseFloat(discountValue)) / 100;
      let couponValueDefault = (parseFloat(amt_temp_fordiscount) * parseFloat(discountValue)) / 100;
      if (this.percentage_maximum_discount > 0 && couponValue > this.percentage_maximum_discount) {
        couponValue = this.percentage_maximum_discount;
      }
      let vale = this.formatter.format(couponValue)
      let dataDiscount = parseFloat(vale.replace('$', ''))
      this.notflatrate = true;
      this.promocodeValue = this.discountAmount = dataDiscount;
      this.finalStoreValue = this.finalPrice = this.finalPrice - this.discountAmount;
      this.changeZoom(0)
      if (couponValueDefault > couponValue) {
        this.toastr.warning(this.promocode.max_percentage_amount_error_msg, 'Warning!');
        if (this.loyaltyData.promoCodeApply && this.isLoyaltyMember) {  // Changes for new promoCode implementation
          this.isDefaultPromoCode = true;
          // this.applyCouponError(1);
        }
      } else {
        if (this.showNotification) {
          //alert(this.firsttimepromocodeload)
          if (promocode.promocode.promocode != "ICONREWARDS10") {
            //alert('2')
            this.toastr.success('Your promo code was successfully applied.', 'Great!');
          }
          //this.firsttimepromocodeload=1; 
        }
      }
    }
    if (discountType === 'flat_rate') {
      //alert('step 5');
      if (this.promocode.is_promocode_valid) {
        this.notflatrate = false;
        this.promocodeValue = 0;
        this.actualPromocodeApplied = 0;

        this.flatrateappliedstatus = true;
        this.flatapplyorginalprice = this.amt;
        this.amt = this.promocode.is_flat_rate;
        this.finalPrice = this.promocode.is_flat_rate;
        if (this.is_bonus_hour_applied) {
          //alert('h')
          this.finalPrice = parseFloat(this.promocode.is_flat_rate) + parseFloat(this.bonus_rate);
          //this.changerange(this.selectedCredit)
          //this.changeafterZoom(this.selectedCredit);
        }



        //this.changeZoom(0)
        if (this.showNotification) {
          //alert('3')
          this.toastr.success('Your promo code was successfully applied.', 'Great!');
          //this.showNotification=false;
        }
      } else {
        this.is_promocode_applied = false;
        if (!this.promocode.is_promocode_valid) {
          this.toastr.error(this.promocode.message, 'Sorry!');
        }

        //this.toastr.error(this.promocode.max_percentage_amount_error_msg, 'Sorry!');
        localStorage.removeItem('couponSave');
        if (this.loyaltyData.promoCodeApply && this.isLoyaltyMember) {  // Changes for new promoCode implementation
          this.isDefaultPromoCode = true;
          this.applyCouponError(10);
        }

      }
    }
    //}
  }
  removePromocode(promocodestatus, num = 20, currentPromocode = '') {
    if (this.flatrateappliedstatus) {
      this.amt = this.flatapplyorginalprice;
      this.finalPrice = this.flatapplyorginalprice;
      this.flatrateappliedstatus = false;
      localStorage.removeItem('couponSave');
    }
    sessionStorage.removeItem('promoApplyPriority');
    if (num == 1) {
      localStorage.removeItem('couponSave');
      if (this.notflatrate == false) {
        this.notflatrate = true;
        this.isRemovedPromoCode = false;
      }
      this.prmcdNme = localStorage.getItem('appliedPromocode')//this.promocode.promocode.promocode;
      this.creditapplyremove = 1;
    } else {
      this.creditapplyremove = 0;
    }
    if (num == 2) {
      this.bonusclick = 0;
    }
    if (promocodestatus == true) {
      this.isRemovedPromoCode = true; // Changes for new promoCode implementation
      this.promocode_text = '';

    }
    this.checkShow = false;
    let existingPromocode = this.promocode.promocode.promocode;

    this.promocode = {};

    let text: any = parseFloat(this.finalPrice) + parseFloat(this.actualPromocodeApplied)
    this.finalStoreValue = this.finalPrice = text;

    this.actualPromocodeApplied = 0
    this.discountAmount = 0;
    this.is_promocode_applied = false;
    this.isDefaultPromoCode = false;
    this.is_promocode_redeemed = true;
    if (this.isLoggedIn) {
      if (this.userPromocodes.promocodedetail.length > 0) {
        this.showDropdown = true;
        this.updatePromocodeDropdown();
      }
    }
    if (promocodestatus == true) {
      if (this.isLoyaltyMember && currentPromocode !== 'ICONREWARDS10') {
        $('input[name="promocode"]').val('ICONREWARDS10');
        $('.couponApplyButton').trigger('click')

        // $('input[name="promocode"]').val('ICONREWARDS10')
      } else {
        $('input[name="promocode"]').val('');
      }
    }
    // this.changeZoom(this.selectedCredit)
    // Changes for new promoCode implementation
    //console.log(this.isRemovedPromoCode)
    //console.log(this.isLoyaltyMember)
    if (this.isRemovedPromoCode && this.isLoyaltyMember && (existingPromocode != this.loyaltyData.promoCodeApply.toUpperCase())) {
      this.isDefaultPromoCode = true;
      //alert('here it is')
      this.applyCoupon(2);
    } else if (this.isRemovedPromoCode && this.isRemovePromocodeDone && this.isLoyaltyMember) {
      //console.log("asas"+ existingPromocode);
      this.isRemovePromocodeDone = false;
      this.isDefaultPromoCode = false;
      if (this.isLoyaltyMember && currentPromocode !== 'ICONREWARDS10') {
        $('input[name="promocode"]').val('ICONREWARDS10');
        if (existingPromocode != 'ICONREWARDS10') {
          $('.couponApplyButton').trigger('click')
        }
      } else {
        $('input[name="promocode"]').val('');
      }

      // this.applyCoupon();
    }
    //   if(this.isLoyaltyMember && currentPromocode !== 'ICONREWARDS10'){
    //   setTimeout(() => {
    //     $('input[name="promocode"]').val(''+ existingPromocode +'');
    //     $('input[id="promocode"]').prop('checked', true);

    //   }, 0);
    //   localStorage.removeItem('couponSave');
    // }

  }


  // removePromocode(promocodestatus,num=20,currentPromocode ='') {
  //   sessionStorage.removeItem('promoApplyPriority');
  //   if(num==1)
  //   {
  //     this.prmcdNme=localStorage.getItem('appliedPromocode')//this.promocode.promocode.promocode;
  //     this.creditapplyremove=1;
  //   }else{
  //     this.creditapplyremove=0;
  //  }
  //   if(num==2)
  //   {
  //     this.bonusclick=0;
  //   }
  //   if(promocodestatus == true){
  //     this.isRemovedPromoCode = true; // Changes for new promoCode implementation
  //     this.promocode_text = '';

  //   }
  //   this.checkShow=false;
  //   let existingPromocode = this.promocode.promocode.promocode;

  //   this.promocode = {};
  //   let text:any =parseFloat(this.finalPrice) + parseFloat(this.actualPromocodeApplied)
  //   this.finalStoreValue=this.finalPrice = text;

  //   this.actualPromocodeApplied=0
  //   this.discountAmount = 0;
  //   this.is_promocode_applied = false;
  //   this.isDefaultPromoCode = false;
  //   this.is_promocode_redeemed = true;
  //   if (this.isLoggedIn) {
  //       if (this.userPromocodes.promocodedetail.length > 0) {
  //         this.showDropdown = true;
  //         this.updatePromocodeDropdown();
  //       }
  //     }
  //   if(promocodestatus == true){
  //       if(this.isLoyaltyMember)
  //       {
  //         $('input[name="promocode"]').val('ICONREWARDS10');
  //         if(existingPromocode != 'ICONREWARDS10') {
  //           $('.couponApplyButton').trigger('click')
  //         }

  //        // $('input[name="promocode"]').val('ICONREWARDS10')
  //       } 
  //     }
  //   // this.changeZoom(this.selectedCredit)
  //   // Changes for new promoCode implementation
  //   //console.log(this.isRemovedPromoCode)
  //   //console.log(this.isLoyaltyMember)
  //   if(this.isRemovedPromoCode && this.isLoyaltyMember && (existingPromocode != this.loyaltyData.promoCodeApply.toUpperCase())){  
  //     this.isDefaultPromoCode = true;
  //     this.applyCoupon(2);
  //   }else if(this.isRemovedPromoCode && this.isRemovePromocodeDone  && this.isLoyaltyMember){
  //     //console.log("asas"+ existingPromocode);
  //     this.isRemovePromocodeDone=false;
  //     this.isDefaultPromoCode = false;
  //     $('input[name="promocode"]').val('ICONREWARDS10');
  //     if(existingPromocode != 'ICONREWARDS10') {
  //       $('.couponApplyButton').trigger('click')
  //     }
  //     // this.applyCoupon();
  //   }
  //   setTimeout(() => {
  //     $('input[name="promocode"]').val(''+ existingPromocode +'');
  //     $('input[id="promocode"]').prop('checked', true);

  //   }, 0);
  //   localStorage.removeItem('couponSave');
  // }

  fetchUserPromocodes() {
    this.isLoaded = false;
    const data = {
      'user_id': this.activeUser.data.id
    };
    this.userServiceObj.fetchMyPromoCodes(data)
      .then(
        data => {
          this.isLoaded = true;
          if (data['data'].promocodedetail != null) {
            this.userPromocodes = data['data'];
            this.updatePromocodeDropdown();
          } else {
            this.userPromocodes.promocodedetail = {};
          }
          if (this.userPromocodes.promocodedetail.length > 0) {
            this.showDropdown = true;
          }
        },
        error => {
          this.isLoaded = true;
          this.userPromocodes.promocodedetail = {};
          this.toastr.error(error.errors.message, 'Sorry!');
        }
      );
  }

  updatePromocodeDropdown() {
    setTimeout(() => {
      $('select#userPromocodes').html('');
      $('select#userPromocodes').append('<option value="-1" selected="selected">Add Promo Code from List</option>');
      for (var i = 0; i < this.userPromocodes.promocodedetail.length; i++) {
        $('select#userPromocodes').append('<option value="' + this.userPromocodes.promocodedetail[i].promocode + '">' + this.userPromocodes.promocodedetail[i].promocode + '</option>');
      }
    }, 5000);
  }

  onChangePromocodeDropdown(event) {
    if ($('select#userPromocodes').val().toString() != '-1') {
      let selectedPromocode = $('select#userPromocodes').val();
      $('input[name="promocode"]').val(selectedPromocode);
      // this.showDropdown = false;
      $('td#selectPromocode').find('button').trigger('click');
    } else {
      this.toastr.error('Please select a valid Promo Code', 'Sorry!');
    }
  }

  resetDiscounts(method) {
    if (this.is_bonus_hour_applied) {
      this.finalPrice = parseInt(this.amt);
      this.finalStoreValue = this.finalPrice = this.finalPrice + parseInt(this.bonus_rate) - this.selectedCredit;

    } else {
      this.finalStoreValue = this.finalPrice = parseFloat(this.amt) - this.selectedCredit;

    }
    this.discountAmount = 0;
    this.is_wallet_applied = false;
    this.is_promocode_applied = false;
    if (method === 'wallet') {
      this.is_wallet_redeemed = true;
      this.is_promocode_redeemed = false;
      this.applyWallet();
    } else if (method === 'promocode') {
      if (this.is_promocode_redeemed && !this.isLoggedIn) {
        this.unselectPromocodeRadio();
      } else {
        if (this.isLoggedIn) {
          if (this.userPromocodes.promocodedetail.length > 0) {
            this.showDropdown = true;
          }
          this.updatePromocodeDropdown();
        }
        this.is_wallet_redeemed = false;
        this.is_promocode_redeemed = true;
      }
    } else {
      this.is_wallet_redeemed = false;
      this.is_promocode_redeemed = false;
      $('#selectWallet input[name="selectDiscountMethod"]').prop('checked', false);
      $('#selectPromocode input[name="selectDiscountMethod"]').prop('checked', false);
      this.promocode = {};
      this.showDropdown = false;
    }
  }
  changerange(value: any) {
    if (value == '') {
      value = 0;
      $('#range').val(value)
      $('#rangerValue').val(value)
    }
    value = parseInt(value);
    if (value > this.maxrange) {
      value = 0;
      $('#range').val(value)
      $('#rangerValue').val(value)
    }
    if (value < 0) {
      value = 0;
      $('#range').val(value)
      $('#rangerValue').val(value)
    }
    if (value == 0) {
      this.showSliderDescription = false;
    } else {
      this.showSliderDescription = true;
    }

    this.showSliderDescriptionValue = value;
    this.totalrange = parseFloat(this.actualPrice);
    if (this.is_bonus_hour_applied) {
      this.totalrange = this.totalrange + parseInt(this.bonus_rate)
    }
    if (this.is_promocode_applied) {
      this.totalrange = this.totalrange - this.promocodeValue
    }
    let amount_value: any = this.totalrange

    if (parseInt(this.wallet.balance) < parseInt(amount_value)) {
      this.maxrange = this.wallet.balance
    }
    this.maxrange = Math.floor(this.maxrange)
    $('#range').val(value)
    $('#rangerValue').val(value)
    this.selectedCredit = value;
    this.changeZoom(value)

  }
  changeZoom(value: any) {
    if (this.notflatrate == false && value > 0) {



      this.flatrateappliedstatus = false;

      this.amt = this.flatapplyorginalprice;
      this.finalPrice = this.flatapplyorginalprice;

    }
    if (this.is_promocode_applied && value > 0) {
      this.isRemovePromocodeDone = false
      this.isDefaultPromoCode = false;
      this.removePromocode(false, 1);



    }


    if (parseInt(value) < 0) {
      value = 0;
      $('#range').val(value)
      $('#rangerValue').val(value)
    }
    if (value == 0) {
      this.showSliderDescription = false;
    } else {
      this.showSliderDescription = true;
    }

    this.showSliderDescriptionValue = value;
    this.totalrange = parseFloat(this.actualPrice);
    if (this.is_bonus_hour_applied) {
      this.totalrange = this.totalrange + parseInt(this.bonus_rate)
      //value=parseInt(value)+5;
    }
    if (this.processFess > 0) {
      this.totalrange = this.totalrange + this.processFess // ye krna h
    }
    if (this.is_promocode_applied) {
      this.totalrange = this.totalrange - this.promocodeValue

    }
    let amount_value: any = this.totalrange

    if (parseInt(this.wallet.balance) < parseFloat(amount_value)) {
      this.maxrange = this.wallet.balance

    }
    else {
      this.maxrange = this.totalrange
    }
    if (amount_value < this.maxrange) {
      this.maxrange = amount_value;
      value = this.maxrange
    }
    this.maxrange = Math.floor(this.maxrange)

    //this.totalrange=Math.floor(this.totalrange)

    let amount = this.totalrange

    this.selectedCredit = value;
    $('#range').val(this.selectedCredit)
    $('#rangerValue').val(this.selectedCredit)
    //console.log(this.isRemovePromocodeDone);
    setTimeout(() => { // Changes for new promoCode implementation
      if (!this.isRemovedPromoCode && this.selectedCredit == 0 && !this.isRemovePromocodeDone) {
        //alert('1');
        // console.log("hello");
        if (this.defaultNotset) {
          //alert('2');
          this.isDefaultPromoCode = true;
        } else {
          //alert('3');
          this.isDefaultPromoCode = false;
        }

        if (this.OldPromocode != '') {
          //alert('4');

          $('.couponApplyButton').trigger('click')

        }
      } else {

        this.isRemovedPromoCode = false;
      }
    }, 1000)


    if (this.maxrange < 0) {
      this.maxrange = 0;
    }
    this.finalPrice = (amount - value)


    if (this.finalPrice < 0) {
      var check = (Math.abs(this.finalPrice) * 1)

      var ceil = Math.ceil(check)
      this.finalPrice = (ceil - check)

      this.finalPrice = 0
      this.selectedCredit = this.maxrange
      $('#range').val(this.selectedCredit)
      $('#rangerValue').val(this.selectedCredit)
    }
    if (this.is_bonus_hour_applied) {

    }
    this.showSliderDescriptionValue = this.selectedCredit;
    let totaladdprice = this.finalPrice + this.processFess;
    if (this.finalPrice <= 0) {
      this.isCardReq = false;
    } else {
      this.isCardReq = true;
    }

    if (this.is_promocode_applied) {
      if (this.finalPrice != 'N/A') {
        this.thisActualFinalPrice = parseFloat(this.finalPrice).toFixed(2);
      } else {
        this.thisActualFinalPrice = 1000000
      }

      if (parseFloat(this.thisActualFinalPrice) < 1000000) {
        this.updatedAmountFinal = this.updatedAmountFinal - parseFloat(this.thisActualFinalPrice)


        if (this.updatedAmountFinal < this.discountAmount) {
          this.actualPromocodeApplied = this.updatedAmountFinal
          this.actualPromocodeApplied = this.discountAmount
        } else {
          this.actualPromocodeApplied = this.discountAmount
        }
      }

    }

  }
  changeafterZoom(value: any) {

  }

  setToexpirationField(reference) {
    this.formData.exp1 = reference.value;
  }

  // ;
  updateReservationStatus(reservation_id, reservation_status) {
    /* reservation tracking  */
    this.reservationServObj.updateReservation(reservation_id, reservation_status).subscribe(
      data => {
        this.reservationData = data
      },
      error => {

      }
    )
  }

  updateReservationEventLogStatus(userId, facility_id, facility_name, getZipCode, errorMsg, iqcode) {
    let device_type = 'web';
    if ($(window).width() < 767) {
      device_type = 'responsive';
    } else { device_type = 'web'; }

    let eventName = 'Facility Booked with ';
    if (this.is_bonus_hour_applied) {
      eventName = 'Extra Hour Checked Facility Booked with ';
    }
    let currentLatitude = (localStorage.getItem('currentLatitude')) ? localStorage.getItem('currentLatitude') : '';
    let currentLongitude = (localStorage.getItem('currentLongitude')) ? localStorage.getItem('currentLongitude') : '';
    let reservation_status_data = {
      user_id: userId,
      event_name: eventName + ' $' + this.finalPrice,
      latitude: currentLatitude,
      longitude: currentLongitude,
      facility_id: facility_id,
      facility_name: facility_name,
      zipcode: getZipCode,
      app_version: "",
      error_message: errorMsg,
      device: "web",
      device_detail: device_type,
      os_version: "",
      booking_code: iqcode,
      email_id: this.userEmailForLog
    }
    if (errorMsg == '') {
      let emailForTrack = '0';
      let userIdForTrck = '0';
      if (userId) {
        userIdForTrck = userId;
      }
      if (this.userEmailForLog) {
        emailForTrack = this.userEmailForLog;
      }


      if (1) {
        // window.VWO = window.VWO || []; 
        // let price=parseFloat(this.finalPrice)
        // window._vis_opt_queue = window._vis_opt_queue || [];
        // window._vis_opt_queue.push(function() {_vis_opt_revenue_conversion(price);}); 
        // window.VWO.push(['track.revenueConversion',price]);
      }
    }
    this.reservationServObj.updateReservationEventLog(reservation_status_data).subscribe(
      data => {
        this.reservationDataEventLog = data
      },
      error => {

      }
    )
  }

  showCardFillForm() {
    /* click book it button */
    // kochava.activity('click_book_it_button', {
    //   book_button : 'paywithCard'
    // });

    if ($('#cardExpand').attr('class') == 'collapse') {
      $('#toogleForm').trigger('click')
    }
    $('.showeroor').trigger('click');
  }
  removeCard(id, idName, message) {
    //return false;
    let mes = "Are you sure you want to remove the card ending with (" + message.replace('XXXX', '..') + ")?";
    let confirmOption = confirm(mes)
    if (confirmOption) {
      this.userServiceObj.deleteUserCard(id).subscribe(
        res => {
          if ($('.cardReservation').length == 1) {

            this.showMethodHeading = false;
          } else {
            this.showMethodHeading = true;
          }
          $('#cards' + idName).parent().hide();
          //this.fetchUserCards();
        }
      )
    }
  }

  restSpace(event: any) {
    if (event.keyCode == 32) {
      event.preventDefault();
    }
  }

  openPopupPayment(overnightPopupPayment) {
    this.modalRef = this.modalService.show(overnightPopupPayment, this.config);
  }
  hideModel() {
    let closepupup: HTMLElement = this.bookerrormsgpopupclose.nativeElement as HTMLElement;
    closepupup.click();
  }

  redirectTopaymentfromPopup() {
    let closepupup: HTMLElement = this.bookerrormsgpopupclose.nativeElement as HTMLElement;
    closepupup.click();

    this.warn_status = 0;
    if (this.paywithcardPopup == 1) {
      let bookwithcarde1: HTMLElement = this.bookwithcard.nativeElement as HTMLElement;
      bookwithcarde1.click();
    }
    if (this.paywithrewardPopup == 1) {
      let bookwithcarde2: HTMLElement = this.bookwithreward.nativeElement as HTMLElement;
      bookwithcarde2.click();
    }
    if (this.paywithsavecardPopup == 1) {
      let bookwithcarde3: HTMLElement = this.bookwithsavecard.nativeElement as HTMLElement;
      bookwithcarde3.click();
    }

  }


  reapplyCoupon() {
    //this.promocode_text='lucky17';
    let promocode = this.promocode_text
    this.promocode_text = promocode

    let email_value = $('#eemail').val()
    if (email_value != '' && email_value != undefined) {
      var regrex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!regrex.test(String(email_value).toLowerCase())) {
        this.toastr.error('Please enter a valid email address. ', 'Sorry!');
        return false;
      }
    }

    if (promocode.toString().length < 1) {
      this.toastr.error('Please Enter Valid Promo Code', 'Validation Error!');
    } else {
      console.log('applyPromoCode 5');
      this.showNotification = false;
      this.applyPromoCode(promocode);
    }
  }

  unmask(val) {
    var val1 = val;
    return val.replace(/[/_ ]/g, '');
  }
  applyDotInPrice(finalPrice) {
    if (finalPrice != 'N/A') {
      if (finalPrice.toString().indexOf(".") > -1) {
        return '$' + finalPrice
      } else {
        return '$' + finalPrice + '.00';
      }
    } else {
      return finalPrice
    }

  }
  applyDotInBonus(finalPrice) {
    if (finalPrice != 'N/A') {
      return finalPrice + '.00';
    } else {
      return finalPrice
    }

  }

  removeDotInHour(value) {
    if (value.toString().indexOf('.') !== -1) {
      return Number(value)
    } else {
      return value;
    }
  }
  login() {
    if ($('#password').val() == '') {
      this.password_error = 1
    } else {
      this.password_error = 0
    }
    if ($('#email').val() == '') {
      this.email_error = 1
    } else {
      if (this.validateEmail($('#email').val())) {
        this.email_error = 0
      } else {
        this.email_error = 1
      }

    }
    if (this.password_error == 0 && this.email_error == 0) {
      let form: any = {}
      form.email = $('#email').val()
      form.password = $('#password').val()
      this.userSignIn(form)
    }
  }
  validateEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }
  userSignIn(formValues) {


    this.userServiceObj.loginUser(formValues)
      .subscribe(
        data => {
          this.parseData = data;
          if (this.parseData.status === 201) {

            // kochava.activity('user_login', {
            //   user_name : this.parseData.data.user.name,
            //   email : formValues.email
            //   });

            //formObj.resetForm();
            CustomCode.markLoginUser(this.parseData.data);
            /************** #Start If name contain email *********************/
            if (!CustomCode.isValidName(this.parseData.data.user.name)) {
              localStorage.setItem('invalidName', '1');
            } else {
              localStorage.setItem('invalidName', '0');
            }
            /************** #End If name contain email *********************/
            if (this.parseData.data.user.isAdmin || this.parseData.data.user.isAdminGroup || this.parseData.data.user.roles[0] == 'mp_sales' || this.parseData.data.user.roles[0] == 'accounts_receivable' || this.parseData.data.user.roles[0] == 'customer_service') {

              localStorage.setItem('is_admin', 'true');
              let role = 'admin';
              if (!this.parseData.data.user.isAdmin) {
                role = this.parseData.data.user.roles[0];
              }
              // console.log(CustomCode.adminUrlLogin+'/redirectto/'+localStorage.getItem('access_token')+'/'+localStorage.getItem('refresh_token'))+'/0'
              // window.location.href=CustomCode.adminUrlLogin+'/redirectto/'+localStorage.getItem('access_token')+'/'+localStorage.getItem('refresh_token');
              let locate = CustomCode.adminUrlLogin + '/redirectto/' + localStorage.getItem('access_token') + '/' + localStorage.getItem('refresh_token') + '/0';
              //locate='https://google.com'
              let el: HTMLElement = this.myDiv.nativeElement as HTMLElement;
              // setInterval(() => {
              //   x
              //   if(el.getAttribute('href')!='')
              //   {
              //     //el.click();
              //    // $('button.close').trigger('click');
              //   }
              // }, 2000);
              // 
              //
              window.location.reload();

            } else {
              const userLat = CustomCode.timeSquareCoords.lat;
              const userLong = CustomCode.timeSquareCoords.long;
              const userLocation = CustomCode.timeSquareCoords.defaultLocation;
              $('button.close').trigger('click');
              if (this.routerObj.url.indexOf('buy-monthly') > -1) {
                $('#loginPopup').hide();
                $('#guestuser').hide();
                $('#loggeduser').show();
                // console.log(localStorage.getItem('monthlyParkingId')+'11111111111111111111111111111111111')
                $('button.close').trigger('click');
              } else if (this.routerObj.url.indexOf('payment') > -1 || this.routerObj.url.indexOf('buy-monthly') > -1) {
                window.location.reload();
              } else {
                if (localStorage.getItem('is_landing_page') || this.routerObj.url.indexOf('offer') > -1) {


                } else if (this.routerObj.url.indexOf('icon-rewards-register') > -1 && localStorage.getItem('is_loyalty') == '1') {
                  if (this.parseData.data.user.is_loyalty_active) {
                    this.routerObj.navigate(['my-account/manage-icon-rewards-details']);
                  } else {
                    this.routerObj.navigate(['my-account/manage-icon-rewards-card']);
                  }
                } else {
                  if (sessionStorage.getItem('email_ref')) {
                    sessionStorage.removeItem('email_ref');
                    this.routerObj.navigate(['/my-account/icon-rewards']);
                  } else {
                    if (this.parseData.data.user.is_loyalty == 1) {
                      if (this.parseData.data.user.is_loyalty_active == 1) {
                        this.routerObj.navigate(['my-account/account']);
                        return false;
                        // if(this.parseData.data.user.is_loyalty_profile_completed == 1){
                        //   this.routerObj.navigate(['my-account/account']);
                        //   return false;
                        // }else{
                        //   this.routerObj.navigate(['my-account/manage-icon-rewards-details']);
                        //   return false;
                        // }
                      } else {
                        this.routerObj.navigate(['my-account/manage-icon-rewards-card']);
                        return false;
                      }
                    }
                    this.routerObj.navigate(['/my-account/account']);
                  }
                }
                // this.routerObj.navigate(['/search', userLat, userLong, this.currDate,
                // this.facilityRadius, userLocation, CustomCode.defaultFacilityType, CustomCode.searchMode()]);
              }
            }
          }
        },
        error => {
          //FormValidationErrors.displayErrors(error.errors);
          const errorsList = error.errors;
          if (errorsList.message !== 'Validation Errors') {
            this.toastr.error(errorsList.message, 'Sorry!');
          } else {
            for (const key in errorsList.detail) {
              if (errorsList.detail.hasOwnProperty(key)) {
                if (typeof errorsList.detail === 'undefined') {
                  this.toastr.error(errorsList.message, 'Sorry!');
                } else {
                  this.toastr.error(errorsList.detail[key], 'Sorry!');
                }
              }
            }
          }
        }
      );
  }

  acceptTermsCondition() {
    this.formData.terms = true;
    this.showTermsError = false;
    this.termsValid = true;
    this.showTermError = false;
  }

  closeTermsPopup() {
    this.formData.terms = false;
    this.hideTermsPopupBtns = false;
    this.termsValid = false;
    this.showTermError = true;
  }
  signUpWithRewardForm() {
    sessionStorage.setItem('RedirectfromPayment', '1');
    sessionStorage.setItem('currentUrl', this.currentUrl);
    var myJSON = JSON.stringify(this.currentUrl);
    CustomCode.setCookie('currentUrl', myJSON, 120);
    this.routerObj.navigate(['/create-account']);
  }
  displayTermsPopup(val) {
    // console.log(val);
    if (val === 0) {
      this.hideTermsPopupBtns = true;
    } else {
      this.hideTermsPopupBtns = false;
    }

  }
  applyDotInPriceWithProcessing(finalPrices) {
    let amt = this.applyDotInPrice(this.amt)
    if (amt == 'N/A') {
      return 'N/A';
    }

    if (finalPrices != 'N/A') {
      //finalPrice=finalPrice+this.processFess
      if (finalPrices.toString().indexOf(".") > -1) {
        // return '$'+finalPrices.toFixed(2); // ye kal ka
        // price=
        //this.finalPrice=this.formatter.format(finalPrices)
        return this.formatter.format(finalPrices)
      } else {
        //return '$'+finalPrices.toFixed(2); // ye kal ka
        // price=
        // this.finalPrice=this.formatter.format(finalPrices)
        return this.formatter.format(finalPrices)
      }

    } else {
      return finalPrices;
    }
  }
  formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2
  })

  getFormatedDate(updatedStartDate, time) {
    let dd = updatedStartDate.getDate();
    let mm = updatedStartDate.getMonth() + 1;
    let yy = updatedStartDate.getFullYear();

    if (dd < 10) { dd = '0' + dd; }
    if (mm < 10) { mm = '0' + mm; }
    return updatedStartDate = mm + '/' + dd + '/' + yy + ' ' + time;
  }
  /* commented   */
  // gettotalUpdatedAmount(){
  //   let updatedPrice = this.amt;
  //   if(this.processFess > 0){
  //     //updatedPrice  =updatedPrice+ this.processFess
  //     updatedPrice  =parseFloat(updatedPrice)+ parseFloat(parseFloat(this.processFess.toString()).toFixed(2));
  //   }
  //   if(this.is_bonus_hour_applied){
  //     updatedPrice = updatedPrice+this.bonus_rate
  //   }
  //   updatedPrice = parseFloat(updatedPrice).toFixed(2);
  //   this.updatedAmountFinal = updatedPrice

  // return updatedPrice;
  // }


  gettotalUpdatedAmount() {

    let updatedPrice = this.amt;

    if (this.processFess > 0) {
      //updatedPrice  =updatedPrice+ this.processFess
      updatedPrice = parseFloat(updatedPrice) + this.round(this.processFess)//parseFloat(parseFloat(this.processFess.toString()).toFixed(2));
    }
    // console.log(parseFloat(parseFloat(this.processFess.toString()).toFixed(2))+'z45')
    if (this.is_bonus_hour_applied) {
      updatedPrice = updatedPrice + this.bonus_rate
    }
    updatedPrice = parseFloat(updatedPrice).toFixed(2);
    this.updatedAmountFinal = updatedPrice


    return updatedPrice;
  }

  changeDate() {
    setTimeout(() => {
      let sDateRightWindow = new Date($('#startdate').val());

      this.updatedStartDate = new Date($('#startdate').val().trim());
      this.updatedEndDate = new Date($('#enddate').val());
      this.updatedStartDate = this.getFormatedDate(this.updatedStartDate, $('#startTime option:selected').text())
      this.updatedEndDate = this.getFormatedDate(this.updatedEndDate, $('#endTime option:selected').text())

      // console.log(this.updatedStartDate);
      // console.log(this.updatedStartDate);

      let a = {
        '01': '1st', '02': '2nd', '03': '3rd', '04': '4th', '05': '5th', '06': '6th', '07': '7th', '08': '8th', '09': '9th', '10': '10th',
        '11': '11th', '12': '12th', '13': '13rd', '14': '14th', '15': '15th', '16': '16th', '17': '17th', '18': '18th', '19': '19th', '20': '20th',
        '21': '21st', '22': '22nd', '23': '23rd', '24': '24th', '25': '25th', '26': '26th', '27': '27th', '28': '28th', '29': '29th', '30': '30th', '31': '31st'
      };
      let eDateRightWindow = new Date($('#enddate').val());
      this.availDate = this.mlist[sDateRightWindow.getMonth()] + ' ' + a[((sDateRightWindow.getDate() > 9) ? sDateRightWindow.getDate() : ('0' + sDateRightWindow.getDate()))]
      this.dayname = this.arrayOfWeekdays[sDateRightWindow.getDay()] + ', ' + this.mlist[sDateRightWindow.getMonth()] + ' ' + ((sDateRightWindow.getDate() > 9) ? sDateRightWindow.getDate() : ('0' + sDateRightWindow.getDate())) + ' at ' + $('#startTime option:selected').text()
      var exitdatetime_calc = new Date($('#enddate').val() + ' ' + $('#endTime').val());
      if (this.is_bonus_hour_applied) {
        exitdatetime_calc.setTime(exitdatetime_calc.getTime() + (3600 * 1000));
      }
      let formated_hour = "";
      if (exitdatetime_calc.getHours() < 10) {
        formated_hour = "0" + exitdatetime_calc.getHours();
      }
      else {
        formated_hour = exitdatetime_calc.getHours().toString();
      }
      let formated_minutes = "";
      if (exitdatetime_calc.getMinutes() < 10) {
        formated_minutes = "0" + exitdatetime_calc.getMinutes();
      }
      else {
        formated_minutes = exitdatetime_calc.getMinutes().toString();
      }
      //alert(this.tConv24(formated_hour+":"+exitdatetime_calc.getMinutes()));
      this.edayname = this.arrayOfWeekdays[exitdatetime_calc.getDay()] + ', ' + this.mlist[exitdatetime_calc.getMonth()] + ' ' + ((exitdatetime_calc.getDate() > 9) ? exitdatetime_calc.getDate() : ('0' + exitdatetime_calc.getDate())) + ' at ' + this.tConv24(formated_hour + ":" + formated_minutes)
      let month = ''
      if (this.mlist[sDateRightWindow.getMonth()] + ' ' + ((sDateRightWindow.getDate() > 9) ? sDateRightWindow.getDate() : ('0' + sDateRightWindow.getDate())) != this.mlist[eDateRightWindow.getMonth()] + ' ' + ((eDateRightWindow.getDate() > 9) ? eDateRightWindow.getDate() : ('0' + eDateRightWindow.getDate()))) {
        month = this.mlist[eDateRightWindow.getMonth()] + ' ' + ((eDateRightWindow.getDate() > 9) ? eDateRightWindow.getDate() : ('0' + eDateRightWindow.getDate())) + ' at '
      }
      this.daynames = this.mlist[sDateRightWindow.getMonth()] + ' ' + ((sDateRightWindow.getDate() > 9) ? sDateRightWindow.getDate() : ('0' + sDateRightWindow.getDate())) + ' at ' + $('#startTime option:selected').text()
      this.edaynames = month + '' + $('#endTime option:selected').text()

    }, 1000)
  }
  tConv24(time24) {
    var ts = time24;
    var H = +ts.substr(0, 2);
    let hs = (H % 12) || 12;
    let hsg = (hs < 10) ? ("0" + hs) : hs;
    var ampm = H < 12 ? " AM" : " PM";
    ts = hsg + ts.substr(2, 3) + ampm;
    return ts;
  };

  endlTime() {
    let startDate: any;
    let startTime: any
    console.log(window.self);
    console.log(window.top);
    if (window.self !== window.top) {
      console.log('1');
      startDate = new Date(this.starttimeForWidget);
      startTime = this.starttimeforwidget
      console.log(startTime);
      // bookingHours  = FilterHelper.getBookingHours(this.starttimeForWidget, this.starttimeforwidget, this.endtimeForWidget, this.endtimeforwidget);
    } else {
      console.log('2');
      startDate = new Date($('#enddate').val());
      startTime = $('#endTime').val();
      console.log(startTime);
      // bookingHours  = FilterHelper.getBookingHours($('#startdate').val(), $('#startTime').val(), $('#enddate').val(), $('#endTime').val());
    }
    //let startDate = new Date($('#startdate').val());

    return FilterHelper.getArrivalTime(startDate, startTime);
  }
  changeFormatdata(val) {
    // return val; // ye kal ka
    let c = val.replace('$', '');

    let price = parseFloat(c);
    return price
  }

  ReservationEventLogStatusForBookBtnClick(userId, facility_id, facility_name, getZipCode, errorMsg, iqcode, emailId) {
    let device_type = 'web';
    if ($(window).width() < 767) {
      device_type = 'responsive';
    } else { device_type = 'web'; }
    let eventName = 'Daily - ' + this.fromSearch;
    let currentLatitude = (localStorage.getItem('currentLatitude')) ? localStorage.getItem('currentLatitude') : '';
    let currentLongitude = (localStorage.getItem('currentLongitude')) ? localStorage.getItem('currentLongitude') : '';
    let reservation_status_data = {
      user_id: userId,
      event_name: eventName,
      latitude: currentLatitude,
      longitude: currentLongitude,
      facility_id: facility_id,
      facility_name: facility_name,
      zipcode: getZipCode,
      app_version: "",
      error_message: errorMsg,
      device: "web",
      device_detail: device_type,
      os_version: "",
      booking_code: iqcode,
      email_id: emailId
    };

    // let checkOutData = {
    //   'event': 'checkout_daily',
    //   'userId' : userId?userId:0, 
    //   'email': emailId?emailId:0,
    //   'FacilityName': facility_name,
    //   'FacilityID': facility_id,
    //   'FacilityPRICE': this.finalPrice,
    //   'FacilityPageLocation': this.reffrel_page,
    //   'Category': 'daily'
    // };
    // (<any>window).dataLayer.push(checkOutData);
    // console.log(checkOutData);

    this.reservationServObj.updateReservationEventLog(reservation_status_data).subscribe(
      data => {
        this.reservationDataEventLog = data
      },
      error => {

      }
    )
  }

  round(num) {
    var m = Number((Math.abs(num) * 100).toPrecision(15));
    return Math.round(m) / 100 * Math.sign(num);
  }
  allowNumbersOnly(event) {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }


  fetchUserStatusForTracking() {
    let getUserId = '0';
    let getUserEmail = '0';
    let user_login_type = '';
    let isLogin = false;
    if (localStorage.getItem('access_token')) {
      isLogin = true;
      getUserId = localStorage.getItem('userid');
      getUserEmail = localStorage.getItem('email');
      user_login_type = localStorage.getItem('user_login_type');
    }
    const loginStatusTrack = {
      'event': 'pageview',
      'loginStatus': isLogin ? 'logged in' : 'Not Logged IN', // 'logged in/Not Logged IN',
      'authenticationMethod': user_login_type,
      'userId': getUserId,
      'pageType': this.routerObj.url,
      'email': getUserEmail,
    };
    (<any>window).dataLayer.push(loginStatusTrack);

  }

  displayNameOnCard(name) {
    if (name != null && name != 'null') {
      return name
    } else {
      return '';
    }
  }

}
