      <h2 class="text-primary text-uppercase">Change Password</h2>
							<br/>
							<div class="clearfix">
							</div>
							<div class="viewOne">
							
							<div id="addMonthlyVehicleContainer"  >
								<form class="p" name="form" role="form" id="floating-label-change-pass" (ngSubmit)="f.form.valid && changepassword(formData, f)" #f="ngForm" (keydown)="keyDownFunction($event)"
								novalidate>
									<div class="row">
										<div class="col-xs-12 col-sm-12 col-md-8">
											<div class="row">
												<div class="col-sm-12">
													<div class="form-group" [ngClass]="{ 'has-error' : f.submitted && !old_password.valid }">
														<label class="control-label">Current password</label>
														<input type="password" name="old_password" class="form-control" id="" [(ngModel)]="formData.old_password"  #old_password="ngModel" required>
													<div *ngIf="f.submitted && !old_password.valid" class="help-block">Old password is required</div>
													</div>
													
												</div>
											</div>
											<div class="row">
												<div class="col-sm-12">
													<div class="form-group" [ngClass]="{ 'has-error' : f.submitted && !password.valid }">
														<label class="control-label">New password</label>
														<input type="password" name="password" class="form-control" id="" [(ngModel)]="formData.password"  #password="ngModel" required>
														<div *ngIf="f.submitted && !password.valid" class="help-block">New password is required</div>
													</div>
													
												</div>
											</div>
											<div class="row">
												<div class="col-sm-12">
													<div class="form-group" [ngClass]="{ 'has-error' : f.submitted && !password_confirmation.valid }">
														<label class="control-label">Re-enter new password</label>
														<input type="password" name="password_confirmation" class="form-control" id="" [(ngModel)]="formData.password_confirmation"  #password_confirmation="ngModel" required>
														<div *ngIf="f.submitted && !password_confirmation.valid" class="help-block">Re-enter new password is required</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="form-group">
										<button type="cancel" class="btn btn-primary theme-btn" (click)="cancelChangePassword()">Cancel</button>
										<button class="btn btn-fill btn-primary theme-btn">Change Password</button>
									</div>
								</form>
							</div>
						</div>