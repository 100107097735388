
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgForm } from '@angular/forms';
import * as $ from 'jquery';
import { ToastrService } from 'ngx-toastr';

import { MyAccountMonthlyParkingService } from '../../services/my-account-monthly-parking.service';
import { Crypto } from '../../classes/crypto';

@Component({
  selector: 'app-add-credit-card',
  templateUrl: './add-credit-card.component.html',
  styleUrls: ['./add-credit-card.component.scss'],
  providers: [MyAccountMonthlyParkingService, Crypto]
})
export class AddCreditCardComponent implements OnInit {

  @Input() currentActive: string;
  @Input() accountNumber: any;

  dateMask: any = [/[0-9]/, /[0-9]/, '/' , /[0-9]/, /[0-9]/];
  cardMask: any = [
          /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, ' ',
          /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, ' ',
          /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, ' ',
          /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/
          ];

  formData: any = {};

  infoIcon = 'assets/img/info.svg';
  cardSecurityIcon = 'assets/img/cardSecurity.png';

  cancel = true;
  has_valet_manager = false;
  showCardError = true;
  creditCardToken = '';
  @Output() cancelEvent = new EventEmitter<boolean>();
  @Output() responseEvent = new EventEmitter<boolean>();

  constructor(
    private crypto: Crypto,
    private toastr: ToastrService,
    private myAccountMonthlyParkingObj: MyAccountMonthlyParkingService
    ) { }

  ngOnInit() {

    if( localStorage.getItem('account_type') && localStorage.getItem('account_type') == 'VM' ){
      this.has_valet_manager = true
    } else {
      this.has_valet_manager = false;
    }
    setInterval(()=>{
      if(this.has_valet_manager == true){
       let token = $('#accountcreditCardToken').val();
      if(token == 'undefined' || token == undefined || token == null || token == ''){
        this.showCardError = true;
       } else {
        this.showCardError = false;
       }
      }
    },100)
  }

  hitCancel() {
    this.cancel = false;
    this.cancelEvent.emit(this.cancel);
  }

  responseReturn() {
    this.responseEvent.emit();
  }

  submitAddCreditCard(formData, form: NgForm) {
    if(this.has_valet_manager == true){
      this.creditCardToken = $('#accountcreditCardToken').val();
      if(this.creditCardToken == 'undefined' || this.creditCardToken == undefined || this.creditCardToken == null || this.creditCardToken == ''){
        this.showCardError = true;
         return;
       }else {
        this.showCardError = false;
        formData.card_number = this.creditCardToken;
       }
    }else {
      formData.card_number = formData.card_number.replace(/\D/g, '');
    }
    formData.card_number = formData.card_number.replace(/\D/g, '');
    formData.expiration_date = formData.expiration_date.replace(/\D/g, '');
    //console.log(formData);
    const nonce = this.crypto.encrypt(formData);

    const data = {
      'payment_type' : 'credit_card',
      'nonce' : nonce
    };
    this.myAccountMonthlyParkingObj.addCreditCard(data, this.accountNumber)
    .then(
      (data:any)=> {
        this.toastr.success('Payment Method Added Successfully', 'Great!');
        this.responseReturn();
        this.hitCancel();
      },
      error => {
        let errorMessage = error.errors.message;
        if (errorMessage === 'Validation Errors') {
          errorMessage = '';
          for (const key in error.errors.detail) {
              errorMessage += error.errors.detail[key];
          }
        }
        this.toastr.error(errorMessage, 'Sorry!');
      }
    );
  }

  restrictNumeric(event) {
    let input;
    if (event.metaKey || event.ctrlKey) {
      return true;
    }
    if (event.which === 32) {
     return false;
    }
    if (event.which === 0) {
     return true;
    }
    if (event.which < 33) {
      return true;
    }
    input = String.fromCharCode(event.which);
    return !!/[\d\s]/.test(input);
  }

}
