<app-header [showTies]="false"></app-header>
<div class="Account orangeBar" >
  <div *ngIf="!isLoyaltyMember"  [style.marginTop.px]="barHeight - 50"></div>
  <div *ngIf="isLoyaltyMember"  [style.marginTop.px]="barHeight "></div>
   
    <div class="account-nav clearfix text-uppercase hidden-print"  [style.top.px]="barHeight" >
        <div class="container">
        <div class="clearfix">
          <div class="dropdown  selectBox  visible-xs"  >
            <a class="btn btn-default btn-block dropdown-toggle linkLabel" data-toggle="dropdown" ><div>MY MONTHLY PARKING</div> <i aria-hidden="true" class="fa fa-angle-down"></i></a>
              <ul style="width:70%;" class="catValue dropdown-menu">
                <li><a (click)="removeHeader();" routerLink="/my-account/account"  aria-hidden="true" >My Accounts</a></li>
                <!-- <li><a (click)="addMenuactive();" routerLink="/my-account/icon-rewards"  aria-hidden="true" >Icon Rewards</a></li> -->
                <li class="active"><a (click)="removeHeader();" routerLink="/my-account/monthly-parking" aria-hidden="true">My Monthly Parking</a></li>
                <!-- <li><a (click)="removeHeader();" routerLink="/my-account/coupons"  aria-hidden="true" >My Offers</a></li> -->
                <li><a (click)="removeHeader();" routerLink="/my-account/reservations"  aria-hidden="true" >My Reservations</a></li>
                <li><a (click)="removeHeader();" routerLink="/my-account/vehicle-request"  aria-hidden="true" >My Vehicle Request</a></li>
                <li><a (click)="removeHeader();" routerLink="/my-account/brand-partnership"  aria-hidden="true" >Brand Partnership</a></li>
                <li><a (click)="removeHeader();" routerLink="/my-account/promo-codes"  aria-hidden="true" >My Promo Codes</a></li>
                <li>
                <div class="btn-group btn-group-justified" role="group" aria-label="...">
                    <a class="logoutBtn" *ngIf="loggedUser" (click)="logOut()">Sign out <fa name="sign-out"></fa></a>
                </div>       
                </li>
              </ul>
          </div>
        </div>
        <ul class="nav nav-tabs nav-justified" *hideItBootstrap="['xs','sm']" >
          <li><a (click)="removeHeader();" routerLink="/my-account/account">My Account</a></li>
          <!-- <li><a (click)="addMenuactive();" routerLink="/my-account/icon-rewards">Icon Rewards</a></li> -->
          <li class="active"><a (click)="removeHeader();" routerLink="/my-account/monthly-parking">My Monthly Parking</a></li>
          <!-- <li><a (click)="removeHeader();" routerLink="/my-account/coupons">My Offers</a></li> -->
          <li><a (click)="removeHeader();" routerLink="/my-account/reservations">My Reservations</a></li>
          <li><a (click)="removeHeader();" routerLink="/my-account/vehicle-request">Vehicle Request</a></li>
          <li><a (click)="removeHeader();" routerLink="/my-account/brand-partnership">Brand Partnerships</a></li>
          <li><a (click)="removeHeader();" routerLink="//my-account/promo-codes">My Promo Codes</a></li>
        </ul>
      </div>
      <app-loyality-badge (updateTierToHeader)='updateTier($event)'  [showTies]="true"></app-loyality-badge>
      </div>
<div [ngClass]="isTierLoaded?'is-tiered-user':''" class="container pad-top-lg pad-bottom-lg">
  <div class="mar-top-sm">
    <div role="tabpanel" class="tab-pane fade active in " id="monthlyparking">
      <div class="viewmonthlymain" *ngIf="showMonthlyParking">
        <div *ngIf="isLoyaltyMember" class="hidden-xs 1" style="padding-top:15px"></div>
        <div *ngIf="isLoyaltyMember" class="visible-xs 2" style="padding-top:35px"></div>
        <h2 class="text-uppercase text-primary mar-bottom-sm">My Monthly Parking</h2>
        <div class="loaderSection" *ngIf="isLoaded">
<div [class.activeWrap]="showClassColorActive" class="xyz">
        <div class="wrapper" *ngFor="let monthlyParking of activeUser.monthly_parking_facilities">
          <div class="well" id="active{{monthlyParking.id}}">
          <h4 class="text-uppercase">{{ monthlyParking?.facility_name_modified}}</h4>
          <ADDRESS>{{ monthlyParking?.entrance_location }}</ADDRESS>
          <div  class="hide-last" *ngFor="let monthlyParkingNum of monthlyParking.accounts">
          <div id="accountD{{monthlyParkingNum.account_number}}" class="hidden-xs dualAccount" *ngIf="monthlyParkingNum.active == '1'">
            <div class="row">
            <div class="col-xs-12 col-sm-3 col-md-2 col-lg-2">
              <p class="mar-bottom-0"><small>Account Number</small><br>
                {{ monthlyParkingNum.account_number }}
              </p>
            </div>
            
            <div class="col-xs-12 col-sm-3 col-md-2 col-lg-2" *ngIf="monthlyParkingNum.active == '1'">
              <p class="mar-bottom-0">
                <small>Status</small><br>
                Active
              </p>
            </div>
            <div class="col-xs-12 col-sm-3 col-md-2 col-lg-2" *ngIf="monthlyParkingNum.active == '0'">
              <p class="mar-bottom-0">
                <small>Status</small><br>
                Inactive
              </p>
            </div>
            <div *ngIf="monthlyParkingNum?.is_valid_ar_account==1" class="col-xs-12 col-sm-6 col-md-8 col-lg-8 text-right">
              <a routerLink="/my-account/monthly-parking/account/{{ monthlyParkingNum.account_number }}/current-balance"  class="btn viewAccount btn-primary btn-fill">View Account</a>
            </div>
            <div *ngIf="monthlyParkingNum?.is_valid_ar_account==0" class="col-xs-12 col-sm-6 col-md-8 col-lg-8 text-right">
             <p style="color:red">Error retrieving data</p>
          </div>
          </div>
          </div>
          <div id="accountM{{monthlyParkingNum.account_number}}" class="visible-xs dualAccount" *ngIf="monthlyParkingNum.active == '1'">
            <div class="row">
            <div class="col-xs-5">
              <p class="mar-bottom-0"><small>Account Number</small><br>
                {{ monthlyParkingNum.account_number }}
              </p>
              <p class="mar-bottom-0 mar-top-xs" *ngIf="monthlyParkingNum.active == '1'">
                <small>Status</small><br>
                Active
              </p>
              <p class="mar-bottom-0 mar-top-xs" *ngIf="monthlyParkingNum.active == '0'">
                <small>Status</small><br>
                Inactive
              </p>
            </div>
            <div *ngIf="monthlyParkingNum?.is_valid_ar_account==1" class="col-xs-7 text-right pad-top-sm pad-left-0">
              <a routerLink="/my-account/monthly-parking/account/{{ monthlyParkingNum.account_number }}/current-balance"  class="mar-top-xs btn viewAccount btn-primary btn-fill">View Account</a>
            </div>
            <div *ngIf="monthlyParkingNum?.is_valid_ar_account==0" class="col-xs-7 text-right pad-top-sm pad-left-0">
             <p style="color:red">Error retrieving data</p>
          </div>
          </div>
          </div>
        </div>
        </div>
      </div>
      </div>
      <div [class.inactiveWrap]="showClassColorInActive" class="xyz">
      <div class="wrapper" *ngFor="let monthlyParking of activeUser.monthly_parking_facilities">
        <div class="well" id="inactive{{monthlyParking.id}}">
        <h4 class="text-uppercase">{{ monthlyParking?.facility_name_modified}}</h4>
        <ADDRESS>{{ monthlyParking?.entrance_location }}</ADDRESS>
        <div  *ngFor="let monthlyParkingNum of monthlyParking.accounts">
        <div id="inaccountD{{monthlyParkingNum.account_number}}" class="hidden-xs dualAccount hide-last" *ngIf="monthlyParkingNum.active == '0'">
          <div class="row">
          <div class="col-xs-12 col-sm-3 col-md-2 col-lg-2">
            <p class="mar-bottom-0"><small>Account Number</small><br>
              {{ monthlyParkingNum.account_number }}
            </p>
          </div>
          
          <div class="col-xs-12 col-sm-3 col-md-2 col-lg-2" *ngIf="monthlyParkingNum.active == '1'">
            <p class="mar-bottom-0">
              <small>Status</small><br>
              Active
            </p>
          </div>
          <div class="col-xs-12 col-sm-3 col-md-2 col-lg-2" *ngIf="monthlyParkingNum.active == '0'">
            <p class="mar-bottom-0">
              <small>Status</small><br>
              Inactive
            </p>
          </div>
          <div *ngIf="monthlyParkingNum?.is_valid_ar_account==1" class="col-xs-12 col-sm-6 col-md-8 col-lg-8 text-right">
            <a routerLink="/my-account/monthly-parking/account/{{ monthlyParkingNum.account_number }}/current-balance"  class="btn viewAccount btn-primary btn-fill">View Account</a>
          </div>
          <div *ngIf="monthlyParkingNum?.is_valid_ar_account==0" class="col-xs-12 col-sm-6 col-md-8 col-lg-8 text-right">
           <p style="color:red">Error retrieving data</p>
        </div>
        </div>
        </div>
        <div id="inaccountM{{monthlyParkingNum.account_number}}" class="visible-xs dualAccount hide-last" *ngIf="monthlyParkingNum.active == '0'">
          <div class="row">
          <div class="col-xs-5">
            <p class="mar-bottom-0"><small>Account Number</small><br>
              {{ monthlyParkingNum.account_number }}
            </p>
            <p class="mar-bottom-0 mar-top-xs" *ngIf="monthlyParkingNum.active == '1'">
              <small>Status</small><br>
              Active
            </p>
            <p class="mar-bottom-0 mar-top-xs" *ngIf="monthlyParkingNum.active == '0'">
              <small>Status</small><br>
              Inactive
            </p>
          </div>
          <div *ngIf="monthlyParkingNum?.is_valid_ar_account==1" class="col-xs-7 text-right pad-top-sm pad-left-0">
            <a routerLink="/my-account/monthly-parking/account/{{ monthlyParkingNum.account_number }}/current-balance"  class="mar-top-xs btn viewAccount btn-primary btn-fill">View Account</a>
          </div>
          <div *ngIf="monthlyParkingNum?.is_valid_ar_account==0" class="col-xs-7 text-right pad-top-sm pad-left-0">
           <p style="color:red">Error retrieving data</p>
        </div>
        </div>
        </div>
      </div>
      </div>
    </div>
  </div>
        
      </div>
    <app-lazy-load *ngIf="isLoaded === false" [isLoaded]="isLoaded" [sectionName]="'monthly parking accounts'"></app-lazy-load>
    <div *ngIf="isLoaded">
      <p *ngIf="noMonthlyAccount">Sorry! Monthly Parking Account Not Found, Try Adding First</p>
      <button type="button" class="hidden-xs btn theme-btn addMonthlyAccount btn-primary" (click)="showAddMonthlyAccount($event)">Add Monthly Account</button>
    </div>
    <div class="row visible-xs" *ngIf="isLoaded">
      <div class="col-xs-10 col-xs-offset-1">
        <button type="button" class="btn theme-btn addMonthlyAccount btn-primary full-width" (click)="showAddMonthlyAccount($event)">Add Monthly Account</button>
      </div>
    </div>
  </div>
  <!-- <app-create-online-account *ngIf></app-create-online-account> -->
  <div class="viewmonthlyaccount customeButtons_styling" *ngIf="showInnerPages">
    <div *ngIf="isLoyaltyMember" class="visible-xs 2" style="padding-top:35px"></div>
    <ol class="breadcrumb pad-top-0 pad-bottom-0 hidden-print pad-left-0">
      <li class="active"><a routerLink="/my-account/monthly-parking" class="btn btn-link pad-left-0 pad-right-xs"><fa name="angle-left"></fa> &nbsp; My Monthly Accounts</a></li>
    </ol>
    <!-- <div class="loaderSection" *ngIf="isLoaded"> -->
    <div class="well">
      <div class="row">
        <div class="col-7 col-sm-7 col-md-9 col-lg-9">
          <h3 class="text-primary mar-top-0">{{ activeAccount?.facility?.facility_name_modified }}</h3>
          <p>{{ activeAccount?.entrance_location }}</p>
        </div>
        <div class="col-5 col-sm-3 col-md-2 col-lg-2 text-right">
          <p>
            <small>Account Number</small><br>
            {{ activeAccount.account_number }}
          </p>
          <p *ngIf="activeAccount.active == '1'">
            <small>Status</small><br>
            Active
          </p>
          <p *ngIf="activeAccount.active == '0'">
            <small>Status</small><br>
            Inactive
          </p>
        </div>
      </div>
    </div>
    <div class="well hide" style="margin-bottom: 0px;" id="monthlyAccountSection">
      <div class="row">
          <div class="col-xs-12 col-sm-12">
              <h3 class="text-primary mar-top-0">{{ activeAccount?.facility?.facility_name_modified }}</h3>
              <p>{{ activeAccount?.entrance_location }}</p>
          </div>
      </div>
      <div class="row">
          <div class="col-xs-6 col-sm-6 text-left">
              <p>
                  <small>Account Number</small> : &nbsp; {{ activeAccount.account_number }}
              </p>
          </div>
          <div class="col-xs-6 col-sm-6 text-left" *ngIf="activeAccount.active == '1'">
              <p>
                  <small>Status</small>: &nbsp; Active
              </p>
          </div>
          <div class="col-xs-6 col-sm-6 text-left" *ngIf="activeAccount.active == '0'">
              <p>
                  <small>Status</small> : &nbsp; Inactive
              </p>
          </div>


      </div>
  </div>
    <div class="row" role="tabpanel">
      <div class="col-xs-12 col-sm-4 col-md-3 col-lg-3">
        <div class="well well-sm clearfix hidden-print mob-well-padding">
          
          
          <ul class="nav nav-tabs  left-tabs nav-stacked" *hideItBootstrap="['xs']" role="tablist">
            <li role="presentation" [ngClass]="{'active': profileModule=='account-subscription-renewal'}" *ngIf="is_subscription">
              <a routerLink="/my-account/monthly-parking/account/{{ accountNumber }}/account-subscription-renewal" aria-controls="subscriptionrenewal" role="tab" data-toggle="tab" class="btn btn-fill btn-primary side-btn" style="text-transform: capitalize!important;">Renew My Subscription</a>
            </li>
            <li role="presentation" [ngClass]="{'active': profileModule=='current-balance'}">
              <a routerLink="/my-account/monthly-parking/account/{{ accountNumber }}/current-balance" aria-controls="currentbalance" role="tab" data-toggle="tab"><i class="fa fa-usd"></i> Current Balance</a>
            </li>
            <li role="presentation" [ngClass]="{'active': profileModule=='auto-pay'}">
              <a routerLink="/my-account/monthly-parking/account/{{ accountNumber }}/auto-pay" aria-controls="autopay" role="tab" data-toggle="tab"><i class="fa fa-calendar-check-o"></i> Manage Autopay</a>
            </li>
            <li role="presentation" [ngClass]="{'active': profileModule=='current-invoice'}">
              <a routerLink="/my-account/monthly-parking/account/{{ accountNumber }}/current-invoice" aria-controls="currentinvoice" role="tab" data-toggle="tab"><i class="fa fa-file-text-o"></i> Current Invoice</a>
            </li>
            <li role="presentation" [ngClass]="{'active': profileModule=='previous-invoices'}">
              <a routerLink="/my-account/monthly-parking/account/{{ accountNumber }}/previous-invoices" aria-controls="previousinvoice" role="tab" data-toggle="tab"><i class="fa fa-files-o"></i> Previous Invoice</a>
            </li>
            <li role="presentation" [ngClass]="{'active': profileModule=='make-payment'}">
              <a routerLink="/my-account/monthly-parking/account/{{ accountNumber }}/make-payment" aria-controls="makepayment" role="tab" data-toggle="tab"><i class="fa fa-credit-card"></i> Make Payment</a>
            </li>
            <li role="presentation" [ngClass]="{'active': profileModule=='payment-methods'}">
              <a routerLink="/my-account/monthly-parking/account/{{ accountNumber }}/payment-methods" aria-controls="paymentmethods" role="tab" data-toggle="tab"><i class="fa fa-money"></i>Payment Methods</a>
            </li>      
            <li *ngIf="!account_type_VM" role="presentation" [ngClass]="{'active': profileModule=='payment-history'}">
              <a routerLink="/my-account/monthly-parking/account/{{ accountNumber }}/payment-history" aria-controls="paymenthistory" role="tab" data-toggle="tab"><i class="fa fa-history"></i>Payment History</a>
            </li>
            <li role="presentation" [ngClass]="{'active': profileModule=='billing-information'}">
              <a routerLink="/my-account/monthly-parking/account/{{ accountNumber }}/billing-information" aria-controls="billinginformation" role="tab" data-toggle="tab"><i class="fa fa-university"></i>Billing Information</a>
            </li>
            <li role="presentation" [ngClass]="{'active': profileModule=='frequently-asked-questions'}">
              <a routerLink="/my-account/monthly-parking/account/{{ accountNumber }}/frequently-asked-questions" aria-controls="faq" role="tab" data-toggle="tab"><i class="fa fa-question-circle"></i>FAQ</a>
            </li>
            
            <!-- <li role="presentation" [ngClass]="{'active': profileModule=='loyalty-club'}">
              <a routerLink="/my-account/monthly-parking/account/{{ accountNumber }}/loyalty-club" aria-controls="loyalty" role="tab" data-toggle="tab"><i class="fa fa-star"></i>Preferred Customer Program</a>
            </li> -->
            <li *ngIf="!account_type_VM" role="presentation" [ngClass]="{'active': profileModule=='delete-account'}">
              <a routerLink="/my-account/monthly-parking/account/{{ accountNumber }}/delete-account" aria-controls="delete" role="tab" data-toggle="tab"><i class="fa fa-trash-o"></i>Unlink Account</a>
            </li>
            <li *ngIf="!account_type_VM" role="presentation" [ngClass]="{'active': profileModule=='account-cancellation-notice'}">
              <a routerLink="/my-account/monthly-parking/account/{{ accountNumber }}/account-cancellation-notice" aria-controls="notice" role="tab" data-toggle="tab"><fa name="ban"></fa>Cancellation Notice</a>
            </li>
            
          </ul>
      
        <!-- for mobile selectbox  -->
          <div class="clearfix">
            <div class="dropdown  selectBox " *showItBootstrap="['xs']" >
              <a class="btn btn-default btn-block dropdown-toggle linkLabel innerLabel  linkSub" data-toggle="dropdown" > <fa [name]="types"></fa> <span id="drptext_not_change_dropdown_title"> Account Details</span>  <i aria-hidden="true" class="fa fa-angle-down"></i></a>
                <ul style="width:80%;" class="catValue catSub dropdown-menu well well-sm">
                  <li *ngIf="is_subscription" (click)="textChahge('Account Details',13,'')" class="drp" id="sub13"><a routerLink="/my-account/monthly-parking/account/{{ accountNumber }}/account-subscription-renewal"  aria-hidden="true" ><fa name="star"></fa> Renew My Subscription</a></li>

                  <li (click)="textChahge('Account Details',1,'')" class="active drp" id="sub1"><a routerLink="/my-account/monthly-parking/account/{{ accountNumber }}/current-balance"  aria-hidden="true" ><fa name="usd"></fa> Current Balance</a></li>
                  <li (click)="textChahge('Account Details',2,'')" class="drp" id="sub2"><a routerLink="/my-account/monthly-parking/account/{{ accountNumber }}/auto-pay"  aria-hidden="true" ><fa name="calendar-check-o"></fa> Manage Autopay</a></li>
                  <li (click)="textChahge('Account Details',3,'')" class="drp" id="sub3"><a routerLink="/my-account/monthly-parking/account/{{ accountNumber }}/current-invoice"  aria-hidden="true" ><fa name="file-text-o"></fa> Current Invoice</a></li>
                  <li (click)="textChahge('Account Details',4,'')" class="drp" id=""><a routerLink="/my-account/monthly-parking/account/{{ accountNumber }}/previous-invoices"  aria-hidden="true" ><fa name="files-o"></fa> Previous Invoice</a></li>
                  <li (click)="textChahge('Account Details',5,'')" class="drp" id="sub5"><a routerLink="/my-account/monthly-parking/account/{{ accountNumber }}/make-payment"  aria-hidden="true" ><fa name="credit-card"></fa> Make Payment</a></li>
                  <li (click)="textChahge('Account Details',6,'')" class="drp" id="sub6"><a routerLink="/my-account/monthly-parking/account/{{ accountNumber }}/payment-methods"  aria-hidden="true" ><fa name="money"></fa> Payment Methods</a></li>
                  <li *ngIf="!account_type_VM" (click)="textChahge('Account Details',7,'')" class="drp" id="sub7"><a routerLink="/my-account/monthly-parking/account/{{ accountNumber }}/payment-history"  aria-hidden="true" ><fa name="history"></fa> Payment History</a></li>
                  <li (click)="textChahge('Billing Information',8,'')" class="drp" id="sub8"><a routerLink="/my-account/monthly-parking/account/{{ accountNumber }}/billing-information"  aria-hidden="true" ><fa name="university"></fa> Billing Information</a></li>
                  <li (click)="textChahge('Account Details',9,'')" class="drp" id="sub9"><a routerLink="/my-account/monthly-parking/account/{{ accountNumber }}/frequently-asked-questions"  aria-hidden="true" ><fa name="question-circle"></fa> FAQ</a></li>
                  <!-- <li (click)="textChahge('Preferred Customer Program',10,'star')" class="drp" id="sub10"><a routerLink="/my-account/monthly-parking/account/{{ accountNumber }}/loyalty-club"  aria-hidden="true" ><fa name="star"></fa> Preferred Customer Program</a></li> -->
                  <li *ngIf="!account_type_VM" (click)="textChahge('Account Details',11,'')" class="drp" id="sub11"><a routerLink="/my-account/monthly-parking/account/{{ accountNumber }}/delete-account"  aria-hidden="true" ><fa name="trash-o"></fa> Unlink Account</a></li>
                  <li *ngIf="!account_type_VM" (click)="textChahge('Account Details',12,'')" class="drp" id="sub12"><a routerLink="/my-account/monthly-parking/account/{{accountNumber }}/account-cancellation-notice"  aria-hidden="true" ><fa name="ban"></fa> Cancellation Notice</a></li>

                </ul>
            </div>
        </div>
  <!-- for mobile selectbox  -->
        </div>
      </div>
      <div class="col-xs-12 col-sm-8 col-md-9 col-lg-9">
       <div class="tab-content">
         
          <!--  current balance and details -->
        <app-current-balance [currentActive]="profileModule" [accountDetails]="activeAccount" *ngIf="profileModule=='current-balance'"></app-current-balance>
        <!-- end Section -->
        <app-subscription-renewal  [currentActive]="profileModule" [accountNumber]="accountNumber" [accountDetails]="activeAccount" *ngIf="profileModule=='account-subscription-renewal'"></app-subscription-renewal>
       
        <!--  autopay section -->
      <app-auto-pay [currentActive]="profileModule" [accountNumber]="accountNumber" [accountDetails]="activeAccount" *ngIf="profileModule=='auto-pay'"></app-auto-pay>
      <!-- end Section -->
      <!-- current invoce section -->
    <app-current-invoice [currentActive]="profileModule" [accountNumber]="accountNumber" *ngIf="profileModule=='current-invoice'"></app-current-invoice>
    <!-- end Section -->
    <!-- previous invoice section -->
  <app-previous-invoices [currentActive]="profileModule" [accountNumber]="accountNumber" *ngIf="profileModule=='previous-invoices'"></app-previous-invoices>
  <!-- end Section -->
  <!-- make payment section -->
<app-make-payment [currentActive]="profileModule" [accountNumber]="accountNumber" [accountDetails]="activeAccount" *ngIf="profileModule=='make-payment'"></app-make-payment>
<!-- End Section -->
<!-- payment history section -->
<app-payment-history [currentActive]="profileModule" [accountNumber]="accountNumber" *ngIf="profileModule=='payment-history'"></app-payment-history>
<!-- End Section -->
<!-- payment methods section -->
<app-payment-methods [currentActive]="profileModule" [activeAccount]="activeAccount" [accountNumber]="accountNumber" *ngIf="profileModule=='payment-methods'"></app-payment-methods>
<!-- End Section -->
<!-- billing information section -->
<app-billing-information [currentActive]="profileModule" [accountNumber]="accountNumber" *ngIf="profileModule=='billing-information'"></app-billing-information>
<!-- End Section -->
<!-- frequently asked questions -->
<app-frequently-asked-questions [currentActive]="profileModule" [accountNumber]="accountNumber" *ngIf="profileModule=='frequently-asked-questions'"></app-frequently-asked-questions>
<!-- End Section -->
<!-- loyalty club -->
<app-loyalty-club [currentActive]="profileModule" [accountNumber]="accountNumber"  *ngIf="profileModule=='loyalty-club'"></app-loyalty-club>
<!-- End Section -->
<!-- cancellation notice section -->
<app-remove-account [currentActive]="profileModule" [accountNumber]="accountNumber" *ngIf="profileModule=='delete-account'"></app-remove-account>
<!-- End Section -->
<!-- cancellation notice section -->
<app-cancellation-notice [currentActive]="profileModule" [accountNumber]="accountNumber" *ngIf="profileModule=='account-cancellation-notice'"></app-cancellation-notice>
<!-- End Section -->
</div>
</div>
</div>
<!-- </div> -->
<!-- <app-lazy-load [isLoaded]="isLoaded" [sectionName]="'account details'"></app-lazy-load> -->
</div>
</div>
</div>
</div>
</div>
<div class="clearfix">
</div>


<app-footer></app-footer>