<!-- <app-header></app-header> -->

<!-- <app-footer></app-footer> -->
<app-header></app-header>


<section class="facality-details commonPage orangeBar ">
    <div class="container mar-top-lg" style="margin-top:200px;min-height:calc(100vh - 200px)" *ngIf="!isLoaded">
        <app-lazy-load [isLoaded]="isLoaded" [sectionName]="'facility details'"></app-lazy-load>
    </div>
    <div class="searchFilter ">
        <app-search-filters (updateFacilityFilters)="receiveFacilityNewSearchFilter($event)" (updateFilters)="receiveNewSearchFilter($event)" (updateFiltersOnSubmit)="receiveNewSearchFilterOnSubmit($event)" (searchtypedata)="getsearchtype($event)"></app-search-filters>
    </div>
    <!-- Directions Model Starts From Here -->
    <div class="modal fade" id="getdirections">
        <div class="modal-dialog modal-sm">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" data-dismiss="modal" aria-hidden="true"><fa name="times"></fa></button>
                    <h3 class="mar-top-0 text-primary">Get Directions</h3>
                </div>

                <div class="modal-body">
                    <div class="form-group">
                        <a *ngIf="facility.geolocations" class="btn btn-primary btn-block theme-btn" target="_blank" id="navigatetogoogle" href="https://maps.google.com?q={{facility.geolocations.latitude}},{{facility.geolocations.longitude}}">Navigate with Google Maps</a>
                    </div>
                    <div class="floating-label">
                        <label class="control-label text-uppercase" for="sendapp">Send to Phone</label>
                        <div class="input-group">
                            <input type="text" class="form-control" id="sendapp" placeholder="Enter phone number">
                            <span class="input-group-btn pad-left-xs">
								<button type="submit" class="btn theme-btn btn-primary" (click)="sendApp()">Send</button>
							</span>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Direction Model Ends Here -->
    <div class="container mar-top-md mar-top-mob homeTopFix " *ngIf="isLoaded">
        <div class="mobile-search-filter-nav hidden">
            <app-search-filters (updateFacilityFilters)="receiveFacilityNewSearchFilter($event)" (updateFilters)="receiveNewSearchFilter($event)"></app-search-filters>
        </div>
        <div class="clearfix mar-top-0 hidden-xs"></div>
        <!--  for back button to all parking -->
        <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-8 col-md-offset-2 facility-mob">
                <!-- <div class="clearfix mar-bottom-xs">
					<button class="btn btn-link text-uppercase" ><fa name="angle-left"></fa> &nbsp; All Parking </button>
				</div> -->
                <!-- Parking image and Map  -->
                <div class="facility-Wt-Box">
                <div class="facalityDetails">
                    <div class="row-xs facalityImg flexItem">
                       <div *ngIf="searchType == 'monthly'" class="booknow-mont-serch text-right"><a href="javascript:;"><img src="assets/img/search-monthlybook.png"></a></div>
                        <img [src]="park_garage" alt="image" class="full-width" *ngIf="!facility?.photos?.url">
                        <img [src]="facility?.photos?.url" alt="image" class="full-width" *ngIf="facility?.photos?.url">
                    </div>
                    <div class="facalityMap flexItem hidden-xs" id="facility_map_container">
                        <!-- <iframe src="https://maps.google.com/maps?q=35.856737, 10.606619&z=15&output=embed" width="100%" height="100%" frameborder="0" style="border:0"></iframe> -->
                    </div>
                </div>
                <div class="clearfix"></div>
                <!--  for other Details Content -->
                <div class="contentWrap">

                    <!-- <article id="special-flag" class="text-center text-uppercase" *ngIf="allParkings.category_id == earlyBirdSpecialCategory" [class.hidden]="!EarlyBrid" > -->

                    <div class="blue-box-flag" *ngIf="searchType == 'daily'">
                        <article id="special-flag" class="text-center special-flag text-uppercase" [class.hidden]="!EarlyBrid" *ngIf="!availabilityCheck && !activeLoading && (categoryId == earlyBirdSpecialCategory || couponCode != '') && showRecord  && (FacilityAvailableFetatures?.ticketech_id != '' )">


                            <div class="erlybirdDate ">
                                <h3 class="text-white visible-xs">online special.</h3>
                                <h3 class="text-white hidden-xs">Web special.</h3>

                                <p *ngIf="categoryId == earlyBirdSpecialCategory && couponCode == ''">PAID RESERVATION  REQUIRED.</p>
                                <p *ngIf="couponCode != ''">PAID RESERVATION  REQUIRED.</p>
                                <!-- <p>Content Shown based on the coupon</p> -->
                            </div>

                        </article>
                    </div>

                    <div class="clearfix panel-body pad-left-0 pad-right-0 box-sdw-border">
                        <div class="flex-row no-gutters">
                            <div class="col-9 col-lg-7 col-md-7">
                                <div class="customeFontSetup">
                                <h3 class=" text-uppercase mar-bottom-0" id="parkfull_name">{{ facility?.entrance_location }}</h3>
                                <div class="mar-top-xs visible-xs"></div>
                                <span><b>Garage Name:</b> {{ facility?.facility_name_modified}}</span>
                                <br *ngIf="facility?.between_streets" />
                                <span *ngIf="facility?.between_streets"><b>Between:</b>  {{ facility?.between_streets }}</span><br/>
                                <span class="mar-bottom-xs" *ngIf="facility?.geolocations?.address_1"><b>Entrance:</b> {{ facility?.geolocations?.address_1 }}</span>
                                </div>
                                <p id="between_entrance"></p>
                                <table class="DateBlock">
                                  <tr>
                                    <td>
                                        <fa name="map-marker"></fa> &nbsp; <span id="locDistance">{{ distance | distanceconversion }}</span>
                                    </td>
                                    <td>
                                        <fa name="phone"></fa> &nbsp; <a id="garage_phone" style="color: #000;" href="tel:{{ facility?.phone_number }}">{{ formatPhoneNumber(facility?.phone_number) }}</a>
                                    </td>
                                  </tr>
                                  </table>

                                <p class="dailystyles  mar-top-xs mar-right-sm hidden-xs"><a data-toggle="modal" href="#getdirections">Get Directions &nbsp;</a> &nbsp;
                                    <a *ngIf="searchType == 'daily'" style="cursor: pointer;"  (click)="openModal(postedfacilityrats,1)">See Posted Rates &nbsp;</a> &nbsp;
                                    <a  [scrollTo]="'#dailyparkingschedule'" [scrollDuration]="1000" [scrollOffset]="-200" style="cursor: pointer;">Hours of Operations</a>
                                </p>
                                <p class=" hidden-xs" *ngIf="workingHours?.length > 0 && facility?.is_alternative_facility > 0 && searchType == 'monthly'" >*Please be advised of amended hours at this location.<br class="hidden-xs"> <a target="_blank" [href]="temporarilyClosedUrl">Click Here</a> for alternative garage information*</p>
                                
                                <p *ngIf="searchType == 'daily' && !activeLoading && (FacilityAvailableFetatures?.ticketech_id != '' || ( FacilityAvailableFetatures?.parkonect_id != '' && FacilityAvailableFetatures?.parkonect_id != null ) || (FacilityAvailableFetatures?.valet_manager_id != '' && FacilityAvailableFetatures?.valet_manager_id != null) )" class="hidden-xs">
                                    <strong [ngClass]="{ 'text-danger' : (FacilityAvailableFetatures?.availability_msg_some_space =='') }">{{FacilityAvailableFetatures?.availability_msg}}</strong>
                                </p>
                                <p *ngIf="searchType == 'daily' && !activeLoading && (FacilityAvailableFetatures?.ticketech_id == '' && ( FacilityAvailableFetatures?.parkonect_id == '' || FacilityAvailableFetatures?.parkonect_id == null ) && (FacilityAvailableFetatures?.valet_manager_id == '' || FacilityAvailableFetatures?.valet_manager_id == null) )" class="text-uppercase hidden-xs">
                                    <strong [ngClass]="{ 'text-danger' : 1 }">This facility is not available for reservations</strong>
                                </p>
                                
                                <p *ngIf="searchType == 'daily'" class="mar-top-xs mar-bottom-xs w-100 hidden-xs"><a role="button" font-12 class="text-dark mt-4" style="cursor: pointer;"><img width="14" class="mar-right-xs" src="/assets/img/best_guaranteed_icon.png">Our Best Deals Guaranteed </a> &nbsp;&nbsp;&nbsp;&nbsp;<a role="button" font-12 class="text-dark mt-4" style="cursor: pointer;"><img width="14" class="mar-right-xs" src="/assets/img/v8-design/riskfree.svg">Risk-free, Easy Cancellation </a></p>
                                <div *ngIf="searchType == 'monthly'" class="month-booknow hidden-xs">
                                <p class="booknow-black"><a href="javascript:;"><img [src]="booknow_blackLogo" alt=""></a></p>
                                <p class="mar-top-xs mar-bottom-xs w-100"><a role="button" font-12 class="text-dark mt-4" style="cursor: pointer;"><img width="14" class="mar-right-xs" src="/assets/img/best_guaranteed_icon.png">Our Best Deals Guaranteed </a></p>
                                </div>
                                <!-- <div class="clearfix mar-top-xs flyimg hidden-xs"><img [src]="quikparkIcon" alt="image" height="40px"></div> -->
                                
                            </div>
                            <div class="col col-lg-5 col-md-5 text-center facility-wt">
                                <div *ngIf="searchType == 'daily'" class="daily-price-details">
                                    <p class="rates-from">Rates from</p>
                                    <h3 class="price-value1">
                                        <!-- <span id="special-flag" >Web special</span> -->
                                        <button class="btnEarlybirdWeb p-0" (click)="btnEarlyBrid()" *ngIf="!availabilityCheck && !activeLoading && (categoryId == earlyBirdSpecialCategory || couponCode != '') && showRecord  && (FacilityAvailableFetatures?.ticketech_id != '' )"><i class="{{earlybirIcon}}"></i></button>
                                        <a *ngIf="activeLoading">
                                            <fa animation="spin" name="circle-o-notch">
                                                <i aria-hidden="true" class="fa fa-circle-o-notch fa-spin"></i>
                                            </fa>
                                        </a>
                                        <div *ngIf="!activeLoading">
                                            <span id="base_rate" *ngIf="!availabilityCheck">{{changeRateUpdated(FacilityAvailableFetatures?.price)}}</span>
                                            <span [ngClass]="{'disable-price': FacilityAvailableFetatures?.price_without_availibility !='N/A'}" *ngIf="availabilityCheck">{{changeRateUpdated(FacilityAvailableFetatures?.price_without_availibility)}}</span>
                                        </div>
                                    </h3>
                                    <h3 class="mar-top-0 mar-bottom-xs text-right date-time-color hidden-xs">
                                        <small class="text-uppercase timeDateEdit" id="monthdaily">
                                        {{ displayableStartDateWrapper() }} - {{ displayableEndDateWrapper() }}<br/>
                                        {{ displayStartTimeWrapper() }} - {{ displayEndTime }}
                                    </small>
                                    </h3>
                                    <div class="clearfix" *ngIf="searchType == 'daily' && isUpdatedEndTimeFlag">
                                    <p class="extend-text hidden-xs"><i>Your reservation was extended at no extra charge !</i></p> 
                                     </div>
                                    <ng-template #MTSPTemplate>
                                        <div class="more-time-same-price-box" [innerHtml]="MTSMMessage"></div>
                                    </ng-template>
                                </div>
                            <div class="hidden-xs">
                            <div *ngIf="is_monthly_campaign && searchType == 'monthly'" class="specialPriceBadgeTop"><span class="special-price">SPECIAL PRICE</span></div>
                            <div class="clearfix border-top monthlyRatesSection" *ngIf="searchType == 'monthly'">
                                    
                                    <div class="row">
                                        <div class="col">
                                        <h4 class="mb-0 mt-3 font-weight-bold"> {{selectedvahicletypeText2 }}  <img style="cursor: pointer;" (click)="openModal(monthlyRateDesc, facility.id)" src="/assets/img/help.svg" alt="image" />  </h4>
                                         <p><b>Starting {{displayStartDateMonthly()}}</b></p>                                       
                                        </div>
                                    </div>
                
                
                                    <!-- <div  class="dropdown 22 selectBoxMap">
                
                                        <ul *ngIf="is_tax_include==0" class="select-list-map 1">
                                            <li  [class.active]="vhc.rate==baserate.replace('$','') " (click)="changeRate(vhc.rate, vhc.rate_type,vhc.net_rate,vhc.hours_description,vhc.description)" *ngFor="let vhc of vehicletype; index as index">
                                                
                                                <div class="radio linkLabel" *ngIf="vhc.net_rate != '0.00' && vhc.active==1" >
                                                     <label htmlFor={{index}} [ngClass]="{'font-weight-bold': selectedvahicletype==vhc.rate_type}" >
                                                        <input id="{{index}}" [ngClass]="{'activeMontlyPlan': selectedvahicletype==vhc.rate_type}" type="radio" name="price" value={{vhc.rate_type}} class="radio radio_alt" />${{vhc.net_rate}} - {{vhc.rate_type}} </label>
                                                        {{makeSelectableMontheke(selectedvahicletype, vhc.rate_type)}}
                                                        <div *ngIf="showSavingFlexibilityMsg(vhc.rate_type)" class="flexibilityBadge">
                                                       SAVINGS + FLEXIBILITY
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                        <ul *ngIf="is_tax_include==1" class="select-list-map 2">
                                                <li  (click)="changeRate(vhc.rate, vhc.rate_type,vhc.rate,vhc.hours_description,vhc.description)" *ngFor="let vhc of vehicletype; index as index">
                                                    
                                                    <div class="radio linkLabel" *ngIf="vhc.net_rate != '0.00' && vhc.active==1" >
                                                          <label htmlFor={{index}} [ngClass]="{'font-weight-bold': selectedvahicletype==vhc.rate_type}" >
                                                            <input id="{{index}}" [ngClass]="{'activeMontlyPlan': selectedvahicletype==vhc.rate_type}" type="radio" name="prices" value={{vhc.rate_type}} class="radio radio_alt" />${{vhc.rate}} - {{vhc.rate_type}} </label>
                                                            {{makeSelectableMontheke(selectedvahicletype, vhc.rate_type)}}
                                                            <div *ngIf="showSavingFlexibilityMsg(vhc.rate_type)" class="flexibilityBadge">
                                                            SAVINGS + FLEXIBILITY
                                                        </div>
                                                    </div></li>
                                            </ul>
                                    </div> -->

                                    <div  class="dropdown selectBoxMap">
        
                                        <ul *ngIf="is_tax_include==0" class="select-list-map ">
                                            <li  [class.active]="vhc.rate==baserate.replace('$','') " (click)="changeRate(vhc.rate, vhc.rate_type,vhc.net_rate,vhc.hours_description,vhc.description)" *ngFor="let vhc of vehicletype; index as index">
                                                <span >{{fetchCurentPrice(selectedvahicletype, vhc.rate_type, vhc.rate)}}</span>  

                                                <div class="radio linkLabel" *ngIf="vhc.net_rate != '0.00' && vhc.active==1" >
                                                  <table style="width:100%; font-size: 14px;"><tr><td style="width:160px">
                                                     <label htmlFor={{index}} [ngClass]="{'IESelector' : isIEBrowser, 'font-weight-bold': selectedvahicletype==vhc.rate_type}">
                                                        <input id="{{index}}" [ngClass]="{'activeMontlyPlan': selectedvahicletype==vhc.rate_type}" type="radio" name="price" value={{vhc.rate_type}} class="radio radio_alt" />${{vhc.net_rate}} - {{vhc.rate_type}} </label>
                                                    </td>
                                                       <td>
                                                        <div *ngIf="showSavingFlexibilityMsg(vhc.rate_type)" [class]="bgclr" class=" pull-left ml-3" >
                                                            {{orangestriptdata}}
                                                        </div>
                                                       </td></tr></table>
                                                       {{makeSelectableMonthyPrice(selectedvahicletype, vhc.rate_type)}}
                                                </div>
                                            </li>
                                        </ul>
                                        <ul *ngIf="is_tax_include==1" class="select-list-map ">
                                                <li  (click)="changeRate(vhc.rate, vhc.rate_type,vhc.rate,vhc.hours_description,vhc.description)" *ngFor="let vhc of vehicletype; index as index">
                                                    <span >{{fetchCurentPrice(selectedvahicletype, vhc.rate_type, vhc.rate)}}</span>  

                                                    <div class="radio linkLabel" *ngIf="vhc.net_rate != '0.00' && vhc.active==1" >
                                                      <table style="width:100%"><tr><td style="width:160px">
                                                      <label htmlFor={{index}} [ngClass]="{'IESelector' : isIEBrowser, 'font-weight-bold': selectedvahicletype==vhc.rate_type}" >
                                                            <input id="{{index}}" [ngClass]="{'activeMontlyPlan': selectedvahicletype==vhc.rate_type}" type="radio" name="prices" value={{vhc.rate_type}} class="radio radio_alt" />${{vhc.rate}} - {{vhc.name_alias}} </label>
                                                      </td>    
                                                           
                                                          <td> <div *ngIf="showSavingFlexibilityMsg(vhc.rate_type)" [class]="bgclr" class=" pull-left ml-3" >
                                                            {{orangestriptdata}}
                                                        </div>
                                                      </td> 
                                                    </tr></table>
                                                    {{makeSelectableMonthyPrice(selectedvahicletype, vhc.rate_type)}}
                                                    </div></li>
                                            </ul>
                                            
                                     
                                        <!-- section for rate engine -->
                                    </div>
                                </div>
                                <!-- <span *ngIf="isUpdatedEndTimeFlag" [popover]="MTSPTemplate" container="body" [outsideClick]="true" containerClass="more-time-same-price-box mob-mts-rt" placement="bottom" class="label mar-bottom-xs label-primary more-space-reservation">More Time Same Price</span> -->
                                <div *ngIf="(FacilityAvailableFetatures?.ticketech_id != '' ) && FacilityAvailableFetatures?.price_without_availibility !='N/A'">
                                    <!-- <article class="floatRateEngine search-alert-bx" *ngIf="getUpdatedPrice && !enableDefaultMsg && !activeLoading && FacilityAvailableFetatures?.price!='N/A'">
                                        {{FacilityAvailableFetatures?.coupon_price_applied}}
                                        <a class="btn btn-xs btn-link" (click)="openModal(rateEngine)"> <img src="assets/img/rateengileicon.svg" alt="image"></a>
                                    </article> -->
                                    <!-- <article class="floatRateEngine" *ngIf="!activeLoading && getUpdatedPrice && enableDefaultMsg && FacilityAvailableFetatures?.price!='N/A'">
									Lower rate may be available
									<a class="btn btn-xs btn-link" (click)="openModal(rateEngine)"> <img src="assets/img/rateengileicon.svg"  ></a>
								</article> -->
                                </div>
                            </div>
                                <!-- <div class="clearfix hidden-xs">
									<a *ngIf="showRecord" class="btn btn-primary pad-left-0 pad-right-0 btn-fill btn-block dailymonthlybutton" id="dailymonthlybutton" (click)="navigateDailyBooking()" >Book Now</a>
									
									<a href="javascript:;" class="btn btn-primary btn-sm btnCoupon theme-btn btn-block" id="checkcoupon" *ngIf="showRecord && couponId && rateTypeId == 2 || rateTypeId == 4" (click)="manageCoupons()">GET COUPON*</a>
									<button style="display: none;" id="triggercoupon" type="button" class="btn btn-primary" (click)="lgModal.show()"></button>
									<small class="spsutoava text-center" id="couponcontent" *ngIf="couponId && rateTypeId == 2 || rateTypeId == 4" >* Space Subject to Availability</small>
								</div> -->

                                <!-- <div class="book-btns-wrapper hidden-xs">
                                    <span class="btn-block mar-bottom-xs"  *ngIf="!getUpdatedPrice && !availabilityCheck && !activeLoading">
										<a class="btn btn-primary pad-left-0 tagRibbon btn-fill btn-block" id="dailymonthlybutton" *ngIf="( FacilityAvailableFetatures.price!='N/A' ) && (FacilityAvailableFetatures.ticketech_id != '' )" (click)="navigateDailyBooking()" >  Book Now  <small>spot guaranteed</small> <span *ngIf="showCoupon && FacilityAvailableFetatures?.paynow_promocode_discount" class="tagoffer">Save {{FacilityAvailableFetatures?.paynow_promocode_discount}} </span> </a>
									</span>
                                    <span class="btn-block mar-bottom-xs"  *ngIf="getUpdatedPrice && !availabilityCheck && !activeLoading">
										<a class="btn btn-primary pad-left-0 tagRibbon btn-fill btn-block" id="dailymonthlybutton" *ngIf="( FacilityAvailableFetatures?.price!='N/A' ) && (FacilityAvailableFetatures.ticketech_id != '' )" (click)="navigateDailyBooking()" >  Book Now  <small>spot guaranteed</small> <span *ngIf="showCoupon && FacilityAvailableFetatures?.paynow_promocode_discount" class="tagoffer">Save {{FacilityAvailableFetatures?.paynow_promocode_discount}} </span> </a>
									</span>
                                    <span  *ngIf="availabilityCheck && !activeLoading">
										<a class="btn btn-primary pad-left-0 disableBookBtn tagRibbon btn-fill btn-block" id="dailymonthlybutton" *ngIf="( FacilityAvailableFetatures?.price_without_availibility !='N/A' ) && (FacilityAvailableFetatures.ticketech_id != '' )" >Book Now <small>spot guaranteed</small> <span *ngIf="showCoupon && FacilityAvailableFetatures?.paynow_promocode_discount" class="tagoffer">Save {{FacilityAvailableFetatures?.paynow_promocode_discount}} </span></a>
									</span>

                                    <a class="btn btn-primary btn-sm btnCoupon theme-btn btn-block searchCoupon" style="max-width:180px" id="checkcoupon" (click)="manageCoupons();" *ngIf="showCoupon && isPartners === false && rateTypeId == 2 || rateTypeId == 4">
                                        Pay At Garage<small >Spot not guaranteed</small></a>
                                    <a class="btn btn-primary btn-sm btnCoupon theme-btn btn-block partnerCoupon" id="checkcoupon" (click)="manageCoupons();" *ngIf="showCoupon && isPartners">Pay At Garage <small >Spot not guaranteed</small></a>
                                    <small class="spsutoava text-center" id="couponcontent" *ngIf="couponId && rateTypeId == 2 || rateTypeId == 4" >* Space Subject to Availability</small>
                                </div> -->
                                <div class="book-btns-wrapper hidden-xs">
                                  <span class="btn-block mar-bottom-xs"  *ngIf="searchType == 'daily' && !getUpdatedPrice && !availabilityCheck && !activeLoading">
                  <a class="btn btn-primary pad-left-0 tagRibbon btn-fill btn-block book-btn-facility" id="dailymonthlybutton" *ngIf="( FacilityAvailableFetatures?.price!='N/A' ) && (FacilityAvailableFetatures?.ticketech_id != '' || ( FacilityAvailableFetatures?.parkonect_id != '' && FacilityAvailableFetatures?.parkonect_id != null ) || (FacilityAvailableFetatures?.valet_manager_id != '' && FacilityAvailableFetatures?.valet_manager_id != null) )" (click)="navigateDailyBooking()" >  Book Now</a>
                </span>
                                  <span class="btn-block mar-bottom-xs"  *ngIf="searchType == 'daily' && getUpdatedPrice && !availabilityCheck && !activeLoading">
                  <a class="btn btn-primary pad-left-0 tagRibbon btn-fill btn-block book-btn-facility" id="dailymonthlybutton" *ngIf="( FacilityAvailableFetatures?.price!='N/A' ) && (FacilityAvailableFetatures?.ticketech_id != '' || ( FacilityAvailableFetatures?.parkonect_id != '' && FacilityAvailableFetatures?.parkonect_id != null ) || (FacilityAvailableFetatures?.valet_manager_id != '' && FacilityAvailableFetatures?.valet_manager_id != null) )" (click)="navigateDailyBooking()" >  Book Now</a>
                </span>
                                  <span  *ngIf="searchType == 'daily' && availabilityCheck && !activeLoading">
                  <a class="btn btn-primary pad-left-0 disableBookBtn tagRibbon btn-fill btn-block book-btn-facility" id="dailymonthlybutton" *ngIf="( FacilityAvailableFetatures?.price_without_availibility !='N/A' ) && (FacilityAvailableFetatures?.ticketech_id != '' || ( FacilityAvailableFetatures?.parkonect_id != '' && FacilityAvailableFetatures?.parkonect_id != null ) || (FacilityAvailableFetatures?.valet_manager_id != '' && FacilityAvailableFetatures?.valet_manager_id != null) )" >Book Now</a>
                </span>
                <span  *ngIf="searchType == 'monthly'">
                    <a *ngIf="(monthlyRateMans >0 && monthlyRateMans < 2500000)" (click)="navigateMonthlyBooking()" class="btn btn-primary pad-left-0 tagRibbon btn-fill btn-block book-btn-facility" id="dailymonthlybutton" >BUY MONTHLY</a>
                    <h3 *ngIf="(monthlyRateMans < 1 || monthlyRateMans > 2400000)" sourceFont class="font-weight-bold m-0 text-left sold-out-price"  style="cursor: default;">
                        Sold Out 
                     </h3>
                  </span>

                                  <!-- <a class="btn btn-primary btn-sm btnCoupon theme-btn btn-block searchCoupon" style="max-width:180px" id="checkcoupon" (click)="manageCoupons();" *ngIf="showCoupon && isPartners === false && rateTypeId == 2 || rateTypeId == 4">
                                      Pay At Garage<small >Spot not guaranteed</small></a>
                                  <a class="btn btn-primary btn-sm btnCoupon theme-btn btn-block partnerCoupon" id="checkcoupon" (click)="manageCoupons();" *ngIf="showCoupon && isPartners">Pay At Garage <small >Spot not guaranteed</small></a> -->
                                  <!-- <small class="spsutoava text-center" id="couponcontent" *ngIf="couponId && rateTypeId == 2 || rateTypeId == 4" >* Space Subject to Availability</small> -->
                          <small *ngIf="showOversizedMsg" class="smallTextAdded hidden-xs"> 
                            {{overSizeMsg}}
                          </small>
                     </div>

                     <!-- <div  class="trustpilot-widget  hidden-xs showRatingWrapper" id="trustboxOpenFacilityWeb" data-locale="en-US" data-template-id="5419b6ffb0d04a076446a9af" data-businessunit-id="5feb84d1e2aa300001625337" data-style-height="20px" data-style-width="420px" data-theme="light" [attr.data-location]="FacilityAvailableFetatures?.facility?.garage_code">
                        <a href="https://www.trustpilot.com/review/iconparkingsystems.com" target="_blank" rel="noopener"></a>
                    </div> -->
                    <div id="trustboxOpenFacilityWeb" class="trustpilot-widget  hidden-xs showRatingWrapper " data-locale="en-US" data-template-id="53aa8807dec7e10d38f59f32" data-businessunit-id="5feb84d1e2aa300001625337" data-style-height="110px" data-style-width="240px" data-theme="light" [attr.data-location]="FacilityAvailableFetatures?.facility?.garage_code">
                        <a href="https://www.trustpilot.com/review/iconparkingsystems.com" target="_blank" style="display: none;" rel="noopener">Trustpilot</a>
                    </div>

            </div>
                        </div>
                        <div class="row visible-xs book-btns-wrapper">
                        <div class="col-sm-12">
                        <p class="dailystyles  mar-top-xs mar-right-sm"><a data-toggle="modal" href="#getdirections">Get Directions &nbsp;</a> &nbsp;
                            <a *ngIf="searchType == 'daily'" style="cursor: pointer;" (click)="openModal(postedfacilityrats,1)">See Posted Rates&nbsp;</a> &nbsp;
                            <a  [scrollTo]="'#dailyparkingschedule'" [scrollDuration]="1000" [scrollOffset]="-200" style="cursor: pointer;">Hours of Operations</a>

                            <!-- <a *ngIf="searchType == 'monthly'" style="cursor: pointer;" (click)="openModal(postedfacilityrats,1)">Hours of Operations</a> -->
                        </p>
                        <p class="visible-xs" style="padding:10px 0 5px" *ngIf="workingHours?.length > 0 && facility?.is_alternative_facility > 0 && searchType == 'monthly'" >*Please be advised of amended hours at this location.<br class="hidden-xs"> <a target="_blank" [href]="temporarilyClosedUrl">Click Here</a> for alternative garage information*</p>
                        <ng-container *ngIf="searchType == 'daily'">
                            <h3 class="mar-top-0 mar-bottom-xs text-right date-time-color">
                                <small class="text-uppercase timeDateEdit" id="monthdaily">
                                                {{ displayableStartDateWrapper() }} - {{ displayableEndDateWrapper() }} &nbsp; &nbsp; {{ displayStartTimeWrapper() }} - {{ displayEndTime }}
                                                </small>
                            </h3>
                            <p  class="extend-text"><i>Your reservation was extended at no extra charge !</i></p>
                            <p *ngIf="searchType == 'daily' && !activeLoading && (FacilityAvailableFetatures?.ticketech_id != '' || ( FacilityAvailableFetatures?.parkonect_id != '' && FacilityAvailableFetatures?.parkonect_id != null ) || (FacilityAvailableFetatures?.valet_manager_id != '' && FacilityAvailableFetatures?.valet_manager_id != null) )" class="">
                            <strong [ngClass]="{ 'text-danger' : (FacilityAvailableFetatures?.availability_msg_some_space =='') }">{{FacilityAvailableFetatures?.availability_msg}}</strong>
                        </p>
                        <p *ngIf="searchType == 'daily' && !activeLoading && (FacilityAvailableFetatures?.ticketech_id == '' && ( FacilityAvailableFetatures?.parkonect_id == '' || FacilityAvailableFetatures?.parkonect_id == null ) && (FacilityAvailableFetatures?.valet_manager_id == '' || FacilityAvailableFetatures?.valet_manager_id == null) )" class="text-uppercase">
                            <strong [ngClass]="{ 'text-danger' : 1 }">This facility is not available for reservations</strong>
                        </p>
                    </ng-container>
                    <div *ngIf="is_monthly_campaign && searchType == 'monthly'" class="specialPriceBadgeTop"><span class="special-price">SPECIAL PRICE</span></div>
                            <div class="clearfix border-top monthlyRatesSection" *ngIf="searchType == 'monthly'">
                                    
                                    <div class="row">
                                        <div class="col">
                                        <h4 class="mb-0 mt-3 font-weight-bold"> {{selectedvahicletypeText2 }}  <img style="cursor: pointer;" (click)="openModal(monthlyRateDesc, facility.id)" src="/assets/img/help.svg" alt="image" />  </h4>
                                         <p><b>Starting {{displayStartDateMonthly()}}</b></p>                                       
                                        </div>
                                    </div>
                
                
                                    <div  class="dropdown selectBoxMap">
                
                                        <ul *ngIf="is_tax_include==0" class="select-list-map 1">
                                            <li  [class.active]="vhc.rate==baserate.replace('$','') " (click)="changeRate(vhc.rate, vhc.rate_type,vhc.net_rate,vhc.hours_description,vhc.description)" *ngFor="let vhc of vehicletype; index as index">
                                                
                                                <div class="radio linkLabel" *ngIf="vhc.net_rate != '0.00' && vhc.active==1" >
                                                  <table style="width:100%; font-size: 14px;"><tr><td style="width:175px">
                                                     <label htmlFor={{index}} [ngClass]="{'font-weight-bold': selectedvahicletype==vhc.rate_type}" >
                                                        <input id="{{index}}" [ngClass]="{'activeMontlyPlanMob': selectedvahicletype==vhc.rate_type}" type="radio" name="price" value={{vhc.rate_type}} class="radio radio_alt" />${{vhc.net_rate}} - {{vhc.rate_type}} </label>
                                                      </td>
                                                        
                                                      <td><div *ngIf="showSavingFlexibilityMsg(vhc.rate_type)" [class]="bgclr" class=" pull-left ml-3" >
                                                        {{orangestriptdata}}
                                                    </div>
                                                  </td></tr></table>
                                                  {{makeSelectableMonthyPriceMob(selectedvahicletype, vhc.rate_type)}}
                                                </div>
                                            </li>
                                        </ul>
                                        <ul *ngIf="is_tax_include==1" class="select-list-map 2">
                                                <li  (click)="changeRate(vhc.rate, vhc.rate_type,vhc.rate,vhc.hours_description,vhc.description)" *ngFor="let vhc of vehicletype; index as index">
                                                    
                                                    <div class="radio linkLabel" *ngIf="vhc.net_rate != '0.00' && vhc.active==1" >
                                                      <table style="width:100%; font-size: 14px;"><tr><td style="width:175px">
                                                          <label htmlFor={{index}} [ngClass]="{'font-weight-bold': selectedvahicletype==vhc.rate_type}" >
                                                            <input id="{{index}}" [ngClass]="{'activeMontlyPlanMob': selectedvahicletype==vhc.rate_type}" type="radio" name="prices" value={{vhc.rate_type}} class="radio radio_alt" />${{vhc.rate}} - {{vhc.rate_type}} </label>
                                                           </td>
                                                            
                                                          <td><div *ngIf="showSavingFlexibilityMsg(vhc.rate_type)" [class]="bgclr" class=" pull-left ml-3" >
                                                            {{orangestriptdata}}
                                                        </div></td></tr></table>
                                                        {{makeSelectableMonthyPriceMob(selectedvahicletype, vhc.rate_type)}}
                                                    </div></li>
                                            </ul>
                                       
                                        <!-- section for rate engine -->
                                    </div>
                                </div>
                                <div *ngIf="(FacilityAvailableFetatures?.ticketech_id != '' ) && FacilityAvailableFetatures?.price_without_availibility !='N/A'">
                                    </div>
                        </div>
                        </div>
                    
                    <div class="row visible-xs book-btns-wrapper">
                        <div class="clearfix mar-bottom-xs">
                            <!-- <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 flyimg"><img [src]="quikparkIcon" alt="image" height="40px"></div> -->
                            <!-- <div  class="col-xs-6 col-sm-6 col-md-6 col-lg-6 availability-text">
								<small class="spsutoava text-center" id="couponcontent" *ngIf="couponId && rateTypeId == 2 || rateTypeId == 4" >* Space Subject to Availability</small>
							</div> -->
                        </div>
                        <!-- <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 pad-0" *ngIf="couponId && rateTypeId == 2 || rateTypeId == 4">
                            <a class="btn btn-primary pad-left-0 tagRibbon btn-fill btn-block dailymonthlybutton" *ngIf="getUpdatedPrice && ( FacilityAvailableFetatures.price!='N/A' ) && (FacilityAvailableFetatures.ticketech_id != '')" id="dailymonthlybutton" (click)="navigateDailyBooking()">Book Now  <small>spot guaranteed</small> <span *ngIf="showCoupon && FacilityAvailableFetatures?.paynow_promocode_discount" class="tagoffer">Save {{FacilityAvailableFetatures?.paynow_promocode_discount}} </span></a>
                            <a class="btn btn-primary pad-left-0 tagRibbon btn-fill btn-block dailymonthlybutton" *ngIf="!getUpdatedPrice && ( FacilityAvailableFetatures?.price!='N/A' ) && (FacilityAvailableFetatures.ticketech_id != '')" id="dailymonthlybutton" (click)="navigateDailyBooking()">Book Now  <small>spot guaranteed</small> <span *ngIf="showCoupon && FacilityAvailableFetatures?.paynow_promocode_discount" class="tagoffer">Save {{FacilityAvailableFetatures?.paynow_promocode_discount}} </span></a>
                            <a class="btn btn-primary mobile-disable-price pad-left-0 pad-right-0 btn-fill btn-block theme-btn dailymonthlybutton" *ngIf="availabilityCheck && ( FacilityAvailableFetatures?.price_without_availibility !='N/A' ) && (FacilityAvailableFetatures.ticketech_id != '')"
                                id="dailymonthlybutton" (click)="navigateDailyBooking()">Book Now </a>
                        </div>
                        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6 pad-0" *ngIf="!(couponId && rateTypeId == 2 || rateTypeId == 4)">
                            <a class="btn btn-primary pad-left-0 tagRibbon btn-fill btn-block dailymonthlybutton" *ngIf="getUpdatedPrice && ( FacilityAvailableFetatures.price!='N/A' ) && (FacilityAvailableFetatures.ticketech_id != '')" id="dailymonthlybutton" (click)="navigateDailyBooking()">Book Now  <small>spot guaranteed</small> <span *ngIf="showCoupon && FacilityAvailableFetatures?.paynow_promocode_discount" class="tagoffer">Save {{FacilityAvailableFetatures?.paynow_promocode_discount}} </span></a>
                            <a class="btn btn-primary pad-left-0 tagRibbon btn-fill btn-block dailymonthlybutton" *ngIf="!getUpdatedPrice && ( FacilityAvailableFetatures?.price!='N/A' ) && (FacilityAvailableFetatures.ticketech_id != '')" id="dailymonthlybutton" (click)="navigateDailyBooking()">Book Now  <small>spot guaranteed</small> <span *ngIf="showCoupon && FacilityAvailableFetatures?.paynow_promocode_discount" class="tagoffer">Save {{FacilityAvailableFetatures?.paynow_promocode_discount}} </span></a>
                            <a class="btn btn-primary mobile-disable-price pad-left-0 pad-right-0 btn-fill btn-block theme-btn dailymonthlybutton" *ngIf="availabilityCheck && ( FacilityAvailableFetatures?.price_without_availibility !='N/A' ) && (FacilityAvailableFetatures.ticketech_id != '')"
                                id="dailymonthlybutton" (click)="navigateDailyBooking()">Book Now </a>
                        </div> -->
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12" *ngIf="searchType == 'daily' && couponId && rateTypeId == 2 || rateTypeId == 4">
                          <a class="btn btn-primary pad-left-0 tagRibbon btn-fill btn-block dailymonthlybutton book-btn-facility" *ngIf="getUpdatedPrice && ( FacilityAvailableFetatures?.price!='N/A' ) && (FacilityAvailableFetatures?.ticketech_id != '' || ( FacilityAvailableFetatures?.parkonect_id != '' && FacilityAvailableFetatures?.parkonect_id != null ) || (FacilityAvailableFetatures?.valet_manager_id != '' && FacilityAvailableFetatures?.valet_manager_id != null) )" id="dailymonthlybutton" (click)="navigateDailyBooking()">Book Now </a>
                          <a class="btn btn-primary pad-left-0 tagRibbon btn-fill btn-block dailymonthlybutton book-btn-facility" *ngIf="!getUpdatedPrice && ( FacilityAvailableFetatures?.price!='N/A' ) && (FacilityAvailableFetatures?.ticketech_id != '' || ( FacilityAvailableFetatures?.parkonect_id != '' && FacilityAvailableFetatures?.parkonect_id != null ) || (FacilityAvailableFetatures?.valet_manager_id != '' && FacilityAvailableFetatures?.valet_manager_id != null) )" id="dailymonthlybutton" (click)="navigateDailyBooking()">Book Now </a>
                          <a class="btn btn-primary mobile-disable-price pad-left-0 pad-right-0 btn-fill btn-block theme-btn dailymonthlybutton book-btn-facility" *ngIf="availabilityCheck && ( FacilityAvailableFetatures?.price_without_availibility !='N/A' ) && (FacilityAvailableFetatures?.ticketech_id != '' || ( FacilityAvailableFetatures?.parkonect_id != '' && FacilityAvailableFetatures?.parkonect_id != null ) || (FacilityAvailableFetatures?.valet_manager_id != '' && FacilityAvailableFetatures?.valet_manager_id != null) )"
                              id="dailymonthlybutton" (click)="navigateDailyBooking()">Book Now </a>
                      </div>
                      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12" *ngIf="searchType == 'daily' && !(couponId && rateTypeId == 2 || rateTypeId == 4)">
                          <a class="btn btn-primary pad-left-0 tagRibbon btn-fill btn-block dailymonthlybutton book-btn-facility" *ngIf="getUpdatedPrice && ( FacilityAvailableFetatures?.price!='N/A' ) && (FacilityAvailableFetatures?.ticketech_id != '' || ( FacilityAvailableFetatures?.parkonect_id != '' && FacilityAvailableFetatures?.parkonect_id != null ) || (FacilityAvailableFetatures?.valet_manager_id != '' && FacilityAvailableFetatures?.valet_manager_id != null) )" id="dailymonthlybutton" (click)="navigateDailyBooking()">Book Now </a>
                          <a class="btn btn-primary pad-left-0 tagRibbon btn-fill btn-block dailymonthlybutton book-btn-facility" *ngIf="!getUpdatedPrice && ( FacilityAvailableFetatures?.price!='N/A' ) && (FacilityAvailableFetatures?.ticketech_id != '' || ( FacilityAvailableFetatures?.parkonect_id != '' && FacilityAvailableFetatures?.parkonect_id != null ) || (FacilityAvailableFetatures?.valet_manager_id != '' && FacilityAvailableFetatures?.valet_manager_id != null) )" id="dailymonthlybutton" (click)="navigateDailyBooking()">Book Now </a>
                          <a class="btn btn-primary mobile-disable-price pad-left-0 pad-right-0 btn-fill btn-block theme-btn dailymonthlybutton book-btn-facility" *ngIf="availabilityCheck && ( FacilityAvailableFetatures?.price_without_availibility !='N/A' ) && (FacilityAvailableFetatures?.ticketech_id != '' || ( FacilityAvailableFetatures?.parkonect_id != '' && FacilityAvailableFetatures?.parkonect_id != null ) || (FacilityAvailableFetatures?.valet_manager_id != '' && FacilityAvailableFetatures?.valet_manager_id != null) )"
                              id="dailymonthlybutton" (click)="navigateDailyBooking()">Book Now </a>
                      </div>
                        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6" *ngIf="searchType == 'daily' && couponId && rateTypeId == 2 || rateTypeId == 4">

                            <a href="javascript:;" class="btn btn-primary  btnCoupon theme-btn btn-block" *ngIf="showCoupon && isPartners === false && rateTypeId == 2 || rateTypeId == 4" id="checkcoupon" (click)="manageCoupons();">Pay At Garage<small >Spot not guaranteed</small></a>
                            <a href="javascript:;" class="btn btn-primary  btnCoupon theme-btn btn-block" *ngIf="showCoupon && isPartners" id="checkcoupon" (click)="manageCoupons();">Pay At Garage <small>Spot not guaranteed</small></a>
                            <button style="display: none;" id="triggercoupon" type="button" class="btn btn-primary" (click)="lgModal.show()"></button>
                            <button style="display: none;" id="triggercoupon1" type="button" class="btn btn-primary" (click)="lgModal1.show()"></button>
                        </div>
                        <div *ngIf="searchType == 'monthly'" class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                            <a *ngIf="(monthlyRateMans >0 && monthlyRateMans < 2500000)" class="btn btn-primary pad-left-0 tagRibbon btn-fill btn-block dailymonthlybutton book-btn-facility"  id="dailymonthlybutton" (click)="navigateMonthlyBooking()">BUY MONTHLY </a>
                            <h3 *ngIf="(monthlyRateMans < 1 || monthlyRateMans > 2400000)" sourceFont class="font-weight-bold m-0 text-left sold-out-price"  style="cursor: default;">
                                Sold Out 
                             </h3>
                        </div>
                        <div class="clearfix"></div>
                        <div class="col-sm-12">
                            <small *ngIf="showOversizedMsg" class="smallTextAdded visible-xs"> 
                                {{overSizeMsg}}
                                <br />
                            </small>
                            
                          <p *ngIf="searchType == 'daily'" class="mar-top-xs mar-bottom-xs w-100 rate-text"><a role="button" font-12 class="text-dark mt-4" style="cursor: pointer;"><img width="14" class="mar-right-xs" src="/assets/img/best_guaranteed_icon.png">Our Best Deals Guaranteed </a> &nbsp;&nbsp;&nbsp;&nbsp;<a role="button" font-12 class="text-dark mt-4" style="cursor: pointer;"><img width="14" class="mar-right-xs" src="/assets/img/v8-design/riskfree.svg">Risk-free, Easy Cancellation </a></p>
                                <div *ngIf="searchType == 'monthly'" class="month-booknow ">
                                <p class="booknow-black"><a href="javascript:;"><img [src]="booknow_blackLogo" alt=""></a></p>
                                <p class="mar-top-xs mar-bottom-xs w-100"><a role="button" font-12 class="text-dark mt-4" style="cursor: pointer;"><img width="14" class="mar-right-xs" src="/assets/img/best_guaranteed_icon.png">Our Best Deals Guaranteed </a></p>
                         </div>
                        
                        </div>

                        <!-- <div class="trustpilot-widget visible-xs showRatingWrapper" id="trustboxOpenFacilityMob" data-locale="en-US" data-template-id="5419b6ffb0d04a076446a9af" data-businessunit-id="5feb84d1e2aa300001625337" data-style-height="20px" data-style-width="420px" data-theme="light" [attr.data-location]="FacilityAvailableFetatures?.facility?.garage_code">
                            <a href="https://www.trustpilot.com/review/iconparkingsystems.com" target="_blank" rel="noopener"></a>
                        </div> -->
                        <div id="trustboxOpenFacilityMob" class="trustpilot-widget  visible-xs showRatingWrapper " data-locale="en-US" data-template-id="53aa8807dec7e10d38f59f32" data-businessunit-id="5feb84d1e2aa300001625337" data-style-height="110px" data-style-width="100%" data-theme="light" [attr.data-location]="FacilityAvailableFetatures?.facility?.garage_code">
                            <a href="https://www.trustpilot.com/review/iconparkingsystems.com" target="_blank" style="display: none;" rel="noopener">Trustpilot</a>
                        </div>
                    </div>
                    </div>
                    <div class="clearfix"></div>
                    <div class="row-xs mar-bottom-0">
                        <!-- <div class="row"> -->
                        <!-- <div class="col-xs-12 col-sm-8 col-md-8 col-lg-8"> -->
                       
                            <div [ngClass]="searchType === 'monthly' ? 'box-sdw-border' : ''">
                              <div class="row" *ngIf="searchType == 'monthly'">
                                <!-- <div class="col-xs-1 col-sm-1 col-md-1 col-lg-1">
                                    <fa class="text-primary" name="calendar" size="2x"></fa>
                                </div> -->
                                <!-- <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    <div class="clearfix">
                                        <p class="text-uppercase text-primary">Monthly Parking </p>
                                        <p class="mar-top-xs  pad-bottom-xs parksafe-facility">
                                           
                                            <img class="psafe-1" src="../../../../assets/img/HORIZONTAL-LOGO.png" > <img class="psafe-2" src="../../../../assets/img/PARK-WITH-PEACE-OF-MIND.png">
                                        </p>
                                        <p>
                                            <img src="../../../../assets/img/Rectangle.svg" height="5px">&nbsp;&nbsp;<span *ngIf="searchType == 'monthly'">NYC's Only </span> Book & Park Instantly
                                        </p>
                                        <p>
                                            <img src="../../../../assets/img/Rectangle.svg" height="5px">&nbsp;&nbsp;Save with Multi-Month Subscriptions
                                        </p>
                                        <p>
                                            <img src="../../../../assets/img/Rectangle.svg" height="5px">&nbsp;&nbsp;Hassle-Free Guaranteed Spot
                                        </p>
                                        <p>
                                            <img src="../../../../assets/img/Rectangle.svg" height="5px">&nbsp;&nbsp;Contactless Check In/Check Out
                                        </p>
                                        </div>
                                    <p class="text-uppercase text-primary">Monthly Parking</p>
                                    <p>Submit an online monthly parking request form or call us to speak to a representative. Our monthly parking specialist will research our inventory and respond to your request.</p>
                                    <div class="clearfix hidden-xs">
                                        <div *ngIf="showRecord" class="pull-left pull-left mar-right-xs">
                                            <a class="btn btn-primary theme-btn btn-sm" href="javascript:;" (click)="navigateMonthlyBooking()">Buy Online</a>
                                        </div>
                                        <div *ngIf="showRecord" class="pull-left pull-left mar-right-xs">
                                            <a class="btn btn-primary theme-btn btn-sm" (click)="redirectTo_RequestPage()">Request information</a>
                                        </div>
                                        <div class="pull-left">
                                            <p>
                                                <a [href]="phoneNumber">Call (888)-705-7845</a><br><small>9am to 7pm</small>
                                            </p>
                                        </div>
                                    </div>
                                </div> -->
                                <div class="col-xs-12 mar-top-xs">
                                 <p ><strong>Need help with a reservation?</strong><br />
                                     Call <a href="tel:(888)705-7845" style="color:#000;">Call (888) 705-7845 </a> (9am to 7pm)</p>
                                </div>
                            </div>
                            </div>
                        <!-- <hr class="whiteHr" > -->
                        <!-- ameneties for desktop -->
                        <div class="clearfix facilityTableData" >
                          <div class="box-sdw-border">   
                          
                                
                                <div id="dailyparkingschedule" >
                                    <div class="TimeTab" *ngIf="workingHours?.length == 0">
                                        <!-- <h4 >Hours of Operation</h4> -->
                                        <p class="mar-top-0 font-weight-bold headingfacility">Hours of Operation</p>
                                        <table>
                                            <tr>
                                                <td width="50%">
                                                    <!-- <fa name="calendar" size="2x" class="text-primary"></fa> &nbsp;&nbsp;7 days a week -->
                                                    Monday - Sunday
                                                </td>
                                                <td width="50%">
                                                    <!-- <fa name="clock-o" class="text-primary" size="2x"></fa> &nbsp;&nbsp; -->
                                                   Open 24 Hours</td>
                                            </tr>
                                        </table>
                                    </div>
                                    <div class="TimeActive table-responsive" *ngIf="workingHours?.length > 0">
                                        <p class="font-weight-bold headingfacility">Hours of Operation</p>
                                        <table class="table table-condensed mar-bottom-0">
                                            <tr *ngFor="let hour of workingHours;">
                                                <td>{{hour.day}}</td>
                                                <td>&nbsp;&nbsp;</td>
                                                <td>{{hour.formatted_open_time}}</td>
                                                <td>-</td>
                                                <td>{{hour.formatted_close_time}}</td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                          </div>
                          <div class="box-sdw-border">  
                          <p class="mar-top-0 font-weight-bold headingfacility">Amenities</p>
								<ul class="amenities-list">
								
									<li *ngIf="FacilityAvailableFetatures?.facility?.is_generic_ev_charging =='1'">
										<img [src]="ev_charge_icon">
										<span>
											EV Charging
										</span>
									</li>
									<li *ngIf="FacilityAvailableFetatures?.facility?.is_motorcycle_parking =='1'">
										<img [src]="motorcycle_icon">
										<span>
											Motorcycle Parking
										</span>
									</li>
									<li *ngIf="FacilityAvailableFetatures?.facility?.is_tesla_charging =='1'">
										<img [src]="tesla_charger_icon">
										<span>
											Tesla Charger
										</span>
									</li>
									<li *ngIf="FacilityAvailableFetatures?.facility?.is_24hour_open =='1'">
										<img [src]="open_24hr_icon">
										<span>
											Open 24 Hours
										</span>
									</li>
									<li *ngIf="FacilityAvailableFetatures?.facility?.is_indoor_parking =='1'">
										<img [src]="indoor_icon">
										<span>
											Indoor Parking
										</span>
									</li>
									<li *ngIf="FacilityAvailableFetatures?.facility?.is_outdoor_parking =='1'">
										<img [src]="outdoor_icon">
										<span>
											Outdoor Parking
										</span>
									</li>
									<li *ngIf="FacilityAvailableFetatures?.facility?.is_oversize =='1'">
										<img [src]="oversized_icon">
										<span>
											Oversized Vehicles
										</span>
									</li>
									<li *ngIf="FacilityAvailableFetatures?.facility?.is_monthly =='1'">
										<img [src]="monthly_icon">
										<span>
											Monthly Parking
										</span>
									</li>
									
                </ul>
                </div>
								<!-- <hr class="whiteHr"> -->
							</div>

                        <!-- <hr class="whiteHr"> -->
                        
                        <div class="clearfix visible-xs text-center mar-top-xs">
                            <!-- <p>
                                <a [href]="phoneNumber">Call (888)-705-7845</a><br><small>9am to 7pm</small>
                            </p> -->
                            <!-- <div class="row">
                                <div class="col-xs-10 col-xs-offset-1">
                                    <a class="btn btn-primary theme-btn btn-sm btn-block" href="javascript:;" (click)="navigateMonthlyBooking()">Buy Online</a>
                                    <a class="btn btn-primary theme-btn btn-sm btn-block" (click)="redirectTo_RequestPage()">Request information</a>
                                </div>
                            </div> -->
                        </div>
                       <!-- <hr class="whiteHr">
                        <div class="row left-icon">
                             <div class="col-xs-1 col-sm-1 col-md-1 col-lg-1">
                                <fa class="text-primary" name="lightbulb-o" size="2x"></fa>
                            </div> -->
                            <!-- <div class="col-xs-11 col-sm-11 col-md-11 col-lg-11">
                                <p class="text-primary text-uppercase">Did you know?</p>
                                <ul class="dashList list-unstyled">
                                    <li>Many garages don't accept <a style="cursor: pointer" (click)="redirectTo_oversized()"> oversized vehicles</a>, or they often charge between $5 - $15 extra.
                                    </li>
                                    <li>If you plan to visit for the day, arriving before 10:00am will almost always result in a lower fee and a flexible departure time.
                                    </li>
                                    <li>
                                        Don't see what you want? <a [href]="phoneNumber2"> Call 1-877-PARKING</a> (727-5464) for expert advice. Monday - Friday, 9:00am to 5:00pm
                                    </li>
                                    <li><a style="cursor: pointer;" (click)="openModal(postedfacilityrats)">Posted rates</a><br/><br/></li>
                                </ul>
                            </div> 
                        </div>-->
                        <!-- <div class="clearfix posted-facilty-box">
                     <p class="text-uppercase text-primary">Posted Facility Rates</p>
                     <p class="hidden-xs">All rates shown below include a {{taxRate}}% parking tax.</p>
                     <accordion>
                        <accordion-group #group  [panelClass]="customClass" (click)="showHide();">
                          <div accordion-heading class="text-center">
                            CLICK TO {{textHideShow}} POSTED FACILITY RATES &nbsp; <i class="float-xs-right glyphicon"
                               [ngClass]="{'glyphicon-chevron-up': group?.isOpen, 'glyphicon-chevron-down': !group?.isOpen}"></i>                          </div>
                       
                    
                     <table class="table tbl-border" *ngFor="let rate of boardRate;">
                       <thead>
                            <tr><th colspan="2">{{rate.name}}</th><th>Total</th></tr>
                        </thead>
                       <tbody>
                                <ng-container *ngFor="let rates of rate.rates;">
                                   <tr *ngIf="rates.category.category==rate.name">
                                        <td >{{rates.description=='' ? '7 Days a week / Enter any time / Exit any time' : rates.description}}</td>
                                        <td >{{rates.details=='' ? '7 Days a week / Enter any time / Exit any time' : rates.details}}</td>
                                        <td >${{rates.price=='' ? '7 Days a week / Enter any time / Exit any time' : rates.price}}</td>                                    </tr>
                                </ng-container>
                           
                       </tbody>
                    </table>
                    <table class="table tbl-border" *ngIf="FacilityAvailableFetatures?.facility?.oversize_fee">
                      <thead>
                           <tr><th colspan="2">Additional Fees</th><th>Total</th></tr>
                       </thead>
                      <tbody>
                               <ng-container>
                                  <tr>
                                       <td colspan="2">Oversized vehicle fee</td>
                                       <td >${{FacilityAvailableFetatures.facility.oversize_fee}}</td>                                    
                                  </tr>
                               </ng-container>
                          
                      </tbody>
                   </table>
               </accordion-group>
           </accordion>
                    </div> -->
                    </div>
                </div>
                </div>
            </div>
        </div>
        <div class="clearfix mar-bottom-md hidden-xs"></div>
    </div>
    <!-- Coupon Model Ends Here -->

    <!-- Coupon Model Starts From Here LATEST CODE -->
    <!-- Coupon Model Ends Here -->

    <!--coupon fir multiple-->
    <div class="A-B-coupon-1">
        <div bsModal #lgModal1="bs-modal" id="coupon-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
            <div class="modal-dialog select-modal-dlg">
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="close pull-right" (click)="lgModal1.hide()" aria-label="Close">
			<span aria-hidden="true">
			  <i class="fa fa-times"></i>
			</span>
		  </button>
                    </div>
                    <h3 class="text-primary text-center mar-top-sm mar-bottom-sm" *ngIf="!ShowCouponListOnLoad">Please wait while we load Rates ...</h3>

                    <div id="couponsErrorMessage" class="hidden couponsErrorMessage"></div>
                    <div class="modal-body coupons-other-areas" id="couponmail1">
                        <div class="servererrors" id="signuperrors-container">
                        </div>
                        <div class="form-group">

                            <form id="floating-label-google-map1" name="form" role="form" class="formFix" (ngSubmit)="cm.form.valid && sendCoupon(mailFormData, cm)" #cm="ngForm" novalidate>
                                <div class="form-group" [ngClass]="{ 'has-error' : cm.submitted && !ucmail.valid || mailFormData.ucmail ==''}">
    
                                    <input placeholder="Enter Your Email" class="form-control" type="mail" name="ucmail" [(ngModel)]="mailFormData.ucmail" #ucmail="ngModel" required />
                                    <div *ngIf="cm.submitted && !ucmail.valid || mailFormData.ucmail ==''" class="help-block emailErrorHelpBlock">Email is required</div>
                                </div>
                                <div class="form-group " [ngClass]="{ 'hide' : hideSendEmailButton}">
                                    <div class="row">
                                        <div class="col-sm-6">
                                            <button class="btn btn-primary btn-block btn-fill" type="submit">EMAIL OFFER</button>
                                            <div class="mar-bottom-xs visible-xs"></div>
                                        </div>
                                        <div class="col-sm-6">
                                            <p *showItDevice="['mobile','tablet']"></p>
                                            <button class="btn btn-primary btn-block btn-fill" type="button" (click)="cancelEmail(mailFormData, cm)">CANCEL</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div class="form-group" *ngIf="!loggedUserName" >

                            <div class="checkbox">
                                <label class="enroll-terms-lbl top-checkbox">
                                    <input id="enrolledInCheck" [checked]="checkedCheckbox" name="enrolledIn"
                                    type="checkbox" required (click)="checkForEnrollNew($event)" value=""> &nbsp;&nbsp;&nbsp;Enroll in Icon Rewards and receive instant discounts on
                                    daily parking.</label>
                        </div>
                        </div>
                        <form *ngIf="showEnrollForm  && !loggedUserName" id="floatForm" class="formFix" name="form" (ngSubmit)="moveToNext(userData, iconR)" role="form"
            #iconR="ngForm">
            
            
            <p>All Icon Rewards members receive at least 10% off posted facility rates and online reservations! Enroll now to unlock immediate discounts!</p>
            <p class="mar-bottom-0">Already have an account? <a class="pad-left-xs" data-toggle="modal" [href]="loginLink" id="loginPopup">Sign in here <fa name="angle-right"></fa></a></p>
            
            <div class="row">
              <div class="col-sm-6">
                <div class="form-group" [ngClass]="{ 'has-error' : iconR.submitted && !first_name.valid}">
                  <label class="control-label" for="fname">First name</label>
                  <input class="form-control" [(ngModel)]="userData.first_name" (keypress)="restChar($event)" (paste)="onPaste($event)" name="first_name" #first_name="ngModel"
                    required type="text" placeholder="" />
                  <div *ngIf="iconR.submitted && !first_name.valid" class="help-block">First Name is required</div>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group" [ngClass]="{ 'has-error' : iconR.submitted && !last_name.valid}">
                  <label class="control-label" for="lname">Last name</label>
                  <input class="form-control" [(ngModel)]="userData.last_name" (keypress)="restChar($event)" placeholder="" (paste)="onPaste($event)" required name="last_name" #last_name="ngModel"
                    type="text" />
                  <div *ngIf="iconR.submitted && !last_name.valid" class="help-block">Last Name is required</div>
                </div>
              </div>
              <!-- <div style="display: none;" class="col-sm-6">
                <div class="form-group" [ngClass]="{ 'has-error' : iconR.submitted && !email.valid}">
                  <label class="control-label" for="email">Email address</label>
                  <input class="form-control" autocomplete="off" [(ngModel)]="userData.email" placeholder="" name="email" #email="ngModel"
                    required type="text" (keydown)="restSpace($event)" pattern="{{emailRegex}}" />
                  <div *ngIf="iconR.submitted && !email.valid" class="help-block">Email is required</div>
                </div>
              </div> -->
              <div class="col-sm-6">
                <div class="form-group" [ngClass]="{ 'has-error' : iconR.submitted && !phone.valid}">
                  <label class="control-label" for="phone">Phone number (optional)</label>
                  <input class="form-control" [(ngModel)]="userData.phone" placeholder="" minlength="10" [textMask]="{mask: phoneMask}" (keypress)="phoneFormat($event)" name="phone"
                     #phone="ngModel" type="text" />
                  <div *ngIf="iconR.submitted && !phone.valid" class="help-block">Not a valid phone number</div>
                </div>
              </div>
              <div class="col-sm-12">
                <h4 class="text-primary text-uppercase">Password</h4>
              </div>
              <div class="col-sm-6">
                <div class="form-group" [ngClass]="{ 'has-error' : iconR.submitted && !password.valid}">
                  <label class="control-label" for="password">Password</label>
                  <input class="form-control" autocomplete="off" placeholder="" [(ngModel)]="userData.password" name="password"
                    #password="ngModel" required reverse="true" validateEqual="cpassword" type="password">
                  <div *ngIf="iconR.submitted && !password.valid" class="help-block">{{passwordErrMsg}}</div>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group" [ngClass]="{ 'has-error' : iconR.submitted && !cpassword.valid}">
                  <label class="control-label" for="re-password">Confirm password</label>
                  <input class="form-control" [(ngModel)]="userData.cpassword" placeholder="" name="cpassword" #cpassword="ngModel"
                    required validateEqual="password" type="password">
                  <div *ngIf="iconR.submitted && !cpassword.valid" class="help-block">Confirm Password and Password
                    should be same</div>
                </div>
              </div>
              <div class="clearfix"></div>
              <!-- <div  class="col-sm-6">
                <div class="form-group" [ngClass]="{ 'has-error' : iconR.submitted && !promocode.valid}">
                  <label class="control-label" for="promocode">Invitation Code (optional)</label>
                  <input class="form-control" [(ngModel)]="userData.promocode" maxlength="8" placeholder="" name="promocode" #promocode="ngModel" type="text">
                </div>
              </div>  -->
              <div class="form-group  col-sm-12" [ngClass]="{ 'has-error' : iconR.submitted && !enrolledIn.valid}">
                <div class="checkbox">
                  <label class="enroll-terms-lbl">
                    <input [(ngModel)]="userData.enrolledIn" (click)="openTermModalWithCheck(AcceptTerms, $event); " name="enrolledIn"
                      #enrolledIn="ngModel" type="checkbox" required value=""> &nbsp;&nbsp;&nbsp;I agree to the Icon
                    Rewards &nbsp;<a target="__blank" (click)="openTermModal(AcceptTerms); ">Terms and Conditions</a></label>
                  <div *ngIf="iconR.submitted && !enrolledIn.valid" class="help-block">You need to accept terms &
                    conditions.</div>
                </div>
              </div>
              <div class="col-sm-6 form-group mar-top-xs mar-bottom-sm btn-center-xs">
                <button class="btn btn-primary btn-fill">Enroll in Icon Rewards</button>
              </div>
              <div class="col-sm-6 form-group mar-top-xs mar-bottom-sm btn-center-xs cancelButtonFixIE">
                <p *showItDevice="['mobile','tablet']"></p>
                <button class="btn btn-primary btn-block btn-fill" type="button" (click)="cancelEnrollForm()">CANCEL</button>
              </div>
            </div>
          </form>
                    </div>
                    <div class="modal-body coupons-other-areas" class="couponprintdata" id="couponprintdata" style="display:block;">
                        <div class="panel-body" id="printableContent" style="padding:0px;">
                            <div class="mar-top-0 four-cpn-box" *ngIf="showSelectBox">
                                <h2 *ngIf="ShowCouponListOnLoad" class="text-primary text-uppercase mar-top-0 mar-bottom-sm">{{array_data?.facility?.entrance_location}}</h2>
                                <app-lazy-load [isLoaded]="isLoaded" [sectionName]="'Rates'"></app-lazy-load>
                                <div class="row" *ngIf="ShowCouponListOnLoad">
                                    <div [ngClass]="{'col-md-6': isClassVisible,'col-md-12':!isClassVisible }" class="single_record col-xs-12 col-sm-6  col-lg-6" *ngFor="let couponData of array_data.coupons; let couponIndexSelect = index">
                                        <div class="well">
                                            <h4>{{couponData?.description}}</h4>
                                            <h3>${{couponData.split_price[0]}}</h3>
                                            <div class="flex-row">
                                                <div class="col pad-0">
                                                    <p class="mar-bottom-0 mar-top-0">
                                                        <small>Description</small>
                                                        <br>
                                                        <ng-container *ngIf="couponData?.details!=''">
                                                            {{couponData?.details}}
                                                        </ng-container>
                                                        <ng-container *ngIf="couponData?.details==''">
                                                            7 Days a week / Enter any time / Exit any time / {{couponData?.max_stay_details}}.
                                                        </ng-container>
                                                    </p>
                                                </div>
                                                <div class="col align-self-end text-right pad-left-10 pad-right-0 coupon-tab-pad">
                                                    <a (click)="showCouponbox(couponIndexSelect)" class="btn btn-primary btn-fill theme-btn">Select</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="single-coupon-box couponList hide" id="couponShow{{couponIndexSelectCoupon}}" *ngFor="let couponData of array_data.coupons; let couponIndexSelectCoupon = index">
                                <a type="button" href="javascript:;" class="change-coupon" (click)="backSelectbox(couponIndexSelectCoupon)">
                                    <fa name="angle-left"></fa>&nbsp; Back
                                </a>
                                <div class="couponDataSection mar-top-sm" id="couponprint{{couponData?.id}}">
                                    <article [ngClass]="{'scrollIos':isIphone,'scrollAndroid':isAndroid }" class="ResponsiveCouponsPrint">
                                        <header>
                                            <!-- <p>Reservation Code (based on availability)</p> -->
                                            <p>Mobile Code</p>
                                            <h1 class="text-primary text-uppercase mar-top-0">{{couponCodeVaue}}</h1>
                                        </header>
                                        <main class="">
                                            <!-- <div class="flex-row visible-xs mar-top-xs">
					  <div class="col-10 col-xs-offset-1">
						<a href="javascript:;" class="btn btn-block theme-btn btn-primary">Add to mobile wallet</a>
					  </div>
					</div> -->


                                            <div class="clearfix panel-body garage-pad">

                                                <div class="flex-row no-gutters align-items-center ">
                                                    <div class="col-9">
                                                        <h2 class="text-primary text-uppercase ">{{array_data?.facility?.entrance_location}}</h2>
                                                        <h4 class="coupon-date-desc"> {{couponData?.description}} </h4>
                                                        <p *ngIf="couponData.details!=''" class="mar-top-sm">{{couponData.details}}.</p>
                                                        <p *ngIf="couponData.details==''" class="mar-top-sm">7 Days a week / Enter any time / Exit any time / {{couponData?.max_stay_details}}.</p>
                                                    </div>
                                                    <div class="col-3 text-center">
                                                        <h1 class="priceCoupon">${{couponData.split_price[0]}}</h1>
                                                        <p>tax included <br />(Based on Availability)</p>
                                                    </div>
                                                </div>
                                                <div class=" text-center  " id="couponvalid">
                                                    <button type="button" class="btn btn-primary theme-btn btn-fill " id="mailcoupon" *ngIf="this.loggedUserName" (click)="saveCouponApi(couponData?.id)">Save
						  <span class="hidden-xs">Offer</span>
						</button>

                                                    <button type="button" class="btn btn-primary theme-btn btn-fill " (click)="mailPopup(couponData?.id,couponData.split_price[0],array_data?.facility?.entrance_location);">Email</button>
                                                    <!-- <div class="visible-xs" style="height:3px;" *ngIf="this.loggedUserName" ></div> -->
                                                    <button style="display: none;" type="button" class="btn btn-primary theme-btn btn-fill " (click)="printMedia(couponData?.id,couponData.split_price[0],array_data?.facility?.entrance_location)">Print</button>&nbsp;
                                                </div>
                                            </div>
                                            <div class="clearfix">


                                                <div class="flex-row no-gutters ">
                                                    <div class="col-12 col-sm-6">
                                                        <div class="panel-body cpn-detal-area-pd">
                                                            <h4 class="text-primary text-uppercase mar-bottom-xs ">Reservation Details</h4>
                                                            <p *ngIf="couponData.coupon_restrictions!=''">{{couponData.coupon_restrictions}}</p>
                                                            <p>{{couponData?.used_text}}
                                                            </p>
                                                            <p *ngIf="couponData?.expire_on">Expires {{couponData?.expire_on}}</p>
                                                            <p>Coupon Code : #{{couponData?.coupon_code}}</p>
                                                            <div class="coupon-barcode">
                                                                <ngx-barcode [bc-height]="53" [bc-margin-top]="1" [bc-margin-bottom]="1" [bc-margin-left]="1" [bc-margin-right]="1" [bc-background]="gray" [bc-width]="2" [bc-element-type]='svg' [bc-value]="barcodeValue" [bc-display-value]="false"></ngx-barcode>
                                                                <!-- <qrcode *ngIf="qrcodeData!=''" [colorlight]="'#ffffff'" [qrdata]="barcodeValue" [size]="150" [level]="'M'"></qrcode> -->
                                                            </div>
                                                            <div class="extraContent hidden-xs">

                                                                <hr>
                                                                <div class="clearfix"></div>
                                                                <p>{{couponData?.disclaimer}}</p>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div class="col-12 col-sm-6 darklight">
                                                        <div class="panel-body fac-detal-area-pd">
                                                            <h4 class="text-primary text-uppercase  ">Facility Details</h4>
                                                            <h3>{{  array_data?.facility?.facility_name_modified }} </h3>
                                                            <p *ngIf="formatPhoneNumber(array_data?.facility?.phone_number)" >
                                                                <fa name="phone"></fa> &nbsp;
                                                                <a id="garage_phone" style="color: #000;" href="tel:{{array_data?.facility?.phone_number}}">{{formatPhoneNumber(array_data?.facility?.phone_number)}}
								</a>
                                                            </p>
                                                            <p class="mar-bottom-xs" *ngIf="array_data?.facility?.between_streets">
                                                                <b>Between:</b> {{ array_data?.facility?.between_streets }}</p>
                                                            <p class="mar-bottom-xs" *ngIf="array_data?.facility?.entrance_location">
                                                                <b>Entrance</b> {{array_data?.facility?.entrance_location}}</p>
                                                            <p class="dailystyles mar-bottom-xs  mar-top-xs ">
                                                                <a (click)="getdirections.show();lgModal1.hide()">Get Directions &nbsp;&nbsp;
								<fa name="chevron-right"></fa>
							  </a>
                                                            </p>
                                                            <div class="clearfix">

                                                                <p *ngIf="array_data.facility?.hours_of_operation?.length == 0" class="mar-bottom-xs">Open: 7 Days a week, 24 hours a day</p>
                                                                <div class="TimeActive table-responsive" *ngIf="array_data.facility?.hours_of_operation?.length > 0">
                                                                    <h4>Hours of Operation</h4>
                                                                    <table class="table table-condensed ">
                                                                        <tr *ngFor="let wh of array_data.facility?.hours_of_operation;">
                                                                            <td>{{wh.day}}</td>
                                                                            <td>:</td>
                                                                            <td>{{wh.formatted_open_time}}</td>
                                                                            <td>-</td>
                                                                            <td>{{wh.formatted_close_time}}</td>
                                                                        </tr>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="parkImg">
                                                            <img [src]="imgSrcLocation" class='full-width' [alt]="imgSrcAlt">
                                                        </div>

                                                    </div>
                                                    <div class="col-12 visible-xs">
                                                        <div class="extraContent">

                                                            <div class="clearfix panel-body">
                                                                <p>{{couponData?.disclaimer}}</p>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </main>
                                    </article>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--coupon end multiple-->
    <!-- Coupon Model Starts From Here LATEST CODE -->
    <div class="A-B-coupon">
        <div bsModal #lgModal="bs-modal" id="coupon-modal" class="modal mobileModal fade" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
            <div class="modal-dialog mobileModal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 id="couponheading" class="coupons-parent-panel modal-title pull-left"></h4>
                        <button type="button" id="closeCouponModal" class="close pull-right" (click)="lgModal.hide()" aria-label="Close">
			<span aria-hidden="true" (click)="reset(mailFormData, cm)">
			  <fa name="times"></fa>
			</span>
		  </button>
                    </div>
                    <h3 class="text-primary text-center mar-top-sm mar-bottom-sm" *ngIf="!ShowCouponListOnLoad">Please wait while we load Rates ...</h3>
                    <div id="couponsErrorMessage" class="hidden couponsErrorMessage"></div>
                    <div class="modal-body coupons-other-areas hidden" id="coupon-mail-print">
                        <!-- <button (click)="mailPopup();" class="btn btn-primary btn-block btn-fill" type="submit">EMAIL COUPON</button>
				  <button (click)="printCoupon();" class="btn btn-primary btn-block btn-fill" type="submit">PRINT COUPON</button>
				  <div class="visible-xs" style="height:3px;"></div>
				  <button id="mailcoupon" (click)="saveCouponApi()" class="btn btn-primary btn-block btn-fill" type="submit">SAVE COUPON</button> -->
                    </div>
                    <div class="modal-body coupons-other-areas" id="couponmail">
                        <div class="servererrors" id="signuperrors-container">
                        </div>
                        <div class="form-group">

                            <form id="floating-label-google-map2" name="form" role="form" class="formFix" (ngSubmit)="cm.form.valid && sendCoupon(mailFormData, cm)" #cm="ngForm" novalidate>
                                <div class="form-group" [ngClass]="{ 'has-error' : cm.submitted && !ucmail.valid || mailFormData.ucmail ==''}">
    
                                    <input placeholder="Enter Your Email" class="form-control" type="mail" name="ucmail" [(ngModel)]="mailFormData.ucmail" #ucmail="ngModel" required />
                                    <div *ngIf="cm.submitted && !ucmail.valid || mailFormData.ucmail ==''" class="help-block emailErrorHelpBlock">Email is required</div>
                                </div>
                                <div class="form-group" [ngClass]="{ 'hide' :hideSendEmailButton2}">
                                    <div class="row">
                                        <div class="col-sm-6">
                                            <button id="submitEmailCoupon" class="btn btn-primary btn-block btn-fill" type="submit">EMAIL OFFER</button>
                                            <div class="mar-bottom-xs visible-xs"></div>
                                        </div>
                                        <div class="col-sm-6">
                                            <p *showItDevice="['mobile','tablet']"></p>
                                            <button class="btn btn-primary btn-block btn-fill" type="button" (click)="cancelEmail(mailFormData, cm)">CANCEL</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div class="form-group" *ngIf="!loggedUserName" >

                            <div class="checkbox">
                                <label class="enroll-terms-lbl top-checkbox">
                                    <input id="enrolledInCheck2" [checked]="checkedCheckbox" name="enrolledIn"
                                    type="checkbox" required (click)="checkForEnrollNew2($event)" value=""> &nbsp;&nbsp;&nbsp;Enroll in Icon Rewards and receive instant discounts on
                                    daily parking.</label>
                        </div>
                        </div>
                        <form *ngIf="showEnrollForm && !loggedUserName" id="floatForm" name="form" class="formFix" (ngSubmit)="moveToNext(userData, iconR)" role="form"
            #iconR="ngForm">
            
            
            <p>All Icon Rewards members receive at least 10% off posted facility rates and online reservations! Enroll now to unlock immediate discounts!</p>
            <p class="mar-bottom-0">Already have an account? <a class="pad-left-xs" data-toggle="modal" [href]="loginLink" id="loginPopup">Sign in here <fa name="angle-right"></fa></a></p>
            
            <div class="row">
              <div class="col-sm-6">
                <div class="form-group" [ngClass]="{ 'has-error' : iconR.submitted && !first_name.valid}">
                  <label class="control-label" for="fname">First name</label>
                  <input class="form-control" [(ngModel)]="userData.first_name" (keypress)="restChar($event)" (paste)="onPaste($event)" name="first_name" #first_name="ngModel"
                    required type="text" placeholder="" />
                  <div *ngIf="iconR.submitted && !first_name.valid" class="help-block">First Name is required</div>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group" [ngClass]="{ 'has-error' : iconR.submitted && !last_name.valid}">
                  <label class="control-label" for="lname">Last name</label>
                  <input class="form-control" [(ngModel)]="userData.last_name" (keypress)="restChar($event)" placeholder="" (paste)="onPaste($event)" required name="last_name" #last_name="ngModel"
                    type="text" />
                  <div *ngIf="iconR.submitted && !last_name.valid" class="help-block">Last Name is required</div>
                </div>
              </div>
              <!-- <div style="display: none;" class="col-sm-6">
                <div class="form-group" [ngClass]="{ 'has-error' : iconR.submitted && !email.valid}">
                  <label class="control-label" for="email">Email address</label>
                  <input class="form-control" autocomplete="off" [(ngModel)]="userData.email" placeholder="" name="email" #email="ngModel"
                    required type="text" (keydown)="restSpace($event)" pattern="{{emailRegex}}" />
                  <div *ngIf="iconR.submitted && !email.valid" class="help-block">Email is required</div>
                </div>
              </div> -->
              <div class="col-sm-6">
                <div class="form-group" [ngClass]="{ 'has-error' : iconR.submitted && !phone.valid}">
                  <label class="control-label" for="phone">Phone number (optional)</label>
                  <input class="form-control" [(ngModel)]="userData.phone" placeholder="" minlength="10" [textMask]="{mask: phoneMask}" (keypress)="phoneFormat($event)" name="phone"
                     #phone="ngModel" type="text" />
                  <div *ngIf="iconR.submitted && !phone.valid" class="help-block">Not a valid phone number</div>
                </div>
              </div>
              <div class="col-sm-12">
                <h4 class="text-primary text-uppercase">Password</h4>
              </div>
              <div class="col-sm-6">
                <div class="form-group" [ngClass]="{ 'has-error' : iconR.submitted && !password.valid}">
                  <label class="control-label" for="password">Password</label>
                  <input class="form-control" autocomplete="off" placeholder="" [(ngModel)]="userData.password" name="password"
                    #password="ngModel" required reverse="true" validateEqual="cpassword" type="password">
                  <div *ngIf="iconR.submitted && !password.valid" class="help-block">{{passwordErrMsg}}</div>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group" [ngClass]="{ 'has-error' : iconR.submitted && !cpassword.valid}">
                  <label class="control-label" for="re-password">Confirm password</label>
                  <input class="form-control" [(ngModel)]="userData.cpassword" placeholder="" name="cpassword" #cpassword="ngModel"
                    required validateEqual="password" type="password">
                  <div *ngIf="iconR.submitted && !cpassword.valid" class="help-block">Confirm Password and Password
                    should be same</div>
                </div>
              </div>
              <div class="clearfix"></div>
              <!-- <div  class="col-sm-6">
                <div class="form-group" [ngClass]="{ 'has-error' : iconR.submitted && !promocode.valid}">
                  <label class="control-label" for="promocode">Invitation Code (optional)</label>
                  <input class="form-control" [(ngModel)]="userData.promocode" maxlength="8" placeholder="" name="promocode" #promocode="ngModel" type="text">
                </div>
              </div>  -->
              <div class="form-group  col-sm-12" [ngClass]="{ 'has-error' : iconR.submitted && !enrolledIn.valid}">
                <div class="checkbox">
                  <label class="enroll-terms-lbl">
                    <input [(ngModel)]="userData.enrolledIn" (click)="openTermModalWithCheck(AcceptTerms, $event); " name="enrolledIn"
                      #enrolledIn="ngModel" type="checkbox" required value=""> &nbsp;&nbsp;&nbsp;I agree to the Icon
                    Rewards &nbsp;<a target="__blank" (click)="openTermModal(AcceptTerms); ">Terms and Conditions</a></label>
                  <div *ngIf="iconR.submitted && !enrolledIn.valid" class="help-block">You need to accept terms &
                    conditions.</div>
                </div>
              </div>
              <div class="col-sm-6 form-group mar-top-xs mar-bottom-sm btn-center-xs">
                <button class="btn btn-primary btn-fill">Enroll in Icon Rewards</button>
              </div>
              <div class="col-sm-6 form-group mar-top-xs mar-bottom-sm btn-center-xs cancelButtonFixIE">
                <p *showItDevice="['mobile','tablet']"></p>
                <button class="btn btn-primary btn-block btn-fill" type="button" (click)="cancelEnrollForm()">CANCEL</button>
              </div>
            </div>
          </form>
                    </div>

                    <div class="modal-body coupons-other-areas" class="singleCoupon" class="couponprintdata" id="couponprintdata" style="display:block;">








                        <!-- testing modals -->
                        <div class=" slide couponPageCarousel " *ngIf="ShowCouponListOnLoad">
                            <carousel class="customeSliderMovement" [noPause]="true" [(activeSlide)]="activeSlide" [interval]="false"  >
                                <slide [class.active]="couponIndex=='0'" *ngFor="let couponData of array_data.coupons; let couponIndex = index" >
                                    <div class="couponDataSection">
                                        <article [ngClass]="{'scrollIos':isIphone,'scrollAndroid':isAndroid }" class="ResponsiveCouponsPrint">
                                            <header>
                                                <p>Mobile Code</p>
                                                <h1 class="text-primary text-uppercase mar-top-0">{{couponCodeVaue}}</h1>
                                            </header>
                                            <main class="">
                                                <!-- <div class="flex-row visible-xs mar-top-xs">
						<div class="col-10 col-xs-offset-1">
						  <a href="javascript:;" class="btn btn-block theme-btn btn-primary">Add to mobile wallet</a>
						</div>
					  </div> -->


                                                <div class="clearfix panel-body garage-pad">

                                                    <div class="flex-row no-gutters align-items-center ">
                                                        <div class="col-9">
                                                            <h2 class="text-primary text-uppercase ">{{array_data?.facility?.entrance_location}}</h2>
                                                            <h4 class="coupon-date-desc"> {{couponData?.description}} </h4>
                                                            <p *ngIf="couponData.details!=''" class="mar-top-sm">{{couponData.details}} .</p>
                                                            <p *ngIf="couponData.details==''" class="mar-top-sm">7 Days a week / Enter any time / Exit any time / {{couponData?.max_stay_details}}.</p>

                                                        </div>
                                                        <div class="col-3 text-center">
                                                            <h1 class="priceCoupon">${{couponData.split_price[0]}}</h1>
                                                            <p>tax included<br />(Based on Availability)</p>
                                                        </div>
                                                    </div>
                                                    <div class=" text-center  " id="couponvalid">
                                                        <button type="button" class="btn btn-primary theme-btn btn-fill " id="mailcoupon" *ngIf="this.loggedUserName" (click)="saveCouponApi(couponData?.id)">Save
							<span class="hidden-xs">Offer</span>
						  </button>

                                                        <button type="button" class="btn btn-primary theme-btn btn-fill " (click)="mailPopup(couponData?.id,couponData.split_price[0],array_data?.facility?.entrance_location);">Email</button>
                                                        <!-- <div class="visible-xs" style="height:3px;" *ngIf="this.loggedUserName" ></div> -->
                                                        <button style="display: none;" type="button" class="btn btn-primary theme-btn btn-fill " (click)="printMedia(couponData?.id,couponData.split_price[0],array_data?.facility?.entrance_location)">Print</button>&nbsp;
                                                    </div>
                                                </div>
                                                <div class="clearfix">


                                                    <div class="flex-row no-gutters ">
                                                        <div class="col-12 col-sm-6">
                                                            <div class="panel-body cpn-detal-area-pd">
                                                                <h4 class="text-primary text-uppercase mar-bottom-xs ">Rate Details</h4>
                                                                <p *ngIf="couponData.coupon_restrictions!=''">{{couponData.coupon_restrictions}}</p>
                                                                <p>{{couponData?.used_text}}
                                                                </p>
                                                                <p *ngIf="couponData?.expire_on">Expires {{couponData?.expire_on}}</p>
                                                                <p>Code : #{{couponData?.coupon_code}}</p>
                                                                <div class="coupon-barcode">
                                                                    <ngx-barcode [bc-height]="53" [bc-margin-top]="1" [bc-margin-bottom]="1" [bc-margin-left]="1" [bc-margin-right]="1" [bc-background]="gray" [bc-width]="2" [bc-element-type]='svg' [bc-value]="barcodeValue" [bc-display-value]="false"></ngx-barcode>
                                                                    <!-- <qrcode *ngIf="qrcodeData!=''" [colorlight]="'#ffffff'" [qrdata]="barcodeValue" [size]="150" [level]="'M'"></qrcode> -->
                                                                </div>
                                                                <div class="extraContent hidden-xs">

                                                                    <hr>
                                                                    <div class="clearfix"></div>
                                                                    <p>{{couponData?.disclaimer}}</p>
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <div class="col-12 col-sm-6 darklight">
                                                            <div class="panel-body fac-detal-area-pd">
                                                                <h4 class="text-primary text-uppercase  ">Facility Details</h4>
                                                                <h3> {{ array_data?.facility?.facility_name_modified }} </h3>
                                                                <p *ngIf="formatPhoneNumber(array_data?.facility?.phone_number)" >
                                                                    <fa name="phone"></fa> &nbsp;
                                                                    <a id="garage_phone" style="color: #000;" href="tel:{{array_data?.facility?.phone_number}}">{{formatPhoneNumber(array_data?.facility?.phone_number)}}
								  </a>
                                                                </p>
                                                                <p class="mar-bottom-xs" *ngIf="array_data?.facility?.between_streets">
                                                                    <b>Between:</b> {{ array_data?.facility?.between_streets }}</p>
                                                                <p class="mar-bottom-xs" *ngIf="array_data?.facility?.entrance_location">
                                                                    <b>Entrance</b> {{array_data?.facility?.entrance_location}}</p>
                                                                <p class="dailystyles mar-bottom-xs  mar-top-xs ">
                                                                    <a (click)="getdirections.show();lgModal.hide()">Get Directions &nbsp;&nbsp;
								  <fa name="chevron-right"></fa>
								</a>
                                                                </p>
                                                                <div class="clearfix">

                                                                    <p *ngIf="array_data.facility?.hours_of_operation?.length == 0" class="mar-bottom-xs">Open: 7 Days a week, 24 hours a day</p>
                                                                    <div class="TimeActive table-responsive" *ngIf="array_data.facility?.hours_of_operation?.length > 0">
                                                                        <h4>Hours of Operation</h4>
                                                                        <table class="table table-condensed ">
                                                                            <tr *ngFor="let wh of array_data.facility?.hours_of_operation;">
                                                                                <td>{{wh.day}}</td>
                                                                                <td>:</td>
                                                                                <td>{{wh.formatted_open_time}}</td>
                                                                                <td>-</td>
                                                                                <td>{{wh.formatted_close_time}}</td>
                                                                            </tr>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="parkImg">
                                                                <img [src]="imgSrcLocation" class='full-width' [alt]="imgSrcAlt">
                                                            </div>

                                                        </div>
                                                        <div class="col-12 visible-xs">
                                                            <div class="extraContent">

                                                                <div class="clearfix panel-body">
                                                                    <p>{{couponData?.disclaimer}}</p>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </main>

                                        </article>


                                    </div>

                                </slide>
                            <!-- add this -->
                            <div class="indicators" id="customDots">
                                <!-- <button *ngFor="let item of items; let i = index" type="button" class="indicator"
                                        [class.active]="i === activeSlide" [attr.aria-pressed]="i === activeSlide"
                                        (click)="switchSlide(i)"></button> -->
                                        <button type="button" class="indicator" *ngFor="let couponDataSlider of array_data.coupons; let couponIndexSlider = index"
                                        [class.active]="couponIndexSlider === activeSlide" [attr.aria-pressed]="couponIndexSlider === activeSlide"
                                        (click)="switchSlide(activeSlide)"><span>${{couponDataSlider.split_price[0]}}</span></button>
                            </div>
                            <!-- /add this -->
                            </carousel>
                            <!-- Wrapper for slides -->
                            <div owl-slider class="owl-carousel owl-theme hidden" >
                                <div class="item" [class.active]="couponIndex=='0'" *ngFor="let couponData of array_data.coupons; let couponIndex = index">
                                    <div class="couponDataSection">
                                        <article [ngClass]="{'scrollIos':isIphone,'scrollAndroid':isAndroid }" class="ResponsiveCouponsPrint">
                                            <header>
                                                <p>Mobile Code</p>
                                                <h1 class="text-primary text-uppercase mar-top-0">{{couponCodeVaue}}</h1>
                                            </header>
                                            <main class="">
                                                <!-- <div class="flex-row visible-xs mar-top-xs">
						<div class="col-10 col-xs-offset-1">
						  <a href="javascript:;" class="btn btn-block theme-btn btn-primary">Add to mobile wallet</a>
						</div>
					  </div> -->


                                                <div class="clearfix panel-body garage-pad">

                                                    <div class="flex-row no-gutters align-items-center ">
                                                        <div class="col-9">
                                                            <h2 class="text-primary text-uppercase ">{{array_data?.facility?.entrance_location}}</h2>
                                                            <h4 class="coupon-date-desc"> {{couponData?.description}} </h4>
                                                            <p *ngIf="couponData.details!=''" class="mar-top-sm">{{couponData.details}} .</p>
                                                            <p *ngIf="couponData.details==''" class="mar-top-sm">7 Days a week / Enter any time / Exit any time / {{couponData?.max_stay_details}}.</p>

                                                        </div>
                                                        <div class="col-3 text-center">
                                                            <h1 class="priceCoupon">${{couponData.split_price[0]}}</h1>
                                                            <p>tax included<br />(Based on Availability)</p>
                                                        </div>
                                                    </div>
                                                    <div class=" text-center  " id="couponvalid">
                                                        <button type="button" class="btn btn-primary theme-btn btn-fill " id="mailcoupon" *ngIf="this.loggedUserName" (click)="saveCouponApi(couponData?.id)">Save
							<span class="hidden-xs">Offer</span>
						  </button>

                                                        <button type="button" class="btn btn-primary theme-btn btn-fill " (click)="mailPopup(couponData?.id,couponData.split_price[0],array_data?.facility?.entrance_location);">Email</button>
                                                        <!-- <div class="visible-xs" style="height:3px;" *ngIf="this.loggedUserName" ></div> -->
                                                        <button style="display: none;" type="button" class="btn btn-primary theme-btn btn-fill " (click)="printMedia(couponData?.id,couponData.split_price[0],array_data?.facility?.entrance_location)">Print</button>&nbsp;
                                                    </div>
                                                </div>
                                                <div class="clearfix">


                                                    <div class="flex-row no-gutters ">
                                                        <div class="col-12 col-sm-6">
                                                            <div class="panel-body cpn-detal-area-pd">
                                                                <h4 class="text-primary text-uppercase mar-bottom-xs ">Rate Details</h4>
                                                                <p *ngIf="couponData.coupon_restrictions!=''">{{couponData.coupon_restrictions}}</p>
                                                                <p>{{couponData?.used_text}}
                                                                </p>
                                                                <p *ngIf="couponData?.expire_on">Expires {{couponData?.expire_on}}</p>
                                                                <p>Code : #{{couponData?.coupon_code}}</p>
                                                                <div class="coupon-barcode">
                                                                    <ngx-barcode [bc-height]="53" [bc-margin-top]="1" [bc-margin-bottom]="1" [bc-margin-left]="1" [bc-margin-right]="1" [bc-background]="gray" [bc-width]="2" [bc-element-type]='svg' [bc-value]="barcodeValue" [bc-display-value]="false"></ngx-barcode>
                                                                    <!-- <qrcode *ngIf="qrcodeData!=''" [colorlight]="'#ffffff'" [qrdata]="barcodeValue" [size]="150" [level]="'M'"></qrcode> -->
                                                                </div>
                                                                <div class="extraContent hidden-xs">

                                                                    <hr>
                                                                    <div class="clearfix"></div>
                                                                    <p>{{couponData?.disclaimer}}</p>
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <div class="col-12 col-sm-6 darklight">
                                                            <div class="panel-body fac-detal-area-pd">
                                                                <h4 class="text-primary text-uppercase  ">Facility Details</h4>
                                                                <h3> {{ array_data?.facility?.facility_name_modified }} </h3>
                                                                <p *ngIf="formatPhoneNumber(array_data?.facility?.phone_number)">
                                                                    <fa name="phone"></fa> &nbsp;
                                                                    <a id="garage_phone" style="color: #000;" href="tel:{{array_data?.facility?.phone_number}}">{{formatPhoneNumber(array_data?.facility?.phone_number)}}
								  </a>
                                                                </p>
                                                                <p class="mar-bottom-xs" *ngIf="array_data?.facility?.between_streets">
                                                                    <b>Between:</b> {{ array_data?.facility?.between_streets }}</p>
                                                                <p class="mar-bottom-xs" *ngIf="array_data?.facility?.entrance_location">
                                                                    <b>Entrance</b> {{array_data?.facility?.entrance_location}}</p>
                                                                <p class="dailystyles mar-bottom-xs  mar-top-xs ">
                                                                    <a (click)="getdirections.show();lgModal.hide()">Get Directions &nbsp;&nbsp;
								  <fa name="chevron-right"></fa>
								</a>
                                                                </p>
                                                                <div class="clearfix">

                                                                    <p *ngIf="array_data.facility?.hours_of_operation?.length == 0" class="mar-bottom-xs">Open: 7 Days a week, 24 hours a day</p>
                                                                    <div class="TimeActive table-responsive" *ngIf="array_data.facility?.hours_of_operation?.length > 0">
                                                                        <h4>Hours of Operation</h4>
                                                                        <table class="table table-condensed ">
                                                                            <tr *ngFor="let wh of array_data.facility?.hours_of_operation;">
                                                                                <td>{{wh.day}}</td>
                                                                                <td>:</td>
                                                                                <td>{{wh.formatted_open_time}}</td>
                                                                                <td>-</td>
                                                                                <td>{{wh.formatted_close_time}}</td>
                                                                            </tr>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="parkImg">
                                                                <img [src]="imgSrcLocation" class='full-width' [alt]="imgSrcAlt">
                                                            </div>

                                                        </div>
                                                        <div class="col-12 visible-xs">
                                                            <div class="extraContent">

                                                                <div class="clearfix panel-body">
                                                                    <p>{{couponData?.disclaimer}}</p>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </main>

                                        </article>


                                    </div>
                                </div>

                            </div>
                            <div class="owl-controls hidden">
                                <div id="customNav" class="owl-nav"></div>
                                <div id="customDots" class="owl-dots">
                                    <button role="button" class="owl-dot" *ngFor="let couponDataSlider of array_data.coupons; let couponIndexSlider = index"><span>${{couponDataSlider.split_price[0]}}</span></button>
                                </div>
                            </div>
                            <div class="hide" *ngFor="let couponData of array_data.coupons; let couponIndexTable = index">
                                <div id="table{{couponData?.id}}">
                                    <table width="600" align="center" cellspacing="0" cellpadding="0" style="border:1px solid #ddd;-webkit-print-color-adjust: exact;">
                                        <tbody>
                                            <tr bgcolor="#ececec">
                                                <td align="center" style="padding: 20px;">
                                                    <ngx-barcode [bc-height]="53" [bc-margin-top]="1" [bc-margin-bottom]="1" [bc-margin-left]="1" [bc-margin-right]="1" [bc-background]="gray" [bc-width]="2" [bc-element-type]='svg' [bc-value]="barcodeValue" [bc-display-value]="false"></ngx-barcode>
                                                    <!-- <qrcode *ngIf="qrcodeData!=''" [colorlight]="'#ffffff'" [qrdata]="barcodeValue" [size]="150" [level]="'M'"></qrcode> -->
                                                    <p style="margin: 0;padding: 10px 0 0 0;font-family: sans-serif;">Coupon Code #{{couponData?.coupon_code}}
                                                    </p>
                                                    <p style="margin: 0;padding: 10px 0 0 0;font-family: sans-serif;">
                                                        Mobile Code #{{couponCodeVaue}}
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <table width="100%" align="center" cellspacing="0" cellpadding="0">
                                                        <tbody>
                                                            <tr>
                                                                <td style="padding:30px;font-family: sans-serif;width: 75%;">
                                                                    <h2 style="font-size: 30px; -webkit-print-color-adjust: exact;color: #ed8222;font-weight: 500;margin: 0;padding: 0;text-transform: uppercase;">

                                                                        <font color="#ed8222">{{array_data?.facility?.entrance_location}}</font>
                                                                    </h2>
                                                                    <h4 style="margin: 10px 0;padding: 0; -webkit-print-color-adjust: exact; font-size: 24px;font-weight: 500;text-transform: uppercase;">
                                                                        {{couponData?.description}}
                                                                    </h4>
                                                                    <p *ngIf="couponData.details!=''" style="font-weight: normal;font-size: 16px;font-weight: 100;">
                                                                        {{couponData.details}}.
                                                                    </p>
                                                                    <p *ngIf="couponData.details==''" style="font-weight: normal;font-size: 16px;font-weight: 100;">
                                                                        7 Days a week / Enter any time / Exit any time / {{couponData?.max_stay_details}}.
                                                                    </p>
                                                                </td>
                                                                <td style="text-align: center;font-family: sans-serif;">
                                                                    <h1 style="font-size: 60px;font-weight: 300;margin-bottom: 0;">
                                                                        ${{couponData.split_price[0]}}
                                                                    </h1>
                                                                    <p style="margin-top: 0;font-weight: normal;">
                                                                        tax included
                                                                    </p>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <table style="transform: scale(0.9);
										transform: translateX(0px);" width="100%" align="center" cellspacing="0" cellpadding="0" style="-webkit-print-color-adjust: exact;">
                                                        <tbody>
                                                            <tr>
                                                                <td width="50%" style="padding: 30px;vertical-align: top;padding-bottom: 0;">
                                                                    <h4 style="font-size: 20px;font-weight: 500;color: #ed8222;margin: 15px 0;text-transform: uppercase;font-family: sans-serif;margin-top: 0; -webkit-print-color-adjust: exact;">
                                                                        <font color="#ed8222">Coupon Details</font>
                                                                    </h4>
                                                                    <p *ngIf="couponData.coupon_restrictions!=''" style="font-size: 14px;font-weight: 500;margin: 15px 0;text-transform: uppercase;font-family: sans-serif;">
                                                                        {{couponData.coupon_restrictions}}
                                                                    </p>

                                                                    <p style="font-size: 14px;font-weight: 500;margin: 15px 0;font-family: sans-serif;">
                                                                        {{couponData?.used_text}}
                                                                    </p>
                                                                    <p *ngIf="couponData?.expire_on" style="font-size: 14px;font-weight: 500;margin: 15px 0;font-family: sans-serif;">
                                                                        Expires {{couponData?.expire_on}}
                                                                    </p>
                                                                    <div style="font-size: 14px;font-weight: 500;margin: 15px 0;font-family: sans-serif;line-height: 1.5;margin-top: 30px;border-top: 2px solid #eee;padding: 15px 0;">
                                                                        <div class="clearfix"></div>
                                                                        <p>
                                                                            {{couponData?.disclaimer}}
                                                                        </p>
                                                                    </div>
                                                                </td>
                                                                <td width="50%" style="background: #ececec;vertical-align: top;-webkit-print-color-adjust: exact;">
                                                                    <div style="padding: 30px;padding-bottom: 0; -webkit-print-color-adjust: exact;">
                                                                        <h4 class="" style="font-size: 20px;font-weight: 500;color: #ed8222;margin: 15px 0;text-transform: uppercase;font-family: sans-serif;padding: 0;margin-top: 0; -webkit-print-color-adjust: exact;">
                                                                            <font color="#ed8222">Facility Details</font>
                                                                        </h4>
                                                                        <h3 style="font-family: sans-serif;text-decoration: none;text-transform: uppercase;font-weight: 500;margin: 0;padding: 0;font-size: 24px;">
                                                                            {{ array_data?.facility?.facility_name_modified }}
                                                                        </h3>
                                                                        <p>
                                                                            <a style="color: #000;font-size: 14px;font-weight: 500;margin: 15px 0;font-family: sans-serif;text-decoration: none;cursor: pointer;">
                                                                                <fa name="phone"></fa> &nbsp;{{array_data?.facility?.phone_number}} </a>
                                                                        </p>
                                                                        <p style="font-size: 14px;font-weight: 500;margin: 15px 0;font-family: sans-serif;" *ngIf="array_data?.facility?.between_streets">
                                                                            <b>Between:</b> {{ array_data?.facility?.between_streets }}
                                                                        </p>
                                                                        <p style="font-size: 14px;font-weight: 500;margin: 15px 0;font-family: sans-serif;" *ngIf="array_data?.facility?.entrance_location">
                                                                            <b>Entrance</b> {{array_data?.facility?.entrance_location}}
                                                                        </p>
                                                                        <p style="font-size: 14px;font-weight: 500;margin: 15px 0;font-family: sans-serif;">
                                                                            <a (click)="getdirections.show()" style="font-size: 14px;font-weight: 500;margin: 15px 0;font-family: sans-serif;color: #ed8222;cursor: pointer; -webkit-print-color-adjust: exact;">
                                                                                <font color="#ed8222">Get Directions &nbsp;&nbsp;<i class=" fa fa-chevron-right"></i></font>
                                                                            </a>
                                                                        </p>
                                                                        <p *ngIf="array_data.facility?.hours_of_operation?.length == 0" style="font-size: 14px;font-weight: 500;margin: 15px 0;font-family: sans-serif;">
                                                                            Open: 7 Days a week, 24 hours a day
                                                                        </p>
                                                                    </div>
                                                                    <div style="padding: 0 30px 10px 30px; -webkit-print-color-adjust: exact;" *ngIf="array_data.facility?.hours_of_operation?.length > 0">
                                                                        <h4>
                                                                            <font face="sans-serif">Hours of Operation</font>
                                                                        </h4>
                                                                        <table cellspacing="0" cellpadding="0" width="100%" style="font-family: sans-serif;font-size: 10px;vertical-align: top;">
                                                                            <tbody>
                                                                                <tr *ngFor="let wh of array_data.facility?.hours_of_operation;">
                                                                                    <td style="width: 20%;vertical-align: top;">
                                                                                        <font face="sans-serif">{{wh.day}}</font>
                                                                                    </td>
                                                                                    <td style="width: 10%;vertical-align: top;">
                                                                                        <font face="sans-serif">:</font>
                                                                                    </td>
                                                                                    <td style="width: 20%;vertical-align: top;">
                                                                                        <font face="sans-serif">{{wh.formatted_open_time}}</font>
                                                                                    </td>
                                                                                    <td style="width: 10%;vertical-align: top;">
                                                                                        <font face="sans-serif">-</font>-</td>
                                                                                    <td style="width: 40%; vertical-align: top;">
                                                                                        <font face="sans-serif">{{wh.formatted_close_time}}</font>
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </div>



                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <table style="transform: scale(0.9);
											transform: translateX(0px);" width="100%" align="center" cellspacing="0" cellpadding="0" style="-webkit-print-color-adjust: exact;">
                                                        <tbody>
                                                            <tr>
                                                                <td width="50%" style="padding: 30px;vertical-align: top;padding-bottom: 0;">
                                                                </td>
                                                                <td width="50%" style="background: #ececec;vertical-align: top;-webkit-print-color-adjust: exact;">
                                                                    <div class="parkImg" style="-webkit-print-color-adjust: exact;">
                                                                        <img class="" [src]="imgSrcLocation" style="width: 100%; -webkit-print-color-adjust: exact;" [alt]="imgSrcAlt">
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                                <!-- Indicators & controles -->
                                <div class="controles mar-top-sm">
                                    <a class="left carousel-control" href="#couponCarousel" data-slide="prev">
                                        <img [src]="leftNav" alt="image">
                                        <span class="sr-only">Previous</span>
                                    </a>
                                    <ol class="carousel-indicators">
                                        <li data-target="#couponCarousel" [attr.data-slide-to]="couponIndexSlider" class="test" [class.active]="couponIndexSlider=='0'" *ngFor="let couponDataSlider of array_data.coupons; let couponIndexSlider = index">${{couponDataSlider.split_price[0]}}</li>
                                        <!-- <li data-target="#couponCarousel" data-slide-to="1">$39</li>
						  <li data-target="#couponCarousel" data-slide-to="2">$60</li> -->


                                    </ol>
                                    <a class="right carousel-control" href="#couponCarousel" data-slide="next">
                                        <img [src]="rightNav" alt="image">
                                        <span class="sr-only">Next</span>
                                    </a>

                                </div>
                            </div>

                        </div>


                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Coupon Model Ends Here LATEST CODE-->

    <ng-template #rateEngine>
        <div class="modal-header" style="z-index: 1; position: relative;">
            <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
				<img   [src]="crossSvg" width="20" alt="image" style="margin-top: 10px;">
			  </button>
        </div>
        <div class="modal-body" style="z-index: 0;">
            <h4 _ngcontent-c7="" class="mar-bottom-sm modal-title text-uppercase text-primary" style="font-size: 25px;margin-top: -30px;">how to secure your spot</h4>
            <p>If your dates and times are flexible, you can save a offer for future use. However, if the garage is full, spots may not be available. If you need a guaranteed spot, always book a reservation.</p>
            <button type="button" class="btn btn-center-xs  btn-primary theme-btn mar-top-xs" aria-label="Close" (click)="modalRef.hide()">Got it</button>


        </div>

    </ng-template>
    <ng-template #postedfacilityrats>
        <div class="modal-header" style="z-index: 1; position: relative;">
            <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
						<img [src]="crossSvg" alt="image" width="20" style="margin-top: 10px;">
					</button>
        </div>
        <div class="modal-body postedfacility-th-bg">
            <div class="clearfix posted-facilty-box">
                <h2 class="text-uppercase">{{ FacilityAvailableFetatures?.facility?.facility_name_modified }}</h2>
                <h4 class="text-uppercase">{{FacilityAvailableFetatures?.facility?.entrance_location}}</h4>
                <h3 class="text-uppercase text-primary">Posted Facility Rates</h3>
                <p class="hidden-xs">All rates shown below include a {{taxRate}}% parking tax.</p>

                <table class="table tbl-border" *ngFor="let rate of boardRate;">
                    <thead>
                        <tr>
                            <th colspan="2">{{rate.name}}</th>
                            <th>Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        <ng-container *ngFor="let rates of rate.rates;">
                            <tr *ngIf="rates.category.category==rate.name">
                                <td>{{rates.description=='' ? '7 Days a week / Enter any time / Exit any time' : rates.description}}</td>
                                <td>{{rates.details=='' ? '7 Days a week / Enter any time / Exit any time' : rates.details}}</td>
                                <td sourcefont>${{rates.price=='' ? '7 Days a week / Enter any time / Exit any time' : rates.price}}</td>
                            </tr>
                        </ng-container>

                    </tbody>
                </table>
                <table class="table" *ngIf="FacilityAvailableFetatures?.facility?.oversize_fee">
                    <thead>
                        <tr>
                            <th colspan="2">Additional Fees</th>
                            <th>Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        <ng-container>
                            <tr>
                                <td colspan="2">Oversized vehicle fee</td>
                                <td sourcefont>${{FacilityAvailableFetatures?.facility?.oversize_fee}}</td>
                            </tr>
                        </ng-container>

                    </tbody>
                </table>
            </div>
        </div>
    </ng-template>
    <!-- Directions Model Starts From Here -->

    <!---  #Term page    -->
<ng-template class="modal fade" #AcceptTerms id="AcceptTerms">
    <div class="modal-header">
      <button type="button" class="close pull-right" aria-label="Close" (click)="closeModel()">
        <span style="font-size: 45px; font-weight: normal;" aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body" style="padding-top:0px;">
      <app-lazy-load [isLoaded]="isLoaded"></app-lazy-load>
      <div *ngIf="isLoaded">
        <h2 class="text-uppercase mar-top-0 mar-bottom-xs pad-bottom-xs text-primary">ENROLL IN ICON REWARDS</h2>
        <p class="pad-bottom-xs mar-bottom-xs">{{cmsPageContent.post_title}}</p>
        <div class="icon-rewards-terms mar-top-xs">
          <div [innerHTML]="cmsPageContent.post_content"></div>
        </div>
      </div>
      <div class="form-group mar-top-sm mar-bottom-0 btn-center-xs">
        <button type="button" class="btn btn-primary btn-fill" (click)="confirm()">I AGREE</button>
        <span class="mar-left-xs"></span>
        <button type="button" class="btn btn-primary theme-btn" (click)="decline()">CANCEL</button>
      </div>
    </div>
  </ng-template>

  <ng-template #monthlyRateDesc>
    <div class="modal-header" style="margin-top:-10px;">
        <h4 class="modal-title pull-left text-primary"></h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true" style="font-size: 50px; font-weight: 400;">&times;</span>
    </button>
    </div>
    <div class="modal-body" style="padding: 10px 20px">
        <h3 class="text-uppercase text-primary">MONTHLY RATES</h3>
        <p>Our rates vary by vehicle type and what service you require.</p>
        <h5 class="mar-bottom-xs">ACCESS HOURS</h5>
        <!-- <div *ngIf="FgarageWorkingHours?.length <= 0">
        <p>
            This facility is open 24 hours a day, 7 days a week. For access hours per rate
            tier, see the ‘Buy Monthly’ page.
        </p>
     </div> -->
        <div *ngIf="FulldayWrking == 1">
            <p>
                This facility is open 24 hours a day, 7 days a week. For access hours per rate tier, see the ‘Buy Monthly’ page.
            </p>
        </div>
        <div *ngIf="FgarageWorkingHours?.length > 0 && FulldayWrking == 0">
            <p>
                This facility is open
                <span class="pHours" *ngFor="let wh of FgarageWorkingHours; let Hoursindex of index">
          {{wh.day}}: {{wh.formatted_open_time}} - {{wh.formatted_close_time}}
          <span class="comma">,</span>
                </span>For access hours per rate tier, see the ‘Buy Monthly’.
            </p>
        </div>
        <h5 class="mar-bottom-xs">VEHICLE CLASSIFICATIONS</h5>
        <p>
            Oversized vehicles include SUV’s, Trucks, and similar. You can find more info and a list of vehicles
            <a href="javascript:;" (click)="redirecttoList()">here</a>.
        </p>
        <p> Exotic vehicles are considered luxury/high-performance/ sports cars.</p>
        <p>
            For assistance with your vehicle, contact us at (888) 705-7845 from 9 AM to 7 PM EST
        </p>
        <div class="btn-center-xs mar-top-sm mar-bottom-xs">
            <a class="btn btn-primary theme-btn custom-popup-close" href="javascript:;" (click)="modalRef.hide()">Got it</a>
        </div>
        <div class="mar-bottom-sm visible-xs"></div>
    </div>
</ng-template>

</section>
<app-footer></app-footer>