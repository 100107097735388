import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Router, NavigationEnd,ActivatedRoute, Params } from '@angular/router';
import { CustomCode } from '../../shared/CustomCode';
import { ToastrService } from 'ngx-toastr';
import * as $ from 'jquery';
import { ResetPasswordService } from '../../services/resetpassword.service';
import { UserserviceService } from '../../services/userservice.service';
declare var google: any;
@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
  providers: [ResetPasswordService]
})
export class ResetPasswordComponent implements OnInit {
	formData: any = {};
	isProcess:boolean = false;
	private parseData;
  showResetPass = true;
  showactChangePass = false;
  resetpassSlug:string;
  formCls = 'form-group mar-top-0';
  passwordErrMsg='New password is required'
	constructor(
	private activatedRouteObj: ActivatedRoute,
	private routerObj: Router,
  private toasterObj: ToastrService,
  private userServiceObj: UserserviceService,
	private resetpasswordObj: ResetPasswordService)
  { }
  @ViewChild('myDiv',{ static: true }) myDiv: ElementRef;
  ngOnInit() {
    if(localStorage.getItem('access_token')){
      this.userServiceObj.fetchUser().then(
        (data:any)=> {
          let uData = data['data'];
          this.formData.email = uData.email;
          this.formCls = 'form-group mar-top-0 focused';
        });
    }
    this.activatedRouteObj.params.subscribe((params: Params) => {
        if (params['resetpass_slug']) {
            this.resetpassSlug = params['resetpass_slug'];
        } 
    
    if (this.resetpassSlug) {
      
      this.showactChangePass = true;
      this.showResetPass = false;
      } else {
      this.showactChangePass = false;
      this.showResetPass = true;
      }
    });
    CustomCode.manageFormLabelsPosition();
	}
	

	changepassword(formValues, formObj: NgForm){
		this.resetpasswordObj.changeuserpassword(formValues)
      .subscribe(
        (data:any)=> {
          this.parseData = data;
          if (this.parseData.status === 201) {
            this.routerObj.navigate(['./']);
            this.toasterObj.success('Please check email for reset password', 'success!');
          }
        },
        error => {
          const errorsList = error.errors;
        if (errorsList.message !== 'Validation Errors') {
            this.toasterObj.error(errorsList.message, 'Sorry!');
          } else {
            for (const key in errorsList.detail) {
              if (errorsList.detail.hasOwnProperty(key)) {
                if (typeof errorsList.detail === 'undefined') {
                  this.toasterObj.error(errorsList.message, 'Sorry!');
                } else {
                  this.toasterObj.error(errorsList.detail[key], 'Sorry!');
                }
              }
            }
          }
        }
      );
  }
	
	changeactpassword(formValues, formObj: NgForm){
    formValues.token=this.resetpassSlug;
    if (formValues.password === undefined || formValues.password === '') {
      this.passwordErrMsg = 'New password is required';
      formObj.form.controls['password'].setErrors({'invalid': true});
      return false;
    }else if(formValues.password.length < 8){
      this.passwordErrMsg = 'Password must be atleast 8 characters long';
      formObj.form.controls['password'].setErrors({'invalid': true});
      return false;
    }else if(formValues.password !== formValues.password_confirmation){
      formObj.form.controls['password_confirmation'].setErrors({'invalid': true});
      return false;
    }

		this.resetpasswordObj.changeactuserpassword(formValues)
      .subscribe(
        (data:any)=> {
          this.parseData = data;
          if (this.parseData.status === 201) {
            this.routerObj.navigate(['./']);
            this.toasterObj.success('Password change successfully', 'success!');
          }
        },
        error => {
          const errorsList = error.errors;
          if (errorsList.message !== 'Validation Errors') {
            this.toasterObj.error(errorsList.message, 'Sorry!');
          } else {
            for (const key in errorsList.detail) {
              if (errorsList.detail.hasOwnProperty(key)) {
                if (typeof errorsList.detail === 'undefined') {
                  this.toasterObj.error(errorsList.message, 'Sorry!');
                } else {
                  this.toasterObj.error(errorsList.detail[key], 'Sorry!');
                }
              }
            }
          }
        }
    );
  }
}
