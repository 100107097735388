<form [formGroup]="coolForm">
	<input formControlName="search" *ngIf="!isSideNav" type="text" class="form-control selectAuto" onFocus="this.selectionStart=0; this.selectionEnd=this.value.length;" onMouseUp="return false"  onTouchEnd="this.selectionStart=0; this.selectionEnd=this.value.length;" #autos id="auto_suggest_input" name="auto_suggest_input" placeholder=""
	[(ngModel)]="findValue"
	(click)="InitiliazeAutosuggest()"
	(keyup)="checkData()"
	>
	<div class="customevent_widget">
		<div class="locationevent_widget" style="">
			<ul class="addressWrapper">
				<li class="clearfix" *ngFor="let record of places; index as k" >
					<a href="javascript:;" (click)="redirectToSearchByLocation(k);">
						<div class="addressMarker">
							<img [src]="location_pinIcon" alt="image">
						</div>
						<div class="addressTitle" id="text{{k}}">{{record}}</div>
						
						
						
					</a>
				</li>
			</ul>
		</div>
	</div>
	<button *ngIf="monthlyInfo !='1'" id="searchlocation" type="button" class="btn-introSearch" ><img [src]="search_icon" alt="image"></button>
</form>