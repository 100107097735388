<app-header></app-header>
<div class="page-header orangeBar" id="widget"></div>
<div class="contactPage pad-top-md pad-bottom-md">
<div class="container">
	<h1 class="text-primary text-uppercase mar-top-0 mar-bottom-sm">Add parking to your website</h1>
	<p class="mar-bottom-sm pad-bottom-xs">
		Allow your visitors to book parking near you directly on your website using Icon's parking widget. Choose between different
		styles and colors and customize with your company's name to make it your own. Setup is quick and easy -just fill out the
		form below, hit the 'GET PARKING WIDGET' button, copy and paste the script into your website, and you're done.
	</p>
	<div class="row">
		<div class="form-container">
			<h4 class="text-uppercase mar-bottom-xs pad-bottom-xs mar-top-0">Choose what type of widget you want</h4>
			<div role="tabpanel">
				<ul class="list-unstyled list-inline tab-list">
					<li role="presentation">
						<a class="active" type="button" aria-controls="" role="tab" (click)="setradio('tab')" [class.active]="activeTab">
							<img [src]="expand_tabIcon" alt="image" width="150px"> Expandable tab
						</a>
					</li>
					<li role="presentation">
						<a type="button" aria-controls="" role="tab" (click)="setradio('embed')" [class.active]="activeWidget">
							<img [src]="embed_mapIcon" alt="image" width="150px"> Embedded map
						</a>
					</li>
				</ul>
			</div>
			<div class="clearfix"></div>
			<form action="" method="POST" role="form" id="floating-label-widget-form" class="customeInput">
				<h4 class="text-uppercase mar-bottom-xs pad-bottom-xs mar-top-sm">Enter your information</h4>
				<div class="form-group" id="companyField">
					<label class="control-label" for="">Company Name</label>
					<input (input)="forvalidate();" id="company" type="text" name="company" class="form-control">
					<span id="helpBlock2" class="help-block hidden">You need to enter your company name</span>
				</div>
				<div class="form-group ph-visible ">
					<label class="control-label" for="">Search for your address (we will search for parking near this location)</label>
					<!-- <input type="text" class="form-control" id="" placeholder=""> -->
					<widget-app-google-autosuggest customeInputBox [widget]="widget" (getLatLong)="getLatLongFun($event)"></widget-app-google-autosuggest>
					<div class="row hidden">
						<div class="form-group">
							<label class="control-label" for="searchTextField">Location</label>
							<input id="searchTextField" type="text" size="50" class="form-control">
						</div>
						<div class="form-group focused">
							<label class="control-label">Latitude</label>
							<input type="text" class="form-control" id="lat">
						</div>
						<div class="form-group">
							<label class="control-label" for="long">Longitude</label>
							<input type="text" class="form-control" id="long">
						</div>
					</div>
				</div>
				<!--  closed -->
				<div class="form-group ph-visible " *ngIf="isSelected('tab')">
					<label class="control-label" for="">Widget label (&quot;Find parking near....&quot;)</label>
					<input type="text" class="form-control" (input)="changeText();" id="label" placeholder="">
				</div>
				<div class="form-group">
					<div class="checkbox mar-top-0">
						<label class="widget-lebel-check">
							<input type="checkbox" class="checkbox" name="" id="" [checked]="customParkTime" (change)="customParkTime = !customParkTime"
							/>Choose default parking start time and duration
							<small *ngIf="!customParkTime">If unchecked, default setting is 2 hour parking at the current time</small>
						</label>
					</div>
				</div>
				<div class="form-group" id="dateselect" *ngIf="customParkTime">
					<table class="dateTime mar-left-sm">
						<tr>
							<td class="text-right">
								<b>FROM</b>
							</td>
							<td class="icon-td">
								<fa name="calendar-o" class="text-primary"></fa>
							</td>
							<td>
								<input readonly class="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false" type="text"
								class="sdate dateTriggers" id="startdate" name="startdate" [minDate]="minDate" [(ngModel)]='startDuration' #dp="bsDatepicker"
								(ngModelChange)="updateStartDuration()" bsDatepicker [(bsValue)]="startDuration">
							</td>
							<td class="icon-td">
								<fa name="clock-o" class="text-primary"></fa>
							</td>
							<td>
								<select name="" [(ngModel)]="startTime" [ngModelOptions]="{standalone: true}" id="startTime" class="form-control" required="required"
									data-toggle="dropdown" class="dropdown-toggle sdate" (change)="updateStartTime()">
									<option [value]="slot.value" *ngFor="let slot of timeSlots" [selected]="slot.value == startTime">{{ slot.time }}</option>
								</select>
							</td>
						</tr>
						<tr>
							<td class="text-right">
								<b>TO</b>
							</td>
							<td class="icon-td">
								<fa name="calendar-o" class="text-primary"></fa>
							</td>
							<td>
								<input readonly class="dropdown-toggle" data-toggle="dropdown" class="sdate dateTriggers" type="text" id="enddate" name="enddate"
								[minDate]="minDate" [(ngModel)]='endDuration' #dp="bsDatepicker" (ngModelChange)="updateEndDuration()" bsDatepicker
								[(bsValue)]="endDuration">
							</td>
							<td class="icon-td">
								<fa name="clock-o" class="text-primary"></fa>
							</td>
							<td>
								<select name="" [(ngModel)]="endTime" [ngModelOptions]="{standalone: true}" id="endTime" class="form-control" required="required"
									data-toggle="dropdown" class="dropdown-toggle sdate" (change)="updateEndTime()">
									<option [value]="slot.value" *ngFor="let slot of timeSlots" [selected]="slot.value == endTime" data-slot="{{ slot }}" data-value="{{ endTime }}">{{ slot.time }}</option>
								</select>
							</td>
						</tr>
					</table>
					<div class="clearfix"></div>
					<span class="help-block hidden">Please Select Valid Date</span>
				</div>
				<div class="widget-fields">
					<h4  *ngIf="isSelected('embed')" class="text-uppercase mar-bottom-xs pad-bottom-xs mar-top-sm">customize your map</h4>
					<h4 *ngIf="isSelected('tab')"  class="text-uppercase mar-bottom-xs pad-bottom-xs mar-top-sm">customize your widget</h4>
					<div class="form-group focused">
						<p class="widget-lebel-check" *ngIf="isSelected('embed')">Map Width on Desktop (pixels or percent)</p>
						<p class="widget-lebel-check" *ngIf="isSelected('tab')" >Widget Width on Desktop (pixels or percent)</p>
						<div class="input-group custom">
							<div class="range-slider">
								<input  (change)="changeZoom(rangerw.value,'width','widthw','1000')" class="range-slider_range" value="{{rangerwValue}}" id="widthw"
								min="0" #rangerw type="range" max="1000">
							</div>
							<div class="input-group-addon">
								<input #inputrangevaluew (input)="changerangew(inputrangevaluew.value,rangerw.value,'widthw','1000')" min="0" type="number"
								value='500' max="1000" class="form-control" id="width">
							</div>
							<span class="input-group-addon">
								<select id="widthSelect" (change)="setType($event.target.value,'width')" class="form-control">
									<option value='px'>PX</option>
									<option value='per'>%</option>
								</select>
							</span>
						</div>
					</div>
					<div class="form-group focused mar-bottom-0">
						<p class="widget-lebel-check" *ngIf="isSelected('tab')" >Widget Height (pixels or percent)</p>
						<p class="widget-lebel-check" *ngIf="isSelected('embed')">Map Height (pixels or percent)</p>
						<div class="input-group custom">
							<div class="range-slider">
								<input  #rangerh (change)="changeZoom(rangerh.value,'height','heightw','1000')" class="range-slider_range" value="{{rangerhValue}}"
								id="heightw" min="0" type="range" max="1000">
							</div>
							<div class="input-group-addon">
								<input #inputrangevalueh (input)="changerangeh(inputrangevalueh.value,rangerh.value,'heightw','1000')" min="0" type="number"
								max="1000" value='500' class="form-control" id="height">
							</div>
							<span class="input-group-addon">
								<select id="heightSelect" (change)="setType($event.target.value,'height')"  class="form-control">
									<option value='px'>PX</option>
									<option value='per'>%</option>
								</select>
							</span>
						</div>
					</div>
				</div>
				<div class="row"  class="wid" [class.hidden]="!activeTab">
					<div class="col-sm-6">
						<div class="form-group focused">
							<label class="control-label">Tab Position</label>
							<select #positionData class="form-control widget-lebel-select" id="position" name="" (change)="setPosition(positionData.value)">
								<option value="bottom-right">Bottom Right</option>
								<option value="bottom-left">Bottom Left</option>
								<option value="top-right">Top Right</option>
								<option value="top-left">Top Left</option>
							</select>
						</div>
					</div>
					<div class="col-sm-6">
						<div class="form-group focused">
							<label class="control-label">Widget Design</label>
							<select #colorData class="form-control widget-lebel-select" id="colorSelect" name="" (change)="setColor(colorData.value)">
								<option value="orange">Orange (default)</option>
								<option value="light-grey">Light Gray</option>
								<option value="dark-sign">Dark Sign</option>
								<option value="custom">Custom</option>
							</select>
							<input value="#ED8222" id="background-color" type="hidden" class="form-control">
							<input value="#fff" id="label-color" type="hidden" class="form-control">
						</div>
					</div>
				</div>
				<div  [class.hidden]="!activeTab" class="form-group mar-top-md" *ngIf="showColor==1">
					<h4 class="text-uppercase mar-bottom-xs pad-bottom-xs mar-top-sm">CUSTOM WIDGET COLORS</h4>
					<div class="row">
						<div class="col-sm-6">
							<p>Background Color</p>
							<div class="form-group">
								<input value="#ED8222" type="text" app-color-picker (onColorChange)="onColorUpdated($event,'background-colo')" />
							</div>
							<div class="form-group">
								<div class="input-group custom color-code">
									<p for="">Hex code:</p>
									<div class="input-group-addon">
										<input value="#ffffff" id="background-colo" type="text" class="form-control">
									</div>
								</div>
							</div>
						</div>
						<div class="col-sm-6">
							<p>Text Color</p>
							<div class="form-group">
								<input value="#ffffff" type="text" app-color-picker (onColorChange)="onColorUpdated($event,'label-colo')" />
							</div>
							<div class="form-group">
								<div class="input-group custom color-code">
									<p for="">Hex code:</p>
									<div class="input-group-addon">
										<input value="#ED8222" id="label-colo" type="text" class="form-control">
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<button type="submit" class="btn btn-primary btn-fill hide show_pop" data-toggle="modal" href='#codePopup'>Get parking widget</button>
				<button type="submit" (click)="gencode()" class="btn btn-primary btn-fill">Get parking widget</button>
			</form>
		</div>
		<!--  models -->
		<div class="preview-container" [class.hidden]="!activeTab">
			<div class="mar-top-sm visible-xs"></div>
			<div class="well mar-bottom-0">
				<h3 class="text-uppercase text-primary mar-top-0">widget preview</h3>
				<h4 class="text-uppercase pad-top-xs mar-top-0">Closed tab</h4>
				<div class="title-bar" >
					<div class="row">
						<div class="col-xs-11 text-left">
							<div class="title-content">
								Find Parking near
								<span class="widget-label">{{widgetLabel}}</span>
							</div>
						</div>
						<div class="col-xs-1 text-right">
							<div class="title-icon">
								<svg width="20px" height="20px" viewBox="0 0 55 56" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
									<defs></defs>
									<g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
										<g class="icon-arrow" fill="#FFFFFF">
											<path d="M0,22.9134197 L33.0469662,22.9134197 L16.1433303,5.86446227 L30.7091846,5.74862624 L52.4810929,28.0839189 L31.140937,50.3033755 L16.5750828,50.3033755 L32.6120546,33.2691608 L0.0410691378,33.2691608 C2.41149553,46.1680293 13.7055084,55.4201689 27.2878095,55.4201689 C42.5929082,55.4201689 55,43.0151831 55,27.7100844 C55,12.4060388 42.5929082,0 27.2878095,0 C13.6223171,0 2.27354535,9.89871527 0,22.9134197"
											id="Fill-5"></path>
										</g>
									</g>
								</svg>
							</div>
						</div>
					</div>
				</div>
				<div class="link-wrap clearfix mar-top-xs">
					<!-- <span>{{widthValue}}{{widthType}} of actual size</span> -->
					<a class="pull-right text-uppercase" data-toggle="modal" href='#widgetPreview'>see full size &nbsp;
						<fa name="chevron-right"></fa>
					</a>
				</div>
				<h4 class="text-uppercase pad-top-xs mar-top-xs">Expanded Map</h4>
				<div class="title-bar">
					<div class="row">
						<div class="col-xs-10">
							<div class="title-content" style="margin-top: -3px;">
								<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" height="28px" width="70px" viewBox="0 0 153 60">
									<defs>
									<polygon id="icon-vector-logo-black-parking-lockup-a" points="74.19 26.222 74.19 51.95 .626 51.95 .626 .494 74.19 .494" />
										</defs>
										<g fill="none" fill-rule="evenodd">
											<polygon class="logo-element" fill="#fff" points="0 8 10 8 10 0 0 0" />
												<polygon class="logo-element" fill="#fff" points="0 34 10 34 10 10 0 10" />
													<g transform="translate(78 8)">
														<mask id="icon-vector-logo-black-parking-lockup-b" fill="#fff">
														<use xlink:href="#icon-vector-logo-black-parking-lockup-a" />
														</mask>
														<path class="logo-element" fill="#fff" d="M19.1293103.496516129C14.4681034.576193548 12.1137931 2.7383871 10.7258621 3.94864516L10.7258621 1.60864516.625862069 1.60864516.625862069 26.1216129 10.7258621 26.1216129 10.7258621 9.48580645C12.8568966 8.15309677 18.1836207 5.47341935 18.375 11.6496774L18.3982759 26.1903871 28.4284483 26.1216129C28.4284483 26.1216129 28.4284483 13.0167742 28.4284483 10.9183226 28.4284483 4.35206452 25.6551724.38916129 19.1293103.496516129M29.1646552 26.109871L56.2181034 26.109871 42.3801724 12.5311613 54.3043103 12.4389032 72.1275862 30.2279355 54.6577586 47.9247097 42.7336207 47.9247097 55.862069 34.3577419 29.1982759 34.3577419C31.1387931 44.6310968 40.3844828 52 51.5034483 52 64.0327586 52 74.1896552 42.12 74.1896552 29.9301935 74.1896552 17.7412258 64.0327586 7.8603871 51.5034483 7.8603871 40.3163793 7.8603871 31.0258621 15.7442581 29.1646552 26.109871"
															mask="url(#icon-vector-logo-black-parking-lockup-b)" />
														</g>
														<path class="logo-element" fill="#DA763C" d="M56,30 C56.072,30.004 56.143,30.017 56.215,30.017 C56.23,30.017 56.244,30.014 56.259,30.014 C56.256,30.014 56.252,30.015 56.249,30.015 C56.162,30.015 56.083,30.001 56,30"
															/>
															<path class="logo-element" fill="#fff" d="M61.9310623 28.4915531C61.9180524 28.4915531 61.9059098 28.4956352 61.8937672 28.4956352 61.8321869 28.4956352 61.7714739 28.4850217 61.7107609 28.4809395 58.1278263 28.3560259 56.9257088 25.1343968 56.9257088 21.1649187 56.9257088 17.1162469 58.0940005 13.8374678 61.9215217 13.8374678 65.7481756 13.8374678 66.9173346 17.1162469 66.9173346 21.1649187 66.9173346 25.2086918 65.67532 28.4858381 61.9310623 28.4915531zM61.5 8C53.4910875 8 47 13.8195064 47 21 47 28.1813101 53.4910875 34 61.5 34 69.5089125 34 76 28.1813101 76 21 76 13.8195064 69.5089125 8 61.5 8L61.5 8zM33.7628419 23.6603856C33.2273796 26.3150745 31.6374551 27.5170903 29.6489665 27.5170903 27.1154846 27.5170903 25.0984033 25.2628021 25.0984033 21.1619507 25.0984033 17.0659822 26.7541775 14.7286841 29.9088996 14.7286841 32.2699592 14.7286841 33.2369105 16.3498185 33.831291 18.7253662L42.9948013 18.7253662C42.3683624 13.4070364 38.7466271 8 29.7139497 8 21.5858398 8 15 13.8945161 15 21.1643921 15 28.4342682 21.5858398 34 29.7139497 34 36.7208813 34 41.518381 30.6649556 43 23.6603856L33.7628419 23.6603856"
																/>
																<path class="logo-element park-text" fill="#ffffff" d="M2.76305297 48.1171957L3.67125498 48.1171957C4.52135941 48.1171957 5.156161 47.9500918 5.5782229 47.6158841 6.00028479 47.2808237 6.21046136 46.7931532 6.21046136 46.1528725 6.21046136 45.5057712 6.03445984 45.0291842 5.68074805 44.7205535 5.32703626 44.4127754 4.77340042 44.2576076 4.01898614 44.2576076L2.76305297 44.2576076 2.76305297 48.1171957zM9 46.0548269C9 47.453043 8.56170496 48.523872 7.68511487 49.2639035 6.80937915 50.0064927 5.56284412 50.3756558 3.94721853 50.3756558L2.76305297 50.3756558 2.76305297 55 0 55 0 42 4.16166698 42C5.74140877 42 6.94266186 42.3393232 7.76628062 43.0179696 8.58819062 43.6966159 9 44.7094701 9 46.0548269L9 46.0548269zM19.6354753 49.616093C18.80247 46.8235256 18.3342607 45.2442688 18.2300332 44.8783228 18.1249915 44.5123767 18.050078 44.2236954 18.0044785 44.0105806 17.8180091 44.7662465 17.2830291 46.6341846 16.3995386 49.616093L19.6354753 49.616093zM21.1638732 55L20.2632829 51.9179022 15.7375314 51.9179022 14.836941 55 12 55 16.3824388 42 19.6012757 42 24 55 21.1638732 55 21.1638732 55zM32.9195468 47.7710519L33.8614235 47.7710519C34.7843363 47.7710519 35.4652327 47.6261149 35.905016 47.3353882 36.3438962 47.0446616 36.5642394 46.5885362 36.5642394 45.9653069 36.5642394 45.3497508 36.339381 44.9106768 35.8905673 44.6497901 35.4417536 44.389756 34.7464083 44.2576076 33.8045316 44.2576076L32.9195468 44.2576076 32.9195468 47.7710519zM32.9195468 50.0116081L32.9195468 55 30 55 30 42 34.0113291 42C35.8824399 42 37.266809 42.3222718 38.1653395 42.9651102 39.0629669 43.6079486 39.5117806 44.5841422 39.5117806 45.8945435 39.5117806 46.6592996 39.2896314 47.3405037 38.8435268 47.9355981 38.3974222 48.5315451 37.7661933 48.9979014 36.950743 49.3355194 39.0223299 52.2581322 40.3723832 54.1465766 41 55L37.7598719 55 34.4736885 50.0116081 32.9195468 50.0116081 32.9195468 50.0116081z"
																	/>
																	<polyline class="logo-element park-text" fill="#ffffff" points="56 55 52.708 55 49.126 49.522 47.9 50.359 47.9 55 45 55 45 42 47.9 42 47.9 47.949 49.041 46.419 52.745 42 55.963 42 51.193 47.753 56 55"
																		/>
																		<polygon class="logo-element park-text" fill="#ffffff" points="60 55 63 55 63 42 60 42" />
																			<path class="logo-element park-text" fill="#ffffff" d="M80 55L76.6586087 55 71.2639166 45.1655955 71.1882901 45.1655955C71.295631 46.9022823 71.3493014 48.1419203 71.3493014 48.8819517L71.3493014 55 69 55 69 42 72.3161824 42 77.7019295 51.7372114 77.7612922 51.7372114C77.6767206 50.0465635 77.6336216 48.8529643 77.6336216 48.1538562L77.6336216 42 80 42 80 55M91.684139 47.7262695L97 47.7262695 97 54.2818321C96.1386336 54.5472059 95.3273672 54.7337969 94.5662006 54.8399464 93.8059129 54.9452666 93.0280463 55 92.2334798 55 90.2110268 55 88.6667199 54.4393978 87.5996804 53.3181934 86.5326408 52.1961597 86 50.5856724 86 48.4867313 86 46.4450115 86.6187775 44.8544271 87.8563324 43.7124904 89.0938873 42.571383 90.8095885 42 93.0043148 42 94.3789852 42 95.7053136 42.2595688 96.9824211 42.7787063L96.0384339 44.9232585C95.0601678 44.4613422 94.0423492 44.231628 92.984978 44.231628 91.7570915 44.231628 90.7726728 44.6205665 90.0334798 45.3992728 89.2942869 46.1779791 88.9242509 47.2237178 88.9242509 48.5389768 88.9242509 49.911457 89.2222133 50.9588543 89.8181382 51.6828273 90.4140631 52.4068002 91.2807032 52.768372 92.4171794 52.768372 93.0095885 52.768372 93.611666 52.7111508 94.2234119 52.5950498L94.2234119 49.9570681 91.684139 49.9570681 91.684139 47.7262695"
																				/>
																			</g>
																		</svg>
																	</div>
																</div>
																<div class="col-xs-2">
																	<div class="title-icon">
																		<svg width="22px" height="24px" viewBox="0 0 26 26" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
																			<g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
																				<g class="icon-cross" fill="#FFFFFF">
																					<rect id="Rectangle" transform="translate(13.000000, 13.435029) rotate(45.000000) translate(-13.000000, -13.435029) " x="11"
																					y="-2" width="4" height="30.8700577"></rect>
																					<rect id="Rectangle" transform="translate(13.000000, 13.435029) rotate(-45.000000) translate(-13.000000, -13.435029) " x="11"
																					y="-2" width="4" height="30.8700577"></rect>
																				</g>
																			</g>
																		</svg>
																	</div>
																</div>
															</div>
														</div>
														<img [src]="map_widgetIcon" alt="map-icon" class="full-width img-responsive">
														<div class="link-wrap text-right clearfix mar-top-xs">
															<a class="pull-right text-uppercase" data-toggle="modal" href='#widgetPreview-expand'>see full size &nbsp;
																<fa name="chevron-right"></fa>
															</a>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="modal fade" id="codePopup">
										<div class="modalWrapper">
											<div class="modal-dialog">
												
												<button type="button" class="close" data-dismiss="modal" aria-hidden="true">
												<fa name="times"></fa>
												</button>
												<div class="panel panel-default mar-bottom-0">
													<h2 class="text-primary text-uppercase mar-top-0 mar-bottom-xs">Your parking widget</h2>
													<p class="pad-bottom-xs">
														Paste the script below on any page where you want the parking widget to appear and it will let your visitors find parking
														right from your website.
													</p>
													<textarea name="" id="codegen" class="form-control mar-bottom-xs" readonly></textarea>
													<a id="cpy260571" onclick="copytext('codegen','cpy260571');" class="btn btn-primary theme-btn btn-sm">Copy Script</a>
												</div>
											</div>
										</div>
									</div>
									<div class="modal fade" id="widgetPreview">
										<div class="modalWrapper">
											<div class="modal-dialog">
												<button type="button" class="close" data-dismiss="modal" aria-hidden="true">
												<fa name="times"></fa>
												</button>
												<div class="panel panel-default mar-bottom-0">
													<h2 class="text-primary text-uppercase mar-top-0 mar-bottom-xs">Widget Preview</h2>
													<p class="pad-bottom-xs">
														Click the tab to preview the expanded map.
													</p>
													<div class="preview-container mar-bottom-xs pad-bottom-xs">
														<img [src]="widgetpreviewIcon" class="full-width" alt="icon" width="100%">
														<div class="title-bar bottom-right" id="title-bar"  style="max-width:96%; min-width: 250px;">
															<div class="row">
																<div class="colo-sm-11 col-xs-11 text-left">
																	<div class="title-content">
																		Find Parking near
																		<span class="widget-label">{{widgetLabel}}</span>
																	</div>
																</div>
																<div class="colo-sm-1 col-xs-1 text-right">
																	<div class="title-icon">
																		<svg width="20px" height="20px" viewBox="0 0 55 56" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
																			<defs></defs>
																			<g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
																				<g class="icon-arrow" fill="#FFFFFF">
																					<path d="M0,22.9134197 L33.0469662,22.9134197 L16.1433303,5.86446227 L30.7091846,5.74862624 L52.4810929,28.0839189 L31.140937,50.3033755 L16.5750828,50.3033755 L32.6120546,33.2691608 L0.0410691378,33.2691608 C2.41149553,46.1680293 13.7055084,55.4201689 27.2878095,55.4201689 C42.5929082,55.4201689 55,43.0151831 55,27.7100844 C55,12.4060388 42.5929082,0 27.2878095,0 C13.6223171,0 2.27354535,9.89871527 0,22.9134197"
																					id="Fill-5"></path>
																				</g>
																			</g>
																		</svg>
																	</div>
																</div>
															</div>
														</div>
													</div>
													<a data-dismiss="modal" aria-hidden="true" class="btn btn-primary theme-btn btn-sm">Close</a>
												</div>
											</div>
										</div>
									</div>
									<div class="modal fade" id="widgetPreview-expand">
										<div class="modalWrapper">
											<div class="modal-dialog">
												<button type="button" class="close" data-dismiss="modal" aria-hidden="true">
												<fa name="times"></fa>
												</button>
												<div class="panel panel-default mar-bottom-0">
													<h2 class="text-primary text-uppercase mar-top-0 mar-bottom-xs">Widget Preview</h2>
													<p class="pad-bottom-xs">
														Click the tab to preview the expanded map.
													</p>
													<div class="preview-container mar-bottom-xs pad-bottom-xs">
														<img [src]="widgetpreviewIcon" class="full-width" alt="icon" width="100%">
														<div class="previewExpand bottom-right" [style.width]="widthStylevaluePreview+'%'" style="min-width: 30%;">
															<div class="title-bar withmapBar">
																<div class="row">
																	<div class="col-xs-11 col-sm-11 text-left ">
																		<div class="title-content" style="margin-top: -5px;">
																			<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" height="26px" width="65px" viewBox="0 0 153 60">
																				<defs>
																				<polygon id="icon-vector-logo-black-parking-lockup-a" points="74.19 26.222 74.19 51.95 .626 51.95 .626 .494 74.19 .494" />
																					</defs>
																					<g fill="none" fill-rule="evenodd">
																						<polygon class="logo-element" fill="#fff" points="0 8 10 8 10 0 0 0" />
																							<polygon class="logo-element" fill="#fff" points="0 34 10 34 10 10 0 10" />
																								<g transform="translate(78 8)">
																									<mask id="icon-vector-logo-black-parking-lockup-b" fill="#fff">
																									<use xlink:href="#icon-vector-logo-black-parking-lockup-a" />
																									</mask>
																									<path class="logo-element" fill="#fff" d="M19.1293103.496516129C14.4681034.576193548 12.1137931 2.7383871 10.7258621 3.94864516L10.7258621 1.60864516.625862069 1.60864516.625862069 26.1216129 10.7258621 26.1216129 10.7258621 9.48580645C12.8568966 8.15309677 18.1836207 5.47341935 18.375 11.6496774L18.3982759 26.1903871 28.4284483 26.1216129C28.4284483 26.1216129 28.4284483 13.0167742 28.4284483 10.9183226 28.4284483 4.35206452 25.6551724.38916129 19.1293103.496516129M29.1646552 26.109871L56.2181034 26.109871 42.3801724 12.5311613 54.3043103 12.4389032 72.1275862 30.2279355 54.6577586 47.9247097 42.7336207 47.9247097 55.862069 34.3577419 29.1982759 34.3577419C31.1387931 44.6310968 40.3844828 52 51.5034483 52 64.0327586 52 74.1896552 42.12 74.1896552 29.9301935 74.1896552 17.7412258 64.0327586 7.8603871 51.5034483 7.8603871 40.3163793 7.8603871 31.0258621 15.7442581 29.1646552 26.109871"
																										mask="url(#icon-vector-logo-black-parking-lockup-b)" />
																									</g>
																									<path class="logo-element" fill="#DA763C" d="M56,30 C56.072,30.004 56.143,30.017 56.215,30.017 C56.23,30.017 56.244,30.014 56.259,30.014 C56.256,30.014 56.252,30.015 56.249,30.015 C56.162,30.015 56.083,30.001 56,30"
																										/>
																										<path class="logo-element" fill="#fff" d="M61.9310623 28.4915531C61.9180524 28.4915531 61.9059098 28.4956352 61.8937672 28.4956352 61.8321869 28.4956352 61.7714739 28.4850217 61.7107609 28.4809395 58.1278263 28.3560259 56.9257088 25.1343968 56.9257088 21.1649187 56.9257088 17.1162469 58.0940005 13.8374678 61.9215217 13.8374678 65.7481756 13.8374678 66.9173346 17.1162469 66.9173346 21.1649187 66.9173346 25.2086918 65.67532 28.4858381 61.9310623 28.4915531zM61.5 8C53.4910875 8 47 13.8195064 47 21 47 28.1813101 53.4910875 34 61.5 34 69.5089125 34 76 28.1813101 76 21 76 13.8195064 69.5089125 8 61.5 8L61.5 8zM33.7628419 23.6603856C33.2273796 26.3150745 31.6374551 27.5170903 29.6489665 27.5170903 27.1154846 27.5170903 25.0984033 25.2628021 25.0984033 21.1619507 25.0984033 17.0659822 26.7541775 14.7286841 29.9088996 14.7286841 32.2699592 14.7286841 33.2369105 16.3498185 33.831291 18.7253662L42.9948013 18.7253662C42.3683624 13.4070364 38.7466271 8 29.7139497 8 21.5858398 8 15 13.8945161 15 21.1643921 15 28.4342682 21.5858398 34 29.7139497 34 36.7208813 34 41.518381 30.6649556 43 23.6603856L33.7628419 23.6603856"
																											/>
																											<path class="logo-element park-text" fill="#ffffff" d="M2.76305297 48.1171957L3.67125498 48.1171957C4.52135941 48.1171957 5.156161 47.9500918 5.5782229 47.6158841 6.00028479 47.2808237 6.21046136 46.7931532 6.21046136 46.1528725 6.21046136 45.5057712 6.03445984 45.0291842 5.68074805 44.7205535 5.32703626 44.4127754 4.77340042 44.2576076 4.01898614 44.2576076L2.76305297 44.2576076 2.76305297 48.1171957zM9 46.0548269C9 47.453043 8.56170496 48.523872 7.68511487 49.2639035 6.80937915 50.0064927 5.56284412 50.3756558 3.94721853 50.3756558L2.76305297 50.3756558 2.76305297 55 0 55 0 42 4.16166698 42C5.74140877 42 6.94266186 42.3393232 7.76628062 43.0179696 8.58819062 43.6966159 9 44.7094701 9 46.0548269L9 46.0548269zM19.6354753 49.616093C18.80247 46.8235256 18.3342607 45.2442688 18.2300332 44.8783228 18.1249915 44.5123767 18.050078 44.2236954 18.0044785 44.0105806 17.8180091 44.7662465 17.2830291 46.6341846 16.3995386 49.616093L19.6354753 49.616093zM21.1638732 55L20.2632829 51.9179022 15.7375314 51.9179022 14.836941 55 12 55 16.3824388 42 19.6012757 42 24 55 21.1638732 55 21.1638732 55zM32.9195468 47.7710519L33.8614235 47.7710519C34.7843363 47.7710519 35.4652327 47.6261149 35.905016 47.3353882 36.3438962 47.0446616 36.5642394 46.5885362 36.5642394 45.9653069 36.5642394 45.3497508 36.339381 44.9106768 35.8905673 44.6497901 35.4417536 44.389756 34.7464083 44.2576076 33.8045316 44.2576076L32.9195468 44.2576076 32.9195468 47.7710519zM32.9195468 50.0116081L32.9195468 55 30 55 30 42 34.0113291 42C35.8824399 42 37.266809 42.3222718 38.1653395 42.9651102 39.0629669 43.6079486 39.5117806 44.5841422 39.5117806 45.8945435 39.5117806 46.6592996 39.2896314 47.3405037 38.8435268 47.9355981 38.3974222 48.5315451 37.7661933 48.9979014 36.950743 49.3355194 39.0223299 52.2581322 40.3723832 54.1465766 41 55L37.7598719 55 34.4736885 50.0116081 32.9195468 50.0116081 32.9195468 50.0116081z"
																												/>
																												<polyline class="logo-element park-text" fill="#ffffff" points="56 55 52.708 55 49.126 49.522 47.9 50.359 47.9 55 45 55 45 42 47.9 42 47.9 47.949 49.041 46.419 52.745 42 55.963 42 51.193 47.753 56 55"
																													/>
																													<polygon class="logo-element park-text" fill="#ffffff" points="60 55 63 55 63 42 60 42" />
																														<path class="logo-element park-text" fill="#ffffff" d="M80 55L76.6586087 55 71.2639166 45.1655955 71.1882901 45.1655955C71.295631 46.9022823 71.3493014 48.1419203 71.3493014 48.8819517L71.3493014 55 69 55 69 42 72.3161824 42 77.7019295 51.7372114 77.7612922 51.7372114C77.6767206 50.0465635 77.6336216 48.8529643 77.6336216 48.1538562L77.6336216 42 80 42 80 55M91.684139 47.7262695L97 47.7262695 97 54.2818321C96.1386336 54.5472059 95.3273672 54.7337969 94.5662006 54.8399464 93.8059129 54.9452666 93.0280463 55 92.2334798 55 90.2110268 55 88.6667199 54.4393978 87.5996804 53.3181934 86.5326408 52.1961597 86 50.5856724 86 48.4867313 86 46.4450115 86.6187775 44.8544271 87.8563324 43.7124904 89.0938873 42.571383 90.8095885 42 93.0043148 42 94.3789852 42 95.7053136 42.2595688 96.9824211 42.7787063L96.0384339 44.9232585C95.0601678 44.4613422 94.0423492 44.231628 92.984978 44.231628 91.7570915 44.231628 90.7726728 44.6205665 90.0334798 45.3992728 89.2942869 46.1779791 88.9242509 47.2237178 88.9242509 48.5389768 88.9242509 49.911457 89.2222133 50.9588543 89.8181382 51.6828273 90.4140631 52.4068002 91.2807032 52.768372 92.4171794 52.768372 93.0095885 52.768372 93.611666 52.7111508 94.2234119 52.5950498L94.2234119 49.9570681 91.684139 49.9570681 91.684139 47.7262695"
																															/>
																														</g>
																													</svg>
																												</div>
																											</div>
																											<div class="col-xs-1 col-sm-1 text-right">
																												<div class="title-icon">
																													<svg width="20px" height="20px" viewBox="0 0 26 26" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
																														<g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
																															<g class="icon-cross" fill="#FFFFFF">
																																<rect id="Rectangle" transform="translate(13.000000, 13.435029) rotate(45.000000) translate(-13.000000, -13.435029) " x="11"
																																y="-2" width="4" height="30.8700577"></rect>
																																<rect id="Rectangle" transform="translate(13.000000, 13.435029) rotate(-45.000000) translate(-13.000000, -13.435029) " x="11"
																																y="-2" width="4" height="30.8700577"></rect>
																															</g>
																														</g>
																													</svg>
																												</div>
																											</div>
																										</div>
																									</div>
																									<div class="claerfix overflowImage">
																										<img [src]="map_widget_large_Icon" alt="icon">
																									</div>
																								</div>
																							</div>
																							<a data-dismiss="modal" aria-hidden="true" class="btn btn-primary theme-btn btn-sm">Close</a>
																						</div>
																					</div>
																				</div>
																			</div>
																		<app-footer></app-footer>