<app-header></app-header>

<div class="main mar-top-md mar-bottom-lg">
		
	<div class="container commonPage orangeBar">
    <div *ngIf="isLoyaltyMember" class="1" style="padding-top:0px"></div>
    <div *ngIf="!isLoyaltyMember" class=" 2" style="margin-top:0px"></div>
		<div class="mar-top-lg mar-bottom-sm text-center btn-center-xs">
			<button type="button" (click)="staticModal.show();resetForm(cm);" class="btn btn-fill btn-primary text-uppercase text-white mar-bottom-xs mr-sm-2">Email Coupon</button>
			<button (click)="printCoupon();" class="btn btn-primary btn-fill mar-bottom-xs" type="submit">PRINT COUPON</button>
		</div>

		<div class="clearfix text-center" id="printableContent">
				
			<!-- 	<img class="printable img-responsive full-width mar-lr-auto" style="max-width: 800px;" src="https://api.iconparkingsystems.com/globalcoupon/morgan-stanley/jpg"> -->

			<!-- <img class="printable img-responsive full-width mar-lr-auto" style="max-width: 800px;" src="{{imgSrc}}"> -->
			<img  *ngIf="imgSrc" class="printable img-responsive full-width mar-lr-auto" style="max-width: 800px;" [src]="sanitizer.bypassSecurityTrustResourceUrl(imgSrc)" alt="image" />
			
		</div>
		
	</div>

</div>
<!--  for email coupons pages model -->

<div class="modal fade" bsModal #staticModal="bs-modal" [config]="{backdrop: 'static'}"
	tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">
	<div class="modal-dialog modal-sm">
		<div class="modal-content">
			<div class="modal-header">
				<h4 class="modal-title pull-left" style="padding-left:15px;">Email Coupon</h4>
				<button type="button" class="close pull-right" (click)="staticModal.hide()" aria-label="Close">
					<span aria-hidden="true"><fa name="times"></fa></span>
				</button>
			</div>

			
			<div class="modal-body">

				<form name="form" role="form" id="floatForm" (ngSubmit)="cm.form.valid && sendCoupon(mailFormData, cm)" #cm="ngForm" novalidate>
					<div class="form-group mar-top-0">
						<input placeholder="Enter Your Email" class="form-control" type="mail" name="ucmail" [(ngModel)]="mailFormData.ucmail" #ucmail="ngModel"
							 required />
					<div *ngIf="cm.submitted && !ucmail.valid"class="help-block">Email is required</div>
					</div>
					<div class="form-group">
						<button class="btn btn-primary btn-block btn-fill" type="submit">EMAIL COUPON</button>
					</div>
					<div class="form-group">
						<button class="btn btn-primary btn-block btn-fill" type="button" (click)="staticModal.hide();" >CANCEL</button>
					</div>
				</form>
				
			</div>
		</div>
	</div>
</div>
<app-footer></app-footer>