<app-header></app-header>

<div class="contactPage pageHeight  commonPage orangeBar  mar-top-lg">
  <div class="container" style="width:100%">
     <div class="row mar-top-md cancellationBox" *ngIf="!has_error">
            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 col-md-offset-3 col-lg-offset-3 col-sm-offset-3">
              <div class="well well-lg text-center">
                <h2 class="text-primary ">Your reservation has been Canceled.</h2>
                <p *ngIf="!hideDesc">You should be receiving a confirmation email shortly.</p>
                 <p>Thank you!</p>
                <a *ngIf="!isFrame" class="btn btn-primary theme-btn  " routerLink="/"> <fa name="home"></fa> Return to home page</a>
                <a *ngIf="isFrame" class="btn btn-primary theme-btn " (click)="fordaily()" > <fa name="home"></fa> Return to home page</a>
              </div>
        </div>
      </div>
      
      <div class="row mar-top-md cancellationBox" *ngIf="has_error && !hideDesc">
        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 col-md-offset-3 col-lg-offset-3 col-sm-offset-3">
          <div class="well well-lg text-center">
            <h2 class="text-primary mb-2 ">Reservation has already begun or is already<br class="hidden-xs"> over and cannot be cancelled.</h2>
            <p class="my-5">Please refer to our <a class="text-dark" style="cursor: pointer;" routerLink="/faq">FAQ page</a> for additional information regarding this issue. </p>

            <a *ngIf="!isFrame" class="btn btn-primary theme-btn  whiteBtnCustome  " routerLink="/"> <fa name="home"></fa> Return to home page</a>
            <a *ngIf="isFrame" class="btn btn-primary theme-btn whiteBtnCustome   " (click)="fordaily()" > <fa name="home"></fa> Return to home page</a>
            <a  class="btn btn-primary ml-3 theme-btn  whiteBtnCustome    " routerLink="/faq"> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; FAQ &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</a>
          </div>
        </div>
      </div>
      <div class="row mar-top-md cancellationBox" *ngIf="has_error && hideDesc">
        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 col-md-offset-3 col-lg-offset-3 col-sm-offset-3">
          <div class="well well-lg text-center">
            <h2 class="text-primary mb-2 ">{{errorMessage}}</h2>
            <!-- <p class="my-5">Please refer to our <a class="text-dark" style="cursor: pointer;" routerLink="/faq">FAQ page</a> for additional information regarding this issue. </p> -->
            <br />
            <p>Thank you!</p>
            <br />
            <a *ngIf="!isFrame" class="btn btn-primary theme-btn  whiteBtnCustome  " routerLink="/"> <fa name="home"></fa> Return to home page</a>
            <a *ngIf="isFrame" class="btn btn-primary theme-btn whiteBtnCustome   " (click)="fordaily()" > <fa name="home"></fa> Return to home page</a>
            <!-- <a  class="btn btn-primary ml-3 theme-btn  whiteBtnCustome    " routerLink="/faq"> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; FAQ &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</a> -->
          </div>
        </div>
      </div>
    </div>
</div>
<!-- 
<div class="contactPage pageHeight  commonPage orangeBar  mar-top-lg">
  <div class="container" style="width:100%">
     <div class="row mar-top-md" >
            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 col-md-offset-3 col-lg-offset-3 col-sm-offset-3">
              <div class="well well-lg text-center">
                <h2 class="text-primary ">Reservation has already begun or <br>is already over and cannot be cancelled</h2>
                <p class="my-4">Please refer to our FAQ page for additional information regarding this issue.</p>
                <a *ngIf="!isFrame" class="btn btn-primary theme-btn  " routerLink="/"> <fa name="home"></fa> Return to home page</a>
                <a *ngIf="isFrame" class="btn btn-black theme-btn " (click)="fordaily()" > <fa name="home"></fa> Return to home page</a>
                <a *ngIf="!isFrame" class="btn btn-primary theme-btn  " routerLink="/faq">&nbsp;&nbsp; &nbsp;&nbsp;FAQ&nbsp;&nbsp;&nbsp;&nbsp;</a>
              </div>
        </div>
      </div>
    </div>
</div> -->
<app-footer></app-footer>
