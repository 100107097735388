import { Component, OnInit, Input } from '@angular/core';
import { MyAccountMonthlyParkingService } from '../../services/my-account-monthly-parking.service';
import { DatePipe } from '@angular/common';
import { UserserviceService } from '../../services/userservice.service';
import * as $ from 'jquery';
import * as FileSaver from 'file-saver';
import { ToastrService } from 'ngx-toastr';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
// declare var ga: Function;
@Component({
  selector: 'app-payment-history',
  templateUrl: './payment-history.component.html',
  styleUrls: ['./payment-history.component.scss'],
  providers: [MyAccountMonthlyParkingService, DatePipe,UserserviceService]
})
export class PaymentHistoryComponent implements OnInit {

  @Input() currentActive: string;
  @Input() accountNumber: any;
  mailFormData: any = {};
  paymentHistory: any = {};
  isLoaded = false;
  receiptId:any=0;
  constructor(private userserviceServiceObj: UserserviceService,
    private router: Router,
    private myAccountMonthlyParkingObj: MyAccountMonthlyParkingService, private datePipe: DatePipe,private toastr: ToastrService) { }

  ngOnInit() {
    this.fetchUserStatusForTracking();
    this.getpaymentHistory();
  }
  cancelEmail(formValues, formObj: NgForm) {
    $('#email_validation').addClass('hide')
    $('#email_validation').removeClass('show')
    formObj.resetForm();
    
  }
  cancelEmailCross()
  {
    $('#email_validation').addClass('hide')
    $('#email_validation').removeClass('show')
    $('#email_data').val('')
  }
  printReceipt(i) {
    this.fetchUserStatusForTracking();
    // window.print();
    $('div#printable').html("<br /><br /><br /><br /><br /><br />" + $('#monthlyAccountSection').html() + '<br /><br /><br /><br />' + $('div#printableContent'+i).html()).show();
    $('div#content-Wrapper').hide();
    $('.overlay-container').hide();
    window.print();
    var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    if (isMobile) {
      setTimeout(()=>{
        $('div#printable').html('').hide();
        $('div#content-Wrapper').show();
        $('.overlay-container').show();
      },8000)
    }else{
      $('div#printable').html('').hide();
      $('div#content-Wrapper').show();
      $('.overlay-container').show();
    }
   }
   sendEmail(id)
   {
     this.receiptId=id
    // this.myAccountMonthlyParkingObj.PaymentHistoryMail(id,this.accountNumber)
    // .subscribe(
    //   (data:any)=> {
    //     this.toastr.success(data.message, 'Great!');
    //   },
    //   error => {
    //     this.toastr.error(error.errors.message, 'Sorry!');
    //   }
    // );
   }
  capitalize(s){
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
  }
  checkvalidate(val)
  {

    let email=val
    if(email=='')
    {
        $('#email_validation').addClass('hide')
        $('#email_validation').removeClass('show')
    }else{
      let validateEmail= this.validateEmail(email)
      if(!validateEmail)
      {
        $('#email_validation').removeClass('hide')
        $('#email_validation').addClass('show')
      }else{
        $('#email_validation').addClass('hide')
        $('#email_validation').removeClass('show')
      }
    }
    
  }
   sendReceipt(formData, formObj: NgForm)
   {
    this.fetchUserStatusForTracking();
     let emailId=''
     if(formData.ucmail=='' || formData.ucmail==undefined)
     {
      emailId=''
      $('#email_validation').addClass('hide')
      $('#email_validation').removeClass('show')
     }else{
      emailId=formData.ucmail;
      
      let validateEmail= this.validateEmail(emailId)
      if(!validateEmail)
      {
        $('#email_validation').removeClass('hide')
        $('#email_validation').addClass('show')
        return false;
      }else{
        $('#email_validation').addClass('hide')
        $('#email_validation').removeClass('show')
      }
     }
     
     //return false;
     /* commented for ga 16-6-2021 by seo team
		 ga('send', 'event', { eventCategory: 'email receipt', eventAction: 'click',value: this.receiptId });
     */
     this.myAccountMonthlyParkingObj.PaymentHistoryMail(this.receiptId,this.accountNumber,emailId)
    .subscribe(
      (data:any) => {
        this.toastr.success(data.message, 'Great!',{timeOut: 20000});
        formObj.resetForm();
        $('#hidepopup').trigger('click')
      },
      error => {
        this.toastr.error(error.errors.message, 'Sorry!');
      }
    );
    }
    validateEmail(email) 
    {
        var re = /\S+@\S+\.\S+/;
        return re.test(email);
    }
   downloadRecepts(id,date)
   {
    this.fetchUserStatusForTracking();
    this.userserviceServiceObj.downloadPaymentReceipts(id,this.accountNumber)
    .then(
      (data:any)=> {
		  
        //var mediaType = 'image/jpg';
        var mediaType = 'application/pdf';
        var blob = new Blob([data], {type: mediaType});
        var filename = 'MonthlyPaymentReceipt_'+date+'.pdf';
        FileSaver.saveAs(blob, filename);
      },
      error => {
        this.toastr.error(error.message, 'Sorry!');
      }
    );
   }
  transformDate(date) {
    return this.datePipe.transform(date, 'medium');
  }

  getpaymentHistory() {
    this.myAccountMonthlyParkingObj.paymentHistory(this.accountNumber)
    .then(
      (data:any)=> {
        this.isLoaded = true;
        this.paymentHistory = data['data'];
        //console.log(this.paymentHistory);
        if(this.paymentHistory.length==0){
          this.toastr.error('No Payment history available', 'Sorry!'); 
        }
      },
      error => {
        //this.toastr.error(error.errors.message, 'Sorry!');
      }
    );
  }

  fetchUserStatusForTracking(){
    let getUserId = '0';
    let getUserEmail = '0';
    let user_login_type = '';
    let isLogin = false;
    if(localStorage.getItem('access_token')){
     isLogin = true;
       getUserId = localStorage.getItem('userid');
       getUserEmail = localStorage.getItem('email');
       user_login_type = localStorage.getItem('user_login_type');
    }
    const loginStatusTrack = {
      'event' : 'pageview',
      'loginStatus' : isLogin? 'logged in': 'Not Logged IN', // 'logged in/Not Logged IN',
      'authenticationMethod' : user_login_type,
      'userId' : getUserId,
      'pageType' : this.router.url,
      'email': getUserEmail,
     };
    (<any>window).dataLayer.push(loginStatusTrack);
    }
}
