<app-header *ngIf="!forLoggedIn"></app-header>
<!-- reservation sectiona and content -->
<div class="mar-top-sm">
    <div class=" reserList" *ngIf="!facId">
        <h2 *ngIf="isLoaded" class="text-primary text-uppercase text-uppercase mar-top-0 mar-bottom-sm">My Reservations</h2>
        <p class="" *ngIf="isLoaded">
            <span *ngIf="reservations?.data.length < 1">You currently have no reservations. Search for a facility to make your first reservation.</span>
        </p>
        <button *ngIf="isLoaded && reservations?.data.length < 1" type="button" class="btn theme-btn btn-primary" [ngClass]="{'btn-fill': reservations?.data.length < 1}" (click)="findSpot()">Find Your Spot</button>
        <div *ngIf="isLoaded && reservations?.total > 0">
            <div class="well" *ngFor="let reservation of reservations?.data">
                <div class="row">
                    <div class="col-xs-12 col-sm-7 col-md-8 col-lg-8">
                        <h4>{{ reservation?.facility?.facility_name_modified }}
                            <ng-container *ngIf="reservation?.is_end_time_updated ==1"><span class="seperator"> - </span>
                                <span style="font-weight: 400;">Your reservation was extended at no extra charge!</span>
                                <!-- <span class="label label-primary more-space-reservation mar-top-res no-pointer">More Time Same Price</span> -->
                            </ng-container>
                        </h4>
                    </div>
                    <div class="hidden-xs col-xs-12 col-sm-5 col-md-4 col-lg-4 text-right">
                        <h3 sourceFont><span *ngIf="reservation?.total > 0 && reservation?.loyalty_point_used == 0" class="text-primary">${{ reservation?.total }}</span><span  class="text-primary" *ngIf="(reservation?.total > 0 && reservation?.loyalty_point_used == 0) && (reservation?.credit_used > 0)"> | </span><span *ngIf="reservation?.credit_used > 0" class="text-primary">CREDIT ${{ reservation?.credit_used | number }}</span><span  class="text-primary" *ngIf="(reservation?.loyalty_point_used > 0) && (reservation?.credit_used > 0)"> | </span><span *ngIf="reservation?.loyalty_point_used > 0" class="text-primary">POINTS {{ reservation?.loyalty_point_used | number }}</span></h3>
                    </div>
                    <div class="visible-xs col-xs-12">
                        <h3 sourceFont><span *ngIf="reservation?.total > 0 && reservation?.loyalty_point_used == 0" class="text-primary">${{ reservation?.total }}</span><span  class="text-primary" *ngIf="(reservation?.total > 0 && reservation?.loyalty_point_used == 0) && (reservation?.credit_used > 0)"> | </span><span *ngIf="reservation?.credit_used > 0" class="text-primary">CREDIT ${{ reservation?.credit_used | number }}</span><span  class="text-primary" *ngIf="(reservation?.loyalty_point_used > 0) && (reservation?.credit_used > 0)"> | </span><span *ngIf="reservation?.loyalty_point_used > 0" class="text-primary">POINTS {{ reservation?.loyalty_point_used | number }}</span></h3>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xs-12 col-sm-5 col-md-4 col-lg-3">
                        <p>{{ reservation?.formatted_start_date}} {{ reservation?.formatted_start_time }} - {{ reservation?.formatted_end_date}} {{ reservation?.formatted_end_time}} <br/> {{ reservation?.length }} HOURS
                        </p>
                    </div>
                    <div class="col-xs-12 col-sm-2 col-md-4 col-lg-6">
                        <p>
                            <small>Status</small><br>
                            <span *ngIf="reservation?.status != 'Cancelled'" class="text-success">{{ reservation?.status }}</span>
                            <span *ngIf="reservation?.status == 'Cancelled'" class="text-success">Canceled</span>
                            <span class="text-success" *ngIf="reservation?.status == 'Cancelled'"> ( {{ reservation?.formatted_cancelled_at_date }}) </span>
                        </p>
                    </div>
                    <div class="col-xs-12 col-sm-5 col-md-4 col-lg-3 text-right pad-left-0">
                        <a (click)="redirectToDetails(reservation?.reservation_code,reservation?.ticketech_code)" class="btn btn-primary btn-fill theme-btn" [ngClass]="{'btn-sm': SmallDevice}">Details</a><span class="mar-left-xs"></span>
                        <a href="javascript:;" (click)="(reservation?.status == 'Future') ? cancelReservation(reservation.id,reservation.ticketech_code,reservation?.total,reservation?.facility?.short_name) : errorCancelReservation(reservation?.status)" class="btn btn-primary theme-btn" [ngClass]="{'btn-sm': SmallDevice}">Cancel</a>
                    </div>
                </div>
            </div>

            <div class="clearfix row" id="pagination" *ngIf="reservations?.total > 20">
                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6" id="pagination">
                    <p>Showing Results for Page {{ reservations?.current_page }} of {{ reservations?.last_page }}</p>
                </div>
                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6 text-right">
                    <ul class="pagination pagination-lg mar-top-0 mar-bottom-xs">
                        <li *ngIf="reservations?.prev_page_url != null">
                            <button type="submit" class="btn btn-primary theme-btn" (click)="loadPreviousNextReservations(reservations?.current_page, 'prev')">
              <fa name="angle-left"></fa>
              </button>
                        </li>
                        <li *ngIf="reservations?.next_page_url != null">
                            <button type="submit" class="btn btn-primary theme-btn" (click)="loadPreviousNextReservations(reservations?.current_page, 'next')">
              <fa name="angle-right"></fa>
              </button>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <!--    <div *ngIf="isLoaded">
      <p>
        <span *ngIf="reservations?.data.length < 1">You currently have no reservations. </span>Search for a facility to make a reservation.
      </p>
      <button type="button" class="btn theme-btn btn-primary hidden-xs" [ngClass]="{'btn-fill': reservations?.data.length < 1}" (click)="findSpot()">Find Your spot</button>
      <div class="visible-xs row">
        <div class="col-xs-offset-1 col-xs-10 mar-top-xs">
          <button type="button" class="btn theme-btn btn-primary" [ngClass]="{'btn-fill': reservations?.data.length < 1}" (click)="findSpot()">Find Your spot</button>
        </div>
      </div>
    </div> -->
    </div>

    <div class="container pad-top-lg  pad-bottom-lg" *ngIf="!forLoggedIn" [class.pad-top-lg]="!isFrame">
        <ol class="breadcrumb pad-top-0 pad-bottom-0 pad-left-0" *ngIf="isFrame">
            <li class="active">
                <a href="javascript:;" onclick="history.go(-1);" class="btn btn-link pad-left-0">
                    <fa name="angle-left"></fa> &nbsp; Back</a>
            </li>
        </ol>
        <div class="clearfix reserItem">
            <app-lazy-load [isLoaded]="isLoaded" [sectionName]="lazyLoadLabel"></app-lazy-load>
            <div *ngIf="facId">
                <div *ngIf="isLoaded" class="row flex-row justify-content-center">
                    <div class="col-xs-12 col-sm-8 col-md-9 col-lg-9 text-center ">
                        <h1 class="text-primary">Thank you for your reservation!</h1>
                        <p class="lead">Please check your email for the reservation redemption voucher. Save reference code
                            <b>{{ reservationDetails?.ticketech_code}}</b> for your records. See your reservation details below.</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="clearfix"></div>
    </div>
    <div class="row">
        <!-- <div *ngIf="isLoyaltyMember && !forLoggedIn && isLoyaltyInfoLoaded" class="well mar-bottom-lg">
            <div class="container">
              <div class="row">
              <div class="col-sm-12 col-md-8 col-md-offset-2 col-xs-12">
                <div class="top-icon-rewards mar-top-xs mar-bottom-xs">
                <ul>
                  <li>
                  <img src="assets/img/icon-rewards-logo-wt.svg" alt="price_tag">
                  </li>
                </ul>
                </div>
                <div class="clearfix"></div>
                <h2 class="text-uppercase mar-bottom-xs">
                <span class="text-primary">ADD YOUR CARD TO ICON REWARDS? </span>
                </h2>
                <p>Add your credit card to your Icon Rewards and you will earn points even when you pay for parking at a garage.
                If you don’t add a credit card you will only earn points when you book on our website or in the Icon GO app.</p>
              </div>
              <div class="clearfix"></div>
              <div class="col-xs-12 col-sm-12 col-md-10 col-md-offset-2">
                <div class="btn-center-xs mar-top-sm mar-bottom-sm">
                <div class="mar-top-xs visible-xs"></div>
                <a href="javascript:void(0)" (click)="redirectToCard(reservationDetails?.ticketech_code)" class="btn btn-primary btn-fill">ADD CARD NOW</a>
                <span class="mar-left-xs"></span>
                <a routerLink="/rewards" class="btn theme-btn btn-primary">READ MORE</a>
                </div>
              </div>
              </div>
            </div>
            </div> -->
        <div *ngIf="!isLoyaltyMember && !forLoggedIn && isLoyaltyInfoLoaded" class="well mar-bottom-lg">
            <div class="container">
                <div class="row">
                    <div class="col-sm-12 col-md-8 col-md-offset-2 col-xs-12">
                        <div class="top-icon-rewards">
                            <ul>
                                <li>
                                    <img src="assets/img/icon-rewards-logo-wt.svg" alt="price_tag">
                                </li>
                            </ul>
                        </div>
                        <h3 class="text-uppercase entroll-msg mar-bottom-sm">ENROLL NOW TO RECEIVE AT LEAST <span class="text-primary">10%</span> OFF RESERVATIONS AND POSTED FACILITY RATES!</h3>
                        <div class="row">
                            <div class="col-sm-6">
                                <h4 class="text-uppercase pad-top-xs pad-right-xs" sourceFont>FOR A LIMITED TIME, REWARDS MEMBERS CAN ALSO RECEIVE A <span class="text-primary">${{rewarded_points | number}} ACCOUNT CREDIT </span> BY ANSWERING A FEW QUESTIONS WHEN ENROLLING.</h4>
                                <div class="btn-center-xs mar-bottom-sm mar-top-lg">
                                    <div class="mar-top-sm visible-xs"></div>
                                    <a href="javascript:void(0)" (click)="moveToEnrollPage(reservationDetails?.ticketech_code)" class="btn btn-primary btn-fill">ENROLL NOW</a>
                                    <span class="mar-left-xs"></span>
                                    <a routerLink="/rewards" class="btn theme-btn btn-primary">READ MORE</a>
                                </div>
                            </div>
                            <div class="visible-xs mar-bottom-sm"></div>
                            <div class="col-sm-6">
                                <div class="row">
                                    <app-how-it-works [isPayment]="true"></app-how-it-works>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="!forLoggedIn && isshowData" class="container">
            <div class="col-md-7 col-md-offset-1 col-lg-7 col-xs-12">
                <h2 class="mar-top-0 text-primary text-uppercase">Reservation details</h2>
                <p>{{currentDateForReservation|date:'EEE, MMMM dd yyyy'}} {{currentDateForReservation | date:'shortTime'}}</p>
                <!-- <p>{{ reservationDetails?.formatted_start_date_time }}</p> -->
                <h4 class="mar-top-sm">{{ activeUser?.name }}</h4>
                <p>{{ activeUser?.email }}</p>
                <p *ngIf="activeUser?.phone != null">{{ activeUser?.phone }} </p>
                <br>
                <p class="accent mar-bottom-sm">Standard size vehicle </p>
                <div class="row">
                    <div class="col-xs-12 col-sm-12 col-md-10 col-lg-8">
                        <p *ngIf="isUpdatedEndTimeFlag" class="accent mar-bottom-xs text-center"><span class="label label-primary more-space-reservation no-pointer">More Time Same Price</span></p>
                        <div [ngClass]="(isUpdatedEndTimeFlag)?'moretime-res-border':''" class="well mar-bottom-sm">
                            <table width="100%" cellpadding="10">
                                <tbody>
                                    <tr class="visible-xs">
                                        <td class="text-uppercase" colspan="2">
                                            <b>Enter after</b>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="text-uppercase hidden-xs">
                                            <b>Enter after</b>
                                        </td>
                                        <td>
                                            <fa class="text-primary" name="calendar-o"></fa> &nbsp; &nbsp; {{ reservationDetails?.formatted_start_date }}
                                        </td>
                                        <td>
                                            <fa class="text-primary" name="clock-o"></fa> &nbsp; &nbsp; {{ reservationDetails?.formatted_start_time }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="2">&nbsp;</td>
                                    </tr>
                                    <tr class="visible-xs">
                                        <td class="text-uppercase" colspan="2">
                                            <b>Exit Before</b>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="text-uppercase hidden-xs">
                                            <b>Exit before</b>
                                        </td>
                                        <td *ngIf="reservationDetails?.formatted_end_date">
                                            <fa class="text-primary" name="calendar-o"></fa> &nbsp; &nbsp; {{ reservationDetails?.formatted_end_date }}
                                        </td>
                                        <td *ngIf="!reservationDetails?.formatted_end_date">
                                            <fa class="text-primary" name="calendar-o"></fa> &nbsp; &nbsp; {{ reservationDetails?.formatted_start_date }}
                                        </td>
                                        <td>
                                            <fa class="text-primary" name="clock-o"></fa> &nbsp; &nbsp; {{ reservationDetails?.formatted_end_time }}
                                        </td>
                                        
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <a class="btn btn-default disabled card-desc">
                    <!-- <fa name="credit-card" *ngIf="reservationDetails?.transaction?.method == 'card'"></fa> -->
                    <img class="cardsize" [src]="visa_Icon" *ngIf="reservationDetails?.transaction?.card_type == 'Visa'" />
                    <img class="cardsize" [src]="mastercardIcon" *ngIf="reservationDetails?.transaction?.card_type == 'MasterCard'" />
                    <img class="cardsize" [src]="amexIcon" *ngIf="reservationDetails?.transaction?.card_type == 'AmericanExpress'" />
                    <img class="cardsize" [src]="discovercardIcon" *ngIf="reservationDetails?.transaction?.card_type == 'Discover'" />
                    <img class="cardsize" [src]="jcbIcon" *ngIf="reservationDetails?.transaction?.card_type == 'JCB'" />
                    <img class="cardsize" [src]="dinersIcon" *ngIf="reservationDetails?.transaction?.card_type == 'DinersClub'" />
                    <img class="cardsize" [src]="card_grey_Icon" *ngIf="reservationDetails?.transaction?.card_type != 'eCheck' && reservationDetails?.transaction?.card_type != 'Visa' && reservationDetails?.transaction?.card_type != 'JCB' && reservationDetails?.transaction?.card_type != 'Discover' && reservationDetails?.transaction?.card_type != 'AmericanExpress' && reservationDetails?.transaction?.card_type != 'MasterCard' && reservationDetails?.transaction?.card_type != 'DinersClub'"
                    />
                    <fa name="bank" *ngIf="reservationDetails?.transaction?.method == 'bank'"></fa>
                    <!-- Visa Credit Card (..1234)  -->
                    &nbsp;&nbsp;{{ reservationDetails?.transaction?.payment_details }}
                </a>
                <p *ngIf="reservationDetails?.loyalty_point_used > 0" class="mar-top-sm"><b>Amount paid by points</b></p>
                <h2 *ngIf="reservationDetails?.loyalty_point_used > 0" class="mar-top-xs">{{ reservationDetails?.loyalty_point_used | number }} POINTS</h2>
                <p *ngIf="reservationDetails?.total > 0 && reservationDetails?.loyalty_point_used == 0 && is_discount>0 " class="mar-top-sm">Reservation amount</p>
                <h2 *ngIf="reservationDetails?.total > 0 && reservationDetails?.loyalty_point_used == 0 && is_discount>0 " class="mar-top-xs" sourceFont>${{ totalReservatiomAmount.toFixed(2) }}</h2>
                <!-- <p *ngIf="processFess > 0" class="mar-top-sm">Processing Fee</p> -->
                <!-- <h2 *ngIf="processFess > 0" class="mar-top-xs">${{processFess | number:'1.2-2'}}</h2> -->
                <p *ngIf="reservationDetails?.total > 0 && reservationDetails?.loyalty_point_used == 0 && is_discount>0" class="mar-top-sm">{{discountType}}</p>
                <h2 *ngIf="reservationDetails?.total > 0 && reservationDetails?.loyalty_point_used == 0 && is_discount>0" class="mar-top-xs" sourceFont>${{ is_discount.toFixed(2) }}</h2>
                <p *ngIf="reservationDetails?.total > 0 && reservationDetails?.loyalty_point_used == 0" class="mar-top-sm">Amount charged to {{ reservationDetails?.transaction?.method }}</p>
                <h2 *ngIf="reservationDetails?.total > 0 && reservationDetails?.loyalty_point_used == 0" class="mar-top-xs" sourceFont>${{ reservationDetails?.total }}</h2>
                <p *ngIf="reservationDetails?.credit_used > 0" class="mar-top-sm">Amount paid by credits</p>
                <h2 *ngIf="reservationDetails?.credit_used > 0" class="mar-top-xs" sourceFont>${{ reservationDetails?.credit_used | number }} CREDIT</h2>
                <div class="clearfix mar-top-sm">
                    <a href="javascript:void(0);" (click)="printGuestReservation(facId)" class="btn btn-primary btn-fill theme-btn hidden-xs">Print Reservation</a><span class="mar-left-xs hdden-xs"></span><a href="javascript:void(0);" (click)="cancelReservationLogoutUser(facId,reservationDetails?.ticketech_code,reservationDetails?.total,reservationDetails?.facility?.short_name)"
                        [ngClass]="{'disabled': reservationDetails?.status != 'Future'}" class="btn theme-btn btn-primary theme-btn hidden-xs">Cancel</a>
                    <div class="row visible-xs">
                        <div class="col-xs-offset-1 col-xs-10">
                            <a href="javascript:void(0);" (click)="printGuestReservation(facId)" class="btn btn-primary btn-fill btn-block theme-btn">Print Reservation</a>
                            <div class="mar-top-xs"></div><a href="javascript:void(0);" (click)="cancelReservationLogoutUser(facId,reservationDetails?.ticketech_code,reservationDetails?.total,reservationDetails?.facility?.short_name)" [ngClass]="{'disabled': reservationDetails?.status != 'Future'}"
                                class="btn btn-primary btn-block theme-btn">Cancel</a>
                        </div>
                    </div>
                </div>
                <div class="clearfix mar-top-sm hidden">
                    <a href="javascript:void(0);" (click)="printGuestReservation(facId)" class="btn btn-primary btn-fill theme-btn">Print Reservations</a>
                    <a href="javascript:void(0);" (click)="cancelReservationLogoutUser(facId,reservationDetails?.ticketech_code,reservationDetails?.total,reservationDetails?.facility?.short_name)" [ngClass]="{'disabled': reservationDetails?.status != 'Future'}" class="btn theme-btn btn-primary theme-btn">Cancel</a>
                </div>
                <div class="hidden-xs">
                    <hr class="mar-top-sm mar-bottom-sm">
                    <p class="text-uppercase"><b>Reservation Policy</b></p>
                    <small sourceFont>
              No in/out (One Entry/Exit) No Partial Refunds for Unused Time<br>
              To cancel for a full refund click the CANCEL BUTTON in the email or on this page ANYTIME PRIOR TO THE ARRIVAL TIME indicated on the reservation.<br>
              If you encounter any issues you can email us at customerservice@iconparking.com<br>
              No refunds will be issued for unused reservations not canceled prior to the arrival time.<br>
              Oversized vehicle surcharge (${{ reservationDetails?.facility?.oversize_fee }}) will be collected at the location if the vehicle does not meet the size requirements, unless paid for at time of reservation.
              </small>
                    <div class="clearfix mar-top-md mar-bottom-md">
                        <p class="text-primary">Thank you for choosing Icon Parking.</p>
                        <p>Questions? Contact our customer service team Monday-Friday, 9am-5pm<br>
                            <a href="tel:(877) 727-5464">(877) 727-5464</a> or at <a href="mailto:customerservice@iconparking.com">customerservice@iconparking.com</a>
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-xs-12 col-sm-4 col-md-3 col-lg-3">
                <p class="visible-xs mar-top-sm"></p>
                <div class="well mar-bottom-0">
                    <h4 class="text-primary">Facility details</h4>
                    <p><b><p>{{ reservationDetails?.facility?.facility_name_modified }}</p></b></p>
                    <p>
                        <fa name="phone"></fa> {{ reservationDetails?.facility?.phone_number }}</p>
                    <p *ngIf="reservationDetails?.facility?.between_streets">Between: {{ reservationDetails?.facility?.between_streets }}</p>
                    <p *ngIf="reservationDetails?.facility?.entrance_location">Entrance: {{ reservationDetails?.facility?.entrance_location }}</p>
                    <a target="_blank" href="https://maps.google.com?q={{reservationDetails?.facility?.geolocations?.latitude}},{{reservationDetails?.facility?.geolocations?.longitude}}" class="btn btn-link">get directions &nbsp;&nbsp; <fa name="chevron-right"></fa> </a>
                    <p class="mar-top-xs" *ngIf="garageWorkingHours?.length == 0">

                        <i class="fa fa-calendar fa-2x text-primary"></i>
                        <!-- <fa name="calendar"  class="ismall text-primary" size="2x" style="vertical-align: sub;"></fa> -->&nbsp; 7 days a week
                    </p>
                    <p class="" *ngIf="garageWorkingHours?.length == 0">
                        <i class="fa fa-clock-o fa-2x text-primary"></i>
                        <!-- <fa name="clock-o" class="ismall text-primary" size="2x" style="vertical-align: sub;"></fa> -->&nbsp; 24 hours a day
                    </p>
                    <div class="TimeActive table-responsive" *ngIf="garageWorkingHours?.length > 0">
                            <h4>Hours of Operation</h4>
                            <table class="table table-condensed ">
                              <tr *ngFor="let wh of garageWorkingHours;">
                                <td>{{wh.day}}</td>
                                <td>{{wh.formatted_open_time}}</td>
                                <td>{{wh.formatted_close_time}} {{showtimefornxtornot(wh.close_time)}}</td>
                              </tr>
                            </table>
                    </div>
                </div>
                <div class="clearfix slideShow">
                    <div id="carousel-id" class="carousel slide" data-ride="carousel">

                        <div class="carousel-inner">
                            <!-- <div class="item">
                    <img src="/assets/img/park-garage-2.jpg" class="full-width">
                  </div> -->
                            <div class="item active">
                                <img [src]="garageIcon" class="full-width" alt="price_tag">
                            </div>
                            <!-- <div class="item">
                    <img src="/assets/img/park-garage-2.jpg" class="full-width">
                    
                  </div> -->
                        </div>
                        <!-- <a class="left carousel-control" href="#carousel-id" data-slide="prev"><fa name="angle-left"></fa></a>
                <a class="right carousel-control" href="#carousel-id" data-slide="next"><fa name="angle-right"></fa></a> -->
                    </div>

                </div>
                <div class="googleMap" id="map-wrapper">
                    <img [src]="garage_mapIcon" class="img-responsive full-width" alt="price_tag">
                </div>
            </div>
        </div>
    </div>
</div>
<div class="clearfix">
</div>
<!-- <app-lazy-load [isLoaded]="isLoaded" [sectionName]="lazyLoadLabel"></app-lazy-load> -->

<div class="clearfix reserItem" *ngIf="forLoggedIn">
    <div class="row" *ngIf="facId">
        <div class="col-sm-12">
            <ol class="breadcrumb pad-top-0 pad-bottom-0">
                <li class="active">
                    <a href="javascript:;" onclick="history.go(-1);" class="btn btn-link pad-left-0 pad-right-xs">
                        <fa name="angle-left"></fa> &nbsp; My Reservations</a>
                </li>
            </ol>
        </div>
        <div *ngIf="isLoaded">
            <div class="col-xs-12 col-sm-9 col-md-9 col-lg-9">
                <p class="lead" style="font-weight: 600;">Please check your email for the reservation redemption voucher. Save reference code <span class="text-uppercase">{{reservationDetails?.ticketech_code}}</span> for your records.</p>
                <h2 class="mar-top-0 text-primary mar-top-sm text-uppercase">Reservation details</h2>
                <p>{{ reservationDetails?.formatted_start_date_time }}</p>
                <h4 *ngIf="reservationDetails?.cancelled" class="mar-top-xs text-capitalize text-primary">Reservation Canceled At: {{ reservationDetails?.formatted_cancelled_at_date }}</h4>
                
                <h4 class="mar-top-xs text-capitalize">{{ activeUser?.name }}</h4>
                <p>{{ activeUser?.email }}</p>
                <p *ngIf="activeUser?.phone != null">{{ activeUser?.phone }}</p>
                <br>
                <p class="accent mar-bottom-xs">Standard size vehicle</p>
                <div class="row">
                    <div class="col-xs-12 col-sm-12 col-md-10 col-lg-8">
                        <p *ngIf="isUpdatedEndTimeFlag" class="accent mar-bottom-xs text-center"><span class="label label-primary more-space-reservation no-pointer">More Time Same Price</span></p>
                        <div [ngClass]="(isUpdatedEndTimeFlag)?'moretime-res-border':''" class="well mar-bottom-sm">
                            <table width="100%" cellpadding="10">
                                <tbody>
                                    <tr class="visible-xs">
                                        <td class="text-uppercase" colspan="2">
                                            <b>Enter after</b>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="text-uppercase hidden-xs">
                                            <b>Enter after</b>
                                        </td>
                                        <td>
                                            <fa class="text-primary" name="calendar-o"></fa> &nbsp; &nbsp; {{ reservationDetails?.formatted_start_date }}
                                        </td>
                                        <td>
                                            <fa class="text-primary" name="clock-o"></fa> &nbsp; &nbsp; {{ reservationDetails?.formatted_start_time }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="2">&nbsp;</td>
                                    </tr>
                                    <tr class="visible-xs">
                                        <td class="text-uppercase" colspan="2">
                                            <b>Exit Before</b>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="text-uppercase hidden-xs">
                                            <b>Exit befores</b>
                                        </td>
                                        <td *ngIf="reservationDetails?.formatted_end_date">
                                            <fa class="text-primary" name="calendar-o"></fa> &nbsp; &nbsp; {{ reservationDetails?.formatted_end_date }}
                                        </td>
                                        <td *ngIf="!reservationDetails?.formatted_end_date">
                                            <fa class="text-primary" name="calendar-o"></fa> &nbsp; &nbsp; {{ reservationDetails?.formatted_start_date }}
                                        </td>
                                        <td>
                                            <fa class="text-primary" name="clock-o"></fa> &nbsp; &nbsp; {{ reservationDetails?.formatted_end_time }}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>


                <a class="btn btn-default disabled card-desc">
                    <!-- <fa name="credit-card" *ngIf="reservationDetails?.transaction?.method == 'card'"></fa> -->
                    <img class="cardsize" [src]="visa_Icon" *ngIf="reservationDetails?.transaction?.card_type == 'Visa'" />
                    <img class="cardsize" [src]="mastercardIcon" *ngIf="reservationDetails?.transaction?.card_type == 'MasterCard'" />
                    <img class="cardsize" [src]="amexIcon" *ngIf="reservationDetails?.transaction?.card_type == 'AmericanExpress'" />
                    <img class="cardsize" [src]="discovercardIcon" *ngIf="reservationDetails?.transaction?.card_type == 'Discover'" />
                    <img class="cardsize" [src]="jcbIcon" *ngIf="reservationDetails?.transaction?.card_type == 'JCB'" />
                    <img class="cardsize" [src]="dinersIcon" *ngIf="reservationDetails?.transaction?.card_type == 'DinersClub'" />
                    <img class="cardsize" [src]="card_grey_Icon" *ngIf="reservationDetails?.transaction?.card_type != 'eCheck' && reservationDetails?.transaction?.card_type != 'Visa' && reservationDetails?.transaction?.card_type != 'JCB' && reservationDetails?.transaction?.card_type != 'Discover' && reservationDetails?.transaction?.card_type != 'AmericanExpress' && reservationDetails?.transaction?.card_type != 'MasterCard' && reservationDetails?.transaction?.card_type != 'DinersClub'"
                    />
                    <fa name="bank" *ngIf="reservationDetails?.transaction?.method == 'bank'"></fa>
                    <!-- Visa Credit Card (..1234)  -->
                    &nbsp;&nbsp;{{ reservationDetails?.transaction?.payment_details }}
                </a>
                <p *ngIf="reservationDetails?.loyalty_point_used > 0" class="mar-top-sm"><b>Amount paid by points</b></p>
                <h2 *ngIf="reservationDetails?.loyalty_point_used > 0" class="mar-top-xs">{{ reservationDetails?.loyalty_point_used | number }} POINTS</h2>
                <p *ngIf="reservationDetails?.total > 0 && reservationDetails?.loyalty_point_used == 0 && is_discount>0 " class="mar-top-sm">Reservation amount</p>
                <h2 *ngIf="reservationDetails?.total > 0 && reservationDetails?.loyalty_point_used == 0 && is_discount>0" class="mar-top-xs">${{ totalReservatiomAmount.toFixed(2) }}</h2>
                <!-- <p *ngIf="processFess > 0" class="mar-top-sm">Processing Fee</p> -->
                <!-- <h2 *ngIf="processFess > 0" class="mar-top-xs">${{processFess | number:'1.2-2'}}</h2> -->
                <p *ngIf="reservationDetails?.total > 0 && reservationDetails?.loyalty_point_used == 0 && is_discount>0" class="mar-top-sm">{{discountType}}</p>
                <h2 *ngIf="reservationDetails?.total > 0 && reservationDetails?.loyalty_point_used == 0 && is_discount>0" class="mar-top-xs">${{ is_discount.toFixed(2) }}</h2>
                <p *ngIf="reservationDetails?.total > 0 && reservationDetails?.loyalty_point_used == 0" class="mar-top-sm"><b>Amount charged to {{ reservationDetails?.transaction?.method }}</b></p>
                <h2 *ngIf="reservationDetails?.total > 0 && reservationDetails?.loyalty_point_used == 0" class="mar-top-xs">${{ reservationDetails?.total }}</h2>
                <p *ngIf="reservationDetails?.credit_used > 0" class="mar-top-sm"><b>Amount paid by credits</b></p>
                <h2 *ngIf="reservationDetails?.credit_used > 0" class="mar-top-xs" sourceFont>${{ reservationDetails?.credit_used | number }} CREDIT</h2>
                <div class="clearfix mar-top-sm">
                    <a href="javascript:void(0);" (click)="printReservation(facId)" class="btn btn-primary btn-fill theme-btn hidden-xs">Print Reservation</a><span class="mar-left-xs"></span><a href="javascript:void(0);" (click)="(reservationDetails?.status == 'Future') ? cancelReservation(reservationDetails?.id,reservationDetails?.ticketech_code,reservationDetails?.total,reservationDetails?.facility?.short_name) : errorCancelReservation(reservationDetails?.status)"
                        class="btn theme-btn btn-primary theme-btn hidden-xs">Cancel</a>
                    <div class="row visible-xs">
                        <div class="col-xs-offset-2 col-xs-8">
                            <a href="javascript:void(0);" (click)="printReservation(facId)" class="btn btn-primary btn-fill btn-block theme-btn">Print Reservation</a>
                            <div class="mar-top-xs"></div><a href="javascript:void(0);" (click)="(reservationDetails?.status == 'Future') ? cancelReservation(reservationDetails?.id,reservationDetails?.ticketech_code,reservationDetails?.total,reservationDetails?.facility?.short_name) : errorCancelReservation(reservationDetails?.status)"
                                class="btn btn-primary btn-block theme-btn">Cancel</a>
                        </div>
                    </div>
                </div>
                <div class="hidden-xs">
                    <hr class="mar-top-sm mar-bottom-sm">
                    <p class="text-uppercase"><b>Reservation Policy</b></p>
                    <small>
            No in/out (One Entry/Exit) No Partial Refunds for Unused Time<br>
            To cancel for a full refund click the CANCEL BUTTON in the email or on this page ANYTIME PRIOR TO THE ARRIVAL TIME indicated on the reservation.<br>
            If you encounter any issues you can email us at customerservice@iconparking.com<br>
            No refunds will be issued for unused reservations not canceled prior to the arrival time.<br>
            Oversized vehicle surcharge (from <span sourceFont>$</span>10 to <span sourceFont>$</span>15) will be collected at the location if the vehicle does not meet the size requirements, unless paid for at time of reservation.
            </small>
                    <div class="clearfix mar-top-md">
                        <p class="text-primary">Thank you for choosing Icon Parking.</p>
                        <p>Questions? Contact our customer service team Monday-Friday, 9am-5pm<br>
                            <a [href]="phoneNumber">(877) 727-5464</a> or at <a [href]="mailId">customerservice@iconparking.com</a>
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-xs-12 col-sm-3 col-md-3 col-lg-3">
                <p class="visible-xs mar-top-sm"></p>
                <div class="well mar-bottom-0 pad-top-sm">
                    <h3 class="text-primary mar-top-0">Facility details</h3>
                    <p class="pad-top-xs"><b><p>{{ reservationDetails?.facility?.facility_name_modified }}</p></b></p>
                    <p>
                        <fa name="phone"></fa> {{ reservationDetails?.facility?.phone_number }}</p>
                    <p><b><small>Between:</small></b> {{ reservationDetails?.facility?.between_streets }}</p>
                    <p><b><small>Entrance:</small></b> {{ reservationDetails?.facility?.entrance_location }}</p>
                    <a target="_blank" href="https://maps.google.com?q={{reservationDetails?.facility?.geolocations?.latitude}},{{reservationDetails?.facility?.geolocations?.longitude}}" class="reserve-direction-link btn-link">get directions &nbsp;&nbsp; <fa name="chevron-right"></fa></a>
                    <p class="mar-top-xs" *ngIf="garageWorkingHours?.length == 0">
                        <i class="fa fa-calendar fa-2x text-primary"></i>
                        <!-- <fa name="calendar"  class="ismall text-primary" size="2x" style="vertical-align: sub;"></fa> -->&nbsp; 7 days a week
                    </p>
                    <p class="" *ngIf="garageWorkingHours?.length == 0">
                        <i class="fa fa-clock-o fa-2x text-primary"></i>
                        <!-- <fa name="clock-o" class=" ismall text-primary" size="2x" style="vertical-align: sub;"></fa> -->&nbsp; 24 hours a day
                    </p>
                    <div class="TimeActive table-responsive" *ngIf="garageWorkingHours?.length > 0">
                            <h4>Hours of Operation</h4>
                            <table class="table table-condensed ">
                              <tr *ngFor="let wh of garageWorkingHours;">
                                <td>{{wh.day}}</td>
                                <td>{{wh.formatted_open_time}}</td>
                                <td>{{wh.formatted_close_time}} {{showtimefornxtornot(wh.close_time)}}</td>
                              </tr>
                            </table>
                    </div>
                </div>
                <div class="well pad-0 webSection-3">
                    <div class="clearfix slideShow">
                        <div id="carousel-id" class="carousel slide" data-ride="carousel">

                            <div class="carousel-inner">
                                <!-- <div class="item">
                    <img src="/assets/img/park-garage-2.jpg" class="full-width">
                  </div>
                  <div class="item">
                    
                    <img src="/assets/img/park-garage.jpg" class="full-width">
                  </div> -->
                                <div class="item active">
                                    <img [src]="park_garage_2" class="full-width" alt="price_tag">
                                </div>
                            </div>
                            <!-- <a class="left carousel-control" href="#carousel-id" data-slide="prev"><fa name="angle-left"></fa></a>
                <a class="right carousel-control" href="#carousel-id" data-slide="next"><fa name="angle-right"></fa></a> -->
                        </div>
                    </div>
                    <div class="googleMap" id="map-wrapper">
                        <img [src]="garage_mapIcon" class="img-responsive" alt="price_tag">
                    </div>
                </div>
                <div class="visible-xs">
                    <p class="text-uppercase"><b>Reservation Policy</b></p>
                    <small>
            No in/out (One Entry/Exit) No Partial Refunds for Unused Time<br>
            To cancel for a full refund click the CANCEL BUTTON in the email or on this page ANYTIME PRIOR TO THE ARRIVAL TIME indicated on the reservation.<br>
            If you encounter any issues you can email us at customerservice@iconparking.com<br>
            No refunds will be issued for unused reservations not canceled prior to the arrival time.<br>
            Oversized vehicle surcharge (from <span sourceFont>$</span>10 to <span sourceFont>$</span>15) will be collected at the location if the vehicle does not meet the size requirements, unless paid for at time of reservation.
            </small>
                    <div class="clearfix mar-top-md">
                        <p class="text-primary">Thank you for choosing Icon Parking.</p>
                        <p>Questions? Contact our customer service team Monday-Friday, 9am-5pm<br>
                            <a [href]="phoneNumber">(877) 727-5464</a> or at <a [href]="mailId">customerservice@iconparking.com</a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-saver-load [imageUrl]="imageCode" [isValidReservation]="isValidReservation" [showPopup]="showDialog" *ngIf="showDialog" (popEvent)="receiveShowDialog($event)"></app-saver-load>
<app-footer *ngIf="!forLoggedIn"></app-footer>