<app-header [showTies]="false"></app-header>

<div class="hidden-xs searchFilter ">
	<app-search-filters></app-search-filters>
</div>

<div class="searchFilter hidden-lg hidden-md hidden-sm">
<app-mobile-search-filter-nav [showTies]="false" class="homePageToast" >
      </app-mobile-search-filter-nav>
</div>

<div class="intro-wrapper">
	<div class="intro">
		<app-loyality-badge [containerClass]="homePageBadge" [showTies]="true"></app-loyality-badge>
		<div class="intro-heading noselect">PARKING IS JUST THE START<sup>TM</sup></div>
		<div class="search-container">
				<form action="" method="POST" role="form">
					<div class="form-group">
						<div class="row">
							<div class=" col-xs-12 col-sm-4 col-md-4 parking-scope"   >
								<div class="btn-group btn-group-justified" data-toggle="buttons">
									<label (click)="settype('daily')" class="btn btn-primary dailymonthlybuttons theme-btn active" name="monthlytype">
										<input   type="radio" class="radio" name="options" id="option1" autocomplete="off" checked> daily
									</label>
									<label  (click)="settype('monthly')" class="btn btn-primary dailymonthlybuttons theme-btn" name="monthlytype">
										<input type="radio" class="radio" name="options" id="option2" autocomplete="off"> monthly
									</label>
								</div>
								<div class="mar-bottom-xs clearfix"></div>
							</div>
							<div class="col-xs-12 col-sm-8 col-md-8 ">
								<div class="parking-search">
								<div class="mainSearch">
									<app-google-autosuggest></app-google-autosuggest>
								</div>
							</div>
							</div>
							<div class="clearfix find-near visible-xs col-xs-12 text-right">
								<a (click)="findNearMe()" href="javascript:;" id="findnearme" class="btn text-white">find near me <img [src]="locationTrack" width="22" height="22" alt="gps"></a>
								<!-- <span class="text-uppercase">find near me<a href="javascript:;" id="findnearme" class="btn"><img src="assets/img/location-track.svg" width="22" height="22" alt="gps"></a></span> -->
							</div>
						</div>
					</div>
				</form>
			</div>


		<!-- <div class="search-container">
			<form action="" method="POST" role="form">
				<div class="form-group">
					<div class=" parking-search">
							<div class="mainSearch">
								<app-google-autosuggest></app-google-autosuggest>
							</div>
					</div>
						<div class="clearfix find-near visible-xs text-right">
							<a (click)="findNearMe()" href="javascript:;" id="findnearme" class="btn text-white">find near me <img src="assets/img/location-track.svg" width="22" height="22" alt="gps"></a>
						</div>
					</div>
			</form>
		</div> -->
	</div>
	<div class="intro-wrapper-footer">
		<div class="container">
			<div class="row">
				<div class="col-xs-4 col-sm-4 col-md-3 col-lg-3 quick-park">
					<!-- <img [src]="qp_logo" class="img-responsive pull-left" alt="QuickPark"> -->
					<p class="text-uppercase">Quik park is now part of the Icon Family.</p>
				</div>
				<div class="col-xs-4 col-sm-4 col-md-4 col-md-offset-1 col-lg-4 col-lg-offset-1 text-center">
					<img [src]="scroller" alt="image" height="62px" class="hidden-xs">
					<div class="scroll-downs hidden" data-toggle="tooltip" title="Scroll Down">
						<div class="hidden-xs hidden-sm mousey">
							<div class="scroller-border">
								<div class="scroller"></div>
							</div>
						</div>
						<!-- <img src="assets/img/hand-scroll.svg" class="visible-xs visible-sm img-responsive" alt="Image"> -->
					</div>
				</div>
			</div>
		</div>
	</div>
	<!-- <rewardpopup></rewardpopup> -->


</div>
<!-- <winter-popup winterPopupWrapper></winter-popup> -->
<loyaltysection></loyaltysection>


<!-- cta section for emails  -->
<section class="email-section" parallax [config]="{axis: Y, speed: -0.3, initialValue:0}">
	<div class="container text-center">
		<h1 class="section-title mar-bottom-sm mar-top-0">Let’s take this to the next level</h1>
		<h4 class="text-uppercase text-white">Don’t miss out on news, promotions, and special offers.</h4>
		<div class="input-group">
			<input type="text" class="form-control text-white" id="addsubscription" placeholder="Enter your email address">
			<span class="input-group-btn">
				<button type="submit" class="btn btn-primary theme-btn text-white" (click)="subscription()">Subscribe</button>
			</span>
		</div>
	</div>
</section>
<!-- Monthly Section -->


<section class="monthly-section">
	<div class="AnimateMonthly animatedParent">
		<img [src]="personIcon" alt="image" class="imgPerson animated carAnimation delay-250 fadeInRightShort ">
		<img [src]="carIcon" alt="image" class="imgCar hidden-sm hidden-xs  animated carAnimation delay-250 fadeInRightShort ">

	</div>
	<div class="container">
		<div class="row">
			<div class="col-xs-9 col-sm-9 col-md-10">
				<h1 class="section-title mar-bottom-sm mar-top-0">Let the meters go hungry.</h1>
				<h4 class="text-uppercase">Park monthly – Your car deserves better than street parking.</h4>
				<ul class="list-unstyled iconic-list">
					<li>
						<img [src]="arrowsIcon" alt="image"> <span class="lead">Unlimited in/out privileges at your home garage, whenever you want</span>
					</li>
					<!-- <li>
						<img [src]="map_markers" alt="image"> <span class="lead">50% off on daily parking at all 200+ Icon locations</span>
					</li> -->
					<li>
						<img [src]="vehicleIcon" alt="image"> <span class="lead">Online and text message vehicle request service</span>
					</li>
					<li>
						<img [src]="accountIcon" alt="image"> <span class="lead">Online account management</span>
					</li>
					<!-- <li>
						<img [src]="cardIcon" alt="image"> <span class="lead">Membership in our Preferred Customer Program <a routerLink="/preferred">(more info)</a></span>
					</li> -->
				</ul>
				<div class="hidden-xs">
					<p class="mar-bottom-sm">
						Contact us to get started now and we can help you figure out the best parking deal for your needs and budget.
					</p>
					<a routerLink="/monthly-parking" class="btn btn-primary theme-btn">Read More</a><p class="visible-xs"></p><a href="JavaScript:;" (click) = "searchLocation('monthly')" class="btn btn-primary theme-btn mar-left-xs">Buy Online</a>
				</div>
			</div>
			<div class="visible-xs col-xs-12 text-center">
				<p class="text-left mar-bottom-sm">
					Contact us to get started now and we can help you figure out the best parking deal for your needs and budget.
				</p>
				<div class="row">
					<div class="col-xs-offset-1 col-xs-10">
						<a routerLink="/monthly-parking" class="btn btn-primary theme-btn btn-block">Read More</a> <p class="visible-xs"></p>
						<a href="JavaScript:;"  (click) = "searchLocation('monthly')" class="btn btn-primary theme-btn btn-block">Buy Online</a>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>
<div class="clearfix">
<app-b2b></app-b2b>	
</div>

<!-- app section  -->
<section class="app-section">
	<div class="container">
		<div class="row">
			<div class=" col-sm-6 col-md-6 hidden-xs img-container">
				<div class="imgWrap animatedParent">
					<div class="imgAnimate animatedParent">
					<img [src]="screen_1" alt="Image" class="animated mobileAnimation  delay-1000 screen1 fadeInUpShort">
					<img [src]="screen_2" alt="Image" class="animated mobileAnimation delay-500 screen2 fadeInUpShort">
					<img [src]="screen_3" alt="Image" class="animated  mobileAnimation screen3 fadeInUpShort">
				</div>
				</div>
			</div>
			<div class="col-xs-10 col-sm-6 col-md-6 text-container">
				<h1 class="mar-top-0 section-title mar-bottom-sm">
			You Find It.<br class="visible-xs"> You Book It.<br class="visible-xs"> We Park It.
				</h1>
				<p class="text-uppercase ">
					<b>
					<span class="hidden-xs">Enter your phone number below and we will send you a link to download Icon GO.</span>
					<span class="visible-xs">Download our app to your phone</span>
				</b>
				</p>
				<ul class="list-unstyled iconic-list">
					<li>
						<img [src]="dollar_icon_big" alt="image"> <span class="lead">Unique coupons and promotions</span>
					</li>
					<li>
						<img [src]="discount_icon" alt="image"> <span class="lead">Direct booking discounts</span>
					</li>
				</ul>
				<div class="input-group hidden-xs">
					<input type="text" class="form-control" id="sendapp" placeholder="Enter your phone number">
					<span class="input-group-btn">
						<button type="submit" class="btn btn-primary theme-btn" (click)="sendApp()">Send App</button>
					</span>
				</div>
				<div class="visible-xs" [class.hide]="iosDevice">
					<a [href]="appStoreUrl"><img [src]="AppStore" alt="Image"></a><br/>
				</div>
				<div class="visible-xs" [class.hide]="!iosDevice">
					<a [href]="googlePlayUrl"><img [src]="googleplayIcon" alt="Image"></a><br/>
				</div>
				<br>
				<p>
					<a routerLink="/app" class="readMore">Read More <fa name="chevron-right"></fa></a>
				</p>
			</div>

			
		</div>
		<div class="imgWrapMobile animatedParent visible-xs">
				
					<img [src]="screen_1" alt="Image" class="animated mobileAnimation  delay-1000 screen1 fadeInUpShort">
					<img [src]="screen_2" alt="Image" class="animated mobileAnimation delay-500 screen2 fadeInUpShort">
					<img [src]="screen_3" alt="Image" class="animated  mobileAnimation screen3 fadeInUpShort">
				
			</div>
	</div>
</section>

<section class="stats-section text-uppercase text-white text-center" id="animate" parallax [config]="{axis: Y, speed: -0.8, initialValue:0}">
	<div class="container">
		<div class="row">
			<div class="col-xs-12 col-sm-4 col-md-4">
				<h1><span class="count counter">200</span>+</h1>
				<h4>Locations</h4>
			</div>
			<p class="visible-xs col-xs-12 mar-bottom-sm"></p>
			<div class="col-xs-12 col-sm-4 col-md-4">
				<h1><span class="count counter">40,000</span>+</h1>
				<h4>Cars Parked Daily</h4>
			</div>
			<p class="visible-xs col-xs-12 mar-bottom-sm"></p>
			<div class="col-xs-12 col-sm-4 col-md-4">
				<h1><span class="count counter">50</span>+</h1>
				<h4>Years in business</h4>
			</div>
		</div>
	</div>
</section>

<a #baseballmodelbtn (click)="baseballPopup.show()" class="hide" >openModel</a>
<div bsModal #baseballPopup="bs-modal" class="modal fade" tabindex="-1" role="dialog" [config]="{backdrop: 'static'}" aria-labelledby="mySmallModalLabel"
  aria-hidden="true">
  <div class="modal-dialog baseballPopup modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close pull-right" aria-label="Close" (click)="baseballPopuphide()">
				<img [src]="crossSvg" alt="image" width="16">
		</button>
		<button style="display: none;" type="button" #hideBaseballPopup class="close" aria-label="Close hide" (click)="baseballPopup.hide()"></button>
      </div>
      <div class="modal-body">
			<app-lazy-load [isLoaded]="isLoaded" [sectionName]></app-lazy-load>
			<div *ngIf="isLoaded" >
			<a *ngIf="baseballContent" (click)="redirctToBaseball()" style="cursor:pointer">
				<img class="img-responsive" [src]="baseballContent" alt="bassball" />
			</a> 
		<a *ngIf="baseballModel?.post_content"  style="cursor:pointer">
			<div style="padding: 20px;" class="mar-sm-top baseballModelcontent" [innerHTML]="baseballModel?.post_content">
			</div></a>
		</div>		          
			</div>
    </div>
  </div>
</div>
<app-footer></app-footer>
<!-- <app-footer></app-footer> -->