<app-lazy-load [isLoaded]="isLoaded" [sectionName]></app-lazy-load>
<div *ngIf="isLoaded" class="row">
  <div class="col-md-12">

    <div class="top-re-icons clearfix">
      <ol *ngIf="isReferal" class="breadcrumb pad-top-0 pad-bottom-0 pad-left-0">
        <li class="active">
          <a routerLink="/my-account/icon-rewards" class="btn btn-link pad-left-0">
            <i class="fa fa-angle-left" style="font-size: 20px; margin-top: -5px;"></i> &nbsp; &nbsp; icon Rewards</a>
        </li>
      </ol>
      <ul *ngIf="isReferal" class="top-icon-rewards pull-right">
        <li>
          <img [src]="icon_rewards_logo_wt" alt="icon">
        </li>
      </ul>
    </div>
    <!-- <h4 *ngIf="!isReferal" class="text-uppercase mar-bottom-xs">ADD MY CREDIT CARD (OPTIONAL)</h4> -->
    <h2 *ngIf="isReferal" class="text-uppercase mar-top-0 text-primary">{{pageTitle}}</h2>
    <!-- <div class="well">
      <table>
        <tr>
          <td valign="top" class="pad-right-xs"><img [src]="price_tag_icon" alt="icon"></td>
          <td class="pad-left-xs">Enroll the credit card you plan to use for parking in your Icon Rewards profile, and
            you will automatically earn credits even when you park without a reservation.</td>
          <td class="pad-left-xs "><a (click)="enrolledCardInfo(ECardInfo);" href="javascript:void(0)" style="vertical-align:middle;"><img
                [src]="helpIcon" alt="icon"></a></td>
        </tr>
      </table>
    </div> -->
    <div style="display:none" id="ErrorContainer"></div>
  </div>
</div>
<form *ngIf="isLoaded" #sCard="ngForm" id="sCard" (ngSubmit)="sCard.valid && sCard.submitted && saveCard(card, sCard)">
  <!-- <div class="clearfix"></div> -->
  <!-- <div class="row"> -->
    <!-- <div class="col-xs-12 col-sm-5 col-md-5">
      <div class="" [ngClass]="{ 'has-error' : sCard.submitted && !card_no.valid}">
        <label class="control-label" for="card">&nbsp;</label>
        <input class="form-control" [textMask]="{mask: cardMask}" (keyup)="checkCard(card_no)" (keypress)="restrictNumeric($event)"
          [(ngModel)]="card.card_no" name="card_no" #card_no="ngModel" required type="text" placeholder="Card number"
          id="standardCardNumber">
        <div *ngIf="sCard.submitted && !card_no.valid" class="help-block">Card number is required</div>
      </div>
    </div> -->
    <!-- <div class="clearfix"></div>
    <div class="col-xs-12 col-sm-4 col-md-4">
      <div class="" [ngClass]="{ 'has-error' : sCard.submitted && !exp.valid}">
        <label class="control-label" for="card">&nbsp;</label>
        <input class="form-control" type="text" autocomplete="off" [textMask]="{mask: dateMask}" [(ngModel)]="card.exp"
          (keyup)="setToexpirationField(exp)" name="exp" #exp="ngModel" required placeholder="Expiration date" id="standardCardExpiration">
        <input class="form-control" type="hidden" autocomplete="off" [(ngModel)]="card.exp1" name="exp1" #exp1="ngModel"
          required placeholder="expiration date" id="standardCardExpiration1">
        <div *ngIf="sCard.submitted && !exp.valid" class="help-block">Card expiration is required</div>
      </div>
    </div> -->
    <!-- <div class="clearfix"></div>
    <div class="col-xs-6 col-sm-2">
      <div class="" [ngClass]="{ 'has-error' : sCard.submitted && !security_code.valid}">
        <label class="control-label" for="card">&nbsp;</label>
        <input class="form-control" maxlength="4" [(ngModel)]="card.security_code" name="security_code" #security_code="ngModel"
          required type="text" placeholder="Security code" id="standardCardCvv" />
        <div *ngIf="sCard.submitted && !security_code.valid" class="help-block">Security code is required</div>
      </div>
    </div> -->
    <!-- <div class=" col-xs-6 col-sm-2">
      <div class="" [ngClass]="{ 'has-error' : sCard.submitted && !zip_code.valid}">
        <label class="control-label" for="card">&nbsp;</label>
        <input class="form-control" [(ngModel)]="card.zip_code" minlength="5" maxlength="6" name="zip_code" #zip_code="ngModel"
          required type="text" placeholder="Zip code" id="standardCardZip" />
        <div *ngIf="sCard.submitted && !zip_code.valid" class="help-block">Zip code is required</div>
      </div>
    </div> -->
  <!-- </div> -->
  <div  class="row">
  <!-- <div class="mar-top-sm col-sm-12">
      <div class="checkbox">
        <label class="enroll-terms-lbl">
            <input type="checkbox" value="1" id="addCardIconReservation"   #addCardIcon="ngModel" [(ngModel)]="card.addCardIcon"
            name="AddCardIcon">&nbsp;&nbsp;Save card for future reservations</label>
      </div>
  </div> -->
  <div *ngIf="showInvitation && !isInvAssosiated" class="mar-top-sm col-xs-12 col-sm-4 col-md-4">
    <label class="control-label" for="promocode">Invitation Code (optional)</label>
    <input class="form-control" maxlength="8" id="promocode" placeholder="Invitation code (optional)" name="promocode" type="text">
  </div>
</div>
  <div *ngIf="isReferal" class="row">
    
    <div class="col-md-12">
      <div class="form-group mar-top-sm btn-center-xs">
        <button type="submit" [disabled]="submtDisabled" class="btn btn-primary btn-fill">{{btnText}}</button>
        <span class="mar-left-xs"></span>
        <a *ngIf="!isEdit" class="btn btn-primary theme-btn" (click)="goBack()">{{btnTextCancel}}</a>
        <a *ngIf="isEdit" class="btn btn-primary theme-btn" (click)="removeCard(cardDetails.id)">REMOVE CARD</a>
      </div>
    </div>
  </div>
</form>
<div *ngIf="!isReferal" class="mar-top-sm">
  <div class="form-group btn-enroll btn-center-xs">
    <button [disabled]="isBtnDisabled" class="btn btn-primary btn-fill" (click)="triggerProfileBtn();">ENROLL
      NOW</button>
    <i class="mar-left-xs"></i>
    <p>AND FILL OUT THE FORM BELOW TO RECEIVE A<span sourceFont> ${{rewarded_points | number}} ACCOUNT CREDIT</span>
    </p>
  </div>
</div>
<!-- <div *ngIf="isLoaded" class="well">
  <div class="row">
    <div class="col-xs-4 col-md-1 col-sm-2 pad-right-0"><img class="img-responsive" [src]="pcjImg" alt="icon"></div>
    <div class="col-md-11 col-xs-12 col-sm-10">
      <p class="pad-top-xs visible-xs"></p>
      <p>Our systems are PCI compliant, and use state of the art encryption and security to store your credit card
        information, because we understand how important it is to protect your data.</p>
    </div>
  </div>
</div> -->
<!---  #ECardInfo page    -->
<ng-template class="modal fade" #ECardInfo id="ECardInfo">
  <div class="modal-header">
    <button type="button" class="close pull-right" aria-label="Close" (click)="close()">
      <span style="font-size: 50px; font-weight: normal;" aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div>
      <h2 class="text-uppercase mar-top-0 mar-bottom-sm text-primary">ADD CARD TO ICON REWARDS</h2>
      <div class="mar-top-xs mar-bottom-xs">Enroll the credit card you plan to use for parking in your Icon Rewards
        profile, and you will earn credits even when you park without a reservation. When you pay with your enrolled
        credit, our system will identify you as an Icon Rewards member and deposit credits into your account after each
        eligible parking session. If you don't add a credit card, you will only earn credits when you book on our
        website or on the Icon GO app.</div>
    </div>
    <div class="form-group mar-top-sm mar-bottom-0 btn-center-xs">
      <button type="button" class="btn btn-primary" (click)="close()">GOT IT</button>
    </div>
  </div>
</ng-template>
<!---  #ECardInfo page    -->
