import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { StaticpagesService } from '../../services/staticpages.service';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { CustomCode } from '../../shared/CustomCode';
import { WebMetaService } from '../../shared/web-meta.service';

@Component({
  selector: 'app-new-gift-landing-page-ui',
  templateUrl: './new-gift-landing-page-ui.component.html',
  encapsulation: ViewEncapsulation.None,
  providers: [StaticpagesService, WebMetaService],
})
export class NewGiftLandingPageUiComponent implements OnInit {
  ogDescription:any;
  ogTitle:any;
  ogUrl:any;
  pageId:string = 'new-gift-12'; 
  cmsPageContent:any = {};
  sanatizeContent;
  isLoaded = false;
  baseurl = CustomCode.BASEURL;
  webdata:any;
  descriptionElement:any;
  path = window.location.pathname;
  metaTags: any;
  constructor( private staticPageObj: StaticpagesService, private sanitized: DomSanitizer, private router:Router, private webMetaServiceObj: WebMetaService ) {

   }


  ngOnInit() {
    this.staticPageObj.getCmsPageContent(this.pageId).subscribe(
      (data:any)=> {
        this.cmsPageContent = data.result[0];
        this.sanatizeContent = this.sanitized.bypassSecurityTrustHtml(this.cmsPageContent.post_content);
        this.isLoaded = true;
      }, error => console.log("e" + error.object),
      
    )

    this.metaTags = Array.from(document.getElementsByTagName('meta'));
  this.descriptionElement = this.getMetaElement('description');
  this.ogDescription = this.getMetaElement1('og:description');
  this.ogTitle = this.getMetaElement1('og:title');
  this.ogUrl = this.getMetaElement1('og:url');
  this.updatePageMeta();
  }

  getMetaElement(name){
    return this.metaTags.find((element) => { return element.getAttribute('name') === name; });
  }
  getMetaElement1(name){
    return this.metaTags.find((element) => { return element.getAttribute('property') === name; });
  }
  updatePageMeta() {
    this.webdata = this.webMetaServiceObj.getSlugMeta(this.path).then((data:any)=> {
      document.title = data['data']['meta']['title'];
      this.descriptionElement.setAttribute('content', data['data']['meta']['description']);
      this.ogTitle.setAttribute('content', data['data']['meta']['ogTitle']);
      this.ogDescription.setAttribute('content', data['data']['meta']['ogDescription']);
      this.ogUrl.setAttribute('content', window.location.href)
    });
  }

}