      <h2 class="text-primary text-uppercase">Reset email</h2>
							<br/>
							<div class="clearfix">
							</div>
							<div class="viewOne">
							
							<div id="addMonthlyVehicleContainer"  >
								<form class="p" name="form" role="form" id="floating-label-reset-email" (ngSubmit)="f.form.valid && resetEmail(formData, f)" (keydown)="keyDownFunction($event)" #f="ngForm"
								novalidate>
									<div class="row">
										<div class="col-xs-12 col-sm-12 col-md-8">
											<div class="row">
												<div class="col-sm-12">
													<div class="form-group" [ngClass]="{ 'has-error' : f.submitted && !email.valid }">
														<label class="control-label">New email</label>
														<input type="text" name="email" class="form-control" id="" [(ngModel)]="formData.email"  #email="ngModel" required>
													<div *ngIf="f.submitted && !email.valid" class="help-block">email is required</div>
													</div>
													
												</div>
											</div>
											<div class="row">
												<div class="col-sm-12">
													<div class="form-group" [ngClass]="{ 'has-error' : f.submitted && !email_confirmation.valid }">
														<label class="control-label">Re-enter new email</label>
														<input type="email" name="email_confirmation" class="form-control" id="" [(ngModel)]="formData.email_confirmation"  #email_confirmation="ngModel" required>
														<div *ngIf="f.submitted && !email_confirmation.valid" class="help-block">Email confirmation is required</div>
													</div>
													
												</div>
											</div>
										</div>
									</div>
									<div class="form-group">
										<button type="cancel" class="btn btn-primary theme-btn" (click)="cancelResetEmail()">Cancel</button>
										<button class="btn btn-fill btn-primary theme-btn">Reset Email</button>
									</div>
								</form>
							</div>
						</div>