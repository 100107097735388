import { Component, OnInit } from '@angular/core';

import { Router, ActivatedRoute, Params } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgForm } from '@angular/forms';
import { SearchpageserviceService } from '../../../services/searchpageservice.service';
import {GoogleAnalyticsEventsService} from "../../../shared/google-analytics-events.service";
import { DomSanitizer } from '@angular/platform-browser';
import { LogsService } from '../../../services/logs.service';
// declare var ga: Function;
//declare var kochava:any;
import * as $ from 'jquery';

@Component({
  selector: 'app-black-friday-coupon',
  templateUrl: './black-friday-coupon.component.html',
  styleUrls: ['./black-friday-coupon.component.scss'],
  providers:[SearchpageserviceService, LogsService]
})
export class blackFridayCouponComponent implements OnInit {

	respdata:any;
	printData:any;
	isLoaded;
	mailFormData: any = {};
	
	constructor(
		private searchPageObj: SearchpageserviceService,
		private router: Router,
		private toasterObj: ToastrService,
		public googleAnalyticsEventsService: GoogleAnalyticsEventsService,
		public sanitizer: DomSanitizer,
		private logsService: LogsService
  	) { }

	ngOnInit() {
	}

	sendCoupon(formData, formObj: NgForm) {
		/* commented for ga 16-6-2021 by seo team
  	    ga('send', 'event', { eventCategory: 'Black friday coupon email', eventAction: 'click'});
		*/
		const cId = 4285;
		//this.getVisitorCouponTracking({"couponId":cId,"type":this.EMAIL_COUPON,"to":formData.ucmail});
		this.searchPageObj.mailLandingCoupon(formData, cId)
		.subscribe(
			(data:any)=> {
				this.respdata = data;
				this.searchPageObj.getMobileCode().subscribe((data:any)=>{
					(<any>window).dataLayer.push(
						{
						'promoCode': 'CM18',
						'couponCode':data.data.code,
						'barCode':data.data.barcode,
						'event': 'Email Coupon',
						'location': data.data.facility.short_name,
						'landing page name':'Black friday coupon email'
						});
						
						/* #Start Add GTM logged in db */
						let logData = {
							'iq_code': null,
							'coupon_code':data.data.code,
							'bar_code':data.data.barcode,
							'event_type':0
						}

						// kochava.activity('email_coupon', {
						// 	coupon_id : cId
						//   });

						this.logsService.addLog(logData).subscribe( (result)=>{ }, (err) => { });
						/* #End GTM logged in db */
 				});
				if (this.respdata.status === 201) {
					this.router.navigate(['/thanks-email']);
				}
			},
			error => {
				const errorsList = error.errors;
				if (errorsList.message !== 'Validation Errors') {
					this.toasterObj.error(errorsList.message, 'Sorry!');
				} else {
					for (const key in errorsList.detail) {
						if (errorsList.detail.hasOwnProperty(key)) {
							if (typeof errorsList.detail === 'undefined') {
								this.toasterObj.error(errorsList.message, 'Sorry!');
							} else {
								this.toasterObj.error(errorsList.detail[key], 'Sorry!');
							}
						}
					}
				}
			}
		);
	}

	printCoupon() {
		/* commented for ga 16-6-2021 by seo team
		ga('send', 'event', { eventCategory: 'Black friday coupon print', eventAction: 'click'});
		*/	
		this.isLoaded = false;
		$('#invoicePrint').css('display', 'none');
		const cId = 4285;
		this.searchPageObj.printCoupon(cId).then(
			(couponPrint) => {
				const urlCreator = window.URL;
				const blobData = this.sanitizer.bypassSecurityTrustUrl(urlCreator.createObjectURL(couponPrint['_body']));
				this.printData = blobData['changingThisBreaksApplicationSecurity'];
				this.isLoaded = true;
				this.searchPageObj.getMobileCode().subscribe((data:any)=>{
					(<any>window).dataLayer.push(
						{
						'promoCode': 'CM18',
						'couponCode':data.data.code,
						'barCode':data.data.barcode,
						'event': 'Print Coupon',
						'location': data.data.facility.short_name,
						'landing page name':'Black friday coupon Print'
						});

						/* #Start Add GTM logged in db */
						let logData = {
							'iq_code': null,
							'coupon_code':data.data.code,
							'bar_code':data.data.barcode,
							'event_type':0
						}

						this.logsService.addLog(logData).subscribe( (result)=>{ }, (err) => { });
						/* #End GTM logged in db */
 				});
				$('#invoicePrint').css('display', 'block');
			},
			(error) => {
				this.isLoaded = true;
			}
		);
		//this.getVisitorCouponTracking({"couponId":facId,"type":this.PRINT_COUPON});
	}

	printMedia() {
		$('#coupon-modal .modal-dialog').removeClass('modal-sm');
		$('div#printable').html($('div#printableContent').html()).show();
		$('div#content-Wrapper').hide();
		window.print();


		$('div#printable').html('').hide();
		$('div#content-Wrapper').show();
		this.router.navigate(['/thanks-print']);	
	}

	resetForm(formObj: NgForm) {
    formObj.resetForm();
  }
}