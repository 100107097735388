<div role="tabpanel" class="tab-pane fade active in" id="delete">
  <h4 class="text-primary text-uppercase mar-top-0">Unlink account</h4>
  <p>
    <b>This will remove account #{{ accountNumber}} from your online profile, and disable Autopay if it is currently enabled.</b>
  </p>
  <p>
    After an account has been removed, you can add it back to this profile or to another online profile by using the "Add Monthly Account" button on your My Account homepage.
  </p>
  <button type="button" class="btn btn-primary btn-fill mar-top-sm" (click)="deleteAccount($event)">Unlink Account
  </button>
</div>