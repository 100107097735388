import { Component, OnInit,ViewEncapsulation } from '@angular/core';
import { StaticpagesService } from './../../services/staticpages.service'
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router'

@Component({
  selector: 'app-moovit-landing',
  templateUrl: './moovit-landing.component.html',
  styleUrls: ['./moovit-landing.component.scss'],
  providers: [StaticpagesService],
  encapsulation: ViewEncapsulation.None
})
export class MoovitLandingComponent implements OnInit {
  bgImage = '../../assets/img/landing-pages/moovit/bgheader.png';
  bottomoverlay = '../../assets/img/landing-pages/moovit/bottomoverlay.png';
  
  isLoadeds = false;
  pageSlug = 'moovit';
  pageData:any = [];
  promocodeList = [];
  showList = false
  constructor( private staticPageService: StaticpagesService, private toastr : ToastrService, private router: Router ) { }

  ngOnInit() {
    // setTimeout(() => {
    //   this.isLoadeds = true
    // }, 2000);

    // this.staticPageService.getCMSpageContentList(this.pageId).subscribe( (data:any)=> {
    //         this.pageData = data.result[0]
    //         this.isLoadeds = true;
    //         this.promocodeList = this.pageData.moovit_2380;
    //         if(this.promocodeList.length>0){ this.showList = true }
    //         console.log(this.pageData);
    // }),
    // (error)=> {
    //   this.isLoadeds = true;
    //   console.log('Error'+ JSON.stringify(error) );
    //   this.toastr.error(JSON.stringify(error), 'Error!')

    // }

    this.staticPageService.getCMSpageContentWithFields(this.pageSlug).subscribe( (data:any)=>{
     // console.log(data)
      this.pageData = data[0];
        if(this.pageData['moovit-offers'] != null && this.pageData['moovit-offers'] != false){
          this.promocodeList = this.pageData['moovit-offers'];
          if(this.promocodeList.length>0){ this.showList = true }
        }
      this.isLoadeds = true;
     
    } ),(error)=> {
      this.isLoadeds = true;
      //console.log('Error'+ JSON.stringify(error) );
      this.toastr.error(JSON.stringify(error), 'Error!')
    }
  }
  

  redirctToSearch(promocode, type){
    if(promocode){
      // promocode  = "testMonthly"
      promocode.trim();
      type.trim();
      if(type == 'monthly'){
        let url = 'specials-promo/'+promocode+'/apply'
        this.router.navigate([url], {queryParams: {coupontype:'monthly'}});
       }else {
        let url = 'specials-promo/'+promocode+'/apply/';
        this.router.navigate([url]);
       }
    }
   // 
   
   
  }

}
