<div class="campaignPage pad-top-lg gameday">
	<div class="container"> 
		<div class="row">
			<div class="col-xs-12 col-sm-12 col-md-9 col-lg-8 mar-lr-auto">
				<div class="text-center">
					<h2 class="text-uppercase text-primary mar-top-0 customesize">
						Score a great gameday parking deal.
					</h2>
					<h3 class="text-uppercase text-white mar-top-sm">
						Use code <span class="text-primary">{{ promoCode }}</span> for <span sourceFont>$</span>10 off your reservation
					</h3>
					<div class="buttonWrap mar-top-sm">
						<button type="button" class="btn btn-fill btn-primary text-white" (click)="savePromocode('madison-square')" >Save code</button><span class="mar-left-xs"></span><button type="button" class="btn btn-fill btn-primary text-white" (click)="savePromocode('madison-square')">book now</button>
					</div>
					<div class="clearfix mar-top-lg">
						<small class="text-white">Promotion ends on 3/31/2018. Limit one use per individual. Promotion cannot be combined with other sales and/or discounts.</small>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<div class="pad-bottom-md pad-top-md text-center text-uppercase">
	<div class="container">
		<div class="row">
			<div class="col-xs-12 col-sm-12 col-md-9 col-lg-8 mar-lr-auto">
				<a href="javascript:;" (click)="savePromocode('madison-square')">
					<img class="full-width hidden-xs" [src]="gameday_parking_desktop" alt="image">
					<img class="full-width visible-xs" [src]="gameday_parking_mobile" alt="image">
				</a>
			</div>
		</div>
	</div>
</div>