<app-header></app-header>
<app-lazy-load [isLoaded]="isLoadeds" [sectionName]="'content'"></app-lazy-load>
<ng-template [ngIf]="isLoadeds" >
<div class="page-header orangeBar specialPage moovit" [ngStyle]="{'background-image': 'url(' +   bgImage + ')'}">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-sm-8">
          <div class="section" [innerHtml]="pageData?.content?.rendered" ></div>
          <!-- <div class="section">
              <div class="row align-items-center">
                <div class="col-12 col-sm-6">
                  <img class="img-responsive" height="80px" src="../../../assets/img/landing-pages/moovit/logo__moovit.png">
                </div>
                <div class=" col-12 col-sm-6">
                <div class="font-weight-blold title text-white text-right" >
                  Exclusive Savings<br>
                  for Moovit Community
                </div>
                </div>
              </div>
          </div> -->
        </div>
      </div>
    </div>
</div>
<div class="pageWrapperLadning orangeBar mooveIt">
  <div class="container mt-5  ">
    <div *ngIf="pageData?.details_section" class="clearfix text-center leadText" [innerHtml]="pageData?.details_section">
      <!-- <p>Just about anywhere you go in Manhattan, you are not far from a safe and <br class="hidden-xs"> convenient Icon parking facility. Icon and Moovit, have recently partnered to<br class="hidden-xs"> offer daily parking discounts for the Moovit community.</p> -->
    </div>
  </div>
</div>

  <div class="pageWrapperLadning orangeBar mooveIt">
    <!-- <div class="container mt-5  ">
      <div class="clearfix text-center leadText">
        <p>Just about anywhere you go in Manhattan, you are not far from a safe and <br class="hidden-xs"> convenient Icon parking facility. Icon and Moovit, have recently partnered to<br class="hidden-xs"> offer daily parking discounts for the Moovit community.</p>
      </div>
      </div> -->

      <div *ngIf="showList" class="container blockSections mt-5 pb-5 ">
        <div class="row justify-content-center">
            <div class="col-12 col-md-12 col-lg-8">

        
      <div class="row">
        <ng-container *ngFor="let offer of promocodeList">
          <div class="col-sm-12 col-md-6 mb-5">
            <div class=" grayBox p-4 text-center">
              <h4 *ngIf="offer?.offer_title" [innerHtml]="offer.offer_title"></h4>
                <div *ngIf="offer?.promocode" class="cwDsgn  my-3">
                  <span >PROMO CODE:</span> {{offer.promocode}}
                </div>
                <div class="clearfix"></div>
                <button class="btn btn-primary  btn-block  btn-fill " (click)="redirctToSearch(offer.promocode,offer.promocode_type)" >{{(offer?.button_label)? offer.button_label: 'Book Now'}}</button>
                <p class="terms-of-coupon" *ngIf="offer?.offer_terms" [innerHtml]="offer.offer_terms">
                  <!-- Online reservations only. <br class="hidden-xs">
                May not be combined with other discounts and/or offers. -->
                </p>
            </div>
  
          </div>
        </ng-container>
        
        
        <!-- <div class="col-sm-12 col-md-6 col-lg-4">
          <div class=" grayBox p-4 text-center">
            <h4>10% OFF<br>
              monthly PARKING</h4>
              <div class="cwDsgn  my-3">
                <span>Promo Code:</span> MOOVIT10
              </div>
              <div class="clearfix"></div>
              <button class="btn btn-primary btn-block  btn-fill ">Book Now</button>
              <p>
                Online reservations only. <br class="hidden-xs">
May not be combined with other discounts and/or offers.
              </p>
          </div>

        </div> -->
     
      </div>
        </div>    </div>
        </div>

    

      <div *ngIf="pageData?.about_description" class="footerMoovit text-center text-white" [ngStyle]="{'background-image': 'url(' +   bottomoverlay + ')'}" [innerHtml]="pageData?.about_description" >
        <!-- <h2>
          About Moovit
        </h2>
        <p>
          Moovit is committed to empowering cities, businesses, and people everywhere to enjoy <br class="hidden-xs"> a better quality of life by removing the limitations and barriers of urban mobility.

        </p>
        <button class="btn btn-light hollowBtn ">Learn More</button> -->

      </div>
    </div>
</ng-template>
<app-footer></app-footer>