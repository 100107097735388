<div class="main faqPage" >
		<!-- <div class="container">
			<div [innerHTML]="staticPageContent.content"></div>
		</div> -->

		<!--  main screens -->
		<div class="container questionDesk" [class.hidden]="isOnQuestionsShow" >
			<div class="pad-top-md hidden-xs"></div><div class="pad-top-sm visible-xs"></div>
			<h1 class="mar-top-0 mar-bottom-sm text-primary text-uppercase">How can we help you?</h1>
			<div class="row">
				<div class="col-xs-12 col-sm-12 col-md-6 animate  " [ngClass]="{ 'col-lg-8 col-md-8' : srcBarActive , 'col-lg-6 col-md-6': !srcBarActive }" >
						<div class="input-group searchBarPageSaperate">
							<input id="searchText" type="text" class="form-control" (keyup)="searchFaq()" placeholder="What are you looking for?" (focus)="focusFunction()" (focusout)="focusOutFunction()">
						     <span class="input-group-btn">
						        <button id="buttonSearch" class="btn btn-link" type="button" (click)="searchFaq()"><img [src]="search_icon" alt="image" [class.hidden]="srcBarActive" > </button>

						          <button id="buttonremove" class="btn btn-link btnCross" [class.hidden]="!srcBarActive" type="button" (click)="clearfaq()">
									<fa name="times" ></fa>
						          </button>
						      </span>
						    </div>


					<!-- <div class="searchBarPageSaperate">
						<input id="searchText" type="text" class="form-control" (keyup.enter)="searchFaq()" placeholder="Where are you headed?">
						<button (click)="searchFaq()" id="" type="button" class=""><img src="../assets/img/search-icon.svg"></button>
					</div> -->
				</div>
				<div class="col-lg-3 col-md-3 col-xs-12 col-sm-12 pull-right" *ngIf="isShow" >
					<p  *showItDevice="['mobile','tablet']">&nbsp;</p>
					<p class="text-right"><b class="text-primary">No Result Found</b></p>
				</div>
			</div>
			<!-- clearfix -->
			<div class="clearfix pad-top-md hidden-xs"></div><div class="pad-top-sm visible-xs"></div>
		</div>
		<app-lazy-load [isLoaded]="isLoaded" [sectionName]="'FAQ'"></app-lazy-load>
		<div *ngIf="hide" class="mainView container" [class.hidden]="isOnQuestions">
			<h2 class="text-uppercase mar-top-0 mar-bottom-xs Titlepage">Frequently asked questions</h2>
			<div class="clearfix pad-top-xs"></div>
			<div class="flex-container">
				<div flexboxWrapper>
					<div flexChild *ngFor="let record of questionAnswer; index as i;">
						<div  class="well">
							<h2 class="text-uppercase text-primary TitleQuestions" id="questionId">{{record.name}}</h2>
							<ul class="questionsLink ">
								<li class="question" *ngFor="let questioncontent of record.content| slice:0:2; index as j;" >
									<a href="javascript:;" (click)="moreLinkQuestion(record.name,questioncontent.seq)">
										{{questioncontent.q}} 
										<i class="fa pull-right" [ngClass]="{'fa-angle-right': 1}"></i>
									</a>
								</li>
								<li *ngIf="record.content.length>2" >
									<a class="moreLink scrollTop" (click)="moreLinkCategory(record.name)">See all {{record.content.length}} questions<fa name="angle-right" class="pull-right"></fa>
									</a>
								</li>
							</ul>
							
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- for open questions -->
		<div  class="container openQuestions" [class.hidden]="openQuestionsWithCategory">
			<!-- <ol class="breadcrumb pad-top-xs pad-bottom-xs pad-left-0">
				<li class="active"><a id="backs" (click)="homeBack()" class="btn btn-link pad-left-0"><fa name="angle-left"></fa> &nbsp; Back</a></li>
			</ol> -->
			<div class="clearfix"></div>
			<div class="questiongroup">
			<div  class="row">
				<div class="col-xs-12 col-sm-8 col-md-8 col-lg-9">
					<div class="mar-bottom-sm" *ngFor="let record of questionAnswer; index as i;">
						<h2 class="text-uppercase text-primary TitleQuestions mar-top-0" id="questionId">{{record.name}}</h2>
						<accordion class="questionsLink">
							<accordion-group (click)="moreLinkShowsQuestion(record.name,questioncontent.seq)" #group *ngFor="let questioncontent of record.content; index as j;" >
								<div accordion-heading >
									{{questioncontent.q}} 
									<i class="fa pull-right" [ngClass]="{'fa-angle-down': group?.isOpen, 'fa-angle-right': !group?.isOpen}"></i>
								</div>
								<div [innerHTML]="questioncontent.a | safeHtml"></div>
							</accordion-group>
						</accordion>
					</div>
				</div>
				<div class="category-wrap col-xs-12 col-sm-4 col-md-4 col-lg-3">
					<div class="pad-top-xs pad-bottom-xs visible-xs"></div>
					<div class="well">
						<h2 class="text-uppercase text-primary TitleQuestions">Categories</h2>
						<ul class="nav nav-pills nav-stacked sideNav">
							<li *ngFor="let record of questionAnswerCategory; index as i;" (click)="moreLinkCategory(record.name)" ><a href="javascript:;">{{record.name}}</a></li>
						</ul>
					</div>
				</div>
			</div>
			<div class="clearfix mar-top-sm"></div>
		</div>
		</div>
		<div  class="container openQuestions" [class.hidden]="openQuestions">
			<ol *ngIf="showBack" class="breadcrumb pad-top-xs pad-bottom-xs pad-left-0">
				<li class="active"><a id="backs" (click)="homeBack()" class="btn btn-link pad-left-0"><fa name="angle-left"></fa> &nbsp; Back</a></li>
			</ol>
			<div class="clearfix"></div>
			<div class="questiongroup hide" *ngFor="let record of questionAnswer; index as i;" id="hide{{record.name.split(' ').join('')}}">
			<div  class="row">
				<div class="col-xs-12 col-sm-8 col-md-8 col-lg-9" >
					<h2 class="text-uppercase text-primary TitleQuestions mar-top-0" id="questionId">{{record.name}}</h2>
					<accordion class="questionsLink">
							<accordion-group (click)="moreLinkShowsQuestion(record.name,questioncontent.seq)" #group *ngFor="let questioncontent of record.content; index as j;" >
								<div accordion-heading id="{{record.name.split(' ').join('')}}{{questioncontent.seq}}">
									{{questioncontent.q}} 
									<i class="fa pull-right" [ngClass]="{'fa-angle-down': group?.isOpen, 'fa-angle-right': !group?.isOpen}"></i>
								</div>
								<div [innerHTML]="questioncontent.a | safeHtml"></div>
							</accordion-group>
						</accordion>
				</div>
				<div class="category-wrap col-xs-12 col-sm-4 col-md-4 col-lg-3">
					<div class="pad-top-xs pad-bottom-xs visible-xs"></div>
					<div class="well">
						<h2 class="text-uppercase text-primary TitleQuestions">Categories</h2>
						<ul class="nav nav-pills nav-stacked sideNav">
							<li *ngFor="let record of questionAnswerCategory; index as i;" (click)="moreLinkCategory(record.name)" ><a href="javascript:;">{{record.name}}</a></li>
						</ul>
					</div>
				</div>
			</div>
			<div class="clearfix mar-top-sm"></div>
		</div>
	</div>
	<div class="container mar-bottom-md">
			<p class="lead">Can’t find your answer? Contact us and we will help you resolve your issue.</p>
			<div class="activeBtns">
				<a [href]="phoneNumber" class="btn btn-link btn-lg">
					<img width="58" height="68" [src]="phoneIcon" alt="phoneIcon"> <span>1 (877) 727-5464<br>
					<small>9am to 7pm</small></span> <fa name="angle-right"></fa>
				</a>
				<a [href]="mailId" class="btn btn-link btn-lg">
					<img width="68" height="68" [src]="mailIcon" alt="mailIcon"> <span> Email us </span> <fa name="angle-right"></fa>
				</a>
			</div>
		</div>