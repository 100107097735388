import { Component, OnInit,Pipe, PipeTransform, Input,Output,EventEmitter, ElementRef, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router, NavigationEnd, ActivatedRoute,Params } from '@angular/router';
import { FilterHelper } from '../../classes/filter-helper';
import { CustomCode } from '../../shared/CustomCode';
import * as $ from 'jquery';
// declare var google: any;
import { UserserviceService } from '../../services/userservice.service';
import { WebMetaService } from '../../shared/web-meta.service';
import {GoogleAnalyticsEventsService} from "../../shared/google-analytics-events.service";
// declare var ga: Function;
declare var google: any;
//declare var kochava:any;
import { FacebookService, InitParams, LoginResponse,LoginOptions } from 'ngx-facebook';
import { environment } from '../../../environments/environment';
// Import RxJs required methods
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { DomSanitizer } from '@angular/platform-browser';
import { Meta, MetaDefinition } from '@angular/platform-browser';
import { StaticpagesService } from './../../services/staticpages.service';

@Pipe({ name: 'safeHtmlHeader'})

export class SafeHtmlHeaderPipe implements PipeTransform  {
  constructor(public sanitizer: DomSanitizer) {}

  transform(value) {
    return this.sanitizer.bypassSecurityTrustHtml(value);
  }
}
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  providers: [UserserviceService,WebMetaService],
  host: {
        '(window:scroll)': 'updateHeader($event)'
    }
})
export class HeaderComponent implements OnInit {
   @Output() findData = new EventEmitter<boolean>();
   ismonthlydailypage="";
   
   
   showbannerStripListUpdated:any;
   partnerBar:any;


    isScrolled = false;
    isCollapsed = false;
    isOpen = false;
    currPos: Number = 0;
    startPos: Number = 0;
    changePos: Number = 300;
    isLogin:boolean=false;
    loyalit_member=0
    tierData: any;
    isTierLoaded: boolean = false;
    isTierExist: boolean = false;
    isTierMsg: string;
    orangeBar: boolean = false;
    barHeight:any;
    loyaltyBarHeight:any;
    showStrips:boolean = true;
    ViewAfterInitData = false;

    htmlStr: string = '<strong>The Tortoise</strong> &amp; the Hare';
    iconAppLogo:string = 'assets/img/iconAppLogo.svg';
    iconAppLogoNew:string = 'assets/img/app-icon-new.png';
    icon_logo:string = 'assets/img/icon_logo.svg';
    menuImg:string='assets/img/menu.png';
    info_mentinence:string = 'assets/img/info_mentinence.png';
    bars:string ='assets/img/new-homepage/menu-bar-icon.svg';
    googleUrl = 'https://play.google.com/store/apps/details?id=com.iconparking&hl=en';
    applePppUrl = 'https://itunes.apple.com/us/app/icon-parking/id394377820?mt=8';
    appStoreUrl = 'https://itunes.apple.com/us/app/icon-parking/id394377820?mt=8';
    googlePlayUrl = 'https://play.google.com/store/apps/details?id=com.iconparking&hl=en';
  
    instagramUrl = 'https://www.instagram.com/iconparkingnyc/?hl=en';
    facebookUrl = 'https://www.facebook.com/IconParkingNYC';
    twitterUrl = 'https://twitter.com/IconParkingNYC';
    linkedinUrl = 'https://www.linkedin.com/company/icon-parking/';
    customerPhone = 'tel:+1877727-5464';
    customerPhone2 = 'tel:+1(877)727-5464';
    phone = 'tel:+1(877)727-5464'
  
    newsTips = '/cms/news/';
    POSuserLat=0;
    POSuserLong=0;
    settypedata:any='daily';
    startDuration:any;
    endDuration:any;
    facilityType = CustomCode.defaultFacilityType;
    showif=true;
    is_admin_user:boolean=false
    adminUrl=CustomCode.adminUrlLogin+'/redirectto/'+localStorage.getItem('access_token')+'/'+localStorage.getItem('refresh_token')+'/0'

// newMenus = CustomCode.staticMenus();
  menuUser = [
      new CustomCode ('My Account', 'account'),
      // new CustomCode ('Icon Rewards', 'icon-rewards'),
      new CustomCode ('My Monthly Parking', 'monthly-parking'),
      //new CustomCode ('My Offers', 'coupons'),
      new CustomCode ('My Reservations', 'reservations'),
      new CustomCode ('Vehicle Request', 'vehicle-request'),
      new CustomCode ('Brand Partnerships', 'brand-partnership'),
      new CustomCode ('My Promo Codes', 'promo-codes'),
     ];
  urlRedirect()
  {
    window.location.href=this.adminUrl;
  }
  updateHeader(evt) {
        this.currPos = (window.pageYOffset || evt.target.scrollTop) - (evt.target.clientTop || 0);
        if (this.currPos >= this.changePos ) {
            this.isScrolled = true;
        } else {
            this.isScrolled = false;
        }
    }

  contactTelNo = CustomCode.contactTelephoneNumber;
  contactInfoMail = CustomCode.contactInfoMail;

  facilityRadius = CustomCode.facilityDefaultRadius;

  currDate = CustomCode.getDateForMapSearch();
  f;

iOS :any;
Android : any;
  checksearchPage = false;
  formData: any = {};
  private respdata;
  private parseData;
  successFailElement = 'success-failed';
  public currentRoute = '';
  loggedUserName;
  isMobile = true;
  metaTags: any;
  path: any;
  descriptionElement:any;
  ogDescription:any;
  ogTitle:any;
  ogUrl:any;
  webdata:any;
  redirectFromAdmin=0;
  covidCareUrl = CustomCode.cmsUrl+'cms/daily-updates/';
  auto_suggest=1;
  redirectUserName='';
  isLoyaltyMember:boolean = false;
  SearchPage = true;  
  SearchPageView = false;
  appPromotion = false;
  mantinanceMsgContent:any;
  showMantinancepopup= false;
  mantinanceMsgContentData:any;
  SearchPageLook = false;
  mantinanceMsg= false;
  @Input() showTies:boolean = true;
  is_reward_url=0
  showonhomepage=1;
  isSearchPage:boolean = false;
  isPartnerPage:boolean = false;
  isAdminPage:boolean = false;
  isfcPage:boolean = false;
  isValineTineLanding = false;
  startTime:any;
  endTime:any;
  showOrangeBar = false;
  pageSlugStrip ='strip-banners';
  bannerStripContent :any;
  showbannerStripList = false;
  NavbarInnerDivHeight = null
  @ViewChild('NavbarInner',{ static: true }) NavbarInnerDiv : ElementRef;
  constructor(private staticPageService: StaticpagesService, private fb: FacebookService,private routerObj: Router,
    private activatedRouteObj: ActivatedRoute,
    private metaService: Meta,
    private userServiceObj: UserserviceService , private webMetaServiceObj: WebMetaService,public googleAnalyticsEventsService: GoogleAnalyticsEventsService) {
   
    if(this.routerObj.url.indexOf('rewards') > -1)
    {
      this.is_reward_url=1
    }else{
      sessionStorage.removeItem('menuActive');
      this.metaService.removeTag("name='robots'");
    }
    
    
}
myFunction() {
  $('.navbar,.footerWidgetHide,.sidebarWidget').remove();
  $('.page-header').css('margin-top','0px');
   $('.menuToggle,.listBtn, .listToggleFilter').hide();
   $('.searchFilter').css('margin-top','0px');
   $('.SearchPanelMobile').css('top','15px');

}
  ngOnInit() {
    if(this.routerObj.url.indexOf('buy-monthly') > -1){
      this.ismonthlydailypage="monthly";
    }
    // if(this.routerObj.url.indexOf('monthly-parking') > -1 && this.routerObj.url.indexOf('monthly') > -1){
    //   this.ismonthlydailypage="monthly";
    // }
    
    // if(this.routerObj.url.indexOf('monthly') > -1 && this.routerObj.url.indexOf('web') > -1){
    //   this.ismonthlydailypage="monthly";
    // }
    
    if(this.routerObj.url.indexOf('reservations') > -1 ){
      this.ismonthlydailypage="daily";
    }
    if(this.routerObj.url.indexOf('daily-confirmation') > -1 ){
      this.ismonthlydailypage="daily";
    }
    if(this.routerObj.url.indexOf('monthly-parking') > -1 ){
      this.ismonthlydailypage="daily";
    }
    if(this.routerObj.url.indexOf('monthly-parking-confirmation') > -1){
      this.ismonthlydailypage="monthly";
    }
    if(this.routerObj.url.indexOf('facility') > -1 ){
      this.ismonthlydailypage="daily";
    }
    if(this.routerObj.url.indexOf('search') > -1 && (this.routerObj.url.indexOf('web') > -1 || this.routerObj.url.indexOf('app') > -1)){
      this.ismonthlydailypage="daily";
    }
    if(this.routerObj.url.indexOf('payment') > -1){
      this.ismonthlydailypage="daily";
    }
    if(this.routerObj.url.indexOf('account-subscription-renewal') > -1){
      this.ismonthlydailypage="monthly";
    }
    if(this.routerObj.url.indexOf('monthly-renewal-confirmation') > -1){
      this.ismonthlydailypage="monthly";
    }
    if(this.routerObj.url.indexOf('guest-reservation') > -1){
      this.ismonthlydailypage="daily";
    }
    
    let check =  setInterval(()=>{
      if( this.NavbarInnerDiv != undefined ){
        
        //console.log(this.NavbarInnerDiv.nativeElement.offsetHeight);
        this.NavbarInnerDivHeight = this.NavbarInnerDiv.nativeElement.offsetHeight;
        if(this.NavbarInnerDivHeight > 0){
          localStorage.setItem('NavbarInnerDivHeight',this.NavbarInnerDivHeight.toString() );
        }
        
        clearInterval(check);
      }
    },0)
    
    setInterval(()=>{
      if(this.NavbarInnerDiv != undefined && this.NavbarInnerDivHeight != this.NavbarInnerDiv.nativeElement.offsetHeight ){
        this.NavbarInnerDivHeight = this.NavbarInnerDiv.nativeElement.offsetHeight;
        if(this.NavbarInnerDivHeight != null){
          localStorage.setItem('NavbarInnerDivHeight',this.NavbarInnerDivHeight.toString() );
        }
      }
    },0)
   //console.log('NavbarInnerDivHeight',this.NavbarInnerDivHeight);
    if(this.routerObj.url.indexOf('monthly-parking')>-1)
    {
      this.showif=false;
    }
    this.activatedRouteObj.params.subscribe((params: Params) => {
// console.log(params);
// console.log('here -----------')
if(params['template_slug'] === "valentines-day"){
  this.isValineTineLanding = true;
}else {
  this.isValineTineLanding = false;
}
    });
    setInterval(()=>{
      //alert();
      if(localStorage.getItem('centerClick'))
      {
        this.findNearMe()
        localStorage.removeItem('centerClick')
      }
      //alert(this.router.url);
    },1000)
    
    setInterval(()=>{
      if(sessionStorage.getItem('removeOrangeBar')){
        this.showOrangeBar = false;
      }else {
        this.showOrangeBar = true;
      }
    },0)
    if(this.routerObj.url.indexOf('search') > -1 || this.routerObj.url.indexOf('partners') > -1 ){
      this.isSearchPage = true;
    } else{
     
    }
    if(this.routerObj.url.indexOf('facility') > -1 ){
      this.isfcPage = true;
    } else{
      this.isfcPage = false;
    }

    // if(this.routerObj.url.indexOf('partners') > -1){
    //     this.isPartnerPage= true;
    //     // $(".negiMap").addClass('partnerPage');
    //     // $('aside.contentBar').addClass('partnerPageSidebar');
    // }else{

    // }
   

    localStorage.removeItem('couponSave');
    if(!localStorage.getItem('specialParking')){
      localStorage.removeItem('sortBy');
      localStorage.removeItem('showNeighbordood');
      localStorage.removeItem('activeNeighbourhood');
    }
    
    localStorage.removeItem('showNeigborMarker');
    localStorage.removeItem('showMonthlyDeal');
    localStorage.removeItem('isHone');
    localStorage.removeItem('enableDealsForNb');
    
    let times_runhide_check = setInterval( ()=> {
      if(localStorage.getItem('userid'))
      {
        //alert(2)
        $('#loginPopup').hide();
        $('#guestuser').hide();
        $('#loggeduser').show();
        this.loggedUserName = CustomCode.add3Dots(CustomCode.getloggedInUserDetails(), 22);
        if(localStorage.getItem('is_loyalty_active') && localStorage.getItem('is_loyalty_active')=='1')
        {
          this.loyalit_member=1
        }else{
          this.loyalit_member=0;
        }
        clearInterval(times_runhide_check);
      }
    }, 100);

  

    let getUrl = window.location;

    if(getUrl.pathname.indexOf('partners') >-1)
    {
      this.showonhomepage=0;
    }else{
      this.showonhomepage=1;
    }

    if (getUrl.pathname.indexOf('/search/') > -1 || ( !(getUrl.pathname.indexOf('monthly-parking-confirmation/') > -1) && getUrl.pathname.indexOf('monthly-parking') > -1) || getUrl.pathname.indexOf('partners') >-1 ) {
      this.SearchPageLook = true;
    }
    else{
      this.SearchPageLook=false;
    }
    if( getUrl.pathname.indexOf('restaurant-partnerships')==1)
    { 
      this.showonhomepage=1;
      this.SearchPageLook = false;
    }
    if (getUrl.pathname.indexOf('/my-account/') > -1) {
      this.isAdminPage = true;
    }
    else{
      this.isAdminPage=false;
    }


    if(localStorage.getItem('preffred_location'))
    {
     localStorage.removeItem('preffred_location')
    }
    if(!localStorage.getItem('preffred_location_bysearch') && localStorage.getItem('preffred_location_bysearch_data'))
    {
      localStorage.removeItem('preffred_location_type');
    }
    $('body').removeClass('showChat');
    let times_runhide= setInterval(function () {
      $('body').removeClass('showChat');
      
    }, 100);
    setTimeout(function () {
      clearInterval(times_runhide);
    }, 3000);

    if(localStorage.getItem('is_admin'))
    {
      this.is_admin_user=true
    }else{
      this.is_admin_user=false
    }
    if(window.self!==window.top)
    {
     this.myFunction()      //setTimeout(this.myFunction, 2000);
    }
    if(localStorage.getItem('masquerade_set'))
    {
      this.redirectFromAdmin=1
      this.redirectUserName=localStorage.getItem('email')
    }
    this.iOS = false;
    this.Android = false;
    this.metaTags = Array.from(document.getElementsByTagName('meta'));
    this.path = window.location.pathname;
    this.descriptionElement = this.getMetaElement('description');
    this.ogDescription = this.getMetaElement1('og:description');
    this.ogTitle = this.getMetaElement1('og:title');
    this.ogUrl = this.getMetaElement1('og:url');

    if (navigator.userAgent.match(/(iPod|iPhone|iPad)/i)) {
      this.Android = true;
    } else if (navigator.userAgent.match(/Android/i)){
      this.iOS = true;
    } else {
      this.iOS = false;
      this.Android = false;
    }

    const checkMobileOrWeb = CustomCode.searchMode();
    this.currentRoute = this.routerObj.url;
    if(this.currentRoute=='/widget-form')
    {
        this.auto_suggest=0
    }
  
    if (this.currentRoute == '/' || this.currentRoute == '/404'  || this.currentRoute.indexOf('offer') > -1 || this.currentRoute.indexOf('adgroup') > -1 || this.currentRoute.indexOf('adroll') > -1 || this.currentRoute.indexOf('adwords') > -1 || this.currentRoute.indexOf('/?') > -1   ) {
      this.SearchPage= false; 
      $('.showBlock').removeClass('hide');
    }
     else {
      this.SearchPage = true; 
        $('.navbar-custom').addClass('navbg');
        $('.showBlock').addClass('hide');
        
         $('.iconContact').remove();
    }
    if(localStorage.getItem('is_loyalty_active') && localStorage.getItem('is_loyalty_active')=='1')
    {
      if (localStorage.getItem('is_loyalty') === "1") {
        this.isLoyaltyMember=true;
        this.userServiceObj.GetTierInfo().subscribe(
          (res:any) => {
            if (res.data.tier_exists) {
              this.tierData = res.data.tier;
              this.isTierMsg = res.data.tier_description;
              this.isTierLoaded = true;
              this.isTierExist = true;
            }
          }
        )
      }
      this.loyalit_member=1
    }else{
      this.loyalit_member=0

    }
       if (this.currentRoute == '/404' || this.currentRoute.indexOf('offer') > -1 || this.currentRoute.indexOf('adgroup') > -1 ) {
         this.appPromotion = false;
          $('.iconContact').remove();
        } 
        // if (this.currentRoute.indexOf('search') > -1) {
        //  this.SearchPage= true;
        // } else{
        //   this.SearchPage= false;
       // }
        if (this.currentRoute == '/') {
          this.appPromotion = true;
         }else{
          this.appPromotion = false;
         }
    
 

    if(localStorage.getItem('is_loyalty')==="1"){ this.isLoyaltyMember = true; }
   
    this.loggedUserName = CustomCode.add3Dots(CustomCode.getloggedInUserDetails(), 22);
    CustomCode.displayLoginUser();
    this.updatePageMeta();

    this.getMantinanceMsg();
    let times_runhides = setInterval( ()=> {
      if(localStorage.getItem('userid'))
      {
        //alert(2)
        this.loggedUserName = CustomCode.add3Dots(CustomCode.getloggedInUserDetails(), 22);
        if(localStorage.getItem('is_loyalty_active') && localStorage.getItem('is_loyalty_active')=='1')
        {
          this.loyalit_member=1
        }else{
          this.loyalit_member=0;
        }
        clearInterval(times_runhides);
      }
    }, 100);
    
   // kochava.page();  // kochava page trach event
   this.fetchBannerStripContent();
  //  if(sessionStorage.getItem('closeBannerStrip')){}else {
  //   this.fetchBannerStripContent();
    
  //   this.fetchBannerStripContentUpdated();
  // }
  this.fetchUserStatusForTracking();
   }

  updateTier(tier){
    this.isTierLoaded = tier;
  }

   redirectToUser()
   {
     if(localStorage.getItem('is_admin')!='false' && localStorage.getItem('is_admin') )
     {
      let access_token = localStorage.getItem('access_token');
      let refresh_token = localStorage.getItem('refresh_token');
      window.location.href=CustomCode.adminUrlLogin+'/redirectto/'+access_token+'/'+refresh_token+'/0';
      //  window.location.href=this.adminUrl;
     }else{
      this.routerObj.navigate(['/my-account/account']);
     }
   }
   redirectToAdmin()
   {
     let masque=CustomCode.adminUrlLogin+'/redirectto/'+localStorage.getItem('access_token1')+'/'+localStorage.getItem('refresh_token1')+'/1';
     CustomCode.userLogout();
     window.location.href=masque;
   }
  getMetaElement(name){
    return this.metaTags.find((element) => { return element.getAttribute('name') === name; });
  }
  getMetaElement1(name){
    return this.metaTags.find((element) => { return element.getAttribute('property') === name; });
  }
  
   resetLoginForm(f) {
     
     // const validationFields = ['email', 'password'];
     // for (let i = 0; i < validationFields.length; i++) {
     //    $('input[name="' + validationFields[i] + '"]').parents('div.form-group').removeClass('has-error');
     //    $('input[name="' + validationFields[i] + '"]').parents('div.form-group').find('div.help-block').addClass('hidden');
     // }
  }


  toggleData() {
    //localStorage.removeItem('searchLocation');
    $('#mySidenav').toggleClass('open');
     $('.sidenavBackDrop').toggleClass('open');
      $('body').addClass('sidebar-noscroll');
  }
dismisThis(){
  $('body').removeClass('noscroll');
  $('.appPromotion').remove();
}

  logOut() {
    var closeBannerStrip_flag=false;
    let currentUrl= window.location.href;
    let fbl=0
    let gml=0
    if(localStorage.getItem('loginwithfb'))
    {
      fbl=1
    }
    if(localStorage.getItem('loginwithgm'))
    {
      gml=1
    }
    
   CustomCode.userLogout();
   
   CustomCode.displayLoginUser();
     if(fbl)
      {
       
        this.fb.logout().then((res: LoginResponse)=> console.log('Logged out!'));
      }
    
    if (this.routerObj.url.indexOf('payment') > -1 || this.routerObj.url.indexOf('buy-monthly') > -1) {
      if(gml)
      {
        window .open("https://www.google.com/accounts/Logout?continue=https://appengine.google.com/_ah/logout?continue=https://gmail.com",'_blank')
        //document.location.href = CustomCode.BASEURL;
        document.location.href = "https://www.google.com/accounts/Logout?continue=https://appengine.google.com/_ah/logout?continue="+CustomCode.BASEURL;
      }else{
          window.location.reload();
      }
     
    } else {

      if(gml)
      {
        window .open("https://www.google.com/accounts/Logout?continue=https://appengine.google.com/_ah/logout?continue=https://gmail.com",'_blank')
        //document.location.href = "https://www.google.com/accounts/Logout?continue=https://appengine.google.com/_ah/logout?continue="+CustomCode.BASEURL;
         document.location.href = CustomCode.SIGNOUTURL;
      }else{
        window.location.href=CustomCode.SIGNOUTURL;
        //window.location.reload();
      }
      
    }

    // this.routerObj.navigate(['/']);
  }

  userSignIn(formValues, formObj: NgForm) {
    this.userServiceObj.loginUser(formValues)
      .subscribe(
      (data:any)=> {
        this.parseData = data;
        if (this.parseData.status === 201) {
          formObj.resetForm();
          CustomCode.markLoginUser(this.parseData.data);
          const userLat = CustomCode.timeSquareCoords.lat;
          const userLong = CustomCode.timeSquareCoords.long;
          const userLocation = CustomCode.timeSquareCoords.defaultLocation;
          $('button.close').trigger('click');
          this.routerObj.navigate(['/search', userLat, userLong, this.currDate,
            this.facilityRadius, userLocation, CustomCode.defaultFacilityType]);
        }
      },
      error => {
        if (error.errors.message !== 'Validation Errors') {
          const failSuccessElem = document.getElementById(this.successFailElement);
          failSuccessElem.style.color = 'red';
          failSuccessElem.innerHTML = error.errors.message;
          setTimeout(function () {
            failSuccessElem.innerHTML = '';
          }, 5000);
        }
      }
     );
  }

  setRecentSearch(userLat, userLong, currDate, facilityRadius, locationUser, searchType, searchMode) {
      localStorage.setItem('recent_search', 'true');
      localStorage.setItem('searchType', searchType);
      localStorage.setItem('searchMode', searchMode);
      localStorage.setItem('userLat', userLat);
      localStorage.setItem('userLong', userLong);
      localStorage.setItem('currDate', currDate);
      localStorage.setItem('facilityRadius', facilityRadius);
      localStorage.setItem('locationUser', locationUser);
  }

  updatePageMeta() {
    this.webdata = this.webMetaServiceObj.getSlugMeta(this.path).then((data:any)=> {
      document.title = data['data']['meta']['title'];
      this.descriptionElement.setAttribute('content', data['data']['meta']['description']);
      console.log('ogTitle',data['data']['meta']['ogTitle']);
      this.ogTitle.setAttribute('content', data['data']['meta']['ogTitle']);
      this.ogDescription.setAttribute('content', data['data']['meta']['ogDescription']);
      this.ogUrl.setAttribute('content', window.location.href)
    });
  }
  submitTopMenuCallEvent() {
        this.googleAnalyticsEventsService.emitEvent("app link", "click", "", 10);
    }
    callcustomer(){
      (function(a,e,c,f,g,h,b,d){var k={ak:"876742851",cl:"BjciCOKo-nsQw5GIogM",autoreplace:"(877) 727-5464"};a[c]=a[c]||function(){(a[c].q=a[c].q||[]).push(arguments)};a[g]||(a[g]=k.ak);b=e.createElement(h);b.async=1;b.src="//www.gstatic.com/wcm/loader.js";d=e.getElementsByTagName(h)[0];d.parentNode.insertBefore(b,d);a[f]=function(b,d,e){a[c](2,b,k,d,null,new Date,e)};a[f]()})(window,document,"_googWcmImpl","_googWcmGet","_googWcmAk","script");
    }

     showMantinanceMsg(){
      this.mantinanceMsg = true;
    }
    hideMantinanceMsg(){
      this.mantinanceMsg = false;
      sessionStorage.setItem('checkMaintinance','1')
    }
    getMantinanceMsg(){
      this.userServiceObj.getMantinanceMsg().subscribe(
        data=>{
          this.mantinanceMsgContent = data;
           if(this.mantinanceMsgContent.success){
            this.showMantinancepopup = true;
             this.mantinanceMsgContentData = this.mantinanceMsgContent.data[0];
             if(sessionStorage.getItem('checkMaintinance')){
              this.mantinanceMsg = false;
             }else{
              this.mantinanceMsg = true;

             }
                
           }

        },
        (error) => { console.log('Error: ' +JSON.stringify(error)); }
      )
    }

  redirectToPage(){
    sessionStorage.setItem('menuActive','1');
    if(localStorage.getItem('is_loyalty')==="1"){ 
      this.isLoyaltyMember = true; 
      this.routerObj.navigate(['my-account/icon-rewards']);
    }else{
      this.routerObj.navigate(['rewards']);
    }
  }
  menuactiveFunction()
  {
    sessionStorage.setItem('menuActive','2');
  }
  isActive()
  {
    if(sessionStorage.getItem('menuActive')=='1')
    {
      return true;
    }else{
      return false;
    }
  }
  isActiveforSubMenu()
  {
    if(sessionStorage.getItem('menuActive')=='2')
    {
      return true;
    }else{
      return false;
    }
  }
  logoutmenu()
  {
    if(this.routerObj.url.indexOf('create-account') > -1)
    {
      return true;
    }else{
      return false;
    }
  }
  
  isActivetoinside()
  {
    if(this.routerObj.url.indexOf('my-account') > -1)
    {
      if(sessionStorage.getItem('menuActive')=='1')
      {
        return false;
      }else{
        return true;
      }
    }else{
      return false;
    }
  }
  removeHeader()
  {
    sessionStorage.removeItem('menuActive')
  }

  findNearMe() {
   // $('.test').trigger('click');
   /* commented for ga 16-6-2021 by seo team
    ga('send', 'event', { eventCategory: 'FIND PARKING', eventLabel:CustomCode.BASEURL, eventAction: 'click'});
  */
    const newYorkBounds = new google.maps.LatLngBounds(
        new google.maps.LatLng(40.518673, -74.266017),
        new google.maps.LatLng(40.861947, -73.785519),
        //new google.maps.LatLng(28.702680, 77.428900)
    );

     if (window.navigator.geolocation) {
        window.navigator.geolocation.getCurrentPosition(position => {
        let userCLat =position.coords.latitude;
        let userCLong =position.coords.longitude;
        $.get("https://maps.googleapis.com/maps/api/geocode/json?latlng="+userCLat+","+userCLong+"&key="+ CustomCode.GlobalGoogleMapAPIKey, ( data ) => {
        
        //alert(userCLong);
    //40.7623737/-73.97391189999996
     //userCLat = 40.7623737;
         //userCLong = -73.97391189999996;
    var myLatlng = new google.maps.LatLng(userCLat, userCLong);
    let exacTime = FilterHelper.defaultStartDurationUrlHomePageData(FilterHelper.changeNyTime());
    //alert(this.endDuration);
    //let endTimeData = FilterHelper.makeDurationDateObject(this.endDuration, this.endTime);
    //alert(endTimeData);
    //this.endTime = FilterHelper.getFormattedTime(this.endDuration);
    if(this.toTimestamp(this.startDuration)<this.toTimestamp(exacTime))
    {
      this.startDuration = FilterHelper.defaultStartDuration();
      this.startTime = FilterHelper.getFormattedTime(this.startDuration);
      localStorage.setItem('monthlyDate',this.startDuration)
      this.endDuration = FilterHelper.defaultEndDuration();
      this.endTime = FilterHelper.getFormattedTime(this.endDuration);
      let endTimeDataIfLess = FilterHelper.makeDurationDateObject(this.endDuration, this.endTime);
      //alert(endTimeDataIfLess)
      // if(this.toTimestamp(endTimeData)<this.toTimestamp(endTimeDataIfLess))
      // {
      //   //alert(2)
      //   //localStorage.setItem('endttimehome',CustomCode.getDateForMapSearchSlashFormatHome(this.endDuration,this.endTime));
      // }
      localStorage.setItem('endttimehome',CustomCode.getDateForMapSearchSlashFormatHome(this.endDuration,this.endTime));
      localStorage.setItem('startimehome',CustomCode.getDateForMapSearchSlashFormatHome(this.startDuration,this.startTime));
    }
    //let endTimeDataIfLess = FilterHelper.makeDurationDateObject(this.endDuration, this.endTime);
   
    let lat:any=40.7589
    let long:any =-73.9851
    let location:any ='Times Square'
    let startTime=FilterHelper.defaultStartDuration();
    let startTimes = FilterHelper.getFormattedTime(startTime);
    //alert()
    if(this.routerObj.url.indexOf('search')>-1)
    {
      localStorage.setItem('startimehome',CustomCode.getDateForMapSearchSlashFormatHomeForFindParking($('#startDateWithID').val()));
    }else{
      localStorage.setItem('startimehome',CustomCode.getDateForMapSearchSlashFormatHome(startTime,startTimes));
    }
    
    
    let stime =localStorage.getItem('startimehome');
    localStorage.removeItem('startimehome');
    if(localStorage.getItem('seachLat'))
    {
      lat =localStorage.getItem('seachLat')
      long =localStorage.getItem('seachLong')
      location =localStorage.getItem('seachLocation')
    }
    if (((data.results[0].formatted_address.indexOf("nY")>-1 || data.results[0].formatted_address.indexOf("Ny")>-1 || data.results[0].formatted_address.indexOf("NY")>-1 || data.results[0].formatted_address.indexOf("ny")>-1) && data.results[0].formatted_address.indexOf("USA")>-1) ||  data.results[0].formatted_address.indexOf("jjhjhjhjh")>-1)
    {
        this.POSuserLat=userCLat;
       this.POSuserLong=userCLong;
       //let url = '/search/'+userCLat+'/'+userCLong+'/'+stime+'/1/'+data.results[0].formatted_address+'/'+this.facilityType+'/'+CustomCode.searchMode()
        //alert(1)
        if(this.routerObj.url!='1')
        {
           //alert(3)
           if(this.routerObj.url.indexOf('monthly-parking')>-1)
           {
             
            
             //$('.test').trigger('click');
            // localStorage.setItem('currentLoc','1')
            this.locationIcon()
             
           }
          if(this.routerObj.url.indexOf('search')>-1)
          {
            this.locationIcon()
           
            //$('.test').trigger('click');
           // localStorage.setItem('currentLoc','1')
           localStorage.setItem('datafromlocation','1')
           
            
          }
          
          
          let typeMode=this.settypedata
          if(this.routerObj.url.indexOf('search')>-1)
          {
            if(this.routerObj.url.indexOf('daily')>-1)
            {
              typeMode='daily';
            }else{
              typeMode='monthly';
            }
          }
          if(this.routerObj.url.indexOf('monthly-parking')>-1)
        {
          if(this.routerObj.url.indexOf('daily')>-1)
          {
            typeMode='daily';
          }else{
            typeMode='monthly';
          }
        }
          let url = '/search/'+userCLat+'/'+userCLong+'/'+stime+'/1/'+data.results[0].formatted_address+'/'+typeMode+'/'+CustomCode.searchMode()
          let number=3
          if(this.routerObj.url.indexOf('/3/')>-1)
          {
              number=2
          }else{
              number =3;
          }
          localStorage.setItem('latNew',userCLat.toString())
          localStorage.setItem('longNew',userCLong.toString())
          localStorage.setItem('locationNew',data.results[0].formatted_address) 
           $('.selectAuto').val(data.results[0].formatted_address);
          //window.location.href=url
          console.log('if');
          
          let startTime=FilterHelper.defaultStartDuration();
          let startTimes = FilterHelper.getFormattedTime(startTime);
          stime =CustomCode.getDateForMapSearchSlashFormatHome(startTime,startTimes);
          this.routerObj.navigate(['/search', userCLat, userCLong, stime, number,  data.results[0].formatted_address, typeMode, CustomCode.searchMode()]);
          
        }
        
       // 
        
      }
     else{
      
      let userLat = CustomCode.timeSquareCoords.lat;
      let userLong = CustomCode.timeSquareCoords.long;
      const locationUser = CustomCode.timeSquareCoords.defaultLocation;
     // let url = '/search/'+userLat+'/'+userLong+'/'+stime+'/1/'+locationUser+'/'+this.settypedata+'/'+CustomCode.searchMode()
      //console.log(2)
     // alert(url);
      //alert(this.routerObj.url.replace('%20',' '));
      //alert(this.routerObj.url.replace('%20',' ')!=url)
      if(this.routerObj.url!='1')
      {
        //alert(5)
        if(this.routerObj.url.indexOf('monthly-parking')>-1)
          {
            
           
            //$('.test').trigger('click');
           // localStorage.setItem('currentLoc','1')
           this.locationIcon()
            
          }
        if(this.routerObj.url.indexOf('search')>-1)
        {
          this.locationIcon()
          //localStorage.setItem('datafromlocation','1')
         // $('.test').trigger('click');
        //  localStorage.setItem('currentLoc','1')
        
          
        }
        
        
        let typeMode=this.settypedata
        if(this.routerObj.url.indexOf('search')>-1)
        {
          if(this.routerObj.url.indexOf('daily')>-1)
          {
            typeMode='daily';
          }else{
            typeMode='monthly';
          }
        }
        if(this.routerObj.url.indexOf('monthly-parking')>-1)
        {
          if(this.routerObj.url.indexOf('daily')>-1)
          {
            typeMode='daily';
          }else{
            typeMode='monthly';
          }
        }
        let url = '/search/'+userLat+'/'+userLong+'/'+stime+'/1/'+locationUser+'/'+typeMode+'/'+CustomCode.searchMode()
        let number=3
        if(this.routerObj.url.indexOf('/3/')>-1)
        {
            number=2
        }else{
            number =3;
        }
        localStorage.setItem('latNew',userCLat.toString())
        localStorage.setItem('longNew',userCLong.toString())
        localStorage.setItem('locationNew',locationUser) 
         $('.selectAuto').val(locationUser);
         console.log('else');
         
        let startTime=FilterHelper.defaultStartDuration();
        let startTimes = FilterHelper.getFormattedTime(startTime);
        stime =CustomCode.getDateForMapSearchSlashFormatHome(startTime,startTimes);
        this.routerObj.navigate(['/search', userLat, userLong, stime, number, locationUser,  typeMode, CustomCode.searchMode()]);
        //window.location.href=url
        }
      
      // 
     }
    })
      }, this.blockLocationCallback.bind(this));
    } 
  
if(this.POSuserLat!=0 && this.POSuserLong!=0){
  var geocoder;
  geocoder = new google.maps.Geocoder();
  var latlng = new google.maps.LatLng(this.POSuserLat, this.POSuserLong);
  var georoute=this.routerObj;
  var add;
    geocoder.geocode(
      {'latLng': latlng}, 
      function(results, status,georoute) {
         add= results[0].formatted_address ;
        //alert("city name is: " + add);  
         $('#googleautosearch').val(add);     
      }
    );
    
}

  }
  toTimestamp(strDate){
    var datum = Date.parse(strDate);
    return datum/1000;
}

blockLocationCallback() {
  let exacTime = FilterHelper.defaultStartDurationUrlHomePageData(FilterHelper.changeNyTime());
  //alert(this.endDuration);
 // let endTimeData = FilterHelper.makeDurationDateObject(this.endDuration, this.endTime);
  //alert(endTimeData);
  //this.endTime = FilterHelper.getFormattedTime(this.endDuration);

  let startTime=FilterHelper.defaultStartDuration();
    let startTimes = FilterHelper.getFormattedTime(startTime);
    if(this.routerObj.url.indexOf('search')>-1)
    {
      localStorage.setItem('startimehome',CustomCode.getDateForMapSearchSlashFormatHomeForFindParking($('#startDateWithID').val()));
    }else{
      localStorage.setItem('startimehome',CustomCode.getDateForMapSearchSlashFormatHome(startTime,startTimes));
    }
    //localStorage.setItem('startimehome',CustomCode.getDateForMapSearchSlashFormatHomeForFindParking($('#startDateWithID').val()));
    let stime =localStorage.getItem('startimehome');

  if(this.toTimestamp(this.startDuration)<this.toTimestamp(exacTime))
  {
    this.startDuration = FilterHelper.defaultStartDuration();
    this.startTime = FilterHelper.getFormattedTime(this.startDuration);
    localStorage.setItem('monthlyDate',this.startDuration)
    this.endDuration = FilterHelper.defaultEndDuration();
    this.endTime = FilterHelper.getFormattedTime(this.endDuration);
    let endTimeDataIfLess = FilterHelper.makeDurationDateObject(this.endDuration, this.endTime);
    //alert(endTimeDataIfLess)
    // if(this.toTimestamp(endTimeData)<this.toTimestamp(endTimeDataIfLess))
    // {
    //   //alert(2)
    //   //localStorage.setItem('endttimehome',CustomCode.getDateForMapSearchSlashFormatHome(this.endDuration,this.endTime));
    // }
    localStorage.setItem('endttimehome',CustomCode.getDateForMapSearchSlashFormatHome(this.endDuration,this.endTime));
    localStorage.setItem('startimehome',CustomCode.getDateForMapSearchSlashFormatHome(this.startDuration,this.startTime));
  }
  let lat:any=40.7589
  let long:any =-73.9851
  let location:any ='Times Square'
   stime =localStorage.getItem('startimehome')
   localStorage.removeItem('startimehome');
  const getTimeCoords: any = JSON.parse(JSON.stringify(CustomCode.timeSquareCoords));
  const userLat = getTimeCoords.lat;
  const userLong = getTimeCoords.long;
  const defaultLoc = getTimeCoords.defaultLocation;
// let url = '/search/'+userLat+'/'+userLong+'/'+stime+'/1/'+defaultLoc+'/'+this.settypedata+'/'+CustomCode.searchMode()
  //alert(url)
  if(this.routerObj.url!='1')
    {
      if(this.routerObj.url.indexOf('monthly-parking')>-1)
          {
            
           
            //$('.test').trigger('click');
           // localStorage.setItem('currentLoc','1')
           this.locationIcon()
            
          }
      if(this.routerObj.url.indexOf('search')>-1)
          {
            this.locationIcon()
            //localStorage.setItem('datafromlocation','1')
            
          // // $('.test').trigger('click');
           // localStorage.setItem('currentLoc','1')
            
        }
        
        let typeMode=this.settypedata
        if(this.routerObj.url.indexOf('search')>-1)
        {
          if(this.routerObj.url.indexOf('daily')>-1)
          {
            typeMode='daily';
          }else{
            typeMode='monthly';
          }
        }
        if(this.routerObj.url.indexOf('monthly-parking')>-1)
        {
          if(this.routerObj.url.indexOf('daily')>-1)
          {
            typeMode='daily';
          }else{
            typeMode='monthly';
          }
        }
        let url = '/search/'+userLat+'/'+userLong+'/'+stime+'/1/'+defaultLoc+'/'+typeMode+'/'+CustomCode.searchMode()
        let number=3
        if(this.routerObj.url.indexOf('/3/')>-1)
        {
            number=2
        }else{
            number =3;
        }
        localStorage.setItem('latNew',userLat.toString())
        localStorage.setItem('longNew',userLong.toString())
        localStorage.setItem('locationNew',defaultLoc) 
         $('.selectAuto').val(defaultLoc);
        // window.location.href=url
        let startTime=FilterHelper.defaultStartDuration();
        let startTimes = FilterHelper.getFormattedTime(startTime);
        stime =CustomCode.getDateForMapSearchSlashFormatHome(startTime,startTimes);
        this.routerObj.navigate(['/search', userLat, userLong, stime, number, defaultLoc, typeMode, CustomCode.searchMode()]);
      }
  //this.routerObj.navigate(['/search', userLat, userLong, stime, 1, defaultLoc, this.settypedata, CustomCode.searchMode()]);
}

triggerEvent(label){
  if('MONTHLY PARKING SPECIALS'==label)
    {
      localStorage.setItem('sortBy','Neighborhood')
      localStorage.setItem('neighbourhoodActive','1')
      localStorage.setItem('showNeighbordood','1');
      localStorage.removeItem('resetSearch');
    }
    /* commented for ga 16-6-2021 by seo team
    if('SPECIALS & OFFERS'==label){
      ga('send', 'event', { eventCategory: label, eventLabel:'redirect to Icon Specials', eventAction: 'Hrefclick'});
    }else {
      ga('send', 'event', { eventCategory: label, eventLabel:CustomCode.BASEURL, eventAction: 'click'});
    }
    */
}

formonthly()
{
    this.startDuration = FilterHelper.defaultStartDuration();
    this.startTime = FilterHelper.getFormattedTime(this.startDuration);
    localStorage.setItem('monthlyDate',this.startDuration)
    this.endDuration = FilterHelper.defaultEndDuration();
    this.endTime = FilterHelper.getFormattedTime(this.endDuration);
    let endTimeDataIfLess = FilterHelper.makeDurationDateObject(this.endDuration, this.endTime);
    localStorage.setItem('endttimehome',CustomCode.getDateForMapSearchSlashFormatHome(this.endDuration,this.endTime));
    localStorage.setItem('startimehome',CustomCode.getDateForMapSearchSlashFormatHome(this.startDuration,this.startTime));
    let stime =localStorage.getItem('startimehome');
    localStorage.setItem('specialParking','1');
    localStorage.setItem('showNeighbordood','1');
    localStorage.setItem('sortBy', "Neighborhood");
    localStorage.setItem('fromhomelanding','1');
    this.routerObj.navigate(['/monthly-parking']);
    //this.routerObj.navigate(['/search', '40.7782667', '-73.9698797', stime, 1,  'central-park','monthly', CustomCode.searchMode()]);
}
locationIcon(){
  if(0) 
  {
  }else{
    localStorage.removeItem('showNeighbordood');
  
  localStorage.removeItem('neighbourhoodActive')
  localStorage.removeItem('showNeighbordood');

 
  }
  localStorage.removeItem('sortBy')
  sessionStorage.removeItem('tabview');
  localStorage.setItem('resetSearch','1')
  
}  


closeCross(i){
  //this.showStrips = false;
  //this.barHeight = this.myIdentifier.nativeElement.offsetHeight;
  //sessionStorage.setItem('showStripsOrange','1');
  sessionStorage.setItem('closeBannerStrip', '1');
  const index = this.bannerStripContent.indexOf(i);
  this.bannerStripContent.splice(i, 1);
    localStorage.setItem('bannerStripList_new',JSON.stringify(this.bannerStripContent));
    this.fetchBannerStripContent();
    this.barHeight = this.myIdentifier.nativeElement.offsetHeight;
    this.NavbarInnerDivHeight = this.NavbarInnerDiv.nativeElement.offsetHeight;
      localStorage.setItem('NavbarInnerDivHeight',this.NavbarInnerDivHeight.toString() );

      let searchFilterHeight = (document.getElementById('searchFilterHeight') !== null)? document.getElementById('searchFilterHeight').offsetHeight: 0;
      let NavbarInner = (document.getElementById('NavbarInner') !== null)? document.getElementById('NavbarInner').offsetHeight:0;
      let headerHeightUpdated = searchFilterHeight + NavbarInner
      $('.webSection').css({ 'height': 'calc(100vh - ' + headerHeightUpdated + 'px)' });
      $('.negiMap').css({ 'height': 'calc(100vh - ' + headerHeightUpdated + 'px)' });
      $('.contentBar.desktopView ').css({ 'height': 'calc(100vh - ' + headerHeightUpdated + 'px)' });
      $('#mainWrapper').css({ 'height': 'calc(100vh - ' + headerHeightUpdated + 'px)' });
    

      localStorage.setItem('searchFilterHeight',searchFilterHeight.toString() );
}
ngAfterViewInit() {
  this.barHeight = this.myIdentifier.nativeElement.offsetHeight;

  setInterval(()=>{
    if(sessionStorage.getItem('showStripsOrange' )){
    this.barHeight = this.myIdentifier.nativeElement.offsetHeight;
    // if(this.barHeight > getHeight){
    //   this.barHeight = getHeight;
    // }else{
    //   this.barHeight = this.myIdentifier.nativeElement.offsetHeight;
    // }}
  }else{
    this.barHeight = this.myIdentifier.nativeElement.offsetHeight;
  }
},100);
setInterval(()=>{
  this.partnerBar = this.NavbarInnerDivHeight;
  
},100);
setTimeout( ()=>{
  this.ViewAfterInitData = true;
}, 500 )
  
}

@ViewChild('orangeBarWrap',{ static: true })myIdentifier: ElementRef;


fetchUserStatusForTracking(){
  let getUserId = '0';
  let getUserEmail = '0';
  let user_login_type = '';
  if(localStorage.getItem('access_token')){
   this.isLogin = true;
     getUserId = localStorage.getItem('userid');
     getUserEmail = localStorage.getItem('email');
     user_login_type = localStorage.getItem('user_login_type');
  }
  const loginStatusTrack = {
    'event' : 'pageview',
    'loginStatus' : this.isLogin? 'logged in': 'Not Logged IN', // 'logged in/Not Logged IN',
    'authenticationMethod' : user_login_type,
    'userId' : getUserId,
    'pageType' : this.routerObj.url,
    'email': getUserEmail,
 };
//  console.log(loginStatusTrack);
(<any>window).dataLayer.push(loginStatusTrack);

}
fetchBannerStripContent(){
 //console.log('bannerStripList',localStorage.getItem('bannerStripList'));  
  if(localStorage.getItem('bannerStripList_new')){
    let content = localStorage.getItem('bannerStripList_new');
        this.bannerStripContent = JSON.parse(content);
        if(this.bannerStripContent.length>0){
          for(let i=0; i<this.bannerStripContent.length; i++){
            let item = this.bannerStripContent[i];
            if(item.turn_on_banner== true){
              this.showbannerStripList = true;
              break;
            }
          } 
        }   
  }
}
// fetchBannerStripContent(){
//   this.staticPageService.getCMSpageContentWithFields(this.pageSlugStrip).subscribe( (data:any)=>{
//     let bannerStripContent = data[0];

//     let BannerContent = bannerStripContent['acf'];
//       if(BannerContent['strip_banners_list'] != null && BannerContent['strip_banners_list'] != false){
//         this.bannerStripContent = BannerContent['strip_banners_list'];
//         if(this.bannerStripContent.length>0){
//           localStorage.setItem('bannerStripList',JSON.stringify(this.bannerStripContent));
//           for(let i=0; i<this.bannerStripContent.length; i++){
//             let item = this.bannerStripContent[i];
//             if(item.turn_on_banner== true){
//               this.showbannerStripList = true;
//               this.fetchBannerStripContentUpdated();
//               break;
//             }else {
//               localStorage.removeItem('bannerStripList');
//             }
//           } 
//         }else {
//           localStorage.removeItem('bannerStripList');
//         }
//         let check =  setInterval(()=>{
//           if( this.NavbarInnerDiv != undefined ){
//             console.log(this.NavbarInnerDiv.nativeElement.offsetHeight);
//             this.NavbarInnerDivHeight = this.NavbarInnerDiv.nativeElement.offsetHeight;
//             if(this.NavbarInnerDivHeight > 0){
//               localStorage.setItem('NavbarInnerDivHeight',this.NavbarInnerDivHeight.toString() );
//             }
//             clearInterval(check);
//           }
//         },0)
        
//         setInterval(()=>{
//           if(this.NavbarInnerDiv != undefined && this.NavbarInnerDivHeight != this.NavbarInnerDiv.nativeElement.offsetHeight ){
//             this.NavbarInnerDivHeight = this.NavbarInnerDiv.nativeElement.offsetHeight;
//             if(this.NavbarInnerDivHeight != null){
//               localStorage.setItem('NavbarInnerDivHeight',this.NavbarInnerDivHeight.toString() );
//             }
//           }
//         },0)
//       }
   
//   } ),(error)=> {
//     this.showbannerStripList = false;
//     localStorage.removeItem('bannerStripList');
//     console.log('Error'+ JSON.stringify(error) );
//   }
// }

fetchBannerStripContentUpdated(){
  //console.log('fetchBannerStripContentUpdated');
  //console.log(localStorage.getItem('bannerStripList'));
  if(localStorage.getItem('bannerStripList')){
    let content = localStorage.getItem('bannerStripList');
        this.fetchBannerStripContentUpdated = JSON.parse(content);
        // console.log(this.fetchBannerStripContentUpdated)
        if(this.fetchBannerStripContentUpdated.length>0){
          for(let i=0; i<this.fetchBannerStripContentUpdated.length; i++){
            let item = this.fetchBannerStripContentUpdated[i];
            //console.log('item',item);
            if(item.turn_on_banner== true){
              this.showbannerStripListUpdated = true;
              break;
            }
          } 
        }   
  }
}
// closeCross(i){
//   sessionStorage.setItem('closeBannerStrip', '1');
//   const index = this.bannerStripContent.indexOf(i);
//   this.bannerStripContent.splice(i, 1);
//   // console.log(this.bannerStripContent);
//   this.fetchBannerStripContentUpdated = this.bannerStripContent;
//     localStorage.setItem('bannerStripList',JSON.stringify(this.bannerStripContent));
//     this.fetchBannerStripContentUpdated();     
// }

}


