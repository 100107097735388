<div *ngIf="isTierLoaded" id="badgeBaLoyalty" [ngClass]="containerClass !==''?containerClass:'badgeBaLoyalty'">
    <div class="container badgeWapper">
        <div [ngClass]="containerClass =='homePageBadge'?'no-gutters':''">

            <ng-container [ngSwitch]="tierData?.id">
                <ng-container *ngSwitchCase="1">
                    <div class="row align-items-center ">
                        <div class="logo col-auto hidden-xs">
                            <img class="badgeImg" [src]="basic">
                        </div>
                        <div class="col">
                            <h2 class=" m-0 text-primary">Congratulations! You have <span class="text-white font-weight-bold">{{tierData?.title}}</span> Status</h2>
                            <p [innerHtml]="isTierMsg" class="text-white"></p>
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngSwitchCase="2">
                    <div class="row align-items-center ">
                        <div class="logo col-auto hidden-xs">
                            <img class="badgeImg" [src]="elite">
                        </div>
                        <div class="col">
                            <h2 class="  m-0  text-primary">Congratulations! You have <span class="text-white font-weight-bold">{{tierData?.title}}</span> Status</h2>
                            <p [innerHtml]="isTierMsg" class="text-white"></p>
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngSwitchCase="3">
                    <div class="row align-items-center ">
                        <div class="logo col-auto hidden-xs">
                            <img class="badgeImg" [src]="elite_plus">
                        </div>
                        <div class="col">
                            <h2 class="  m-0  text-primary">Congratulations! You have <span class="text-white font-weight-bold">{{tierData?.title}}</span> Status</h2>
                            <p [innerHtml]="isTierMsg" class="text-white"></p>
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngSwitchCase="4">
                    <div class="row align-items-center ">
                        <div class="logo col-auto hidden-xs">
                            <img class="badgeImg" [src]="diamond">
                        </div>
                        <div class="col">
                            <h2 class="  m-0 text-primary ">Congratulations! You have <span>{{tierData?.title}}</span> Status</h2>
                            <p [innerHtml]="isTierMsg" class="text-white"></p>
                        </div>
                    </div>
                </ng-container>
            </ng-container>

            <a href="javascript:void(0)" *ngIf="containerClass == 'filter-badge-loyalty'" class="close-badge" (click)="closeBadge()"><img [src]="cross" height="20px"></a>
        </div>
    </div>
</div>