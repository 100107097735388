import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CustomCode } from '../../../shared/CustomCode';
import { GoogleAnalyticsEventsService } from "../../../shared/google-analytics-events.service";
import { HomepageserviceService } from '../../../services/homepageservice.service';
import { MyAccountMonthlyParkingService } from '../../../services/my-account-monthly-parking.service';
import { ToastrService } from 'ngx-toastr';
import { UserserviceService } from '../../../services/userservice.service';
// declare var ga: Function;

@Component({
  selector: 'app-email-landing',
  templateUrl: './email-landing.component.html',
  styleUrls: ['./email-landing.component.scss'],
  providers: [HomepageserviceService, MyAccountMonthlyParkingService]
})
export class EmailLandingComponent implements OnInit {

	@Input() promoCode: string;
	@Output() saveEvent = new EventEmitter<boolean>();
  responseData;
  
  app_icon:string = 'assets/img/app_icon.svg';
  AppStore:string = 'assets/img/AppStore.svg';
  googleplayIcon:string = 'assets/img/google-play.svg';
  dollar_icon:string= 'assets/img/dollar_icon.svg';
  discount_icon:string = 'assets/img/discount_icon.svg';
  arrowsIcon : string = 'assets/img/arrows.svg';
  starICon:'assets/img/star.svg';
  appleAppUrl = 'https://itunes.apple.com/us/app/icon-parking/id394377820?mt=8';
  googleplatUrl = 'https://play.google.com/store/apps/details?id=com.iconparking&hl=en'

	constructor(
		public googleAnalyticsEventsService: GoogleAnalyticsEventsService,
		private homepageserviceServiceObj: HomepageserviceService,
	    private myAccountMonthlyParkingObj: MyAccountMonthlyParkingService,
	    private userserviceServiceObj: UserserviceService,
	    private toastr: ToastrService
		) { }

	ngOnInit() {
	}

	savePromocode(neighborhood) {
    /* commented for ga 16-6-2021 by seo team
    ga('send', 'event', { eventCategory: 'MAIL20', eventAction: 'click'});
    */
    (<any>window).dataLayer.push(
         {
            'promoCode': 'MAIL20',
            'event': 'Promocode save',
         });
		this.saveEvent.emit(neighborhood);
	}

	sendApp() {
    /* commented for ga 16-6-2021 by seo team
    ga('send', 'event', { eventCategory: 'EMAIL20APPLINK', eventAction: 'click'});
    */
    const appText = parseInt((<HTMLInputElement>document.getElementById('sendapp')).value.trim());
    if (appText) {
      const phoneNum = ((<HTMLInputElement>document.getElementById('sendapp'))).value;
      const text = 'Welcome to icon parking. click on the link below https://www.google.com';
      this.homepageserviceServiceObj.messageToMobile(phoneNum, text)
          .subscribe(
          (data:any)=> {
            this.responseData = data;
            this.toastr.success('App link sent successfully', 'success!');
          },
          error => {
            if (error.errors.message !== 'Validation Errors') {
              if (error.status === 500) {
                this.toastr.error('Something wrong happened, Please try again later', 'Sorry!');
              } else {
                this.toastr.error(error.errors.message, 'Sorry!');
              }
            } else {
              for (const key in error.errors.detail) {
                if (error.errors.detail.hasOwnProperty(key)) {
                  if (typeof error.errors.detail === 'undefined') {
                    this.toastr.error(error.errors.message, 'Sorry!');
                  } else {
                    this.toastr.error(error.errors.detail[key], 'Sorry!');
                  }
                }
              }
            }
          }
          );
    } else {
      this.toastr.error('Number is required', 'Sorry!');
    }
    (<HTMLInputElement>document.getElementById('sendapp')).value = '';
  }

  iconLink(){
    /* commented for ga 16-6-2021 by seo team
    ga('send', 'event', { eventCategory: 'EMAIL20APPLINK', eventAction: 'click'});
    */
  }

}
