import { Component, OnInit, Input,Output,EventEmitter,ElementRef,Pipe,PipeTransform} from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
//import { Http, Headers, RequestOptions, Response, ResponseContentType } from '@angular/http';
import { HttpClient } from '@angular/common/http';
import { AutosuggestEventService } from '../../../../services/autosuggest-event.service';
import { Ng2DeviceService } from 'ng2-device-detector';
declare var google: any;
// Import RxJs required methods
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { DomSanitizer } from '@angular/platform-browser'
import { CustomCode } from '../../../../shared/CustomCode';
import * as $ from 'jquery';
import { FormControl,FormGroup,FormBuilder,ReactiveFormsModule } from '@angular/forms';
@Component({
  selector: 'widget-app-google-autosuggest',
  templateUrl: './widget-google-autosuggest.component.html',
  styleUrls: ['./widget-google-autosuggest.component.scss'],
  providers: [ AutosuggestEventService ]
})
export class WidgetGoogleAutosuggestComponent implements OnInit {

  defaultSearchLocation:any = {
      label: 'Times Square',
      latitude: 40.7589,
      longitude: -73.9851
    };
    currentRoute:any;
    @Input() isSideNav: string;
    @Input() widget:string;
    @Input() monthlyInfo:string;
    @Output() getLatLong = new EventEmitter<object>();
    private googleFetchUrl = "https://maps.googleapis.com/maps/api/geocode/json?address=";
    private googleFetchKey: string;

    findValue: String;
    mapProp;
    autosuggest=[]
    facilityRadius = 2;
    places=[]
    searchField: FormControl;
    currentTime:any=new Date();
    coolForm: FormGroup;
    currentHour:any=''
    currentEventTime:any=''
    timeZoneOffset = Intl.DateTimeFormat().resolvedOptions().timeZone;
    deviceInfo=null;
    location_pinIcon = 'assets/img/location_pin.svg';
    search_icon = '/assets/img/search-icon.svg';


  constructor(
    private deviceService: Ng2DeviceService,
    private router: Router,
    private http: HttpClient,
    private autoSuggestEvent: AutosuggestEventService,

        private elementRef:ElementRef,
        private fb:FormBuilder
  ) {
    this.searchField = new FormControl();
     this.coolForm = fb.group({search: this.searchField});

   }


  ngOnInit() {
    //let preffred_location_bysearch='';
    if(localStorage.getItem('preffred_location_bysearch'))
    {
      this.findValue=localStorage.getItem('preffred_location_bysearch');
      localStorage.setItem('preffred_location_bysearch_data',this.findValue.toString());
    }
    this.deviceInfo = this.deviceService.getDeviceInfo().browser;
    let currentTime = new Date();
    currentTime.setMinutes(Math.ceil(currentTime.getMinutes() + 120));
    let cuHours=("0" + (currentTime.getHours())).slice(-2); // => 9
    let cuMin=("0" + (currentTime.getMinutes())).slice(-2);; // =>  30
    let cuSec=("0" + (currentTime.getSeconds())).slice(-2);;
    this.currentEventTime =cuHours+':00:00'  // $(".selectAuto").focus(function(){
    //   this.selectionStart=0;
    //   this.selectionEnd=this.value.length;
    //   $(this).select();
    // });
    this.googleFetchKey = CustomCode.GlobalGoogleMapAPIKey;
    
    this.currentRoute = this.router.url;
    this.InitiliazeAutosuggest();
    this.searchField.valueChanges
          .debounceTime(10)
            .switchMap(term => this.autoSuggestEvent.getRelatedEvents(term))
            .subscribe(data=>{
                this.filterNewYorkSuggestions(this.searchField.value)
                let input_val;
				input_val =$('#auto_suggest_input').val();
                if(this.searchField.value!=undefined)
                {
                  if (data.data.length>0)
                  {
                    
                    var height:any = $('.pac-container').height()
                    let gg= parseInt(height)+2
                    this.autosuggest= data['data']
                    
                      $('.customevent_widget').show();
                      //$('.relatedevent').show();
                    
                  }
                  else
                  {
                     this.autosuggest=[]
                     $('.customevent_widget').show();
                     $('.relatedevent').hide();
                  }
                }
                if(input_val=='')
                 {
                    this.autosuggest=[];
                    $('.customevent_widget').hide();
                    $('.relatedevent').hide();
                    $('.locationevent_widget').hide();
                 }
				 if(input_val!='')
				 {
					
					 $('input').focusout(function(e) {
						setTimeout(() => {$('.customevent_widget').hide();$('.pac-container').remove()},300);
					});
				 }
                 
                  });
    setTimeout(() => { 
      $('.customevent_widget').hide();
    },500);
   
  }
  checkData(value)
  {
    let search

  search = $('#auto_suggest_input').val().trim();
  localStorage.setItem('preffred_location', search);
  localStorage.setItem('preffred_location_type', '2');
      if(search=='')
      {
          $('.customevent_widget').hide();
          $('.relatedevent_widget').hide();
          $('.locationevent_widget').hide();
      }
  }
  filterNewYorkSuggestions(value) {
    var increment=0;
    let localplaces=[]
    this.places=[];
      $('.pac-container .pac-item').each(function() {
        if ($(this).text().indexOf('NY') > 0 || $(this).text().indexOf('New York') > 0) { 
            let loc=$(this).html().replace(/<\/span>/g,'').replace(/(<([^>]+)>)/ig, ' ').replace(/&amp;/g,"&");;
                   
                    if(increment<5)
                    localplaces[increment++]=loc
        } else {
          $(this).remove();
        }
        
      });
      this.places=[];
           
            $('.customevent_widget').show();
            $('.locationevent_widget').show();
            this.places=localplaces
            
  }
  redirectToSearchByLocation(id){
        $('.relatedevent').hide()
        $('.locationevent').hide()
        localStorage.removeItem('searchType');
        let addressText= $('#text'+id).html().trim();
        let address = addressText.split(" ").join("+");
        $.get("https://maps.googleapis.com/maps/api/geocode/json?address="+address+"+NY&key="+this.googleFetchKey, ( data ) => {
        let googleResults = data.results;
        let resultLength = googleResults.length;
        if (googleResults && resultLength > 0) {
          let newLat = googleResults[0].geometry.location.lat;
          let newLong = googleResults[0].geometry.location.lng;
          if(this.widget=='1')
          {
            localStorage.setItem('preffred_location', addressText);
            localStorage.setItem('preffred_location_type', '2');
            $('#auto_suggest_input').val(addressText)
            var latLong={'lat':newLat,'long':newLong,'location':addressText}
            this.getLatLong.emit(latLong)
          }
          else{
             this.triggerSearchLocation(newLat, newLong, addressText);
          }
        }});
    }
    redirectToSearchByEvent(id)
    {

        $('.relatedevent').hide()
        $('.locationevent').hide()
        let lat= $('#lat'+id).html()
        let venue= $('#venue'+id).html().trim();
        let long= $('#long'+id).html()
        let stime= $('#stime'+id).html().trim();
        let hourFromStime=stime.substr(11,2)
        let minusTime=parseInt(hourFromStime)-1;

        if(minusTime<0)
        {
          minusTime=23;
        }
        let twoMinusTime = ("0" + (minusTime)).slice(-2)
        let beforeHourTime=stime.substr(0,11)
        let afterHourTime=stime.substr(13,6);
        stime=beforeHourTime+''+twoMinusTime+''+afterHourTime;
        let etime= $('#etime'+id).html().trim();
        let starttime = new Date(stime);
        let endtime:any='';
        let curr=new Date();
        if(etime=='T')
        {
            endtime = stime;
        }else{
            endtime =etime
        }
        localStorage.setItem('searchType','event');
        localStorage.setItem('endtime',endtime);
        if(venue=='Yankee Stadium Station, New York, NY, USA' || venue.toLowerCase()=='yankee' || venue.toLowerCase()=='yankee stadium' )
        {
        // window.open("https://yankees.iconparkingsystems.com/yankee-reservations/", "_blank");
        }
        
        window.location.href='/search/'+lat+'/'+long+'/'+stime+'/'+this.facilityRadius+'/'+venue+'/daily/'+CustomCode.searchMode()
    }
  InitiliazeAutosuggest() {
    this.loadAutoC();
    $('auto_suggest_input').val(' ');
  }
  loadAutoC() {
      google.maps.event.addDomListener($(window), 'load', this.autoI());
    }

  autoI() {
    
      const newYorkBounds = new google.maps.LatLngBounds(
        new google.maps.LatLng(40.518673, -74.266017),
        new google.maps.LatLng(40.861947, -73.785519)
      );

       const options = {
          bounds: newYorkBounds,
          //types: ['geocode'],
          componentRestrictions: { country: 'us' }
        };

	this.mapProp = new google.maps.places.Autocomplete(<HTMLInputElement>document.getElementById('auto_suggest_input'), options);
      google.maps.event.addListener(this.mapProp, 'place_changed', () => {
        $('.pac-container').remove(); 
      const place = this.mapProp.getPlace();
        if(place!=undefined) 
        {
          if(this.currentRoute!='/widget-form')
              {
                  if (place.geometry && place.geometry) {
              const userLat = place.geometry.location.lat();
              const userLong = place.geometry.location.lng();
              let userLoc;
              if (this.isSideNav) {
                userLoc = $('#auto_suggest_input_field_side').val();
              } else {
                userLoc = $('#auto_suggest_input_field').val();
              }
              
              $('.closeBtnSidenav').click();
          
              this.triggerSearchLocation(userLat, userLong, userLoc);
              } else {
                  this.getManualLatLong(place.name);
              }
            }
            else{

                if (place.geometry && place.geometry) 
                {
                  const userLat = place.geometry.location.lat();
                  const userLong = place.geometry.location.lng();
                  let userLoc;
                  if (this.isSideNav) {
                    userLoc = $('#auto_suggest_input_field_side').val();
                  } else {
                    userLoc = $('#auto_suggest_input_field').val();
                  }
                  var latLong={'lat':userLat,'long':userLong,'location':userLoc}
                  this.getLatLong.emit(latLong)
                }

            }
        }
        
      
      
      });

  }

  getManualLatLong(loc) {

    let address = loc.split(" ").join("+");
    $.get(this.googleFetchUrl + address + "+NY&key=" + this.googleFetchKey, ( data ) => {
      let googleResults = data.results;
      let resultLength = googleResults.length;
      if (googleResults && resultLength > 0) {
        let newLat = googleResults[0].geometry.location.lat;
        let newLong = googleResults[0].geometry.location.lng;
        let newLoc = address.split("+").join(" ");
        this.triggerSearchLocation(newLat, newLong, newLoc);
      } else {
        this.triggerSearchLocation(40.7589, -73.9851, 'Times Square');
      }
    });
  }

  triggerSearchLocation(lat, long, loc) {
    (<HTMLInputElement>document.getElementById('userlat')).value = lat;
    (<HTMLInputElement>document.getElementById('userlong')).value = long;
    (<HTMLInputElement>document.getElementById('locationname')).value = loc;
    $('.closeBtnSidenav').trigger('click');
    $('#searchlocation').trigger('click');
  }

  searchLocation(type) {
        let input_val=''
        if (this.isSideNav) {
          input_val =$('#auto_suggest_input_field_side').val().trim();
        }else{
          input_val =$('#auto_suggest_input_field').val().trim();
        }
        
        $('.relatedevent').hide()
        $('.locationevent').hide()
        if (type === '') {
            type = $('label[name="monthlytype"].active').text().trim().toLowerCase();
        }
        if (type == "" || type == null) {
            type = CustomCode.defaultFacilityType;
        }
        let userLat = (<HTMLInputElement>document.getElementById('userlat')).value;
        let userLong = (<HTMLInputElement>document.getElementById('userlong')).value;
        let locationUser = '';
        if(input_val=='')
        {
           locationUser = (<HTMLInputElement>document.getElementById('locationname')).value;
        }else{
           locationUser = input_val;
        }
        if(input_val.toLowerCase()=="yankees" || input_val=="yankees" || input_val.toLowerCase()=="yankee's" || input_val=="yankee's" || input_val=='Yankee Stadium Station, New York, NY, USA' || input_val.toLowerCase()=='yankee' || input_val.toLowerCase()=='yankee stadium' )
        {
          userLat='40.8296426'
          userLong='-73.9261745'
          locationUser = input_val;
         // window.open("https://yankees.iconparkingsystems.com/yankee-reservations/", "_blank");
        }
        if (!userLat || !userLong || !locationUser || locationUser == 'Times Square, New York, NY, United States') {
          userLat = CustomCode.timeSquareCoords.lat;
          userLong = CustomCode.timeSquareCoords.long;
          locationUser = (locationUser == 'Times Square, New York, NY, United States')?locationUser:CustomCode.timeSquareCoords.defaultLocation;
        }
        if(locationUser=='Yankee Stadium Station, New York, NY, USA' || locationUser.toLowerCase()=='yankee' || locationUser.toLowerCase()=='yankee stadium' )
        {
        // window.open("https://yankees.iconparkingsystems.com/yankee-reservations/", "_blank");
        }
        
        window.location.href='/search/'+userLat+'/'+userLong+'/'+CustomCode.getDateForMapSearchSlashFormat()+'/'+this.facilityRadius+'/'+locationUser+'/'+type+'/'+CustomCode.searchMode()
        $('.sidenav').removeClass('open');
        $('.sidenavBackDrop').removeClass('open');
        $('body').removeClass('noscroll');
        //window.location.reload();
    }


  
  

}
