<input type="hidden" id="facilitytype" name="facilitytype" />
<input type="hidden" id="selecteddate" name="selecteddate" />
<input type="hidden" id="userradius" name="userradius" />
<input type="hidden" id="locationname" name="locationName" />
<input type="hidden" id="userlat" name="userlat" />
<input type="hidden" id="userlong" name="userlong" />
<form [formGroup]="coolForm" class="webView" *ngIf="!isSideNav" [class.hidden]="ispartner" >

	<div class="input-group" style="width: 100%;">
<input formControlName="search" type="text" (focus)="focusFunction($event)" class="form-control selectAuto" onFocus="this.selectionStart=0; this.selectionEnd=this.value.length; " onMouseUp="return false"  onTouchEnd="this.selectionStart=0; this.selectionEnd=this.value.length;" #auto id="auto_suggest_input_field" name="auto_suggest_input_field" placeholder="Search Address, Place or Events"
	[(ngModel)]="findValue"
	(keyup)="checkData($event)"
	(keyup.enter)="searchLocation('')"
>
<div class="customevent" >
	<div class="locationevent" >
		<ul class="addressWrapper">
			<li class="clearfix" *ngFor="let record of places; index as k" >
			<a href="javascript:;" (click)="redirectToSearchByLocation(k);">
				<div class="addressMarker">
				<img [src]="location_pin" alt="image">
				</div>
				<div class="addressTitle" id="text{{k}}">{{record}}</div>
			</a>
			</li>
		</ul>
	</div>
	<div class="locationllc" style="display:none;">
		<ul class="addressWrapper">
			<li class="clearfix" *ngFor="let record of filterFacilities; index as j" >
				<a href="javascript:;" (click)="redirectToSearchByLocationLlc(j);">
					<div style="padding-top:5px;" class="addressMarker"><img [src]="llc_pin" alt="image"></div>
					<div class="addressTitle" id="llctext{{j}}">{{ replaceText(record?.full_name | uppercase )}}</div>
					<span class="hide" id="llclong{{j}}">{{record.longitude}}</span>
					<span class="hide" id="llclat{{j}}">{{record.latitude}}</span>
				</a>
			</li>
		</ul>
	</div>
	<div *ngIf="widget!=1" class="relatedevent hide" style="display:none !important;">
		<h4>Related Events</h4>
		<ul class="eventWrapper">
			<li class="clearfix" *ngFor="let record of autosuggest; index as i">
			<a href="javascript:;" (click)="redirectToSearchByEvent(i);">
				<div class="eventMarker">
					<fa name="ticket"></fa>
				</div>
				<div class="contentEvent" *ngIf="record?.end_local_time!=''">
				<div class="eventNameTitle clearfix">{{record.event_name}}</div>
					<p  *ngIf="record?.start_local_time!='' && deviceInfo!='safari'" class="eventVenue clearfix">{{record?.start_local_date | date : "MMMM dd"}}, {{(record?.start_local_date+'T'+record?.start_local_time) | date : "shortTime"}}<small class="eventTime"> - {{record?.venue_name}}</small></p>
					<p  *ngIf="record?.start_local_time!=''  && deviceInfo=='safari'" class="eventVenue clearfix">{{record?.start_local_date | date : "MMMM dd"}}, {{(record?.start_local_date+'T'+record?.start_local_time) | safeDate}}<small class="eventTime"> - {{record?.venue_name}}</small></p>
					<p  *ngIf="record?.start_local_time ==''" class="eventVenue clearfix">{{record?.start_local_date | date : "MMMM dd"}}  <small class="eventTime"> - {{record?.venue_name}}</small></p>
					
					 <div *ngIf="record?.start_local_time!=''" class="hide clearfix" id="stime{{i}}">
					 {{record?.start_local_date}}T{{record?.start_local_time }}</div>
					 <div  *ngIf="record?.start_local_time == ''" class="hide clearfix" id="stime{{i}}">
					 {{record?.start_local_date}}T{{currentEventTime}}</div>
					 <div class="hide clearfix" id="venue{{i}}">
					 {{record?.venue_name}}</div>
					 <span class="hide" id="etime{{i}}" *ngIf="record?.end_local_time!=''">{{record?.end_local_date}}T{{record?.end_local_time }}</span>
					 <span class="hide" id="etime{{i}}" *ngIf="record?.end_local_time==''">T</span>
					<span class="hide" id="long{{i}}">{{record.longitude}}</span>
					<span class="hide" id="lat{{i}}">{{record.latitude}}</span>
				</div>
				<div class="contentEvent" *ngIf="record?.end_local_time==''">
						<div class="eventNameTitle clearfix">{{record.event_name}}</div>
							<p  *ngIf="record?.start_local_time!='' && deviceInfo!='safari'" class="eventVenue clearfix">{{record?.start_local_date | date : "MMMM dd"}}, {{(record?.start_local_date+'T'+record?.start_local_time) | date : "shortTime"}}<small class="eventTime"> - {{record?.venue_name}}</small></p>
							<p  *ngIf="record?.start_local_time!=''  && deviceInfo=='safari'" class="eventVenue clearfix">{{record?.start_local_date | date : "MMMM dd"}}, {{(record?.start_local_date+'T'+record?.start_local_time) | safeDate}}<small class="eventTime"> - {{record?.venue_name}}</small></p>
							<p  *ngIf="record?.start_local_time =='' && record?.start_local_date!=todayDate" class="eventVenue clearfix">{{record?.start_local_date | date : "MMMM dd"}} <small class="eventTime"> - {{record?.venue_name}}</small></p>
							<p  *ngIf="record?.start_local_time =='' && record?.start_local_date==todayDate" class="eventVenue clearfix">Ongoing  <small class="eventTime"> - {{record?.venue_name}}</small></p>
							
							 <div *ngIf="record?.start_local_time!=''" class="hide clearfix" id="stime{{i}}">
							 {{record?.start_local_date}}T{{record?.start_local_time }}</div>
							 <div  *ngIf="record?.start_local_time == ''" class="hide clearfix" id="stime{{i}}">
							 {{record?.start_local_date}}T{{currentEventTime}}</div>
							 <div class="hide clearfix" id="venue{{i}}">
							 {{record?.venue_name}}</div>
							 <span class="hide" id="etime{{i}}" *ngIf="record?.end_local_time!=''">{{record?.end_local_date}}T{{record?.end_local_time }}</span>
							 <span class="hide" id="etime{{i}}" *ngIf="record?.end_local_time==''">T</span>
							<!-- <span class="hide" id="etime{{i}}">{{record?.end_local_date}}T{{record?.end_local_time }}</span> -->
							<span class="hide" id="long{{i}}">{{record.longitude}}</span>
							<span class="hide" id="lat{{i}}">{{record.latitude}}</span>
						</div>
				<!-- <span class="pac-icon pac-icon-marker"></span>
					<span class="pac-item-query"><span class="pac-matched">
				</span>
				<span><span class="pac-matched">NY</span>, <span class="pac-matched">USA</span></span>
				 -->
			</a>
			</li>
		</ul>
	</div>
</div>
<!--
<input  type="hidden" id="facilitytype" name="facilitytype" />
<input  type="hidden" id="selecteddate" name="selecteddate" />
<input  type="hidden" id="userradius" name="userradius" />
<input  type="hidden" id="locationname" name="locationName" />
<input  type="hidden" id="userlat" name="userlat" />
<input  type="hidden" id="userlong" name="userlong" />
-->
<div class="input-group-btn">
<a *ngIf="showSearchIcon" style="cursor:pointer;"  id="searchlocation 1" class="btn btn-link btn-xs" type="button" (click) = "searchLocation('')"><img  class="imgInput_group" [src]="searchIconSvg" alt="image"></a>
<a *ngIf="!showSearchIcon" style="cursor:pointer;"  id="searchlocation 2" class="btn btn-link btn-xs pad-0" type="button" (click) = "navigate()"><img  class="imgInput_group" [src]="searchIconSvg" alt="image"></a>
</div>
</div>
</form>

<form [formGroup]="coolForm" [class.hidden]="ispartner" *ngIf="isSideNav" >

<input formControlName="search"  type="text" #automobile class="form-control selectAuto" onFocus="this.selectionStart=0; this.selectionEnd=this.value.length;" onMouseUp="return false"  onTouchEnd="this.selectionStart=0; this.selectionEnd=this.value.length;" id="auto_suggest_input_field_side" name="auto_suggest_input_field" placeholder="Where are you headed?"
	[(ngModel)]="findValue"  
	
	(keyup)="checkData($event)"
	(keyup.enter)="searchLocationHamburg('')"
>

<div class="customevent ">

		<div  class=" locationevent" >
			<ul *ngIf="places.length > 0" class="addressWrapper">
				<li class="clearfix" *ngFor="let record of places | slice:0:4; index as k">
				<a href="javascript:;" (click)="redirectToSearchByLocationHamburg(k);">
					<div class="addressMarker">
					<img [src]="location_pin" alt="image">
					</div>
					<div class="addressTitle" id="textham{{k}}">{{record}}</div>
						
					
					
				</a>
				</li>
			</ul>
		</div>
		<div class="locationllc" style="display:none;">
			<ul class="addressWrapper">
				<li class="clearfix" *ngFor="let record of filterFacilities; index as j" >
					<a href="javascript:;" (click)="redirectToSearchByLocationLlc(j);">
						<div style="padding-top:5px;" class="addressMarker"><img [src]="llc_pin" alt="image"></div>
						<div class="addressTitle" id="llctext{{j}}">{{ replaceText(record?.full_name | uppercase )}}</div>
						<span class="hide" id="llclong{{j}}">{{record.longitude}}</span>
						<span class="hide" id="llclat{{j}}">{{record.latitude}}</span>
					</a>
				</li>
			</ul>
		</div>		

<div class="relatedevent hide" style="display:none!important;">
	<h4 class="eventWrapper hide">Related Events</h4>
	<ul class="eventWrapper hide">
		<li class="clearfix" *ngFor="let record of autosuggest; index as i">
		<a href="javascript:;" (click)="redirectToSearchByEvent(i);">
			<div class="eventMarker">
				<fa name="ticket"></fa>
			</div>
			<div class="contentEvent">
				<div class="eventNameTitle clearfix">{{record.event_name}}</div>
					<p  *ngIf="record?.start_local_time!='' && deviceInfo!='safari'" class="eventVenue clearfix">{{record?.start_local_date | date : "MMMM dd"}}, {{(record?.start_local_date+'T'+record?.start_local_time) | date : "shortTime"}}<small class="eventTime"> - {{record?.venue_name}}</small></p>
					<p  *ngIf="record?.start_local_time!=''  && deviceInfo=='safari'" class="eventVenue clearfix">{{record?.start_local_date | date : "MMMM dd"}}, {{(record?.start_local_date+'T'+record?.start_local_time) | safeDate}}<small class="eventTime"> - {{record?.venue_name}}</small></p>
					<p  *ngIf="record?.start_local_time ==''" class="eventVenue clearfix">{{record?.start_local_date | date : "MMMM dd"}}, <small class="eventTime"> - {{record?.venue_name}}</small></p>
					
					 <div *ngIf="record?.start_local_time!=''" class="hide clearfix" id="stime{{i}}">
					 {{record?.start_local_date}}T{{record?.start_local_time }}</div>
					 <div  *ngIf="record?.start_local_time == ''" class="hide clearfix" id="stime{{i}}">
					 {{record?.start_local_date}}T{{currentEventTime}}</div>
					 <div class="hide clearfix" id="venue{{i}}">
					 {{record?.venue_name}}</div>
					<!-- <span class="hide" id="etime{{i}}">{{record?.end_local_date}}T{{record?.end_local_time }}</span> -->
					<span class="hide" id="etime{{i}}" *ngIf="record?.end_local_time!=''">{{record?.end_local_date}}T{{record?.end_local_time }}</span>
					<span class="hide" id="etime{{i}}" *ngIf="record?.end_local_time==''">T</span>
					<span class="hide" id="long{{i}}">{{record.longitude}}</span>
					<span class="hide" id="lat{{i}}">{{record.latitude}}</span>
				</div>
			<!-- <span class="pac-icon pac-icon-marker"></span>
				<span class="pac-item-query"><span class="pac-matched">
			</span>
			<span><span class="pac-matched">NY</span>, <span class="pac-matched">USA</span></span>
			 -->
		</a>
		</li>
	</ul>
</div>
</div>
<a style="cursor:pointer;" id="searchlocation 3" type="button" class="btn-introSearch" (click) = "searchLocationHamburg('')"><img [src]="searchIconSvg" alt="image"></a>
</form>

