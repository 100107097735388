import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Location, PopStateEvent } from '@angular/common';

import { CustomCode } from './shared/CustomCode';

import { UserserviceService } from './services/userservice.service';
import { ToastrService } from 'ngx-toastr';
import {GoogleAnalyticsEventsService} from "./shared/google-analytics-events.service";
// declare var ga: Function;
import * as $ from 'jquery';
import { LoadingBarService } from '@ngx-loading-bar/core';
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    providers: [UserserviceService]
})
export class AppComponent implements OnInit {
    
    title = 'app';
    private lastPoppedUrl: string;

    private respdata;

    constructor(
        private loadingBar: LoadingBarService,
        private router: Router, 
        private location: Location,
        private toastr: ToastrService,
        private userServiceObj: UserserviceService,
        public googleAnalyticsEventsService: GoogleAnalyticsEventsService
    ) { 
        this.startLoading();
        this.router.events.subscribe(event => {
          if (event instanceof NavigationEnd) {
              /* commented for ga 16-6-2021 by seo team
            ga('set', 'page', event.urlAfterRedirects);
            ga('send', 'pageview');
             */
          }
        });
        
    }
    startLoading() {
        //this.loadingBar.start();
    }
    
    stopLoading() {
        //this.loadingBar.complete();
    }
    ngAfterViewChecked(){
        document.getElementById('ico_preloader').style.display="none";
        this.stopLoading(); 
    }
    

    ngOnInit() {

        // setInterval(()=>{
        //     if(sessionStorage.getItem('removeOrangeBar')){
        //     $('.pageWrapper').removeClass('orangeBar');
        //     $('.pageWrapperLadning').removeClass('orangeBar');
        //     $('.page-header').removeClass('orangeBar');
        //     $('.payment-section').removeClass('orangeBar');
        //     $('.negiMap').removeClass('orangeBar');
        //     $('.Account').removeClass('orangeBar');
        //     $('.Loader').removeClass('orangeBar');
        //     $('.termsSection').removeClass('orangeBar');
        //     $('.commonPage').removeClass('orangeBar');
        //     $('.monthlyParking').removeClass('orangeBar');
        //     $('.infoBlock').removeClass('orangeBar');
        //     $('.webSection').removeClass('orangeBar');
        //     $('.facilityList.facilityList1.1').removeClass('orangeBar');
        //     $('.facality-details').removeClass('orangeBar');
        //     }else {
             
        //     }
        //   },0)
  



          
        var is_safari = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1 &&  navigator.userAgent.indexOf('Android') == -1;
        if (is_safari) {
          // /* On folder hover lower featured image z-index and almost hide all folder icons */
          if(this.router.url.indexOf('buy-monthly') > -1 && this.router.url.indexOf('payment') > -1 )
          {
            $("body").removeClass("safari");
          }
          $("body").addClass("safari");
        }else{

        }
        
        window.addEventListener("load", function() { window. scrollTo(0, 0); });


            $('.scrollTop').click(function(){
              $("body").animate({ scrollTop: 0 }, 500);
                window.scrollTo(0,0);
        });
                

         window.scrollTo(0,0);
            $("body").animate({ scrollTop: 0 }, 500);
        
        this.location.subscribe((ev: PopStateEvent) => {
            this.lastPoppedUrl = ev.url;
        });
        this.router.events.subscribe((ev) => {
            window.scrollTo(0,0);
            $("body").animate({ scrollTop: 0 }, 500);
            $('body').removeClass('noscroll');
            $('body').removeClass('sidebar-noscroll');
            $('body').removeClass('noserch-scroll');
            if (ev instanceof NavigationEnd) {
                // refresh token after in every hour
                /* var checkSessionTime = CustomCode.checkAccessTokenDuration();
                if (checkSessionTime === false) {
                    var refTokenData = CustomCode.refreshTokenData();
                    this.userRefreshToken(refTokenData);
                } */
                CustomCode.manageFormLabelsPosition();
                // scroll to top if user click on any link from footer
                if (ev.url === this.lastPoppedUrl) {
                    this.lastPoppedUrl = undefined;
                } else {
                    window.scrollTo(0, 0);
                }
            }
        });
        this.getVisitorCode();
        

    }

    submitTopMenuCallEvent() {
        this.googleAnalyticsEventsService.emitEvent("app link", "click", "", 10);
    }

    getVisitorCode() {
       localStorage.setItem('referrerUrl', document.referrer);
       this.userServiceObj.getVisitorCode()
          .subscribe(
          (data:any) => {
              //console.log("getVisitorCode",data);
             localStorage.setItem('X-Big-Apple', data.data.visitor_code);
              if(localStorage.getItem('referrer') != 'true')
              {
           
                    this.getReferrerForVisitor();
            
               }
           },
          error => {
              //console.log("getVisitorCode error",error);
            let errorMessage = error.errors.message;
          }
        );
    }

    getReferrerForVisitor() {
    //    this.userServiceObj.getReferrerForVisitor()
    //        .subscribe(
    //             (data:any)=> {
    //                 localStorage.setItem('referrer', 'true');
    //             },
    //             error => {
    //                // let errorMessage = error.errors.message;
    //             }
    //     );
    }


}

