<app-header></app-header>

<div class="contactPage commonPage orangeBar pageHeight mar-top-lg">
  <div class="container" style="width:100%">
     <div class="row mar-top-md" >
            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 col-md-offset-3 col-lg-offset-3 col-sm-offset-3">
              <div class="well well-lg text-center">
                <h2 class="text-primary ">Thanks for submitting your message</h2>
                <p>We will be in touch shortly.</p>
                 <p>Thank you!</p>
                <a class="btn btn-black theme-btn" routerLink="/"><fa name="home"></fa> Return to the home page</a>
              </div>
        </div>
      </div>
    </div>
</div>
<app-footer></app-footer>
