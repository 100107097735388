
import { Component, OnInit, TemplateRef, ViewChild, ElementRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router,ActivatedRoute } from '@angular/router';
import { CustomCode } from '../../shared/CustomCode';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { StaticpagesService } from '../../services/staticpages.service';
import { UserserviceService } from '../../services/userservice.service';
import { ToastrService } from 'ngx-toastr';


import { Crypto } from '../../classes/crypto';
// declare var ga: Function;
// declare var gtag: Function;
// declare var gtag_report_conversion: Function;
declare var google: any;
import * as $ from 'jquery';

@Component({
  selector: 'app-icon-rewards-register',
  templateUrl: './icon-rewards-register.component.html',
  styleUrls: ['./icon-rewards-register.component.scss'],
  providers: [StaticpagesService, UserserviceService, Crypto]
})
export class IconRewardsRegisterComponent implements OnInit {
  emailRegex = CustomCode.emailRegex;
  phoneMask: any = CustomCode.phoneMask;
  modalRef: BsModalRef;
  userData = {
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    password: '',
    cpassword: '',
    enrolledIn: false,
    promocode:''
  }
  respdata: any = {};
  pageSlug: string = 'terms-and-conditions';
  cmsPageContent: any = {};
  isLoaded = false;
  isRegistered: boolean = false;
  loginLink = '#login';
  firstStep: boolean = true;
  secondStep: boolean = false;
  icon_rewards_logo_wt = 'assets/img/icon-rewards-logo-wt.svg';
  price_tag_icon = 'assets/img/price-tag.svg';
  helpIcon = 'assets/img/help.svg';
  pcjImg = 'assets/img/badge_pci_dss.svg';
  public config = { ignoreBackdropClick: true }
  card = {
    card_no: '',
    exp: '',
    exp1: '',
    security_code: '',
    zip_code: '',
    addCardIcon: false,
    promocode: '',
  };
  dateMask: any = [/[0-1]/, /[0-9]/, '/', /[0-9]/, /[0-9]/];
  cardMask: any = [
    /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, ' ',
    /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, ' ',
    /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, ' ',
    /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/
  ];
  isAgreed: boolean = false;
  rewarded_points:number = CustomCode.rewarded_points;
  isInvAssosiated:boolean = false;
  passwordErrMsg = 'Password is required';
 // progressRef: NgProgressRef;

  constructor(
    private modalService: BsModalService,
    private staticObj: StaticpagesService,
    private userServiceObj: UserserviceService,
    private routeObj: Router,
    private toasterObj: ToastrService,
//private progress: NgProgress,
    private ActivatedRouteObj:ActivatedRoute,
    private cryptoObj: Crypto
  ) {

  }
  @ViewChild('myDiv',{ static: true }) myDiv: ElementRef;
  ngOnInit() {
    this.ActivatedRouteObj.queryParams.subscribe(params => {
      if(params['key']!==undefined){
        let key = { keyword:params['key'] }
        this.userServiceObj.EncrypInformation(key)
          .subscribe(
            (response:any) => {
              let data = response.data;
              sessionStorage.setItem('iq_code', data['iq_code'])
            }
        );      
      }
    });
    if (localStorage.getItem('access_token') && localStorage.getItem('is_loyalty')) {
      /* let isFirstRegister = localStorage.getItem('isFirstRegister'); // commented for new loyalty changes IPSD-1776
      if (isFirstRegister == 'true') {
        this.secondStep = true;
        this.firstStep = false;
      } else {
        this.routeObj.navigate(['/my-account/account']);
      } */
      this.routeObj.navigate(['/my-account/account']);
    }

    this.staticObj.getCmsPageContent(this.pageSlug).subscribe((data:any)=> {
      this.cmsPageContent = data.result[0];
      this.isLoaded = true;
    }, (error) => {
      this.isLoaded = true;
    }
    );
    if(localStorage.getItem('access_token')) {
      this.userServiceObj.GetLoyaltyWallet().subscribe(
        (response:any) => {
          this.rewarded_points = response.data.loyalty_config.POINTS_AT_REGISTRATION;
          this.isInvAssosiated = response.data.loyalty_config.is_invitation_code;
        }
      );
    }

    setTimeout(()=>{ 
      $("input[name='password']").parents().removeClass('focused');
      $("input[name='email']").parents().removeClass('focused');
      $("input[name='email']").val('');
      $("input[name='password']").val('');
    }, 500);
  }

  restrictNumeric(event) {
    CustomCode.restrictNumericInCard(event);
  }

  setToexpirationField(reference) {
    this.card.exp1 = reference.value;
  }

  checkCardByvalue(card_no, totalnumber) {
    let flag = 0;
    //SET CURSOR POSITION
    $.fn.setCursorPosition = function(pos) {
      this.each(function(index, elem) {
        if (elem.setSelectionRange) {
          elem.setSelectionRange(pos, pos);
        } else if (elem.createTextRange) {
          var range = elem.createTextRange();
          range.collapse(true);
          range.moveEnd('character', pos);
          range.moveStart('character', pos);
          range.select();
        }
      });
      return this;
    };
    if (card_no == '3') {
      if(totalnumber[1] == '_' && flag ==0 ){
        $('#standardCardNumber').setCursorPosition(1);
        flag = 1;
        }
      this.cardMask = [
        /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, ' ',
        /[0-9]/, /[0-9]/, /[0-9]/, ' ',
        /[0-9]/, /[0-9]/, /[0-9]/, ' ',
        /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/
      ];
    } else {
      this.cardMask = [
        /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, ' ',
        /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, ' ',
        /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, ' ',
        /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/
      ];
    }
  }

  checkCard(card_no) {
    this.checkCardByvalue(card_no.value[0], card_no.value);
  }

  confirm(): void {
    this.isAgreed = true;
    this.userData.enrolledIn = true;
    this.modalRef.hide();
  }

  decline(): void {
    this.userData.enrolledIn = false;
    this.isAgreed = false;
    this.modalRef.hide();
  }

  openModal(AcceptTerms: TemplateRef<any>, event) {
    if (event.target.checked) {
      this.modalRef = this.modalService.show(AcceptTerms, this.config);
    }
    this.userData.enrolledIn = false;
    return true;
  }

  openTermModal(AcceptTerms: TemplateRef<any>) {
    this.modalRef = this.modalService.show(AcceptTerms, this.config);
    return false;
  }

  closeModel() {
    this.modalRef.hide();
    if (!this.isAgreed) {
      this.userData.enrolledIn = false;
    }
  }

  phoneFormat(event: any) {
    const pattern = /[0-9\)\-\(\.\ ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && event.keyCode != 9 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  moveToNext(userData, formRef: NgForm) {

    const reg =/<(.|\n)*?>/g; 
    if (reg.test(userData.first_name) == true) {
      this.toasterObj.error('Please enter valid First Name.', 'Sorry!');
      return false;
    }else  if (reg.test(userData.last_name) == true) {
      this.toasterObj.error('Please enter valid Last Name.', 'Sorry!');
      return false;
    } else {
      if (reg.test(userData.phone) == true) {
        this.toasterObj.error('Please enter valid Phone.', 'Sorry!');
        return false;
      }
    }
    window.scrollTo(0, 0);
    $("body").animate({ scrollTop: 0 }, 0);


    let Data = {
      name: this.userData.first_name + ' ' + this.userData.last_name,
      email: this.userData.email,
      password: this.userData.password,
      cpassword: this.userData.cpassword,
      phone: this.userData.phone,
      is_loyalty: this.userData.enrolledIn,
      invitation_code: this.userData.promocode,
      iq_code:(sessionStorage.getItem('iq_code')!==null)?sessionStorage.getItem('iq_code'):'',
    }


    if(Data.phone != undefined && Data.phone != ''){
      let ph = Data.phone.replace(/\D/g, '');
      if(ph.length !==0 && ph.length < 10){
        formRef.form.controls['phone'].setErrors({'invalid': true});
        return false;
      }
    }

    if (Data.password === undefined || Data.password === '') {
      this.passwordErrMsg = 'Password is required';
      formRef.form.controls['password'].setErrors({'invalid': true});
      return false;
    }else if(Data.password.length < 8){
      this.passwordErrMsg = 'Password must be atleast 8 characters long';
      formRef.form.controls['password'].setErrors({'invalid': true});
      return false;
    }else if(Data.password !== Data.cpassword){
      formRef.form.controls['cpassword'].setErrors({'invalid': true});
      return false;
    }else if(!Data.is_loyalty){
      formRef.form.controls['enrolledIn'].setErrors({'invalid': true});
      return false;
    }

    this.userServiceObj.registerLoyaltyUser(Data)
      .subscribe(
        (data:any) => {
          let resData = data;
          localStorage.setItem('isInvAsso', '1');
          // localStorage.setItem('isFirstRegister', resData.data.is_new_user); // commented for new loyalty changes IPSD-1776
          /* #start login user after registration */
          const userVal = {
            user: {
              name: resData.data.user.name,
              email: resData.data.user.email,
              phone: resData.data.user.phone,
              id: resData.data.user.id,
              is_loyalty: resData.data.user.is_loyalty,
              is_loyalty_active: resData.data.user.is_loyalty_active
            },
            access_token: resData.data.session.access_token,
            refresh_token: resData.data.session.refresh_token,
            expires_in: resData.data.session.expires_in,
            token_type: resData.data.session.token_type,
          }

          CustomCode.markLoginUser(userVal);

          /* #end login user after registration */
          if (sessionStorage.getItem('event_for_loyalty') == '1') {
            let eventlabel = sessionStorage.getItem('eventlabel');
            let eventcategory = sessionStorage.getItem('eventCategory');
            /* commented for ga 16-6-2021 by seo team
            ga('send', 'event', { eventCategory: eventcategory + " Inactive", eventAction: 'Click', eventLabel: eventlabel, eventValue: '10' });
            */
            sessionStorage.removeItem('event_for_loyalty');
            sessionStorage.removeItem('eventlabel');
            sessionStorage.removeItem('eventCategory');
          }
          /* commented for ga 16-6-2021 by seo team
          ga('send', 'event', { eventCategory: 'Enroll User Successfull', eventAction: 'click' });
          */
          (<any>window).dataLayer.push({ 'event': 'rewardsEnrolled-Inactive' });
          if(sessionStorage.getItem('RedirectfromPayment') == '1'){
            let currentUrl = CustomCode.getCookie('currentUrl');
            let currentUrlObj = JSON.parse(currentUrl);
            CustomCode.deleteCookie('currentUrl');
            this.routeObj.navigate(['/payment', currentUrlObj.facId, currentUrlObj.facType, currentUrlObj.facPay]);
          }else {
            this.routeObj.navigate(['my-account/account']);
          }
          // window.location.href = '/icon-rewards-register'; // commented for new loyalty changes IPSD-1776
        },
        error => {
          const errorsList = error.errors;
          if (errorsList.message !== 'Validation Errors') {
            this.toasterObj.error(errorsList.message, 'Sorry!', { enableHtml: true, toastClass: 'toast register-toaster' });
          } else {
            for (const key in errorsList.detail) {
              if (errorsList.detail.hasOwnProperty(key)) {
                if (typeof errorsList.detail === 'undefined') {
                  this.toasterObj.error(errorsList.message, 'Sorry!', { enableHtml: true, toastClass: 'toast register-toaster' });
                } else {
                  this.toasterObj.error(errorsList.detail[key], 'Sorry!', { enableHtml: true, toastClass: 'toast register-toaster' });
                }
              }
            }
          }
        }
      );
  }

  goBack() {
    window.scrollTo(0, 0);
    $("body").animate({ scrollTop: 0 }, 0);
    this.firstStep = true;
    this.secondStep = false;
  }

  enrolledNow(card, sCard) {
   // this.progressRef = this.progress.ref();
   // this.progressRef.start();
    //this.progress.start();
    /* #Start register user */
    let time = 1;
    var interval = setInterval(() => {
      time++;
      if (time > 10) {
        this.toasterObj.error('Network Problem', 'sorry!');
      }

      if (localStorage.getItem('card_tokenHard')) {
        localStorage.removeItem('card_tokenHard');
        clearInterval(interval);
      //  this.progressRef = this.progress.ref();
      //  this.progressRef.destroy();
        //this.progress.done();
        let card_token = localStorage.getItem('card_token');
        let card_type = localStorage.getItem('card_type');
        if (card_token !== null) {
          var tokenizationData = {
            "token": card_token,
            "type": card_type,
            "length": (card.card_no.replace(/\D/g, '')).length,
            "bin": card.card_no.replace(/\D/g, '').substr(0, 6),
            "last_four": (card.card_no.replace(/\D/g, '')).substr(-4),
            "expiration": card.exp,
            "zip": card.zip_code,
            "name_on_card": '',
            "card_number": card.card_no.replace(/\D/g, ''),
            "security_code": card.security_code
          }
          localStorage.removeItem('card_token');
          localStorage.removeItem('card_type');
        }
       
        let postData = {
          'card': this.cryptoObj.encryptCard(tokenizationData),
          'iq_code': (sessionStorage.getItem('iq_code') !== null) ? sessionStorage.getItem('iq_code') : '',
          'add_to_account': card.addCardIcon,
          'invitation_code': card.promocode
        }

        this.userServiceObj.LoyaltyAddCard(postData)
          .subscribe(
            (data:any)=> {
              (<any>window).dataLayer.push({ 'event': 'rewardsEnrolled' });
              let eventlabel = sessionStorage.getItem('eventlabel');
              let eventcategory = sessionStorage.getItem('eventCategory');
              /* commented for ga 16-6-2021 by seo team
              ga('send', 'event', { eventCategory: eventcategory + "With card", eventAction: 'Click', eventLabel: eventlabel, eventValue: '10' });
              */
              this.respdata = data;
              if (this.respdata.status === 201) {
                /*********** #Start card toasters messages ***************/
                if("account_card" in data.data){
                  if(data.data.account_card.success){
                    this.toasterObj.success(data.data.account_card.message);
                  }else{
                    this.toasterObj.error(data.data.account_card.message, 'Sorry!');
                  }
                }

                if("invitation_code" in data.data){
                  if(data.data.invitation_code.success){
                    this.toasterObj.success(data.data.invitation_code.message);
                  }else{
                    this.toasterObj.error(data.data.invitation_code.message, 'Sorry!');
                  }
                }

                if(data.data.success){
                  this.userServiceObj.GetLoyaltyWallet().subscribe(
                    (response:any) => {
                      this.rewarded_points = response.data.loyalty_config.POINTS_AT_REGISTRATION;
                      this.toasterObj.success(data.data.message);
                      this.isRegistered = true;
                      sessionStorage.removeItem('iq_code');
                      localStorage.setItem('is_loyalty_active','1');
                    }
                  );
                }else{
                  this.toasterObj.error(data.data.message, 'Sorry!');
                }
                /*********** #End card toasters messages ***************/
              }
            },
            error => {
              // const errorsMessage = 'We are unable to add a credit card at the moment – Your web account has been created. Please login again to add your credit card details in order to take full advantage of the benefits of Icon Rewards.';
              this.toasterObj.error(error.errors.message, 'Sorry!', { enableHtml: true, toastClass: 'toast register-toaster' });
            }
          );
      }
    }, 1000);
    /* #End register user */
  }

  enrolledCardInfo(ECardInfo: TemplateRef<any>) {
    this.modalRef = this.modalService.show(ECardInfo, this.config);
    return false;
  }

  close() {
    window.scrollTo(0, 0);
    $("body").animate({ scrollTop: 0 }, 0);
    this.modalRef.hide();
  }

  restChar(event: any) {
    if (!CustomCode.restricSpecialChar(event)) {
      event.preventDefault();
    }
  }

  onPaste(event: any) {
    if (!CustomCode.restricPaste(event)) {
      event.preventDefault();
    }
  }

  restSpace(event: any){
    if (event.keyCode == 32) {
      event.preventDefault();
    }
  }
}
