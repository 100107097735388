<app-lazy-load [isLoaded]="isLoaded" [sectionName]></app-lazy-load>
<div *ngIf="isLoaded" class="row">
  <div class="col-md-12">
  <div class="top-re-icons clearfix">
    <ol *ngIf="isReferal" class="breadcrumb pad-top-0 pad-bottom-0 pad-left-0">
      <li class="active">
        <a routerLink="/my-account/icon-rewards" class="btn btn-link pad-left-0">
          <i class="fa fa-angle-left" style="font-size: 20px; margin-top: -5px;"></i> &nbsp; Icon Rewards</a>
      </li>
    </ol>
      <ul *ngIf="isReferal" class="top-icon-rewards pull-right">
        <li>
          <img [src]="icon_rewards_logo_wt" alt="icon">
        </li>
      </ul>
   </div>
   <div class="vissible-xs margin-top-sm"></div>
    <h4 *ngIf="!isReferal" class="text-uppercase mar-bottom-xs text-block">YOUR INFO (OPTIONAL)</h4>
    <h2 *ngIf="isReferal" class="text-uppercase mar-top-0 text-primary">{{pageHeading}} </h2>
  
    <div *ngIf="!isProfileCompleted" class="icon-rewards-your-info well pad-grey-box">
      <h4 class="text-uppercase mar-top-0 text-primary"><b class="semi-bold">LIMITED TIME OFFER</b></h4>
      <app-lazy-load [isLoaded]="isPointsLoaded"></app-lazy-load>
      <p *ngIf="isPointsLoaded" sourceFont>Fill out some basic questions about yourself and receive a <span
        class="high-light">${{rewarded_points | number}} account credit</span> to your Rewards account.</p>
    </div>
  </div>
</div>
<form *ngIf="isLoaded" #saveDetails="ngForm" id="saveDetails" name="form" (ngSubmit)="saveDetails.form.valid && saveUserInfo(user, saveDetails)">
<div class="row">
  <div class="col-6 col-sm-3 col-md-2">
    <div class="form-group" [ngClass]="{ 'has-error' : saveDetails.submitted && !phone.valid}">
      <label class="control-label semi-bold" for="">Phone number</label>
      <input class="form-control" [(ngModel)]="user.phone" [textMask]="{mask: phoneMask}" minlength="10" (keypress)="phoneFormat($event)" name="phone" #phone="ngModel" type="text" placeholder="Phone number">
      <div *ngIf="saveDetails.submitted && !phone.valid" class="help-block">Not a valid number</div>
    </div>
  </div>
  <div class="clearfix"></div>
  <div class="col-5 col-sm-3 col-md-2">
    <div class="form-group" [ngClass]="{ 'has-error' : saveDetails.submitted && !zip.valid}">
      <label class="control-label semi-bold" for="Home zip code">Home zip code </label>
      <input class="form-control" [(ngModel)]="user.zip" minlength="5" maxlength="6" name="zip" #zip="ngModel" type="text" placeholder="Home zip code">
      <div *ngIf="saveDetails.submitted && !zip.valid" class="help-block">Not a valid zip</div>
    </div>
  </div>
  <div class="clearfix"></div>
  <div class="col-sm-3 col-md-2 col-5">
    <div class="form-group focused" [ngClass]="{ 'has-error' : saveDetails.submitted && !month.valid}">
      <label class="control-label semi-bold" for="month">Birth date</label>
      <select class="form-control" [(ngModel)]="user.month" name="month" #month="ngModel" type="text">
        <option value="">Month</option>
        <option *ngFor="let month of monthsList;" value={{month.id}}>{{month.value}}</option>
      </select>
      <div *ngIf="saveDetails.submitted && !month.valid" class="help-block">Month is required</div>
    </div>
  </div>
  <!-- <div class="col-sm-2 col-4">
    <div class="form-group focused" [ngClass]="{ 'has-error' : saveDetails.submitted && !day.valid}">
      <label class="control-label" for="day">&nbsp;</label>
      <select class="form-control" [(ngModel)]="user.day" required name="day" #day="ngModel" type="text">
        <option value="">Day</option>
        <option *ngFor="let day of daysList" value={{day}}>{{day}}</option>
      </select>
      <div *ngIf="saveDetails.submitted && !day.valid" class="help-block">Day is required</div>
    </div>
  </div> -->
  <div class="col-sm-2 col-md-1 col-4">
    <div class="form-group focused" [ngClass]="{ 'has-error' : saveDetails.submitted && !year.valid}">
      <label class="control-label" for="yaer">&nbsp;</label>
      <select class="form-control" [(ngModel)]="user.year" name="year" #year="ngModel" type="text">
        <option value="">Year</option>
        <option *ngFor="let year of yearsList" value={{year}}>{{year}}</option>
      </select>
      <div *ngIf="saveDetails.submitted && !year.valid" class="help-block">Year is required</div>
    </div>
  </div>
  <div class="clearfix"></div>
  <div class="col-sm-3 col-md-2 col-6">
    <div class="form-group focused" [ngClass]="{ 'has-error' : saveDetails.submitted && !gender.valid}">
      <label class="control-label semi-bold" for="gender">Gender</label>
      <select class="form-control" [(ngModel)]="user.gender" name="gender" #gender="ngModel" type="text">
        <option value="0">Select Gender</option>
        <option *ngFor="let gender of genderList" value={{gender.id}}>{{gender.value}}</option>
      </select>
      <div *ngIf="saveDetails.submitted && !gender.valid" class="help-block">Gender is required</div>
    </div>
  </div>
</div>
<div *ngIf="!isProfileCompleted" >
  <div *ngFor="let q of questionList">
    <ng-container *ngIf="q.id===1">
        <div class="form-group focused" [ngClass]="{ 'has-error' : (saveDetails.submitted && !ques1.valid || invalidNumber1)}">
          <div class="row">
          <div class="col-sm-12 col-md-5">

          <label class="control-label semi-bold" for="{{q.question}}">{{q.question}}</label>
          </div>
          <div class="clearfix"></div>
          <div class="col-4 col-sm-3 col-md-1">
          <input class="form-control" (keypress)="restricDec($event)" (change)="UpdateKnow1();" (keyup)="onKey($event, 1)" type="number" [(ngModel)]="user.ques1" min="0" max="{{maxLimit}}" name="ques1" #ques1="ngModel">
          <div *ngIf="saveDetails.submitted && !ques1.valid" class="help-block">Not a valid number</div>
          <div *ngIf="invalidNumber1" class="help-block">Not a valid number</div>
          </div>
        </div>
        </div>
        <div class="clearfix"></div>
      <!-- <label class="control-label" for="q.value">{{q.question}}</label>
      <input style="width:calc(100% - 80px);" class="pull-left" (change)="changeZoom(ranger.value, q.id)" type="range" [(ngModel)]="rangerValue1" name="rangerValue1" min="0" max="{{maxrange}}" #ranger>
      <input inputValueRange1 class="pull-left input-range-border" [(ngModel)]="user.ques1" name="ques1"  (input)="changerange(ques1.value, q.id)" type="number" (focus)="changerange(ques1.value, q.id)" min="0" max="{{maxrange}}" value="{{ques1}}" id="rangerValue" #ques1> -->
    </ng-container>

    <ng-container *ngIf="q.id===2">
        <div class="form-group focused" [ngClass]="{ 'has-error' : (saveDetails.submitted && !ques2.valid || invalidNumber2)}">
          <div class="row">
          <div class="col-sm-12 col-md-5">
          <label class="control-label semi-bold" for="{{q.question}}">{{q.question}}</label>
          </div>
          <div class="clearfix"></div>
          <div class="col-4 col-sm-3 col-md-1">
          <input style="display: inline;" (keypress)="restricDec($event)" (change)="UpdateKnow();" (keyup)="onKey($event, 2)" class="form-control" type="number" min="0" max="{{maxLimit}}" [(ngModel)]="user.ques2" name="ques2" #ques2="ngModel">
          <div *ngIf="saveDetails.submitted && !ques2.valid" class="help-block">Not a valid number</div>
          <div *ngIf="invalidNumber2" class="help-block">Not a valid number</div>
          <span class="range-doller pull-right"><i class="fa fa-percent"></i></span>
          
          </div>
          <div class="col-1 col-sm-1 col-md-1 mar-top-xs text-center">
              <span><b>OR</b></span>
          </div>
          <div class="col-7 col-sm-5 col-md-5">
              <div class="checkbox mar-top-xs">
                <label class="widget-lebel-check">
                  <input type="checkbox" class="pad-right-md" (click)="dontKnowCount($event)" [(ngModel)]="dontKnow" name="dontKnow">I don't know
                </label>
              </div>
             </div>
             </div>
          </div>
          <div class="clearfix"></div>
      <!-- <label class="control-label" for="q.value">{{q.question}}</label>
      <input style="width: calc(100% - 80px);" class="pull-left" (change)="changeZoom(ranger.value, q.id)" type="range" [(ngModel)]="rangerValue2" name="rangerValue2" min="0" max="{{maxrange}}" #ranger>
      <input inputValueRange2 class="pull-left mar-left-xs input-range-border" [(ngModel)]="user.ques2" name="ques2" (input)="changerange(ques2.value, q.id)" type="number" (focus)="changerange(ques2.value, q.id)" min="0" max="{{maxrange}}" value="{{ques2}}" id="rangerValue" #ques2 >
      <span class="range-doller pull-right"><i class="fa fa-percent"></i></span>
      <div class="clearfix"></div>
       <div><b>OR</b></div>
       <div class="clearfix"></div>
      <div class="form-group">
          <div class="checkbox mar-top-0">
            <label class="widget-lebel-check">
      <input type="checkbox" class="pad-right-md" (click)="dontKnowCount($event)" [(ngModel)]="dontKnow" name="dontKnow">I don't know
      </label>
      </div>
      </div> -->
    </ng-container>
  </div>
</div>
<div *ngIf="isReferal" class="row">
  <div class="col-md-12 mar-top-sm">
    <div class="form-group btn-center-xs">
      <button [disabled]="submtDisabled" class="btn btn-primary btn-fill">{{btnText}}</button>
    </div>
  </div>
  <div *ngIf="!isProfileCompleted" class="col-md-12 text-danger">In order to earn your ${{rewarded_points | number}} account credit, please answer all of the questions above.</div>
</div>
</form>
<div *ngIf="!isReferal" class="row">
  <div class="col-md-12">
    <div class="form-group btn-center-xs">
      <button class="btn btn-primary btn-fill" [disabled]="isBtnDisabled" id="profileBtn" (click)="enrollNow(user, saveDetails)">ENROLL</button>
    </div>
  </div>
  <div class="col-md-12 text-danger">In order to earn your ${{rewarded_points | number}} account credit, please answer all of the questions above.</div>
</div>
