<!-- <app-header></app-header>
<div class="container mar-top-lg">
	<div *ngIf="dataDebugging">
		<h4>Partner :- </h4>
		<pre class="elem1">
			{{ syntaxHighlight(partner, 'elem1') }}
		</pre>
		<h4>Partner Facilities :- </h4>
		<pre class="elem2">
			{{ syntaxHighlight(partnerFacilities, 'elem2') }}
		</pre>
	</div>
</div>
<app-footer></app-footer> -->
<app-header *ngIf="searchCriteria.device == 'web'"></app-header>
<app-mobileheader *ngIf="searchCriteria.device == 'app'"></app-mobileheader>
<!--<div [style.height.px]="NavbarInnerDivHeight"></div>-->
<div class="hidden-xs searchFilter " >
	<app-search-filters (updateFilters)="receiveNewSearchFilter($event)"></app-search-filters>
</div>
<div class="sidebarWindow panel panel-default sidebarWindow2" [class.isLoyality]="isShowLoyalyBadge" *ngIf="isPopup">
	<div class="panel-body popupBox">
		<button class="btn btn-fill btn-link btn-sm crossBtn" (click)="dismissPopup()"><i class="fa fa-times"></i></button>
		<div class="row no-gutters">
			<div *ngIf="patner_image != ''" class="col partnerLogo">
				<img *ngIf="patner_image != ''" [src]="patner_image" alt="image">
			</div>
			<div class="col popupBoxContent">
				<h3 class="font-weight-bold">{{ partner.title }}</h3>
			</div>
		</div>
	</div>
<!-- <h3> {{ partner.title }} </h3> -->
<!-- <h4>Trolls Experience Discount Parking</h4>
    <p>6 hour parking for $45 (including tax) valid 7days a week / Enter Anytime</p> -->
		<!-- <div class="partnerLogo" style="max-width: 50%;float: left;display: inline-block; margin-right: 25px;">
			<img *ngIf="patner_image != ''" [src]="patner_image" alt="image" style="max-height: 85px;">
		</div> -->	
</div>
<div class="partner-page-wrap"><app-google-map [class.mobileHide]="isIphone" (updateFilters)="receiveNewFilters($event)" (updateMobileFilters)="receiveMobileFilters($event)"></app-google-map></div>
<app-footer *hideItDevice="['mobile']"></app-footer>
