import { Component, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { NgForm } from '@angular/forms';
import * as $ from 'jquery';
import { GoogleMapComponent } from '../../Global/google-map/google-map.component';

import { CustomCode } from '../../../shared/CustomCode';
import { SearchPageScript } from '../../../shared/searchPageScript';
import { FormValidationErrors } from '../../../shared/formValidationErrors';
import { SearchpageserviceService } from '../../../services/searchpageservice.service';
import { ToastrService } from 'ngx-toastr';
import { MyAccountMonthlyParkingService } from '../../../services/my-account-monthly-parking.service';
import { UserserviceService } from '../../../services/userservice.service';
@Component({
	selector: 'app-search-facility',
	templateUrl: './search-track.component.html',
	providers: [SearchpageserviceService, MyAccountMonthlyParkingService]
})
export class SearchTrackComponent implements OnInit {
	constructor(
		private router: Router,
		private userServiceObj: UserserviceService,
		private activatedRouteObj: ActivatedRoute,
		private toastr: ToastrService,
		private searchPageObj: SearchpageserviceService,
		private myAccountMonthlyParkingObj: MyAccountMonthlyParkingService
	) { }
	searchCriteria: any = {
		'type': CustomCode.defaultFacilityType,
		'device': 'web',
		'lat': CustomCode.timeSquareCoords.lat,
		'long': CustomCode.timeSquareCoords.long,
		'currdate': CustomCode.getDateForMapSearch(),
		'radius': CustomCode.facilityDefaultRadius,
		'label': CustomCode.timeSquareCoords.defaultLocation,
		'length_of_stay': 2
	};
	ngOnInit() {
		this.activatedRouteObj.params.subscribe((params: Params) => {
			if (params['lat'] && params['long'] && params['currdate'] && params['radius'] && params['label'] && params['type'] && params['device']) {
				localStorage.setItem('companyName',params['device']);
				this.searchCriteria.type = params['type'];
				this.searchCriteria.device = 'app';
				this.searchCriteria.lat = +params['lat'];
				this.searchCriteria.long = +params['long'];
				this.searchCriteria.currdate = params['sdate']
				this.searchCriteria.radius = params['radius'];
				this.searchCriteria.label = params['label'];
				if(params['edate']!=='undefined')
				{
					localStorage.setItem('searchType','widget');
					localStorage.setItem('endtime',params['edate']);
				}
				else{
					localStorage.removeItem('searchType');
					localStorage.removeItem('endtime');
				}
				this.router.navigate(['/search', this.searchCriteria.lat, this.searchCriteria.long, this.searchCriteria.currdate, this.searchCriteria.radius, this.searchCriteria.label,'daily', this.searchCriteria.device]);
				//window.location.href='/search/'+this.searchCriteria.lat+'/'+this.searchCriteria.long+'/'+this.searchCriteria.currdate+'/'+this.searchCriteria.radius+'/'+this.searchCriteria.label+'/daily/'+this.searchCriteria.device;
			}
			if (params['masquerade']){
				
				let val = params['masquerade'].split('lbha ');
				localStorage.setItem('username',val[0].replace(/XANDX/g,'&').replace(/XBROX/g,'(').replace(/XBRCX/g,')'))
				localStorage.setItem('email',val[1])
				localStorage.setItem('access_token',val[2])
				localStorage.setItem('refresh_token',val[3])
				localStorage.setItem('userid',val[4].slice(0, -2))
				localStorage.setItem('masquerade_set',val[4].slice(0, -2))
				localStorage.setItem('is_loyalty',val[5])
				localStorage.setItem('is_loyalty_active',val[6])
				localStorage.setItem('access_token1',val[7])
				localStorage.setItem('refresh_token1',val[8])
				//localStorage.setItem('access_token',val[7])
				//localStorage.setItem('refresh_token',val[8])
				localStorage.setItem('invalidName', '0');
				//console.log(window.location.href);
				this.getVisitorCode();
				
			}
			if (params['admin']){
				let val = params['admin'].split('lbha ');
				localStorage.setItem('username',val[0].replace(/XANDX/g,'&').replace(/XBROX/g,'(').replace(/XBRCX/g,')'))
				if(val[1]!='undefined')
				{
					localStorage.setItem('email',val[1])
				}
				// localStorage.setItem('email',val[1])
				localStorage.setItem('access_token',val[2])
				localStorage.setItem('refresh_token',val[3])
				localStorage.setItem('userid',val[4].slice(0, -2))
				localStorage.setItem('is_admin','true')
				localStorage.setItem('is_loyalty',val[5])
				localStorage.setItem('is_loyalty_active',val[6])
				localStorage.setItem('invalidName', '0');
				// this.myAccountMonthlyParkingObj.fetchUser()
				// .then(
				// 	(data:any)=> {
				// 		let loggedUsername = data['data'].name;
				// 		localStorage.setItem('username', loggedUsername);
				// 		window.location.href='/my-account/account';
				// 	},
				// 	error => {
				// 		window.location.href='/my-account/account';
				// 	}
				// );
				this.getVisitorCode();
			}
		});

	}
	fetchUserDetails() {
		this.myAccountMonthlyParkingObj.fetchUser()
		.then(
			(data:any)=> {
				let loggedUsername = data['data'].name;
				localStorage.setItem('username', loggedUsername);
				window.location.href='/my-account/account';
			},
			error => {
				//console.log('search-track fetchUser error')
				this.reloadComponent()
				//window.location.href=window.location.href;
				//window.location.href='/my-account/account';
			}
		);
	}
	reloadComponent() {
		let currentUrl = this.router.url;
			this.router.routeReuseStrategy.shouldReuseRoute = () => false;
			this.router.onSameUrlNavigation = 'reload';
			this.router.navigate([currentUrl]);
	}

	getVisitorCode() {
		this.userServiceObj.getVisitorCode()
		   .subscribe(
		   (data:any) => {
			 // var test = JSON.parse(data);
			 
			  localStorage.setItem('X-Big-Apple', data.data.visitor_code);
			  this.fetchUserDetails();
			},
		   error => {
			 let errorMessage = error.errors.message;
		   }
		 );
	   }
}
