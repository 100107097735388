import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { StaticpagesService } from '../../services/staticpages.service';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { CustomCode } from '../../shared/CustomCode';
import { WebMetaService } from '../../shared/web-meta.service';
import * as $ from 'jquery';

@Component({
  selector: 'app-careers',
templateUrl: './careers.component.html',
styleUrls: ['./careers.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [StaticpagesService, WebMetaService],
})


export class CareersComponent implements OnInit {
    is_career_page = true;
    pageId:string = 'careers'; 

    careers_ps_nyc ='careers_ps_nyc';
    careers_ps_newark ='careers_ps_newark';
    careers_aps_boston ='careers_aps_boston';

    cmsPageContent:any = {};
    sanatizeContent;
    isLoaded = false;
    baseurl = CustomCode.BASEURL;
    webdata:any;
    descriptionElement:any;
    path = window.location.pathname;
    metaTags: any;
    stripHeight = 0;
    constructor( private staticPageObj: StaticpagesService, private sanitized: DomSanitizer, private router:Router, private webMetaServiceObj: WebMetaService ) {
  
     }
  
  
    ngOnInit() {
        let currentRouter = this.router.url.toLowerCase();
        if(currentRouter.indexOf('/') > -1){
          currentRouter =  currentRouter.replace('/', '');
        }
        if(currentRouter.indexOf('-') > -1){
           currentRouter = currentRouter.split('-').join('_');
        }
        this.pageId =currentRouter;
        if(currentRouter == 'careers' || currentRouter == 'jobs'){
          this.is_career_page = true;
        }else {
           this.is_career_page =false;
        }

      //console.log(currentRouter);
      this.staticPageObj.getCmsPageContent(this.pageId).subscribe(
        (data:any)=> {
          this.cmsPageContent = data.result[0];
          this.sanatizeContent = this.sanitized.bypassSecurityTrustHtml(this.cmsPageContent.post_content);
          this.isLoaded = true;
        }, error => console.log("e" + error.object),
        
      )

    }

    getRouteforBookBtn(event){
      let routeUrlDefault = event.target.getAttribute('routerlink');
    
      if((routeUrlDefault && routeUrlDefault!='' && routeUrlDefault == '/employment-application')){
        this.fetchUserStatusForTracking();
        let headerHeight = $('.bar-section').outerHeight();
    
        if (navigator.userAgent.match(/iPad|iPhone|iPod|Windows Phone/i)) {  
          var offTop = $('#irameSection').offset().top - headerHeight;
          $(window).scrollTop(offTop);
        }else {
          let scrollToPosition = $('#irameSection').offset().top - headerHeight
          $('html').animate({ 'scrollTop': scrollToPosition }, 500);
        }    

     }else {
         return false
     }
     
      }


    fetchUserStatusForTracking(){
      let getUserId = '0';
      let getUserEmail = '0';
      let user_login_type = '';
      let isLogin = false;
      if(localStorage.getItem('access_token')){
       isLogin = true;
         getUserId = localStorage.getItem('userid');
         getUserEmail = localStorage.getItem('email');
         user_login_type = localStorage.getItem('user_login_type');
      }
      const loginStatusTrack = {
        'event' : 'pageview',
        'loginStatus' : isLogin? 'logged in': 'Not Logged IN', // 'logged in/Not Logged IN',
        'authenticationMethod' : user_login_type,
        'userId' : getUserId,
        'pageType' : this.router.url,
        'email': getUserEmail,
     };
    (<any>window).dataLayer.push(loginStatusTrack);
    }
  
    }
  
  
  
  