<!--  current balance and details -->
<div role="tabpanel" class="tab-pane fade active in" id="currentbalance">
  <h4 class="text-primary mar-top-0">CURRENT BALANCE</h4>
  <div class="row">
    <div class="col-xs-8 col-sm-5 col-md-3 col-lg-3 pad-right-0">
      Current Balance Due:
    </div>
    <div class="col-xs-4 col-sm-7 col-md-9 col-lg-9" *showItBootstrap="['sm','md','lg','xl']" sourceFont>
      ${{ accountDetails.curr_month_balance }}
    </div>
    <div class="col-xs-4 col-sm-7 col-md-9 col-lg-9 text-right"  *showItDevice="['mobile','tablet']" sourceFont>
      ${{ accountDetails.curr_month_balance }}
    </div>
  </div>
  <div class="row">
    <div class="col-xs-8 col-sm-5 col-md-3 col-lg-3 pad-right-0">
      Total Account Balance:
    </div>
    <div class="col-xs-4 col-sm-7 col-md-9 col-lg-9" *showItBootstrap="['sm','md','lg','xl']" sourceFont>
      ${{ accountDetails.balance }}
    </div>
    <div class="col-xs-4 col-sm-7 col-md-9 col-lg-9 text-right"  *showItDevice="['mobile','tablet']" sourceFont>
      ${{ accountDetails.balance }}
    </div>
  </div>
  <a routerLink="/my-account/monthly-parking/account/{{ accountDetails.account_number }}/make-payment" class="btn btn-primary btn-fill mar-top-sm hidden-xs">Make a Payment</a>
  <div class="clearfix visible-xs">
    <div class="col-xs-10 col-xs-offset-1">
      <a routerLink="/my-account/monthly-parking/account/{{ accountDetails.account_number }}/make-payment" class="btn btn-primary btn-fill mar-top-sm full-width">Make a Payment</a>
    </div>
  </div>
  <!-- {{ accountDetails | json }} -->
</div>