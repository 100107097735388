<app-header></app-header>
<app-lazy-load [isLoaded]="isLoadeds" [sectionName]="'content'"></app-lazy-load>
<ng-template [ngIf]="isLoadeds">

<div class="" [innerHtml]="staticPageContent?.content?.rendered" ></div>
<div class="pageWrapperLadning  specialPage">
<div class="container mt-5 pb-5 ">
  <div class="text-center mb-5 text-center">

  <div class="btn-group specialTab btn-group-justified ">
    <label (click)="updateFiler(0)" class="btn btn-primary" [(ngModel)]="radioModel"
           btnRadio="All" tabindex="0" role="button">All Specials</label>
    <label (click)="updateFiler(2)" class="btn btn-primary midButton" [(ngModel)]="radioModel"
           btnRadio="Monthly" tabindex="0" role="button">Monthly Specials</label>
    <label (click)="updateFiler(1)" class="btn btn-primary" [(ngModel)]="radioModel"
           btnRadio="Daily" tabindex="0" role="button">Daily Specials</label>
  </div>
</div>
  <div class="clearfix"></div>
  
  <ng-container *ngFor=" let item of staticPageContent?.sections_list; let index = index">
  <div class="listBlock bg-white" *ngIf="allBlock || ( daily && item?.offer_type =='Daily' || monthly && item?.offer_type=='Monthly'  ) ">
   <div class="row no-gutters">
  
     <div class="col-md col-12 content">
      <div class="imageBlock"  [ngStyle]="{'background-image': 'url(' + item.offer_image + ')'}" >
        <!-- <div class="imageBlock"  > -->
        <!-- <img *ngIf="item?.offer_image" [src]="item.offer_image" class="img-responsive"/> -->
       </div>
       <div class="px-4 pt-4  ">
        <h4 *ngIf="item?.offer_title" class="mb-3 font-weight-bold">{{item?.offer_title}}</h4>
      <p *ngIf="item?.offer_valid_dates" class="text-primary">Valid Dates: {{item?.offer_valid_dates}}</p>
      
      <ng-container *ngIf="item?.description && checkLengthOfDescription(item?.description, 0)">
        <div id="id-{{index}}" class="appended-wrapper-content   mt-3" [ngClass]="{'limitedContent': checkLengthOfDescription(item?.description, 0)}">
          <div class="limited-text text-wrap appended-text">
            <p><span [innerHtml]="getLimitedContent(item?.description)"></span> 
              <a class="limited-text" (click)="showFullContent(index)" style="cursor: pointer;">Read More</a>
            </p>

          </div>
        
          <div class="text-wrap appended-text full-text hide" >
            <p [ngClass]="{'pb-4': item.disclaimer ==''}" >
              <span [innerHtml]="item?.description"></span>
              <a class="show-limited-text full-text" (click)="showLimitedContent(index)" style="cursor: pointer;">Read Less</a>
            </p>
          </div>
          
        </div>
      </ng-container>
      <ng-container *ngIf="item?.description && !checkLengthOfDescription(item?.description, 0)">
        <div class=" mt-3 mb-4">
          <div class="text-wrap appended-text"><p [innerHtml]="item?.description"></p></div>
        </div>
      </ng-container>
  
    </div>  
    <small *ngIf="item?.disclaimer"><strong>Disclaimer</strong>: - {{item.disclaimer}}</small>
  </div>
     <div class="col-md-auto col-12 btnBlockFix  ">
       <div class="btnBlock text-center">

     
      <div *showItBootstrap="['xs']" cla  >
      <div class="row row align-items-center">
        <div *ngIf="item?.booked_amount" class="col">
          <p class="m-0">{{item?.booked_amount}}</p>
        </div>
        <div class="col">
            <button (click)="redirectTOUrl(item)" class="btn btn-primary btn-fill" >
       {{(item?.offer_button_text ? item.offer_button_text : 'Book Now')}}
     </button>
        </div>
      </div>
      </div>
      <div *hideItBootstrap="['xs']" class="p-4">
        <button (click)="redirectTOUrl(item)" class="btn btn-primary btn-fill">
          {{(item?.offer_button_text ? item.offer_button_text : 'Book Now')}}
        </button>  
        <p *ngIf="item?.booked_amount" class="m-0 mt-2">{{item?.booked_amount}}</p>
   
       </div>
   </div>
  </div>
   </div>
  </div>
  </ng-container>

</div>
</div>
</ng-template>
<app-footer></app-footer>