import { Component, OnInit, ElementRef,Pipe, PipeTransform } from '@angular/core';
import { Router,ActivatedRoute,Params } from '@angular/router';
//import { Http, Headers, RequestOptions, Response } from '@angular/http';
import { FAQContentServic } from '../../services/faq-content.service';
import { Observable } from 'rxjs/Rx';
import { ToastrService } from 'ngx-toastr';
import { Location} from '@angular/common';
// Import RxJs required methods
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { DomSanitizer } from '@angular/platform-browser'
//import {SafeHtmlPipe } from '../../Components/promocode-cms-landing/promocode-cms-landing.component';
import { StaticpagesService } from '../../services/staticpages.service';
import * as $ from 'jquery';

@Pipe({ name: 'safeHtmlMobile'})
export class SafeHtmlMobilePipes implements PipeTransform  {
  constructor(private sanitized: DomSanitizer) {}
  transform(value) {
    return this.sanitized.bypassSecurityTrustHtml(value);
  }
}
@Component({
  selector: 'app-faq',
  templateUrl: './faq-mobile.component.html',
  styleUrls: ['./faq-mobile.component.scss'],
  providers: [StaticpagesService]
})
export class FaqMobileComponent implements OnInit {

  public isFirstOpen: boolean = true;
  page: String = 'frequently-asked-questions';
  staticPageContent: any = {};
  isOnQuestions: any;
  openQuestions: any=1;
  questionAnswer:any
  questionAnswerStore:any
  questionAnswerCategory:any;
  srcBarActive:any;
  isLoaded=false;
  showHide:any='x';
  hide=false
  isShow:any=0;
  categoryName='';
  catName=''
  openQuestionsWithCategory=true;
  quesName=''
  showBack=false
  isOnQuestionsShow=true
  fromUrl='no';
  searchKeyword='';
  search_icon = '../assets/img/search-icon.svg';
  phoneIcon = '/assets/img/phone.svg';
  mailIcon = '/assets/img/mail.svg'
  phoneNumber = 'tel:1 (877) 727-5464';
  mailId = 'mailto:customerservice@iconparking.com';

  constructor(private location:Location,private activatedRouteObj: ActivatedRoute,private toasterObj: ToastrService,private faqcontnent:FAQContentServic,private router: Router, private staticObj: StaticpagesService) {
    this.isOnQuestions = false;
    this.openQuestions = true;
    this.srcBarActive = false;
  }

  public customClass: string = 'questionsLink';
  ngOnInit() {
    this.isLoaded=false;
     this.faqcontnent.fetchContent('all').subscribe(
       (data:any)=>{ this.questionAnswerStore=this.questionAnswerCategory=this.questionAnswer=data.subcategory
       this.isLoaded=true;
       this.hide=true
       this.showBack=false;
       this.isOnQuestionsShow=false
       this.activatedRouteObj.params.subscribe((params: Params) => {
        if (params['template_cat']) {
          this.catName = params['template_cat'];
          this.isOnQuestions = true;
          this.isOnQuestionsShow=true
          this.moreLinkShowCategory(2,params['template_cat'])
        }
        if(params['template_que']){
          this.quesName=params['template_que'];
          this.fromUrl='yes'
        }
        if(!params['template_cat'])
        {
          if(localStorage.getItem('searchKeyword'))
          {
            
            setTimeout(() => { 
              $('#searchText').val(localStorage.getItem('searchKeyword'))
              $('#buttonSearch').trigger('click');
              localStorage.removeItem('searchKeyword')
            },500);
          }
        }
       
      });
     
      },error=>console.log("e"+error.object));
  }
  moreLinkQuestion(text,question)
  {
   localStorage.setItem('searchKeyword',$('#searchText').val())
   this.router.navigate(['/faq-mobile/', text,question]);
  }
 
  moreLinkQuestionC(i,j)
  {
    // this.showHide=i+''+j;
    // window.scrollTo(0,0);
    // this.isOnQuestions = true;
    // this.openQuestions = false;
    // $('.questiongroup').addClass('hide');
    // $('#hide'+i).removeClass('hide')
    // $('#'+i+''+j).trigger('click');
    // $('.question .card-block').css('display','none');
  }
  clearfaq()
  {
     $('#searchText').val('a');
     $('#searchText').val('');
     this.searchFaq();
     this.srcBarActive = false;
     this.location.replaceState('/faq-mobile');
  }
  searchFaq()
  {

    this.srcBarActive = true;
    this.openQuestionsWithCategory=false;
    this.isOnQuestions=true;
    this.isShow=0;
    this.questionAnswer=this.questionAnswerStore
    var slug=$('#searchText').val()
    if(slug.replace(/\s/g, "")=='')
    {
      slug='';
    }
    this.hide=false
    this.isLoaded=false;
    let check=[];
    var j=0;
    var k=0;
    var found=0;
    let question=[]
    let catName=[];
    if(slug!='')
    {
      for(var i=0; i<this.questionAnswer.length;i++)
      {
        if(this.questionAnswer[i].name.toLowerCase().indexOf(slug.toLowerCase())>-1)
        {
            catName[i]=this.questionAnswer[i].name
            check[j]=this.questionAnswer[i];
            j++
            found=1;
        }
      }
      for(var i=0; i<this.questionAnswer.length;i++){
        var k=0;
        question=[]
          for(var p=0;p<this.questionAnswer[i].content.length;p++)
          {
            if(this.questionAnswer[i].content[p].q.toLowerCase().indexOf(slug.toLowerCase())>-1)
            {
              question[k++]=this.questionAnswer[i].content[p];
              
            }else if(this.questionAnswer[i].content[p].a.toLowerCase().indexOf(slug.toLowerCase())>-1)
            {
              question[k++]=this.questionAnswer[i].content[p];
            }
          }
          if(question.length > 0 && catName.indexOf(this.questionAnswer[i].name)==-1 ){   
            check[j]={'name':this.questionAnswer[i].name,'content':question}
            found=1;
            j++
          }
         
        }
        
      this.questionAnswer=check;
   }
   if(found==0)
   {
     // this.toasterObj.error('No results found.', 'Sorry');
     this.isShow=1;
     this.openQuestionsWithCategory=true;
     this.isOnQuestions=false;
      this.location.replaceState('/faq-mobile');
     if(slug=='')
     {
       this.isShow=0;
       this.srcBarActive = false;
     }
     this.questionAnswer=this.questionAnswerStore
   }
   this.isLoaded=true;
   this.hide=true
  // this.isOnQuestions = false;
   this.openQuestions = true;
  }
  focusFunction(){
    this.srcBarActive = true;
  }
  focusOutFunction(){
    var slug=$('#searchText').val()
    if(slug.replace(/\s/g, "")=='')
    {
      this.srcBarActive = false;
    }
     
   }
  moreLink(value:number) {
    window.scrollTo(0,0);
    this.isOnQuestions = true;
    this.openQuestions = false;

    $('.questiongroup').addClass('hide');
    $('#hide'+value).removeClass('hide')
  }
  moreLinkCategory(text:string)
  {
   localStorage.setItem('searchKeyword',$('#searchText').val())
   this.router.navigate(['/faq-mobile/', text]);
  
  }
  moreLinkShowCategory(value:number,text:string)
  {
    window.scrollTo(0,0);
    $('.questiongroup').addClass('hide')
    this.searchFaqOnlyCate(text);
    this.isOnQuestions = true;
    this.openQuestions = false;
    this.isLoaded=false
    this.showBack=false
    setTimeout(() => { 
      $('#hide'+this.catName.split(' ').join('')).removeClass('hide');
      this.isLoaded=true
      this.showBack=true;
      if(this.quesName!='')
      {
        this.openQuestion();
      }
    },100);
    
    //this.searchFaq(text);
    
  }
  openQuestion()
  {
    setTimeout(() => { 
      $('#'+this.catName.split(' ').join('')+''+this.quesName).trigger('click');
    },500);
       
  }
   moreLinkShowsQuestion(text,number)
  {
      this.location.replaceState('/faq-mobile/'+text+'/'+number);
  }
  searchFaqOnlyCate(text)
  {
    this.isShow=0;
    this.questionAnswer=this.questionAnswerStore
    var slug=$('#searchText').val()
    if(slug.replace(/\s/g, "")=='')
    {
      slug='';
    }
    this.hide=false
    this.isLoaded=false;
    let check=[];
    var j=0;
    var found=0;
    if(slug!='')
    {
      for(var i=0; i<this.questionAnswer.length;i++)
      {
        if(this.questionAnswer[i].name.toLowerCase().indexOf(slug.toLowerCase())>-1)
        {
            check[j]=this.questionAnswer[i];
            j++
            found=1;
        }
      }
      this.questionAnswer=check;

   }
   if(found==0)
   {
     // this.toasterObj.error('No results found.', 'Sorry');
     this.isShow=1;
     if(slug=='')
     {
       this.isShow=0;
       this.srcBarActive = false;
     }
     this.questionAnswer=this.questionAnswerStore
   }
   this.isLoaded=true;
   this.hide=true
   this.isOnQuestions = true;
   this.openQuestions = false;
  }
  homeBack() {
   this.router.navigate(['/faq-mobile']);
  }

}