import { Component, OnInit ,ViewEncapsulation,ElementRef,Pipe, PipeTransform} from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { SearchpageserviceService } from '../../services/searchpageservice.service';
import { StaticpagesService } from '../../services/staticpages.service';
import { CustomCode } from '../../shared/CustomCode';
import { DomSanitizer } from '@angular/platform-browser';
import { NgForm } from '@angular/forms';
declare var google: any;
import * as $ from 'jquery';
import { UserserviceService } from '../../services/userservice.service';
// import { setTimeout } from 'timers';

@Pipe({ name: 'safeHtml'})

export class SafeHtmlRenewalWebPipe implements PipeTransform  {
  constructor(public sanitizer: DomSanitizer) {}

  transform(value) {
    return this.sanitizer.bypassSecurityTrustHtml(value);
  }
}

@Component({
  selector: 'app-renewal-confirmation-web',
  templateUrl: './renewal-confirmation-web.component.html',
  styleUrls: ['./renewal-confirmation-web.component.scss'],
  providers: [SearchpageserviceService, StaticpagesService,UserserviceService],
  encapsulation:ViewEncapsulation.None
})
export class RenewalConfirmationWebComponent implements OnInit {
  margin_height="0px";
  buymonthlybannerimage="";
  isresponsive=false;
  respdata;
  resId;
  isFrame:any;
  isCollapsed = false;
  page = 'monthly-renewal-confirmation';
  cmsData:any
  isLoaded = false;
  reservation_data:any
  reservation_facility_details:any;
  monthly_request:any;
  facility_data:any;
  lat='40.7589';
  long='-73.9851';
  loyalityVal = 0;
  responseFromReservation = 0;
  facilityName = '';
  appendMap = 0;
  oversizedFee = '';
  accountNumber = '';
  isLoyaltyMember:boolean = false;
  isLoyality = false;
  constructor( private staticObj: StaticpagesService, 
    private searchPageServiceObj: SearchpageserviceService,
    private activatedRouteObj: ActivatedRoute,
    private elementRef:ElementRef,
    private router:Router
    ) { }

  backBtn = `<li class="active"><a href="javascript:;" onclick="history.go(-1);" class="btn btn-link pad-left-0"><fa name="angle-left"></fa> &nbsp; Back</a></li>`

  ngOnInit() {
    let url = 'buymonthly_banner';
    this.staticObj.getCmsPageContent(url).subscribe(
      (data:any)=> {
        
        let cmsPageContent = data.result[0];
        console.log(cmsPageContent)
        console.log(cmsPageContent.desktop_image)
        console.log(cmsPageContent.mobile_image)
        
        if ($(window).width() >= 768  ) {
          //alert('1')
          this.isresponsive=false;
          this.buymonthlybannerimage=cmsPageContent.desktop_image;
        }
        else if (navigator.userAgent.match(/(iPod|iPhone|iPad)/i)) {
          this.buymonthlybannerimage=cmsPageContent.mobile_image;
          //alert('2')
          this.margin_height="iosbannercls";
        }
        else{
          //alert('3')
          this.buymonthlybannerimage=cmsPageContent.mobile_image;
          this.margin_height="androidbannercls";
        }
        
        
      }, error => console.log("e" + error.object),
      
    )
    if(localStorage.getItem('is_loyalty_active') && localStorage.getItem('is_loyalty_active')=='1')
    {
      this.isLoyality = true
    }

    if(window.self!==window.top)
    {
      this.isFrame = true;
    }else{
      this.isFrame = false;
    }
    if(localStorage.getItem('is_loyalty')==="1" && localStorage.getItem('is_loyalty_active')=='1'){
      this.isLoyaltyMember = true;
    }
    this.activatedRouteObj.params.subscribe((params: Params) => {
      // console.log(params, 'data of url here')
      this.resId = params['resId'];
      this.loyalityVal = params['id'];
	  
	  this.searchPageServiceObj.getMonthlyParkingReservationDetails(this.resId).subscribe(
        (res) => {
          
          // console.log(res, 'data here')
          this.respdata = res;
          this.respdata =  this.respdata.data;
          let monthly_rate = this.respdata.monthly_request.monthly_rate
          /* commented as per client  at 29-7-2021
          let USI_orderID = this.resId;
          let USI_orderAmt = monthly_rate;
          let USI_currency = 'USD';
          var USI_headID = document.getElementsByTagName("head")[0];
          var USI_dynScript = document.createElement("script");
          USI_dynScript.setAttribute('type','text/javascript');
          USI_dynScript.src = 'https://www.upsellit.com/active/iconparking_pixel.jsp';
          USI_headID.appendChild(USI_dynScript);
          */
          
        this.facility_data = this.respdata.facility_data;
          let monthly_duration_value = '';
          let numberPlate = '';
          let startDate:any ;
          let endDate:any ;
          let is_oversized = '';
          let is_247_open = '';
          let account_number='';
          let paymentType = '<img src="assets/img/card-grey.svg" alt="" height="16">';
          let payment_last_four = '';
          let expiration_date = '';
          let paymentDetails = '-';
          let imageUrl = '';
          let valicleColor = '';
          let address_1 = '';
          let address_2 = '';
          let companyName = '';
          let driversInfo = '';
          let paymentMethodSHow=0;
          let between_streets = '';
          let additional_driver_1 =''; let additional_driver_2 =''; let additional_driver_3 ='';
          if(this.respdata.monthly_request['additional_driver_1'] && this.respdata.monthly_request['additional_driver_1'] != null ){ additional_driver_1 = this.respdata.monthly_request.additional_driver_1 }
          if(this.respdata.monthly_request['additional_driver_2'] && this.respdata.monthly_request['additional_driver_2'] != null ){ additional_driver_2 = this.respdata.monthly_request.additional_driver_2 }
          if(this.respdata.monthly_request['additional_driver_3'] && this.respdata.monthly_request['additional_driver_3'] != null) {  additional_driver_3 = this.respdata.monthly_request.additional_driver_3   }
          

          if(additional_driver_1 != '' || additional_driver_2 != '' || additional_driver_3 != ''){
           driversInfo = 'show';
          }else {
            driversInfo = 'hide';
          }

          if(this.respdata.monthly_request.account_number){ account_number = this.respdata.monthly_request.account_number}else {account_number =''}

          if(this.respdata.monthly_request.monthly_duration_value){
            monthly_duration_value = this.respdata.monthly_request.monthly_duration_value;
          }else {
            monthly_duration_value = '';
          }
          if(this.respdata.facility_data.is_247_open == 1 ){
            is_247_open = '<p class="cc"><span class="ismall text-primary fa fa-calendar"></span> &nbsp;  7 days a week</p><p class="cc"><span class="ismall text-primary fa fa-clock-o time-0" ></span> &nbsp; 24 hours a day </p>';
          }else {
            let hoursData = '';
            if( 'hours_of_operation_data' in this.respdata.facility_data && this.respdata.facility_data['hours_of_operation_data'] != null && this.respdata.facility_data['hours_of_operation_data'].length>0 ){
              this.respdata.facility_data.hours_of_operation_data.map( ( wh, index )=> {
                hoursData = hoursData +  `<tr><td>${wh.day}</td> <td>:</td><td>${wh.formatted_open_time}</td><td>-</td> <td>${wh.formatted_close_time} ${this.showtimefornxtornot(wh.close_time)}</td></tr>`;
              } )
              is_247_open =  `<div class="TimeActive table-responsive">
                        <h4>Hours of Operation</h4>
                        <table class="table table-condensed tbl-condensed">
                                ${hoursData}
                        </table>
                    </div>`
            }

          }
          
          if(this.respdata.monthly_request.vehicle_plate){
            numberPlate = `<p>License: ${this.respdata.monthly_request.vehicle_plate}</p>`;
           }else {
            numberPlate = '';
           }

           

           if(this.respdata.monthly_request.account_number) {
            this.accountNumber = this.respdata.monthly_request.account_number
           }else {
            this.accountNumber = ''
           }

           if(this.respdata.monthly_request.oversize_fee != null && this.respdata.monthly_request.oversize_fee != '0.00' ){
            is_oversized = 'Oversized Vehicle';
          } else if(this.respdata.monthly_request.electric_free != null && this.respdata.monthly_request.electric_free != '0.00' ){
            is_oversized = 'Electric Vehicle';
          }
          else if(this.respdata.monthly_request.exotic_fee != null && this.respdata.monthly_request.exotic_fee != '0.00'  ){
            is_oversized = 'Exotic Vehicle';
          }else {
            is_oversized = '';
          }
          valicleColor =  this.respdata.monthly_request.vehicle_color.toUpperCase();
          if( this.respdata.facility_data['photo'] && this.respdata.facility_data.photo['url'] ){
            imageUrl = this.respdata.facility_data.photo.url;
          }else {
            imageUrl  = 'https://staging.iconparkingsystems.com/cms/wp-content/uploads/2020/09/park_garage.png'
          }
          if(this.respdata.monthly_request.desired_start_date){
            // startDate =new Date(this.respdata.monthly_request.desired_start_date)
            // var options = { month: 'long', day: '2-digit', year: 'numeric' };
            // startDate = startDate.toLocaleDateString("en-us", options);

            const months = ['no-month','January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
            let dateSplit=this.respdata.monthly_request.desired_start_date.split('-');
            const month =  months[parseInt(dateSplit[1])];
            let newDate=dateSplit[0]+'-'+month+'-'+dateSplit[2]
            newDate = month + ' '+ dateSplit[2] + ', '+ dateSplit[0];
            
            if(this.respdata.monthly_request.hasOwnProperty('subscription_end_date')){
              let dateSplit_end=this.respdata.monthly_request.subscription_end_date.split('-');
              const month_end =  months[parseInt(dateSplit_end[1])];
              let newDate_end=dateSplit_end[0]+'-'+month_end+'-'+dateSplit_end[2]
              newDate_end = month_end + ' '+ dateSplit_end[2] + ', '+ dateSplit_end[0];
              endDate = newDate_end;
              }else{
              endDate = '';
              }

            

            //alert(newDate);
           // startDate =new Date(newDate)
           // var options = { month: 'long', day: '2-digit', year: 'numeric' };
            startDate = newDate;
           

          }         
          if('payment_profile' in this.respdata.monthly_request && 'payment_profile_id' in this.respdata.monthly_request && this.respdata.monthly_request['payment_profile'] != null ){
            let type = this.respdata.monthly_request.payment_type.toLowerCase()
            if(type=='visa'){
              paymentType = '<img src="assets/img/visa.svg" alt="" height="16">';
            }else if (type=='mastercard') { 
              paymentType = '<img src="assets/img/mastercard.svg" alt="" height="16">';
            }else if (type=='discover') { 
              paymentType = '<img src="assets/img/discovercard.svg" alt="" height="16">';
            }else if (type=='americanexpress'  || type=='amex') {  
              paymentType = '<img src="assets/img/amex.svg" alt="" height="16">';
            }else if (type=='jcb') { 
              paymentType = '<img src="assets/img/jcbcard.svg" alt="" height="16">';
            }else {
              paymentType = '<img src="assets/img/card-grey.svg" alt="" height="16">';
            }
            payment_last_four = this.respdata.monthly_request.payment_last_four
            paymentDetails = `${paymentType}<span class="mar-left-xs">${this.respdata.monthly_request.payment_method_type} Ending in ${payment_last_four}`;
            if( 'payment_profile' in this.respdata.monthly_request && this.respdata.monthly_request['payment_profile'] != null  ){
              if('expiration_date' in this.respdata.monthly_request.payment_profile && this.respdata.monthly_request.payment_profile['expiration_date'] != null && 'formatted' in this.respdata.monthly_request.payment_profile.expiration_date){
                expiration_date = this.respdata.monthly_request.payment_profile.expiration_date.formatted;
                paymentDetails = `${paymentType}<span class="mar-left-xs">${this.respdata.monthly_request.payment_method_type} Ending in ${payment_last_four} Expiring ${expiration_date}</span>`;
              }
            }
          }
          if('vm_payment_profile' in this.respdata.monthly_request && 'vm_payment_profile_id' in this.respdata.monthly_request && this.respdata.monthly_request['vm_payment_profile'] != null ){
            let type = this.respdata.monthly_request.payment_type.toLowerCase()
            if(type=='visa'){
              paymentType = '<img src="assets/img/visa.svg" alt="" height="16">';
            }else if (type=='mastercard') { 
              paymentType = '<img src="assets/img/mastercard.svg" alt="" height="16">';
            }else if (type=='discover') { 
              paymentType = '<img src="assets/img/discovercard.svg" alt="" height="16">';
            }else if (type=='americanexpress'  || type=='amex') { 
              paymentType = '<img src="assets/img/amex.svg" alt="" height="16">';
            }else if (type=='jcb') { 
              paymentType = '<img src="assets/img/jcbcard.svg" alt="" height="16">';
            }
            payment_last_four = this.respdata.monthly_request.payment_last_four
            paymentDetails = `${paymentType}<span class="mar-left-xs">Card Ending in ${payment_last_four}`;
            if( 'vm_payment_profile' in this.respdata.monthly_request && this.respdata.monthly_request['vm_payment_profile'] != null  ){
              if('expiration_date' in this.respdata.monthly_request.vm_payment_profile && this.respdata.monthly_request.vm_payment_profile['expiration_date'] != null && 'formatted' in this.respdata.monthly_request.vm_payment_profile.expiration_date){
                expiration_date = this.respdata.monthly_request.vm_payment_profile.expiration_date.formatted;
                paymentDetails = `${paymentType}<span class="mar-left-xs">Card Ending in ${payment_last_four} Expiring ${expiration_date}</span>`;
              }
            }
          }
          if(paymentDetails!='-')
          {
            paymentMethodSHow=1
          }else{
            paymentMethodSHow=0
          }
          if(this.respdata.facility_data['geolocation'] && this.respdata.facility_data.geolocation['latitude']){
            this.lat = this.respdata.facility_data.geolocation.latitude
          }
          if(this.respdata.facility_data['geolocation'] && this.respdata.facility_data.geolocation['longitude']){
            this.long = this.respdata.facility_data.geolocation.longitude
          }
          if(this.respdata.monthly_request.address_1 && this.respdata.monthly_request.address_1 != null){
            address_1 = this.respdata.monthly_request.address_1
          }
          if(this.respdata.monthly_request.address_2 && this.respdata.monthly_request.address_2 != null){
            address_2 = this.respdata.monthly_request.address_2
          }

          if(this.respdata.monthly_request.company_name && this.respdata.monthly_request.company_name != null){
            companyName = '<p>'+this.respdata.monthly_request.company_name+'</p>';
          }
          let text=''
          if(this.respdata.monthly_request.vehicle_make == this.respdata.monthly_request.vehicle_model){
            text = this.respdata.monthly_request.vehicle_make;
          }else{
            text =this.respdata.monthly_request.vehicle_make+' '+this.respdata.monthly_request.vehicle_model
          }
          
          if(this.respdata.facility_data.between_streets.length>0){
            between_streets = `<p class="mar-top-0"><strong>Between:</strong> &nbsp;<span>${this.respdata.facility_data.between_streets}</span></p>`;
           }
          
         this.facilityName = this.respdata.facility_data.facility_name_modified;
         this.oversizedFee = '$'+this.respdata.facility_data.monthly_oversized_fee;
         let URLGETDIRECTION = 'https://www.google.com/maps?q='+this.respdata.facility_data.entrance_location+'+'+this.respdata.facility_data.geolocations.city+'+'+this.respdata.facility_data.geolocations.state+'+'+this.respdata.facility_data.geolocations.zip_code;
         


                this.reservation_data = `<div class="reservation-tab visible-xs">
                <div class="reservation-rt">
                  <h2 class="mar-top-0">${this.respdata.facility_data.facility_name_modified}</h2>
                  <p class="mar-bottom-0"><b>${this.respdata.facility_data.entrance_location}</b></p>
                  ${between_streets}
            
                  ${is_247_open}
                </div>
                <div class="devide-border"></div>
                </div>
                  <div class="reservation-tab">
                  <div class="row">
                   <div class="col-md-3"><p><strong>Account Number</strong></p></div>
                   <div class="col-md-9"><p>${account_number}</p></div>
                  </div>
                  <div class="devide-border"></div>
                  </div>
                  
                  <div class="reservation-tab">
                  <div class="row">
                      <div class="col-md-3"><p><strong>Monthly Subscription Term</strong></p></div>
                      <div class="col-md-9">
                        <p>${monthly_duration_value}</p>
                        <p>Start Date: ${startDate}</p>
                        <p>End Date: ${endDate}</p>
                      </div>
                  </div>
                  <div class="devide-border"></div>
                 </div>
                 <div class="reservation-tab">
                  <div class="row">
                      <div class="col-md-3"><p><strong>Applicant Info</strong></p></div>
                      <div class="col-md-9">
                        <p>${this.respdata.monthly_request.first_name} ${this.respdata.monthly_request.last_name}</p>
                        <p><a class="text-dark"  href="mailto:${this.respdata.monthly_request.email}">${this.respdata.monthly_request.email}</a> </p>
                        <p><a class="text-dark"  href="tel:${this.respdata.monthly_request.phone}">${this.respdata.monthly_request.phone}</a>
                        </p>
                        ${companyName}
                        <p>${address_1} ${address_2}
                        <br /> ${this.respdata.monthly_request.city} ${this.respdata.monthly_request.state}
                        ${this.respdata.monthly_request.zip_code}
                        </p>
                      </div>
                  </div>
                  <div class="devide-border"></div>
                 </div>
                 <div class="reservation-tab">
                  <div class="row">
                      <div class="col-md-3"><p><strong>Vehicle Info</strong></p></div>
                      <div class="col-md-9">
                        <p>${text}
                        ${valicleColor}</p>
                        ${numberPlate}
                        <p>${is_oversized}</p>
                      </div>
                  </div>
                  <div class="devide-border"></div>
                 </div>
                 <div class="reservation-tab">
                  <div class="row">
                      <div class="col-md-3"><p><strong>Payment Method</strong></p></div>
                      <div class="col-md-9"><p class="cc">${paymentDetails}</p></div>
                  </div>
                  <div class="devide-border"></div>
                 </div>
                 <div class="reservation-tab ${driversInfo}">
                  <div class="row">
                      <div class="col-md-3"><p><strong>Authorized Drivers</strong></p></div>
                      <div class="col-md-9">
                        <p>${additional_driver_1} </p>
                        <p>${additional_driver_2}</p>
                        <p>${additional_driver_3}</p>
                      </div>
                  </div>
                  <div class="devide-border"></div>
                 </div>
                 <div class="reservation-tab">
                  <div class="row">
                      <div class="col-md-12"><p><strong class="ammount-charge">Amount charged to card</strong></p></div>
                      <div class="col-md-12"><p class="reservation-value"><strong>$${this.respdata.monthly_request.monthly_rate}</strong> </p></div>
                      <div class="col-md-12"><a id="print-reservation" class="print-reservation btn btn-primary btn-fill theme-btn mar-top-xs hidden-xs btn-radius">Print Reservation</a></div>
                  </div>
                 </div>`;
                 //<div class="col-md-12"><p class="reservation-value"><strong>${this.respdata.monthly_request.monthly_rate}</strong> <span class="text-primary">You saved $25.00 a month!</span></p></div>

                 this.reservation_facility_details = `<div class="reservation-facility-details">
            <div class="clearfix slideShow">
              <div id="carousel-id" class="carousel slide" data-ride="carousel">
                
                <div class="carousel-inner">
                  <div class="item active" alt="image">
                    <img src="${imageUrl}" alt="image">
                  </div>
                </div>
              </div>
              
            </div>
            <div class="reservation-rt">
              <h2 class="mar-top-0">${this.respdata.facility_data.facility_name_modified}</h2>
              <p class="mar-bottom-0"><b>${this.respdata.facility_data.entrance_location}</b></p>
              ${between_streets}

              
              ${is_247_open}
              <div class="googleMap mar-top-xs" id="map-wrapper">
                <iframe src="https://maps.google.com/maps?q=${this.lat},${this.long}&hl=es;z=14&output=embed" width='257'  height='170' frameborder='0'  scrolling='no'  marginheight='0'  marginwidth='0'  allowfullscreen></iframe>
              </div>
              <p class="mar-top-xs"><a target="_blank" href="${URLGETDIRECTION}" class="btn theme-btn btn-primary theme-btn btn-block outline-btn" rel="noopener noreferrer">get directions</a></p>
            </div>
            </div>`;
            this.responseFromReservation  = 1;
            // setTimeout( ()=> {
            //   this.bindElement();
            // },10000 )
        },
        (error) => { console.log('Error: ' + JSON.stringify(error)); }
      );
	  
    });
   
     

    this.staticObj.getCmsPageContent(this.page).subscribe(
      (data:any)=> {
        this.cmsData  = data.result[0];
        this.cmsData = this.cmsData.post_content
        this.isLoaded = true;
        let c = setInterval( () =>{
          if(this.responseFromReservation == 1){
            if(this.isFrame && this.cmsData){
              this.cmsData = this.cmsData.replace("{{back}}",this.backBtn);
            }else {
              this.cmsData = this.cmsData.replace("{{back}}",'');
            }
            
            this.cmsData = this.cmsData.replace("{{facility_name}}", this.facilityName);
            this.cmsData = this.cmsData.replace("{{reservationData}}", this.reservation_data);
            this.cmsData = this.cmsData.replace("{{reservationFacilityDetails}}", this.reservation_facility_details);
            this.cmsData = this.cmsData.replace("{{oversizedVehicleSurcharge}}", this.oversizedFee);
            this.cmsData = this.cmsData.replace("{{accountNumber}}", this.accountNumber);
            
            if(this.loyalityVal == 1){
              $('.loyality-member').addClass('hide');
              $('.loyality-member-web').removeClass('col-md-4').addClass('col-md-6');
              $('.appLinkWrapper').addClass('pad-left-0');
              $('.loyality-member-web').addClass('black-2');
             }

             clearInterval(c);
            this.bindElement();
             
          }
        }, 100);
        // setTimeout( ()=> {
        //   clearInterval(c);
        //   this.bindElement();
        // }, 5000 )
        let d = setInterval( () =>{
        $('.googleMap').html("<iframe width='257' height='170' frameborder='0' scrolling='no' marginheight='0' marginwidth='0' src='https://maps.google.com/maps?q="+this.lat+","+this.long+"&hl=es;z=14&amp;output=embed' allowfullscreen>");
          this.appendMap = 1;
          if(this.appendMap){
            clearInterval(d);
          }
      }, 100);
      
      }, (error) => { console.log('Error: ' + JSON.stringify(error)); 
      this.isLoaded = true;
    }
    )

  }

  onClickBook(event) {
    // console.log($('div.payment-section').html())
    this.fetchUserStatusForTracking();  // fetch user status
    $('div#printable').html($('.printData').html()).show(); 
    $('div#content-Wrapper').hide();
    window.print();
    $('div#printable').html('').hide();
    $('div#content-Wrapper').show();
    
  }

  bindElement()
    {
     let a = setInterval(() => {
        if(this.elementRef.nativeElement.querySelector('#print-reservation')) {
           this.elementRef.nativeElement.querySelector('#print-reservation').addEventListener('click', this.onClickBook.bind(this));
            clearInterval(a);
        }
        }, 100);
    }
    showtimefornxtornot(text)
    {
      var res = text.split(':');
      if(res[0]>24)
      {
        return '(next day)'
      }else{
        return '';
      }
    }

    fetchUserStatusForTracking(){
      let getUserId = '0';
      let getUserEmail = '0';
      let user_login_type = '';
      let isLogin = false;
      if(localStorage.getItem('access_token')){
       isLogin = true;
         getUserId = localStorage.getItem('userid');
         getUserEmail = localStorage.getItem('email');
         user_login_type = localStorage.getItem('user_login_type');
      }
      const loginStatusTrack = {
        'event' : 'pageview',
        'loginStatus' : isLogin? 'logged in': 'Not Logged IN', // 'logged in/Not Logged IN',
        'authenticationMethod' : user_login_type,
        'userId' : getUserId,
        'pageType' : this.router.url,
        'email': getUserEmail,
     };
    (<any>window).dataLayer.push(loginStatusTrack);
    
    }

  }

