<div role="tabpanel" class="tab-pane fade active in">
    <div *ngIf="isLoaded">
      <div class="faq-content" [innerHTML]="sanatizeContent"></div>
    </div>  
     <app-lazy-load [isLoaded]="isLoaded" [sectionName]="''"></app-lazy-load>
  </div>

<!-- <div id="newGift" class="bg-white">
  <section class="logo bg-dark p-4 px-5 w-100" >
    <a href="../" target="_blank" rel="noopener noreferrer"><img src="../../assets/img/icon_logo.svg" height="40" alt="Icon Parking"/></a>
  </section>
  <section class="header1-sec">
    <div class="firstImg">
      <img  src="./../../../assets/img/landing-pages/new-gift/banner-1.jpg" class="w-100" alt="12 Days of Gifting" />
    </div>
    <div class="bg-primary d-none p-3 w-100 "></div>
    <div class="heading-title mt-4">
      <div class="container container-top">
        <div class="heading-center mb-5 ">
          <h2 class="find-title text-primary text-center" title="Icon Parking gives you 
          the Gift of Savings!"><span>Icon Parking gives you 
             <br class="hidden-xs" >the Gift of Savings!</span></h2>
        
             <h3 class="text-center text-dark">Celebrate with Multiple Parking Offers!</h3>
         
        </div>
      </div>
        <div class="bg-primary text-center p-4">
          <img class="img-responsive mx-auto" src="../../../assets/img/landing-pages/new-gift/banner-top.svg">
        </div>
        <div class="container">
          <h3 class="text-center  my-5"> Sunday - Wednesday </h3>

          <div class="row justify-content-center">
            <div class="col-sm-auto col-12 leftSec">
              <div class="d-flex mb-3">
                <h1 class="text-primary">20%</h1><span class="text-primary text-italic">Off<br>
                  daily <br>
                  rates </span>
              </div>
              <h4 class="my-4">Mon-Thurs Enter before 4pm</h4>
              <a href="https://staging2.iconparkingsystems.com/specials-promo/GIFT20E/apply" target="_blank" class="btn btn-primary btn-fill btn-round px-5 shadowBtn" rel="noopener noreferrer">
                <img src="../../../assets/img/landing-pages/new-gift/arrow.svg"> &nbsp; BOOK NOW
              </a>

            </div>
           

            <div class="col-sm-auto col-12 ml-sm-5 rightSec">
              <h4 class="mt-0 mb-2">Promo code for you</h4>
              <h3 class="text-primary mb-3 mt-0"><i>GIFT20E</i></h3>
              <h4 class="mt-0 mb-2">Promo code for a friend</h4>
              <h3 class="text-primary mb-3 mt-0"><i>FRIENDGIFT20E</i></h3>
              <p class="m-0 color-99">Book By <span class="text-primary"><b><i>December 22, 2021</i></b></span></p>
              <p class="m-0 color-99">Park by <span class="text-primary"><b><i>February 28, 2022</i></b></span></p>
            </div>

          </div>

      <div class="row  mt-5 justify-content-center">
        <div class="col-sm-auto col-12 leftSec">
          <div class="d-flex mb-3">
            <h1 class="text-primary">30%</h1><span class="text-primary text-italic">Off<br>
              nights &amp; <br>
              weekend </span>
          </div>
          <h4 class="my-4">Mon-Thurs Enter after 4pm<br>Fri-Sun Enter at anytime
          </h4>
          <a href="https://staging2.iconparkingsystems.com/specials-promo/GIFT30E/apply" target="_blank" class="btn btn-primary btn-fill btn-round px-5 shadowBtn" rel="noopener noreferrer">
            <img src="../../../assets/img/landing-pages/new-gift/arrow.svg"> &nbsp; BOOK NOW
          </a>

        </div>
       

        <div class="col-sm-auto col-12 ml-sm-5 rightSec">
          <h4 class="mt-0 mb-2">Promo code for you</h4>
          <h3 class="text-primary mb-3 mt-0"><i>GIFT30E</i></h3>
          <h4 class="mt-0 mb-2">Promo code for a friend</h4>
          <h3 class="text-primary mb-3 mt-0"><i>FRIENDGIFT30E</i></h3>
          <p class="m-0 color-99">Book By <span class="text-primary"><b><i>December 22, 2021</i></b></span></p>
          <p class="m-0 color-99">Park by <span class="text-primary"><b><i>February 28, 2022</i></b></span></p>
        </div>

      </div>


        </div>
      </div>
    </section>

    <section class="header1-sec">
     
      <div class="bg-primary d-none p-3 w-100 "></div>
      <div class="heading-title mt-4">
   
          <div class="bg-primary text-center p-4">
            <img class="img-responsive mx-auto" src="../../../assets/img/landing-pages/new-gift/banner-2.svg">
          </div>
          <div class="container">
            <h3 class="text-center  my-5"> Thursday - Sunday </h3>

            <div class="row justify-content-center align-items-center">
              <div class="col-sm-auto col-12 leftSec">
                <div class="d-flex mb-3">
                  <h1 class="text-primary">15%</h1><span class="text-primary text-italic">Off<br>
                    daily <br>
                    rates </span>
                </div>
                <h4 class="my-4">Mon-Thurs Enter before 4pm</h4>
                <a href="https://staging2.iconparkingsystems.com/specials-promo/GIFT15E/apply" target="_blank" class="btn btn-primary btn-fill btn-round px-5 shadowBtn" rel="noopener noreferrer">
                  <img src="../../../assets/img/landing-pages/new-gift/arrow.svg"> &nbsp; BOOK NOW
                </a>

              </div>
             

              <div class="col-sm-auto col-12 ml-sm-5 rightSec">
                <h4 class="mt-0 mb-3">Promo code for you</h4>
                <h3 class="text-primary mb-3 mt-0"><i>GIFT15E</i></h3>
                <p class="m-0 color-99">Book By <span class="text-primary"><b><i>December 26, 2021</i></b></span></p>
                <p class="m-0 color-99">Park by <span class="text-primary"><b><i>February 28, 2022</i></b></span></p>
              </div>

            </div>

        <div class="row  mt-5 justify-content-center align-items-center">
          <div class="col-sm-auto col-12 leftSec">
            <div class="d-flex mb-3">
              <h1 class="text-primary">25%</h1><span class="text-primary text-italic">Off<br>
                nights &amp; <br>
                weekend </span>
            </div>
            <h4 class="my-4">Mon-Thurs Enter after 4pm<br>Fri-Sun Enter at anytime
            </h4>
            <a href="https://staging2.iconparkingsystems.com/specials-promo/GIFT25E/apply" target="_blank" class="btn btn-primary btn-fill btn-round px-5 shadowBtn" rel="noopener noreferrer">
              <img src="../../../assets/img/landing-pages/new-gift/arrow.svg"> &nbsp; BOOK NOW
            </a>

          </div>
           
          <div class="col-sm-auto col-12 ml-sm-5 rightSec">
            <h4 class="mt-0 mb-3">Promo code</h4>
            <h3 class="text-primary mb-3 mt-0"><i>GIFT25E</i></h3>
            <p class="m-0 color-99">Book By <span class="text-primary"><b><i>December 26, 2021</i></b></span></p>
            <p class="m-0 color-99">Park by <span class="text-primary"><b><i>February 28, 2022</i></b></span></p>
          </div>

        </div>


          </div>
        </div>
      </section>

      <section class="header1-sec">
     
        <div class="bg-primary d-none p-3 w-100 "></div>
        <div class="heading-title mt-4">
     
            <div class="bg-primary text-center p-4">
              <img class="img-responsive mx-auto w-100" style="max-width:812px; " src="https://staging2.iconparkingsystems.com/cms/wp-content/uploads/2021/12/banner-3.png">
            </div>
            <div class="container">
              <h3 class="text-center  my-5"> Monday - Thursday </h3>
  
              <div class="row justify-content-center align-items-center">
                <div class="col-sm-auto col-12 leftSec">
                  <div class="d-flex mb-3">
                    <h1 class="text-primary">15%</h1><span class="text-primary text-italic">Off<br>
                      daily <br>
                      rates </span>
                  </div>
                  <h4 class="my-4">Mon-Thurs Enter before 4pm</h4>
                  <a href="https://staging2.iconparkingsystems.com/specials-promo/GIFT15ELC/apply" target="_blank" class="btn btn-primary btn-fill btn-round px-5 shadowBtn" rel="noopener noreferrer">
                    <img src="../../../assets/img/landing-pages/new-gift/arrow.svg"> &nbsp; BOOK NOW
                  </a>
  
                </div>
               
  
                <div class="col-sm-auto col-12 ml-sm-5 rightSec">
                  <h4 class="mt-0 mb-3">Promo code for you</h4>
                  <h3 class="text-primary mb-3 mt-0"><i>GIFT15ELC</i></h3>
                  <p class="m-0 color-99">Book By <span class="text-primary"><b><i>December 30, 2021</i></b></span></p>
                  <p class="m-0 color-99">Park by <span class="text-primary"><b><i>February 28, 2022</i></b></span></p>
                </div>
  
              </div>
  
          <div class="row  mt-5 justify-content-center align-items-center">
            <div class="col-sm-auto col-12 leftSec">
              <div class="d-flex mb-3">
                <h1 class="text-primary">20%</h1><span class="text-primary text-italic">Off<br>
                  nights &amp; <br>
                  weekend </span>
              </div>
              <h4 class="my-4">Mon-Thurs Enter after 4pm<br>Fri-Sun Enter at anytime
              </h4>
              <a href="https://staging2.iconparkingsystems.com/specials-promo/GIFT20ELC/apply" target="_blank" class="btn btn-primary btn-fill btn-round px-5 shadowBtn" rel="noopener noreferrer">
                <img src="../../../assets/img/landing-pages/new-gift/arrow.svg"> &nbsp; BOOK NOW
              </a>
  
            </div>
               
            <div class="col-sm-auto col-12 ml-sm-5 rightSec">
              <h4 class="mt-0 mb-3">Promo code</h4>
              <h3 class="text-primary mb-3 mt-0"><i>GIFT20ELC</i></h3>
              <p class="m-0 color-99">Book By <span class="text-primary"><b><i>December 30, 2021</i></b></span></p>
              <p class="m-0 color-99">Park by <span class="text-primary"><b><i>February 28, 2022</i></b></span></p>
            </div>
  
          </div>
  
  
            </div>
          </div>
        </section>


    <section class="appStoreSections mt-0 mb-5 mt-sm-4  ">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-sm-5 col-7">
            <div class="row">
              <div class="col-auto icon-go-img pr-1">
                <img class="img-responsive mx-auto" src="../../assets/img/landing-pages/sepoffer/sep-icon-go.png" alt="app-icon">
              </div>
              <div class="col pl-0"><p><i>Let’s Journey<br>Together</i></p></div>
            </div>
            <div class="row">
              <div class="col-12 icon-go-text">
                <h4>THE ICON GO APP</h4>
                <h6>BOOK, PAY, PRE <br>CHECK-IN & PLAY</h6>
                <a class="pr-sm-4 pr-1" href="https://itunes.apple.com/us/app/icon-parking/id394377820?mt=8" target="_blank" rel="noopener noreferrer"><img src="../../assets/img/landing-pages/sepoffer/to-do-app-icon.svg" alt="app_store"></a>  <a class="p-0" href="https://play.google.com/store/apps/details?id=com.iconparking&hl=en" target="_blank" rel="noopener noreferrer"><img src="../../assets/img/landing-pages/sepoffer/to-do-google-icon.svg" alt="play_store"></a>
              </div>
            </div>
          </div>
          <div class="col-sm-5 col-5 to-do-mob">
            <img class="img-responsive mob-image" src="../../assets/img/landing-pages/sepoffer/to-do-mob.png" alt="Mob-icon">
          </div>
        </div>
      </div>
    </section>
    <section class="bg-dark  text-white footerJulyOffer ">
      <div class="container">
        <p class="visible-xs"><br><br></p>
        <div class="row justify-content-center">
          <div class="col-sm-6 col-12 text-center">
            <div class="mb-0 mb-sm-4">
              <a class="btn p-1 btn-link" href="https://www.facebook.com/IconParkingNYC" target="_blank" rel="noopener noreferrer"><img alt="Facebook" src="./../assets/img/landing-pages/augustoffer/fb.svg" alt="facebook"></a>
              <a class="btn p-1 btn-link" href="https://www.instagram.com/iconparkingnyc/?hl=en" target="_blank" rel="noopener noreferrer"><img src="./../assets/img/landing-pages/augustoffer/insta.svg" alt="instagram"></a>
              <a href="https://twitter.com/IconParkingNYC" target="_blank" class="btn p-1 btn-link" rel="noopener noreferrer"><img src="./../assets/img/landing-pages/augustoffer/twitter.svg" alt="twitter"></a>

<a href="https://www.linkedin.com/company/icon-parking/" target="_blank" class="btn p-1 btn-link" rel="noopener noreferrer"><img height="24px" class="linkedin-img" src="https://staging2.iconparkingsystems.com/cms/wp-content/uploads/2021/10/linkedin.png" alt="twitter"></a>
            </div>
            <p class="mb-0 mb-sm-3">&nbsp;</p>
            <p>&nbsp;</p>
            <p>
            Disclaimer:
            </p>
            <p>&nbsp;</p>
             <p>Icon Parking Reserves All Rights. Online Reservations ONLY. Offer may not be combined with any other offers or discounts. Discount valid per 24 Hour Daily Parking Reservation $20 or more! Maximum value of a $100 discount per reservation. 15% OFF Daily Rates Offer | Promo Code DECEMBER15E | Valid Parking Dates Now – February 28, 2022. | Book by December 10, 2021 at 11:59pm. Monthly promo code DECEMBER2021E | Book by 12/31/21 at 11:59pm | Booking code valid for 1 month, 3 months, 6 months, or 12 months reservations | Rates for new customers only | Standard Monthly Parking terms apply. Book online and save more! Online Rates are already discounted</p>
          </div>
        </div>
      </div>
    </section>
  </div>   -->
