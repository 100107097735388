import { Component, OnInit,ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { StaticpagesService } from '../../services/staticpages.service';
import { EvcService } from '../../services/evc.service';
import { CustomCode } from '../../shared/CustomCode';
import { ToastrService } from 'ngx-toastr';
import { SearchPageScript } from '../../shared/searchPageScript';
import { SearchpageserviceService } from './../../services/searchpageservice.service';
import { FilterHelper } from '../../classes/filter-helper';
import * as $ from 'jquery';

@Component({
  selector: 'app-evcharging',
  templateUrl: './evcharging.component.html',
  styleUrls: ['./evcharging.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [StaticpagesService, ToastrService,EvcService, SearchpageserviceService]
})
export class EvchargingComponent implements OnInit {

  constructor(private evc: EvcService, private staticpagesServiceObj: StaticpagesService, 
    private toastr: ToastrService, private router: Router,
    private searchPageObj: SearchpageserviceService
    ) { }

  activeNeighbourhood: any = 'central-park';
  facilityDetails:any = {};
  evcDeatils:any=[]
  findParkingUrl='';
  isLoaded = false;
  pageId:string = 'ev-charging';
  getPageContent:any = {}; 
  mapViewScroll:any=false;
  neighbourhoods:any;
  activeNeighbourhoodLat = '';
  activeNeighbourhoodLong = '';
  ev_charge_icon = 'assets/img/amenities/ev_charge_icon.svg';
  tesla_charger_icon = 'assets/img/amenities/tesla_charger_icon.svg';
  evimg ='assets/img/evbanner.png';

  evList(){
    this.fetchUserStatusForTracking();
    $('#drp').text('SELECT YOUR NEIGHBORHOOD');
    $('.drp').removeClass('active');
    $('.viewAll').addClass('active');
    $('.llcShow').removeClass('hide');
    $('.evPage').toggleClass('hidden');
    $('.evList').toggleClass('hidden');
    window.scrollTo(0,0);
    $("body").animate({ scrollTop: 0 }, 500);
  }

  evPage(){
    $('.evPage').toggleClass('hidden');
    $('.evList').toggleClass('hidden');
    $('.geo').removeClass('eventmap')
    window.scrollTo(0,0);
    $("body").animate({ scrollTop: 0 }, 500);
  }

  ngOnInit() {

    this.searchPageObj.getNeighbourhoods().subscribe(
                (data:any) => {
                  if (data.data) {
                   for (let i = 0; i < data.data.length; i++) {
                     this.neighbourhoods =  data.data;
                  }
                }
                this.fetchNeighborhoodData(this.activeNeighbourhood);
              }
    );


    // get page content from cms
    this.staticpagesServiceObj.getCmsPageContent(this.pageId).subscribe(
      (data:any) => {
        this.getPageContent = data.result[0];
        this.isLoaded = true;
      },
      (error) => { console.log('Error: ' + JSON.stringify(error)); 
      this.isLoaded = true;
      }
    )

    this.findParkingUrl = CustomCode.searchMode()
    
      this.evc.getRelatedEvc().subscribe((data:any)=>{
        console.log(data);
        let neighArray=[];
        
        let arraytest=[];
        let i=1;
        //console.log(data.data.facilities[0].neighborhood.title)
        for( propOfFacility of data.data.facilities )
        {
          if(propOfFacility.neighborhood!=null){
            console.log(propOfFacility.neighborhood.title);
            arraytest.push(propOfFacility.neighborhood.title)
          }
          
        }
        let filterArray=this.removeDuplicate(arraytest)
        console.log('filterArray',filterArray);
        for(let props of filterArray)
        {
          let facilityArray=[]
          let slug=''
          for(var propOfFacility of data.data.facilities )
          {
            if(propOfFacility.neighborhood!=null){
             if(props==propOfFacility.neighborhood.title)
             {
               facilityArray.push(propOfFacility);
               slug=propOfFacility.neighborhood.slug
             }
            }
          }
          neighArray.push({'neighborhood':props,'slug':slug,'facility':facilityArray}) 
        }
       
        this.evcDeatils=neighArray;
        console.log('evcDeatils', this.evcDeatils)
      });
      $(".nbhValue li a").click(function () {
      $(".linkLabel div").html($(this).html());
      $(this).parent().addClass('active').siblings().removeClass('active');
      $(".nbhValue").parent('.selectBox').removeClass('open');
    });
  }
  removeDuplicate(arr) {
    const result = [];
    let idx = 0;
    const tmp = {};

    for (let i = 0; i < arr.length; i++) {
        if (!tmp[arr[i]]) {
            tmp[arr[i]] = 1;
            result[idx] = arr[i];
            idx++;
        } 
    }
    return result;
}
  redirectToLocation(lat,long,loc)
  {
     this.router.navigate(['/search', 
        lat, 
        long, 
        this.formonthly(),
        CustomCode.facilityDefaultRadius, 
        loc, 
        'daily', 
        CustomCode.searchMode()
    ]);

  }
  redirectToFacilityBooking(facId) {
    let url = "rate/facility/" + facId + "?arrival_time=" +this.formonthly() + "&length_of_stay=2&use_bonus=0";
    this.staticpagesServiceObj.fetchFacilityRates(url)
      .then(
      (data:any)=> {
        this.facilityDetails = data;
        this.sendToMap();
      },
      error => {
        this.toastr.error(error.errors.message, 'Sorry!');
      }
    );
  }

  sendToMap() {
    this.router.navigate(['/search', 

        this.facilityDetails['data'].facility.geolocations.latitude, 
        this.facilityDetails['data'].facility.geolocations.longitude, 
        this.formonthly(),
        CustomCode.facilityDefaultRadius, 
        this.facilityDetails['data'].facility.display_name, 
        'daily', 
        CustomCode.searchMode()
    ]);
  }

  sendToPayment() {
    // Code Goes Here ...
  }

  reloadList(location) {
    this.fetchUserStatusForTracking();
    this.fetchNeighborhoodData(location);
    $("body").animate({ scrollTop: 0 }, 500);
    window.scrollTo(0,0);
    let deSelect=$('#'+location).hasClass("eventmap")
    window.scrollTo(0,0);
    $('.geo').removeClass('eventmap')
    if(!deSelect)
    {
      $('#'+location).addClass("eventmap")
      if(location=='all')
      {
        $('.llcShow').removeClass('hide');
      }else
      {
        $('.llcShow').addClass('hide');
        $('#contnent_'+location).removeClass('hide')
        $('#heading_'+location).removeClass('hide')
        this.activeNeighbourhood = location;
      }
    }else{
        $('#'+location).removeClass("eventmap")
        $('.llcShow').removeClass('hide');
    }
    let len:any=$('.well').length;
    let  hlen:any=$('.hide .well').length;
    var total_show_div = parseInt(len)-parseInt(hlen)
    if(total_show_div < 6){
      $('#listScrollSection').removeClass('scrollFix');
      // $('.wrapperMapEv').removeAttr("data-spy");
      // $('.wrapperMapEv').removeAttr("data-offset-top");
      // $('.wrapperMapEv').removeAttr("data-offset-bottom");
      // $('.wrapperMapEv').removeClass("affix");
      // $('.wrapperMapEv').removeClass("affix-top");
      // $('.wrapperMapEv').removeClass("affix-bottom");
     
    }else{
      $('#listScrollSection').addClass('scrollFix');
      // $('.wrapperMapEv').attr("data-spy", "affix");
      // $('.wrapperMapEv').attr("data-offset-top", "400");
      // $('.wrapperMapEv').attr("data-offset-bottom", "428.5");
      // $('.wrapperMapEv').addClass("affix-top");
   
    
    }
  }
  reloadListDrop(location) {
    this.fetchUserStatusForTracking();
    $("body").animate({ scrollTop: 0 }, 500);
    window.scrollTo(0,0);
    
    
      if(location=='all')
      {
        $('.llcShow').removeClass('hide');
      }else
      {
        $('.llcShow').addClass('hide');
        $('#contnent_'+location).removeClass('hide')
        $('#heading_'+location).removeClass('hide')
        this.activeNeighbourhood = location;
      } 
  }

  redirectToMonthlyParking(){
    
    // localStorage.setItem('activeNeighbourhood',this.activeNeighbourhood);
    // localStorage.setItem('redirectFrom', 'evCharging');
    this.router.navigate(['/search', this.activeNeighbourhoodLat, this.activeNeighbourhoodLong, this.formonthly(), 2,  this.activeNeighbourhood,'daily', CustomCode.searchMode()]);
  }

  formonthly()
  {
      let startDuration = FilterHelper.defaultStartDuration();
      let startTime = FilterHelper.getFormattedTime(startDuration);
      localStorage.setItem('monthlyDate',startDuration.toString())
      let endDuration = FilterHelper.defaultEndDuration();
      let endTime = FilterHelper.getFormattedTime(endDuration);
      let endTimeDataIfLess = FilterHelper.makeDurationDateObject(endDuration,endTime);
      //alert(endTimeDataIfLess)
     // alert(startDuration);
      localStorage.setItem('endttimehome',CustomCode.getDateForMapSearchSlashFormatHome(endDuration,endTime));
      localStorage.setItem('startimehome',CustomCode.getDateForMapSearchSlashFormatHome(startDuration,startTime));
      let stime =localStorage.getItem('startimehome');

      // localStorage.setItem('specialParking','1');
      // localStorage.setItem('showNeighbordood','1');
      // localStorage.setItem('sortBy', "Neighborhood");
      // localStorage.setItem('fromhomelanding','1');
      return stime;
      //this.router.navigate(['/search', '40.7782667', '-73.9698797', stime, 1,  'Central Park','daily', CustomCode.searchMode()]);
  }

  fetchNeighborhoodData(location){
    if(location){
      for(let i=0; i< this.neighbourhoods.length; i++){
         if(this.neighbourhoods[i].slug == location){
           this.activeNeighbourhoodLat = this.neighbourhoods[i].latitude
           this.activeNeighbourhoodLong = this.neighbourhoods[i].longitude
         }
      }
    }
  }

  fetchUserStatusForTracking(){
    let getUserId = '0';
    let getUserEmail = '0';
    let user_login_type = '';
    let isLogin = false;
    if(localStorage.getItem('access_token')){
     isLogin = true;
       getUserId = localStorage.getItem('userid');
       getUserEmail = localStorage.getItem('email');
       user_login_type = localStorage.getItem('user_login_type');
    }
    const loginStatusTrack = {
      'event' : 'pageview',
      'loginStatus' : isLogin? 'logged in': 'Not Logged IN', // 'logged in/Not Logged IN',
      'authenticationMethod' : user_login_type,
      'userId' : getUserId,
      'pageType' : this.router.url,
      'email': getUserEmail,
   };
  (<any>window).dataLayer.push(loginStatusTrack);
  }
}
