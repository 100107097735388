<app-header></app-header>


<div *ngIf="showPromocodeStipt" class="infoWrapper"></div>
<div *ngIf="showPromocodeStipt" class="infoBlock" [style.top.px]='navHeight' [class.withLoyaltyBar]='isLoyality'>
    <div class="container " style="margin: 0;width: 100%;margin: 0 auto; ">
        <table class="content" cellpadding="0" style="vertical-align:middle" width="100%" cellspacing="0">
            <tr>
                <td width="20px">
                    <fa name="{{sign}}" style="font-size: 1.7rem;" size="2x"></fa>
                </td>
                <td style="font-size: 16px; padding: 0 10px;"><span [innerHTML]="appliedPromocodeStrip"></span> </td>
                <td  class="text-right" width="25px">
                    <a href="javascript:void(0)" (click)="hideStripe();" class="btn-link">
                        <i class="fa fa-times text-white"></i>
                    </a>
                </td>
            </tr>
        </table>
        <div class="col-xs-1 text-right pad-right-0">
        </div>
    </div>
</div>
<div *ngIf="!isresponsive" class="bg-dark hidden-xs"  style="background-color: black;margin-top: 130px;" ><img [src]="buymonthlybannerimage" style="width:50%;"></div>

<div *isIphone [class]='margin_height' ><img [src]="buymonthlybannerimage" style="width:100%;"></div>
<div *isAndroidMobile [class]='margin_height' ><img [src]="buymonthlybannerimage" style="width:100%;"></div>

<form (ngSubmit)="f.form.valid && monthlyPayment(formData, f,1)" #f="ngForm" novalidate method="POST" role="form" id="floating-label-buy-monthly" class="floating-label">

<div class="monthlyParking mar-bottom-lg orangeBar">
    <!-- <div *ngIf="isLoyaltyMember" class="hidden-xs 1" style="margin-top:60px"></div> -->
    <!-- <div *ngIf="!isLoyaltyMember" class="hidden-xs 2" style="margin-top:55px"></div> -->
    <!-- <div *ngIf="isLoyaltyMember" class="visible-xs 3" style="margin-top:50px"></div> -->
    <!-- <div *ngIf="!isLoyaltyMember" class="visible-xs 4" style="margin-top:55px"></div> -->
    <!-- <div *ngIf="!isLoyaltyMember" class="visible-xs"></div> -->
    <div class="back-btn-top mar-top-0 mar-bottom-0">
    <div class="container">


        <!-- <ol class="breadcrumb pad-top-0 pad-bottom-0 pad-left-0" *ngIf="isFrame">

            <li class="active"> 

                <a href="javascript:;" onclick="history.go(-1);" class="btn btn-link pad-left-0 fa-back">

                    <fa name="chevron-left"></fa> &nbsp; Back</a>

            </li>

        </ol> -->

        <!-- <p *ngIf="!isFrame">

            <a href="javascript:;" onclick="history.go(-1);" class="btn btn-link pad-left-0 fa-back">

                <fa name="chevron-left"></fa> &nbsp; Back</a>

            </p> -->
        </div>
       </div>
       <div class="container">
            <div class="top-tab-in-payment">
                <div class="wizard">
                    <!-- <div class="wizard-inner">
                        <div class="connecting-line"></div>
                        <div class="first-line-active"></div>
                        
                        <ul class="nav nav-tabs" role="tablist">
        
                            <li role="presentation" class="active1">
                                <a data-toggle="tab" aria-controls="step1" role="tab">
                                    <span class="round-tab">
                                        <i class="fa fa-chevron-left"></i>
                                    </span>
                                    <b>Search Results</b>
                                </a>
                            </li>
        
                            <li role="presentation" class="active1 active">
                                <a data-toggle="tab" aria-controls="monthlypayment" role="tab">
                                    <span class="round-tab">
                                        <i class="fa fa-check"></i>
                                    </span>
                                    <b style="left: -44px;">Payment & Checkout</b>
                                </a>
                            </li>
                            <li role="presentation" class="disabled">
                                <a>
                               
                                    <span class="round-tab">
                                        <i class="fa fa-check"></i>
                                    </span>
                                    <b>Booking Confirmed</b>
                                </a>
                            </li>
                        </ul>
                    </div>
         -->
                        <div class="tab-content">
                            <!-- <div class="tab-pane" role="tabpanel" id="step1">
                                <h3>Step 1</h3>
                                <p>This is step 1</p>
                                <ul class="list-inline pull-right">
                                    <li><button type="button" class="btn btn-primary next-step">Save and continue</button></li>
                                </ul>
                            </div> -->
                            <div class="tab-pane active" role="tabpanel" id="monthlypayment">
                                
                            </div>
                            <!-- <div class="tab-pane" role="tabpanel" id="complete">
                                <h3>Complete</h3>
                                <p>You have successfully completed all steps.</p>
                            </div> -->
                            <div class="clearfix"></div>
                        </div>
                </div>
        </div>
        
        <div class="row">
            <!-- <div class="col-sm-12 hide">
                <div class="month-box-top">
                    <table><tr><td><img src="/assets/img/v8-design/time-icon-red.svg"></td><td>Limited slots are available at this rate in this location at April 7th. <a href="javascript:;">Lock in your price now.</a></td></tr></table>
                </div>              
            </div> -->
            <div class="col-sm-8">

                

                <div class="white-box-sdw srink-box">
                
                <h3 class="mar-top-0 font-weight-bold top-text-details-2"> {{respdata?.facility_name_modified}} <!-- <span class="pull-right visible-xs pad-left-sm"><a href="javascript:;"><img (click)="openModal(monthlyFacilityDate)" [src]="infoIcon" alt="infoIcon" /></a></span>--> </h3>

                <div class="row">

                    <div class="col-md-6">

                        <h3 class="mar-top-0 font-weight-bold top-text-details-2">{{respdata?.geolocations?.address_1}}</h3>

                        <p *ngIf="respdata?.between_streets"><b>Between:</b> {{respdata?.between_streets}}</p>

                    </div>
                    <div class="col-md-6">
                       <b> <small class="text-center">Operating Hours</small></b><br>
                      <p class="mar-bottom-xs" *ngIf="FgarageWorkingHours?.length <= 0">7 Days a Week: 24 Hours a Day</p>
                      <div class="dropdown mb-0 selectBox week-date-btn" *ngIf="FgarageWorkingHours?.length > 0">
                        <a class="btn btn-default btn-block  dropdown-toggle linkLabel week-day" data-toggle="dropdown" ><div> {{FgarageWorkingHours[0].day}} : {{FgarageWorkingHours[0].formatted_open_time}} - {{FgarageWorkingHours[0].formatted_close_time}} {{showtimefornxtornot(FgarageWorkingHours[0].close_time)}} </div> <i *ngIf="FgarageWorkingHours?.length > 1" aria-hidden="true" class="fa fa-angle-down text-primary"></i></a>
                          <ul style="width:100%;" class="catValue dropdown-menu" *ngIf="FgarageWorkingHours?.length > 1"> 
                            <li *ngFor="let wh of FgarageWorkingHours; index as index">
                                <a *ngIf="index != 0" id="{{index}}" href="javascript:;">
                                {{wh.day}} : {{wh.formatted_open_time}} - {{wh.formatted_close_time}} {{showtimefornxtornot(wh.close_time)}}
                                </a></li>
                           </ul>
                      </div>
                     <!-- <p style="padding-top: 10px;" *ngIf="respdata?.is_alternative_facility>0 && is_24hour_open == '0'">*Please be advised of amended hours at this location.<br> <a target="_blank" [href]="temporarilyClosedUrl">Click Here </a>for alternative garage information*</p>-->
                    </div>

                        <!-- <div *ngIf="FgarageWorkingHours?.length > 0">

                            <div class="row mar-bottom-xs mar-top-xs">

                                <div class="col-xs-6 facility-tbl">

                                    <img [src]="calendarIcon" alt="calendarIcon" />

                                </div>

                                <div class="col-xs-6 facility-tbl">

                                    <img [src]="clockIcon" alt="clockIcon" />

                                </div>

                            </div>

                            <div *ngFor="let wh of FgarageWorkingHours;">

                                <div class="row mar-bottom-xs">

                                    <div class="col-xs-6 facility-tbl">

                                        <span>{{wh.day}} </span>

                                    </div>

                                    <div class="col-xs-6 facility-tbl">

                                        <span> {{wh.formatted_open_time}} - {{wh.formatted_close_time}}</span>

                                    </div>

                                </div>

                            </div>



                        </div> -->

                    

                    </div>

                <!-- <p class="mar-bottom-sm" *ngIf="respdata?.phone_number.length > 0">

                    <a class="text-uppercase" (click)="callMonthlyCustomer()" [href]="phoneNumber" style="color: #000;"><b style="font-size: 13px;">Questions

                        <span class="quest-icon"><fa name="question"></fa></span>

                               Call Us</b> &nbsp;<fa name="phone"></fa>&nbsp; 888-705-7845</a>

                </p> -->

                <table cellspacing="20" class="desired-park mar-bottm-sm mar-top-sm">

                    <tr class="hidden-xs">

                        <td>

                            <b>DESIRED PARKING START DATE</b>

                        </td>

                        <td width="5%">&nbsp;</td>

                        <td class="posi-pencil">

                            <div class="date-pic">

                            <fa name="calendar-o" class="text-primary"></fa>

                            <input id="monthlydate" name="newMonthlyDate" (ngModelChange)="checkdate();" [minDate]="minDate" [(ngModel)]='newMonthlyDate' type="text" class="datestyle" #dp="bsDatepicker" bsDatepicker [(bsValue)]="newMonthlyDate" readonly />

                            <fa class="text-primary pe" name="chevron-down"></fa>

                            </div>

                        </td>

                        <td align="left">

                            <!-- <a (click)="dp.show()" class="btn btn-link" > <fa name="pencil"></fa> -->

                        </td>

                    </tr>

                    <tr class="visible-xs">

                        <td colspan="4">

                            <b>DESIRED PARKING START DATE</b>

                        </td>

                    </tr>

                    <tr class="visible-xs mar-top-md">

                        <td class="posi-pencil">

                            

                            <div class="date-pic ">

                            <fa name="calendar-o" class="text-primary"></fa>

                            <input id="monthlydate" name="newMonthlyDate"  (ngModelChange)="checkdateMob();" [minDate]="minDate" [(ngModel)]='newMonthlyDate' type="text" class="datestyle" #dp="bsDatepicker" bsDatepicker [(bsValue)]="newMonthlyDate" readonly (onShown)="onShowPicker($event)"  />

                            <fa class="text-primary" name="chevron-down"></fa>

                            </div>

                        </td>

                    </tr>

                </table>
                <!-- <p>

                    PRICE&nbsp;&nbsp;${{rateDetails|number}}

                </p> -->



                <!--Rate Details-->

                <!-- <hr/> -->

                <div class="rate-details mar-top-sm">

                   
                    <!-- <h4 class=" mar-top-xs font-weight-bold pad-bottom-xs">RATE DETAILS </h4> -->
                    <h4 class=" mar-top-xs rate-details-ft">SAVE WITH MULTI-MONTH PACKAGES! (where available) &nbsp;<img height="16" (click)="openModal(monthlyRateDesc)" [src]="helpIcon" style="cursor: pointer;" alt="helpIcon" /></h4>
                    <ng-template #monthlyRateDesc>

                        <div class="modal-header" style="margin-top: -10px;">

                            <h4 class="modal-title pull-left text-primary text-uppercase"></h4>

                            <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">

                                <span aria-hidden="true" style="font-size: 50px; font-weight: 400;">&times;</span>

                              </button>

                        </div>

                        <div class="modal-body" style="padding: 10px 20px">

                            <h3 class="text-uppercase text-primary mar-top-0 mar-bottom-xs">MONTHLY RATES</h3>

                            <p>Our rates vary by vehicle type and what service you require.</p>

                            <h5 class="text-uppercase">ACCESS HOURS</h5>

                            <div *ngIf="FgarageWorkingHours?.length <= 0">

                                <p>

                                    This facility is open 24 hours a day, 7 days a week.

                                </p>

                            </div>

                            <div *ngIf="FgarageWorkingHours?.length > 0">

                                <p>

                                    This facility is open

                                    <span class="pHours" *ngFor="let wh of FgarageWorkingHours;">

                                    {{wh.day}}: {{wh.formatted_open_time}} - {{wh.formatted_close_time}}<span class="comma">,</span>

                                    </span>

                                </p>

                            </div>

                            <h5 class="text-uppercase">VEHICLE CLASSIFICATIONS</h5>

                            <p>

                                Oversized vehicles include SUV's, Trucks, and similar. You can find more info and a list of vehicles <a href="javascript:;" (click)="redirecttoList()">here</a>.

                            </p>

                            <p>

                                Exotic vehicles are considered luxury/high-performance/ sports cars.

                            </p>

                            <p>

                                For assistance with your vehicle, contact us at (888) 705-7845 from 9 AM to 7 PM EST

                            </p>

                            <div class="btn-center-xs mar-top-sm mar-bottom-xs">

                                <a class="btn btn-primary theme-btn" href="javascript:;" (click)="modalRef.hide()">Got it</a>

                            </div>

                            <div class="mar-bottom-sm visible-xs"></div>

                        </div>

                    </ng-template>

                   
                    <!-- <p class="mar-bottom-sm pad-top-xs">If you're unsure of what your car is classified as, you can contact our monthly sales team at <b>(888) 705-7845</b> from 9 AM to 7 PM EST for assistance.</p> -->

                </div>

                <div class="mar-top-xs mar-bottom-xs rate-table">

                        <table *ngIf="0">

                                <tr *ngFor="let monthlyrats of respdata?.facilityMonthlyTaxRates">

                                    <span *ngIf="monthlyrats.rate != '0.00' && monthlyrats.active==1">

                                            <td><input (click)="getRate(monthlyrats.rate,monthlyrats.net_rate,monthlyrats.tax_rate,monthlyrats.rate_description.name)" [checked]="monthlyrats.rate_description.name==selectedvahicletype" class="radio radio_alt" name="rateDetailss" type="radio" id="rate-{{monthlyrats.rate}}" value="{{monthlyrats.rate}}"></td>

                                            <td  *ngIf="respdata?.monthlytaxFlag==0">

                                                <b sourceFont>${{checkFormat(monthlyrats.net_rate)}}</b>

                                            </td>

                                            <td *ngIf="respdata?.monthlytaxFlag==1">

                                                <b sourceFont>${{checkFormat(monthlyrats.rate)}}</b>

                                            </td>

                                            <td>

        

                                                <p>{{monthlyrats.rate_description.name}} </p>

                    

                                                <span>

                                                    {{monthlyrats.rate_description.hours_description}}

                                                    <span *ngIf="monthlyrats?.rate_description?.name_alias.indexOf('Standard ') > -1">{{monthlyrats.rate_description?.description}}</span>

                                    <span *ngIf="monthlyrats?.rate_description?.name_alias.indexOf('Oversized') > -1">{{monthlyrats.rate_description?.description}}<a *ngIf="monthlyrats?.rate_description?.name.indexOf('oversized') > -1 || monthlyrats?.rate_description?.name.indexOf('Oversized') > -1" class="text-uppercase" href="javascript:;" routerLink="/oversized">&nbsp; Is my car oversized?</a></span>

                                    <span *ngIf="monthlyrats?.rate_description?.name_alias.indexOf('Exotic') > -1">{{monthlyrats.rate_description?.description}}</span>

                                    <span *ngIf="monthlyrats?.rate_description?.name_alias.indexOf('Self-parking') > -1">{{monthlyrats.rate_description?.description}}</span>

                                    </span>

                                    <!-- <div *ngIf="FgarageWorkingHours?.length > 0">

                                                    <span *ngFor="let wh of FgarageWorkingHours;">

                                                            {{wh.day}}: {{wh.formatted_open_time}} - {{wh.formatted_close_time}},

                                                    </span>

                                                    <span *ngIf="monthlyrats?.rate_description?.name_alias.indexOf('Standard ') > -1"> {{monthlyrats?.rate_description?.description}}</span>

                                                    <span *ngIf="monthlyrats?.rate_description?.name_alias.indexOf('Oversized') > -1"> {{monthlyrats?.rate_description?.description}}<a *ngIf="monthlyrats?.rate_description?.name.indexOf('oversized') > -1 || monthlyrats?.rate_description?.name.indexOf('Oversized') > -1" class="text-uppercase" href="javascript:;" routerLink="/oversized">&nbsp; Is my car oversized?</a></span>

                                                    <span *ngIf="monthlyrats?.rate_description?.name_alias.indexOf('Exotic') > -1"> {{monthlyrats?.rate_description?.description}}</span>

                                                    <span *ngIf="monthlyrats?.rate_description?.name_alias.indexOf('Self-parking') > -1"> {{monthlyrats?.rate_description?.description}}</span>

                                                </div> -->

        

                                    </td>

                                    </span>

                                </tr>

                            </table>

                    <table class="table" *ngIf="respdata?.facilityMonthlyTaxRates">

                        <tr *ngFor="let monthlyrats of respdata?.facilityMonthlyTaxRates; index as zi">

                                

                            <th><div class="radio-boxx"><input  class="radio radio_alt idsclass" name="rateDetails" (click)="getRate(monthlyrats.rate,monthlyrats.net_rate,monthlyrats.tax_rate,monthlyrats.rate_description.name)" [checked]="monthlyrats.rate_description.name==selectedvahicletype"  type="radio" id="rate-{{monthlyrats.rate}}" value="{{zi}}"><span style="display: none;" id="srate{{zi}}">{{monthlyrats.rate_description.name}}</span> <span class="mar-left-sm">{{monthlyrats.rate_description.name_alias}}</span> <span *ngIf="showSavingFlexibilityMsg(monthlyrats.rate_description.name)" [class]="bgclr" >{{orangestriptdata}} </span> </div></th>

                            <th *ngIf="respdata?.monthlytaxFlag==0" class="text-right" sourceFont> &nbsp; ${{checkFormatForList(monthlyrats.net_rate)}}/mo</th>

                            <th *ngIf="respdata?.monthlytaxFlag==1" class="text-right" sourceFont> &nbsp; ${{checkFormatForList(monthlyrats.rate)}}/mo</th>

                            

                        </tr>

                        

                    </table>
                     <!-- <p>*Rates quoted are for new customers</p> -->

                </div>
                <div class="currently-select-box">

                    <h4 class="text-uppercase text-primary font-weight-bold my-2">Currently Selected</h4>

                    

                    <div class="ml-0" *ngIf="respdata?.facilityMonthlyTaxRates">

                        

                        <div *ngFor="let monthlyrats of respdata?.facilityMonthlyTaxRates">

                            <!-- <div class="row" *ngIf="monthlyrats.rate != '0.00' && monthlyrats.active==1 && monthlyrats.rate_description.name==selectedvahicletype"> -->
                                <div class="row" *ngIf="monthlyrats.rate_description.name==selectedvahicletype">
                                    <div class="col-sm-7">

                                        <h4 style="margin-top: 5px;" sourceFont>

                                            <b *ngIf="respdata?.monthlytaxFlag==1">${{checkFormat(monthlyrats.rate)}}</b>

                                            <b *ngIf="respdata?.monthlytaxFlag==0">${{checkFormat(monthlyrats.net_rate)}}</b>

                                            &nbsp;<span class="stand">{{monthlyrats.rate_description.description}}</span></h4>

                                        <!-- <p class="small mt-1">Early termination fees will be charged if you cancel your monthly parking before the end of the selected time frame.</p> -->
                                        <p class="small mt-1">{{monthlyrats.rate_description.termination_text}} </p>

                                    </div>

                                    <div class="col-sm-5">



                                        <p style="margin-top: 10px;">{{monthlyrats.rate_description.name}}</p>

            

                                        <span>

                                            {{monthlyrats.rate_description.hours_description}}

                                            <span *ngIf="monthlyrats?.rate_description?.name_alias.indexOf('Standard ') > -1">{{monthlyrats.rate_description?.description}}</span>

                            <span *ngIf="monthlyrats?.rate_description?.name_alias.indexOf('Oversized') > -1">{{monthlyrats.rate_description?.description}}<a *ngIf="monthlyrats?.rate_description?.name.indexOf('oversized') > -1 || monthlyrats?.rate_description?.name.indexOf('Oversized') > -1" class="text-uppercase" href="javascript:;" routerLink="/oversized">&nbsp; Is my car oversized?</a></span>

                            <span *ngIf="monthlyrats?.rate_description?.name_alias.indexOf('Exotic') > -1">{{monthlyrats.rate_description?.description}}</span>

                            <span *ngIf="monthlyrats?.rate_description?.name_alias.indexOf('Self-parking') > -1">{{monthlyrats.rate_description?.description}}</span>

                            </span>



                                    </div>

                                </div>

                        </div>

                    </div>

                </div>

                <h4 class="mar-top-sm mar-bottom-xs ">VEHICLE INFORMATION</h4>
                <!--<p  style="margin-top: 7px;" *ngIf="showoversizemsg" class="smallTextAdded">{{overSizedMessage}}</p>-->
                                

                            <!-- <p class="mar-bottom-sm">

                                Oversize vehicle surcharge: Vehicles over 181″ in Length and over 70″ in Height (or 75" high regardless of length) are subject to an additional fee. Please contact us for additional information for oversize vehicles.

                            </p> -->

                            <div class="row payment-login-info">

                                <div class="col-sm-6 col-md-5 col-lg-5">

                                    <!-- <div class="form-group mar-top-0" [ngClass]="{ 'has-error' : f.submitted && !vehiclemake.valid}">

                                        <label class="control-label" for="vehiclemake">Vehicle Make</label>

                                        <input class="form-control" type="text" name="vehiclemake" [(ngModel)]="formData.vehiclemake" #vehiclemake="ngModel" required />

                                        <div *ngIf="f.submitted && !vehiclemake.valid" class="help-block">Vehicle Make is required</div>

                                    </div> -->

                                    <div class="form-group mar-bottom-xs mar-top-xs">

                                        <div class="form-group mar-top-0" [ngClass]="{ 'has-error' : f.submitted && !make_vehicle.valid}">

                                        <select (change)="vehicleDetails(make_vehicle.value)"   name="make_vehicle"   [(ngModel)]="formData.make_vehicle" required #make_vehicle="ngModel" class="form-control" id="exampleFormControlSelect1">

                                          <option value=''>Vehicle Make</option>

                                          <option *ngFor="let makes of make; index as z" value='{{z}}'>{{makes.name}}</option>

                                         

                                        </select>

                                        <div *ngIf="f.submitted && !make_vehicle.valid" class="help-block">Vehicle Make is required</div>

                                    </div> 

                                    </div>

                                </div>

    

                                <div class="col-sm-6 col-md-5 col-lg-5">

                                    <!-- <div class="form-group mar-top-0" [ngClass]="{ 'has-error' : f.submitted && !vehiclemodel.valid}">

                                        <label class="control-label" for="vehiclemodel">Vehicle Model</label>

                                        <input class="form-control" type="text" name="vehiclemodel" [(ngModel)]="formData.vehiclemodel" #vehiclemodel="ngModel" required />

                                        <div *ngIf="f.submitted && !vehiclemodel.valid" class="help-block">Vehicle Model is required</div>

                                    </div> -->

                                    <div class="form-group mar-bottom-xs mar-top-xs">

                                    <div class="form-group mar-top-0" [ngClass]="{ 'has-error' : f.submitted && !make_modal.valid}">

                                        <select (change)="vehicleDetailsWithModal(make_vehicle.value,make_modal.value)"   name="make_modal"   [(ngModel)]="formData.make_modal" required #make_modal="ngModel" class="form-control" id="exampleFormControlSelect1">

                                          <option value=''>Vehicle Model</option>

                                          <option *ngFor="let makesModals of modalName; index as m" value='{{m}}'>{{makesModals.name}}</option>

                                        </select>

                                        <div *ngIf="f.submitted && !make_modal.valid" class="help-block">Vehicle Modal is required</div>

                                        </div>

                                    </div>

                                </div>
                               <div class="clearfix"></div>
                                <div class="col-sm-6 col-md-5 col-lg-5">

                                    <div class="form-group mar-bottom-xs mar-top-xs" [ngClass]="{ 'has-error' : f.submitted && !licenceplate.valid}">

                                        <input class="form-control" type="text" name="licenceplate" [(ngModel)]="formData.licenceplate" #licenceplate="ngModel" (keypress)="licenceplateFormat($event)" maxlength="15" placeholder="License Plate (optional)"/>
                                        <!-- <div *ngIf="f.submitted && !licenceplate.valid" class="help-block">License Plate is required</div> -->
                                    </div>
                                    <p class="smallTextAdded help-block eroor-help hidden-xs" style="font-size: 11px;">{{dontahowmessge}}</p>

                                </div>

                                <!-- <div class="col-sm-6">

                                        <div class="form-group mt-0">

                                                <label class="control-label">License Plate (optional)</label>

                                                <input type="text" class="form-control" id="">

                                        </div>

                                </div> -->

                                <div class="col-sm-6 col-md-5 col-lg-5">

                                    <div class="form-group mar-bottom-xs mar-top-xs" [ngClass]="{ 'has-error' : f.submitted && !vehiclecolor.valid}">

                                        <input class="form-control" type="text" name="vehiclecolor" [(ngModel)]="formData.vehiclecolor" #vehiclecolor="ngModel" required placeholder="Vehicle Color" />

                                        <div *ngIf="f.submitted && !vehiclecolor.valid" class="help-block">Vehicle Color is required</div>

                                    </div>
                                    <p class="smallTextAdded help-block eroor-help visible-xs" style="font-size: 11px;">{{dontahowmessge}}</p>

                                </div>

                            </div>

                            <div class="vehicle-info-text mar-top-sm mar-bottom-sm" *ngIf="extraCharge">

                                <p><img src="/assets/img/icon-alert.png" width="16"/><b>{{heading}}</b> {{des_surchage}}</p>

                                <p class="text-danger">{{price_surcharge}}</p>

                                <div class="form-check newcheckbox-d">

                                    <input (change)="surcharge(surcharge_amount.value)" class="form-check-input" type="checkbox" name="surcharge_amount"  [(ngModel)]="formData.surcharge_amount" #surcharge_amount="ngModel" [required]="extraCharge">

                                    <label class="form-check-label mar-left-sm" for="">

                                    I understand and accept additional surcharge fees. </label>

                                    <div *ngIf="f.submitted && !surcharge_amount.valid" class="help-block eroor-help" style="padding-top: 20px;">**Please accept Surcharge fee Terms and conditions</div>

                                </div>

                            </div>

            </div>

            <!-- <div class="white-box-sdw your-purchage-box mar-top-sm srink-box">

                <h4 class="mar-bottom-xs text-uppercase">Add to your purchase</h4>
                    
                <ul class="nav nav-tabs row">

                    <li class="active col-xs-12 col-sm-6 col-md-6">

                        <div data-toggle="tab">

                        <h4>Monthly Plus (+ $200)</h4>

                        <p>Add another location to park </p>

                        <p><a class="text-secondary" href=""><fa name="minus"></fa>&nbsp; <b>Remove Item</b></a></p>

                        </div>

                    </li>

                    <li class="col-xs-12 col-sm-6 col-md-6">

                        <div data-toggle="tab">

                            <h4>Monthly All Access (+ $300)</h4>

                            <p>Ability to park at all Icon/QP locations</p>

                            <p><a href=""><fa name="plus"></fa>&nbsp; <b>Add Item</b></a></p>

                        </div>

                    </li>

                    <li class="col-xs-12 col-sm-6 col-md-6">

                        <div data-toggle="tab">

                            <h4>Monthly Perk Option 1(+$50)</h4>

                            <p>Unlimited joCobicycle rentals </p>

                            <p><a href=""><fa name="plus"></fa>&nbsp; <b>Add Item</b></a></p>

                        </div>

                    </li>

                    <li class="col-xs-12 col-sm-6 col-md-6">

                        <div data-toggle="tab">

                            <h4>Monthly Perk Option 1(+$100)</h4>

                            <p>10 Uber rides a month </p>

                            <p><a href=""><fa name="plus"></fa>&nbsp; <b>Add Item</b></a></p>

                        </div>

                    </li>

                  </ul>

            </div>  -->
            <div class="hidden-xs mb-4">&nbsp; </div>
            <div *ngIf="!isLogin" class="white-box-sdw your-purchage-box loginButtonBox srink-box">
                <p>
              <a href="javascript:;" (click)="redirectToSignUp();" data-toggle="modal" href='#login' class="btn btn-outline-primary ">LOG IN</a> &nbsp; <strong> OR CREATE ACCOUNT BELOW</strong></p>
               <p class="text-danger"><strong>*You must log in or create a new account to proceed.</strong></p>
            </div> 
            <!-- Park safe responsive -->
            <!-- <div class="white-box-sdw your-purchage-box  mar-top-sm srink-box visible-xs"> -->
            <!-- <div [innerHTML]="respdata?.resparksafetext | safeParkSafe"></div> -->
            <!-- <div class="clearfix pad-top-xs pad-bottom-xs" style="font-size: 14px;">
              <div class="flex-row align-items-center justify-content-center">
                   <div class="col-5 col pad-left-0">
                     <img class="img-responsive" src="/assets/img/logo-parksafe-1.png"> 
                  </div>
                  <div class="col-7 pad-left-0 ">
                      <h4 style="font-weight: 600;">Park with Peace of Mind.</h4>
                  </div>
              </div>
              <div class="flex-row parksafe-with-img-mob pad-top-xs">
                  <div class="col pad-left-0 pad-right-0">
                          <div class="clearfix mar-top-xs">
                              <h4 class="text-primary">Service</h4>
                              <p>Fully contactless mobile reservations, payments, check-in & check-out</p>
                          </div>
                          <div class="clearfix mar-top-xs">
                          <h4 class="text-primary">Sanitize</h4> 
                              <p> Designated Viking Pure® natural cleaning stations at every location</p>
                          </div>
                          <div class="clearfix mar-top-xs">
                              <h4 class="text-primary">Safety</h4>
                              <p>Trained team members with masks & all practicing social distancing</p>
                          </div>
                      </div>

              </div>
              </div> -->
            <!-- </div> -->

               <div class="white-box-sdw your-purchage-box  mar-top-sm srink-box">
                    <!-- <h4 class="mar-top-sx mar-bottom-sm ">ACCOUNT INFORMATION</h4>
                <div *ngIf="!isLogin" class="mar-bottom-sm hidden-xs">
                <a  class="btn btn-outline-primary px-5" id="secondloginbtn" type="button" data-toggle="modal" href='#login'>Log in</a>&nbsp;

                <button class="btn btn-default btnExpand " type="button" >Or, CREATE AN ACCOUNT BELOW </button>

               </div>
               <div class="visible-xs">
               <div class="flex-row no-gutters">
                  <div class="col-auto"><a  class="btn btn-outline-primary px-5 theme-btn btn-rounded" type="button" data-toggle="modal" href='#login'>Log in</a></div>
                   <div class="col">
                    <p style="font-size: 14px; padding-left: 12px;">Not a member? <a style="cursor: pointer;" href="/create-account">Sign Up</a> for Icon Rewards and Save $10</p>
                    </div>
               </div>
               </div>
               <a class="btnExpand-2 text-dark visible-xs" type="button" (click)="isCollapsed = !isCollapsed">Or, CREATE AN ACCOUNT BELOW &nbsp;<fa name="angle-down"></fa></a> -->
					<h4 class="mar-top-sx mar-bottom-xs ">ACCOUNT INFORMATION</h4>
				<!-- <a *ngIf="!isLogin" class="btn btn-outline-primary px-5" id="secondloginbtn" type="button" data-toggle="modal" href='#login'>Log in</a>&nbsp;

				<button  *ngIf="!isLogin" class="btn btn-default btnExpand " type="button" >Or, CREATE AN ACCOUNT BELOW </button> -->

                

                <!-- <button class="btn btn-default btnExpand " type="button" (click)="isCollapsed = !isCollapsed">Or, CREATE AN ACCOUNT BELOW &nbsp;<fa name="angle-up"></fa></button> -->

                <div class="mar-top-sx payment-login-info" id="accountExpand" [collapse]="isCollapsed">

                    

                        <div class="row">

                            <div class="col-sm-6">

                                <div class="form-group mar-top-0" [ngClass]="{ 'has-error' : f.submitted && !firstname.valid}">

                                    <input class="form-control" type="text" placeholder="First Name" id="firstname" name="firstname" [(ngModel)]="formData.firstname" #firstname="ngModel" required />

                                    <div *ngIf="f.submitted && !firstname.valid" class="help-block">First name is required</div>

                                </div>

                            </div>

                            <div class="col-sm-6">

                                <div class="form-group mar-top-0" [ngClass]="{ 'has-error' : f.submitted && !lastname.valid}">

                                    <input class="form-control" type="text" placeholder="Last Name" name="lastname" [(ngModel)]="formData.lastname" #lastname="ngModel" required />

                                    <div *ngIf="f.submitted && !lastname.valid" class="help-block">Last name is required</div>

                                </div>

                                <!-- <div>prodution1</div>> -->

                            </div>

                            <div class="col-sm-6">

                                <div class="form-group mar-top-0" [ngClass]="{ 'has-error' : f.submitted && !email.valid}">

                                    <input [readonly]='userLogin' class="form-control" type="text" placeholder="Email" name="email" [(ngModel)]="formData.email" #email="ngModel" required (keydown)="restSpace($event)" pattern="{{emailRegex}}" />

                                    <div *ngIf="f.submitted && !email.valid" class="help-block">Email is required</div>

                                </div>

                            </div>

                            <div class="col-sm-6">

                                <div class="form-group mar-top-0" [ngClass]="{ 'has-error' : f.submitted && !phone.valid }">

                                    <input class="form-control" type="text" placeholder="Phone Number" name="phone" minlength="10" [textMask]="{mask: phoneMask}" (keyup)="checkPhoneLength(formData.phone)" (keypress)="phoneFormat($event)" [(ngModel)]="formData.phone" #phone="ngModel" required />

                                    <div *ngIf="f.submitted && !phone.valid" class="help-block">Phone Number is required</div>
                                    <!-- <div *ngIf="f.submitted && phoneLengthErrMsg" class="help-block">{{phoneLengthErrMsg}}</div> -->
                                    
                                </div>

                            </div>

                            <div class="col-sm-6">

                                <div class="form-group mar-top-0">

                                    <input class="form-control" type="text" placeholder="Company Name (optional)" name="companyname" [(ngModel)]="formData.companyname" #companyname="ngModel" />

                                </div>

                            </div>

                        </div>

                        <div class="row">

                            <div class="col-sm-6">

                                <div class="form-group mar-top-0" [ngClass]="{ 'has-error' : f.submitted && !address1.valid}">

                                    <input class="form-control" type="text" placeholder="Address Line 1" name="address1" [(ngModel)]="formData.address1" #address1="ngModel" required />

                                    <div *ngIf="f.submitted && !address1.valid" class="help-block">Address Line 1 is required</div>

                                </div>

                            </div>

                            <div class="col-sm-6">

                                <div class="form-group mar-top-0">

                                    <input class="form-control" type="text" placeholder="Address Line 2 (optional)" name="address2" [(ngModel)]="formData.address2" #address2="ngModel" />

                                </div>

                            </div>

                            <div class="col-sm-6">

                                <div class="form-group mar-top-0" [ngClass]="{ 'has-error' : f.submitted && !city.valid}">

                                    <input class="form-control" type="text" placeholder="City" name="city" [(ngModel)]="formData.city" #city="ngModel" required />

                                    <div *ngIf="f.submitted && !city.valid" class="help-block">City is required</div>

                                </div>

                            </div>

                            <div class="col-sm-3 col-xs-6">

                                <div class="form-group mar-top-0" [ngClass]="{ 'has-error' : f.submitted && !state.valid}">

                                    <input class="form-control" type="text" placeholder="State" name="state" maxlength="2" (keydown)="restDigit($event)" [(ngModel)]="formData.state" #state="ngModel" required>

                                    <div *ngIf="f.submitted && !state.valid" class="help-block">State is required</div>

                                </div>

                            </div>

                            <div class="col-sm-3 col-xs-6">

                                <div class="form-group mar-top-0" [ngClass]="{ 'has-error' : f.submitted && !zipcode.valid}">

                                    <input class="form-control" maxlength="6" minlength="5" type="text" placeholder="Zip Code" name="zipcode" (keypress)="allowNumbersOnly($event)" [(ngModel)]="formData.zipcode" #zipcode="ngModel" required />

                                    <div *ngIf="f.submitted && !zipcode.valid" class="help-block">Zip Code is required</div>

                                </div>

                            </div>

                        </div>

                        <div *ngIf="!isLogin" class=" mar-top-sm ">
                            <p class="font-weight-bolder mar-top-xs">Create Account Password</p>
                                <div  class="row">
                                    <div class="col-sm-6">
                                        <div class="form-group mar-top-0" [ngClass]="{ 'has-error' : f.submitted && !password.valid}">
                                        <input class="form-control" type="password" name="password" placeholder="Create Password" (keyup)="checkPasswordLength(formData.password)" [(ngModel)]="formData.password" #password="ngModel" required="!isLogin"  validateEqual="cpassword" reverse="true"/>
                                        <div *ngIf="f.submitted && !password.valid" class="help-block">{{passwordErrMsg}}</div>
                                        <div *ngIf="passwordLengthErrMsg" class="help-block error-msg">{{passwordLengthErrMsg}}</div>
                                    </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="form-group mar-top-0" [ngClass]="{ 'has-error' : f.submitted && !cpassword.valid}">
                                            <input class="form-control" type="password" placeholder="Confirm Password" name="cpassword" (keyup)="checkConfirmPassword(formData.cpassword)" [(ngModel)]="formData.cpassword" #cpassword="ngModel" required="!isLogin" validateEqual="password" />
                                            <div *ngIf="cPasswordErrMsg" class="error-msg help-block">{{cPasswordErrMsg}}</div>
                                            <div *ngIf="f.submitted && !cpassword.valid && cPasswordReqMsg" class="help-block">Confirm Password is required</div>
                                          </div>
                                    </div>
                                </div>
                        </div>

                        <!-- <h4 class="mar-top-xs text-uppercase">ADDITIONAL INFORMATION</h4> -->

                        <p class="hidden-xs font-weight-bolder mar-top-xs">The persons listed below are authorized to use this vehicle  &nbsp;

                            <a type="button"

                           popover="This vehicle will not be released to

                           any other individual without a

                           letter of authorization signed by

                           the monthly applicant."

                           placement="right" style="cursor:pointer;"><img [src]="helpIcon" width="16" alt="helpIcon"/></a>

                       </p>

                        <div class="row hidden-xs">

                            <div class="col-sm-6">

                                <div class="form-group">

                                    <input class="form-control" type="text" placeholder="Additional Driver 1 (optional)" name="driver1" [(ngModel)]="formData.driver1" #driver1="ngModel" />

                                </div>

                                <div class="form-group">

                                    <input class="form-control" type="text" placeholder="Additional Driver 2 (optional)" name="driver2" [(ngModel)]="formData.driver2" #driver2="ngModel" />

                                </div>

                                <div class="form-group">

                                    <input class="form-control" type="text" placeholder="Additional Driver 3 (optional)" name="driver3" [(ngModel)]="formData.driver3" #driver3="ngModel" />
</div>
                        </div>
                    </div>
                    <div class="additional-driver visible-xs">
                        <div id="accordion" class="accordion">
                            <div class="mar-top-xs mar-bottom-xs">
                                <div class="c-header collapsed" data-toggle="collapse" href="#driver1">
                                    <a class="card-title text-dark"><b>Add Additional Drivers</b><br>
                                    <span>Add up to 3 Drivers. This step is optional</span>
                                    </a>
                                </div>
                                <div id="driver1" class="collapse max-bottom-xs" data-parent="#accordion">
                                    <div class="form-group">
                                        <input class="form-control" type="text" placeholder="Additional Driver 1 (optional)" name="driver1" [(ngModel)]="formData.driver1" #driver1="ngModel" />
                                    </div>
                                    <div class="form-group">
                                        <input class="form-control" type="text" placeholder="Additional Driver 2 (optional)" name="driver2" [(ngModel)]="formData.driver2" #driver2="ngModel" />
                                    </div>
                                    <div class="form-group">
                                        <input class="form-control" type="text" placeholder="Additional Driver 3 (optional)" name="driver3" [(ngModel)]="formData.driver3" #driver3="ngModel" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
               
            </div>
            <div class="hidden-xs mb-4">&nbsp; </div>
                <div class="white-box-sdw your-purchage-box  srink-box">

                    <div class="mar-top-xs" id="accountExpand">

                        

                        <h4 class="mar-top-0 text-uppercase">Payment Details</h4>
                        <p class="mar-top-0 mar-bottom-sx">Enter Card Details</p>
                        <div class="red-box-payment" *ngIf='paymentError'>
                            <table class="hidden-xs">
                                <tr><td><img [src]="redboxstrip"></td>
                                <td>
                                <p><b>We were unable to authorize the credit card you provided.</b></p>
                                <p>Please confirm the card info or use another card and try again.</p>
                                </td></tr>
                            </table>
                            <p class="text-center visible-xs">
                              <b>We were unable to authorize the credit card you provided. Please confirm the card info or use another card and try again.</b>
                            </p>
                        </div>
                        <!-- <div class="red-box-payment" *ngIf='paymentError1'>
                            <table class="hidden-xs">
                                <tr><td><img [src]="redboxstrip"></td>
                                <td>
                                <p><b>{{errormessage}}</b></p>
                                
                                </td></tr>
                            </table>
                            <p class="text-center visible-xs">
                              <b>We were unable to authorize the credit card you provided. Please confirm the card info or use another card and try again.</b>
                            </p>
                        </div> -->
                        <!-- <div class="form-check mar-bottom-xs">

                            <input class="form-check-input" type="checkbox" name="sameonbiling"  [(ngModel)]="formData.sameonbiling" #sameonbiling="ngModel" (change)="checkValue(formData.sameonbiling)">

                            <label class="form-check-label mar-left-sm" for="sameAdd">Billing address same as account address</label>

                        </div>  -->
                        <hr class="my-3" />

                           <!--  <div class="militry-collaps">

                            <div id="accordion" class="accordion">

                                <div class="mar-top-xs mar-bottom-xs hidden-xs">

                                    <div class="c-header collapsed" data-toggle="collapse" href="#collapseOne">

                                        <a class="card-title text-dark"><b>Military & First Responder's Discount </b></a>

                                    </div>

                                    <div id="collapseOne" class="collapse mar-top-xs max-bottom-xs" data-parent="#accordion">

                                    <p>Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic or web designs. The passage is attributed to an unknown typesetter in the 15th century</p>

                                    </div>

                                </div>

                                <div class="mar-top-xs mar-bottom-xs visible-xs">

                                    <div class="row">

                                        <div class="col-xs-12">

                                                <div class="c-header collapsed" data-toggle="collapse" href="#collapseOnemob">
                                               <a class="card-title text-dark"><b>Military & First Responder's Discount </b></a>
                                                </div>
                                                <div id="collapseOnemob" class="collapse mar-top-xs max-bottom-xs" data-parent="#accordion">

                                               <p>Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic or web designs. The passage is attributed to an unknown typesetter in the 15th century</p>

                                                </div>

                                        </div>

                                    </div>

                                

                                    

                                </div>

                            </div>

                            </div> -->

                            <!-- <p class="mar-top-xs hidden-xs">

                                    <a href="javascript:;" class="pr-1"><img width="185" src="assets/img/verify_id.png"> &nbsp;&nbsp;</a>

                                    <span class="py-2 verification-id"><img width="13" src="assets/img/lock_icon.png" style="margin-top: -4px; margin-right: 5px;"> Verification by ID.me <a href="javascript:;" class="text-primary">What is ID.me?</a></span>

                            </p> -->

                            <!-- <p class="mar-top-xs visible-xs row">
                        <a href="javascript:;" class="pr-1 col-xs-6"><img width="185" class="img-responsive" src="assets/img/verify_id.png"> &nbsp;&nbsp;</a>
                        <span class="py-2 verification-id col-xs-6"><img width="13" src="assets/img/lock_icon.png" style="margin-top: -4px; margin-right: 5px;"> Verification by ID.me <a href="javascript:;" class="text-primary">What is ID.me?</a></span>
                        </p> -->
                        <!-- <button type="button" class="btn btn-payment btn-block visible-xs mar-top-xs max-bottom-xs" data-toggle="collapse" data-target="#monthpayment"><fa name="credit-card" class="text-primary card-credit"></fa> &nbsp; New Credit/Debit Card &nbsp;&nbsp;<fa name="angle-down"  class="text-primary down-angle"></fa></button> -->
                        <div id="monthpayment" class="collapse payment-login-info in">
                        <div class="row">

                                <div class="col-sm-6 col-md-5">

                                    <div class="form-group nameOnCardDiv mar-bottom-xs" [ngClass]="{ 'has-error' : f.submitted && !name_on_card.valid}">

                                        <input id="nameOnCard" class="form-control inputBoxStyling" type="text" autocomplete="off" name="name_on_card" placeholder="Name on Card" [(ngModel)]="formData.name_on_card" #name_on_card="ngModel" [required]="isCardReq" />

                                        <div *ngIf="f.submitted && !name_on_card.valid" class="help-block">Name on Card is required</div>

                                    </div>

                                </div>

                            </div>

                            <div class="row">

                            <div *ngIf="has_valet_manager == false"  class="col-sm-6 col-md-5">

                                    <div class="form-group paymentDiv mar-bottom-xs" [ngClass]="{ 'has-error' : f.submitted && !card_number.valid}">

                                        <input type="tel" autocomplete="off" placeholder="Card Number" (keyup)="checkCard($event)" [textMask]="{mask: cardMask}" (keypress)="restrictNumeric($event)" class="form-control inputBoxStyling" name="card_number" id="cardNumber" [(ngModel)]="formData.card_number" 

                                            #card_number="ngModel" (ngModelChange)="formData.card_number = unmask($event)" [required]="isCardReq" />

                                            <!-- <iframe *ngIf="FacAccountType == 'VM'" id="tokenFrame" height="40px" name="tokenFrame" src="https://valetmanager.cardconnect.com/itoke/ajax-tokenizer.html?formatinput=true&placeholder=Card%20Number&cardinputmaxlength=19&invalidcreditcardevent=true&css=body%7Bfont-family%3Ainherit%3Bpadding%3A0px%3Bmargin%3A0px%3Bcolor%3A%23000%3B%7D%3A%3A-webkit-input-placeholder%7Bcolor%3A%23999%3Bfont-weight%3A300%3B%7D%3A-ms-input-placeholder%7Bcolor%3A%23999%3Bfont-weight%3A300%3B%7D%3A%3Aplaceholder%20%7Bcolor%3A%23999%3Bfont-weight%3A300%3B%7Dinput%3Afocus%20%7Bborder%3A1px%20solid%20%23ed8222!important%3Bbackground-color%3A%23fff!important%3B-webkit-box-shadow%3Ainset%200%201px%201px%20rgba(0%2C0%2C0%2C.075)!important%3Bbox-shadow%3Ainset%200%201px%201px%20rgba(0%2C0%2C0%2C.075)!important%3B%7Dinput%7Bborder%3A1px%20solid%20%23d8d8d8%3Bbox-sizing%3Aborder-box%3Bborder-radius%3A4px%3Bbackground-color%3Atransparent%3B-webkit-box-shadow%3Anone%3Bbox-shadow%3Anone%3Bcolor%3A%23000%3Bheight%3A40px%3Bwidth%3A100%25%3Bbackground-position%3A96%25center%3Bpadding-left%3A15px%3Bpadding-right%3A15px%7D"  scrolling="no" width="100%"  frameborder="0"></iframe> -->
                                            <!-- <small *ngIf="FacAccountType == 'VM'" class="error text-danger" >Please Enter Valid Card Number</small> -->
                                            <!-- <input *ngIf="FacAccountType == 'VM'" style="visibility: hidden;" type="text" name="mytoken" id="mytoken"/> -->

                                            <img height="22px" class="visible-xs" style="height: 22px;margin-top: 8px;position: absolute;right: 10px;top: 0;" src="../../../assets/img/card-list.png">


                                        <div *ngIf="f.submitted && !card_number.valid" class="help-block">Card Number is required</div>

                                    </div>

                                </div>
                                <div *ngIf="has_valet_manager"  class="col-sm-6 col-md-5">

                                    <div class="form-group paymentDiv mar-bottom-xs" style="margin-bottom: -10px;" [ngClass]="{ 'has-error' : f.submitted && showCardError}">
                                            <iframe  id="tokenFrame" height="40px" name="tokenFrame" src="https://valetmanager.cardconnect.com/itoke/ajax-tokenizer.html?formatinput=true&invalidinputevent=true&placeholder=Card%20Number&cardinputmaxlength=19&invalidcreditcardevent=true&css=body%7Bfont-family%3Ainherit%3Bpadding%3A0px%3Bmargin%3A0px%3Bcolor%3A%23000%3B%7D%3A%3A-webkit-input-placeholder%7Bcolor%3A%23999%3Bfont-weight%3A300%3B%7D%3A-ms-input-placeholder%7Bcolor%3A%23999%3Bfont-weight%3A300%3B%7D%3A%3Aplaceholder%20%7Bcolor%3A%23999%3Bfont-weight%3A300%3B%7Dinput%3Afocus%20%7Bborder%3A1px%20solid%20%23ed8222!important%3Bbackground-color%3A%23fff!important%3B-webkit-box-shadow%3Ainset%200%201px%201px%20rgba(0%2C0%2C0%2C.075)!important%3Bbox-shadow%3Ainset%200%201px%201px%20rgba(0%2C0%2C0%2C.075)!important%3B%7Dinput%7Bborder%3A1px%20solid%20%23d8d8d8%3Bbox-sizing%3Aborder-box%3Bborder-radius%3A4px%3Bbackground-color%3Atransparent%3B-webkit-box-shadow%3Anone%3Bbox-shadow%3Anone%3Bcolor%3A%23000%3Bheight%3A40px%3Bwidth%3A100%25%3Bbackground-position%3A96%25center%3Bpadding-left%3A15px%3Bpadding-right%3A15px%7D"  scrolling="no" width="100%"  frameborder="0"></iframe> 
                                            <!-- <small *ngIf="f.submitted && showCardError" class="error text-danger" >Please Enter Valid Card Number</small> -->
                                            <div style="bottom: 3px;" *ngIf="f.submitted && showCardError" class="help-block">Please Enter Valid Card Number</div>
                                            <input style="visibility: hidden; padding: 0; height: 0; margin: 0; width: 0;" type="text" name="creditCardToken" id="creditCardToken"/>

                                            <img height="22px" class="visible-xs" style="height: 22px;margin-top: 8px;position: absolute;right: 10px;top: 0;" src="../../../assets/img/card-list.png">

                                    </div>

                                </div>

                                <div class="col-sm-6 col-md-5 hidden-xs">

                                    <div class="form-group paymentDiv mar-bottom-xs " >

                                            <img height="22px" style="height: 22px;margin-top: 8px;" src="../../../assets/img/card-list.png">

                                    </div>

                                </div>

                            </div>
                            <!-- <div *ngIf="FacAccountType == 'VM'" class="row">
                                <div class="col-sm-6 col-md-5">
                                    <div class="form-group" [ngClass]="{ 'has-error' : f.submitted && showCardError}">
                                        <iframe id="tokenFrame" name="tokenFrame" src="https://valetmanager.cardconnect.com/itoke/ajax-tokenizer.html?formatinput=true&cardinputmaxlength=19&invalidcreditcardevent=true&invalidcreditcardevent" frameborder="0" scrolling="no"></iframe>
                         <input type="text" name="creditCardToken" id="creditCardToken"/>
                                    <div *ngIf="f.submitted && showCardError" class="help-block">Card Number is required</div>
                                    </div>
                                </div>
                            </div> -->

                            <div class="row">

                                <div class="col-sm-6 col-md-5">

                                    <div class="form-group paymentDiv mar-bottom-xs" [ngClass]="{ 'has-error' : f.submitted && !expiration_date.valid}">

                                        <input type="tel" autocomplete="off" placeholder="Expiration Date (mm/yy)" (keyup)="setToexpirationField(expiration_date)" id="standardCardExpiration" [textMask]="{mask: dateMask}" minlength="4" class="form-control inputBoxStyling" name="expiration_date"

                                            [(ngModel)]="formData.expiration_date" #expiration_date="ngModel" (ngModelChange)="formData.expiration_date = unmask($event)" [required]="isCardReq" />

                                        <input class="form-control" type="hidden" autocomplete="off" [(ngModel)]="formData.exp1" name="exp1" #exp1="ngModel" id="standardCardExpiration1">

                                        <div *ngIf="f.submitted && !expiration_date.valid" class="help-block">Expiration Date is required</div>

                                    </div>

                                </div>

                            </div>

                            <div class="row">

                                <div class="col-sm-6 col-md-5">

                                    <div class="form-group paymentDiv mar-bottom-xs" [ngClass]="{ 'has-error' : f.submitted && !security_code.valid}">

                                        <div class="input-group-1">

                                            <input type="tel" autocomplete="off" placeholder="CVV / Security Code" maxlength="4" class="form-control inputBoxStyling securityCodeInput" name="security_code" id="standardCardCvv" (keypress)="allowNumbersOnly($event)" [(ngModel)]="formData.security_code" #security_code="ngModel" [required]="isCardReq"

                                            />

                                            <div class="input-group-append ml-2 d-none d-sm-block my-2" container="body" containerClass="PaymentCustome" placement="top" [tooltip]="popTemplate"><img [src]="infoIcon" width="19" alt="i"></div>

                                            <!-- fix for mobile device tooltip -->

                                            <div class="input-group-append ml-2 d-block d-sm-none my-2" container="body" containerClass="PaymentCustome" placement="left" [tooltip]="popTemplate"><img [src]="infoIcon" width="19" alt="i"></div>

                                            <!-- tooltip fif ended -->

                                        </div>

                                        <div *ngIf="f.submitted && !security_code.valid" class="help-block zip-error">Security Code is required</div>

                                    </div>

                                </div>

                            </div>
                            <div class="row">

                                <div class="col-sm-6 col-md-5">

                                    <div class="form-group nameOnCardDiv mar-bottom-xs" [ngClass]="{ 'has-error' : f.submitted && !pzipcode.valid}">

                                        
                                        <input class="form-control" maxlength="6" minlength="5" type="text" name="pzipcode" placeholder="BIlling Address Zip Code" (keypress)="allowNumbersOnly($event)" [(ngModel)]="formData.pzipcode" #pzipcode="ngModel" [required]="isCardReq" />
                                        <div *ngIf="f.submitted && !pzipcode.valid" class="help-block">Zip Code is required</div>

                                    </div>

                                </div>

                            </div>
                            <!-- <div class="row">

                                <div class="col-sm-6 col-md-5">

                                    <div class="form-group mar-bottom-xs" [ngClass]="{ 'has-error' : f.submitted && !zip_code.valid}">

                                            <input autocomplete="off" maxlength="6" placeholder="Zip Code" minlength="5" class="form-control inputBoxStyling" type="text" name="zip_code" id="standardCardZip" [(ngModel)]="formData.zip_code" #zip_code="ngModel" [required]="isCardReq" />

                                            <div *ngIf="f.submitted && !zip_code.valid" class="help-block">Zip Code is required</div>

                                    </div>

                                </div>

                            </div> -->

                            <div class="row mar-top-xs ">

                                <div class="col-sm-6 col-md-6">

                                    <div class="form-group mar-top-0">

                                        <label for="promocode">Add Promo Code </label>

                                        <div class="promocode-1">

                                            <input class="form-control" type="text" id="promocodes" name="promocode"  placeholder="Promo Code" [(ngModel)]="formData.promocode" #promocode="ngModel" />

                                            <a class="apply-btn btn btn-secondary mar-left-xs" (click)="apply()">Apply</a>

                                        </div>

                                    </div>

                                </div>

                            </div>

                            </div>

                            

                        <div class="row mar-top-xs">

                            <!-- <div class="col-md-6" *ngIf="respdata?.monthlytaxFlag==0">

                                <div class="clearfix">

                                    <p class="currentCarD currentCarbtn">

                                        <span *ngIf="respdata?.monthlytaxFlag==1">Total</span>

                                        <span *ngIf="respdata?.monthlytaxFlag==0">Price</span>

                                        <span class="fa-2x" *ngIf="respdata?.monthlytaxFlag==1"> ${{checkFormat(rateDetails)}}</span>

                                        <span class="fa-2x" *ngIf="respdata?.monthlytaxFlag==0"> ${{checkFormat(rateDetailsNetRate)}}</span>

                                    </p>

                                </div>

                            </div>

                            <div class="clearfix" *ngIf="respdata?.monthlytaxFlag==0"></div>

                            <div class="col-md-6" *ngIf="respdata?.monthlytaxFlag==0">

                                <div class="clearfix">

                                    <p class="currentCarD currentCarbtn">

                                        <span>Tax</span>

                                        <span class="fa-2x"> ${{checkFormat(taxForThis)}}</span>

                                    </p>

                                </div>

                            </div>

                            <div class="clearfix"></div>

                            <div class="col-md-6">

                                <div class="clearfix">

                                    <p class="currentCarD currentCarbtn">

                                        <span>Total</span>

                                        <span class="fa-2x"> ${{checkFormat(rateDetails)}}</span>

                                        <button #PromocodePopup type="button" class="btn btn-primary btn-fill pull-right hide" (click)="openModal(template)">SUBMIT</button>

                                        <button type="submit" class="btn btn-primary btn-fill pull-right ">SUBMIT</button>

                                    </p>

                                    Rates quoted are for new customers of Icon Parking and Quik Park

                                </div>

                            </div> -->

                            <!-- <div class="col-sm-6 text-danger">

                                <small>Looks like something isnʼt filled out correctly. Please check your fields and try again.</small>

                            </div> -->

                        </div>

                    

                        <ng-template #template>

                            <div class="modal-header" style="margin-top: -10px;">

                                <h4 class="modal-title pull-left text-primary text-uppercase"></h4>

                                <button type="button" class="close pull-right" aria-label="Close" [scrollTo]="'#testID'" [scrollDuration]="1000" [scrollOffset]="-200" (click)="modalRef.hide(); enableButton()">

                                    <span aria-hidden="true" style="font-size: 50px; font-weight: 400;">&times;</span>

                                </button>

                            </div>

                            <div class="modal-body" style="padding: 10px 20px">

                                <h3 class="text-primary mar-top-0 mar-bottom-xs">Promo vouchers will be emailed in separate email after purchase.</h3>

                                <div class="btn-center-xs mar-top-sm mar-bottom-xs">

                                    <a class="btn btn-primary theme-btn" [scrollTo]="'#testID'" [scrollDuration]="1000" [scrollOffset]="-200" href="javascript:;" (click)="UpdatePromocodeStatus(formData, f)">Got it</a>

                                    <a class="btn btn-primary theme-btn" [scrollTo]="'#testID'" [scrollDuration]="1000" [scrollOffset]="-200" href="javascript:;" (click)="modalRef.hide(); enableButton()">Cancel</a>

                                </div>

                                <div class="mar-bottom-sm visible-xs"></div>

                            </div>

                        </ng-template>

                </div>

            </div>

            <div class="white-box-sdw your-purchage-box mar-top-sm srink-box mob-top-0 box-hd newcheckbox-d">

                <div class="form-check mt-2"  id="testID">
            <span id="ModalovernightPopup" #myDiv (click)="lgModalovernightPopup.show();openpopupsetup()">
                    <input id="termsConditionCheck" class="form-check-input" type="checkbox" name="terms" [(ngModel)]="formData.terms" #terms="ngModel" required>

                    <label class="form-check-label mar-left-sm mar-bottom-xs mar-top-0" for="termsConditionCheck">

                        <!-- I understand and agree with the terms of this subscription and its fees. <a target="_blank" href="/monthly-parking-terms-conditions">Details.</a> -->
                        <!-- I understand and agree with the terms of this subscription and its fees. <a type="button" style="cursor: pointer;" >Details. checkFormatForList  formatChange(checkFormatWithSurcharge(rateDetails))</a> -->

                        <!-- modified on 11 july 2022 -->
                        <!-- I understand that payment for the amount of <strong sourceFont style="font-weight:800;">${{formatChange(checkFormatWithSurcharge(rateDetails))}}</strong> will be charged today and then on the 1st of each month, as applicable to the term selected, to the credit card entered. 
                        The listed rate is guaranteed for {{selectedvahicletype | lowercase}}.  After the selected time frame, billing will continue at a rate that is subject to change until a cancellation request is submitted online.  
                            I understand and agree with the terms of this subscription.<a type="button" style="cursor: pointer;" > Read Terms & Conditions</a> -->

                            <!--  moddification ends -->
                            <!-- I understand that payment for the amount of <strong sourceFont style="font-weight:800;">${{formatChange(checkFormatWithSurcharge(rateDetails))}}</strong> will be charged today and then on the 1st of each month<span *ngIf="showtcextra">, as applicable to the term selected,</span> to the credit card entered.  -->
                            <!-- The listed rate is guaranteed for {{selectedvahicletype | lowercase}}. After the selected time frame, billing will continue at a rate that is subject to change until a cancellation request is submitted online.  -->
                            <!-- 
                                this section is for 3,6,12 months
                            -->
                            <span *ngIf="showtcextra">
                               
                                I understand that payment for <strong sourceFont style="font-weight:800;">${{formatChange(checkFormatWithSurcharge(rateDetails))}}</strong> will be charged today and then on the 1st of each month,
                                 as applicable to the term selected. Early cancellation will be subject to a termination fee of 50% of your remaining subscription term balance, up to $1,000. At the conclusion of your 
                                 <strong sourceFont style="font-weight:800;">{{selectedvahicletype | lowercase}} subscription</strong>, billing will continue at a rate that is subject to change until a cancellation notice is submitted online.
                                     You must submit your cancellation notice at least 10 days prior to the subscription end date. I understand and agree with the terms of this subscription.
                                      <a type="button" style="cursor: pointer;" > Read Terms & Conditions</a>
                            </span>
                            <!-- 
                                this section is for month to month
                            -->
                            <span *ngIf="showtcextra==false">
                                I understand that payment for <strong sourceFont style="font-weight:800;">${{formatChange(checkFormatWithSurcharge(rateDetails))}}</strong>
                                 will be charged today and then on the 1st of each month. Billing will continue month to month at a rate that is subject to change until a cancellation notice is submitted online. You must submit your cancellation notice at least 10 days in advance. I understand and agree with the terms of this subscription.
                                <a type="button" style="cursor: pointer;" > Read Terms & Conditions</a> 
                            </span>
                            
                            
                            
                            <!-- <span *ngIf="showtcextra">Early cancellation will be subject to a termination fee of 50% of your remaining subscription term balance, up to $1,000. The listed rate is guaranteed for {{selectedvahicletype | lowercase}}. After the selected time frame, billing will continue at a rate that is subject to change until a cancellation request is submitted online.</span> 
                            <span *ngIf="showtcextra==false">Billing will continue month to month at a rate that is subject to change, until a cancellation request is submitted online. Rate shown is guaranteed only for the first month.</span>
                            I understand and agree with the terms of this subscription. <a type="button" style="cursor: pointer;" > Read Terms & Conditions</a>
                            <b>New customers only. You are entering a monthly subscription agreement with Icon Parking for the term length selected. Rate shown is locked for term length. After the selected time frame, billing will continue at a rate that is subject to change until a cancellation request is submitted online. Must commit to monthly autopay via credit card. Early cancellation will be subject to 50% of your remaining subscription term balance, up to $1,000.</b> (For one month subscriptions leave out “Must commit to monthly autopay via credit card. Early cancellation will be subject to 50% of your remaining subscription term balance, up to $1,000.”). -->
                    </label>
                </span>
                    <div *ngIf="f.submitted && !terms.valid" class="help-block eroor-help">Please accept Monthly Parking Terms and Conditions </div>

                </div>

            </div>
           

<div bsModal #lgModalovernightPopup="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true" [config]="{backdrop: 'static'}">

    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" style="margin-bottom: 20px" #bookerrormsgpopupclose class="close" aria-label="Close" [scrollTo]="'#testID'" [scrollDuration]="1000" [scrollOffset]="-200" (click)="lgModalovernightPopup.hide();closeTermsPopup()">
          <fa name="times"></fa>
        </button>
            </div>

            <div class="modal-body" style="padding-top:0px;">
                <!-- <div class="icon-rewards-terms mar-top-xs">
                    <div [innerHTML]="staticPageContent.post_content"></div>
                </div> -->
                <div class="scroll-container ps border" style="height:300px;overflow-y: scroll;" id="tcevnt">                
                   <div class="scrollable-content" [innerHTML]="staticPageContent.post_content"></div>
                </div>
              <div class="form-group my-4">
                <span id="ModalovernightPopup" #myDiv (click)="lgModalovernightPopup.show()" >
                    <input (click)="activate_term_btn()" class="form-check-input " type="checkbox" name="terms_check" id="terms_checkbox" [disabled]="disabled"  required>
                    <label class="form-check-label ml-5 my-2" for="termsConditionCheck">By checking this box you have read, understood and agreed to the Terms & Conditions</label>
                </span>
              </div>  
              <div class="form-group mar-bottom-0 btn-center-xs">
                
                
                <button (click)="acceptTermsCondition();lgModalovernightPopup.hide();" [scrollTo]="'#testID'" [scrollDuration]="1000" [scrollOffset]="-200" type="button" class="btn btn-primary btn-fill mb-2" [disabled]="disabled_btn" >ACCEPT</button>
                <span class="mar-left-xs"></span>
                <button (click)="lgModalovernightPopup.hide();closeTermsPopup()" [scrollTo]="'#testID'" [scrollDuration]="1000" [scrollOffset]="-200" type="button" class="btn btn-primary theme-btn mb-2">CANCEL</button>
                <span class="mar-left-xs"></span>
                <a routerLink="/monthly-terms-and-conditions/" target="_BLANK" style="white-space: break-spaces;" class="btn btn-link text-primary mb-2" >Open Icon Parking Monthly Agreement – Terms and Conditions</a>
              </div>
        </div>
    </div>
</div>
</div>

            <div class="d-block mar-top-sm hidden-xs">
                <!-- <button [disabled]="disableSubmit " #PromocodePopup type="button" style="min-width: 200px;" class="btn btn-primary btn-fill px-5 d-inline-block book-it hide" (click)="openModal(template)">BOOK IT</button> -->
                <!-- <button *ngIf="!(isLogin)" [ngClass]="{'hide':isLogin}" [disabled]="disableSubmit" type="submit"  style="min-width: 200px;" class="btn btn-primary btn-fill px-5 d-inline-block book-it">BOOK IT</button> -->
                <button [disabled]="disableSubmit" type="submit" style="min-width: 200px;" class="btn btn-primary btn-fill px-5 d-inline-block book-it">BOOK IT</button>
            </div>

            <p class="mar-top-xs mar-bottom-sm w-100  hidden-xs"><a role="button" font-12 class="text-dark mt-4 text-center" style="cursor: pointer;"><img width="15px" class="mar-right-xs" src="/assets/img/best_guaranteed_icon.png">Our Best Deals Guaranteed </a></p>
            <div class="mar-top-sm visible-xs"></div>
            

            </div>

            <div class="col-sm-4 col-xs-12 pull-right mob-sidemap">

                

                <img [src]="facilityImage" alt="facilityImage" style="border-radius: 0px 0px 0 0;" class="img-responsive full-width" onerror="delete img.onerror;" height="200px">

                <div class="white-box-sdw your-purchage-box">

                <p class="visible-xs">&nbsp;</p>

                <h3>Reservation Summary</h3>

                <p *ngIf='dateFound'>Starting {{changeTime(monthlyDate)}}</p>

                <div class="" sourceFont>
                   

                    <table width="100%" class="monthly-price-section">

                        
                        <tr *ngIf='discount'>
                            <td colspan="2" class="text-cetner">
                                <h4 class="text-center text-primary font-weight: 700;" style="font-weight: 700;">{{monthlyPromocodeApplied}} &nbsp;&nbsp;&nbsp;
                                   <a href="javascript:void(0)" class="removePromocodeLink" (click)="removeoupon()">
                                       <fa name="times" style="vertical-align: text-bottom;"></fa>
                                   </a>
                               </h4>
                           </td>
                           </tr>
                            <tr>
                                <td><b>Price</b></td>
                                <td  align="right" *ngIf='!taxHideShow  && showTPriceAndTotal'><b>{{CheckForSoldOut(checkFormat(rateDetailsNetRate))}}</b></td>
                                <td  align="right" *ngIf='taxHideShow  && showTPriceAndTotal'><b>{{CheckForSoldOut(checkFormat(rateDetails) )}}</b></td>
                                <!-- <td  align="right" *ngIf='!taxHideShow && !showTPriceAndTotal'></td>
                                <td  align="right" *ngIf='taxHideShow && !showTPriceAndTotal'></td> -->
                            </tr>
                            <tr *ngIf='discount'> 
                                <td><b>Promo code</b></td>
                                <td  align="right" *ngIf='!taxHideShow'><b>-${{discountData(rateDetailsNetRate)}}</b></td>
                                <td  align="right" *ngIf='taxHideShow'><b>-${{discountData(rateDetails)}}</b></td>
                            </tr>
                            <tr class="hide" *ngIf='discountWithApi'> 
                                <td><b>Promo code</b></td>
                                <td  align="right" *ngIf='!taxHideShow'><b>-${{discountData(rateDetailsNetRate)}}</b></td>
                                <td  align="right" *ngIf='taxHideShow'><b>-${{discountData(rateDetails)}}</b></td>
                            </tr>

                            <tr *ngIf='extraChargeCheck'>
                                    <td><b>{{surchargeType}}</b></td>
                                    <td  align="right"><b>${{checkFormat(price_surcharge_value)}}</b></td>
                            </tr>
                            <tr *ngIf='!taxHideShow'>

                                <td><b>Tax</b></td>

                                <td  align="right"><b>${{checkFormat(taxForThis)}}</b></td>

                            </tr>
                            <tr *ngIf='processing_fees>0'>

                                <td><b>Activation Fee <span containerClass="processingFeesClass"  [tooltip]="processingFeesTooltip"
                                    placement="top"><img width="12px" [src]="infoIcon"/></span></b></td>

                                <td  align="right"><b>+ {{checkProcessing(processing_fees)}} </b></td>

                            </tr>

                    </table>
                    <!-- <ng-template #processingFeesTooltip><p>The activation fee is a one-time charge for the account set-up, access tag, individual account administrative access, secure billing connectivity, and service from the start date for the duration of the agreement. </p></ng-template> -->

                    <ng-template #processingFeesTooltip><p [innerHtml]="staticPageContent.processing_fee_description"> </p></ng-template>
                    <hr class="my-3"/>

                    <table width="100%">
                       
                    <tr>

                        <td class="font-weight-bolder pb-2" style="font-size: 20px;">Total </td>
                        <!-- <td align="right" *ngIf='!showTPriceAndTotal'><h2 style="font-size: 20px;" class="my-4 font-weight-bolder">
                            </h2></td> -->
                        <td *ngIf='showTPriceAndTotal' align="right"><h2 style="font-size: 20px;" class="my-4 font-weight-bolder">{{CheckForSoldOut(formatChange(checkFormatWithSurcharge(rateDetails)) )}}
                            <br /><span class="taxIncludeText">(Taxes Included)</span></h2></td>

                    </tr>

                  </table>

                    <div class="d-block hidden-xs">

                        <!-- <button [disabled]="disableSubmit || !(make_vehicle.valid && make_modal.valid && vehiclecolor.valid && phone.valid && address1.valid && city.valid && state.valid && zipcode.valid && name_on_card.valid && card_number.valid && expiration_date.valid && security_code.valid && pzipcode.valid && terms.valid )" #PromocodePopup type="button" class="btn btn-primary btn-fill book-it hide" (click)="openModal(template)">BOOK IT </button> -->
                         <!-- <button [disabled]="disableSubmit || !(make_vehicle.valid && make_modal.valid && vehiclecolor.valid && phone.valid && address1.valid && city.valid && state.valid && zipcode.valid && name_on_card.valid && card_number.valid && expiration_date.valid && security_code.valid && pzipcode.valid && terms.valid )" type="submit"  style="min-width: 200px;" class="btn btn-primary btn-fill btn-block d-inline-block book-it hide">BOOK IT </button> -->
                        

                    </div>

                    <p class="w-100 text-center mar-top-xs hidden-xs"><a role="button" font-12 class="text-dark text-center" style="cursor: pointer;"><img width="15px" class="mar-right-xs" src="/assets/img/best_guaranteed_icon.png"> Our Best Deals Guaranteed </a></p>

                    <!-- <div class="col-sm-6 text-danger">

                        <small>Looks like something isnʼt filled out correctly. Please check your fields and try again.</small>

                    </div> -->

                </div>

                <!---- mobile monthly popup --->

                
                <!--Monthly facility details-->

                <!-- <div class="well mar-bottom-0 monthly-fac-details hidden-xs">

                    <h4 class="text-primary text-uppercase">facility details</h4>

                    <p><b>Between:</b> {{respdata?.between_streets}} </p>

                    <p><b>Building Address:</b> {{respdata?.geolocations.address_1}}</p>

                    <div class="row" *ngIf="FgarageWorkingHours?.length <= 0">

                        <div class="col-xs-12 facility-tbl">

                            <img [src]="calendarIcon" alt="calendarIcon" />

                            <p>7 Days a Week</p>

                        </div>

                        <div class="col-xs-12 facility-tbl">

                            <img [src]="clockIcon" alt="clockIcon" />

                            <p>24 Hours a Day</p>

    

                        </div>

                    </div>

                    <div class="row" *ngIf="FgarageWorkingHours?.length > 0">

                        <div class="col-xs-6 facility-tbl">

                            <img [src]="calendarIcon" alt="calendarIcon" />

                        </div>

                        <div class="col-xs-6 facility-tbl">

                            <img [src]="clockIcon" alt="clockIcon" />

                        </div>

    

                        <div *ngFor="let wh of FgarageWorkingHours;">

                            <div class="col-xs-6 facility-tbl">

                                <p>{{wh.day}} </p>

                            </div>

                            <div class="col-xs-6 facility-tbl">

                                <p> {{wh.formatted_open_time}} - {{wh.formatted_close_time}}</p>

                            </div>

                        </div>

    

    

                    </div>

                </div> -->

                <p class="visible-xs">&nbsp;</p>

            </div>
            
            <!-- <div class="white-box-sdw your-purchage-box mar-top-sm hidden-xs">


<div  class="flex-row parksafe-with-img hidden-xs">
    <div  class="col-xs-12 text-center">
            <img style="max-width: 170px;" src="/assets/img/logo-parksafe-1.png">
    </div>
    <div  class="col-xs-12">
        <h3 class="text-center mar-bottom-sm">Park with Peace of Mind.</h3>
        <ul  class="dotsUl">
            <li><h4 class="text-primary">Service</h4>
                <p>Fully contactless mobile reservations, payments, check-in & check-out</p>
            </li>
            <li>
                <h4 class=" text-primary"> Sanitize</h4>
                <p>Designated Viking Pure® natural cleaning stations at every location</p>
            </li>
            <li>
                <h4 class="text-primary">Safety</h4>
                <p> Trained team members with masks & all practicing social distancing </p>
            </li>
            </ul>
    </div>
</div>

                
            </div> -->
        <div class="mar-top-xs visible-xs book-box-mob">
            <div class="d-block mar-top-sm">
                <!-- <button [disabled]="disableSubmit" #PromocodePopup type="button" class="btn btn-primary btn-fill btn-block book-it hide" (click)="openModal(template)">BOOK IT </button> -->
                <button [disabled]="disableSubmit" type="submit"  style="min-width: 200px;" class="btn btn-primary btn-fill btn-block d-inline-block book-it">BOOK IT </button>
                <!-- <button *ngIf="isLogin"  [disabled]="disableSubmit" type="submit"  style="min-width: 200px;" class="btn btn-primary btn-fill btn-block d-inline-block book-it">BOOK IT </button> -->
            </div>
            <p class="mar-top-xs w-100 text-center"><a role="button" font-12 class="text-dark text-center" style="cursor: pointer;"><img width="15px" class="mar-right-xs" src="/assets/img/best_guaranteed_icon.png"> Our Best Deals Guaranteed </a></p>
            
        </div>
    
    </div>

        <ng-template #monthlyFacilityDate>

            <div class="modal-header">

                <h4 class="modal-title text-primary text-uppercase pull-left"></h4>

                <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">

                        <span aria-hidden="true" style="font-size: 50px; font-weight: 400;">&times;</span>

                      </button>

            </div>

            <div class="modal-body" style="padding: 10px 20px">

                <h3 class="text-uppercase mar-top-0 mar-bottom-xs"> {{respdata?.display_name}}</h3>

                <h3>Reservation Summary</h3>

        <p>Starting 04/07/2020</p>

        <div class="">

            <table width="100%" class="monthly-price-section">

                

                    <tr>

                        <td><b>Price</b></td>

                        <td  align="right"><b>{{CheckForSoldOut(checkFormat(rateDetailsNetRate))}}</b></td>

                    </tr>

                    <tr>

                        <td><b>Tax</b></td>

                        <td  align="right"><b>${{checkFormat(taxForThis)}}</b></td>

                    </tr>

            </table>

            <hr class="my-3"/>

            <table width="100%">

            <tr>

                <td class="font-weight-bolder pb-2" style="font-size: 18px;">Total </td>

                <td align="right"><h2 style="font-size: 18px;" class="my-0 font-weight-bolder pb-4">{{CheckForSoldOut(checkFormat(rateDetails) )}}
                    <br /><span class="taxIncludeText">(Taxes Included)</span>
                </h2></td>

            </tr>

          </table>

            <div class="d-block">
                <!-- <button [disabled]="disableSubmit || !(make_vehicle.valid && make_modal.valid && vehiclecolor.valid && phone.valid && address1.valid && city.valid && state.valid && zipcode.valid && name_on_card.valid && card_number.valid && expiration_date.valid && security_code.valid && pzipcode.valid && terms.valid )" #PromocodePopup type="button" class="btn btn-primary btn-fill book-it hide" (click)="openModal(template)">BOOK IT </button> -->
            </div>

            <p class="py-2 w-100 text-center"><a role="button" font-12 class="text-dark mt-4 text-center" style="cursor: pointer;"><img width="15px" class="mar-right-xs" src="/assets/img/best_guaranteed_icon.png"> Our Best Deals Guaranteed </a></p>
        </div>

        <div class=" mar-top-sm">

            <div class="">

                <!-- <p><img width="200" src="/assets/img/iconpark-safe.png" ></p>

                <ul class="dotsUl">
                    <li><h4 class="mar-bottom-0 text-primary">Service</h4>
                    <p>Fully contactless mobile reservations, payments, check-in & check-out</p>
                    </li>
                    <li>
                    <h4 class="mar-bottom-0 text-primary"> Sanitize</h4>
                    <p>Designated Viking Pure® natural cleaning stations at every location</p>
                    </li>
                    <li>
                    <h4 class="mar-bottom-0 text-primary">Safety</h4>
                    <p> Trained team members with masks & all practicing social distancing</p>
                    </li>
                    </ul> -->

            </div>

        </div>

            </div>

        </ng-template>

        </div>
    </div>





</div>

</form>

<!-- app footer -->

<app-footer></app-footer>

<ng-template #popTemplate>

    <img [src]="cardSecurityIcon" alt="helpIcon">



</ng-template>
