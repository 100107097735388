<div role="tabpanel" class="tab-pane fade active in" id="previousinvoice" *ngIf="isLoaded">
	<h4 class="text-primary mar-top-0">Previous Invoice</h4>
	<!-- content for previous invoice -->
	<div class="table-responsive">
	<table class="table table-hover" *ngIf="previousInvoices.length > 0">
		<thead>
			<tr>
				<th>Invoice Date</th>
				<th>Invoice Amount</th>
				<th align="left" style="width: 35%;"></th>
			</tr>
		</thead>
		<tbody>
			<tr *ngFor="let invoice of previousInvoices" sourceFont>
				<td> {{ invoice.invoice_date }} </td>
				<td>${{ invoice.invoice_total }}</td>
				<td align="left" style="width: 35%;">
						<!-- <a (click)="openPdf(invoice.invoice_date)" tooltip="Open PDF" class="btn  mar-right-xs pad-left-xs pad-right-xs btn-primary btn-fill btn-sm"><i class="fa smallFix fa-2x fa-eye"></i></a>
						<a (click)="downloadInvoice(invoice.invoice_date)" tooltip="Download Invoice" class="btn  mar-right-xs pad-left-xs pad-right-xs btn-primary btn-fill btn-sm"><i class="fa smallFix fa-2x fa-download"></i></a> -->
						
					<button type="submit" class="btn btn-primary theme-btn apiTrigger btn-sm" (click)="downloadInvoice(invoice.invoice_date)">
						Download invoice
					</button><span class="mar-left-xs"></span><button type="submit" class="btn btn-primary theme-btn btn-sm" (click)="openPdf(invoice.invoice_date)">
						Open Pdf
					</button>
				</td>
			</tr>
		</tbody>
	</table>
	</div>
	<div class="clearfix">
		<!-- <div class="row"> -->
			<div class="col-xs-12 col-sm-6 col-md-6 col-lg-6" *ngIf="previousInvoices.length > 0">
				<p>Showing Results for Page {{ currentPage }} of {{ lastPage }}</p>
			</div>
			<div class="col-xs-12 col-sm-6 col-md-6 col-lg-6 text-right" *ngIf="previousInvoices.length > 0">
				<ul class="pagination  pagination-lg">
					<li *ngIf="prevPage != null">
						<button type="submit" class="btn btn-primary theme-btn" (click)="loadPreviousInvoices(prevPage)">
							<fa name="angle-left"></fa>
						</button>
					</li>
					<li *ngIf="nextPage != null">
						<button type="submit" class="btn btn-primary theme-btn" (click)="loadPreviousInvoices(nextPage)">
							<fa name="angle-right"></fa>
						</button>
					</li>
				</ul>
			</div>
		<!-- </div> -->
	</div>
	<div *ngIf="previousInvoices.length < 1">
		<h4 style="margin-top: 2em;margin-bottom: 2em;">Sorry, Couldn't Find Any Previous Invoice For This Account</h4>
	</div>
	<app-saver-load [imageUrl]="imageCode" [showPopup]="showDialog" (popEvent)="receiveShowDialog($event)"></app-saver-load>
</div>
<app-lazy-load [isLoaded]="isLoaded" [sectionName]="'previous invoices'"></app-lazy-load>