<div *ngIf="showneighbour" class="speceSedulePanel">


    <div class=" SchedulePanel hidden ">

        <div class="flex-row">
            <div class="col">

                <input type="text" class="form-control" placeholder="Plase select date range for facality" />

            </div>
            <div class="col-auto">
                <a href="javascript:;" class="btn btn-link pad-left-0 pad-right-0 text-primary"><i class="fa fa-pencil"></i></a>
            </div>
        </div>
        <div class="row hidden">
            <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 text-center">
                <b>Arrive</b><br>
                <label for="mobileNavStartTime">{{ displayableTime('start') }}</label>
                <input type="hidden" [(ngModel)]="startTime" id="mobileNavStartTime" mbsc-datetime [mbsc-options]="mobileNavStartTime" #mbscDatetime="mobiscroll" />
            </div>
            <div class="durationBox bg-primary text-center" (click)="arivalClick()">
                <b>Duration</b>
                <div>{{ lengthOfStay }} hrs</div>
            </div>
            <div class="col-xs-offset-4 col-xs-4 col-sm-4 col-md-4 col-lg-4 text-center">
                <b>Depart</b><br>
                <label for="mobileNavEndTime">{{ displayableTime('end') }}</label>
                <input type="hidden" [(ngModel)]="endTime" id="mobileNavEndTime" mbsc-datetime [mbsc-options]="mobileNavEndTime" #mbscDatetime="mobiscroll" />
            </div>
        </div>
    </div>
</div>
<div *ngIf="showTies">
    <app-loyality-badge (updateTierNeighbour)='updateTier($event)' [containerClass]="filterBadge" [showTies]="true"></app-loyality-badge>
</div>
<div  *ngIf="is_home_page" class="infoBlock mobile" style="padding: 0px;margin-bottom: 2px;width: 100%;background-color:#ec7d19!important">
		<div class="container " style="margin: 0;width: 100%;margin: 0 auto; ">
	
			<table class="content" cellpadding="0" style="vertical-align:middle" width="100%" cellspacing="0">
				<tr>
					<td style="font-size: 16px; padding: 0 10px;"><span style="color:white;" ><a style="color:white" class="link_text_corona" href="https://iconparkingsystems.com/cms/daily-updates/">Icon Parking is committed to keeping our customers, team members and community updated on all developments on the evolving situation with Covid-19. Please <a class="click_here" style="color:white">click here</a> for the latest updates.</a></span></td>
				</tr>
			</table>
	
			<div class="col-xs-1 text-right pad-right-0">
			</div>
		</div>
	</div>
<div *ngIf="is_show_landing_strip && is_landing_page && show_strip_search" class="infoBlock mobile" style="width: 100%;">
    <table class="wrapMobile" width="100%">
        <tr>
            <td>
                <fa name="{{sign}}" size="2x"></fa>
            </td>

            <td *ngIf="!isLoggedIn"><span [innerHTML]="Couponmessage"></span>&nbsp; <a *ngIf="is_landing_page_save" (click)="launchLoginPopup()" data-toggle="modal" href='#login'><b>click here and save it for later</b> <i class="fa fa-angle-right"></i></a> </td>
            <td *ngIf="isLoggedIn"><span [innerHTML]="Couponmessage"></span>&nbsp; <a *ngIf="is_landing_page_save" (click)="launchLoginPopup()"><b>click here and save it for later.</b> <i class="fa fa-angle-right"></i></a> </td>

            <td align="right"><button (click)="hideStripe();" type="button" class="btn btn-link btn-xs"><i class="fa fa-times text-white"></i></button></td>
        </tr>
    </table>
</div>

<div *ngIf="inactiveFacility" class="infoBlock mobile">
    <table class="wrapMobile">
        <tr>
            <td>Sorry! The facility you are searching for is no longer active.</td>
            <td><button type="button" (click)="hidestripfacility();" class="btn btn-link btn-xs"><i class="fa fa-times text-white"></i></button></td>
        </tr>
    </table>
</div>