<app-header>
	</app-header>
	<div class="monthlyParking mar-top-md mar-bottom-lg" [ngClass]="{'pad-top-lg':!isFrame }">
		<div class="container  commonPage orangeBar">
	
			<ol class="breadcrumb pad-top-0 pad-bottom-0 pad-left-0" >
				<li class="active">
					<a href="javascript:;" onclick="history.go(-1);" class="btn btn-link pad-left-0">
						<fa name="angle-left"></fa> &nbsp; Back</a>
				</li>
			</ol>
			<div class="row">
				<div class="col-sm-4 col-xs-12 pull-right">
					<img [src]="facilityImage" alt="facilityImage" class="img-responsive full-width mar-bottom-xs" onerror="delete img.onerror;" height="200px">
					<p class="visible-xs">&nbsp;</p>
	
					<!---- mobile monthly popup --->
	
					<template #monthlyFacilityDate>
								<div class="modal-header">
								  <h4 class="modal-title text-primary text-uppercase pull-left"></h4>
								  <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
									<span aria-hidden="true" style="font-size: 50px; font-weight: 400;">&times;</span>
								  </button>
								</div>
								<div class="modal-body" style="padding: 10px 20px">
								<h3 class="text-primary text-uppercase mar-top-0 mar-bottom-xs"> {{respdata?.display_name}}</h3>
								<h4 class="modal-title text-primary text-uppercase pad-top-xs">facility details</h4>
								<p *ngIf="respdata?.between_streets"><b>Between:</b> {{respdata?.between_streets}} </p> 
								<p *ngIf="respdata?.geolocations.address_1"><b>Building Address:</b> {{respdata?.geolocations.address_1}}</p> 
								<div class="row mar-top-xs" *ngIf="FgarageWorkingHours?.length <= 0"> 
									<div class="col-xs-12 facility-tbl mar-bottom-xs"> 
										<img [src]="calendarIcon" alt="calendarIcon"/> 
										<span class="pad-left-xs">7 Days a Week</span> 
									</div> 
									<div class="col-xs-12 facility-tbl mar-bottom-xs mar-bottom-xs"> 
										<img [src]="clockIcon" alt="clockIcon" /> 
										<span class="pad-left-xs">24 Hours a Day</span> 
									</div> 
								</div>
									<div *ngIf="FgarageWorkingHours?.length > 0">
											  <div class="row mar-bottom-xs mar-top-xs">
												<div class="col-xs-6 facility-tbl"> 
													<img [src]="calendarIcon" alt="calendarIcon" /> 
												</div> 
												<div class="col-xs-6 facility-tbl"> 
													<img [src]="clockIcon" alt="clockIcon" /> 
												</div>
											  </div>
															
											 <div *ngFor="let wh of FgarageWorkingHours;">
											  <div class="row mar-bottom-xs">	
													<div class="col-xs-6 facility-tbl"> 
														<span>{{wh.day}} </span> 
													</div> 
													<div class="col-xs-6 facility-tbl">
														<span> {{wh.formatted_open_time}} - {{wh.formatted_close_time}}</span> 
													</div> 
											</div>	
											</div>
									
	
									</div> 
								 
								</div>
							  </template>
					<!--Monthly facility details-->
					<div class="well mar-bottom-0 monthly-fac-details hidden-xs">
						<h4 class="text-primary text-uppercase">facility details</h4>
						<p *ngIf="respdata?.between_streets"><b>Between:</b> {{respdata?.between_streets}} </p>
						<p *ngIf="respdata?.geolocations.address_1"><b>Building Address:</b> {{respdata?.geolocations.address_1}}</p>
						<div class="row" *ngIf="FgarageWorkingHours?.length <= 0">
							<div class="col-xs-12 facility-tbl">
								<img [src]="calendarIcon" alt="calendarIcon" />
								<p>7 Days a Week</p>
							</div>
							<div class="col-xs-12 facility-tbl">
								<img [src]="clockIcon" alt="clockIcon" />
								<p>24 Hours a Day</p>
	
							</div>
						</div>
						<div class="row" *ngIf="FgarageWorkingHours?.length > 0">
							<div class="col-xs-6 facility-tbl">
								<img [src]="calendarIcon" alt="calendarIcon" />
							</div>
							<div class="col-xs-6 facility-tbl">
								<img [src]="clockIcon" alt="clockIcon" />
							</div>
	
							<div *ngFor="let wh of FgarageWorkingHours;">
								<div class="col-xs-6 facility-tbl">
									<p>{{wh.day}} </p>
								</div>
								<div class="col-xs-6 facility-tbl">
									<p> {{wh.formatted_open_time}} - {{wh.formatted_close_time}}</p>
								</div>
							</div>
	
	
						</div>
					</div>
					<p class="visible-xs">&nbsp;</p>
				</div>
				<div class="col-sm-8">
					<h1 class="text-primary text-uppercase mar-top-0 mar-bottom-sm"> {{respdata?.display_name}} <span class="pull-right visible-xs pad-left-sm"><a href="javascript:;"><img (click)="openModal(monthlyFacilityDate)" [src]="infoIcon" alt="infoIcon" /></a></span></h1>
	
					<p class="mar-bottom-sm" *ngIf="respdata?.phone_number.length > 0">
						<a class="text-uppercase" (click)="callMonthlyCustomer()" [href]="phoneNumber" style="color: #000;"><b style="font-size: 13px;">Questions
							<span class="quest-icon"><fa name="question"></fa></span>
								   Call Us</b> &nbsp;<fa name="phone"></fa>&nbsp; 888-705-7845</a>
					</p>
	
					<table cellspacing="20" class="mar-bottm-sm">
						<tr>
							<td>
								DESIRED PARKING START DATE
							</td>
							<td width="5%">&nbsp;</td>
							<td>
								<fa name="calendar-o" class="text-primary"></fa>
							</td>
							<td class="posi-pencil">
								<input id="monthlydate" (ngModelChange)="checkdate();" [minDate]="minDate" [(ngModel)]='newMonthlyDate' type="text" class="datestyle" #dp="bsDatepicker" bsDatepicker [(bsValue)]="newMonthlyDate" readonly />
								<fa class="text-primary pe" name="pencil"></fa>
							</td>
	
							<td align="left">
								<!-- <a (click)="dp.show()" class="btn btn-link" > <fa name="pencil"></fa> -->
							</td>
						</tr>
					</table>
					<!-- <p>
						PRICE&nbsp;&nbsp;${{rateDetails|number}}
					</p> -->
	
	
					<!--Rate Details-->
					<hr/>
					<div class="rate-details">
						<h3 class="text-primary mar-top-xs text-uppercase">Rate details <img (click)="openModal(monthlyRateDesc)" [src]="helpIcon" style="cursor: pointer;" alt="helpIcon" /></h3>
						<template #monthlyRateDesc>
								<div class="modal-header" style="margin-top: -10px;">
								  <h4 class="modal-title pull-left text-primary text-uppercase"></h4>
								  <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
									<span aria-hidden="true" style="font-size: 50px; font-weight: 400;">&times;</span>
								  </button>
								</div>
								<div class="modal-body" style="padding: 10px 20px">
								<h3 class="text-uppercase text-primary mar-top-0 mar-bottom-xs">MONTHLY RATES</h3>
								 <p>Our rates vary by vehicle type and what service you require.</p>
								 <h5 class="text-uppercase">ACCESS HOURS</h5>
								 <div *ngIf="FgarageWorkingHours?.length <= 0">
									<p>
										This facility is open 24 hours a day, 7 days a week.
									</p>
								</div>
								<div *ngIf="FgarageWorkingHours?.length > 0">
									<p>
										This facility is open
										<span class="pHours" *ngFor="let wh of FgarageWorkingHours;">
										{{wh.day}}: {{wh.formatted_open_time}} - {{wh.formatted_close_time}}<span class="comma">,</span>
										</span>
									</p>
								</div>
								<h5 class="text-uppercase">VEHICLE CLASSIFICATIONS</h5>
								 <p>
									Oversized vehicles include SUV’s, Trucks, and similar. You can
									find more info and a list of vehicles <a href="javascript:;" (click)="redirecttoList()">here</a>.
								</p>
								<p>
									Exotic vehicles are considered luxury/high-performance/
									sports cars.
								</p>
								<p>
									For assistance with your vehicle, contact us at (888) 705-7845
									from 9 AM to 7 PM EST
								 </p>
								 <div class="btn-center-xs mar-top-sm mar-bottom-xs">
								 <a class="btn btn-primary theme-btn" href="javascript:;" (click)="modalRef.hide()">Got it</a>
								 </div>
								 <div class="mar-bottom-sm visible-xs"></div>
								</div>
							  </template>
	
						<table *ngIf="monthlySpecialPrice" sourceFont>
							<tr>
								<td>
									<b>${{monthlySpecialPrice}}</b>
								</td>
								<td>
									<!-- <p>monthlyrats.rate_description.name</p> -->
								</td>
							</tr>
						</table>
	
						<p class="mar-bottom-sm pad-top-xs">If you’re unsure of what your car is classified as, you can contact our monthly sales team at <b>(888) 705-7845</b> from 9 AM to 7 PM EST for assistance.</p>
					</div>
	
					<hr/>
					<input type="hidden" value="{{rateDetails}}" id="mrate" />
					<h3 class="text-primary mar-top-0">ACCOUNT INFORMATION</h3>
					<a class="btn btn-primary theme-btn" id="secondloginbtn" type="button" data-toggle="modal" href='#login'>Log in</a>&nbsp;
					<button class="btn btn-default btnExpand " type="button" (click)="isCollapsed = !isCollapsed">ENTER YOUR INFO BELOW  &nbsp;<fa name="angle-up"></fa></button>
					<div class="mar-top-sm" id="accountExpand" [collapse]="isCollapsed">
						<form name="buyMonthlyForm" (ngSubmit)="f.form.valid && monthlyPayment(formData, f,1)" #f="ngForm" novalidate method="POST" role="form" id="floating-label" class="floating-label">
							<div class="row">
								<div class="col-sm-6">
									<div class="form-group mar-top-0" [ngClass]="{ 'has-error' : f.submitted && !firstname.valid}">
										<label class="control-label" for="firstname">First Name</label>
										<input class="form-control" type="text" name="firstname" [(ngModel)]="formData.firstname" #firstname="ngModel" required />
										<div *ngIf="f.submitted && !firstname.valid" class="help-block">First name is required</div>
									</div>
								</div>
								<div class="col-sm-6">
									<div class="form-group mar-top-0" [ngClass]="{ 'has-error' : f.submitted && !lastname.valid}">
										<label class="control-label" for="lastname">Last Name</label>
										<input class="form-control" type="text" name="lastname" [(ngModel)]="formData.lastname" #lastname="ngModel" required />
										<div *ngIf="f.submitted && !lastname.valid" class="help-block">Last name is required</div>
									</div>
								</div>
								<div class="col-sm-6">
									<div class="form-group mar-top-0" [ngClass]="{ 'has-error' : f.submitted && !email.valid}">
										<label class="control-label" for="email">Email</label>
										<input class="form-control" type="text" name="email" [(ngModel)]="formData.email" #email="ngModel" required (keydown)="restSpace($event)" pattern="{{emailRegex}}" />
										<div *ngIf="f.submitted && !email.valid" class="help-block">Email is required</div>
									</div>
								</div>
								<div class="col-sm-6">
									<div class="form-group mar-top-0" [ngClass]="{ 'has-error' : f.submitted && !phone.valid}">
										<label class="control-label" for="phone">Phone Number</label>
										<input class="form-control" type="text" name="phone" minlength="10" [textMask]="{mask: phoneMask}" (keypress)="phoneFormat($event)" [(ngModel)]="formData.phone" #phone="ngModel" required />
										<div *ngIf="f.submitted && !phone.valid" class="help-block">Phone Number is required</div>
									</div>
								</div>
								<div class="col-sm-6">
									<div class="form-group mar-top-0">
										<label class="control-label" for="companyname">Company Name (If applicable)</label>
										<input class="form-control" type="text" name="companyname" [(ngModel)]="formData.companyname" #companyname="ngModel" />
									</div>
								</div>
							</div>
							<div class="row">
								<div class="col-sm-6">
									<div class="form-group mar-top-0" [ngClass]="{ 'has-error' : f.submitted && !address1.valid}">
										<label class="control-label" for="address1">Address Line 1</label>
										<input class="form-control" type="text" name="address1" [(ngModel)]="formData.address1" #address1="ngModel" required />
										<div *ngIf="f.submitted && !address1.valid" class="help-block">Address Line 1 is required</div>
									</div>
								</div>
								<div class="col-sm-6">
									<div class="form-group mar-top-0">
										<label class="control-label" for="address2">Address Line 2 (optional)</label>
										<input class="form-control" type="text" name="address2" [(ngModel)]="formData.address2" #address2="ngModel" />
									</div>
								</div>
								<div class="col-sm-6">
									<div class="form-group mar-top-0" [ngClass]="{ 'has-error' : f.submitted && !city.valid}">
										<label class="control-label" for="city">City</label>
										<input class="form-control" type="text" name="city" [(ngModel)]="formData.city" #city="ngModel" required />
										<div *ngIf="f.submitted && !city.valid" class="help-block">City is required</div>
									</div>
								</div>
								<div class="col-sm-3">
									<div class="form-group mar-top-0" [ngClass]="{ 'has-error' : f.submitted && !state.valid}">
										<label class="control-label">State</label>
										<input class="form-control" id="state" type="text" name="state" (keydown)="restDigit($event)" minlength="2" maxlength="2" [(ngModel)]="formData.state" #state="ngModel" required>
										<div *ngIf="f.submitted && !state.valid" class="help-block">State is required</div>
									</div>
								</div>
								<div class="col-sm-3">
									<div class="form-group mar-top-0" [ngClass]="{ 'has-error' : f.submitted && !zipcode.valid}">
										<label class="control-label" for="zipcode">Zip Code</label>
										<input class="form-control" maxlength="6" minlength="5" type="text" name="zipcode" [(ngModel)]="formData.zipcode" #zipcode="ngModel" required />
										<div *ngIf="f.submitted && !zipcode.valid" class="help-block">Zip code is required</div>
									</div>
								</div>
							</div>
	
							<hr class="clearfix mr-bottom-sm mar-top-sm" />
							<h3 class="text-primary mar-top-0 mar-bottom-sm">VEHICLE INFORMATION</h3>
							<p class="mar-bottom-sm">
								Oversize vehicle surcharge: Vehicles over 181″ in Length and over 70″ in Height (or 75” high regardless of length) are subject to an additional fee. Please contact us for additional information for oversize vehicles
							</p>
							<div class="row">
								<div class="col-sm-6">
									<div class="form-group mar-top-0" [ngClass]="{ 'has-error' : f.submitted && !vehiclemake.valid}">
										<label class="control-label" for="vehiclemake">Vehicle Make</label>
										<input class="form-control" type="text" name="vehiclemake" [(ngModel)]="formData.vehiclemake" #vehiclemake="ngModel" required />
										<div *ngIf="f.submitted && !vehiclemake.valid" class="help-block">Vehicle Make is required</div>
									</div>
								</div>
	
								<div class="col-sm-6">
									<div class="form-group mar-top-0" [ngClass]="{ 'has-error' : f.submitted && !vehiclemodel.valid}">
										<label class="control-label" for="vehiclemodel">Vehicle Model</label>
										<input class="form-control" type="text" name="vehiclemodel" [(ngModel)]="formData.vehiclemodel" #vehiclemodel="ngModel" required />
										<div *ngIf="f.submitted && !vehiclemodel.valid" class="help-block">Vehicle Model is required</div>
									</div>
								</div>
								<div class="col-sm-6">
									<div class="form-group mar-top-0">
										<label class="control-label" for="licenceplate">License Plate (optional)</label>
										<input class="form-control" type="text" name="licenceplate" (keypress)="licenceplateFormat($event)" maxlength="15" [(ngModel)]="formData.licenceplate" #licenceplate="ngModel" />
									</div>
								</div>
	
								<div class="col-sm-6">
									<div class="form-group mar-top-0" [ngClass]="{ 'has-error' : f.submitted && !vehiclecolor.valid}">
										<label class="control-label" for="vehiclecolor">Vehicle Color</label>
										<input class="form-control" type="text" name="vehiclecolor" [(ngModel)]="formData.vehiclecolor" #vehiclecolor="ngModel" required />
										<div *ngIf="f.submitted && !vehiclecolor.valid" class="help-block">Vehicle Color is required</div>
									</div>
								</div>
							</div>
							<hr class="clearfix mar-top-sm mar-bottom-sm" />
							<h3 class="text-primary mar-top-0">ADDITIONAL INFORMATION</h3>
							<p>
								This step is optional
							</p>
							<div class="row">
								<div class="col-sm-6">
									<div class="form-group">
										<label class="control-label" for="driver1">Additional Driver 1 (optional)</label>
										<input class="form-control" type="text" name="driver1" [(ngModel)]="formData.driver1" #driver1="ngModel" />
									</div>
									<div class="form-group">
										<label class="control-label" for="driver2">Additional Driver 2 (optional)</label>
										<input class="form-control" type="text" name="driver2" [(ngModel)]="formData.driver2" #driver2="ngModel" />
									</div>
									<div class="form-group">
										<label class="control-label" for="driver3">Additional Driver 3 (optional)</label>
										<input class="form-control" type="text" name="driver3" [(ngModel)]="formData.driver3" #driver3="ngModel" />
									</div>
								</div>
							</div>
							<hr class="clearfix mar-top-sm mar-bottom-sm" />
							<h3 class="text-primary mar-top-0">PAYMENT</h3>
	
							<div class="row">
								<div class="col-sm-6">
									<div class="form-group mar-top-0" [ngClass]="{ 'has-error' : f.submitted && !name_on_card.valid}">
										<label class="control-label" for="card">Name on Card</label>
										<input class="form-control" type="text" name="name_on_card" [(ngModel)]="formData.name_on_card" #name_on_card="ngModel" required />
										<div *ngIf="f.submitted && !name_on_card.valid" class="help-block">Name on Card is required</div>
									</div>
								</div>
								<div class="col-sm-6">
									<div class="form-group mar-top-0" [ngClass]="{ 'has-error' : f.submitted && !card.valid}">
										<label class="control-label" for="card">Card Number</label>
										<input [textMask]="{mask: cardMask}" autocomplete="off" (keypress)="restrictNumeric($event)" class="form-control" type="text" name="card" [(ngModel)]="formData.card" #card="ngModel" required />
										<div *ngIf="f.submitted && !card.valid" class="help-block">Card Number is required</div>
									</div>
								</div>
								<div class="col-sm-3">
									<div class="form-group mar-top-0 focused" [ngClass]="{ 'has-error' : f.submitted && !expiration_date.valid}">
										<label class="control-label" for="expiration_date">Expiration Date</label>
										<select class="form-control" name="expiration_date" [(ngModel)]="formData.expiration_date" #expiration_date="ngModel" required>
										<option *ngFor="let month of months" value={{month.value}} ng-selected="month.value==01">
											{{month.label}}
										</option>
										</select>
	
										<div *ngIf="f.submitted && !expiration_date.valid" class="help-block">Expiration Date is required</div>
									</div>
								</div>
								<div class="col-sm-3">
									<div class="form-group mar-top-0" [ngClass]="{ 'has-error' : f.submitted && !expiration_year.valid}">
										<label class="control-label" for="expiration_year"></label>
										<select class="form-control" name="expiration_year" [(ngModel)]="formData.expiration_year" #expiration_year="ngModel" required>
										<option *ngFor="let year of years" value={{year.value}}>
											{{year.label}}
										</option>
										</select>
										<div *ngIf="f.submitted && !expiration_year.valid" class="help-block">Expiration Year is required</div>
									</div>
								</div>
							</div>
							<div class="row">
								<div class="col-sm-4">
									<div class="form-group mar-top-0" [ngClass]="{ 'has-error' : f.submitted && !security_code.valid}">
										<label class="control-label" for="security_code">Security Code</label>
										<div class="input-group">
											<input maxlength="4" class="form-control" type="text" name="security_code" [(ngModel)]="formData.security_code" #security_code="ngModel" required />
											<div class="input-group-addon pad-left-xs pad-right-xs bgTransparent" container="body" containerClass="PaymentCustome" [tooltip]="popTemplate"><img [src]="infoIcon" alt="image"></div>
										</div>
										<div *ngIf="f.submitted && !security_code.valid" class="help-block">Security Code is required</div>
									</div>
								</div>
								<div class="col-sm-4">
									<div class="form-group mar-top-0" [ngClass]="{ 'has-error' : f.submitted && !cardzipcode.valid}">
										<label class="control-label" for="cardzipcode">Zip Code</label>
										<input class="form-control" maxlength="6" minlength="5" type="text" name="cardzipcode" [(ngModel)]="formData.cardzipcode" #cardzipcode="ngModel" required />
										<div *ngIf="f.submitted && !cardzipcode.valid" class="help-block">Zip code is required</div>
									</div>
								</div>
							</div>
							<div class="row">
								<div class="col-sm-4">
									<div class="form-group mar-top-0">
										<label class="control-label" for="promocode">Promo Code (optional)</label>
										<div class="input-group">
											<input class="form-control" type="text" name="promocode" [(ngModel)]="formData.promocode" #promocode="ngModel" />
										</div>
									</div>
								</div>
							</div>
							<div class="row mar-top-sm mar-bottm-sm" sourceFont>
								<div class="col-md-6">
									<div class="clearfix">
										<p class="currentCarD currentCarbtn">
											<span>Price</span>
											<span class="fa-2x"> ${{monthlySpecialPrice}}</span>
	
										</p>
	
									</div>
								</div>
								<div class="clearfix"></div>
								<div class="col-md-6">
									<div class="clearfix">
										<p class="currentCarD currentCarbtn">
											<span>Tax</span>
											<span class="fa-2x"> ${{monthlySpecialTaxPrice}}</span>
										</p>
									</div>
								</div>
								<div class="clearfix"></div>
								<div class="col-md-6">
									<div class="clearfix">
										<p class="currentCarD currentCarbtn">
											<span>Total</span>
											<span class="fa-2x"> ${{monthlySpecialNetPrice}}</span>
											<button #PromocodePopup type="button" class="btn btn-primary btn-fill pull-right hide" (click)="openModal(template)">SUBMIT</button>
											<button type="submit" class="btn btn-primary btn-fill pull-right">SUBMIT</button>
										</p>
										Rates quoted are for new customers of Icon Parking and Quik Park
									</div>
								</div>
	
							</div>
	
	
	
							<ng-template #template>
								<div class="modal-header" style="margin-top: -10px;">
									<h4 class="modal-title pull-left text-primary text-uppercase"></h4>
									<button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
				<span aria-hidden="true" style="font-size: 50px; font-weight: 400;">&times;</span>
			</button>
								</div>
								<div class="modal-body" style="padding: 10px 20px">
									<h3 class="text-uppercase text-primary mar-top-0 mar-bottom-xs">promo code discount will be applied to the final credit card charge</h3>
									<div class="btn-center-xs mar-top-sm mar-bottom-xs">
										<a class="btn btn-primary theme-btn" href="javascript:;" (click)="UpdatePromocodeStatus(formData, f)">Got it</a>
										<a class="btn btn-primary theme-btn" href="javascript:;" (click)="modalRef.hide()">Cancel</a>
									</div>
									<div class="mar-bottom-sm visible-xs"></div>
								</div>
							</ng-template>
	
	
						</form>
					</div>
				</div>
			</div>
		</div>
	
	</div>
	<!-- app footer -->
	<app-footer></app-footer>
	<ng-template #popTemplate>
	
		<img [src]="cardSecurityIcon" alt="helpIcon">
	
	
	</ng-template>