import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { NgForm } from '@angular/forms';

import { UserserviceService } from '../../../services/userservice.service';
import { CustomCode } from '../../../shared/CustomCode';
import { HomepageserviceService } from '../../../services/homepageservice.service';
import { MyAccountMonthlyParkingService } from '../../../services/my-account-monthly-parking.service';
import { ToastrService } from 'ngx-toastr';
import * as $ from 'jquery';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss'],
  providers: [UserserviceService, HomepageserviceService, MyAccountMonthlyParkingService]
})
export class LandingPageComponent implements OnInit {

  	constructor(
      private router: Router,
  		private activatedRouteObj: ActivatedRoute,
  		private userserviceServiceObj: UserserviceService,
      private homepageserviceServiceObj: HomepageserviceService,
      private myAccountMonthlyParkingObj: MyAccountMonthlyParkingService,
    	private toastr: ToastrService
  	) { }

  	templateSlug: string;
  	promotionId: string;
  	promoCode: string;
  	actionType: string;
    campaignTemplate:any;
    isLoaded = false;
    responseData:any;
    isLoggedIn:boolean = false;
    userDetails:any = {};
    landingPage:string;
    routeUrl:any;
    promocodeDetail:any = {};

    availableCampaignPages:any = [
      'promocode-landing',
      'holiday-landing',
      'park-landing',
      'email-landing',
      'monthly-landing',
      'winter-monthly-landing',
      'icon-coupon-holiday',
      'coupon-nye',
      'winter-landing',
      'errorlanding',
      'gameday-hockey',
      'snow-storm',
      'valentine-day',
      'march-madness',
      'spring',
      'labour-day',
      'labourday',
      'fall-coupon',
      'black-friday-coupon',
      'cyber-monday-coupon',
      'happy-new-year'
    ];

    defaultNeighborhood:string = "times-square";
    landingLocations:any = {};

  	ngOnInit() {

      //alert(111)
       window.scrollTo(0,0);
      $("body").animate({ scrollTop: 0 }, 500);
    
      localStorage.removeItem('save');
      this.initializeLocationData();
  		this.activatedRouteObj.params.subscribe((params: Params) => {
      		if (params['template_slug'] && params['promotion_id'] && params['promo_code'] && params['action_type']) {
				let routeFullurl=this.router.url.split('/')
                this.routeUrl = routeFullurl[1];
				//this.routeUrl = this.router.url
            this.templateSlug = params['template_slug'];
            if(this.templateSlug=='icon-special-offer')
            {
              this.templateSlug='fall-coupon';
            }
            ///this.templateSlug = 'labour-day';
            this.landingPage = this.templateSlug
        		this.promotionId = params['promotion_id'];
                this.routeUrl = routeFullurl[1];
        		this.promoCode = params['promo_code'];
        		this.actionType  = params['action_type']
            this.saveDetailsInLocalStorage();
            if (localStorage.getItem('access_token')) {
                this.isLoggedIn = true;
                this.fetchUserDetails();
            } else {
              this.checkLandingPageAction();
            }
      		}
    	});

      if ($.inArray(this.landingPage, this.availableCampaignPages) < 0) {
        this.throw404Page();
      }
  	}

    initializeLocationData() {
      this.landingLocations['default'] = {
        'lat': CustomCode.timeSquareCoords.lat,
        'long': CustomCode.timeSquareCoords.long,
        'label': CustomCode.timeSquareCoords.defaultLocation,
        'type' : 'location'
      };

      this.landingLocations['all-nbh'] = {
        'slug': "central-park",
        'type' : 'neighborhood'
      };

      this.landingLocations['bryant-park'] = {
        'lat': 40.753597,
        'long': -73.983233,
        'label': "Bryant Park Winter Village, NY, United States",
        'type' : 'location'
      };
      this.landingLocations['rockefeller'] = {
        'lat': 40.758701,
        'long': -73.978595,
        'label': "Rockefeller Center Christmas Tree, NY, United States",
        'type' : 'location'
      };
      this.landingLocations['5th-ave'] = {
        'lat': 40.758074,
        'long': -73.977059,
        'label': "5th Ave Window Displays, NY, United States",
        'type' : 'location'
      };
      this.landingLocations['union-square'] = {
        'lat': 40.735863,
        'long': -73.991084,
        'label': "Union Square, NY, United States",
        'type' : 'location'
      };

      this.landingLocations['times-square'] = {
        'lat': 40.7589,
        'long': -73.9851,
        'label': "Times Square, New York, NY, United States",
        'type' : 'location'
      };

      this.landingLocations['madison-square'] = {
        'lat': 40.7505085,
        'long': -73.9956274,
        'label': "Madison Square Garden Towers, New York, NY, United States",
        'type' : 'location'
      };

      this.landingLocations['timessquare'] = {
        // 'lat': 40.7589,
        // 'long': -73.9851,
        // 'label': "Times Square, New York, NY, United States",
        'type' : 'neighborhood',
        'slug' : 'times-square'
      };

      this.landingLocations['midtown-west'] = {
        // 'lat': 40.7637581,
        // 'long': -73.99181809999999,
        // 'label': "Midtown West, New York, NY, United States",
        'type' : 'neighborhood',
        'slug' : 'midtown-west',
      };

      this.landingLocations['upper-west-side'] = {
        // 'lat': 40.7870106,
        // 'long': -73.97536760000003,
        // 'label': "Upper West Side, New York, NY, United States",
        'type' : 'neighborhood',
        'slug' : 'upper-west-side'
      };

      this.landingLocations['midtown-east'] = {
        // 'lat': 40.75403689999999,
        // 'long': -73.9668408,
        // 'label': "Midtown East, New York, NY, United States",
        'type' : 'neighborhood',
        'slug' : 'midtown-east'
      };
    }

    throw404Page() {
      this.router.navigate(['/404']);
    }

    fetchUserDetails() {
      this.myAccountMonthlyParkingObj.fetchUser()
      .then(
        (data:any)=> {
          this.userDetails = data['data'];
          this.checkLandingPageAction();
        },
        error => {
          this.toastr.error(error.errors.message, 'Sorry!');
        }
      );
    }

    saveDetailsInLocalStorage() {
	  localStorage.setItem('routeUrl', this.routeUrl);	
      localStorage.setItem('is_landing_page', 'true');
	  localStorage.setItem('routeUrl', this.routeUrl);
      localStorage.setItem('template_slug', this.templateSlug);
      localStorage.setItem('promotion_id', this.promotionId);
      localStorage.setItem('promocode', this.promoCode);
      localStorage.setItem('action_type', this.actionType);
      localStorage.setItem('reservation_apply', 'true');
    }

    checkLandingPageAction() {
      if (this.actionType === 'save') {
        // this.activateCouponSaveFlow();
      } else if (this.actionType === 'apply') {
        this.activateCouponApplyFlow();
      } else {
        this.activateDefaultFlow();
      }
    }

    activateCouponSaveFlow() {
      this.validatePromoCode('save', this.defaultNeighborhood);
    }

    activateCouponApplyFlow() {
        this.validatePromoCode('apply', this.defaultNeighborhood);
    }

    validatePromoCode(mode, neighborhood) {
      localStorage.setItem('neighborhood', neighborhood);
      let data = {};
      if (this.isLoggedIn) {
        data = {
          'promocode': this.promoCode,
          'user_id' : this.userDetails.id
        };
      } else {
        data = {
          'promocode': this.promoCode,
          'is_landing_page':1
        };
      }
      this.userserviceServiceObj.validatePromoCode(data)
      .then(
        (data:any)=> {
          if (data['data'].is_promocode_valid) {
              if (mode == "save") {
                  this.savePromocode(neighborhood);
              } else {
                  this.notifyReservationPromoCodeApply();
              }
          } else {
			localStorage.setItem('couponMessage','Promocode is not valid')
			localStorage.setItem('errorType','1')
            this.toastr.error('Promocode is not valid', 'Sorry!');
          }
		  if(mode=='apply')
		  {
			 window.location.href='/search'
		  }
		  
        },
        error => {
		localStorage.setItem('couponMessage',error.errors.message)
		localStorage.setItem('errorType','0')
		  if(error.errors.message == "This Promocode is only for guest user")
		  {
			  localStorage.setItem('errorType','4');
		  }else if (error.errors.message == "Please log in or create an account to redeem this promo code.") {
			localStorage.setItem('errorType','1')
            this.notifyAccountRequirement();
            this.launchLoginPopup();
          } else {
            this.toastr.error(error.errors.message, 'Sorry!');
          }
		  if(mode=='apply')
		  {
			 window.location.href='/search'
		  }
        }
      );
    }

    navidateToSearch(location) {
      if (this.landingLocations[location]) {
        if (this.landingLocations[location].type == "location") {
          this.navigateToLocation(this.landingLocations[location]);
        } else {
          this.navigateToNeighborhood(this.landingLocations[location]);
        }
      } else {
        this.navigateToLocation(this.landingLocations['default']);
      }
    }

    navigateToLocation(details) {
      this.router.navigate([
          '/search', 
          details.lat, 
          details.long, 
          CustomCode.getDateForMapSearch(), 
          CustomCode.facilityDefaultRadius, 
          details.label, 
          CustomCode.defaultFacilityType, 
          CustomCode.searchMode()
      ]);
    }

    navigateToNeighborhood(details) {
      this.router.navigate(['/neighborhoods/', details.slug]);
    }

    savePromocode(neighborhood) {
      if (!this.isLoggedIn) {
        this.notifyAccountRequirement();
        this.launchLoginPopup();
      } else {
        const data = {'promocode' : this.promoCode, 'user_id' : this.userDetails.id};
        this.userserviceServiceObj.addPromoCode(data)
        .then(
          (data:any)=> {
            if (!data['errors']) {
                this.toastr.success('Promocode successfully added, Please make a reservation to redeem this offer.', 'Great!');
                this.navidateToSearch(neighborhood);
            } else {
              this.toastr.error('Something wrong happened, try again later', 'Sorry!');
            }
          },
          error => {
            // this.toastr.error(error.errors.message, 'Sorry!');
            this.navidateToSearch(neighborhood);
          }
        );
      }
    }

    loadLandingPageContent() {
      this.homepageserviceServiceObj.fetchCampaignTemplate(this.templateSlug)
      .then(
        (data:any)=> {
          this.isLoaded = true;
          this.campaignTemplate = data['data'];
        },
        error => {
          this.isLoaded = true;
          this.toastr.error(error.errors.message, 'Sorry!');
        }
      );
    }

  	debugIncomingData() {
  		
  	} 	

  	activateDefaultFlow() {
  		this.activateCouponApplyFlow();
  	}

  	launchLoginPopup() {
      localStorage.setItem('save', 'true');
      $("a#loginPopup")[0].click();
  	}

  	notifyReservationPromoCodeApply() {
		localStorage.setItem('errorType','2')
		localStorage.setItem('couponMessage','Coupon applied successfully, Please make a reservation to avail this offer')
  		this.toastr.success('Coupon applied successfully, Please make a reservation to avail this offer', 'Congratulation!');
  	}

    notifyAccountRequirement() {
      this.toastr.info("Please login to your existing account or create a new one in order to save and redeem this promotion.", '');
    }

    sendApp() {
    const appText = parseInt((<HTMLInputElement>document.getElementById('sendapp')).value.trim());
    if (appText) {
      const phoneNum = ((<HTMLInputElement>document.getElementById('sendapp'))).value;
      const text = 'Welcome to icon parking. click on the link below https://www.google.com';
      this.homepageserviceServiceObj.messageToMobile(phoneNum, text)
          .subscribe(
          (data:any)=> {
            this.responseData = data;
            this.toastr.success('App link sent successfully', 'success!');
          },
          error => {
            if (error.errors.message !== 'Validation Errors') {
              if (error.status === 500) {
                this.toastr.error('Something wrong happened, Please try again later', 'Sorry!');
              } else {
                this.toastr.error(error.errors.message, 'Sorry!');
              }
            } else {
              for (const key in error.errors.detail) {
                if (error.errors.detail.hasOwnProperty(key)) {
                  if (typeof error.errors.detail === 'undefined') {
                    this.toastr.error(error.errors.message, 'Sorry!');
                  } else {
                    this.toastr.error(error.errors.detail[key], 'Sorry!');
                  }
                }
              }
            }
          }
          );
    } else {
      this.toastr.error('Number is required', 'Sorry!');
    }
    (<HTMLInputElement>document.getElementById('sendapp')).value = '';
  }

}
