import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
declare var google: any;
import * as $ from 'jquery';
import { CustomCode } from '../../shared/CustomCode';
import { UserserviceService } from '../../services/userservice.service';
import { MyAccountMonthlyParkingService } from '../../services/my-account-monthly-parking.service';
import { ReservationService } from '../../services/reservation.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { LogsService } from '../../services/logs.service';
import { FilterHelper } from '../../classes/filter-helper';
import { SearchpageserviceService } from '../../services/searchpageservice.service';
import { DatePipe } from '@angular/common';
// declare var ga: Function;
import * as FileSaver from 'file-saver';
//declare var kochava:any;
@Component({
  	selector: 'app-reservations',
  	templateUrl: './reservations.component.html',
	styleUrls: ['./reservations.component.scss'],
	providers: [UserserviceService, MyAccountMonthlyParkingService, ReservationService, LogsService, DatePipe]
})

export class ReservationsComponent implements OnInit {

	phoneNumber = 'tel:(877) 727-5464';
	mailId = 'mailto:customerservice@iconparking.com';
	garageIcon = '/assets/img/park-garage.png';
	garage_mapIcon = '/assets/img/garage-map.png';
	visaIcon = 'assets/img/visa.svg';
	park_garage_2 = '/assets/img/park-garage-2.jpg';

	visa_Icon = 'assets/img/visa.svg';
 	 mastercardIcon =  'assets/img/mastercard.svg';
  	discovercardIcon = 'assets/img/discovercard.svg';
  	amexIcon = 'assets/img/amex.svg';
	card_grey_Icon = 'assets/img/card-grey.svg';
	jcbIcon = 'assets/img/jcbcard.svg';
	dinersIcon = 'assets/img/dinersclub-icon.svg';
	troffyIcon = 'assets/img/loyalty/piggybank.svg';
	giftIcon = 'assets/img/icon-rewards-gift.svg';
	priceIcon = 'assets/img/price-tag.svg';
	dollarIcon = 'assets/img/loyalty/disocunt-credit.svg';
	activeUser;
	reservations;
	imageCode: any;
	isFrame = false;
	
	isshowData= true;
	isLoaded = false;
	isLoyaltyInfoLoaded = false;
	CanIsLoaded = false;
	showDialog = false;
	SmallDevice :any;
	facId;
	reservationDetails;
	reservationUserDetails;
	lazyLoadLabel = 'reservations';
	forLoggedIn = false;
	currentDateForReservation = FilterHelper.changeNyTime();
	reservation_id_for_cancel;
	searchCriteria: any = {
		'type': CustomCode.defaultFacilityType,
		'device': 'web',
		'lat': CustomCode.timeSquareCoords.lat,
		'long': CustomCode.timeSquareCoords.long,
		'currdate': CustomCode.getDateForMapSearchSlashFormat(),
		'radius': CustomCode.facilityDefaultRadius,
		'label': CustomCode.timeSquareCoords.defaultLocation,
		'length_of_stay': 2,
		'firstLoad' : false
	};
	isLoyaltyMember:boolean = false;
	loyaltyPointMissed:number = 0;
	rewarded_points:number = CustomCode.rewarded_points;
	totalReservatiomAmount:any=0
	is_discount:any=0;
	discountType:any='';
	isValidReservation:boolean = true;
	isUpdatedEndTimeFlag:boolean = false;
	tickID='';
	reservation_id:any=0
	garageWorkingHours:any=[];
	processFess=0.00;
  	constructor(
		private userserviceServiceObj: UserserviceService,
		private reservationObj: ReservationService,
		private myAccountMonthlyParkingObj: MyAccountMonthlyParkingService,
		private activatedRouteObj: ActivatedRoute,
		private toastr: ToastrService,
		private sanitizer: DomSanitizer,
		private router: Router,
		private logsService:LogsService,
		private searchPageObj: SearchpageserviceService,
	) {
		this.SmallDevice = false;
	}

	receiveShowDialog($event) {
    this.showDialog = $event;
    if(this.showDialog == false){
      $('body').removeClass('modal-open');
    }
  }

  	ngOnInit() {


		
		if(window.self!==window.top)
		{
		  this.isFrame = true;
		}else{
		  this.isFrame = false;
		}

  		if ($(window).width() <= 467) {
			this.SmallDevice = true;
		}
  		if (this.router.url.indexOf('my-account') > -1) {
    		this.forLoggedIn = true;
    		this.fetchUserDetails();
    	}
		this.activatedRouteObj.params.subscribe((params: Params) => {
			
			if (params['resId']) {
				
				this.reservation_id_for_cancel = params['resId'];
				this.facId = params['resId'];
				this.tickID =params['ticketId'];
		      	this.lazyLoadLabel = 'reservation #' + this.tickID;
	      		this.isLoaded = false;
	   		 	if (localStorage.getItem('access_token')) {
					this.fetchUserDetails();
				}
				  this.fetchReservation();
			}
			
			if(params['resCode'] && params['ticketechCode']){
				//alert(params['resCode']); alert(params['ticketechCode']);
				
				this.userserviceServiceObj.printReservationByEmail(params['resCode'],params['ticketechCode'])
				.then(
				  (data:any)=> {
					this.isValidReservation = true;
					const urlCreator = window.URL;
					this.isLoaded = true;
					const blobData = this.sanitizer.bypassSecurityTrustUrl(urlCreator.createObjectURL(data));
          this.imageCode = blobData['changingThisBreaksApplicationSecurity'];
          $('body').addClass('modal-open');
					this.showDialog = true;
					//this.printMedia(this.imageCode);
					//this.router.navigate(['/thanks-print']);
					//$( document ).ready(function() {$(".btn-dark").click();});
				  },
				  error => {
						this.isValidReservation = false;
						const urlCreator = window.URL;
					this.isLoaded = true;
					const blobData = this.sanitizer.bypassSecurityTrustUrl(urlCreator.createObjectURL(error['_body']));
					this.imageCode = blobData['changingThisBreaksApplicationSecurity'];
					this.showDialog = true;
					this.toastr.error(error.errors.message, 'Sorry!');
				  }
				);
			}
			if(params['canResCode'] && params['canTicketechCode']){
				this.isshowData=false;
				this.userserviceServiceObj.fetchUserReservationsByCode(params['canResCode'],params['canTicketechCode'])
				.then(
					(data:any)=> {
						this.isshowData=true;
							this.reservationUserDetails = data;
						     this.reservationDetails = data;
							this.reservationDetails = this.reservationDetails.data;
							this.reservation_id_for_cancel = this.reservationDetails.id;
							setTimeout(() => {
								
							
							this.userserviceServiceObj.cancelReservationByEmail(params['canResCode'],params['canTicketechCode'],this.reservationUserDetails.id)
								.then(
									(data:any)=> {
										
										this.CanIsLoaded = true;
										const reservation_status = '2';
										const reservation_id = this.reservation_id_for_cancel;
										this.updateReservationStaus(reservation_id, reservation_status);

										this.toastr.success('Reservation Successfully Canceled', 'Great!');
										this.router.navigate(['reservations/cancel/email']);
										
									},
									error => {
										sessionStorage.setItem('has_error', 'true');
										sessionStorage.setItem('has_error_msg', error.errors.message);
										this.toastr.error(error.errors.message);					
										this.router.navigate(['reservations/cancel/email']);
									}
								);
							}, 3000);
					},
					error => {
						this.toastr.error(error.errors.message);
						// this.router.navigate(['/search']);
						let startDuration = FilterHelper.defaultStartDuration();
      let startTime = FilterHelper.getFormattedTime(startDuration);
      localStorage.setItem('monthlyDate', startDuration.toString())
      let endDuration = FilterHelper.defaultEndDuration();
      let endTime = FilterHelper.getFormattedTime(endDuration);
      let endTimeDataIfLess = FilterHelper.makeDurationDateObject(endDuration, endTime);
      localStorage.setItem('endttimehome',CustomCode.getDateForMapSearchSlashFormatHome(endDuration, endTime));
      localStorage.setItem('startimehome',CustomCode.getDateForMapSearchSlashFormatHome(startDuration, startTime));
      let stime =localStorage.getItem('startimehome');
      this.router.navigate(['/search', '40.7589', '-73.9851', stime, 1,  'Times Square','monthly', CustomCode.searchMode()]);
					}
				);
				/*this.userserviceServiceObj.cancelReservationByEmail(params['canResCode'],params['canTicketechCode'])
				.then(
					(data:any)=> {
						this.toastr.success('Reservation Successfully Canceled', 'Great!');
						this.router.navigate(['/my-account/reservations']);
						//this.fetchUserReservationDetails();
					},
					error => {
						this.toastr.error(error.errors.message);
					}
				);*/
				
			}
			//reservation/cancel/:canResCode/:canTicketechCode
		});
		if(localStorage.getItem('access_token')) {
			this.userserviceServiceObj.GetLoyaltyWallet().subscribe(
				(response:any) => {
				this.rewarded_points = response.data.loyalty_config.POINTS_AT_REGISTRATION;
				}
			);
		}
	}
	updateFacilitySchedule(id) {
		//alert(id);
		this.searchPageObj.getFacilitySchedule(id).then(
			(res) => {
				this.garageWorkingHours = res;
				this.garageWorkingHours = this.garageWorkingHours.data;
			},
			(error) => {

			}
		);
	}
	showtimefornxtornot(text)
	{
		var res = text.split(':');
		if(res[0]>24)
		{
			return '(next day)'
		}else{
			return '';
		}
	}
	fetchReservation() {
      	this.reservationObj.reservationDetailsForNewImplementation(this.facId,this.tickID).then(
        	(res) => {
				this.isLoaded = true;
				//alert(3);
				  this.reservationDetails = res;
				  this.reservation_id=this.reservationDetails.data.id
				this.reservationDetails = this.reservationDetails.data;
				this.processFess = parseFloat(this.reservationDetails.facility.processing_fee);
				this.updateFacilitySchedule(this.reservationDetails.facility_id)
				if (this.router.url.indexOf('my-account') > -1) {					
				
				}else {

			/* commented as per client at 29-7-2021
				let USI_orderID = this.tickID;
				let USI_orderAmt = this.reservationDetails.total;
				let USI_currency = 'USD';
				var USI_headID = document.getElementsByTagName("head")[0];
				var USI_dynScript = document.createElement("script");
				USI_dynScript.setAttribute('type','text/javascript');
				USI_dynScript.src = 'https://www.upsellit.com/active/iconparking_pixel.jsp';
				USI_headID.appendChild(USI_dynScript);
				*/	

				}
				
				//this.garageIcon=this.reservationDetails.facility.photos.url
				//this.park_garage_2=this.reservationDetails.facility.photos.url
				let pointUsed = this.reservationDetails.loyalty_point_used;
				this.garageIcon=(this.reservationDetails.facility.photos !==null)?this.reservationDetails.facility.photos.url:this.garageIcon;
				this.park_garage_2=(this.reservationDetails.facility.photos!==null)?this.reservationDetails.facility.photos.url:this.park_garage_2;
				this.isUpdatedEndTimeFlag = (this.reservationDetails.is_end_time_updated == 1)?true:false
				this.totalReservatiomAmount=parseFloat(this.reservationDetails.total)+parseFloat(this.reservationDetails.discount)
				this.is_discount=parseFloat(this.reservationDetails.discount)
				if(this.reservationDetails.promo_usage!=undefined && this.reservationDetails.promo_usage!=null)
				{
					if(this.reservationDetails.promo_usage.promocode.indexOf('ICONREWARDS10')>-1)
					{
						this.discountType='Rewards discount';
					}else{
						this.discountType='Promo Code discount';
					}
					
				}
				if (localStorage.getItem('access_token')) {
					this.userserviceServiceObj.GetLoyaltyWallet().subscribe(
						(response:any) => {
							let pointRatio = response.data.loyalty_config.POINTS_RATIO;
							if(response.data.is_loyalty){
								this.isLoyaltyMember = true;
								if(pointUsed>0){
									this.reservationDetails.total = this.reservationDetails.loyalty_point_used;
								}
							}else{
								this.loyaltyPointMissed  = pointRatio*this.reservationDetails.total;
							}
							
							this.isLoyaltyInfoLoaded = true;
						}
					);
				}else{
					this.userserviceServiceObj.GetLoyaltyWalletConfig().subscribe(
						(response:any) => {
							let pointRatio = response.data.loyalty_config.POINTS_RATIO;
							this.loyaltyPointMissed  = pointRatio*this.reservationDetails.total;
							this.isLoyaltyInfoLoaded = true;
						}
					);
				}
				
				var lat = parseFloat(this.reservationDetails.facility.geolocations.latitude);
				var lng = parseFloat(this.reservationDetails.facility.geolocations.longitude);

				 $( document ).ready(function() {
					
								$('#map-wrapper').html("<iframe   width='257'  height='170' frameborder='0'  scrolling='no'  marginheight='0'  marginwidth='0' src='https://maps.google.com/maps?q="+lat+","+lng+"&hl=es;z=14&amp;output=embed' >");
								
					  });
				
        	},
        		(error) => {
        			this.toastr.error('Sorry! You are not authorized to view this reservation.');
					// this.router.navigate(['/search']);
					let startDuration = FilterHelper.defaultStartDuration();
      let startTime = FilterHelper.getFormattedTime(startDuration);
      localStorage.setItem('monthlyDate', startDuration.toString())
      let endDuration = FilterHelper.defaultEndDuration();
      let endTime = FilterHelper.getFormattedTime(endDuration);
      let endTimeDataIfLess = FilterHelper.makeDurationDateObject(endDuration, endTime);
      localStorage.setItem('endttimehome',CustomCode.getDateForMapSearchSlashFormatHome(endDuration, endTime));
      localStorage.setItem('startimehome',CustomCode.getDateForMapSearchSlashFormatHome(startDuration, startTime));
      let stime =localStorage.getItem('startimehome');
      this.router.navigate(['/search', '40.7589', '-73.9851', stime, 1,  'Times Square','monthly', CustomCode.searchMode()]);
        		}
		  ).catch((e)=>
		  {console.log(e)});
	}

	fetchUserDetails() {
	    this.myAccountMonthlyParkingObj.fetchUser()
	    .then(
			(data:any)=> {
				this.activeUser = data['data'];
				this.fetchUserReservationDetails();
			},
	      	error => {
	      		this.toastr.error(error.errors.message);
    			this.isLoaded = true;
	      	}
	    );
	 }

	fetchUserReservationDetails() {
		// this.lazyLoadLabel = 'reservation';
		this.userserviceServiceObj.fetchUserReservations(this.activeUser.id)
		.then(
			(data:any)=> {
				this.isLoaded = true;
				this.reservations = data;
        		this.reservations = this.reservations.data;
				$('#pagination').css('display', 'block');
			},
			error => {
				this.toastr.error(error.errors.message);
    			this.isLoaded = true;
			}
		);
	}

	loadPreviousNextReservations(url, type) {
		let cPage = url - 1;
		if (type === 'next') {
			cPage = url + 1;
		}
		// this.isLoaded = false;
		this.userserviceServiceObj.fetchNextPrevReservations(this.activeUser.id, cPage)
		.then(
			(data:any)=> {
				this.reservations = data;
        		this.reservations = this.reservations.data;
				$('#pagination').css('display', 'block');
				this.isLoaded = true;
			},
			error => {
				this.toastr.error(error.errors.message);
    			this.isLoaded = true;
			}
		);
	}

	redirectToDetails(resId,ticketId) {
		this.fetchUserStatusForTracking();
		this.router.navigate(['/my-account/reservations', resId,ticketId]);
	}

	reserbtn() {
  		$('.reserList').toggleClass('hidden');
  		$('.reserItem').toggleClass('hidden');
  	}
  	reserbackbtn() {
  		$('.reserList').toggleClass('hidden');
  		$('.reserItem').toggleClass('hidden');
	}

	cancelReservation(id,reservationCode,total,location) {
		$('body').removeAttr('style');
		this.fetchUserStatusForTracking();
		if(confirm("Are you sure you want to cancel?")) {
			const reservation_status_cancelRes= '2';
			this.userserviceServiceObj.cancelReservation(id)
		    .then(
				(data:any)=> {
					this.updateReservationStaus(id, reservation_status_cancelRes);
					this.toastr.success('Reservation Successfully Canceled', 'Great!');
					(<any>window).dataLayer.push(
						{'reservationCode': reservationCode,
						 'location':location,
						 'value':total,
						 'event':'Cancel reservation'
					});
					/* commented on 15-6-2021 as discussed with jitender  */
					// ga('ec:setAction', 'refund', { 'id': id });
					this.router.navigate(['/my-account/reservations']);
					this.fetchUserReservationDetails();

					// kochava.activity('cancel_reservation ', {
					// 	reservation_id : id,
					// 	reservationCode: reservationCode,
					// 	value : total,
					// 	location:location
					// });
				},
		      	error => {
		      		this.toastr.error(error.errors.message);
		      	}
		    );
		}
	}
	
	cancelReservationLogoutUser(resId,reservationCode,total,location) {
		this.fetchUserStatusForTracking();
		if(confirm("Are you sure you want to cancel?")) {
			resId=this.reservation_id
			this.reservationObj.reservationDetails(resId,reservationCode).then(
	        	(res) => {
	        		this.isLoaded = true;
	          		this.reservationDetails = res;
					  this.reservationDetails = this.reservationDetails.data;
					  
					//alert(this.reservationDetails.id);alert(this.reservationDetails.ticketech_code);alert(this.reservationDetails.reservation_code);
					
					this.userserviceServiceObj.cancelReservationByEmail(this.reservationDetails.reservation_code,this.reservationDetails.ticketech_code,resId)
									.then(
										(data:any)=> {
											const  reservation_status_cancel = '2';
											this.updateReservationStaus(resId, reservation_status_cancel);
											this.toastr.success('Reservation Successfully Canceled', 'Great!');
											(<any>window).dataLayer.push(
											{'reservationCode': reservationCode,
												 'location':location,
												 'value':total,
												 'event':'Cancel reservation'
											});
											/* commented on 15-6-2021 as discussed with jitender  */
											//ga('ec:setAction', 'refund', { 'id': resId });
											/* #Start Add GTM logged in db */
											let logData = {
												'iq_code':reservationCode,
												'coupon_code':null,
												'bar_code':null,
												'event_type':1
											}
											// kochava.activity('cancel_reservation ', {
											// 	reservation_id : resId,
											// 	reservationCode: reservationCode,
											// 	value : total,
											// 	location:location
											// });
											let reservation_id= resId;
											let reservation_status = '2';
											this.updateReservationStaus(reservation_id, reservation_status);
											
											this.logsService.addLog(logData).subscribe( (result)=>{ }, (err) => { });
											/* #End GTM logged in db */
											this.router.navigate(['reservations/cancel/email']);
											
										},
										error => {
											sessionStorage.setItem('has_error', 'true');
											sessionStorage.setItem('has_error_msg', error.errors.message);
											this.toastr.error(error.errors.message);					
											this.router.navigate(['reservations/cancel/email']);
										}
									);
					},
	        		(error) => {
	        			this.toastr.error(error.errors.message);
	        			this.isLoaded = true;
	        		}
	      	);
			/*this.userserviceServiceObj.cancelReservation(id)
		    .then(
				(data:any)=> {
					this.toastr.success('Reservation Successfully Canceled', 'Great!');
					this.router.navigate(['/my-account/reservations']);
					this.fetchUserReservationDetails();
				},
		      	error => {
		      		this.toastr.error(error.errors.message);
		      	}
		    );*/
		}
	}

	updateReservationStaus( reservation_id, reservation_status ){
		/* reservation tracking  */
		// kochava.activity('cancel_reservation ', {
		// 	reservation_id: reservation_id,
		// }); //is wale ko uncomment nhi krna h

		this.reservationObj.updateReservation(reservation_id, reservation_status).subscribe(
		  (data:any) => {
			this.reservationObj = data;
		  },
		  error =>{

		}
		)
	}
	errorCancelReservation(reservationStatus) {
		if(reservationStatus == 'Past'){
			this.toastr.error("Reservation has already begun or is already over and cannot be canceled.", 'Error', {timeOut: 2000, });
		}else if(reservationStatus == 'Cancelled'){
			this.toastr.error("Reservation has already been Canceled", 'Error', {timeOut: 2000, });
		}else if(reservationStatus == 'Current'){
			this.toastr.error("Reservation has already begun or is already over and cannot be canceled.", 'Error', {timeOut: 2000, });
		}else{
			this.toastr.error("Reservation can't be Canceled", 'Error', {timeOut: 2000, });
		}
	}


	findSpot() {

		let startDuration = FilterHelper.defaultStartDuration();
    let startTime = FilterHelper.getFormattedTime(startDuration);
    localStorage.setItem('monthlyDate',startDuration.toString())
    let endDuration = FilterHelper.defaultEndDuration();
    let endTime = FilterHelper.getFormattedTime(endDuration);
    let endTimeDataIfLess = FilterHelper.makeDurationDateObject(endDuration, endTime);
    localStorage.setItem('endttimehome',CustomCode.getDateForMapSearchSlashFormatHome(endDuration, endTime));
    localStorage.setItem('startimehome',CustomCode.getDateForMapSearchSlashFormatHome( startDuration, startTime));
    let stime =localStorage.getItem('startimehome');
    this.router.navigate(['/search', '40.7589', '-73.9851', stime, 1,  this.searchCriteria.label, this.searchCriteria.type, CustomCode.searchMode()]);
		// this.router.navigate(['/search', this.searchCriteria.lat, this.searchCriteria.long, this.searchCriteria.currdate, this.searchCriteria.radius, this.searchCriteria.label, this.searchCriteria.type, CustomCode.searchMode()]);

	}

	printReservation(id) {
    this.userserviceServiceObj.printReservation(this.reservation_id)
    .then(
      (data:any)=> {
		  
		// var mediaType = 'application/pdf';
        // var blob = new Blob([data['_body']], {type: mediaType});
        // var filename = 'test.jpeg';
        // FileSaver.saveAs(blob, filename);
        const urlCreator = window.URL;
        const blobData = this.sanitizer.bypassSecurityTrustUrl(urlCreator.createObjectURL(data['_body']));
        this.imageCode = blobData['changingThisBreaksApplicationSecurity'];
        $('body').addClass('modal-open');
        this.showDialog = true;
      },
      error => {
		    const urlCreator = window.URL;
			const blobData = this.sanitizer.bypassSecurityTrustUrl(urlCreator.createObjectURL(error['_body']));
			this.imageCode = blobData['changingThisBreaksApplicationSecurity'];
			this.showDialog = true;
        //this.toastr.error(error.errors.message, 'Sorry!');
      }
    );
  }
  
  printGuestReservation(facId) {
	  //alert(facId);
      	this.reservationObj.reservationDetails(this.reservation_id,this.tickID).then(
        	(res) => {
        		this.isLoaded = true;
          		this.reservationDetails = res;
          		this.reservationDetails = this.reservationDetails.data;
				//alert(this.reservationDetails.ticketech_code);alert(this.reservationDetails.reservation_code);
				this.userserviceServiceObj.printReservationByEmail(this.reservationDetails.reservation_code,this.reservationDetails.ticketech_code)
				.then(
				  (data:any)=> {
					const urlCreator = window.URL;
					const blobData = this.sanitizer.bypassSecurityTrustUrl(urlCreator.createObjectURL(data));
          this.imageCode = blobData['changingThisBreaksApplicationSecurity'];
          $('body').addClass('modal-open');
					this.showDialog = true;
					
				  },
				  error => {
					  const urlCreator = window.URL;
					  const blobData = this.sanitizer.bypassSecurityTrustUrl(urlCreator.createObjectURL(error['_body']));
					  this.imageCode = blobData['changingThisBreaksApplicationSecurity'];
					  this.showDialog = true;
					
					//this.toastr.error(error.errors.message, 'Sorry!');
				  }
				);

			},
        		(error) => {
        			this.toastr.error(error.errors.message);
        			this.isLoaded = true;
        		}
      	);
	}

	redirectToCard(iQCode){
		sessionStorage.setItem('iq_code', iQCode);
		this.router.navigate(['/my-account/manage-icon-rewards-card']);
	}

	moveToEnrollPage(iQCode){
		sessionStorage.setItem('iq_code', iQCode);
		if (localStorage.getItem('access_token')) {
			this.router.navigate(['/rewards']);
		}else{
			this.router.navigate(['/icon-rewards-register']);
		}
	}
	withoutprocessing(b)
	{
		return parseFloat(b)-this.processFess;
	}

	fetchUserStatusForTracking(){
		let getUserId = '0';
		let getUserEmail = '0';
		let user_login_type = '';
		let isLogin = false;
		if(localStorage.getItem('access_token')){
		 isLogin = true;
		   getUserId = localStorage.getItem('userid');
		   getUserEmail = localStorage.getItem('email');
		   user_login_type = localStorage.getItem('user_login_type');
		}
		const loginStatusTrack = {
		  'event' : 'pageview',
		  'loginStatus' : isLogin? 'logged in': 'Not Logged IN', // 'logged in/Not Logged IN',
		  'authenticationMethod' : user_login_type,
		  'userId' : getUserId,
		  'pageType' : this.router.url,
		  'email': getUserEmail,
	   };
	  (<any>window).dataLayer.push(loginStatusTrack);
	  }
}
