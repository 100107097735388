<section class="sineUpWrapper orangeBar pageWrapperLadning pad-bottom-sm">
  <div class="container">
    <div class="row">
      <div class="col-xs-12 mar-lr-auto col-sm-10 col-md-7 col-lg-7 text-center">
        <app-lazy-load [isLoaded]="isLoaded"></app-lazy-load>
      </div>
      
      <div *ngIf="!isSuccess && isLoaded" class="col-xs-12 mar-lr-auto col-sm-10 col-md-7 col-lg-7">
        <div class="signupform signupform-dital-card">
          <div class="clearfix">
            <img [src]="QuikParkImg" width="380" style="margin-bottom: 40px;">
           
            
          </div>
          <form name="form" role="form" id="floatForm" (ngSubmit)="addUserToPartner(formData, f)"
            #f="ngForm" novalidate>
            <h2 class="text-primary text-uppercase mar-top-0 mar-bottom-xs">{{PartnerData?.corporate_partner_name}}</h2>
            <p class="mar-bottom-sm">{{corporateDetails}}</p>
            <p class="mar-bottom-sm">{{infoText}}</p>
            <div class="form-group" [ngClass]="{ 'has-error' : f.submitted && !fname.valid}">
              <label class="control-label" for="fname">First Name</label>
              <input class="form-control" type="text" name="fname" (paste)="onPaste($event)"
                (keypress)="restChar($event)" [(ngModel)]="formData.fname" #fname="ngModel" required/>
              <div *ngIf="f.submitted && !fname.valid" class="help-block">First name is required</div>
            </div>
            <div class="form-group" [ngClass]="{ 'has-error' : f.submitted && !lname.valid}">
              <label class="control-label" for="lname">Last Name</label>
              <input class="form-control" type="text" name="lname" (paste)="onPaste($event)"
                (keypress)="restChar($event)" [(ngModel)]="formData.lname" #lname="ngModel" required/>
              <div *ngIf="f.submitted && !lname.valid" class="help-block">Last name is required</div>
            </div>
            <div class="form-group" [ngClass]="{ 'has-error' : f.submitted && !email.valid}">
              <label class="control-label" for="email">Email Address</label>
              <input class="form-control" type="text" (keydown)="restSpace($event)" name="email"
                [(ngModel)]="formData.email" #email="ngModel" required pattern="{{emailRegex}}" />
              <div *ngIf="f.submitted && !email.valid" class="help-block">Email address is required</div>
            </div>
            <div style="margin-bottom: 20px; font-weight:bold" class="text-danger" *ngIf="isNewUser">{{userMessage}}
            </div>
            <div class="form-group" *ngIf="isNewUser" [ngClass]="{ 'has-error' : f.submitted && !password.valid}">
              <label class="control-label" for="password">Password</label>
              <input class="form-control" type="password" name="password" [(ngModel)]="formData.password"
                #password="ngModel" [required]=reqPassword reverse="true" />
              <div *ngIf="f.submitted && !password.valid" class="help-block">{{passwordErrMsg}}</div>
            </div>
            <div class="form-group" *ngIf="isNewUser" [ngClass]="{ 'has-error' : f.submitted && !cpassword.valid}">
              <label class="control-label" for="cpassword">Confirm Password</label>
              <input class="form-control" type="password" name="cpassword" [(ngModel)]="formData.cpassword"
                #cpassword="ngModel" [required]=reqPassword />
              <div *ngIf="f.submitted && !cpassword.valid" class="help-block">Confirm Password and Password should be
                same</div>
            </div>
            <div class="form-group">
              <button class="btn btn-primary theme-btn btn-lg active">SUBMIT</button>
            </div>
          </form>
        </div>
        <div class="text-center mar-top-sm mar-bottom-sm">
          © {{copyrightyear}} Icon Parking | <a routerLink="/" target="_blank">{{baseUrl}}</a>
        </div>
      </div>
      <div class="clearfix"></div>
            <div *ngIf="isSuccess" class="col-xs-12 mar-lr-auto col-sm-10 col-md-6 col-lg-6">
              <div class="signupform">
                <div class="clearfix text-center">
                  <img [src]="QuikParkImg" width="380" style="margin-bottom: 40px;">
                </div>
                <h2 class="text-primary text-center text-uppercase mar-top-0 mar-bottom-sm">Thank You</h2>
                <h4 class="mar-bottom-sm text-center">You have successfully been added to your discount program. Please check your email for your welcome confirmation.</h4>
              </div>
            </div>
    </div>
  </div>
</section>