<app-header></app-header>
<div class="commonPage orangeBar">
<app-promocode-landing 
	[promoCode]="promoCode" 
	(saveEvent)="validatePromoCode('save', $event)"
	*ngIf="landingPage === 'promocode-landing'">
</app-promocode-landing>

<!-- <app-holiday-landing 
	[promoCode]="promoCode" 
	(saveEvent)="validatePromoCode('save', $event)" 
	*ngIf="landingPage === 'holiday-landing'">
</app-holiday-landing> -->
<app-errorlanding *ngIf="landingPage === 'holiday-landing'"></app-errorlanding>

<app-park10-landing 
	[promoCode]="promoCode" 
	(saveEvent)="validatePromoCode('save', $event)" 
	*ngIf="landingPage === 'park-landing'">
</app-park10-landing>

<app-email-landing 
	[promoCode]="promoCode" 
	(saveEvent)="validatePromoCode('save', $event)" 
	*ngIf="landingPage === 'email-landing'">
</app-email-landing>

<app-monthly-landing 
	*ngIf="landingPage === 'monthly-landing'">
</app-monthly-landing>

<app-winter-monthly-landing 
	*ngIf="landingPage === 'winter-monthly-landing'">
</app-winter-monthly-landing>

<!--  for coupon-nye lading page replace -->


<app-errorlanding *ngIf="landingPage === 'coupon-nye'"></app-errorlanding>



<!--  for holiday landing page replace -->
<app-errorlanding *ngIf="landingPage === 'icon-coupon-holiday'"></app-errorlanding>

<!-- <app-icon-coupon-holiday
	*ngIf="landingPage === 'icon-coupon-holiday'">
</app-icon-coupon-holiday> -->

<!-- <app-coupon-nye
	*ngIf="landingPage === 'coupon-nye'">
</app-coupon-nye> -->

<!-- <app-winter-landing
	*ngIf="landingPage === 'winter-landing'">
</app-winter-landing> -->

<app-errorlanding 
*ngIf="landingPage === 'winter-landing'">
</app-errorlanding>


<app-gameday
	[promoCode]="promoCode"
	(saveEvent)="validatePromoCode('save', $event)" 
	*ngIf="landingPage === 'gameday-hockey'">
</app-gameday>

<app-snow-storm
	*ngIf="landingPage === 'snow-storm'">
</app-snow-storm>

<!-- <app-valentine-day
	[promoCode]="promoCode"
	(saveEvent)="validatePromoCode('save', $event)" 
	*ngIf="landingPage === 'valentine-day'">
</app-valentine-day> -->
<app-errorlanding *ngIf="landingPage === 'valentine-day'"></app-errorlanding>

<app-march-madness
	[promoCode]="promoCode"
	(saveEvent)="validatePromoCode('save', $event)"
	*ngIf="landingPage === 'march-madness'">
</app-march-madness>

<!-- <app-spring
	*ngIf="landingPage === 'spring'">
</app-spring> -->

<app-errorlanding 
*ngIf="landingPage === 'spring'">
</app-errorlanding>
<!-- <app-labour-day
	*ngIf="landingPage === 'labour-day'">
</app-labour-day>
<app-labourday
	*ngIf="landingPage === 'labourday'">
</app-labourday> -->

<app-fall-coupon
	*ngIf="landingPage === 'fall-coupon'">
</app-fall-coupon>
<app-black-friday-coupon
	*ngIf="landingPage === 'black-friday-coupon'">
</app-black-friday-coupon>
<app-cyber-monday-coupon
	*ngIf="landingPage === 'cyber-monday-coupon'">
</app-cyber-monday-coupon>
<app-happy-new-year-coupon
	*ngIf="landingPage === 'happy-new-year'">
</app-happy-new-year-coupon>

</div>
<app-footer></app-footer>