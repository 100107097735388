import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { FilterHelper } from '../classes/filter-helper';
import { CustomCode }  from '../shared/CustomCode';
//import { Http, Headers, RequestOptions, Response } from '@angular/http';
import { HttpClient,HttpHeaders  } from '@angular/common/http';

import { Observable } from 'rxjs/Rx';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
@Injectable({
  providedIn: 'root'
})
export class AutosuggestEventService {
  
  constructor(private http: HttpClient) { }

  private appTicketMasterURL = CustomCode.APIURL+'search-public-events';
  private stateCode = 'NY';
  private countryCode = 'US';
  private dmaID = 345;

  private startDateTime = '';
  private endDateTime = '';
  private static DEFAULT_RESERVATION_DAYS = 29;
  
  


    getRelatedEvents(userSearchText) : Observable<any> {
        //this.startDateTime = (new Date()).toISOString('en-US');

        const headers = new HttpHeaders();
        this.startDateTime =FilterHelper.cDate(new Date()).toISOString();
        this.startDateTime=this.startDateTime.substring(0, this.startDateTime.length - 5)+'Z';
        headers.append('Access-Control-Allow-Origin', '*');
        headers.append('Access-Control-Allow-Methods', 'GET, POST, DELETE, PUT, OPTIONS, HEAD');
        headers.append('Access-Control-Allow-Headers', 'Content-Type, Authorization, X-Requested-With');
        this.endDateTime =FilterHelper.addDays(new Date(),AutosuggestEventService.DEFAULT_RESERVATION_DAYS).toISOString();
        this.endDateTime=this.endDateTime.substring(0, this.endDateTime.length - 5)+'Z';;
        //const options = new RequestOptions({ headers: headers });
        //const formDetails = {'old_password': chpadata.old_password,'password': chpadata.password,'password_confirmation': chpadata.password_confirmation};
        const options = CustomCode.returnHeaders();               
        //return this.http.get(this.appTicketMasterURL+'?apikey='+CustomCode.appTicketMasterkey+'&keyword='+userSearchText+'&dmaID='+this.dmaID+'&stateCode=NY&countryCode=US&startDateTime='+this.startDateTime+'&endDateTime='+this.endDateTime+'&sort=date,asc',options).map((res: Response) => res.json())
        if(userSearchText==undefined || userSearchText=='')
        {
          userSearchText='nopesss';
        }
        //userSearchText='mhm';
        const formDetails = {'search_keyword':userSearchText,'device_type':'web'};
        return this.http.post(this.appTicketMasterURL, formDetails, { headers: options })
        .retry(3)
        .map((res: Response) => res)
        .catch((error: any) => Observable.throw(error));
          //return this.http.post(this.appTicketMasterURL,{'search_keyword':userSearchText}).map((res: Response) => res.json()).catch((error: any) => Observable.throw(error.json()));
        }


}
