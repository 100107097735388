import { Injectable } from '@angular/core';
//import { Http, Headers, RequestOptions, Response } from '@angular/http';
import { HttpClient,HttpHeaders  } from '@angular/common/http';
import { CustomCode } from '../shared/CustomCode';
import 'rxjs/add/operator/map';

@Injectable({
  providedIn: 'root'
})
export class LogsService {
  constructor(private http: HttpClient) { }

  /*
    Function for add log in db
    @param LogData JSON_ARRAY
    @return JSON_ARRAY
  */
 
  addLog(logData) {
    return  this.http.post(CustomCode.APIURL+'gtm-tracking', logData).map(
      (res: Response) => res.json()
    )
  }
  
}


