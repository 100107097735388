<div id="mySidenav" class="sidenav isDesktop" *ngIf="!isFrame" > 

  <a href="javascript:;"  (click)="close_side()"  class="closeBtnSidenav"><fa size="3x" name="times"></fa></a>
  <div class="newSidebar" >
    <form action="/search">
    <div class="searchBarSideNav">
      <app-google-autosuggest [isSideNav]="true"></app-google-autosuggest>
    </div>
    </form>

   <div loginOptions *showItBootstrap="['xs','sm']" >
     <div class="row  no-gutters justify-content-center">
       <div class="col-auto" *ngIf="loggedUser">
                         <a class=" btn btn-link textTruncate"  routerLink="/my-account/account" *ngIf="loggedUser" type="button" ><span style=" overflow: hidden;
                          text-overflow: ellipsis; max-width: 47vw; display: inline-block; vertical-align: middle;">{{loggedUser}}</span>&nbsp;<fa name="user" style="vertical-align: middle;"></fa></a>

       </div>
       <div class="col-auto" *ngIf="!loggedUser">
                           <a class="btn btn-link loginPopupActive" data-toggle="modal" id="loginPopup"  href='#login' (click)="resetLoginForm(f) " oncontextmenu="return false;"><span  style="color: #fff;">LOGIN / </span><span class="text-primary">SIGNUP</span>&nbsp;<fa name="user"></fa></a>

       </div>
       <div class="col-auto" *ngIf="loggedUser">
                         <a class="btn  btn-danger"  (click)="logOut()"><span style="display: inline-block; vertical-align: middle;">Sign out</span>&nbsp; <fa name="sign-out" style="vertical-align: middle; display: inline-block;"></fa></a> 

       </div>
     </div>

   </div>

    <div class="wrapBox" >
    <ul class="sideBarNavMob" >
  
      <li class="visible-xs" (click)="closeBtnSidenav()">
        <a routerLink="/" ><img [src]="homeIcon" class="mar-right-xs" alt="Image" width="26px" height="26px"> Home</a>
      </li>
    

      <li (click)="closeBtnSidenav()" class="">
        <a  routerLinkActive="active" (click)="formonthly2()" style="cursor: pointer;"><img [src]="monthlyParkingIcon" class="mar-right-xs" alt="Image" width="26px" height="26px"> Monthly Parking</a>
      </li>
      <li (click)="closeBtnSidenav()" ><a (click)="triggerEvent('SPECIALS & OFFERS')" href="javascript:;" routerLinkActive="active"  routerLink="/specials" class="font-15"> Specials & offers </a></li>
      <li (click)="closeBtnSidenav()">
        <a  routerLinkActive="active" routerLink="/business-parking-nyc"><img [src]="contactUsIcon" class="mar-right-xs" alt="Image" width="26px" height="26px">Icon For Business</a>
      </li>
      <!-- <li (click)="closeBtnSidenav()">
        <a *ngIf="!isLoyaltyMember"  routerLinkActive="active" class="mar-right-xs" width="26px" height="26px" routerLink="/rewards">icon rewards</a>
        <a *ngIf="isLoyaltyMember"  routerLinkActive="active" class="mar-right-xs" width="26px" height="26px" routerLink="/my-account/icon-rewards">icon rewards</a>
      </li> -->
  
    
      <li (click)="closeBtnSidenav()">
        <a  routerLinkActive="active" routerLink="/contact"><img [src]="contactUsIcon" class="mar-right-xs" alt="Image" width="26px" height="26px"> Contact Us</a>
      </li>
      <li (click)="closeBtnSidenav()">
        <a  routerLinkActive="active" [href]="newsTips"><img [src]="newsIcon" class="mar-right-xs" alt="Image" width="26px" height="26px"> News + Tips</a>
      </li>
      <!-- <li (click)="closeBtnSidenav()" class="visible-xs">
        <a routerLinkActive="active" routerLink="/neighborhoods/central-park/monthly"><img [src]="neighborhoodsIcon" class="mar-right-xs" alt="Image" width="26px" height="26px"> Neighborhoods</a>
      </li> -->
      <li *hideItBootstrap="['xs','sm']" (click)="closeBtnSidenav()" >
          <a *ngIf="!loggedUser" data-toggle="modal" href='#login'><img [src]="managementIcon" class="mar-right-xs" alt="Image" width="26px" height="26px"> Login</a>
        <a  *ngIf="loggedUser" routerLinkActive="active" routerLink="/my-account/account" ><img [src]="managementIcon" class="mar-right-xs" alt="Image" width="26px" height="26px"> My Account</a>
      </li>
      <!-- <li>
        <a [href]="yankeeUrl" target="_blank"><img [src]="yankeeStadiumIcon" class="mar-right-xs" alt="Image" width="26px" height="26px"> Yankee Stadium</a>
      </li> -->
      <li (click)="closeBtnSidenav()">
        <a routerLinkActive="active" routerLink="/about-us"><img [src]="aboutIcon" class="mar-right-xs" alt="Image" width="26px" height="26px"> About Us</a>
      </li>
      <li (click)="closeBtnSidenav()">
        <a routerLinkActive="active" routerLink="/evcharging"><img [src]="evChargingIcon" class="mar-right-xs" alt="Image" width="26px" height="26px"> EV Charging</a>
      </li>
      <li (click)="closeBtnSidenav()">
        <a routerLinkActive="active" routerLink="/careers"><img [src]="careersIcon" class="mar-right-xs" alt="Image" width="26px" height="26px"> Careers/Jobs </a>
      </li>
      <li (click)="closeBtnSidenav()">
        <a routerLinkActive="active" routerLink="/faq"><img [src]="faqIcon" class="mar-right-xs" alt="Image" width="26px" height="26px"> FAQ</a>
      </li>
      <!-- <li (click)="closeBtnSidenav()" class="mar-top-sm" *showItBootstrap="['xs','sm']">
          <a class="iconContact " [href]="phone" onclick="ga('send', 'event', { eventCategory: 'menu Mobilecalls', eventAction: 'click'});callcustomer();">1 (877) 727-5464</a>
      </li> -->
     
    </ul>
  </div>
    
  </div>
</div>
