<div role="tabpanel" class="tab-pane fade active in" id="methods" *ngIf="isLoaded">
	<h4 class="text-primary text-uppercase mar-top-0">Payment Methods</h4>
	<p class="currentCarD" *ngFor="let paymentMethod of paymentMethods?.payments">
    <span *ngIf="paymentMethod?.card !== null">
    <div class="row align-items-center " >
    
    <div class="col-auto">
                <fa name="credit-card" size="2x"></fa>
            </div>
    
      <div class="col col-sm-auto pl-0 pr-0">Card Ending In ({{ paymentMethod?.card?.card_number}}) for {{ displayNameOnCard(paymentMethod?.address?.first_name) }} {{ displayNameOnCard(paymentMethod?.address?.last_name) }}, Expires on
    <span *ngIf="paymentMethod?.expiration_month && paymentMethod?.expiration_year">
    {{ paymentMethod?.expiration_month }}/{{ paymentMethod?.expiration_year }}
    </span>
    <span *ngIf="!paymentMethod?.expiration_month && !paymentMethod?.expiration_year">
    ----/----
    </span>
    </div>
            <div class="col-3 col-sm pl-0 pr-0">
    
    <a *ngIf="isSubscriptionBasedUser && paymentMethod?.is_autopay_profile !==1" href="javascript:;" tooltip="Click here to delete this payment method" class="btn btn-link {{ paymentMethod?.payment_profile_id }}" (click)="deleteMethod(paymentMethod?.payment_profile_id)" >
    <fa name="trash"></fa>
    </a>
    <a *ngIf="!isSubscriptionBasedUser" href="javascript:;" tooltip="Click here to delete this payment method" class="btn btn-link {{ paymentMethod?.payment_profile_id }}" (click)="deleteMethod(paymentMethod?.payment_profile_id)" >
    <fa name="trash"></fa>
    </a>
      </div>
      </div>
    </span>
    <span *ngIf="paymentMethod?.bank !== null">
    <div class="row align-items-center " >
    
    <div class="col-auto">
                <fa name="bank" size="2x"></fa>
            </div>
    
      <div class="col col-sm-auto pl-0 pr-0"> Bank Ending In ({{ paymentMethod?.bank?.bank_account}}) for {{ displayNameOnCard(paymentMethod?.address?.first_name) }} {{ displayNameOnCard(paymentMethod?.address?.last_name) }}
      </div>
        <div class="col-3 col-sm pl-0 pr-0">
    <a *ngIf="isSubscriptionBasedUser && paymentMethod?.is_autopay_profile !==1" href="javascript:;" tooltip="Click here to delete this payment method" class="btn btn-link {{ paymentMethod?.payment_profile_id }}" (click)="deleteMethod(paymentMethod?.payment_profile_id)">
    <fa name="trash" ></fa>
    </a>
    <a *ngIf="!isSubscriptionBasedUser" href="javascript:;" tooltip="Click here to delete this payment method" class="btn btn-link {{ paymentMethod?.payment_profile_id }}" (click)="deleteMethod(paymentMethod?.payment_profile_id)">
    <fa name="trash" ></fa>
    </a>
    </div>
    </div>
    </span>
    </p>
	<div class="clearfix mar-top-sm">
		<p>Choose one or more payment methods to add to your account.</p>
		<button type="button" class="btn btn-primary btn-fill" *ngIf="showAddCreditCardBtn" (click)="showAddCreditCard($event)">add credit card</button> &nbsp; &nbsp;<span class="mar-left-xs hidden-xs" *ngIf="showAddCreditCardBtn"></span><button type="button" class="btn btn-primary btn-fill" *ngIf="showAddBankAccountBtn" (click)="showAddBankAccount($event)">add bank account</button>
	</div>
	<p></p>
	<p></p>
	<p></p>
	<div *ngIf="addingPaymentMethods">
		<div class="add-credit-card" *ngIf="addCreditCard">
			<app-add-credit-card [currentActive]="currentActive" [accountNumber]="accountNumber" (cancelEvent)="receiveCancel($event)" (responseEvent)="afterAdd($event)"></app-add-credit-card>
		</div>
		<div class="add-bank-account" *ngIf="addBankAccount">
			<app-add-bank-account [currentActive]="currentActive" [accountNumber]="accountNumber" (cancelEvent)="receiveCancel($event)" (responseEvent)="afterAdd($event)"></app-add-bank-account>
		</div>
	</div>
</div>
<!-- <ng-template #deleteTemplate>Dummy Text</ng-template> -->
<app-lazy-load [isLoaded]="isLoaded" [sectionName]="'payment methods'"></app-lazy-load>
<div id="submitDeleteButton" (click)="openModal(template22)" class="hide" style="display: none;">Modal</div>
<ng-template class="rounded" #template22>
	<div class="modal-body " style="font-size: 16px;">
		<p>{{disclamerMsg}}</p>

	</div>
	<div class="modal-footer actionModal  border-top pt-2">
		<button type="button" class="btn btn-link text-info" (click)="confirm()">Continue</button>
		<button type="button" class="btn btn-link text-info" (click)="decline()">Cancel</button>
	</div>
</ng-template>