import { Injectable } from '@angular/core';
//import {Http, XHRBackend, RequestOptions, Request, RequestOptionsArgs, Response, Headers} from '@angular/http';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';
import { UserserviceService } from './userservice.service';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import * as $ from 'jquery';
import { CustomCode } from '../shared/CustomCode';

@Injectable({
  providedIn: 'root'
})
export class HttpService {
  access_token_call_status = 0;
  constructor(private http: HttpClient) {
    // const token = localStorage.getItem('access_token');
    // const bigApple = localStorage.getItem('X-Big-Apple');
    // options.headers.set('Authorization', 'Bearer ' + token);
    // options.headers.set('X-Big-Apple',bigApple);
    // super(backend, options);
  }

  get(url: string, headers?: HttpHeaders): Observable<any> {
    headers = new HttpHeaders();
    const token = localStorage.getItem('access_token');
    const bigApple = localStorage.getItem('X-Big-Apple');
    headers.set('Authorization', 'Bearer ' + token);
    headers.set('X-Big-Apple', bigApple);
    //console.log('httprequest get URL ',url);
    let notLoad = 2;
    if (url.indexOf('facility_llc') > -1 || url.indexOf('mobile-device-version/web') > -1 || url.indexOf('get-user-tier') > -1 || url.indexOf('facilities-rates') > -1) {
      notLoad = 1;
    } else {
      notLoad = 1
    }

    if (!localStorage.getItem('firsttest') && notLoad) {
      localStorage.setItem('firsttest', '1')

      let versionUrl = CustomCode.APIURL + 'mobile-device-version/web';
      const options = CustomCode.returnHeaders();
      this.http.get(versionUrl, { headers: headers })
        .map((res: any) => res).subscribe((done) => {
          let res = done['data'].web_version
          let b = this.getCookie('versions')
          console.log('version reload test')
          if (b == '') {
            this.setCookie('versions', res, 10000)
            //location.reload(true);
          } else if (b != res) {
            this.setCookie('versions', res, 10000)
            //location.reload();
            console.log('version reload test 1')
            window.location.href = window.location.href
            //location.reload(true);
          }

        }, (error) => {
          console.log(error)
        });


    }
    setTimeout(() => {
      localStorage.removeItem('firsttest')
    }, 4000);



    return this.http.get<any>(url, { headers: headers });

  }
  getwithheader(url: string, headers?: HttpHeaders): Observable<any> {
    headers = new HttpHeaders();
    const token = localStorage.getItem('access_token');
    const bigApple = localStorage.getItem('X-Big-Apple');
    headers.set('Authorization', 'Bearer ' + token);
    headers.set('X-Big-Apple', bigApple);
    //console.log('httprequest get URL ',url);

    return this.http.get(url, { headers: new HttpHeaders({ 'Authorization': 'Bearer ' + token, 'X-Big-Apple': bigApple }) }).catch(this.catchAuthError(this, url, { headers: new HttpHeaders({ 'Authorization': 'Bearer ' + token, 'X-Big-Apple': bigApple }) }));;
  }
  getimage(url: string, headers?: HttpHeaders): Observable<any> {
    headers = new HttpHeaders();
    const token = localStorage.getItem('access_token');
    const bigApple = localStorage.getItem('X-Big-Apple');
    headers.set('Authorization', 'Bearer ' + token);
    headers.set('X-Big-Apple', bigApple);
    //console.log('httprequest URL ',url);
    //return this.http.get<any>(url,{headers:headers})
    return this.http.get(url, { responseType: 'blob', headers: new HttpHeaders({ 'Authorization': 'Bearer ' + token, 'X-Big-Apple': bigApple }) }).catch(this.catchAuthError(this, url, { responseType: 'blob', headers: new HttpHeaders({ 'Authorization': 'Bearer ' + token, 'X-Big-Apple': bigApple }) }));
  }


  post(url: string, body: any, headers?: HttpHeaders): Observable<any> {
    headers = new HttpHeaders();
    //const token = localStorage.getItem('access_token');
    const token = localStorage.getItem('access_token');
    const bigApple = localStorage.getItem('X-Big-Apple');
    headers.set('Authorization', 'Bearer ' + token);
    headers.set('X-Big-Apple', bigApple);
    //const body=JSON.stringify(person);
    //return  this.http.post<any>(url, body, {headers:headers , observe: 'response'})
    return this.http.post(url, body, { headers }).catch(this.catchAuthError(this, url, { headers }));
    // .subscribe(
    //  response=> {
    //       console.log("POST completed sucessfully. The response received "+response);
    //   })

  }
  postnew(url: string, body: any, headers?: HttpHeaders): Observable<any> {
    headers = new HttpHeaders();
    //const token = localStorage.getItem('access_token');
    const token = localStorage.getItem('access_token');
    const bigApple = localStorage.getItem('X-Big-Apple');
    headers.set('Authorization', 'Bearer ' + token);
    headers.set('X-Big-Apple', bigApple);
    //const body=JSON.stringify(person);
    //return  this.http.post<any>(url, body, {headers:headers , observe: 'response'})
    console.log(url)
    console.log(body)
    return this.http.post(url, body, { headers: new HttpHeaders({ 'Authorization': 'Bearer ' + token, 'X-Big-Apple': bigApple }) }).catch(this.catchAuthError(this, url, { headers: new HttpHeaders({ 'Authorization': 'Bearer ' + token, 'X-Big-Apple': bigApple }) }));


    // .subscribe(
    //  response=> {
    //       console.log("POST completed sucessfully. The response received "+response);
    //   })

  }
  put(url: string, body: any, headers?: HttpHeaders): Observable<any> {
    headers = new HttpHeaders();
    const token = localStorage.getItem('access_token');
    const bigApple = localStorage.getItem('X-Big-Apple');
    headers.set('Authorization', 'Bearer ' + token);
    headers.set('X-Big-Apple', bigApple);
    //const body=JSON.stringify(person);
    return this.http.put<any>(url, body, { headers: new HttpHeaders({ 'Authorization': 'Bearer ' + token, 'X-Big-Apple': bigApple }) }).catch(this.catchAuthError(this, url, { headers: new HttpHeaders({ 'Authorization': 'Bearer ' + token, 'X-Big-Apple': bigApple }) }))
    // .subscribe(
    //  response=> {
    //       console.log("POST completed sucessfully. The response received "+response);
    //   })

  }
  delete(url: string, headers?: HttpHeaders): Observable<any> {
    headers = new HttpHeaders();
    const token = localStorage.getItem('access_token');
    const bigApple = localStorage.getItem('X-Big-Apple');
    headers.set('Authorization', 'Bearer ' + token);
    headers.set('X-Big-Apple', bigApple);
    return this.http.delete<any>(url, { headers: new HttpHeaders({ 'Authorization': 'Bearer ' + token, 'X-Big-Apple': bigApple }) }).catch(this.catchAuthError(this, url, { headers: new HttpHeaders({ 'Authorization': 'Bearer ' + token, 'X-Big-Apple': bigApple }) }))
  }
  // get(url: string|Request, options?:  {
  //         headers?: HttpHeaders | { [header: string]: string | string[]; };
  //         //params?: HttpParams | { [param: string]: string | string[]; };
  //         observe?: "body|events|response|";
  //         //responseType: "arraybuffer|json|blob|text";
  //         reportProgress?: boolean; 
  //         withCredentials?: boolean;}): Observable<Object> {
  //   const token = localStorage.getItem('access_token');
  //   const bigApple = localStorage.getItem('X-Big-Apple');
  //   if (typeof url === 'string') {
  //     // if (!options) {
  //     //   const headers: new HttpHeaders();
  //     // }
  //     const headers = new HttpHeaders();
  //     headers.set('Authorization', 'Bearer ' + token);
  //     headers.set('X-Big-Apple',bigApple);

  //   } else {
  //     url.headers.set('Authorization', 'Bearer ' + token);
  //     url.headers.set('X-Big-Apple',bigApple);

  //   }
  //   let notLoad=1;
  //   if(url['url'].indexOf('facility_llc')>-1 || url['url'].indexOf('mobile-device-version/web')>-1 ) 
  //   {
  //     notLoad=0;
  //   }else{
  //     notLoad=1
  //   }
  //   //

  //   if(!localStorage.getItem('firsttest') && notLoad)
  //   {
  //     localStorage.setItem('firsttest','1')
  //     let userServiceObj = new UserserviceService(this);
  //     userServiceObj.checkVersion().subscribe((done)=>{
  //        let res=done['data'].web_version
  //        let b= this.getCookie('versions')
  //        //alert(b)
  //        if(b=='')
  //        {
  //         this.setCookie('versions',res,10000)
  //         //location.reload(true);
  //        }else if(b!=res){
  //         this.setCookie('versions',res,10000)
  //         location.reload(true);
  //        }

  //     },(error)=>{
  //       console.log(error)
  //     });
  //   }
  //   setTimeout(() => {
  //     localStorage.removeItem('firsttest')
  //    }, 10000);
  //  // this.setCookie('version','1.0',10000)

  //  if(url['method']==1)
  //  {

  //   if(url['_body'] instanceof Object)
  //   {
  //     url['_body'].device_type=this.getCurrentDeviceType();
  //   }
  //   //url['_body'].device_type=this.getCurrentDeviceType();;
  //  }
  // //  if(url['method']==3)
  // //  {

  // //   url['_body'].device_type=this.getCurrentDeviceType();;
  // //  }
  //  if(url['method']==2)
  //  {
  //   if(url['_body']!=null)
  //   {
  //     url['_body'].device_type=this.getCurrentDeviceType();
  //   }
  //  }

  // //  console.log(url);
  //   return get(url, options).catch(this.catchAuthError(url, options));
  //  // return this.http.get(url, options)
  // }
  setCookie(cname, cvalue, exdays) {

    //document.cookie = cname + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    var d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    var expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }
  getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }
  private catchAuthError(self: HttpService, url, options) {
    //console.log('catchAuthError');
    //this.handleSessionRefresh();
    return (res: any) => {
      //console.log('catchAuthError inner',res.status,res);
      let response = res;
      if (res.status == '0') {
        //console.log('cors occured');
        if (this.access_token_call_status == 0) {
          this.handleSessionRefresh();
        }
      }

      //console.log(res.error.errors.message)

      if ((res.status === 401) && (res.error.errors.message == "The user credentials were incorrect.")) {
        //console.log('catchAuthError 1')
        return Observable.throw(res);
      } else if (res.status == 401 || res.status === 403) {
        //console.log('catchAuthError 2')
        this.userLogout();
        // if (this.access_token_call_status == 0) {
        //   this.handleSessionRefresh();
        // }

      } else if (res.status === 400) {
        this.userLogout();
        // if (this.access_token_call_status == 0) {
        //   this.handleSessionRefresh();
        // }
        // Logout and redirect to Home Page ...
        // this.userLogout();
        // var urlChunks = window.location.href.split('/');
        // var newURL = urlChunks[0] + '//' + urlChunks[2];
        // if (window.location.href.indexOf('.com') > 0) {
        //   window.location.href = newURL;
        // }
      } else {
        return Observable.throw(res);
      }
    };
  }
  handleSessionRefresh() {
    this.access_token_call_status = 1;
    const refTokenData = {
      'grant_type': 'refresh_token',
      'client_id': CustomCode.clientId,
      'client_secret': CustomCode.clientSecret,
      'refresh_token': localStorage.getItem('refresh_token'),
      'remember_me': null
    };
    this.refreshToken(refTokenData)
      .subscribe(
        (data: any) => {
          let responseData = data.data;
          localStorage.setItem('user', responseData.user);
          localStorage.setItem('access_token', responseData.access_token);
          localStorage.setItem('refresh_token', responseData.refresh_token);
          localStorage.setItem('expires_in', responseData.expires_in);
          localStorage.setItem('token_type', responseData.token_type);
          window.location.reload();
        },
        error => {
          this.userLogout();
          return false;
        }
      );
  }
  refreshToken(data) {
    let loginUrl = CustomCode.APIURL + 'oauth/access_token';
    return this.http.post(loginUrl, data)
      .retry(3)
      .map(
        (res: Response) => res
      )
    // .catch(
    //   this.userLogout();
    //   this.catchAuthError(this, loginUrl, data)
    // );
  }

  public userLogout() {
    console.log('user logout')
    localStorage.clear();
    CustomCode.userLogout();
    window.location.href =CustomCode.SIGNOUTURL
  }

  //   public displayLoginUser() {
  //     const loggedUserName = this.getloggedInUserDetails();
  //     if (loggedUserName) {
  //         $('#guestuser').css('display', 'none');
  //         $('#loggeduser').css('display', 'block');
  //       } else {
  //        $('#guestuser').css('display', 'block');
  //         $('#loggeduser').css('display', 'none');
  //       }
  //   }

  //   public getloggedInUserDetails() {
  //     let loggedUser = '';
  //     if (localStorage.getItem('access_token')) {
  //         loggedUser = localStorage.getItem('username');
  //     }
  //     return loggedUser;
  //   }


  // public  getCurrentDeviceType(){
  //   let device_type = 'web';
  //   if ($(window).width() < 767) {  device_type = 'web responsive'; }
  //   else{ device_type = 'web'; }
  //   return device_type;
  // }

}