<app-header></app-header>
<div class="page-header orangeBar" id="contact"></div>
<div class="contactPage pad-top-md pad-bottom-md contact-top-30">
  <div class="container">

    <app-lazy-load [isLoaded]="isLoaded" [sectionName]></app-lazy-load>

    <h1 *ngIf="isLoaded" class="text-uppercase text-primary mar-top-0 mar-bottom-sm">{{contactDetails.post_title}}</h1>
    <div *ngIf="isLoaded" class="row mar-top-sm mar-bottom-xs">
      <div class="col-xs-12 col-sm-12" [innerHTML]="contactDetails?.post_content"></div>
      <!-- <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 d-none">
            <h4 class="text-uppercase mar-top-0">Phone</h4>
            <div class="row pad-top-xs">
              <div class="col-xs-4 col-sm-5 col-md-4">
                  <b>{{contactDetails.customer_service}}</b>
              </div>
              <div class="col-xs-8 col-sm-7 col-md-8">
                <p>
                    {{contactDetails.customer_service_phone}}
                </p>
               
              </div>
            </div>
            <div class="row pad-top-xs">
              <div class="col-xs-4 col-sm-5 col-md-4">
                <b>{{contactDetails.monthly_parking}}</b>
              </div>
              <div class="col-xs-8 col-sm-7 col-md-8">
                <p>{{contactDetails.monthly_parking_phone}}</p>
              </div>
            </div>
            <div class="row pad-top-xs">
              <div class="col-xs-4 col-sm-5 col-md-4">
                <b>{{contactDetails.corporate_parking}}</b>
              </div>
             
            </div>
          </div> -->
      <!-- <div class="col-xs-12 visible-xs visible-sm d-none">
            <p><br/></p>
          </div> -->
      <!-- <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 hidden-xs">
            <h4 class="text-uppercase mar-top-0">Email</h4>
            <div class="row"><div class="col-xs-12 pad-top-xs"><a href="mailto:{{contactDetails.customer_service_email}}"><b>{{contactDetails.customer_service_email}}</b></a></div></div>
            <div class="row hidden-xs hidden-sm"><div class="col-xs-12 pad-top-xs"><p>&nbsp;</p></div></div>
            <div class="row"><div class="col-xs-12 pad-top-xs"><a href="mailto:{{contactDetails.corporate_parking_email}}"><b>{{contactDetails.corporate_parking_email}}</b></a></div></div>
          </div> -->
    </div>
    <!-- <div class="contact-box-grey">
          <div class="row">
            <div class="col-md-4 col-12 mar-bottom-xs col-pad-1">
              <div class="contact-box">
                <h3 class="text-uppercase">BUY MONTHLY PARKING</h3>
                <p><a href="">You can purchase a new monthly<br class="hidden-xs">
                  space here <i class="fa fa-chevron-right"></i></a></p>
                <p><a href="" class="btn theme-btn btn-primary btn-fill text-uppercase">BUY MONTHLY SPACE</a></p>
                <p>or sign up with the onsite manager at <br class="hidden-xs">your preferred location.</p>
              </div>
            </div>
            <div class="col-md-4 col-12 mar-bottom-xs col-pad-2">
              <div class="contact-box">
                <h3 class="text-uppercase">Customer Service</h3>
                <p><a href="">You can find answers to many <br class="hidden-xs">frequently asked questions here <i class="fa fa-chevron-right"></i></a></p>
                <p><a href="" class="btn theme-btn btn-primary btn-fill text-uppercase">FREQUENTLY ASKED QUESTIONS</a></p>
                <p>For support and service concerns, <br class="hidden-xs">send us an email at:<br>
                  <a href="">customerservice@iconparking.com</a>
                </p>
              </div>
            </div>
            <div class="col-md-4 col-12 mar-bottom-xs col-pad-3">
              <div class="contact-box">
                <h3 class="text-uppercase">Corporate and events parking</h3>
                <p>For inquiries, send us an email at:<br>
                  <a href="">corporateparking@iconparking.com</a>
                </p>
              </div>
            </div>
          </div>
          </div> -->
    <form name="form" role="form" id="floating-label-contact-us" (ngSubmit)="f.form.valid && contactUsSubmit(formData, f)"
      #f="ngForm" novalidate>
      <div id="errors-container">
      </div>
      <p id="successpara" class="submit-success"></p><br>

      <h4 class="text-uppercase  font-weight-bold">Contact review Form</h4>
      <div class="clearfix">
        <p class="text-danger"><b>*</b> indicates required field</p>
      </div>
      <div class="row">
        <div class=" col-xs-12 col-sm-8 col-md-8 col-lg-8 contact-pad-r">
          <!-- <div class="form-group mb-1" [ngClass]="{ 'has-error' : f.submitted && !name.valid}">
                <label class="control-label" for="name">Name*</label>
                <input class="form-control" type="text" maxlength=100 name="name" [(ngModel)]="formData.name" #name="ngModel" required />
                <div *ngIf="f.submitted && !name.valid" class="help-block">Name is required</div>
              </div> -->
          <div class="form-group mb-1 " [ngClass]="{ 'has-error' : f.submitted && !first_name.valid}">
            <label class="control-label" for="first_name">First Name*</label>
            <input class="form-control" type="text" name="first_name" [(ngModel)]="formData.first_name" #first_name="ngModel"
              required />
            <div *ngIf="f.submitted && !first_name.valid" class="help-block">First name is required</div>
          </div>
          <div class="form-group mb-1" [ngClass]="{ 'has-error' : f.submitted && !last_name.valid}">
            <label class="control-label" for="last_name">Last Name*</label>
            <input class="form-control" type="text" maxlength=100 name="last_name" [(ngModel)]="formData.last_name"
              #last_name="ngModel" required />
            <div *ngIf="f.submitted && !last_name.valid" class="help-block">Last name is required</div>
          </div>
          <div class="form-group mb-1" [ngClass]="{ 'has-error' : f.submitted && !email.valid}">
            <label class="control-label" for="email">Email Address*</label>
            <input class="form-control" type="text" maxlength=100 name="email" [(ngModel)]="formData.email" #email="ngModel"
              required (keydown)="restSpace($event)" pattern="{{emailRegex}}" />
            <div *ngIf="f.submitted && !email.valid" class="help-block">Email address is required</div>
          </div>
          <div class="form-group mb-1" [ngClass]="{ 'has-error' : f.submitted && !phone.valid}">
            <label class="control-label" for="phone">Phone Number *</label>
            <input class="form-control" minlength="10" [textMask]="{mask: phoneMask}" (keypress)="keyPress($event)"
              type="text" name="phone_number" [(ngModel)]="formData.phone_number" #phone="ngModel" required />
            <div *ngIf="f.submitted && !phone.valid" class="help-block">Phone Number is required</div>
          </div>
          <div class="row">
            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
              <div class="form-group mb-1" [ngClass]="{ 'has-error' : f.submitted && !monthly_account_number.valid}">
                <label class="control-label" for="monthly_account_number">Monthly Account Number</label>
                <input class="form-control" maxlength=100 type="text" (keypress)="restrictNumeric($event)" name="monthly_account_number"
                  [(ngModel)]="formData.monthly_account_number" #monthly_account_number="ngModel" />
              </div>
            </div>

          </div>
          <div class="row">
            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
              <div class="form-group mb-1" [ngClass]="{ 'has-error' : f.submitted && !reservation_number.valid}">
                <label class="control-label" for="reservation_number">Reservation Number</label>
                <input class="form-control" maxlength=100 type="text" (keypress)="restrictNumericDecimal($event)" name="reservation_number"
                  [(ngModel)]="formData.reservation_number" #reservation_number="ngModel" />
              </div>
            </div>
            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
              <div class="form-group mb-1" [ngClass]="{ 'has-error' : f.submitted && !drive_up_ticket.valid}">
                <label class="control-label" for="drive_up_ticket">Drive-up Ticket Number </label>
                <input class="form-control" maxlength=100 type="text" name="drive_up_ticket" [(ngModel)]="formData.drive_up_ticket"
                  #drive_up_ticket="ngModel" />
              </div>

              <!-- <div class="form-group mb-1 focused" [ngClass]="{ 'has-error' : f.submitted && !daily_or_monthly.valid}">
                <span style="margin-top: -12px;
                display: block;
                margin-bottom: -5px;
                font-size: 11px;
                font-weight: 600;"
                  for="daily_or_monthly">Category</span>
                <select class="form-control" title="daily_or_monthly" name="daily_or_monthly" [(ngModel)]="formData.daily_or_monthly"
                  #daily_or_monthly="ngModel" required>
                  <option value="Daily_Reservation">Daily Reservation</option>
                  <option value="Monthly_Account">Monthly Account</option>
                </select>
              </div> -->
            </div>
          </div>
          <div class="row">
            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
              <div class="form-group mb-1" [ngClass]="{ 'has-error' : f.submitted && !FacilityName.valid}">
                <label class="control-label" for="FacilityName">Facility Name </label>
                <input class="form-control" maxlength=100 type="text" name="FacilityName" [(ngModel)]="formData.FacilityName"
                  #FacilityName="ngModel" />
              </div>
            </div>
            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
              <div class="form-group mb-1 focused" [ngClass]="{ 'has-error' : f.submitted && !incidentdate.valid}">
                <span style="margin-top: -12px;
                  display: block;
                  margin-bottom: -5px;
                  font-size: 11px;
                  font-weight: 600;"
                  for="incidentdate">Incident Date</span>
                <input readonly class="form-control" type="text" #dp="bsDatepicker" name="incidentdate" [(ngModel)]="formData.incidentdate"
                  #incidentdate="ngModel" bsDatepicker [(bsValue)]="formData.incidentdate" />
              </div>
            </div>

            <!-- <input  [attr.disabled]="ispartner ? '' : null" readonly class="dropdown-toggle" style="width: 50px;" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false" type="text" class="timeFrame dateTriggers" id="startdate" name="startdate" [minDate]="minDate" [(ngModel)]='startDuration' [maxDate]="maxDate"
                            #dp="bsDatepicker" [bsConfig]="{ dateInputFormat: 'MMM D' }" (ngModelChange)="updateStartDuration()" bsDatepicker [(bsValue)]="startDuration">
                            <input *ngIf="isDedicatedFacility" type="hidden" name="sDate" value="{{startDuration}}" id="sdateDaily"> -->
          </div>

          <!-- <div class="row">
                <div class="col-xs-12 col-sm-8 col-md-8 col-lg-8">
                  <div class="form-group mb-1" [ngClass]="{ 'has-error' : f.submitted && !city.valid}">
                    <label class="control-label" for="city">City</label>
                    <input class="form-control" maxlength=100 type="text" name="city" [(ngModel)]="formData.city" #city="ngModel"  />
                    <div *ngIf="f.submitted && !city.valid" class="help-block">City is required</div>
                  </div>
                </div>
                <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                  <div class="form-group mb-1" [ngClass]="{ 'has-error' : f.submitted && !state.valid}">
                   <label class="control-label" for="state">State</label>
                     <select class=" form-control" title="State" name="state" [(ngModel)]="formData.state" #state="ngModel" required>
                  		  <option value="0" [selected]="0">Please select a state ...</option>
						            <option value="NY">New York</option>
                        <option value="NY">Times Squares</option>
                    </select> 
                    <input class="form-control" type="text" title="State" name="state" (keydown)="restDigit($event)" minlength="2" maxlength="2" [(ngModel)]="formData.state" #state="ngModel" >
                      <div *ngIf="f.submitted && !state.valid" class="help-block">State is required</div>
                  </div>
                </div>
              </div> -->
        </div>
      </div>
      <!-- </div> -->
      <div class="row">
        <div class="col-xs-12 col-sm-8 col-md-8 col-lg-8 contact-pad-r">
          <div class="form-group mb-1" [ngClass]="{ 'has-error' : f.submitted && !Reviewcomments.valid}">
            <label class="control-label">Issue Details and Comments*</label>
            <textarea style="padding-top: 11px;" [(ngModel)]="formData.Reviewcomments" #Reviewcomments="ngModel" name="Reviewcomments"
              id="Reviewcomments" class="form-control pad-top-51" maxlength=1000 rows="7" required></textarea>
            <div *ngIf="f.submitted && !Reviewcomments.valid" class="help-block">Issue details and comments is required</div>
          </div><br>
          <p [innerHTML]="contactDetails?.contact_form_terms"></p>
          <!-- <p>Please note, response times may take more than thirty days. We are currently working 
          <br class="hidden-xs">through a backlog of requests and appreciate your patience and understanding while we continue<br class="hidden-xs"> to navigate through these unprecedented times together. Please visit our <a class="faqBtnRedirect">FAQ section</a> for answers to many <br class="hidden-xs">frequently asked questions.</p> -->
        </div>
        <div class="col-xs-12 col-sm-8 col-md-8 col-lg-8">
          <div class="form-group mb-1 mar-bottom-0 pad-top-xs contact-btn-center">
            <button type="submit" class="btn btn-primary theme-btn px-5">Submit</button>
          </div>
        </div>
      </div>

      <!-- </div> -->
    </form>

  </div>
</div>
<app-footer></app-footer>