<div role="tabpanel" class="tab-pane fade active in" id="billinginformation">
  <h4 class="text-primary text-uppercase mar-top-0">Billing Address</h4>
  <form action="" method="POST" role="form" id="billingForm" class="floating-label" (ngSubmit)="f.form.valid && updateBillingInformation(formData, f)" #f="ngForm" novalidate>
   <div class="row" *ngIf="showName && formData.name != ''">
    <div class="col-sm-6">
     <div class="form-group focused">
      <label class="control-label"> Name</label>
      <p class="clearfix pad-top-xs">{{formData.name}}</p>
      <!-- <input type="text" class="form-control hidden" name="name" id="" [(ngModel)]="formData.name" #name="ngModel" readonly disabled="disabled"> -->
      <!-- <div *ngIf="f.submitted && !name.valid" class="help-block">Name field is required</div> -->
     </div>
    </div>
   </div>
   <div class="row">
      <div class="col-sm-6">
       <div class="form-group">
        <label class="control-label">Company Information</label>
        <input type="text" class="form-control hidden" name="name" id="" [(ngModel)]="formData.name" #name="ngModel" readonly disabled="disabled">
        <input type="text" class="form-control" name="company_name" id="" value="" [(ngModel)]="formData.company_name" #company_name="ngModel">
       </div>
      </div>
   </div>
   <div *ngIf="showFields" class="row mar-top-xs">
    <div class="col-sm-3">
     <div class="form-group focused" [ngClass]="{ 'has-error' : f.submitted && !address_type.valid }">
      <label class="control-label">Address Type</label>
      <select name="" id="input" name="address_type" class="form-control" required="required" [(ngModel)]="formData.address_type" #address_type="ngModel" required>
          <option value="business" selected>Business</option>
          <option value="home">Home</option>
          <option value="other">Other</option>
      </select>
      <div *ngIf="f.submitted && !address_type.valid" class="help-block">Address type field is required</div>
     </div>
    </div>
   </div>
   <div class="row">
    <div class="col-sx-12" [ngClass]="{'col-sm-9': !showFields, 'col-sm-12': showFields}">
     <div class="form-group" [ngClass]="{ 'has-error' : f.submitted && !address_one.valid }">
      <label class="control-label">Address </label>
      <input type="text" class="form-control" name="address_one" id="" [(ngModel)]="formData.address_one" #address_one="ngModel" required>
      <div *ngIf="f.submitted && !address_one.valid" class="help-block">Address field is required</div>
     </div>
    </div>
  </div>
  <!-- <div class="row">
    <div class="col-sm-12">
     <div class="form-group">
      <label class="control-label">Address Line 2 (optional)</label>
      <input type="text" class="form-control" name="address_two" id="" [(ngModel)]="formData.address_two" #address_two="ngModel">
      <div *ngIf="f.submitted && !address_two.valid" class="help-block">Address Line 2 field is required</div>
     </div>
    </div>
   </div> -->
   <div class="row">
    <div class="col-sm-3">
     <div class="form-group" [ngClass]="{ 'has-error' : f.submitted && !city.valid }">
      <label class="control-label">City</label>
      <input type="text" class="form-control" name="city" id="" [(ngModel)]="formData.city" #city="ngModel" required>
      <div *ngIf="f.submitted && !city.valid" class="help-block">City field is required</div>
     </div>
    </div>
    <div class="col-sm-3">
     <div class="form-group" [ngClass]="{ 'has-error' : f.submitted && !state.valid }">
      <label class="control-label">State</label>
      <input type="text" class="form-control" (keydown)="restDigit($event)" minlength="2" maxlength="2" name="state" id="" [(ngModel)]="formData.state" #state="ngModel" required>
      <div *ngIf="f.submitted && !state.valid" class="help-block">State field is required</div>
     </div>
    </div>
    <div class="col-sm-3">
     <div class="form-group" [ngClass]="{ 'has-error' : f.submitted && !zip.valid }">
      <label class="control-label">Zip Code</label>
      <input type="text" class="form-control" name="zip" id="" minlength="5" maxlength="6" [(ngModel)]="formData.zip" #zip="ngModel" required>
      <div *ngIf="f.submitted && !zip.valid" class="help-block">Zip Code field is required</div>
     </div>
    </div>
    <div *ngIf="showFields" class="col-sm-3">
     <div class="form-group" [ngClass]="{ 'has-error' : f.submitted && !country.valid }">
      <label class="control-label">Country</label>
      <input type="text" class="form-control" name="country" id="" [(ngModel)]="formData.country" #country="ngModel" [required]="showFields">
      <div *ngIf="f.submitted && !country.valid" class="help-block">Country field is required</div>
     </div>
    </div>
   </div>
   <div class="clearfix">
    <hr>
   </div>
   <h4 class="text-primary text-uppercase">Contact</h4>
   <div class="row mar-top-xs">
    <div class="col-sm-3">
     <div class="form-group" [ngClass]="{ 'focused' : formData.phone_type_one !='' }" [ngClass]="{ 'has-error' : f.submitted && !phone_type_one.valid }">
      <label class="control-label">Phone Type</label>
      <select name="" id="input" name="phone_type_one" class="form-control" [(ngModel)]="formData.phone_type_one" #phone_type_one="ngModel">
        <option value="none">None</option>
        <option value="home" selected>Home</option>
        <option value="business">Business</option>
        <option value="cell">Cell</option>
        <option value="fax">Fax</option>
        <option value="Beeper/Pager">Beeper/Pager</option>
        <option value="other">Other</option>
      </select>
      <div *ngIf="f.submitted && !phone_type_one.valid" class="help-block">Phone Type field is required</div>
     </div>
    </div>
    <div class="col-sm-3">
      <div class="form-group">
       <label class="control-label">Phone Number</label>
       <input [textMask]="{mask: phoneMask}" type="text" class="form-control" name="phone_number_one" id="" [(ngModel)]="formData.phone_number_one" #phone_number_one="ngModel">
      </div>
     </div>
     <div class="col-sm-3">
      <div class="form-group">
       <label class="control-label">Extension</label>
       <input type="text" class="form-control" name="phone_extn_one" id="" [(ngModel)]="formData.phone_ext_one" #phone_ext_one="ngModel">
      </div>
     </div>
   </div>
   <div class="row" id="contactPhone">
    <!-- <div class="col-sm-5">
     <div class="form-group">
      <label class="control-label">Phone Number</label>
      <input [textMask]="{mask: phoneMask}" type="text" class="form-control" name="phone_number_one" id="" [(ngModel)]="formData.phone_number_one" #phone_number_one="ngModel">
     </div>
    </div>
    <div class="col-sm-3">
     <div class="form-group">
      <label class="control-label">Extension</label>
      <input type="text" class="form-control" name="phone_extn_one" id="" [(ngModel)]="formData.phone_ext_one" #phone_ext_one="ngModel">
     </div>
    </div> -->
    <div *ngIf="showFields" class="col-sm-4">
     <div class="form-group">
      <label class="control-label">Contact Person</label>
      <input type="text" class="form-control" name="phone_contact_one" id="" [(ngModel)]="formData.phone_contact_one" #phone_contact_one="ngModel">
     </div>
    </div>
   </div>
 <!--   <button type="button" class="btn btn-link addSecondryButton" (click)="showSecondryPhone()">Add Secondary Number&nbsp;&nbsp;<i class="fa fa-plus"></i></button> -->
<!--    <div *ngIf="secondryPhone">
    <div class="row mar-top-xs">
      <div class="col-sm-3">
       <div class="form-group">
        <label class="control-label">Phone Type</label>
        <select name="" id="input" name="phone_type_two" class="form-control" [(ngModel)]="formData.phone_type_two" #phone_type_two="ngModel">
          <option value="none" selected>None</option>
          <option value="home">Home</option>
          <option value="business">Business</option>
          <option value="cell">Cell</option>
          <option value="fax">Fax</option>
          <option value="Beeper/Pager">Beeper/Pager</option>
          <option value="other">Other</option>
        </select>
       </div>
      </div>
     </div>
     <div class="row" id="contactPhone2">
      <div class="col-sm-5">
       <div class="form-group">
        <label class="control-label">Phone Number</label>
        <input [textMask]="{mask: phoneMask}" type="text" class="form-control" name="phone_number_two" id="" [(ngModel)]="formData.phone_number_two" #phone_number_two="ngModel">
       </div>
      </div>
      <div class="col-sm-3">
       <div class="form-group">
        <label class="control-label">Extension</label>
        <input type="text" class="form-control" name="phone_extn_two" id="" [(ngModel)]="formData.phone_ext_two" #phone_ext_two="ngModel">
       </div>
      </div>
      <div class="col-sm-4">
       <div class="form-group">
        <label class="control-label">Contact Person</label>
        <input type="text" class="form-control" name="phone_contact_two" id="" [(ngModel)]="formData.phone_contact_two" #phone_contact_two="ngModel">
       </div>
      </div>
     </div>
   </div> -->
   <div class="clearfix">
    <hr>
   </div>
   <h4 class="text-primary text-uppercase">Preferred Billing Method</h4>
   <div class="row" id="contactEmail">
    <div class="col-sm-4">
     <div class="form-group focused" [ngClass]="{ 'has-error' : f.submitted && !billing_method.valid }">
      <label class="control-label">Billing Method</label>
      <select name="" id="input" class="form-control" name="billing_method" [(ngModel)]="formData.billing_method" #billing_method="ngModel" required>
       <option value="mail" selected>Mail</option>
       <option value="email" selected>Email</option>
       <option value="both" selected>Mail and Email</option>
      </select>
      <div *ngIf="f.submitted && !billing_method.valid" class="help-block">Billing Method field is required</div>
     </div>
    </div>
   </div>
   <div class="form-group">
    <button type="submit" class="btn btn-primary theme-btn btn-fill">Save Information</button>
   </div>
  </form>
 </div>