import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { StaticpagesService } from '../../services/staticpages.service';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { CustomCode } from '../../shared/CustomCode';
import { WebMetaService } from '../../shared/web-meta.service';
import { Meta, MetaDefinition } from '@angular/platform-browser';

@Component({
  selector: 'app-september-offer-landing',
  templateUrl: './september-offer-landing.component.html',
  encapsulation: ViewEncapsulation.None,
  providers: [StaticpagesService, WebMetaService],

})
export class SeptemberOfferLandingComponent implements OnInit {
  ogDescription:any;
  ogTitle:any;
  ogUrl:any;
  pageId:string = 'things-to-do'; 
  cmsPageContent:any = {};
  sanatizeContent;
  isLoaded = false;
  baseurl = CustomCode.BASEURL;
  webdata:any;
  descriptionElement:any;
  robotselement:any;
  path = window.location.pathname;
  metaTags: any;
  constructor( private staticPageObj: StaticpagesService, private sanitized: DomSanitizer, private router:Router, private webMetaServiceObj: WebMetaService, private metaService: Meta ) {

   }


  ngOnInit() {
let url = '';

if(this.router.url.includes('juneteenth_LetterfromourCEO')){
  url = 'june-teenth-letter-from-our-ceo';
    }
    else if(this.router.url.includes('Winning-Ticket')){
      url = 'winning-page';
    }
    else if(this.router.url.includes('spin-to-win')){
      url = 'spin-to-win';
    }
    else if(this.router.url.includes('spintowin-termsconditions')){
      url = '032023-spintowin-termsconditions';
    }
    else if(this.router.url.includes('monthly-request-my-vehicle')){
      url = 'monthly-request-my-vehicle';
    }
    else if(this.router.url.includes('Monthly-Request-My-Vehicle')){
      url = 'monthly-request-my-vehicle';
    }
    else if(this.router.url.includes('MONTHLY-REQUEST-MY-VEHICLE')){
      url = 'monthly-request-my-vehicle';
    }
    else{
      url = this.pageId;
    }
    this.staticPageObj.getCmsPageContent(url).subscribe(
      (data:any)=> {
        this.cmsPageContent = data.result[0];
        this.sanatizeContent = this.sanitized.bypassSecurityTrustHtml(this.cmsPageContent.post_content);
        this.isLoaded = true;
        
      }, error => console.log("e" + error.object),
      
    )

    
    this.metaTags = Array.from(document.getElementsByTagName('meta'));
  this.descriptionElement = this.getMetaElement('description');
  this.ogDescription = this.getMetaElement1('og:description');
  this.ogTitle = this.getMetaElement1('og:title');
  this.ogUrl = this.getMetaElement1('og:url');
  this.robotselement = this.getMetaElement('robots');
  this.updatePageMeta();
  }

  getRouteforBookBtn(event){
    let routeUrlDefault = event.target.getAttribute('routerlink');
    let routeUrl = '';
   if( (routeUrlDefault && routeUrlDefault!='') && routeUrlDefault.indexOf(this.baseurl) > -1){
      routeUrl = routeUrlDefault.replace(this.baseurl, '');
    }else {
      routeUrl = routeUrlDefault;
    }
    if(routeUrl && routeUrl!='' ){
        if(routeUrl.indexOf('?') > -1){
          let obj = {};
            let routeUrlNew = routeUrl.split('?');
            if(routeUrlNew.length > 0){
                let url = routeUrlNew[0];
                let routeUrlNew1 = routeUrlNew[1];
                //console.log(routeUrlNew1);
                if(routeUrlNew1.indexOf('=') >-1 && !(routeUrlNew1.indexOf('&') >-1) ){
                  let regex = new RegExp('=', 'g');
                  let a = routeUrlNew1.replace(regex,':');
                  let tup = a.split(':');
                  obj[tup[0]] = tup[1];
                  this.router.navigate([url], {queryParams: obj} );
                }
                else if(routeUrlNew1.indexOf('=') >-1 && routeUrlNew1.indexOf('&') >-1 ) {
                  let regex = new RegExp('=', 'g');
                  let regex1 = new RegExp('&', 'g');
                  let a = routeUrlNew1.replace(regex,':');
                  let b = a.replace(regex1,',');
                  let properties = b.split(',');
                  let obj = {};
                  properties.forEach(function(property) {
                    let tup = property.split(':');
                      obj[tup[0]] = tup[1];
                  });
                  this.router.navigate([url], {queryParams: obj} );
                }else {
                  window.location.href=routeUrl;
                }
            }
            else {
                this.router.navigate([routeUrl])
            }
      }
      else {
        this.router.navigate([routeUrl])
      }

    } 

  }

  getMetaElement(name){
    return this.metaTags.find((element) => { return element.getAttribute('name') === name; });
  }
  getMetaElement1(name){
    return this.metaTags.find((element) => { return element.getAttribute('property') === name; });
  }
  updatePageMeta() {
    this.webdata = this.webMetaServiceObj.getSlugMeta(this.path).then((data:any)=> {
      document.title = data['data']['meta']['title'];
      this.descriptionElement.setAttribute('content', data['data']['meta']['description']);
      this.ogTitle.setAttribute('content', data['data']['meta']['ogTitle']);
      this.ogDescription.setAttribute('content', data['data']['meta']['ogDescription']);
      this.ogUrl.setAttribute('content', window.location.href)
      if(this.router.url.includes('Winning-Ticket')){
        this.metaService.addTag({ name: 'robots', content: 'noindex' });
        this.robotselement.setAttribute('content', 'noindex');
      }
      if(this.router.url.includes('032023-spintowin-termsconditions')){
        //this.metaService.addTag({ name: 'robots', content: 'noindex' });
        //this.robotselement.setAttribute('content', 'noindex');
      }
      if(this.router.url.includes('spin-to-win')){
        let keyword = this.getMetaElement('keyword');
        keyword.setAttribute('content', 'Icon Parking, Monthly Parking NYC, Daily Parking, Parking near me, best parking in New York City, Icon Parking Service & Safe Parking Excellence');
        this.ogTitle.setAttribute('content', 'Parking NYC | Experience Icon Parking | Play and Spin to Win Daily Prizes + Enter to Win Free Parking for a Year');
        this.ogDescription.setAttribute('content', 'Win Daily+ Monthly Parking Prizes Daily + Win FREE New York City Parking for a Year at Icon Parking locations. Parking is Just the Start!');
        this.ogUrl.setAttribute('content', window.location.href)
      }
      if(this.router.url.includes('monthly-request-my-vehicle') || this.router.url.includes('Monthly-Request-My-Vehicle') || this.router.url.includes('MONTHLY-REQUEST-MY-VEHICLE')){
        let keyword = this.getMetaElement('keyword');
        keyword.setAttribute('content', 'Iconparking.com, Icon Parking NYC, Icon Parking Monthly Parking');
        
      }
    });
  }

}
