<app-header></app-header>
<!-- ev charging Section -->

<div class="page-heade orangeBar" id="charging">
	<img [src]="evimg" class="w-100"/>

</div>
<div class="evPage">
	<div class="charging mar-top-md mar-bottom-md">
		<div class="container">

				 <app-lazy-load [isLoaded]="isLoaded" [sectionName]></app-lazy-load> 

			<div *ngIf="isLoaded" class="page-content" [innerHTML] ="getPageContent.post_content"></div>
		</div>
	</div>
	<div class="findCharging pad-top-md pad-bottom-md mar-bottom-md">
		<div class="findChargingDecor"></div>
		<div class="container">
			<div class="text-wrap">
				<h2 class="text-white text-uppercase mar-bottom-sm mar-top-0">
					{{getPageContent.garages_with_ev_charging}}
				</h2>
				<div class="clearfix">
					<a (click)="evList()" class="btn btn-primary scrollTop theme-btn btn-fill text-white">Find your spot</a>
				</div>
			</div>
		</div>
	</div>
</div>
<div class="evList hidden mar-bottom-md">
	<div class="container">
		<ol class="breadcrumb pad-top-xs pad-bottom-xs mar-bottom-0 pad-left-0">
			<li class="active"><a (click)="evPage()"  class="btn btn-link pad-left-0 scrollTop"><fa name="angle-left"></fa> &nbsp; Back</a></li>
		</ol>
		<div class="clearfix"></div>
		<div class="row">
			<div class="col-xs-12 col-sm-3 evMap-wrap pull-right hidden-xs">
				<div id="test1" class="wrapperMapEv" >
					
						<h4 class="mar-top-0 mar-bottom-sm text-primary text-uppercase">Select your neighborhood:</h4>
						<div class="neighborhoodSVG evMapSVG">
							<svg data-name="map areas" xmlns="http://www.w3.org/2000/svg" width="400" height="790" viewBox="0 0 400 790" onClick={this.onClick}>
								<!-- <title>IconQuik_Coupons_Map</title> -->
								<g class="geo" id="morning-side-heights" (click)="reloadList('morning-side-heights')">
									<rect class="cls-1" [ngClass]="{'cls-2': activeNeighbourhood=='morning-side-heights'}" x="3.25" y="61.27" width="70.5" height="51.5" />
									<text class="cls-3" textAnchor="start" x="7.5" y="78.02">MORNING-</text>
									<text class="cls-3" textAnchor="start" x="7.5" y="92.42">SIDE</text>
									<text class="cls-3" textAnchor="start" x="7.5" y="106.82">HEIGHTS</text>
								</g>
								<g class="geo" id="upper-west-side" (click)="reloadList('upper-west-side')">
									<rect  class="cls-1" [ngClass]="{'cls-2': activeNeighbourhood=='upper-west-side'}" x="2.5" y="117.75" width="71" height="88.77" />
									<text id="upper-west-side" class="cls-3" textAnchor="start" x="11.5" y="153.02">UPPER</text>
									<text id="upper-west-side" class="cls-3" textAnchor="start" x="11.5" y="167.42">WEST</text>
									<text id="upper-west-side" class="cls-3" textAnchor="start" x="11.5" y="181.82">SIDE</text>
								</g>
								<g class="geo" id="lincoln-square" (click)="reloadList('lincoln-square')">
									<rect class="cls-1" [ngClass]="{'cls-2': activeNeighbourhood=='lincoln-square'}" x="2.07" y="210.59" width="71.43" height="66.93" />
									<text id="lincoln-square" class="cls-3" textAnchor="start" x="11.5" y="242.02">LINCOLN</text>
									<text id="lincoln-square" class="cls-3" textAnchor="start" x="11.5" y="256.42">SQUARE</text>
								</g>
								<g class="geo" id="midtown-west" (click)="reloadList('midtown-west')">
									<rect class="cls-1" [ngClass]="{'cls-2': activeNeighbourhood=='midtown-west'}" x="2.07" y="281.52" width="118.93" height="45" />
									<text class="cls-3" textAnchor="start" x="11.5" y="311.02">MIDTOWN</text>
									<text class="cls-3" textAnchor="start" x="73.5" y="311.02">WEST</text>
								</g>
								<g class="geo" id="times-square" (click)="reloadList('times-square')">
									<rect class="cls-1" [ngClass]="{'cls-2': activeNeighbourhood=='times-square'}" x="2.07" y="331.52" width="118.93" height="36.5" />
									<text class="cls-3" textAnchor="start" x="11.5" y="355.02">TIMES SQUARE</text>
								</g>
								<g class="geo" id="fashion-center" (click)="reloadList('fashion-center')">
									<rect class="cls-1" [ngClass]="{'cls-2': activeNeighbourhood=='fashion-center'}" x="1.5" y="372.02" width="119.5" height="33" />
									<text class="cls-3" textAnchor="start" x="7.5" y="394.02">F</text>
									<text class="cls-3" textAnchor="start" x="14.17" y="394.02">ASHION CENTER</text>
								</g>
								<g class="geo" id="chelsea" (click)="reloadList('chelsea')">
									<polygon class="cls-1" [ngClass]="{'cls-2': activeNeighbourhood=='chelsea'}" points="121 502.52 16 502.52 0 442.52 0 409.52 121 409.52 121 502.52" />
									<text class="cls-3" textAnchor="start" x="34.5" y="463.02">CHELSEA</text>
								</g>
								<g class="geo" id="west-village" (click)="reloadList('west-village')">
									<polygon class="cls-1" [ngClass]="{'cls-2': activeNeighbourhood=='west-village'}" points="43.51 573.69 80 563.02 79.5 506.52 17 506.52 43.51 573.69" />
									<text class="cls-3" textAnchor="start" x="29.5" y="523.02">WEST</text>
									<text class="cls-3" textAnchor="start" x="29.5" y="537.42">VILLAGE</text>
								</g>
								<g class="geo" id="greenwich-village" (click)="reloadList('greenwich-village')">
									<rect class="cls-1" [ngClass]="{'cls-2': activeNeighbourhood=='greenwich-village'}" x="85" y="506.52" width="52.5" height="56.5" />
									<text class="cls-3" textAnchor="start" x="87.5" y="526.02">GREEN-</text>
									<text class="cls-3" textAnchor="start" x="87.5" y="540.42">WICH</text>
									<text class="cls-3" textAnchor="start" x="87.5" y="554.82">VILLAGE</text>
								</g>
								<g class="geo" id="east-village" (click)="reloadList('east-village')">
									<polygon class="cls-1" [ngClass]="{'cls-2': activeNeighbourhood=='east-village'}" points="142 563.02 284 563.02 271.5 506.52 142 506.52 142 563.02" />
									<text class="cls-3" textAnchor="start" x="178.5" y="533.02">EAST</text>
									<text class="cls-3" textAnchor="start" x="178.5" y="547.42">VILLAGE</text>
								</g>
								<g class="geo" id="soho" (click)="reloadList('soho')">
									<polygon class="cls-1" [ngClass]="{'cls-2': activeNeighbourhood=='soho'}" points="55 615.02 166.5 615.02 166.5 567.52 85 567.52 45 578.52 55 615.02" />
									<text class="cls-3" textAnchor="start" x="89.5" y="597.02">SOHO</text>
								</g>
								<g class="geo" id="lower-east-side" (click)="reloadList('lower-east-side')">
									<polygon class="cls-1" [ngClass]="{'cls-2': activeNeighbourhood=='lower-east-side'}" points="294 615.02 170.5 615.02 170.5 567.52 284.5 567.52 294 615.02" />
									<text class="cls-3" textAnchor="start" x="195.5" y="590.02">LOWER</text>
									<text class="cls-3" textAnchor="start" x="195.5" y="604.42">EAST</text>
									<text class="cls-3 cls-4" textAnchor="start" x="226.63" y="604.42"> SIDE</text>
								</g>
								<g class="geo" id="tribeca" (click)="reloadList('tribeca')">
									<polygon class="cls-1" [ngClass]="{'cls-2': activeNeighbourhood=='tribeca'}" points="79.5 713.02 131.5 713.52 131.5 619.52 57 619.52 77 679.02 79.5 713.02" />
									<text class="cls-3" textAnchor="start" x="75.5" y="666.02">TRIBECA</text>
								</g>
								<g class="geo" id="chinatown" (click)="reloadList('chinatown')">
									<polygon class="cls-1" [ngClass]="{'cls-2': activeNeighbourhood=='chinatown'}" points="137 649.02 201.47 667.51 212 642.02 212.5 620.02 137.5 619.52 137 649.02" />
									<text class="cls-3" textAnchor="start" x="138.5" y="644.02">CHINATOWN</text>
								</g>
								<g class="geo" id="civic-center" (click)="reloadList('civic-center')">
									<polygon class="cls-1" [ngClass]="{'cls-2': activeNeighbourhood=='civic-center'}" points="182 713.52 137.5 713.52 137.5 654.52 199 673.52 182 713.52" />
									<text class="cls-3" textAnchor="start" x="139.5" y="678.02">CIVIC</text>
									<text class="cls-3" textAnchor="start" x="139.5" y="692.42">CENTER</text>
								</g>
								<g class="geo" id="two-bridges" (click)="reloadList('two-bridges')">
									<path class="cls-1" [ngClass]="{'cls-2': activeNeighbourhood=='two-bridges'}" d="M221.5,713.52h-35L216.95,646l0.55-26.48h77s5,10-3,20.5C285.61,647.75,221.5,713.52,221.5,713.52Z" />
									<text class="cls-3" textAnchor="start" x="223.5" y="638.02">TWO</text>
									<text class="cls-3" textAnchor="start" x="223.5" y="652.42">BRIDGES</text>
								</g>
								<g class="geo" id="financial-district" (click)="reloadList('financial-district')">
									<path class="cls-1" [ngClass]="{'cls-2': activeNeighbourhood=='financial-district'}" d="M149.5,783.52c-25.29,13.76-59-31.5-59-31.5l-11-33.5,139-.5a186,186,0,0,1-15,23C192.57,755.25,171.2,771.71,149.5,783.52Z" />
									<path class="cls-1" [ngClass]="{'cls-2': activeNeighbourhood=='financial-district'}" d="M84,751.52s-5,1.5-8.5-1-12.5-42-11-46.5,4-2.5,4-2.5Z" />
									<text class="cls-3" textAnchor="start" x="112.5" y="746.02">FINANCIAL</text>
									<text class="cls-3" textAnchor="start" x="112.5" y="760.42">DISTRICT</text>
								</g>
								<g class="geo" id="gramercy" (click)="reloadList('gramercy')">
									<rect class="cls-1" [ngClass]="{'cls-2': activeNeighbourhood=='gramercy'}" x="126.5" y="444.52" width="102.5" height="57.5" />
									<text class="cls-3" textAnchor="start" x="141.5" y="479.02">GRAMERCY</text>
								</g>
								<g class="geo" id="murray-hill" (click)="reloadList('murray-hill')">
									<polygon class="cls-1" [ngClass]="{'cls-2': activeNeighbourhood=='murray-hill'}" points="191 440.02 126.5 440.02 126.5 356.52 213 356.52 218.5 394.02 191 394.02 191 440.02" />
									<text class="cls-3" textAnchor="start" x="141.5" y="394.02">MURRAY</text>
									<text class="cls-3" textAnchor="start" x="141.5" y="408.42">HIL</text>
									<text class="cls-3" textAnchor="start" x="160.17" y="408.42">L</text>
								</g>
								<g class="geo" id="kips-bay" (click)="reloadList('kips-bay')">
									<polygon class="cls-1" [ngClass]="{'cls-2': activeNeighbourhood=='kips-bay'}" points="195 439.52 228 439.52 218.5 397.52 195 397.52 195 439.52" />
									<text class="cls-3" textAnchor="start" x="246.5" y="413.02">KIPS</text>
									<text class="cls-3   cls-4" textAnchor="start" x="246.5" y="427.42"> B</text>
									<text class="cls-3" textAnchor="start" x="257.84" y="427.42">A</text>
									<text class="cls-3" textAnchor="start" x="264.95" y="427.42">Y</text>
									<line class="cls-6" x1="205.5" y1="418.02" x2="237.46" y2="418.45" />
									<circle class="cls-7" cx="237.27" cy="418.45" r="3.76" transform="translate(-184.37 650) rotate(-89.22)" />
								</g>
								<g class="geo" id="midtown-east" (click)="reloadList('midtown-east')">
									<polygon class="cls-1" [ngClass]="{'cls-2': activeNeighbourhood=='midtown-east'}" points="213 351.02 126.5 351.02 126.5 281.52 221.5 281.52 213 351.02" />
									<text class="cls-3" textAnchor="start" x="141.5" y="313.02">MIDTOWN</text>
									<text class="cls-3" textAnchor="start" x="141.5" y="327.42">EAST</text>
								</g>
								<g class="geo" id="east-60s" (click)="reloadList('east-60s')">
									<polygon class="cls-1" [ngClass]="{'cls-2': activeNeighbourhood=='east-60s'}" points="221.5 277.52 126.5 277.52 126.5 249.02 224.75 249.77 221.5 277.52" />
									<text class="cls-3" textAnchor="start" x="141.5" y="269.02">EAST</text>
									<text class="cls-3 cls-4" textAnchor="start" x="172.63" y="269.02"> 60s</text>
								</g>
								<g class="geo" id="east-70s" (click)="reloadList('east-70s')">
									<polygon class="cls-1" [ngClass]="{'cls-2': activeNeighbourhood=='east-70s'}" points="226.5 244.52 126.5 244.52 126.5 216.52 232 216.52 226.5 244.52" />
									<text class="cls-3" textAnchor="start" x="141.5" y="235.02">EAST</text>
									<text class="cls-3 cls-4" textAnchor="start" x="172.63" y="235.02"> 70s</text>
								</g>
								<g class="geo" id="east-80s" (click)="reloadList('east-80s')">
									<polygon class="cls-1" [ngClass]="{'cls-2': activeNeighbourhood=='east-80s'}" points="231.5 212.02 126 212.02 126 166.52 234.5 167.01 231.5 212.02" />
									<text class="cls-3" textAnchor="start" x="141.5" y="196.02">EAST</text>
									<text class="cls-3 cls-4" textAnchor="start" x="172.63" y="196.02"> 80s</text>
								</g>
								<g class="geo" id="east-90s" (click)="reloadList('east-90s')">
									<path class="cls-1" [ngClass]="{'cls-2': activeNeighbourhood=='east-90s'}" d="M234,162H126v-49.5h93.5s6,20,10.5,26C237.5,148.52,234,162,234,162Z" />
									<text class="cls-3" textAnchor="start" x="141.5" y="142.02">EAST</text>
									<text class="cls-3 cls-4" textAnchor="start" x="172.63" y="142.02"> 90s</text>
								</g>
								<g class="geo" id="east-harlem" (click)="reloadList('east-harlem')">
									<polygon class="cls-1" [ngClass]="{'cls-2': activeNeighbourhood=='east-harlem'}" points="218.5 107.52 126.5 107.52 126.5 60.52 212.5 60.52 218.5 107.52" />
									<text class="cls-3" textAnchor="start" x="141.6" y="82.02">EAST</text>
									<text class="cls-3" textAnchor="start" x="141.5" y="96.42">HARLEM</text>
								</g>
								<g class="geo" id="stuyvesant" (click)="reloadList('stuyvesant')">
									<path class="cls-1" [ngClass]="{'cls-2': activeNeighbourhood=='stuyvesant'}" d="M269.5,502H233l0.5-53s5,17,17.5,21C262.24,473.61,269.5,502,269.5,502Z" />
									<text class="cls-3" textAnchor="start" x="284.5" y="478.02">STUY</text>
									<text class="cls-3 cls-4" textAnchor="start" x="284.5" y="492.42">VESANT</text>
									<line class="cls-6" x1="243.26" y1="484" x2="279.08" y2="483.95" />
									<circle class="cls-7" cx="278.89" cy="483.95" r="3.76" transform="translate(-0.75 0.43) rotate(-0.09)" />
								</g>
								<g class="geo" id="central-park" >
									  <rect fill="#628349"   x="79.4" y="60.5" width="41.35" height="216.76" />
									<text class="cls-5" textAnchor="start" x="104.73" y="215.47" transform="rotate(-90 104.73 215.47)">CENTRAL</text>
									<text class="cls-5" textAnchor="start" x="163.63" y="215.47" transform="rotate(-90 104.73 215.47)">PARK</text>
								</g>
								<!-- <text class="cls-8" textAnchor="start" x="3.5" y="18.02">Select your neighborhood:</text> -->
							</svg>
						</div>
						</div>
			</div>
			<div class="col-xs-12 col-sm-9 evList-content">
				<p class="mar-bottom-sm">
					Please find Tesla and electronic vehicle charging locations organized by neighborhood for your convenience. We look forward to charging your EV soon!
				</p>
				<div class="dropdown mar-bottom-sm visible-xs selectBox">
					<a class="btn btn-default btn-block dropdown-toggle linkLabel" type="button" data-toggle="dropdown">
						<div id="drp">SELECT YOUR NEIGHBORHOOD</div> <fa name="angle-down"></fa>
					</a>
					<ul style="width:100%;" class="nbhValue dropdown-menu">
						<li class="active viewAll drp"><a href="javascript:;" (click)="reloadList('all')" aria-hidden="true">SELECT YOUR NEIGHBORHOOD</a></li>
						<li class="drp"><a href="javascript:;" (click)="reloadListDrop('morningside-heights')" aria-hidden="true">Morning Side Heights</a></li>
						<li class="drp"><a href="javascript:;" (click)="reloadListDrop('upper-west-side')" aria-hidden="true">Upper West Side</a></li>
						<li class="drp"><a href="javascript:;" (click)="reloadListDrop('lincoln-square')" aria-hidden="true">Lincoln Square</a></li>
						<li class="drp"><a href="javascript:;" (click)="reloadListDrop('central-park')" aria-hidden="true">Central Park</a></li>
						<li class="drp"><a href="javascript:;" (click)="reloadListDrop('east-harlem')" aria-hidden="true">East Harlem</a></li>
						<li class="drp"><a href="javascript:;" (click)="reloadListDrop('east-90s')" aria-hidden="true">East 90s</a></li>
						<li class="drp"><a href="javascript:;" (click)="reloadListDrop('east-80s')" aria-hidden="true">East 80s</a></li>
						<li class="drp"><a href="javascript:;" (click)="reloadListDrop('east-70s')" aria-hidden="true">East 70s</a></li>
						<li class="drp"><a href="javascript:;" (click)="reloadListDrop('east-60s')" aria-hidden="true">East 60s</a></li>
						<li class="drp"><a href="javascript:;" (click)="reloadListDrop('midtown-west')" aria-hidden="true">Midtown West</a></li>
						<li class="drp"><a href="javascript:;" (click)="reloadListDrop('midtown-east')" aria-hidden="true">Midtown East</a></li>
						<li class="drp"><a href="javascript:;" (click)="reloadListDrop('times-square')" aria-hidden="true">Times Square</a></li>
						<li class="drp"><a href="javascript:;" (click)="reloadListDrop('fashion-center')" aria-hidden="true">Fashion Center</a></li>
						<li class="drp"><a href="javascript:;" (click)="reloadListDrop('murray-hill')" aria-hidden="true">Murray Hill</a></li>
						<li class="drp"><a href="javascript:;" (click)="reloadListDrop('kips-bay')" aria-hidden="true">Kips Bay</a></li>
						<li class="drp"><a href="javascript:;" (click)="reloadListDrop('chelsea')" aria-hidden="true">Chelsea</a></li>
						<li class="drp"><a href="javascript:;" (click)="reloadListDrop('gramercy')" aria-hidden="true">Gramercy</a></li>
						<li class="drp"><a href="javascript:;" (click)="reloadListDrop('stuyvesant')" aria-hidden="true">Stuy Vesant</a></li>
						<li class="drp"><a href="javascript:;" (click)="reloadListDrop('west-village')" aria-hidden="true">West Village</a></li>
						<li class="drp"><a href="javascript:;" (click)="reloadListDrop('greenwich-village')" aria-hidden="true">Greenwich Village</a></li>
						<li class="drp"><a href="javascript:;" (click)="reloadListDrop('east-village')" aria-hidden="true">East Village</a></li>
						<li class="drp"><a href="javascript:;" (click)="reloadListDrop('soho')" aria-hidden="true">Soho</a></li>
						<li class="drp"><a href="javascript:;" (click)="reloadListDrop('lower-east-side')" aria-hidden="true">Lower East Side</a></li>
						<li class="drp"><a href="javascript:;" (click)="reloadListDrop('tribeca')" aria-hidden="true">Tribeca</a></li>
						<li class="drp"><a href="javascript:;" (click)="reloadListDrop('chinatown')" aria-hidden="true">China Town</a></li>
						<li class="drp"><a href="javascript:;" (click)="reloadListDrop('civic-center')" aria-hidden="true">Civic Center</a></li>
						<li class="drp"><a href="javascript:;" (click)="reloadListDrop('two-bridges')" aria-hidden="true">Two Bridges</a></li>
						<li class="drp"><a href="javascript:;" (click)="reloadListDrop('financial-district')" aria-hidden="true">Financial District</a></li>
					</ul>
				</div>
				<div id="listScrollSection" class="scrollFix">
				<div *ngFor="let record of evcDeatils; index as i;">
				<h3 id="heading_{{record.slug}}" class="llcShow text-primary text-uppercase mar-top-0 pad-bottom-xs mar-bottom-xs" *ngIf="record?.neighborhood">{{record.neighborhood}}</h3>
				<div id="contnent_{{record.slug}}" class="llcShow row evGarage-wrap" flexboxWrapper>
					<div *ngFor="let records of record.facility;"class="col-xs-12 col-sm-12 col-md-6 col-lg-6" flexChild (click)="redirectToLocation(records.geolocations.latitude,records.geolocations.longitude,records.full_name)">
						<div class="well">
							<h4 class="text-uppercase mar-top-0"><a href="javascript:;">{{records?.facility_name_modified}}</a></h4>
							<p class="entrance">{{records?.entrance_location}}</p>
							<div class="evOptions">
								<div class="row">
									<div *ngIf="records?.is_generic_ev_charging==1" class="col-xs-6">
										<img [src]="ev_charge_icon" alt="image">Universal Connector
									</div>
									<div *ngIf="records?.is_tesla_charging==1" class="col-xs-6">
										<img [src]="tesla_charger_icon" alt="image">Tesla Connector
									</div>
								</div>
							</div>
						</div>
					</div>
					
				</div>
				
			</div>
		</div>
				<p class="pad-bottom-xs">
					If you are not in need of EV charging at this time, you can park at any of our 300 garages below to fulfill your parking needs.
				</p>
				<a *ngIf="findParkingUrl=='web'" (click)="redirectToMonthlyParking()" class="btn btn-primary scrollTop theme-btn">Find Parking</a>
				<a *ngIf="findParkingUrl=='app'" (click)="redirectToMonthlyParking()" class="btn btn-primary scrollTop theme-btn">Find Parking</a>
			</div>
			
		</div>
	</div>
</div>


<app-footer></app-footer>