import { Component, OnInit, AfterViewInit, TemplateRef, ElementRef, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
//import { Http, Headers, RequestOptions, Response } from '@angular/http';

import { Observable } from 'rxjs/Rx';

declare var google: any;
declare var routerObj: Router;

import * as $ from 'jquery';

import { CustomCode } from '../shared/CustomCode';
import { SearchPageScript } from '../shared/searchPageScript';

import { ToastrService } from 'ngx-toastr';

// Import RxJs required methods
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';

import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { HomepageserviceService } from '../services/homepageservice.service';
import { SearchpageserviceService } from '../services/searchpageservice.service';
import { MyAccountMonthlyParkingService } from '../services/my-account-monthly-parking.service';
import {GoogleAnalyticsEventsService} from "../shared/google-analytics-events.service";
import { UserserviceService } from '../services/userservice.service';
import { error } from 'util';
// declare var ga: Function;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  providers: [HomepageserviceService, SearchpageserviceService, MyAccountMonthlyParkingService, UserserviceService]
})

export class HomeComponent implements OnInit, AfterViewInit {
  contactTelNo = CustomCode.contactTelephoneNumber;
  
   POSuserLat=0;
   POSuserLong=0;
   iosDevice:any;
   message :any;
   baseballId = 'home-banner';
   baseballModel:any;
   baseModelStatus = 0;
   baseballContent;
   link_to_redirect;
   isLoaded = false;
   locationTrack = 'assets/img/location-track.svg';
   qp_logo = 'assets/img/qp_logo.svg';
   scroller= 'assets/img/scroller.svg';
   screen_1 = 'assets/img/screen_1.png';
   screen_2 = 'assets/img/screen_2.png';
   screen_3 = 'assets/img/screen_3.png';
   dollar_icon= 'assets/img/dollar_icon.svg';
   dollar_icon_big= 'assets/img/loyalty/icon-rewards-dollar_icon.svg';
   discount_icon= 'assets/img/discount_icon.svg';
   AppStore ='assets/img/AppStore.svg';
   googleplayIcon = 'assets/img/google-play.svg';
   personIcon = '/assets/img/person.png';
   arrowsIcon ='assets/img/arrows.svg';
   carIcon = '/assets/img/car.png';
   map_markers = 'assets/img/map_markers.svg'
   vehicleIcon = 'assets/img/vehicle.svg';
   accountIcon = 'assets/img/account.svg';
   cardIcon = 'assets/img/card.svg';
   crossSvg='assets/img/cross.svg';

   appStoreUrl = 'https://itunes.apple.com/us/app/icon-parking/id394377820?mt=8';
   googlePlayUrl = 'https://play.google.com/store/apps/details?id=com.iconparking&hl=en'
  
  mapProp;
  findValue: String;
  locationDetails: any = {};
  userLocation: any = {};
  options = {};
  responseData: any = {};
  latLongResponse: any = {};
  facilityRadius = 1;
  facilityLabel = 'New York';
  userLoc: any = {};
  initialSearch = {
    label: 'Times Square',
    latitude: 40.7589,
    longitude: -73.9851
  };
  activeUser:any = {};

  newYorkCenter = {
    latitude: 40.729295,
    longitude: -73.999392
  };
  Y;
  currDate = CustomCode.getDateForMapSearch();
  settypedata:any='daily';
  facilityType = CustomCode.defaultFacilityType;
  f;
  iconMessage:number=0;
  iconMessageRandom:number=0;
  public modalRef: BsModalRef;
  homePageBadge = 'homePageBadge';
  @ViewChild('baseballmodelbtn',{ static: true }) baseballmodelbtn: ElementRef;
  @ViewChild('hideBaseballPopup',{ static: true }) hideBaseballPopup: ElementRef;
  // isTierExist:boolean=false;
  constructor(private router: Router,
    private homePageServiceObj: HomepageserviceService,
    private searchPageServObj: SearchpageserviceService,
    private toasterObj: ToastrService,
	  private activatedRouteObj: ActivatedRoute,
    private myAccountMonthlyParkingObj: MyAccountMonthlyParkingService,
    public googleAnalyticsEventsService: GoogleAnalyticsEventsService,
    private modalService: BsModalService,
    private userServiceObj: UserserviceService
  ) {

    this.iosDevice = false;
   }
  ngOnInit() {

        if( navigator.geolocation ) {
           navigator.geolocation.getCurrentPosition(function(position){ 
			    //console.log("Found your location \nLat : "+position.coords.latitude+" \nLang :"+ position.coords.longitude);
          localStorage.setItem('currentLatitude', position.coords.latitude.toString());
          localStorage.setItem('currentLongitude', position.coords.longitude.toString());
		      });
        } else  {  //alert("Sorry, your browser does not support geolocation services."); 
        }

  let random = Array(1,2,3,4);
  let ranValue = random[Math.floor(Math.random()*random.length)]
  if (localStorage.getItem('access_token')) {
    this.iconMessage=1;
    this.iconMessageRandom=ranValue;
    if(localStorage.getItem('is_loyalty')=='1')
    {
      this.iconMessage=0;
    }
  }else{
    this.iconMessage=1;
    this.iconMessageRandom=ranValue;
    
  }

  
    this.activatedRouteObj.queryParams.subscribe(params => {
      if(params['page']!==undefined && params['page']==='icon-rewards'){
        if(localStorage.getItem('access_token')){
          if(localStorage.getItem('is_loyalty')==="1"){ 
            this.router.navigate(['my-account/icon-rewards']);
          }else{
            this.router.navigate(['my-account/account']);
          }
        }else{
          this.router.navigate(['rewards']);
        }
      }
    })
  localStorage.removeItem('searchLocation')
   if (navigator.userAgent.match(/(iPod|iPhone|iPad)/i)) {
   this.iosDevice = false;
  };
  if (navigator.userAgent.match(/Android/i)){
    this.iosDevice = true;
  };
  this.flushLocalStorage();
  document.getElementById('navDesk').removeAttribute('style');
  SearchPageScript.manageHeaderPosition();
  $(window).scroll( () => {
      // mobile animation
      $('.animated').each( function() {
        let elementTop = $(this).offset().top;
        let elementBottom = elementTop + $(this).outerHeight();
        let viewportTop = $(window).scrollTop();
        let viewportBottom = viewportTop + $(window).height();
        if (elementBottom > viewportTop && elementTop < viewportBottom) {
          $(this).addClass('go');
        } else {
          $(this).removeClass('go');
        }
      });
    });

    if (localStorage.getItem('access_token')) {
        // this.isLoggedIn = true;
        this.fetchUserDetails();
    }
	this.activatedRouteObj.params.subscribe((params: Params) => {
	
		//alert(params['userId']);alert(params['encryptCode']);
		if(!params['userId']){

    } else {
      if(params['userId']!='' && params['encryptCode']!=''){
			
			this.homePageServiceObj.userEmailConfirmation(params['userId'], params['encryptCode'])
          .subscribe(
          (data:any)=> {
			  //Thanks for confirming your email!
               //You can now log in using your username and password.
            this.responseData = data;
            this.toasterObj.success('Thanks for confirming your email! You can now log in using your username and password.', 'success!');
			//this.vresetLoginForm(this.f);
			this.router.navigate(['/']);
          },
          error => {
            const errorsList = error.errors;
            if (errorsList != null) {
              if (errorsList.message !== 'Validation Errors') {
                this.toasterObj.error(errorsList.message, 'Sorry!');
              } else {
                for (const key in errorsList.detail) {
                  if (errorsList.detail.hasOwnProperty(key)) {
                    if (typeof errorsList.detail === 'undefined') {
                      this.toasterObj.error(errorsList.message, 'Sorry!');
                    } else {
                      this.toasterObj.error(errorsList.detail[key], 'Sorry!');
                    }
                  }
                }
              }
            }
          }
        );
		}
  }
		
  });
  this.getBaseballModelContent();
  }

  ngAfterViewInit(){
  }
  
  vresetLoginForm(f) {
    
  }

  flushLocalStorage() {
    localStorage.removeItem('recent_search');
    localStorage.removeItem('userLat');
    localStorage.removeItem('userLong');
    localStorage.removeItem('currDate');
    localStorage.removeItem('facilityRadius');
    localStorage.removeItem('locationUser');
    localStorage.removeItem('searchType');
    localStorage.removeItem('searchMode');
  }

  fetchUserDetails() {
    this.myAccountMonthlyParkingObj.fetchUser()
      .then(
      (data:any)=> {
        this.activeUser = data;
        
      },
      error => {
        this.toasterObj.error(error.errors.message, 'Sorry!');
        }
      );
  }

  sendApp() {
    /* commented for ga 16-6-2021 by seo team
    ga('send', 'event', { eventCategory: 'app link', eventAction: 'click'});
    */
    const appText = parseInt((<HTMLInputElement>document.getElementById('sendapp')).value.trim());
    if (appText) {
		const phoneNum = ((<HTMLInputElement>document.getElementById('sendapp'))).value;
    
   
		const text = 'Welcome to Icon Parking. click on the link below Android App https://play.google.com/store/apps/details?id=com.iconparking&hl=en and Iphone App https://itunes.apple.com/us/app/icon-parking/id394377820?mt=8';
      this.homePageServiceObj.messageToMobile(phoneNum, text)
          .subscribe(
          (data:any)=> {
            this.responseData = data;
            this.toasterObj.success('App link sent successfully', 'success!');
          },
          error => {
            const errorsList = error.errors;
            if (errorsList.message !== 'Validation Errors') {
              this.toasterObj.error(errorsList.message, 'Sorry!');
            } else {
              for (const key in errorsList.detail) {
                if (errorsList.detail.hasOwnProperty(key)) {
                  if (typeof errorsList.detail === 'undefined') {
                    this.toasterObj.error(errorsList.message, 'Sorry!');
                  } else {
                    this.toasterObj.error(errorsList.detail[key], 'Sorry!');
                  }
                }
              }
            }
          }
          );
    } else {
      this.toasterObj.error('Number is required', 'Sorry!');
    }
    (<HTMLInputElement>document.getElementById('sendapp')).value = '';
  }

  subscription() {
    const subscribeText = (<HTMLInputElement>document.getElementById('addsubscription')).value.trim();
    if (subscribeText) {
      let data = {};
      if (localStorage.getItem('access_token')) {
        data = {
          "email" : (<HTMLInputElement>document.getElementById('addsubscription')).value.trim(),
          "user_id" : this.activeUser.data.id
        };
      } else {
        data = {
          "email" : (<HTMLInputElement>document.getElementById('addsubscription')).value.trim()
        };
      }
     
      this.homePageServiceObj.subscribeToApplication(data)
        .subscribe(
        (data:any)=> {
          this.responseData = data;
          if (this.responseData.status === 201) {
            this.toasterObj.success('Email subscribed successfully', 'success!');
          } else {
            this.toasterObj.error('This email address is already subscribed to our mailing list', 'Sorry!');
          }
          (<HTMLInputElement>document.getElementById('addsubscription')).value = '';
        },
        error => {
          const errorsList = error.errors;
          if (errorsList.message !== 'Validation Errors') {
            this.toasterObj.error(errorsList.message, 'Sorry!');
          } else {
            for (const key in errorsList.detail) {
              if (errorsList.detail.hasOwnProperty(key)) {
                let errorMessage = "This email address is already subscribed to our mailing list";
                if (typeof errorsList.detail === 'undefined') {
                  if (errorsList.message != "The email has already been taken.") {
                    errorMessage = errorsList.message
                  }
                  // this.toasterObj.error(errorsList.message, 'Sorry!');
                } else {
                  if (errorsList.detail[key] != "The email has already been taken.") {
                    errorMessage = errorsList.detail[key]
                  }
                  // this.toasterObj.error(errorsList.detail[key], 'Sorry!');
                }
                this.toasterObj.error(errorMessage, 'Sorry!');
              }
            }
          }
        }
        );
      (<HTMLInputElement>document.getElementById('addsubscription')).value = '';
    } else {
      this.toasterObj.error('email is required', 'Sorry!');
    }
  }

  setRecentSearch(userLat, userLong, currDate, facilityRadius, locationUser, searchType, searchMode) {
      localStorage.setItem('recent_search', 'true');
      localStorage.setItem('searchType', searchType.toString());
      localStorage.setItem('searchMode', searchMode.toString());
      localStorage.setItem('userLat', userLat.toString());
      localStorage.setItem('userLong', userLong.toString());
      localStorage.setItem('currDate', currDate.toString());
      localStorage.setItem('facilityRadius', facilityRadius.toString());
      localStorage.setItem('locationUser', locationUser.toString());
  }
 settype(type)
 {
   this.settypedata=type;
 }
 findNearMe() {
    const newYorkBounds = new google.maps.LatLngBounds(
        new google.maps.LatLng(40.518673, -74.266017),
        new google.maps.LatLng(40.861947, -73.785519)
    );

     if (window.navigator.geolocation) {
        window.navigator.geolocation.getCurrentPosition(position => {
        let userCLat = position.coords.latitude;
        let userCLong = position.coords.longitude;
		//40.7623737/-73.97391189999996
		 //userCLat = 40.7623737;
         //userCLong = -73.97391189999996;
    var myLatlng = new google.maps.LatLng(userCLat, userCLong);
	//alert(userCLat);alert(userCLong);
    if (newYorkBounds.contains(myLatlng)) {//alert('test2');
		//alert(userCLat);alert(userCLong);
		this.POSuserLat=userCLat;
		this.POSuserLong=userCLong;
            this.router.navigate(['/search', userCLat, userCLong, CustomCode.getDateForMapSearchSlashFormat(), 1,  this.settypedata, this.facilityType, CustomCode.searchMode()]);
     }else{//alert('test3');
      let userLat = CustomCode.timeSquareCoords.lat;
      let userLong = CustomCode.timeSquareCoords.long;
      const locationUser = CustomCode.timeSquareCoords.defaultLocation;
      this.router.navigate(['/search', userLat, userLong, CustomCode.getDateForMapSearchSlashFormat(), 1, locationUser,  this.settypedata, CustomCode.searchMode()]); 
     }
      }, this.blockLocationCallback.bind(this));
    } 
	
if(this.POSuserLat!=0 && this.POSuserLong!=0){
	var geocoder;
	geocoder = new google.maps.Geocoder();
	var latlng = new google.maps.LatLng(this.POSuserLat, this.POSuserLong);
	var georoute=this.router;
	var add;
		geocoder.geocode(
			{'latLng': latlng}, 
			function(results, status,georoute) {
				 add= results[0].formatted_address ;
				//alert("city name is: " + add);	
				 $('#googleautosearch').val(add);			
			}
		);
		
 }
  }

  blockLocationCallback() {
    const getTimeCoords: any = JSON.parse(JSON.stringify(CustomCode.timeSquareCoords));
    const userLat = getTimeCoords.lat;
    const userLong = getTimeCoords.long;
    const defaultLoc = getTimeCoords.defaultLocation;

    this.router.navigate(['/search', userLat, userLong, CustomCode.getDateForMapSearchSlashFormat(), 1, defaultLoc, this.settypedata, CustomCode.searchMode()]);
  }

  searchLocation(type) {
      if (type === '') {
      type = $('label[name="monthlytype"].active').text().trim().toLowerCase();
      }
      let userLat = (<HTMLInputElement>document.getElementById('userlat')).value;
      let userLong = (<HTMLInputElement>document.getElementById('userlong')).value;
      let locationUser = (<HTMLInputElement>document.getElementById('locationname')).value;
      if (!userLat || !userLong || !locationUser || locationUser == 'Times Square, New York, NY, United States') {
        userLat = CustomCode.timeSquareCoords.lat;
        userLong = CustomCode.timeSquareCoords.long;
        locationUser = (locationUser == 'Times Square, New York, NY, United States')?locationUser:CustomCode.timeSquareCoords.defaultLocation;
      }
      this.router.navigate(['/search', userLat, userLong, CustomCode.getDateForMapSearchSlashFormat(), 1, locationUser, type, CustomCode.searchMode()]);
  }

  getBaseballModelContent(){
    return this.homePageServiceObj.getCmsBaseballModelContent(this.baseballId).subscribe(
      (data:any)=> {
        
        this.isLoaded = true;
        this.baseballModel = data;
          this.baseballModel = this.baseballModel.result[0];  
          
          if((localStorage.getItem('openBaseballModel')!= this.baseballModel.campaign_banner_name) ) {
          if(this.baseballModel.status == 1){
            this.baseballContent = this.baseballModel.model_popup_image;
            this.link_to_redirect = this.baseballModel.link_to_redirect;
            setTimeout(() => {
              this.baseballmodelbtn.nativeElement.click();
            }, 500);
          } 
        }
      },
      (error) => {
       
        this.isLoaded = true;
      }
    )
  }

  baseballPopuphide(){
    localStorage.setItem('openBaseballModel', this.baseballModel.campaign_banner_name);
    /* commented for ga 16-6-2021 by seo team
    ga('send', 'event', {eventCategory: this.baseballModel.campaign_banner_name+' - Popup Banner', eventAction: 'click', eventLabel: 'Cancel Button'});
    */
    this.hideBaseballPopup.nativeElement.click();
  }

  redirctToBaseball(){
    /* commented for ga 16-6-2021 by seo team
    ga('send', 'event', {eventCategory: this.baseballModel.campaign_banner_name+' - Popup Banner', eventAction: 'click', eventLabel: 'redirect'});
    */
    localStorage.setItem('openBaseballModel', this.baseballModel.campaign_banner_name);
    window.location.href=this.link_to_redirect
   // this.router.navigate(['/'+ this.link_to_redirect]);
  }

  


}
