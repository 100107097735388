<div role="tabpanel" class="tab-pane fade active in" id="currentinvoice" *ngIf="isLoaded">
	<!-- <i class="fa fa-circle-o-notch fa-spin btn-loader-image" *ngIf="isProcess"></i> -->
	<h4 class="text-primary mar-top-0">Current Invoice</h4>
	<div *ngIf="!noInvoice">
	<!-- <h4 class="text-primary mar-top-0">Current Invoice</h4> -->
		<div class="row">
			<div class="col-xs-12 col-sm-12 col-md-4 col-lg-6 mar-bottom-xs">
				<p>Account Invoice Sent {{ invoiceDate }}</p>
			</div>
			<div class="col-xs-12 col-sm-12 col-md-8 col-lg-6 text-right hidden-xs hidden-sm">
				<button type="submit" class="btn btn-primary theme-btn" (click)="downloadInvoice($event)">
					Download invoice
				</button><span class="mar-left-xs"></span><button type="submit" class="btn btn-primary theme-btn"  (click)="openPdf($event)">
					Open Pdf
				</button>
			</div>
			<div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 text-right visible-sm">
				<div class="row">
					<div class="col-sm-6 text-left pad-right-xs">
						<button type="submit" class="btn btn-primary theme-btn" (click)="downloadInvoice($event)">
							Download invoice
						</button>
					</div>
					<div class="col-sm-6 text-right pad-left-0">
						<button type="submit" class="btn btn-primary theme-btn"  (click)="openPdf($event)">
							Open Pdf
						</button>
					</div>
				</div>
			</div>
			<div class="col-xs-12 visible-xs">
					<div class="row">
							<div class="col-xs-offset-1 col-xs-10">
								<button type="submit" class="btn btn-primary theme-btn full-width" (click)="downloadInvoice($event)">
									Download invoice
								</button>
							</div>
						</div>
				<div class="row">
					<div class="col-xs-offset-1 col-xs-10">
						<button type="submit" class="btn btn-primary theme-btn full-width" (click)="openPdf($event)">
							Open Pdf
						</button>
					</div>
				</div>
				
			</div>
		</div>
		<div class="clearfix mar-top-xs">
				<div class="table-responsive">
			<table class="table table-hover">
				<thead>
					<tr>
						<th>Monthly Nr.</th>
						<th>Description</th>
						<th>Net Amt.</th>
						<th>Tax %</th>
						<th>Tax Amt.</th>
						<th>Total Amt.</th>
						<th>Balance Due</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let invoice of currentInvoice">
						<td>{{ invoice.tenant_number }}</td>
						<td>{{ invoice.description }}</td>
						<td>{{ invoice.net_amount }}</td>
						<td>{{ invoice.tax }}</td>
						<td>{{ invoice.tax_amount }}</td>
						<td>{{ invoice.total }}</td>
						<td>{{ invoice.balance_due }}</td>
					</tr>
				</tbody>
			</table>
		</div>
		</div>
		<div class="row">
			<div class="col-xs-12 col-sm-8 col-md-8 col-lg-8">
				<small>Note: All monthly parking charges are due on the first of the month. If payment is not received by the tenth day of the month, your vehicle will not be released without full payment plus a late fee.</small>
			</div>
			<div class="hidden-xs col-xs-12 col-sm-4 col-md-4 col-lg-4 text-right">
				<small>Account Balance as of<br>
				{{ invoiceDate }}</small>
			</div>
			<div class="visible-xs col-xs-12 col-sm-4 col-md-4 col-lg-4">
				<small>Account Balance as of<br>
				{{ invoiceDate }}</small>
			</div>
		</div>
		<div class="row">
			<div class="col-xs-12 col-sm-4 col-md-4 col-lg-4 text-right pull-right">
				<h2 sourceFont>${{ currentInvoice[0]?.bill_total }}</h2>				
			</div>
		</div>
		<app-saver-load [imageUrl]="imageCode" [showPopup]="showDialog" *ngIf="showDialog" (popEvent)="receiveShowDialog($event)"></app-saver-load>
	</div>
	<div *ngIf="noInvoice">
		<h4 style="margin-top: 2em;margin-bottom: 2em;">Sorry, Couldn't Find Any Current Invoice For This Account</h4>
	</div>
</div>
<app-lazy-load [isLoaded]="isLoaded" [sectionName]="'current invoice'"></app-lazy-load>