<app-lazy-load [isLoaded]="isLoaded" [sectionName]></app-lazy-load>
<div class="mar-top-sm" *ngIf="isLoaded">
  <div class="iconrewards">
    <div class="top-re-icons clearfix">
    <ol class="breadcrumb pad-top-0 pad-bottom-0 pad-left-0">
      <li class="active">
        <a class="btn btn-link pad-left-0" routerLink="/my-account/account">
          <i class="fa fa-angle-left" style="font-size: 20px; margin-top: -5px;"></i>&nbsp; My Account</a>
      </li>
    </ol>
      <ul class="top-icon-rewards pull-right">
        <li>
          <img [src]="icon_rewards_logo_wt" alt="icon">
        </li>
      </ul>
   </div>
    <div class="clearfix"></div>
    <div class="row">
      <div class="col-md-12">
        <h2 class="text-uppercase mar-top-0 mar-bottom-xs pad-bottom-sm text-primary">ENROLL IN ICON REWARDS</h2>
        <p class="pad-bottom-xs text-uppercase"><b class="semi-bold">{{cmsPageContent.post_title}}</b></p>
        <div class="icon-rewards-terms mar-top-xs" [innerHTML]="cmsPageContent.post_content"></div>
        <div class="mar-top-xs">
          <div class="form-group">
            <div class="checkbox">
              <label class="">
                <input (change)="acceptTerm($event)" type="checkbox"> I accept the Terms and Conditions above</label>
            </div>
          </div>
        </div>
        <app-icon-rewards-card (enrolledNow)="enrolledNow($event)" [isBtnDisabled]="enrollDisable"></app-icon-rewards-card> 
      </div>
    </div>
    <app-icon-rewards-details (enrolledNowProf)="enrolledNowProf($event)" [isBtnDisabled]="enrollDisable"></app-icon-rewards-details>
  </div>
</div>
