import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { CustomCode } from '../../shared/CustomCode';
import { FormValidationErrors } from '../../shared/formValidationErrors';
import { StaticpagesService } from '../../services/staticpages.service';
import { Location } from '@angular/common';

// import * as $ from 'jquery';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-b2',
  templateUrl: './b2bframe.component.html',
  styleUrls: ['./b2bframe.component.scss'],
  providers: [StaticpagesService]
})
export class B2bFrameComponent implements OnInit {
  formData: any = {};
  emailRegex = CustomCode.emailRegex;
  phoneMask: any = CustomCode.phoneMask;
  mobileLenght=0;
  hideb2bForm = false;
  private respdata;
  private parseData;
  constructor( private router: Router, private location: Location, private toasterObj: ToastrService, private staticObj: StaticpagesService) { }

  ngOnInit() {  }
 // this.loadScript();
  //     toggleData() {
  //   //localStorage.removeItem('searchLocation');
  //   $('#mySidenav').toggleClass('open');
  //    $('.sidenavBackDrop').toggleClass('open');
  //     $('body').addClass('sidebar-noscroll');
  // }
  ngAfterViewInit() {
    //this.loadScript();
  }
  enter_valueCompany(b)
  {
    $('input[id^="Prospect__c.Name"]').each(function(input){
      $(this).val($('#'+b).val());
  });
  $('input[id^="tfa_2"]').each(function(input){
    $(this).val($('#'+b).val());
  });
  
  }
  enter_valueEmployee(b)
  {
    //alert($('#'+b).val());
    $('input[id^="Prospect__c.Employees__c"]').each(function(input){
      $(this).val($('#'+b).val());
  });
  $('select[id^="tfa_4"]').each(function(input){
    $(this).val($('#'+b).val());
});
  

  }
  enter_valueFname(b)
  {
    //$('#tfa_10').val($('#'+b).val())
    $('input[id^="Prospect__c.First_Name__c"]').each(function(input){
      $(this).val($('#'+b).val());
  });
  $('input[id^="tfa_10"]').each(function(input){
    $(this).val($('#'+b).val());
});

  }
  enter_valueLname(b)
  {
    $('input[id^="Prospect__c.Last_Name__c"]').each(function(input){
      $(this).val($('#'+b).val());
  });
  $('input[id^="tfa_11"]').each(function(input){
    $(this).val($('#'+b).val());
});
  

  }
  enter_valueEmail(b)
  {
    $('input[id^="Prospect__c.Email__c"]').each(function(input){
      $(this).val($('#'+b).val());
  });
  $('input[id^="tfa_13"]').each(function(input){
    $(this).val($('#'+b).val());
});
  

  }
  enter_valuePhone(b)
  {
   // alert($('#'+b).val())
    $('input[id^="Prospect__c.Phone__c"]').each(function(input){
      $(this).val($('#'+b).val());
  });
  $('input[id^="tfa_14"]').each(function(input){
    $(this).val($('#'+b).val());
});
  

  }
  public loadScript() {
    let body = <HTMLDivElement> document.body;
    let script = document.createElement('script');
    script.innerHTML = '';
    script.src = 'https://sfapi.formstack.io/FormEngine/Scripts/Main.js?d=N5sfpqY_HIj6mWVrD0PZijBeNtQZ0XYyybTirvBaL7ttWRcb4y4kTFbkUeoswGZC';
    script.async = true;
    script.defer = true;
    body.appendChild(script);
}

  b2bFormSubmit(formValue, formObj: NgForm) {
    if (typeof formValue.contact_number === "undefined" || formValue.contact_number === "") {
      this.mobileLenght=1;
  }
  else{
    let number= formValue.contact_number;
     formValue.contact_number = formValue.contact_number.replace(/[&\/\\# ,+()$~%.'":*?<>{}]/g, '');
     formValue.contact_number = formValue.contact_number.replace(/-/g,'');
     if(formValue.contact_number.length >=9 && formValue.contact_number.length <=12 ) {
       this.mobileLenght=1;
     }else{
       this.mobileLenght=0;
       formValue.contact_number=number;
     }
  }
   
  if(formValue.contact_number != undefined && formValue.contact_number != ''){
    let ph = formValue.contact_number.replace(/\D/g, '');
    if(ph.length !==0 && ph.length < 10){
      formObj.form.controls['contact_number'].setErrors({'invalid': true});
      return false;
    }
  }


  const reg =/<(.|\n)*?>/g; 

    if (reg.test(formValue.company_name) == true) {
      this.toasterObj.error('HTML Tags are not allowed in Company Name.', 'Sorry!');
      return false;
    } else {
        if (reg.test(formValue.email) == true) {
          this.toasterObj.error('HTML Tags are not allowed in Email.', 'Sorry!');
          return false;
        } else{
          if (reg.test(formValue.first_name) == true) {
            this.toasterObj.error('HTML Tags are not allowed in First Name.', 'Sorry!');
            return false;
          } else {
            if (reg.test(formValue.Last_name) == true) {
              this.toasterObj.error('HTML Tags are not allowed in State.', 'Sorry!');
              return false;
            }else{
              //return true;
            }
          }
        }
    }
  

  if(this.mobileLenght==1) { 
    formValue.contact_number = formValue.contact_number;
    this.staticObj.B2BbusinessForm(formValue)
      .subscribe(
      (data:any)=> {
        this.respdata = JSON.stringify(data);
        this.parseData = JSON.parse(this.respdata);
        if (this.parseData.status === 201) {
          formObj.resetForm();
          // this.toasterObj.success('Thanks for your enquiry. We will get in touch with you.', 'Success!');
          this.hideb2bForm = true;;
          
          $('#submit_button').trigger('click')
          $('#btnsubmit').trigger('click')
         setTimeout(function(){  $('.ui-corner-all').trigger('click') }, 2000); 
         
          setTimeout(function(){ window.scrollTo(0, 0); }, 0); 

          // $('html,body').animate({
          //   scrollTop: $(".second").offset().top},
          //   'slow');
		  //this.router.navigate(['/contact/thanks']);
        }
      },
      error => {
        const errorsList = error.errors;
        if (errorsList.message !== 'Validation Errors') {
          this.toasterObj.error(errorsList.message, 'Sorry!');
        } else {
          this.hideb2bForm = false
          for (const key in errorsList.detail) {
            if (errorsList.detail.hasOwnProperty(key)) {
              if (typeof errorsList.detail === 'undefined') {
                this.toasterObj.error(errorsList.message, 'Sorry!');
              } else {
                this.toasterObj.error(errorsList.detail[key], 'Sorry!');
              }
            }
          }
        }
      }
      );
    }
    else{
      this.hideb2bForm = false 
          this.toasterObj.error('Contact number must contain 9 to 12 digit.', 'Error!');
    }

  }

  restSpace(event: any){
    if (event.keyCode == 32) {
      event.preventDefault();
    }
  }

  keyPress(event: any) {
    this.enter_valuePhone('phonec');
    const pattern = /[0-9\)\-\(\.\ ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && event.keyCode != 9 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  goBack() {
    this.location.back();
  }

}
