    <app-header></app-header>
    <div class="contactPage  pageHeight mar-top-lg pad-top-md">

      <!-- new thanks page coupon design -->

      <article class="thankyoupage commonPage orangeBar ">
        <div class="topwrap">
          <div class="container text-center"   >
            <div id="thankprinting"  *ngIf="thanksPrint" >
                <h1 class="text-primary text-center">Thank You</h1> 
                <h3>We look forward to providing you with a stress-free parking experience.</h3>
                <p class="mar-top-sm">Remember to bring your offer to the parking facility to receive your special rate.</p>
            </div>

            <div id="thankemail" *ngIf="thanksEmail" >
                <h1 class="text-primary text-center">Thank You</h1> 
                <h3>We look forward to providing you with a stress-free parking experience.</h3>
                <p class="mar-top-sm">Please check your email for the offers. Remember to present your offer at the parking facility to receive your special rate (based on availability). </p>
            </div>

      <div *ngIf="thanksSaved"  id="thanksave">
        <h1 class="text-primary text-center">Thank You</h1> 
        <h3>We look forward to providing you with a stress-free parking experience.</h3>
        <p class="mar-top-sm">Check the My Offers section of your account to see your Offer.</p>
      
      </div>

        <div class="btn-cont mar-top-sm " >
            <a *ngIf="thanksSaved && !isFrame" class="btn btn-primary theme-btn btn-fill btn-center-xs" (click)="redirectToAccount()">My Account</a> 
            <p class="visible-xs">&nbsp;</p>
            <a *ngIf="!isFrame" class="btn btn-primary  theme-btn btn-center-xs" routerLink="/">Return to home page</a>
            <a *ngIf="isFrame" class="btn btn-primary  theme-btn btn-center-xs" routerLink="/search">Return to home page</a>
        </div>
      </div>

</div>
        <div class="full-width bg-gray-lighter pad-top-md  mar-top-md monthly-section cta mar-bottom-md">
          <div class="container ">
            <div class=" flex-row row ">
              <div class="col-xs-12 col-sm-12 col-md-1"><img class="appIcon" [src]="app_icon" alt="appIcon"></div>
              <div class="col-xs-12 col-sm-8 col-md-8">
                <p *showItBootstrap="['xs']">&nbsp;</p>
                <h1 class="text-primary mar-top-0 text-uppercase">Have you downloaded our new app, Icon GO?</h1>
                <p>Icon GO is the newest way to find and book parking with Icon and Quik Park.
                  </p>
                  <p class="mar-top-sm">Icon GO unlocks exclusive promotions and offers that can be redeemed in any of Icon and Quik Park’s 200+ garages across New York City. Registered users also have access to new and exciting features, like mobile check in and check out, payment management and exclusive offers, promotions and deals directly from Icon.</p>
                  <p class="text-uppercase mar-top-sm"><b>For a limited time, new users receive <span sourceFont>$</span>10 off their first reservation when using the promo code <span class="text-primary">ICONAPPW10.</span></b></p>

                  <!-- section for action -->
                  <ul class="list-unstyled iconic-list">
                      <li>
                        <img [src]="dollar_icon" alt="appIcon"> <span class="lead">Unique coupons and promotions</span>
                      </li>
                      <li>
                        <img [src]="discount_icon" alt="appIcon"> <span class="lead">Direct booking discounts</span>
                      </li>
                    </ul>
                    <div class=" hidden-xs">
                      <div class="flex-row">
                        <div class="col-8 pad-left-0">
                            <input type="text" class="form-control" id="sendapp" placeholder="Enter phone number">
                        </div>
                        <div class="col-4">
                            <button type="submit" class="btn btn-primary theme-btn" (click)="sendApp()">Send App</button>
                        </div>
                      </div>
                    </div>
                    <div *showItBootstrap="['xs']" class=" text-center mar-top-sm mar-bottom-sm" >
                      <a *isIphone [href]="ituneUrl"><img [src]="AppStoreIcon" alt="Image"></a><br/>
                      <a  *isAndroidMobile [href]="googleplayUrl"><img [src]="google_playIcon" alt="Image"></a>
                    </div>
                    <p *hideItBootstrap="['xs']" >
                        <a routerLink="/app" class="readMore btn  pad-left-0">Read More <fa name="chevron-right"></fa></a>
                      </p>
                    <p *showItBootstrap="['xs']" class="" >
                      <a routerLink="/app" class="readMore btn-block btn btn-link ">Read More <fa name="chevron-right"></fa></a>
                    </p>
                    <p class="pad-top-sm">&nbsp;</p>
              </div>
              <div class="col-12 col-sm-4 col-md-3 align-self-end text-center"> <div class="imgwrp"> <img [src]="ios_map_Icon" alt="appIcon"></div></div>
            </div>
          </div>

        </div>
     
      </article>
      <!-- <div class="container" >
    
          <h3>Thank you for choosing Icon Parking. We look forward to providing you with a stress-free parking experience!</h3>
          <p style="font-size: 15px;">Have you downloaded our new app, Icon GO? Icon GO is the newest way to find and book parking with Icon and Quik Park.</p>
          <p style="font-size: 15px;">Icon GO unlocks exclusive promotions and offers that can be redeemed in any of Icon and Quik Park’s 200+ garages across New York City. Registered users also have access to new and exciting features, like mobile check in and check out, payment management and exclusive offers, promotions and deals directly from Icon.</p>
          <p style="font-size: 15px;">For a limited time, new users receive $10 off their first reservation when using the promo code<b> ICONAPPW10. </b> <a class="btn btn-primary btn-sm theme-btn btn-fill" routerLink="/app">CLICK HERE FOR MORE INFO</a></p>
        
          <a class="btn btn-black theme-btn" routerLink="/">Return to the home page</a>
    
      </div> -->
    </div>
    <app-footer></app-footer>
  

