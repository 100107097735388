<div class="winter-landing campaignPage pad-top-lg">
	<div class="container"> 
		<div class="row">
			<div class="col-xs-12 col-sm-12 col-md-10 col-lg-9 mar-lr-auto">
				<div class="text-center">
					<h1 class="text-center text-primary text-uppercase mar-bottom-sm">
					Save on Parking
					</h1>
					<div class="buttonWrap">
						<button (click)="staticModal.show();resetForm(cm);emailCouponPop();" type="button" class="btn btn-fill btn-primary text-white">Email Coupon</button><span class="mar-left-xs hidden-xs"></span>
						<button (click)="printModal.show();printCoupon();" type="button" class="btn btn-fill btn-primary text-white">Print Coupon</button>
					</div>
					
					<div class="coupon-details clearfix mar-top-sm">
						<p class="couponRate mar-bottom-sm text-uppercase">
							<span class="couponUsage mar-bottom-xs" sourceFont>$<span>35</span> for <span>24</span> hrs</span>
							<small>Tax Included</small>
						</p>
						<h3 class="mar-top-0 pad-top-xs mar-bottom-xs text-uppercase text-primary">MORE THAN 55% OFF STANDARD PARKING RATES.</h3>
						<p class="mar-top-0 pad-top-xs mar-bottom-0">
							<span sourceFont>$</span>35 for up to 24 hours at any Icon or Quik Park facility.*
						</p>
					</div>
					<div class="clearfix offer-terms">
						<hr class="white mar-top-0">
						<p class="text-primary text-uppercase">*Offer Terms</p>
						<ul class="dashList list-unstyled">
							<li>
								Coupon expires on 12/31/18.
							</li>
							<li>
								Valid only with this coupon and payment by Credit Card.
							</li>
							<li>
								Oversized 4x4’s and utility vehicles additional charge
							</li>
							<li>
								At participating locations
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>


<!--  for email coupons pages model -->

<div class="modal fade" bsModal #staticModal="bs-modal" [config]="{backdrop: 'static'}"
	tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">
	<div class="modal-dialog modal-sm">
		<div class="modal-content">
			<div class="modal-header">
				<h4 class="modal-title pull-left" style="padding-left:15px;">Email Coupon</h4>
				<button type="button" class="close pull-right" (click)="staticModal.hide();cancelEmailPopUp();" aria-label="Close">
					<span aria-hidden="true"><fa name="times"></fa></span>
				</button>
			</div>

			
			<div class="modal-body">

				<form name="form" role="form" id="floatForm" (ngSubmit)="cm.form.valid && sendCoupon(mailFormData, cm)" #cm="ngForm" novalidate>
					<div class="form-group mar-top-0">
						<input placeholder="Enter Your Email" class="form-control" type="mail" name="ucmail" [(ngModel)]="mailFormData.ucmail" #ucmail="ngModel"
							 required />
					<div *ngIf="cm.submitted && !ucmail.valid"class="help-block">Email is required</div>
					</div>
					<div class="form-group">
						<button class="btn btn-primary btn-block btn-fill" type="submit">EMAIL COUPON</button>
					</div>
					<div class="form-group">
						<button class="btn btn-primary btn-block btn-fill" type="button" (click)="staticModal.hide();cancelEmailPopUp();" >CANCEL</button>
					</div>
				</form>
				
			</div>
		</div>
	</div>
</div>

<!--  for print coupons pages model -->

<div class="modal fade" bsModal #printModal="bs-modal" [config]="{backdrop: 'static'}"
	tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">
	<div class="modal-dialog">
		<div class="modal-content">
			<div class="modal-header">
				<button type="button" class="close pull-right" (click)="printModal.hide();cancelPrintPopUp();" aria-label="Close">
					<span aria-hidden="true"><fa name="times"></fa></span>
				</button>
			</div>
			<div class="modal-body">
				<div class="text-center mar-bottom-sm">
					<button class="btn btn-primary theme-btn btn-fill btn-dark" type="button" (click)="printMedia()">Print</button>&nbsp;
					<button class="btn btn-primary theme-btn btn-fill" (click)="printModal.hide();cancelPrintPopUp();" type="button">Cancel</button>
				</div>
				<div class="panel-body"id="printableContent">
					<app-lazy-load [isLoaded]="isLoaded" [sectionName]="'coupons'"></app-lazy-load>
					<img  style="display:none;"class="img-responsive" [src]="sanitizer.bypassSecurityTrustResourceUrl(printData)"  id="invoicePrint" 
					class="printable img-responsive full-width" alt="Image"> 
				</div>
			</div>
		</div>
	</div>
</div>