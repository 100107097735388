<div class="section">
    <div class="container">
        <div role="tabpanel" class="tab-pane fade active in" id="prefclub">
            <h4 class="text-primary">Preferred Customer Program</h4>
            <ul>
                <li>50% off posted rates at participating facilities all the time.</li>
                <li>New monthly customers signing up directly through Icon or Quik Park will automatically be enrolled in
                    the Preferred Customer Program and will receive 50% off all daily posted rates at 325 Icon and Quik
                Park locations throughout Manhattan and Brooklyn.</li>
                <li>The 50% discount does not apply to monthly parking rates and may not be combined with any other discount offers or coupons.</li>
            </ul>
            <a routerLink="/faq-mobile" class="btn btn-link pad-left-0">Frequently asked questions &nbsp;<fa name="chevron-right"></fa></a>
            <div class="well mar-top-sm">
                <div class="mar-top-sm"></div>
                <h4 class="text-primary text-uppercase mar-bottom-sm mar-top-0">Enrollment</h4>
                <div class="row mar-bottom-sm">
                    <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4 text-center">
                        <i class=" text-primary fa fa-4x "></i><br><br>
                        There is no enrollment fee to be a member of the Preferred Customer Program.
                        <p class="visible-xs">&nbsp;</p>
                    </div>
                    <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4 text-center">
                        <i class=" text-primary  fa fa-4x "></i><br><br>
                        You will receive your Preferred Customer Card in the mail during your second month of parking. Once you receive your card you can immediately begin taking advantage of this incredible offer.
                        <p class="visible-xs">&nbsp;</p>
                    </div>
                    <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4 text-center">
                        <i class=" text-primary  fa fa-4x "></i><br><br>
                        Your monthly account must remain active to retain your membership.
                    </div>
                </div>
            </div>
            <div class="clearfix mar-top-sm">
                <h4 class="text-primary">Excluded Facilities</h4>
                <ul>
                    <li>L.T.G Parking Corp. 150, (Gemini Parking 150), 150 West End Ave, NY, NY 10023</li>
                    <li>L.T.G Parking Corp. 165, (Gemini Parking 165), 165 West End Ave, NY, NY 10023</li>
                    <li>L.T.G Parking Corp. 185, (Gemini Parking 185), 185 West End Ave, NY, NY 10023</li>
                    <li>Quik Park @ Lincoln Center (Quik Park W 62nd St), 103-163 West 62nd St, NY, NY 10023</li>
                    <li>Yankee Stadium</li>
                    <li>All Montefiore Hospital Locations</li>
                </ul>
            </div>
            <div class="clearfix mar-top-sm">
                <h4 class="text-primary">Payment</h4>
                <p>To receive your 50% discount, you must present your Preferred Customer Card, and a valid credit card with the same name as the Preferred Customer Card, to the cashier at the time of payment. Cash payment will not be accepted for ½ price parking.</p>
                <p>You can use this website to find locations near your destination and compare prices of how much it will cost to park at any of our locations</p>
            </div>
            <div class="clearfix mar-top-sm">
                <h4 class="text-primary">Eligibility</h4>
                <p>You must be a monthly parker with Icon or Quik Park.</p>
            </div>
            <div class="clearfix mar-top-sm">
                <h4 class="text-primary">Terms and Conditions</h4>
                <ul sourceFont>
                    <li>The Preferred Customer Card is to be used solely by the monthly applicant.</li>
                    <li>The 50% discount does not apply to monthly parking rates and may not be combined with any other discount offers or
                    coupons.</li>
                    <li>A <span sourceFont>$2</span> Fee will be charged on all transactions.</li>
                    <li>Offer does not apply to motorcycles.</li>
                    <li>Your monthly account must remain active to retain your membership.</li>
                    <li>Icon and Quik Park reserve the right at their sole discretion to alter or modify these terms and conditions, including the
                    fees, discount benefits, and participating locations, at any time.</li>
                </ul>
            </div>
            <div class="clearfix mar-top-sm">
                <h4 class="text-primary">Preferred Customer Program FAQ</h4>
                <ol sourceFont>
                    <li><b>What is the Preferred Customer Club?</b><br>The Preferred Customer Club is an exclusive membership for our monthly customers which entitles them to park for 50% off the daily posted rates at over 325 Icon and Quik Park garages in Manhattan.</li>
                    <li><b>How do I apply to become a member of the Preferred Customer Club?</b><br>A complimentary card will automatically be mailed to you in your second month of parking.</li>
                    <li><b>How much does the card cost?</b><br>There is no charge for the card. Replacement cards cost $5 for a processing and activation fee.</li>
                    <li><b>Is there a monthly fee to be a member of the Preferred Customer Club?</b><br>No, membership is free.</li>
                    <li><b>Can I get more than one card?</b><br>No, only one card can be issued per monthly parking space.</li>
                    <li><b>When does the card expire?</b><br>When you are no longer a monthly customer at the location your card was issued for. If you change locations or open a new account, you will be issued a new card.</li>
                    <li><b>Can I use the card at any Icon or Quik Park garage?</b><br>You can use the card at any Icon or Quik Park garage with the exception of the short list of excluded facilities shown above.</li>
                    <li><b>Can I pay by cash when I use the card?</b><br>No, the payment must be made by a credit card with the same last name as the member.</li>
                    <li><b>How do I find where Icon and Quik Park garages are located?</b><br>You can locate an Icon or Quik Park garage on the website, by looking for the Icon or Quik Park logo or by the iPhone and Android app.</li>
                    <li><b>What if I lost my card?</b><br>Email IconPreferredCustomerClub@iconparking.com with subject "Lost Card" and include the monthly applicant name to appear on the card, the garage at which you park monthly and your monthly number and we will send you a replacement card. A one-time $5 processing and activation fee will be applied for the replacement card.</li>
                    <li><b>Do you have apps for iPhone and Android users?</b><br>Yes, we currently have apps for these platforms.<a routerLink="/app"> Read more here.</a></li>
                    <li><b>I forgot to take my card to the garage but know my card number, can I still get the discount?</b><br>No, in order to receive the discount you must have the card with you at checkout.</li>
                </ol>
            </div>
        </div>
    </div>
</div>