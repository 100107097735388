import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { FilterHelper } from '../classes/filter-helper';
import { CustomCode }  from '../shared/CustomCode';
//import { Http, Headers, RequestOptions, Response } from '@angular/http';
import { HttpClient,HttpHeaders  } from '@angular/common/http';

import { Observable } from 'rxjs/Rx';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
@Injectable({
  providedIn: 'root'
})
export class EvcService {
  
  constructor(private http: HttpClient) { }


  private evcUrl = CustomCode.APIURL+'evcharging';
// private evcUrl = 'https://localhost/icon-modern-api-2.0/public/evcharging';

 

    getRelatedEvc() {
       // const options = CustomCode.returnHeaders();               
        return this.http.get(this.evcUrl).map((res: Response) => res).catch((error: any) => Observable.throw(error));
          
        }


}
