import { Injectable } from '@angular/core';

//import { Http, Headers, RequestOptions, Response } from '@angular/http';
import { HttpClient,HttpHeaders  } from '@angular/common/http';

import { HttpService } from './http.service';
import { Observable } from 'rxjs/Rx';
import { CustomCode } from '../shared/CustomCode';
import { DomSanitizer } from '@angular/platform-browser';

import * as FileSaver from 'file-saver';

// Import RxJs required methods
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';

@Injectable({
  providedIn: 'root'
})
export class ChangePasswordService {

constructor(private http: HttpClient) { }

private getUsersDetails = CustomCode.APIURL + 'api';
private getChangeUserPassword = CustomCode.APIURL + 'user/';

  
  fetchUser() {
    return new Promise((resolve, reject) => {
      this.http.get(this.getUsersDetails).subscribe(
        (res:any) => {
			
          resolve(res.json());
        },
        error => {
          reject(JSON.parse(error['_body']));
        }
      );
    });
  }
  //{old_password: "adadasdsadasd", password: "aaaaaaaa", password_confirmation: "aaaaaaaa"}
  changeuserpassword(chpadata,userid){
	  
	  const headers = new HttpHeaders();
    const token = localStorage.getItem('access_token');
    const bigApple = localStorage.getItem('X-Big-Apple');
	  //headers.append('Authorization', 'Bearer ' + access_token);
   // const options = new RequestOptions({ headers: headers });
	const formDetails = {'old_password': chpadata.old_password,'password': chpadata.password,'password_confirmation': chpadata.password_confirmation};
	
    return this.http.post(this.getChangeUserPassword+userid+'/reset-password', formDetails, { headers: new HttpHeaders({'Authorization': 'Bearer ' + token,'X-Big-Apple':bigApple})})
    .map((res: Response) => res)
    .catch((error: any) => Observable.throw(error.error));  
	  
  }
  

}

