<div id="main">
    <app-header></app-header>
	<div class="page-header orangeBar" id="contact"></div>
	<div class="contactPage pad-top-md pad-bottom-md">
		<div class="container" *ngIf="showResetPass">
			<h2 class="text-primary text-uppercase mar-bottom-sm mar-top-0">RESET YOUR PASSWORD</h2>
			<small>Enter your email address below to have a password reset link sent to your email.</small>
			<div class="viewOne">
				<div id="addMonthlyVehicleContainer"  >
					<form class="p mar-top-sm floating-label" name="form" role="form" id="floating-label-reset-pass1" (ngSubmit)="f.form.valid && changepassword(formData, f)" #f="ngForm"
					novalidate>
						<div class="flex-row no-gutters">
							<div class="col-xs-12 col-sm-6 col-md-4 mar-bottom-sm">
								<div class="formCls" [ngClass]="{ 'has-error' : f.submitted && !email.valid }">
									<label class="control-label">Enter email</label>
									<input type="text" name="email" class="form-control" id="" [(ngModel)]="formData.email"  #email="ngModel" required>
									<div *ngIf="f.submitted && !email.valid" style="position: absolute;" class="help-block">Email is required</div>
								</div>
							</div>
							<div class="col-xs-12 col-sm-6 col-md-8 align-self-end mar-bottom-sm">
								<button class="mar-left-sm btn  btn btn-fill btn-primary">Send Reset Password Email</button>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
		
		<div class="container" *ngIf="showactChangePass">
			<h2 class="text-primary text-uppercase mar-bottom-sm mar-top-0">Reset your password</h2>
			<div class="viewOne">
				<div id="addMonthlyVehicleContainer"  >
					<form class="p mar-top-sm floating-label" name="form" role="form" id="floating-label-reset-pass2" (ngSubmit)="changeactpassword(formData, f)" #f="ngForm"
					novalidate>
						<div class="row">
							<div class="col-xs-12 col-sm-6 col-md-4">
								<div class="form-group mar-top-0" [ngClass]="{ 'has-error' : f.submitted && !password.valid }">
									<label class="control-label">New Password</label>
									<input type="password" name="password" class="form-control" id="" [(ngModel)]="formData.password"  #password="ngModel" required>
									<div *ngIf="f.submitted && !password.valid" class="help-block">{{passwordErrMsg}}</div>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-xs-12 col-sm-6 col-md-4">
								<div class="form-group mar-top-0" [ngClass]="{ 'has-error' : f.submitted && !password_confirmation.valid }">
									<label class="control-label">Confirm Password</label>
									<input type="password" name="password_confirmation" class="form-control" id="" [(ngModel)]="formData.password_confirmation"  #password_confirmation="ngModel" required>
									<div *ngIf="f.submitted && !password_confirmation.valid" class="help-block">Confirm Password is required</div>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-xs-12 col-sm-6 col-md-8">
								<button class="btn  btn btn-fill btn-primary ">Reset Password</button>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
	<app-footer></app-footer>

</div>