<app-header></app-header>
<app-lazy-load [isLoaded]="isLoadeds" [sectionName]="'content'"></app-lazy-load>
<div class="jocoWrapper commonPage orangeBar mar-top-lg" [innerHTML]="staticPageContent.post_content"></div>

<!-- <div class="jocoWrapper"> 
  <div class="headerBlock"> 
   <div class="container">
     <div class="row aling-itmes-center justify-content-center ">
       <div class="col-12 col-sm-4">
        <img class="mt-3 logo" src="../../../assets/img/landing-pages/joco/logo-top.png">
        <h1 class="py-4 fwbler titleHeader">
          Introducing JOCO <br >
          Premium E-bikes
        </h1>
        <h4>
          A new wey icon is helping bring <br class="visible-xs"> more <br class="hidden-xs">mobility to New York.
        </h4>
        <a role="button" href="http://ridejoco.com/icon" target="_blank" class="btn btn-rounded hidden-xs btn-lg mt-4 btn-fill btn-primary">  
          Sign up
        </a>
       </div>
       <div class="co-12  col-sm-5 bike-image">
            <img class="img-responsive" src="../../../assets/img/landing-pages/joco/bike_cutout.png">
       </div>
     </div>
   </div>
  </div>
  <div class="container">
    <h3 class="text-center section-2">
      Now you can drop off your car and<br class="visible-xs"> pick up a bike, and <br class="hidden-xs">
      experiance New<br class="visible-xs"> York in whole new way.
    </h3>
  </div>
  <section class="colbox">
      <div class="row no-gutters align-items-center">
          <div class=" col-12 col-sm-6 p-0">
              <div class="image image-1"> </div>
          </div>
          <div class="col-12 col-sm-4  ">
            <div class="p-5">
              <h1 class="text-primary mt-0 fwbler">
                Availabe at<br class="visible-xs"> select icon<br class="visible-xs"> locations
              </h1>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Cumque placeat vitae autem modi ipsum quasi, ducimus quis molestiae eveniet dolor libero sed omnis? At modi consectetur quod, eaque eius tempora.
              </p>
              <a role="button" href="http://ridejoco.com/faq" target="_blank" class="btn btn-rounded btn-lg mt-4 btn-fill btn-black">  
                Learn More
              </a> 
            </div>
      </div>
      </div>
  </section>
  <section class="colbox">
    <div class="row no-gutters align-items-center">
                <div class="col-12 col-sm-4 order-1 order-md-0 col-md-offset-2   ">
                  <div class="p-5 older-2">
                    <h1 class="text-primary fwbler">
                    How to Ride
                    </h1>
                    <p>
                    For FAQ's information on how to ride, and to <br> download the JOCO app visit <a class="text-link text-dark"  href="http://ridejoco.com/icon">http://ridejoco.com/icon</a>
                    </p>
                    <a role="button" href="http://ridejoco.com/faq" target="_blank" class="btn btn-rounded btn-lg mt-4 btn-fill btn-black">  
                      Visit JOCO
                    </a> 
                  </div>
            </div>
            <div class=" col-12 col-sm-6 p-0">
              <div class="image image-2"> </div>
          </div>
    </div>
</section>
<section class="colbox">
  <div class="row no-gutters align-items-center">
      <div class=" col-12 col-sm-6 p-0">
          <div class="image image-3"> </div>
      </div>
      <div class="col-12 col-sm-4  ">
        <div class="p-5 ">
          <a href="https://apps.apple.com/us/app/joco-premium-shared-e-bikes/id1563077589" target="_blank">
          <img src="../../../assets/img/AppStore.svg" height="30px"> 
          </a>  &nbsp; &nbsp;  <a href="https://play.google.com/store/apps/details?id=com.vulog.carshare.ff.beambikes.prod" target="_blank">
            <img src="../../../assets/img/google-play.svg" height="30px"> 
            </a>
          <h1 class="text-primary fwbler mb-4">
            Download the<br>
            JOCO app
          </h1>
          <p>
         To ride you need to download the JOCO app.<br>
         It's simple as that.
          </p>
          <a role="button" href="http://ridejoco.com/icon" target="_blank" class="btn btn-rounded btn-lg mt-4 btn-fill btn-black">  
           Sing Up
          </a> 
        </div>
  </div>
  </div>
</section>
<footer class="jocoApp headerBlock">
<div class="container">
  <div class="row align-items-center">
    <div class="col-12 order-3 order-md-0 col-sm">
      <img class=" logo" src="../../../assets/img/landing-pages/joco/logo-top.png">
    </div>
    <div class="col-12 order-0 order-md-1 col-sm text-center midLinks">
      <a href="http://ridejoco.com/locations"> 
        Locations
      </a> &nbsp; &nbsp; <br  class="visible-xs">
      <a href="http://ridejoco.com/faqs"> 
        FAQ's
      </a>
      <div class="visible-xs py-5" ></div>
    </div>
    <div class="col-12 order-1 order-md-2 col-sm text-center">
      <a href="https://www.instagram.com/ridejoco/">
        <img class=" logo" src="../../../assets/img/landing-pages/joco/insta.png">
      </a> &nbsp; &nbsp;
      <a href="https://facebook.com/ridejoco">
        <img class=" logo" src="../../../assets/img/landing-pages/joco/fb.png">
      </a>
      <br>
     <p class="text-center">
      #RideJOCOIconparking
     </p>
     <p class="visible-xs">
&nbsp;
     </p>
    </div>
  </div>
</div>
</footer>
</div> -->