<!-- app header -->
<app-header></app-header>

<!-- page content -->

	<!-- Monthly Parking -->
				<div class="page-header orangeBar" id="app"></div>
				<div class="appPage">
					<div class="container mar-top-sm mar-bottom-sm">
						<ol class="breadcrumb">
						</ol>
						<h1 class="text-uppercase text-primary mar-top-0 mar-bottom-sm appiconHeading">
							<img [src]="app_iconIcon" class="appicon" alt="Image" width="80px" height="80px">Introducing the Icon GO app.<br/>Book, pay, and play.
						</h1>
						<div class="visible-xs mar-top-sm mar-bottom-xs text-center">
							<a [href]="itunesUrl"><img [src]="AppStoreIcon" alt="image"></a><span class="hidden-xs mar-left-xs"></span><div class="visible-xs mar-top-xs"></div>
							<a [href]="play_google"><img [src]="google_play_Icon" alt="image"></a>
						</div>
						<div class="row">
							<div class="col-sm-6">
								<ul class="list-unstyled iconic-list">
									<li>
										<img [src]="dollar_icon" alt="icon"><span class="lead">Unique coupons and promotions</span>
									</li>
									<li>
										<img [src]="discount_icon" alt="icon"><span class="lead">Direct booking discounts</span>
									</li>
									<li>
										<img [src]="arrowsIcon" alt="icon"><span class="lead">Seamless check in and check out</span>
									</li>
									<li>
										<img [src]="starIcon" alt="icon"><span class="lead">Exclusive services and features</span>
									</li>
								</ul>
								<p class="visible-xs">&nbsp;</p>
							</div>
							<div class="col-sm-6 hidden-xs mar-top-sm">
								<p class="text-uppercase">
									<!-- <b>Enter your phone number below and we will send you a link to download Icon GO.</b> -->
									<b>Download IconGo for:</b>
								</p>
								<!-- <div class="input-group mar-bottom-md">
									<input type="text" class="form-control" id="sendapp" placeholder="Enter your phone number">
									<span class="input-group-btn">
										<button type="submit" class="btn btn-primary theme-btn" (click)="sendApp()">Send Link</button>
									</span>
								</div> -->
								<a [href]="itunesUrl" target="_blank"><img [src]="AppStoreIcon" alt="image"></a>&nbsp;&nbsp;
								<a [href]="play_google" target="_blank"><img [src]="google_play_Icon" alt="image"></a>
							</div>
						</div>
					</div>
					<div class="app-parallax text-white pad-top-md">
						<div class="container">
							<div class="row">
								<div class="col-xs-6 col-sm-12 col-md-12 col-lg-12">
									<div class="row">
										<div class="col-sm-4 mar-bottom-sm">
											<h2 class="mar-top-0 mar-bottom-sm text-primary text-uppercase">
												You find it.
											</h2>
											<p>
												Find and compare our 200+ garages and rates to find the perfect one for the time you need.
											</p>
											<p class="visible-xs">&nbsp;</p>
										</div>
										<div class="col-sm-4 mar-bottom-sm">
											<h2 class="mar-top-0 mar-bottom-sm text-primary text-uppercase">
												You book it.
											</h2>
											<p>
												Reserve your spot on your phone. No cash needed, just pay for the time you need right in the app.
											</p>
											<p class="visible-xs">&nbsp;</p>
										</div>
										<div class="col-sm-4 mar-bottom-sm">
											<h2 class="mar-top-0 mar-bottom-sm text-primary text-uppercase">
												We park it.
											</h2>
											<p>
												Get directions, and pull up. We’ll handle the rest. You can just relax and enjoy our world-class service.
											</p>
										</div>
									</div>
								</div>
								<div class="col-xs-6 col-sm-12 col-md-12 col-lg-12 app-parallax-imgWrap">
									<img [src]="appPhoneIcon" class="full-width hidden-xs" alt="Image">
								</div>
							</div>
						</div>
						<img [src]="appPhoneXsIcon" class="full-width visible-xs" alt="Image">
					</div>
				</div>
				
<!-- app footer -->
<app-footer></app-footer>