
<!--  for mobile only -->
	<!-- <div class=" appPromotion clearfix visible-xs" *ngIf="appPromotion" >
		<div class="clearfix"  >
	<button type="button" class="close"(click)="dismisThis()" ><fa name="times"></fa> </button>
	<img [src]="iconAppLogo">
	<span>
		<h4 class="text-primary text-uppercase">iconGO : book , pay, play.</h4>
		<p>Get $10 off parking with promo code:  <b>ICONAPPW10</b></p>
		<a [class.hide]="iOS" [href]="applePppUrl" class="btn btn-primary btn-sm theme-btn" onClick="ga('send', 'event', { eventCategory: 'Mobile app download', eventAction: 'click'});">Download App</a>
		<a [class.hide]="Android" [href]="googleUrl" class="btn btn-primary btn-sm theme-btn" onClick="ga('send', 'event', { eventCategory: 'Mobile app download', eventAction: 'click'});">Download App</a>
	</span>
	</div>
</div> -->

<div class="clearfix hidden showBlock" [ngClass]= "{'visible-xs': isScrolled, 'AddHeight': !isValineTineLanding}"></div>

<!-- mobile sidenav button -->
<app-sidenav></app-sidenav>
<!--desktop and mobile navigation bar -->


<div sourceSense class="bar-section" barSection #NavbarInner id="NavbarInner" >
    <!--  strip for covid -->
<div sourceSense  class="d-flex bg-white  flex-column border-top justify-content-center text-dark  py-2" topbar>
    <div class="container">
<div class="row top-add ">

  
    <div class="col-md-12">
    <p class="text-center">
        <!-- <span>
            <img width="18" src="/assets/img/park-safe-icon.png"/>&nbsp;<a style="cursor: pointer;" (click)="triggerEvent('COVID-19 RESPONSE: ICON PARKSAFE')" routerLink="/iconparksafe"   class="text-primary">Learn more about ICON PARKSAFE: </a>
            
            Contactless Payments, CDC Guideline Compliant, & Social Distancing. 
           </span> -->
           <span *ngIf="ismonthlydailypage=='monthly'">
            <img width="18" src="/assets/img/park-safe-icon.png"/>&nbsp;<a    class="text-primary ">Icon Parking PROMISE | BEST ONLINE Rates & Savings: </a>
            BOOK TODAY to Lock in Your Term Rates, Limited Spots Available!
        </span>
        <span *ngIf="ismonthlydailypage=='daily'">
            <img width="18" src="/assets/img/park-safe-icon.png"/>&nbsp;<a   class="text-primary ">Our Icon Parking PROMISE | BEST Rates ONLINE:  </a>
             <!-- Contactless Payments, Hospital-Grade Disinfection, Gloves, Masks, & Social Distancing. -->
             Avoid the hassle, Book Online in Advance and Save More! BOOK NOW!
        </span>
        <span *ngIf="ismonthlydailypage==''">
            <img width="18" src="/assets/img/park-safe-icon.png"/>&nbsp;<a style="cursor: pointer;" (click)="triggerEvent('COVID-19 RESPONSE: ICON PARKSAFE')" routerLink="/iconparksafe"   class="text-primary">Learn more about ICON PARKSAFE: </a>
             <!-- Contactless Payments, Hospital-Grade Disinfection, Gloves, Masks, & Social Distancing. -->
            Contactless Payments, CDC Guideline Compliant, & Social Distancing. 
        </span>       
    </p>
    </div>
</div>
</div>
</div>
    <nav class="navbar " navbar>
        <div class="container">
            <div class="flex-row no-gutters justify-content-between align-items-center">
            <!-- Brand and toggle get grouped for better mobile display -->
            <div class="col-auto" >
                <a class="navbar-brand  pad-right-0" href="javascript:; " routerLink="/"><img [src]="icon_logo">
                <!-- <h4   style="margin-top: 5px;" class=" text-white visible-xs visible-sm">PARKING IS JUST THE START<span class="text-primary">.</span><sup>TM</sup></h4> -->
                </a>
                <!-- <h5  *ngIf="!SearchPageLook" style=" float: right; margin-top: 21px;" class="ml-2 wepark-logo-mob">PARKING IS JUST THE START<span class="text-primary">.</span><sup>TM</sup></h5> -->
                <h5 style=" float: right; margin-top: 21px;font-weight:600;" class="ml-2 wepark-logo-mob">PARKING IS JUST THE START<sup>TM</sup></h5>
                
			</div>
			<div class="col hidden-sm hidden-xs hidden "    >

				<div class="navbar-form navbar-left searchBar searchPageBar  "  style="max-width:300px" role="search"  >
						<div class="form-group">
							<app-google-autosuggest ></app-google-autosuggest>
						</div>
					</div> 
			</div>
          
            <!-- Collect the nav links, forms, and other content for toggling -->
            <div class="col-auto  hidden-sm hidden-xs ">
                <ul class="nav navbar-nav navbar-right  ">

                   
                    <!-- <li class="hidden-xs margin-head-ban"><a routerLink="/cares"><img class="img-responsive my-image" src="../../assets/img/new-homepage/icon-img.jpg" style="margin-top: -8px;"></a></li> -->
                    <!-- <li><a href="javascript:;" class="btn-outer" routerLink="/b2bsolutions">ICON  <span class="text-primary">|</span>  FOR BUSINESS </a></li> -->
                    <li ><a id="findpark" (click)="findNearMe()"  href="javascript:;" class="font-15">FIND PARKING </a></li>
                    <!-- <li><a href="javascript:;" routerLink="/monthly-parking">MONTHLY PARKING </a></li> -->
                    <li><a href="javascript:;" (click)="triggerEvent('MONTHLY PARKING SPECIALS');formonthly();" class="font-15">MONTHLY PARKING</a></li>
                    <li><a href="javascript:;" (click)="triggerEvent('SPECIALS & OFFERS')"  routerLink="/specials" class="font-15">SPECIALS & OFFERS </a></li>
                    <li><a href="javascript:;" (click)="triggerEvent('ICON FOR BUSINESS')" routerLink="/business-parking-nyc" class="font-15">ICON FOR BUSINESS </a></li>
                    <!-- <li><a href="javascript:;" *ngIf="!isLoyaltyMember" class="" routerLink="/rewards">ICON REWARDS</a>
                        <a *ngIf="isLoyaltyMember" routerLink="/my-account/icon-rewards">ICON REWARDS</a> </li> -->
                   
                    
                    <!-- <li *ngIf="!isLoggedIn">
                        <a data-toggle="modal" id="loginPopup" href='#login'>LOGIN</a>
                    </li> -->
                    <!-- <li><a href="javascript:;" routerLink="/contact">CONTACT US</a></li> -->
                   
                    <!-- <li *ngIf="isLoggedIn">
                        <a href="javascript:;" routerLink="/my-account/account">MY ACCOUNT</a>
                    </li> -->
                    <!-- <li><a href="javascript:;" (click)="callcustomer()" [href]="phone" class="text-white">  1 (877) 727-5464</a></li> -->
                    <li>
                        <a href="javascript:; " class="toggleMenuBtn" (click)="toggleData()"> <i class="fa fa-bars fa-2x"></i> </a>
                    </li>
                    <li class="get-app-tp">
                        <a (click)="triggerEvent('GET THE APP')" routerLink="/app" style="cursor: pointer;">GET THE APP &nbsp;<img width="9" src="/assets/img/gray-app-mob-icon.png"/></a>
                    </li>
                    <li class="top-socila">

                       
                        <a [href]="instagramUrl" target="_blank"><img src="/assets/img/gray-insta.png"/></a>
                        <a [href]="facebookUrl" target="_blank"><img src="/assets/img/gray-fb.png"/></a>
                        <a [href]="twitterUrl" target="_blank"><img src="/assets/img/gray-twit.png"/></a>
                        <a [href]="linkedinUrl" target="_blank"><img src="/assets/img/gray-linkd.png"/></a>
					</li>
					

					<li id="guestuser">
						
						<span class="btn-outer-2" ><!-- <a data-toggle="modal" id="loginPopup" href='#loginreward'><img width="70" src="/assets/img/icon-rewards-logo-wt.svg"/></a> -->
                            <!-- <button [popover]="popTemplate"
                            [outsideClick]="true"
                            triggers=""  [isOpen]="isOpen"   
                            placement="bottom"   containerClass="customClass" class="btn btn-link p-0" type="button" 
                            
               >
                <img (click)="isOpen = !isOpen"  width="70" src="/assets/img/icon-rewards-logo-wt.svg"/>
        </button> -->
                            <!-- <a  class="popupButton" [popover]="popTemplate" 
                            triggers="focus"   placement="bottom"   containerClass="customClass" ><img   width="70" src="/assets/img/icon-rewards-logo-wt.svg"/></a> -->
                            <a data-toggle="modal" id="loginPopup" href='#login'><span style="color: #fff;">LOGIN /</span><span class="text-primary"> SIGNUP</span></a></span>
                    </li>
					
					<li class="dropdown userLogin" id="loggeduser" >
						<span class="btn-outer-2" >
                            <!-- <button *ngIf="loyalit_member" routerLink="/my-account/icon-rewards" class="btn btn-link p-0" type="button" 
                            
                                >
                                    <img width="70" src="/assets/img/icon-rewards-logo-wt.svg"/>
                            </button> -->
                        <a [class.active]="isActivetoinside()" class="test" routerLink="/my-account/" (click)="redirectToUser();" style="cursor:pointer;" class="dropdown-toggle" data-toggle="dropdown">{{ loggedUserName }} &nbsp; <fa name="angle-down"></fa></a>
                        <ul class="dropdown-menu">

                            <li *ngFor="let menues of menuUser">
                                <a (click)="removeHeader();" *ngIf="menues.menuPath!='icon-rewards'" routerLinkActive="active" routerLink="/my-account/{{ menues.menuPath }}">{{ menues.menuUrl }}</a>
                                <a *ngIf="menues.menuPath=='icon-rewards';" [class.active]="isActiveforSubMenu()" (click)="menuactiveFunction()" routerLink="/my-account/{{ menues.menuPath }}">{{ menues.menuUrl }}</a>
                            </li>
                            
                            <li><a class="text-primary" href="javascript:void();" (click)="logOut()">Sign Out <fa name="sign-out"></fa></a></li>
                        </ul>
                        </span>
					</li>

                    <!-- <li *ngIf="isLoggedIn" class="dropdown userLogin" id="loggeduser">
                        <span class="btn-outer-2" >
                            <button [popover]="popTemplate"
                            [outsideClick]="true"
                            triggers=""  [isOpen]="isOpen"   
                            placement="bottom"   containerClass="customClass" class="btn btn-link p-0" type="button" 
                            
               >
                <img (click)="isOpen = !isOpen"  width="70" src="/assets/img/icon-rewards-logo-wt.svg"/>
        </button>
                        <a [class.active]="isActivetoinside()" class="test" routerLink="/my-account/" (click)="redirectToUser();" style="cursor:pointer;" class="dropdown-toggle" data-toggle="dropdown">{{ loggedUserName }} &nbsp; <fa name="angle-down"></fa></a>
                        <ul class="dropdown-menu">

                            <li *ngFor="let menues of menuUser">
                                <a (click)="removeHeader();" *ngIf="menues.menuPath!='icon-rewards'" routerLinkActive="active" routerLink="/my-account/{{ menues.menuPath }}">{{ menues.menuUrl }}</a>
                                <a *ngIf="menues.menuPath=='icon-rewards';" [class.active]="isActiveforSubMenu()" (click)="menuactiveFunction()" routerLink="/my-account/{{ menues.menuPath }}">{{ menues.menuUrl }}</a>
                            </li>
                            
                            <li><a class="text-primary" href="javascript:void();" (click)="logOut()">Sign Out <fa name="sign-out"></fa></a></li>
                        </ul>
                        </span>
                    </li> -->
                    
                    <!-- <ng-template #popTemplate>
                        <a class="close" (click)="isOpen = !isOpen" ><span style="color: #666;" class="fa  fa-times"></span></a>
                        <form class="p normallogin" name="form" role="form" id="floating-label-login-comp1" (ngSubmit)="f.form.valid && userSignIn(formData, f)" #f="ngForm"
                        novalidate>
                        <div class="text-center" id="errors-container"></div>
                        <h2 class="mar-top-xs mar-bottom-xs"><span class="text-primary">Save 10%</span> or more<br> when you sign up</h2>
                        <div class="form-group">
                            <button (click)="triggerEvent('ICON REWARDS')" routerLink='/icon-rewards-register' class="btn btn-primary btn-fill btn-block">Sign Up Now</button>
                        </div>
                    </form>
                </ng-template> -->
                </ul>
                <a href="javascrpt:; " class="visible-xs" routerLink="/"><img [src]="icon_logo"></a>
            </div>
            <div class="col visible-sm visible-xs">
                <button type="button " (click)="toggleData()" class="navbar-toggle  ">
                    <i class="fa fa-bars fa-2x text-primary" style="margin-top: -5px;" ></i>
              </button>
              <!-- <a (click)="callcustomer() " [href]="phone " class="text-white navbar-toggle pull-right"> 1 (877) 727-5464</a> -->

            </div>
            <!-- /.navbar-collapse -->
        </div>
        </div>

        <!-- /.container-fluid -->
    </nav>

<div #orangeBarWrap class="barWrapList" id="stripBar">
    <ng-container *ngIf="showbannerStripList">
        <div class="bannerWrap" *ngFor="let banner of bannerStripContent; let index = index" id="{{index}}" >  
            <ng-container *ngIf="banner?.turn_on_banner">
                <div  [innerHtml]="banner?.banner_content"></div>
                <a class="text-white" closeButton *ngIf="banner?.close_button_text" href="javascript:;" (click)="closeCross(index)">{{banner?.close_button_text}}</a>
                <a class="text-white"  closeButton *ngIf="!banner?.close_button_text" href="javascript:;" (click)="closeCross(index)">
                    <i class="fa fa-times" style="color: #fff!important;"></i></a>
            </ng-container>
        </div>
    </ng-container>
    <!-- <div class="strip1" *ngIf="showStrips">
         <p class="p-3 m-0 bg-white" >Lorem, ipsum dolor sit amet consectetur adipisicing elit. Labore blanditiis incidunt numquam doloribus repellat sint id magnam necessitatibus atque aliquam sed magni ducimus ullam fugiat vero, architecto deserunt quo soluta.

        <a href="javascript:;" (click)="closeCross()">Close</a>
    </p>
    </div> -->
   
 
   <!-- <app-orangebar-comp   *ngIf="showOrangeBar"  ></app-orangebar-comp> -->
 
</div>



        <app-loyality-badge *ngIf="showonhomepage" (updateTierToHeader)='updateTier($event)' [showTies]="showTies"></app-loyality-badge>



    <div class="modal fade login-reward" id="loginreward">
        <div class="modalWrapper">
        <div class="modal-dialog signBgTo">
            <div class="pad-top-sm" *isIphone></div>
            <div class="panel panel-default mar-bottom-0">
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true"><span class="fa fa-times"></span></button>
                <form class="p normallogin" name="form" role="form" id="floating-label-login-comp1" 
                    novalidate>
                    <div class="text-center" id="errors-container"></div>
                    <h2 class="mar-top-xs mar-bottom-xs"><span class="text-primary">Save 10%</span> Or more <br>when you sign up</h2>
                    <div class="form-group">
                        <button (click)="triggerEvent('ICON REWARDS')" routerLink="icon-rewards-register" class="btn btn-primary btn-fill btn-block">Sign Up Now</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
    </div>


  
 
</div>


<nav id="navDesk" class="navbar navbar navbar-custom hidden " role="navigation" [ngClass]= "{'navbar-fixed-top whitePane': isScrolled}">



<div class="container">


	<div class="flex-row topBar">
		<div class="  col-12 col-sm-12 col-md-12 col-lg-1 col-xl-1 align-self-center navbar-header  ">
				<a class="navbar-brand forMobile" routerLink="/"><img [src]="icon_logo" width="100"  alt="Icon Parking"></a> 
				<button (click)="toggleData()" type="button" class="btn btn-link pull-right navbar-toggle" id="sidebarBtn">
					<img [src]="menuImg" alt="image">
				</button>
				<div class="mob-mantinance-popup" *showItBootstrap="['xs','sm']"   >
					<div *ngIf="showMantinancepopup">
					<a class="btnmantinance mob-design" (click)="showMantinanceMsg()">
						<img class="mantinance-flag" alt="image" [src]="info_mentinence" width="17px">
					</a> 
				 <article *ngIf="mantinanceMsg" mobileFlag  class="text-uppercase" class="special-flag">

					<div  class="erlybirdDate ">
						<h3  *ngIf="mantinanceMsgContentData.title" class="text-white">{{mantinanceMsgContentData.title}}</h3>
						<p  [innerHTML]="mantinanceMsgContentData.description | safeHtml"></p>
						<a closeBtnFlag class="btn btn-link btn-xs pull-right" id="hideInofo" (click)="hideMantinanceMsg()" href="javascript:;">Dismiss&nbsp; <i class="fa fa-times"></i></a>
						<p class="text"></p>
					</div>
				</article>
				</div>
			</div>
				
				<!-- <a class="text-white" [href]="customerPhone"  class="iconContact visible-xs visible-sn" 
				onclick="ga('send', 'event', { eventCategory: 'menu Mobilecalls', eventAction: 'click'});callcustomer();">1 (877) 727-5464</a> -->

		</div>
		<div class="flex-grow-1" *hideItBootstrap="['xs','sm']"  >

				<div class="navbar-form navbar-left searchBar searchPageBar  " role="search" *ngIf="SearchPage"  >
						<div class="form-group">
							<app-google-autosuggest ></app-google-autosuggest>
						</div>
					</div> 
			</div>
			<div class="flex-grow-1 mr-auto hidden-xs hidden-sm " >

					<ul class="nav nav-pills navbar-right  ">
						<li>
							<a routerLinkActive="active" (click)="formonthly();">Monthly Parking</a>
						</li>
						<li><a href="javascript:;"  routerLink="/business-parking-nyc">ICON FOR BUSINESS </a></li>

							
							<!-- <li>
								<a routerLinkActive="active"  routerLink="/neighborhoods/central-park/monthly">OFFERS BY NEIGHBORHOOD</a>
							</li> -->
							<!-- <li>
								<a [class.active]="isActive()"   href="javascript:void(0)" (click)="redirectToPage()">icon rewards</a>
							</li> -->
							
							
							
							<li id="guestuser">
								<a [class.active]="logoutmenu()" data-toggle="modal" id="loginPopup" href='#login' (click)="resetLoginForm(f)" oncontextmenu="return false;">Login</a>
							</li>
							
							<li class="dropdown userLogin" id="loggeduser" >
								<a [class.active]="isActivetoinside()" class="test" routerLink="/my-account/"  (click)="redirectToUser();" style="cursor:pointer;" class="dropdown-toggle" data-toggle="dropdown">{{ loggedUserName }} <fa name="user"></fa></a>
								<ul class="dropdown-menu">
				
									<li  *ngFor="let menues of menuUser" ><a (click)="removeHeader();" *ngIf="menues.menuPath!='icon-rewards'" routerLinkActive="active" routerLink="/my-account/{{ menues.menuPath }}">{{ menues.menuUrl }}</a><a *ngIf="menues.menuPath=='icon-rewards';"><a [class.active]="isActiveforSubMenu()" (click)="menuactiveFunction()" routerLink="/my-account/{{ menues.menuPath }}">{{ menues.menuUrl }}</a></a></li>
									<li><a class="text-primary" href="javascript:void();" (click)= "logOut()">Sign Out <fa name="sign-out"></fa></a></li> 
								</ul>
							</li>
							<li>
								<a routerLinkActive="active" routerLink="/contact">Contact Us</a>
							</li>
							<li headerFlagContainer  *ngIf="showMantinancepopup" class="btn-mantinance-wrapper hidden-xs">
								<a class="btnmantinance" (click)="showMantinanceMsg()">
										<img class="mantinance-flag" style="margin-top:8px;" alt="image" [src]="info_mentinence" width="12px"> <div *ngIf="mantinanceMsg" toolTipDown ></div>
								</a> 
							 <article *ngIf="mantinanceMsg" headerFlag  class="text-uppercase" class="special-flag">

								<div  class="erlybirdDate ">
									<h3 *ngIf="mantinanceMsgContentData.title" class="text-white">{{mantinanceMsgContentData.title}}</h3>
									<p [innerHTML]="mantinanceMsgContentData.description | safeHtml" style="text-transform: none;"></p>
									<a closeBtnFlag class="btn btn-link btn-xs 1" id="hideInofo" (click)="hideMantinanceMsg()" href="javascript:;">Dismiss&nbsp; <i class="fa fa-times"></i></a>
									<p class="text"></p>
								</div>
							</article>

							</li>
							<!-- <li>
								<a [href]="customerPhone2" onClick="ga('send', 'event', { eventCategory: 'menu WebCalls', eventAction: 'click'});">1 (877) 727-5464</a>
							</li> -->
							<!-- for iconmenu bar -->
							 <li>
								  <a href="javascript:;" style="margin-top: -5px;" class="toggleMenuBtn" (click)="toggleData()" >
									<i aria-hidden="true" class="fa fa-navicon fa-2x" ></i>
								  </a>
							  </li>
							  <!-- for iconmenu bar ends -->
						</ul>
				</div>
	</div>
	

	<!-- Brand and toggle get grouped for better mobile display -->

	
	<!-- Collect the nav links, forms, and other content for toggling -->

    <div class="orangeBar bg-primary">
        <div class="row align-items-center">
            <div class="col">
                <p class="font-wight-bold">
                    Important reminder for our valued customers and business partners. In observance of Juneteenth, A Day of Freedom, Icon Parking Support Center Offices will be closed on Friday, June 18th. We appreciate your flexibility and planned approach to ensure all urgent needs are accommodated in advance and look forward to assisting you upon our return on Monday, June 21st. Not to worry, the garages will remain open at normal business hours.
                </p>
            </div>
            <div class="col-auto"><i class="fa fa-times fa-2x"></i></div>
        </div>

		</div>
        <!-- /.navbar-collapse -->
	</div>
	<app-loyality-badge *ngIf="showonhomepage" (updateTierToHeader)='updateTier($event)' [showTies]="showTies"></app-loyality-badge>
</nav>

<ng-container *ngIf="ViewAfterInitData">
<!-- <div *ngIf="isTierLoaded && showif" [class.hidden]="isSearchPage"  [ngClass]="isTierLoaded?'tier-header-container':''"></div> -->
<div *ngIf="isTierLoaded" [class.hidden]="SearchPageLook"  [class.faciltyPage]="isfcPage" [class.adminPage]="isAdminPage"  [ngClass]="isTierLoaded?'tier-header-container':''"></div>
<!-- login modal -->
<a *ngIf="redirectFromAdmin==1" (click)="redirectToAdmin()"  class="btn btn-primary btn-fill " mascotBtn>Stop acting as user<br>{{redirectUserName}}</a>
<div [style.height.px]="barHeight"[class.hidden]="SearchPageLook" ></div>
<div [style.height.px]="partnerBar"[class.d-none]="showonhomepage" ></div>
</ng-container>
