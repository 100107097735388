import { Component, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { NgForm } from '@angular/forms';
import * as $ from 'jquery';
import { GoogleMapComponent } from '../../Global/google-map/google-map.component';

import { CustomCode } from '../../../shared/CustomCode';
import { SearchPageScript } from '../../../shared/searchPageScript';
import { FormValidationErrors } from '../../../shared/formValidationErrors';
import { SearchpageserviceService } from '../../../services/searchpageservice.service';
import { PartnerService } from '../../../services/partner.service';
import { ToastrService } from 'ngx-toastr';
import { FilterHelper } from '../../../classes/filter-helper';
declare var Trustpilot;

@Component({
	selector: 'app-partners',
  templateUrl: './partners.component.html',
  styleUrls: ['./partners.component.scss'],
	providers: [SearchpageserviceService, PartnerService]
})
export class PartnersComponent implements OnInit {

	@ViewChild(GoogleMapComponent,{ static: true }) googleMap: GoogleMapComponent;

	partner_slug:string;
	startDuration:object;
	endDuration:object;
	isIphone=0;

	partner:any = {};
	partnerFacilities:any = {};

	defaultLengthOfStay:number = 2;
	defaultRadius:number = 1;

	dataDebugging:boolean = true;

	dSD:any = SearchPageScript.defaultDateForDailyMonthlyFacility();
	dailyEndDate: any = SearchPageScript.defaultDateForDailyMonthlyFacility();

	searchResult: any = {};
	currDateObj = new Date();
	bsValue:any;
	maxDate:any;

	isLoyality = false;
	coeff = 1000 * 60 * 30;
	sstartTime = new Date(Math.round(this.currDateObj.getTime() / this.coeff) * this.coeff);

	restrictRequestOnInit = 'stop';
	monthlyDate = SearchPageScript.defaultDateForDailyMonthlyFacility();
	endTime = new Date(Math.round(this.currDateObj.getTime() / this.coeff) * this.coeff);
	isShowLoyalyBadge = false;
	//isLoyality = false;
	stime;
	etime;
	minDate: Date;
	preventUnusedAjax = false;
	constructor(
		private router: Router,
		private activatedRouteObj: ActivatedRoute,
		private toastr: ToastrService,
		private searchPageObj: SearchpageserviceService,
		private activatedRoute: ActivatedRoute,
 		private partnerService: PartnerService
	) { }

	firstLoad = false;
	isPopup:boolean = false;
	patner_image:any='';
	barHeight:any;
	searchCriteria: any = {
		'type': CustomCode.defaultFacilityType,
		'device': CustomCode.searchMode(),
		'lat': CustomCode.timeSquareCoords.lat,
		'long': CustomCode.timeSquareCoords.long,
		'currdate': CustomCode.getDateForMapSearch(),
		'radius': this.defaultRadius,
		'partner_id' : 1,
		'length_of_stay': this.defaultLengthOfStay,
		'firstLoad' : this.firstLoad
	};

	ngOnInit() {
		if(localStorage.getItem('is_loyalty_active') && localStorage.getItem('is_loyalty_active')=='1')
		{
		  this.isLoyality = true;
		  this.isShowLoyalyBadge = true;
		  localStorage.setItem('showingLoyalityStrip', '1');
		  localStorage.removeItem('fromPartner');
		}
	setInterval(() => {
			if(localStorage.getItem('showingLoyalityStrip')){ this.isShowLoyalyBadge = true;
			} else { this.isShowLoyalyBadge = false; }
		} ,10 );

		if(localStorage.getItem('is_loyalty_active') && localStorage.getItem('is_loyalty_active')=='1')
    {
      this.isLoyality = true
    }

		if (navigator.userAgent.match(/(iPod|iPhone|iPad|Android)/i)) {
			this.isIphone = 1;
		}else{
			this.isIphone= 0;
		}





		localStorage.setItem('is_partner_page', '1');
		window.scrollTo(0,0);
           $("body").animate({ scrollTop: 0 }, 500);

		$('.dropdown-menu-right').on( 'click', 'a', function() {
		    var text = $(this).html();
		    $(this).closest('.dropdown').find('.dropdown-toggle').val(text).parent().find('td').removeClass('open');
		});

		this.activatedRoute.params.subscribe((params: Params) => {
			if (params['partner_slug']) {
		        this.partner_slug = params['partner_slug'];
				this.startDuration = FilterHelper.defaultStartDuration();
				this.endDuration = FilterHelper.defaultEndDuration();
				this.fetchPartnerData();
		    } else {
		    	// this.defaultAction();
		    }
      	});

		// this.updateRecordsOnLabelChange(this.searchCriteria.type);
		setTimeout(() => {
          $('label#' + this.searchCriteria.type).addClass('active');
        }, 100);		
	}
	ngAfterViewInit() {
		this.barHeight = (document.getElementById('NavbarInner') !== null) ? document.getElementById('NavbarInner').offsetHeight : 0;
	
		setInterval(()=>{
		  let getHeight = (document.getElementById('NavbarInner') !==null) ? document.getElementById('NavbarInner').offsetHeight: 0;
		  if(this.barHeight > getHeight){
			this.barHeight = getHeight;
		  }
		},0);
	
		
		}

	filtersSetup() {
		this.sstartTime = new Date(this.sstartTime.setMinutes(this.sstartTime.getMinutes() + 30));
		this.endTime = new Date(this.endTime.setMinutes(this.endTime.getMinutes() + 150));
		this.minDate = new Date();

		this.stime = CustomCode.getUserTime(this.sstartTime);
		this.etime = CustomCode.getUserTime(this.endTime);
		this.dSD = new Date();
		this.dailyEndDate = new Date();
		const splitDate = this.stime.split(' ');
		const getHour = splitDate[0].split(':');
		const amPm = splitDate[1];
		if(amPm === 'PM' && getHour[0] >= 10) {
			const dateObj = new Date();
			const nDate = new Date(dateObj.setDate(dateObj.getDate() + 1));
			const newMonth = nDate.getMonth() + 1;
			$('#enddate').val(newMonth + '/' + nDate.getDate() + '/' + nDate.getFullYear());
			this.dailyEndDate = nDate;

		}

		$('#stime').val(this.stime);
		$('#etime').val(this.etime);
	}

	fetchPartnerData() {
		this.partnerService.getPartnerDetails(this.partner_slug)
	      	.subscribe(
	      	(data:any) => {
	      		if (data.data) {
	      			this.partner = data.data;
	      			this.searchCriteria.lat = this.partner.geolocations.latitude;
	      			this.searchCriteria.long = this.partner.geolocations.longitude;
	      			this.searchCriteria.partner_id = this.partner.id;
					this.isPopup = true;
					localStorage.setItem('partnerData','1')
	      			setTimeout(() => {
						  if('content' in this.partner)
						  {
							$('div.popupBoxContent').append('<div>' + this.partner.content + '</div>');
						  }
					    if(this.partner.photos.length>0){
							this.patner_image=this.partner.photos[0].url;
						}
						
	      			}, 100);
	      			this.setSearchBarValue();
	      			this.googleMap.loadMap(this.searchCriteria);
	      			this.filtersSetup();
	      			this.fetchPartnerFacilityData();
	      		} else {
	      			this.toastr.error('Could not fetch partner details', 'Error!');
	      		}
	       	},
	      	error => {
	      		// window.location.href = "/search";
				  // this.router.navigate(['/search']);
				  let startDuration = FilterHelper.defaultStartDuration();
      let startTime = FilterHelper.getFormattedTime(startDuration);
      localStorage.setItem('monthlyDate', startDuration.toString())
      let endDuration = FilterHelper.defaultEndDuration();
      let endTime = FilterHelper.getFormattedTime(endDuration);
      let endTimeDataIfLess = FilterHelper.makeDurationDateObject(endDuration, endTime);
      localStorage.setItem('endttimehome',CustomCode.getDateForMapSearchSlashFormatHome(endDuration, endTime));
      localStorage.setItem('startimehome',CustomCode.getDateForMapSearchSlashFormatHome(startDuration, startTime));
      let stime =localStorage.getItem('startimehome');
	  //chnaged from monthly to daily for IPSD-4217
      this.router.navigate(['/search', '40.7589', '-73.9851', stime, 1,  'Times Square','daily', CustomCode.searchMode()]);
	  //this.router.navigate(['/search', '40.7589', '-73.9851', stime, 1,  'Times Square','monthly', CustomCode.searchMode()]);
	      		this.toastr.error('Sorry, Partner could not be found.', 'Error!');
	      	}
    	);
	}

	setSearchBarValue() {
		$('input#auto_suggest_input_field').val(this.partner.title);
	}

	fetchPartnerFacilityData() {
		if(localStorage.getItem('partnerData'))
		{
			if (!this.searchCriteria.partner_id) {
				return;
			}
			this.partnerService.getPartnerFacilities(this.preparePartnerFacilityData())
				.subscribe(
				(data:any) => {
					localStorage.removeItem('partnerData')
					if (data.data) {
						this.firstLoad = true;
						this.partnerFacilities = data.data;
						this.loadGoogleMapMarkers();
						this.preventUnusedAjax = true;
						this.searchCriteria.firstLoad = true;
					} else {
						this.toastr.error('Could not fetch partner facility details', 'Error!');
					}
				},
				error => {
					
				}
			);
		}
	}

	setTime()
	{
		if(this.searchCriteria.currdate.indexOf('-')>-1)
		{
			let time=this.searchCriteria.currdate.split('-') //12/14/2020 06:30:00   //2020,12,14 06:30:00
			let times=time[2].split(' ');
			let newTime=time[1]+'/'+times[0]+'/'+time[0]+' '+times[1]
			$('#lenth_stay').val(this.searchCriteria.length_of_stay)
			$('#time_search').val(newTime)
		}else{
			$('#lenth_stay').val(this.searchCriteria.length_of_stay)
			$('#time_search').val(this.searchCriteria.currdate)
		}	
		
	}

	preparePartnerFacilityData() {
		this.setTime();
			// $('#lenth_stay').val(this.searchCriteria.length_of_stay);
			// $('#time_search').val(this.searchCriteria.currdate);
		return {
			"arrival_time" 	: this.searchCriteria.currdate,
			"latitude" : this.searchCriteria.lat,
			"length_of_stay" : this.searchCriteria.length_of_stay,
			"longitude" : this.searchCriteria.long,
			"partner_id" : this.searchCriteria.partner_id,
			"radius" : this.defaultRadius,
			"type" : this.searchCriteria.type,

		};
	}

	flushLocalStorage() {		
		localStorage.removeItem('recent_search');		
		localStorage.removeItem('userLat');		
		localStorage.removeItem('userLong');		
		localStorage.removeItem('currDate');		
		localStorage.removeItem('facilityRadius');		
		localStorage.removeItem('locationUser');		
		localStorage.removeItem('searchType');		
		localStorage.removeItem('searchMode');		
	}

	manageDatePicker(val) {
		$('#' + val).trigger('click');
	}

	receiveNewFilters($event) {
		let device = this.searchCriteria.device;
		this.searchCriteria = $event;
		this.searchCriteria.device = device;
		this.fetchPartnerFacilityData();
	}

	receiveNewSearchFilter($event) {
		this.searchCriteria.length_of_stay = $event.length_of_stay;
		this.searchCriteria.currdate = $event.currdate;
		this.searchCriteria.type = $event.type;
		this.fetchPartnerFacilityData();
	}

	receiveMobileFilters($event) {
		this.searchCriteria = $event;
		this.fetchPartnerFacilityData();
	}

	updateRecordsOnLabelChange(val) {
		SearchPageScript.markLabelActive(val);
		this.searchCriteria.type = val;

		if (val === 'daily') {
			this.getEndTime(this.endTime);
		} else {
			this.getMonthlyDate();
		}
		const searchType = $('[name="monthlytype"].active').attr("id");
	}

	loadGoogleMapMarkers() {
		this.googleMap.loadMarkers(this.partnerFacilities);
	}

	getMonthlyDate() {
		if (this.preventUnusedAjax === true) {
			if ($('#monthlydate').val().toString().trim() !== '') {
				const mD = $('#monthlydate').val().toString().split('/');
				const fDate = mD[2] + '-' + mD[0] + '-' + mD[1];
				this.searchCriteria.currdate = fDate;
				this.fetchPartnerFacilityData();
			}
		}
	}

	getStartDate() {

	}

	getEndDate(event) {
	
	}

	updateEndTime() {
		let ssTime = this.sstartTime;
		let newsTime = new Date(ssTime);
		this.endTime = new Date(newsTime.setMinutes(newsTime.getMinutes() + 120));
		
		const stime = CustomCode.getUserTime(ssTime);

		const splitDate = stime.split(' ');
		const getHour = splitDate[0].split(':');
		const amPm = splitDate[1];
		if(amPm === 'PM' && parseInt(getHour[0]) >= 10) {
			const dateObj = new Date();
			const nDate = new Date(dateObj.setDate(dateObj.getDate() + 1));
			const newMonth = nDate.getMonth() + 1;
			$('#enddate').val(newMonth + '/' + nDate.getDate() + '/' + nDate.getFullYear());
			this.dailyEndDate = new Date(newMonth + '/' + nDate.getDate() + '/' + nDate.getFullYear());
		}
	}

	getStartTime($event) {

	}

	getEndTime($event) {

	}

	changeToFullTime(time) {
		const date = new Date();
		let hours;
		let minutes;
		let am_pm;
		const newTime = time.split(':');
		hours = newTime[0];
		const newT = newTime[1].split(' ');
		minutes = newT[0];
		am_pm = newT[1];
		if (am_pm == 'PM') {
			const convertToFullDate = ['12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '12'];
			hours = convertToFullDate[hours];
		}
		let returnVal = hours + ':' + minutes + ':00';
		returnVal.split('â').join('');
		return returnVal;
	}

	dismissPopup() {
		this.isPopup = false;
	}
}


