<app-header></app-header>
<app-lazy-load [isLoaded]="isLoadeds" [sectionName]="'content'"></app-lazy-load>

<section sourceFont class="newYerWrap" [ngClass]="{'liyalityMember':isLoyality}">
<div class="" [ngClass]="{'default-page-content':!isLoggedIn,'default-page-content-care':isLoggedIn ,'liyalityMember':isLoyality }" >
<div *ngIf="isLoyality" class="liyalityMember hidden-xs"></div>
<div *ngIf="isLoyality" class="liyalityMember-mob visible-xs"></div>


<div *ngIf="isLoadeds" class="page-wrapper commonPage orangeBar" [innerHTML]="staticPageContent.post_content | safeHtmlCoupon"></div>
</div>
</section>
<app-footer></app-footer>