<div class="campaignPage pad-top-lg monthly-landing">
	<div class="container"> 
		<div class="row">
			<div class="col-xs-12 col-sm-12 col-md-8 col-lg-8 mar-lr-auto">
				<div class="text-center">
					<h2 class="text-uppercase text-primary mar-top-0 customesize pad-bottom-xs">Buy Two months, get one free.<a href="javascript:;" [scrollTo]="'#offer-terms'" [scrollDuration]="1000" [scrollOffset]="-80">*</a></h2>
					<h3 class="text-uppercase text-white mar-top-xs customesize">
						Contact us now to reserve your monthly spot.<br/>
						mention this promotion to redeem.
					</h3>
					<p class="mar-top-sm mar-bottom-0">
						100 NYC Locations to Choose From.<br class="visible-xs"/> 12 Month Rate Guarantees Available.
					</p>
					<div class="clearfix mar-top-md mar-bottom-md support-section">
						<div class="row">
							<div class="col-sm-6 text-right">
								<a [href]="Customer_phone" class="mail-btn" onClick="callMonthlyCustomer()">
									<img [src]="phoneIcon" alt="Image" width="50">
									<span class="mar-left-xs">
										Call 1 (888) 705-7845<img class="mar-left-xs" [src]="back_chevron_whiteIcon" alt="image">
										<small>9am to 7pm</small>
									</span>
								</a>
								<p class="visible-xs pad-0 mar-0"></p>
							</div>
							<div class="col-sm-6 text-left">
								<a routerLink="/monthly-parking-information" class="mail-btn">
									<img [src]="mailIcon" alt="Image" width="50">
									<span class="mar-left-xs">
										Request Information<img class="mar-left-xs" [src]="back_chevron_whiteIcon" alt="image">
									</span>
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<div class="pad-bottom-md pad-top-md text-center text-uppercase">
	<div class="container">
		<h3 class="mar-top-0 mar-bottom-sm customesize">park monthly with icon and get special privileges</h3>
		<div class="row">
			<div class="col-xs-12 col-sm-12 col-md-8 col-lg-8 mar-lr-auto">
				<div class="row mar-bottom-sm">
					<div class="col-xs-12 col-sm-4 col-md-4 col-lg-4">
						<img [src]="arrowsIcon" alt="image" width="50" height="50">
						<h4>Unlimited in/out privileges</h4>
						<p class="visible-xs pad-bottom-xs"></p>
					</div>
					<div class="col-xs-12 col-sm-4 col-md-4 col-lg-4 mar-bottom-xs">
						<img [src]="map_markers" alt="image" width="50" height="50">
						<h4>50% off daily parking</h4>
						<p class="visible-xs pad-bottom-xs"></p>
					</div>
					<div class="col-xs-12 col-sm-4 col-md-4 col-lg-4 mar-bottom-xs">
						<img [src]="vehicleIcon" alt="image" width="50" height="50">
						<h4>Vehicle request service</h4>
					</div>
				</div>
				<div class="text-center">
					<a routerLink="/monthly-parking" class="btn btn-primary theme-btn">learn more about monthly parking</a>
				</div>
			</div>
		</div>
	</div>
</div>
<div class="offer-terms pad-bottom-sm pad-top-sm" id="offer-terms">
	<div class="container">
		<div class="row">
			<div class="col-xs-12 col-sm-12 col-md-8 col-lg-8 mar-lr-auto">
				<p class="text-primary text-uppercase">*Offer Terms</p>
				<ul class="dashList list-unstyled">
					<li>Promotional offer must be redeemed by 4/30/2018.</li>
					<li>Rate does not include any applicable tax.</li>
					<li>Offer not valid for customers who have had a monthly parking account with Icon or Quik Park within the last six months.</li>
				</ul>
			</div>
		</div>
	</div>
</div>