<app-header *ngIf="!forLoggedIn"></app-header>
<app-lazy-load [isLoaded]="isLoaded" [sectionName]></app-lazy-load>

  <div *ngIf="isLoaded" class="payment-section orangeBar dailyConfirm " [ngClass]= "{'onMyAcountPage': isMyAcoount, 'padding-0': !forLoggedIn}" >
  <div class="container">

		<!-- <ol class="breadcrumb pad-top-0 pad-bottom-0 pad-left-0"  >
				<li class="active"><a href="javascript:;" onclick="history.go(-1);" class="btn btn-link pad-left-0"><fa name="angle-left"></fa> &nbsp; Back</a></li>
    </ol> -->

    <div *ngIf="forLoggedIn" class="col-sm-12 row">
      <ol class="breadcrumb pad-left-0  pad-top-0 pad-bottom-0">
          <li class="active">
              <a href="javascript:;" onclick="history.go(-1);" class="btn text-uppercase btn-link pad-left-0 pad-right-xs">
                  <fa name="angle-left"></fa> &nbsp; My Reservations</a>
          </li>
      </ol>
    </div>
    
    <div *ngIf="!forLoggedIn" class="top-tab-in-payment"  [ngClass]="{'loyalityMemberSpace': !loyalityVal}" >
      <div *ngIf="loyalityVal" class="hidden-xs 1" style="margin-top: 120px"></div>
      <div *ngIf="!loyalityVal" class="hidden-xs 2" style="margin-top:80px"></div>
      <div *ngIf="loyalityVal" class="visible-xs 3" style="margin-top:80px"></div>
      <div *ngIf="!loyalityVal" class="visible-xs 4" style="margin-top:140px"></div>
      <div class="wizard">
          <div class="wizard-inner">
              <div class="connecting-line"></div>
              <!-- <div class="first-line-active"></div> -->
              <div class="first-line-success"></div>
              <ul class="nav nav-tabs" role="tablist">

                  <li role="presentation" class="active1">
                      <a>
                          <span class="round-tab">
                              <i class="fa fa-chevron-left"></i>
                          </span>
                          <b>Search Results</b>
                      </a>
                  </li>

                  <li role="presentation" class="active1">
                      <a data-toggle="tab" aria-controls="dailypaymenttab" role="tab">
                          <span class="round-tab">
                              <i class="fa fa-check"></i>
                          </span>
                          <b style="left: -44px;">Payment & Checkout</b>
                      </a>
                  </li>
                  <li role="presentation" class="active1 active">
                      <a>
                      <!-- <a href="#complete" data-toggle="tab" aria-controls="complete" role="tab"> -->
                          <span class="round-tab">
                              <i class="fa fa-check"></i>
                          </span>
                          <b>Booking Confirmed</b>
                      </a>
                  </li>
              </ul>
          </div>

              <!-- <div class="tab-content">
                  <div class="tab-pane" role="tabpanel" id="dailysearch">
                      <h3>Step 1</h3>
                      <p>This is step 1</p>
                      <ul class="list-inline pull-right">
                          <li><button type="button" class="btn btn-primary next-step">Save and continue</button></li>
                      </ul>
                  </div> 
                  <div class="tab-pane active" role="tabpanel" id="dailypaymenttab"></div>
              </div> -->
          </div>
  </div>
  <div *ngIf="isLoaded && cmsData !== undefined" id="confirmation-details-web" class="confirmation-details printData" [innerHTML]="cmsData | safeHtml"></div>

  <!-- <div class="white-box-sdw mar-top-sm srink-box">
    <div class="step-box-bg">
      <div class="head-title">
      <h2>Thank you for your reservation!</h2>
      <p class="mar-top-0">Please check your email for the reservation redemption voucher.</p>
      <p class="mar-top-0">Save reference code <strong class="text-primary">IQ948562</strong> for your records.</p>
      <p class="mar-top-0">See your reservation details below.</p>
      </div>
    </div>
      <div class="black-head-bg">
      <div class="row">
          <div class="col-xs-12 col-md-4">
          <div class="black-head-box white-box-sdw">
              <div class="black-header text-center bg-dark" style=" background-color: #000000; -webkit-print-color-adjust: exact;">
                  <h4 class="text-uppercase mar-top-0 mar-bottom-0" style="color: #ffffff; -webkit-print-color-adjust: exact;">Member rewards discount</h4>
              </div>
              <div class="black-body">
                  <div class="blak-reward"><img src="../../../assets/img/reward_logo2.png"></div>
                  <div class="b-list-box">
                  <p><b>Imadiate parking discount</b></p>
                  <p>Receive discounts on parking purchases.</p>
                  </div>
                  <div class="b-list-box">
                    <p><b>Annual Bonus</b></p>
                    <p>Be rewarded based on tier level.</p>
                  </div>
                  <div class="b-list-box">
                    <p><b>Best Pricing</b></p>
                    <p>Park for the lowest price.</p>
                   </div>
              </div>
              <div class="black-footer" style="min-height: 85px;">
              <p class="mar-top-xs pad-top-xs"><a class="btn theme-btn btn-primary theme-btn btn-block outline-btn" href="#">Sign up for icon Rewards</a></p></div>
          </div>
        </div>
        <div class="col-xs-12 col-md-4">
            <div class="black-head-box white-box-sdw">
                <div class="black-header text-center bg-dark"  style=" background-color: #000000; -webkit-print-color-adjust: exact;">
                    <h4 class="text-uppercase mar-top-0 mar-bottom-0" style="color: #ffffff; -webkit-print-color-adjust: exact;">icon go app-free car sanitization</h4>
                </div>
                <div class="black-body">
                    <h2 class="mar-top-sm mar-bottom-xs">Download App and receive <span class="text-primary">Complimentary Car Sanitization</span></h2>
                    <div class="car-app-box">
                        <div class="app-icon-go"><img src ="../../../assets/img/new-homepage/icon-go.svg"></div>
                        <div class="app-text-l">
                       <p>Select your phone’s app store below to redirect you to the page where you can download IconGo</p>
                       </div>
                     </div>
                </div>
                <div class="black-footer">
                  <div class="clearfix"></div>
                   <div class="mar-top-xs pad-top-xs row app-icon-logo">
                    <div class="col-xs-6"><a href="javascript:;"><img src = "../../../assets/img/new-homepage/AppStore.svg"></a></div>
                    <div class="col-xs-6 pad-left-0"><a href="javascript:;"><img src = "../../../assets/img/new-homepage/google-play.svg"></a></div>
                   </div>
                </div>
            </div>
          </div>
          <div class="col-xs-12 col-md-4 hidden-xs">
            <div class="black-head-box white-box-sdw">
                <div class="black-header text-center bg-dark" style=" background-color: #000000; -webkit-print-color-adjust: exact;">
                    <h4 class="text-uppercase mar-top-0 mar-bottom-0" style="color: #ffffff; -webkit-print-color-adjust: exact;">MONTHLY PARKING PROMO</h4>
                </div>
                <div class="black-body">
                    <h2 class="mar-top-sm mar-bottom-xs">Order now and we’ll <span class="text-primary">discount</span> the cost of your last purchase of $33.00</h2>
                    <div class="car-app-box">
                        <div class="app-text-l">
                       <p>Unlimited in/out privileges at home garage</p>
                       <p>Online and text message vehicle request</p>
                       <p>Online Account management</p>
                       </div>
                     </div>
                </div>
                <div class="black-footer pad-top-xs" style="min-height: 90px;">
                <p class="mar-top-xs"><a class="btn theme-btn btn-primary theme-btn btn-block outline-btn" href="javascript:;">GET MONTHLY PARKING</a></p></div>
            </div>
          </div>
      </div>
      </div>
  </div> -->
  <!-- <div class="reservation-details">
    <div class="reservation-head-bg">
    <div class="row">
        <div class="col-xs-12 col-md-8 col-sm-8">
        <div class="reservation-head-box white-box-sdw srink-box">
            <div class="reservation-header bg-primary" style="background-color: #ed8222;-webkit-print-color-adjust: exact;">
                <h4 class="text-uppercase mar-top-0 mar-bottom-0" style="color: #ffffff;-webkit-print-color-adjust: exact;">Reservation Details</h4>
            </div>
            <div class="reservation-body pad-top-sm">
            <div class="reservation-tab">
                  <div class="row">
                   <div class="col-md-3"><p><strong>Reference ID:</strong></p></div>
                   <div class="col-md-9"><p>IQ948562</p></div>
                  </div>
                  <div class="devide-border"></div>
                  </div>
                <div class="reservation-tab exit-tab">
                <div class="row mar-bottom-xs">
                    <div class="col-md-3"><p>  <strong>Enter After</strong></p></div>
                    <div class="col-md-9">
                      <p class="cc">
                        <img width="15" src="/assets/img/enter_after_icon.png" class="mar-right-xs"><img width="13" [src]="dateIcon" class="mar-right-xs">04/07/2020 &nbsp;&nbsp; <img width="14" [src]="timeIcon" class="mar-right-xs"> 08:30 AM
                      </p>
                    </div>
                </div>
                <div class="row">
                  <div class="col-md-3"><p><strong>Exit Before</strong></p></div>
                  <div class="col-md-9">
                    <p class="cc">
                      <img width="15" src="/assets/img/exit_before_icon.png" class="mar-right-xs"><img width="13" [src]="dateIcon" class="mar-right-xs">04/07/2020 &nbsp;&nbsp; <img width="14" [src]="timeIcon" class="mar-right-xs"> 06:30 PM
                    </p>
                  </div>
              </div>
                <div class="devide-border"></div>
               </div>
               <div class="reservation-tab">
                <div class="row">
                    <div class="col-md-3"><p><strong>Date Booked:</strong></p></div>
                    <div class="col-md-9">
                      <p>Sun, April 05, 2020 7:22PM</p>
                    </div>
                </div>
                <div class="devide-border"></div>
               </div>
               <div class="reservation-tab">
                <div class="row">
                    <div class="col-md-3"><p><strong>Guest Details:</strong></p></div>
                    <div class="col-md-9">
                      <p>Andrew Bernardo</p>
                      <p><a class="text-dark" href="mailto:drew@drexagency.com">drew@drexagency.com</a></p>
                      <p><a class="text-dark" href="tel:(702) 348-9370">(702) 348-9370</a></p>
                    </div>
                </div>
                <div class="devide-border"></div>
               </div>
               <div class="reservation-tab">
                <div class="row">
                    <div class="col-md-3"><p><strong>Vehicle:</strong></p></div>
                    <div class="col-md-9">
                      <p>Standard size vehicle</p>
                    </div>
                </div>
                <div class="devide-border"></div>
               </div>
               <div class="reservation-tab">
                <div class="row">
                    <div class="col-md-3"><p><strong>Payment Method:</strong></p></div>
                    <div class="col-md-9"><p class="cc"><img src="assets/img/visa.svg" alt="" height="16"><span class="mar-left-xs">Card Ending in 7213 Expiring 06/21</span></p></div>
                </div>
                <div class="devide-border"></div>
               </div>
               <div class="reservation-tab">
                <div class="row mar-top-xs  mar-bottom-xs">
                    <div class="col-md-12"><p class="reservation-value"><span>Amount charged to card</span> &nbsp; <strong>$624.50</strong> <span style="font-style: italic;" class="text-primary">You saved $2.00 !</span></p></div>
                    <div class="col-md-12"><a class="btn btn-primary btn-fill theme-btn mar-top-sm btn-radius" href="#">Print Reservation</a></div>
                </div>
               </div>
            </div>
        </div>
        </div>
        <div class="col-xs-12 col-sm-4 col-md-4 reservation-plcy">
          <div class="webSection white-box-sdw gray-mob">
            <div class="clearfix slideShow">
              <img class="img-responsive" src="../../../assets/img/park_garage.png" alt="image" style="width: 100%;">
            </div>
            <div class="reservation-rt">
              <h2 class="mar-top-0">Astor Parking LLC</h2>
              <p class="mar-top-xs cc">
                <fa name="phone" class="ismall text-primary"></fa> &nbsp; (212) 869-3543
              </p>
              <p class="cc">
                <img width="12" [src]="dateIcon" class="mar-right-xs">7 days a week
              </p>
              <p class="cc">
                <img width="14" [src]="timeIcon" class="mar-right-xs">24 hours a day
              </p>
              <p class="mar-top-xs mar-bottom-0">Between: &nbsp;<span>3rd Ave and 2nd Av</span></p>
              <p class="mar-top-0">Entrance: &nbsp;<span>279 East 6th</span></p>
              <div class="googleMap mar-top-xs" id="map-wrapper">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6044.279178532842!2d-73.98935742381136!3d40.75895446730697!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c25855b8fb3083%3A0xa0f9aef176042a5c!2sTheater%20District%2C%20New%20York%2C%20NY%2C%20USA!5e0!3m2!1sen!2sin!4v1597833360896!5m2!1sen!2sin" width="100%" height="210" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
              </div>
              <p class="mar-top-xs"><a target="_blank" href="https://maps.google.com?q={{respdata?.geolocations?.latitude}},{{respdata?.geolocations?.longitude}}" class="btn theme-btn btn-primary theme-btn btn-block outline-btn">get directions</a></p>
            </div>
          </div>
        </div>
    </div>
</div>
</div> -->
  <div class="clearfix reserItem"></div>
</div>
</div>
<app-saver-load [imageUrl]="imageCode" [isValidReservation]="isValidReservation" [showPopup]="showDialog" *ngIf="showDialog" (popEvent)="receiveShowDialog($event)"></app-saver-load>

<app-footer *ngIf="!forLoggedIn"></app-footer>