
import { Component, OnInit, Input } from '@angular/core';
import { MyAccountMonthlyParkingService } from '../../services/my-account-monthly-parking.service';
import { NgForm } from '@angular/forms';
import * as $ from 'jquery';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Component({
  selector: 'app-billing-information',
  templateUrl: './billing-information.component.html',
  styleUrls: ['./billing-information.component.scss'],
  providers: [MyAccountMonthlyParkingService]
})
export class BillingInformationComponent implements OnInit {

  @Input() currentActive: string;
  @Input() accountNumber: any;

  formData: any = {};
  phoneMask: any = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

  billingInformation: any = {};

  isLoaded = false;
  secondryPhone = false;
  showFields = false;
  showName = false;
  constructor(private myAccountMonthlyParkingObj: MyAccountMonthlyParkingService, private toastr: ToastrService, private router:Router) { }

  ngOnInit() {
    this.fetchUserStatusForTracking();
    // $(":input").inputmask();
  	this.getBillingInformation();
    this.myAccountMonthlyParkingObj.getAccountDetails(this.accountNumber)
    .then(
      (data:any)=> {
        let AccountData = data['data'];
        if( AccountData['account_type'] && AccountData.account_type == 'VM'){
          this.showFields = false;
        }else {
          this.showFields = true;
        }
      }
    );
  }

  getBillingInformation() {
  	this.myAccountMonthlyParkingObj.billingInformation(this.accountNumber)
    .then(
      (data:any)=> {
        this.isLoaded = true;
        this.formData = data['data'];
        this.showName = true;
        this.updateFormFieldFloats();
        if (this.formData.phone_type_two !== '') {
          this.showSecondryPhone();
        }
      },
      error => {
        this.showName = false;
        this.toastr.error(error.errors.message, 'Sorry!');
      }
    );
  }

  showSecondryPhone() {
  	if (this.secondryPhone) {
  		this.secondryPhone = false;
  		$('button.addSecondryButton').html('Add Secondary Number&nbsp;&nbsp;<i class="fa fa-plus"></i>');
      this.formData.phone_number_two = '';
      this.formData.phone_type_two = 'none';
      this.formData.phone_ext_two = '';
      this.formData.phone_contact_two = '';
  	} else {
  		this.secondryPhone = true;
  		$('button.addSecondryButton').html('Remove Secondary Number&nbsp;&nbsp;<i class="fa fa-minus"></i>');
  	}
    this.updateFormFieldFloats();
  }

  updateBillingInformation(formData, form: NgForm) {
    this.fetchUserStatusForTracking();
    const reg =/<(.|\n)*?>/g; 
    if (reg.test(formData.company_name) == true) {
      this.toastr.error('Please enter valid Company Name.', 'Sorry!');
      return false;
    } else {
        if (reg.test(formData.address_one) == true) {
          this.toastr.error('Please enter valid Address1.', 'Sorry!');
          return false;
        } else{
          if (reg.test(formData.address_two) == true) {
            this.toastr.error('HTML Tags are not allowed  in Address2.', 'Sorry!');
            return false;
          } else {
            if (reg.test(formData.city) == true) {
              this.toastr.error('Please enter valid City.', 'Sorry!');
              return false;
            }else{
              if (reg.test(formData.state) == true) {
                this.toastr.error('Please enter valid State.', 'Sorry!');
                return false;
              }
              else{
                if (reg.test(formData.zip) == true) {
                  this.toastr.error('Please enter valid Zip.', 'Sorry!');
                  return false;
                }
                else{
                  if (reg.test(formData.phone_extn_one) == true) {
                    this.toastr.error('Please enter valid phone extension.', 'Sorry!');
                    return false;
                  }
                  else{
                   //return true;
                  }
                }
              }
            }
          }
        }
    }
    const data = formData;
    data.phone_number_one = data.phone_number_one.replace(/\D/g, '');
    data.phone_number_two = data.phone_number_two.replace(/\D/g, '');
    if(!this.showFields && data.hasOwnProperty('country')){
      delete data.country;
    }
    if(!this.showFields && data.hasOwnProperty('phone_contact_one')){
      delete data.phone_contact_one;
    }
    if(!this.showFields && data.hasOwnProperty('address_type')){
      delete data.address_type;
    }
    this.myAccountMonthlyParkingObj.updatebillingInformation(data, this.accountNumber)
    .then(
      (data:any)=> {
        if (!data['errors']) {
          this.toastr.success('Billing Information Updated Successfully', 'Great!');
        } else {
          this.toastr.error('Some Error Occured, Try Again', 'Sorry!');
        }
      },
      error => {
        let errorMessage = error.errors.message;
        if (error.errors.message === 'Validation Errors') {
          errorMessage = '';
          for (const key in error.errors.detail) {
              errorMessage += error.errors.detail[key];
          }
        }
        this.toastr.error(errorMessage, 'Sorry!');
      }
    );
  }

  updateFormFieldFloats() {
  	setTimeout(function(){
  		$('form#billingForm input, form#billingForm select').each(function() {
	  		if ($(this).val() != null && $(this).val().toString().trim().length > 0) {
	  			$(this).parents('.form-group').addClass('focused');
	  		}
	  	});
  	}, 500);

  }

  restDigit(event: any){
    if (event.target.value.length > 1 && event.keyCode != 8 && event.keyCode != 9) {
      event.preventDefault();
      this.toastr.error('Please enter valid two digit state code.', 'Sorry!');
    }
  }

  fetchUserStatusForTracking(){
    let getUserId = '0';
    let getUserEmail = '0';
    let user_login_type = '';
    let isLogin = false;
    if(localStorage.getItem('access_token')){
     isLogin = true;
       getUserId = localStorage.getItem('userid');
       getUserEmail = localStorage.getItem('email');
       user_login_type = localStorage.getItem('user_login_type');
    }
    const loginStatusTrack = {
      'event' : 'pageview',
      'loginStatus' : isLogin? 'logged in': 'Not Logged IN', // 'logged in/Not Logged IN',
      'authenticationMethod' : user_login_type,
      'userId' : getUserId,
      'pageType' : this.router.url,
      'email': getUserEmail,
     };
    (<any>window).dataLayer.push(loginStatusTrack);
    }

}
