import { Component, OnInit, Input } from '@angular/core';

import { MyAccountMonthlyParkingService } from '../../services/my-account-monthly-parking.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Component({
  selector: 'app-current-invoice',
  templateUrl: './current-invoice.component.html',
  styleUrls: ['./current-invoice.component.scss'],
  providers: [MyAccountMonthlyParkingService]
})
export class CurrentInvoiceComponent implements OnInit {

  @Input() currentActive: string;
  @Input() accountNumber: any;

  currentInvoice: any = [];
  invoiceDate: any;
  billTotel: any;

  imageCode: any;

  isLoaded = false;
  showDialog = false;
  noInvoice:boolean = false;

  constructor(
    private myAccountMonthlyParkingObj: MyAccountMonthlyParkingService,
    private toastr: ToastrService,
    private router: Router,
    private sanitizer: DomSanitizer) { }

  ngOnInit() {
    this.fetchUserStatusForTracking();
    this.getCurrentInvoice();
  }

  receiveShowDialog($event) {
    this.showDialog = $event;
  }

  getCurrentInvoice() {
    this.fetchUserStatusForTracking();
    this.myAccountMonthlyParkingObj.currentInvoice(this.accountNumber)
    .then(
      (data:any)=> {
        this.isLoaded = true;
        if (data['data'] != '' && data['data'] != null) {
          this.invoiceDate = data['data'][0].invoice_date;
          this.billTotel = data['data'][0].bill_total;
          this.currentInvoice = data['data'];
          this.noInvoice = false;
        } else {
          this.noInvoice = true;
          this.toastr.error('Couldn\'t Find Any Current Invoice For This Account', 'Sorry!');
        }
      },
      error => {
        this.toastr.error(error.errors.message, 'Sorry!');
      }
    );
  }

  openPdf(event) {
    this.myAccountMonthlyParkingObj.currentInvoiceJpg(this.accountNumber)
    .then(
      (data:any)=> {
        const urlCreator = window.URL;
        const blobData = this.sanitizer.bypassSecurityTrustUrl(urlCreator.createObjectURL(data));
        this.imageCode = blobData['changingThisBreaksApplicationSecurity'];
        //window.open(this.imageCode);
        this.showDialog = true;
      },
      error => {
        this.toastr.error(error.errors.message, 'Sorry!');
      }
    );

  }

  downloadInvoice(event) {
    this.fetchUserStatusForTracking();
    this.myAccountMonthlyParkingObj.currentInvoicePdf(this.accountNumber, this.invoiceDate)
    .then(
      (data:any)=> {
       
      },
      error => {
        this.toastr.error(error.errors.message, 'Sorry!');
      }
    );
  }

  fetchUserStatusForTracking(){
    let getUserId = '0';
    let getUserEmail = '0';
    let user_login_type = '';
    let isLogin = false;
    if(localStorage.getItem('access_token')){
     isLogin = true;
       getUserId = localStorage.getItem('userid');
       getUserEmail = localStorage.getItem('email');
       user_login_type = localStorage.getItem('user_login_type');
    }
    const loginStatusTrack = {
      'event' : 'pageview',
      'loginStatus' : isLogin? 'logged in': 'Not Logged IN', // 'logged in/Not Logged IN',
      'authenticationMethod' : user_login_type,
      'userId' : getUserId,
      'pageType' : this.router.url,
      'email': getUserEmail,
     };
    (<any>window).dataLayer.push(loginStatusTrack);
    }

}
