<!-- <app-header class="searchHeader" [showTies]="false" (click)="showpopUpOnmobile();"></app-header>
<div (click)="showpopUpOnmobile();" (mouseenter) ="showPop()" class=" searchFilter ">
	<app-search-filters   (updateFacilityFilters)="receiveFacilityNewSearchFilter($event)" (updateFilters)="receiveNewSearchFilter($event)" (updateFiltersMobile)="receiveNewSearchFilterMobile($event)" (updateFiltersOnSubmit)="receiveNewSearchFilterOnSubmit($event)"></app-search-filters>
</div>
<app-google-map (updateNeighborhoodFilters)="neighborhoodSearchFilter($event)" (updateFacilityFilters)="receiveFacilityNewSearchFilter($event)" (updateFilters)="receiveNewFilters($event)" (updateMobileFilters)="receiveMobileFilters($event)"></app-google-map> -->
<!-- <app-footer   class="hidden-xs"  *ngIf="searchCriteria.device != 'app'"></app-footer> -->


<app-header class="searchHeader" [showTies]="false" (click)="showpopUpOnmobile()" (findData)="findDatas()"></app-header>

<div (click)="showpopUpOnmobile();" (mouseenter) ="showPop()" id="searchFilterComp" class=" searchFilter ">
	<app-search-filters   (updateFacilityFilters)="receiveFacilityNewSearchFilter($event)" (updateFilters)="receiveNewSearchFilter($event)" (updateFiltersMobile)="receiveNewSearchFilterMobile($event)" (updateFiltersOnSubmit)="receiveNewSearchFilterOnSubmit($event)"></app-search-filters>
</div>

<app-google-map (updateFiltersDragMap)="receiveNewFiltersdrag($event)" (updateNeighborhoodFilters)="neighborhoodSearchFilter($event)" (updateFacilityFilters)="receiveFacilityNewSearchFilter($event)" (updateFilters)="receiveNewFilters($event)" (updateMobileFilters)="receiveMobileFilters($event)"></app-google-map>
<!-- <app-footer   class="hidden-xs"  *ngIf="searchCriteria.device != 'app'"></app-footer> -->
