import { Component, OnInit } from '@angular/core';
declare var Trustpilot;
@Component({
  selector: 'app-trustbox',
  templateUrl: './trustbox.component.html',
  styleUrls: ['./trustbox.component.scss']
})
export class TrustboxComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    const trustboxRef = document.getElementById('trustbox');
    Trustpilot.loadFromElement(trustboxRef);
  }

}
