import { Component, OnInit, Input } from '@angular/core';
import { MyAccountMonthlyParkingService } from '../../services/my-account-monthly-parking.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Component({
  selector: 'app-previous-invoices',
  templateUrl: './previous-invoices.component.html',
  styleUrls: ['./previous-invoices.component.scss'],
  providers: [MyAccountMonthlyParkingService]
})
export class PreviousInvoicesComponent implements OnInit {

  @Input() currentActive: string;
  @Input() accountNumber: any;

  previousInvoices: any = [];
  currentPage: any = 1;
  imageCode: any;
  lastPage: any;
  nextPage: any = null;
  prevPage: any = null;
  nextPageUrl = '';

  isLoaded = false;
  prevPageUrl = '';
  showDialog = false;

  constructor(
      private myAccountMonthlyParkingObj: MyAccountMonthlyParkingService,
      private toastr: ToastrService,
      private router: Router,
      private sanitizer: DomSanitizer) { }

  ngOnInit() {
    this.fetchUserStatusForTracking();;
    this.getPreviousInvoices(this.currentPage);
  }

  getPreviousInvoices(pageNum) {
    this.myAccountMonthlyParkingObj.previousInvoices(this.accountNumber, pageNum)
    .then(
      (data:any)=> {
        this.isLoaded = true;
        if (data['data'].data != '' && data['data'].data != null) {
          this.previousInvoices = data['data'].data;
          this.lastPage = data['data'].last_page;
          this.currentPage = data['data'].current_page;
          if (data['data'].next_page_url != null) {
            this.nextPageUrl = data['data'].next_page_url;
            this.nextPage = this.nextPageUrl.split('?page=')[1];
          } else {
            this.nextPage = null;
          }

          if (data['data'].prev_page_url != null) {
            this.prevPageUrl = data['data'].prev_page_url;
            this.prevPage = this.prevPageUrl.split('?page=')[1];
          } else {
            this.prevPage = null;
          }
        } else {
          this.toastr.error('Couldn\'t Find Any Previous Invoice For This Account', 'Sorry!');
        }
      },
      error => {
      }
    );
  }

  loadPreviousInvoices(pageNum) {
    this.fetchUserStatusForTracking();
    this.getPreviousInvoices(pageNum);
  }

  receiveShowDialog($event) {
    this.showDialog = $event;
  }

  openPdf(date) {
    this.fetchUserStatusForTracking();
    this.myAccountMonthlyParkingObj.previousInvoiceJpg(this.accountNumber, date)
    .then(
      (data:any)=> {
        const urlCreator = window.URL;
        const blobData = this.sanitizer.bypassSecurityTrustUrl(urlCreator.createObjectURL(data));
        this.imageCode = blobData['changingThisBreaksApplicationSecurity'];
        //window.open(this.imageCode);
        this.showDialog = true;
      },
      error => {
      }
    );
  }

  downloadInvoice(date) {
    this.fetchUserStatusForTracking();
    this.myAccountMonthlyParkingObj.previousInvoicePdf(this.accountNumber, date)
    .then(
      (data:any)=> {
      },
      error => {
      }
    );
  }

  fetchUserStatusForTracking(){
    let getUserId = '0';
    let getUserEmail = '0';
    let user_login_type = '';
    let isLogin = false;
    if(localStorage.getItem('access_token')){
     isLogin = true;
       getUserId = localStorage.getItem('userid');
       getUserEmail = localStorage.getItem('email');
       user_login_type = localStorage.getItem('user_login_type');
    }
    const loginStatusTrack = {
      'event' : 'pageview',
      'loginStatus' : isLogin? 'logged in': 'Not Logged IN', // 'logged in/Not Logged IN',
      'authenticationMethod' : user_login_type,
      'userId' : getUserId,
      'pageType' : this.router.url,
      'email': getUserEmail,
     };
    (<any>window).dataLayer.push(loginStatusTrack);
    }
}
