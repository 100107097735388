<div class="campaignPage pad-top-lg march-madness">
	<div class="container">
		<div class="row">
			<div class="col-xs-12 col-sm-12 col-md-9 col-lg-8 mar-lr-auto">
				<div class="text-center">
					<h2 class="text-uppercase text-primary mar-top-0 customesize"> Spending an hour trying to find a parking spot is our idea of madness.</h2>
					<h3 class="text-uppercase text-white mar-top-sm"> Use code <span class="text-primary">{{ promoCode }}</span> for 10% off basketball parking at any <br *isDesktop>Icon or Quik Park garage in NYC*</h3>
					<div class="buttonWrap mar-top-sm">
						<button type="button" class="btn btn-fill btn-primary text-white" (click)="savePromocode('madison-square')" >Save code</button><span class="mar-left-xs"></span><button type="button" class="btn btn-fill btn-primary text-white" (click)="savePromocode('madison-square')">book now</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<div class="pad-bottom-md pad-top-md">
	<div class="container">
		<div class="row">
			<div class="col-xs-12 col-sm-12 col-md-9 col-lg-8 mar-lr-auto">
				<a href="javascript:;" (click)="savePromocode('madison-square')">
					<img class="full-width hidden-xs" [src]="gameday_parking_desktop" alt="image">
					<img class="full-width visible-xs" [src]="gameday_parking_mobile" alt="image">
				</a>
				<div class="offer-terms">
					<p class="text-primary text-uppercase mar-top-sm">*Offer Terms</p>
					<ul class="dashList list-unstyled">
						<li> Promotion ends on 3/11/2018. </li>
						<li> This code cannot be combined with other promotions and is limited to one use per individual. </li>
						<li> Promo code only valid for online pre-paid reservations made through the website or Icon GO app. Customers must be registered users logged in to their accounts to use promo code at check-out. </li>
					</ul>
				</div>
			</div>
		</div>
	</div>
</div>