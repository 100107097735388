<app-header></app-header>

<app-lazy-load [isLoaded]="isLoadeds" [sectionName]="'content'"></app-lazy-load>
<ng-template [ngIf]="isLoadeds" >

  <div class="page-header orangeBar specialPage opentable">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-sm-8">
          <div class="section" [innerHtml]="pageData?.content?.rendered" ></div>
        </div>
      </div>
    </div>
</div>
<div class="pageWrapperLadning orangeBar opentable2">
  <div class="container mt-5  ">
    <div *ngIf="pageData?.details_section" class="clearfix text-center leadText" [innerHtml]="pageData?.details_section"></div>
  </div>
</div>

  <div class="pageWrapperLadning orangeBar opentable2">
      <div *ngIf="showList" class="container blockSections mt-5 pb-5 ">
        <div class="row justify-content-center">
            <div class="col-12 col-md-12 col-lg-10">
      <div class="row justify-content-center">
        <ng-container *ngFor="let offer of promocodeList">
          <div class="col-sm-12 col-md-6 col-lg-5 mb-3">
              <div class=" grayBox p-4 text-center">
            <h2 *ngIf="offer?.offer_title" [innerHtml]="offer.offer_title"></h2>
            <p *ngIf="offer?.offer_subtitle" class="promo"><small [innerHtml]="offer.offer_subtitle"></small>
               </p>
               <h4>Promo Code:</h4>
                  <h3 class="cwDsgn  mb-3">
                    {{offer.promocode}}
                  </h3>
                  <div class="clearfix"></div>
                  <button class="btn btn-primary btn-block  btn-fill " (click)="redirctToSearch(offer.promocode,offer.promocode_type)" >{{(offer?.button_label)? offer.button_label: 'Book Now'}}</button>
                  <p class="terms-of-coupon" *ngIf="offer?.offer_terms" [innerHtml]="offer.offer_terms"></p>
              </div>
            </div>
      </ng-container>
        
      </div>
        </div>   
      </div>
        <div *ngIf="pageData?.about_description" class="row justify-content-center opentable-footer" [innerHtml]="pageData?.about_description" ></div>
    </div>
    </div>

</ng-template>
<app-footer></app-footer>
