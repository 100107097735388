<!-- app header -->
<app-header></app-header>
<div [innerHTML]="aboutContent.content" class="hidden"></div>
<!-- about us page content -->
<div class="page-header orangeBar" id="about-us"></div>
<app-lazy-load [isLoaded]="isLoaded" [sectionName]></app-lazy-load>
<div class="aboutUs" *ngIf="isLoaded">
	<section class="about-us pad-top-md pad-bottom-md">
		<span class="iconSignreverse pull-right text-right">
			<img [src]="cmsPageContent.park_sign_image" alt="Image" class="img-responsive">
		</span>
		<div class="container">
		
				<div class="contentAbout">
						<h1 class="text-uppercase text-primary mar-top-0 mar-bottom-sm">{{cmsPageContent.post_title}}</h1>
			
			<div [innerHTML]="cmsPageContent.post_content"></div>
				</div>
		
		
			
			<h3 *ngIf="cmsPageContent?.citizens_parking_icon" class="mar-top-md pad-bottom-xs"><img [src]="cmsPageContent?.citizens_parking_icon" class="img-responsive" alt="Image"></h3>
			{{cmsPageContent?.citizens_parking_content}}
		</div>
	</section>
	<section class="bg-gray-lighter pad-top-md pad-bottom-md text-center">
		<div class="container">
			<h2 class="text-primary text-uppercase mar-top-0">{{cmsPageContent.core_values_title}}</h2><br/>
			<div class="row">
				<div class="col-md-10 col-lg-10 col-md-offset-1 col-lg-offset-1">
					<div class="row">
						<div class="col-xs-12 col-sm-4 col-md-4 col-lg-4">
							<img class="mar-bottom-xs" [src]="cmsPageContent.passion_section_icon" alt="heart">
							<h4 class="mar-top-xs mar-bottom-xs">{{cmsPageContent.passion_section_title}}</h4>
							<p class="pad-top-xs">
								{{cmsPageContent.passion_section_content}}
							</p>
							<p class="visible-xs">&nbsp;</p>
						</div>
						<div class="col-xs-12 col-sm-4 col-md-4 col-lg-4">
							<img class="mar-bottom-xs" [src]="cmsPageContent.integrity_section_icon" alt="heart">
							<h4 class="mar-top-xs mar-bottom-xs">{{cmsPageContent.integrity_section_title}}</h4>
							<p class="pad-top-xs">
								{{cmsPageContent.integrity_section_content}}
							</p>
							<p class="visible-xs">&nbsp;</p>
						</div>
						<div class="col-xs-12 col-sm-4 col-md-4 col-lg-4">
							<img class="mar-bottom-xs" [src]="cmsPageContent.service_excellence_section_icon" alt="heart">
							<h4 class="mar-top-xs mar-bottom-xs">{{cmsPageContent.service_excellence_section_title}}</h4>
							<p class="pad-top-xs">
								{{cmsPageContent.service_excellence_section_content}}
							</p>
							<p class="visible-xs">&nbsp;</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
	<section class="stats-section text-uppercase text-white text-center" id="animate">
		<div class="container">
			<div class="row">
				<div class="col-xs-12 col-sm-4 col-md-4">
					<h1><span class="count counter">200</span>+</h1>
					<h4>Locations</h4>
				</div>
				<p class="visible-xs col-xs-12 mar-bottom-sm"></p>
				<div class="col-xs-12 col-sm-4 col-md-4">
					<h1><span class="count counter">40,000</span>+</h1>
					<h4>Cars Parked Daily</h4>
				</div>
				<p class="visible-xs col-xs-12 mar-bottom-sm"></p>
				<div class="col-xs-12 col-sm-4 col-md-4">
					<h1><span class="count counter">50</span>+</h1>
					<h4>Years in business</h4>
				</div>
			</div>
		</div>
	</section>
</div>
<!-- app footer -->
<app-footer></app-footer>