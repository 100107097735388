<app-sidenav></app-sidenav>
<!-- menu toggle button -->
<div class="clearfix"></div>
<button type="button" (click)="toggleData()" class="btn pull-left menuToggle btn-link">
			<fa size="2x" name="navicon"></fa>
	</button>

<!-- Filter toggle Button -->

<div class="listToggleFilter btn-group btn-group-justified hidden" data-toggle="buttons">
    <label class="btn btn-black" (click)="mSortBy('price')">
			<input  type="radio" class="radio" name="filt" id="option1" autocomplete="off" checked> Sort By Price
		</label>
    <label class="btn btn-black active" (click)="mSortBy('distance')">
			<input  type="radio" class="radio" name="filt" id="option2" autocomplete="off"> Sort By Distance
		</label>
</div>

<!-- list toggle buttons -->

<button type="button" (click)="listButtonClick()" class="btn btn-link listBtn hidden">
		<i class="ti-menu-alt fa-2x"></i>

	</button>
<div class="clearfix"></div>

<!-- Searchbar -->

<!-- search pahel here -->


<div class="SearchPanelMobile SearchPanel  MobSearch "  [class.partnerSearchHide]="partnerSearchHideOn">
    <form role="search">
        <app-google-autosuggest></app-google-autosuggest>
    </form>
</div>


<app-login></app-login>