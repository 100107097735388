import { Component, OnInit, Input, TemplateRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import * as $ from 'jquery';
import { MyAccountMonthlyParkingService } from '../../services/my-account-monthly-parking.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal'
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
@Component({
  selector: 'app-cancellation-notice',
  templateUrl: './cancellation-notice.component.html',
  styleUrls: ['./cancellation-notice.component.scss'],
  providers: [MyAccountMonthlyParkingService]
})

export class CancellationNoticeComponent implements OnInit {
  @Input() currentActive: string;
  @Input() accountNumber: any;
  modalRef: BsModalRef;
  formData: any = {};
  autopay = 0;
  successRes;
  isCancelled = false;
  showForm = true;
  cnNotify = false;
  minDate = new Date();
  termination_date: any = new Date();
  defaultTerminationDate: '';
  Validation = false;
  disableBtn = false;
  tenant_status: any = [];
  counter = 0;
  isLoaded = false;
  isSubscriberBasedUserLoaded = false;
  isNonSubscriberBasedUserLoaded = false;
  isAccontLoaded = false;
  tenantDetails: any = {};
  showPlan: any = false;
  currentPlan = ''
  facility: any = ''
  addFacility: any = ''
  disclaimer: any = ''
  // disabledHideButton = true
  is_yes = false;
  showDisclaimer: boolean = false;
  isSubscriptionBasedUser: boolean = false;
  isAllUnchecked: boolean = false;
  subscriptionDetails: any;
  tenants: any = [];
  tenantLists: any = [];
  cancel_reason: any = [
    {
      "reason_name": "Relocating Within NYC",
      "id": "1"
    },
    {
      "reason_name": "No Longer Require Monthly Parking",
      "id": "2"
    },
    {
      "reason_name": "Price",
      "id": "3"
    },
    {
      "reason_name": "Service",
      "id": "4"
    },
    {
      "reason_name": "Other",
      "id": "5"
    }
  ]

  constructor(
    private myAccountMonthlyParkingObj: MyAccountMonthlyParkingService,
    private toastr: ToastrService,
    private modalService: BsModalService,
    private router: Router
  ) { }
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, { class: 'modal-sm actionModal top-center-modal', backdrop: 'static' });
  }

  confirm(): void {
    this.is_yes = true;
    $('#submitbutton').trigger('click');
    this.modalRef.hide();
  }

  decline(): void {
    this.is_yes = false

    this.modalRef.hide();
  }

  ngOnInit() {
    this.fetchUserStatusForTracking();
    if (!localStorage.getItem('pageRefreshNew')) {
      // window.location.reload(true);
      //localStorage.setItem("pageRefreshNew", "1");
    }

    this.termination_date = new Date(this.termination_date.setDate(this.termination_date.getDate() + 1));
    this.cnNotify = true;
    this.isCancelled = false;
    this.showForm = true;
    this.minDate = new Date(this.minDate.setDate(this.minDate.getDate() + 1));

    // this.getTenantNumberDataCancelApi();
    this.fetchMonthlyParkingAccount();
    // this.updateLabel();
  }

  checkthis() {
    //alert(this.tenantDetails['data'])
  }

  updateDisclaimer() {
    if (this.termination_date) {
      if ($('.cancel-options li input:checkbox:checked').length !== 0) {
        this.isAllUnchecked = false;
        this.updateLabel();
      } else {
        this.showDisclaimer = false;
        this.isAllUnchecked = true;
        this.disableBtn = false;
      }
    }
  }

  getTenantNumberDataCancelApi() {
    this.myAccountMonthlyParkingObj.monthlyCancellationRequest(this.accountNumber)
      .then(
        (data:any)=> {
          this.tenantDetails = data;
          this.successRes = true;
          this.isNonSubscriberBasedUserLoaded = true;
          //this.disabledHideButton=this.tenantDetails['data']
          var k = 0;
          if (this.tenantDetails.status != 404) {
            for (var key in this.tenantDetails['data']) {
              if ('Status' in this.tenantDetails['data'][key]) {
                var status = this.tenantDetails['data'][key].Status;
                if (status == '1' || status == '0') {
                  this.tenant_status[k] = k;
                  k++;
                }
              }
            }
            if (typeof this.tenant_status != "undefined") {
              if (this.tenant_status.length == this.tenantDetails['data'].length) {
                this.disableBtn = false;
              }
            }
          }
        },
        error => {
          this.successRes = false;
          this.isNonSubscriberBasedUserLoaded = true;
          this.toastr.error(error.errors.message, 'Sorry!');
        }
      );
  }

  getAllTenantsRequest() {
    this.myAccountMonthlyParkingObj.getAllTenants(this.accountNumber).then(
      (res:any)=> {
        this.tenantLists = res['data'];
        this.successRes = false;
        this.isSubscriberBasedUserLoaded = true;
      }, error => {
        this.successRes = false;
        this.isSubscriberBasedUserLoaded = true;
        this.toastr.error(error.errors.message, 'Sorry!');
      }
    )
  }

  // get tenant number of account

  updateLabel() {
    // this.disabledHideButton = false;
    let newDate = new Date(this.termination_date);
    let day = newDate.getDate();
    let month = ("0" + (newDate.getMonth() + 1)).slice(-2);
    let year = newDate.getFullYear();
    const terminationDate = year + '-' + month + '-' + day;
    if(this.isSubscriptionBasedUser)
    {
    if (($('.cancel-options li input:checkbox:checked').length <= 0)) {
      this.toastr.error("Please select tenant number(s)", 'Error!');
      $('#termination_date').val('');
      this.termination_date = '';
      return
    }

    let tenantsIds = [];
    $('.cancel-options li input:checkbox:checked').each(function (i) {
      var dataId = $(this).attr('id');
      let Tenant_ID = dataId.split('-')[0];
      tenantsIds.push(Tenant_ID);
    });

    let data = {
      "account_no": this.accountNumber,
      "termination_date": terminationDate,
      "tenant_ids": tenantsIds.join(',')
    }
    this.myAccountMonthlyParkingObj.CancelRequestDateTCmeassge(data)
      .then(
        (data:any)=> {
          // this.disabledHideButton = true;
          if (!data['errors']) {
            //this.checkAutopay();
            if (data['data'].amount > 0) {
              this.disclaimer = data['data'].disclaimer;
              this.disableBtn = false;
              this.showDisclaimer = true;
            } else {
              this.disableBtn = false;
              this.showDisclaimer = false;
            }
            //this.showForm = false;
            //this.isCancelled = true;
          }
        }, error => {
          this.disableBtn = false;
          const errorsList = error.errors;
          if (errorsList.message !== 'Validation Errors') {
            this.toastr.error(errorsList.message, 'Sorry!');
          } else {
            for (const key in errorsList.detail) {
              if (errorsList.detail.hasOwnProperty(key)) {
                if (typeof errorsList.detail === 'undefined') {
                  this.toastr.error(errorsList.message, 'Sorry!');
                } else {
                  this.toastr.error(errorsList.detail[key], 'Sorry!');
                }
              }
            }
          }
          // this.disabledHideButton = true;
        }
      );
      }else{
        this.disableBtn = false
      }
    $('.termination_date_label').addClass('focused');
  }

  submitCancellation(formData, form: NgForm) {
    this.fetchUserStatusForTracking();
    //alert(this.formData.tnc)
    // if(this.showDisclaimer && this.formData.tnc!=true)
    // {
    //   this.toastr.error("Please accept terms and conditions.", 'Error!');
    //   return
    // }
    if(this.isSubscriptionBasedUser)
    {
      if ($('.cancel-options li input:checkbox:checked').length <= 0) {
        this.toastr.error("Please select tenant number(s)", 'Error!');
        return
      } 
    }
    
    if (!this.termination_date) {
      this.toastr.error("Please provide final day of parking", 'Error!');
      return
    } else {
      if ($('.cancel-options li input:checkbox:checked').length <= 0) {
        this.toastr.error("Please select tenant number(s)", 'Error!');
        return
      } else {
        if ($('.cancel-reason-fields li input:checkbox:checked').length <= 0) {
          this.toastr.error("please select a reason for cancelling", 'Error!');
          return
        } else {
          this.Validation = true;
          //  if (!this.formData.comments){
          //     this.toastr.error("Please enter a comment", 'Error!');
          //   }else{
          //this.Validation = true;
          //   }
        }
      }
    }

    if (!this.is_yes && this.isSubscriptionBasedUser) {
      $('#modal').trigger('click')
      //alert(33)
      return;
    }

    if ((this.termination_date) && ($('.cancel-reason-fields li input:checkbox:checked').length > 0) && ($('.cancel-options li input:checkbox:checked').length > 0)) {
      var cancel_reason = [];
      var tenant_val = [];

      $('.cancel-options li input:checkbox:checked').each(function (i) {
        var dataId = $(this).attr('id');
        let Tenant_ID = dataId.split('-')[0];
        let Account_ID = dataId.split('-')[1];
        let Account_Code = $(this).attr('ng-reflect-name');
        let Tenant_Spot = $(this).attr('value');
        tenant_val[i] = {
          ['Tenant_ID']: Tenant_ID,
          ['Account_ID']: Account_ID,
          ['Account_Code']: Account_Code,
          ['Tenant_Spot']: Tenant_Spot,
        };
      });

      $('.cancel-reason-fields li input:checkbox:checked').each(function (i) {
        cancel_reason[i] = $(this).attr('id');
      });

      let newDate = new Date(this.termination_date);
      let day = newDate.getDate();
      let month = ("0" + (newDate.getMonth() + 1)).slice(-2);
      let year = newDate.getFullYear();
      const terminationDate = year + '-' + month + '-' + day;

      let CancelRequestData = {
        "account_no": this.accountNumber,
        "reasons": cancel_reason,
        "termination_date": terminationDate,
        "tenants": tenant_val,
        "comments": formData.comments
      }

      //console.log(CancelRequestData);

      if (this.Validation == true) {
        this.myAccountMonthlyParkingObj.CancelRequestWithTenantNumber(CancelRequestData)
          .then(
            (data:any)=> {
              if (!data['errors']) {
                //this.checkAutopay();
                this.toastr.success('Cancellation Request Submitted Successfully', 'Great!');
                this.showForm = false;
                this.isCancelled = true;
              } else {

              }
            }, error => {
              //this.toastr.error(error.errors.detail.termination_date[0], 'Sorry!');
              this.toastr.error(error.errors.message, 'Sorry!');
            }
          );

      }  // end else
    }
  }

  // checkAutopay() {
  //   this.myAccountMonthlyParkingObj.autoPay(this.accountNumber)
  //   .then(
  //     (data:any)=> {
  //       this.autopay = data['data'].autopay;
  //       this.showForm = false;
  //       this.isCancelled = true;
  //     },
  //     error => {
  //       this.toastr.error(error.errors.message, 'Sorry!');
  //     }
  //   );
  // }
  fetchMonthlyParkingAccount() {
    this.myAccountMonthlyParkingObj.getAccountDetails(this.accountNumber)
      .then(
        (data:any)=> {
          this.subscriptionDetails = data['data'];
          this.isSubscriptionBasedUser = (this.subscriptionDetails.subscription_details.is_subscription_based_user == 1) ? true : false;
          if (this.isSubscriptionBasedUser) {
            this.getAllTenantsRequest();

          } else {
            this.getTenantNumberDataCancelApi();
          }
          // if (data['data'].subscription_details.is_subscription_based_user == 1) {
          //   this.showPlan = true;
          //   this.currentPlan = '<strong>' + data['data'].subscription_details.duration_in_months + ' Month Plan: $' + data['data'].subscription_details.monthly_rate + '/month</strong>&nbsp; (' + data['data'].subscription_details.desired_start_date + ' Through ' + data['data'].subscription_details.valid_until + ')'
          // }
          this.facility = this.subscriptionDetails.facility.facility_name_modified;
          this.addFacility = this.subscriptionDetails.facility.entrance_location;
          this.isAccontLoaded = true;
        },
        error => {
          // this.isLoaded = true;
          this.isAccontLoaded = true;
          this.toastr.error(error.errors.message, 'Sorry!');
        }
      );
  }

  fetchUserStatusForTracking(){
    let getUserId = '0';
    let getUserEmail = '0';
    let user_login_type = '';
    let isLogin = false;
    if(localStorage.getItem('access_token')){
     isLogin = true;
       getUserId = localStorage.getItem('userid');
       getUserEmail = localStorage.getItem('email');
       user_login_type = localStorage.getItem('user_login_type');
    }
    const loginStatusTrack = {
      'event' : 'pageview',
      'loginStatus' : isLogin? 'logged in': 'Not Logged IN', // 'logged in/Not Logged IN',
      'authenticationMethod' : user_login_type,
      'userId' : getUserId,
      'pageType' : this.router.url,
      'email': getUserEmail,
     };
    (<any>window).dataLayer.push(loginStatusTrack);
    }
}
