import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { HomepageserviceService } from '../../../services/homepageservice.service';
import { MyAccountMonthlyParkingService } from '../../../services/my-account-monthly-parking.service';
import { ToastrService } from 'ngx-toastr';
import { UserserviceService } from '../../../services/userservice.service';

@Component({
  selector: 'app-promocode-landing',
  templateUrl: './promocode-landing.component.html',
  styleUrls: ['./promocode-landing.component.scss'],
  providers: [HomepageserviceService, MyAccountMonthlyParkingService]
})
export class PromocodeLandingComponent implements OnInit {

	@Input() promoCode: string;

	@Output() saveEvent = new EventEmitter<boolean>();
	responseData;
  // @Output() navigateEvent = new EventEmitter<boolean>();
  
  app_icon = 'assets/img/app_icon.svg';
  googlePlayIcon = 'assets/img/google-play.svg';
  appstoreIcon = 'assets/img/AppStore.svg';
  dollar_icon = 'assets/img/dollar_icon.svg';
  discount_icon = 'assets/img/discount_icon.svg';
  arrowsIcon = 'assets/img/arrows.svg';
  starIcon = 'assets/img/star.svg'
  appleAppUrl = 'https://itunes.apple.com/us/app/icon-parking/id394377820?mt=8';
  googlePlayAppUrl = 'https://play.google.com/store/apps/details?id=com.iconparking&hl=en';
  

	constructor(
		private homepageserviceServiceObj: HomepageserviceService,
	    private myAccountMonthlyParkingObj: MyAccountMonthlyParkingService,
	    private userserviceServiceObj: UserserviceService,
	    private toastr: ToastrService
	) { }

	ngOnInit() {

	}

	savePromocode(location) {
		this.saveEvent.emit(location);
	}

	sendApp() {
    const appText = parseInt((<HTMLInputElement>document.getElementById('sendapp')).value.trim());
    if (appText) {
      const phoneNum = ((<HTMLInputElement>document.getElementById('sendapp'))).value;
      const text = 'Welcome to icon parking. click on the link below https://www.google.com';
      this.homepageserviceServiceObj.messageToMobile(phoneNum, text)
          .subscribe(
          (data:any)=> {
            this.responseData = data;
            this.toastr.success('App link sent successfully', 'success!');
          },
          error => {
            if (error.errors.message !== 'Validation Errors') {
              if (error.status === 500) {
                this.toastr.error('Something wrong happened, Please try again later', 'Sorry!');
              } else {
                this.toastr.error(error.errors.message, 'Sorry!');
              }
            } else {
              for (const key in error.errors.detail) {
                if (error.errors.detail.hasOwnProperty(key)) {
                  if (typeof error.errors.detail === 'undefined') {
                    this.toastr.error(error.errors.message, 'Sorry!');
                  } else {
                    this.toastr.error(error.errors.detail[key], 'Sorry!');
                  }
                }
              }
            }
          }
          );
    } else {
      this.toastr.error('Number is required', 'Sorry!');
    }
    (<HTMLInputElement>document.getElementById('sendapp')).value = '';
  }

}
