  <app-header></app-header>
  <div class="payment-section orangeBar  dailyConfirm ">
  <div class="container">
    <div class="hidden-xs mar-top-md clearfix"></div>
    <!-- <h1 class="text-primary">Thank you for your reservation!</h1> -->
    <div class="clearfix reserItem">
      <div class="row">
      <div class="col-xs-12 col-sm-9 col-md-9 col-lg-9">
        <h2 class="mar-top-0 text-primary mar-top-sm text-uppercase">Reservation details</h2>
        <p>{{ reservationDetails?.start_timestamp | paymentdate }}</p>
        <h4 class="mar-top-sm">Michael Smith</h4>
        <p>michael.smith@gmail.com</p>
        <p>(971) 123-4567</p>
        <br>
        <p class="accent mar-bottom-sm">Standard size vehicle </p>
        <div class="row">
          <div class="col-xs-12 col-sm-12 col-md-10 col-lg-8">
            <div class="well mar-bottom-sm">
              <table width="100%" cellpadding="10">
                <tbody>
                <tr class="visible-xs">
                  <td class="text-uppercase" colspan="2">
                    <b>Enter after</b>
                  </td>
                </tr>
                <tr>
                  <td class="text-uppercase hidden-xs">
                    <b>Enter after</b>
                  </td>
                  <td>
                    <fa class="text-primary" name="calendar-o"></fa> &nbsp; &nbsp; {{ reservationDetails?.formatted_start_date }}
                  </td>
                  <td>
                    <fa class="text-primary" name="clock-o"></fa> &nbsp; &nbsp; {{ reservationDetails?.formatted_start_time }}
                  </td>
                </tr>
                <tr>
                  <td colspan="2">&nbsp;</td>
                </tr>
                <tr class="visible-xs">
                  <td class="text-uppercase" colspan="2">
                    <b>Enter Before</b>
                  </td>
                </tr>
                <tr>
                  <td class="text-uppercase hidden-xs">
                    <b>Exit before</b>
                  </td>
                  <td *ngIf="reservationDetails?.formatted_end_date">
                    <fa class="text-primary" name="calendar-o"></fa> &nbsp; &nbsp; {{ reservationDetails?.formatted_end_date }}
                  </td>
                  <td *ngIf="!reservationDetails?.formatted_end_date">
                      <fa class="text-primary" name="calendar-o"></fa> &nbsp; &nbsp; {{ reservationDetails?.formatted_start_date }}
                    </td>
                  <td>
                    <fa class="text-primary" name="clock-o"></fa> &nbsp; &nbsp;  {{ reservationDetails?.formatted_end_time }}
                  </td>
                </tr>
              </tbody></table>
            </div>
          </div>
        </div>
        <p class="cc mar-bottom-sm"><img [src]="visaIcon" alt="icon" height="40"><span class="mar-left-xs">My Visa Credit Card (1254-xxxx..)</span></p>
        <p class="mar-top-sm">Amount charged to card</p>
        <h2 class="mar-top-xs" sourceFont>${{ reservationDetails?.total }}</h2>
        <div class="clearfix mar-top-sm">
          <a href="#" class="btn btn-primary btn-fill theme-btn hidden-xs">Print Reservation</a><span class="mar-left-xs hdden-xs"></span><a href="#" class="btn theme-btn btn-primary theme-btn hidden-xs">Cancel</a>
          <div class="row visible-xs">
            <div class="col-xs-offset-1 col-xs-10">
              <a href="#" class="btn btn-primary btn-fill btn-block theme-btn">Print Reservation</a><div class="mar-top-xs"></div><a href="#" class="btn btn-primary btn-block theme-btn">Cancel</a>
            </div>
          </div>
        </div>
        <div class="hidden-xs">
          <hr class="mar-top-sm mar-bottom-sm">
          <p class="text-uppercase"><b>Reservation Policy</b></p>
          <small>
          No in/out (One Entry/Exit) No Partial Refunds for Unused Time<br>
          To cancel for a full refund click the CANCEL BUTTON in the email or on this page ANYTIME PRIOR TO THE ARRIVAL TIME indicated on the reservation.<br>
          If you encounter any issues you can email us at customerservice@iconparking.com<br>
          No refunds will be issued for unused reservations not canceled prior to the arrival time.<br>
          Oversized vehicle surcharge (from <span sourceFont>$</span>10 to <span sourceFont>$</span>$15) will be collected at the location if the vehicle does not meet the size requirements, unless paid for at time of reservation.
          </small>
          <div class="clearfix mar-top-md">
            <p class="text-primary">Thank you for choosing Icon Parking.</p>
            <p>Questions? Contact our customer service team Monday-Friday, 9am-5pm<br>
              <a [href]="phoneNumber">(877) 727-5464</a> or at <a [href]="mailId">customerservice@iconparking.com</a>
            </p>
          </div>
        </div>
      </div>
      <div class="col-xs-12 col-sm-3 col-md-3 col-lg-3">
        <p class="visible-xs mar-top-sm"></p>
        <div class="well mar-bottom-0">
          <h4 class="text-primary mar-top-0 text-uppercase">Facility details</h4>
          <p class="pad-top-xs"><b>{{ reservationDetails?.facility?.short_name }}</b></p>
          <p><fa name="phone"></fa> {{ reservationDetails?.facility?.phone_number }}</p>
          <p>Between: {{ reservationDetails?.facility?.between_streets }}</p>
          <p>Entrance: {{ reservationDetails?.facility?.entrance_location }}</p>
          <a target="_blank" href="https://maps.google.com?q={{reservationDetails?.facility?.geolocations?.latitude}},{{reservationDetails?.facility?.geolocations?.longitude}}" class="btn btn-link pad-left-0">get directions &nbsp;&nbsp; <fa name="chevron-right"></fa> </a>
          <p class="mar-top-xs">
            <fa name="calendar"  class="ismall text-primary" size="2x" style="vertical-align: sub;"></fa> &nbsp; 7 days a week
          </p>
          <p class="">
            <fa name="clock-o" class=" ismall text-primary" size="2x" style="vertical-align: sub;"></fa> &nbsp; 24 hours a day
          </p>
        </div>
        <div class="well pad-0 webSection-3">
          <div class="clearfix slideShow">
            <div id="carousel-id" class="carousel slide" data-ride="carousel">
              
              <div class="carousel-inner">
                <!-- <div class="item">
                  <img src="/assets/img/park-garage-2.jpg" class="full-width">
                </div>
                <div class="item">
                  
                  <img src="/assets/img/park-garage.jpg" class="full-width">
                </div> -->
                <div class="item">
                  <img [src]="park_garage_2Icon" alt="icon">
                </div>
              </div>
              <!-- <a class="left carousel-control" href="#carousel-id" data-slide="prev"><fa name="angle-left"></fa></a>
              <a class="right carousel-control" href="#carousel-id" data-slide="next"><fa name="angle-right"></fa></a> -->
            </div>
          </div>
          <div class="googleMap" id="map-wrapper" >
            <img [src]="garage_map_icon" alt="icon" class="img-responsive full-width">
          </div>
        </div>
        <div class="visible-xs">
          <p class="text-uppercase"><b>Reservation Policy</b></p>
          <small>
          No in/out (One Entry/Exit) No Partial Refunds for Unused Time<br>
          To cancel for a full refund click the CANCEL BUTTON in the email or on this page ANYTIME PRIOR TO THE ARRIVAL TIME indicated on the reservation.<br>
          If you encounter any issues you can email us at customerservice@iconparking.com<br>
          No refunds will be issued for unused reservations not canceled prior to the arrival time.<br>
          Oversized vehicle surcharge (from <span sourceFont>$</span>10 to <span sourceFont>$</span>15) will be collected at the location if the vehicle does not meet the size requirements, unless paid for at time of reservation.
          </small>
          <div class="clearfix mar-top-md">
            <p class="text-primary">Thank you for choosing Icon Parking.</p>
            <p>Questions? Contact our customer service team Monday-Friday, 9am-5pm<br>
              <a [href]="phoneNumber">(877) 727-5464</a> or at <a [href]="mailId">customerservice@iconparking.com</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
  </div>
  <app-footer></app-footer>