<app-sidenav></app-sidenav>
<!-- <div sourceSense class="d-flex justify-content-center py-2" topbar>
    <div class="mr-4"><img height="16px" [src]=" logo_icon" /> </div>
    <div class="text-white mr-2 font-weight-bold">MONTHLY PARKING SPECIAL!</div>
    <div class="mr-4 "><strong>Starts at $232.31 + Tax / 6 Month Rate Guarantee</strong></div>
    <div class="d-none d-sm-block"><a routerLink="/monthly-special-campaign" class="badge bg-white text-primary">LEARN MORE <img [src]="arrow_right"/></a></div>
</div> -->
<!-- <div style="background:radial-gradient(ellipse at center, #484848 0%, black 100%)">home page</div> -->
<!-- app promotion footer bar -->
<div *ngIf="showAppPromotion" class=" appPromotion bottomFixBanner clearfix visible-xs">
    <div class="flex-row no-gutters align-items-center">
        <div class="col-auto">
            <button type="button" class="close" (click)="dismisThis()"><i class="fa fa-times"
                    style="color:#333;"></i></button>
        </div>
        <div class="col">
            <div class="flex-row no-gutters align-items-center">
                <div class="col-auto">
                    <img [src]="iconAppLogoNew">
                </div>
                <div class="col">
                    <h4 [class.hide]="iOS">Icon Parking for iPhone</h4>
                    <h4 [class.hide]="Android">Icon Parking for Android</h4>
                    <!-- <p><i class="fa  text-primary fa-star"></i> <i class="fa  text-primary fa-star"></i>  <i class="fa  text-primary fa-star"></i> <i class="fa  text-primary fa-star"></i> <i class="fa  text-primary fa-star-o"></i> (363,648) </p> -->
                    <p [class.hide]="iOS">Free - In the App Store</p>
                    <p [class.hide]="Android">Free - In the Play Store</p>
                </div>
            </div>

        </div>
        <div class="col-auto">
            <a [class.hide]="iOS" [href]="appStoreUrl" class="btn btn-sm text-info">Open</a>
            <a [class.hide]="Android" [href]="googlePlayUrl" class="btn btn-sm text-info">Open</a>
        </div>
    </div>
</div>
<div sourceSense class="d-flex  hidden-xs  flex-column border-top justify-content-center text-dark  py-2" topbar>
    <div class="container">
        <div class="row top-add hidden-xs">

            <div class="col-md-5">
                <p><span><a routerLink="/app/" (click)="triggerEvent('SAVE TIME & MONEY - DOWNLOAD THE ICON GO APP')"
                            class="ft-text text-uppercase"><img src="/assets/img/tag icon.png" /> Save Time & Money -
                            Download the Icon Go app</a></span></p>
            </div>
            <div class="col-md-7">
                <!-- <p class="text-right"><span><img width="18" src="/assets/img/park-safe-icon.png"/> &nbsp;COVID-19 RESPONSE: ICON PARKSAFE <a style="cursor: pointer;" (click)="triggerEvent('COVID-19 RESPONSE: ICON PARKSAFE')" [href]="covidCareUrl"
       > <u>LEARN MORE</u></a></span></p> -->

            </div>
        </div>
    </div>
</div>

<!-- <div sourceSense  class="d-flex  visible-xs  flex-column border-top justify-content-center text-dark  py-2" topbar> -->
<!-- <div class="row top-add">

   
    <div class="col">
        <p class="text-right">
            <span>
                <img width="18" src="/assets/img/park-safe-icon.png"/> &nbsp;COVID-19 RESPONSE: ICON PARKSAFE <a style="cursor: pointer;display: inline-block;" (click)="triggerEvent('COVID-19 RESPONSE: ICON PARKSAFE')" [href]="covidCareUrl"
           > <u>LEARN MORE</u></a></span></p>
        
        </div>
    </div> -->
<!-- <a  class="btn btn-link visible-xs d-sm-none p-0 btn-block" (click)="isCollapsed = !isCollapsed"
    [attr.aria-expanded]="!isCollapsed" aria-controls="collapseBasic" >
        Covid-19 Update <img class="ml-2" width="15px" [src]="arrowDown"/>
    </a>

    <div style="padding: 10px;" [collapse]="isCollapsed" id="collapseBasic"  class="mr-4 msg-container  text-center"><a class="link_text_corona" href="https://iconparkingsystems.com/cms/daily-updates/">Icon Parking is committed to keeping our customers, team members and community updated on all developments on the evolving situation with Covid-19. Please <a class="click_here" >click here</a> for
        the latest updates.
    </a>
        <a  (click)="isCollapsed = !isCollapsed"
        [attr.aria-expanded]="!isCollapsed" aria-controls="collapseBasic" style="margin-top: -5px; padding: 0;" class="btn btn-link mar-left-xs pad-left-0 pad-right-0"> <img height="15px" class="crossDark" [src]="crossDark"></a>
     
    </div> -->
<!-- </div> -->

<div class="barWrapList">
    <ng-container *ngIf="showbannerStripList || showbannerStripListUpdated">
        <div class="bannerWrap" *ngFor="let banner of fetchBannerStripContentUpdated; let index = index" id="{{index}}">
            <ng-container *ngIf="banner?.turn_on_banner">
                <div [innerHtml]="banner?.banner_content"></div>
                <a closeButton class="text-white" *ngIf="banner?.close_button_text" href="javascript:;"
                    (click)="closeCross(index)">{{banner?.close_button_text}}</a>
                <a closeButton class="text-white" *ngIf="!banner?.close_button_text" href="javascript:;"
                    (click)="closeCross(index)"><i style="color: #fff!important;" class="fa fa-times"></i></a>
            </ng-container>
        </div>
    </ng-container>
</div>
<div *ngIf="is_show_landing_strip && is_landing_page && show_strip_search" sourceSense
    class="d-flex ndStrip border-top justify-content-center py-2 px-4" topbar>
    <table class="content" cellpadding="0" style="vertical-align:middle" cellspacing="0">
        <tr>
            <td width="20px">
                <i class="fa text-white fa-2x fa-usd"></i>
            </td>
            <td style="font-size: 16px; padding: 0 10px;">
                <span [innerHTML]="Couponmessage"></span>&nbsp;<a *ngIf="is_landing_page_save"
                    (click)="launchLoginPopup()"><b>click here and save it for later. </b><i
                        class="fa fa-angle-right"></i></a>
            </td>
            <td class="text-right" width="25px" style="padding-top: 4px;">
                <a href="javascript:void(0)" (click)="hideStripe();" class="btn-link">
                    <i class="fa fa-times text-white"></i>
                </a>
            </td>
        </tr>
    </table>

</div>
<div *ngIf="showOnMothly" sourceSense class="d-flex ndStrip border-top justify-content-center py-2 px-4" topbar>
    <table class="content" cellpadding="0" style="vertical-align:middle" cellspacing="0">
        <tr>
            <td width="20px">
                <i class="fa text-white fa-2x fa-usd"></i>
            </td>
            <td style="font-size: 16px; padding: 0 10px;">
                <span class="noticeSpan" [innerHTML]="Couponmessages">
                </span>

            </td>
            <td class="text-right" width="25px" style="padding-top: 4px;">
                <a href="javascript:void(0)" (click)="hideStripeMonhthly();" class="btn-link">
                    <i class="fa fa-times text-white"></i>
                </a>
            </td>
        </tr>
    </table>

</div>
<div *ngIf="is_show_landing_strip && is_landing_page && show_strip_search"
    class="d-flex  hidden border-top justify-content-center py-2" topbar>


    <table class="content" cellpadding="0" style="vertical-align:middle" width="100%" cellspacing="0">
        <tr>
            <td width="20px">

                <i class="fa text-white fa-2x fa-dollar-sign"></i>
            </td>
            <!-- <td style="font-size: 16px; padding: 0 10px;"><span [innerHTML]="Couponmessage"></span>&nbsp;<a *ngIf="is_landing_page_save" (click)="launchLoginPopup()"><b>click here and save it for later. </b><i class="fa fa-angle-right"></i></a></td> -->
            <td style="font-size: 16px; padding: 0 10px;"><span [innerHTML]="Couponmessage"></span>&nbsp;<a
                    (click)="launchLoginPopup()"><b>click here and save it for later. </b><i
                        class="fa fa-angle-right"></i></a></td>
            <td class="text-right" width="25px">
                <a href="javascript:void(0)" (click)="hideStripe();" class="btn-link">
                    <i class="fa fa-times text-white"></i>
                </a>
            </td>
        </tr>
    </table>

    <div class="col-xs-1 text-right pad-right-0">
    </div>

</div>
<div sourceSense class="bar-section" barSection>
    <nav class="navbar " navbar>
        <div class="container">
            <!-- Brand and toggle get grouped for better mobile display -->
            <div class="navbar-header">
                <a class="navbar-brand  pad-right-0" href="javascript:; " routerLink="/"><img [src]="icon_logo">
                    <h4 style="margin-top: 5px;font-weight:600;" class=" text-white visible-xs visible-sm">PARKING IS JUST THE START<sup>TM</sup></h4>
                </a>
                <h5 style=" float: right; margin-top: 21px;font-weight:600;" class="ml-2 hidden-xs hidden-sm">PARKING IS JUST THE START<sup>TM</sup></h5>
                <div class="mobileHide">
                    <button type="button " class="navbar-toggle hidden-xs   " (click)="toggleData()">
                        <img [src]="bars" />
                    </button>
                    <button type="button " (click)="toggleData()" class="navbar-toggle visible-xs ">
                        <i class="fa fa-bars fa-2x text-primary" style="margin-top: -5px;"></i>
                    </button>

                    <a [class.hide]="iOS" [href]="appStoreUrl"
                        class="text-white navbar-toggle downBtnApp pull-right visible-xs visible-sm">

                        <img src="../../assets/img/downlod-app-btn.svg" style="width: 100%;">
                    </a>
                    <a [class.hide]="Android" [href]="googlePlayUrl"
                        class="text-white navbar-toggle downBtnApp pull-right visible-xs visible-sm">
                        <img src="../../assets/img/downlod-app-btn.svg" style="width: 100%;"></a>
                </div>
            </div>

            <!-- Collect the nav links, forms, and other content for toggling -->
            <div class="collapse navbar-collapse ">
                <ul class="nav navbar-nav navbar-right hidden-xs ">


                    <!-- <li class="hidden-xs margin-head-ban"><a routerLink="/cares"><img class="img-responsive my-image" src="../../assets/img/new-homepage/icon-img.jpg" style="margin-top: -8px;"></a></li> -->
                    <!-- <li><a href="javascript:;" class="btn-outer" routerLink="/business-parking-nyc">ICON  <span class="text-primary">|</span>  FOR BUSINESS </a></li> -->
                    <li><a (click)="findNearMe()" href="javascript:;" class="font-15">FIND PARKING </a></li>
                    <!-- <li><a href="javascript:;" routerLink="/monthly-parking">MONTHLY PARKING </a></li> -->
                    <li><a href="javascript:;" (click)="triggerEvent('MONTHLY PARKING SPECIALS');formonthly();"
                            class="font-15">MONTHLY PARKING</a></li>
                    <li><a href="javascript:;" (click)="triggerEvent('SPECIALS & OFFERS')" routerLink="/specials"
                            class="font-15">SPECIALS & OFFERS </a></li>
                    <li><a href="javascript:;" (click)="triggerEvent('ICON FOR BUSINESS')"
                            routerLink="/business-parking-nyc" class="font-15">ICON FOR BUSINESS </a></li>


                    <!-- <li><a href="javascript:;" *ngIf="!isLoyaltyMember" class="" routerLink="/rewards">ICON REWARDS</a>
                        <a *ngIf="isLoyaltyMember" routerLink="/my-account/icon-rewards">ICON REWARDS</a> </li> -->


                    <!-- <li *ngIf="!isLoggedIn">
                        <a data-toggle="modal" id="loginPopup" href='#login'>LOGIN</a>
                    </li> -->
                    <!-- <li><a href="javascript:;" routerLink="/contact">CONTACT US</a></li> -->
                    <!-- <li *ngIf="isLoggedIn" class="dropdown userLogin" id="loggeduser">
                        <a [class.active]="isActivetoinside()" class="test" routerLink="/my-account/" (click)="redirectToUser();" style="cursor:pointer;" class="dropdown-toggle" data-toggle="dropdown">{{ loggedUserName }} <fa name="user"></fa></a>
                        <ul class="dropdown-menu">

                            <li *ngFor="let menues of menuUser">
                                <a (click)="removeHeader();" *ngIf="menues.menuPath!='icon-rewards'" routerLinkActive="active" routerLink="/my-account/{{ menues.menuPath }}">{{ menues.menuUrl }}</a>
                                <a *ngIf="menues.menuPath=='icon-rewards';" [class.active]="isActiveforSubMenu()" (click)="menuactiveFunction()" routerLink="/my-account/{{ menues.menuPath }}">{{ menues.menuUrl }}</a>
                            </li>
                            
                            <li><a class="text-primary" href="javascript:void();" (click)="logOut()">Sign Out <fa name="sign-out"></fa></a></li>
                        </ul>
                    </li> -->
                    <!-- <li *ngIf="isLoggedIn">
                        <a href="javascript:;" routerLink="/my-account/account">MY ACCOUNT</a>
                    </li> -->
                    <!-- <li><a href="javascript:;" (click)="callcustomer()" [href]="phone" class="text-white">  1 (877) 727-5464</a></li> -->
                    <li>
                        <a href="javascript:; " class="toggleMenuBtn" (click)="toggleData()"> <i
                                class="fa fa-bars fa-2x"></i> </a>
                    </li>
                    <li class="get-app-tp">
                        <a routerLink="/app" (click)="triggerEvent('GET THE APP')" style="cursor: pointer;">GET THE APP
                            &nbsp;<img width="9" src="/assets/img/gray-app-mob-icon.png" /></a>
                    </li>
                    <li class="top-socila">


                        <a [href]="instagramUrl" target="_blank"><img src="/assets/img/gray-insta.png" /></a>
                        <a [href]="facebookUrl" target="_blank"><img src="/assets/img/gray-fb.png" /></a>
                        <a [href]="twitterUrl" target="_blank"><img src="/assets/img/gray-twit.png" /></a>
                        <a [href]="linkedinUrl" target="_blank"><img src="/assets/img/gray-linkd.png" /></a>
                    </li>
                    <li *ngIf="isLoggedIn" class="dropdown userLogin" id="loggeduser">
                        <span class="btn-outer-2">
                            <!-- <button *ngIf="loyalit_member" routerLink="/my-account/icon-rewards" class="btn btn-link p-0" type="button" 
                            
               >
                <img   width="70" src="/assets/img/icon-rewards-logo-wt.svg"/>
        </button> -->
                            <a [class.active]="isActivetoinside()" class="test" routerLink="/my-account/"
                                (click)="redirectToUser();" style="cursor:pointer;" class="dropdown-toggle"
                                data-toggle="dropdown">{{ loggedUserName }} &nbsp; <fa name="angle-down"></fa></a>
                            <ul class="dropdown-menu">

                                <li *ngFor="let menues of menuUser">
                                    <a (click)="removeHeader();" *ngIf="menues.menuPath!='icon-rewards'"
                                        routerLinkActive="active" routerLink="/my-account/{{ menues.menuPath }}">{{
                                        menues.menuUrl }}</a>
                                    <a *ngIf="menues.menuPath=='icon-rewards';" [class.active]="isActiveforSubMenu()"
                                        (click)="menuactiveFunction()" routerLink="/my-account/{{ menues.menuPath }}">{{
                                        menues.menuUrl }}</a>
                                </li>

                                <li><a class="text-primary" href="javascript:void();" (click)="logOut()">Sign Out <fa
                                            name="sign-out"></fa></a></li>
                            </ul>
                        </span>
                    </li>
                    <li *ngIf="!isLoggedIn">
                        <span class="btn-outer-2">
                            <!-- <a data-toggle="modal" id="loginPopup" href='#loginreward'><img width="70" src="/assets/img/icon-rewards-logo-wt.svg"/></a> -->
                            <!-- <button [popover]="popTemplate"
                            [outsideClick]="true"
                            triggers=""  [isOpen]="isOpen"   
                            placement="bottom"   containerClass="customClass" class="btn btn-link p-0" type="button" 
                            
               >
                <img (click)="isOpen = !isOpen"  width="70" src="/assets/img/icon-rewards-logo-wt.svg"/>
        </button> -->
                            <!-- <a  class="popupButton" [popover]="popTemplate" 
                            triggers="focus"   placement="bottom"   containerClass="customClass" ><img   width="70" src="/assets/img/icon-rewards-logo-wt.svg"/></a> -->
                            <a data-toggle="modal" id="loginPopup" href='#login'><span style="color: #fff;">LOGIN
                                    /</span><span class="text-primary"> SIGN UP</span></a>
                        </span>
                    </li>

                    <ng-template #popTemplate>
                        <a class="close" (click)="isOpen = !isOpen"><span style="color: #666;"
                                class="fa  fa-times"></span></a>
                        <form class="p normallogin" name="form" role="form" id="floating-label-login-comp1"
                            (ngSubmit)="f.form.valid && userSignIn(formData, f)" #f="ngForm" novalidate>
                            <div class="text-center" id="errors-container"></div>
                            <h2 class="mar-top-xs mar-bottom-xs"><span class="text-primary">Save 10%</span> or more<br>
                                when you sign up</h2>
                            <div class="form-group">
                                <button routerLink='/icon-rewards-register' (click)="triggerEvent('Icon rewards')"
                                    class="btn btn-primary btn-fill btn-block">Sign Up Now</button>
                            </div>
                        </form>
                    </ng-template>
                </ul>
                <a href="javascrpt:; " class="visible-xs" routerLink="/"><img [src]="icon_logo"></a>
            </div>
            <!-- /.navbar-collapse -->
        </div>
        <!-- /.container-fluid -->
    </nav>
    <!-- <app-orangebar-comp *ngIf="showOrangeBar" class="mar-bottom-xs d-block"></app-orangebar-comp> -->
    <div class="modal fade login-reward" id="loginreward">
        <div class="modalWrapper">
            <div class="modal-dialog signBgTo">
                <div class="pad-top-sm" *isIphone></div>
                <div class="panel panel-default mar-bottom-0">
                    <button type="button" class="close" data-dismiss="modal" aria-hidden="true"><span
                            class="fa fa-times"></span></button>
                    <form class="p normallogin" name="form" role="form" id="floating-label-login-comp1" novalidate>
                        <div class="text-center" id="errors-container"></div>
                        <h2 class="mar-top-xs mar-bottom-xs"><span class="text-primary">Save 10%</span> Or more <br>when
                            you sign up</h2>
                        <div class="form-group">
                            <button routerLink="icon-rewards-register" class="btn btn-primary btn-fill btn-block">Sign
                                Up Now</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    <section sourceSense searchSection>
        <div class="container">
            <div class=" flex-row justify-content-center no-gutters align-items-center  ">
                <div class="col-md-6 col-lg-5   hidden-xs  col-sm-6 ">

                    <!-- <h2>Park with Peace of Mind.</h2> -->
                </div>
                <div class="col-md-6 col-lg-5 col-sm-6  ">
                    <div class="flex-row no-gutters combSec  hidden-xs mb-2">
                        <!-- <div class="col" *ngIf="!isLoggedIn" >
                            <div  class="contentTopBar text-center">
                                <img height="60" src="../../assets/img/new-homepage/b2b-img/reward.png">&nbsp; <b> <a [class.active]="logoutmenu()" data-toggle="modal" id="loginPopup" routerLink='/icon-rewards-register'><u> Sign up</u></a> for exclusive offers & rates!</b>
                            </div>
                            <h4 class="font-weight-bold">Save your spot &nbsp; <img height=" 20 " src="../../assets/img/new-homepage/icon-down-orange.svg " /></h4>
                        </div> -->
                        <div class="col text-center" *ngIf="loyalit_member">
                            <small><b>You have {{tierData?.title}} Status, </b></small>
                            <small>You receive <span class="text-primary"><b>10% off</b></span> online
                                reservations</small>
                            <!-- <p [innerHtml]="isTierMsg"></p> -->
                        </div>

                    </div>
                    <div style="text-align:center" class="flex-row no-gutters combSec visible-xs mb-2">
                        <div class="col" *ngIf="loyalit_member">
                            <small style="font-size: 10px;"><b>You have {{tierData?.title}} Status</b></small>
                            <small style="font-size: 10px;">You receive <span class="text-primary"><b>10% off</b></span>
                                online reservations</small>
                            <!-- <p [innerHtml]="isTierMsg"></p> -->
                        </div>

                    </div>


                </div>

            </div>
            <div class="mar-top-xs  flex-row   mb-2 mr-2">
                <div class="col-md-6 hidden-xs  col-lg-offset-1 col-md-offset-1  col-sm-6 col-lg-5 pl-0 ">
                    <carousel [isAnimated]="true">
                        <slide *ngFor="let item of sliderstaticPageContent; let index = index">
                            <a href="{{item.internal_url}}">
                                <img src="{{item.offer_image}}" class="img-responsive">
                            </a>
                        </slide>
                        <!-- <slide>
                            <a href="/2022ICON15H/apply/?coupontype=monthly ">
                                <img src="https://staging2.iconparkingsystems.com/cms/wp-content/uploads/2022/10/15-3-Month-Offer-Homepage.png" class="img-responsive">
                            </a>
                        </slide>
                        <slide>
                            <a href="/ICNW35H/apply ">
                                <img src="https://staging2.iconparkingsystems.com/cms/wp-content/uploads/2022/10/Bitmap.png" class="img-responsive">
                            </a>
                        </slide> -->

                    </carousel>
                </div>
                <!-- <div class="col-md-6 hidden-xs  col-lg-offset-1 col-md-offset-1  col-sm-6 col-lg-5 pl-0 " [innerHTML]="staticPageContent.post_content">
                    <a href="/ICNW35H/apply ">
                    <img src="https://staging2.iconparkingsystems.com/cms/wp-content/uploads/2022/09/ICON-final-night-weekends.png" class="img-responsive"></a>
                </div>     -->
                <!--<div class="col-md-6 hidden-xs  col-lg-offset-1 col-md-offset-1  col-sm-6 col-lg-5 ">
                    <div class=" hidden" >

                  
                    <div class="text-center">
                        <a href="javascrpt:; " class="visible-xs" routerLink="/ "><img [src]="icon_logo"></a>
                    </div>
                    <div class="clearfix">
                        <p>
                            <img src="../../assets/img/new-homepage/b2b-img/parksafe.png" height="50">
                        </p>
                        <ul class="dotsUl">
                            <li>
                                <p> 100% Contactless mobile reservations and payments </p>
                            </li>
                            <li>
                                <p> Touch-points wiped for disinfection (steering wheel, gear shift, etc.)  </p>
                            </li>
                            <li>
                                <p> Trained team members with masks & all practicing social distancing </p>
                            </li>
                            </ul>

                          <a routerLink="/iconparksafe" (click)="triggerEvent('iconparksafe')" class="btn btn-pirmary pad-0 btn-link text-primary ">&nbsp; &nbsp;&nbsp;LEARN MORE <span class="arrow-right"></span></a>
                    </div>

                        </div>


                        <div class="flex-row batchFile  ">
                            <div class="col-auto col-xs-6 imgSide pad-right-0">
                                    <img style="max-width: 250px;" class="img-responsive" [src]=sealLogo>
                            </div>
                            <div class="col col-xs-6">
                                <ul class="dotsUl">
                                    <li><h4 class="mar-bottom-0"><img width="6px" style="margin-right: 6px;vertical-align: middle;display: inline-block;margin-top: -3px;" [src]=dotsbuttles >SERVICE</h4>
                                        <p>Fully contactless mobile reservations,<br class="hidden-md"> payments, check-in & check-out</p>
                                    </li>
                                    <li>
                                        <h4 class="mar-bottom-0"> <img width="6px" style="margin-right: 6px;vertical-align: middle;display: inline-block;margin-top: -3px;" [src]=dotsbuttles >SANITIZE</h4>
                                        <p>Designated Viking PureÂ® natural <br class="hidden-md">cleaning stations at every location</p>
                                    </li>
                                    <li>
                                        <h4 class="mar-bottom-0"> <img width="6px" style="margin-right: 6px;vertical-align: middle;display: inline-block;margin-top: -3px;" [src]=dotsbuttles >SAFETY</h4>
                                        <p> Trained team members CDC guideline<br class="hidden-md"> compliant, all practicing social distancing</p>
                                    </li>
                                    </ul>
                                  <a (click)="triggerEvent('iconparksafe')" routerLink="/iconparksafe" class="btn btn-pirmary pad-0 btn-link text-primary ">&nbsp;&nbsp;LEARN MORE <span class="arrow-right"></span></a>
                            </div>
                        </div>



                        <div class="flex-row batchFile  ">
                            
                            <div class="col-auto col-xs-6 imgSide pad-right-0">
                                    <img style="max-width: 250px;" class="img-responsive" [src]=sealLogo>
                            </div>
                            <div class="col col-xs-6">
                                <ul class="dotsUl">
                                    <li><h4 class="mar-bottom-0"><img width="6px" style="margin-right: 6px;vertical-align: middle;display: inline-block;margin-top: -3px;" [src]=dotsbuttles >SERVICE</h4>
                                        <p>Fully contactless mobile reservations,<br class="hidden-md"> payments, check-in & check-out</p>
                                    </li>
                                    <li>
                                        <h4 class="mar-bottom-0"> <img width="6px" style="margin-right: 6px;vertical-align: middle;display: inline-block;margin-top: -3px;" [src]=dotsbuttles >SANITIZE</h4>
                                        <p>Designated Viking Pure® natural <br class="hidden-md">cleaning stations at every location</p>
                                    </li>
                                    <li>
                                        <h4 class="mar-bottom-0"> <img width="6px" style="margin-right: 6px;vertical-align: middle;display: inline-block;margin-top: -3px;" [src]=dotsbuttles >SAFETY</h4>
                                        <p> Trained team members CDC guideline<br class="hidden-md"> compliant, all practicing social distancing</p>
                                    </li>
                                    </ul>
                                  <a (click)="triggerEvent('iconparksafe')" routerLink="/iconparksafe" class="btn btn-pirmary pad-0 btn-link text-primary ">&nbsp;&nbsp;LEARN MORE <span class="arrow-right"></span></a>
                            </div>
                        </div>

                         <h2 style="margin-top: 40px; padding-right: 65px;" class="text-center">Park with Peace of Mind.</h2>
                         <div style="font-size: 14px;" class="flex-row align-items-center">
                            <div class="col col pad-left-0">
                               <img class="img-responsive" src="../../assets/img/new-homepage/parksafe-logo.png"> 
                            </div>
                            <div class="col">
                                <div class="clearfix mb-2">
                                    <h4>
                                    <img src="../../assets/img/new-homepage/b2b-img/dots.png"> &nbsp;  SERVICE </h4>
                                    <p>
                                        100% Contactless mobile reservations and payments
                                    </p>
                                </div>
                                <div class="clearfix mb-2">
                                   <h4><img src="../../assets/img/new-homepage/b2b-img/dots.png"> &nbsp;  SANITIZE </h4> 
                                    <p>
                                        Steering wheel, gear shift, and touch-points are wiped for disinfection 
                                    </p>
                                </div>
                                <div class="clearfix mb-2">
                                    <h4>
                                    <img src="../../assets/img/new-homepage/b2b-img/dots.png"> &nbsp;  SAFETY </h4>
                                    <p>
                                        Team members with gloves & masks, all practicing social distancing
                                    </p>
                                </div>
                                <a routerLink="/iconparksafe" class=" ">LEARN MORE <span class="arrow-right"></span></a>

                            </div>
                        </div> 
                </div>-->
                <div class="col-md-6 col-sm-6 col-lg-5 pr-0">

                    <div class="booknow-home text-right"><a href="javascript:void" style="cursor:context-menu;"><img
                                src="../../assets/img/booknow-home-btn.png"></a></div>
                    <div class="search-section ">

                        <div class="btn-group border-bottom btn-group-justified selectorBtn" data-toggle="buttons">
                            <label (click)="settype('daily')"
                                class="btn btn-primary btn-white active border-radius-left-top ">
                                <input type="radio" name="options" id="option1" autocomplete="off" checked> DAILY
                            </label>
                            <label (click)="settype('monthly')"
                                class="btn btn-primary   btn-white border-left border-radius-right-top  ">
                                <input type="radio" name="options" id="option2" autocomplete="off"> MONTHLY
                            </label>

                        </div>

                        <!-- <div class="btn-group border-bottom btn-group-justified selectorBtn" data-toggle="buttons ">
                            <label (click)="settype('daily')" class="boxestype daily btn btn-white border-radius-left-top active ">
                              <input type="radio" name="options" id="option1" autocomplete="off" checked> DAILY
                            </label>
                            <label (click)="settype('monthly')" class="boxestype monthly btn btn-white border-left border-radius-right-top">
                              <input type="radio" name="options" id="option2" autocomplete="off"> MONTHLY
                            </label>
                        </div> -->
                        <!-- <app-google-autosuggest></app-google-autosuggest> -->
                        <div class="searchHolder mb-3 ">

                            <app-google-autosuggest></app-google-autosuggest>

                        </div>
                        <div class=" mb-2 bg-white filterBox text-dark">

                            <div class="row ">
                                <div [ngClass]="{'col-xs-6' : is_show,'col-xs-12' : !is_show}">
                                    <div class="form-group m-0">
                                        <label *ngIf="is_show">ENTRANCE DATE</label>

                                        <label *ngIf="!is_show">MONTHLY PARKING STARTING</label>
                                        <input *ngIf="is_show" readonly class=" form-control" aria-haspopup="true"
                                            type="text" id="startdate" name="startdate" [minDate]="minDate"
                                            [(ngModel)]='startDuration' [maxDate]="maxDate" #dp="bsDatepicker"
                                            (ngModelChange)="updateStartDuration()" bsDatepicker
                                            [(bsValue)]="startDuration" (onShown)="onShowPicker($event)">
                                        <input *ngIf="!is_show" readonly class=" form-control" aria-haspopup="true"
                                            type="text" id="startdate" name="startdate" [minDate]="minDate"
                                            [(ngModel)]='startDuration' #dp="bsDatepicker"
                                            (ngModelChange)="updateStartDuration()" bsDatepicker
                                            [(bsValue)]="startDuration" (onShown)="onShowPicker($event)">


                                    </div>


                                </div>
                                <div class="col-xs-6 border-left" *ngIf="is_show">
                                    <div class="form-group m-0">
                                        <label>ENTRANCE TIME</label>
                                        <select name="" [(ngModel)]="startTime" [ngModelOptions]="{standalone: true}"
                                            id="startTime" class="form-control" required="required"
                                            (change)="updateStartTime()">
                                            <option [value]="slot.value" *ngFor="let slot of timeSlots"
                                                [selected]="slot.value == startTime">{{ slot.time }}</option>
                                        </select>

                                    </div>
                                </div>
                            </div>
                            <div class="row border-top " *ngIf="is_show">
                                <div class="col-xs-6">
                                    <div class="form-group m-0">
                                        <label>EXIT DATE</label>
                                        <input readonly class="form-control" type="text" id="enddate" name="enddate"
                                            [minDate]="minDate" [maxDate]="maxEndDate" (onShown)="onShowPicker($event)"
                                            [(ngModel)]='endDuration' #dp="bsDatepicker"
                                            (ngModelChange)="updateEndDuration()" bsDatepicker
                                            [(bsValue)]="endDuration">
                                    </div>
                                </div>
                                <div class="col-xs-6 border-left">
                                    <div class="form-group m-0">
                                        <label>EXIT TIME</label>
                                        <select name="" [(ngModel)]="endTime" [ngModelOptions]="{standalone: true}"
                                            id="endTime" class="form-control" required="required"
                                            (change)="updateEndTime()">
                                            <option [value]="slot.value" *ngFor="let slot of timeSlots"
                                                [selected]="slot.value == endTime" data-slot="{{ slot }}"
                                                data-value="{{ endTime }}">{{ slot.time }}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button (click)="navigate()"
                            class="btn btn-primary btn-fill customeBtn btn-lg  btn-block text-left">Search Available
                            Parking Space</button>
                    </div>
                </div>
                <!-- <div class="col-sm-12 text-center mar-top-sm mb-2">
                    <img dBlock class="mb-2 visible-xs" src="../../assets/img/new-homepage/seal.svg" />
                </div> -->
                <div class="col-sm-12 visible-xs text-center mar-top-xs mb-2">
                    <a (click)="findNearMe()" class="btn btn-link text-center text-white btn-block">
                        <i class="fa fa-map-marker"> </i> &nbsp; <u>Find Parking Nearby</u>
                    </a>
                </div>
                <div style="font-size: 14px;" class="clearfix mar-top-sm  visible-xs">
                    <carousel [isAnimated]="true">
                        <slide *ngFor="let item of sliderstaticPageContent; let index = index">
                            <a href="{{item.internal_url}}">
                                <img src="{{item.offer_image}}" class="img-responsive">
                            </a>
                        </slide>
                        <!-- <slide>
                                <a href="/2022ICON15H/apply/?coupontype=monthly ">
                                    <img src="https://staging2.iconparkingsystems.com/cms/wp-content/uploads/2022/10/15-3-Month-Offer-Homepage.png" class="img-responsive">
                                </a>
                            </slide>
                            <slide>
                                <a href="/ICNW35H/apply ">
                                    <img src="https://staging2.iconparkingsystems.com/cms/wp-content/uploads/2022/10/Bitmap.png" class="img-responsive">
                                </a>
                            </slide> -->

                    </carousel>
                </div>
                <!-- <div style="font-size: 14px;" class="clearfix mar-top-sm  visible-xs" [innerHTML]="staticPageContent.post_content">
                    
                    </div>  -->


            </div>
            <div class="row no-gutters mb-2 mar-top-xs mar-bottom-sm  sliderSection justify-content-center  ">

                <div cardSlide class="col-lg-10 col-md-12 mb-2 ">
                    <div class="row  ">
                        <div class="col-sm-12  col-md-4 mb-2 " id="icon_for_business">

                            <!-- <div (click)="triggerEvent('Reserve Your Spots')" routerLink="/ourgifttoyou-w" style="cursor:pointer; padding: 0;" class="card slide" rounded>
                                <div class="row">
                                    <div class="col" style="height: 150px;     background-position: center center;     background-size: cover; background-image: url('../../assets/img/landing-pages/newyaer/hotels-banner-small.jpg');"></div>
                                   
                                        <div class="col" style="height: 150px;     background-position: center center;     background-size: cover; background-image: url('../../assets/img/landing-pages/newyaer/hero-graphic_600x400_v1_animated.gif');">

                                    </div>
                                    <div class="col">
                                        <h4 style="margin-top: 23px;">Weekend and Monthly Parking Savings</h4>
                                        <a routerLink="/ourgifttoyou-w" (click)="triggerEvent('Reserve Your Spots')"  style="margin-right: 15px; "class="btn  btn-link text-right">Book This Deal <img style="width: 10px; margin-top: -2px;" [src]="arrow_right"/> </a>

                                    </div>
                                </div>
                             
                            
                            </div> -->


                            <div (click)="triggerEvent('Reserve Your Spots')" id="icon_for_business_link"
                                routerLink="/business-parking-nyc" style="cursor:pointer" class="card slide" rounded>
                                <img src="../../assets/img/b2b/logo.png" height="40" /><br>
                                <h4>Icon For Business - Safe & Seamless Parking for Your Company</h4>
                                <a routerLink="/business-parking-nyc" (click)="triggerEvent('Reserve Your Spots')"
                                    class="btn  btn-link">Reserve Your Spots <img style="width: 10px; margin-top: -2px;"
                                        [src]="arrow_right" /> </a>
                            </div>
                        </div>
                        <!-- <div class="col-sm-12  col-md-4 mb-2">
                            <div style="cursor: pointer;" (click)="imageRedirect('redirect to neighborhoods page  from homepage')" routerLink="/parksafe-labour-day"  class="card slide noShadow " rounded>
                                <h2 style="margin-top: 4px;margin-bottom: 14px;" class="text-primary">ParkSafe this Labor Day</h2>
                            
                                <h4>Get unlimited across all 200+ <br />NYC Icon Parking garages</h4>
                                <!- <div class="flex-row align-items-center no-gutters">
                                    <div class="col-auto">
                                          <img style="max-height: initial; max-width: 110px;" class="img-responsive mar-left-0" src=".../../assets/img/new-homepage/parksafe-logo.png" />
                                    </div>
                                    <div class="col-xs-7">
                                        <h4 style="font-size: 16px;">&nbsp;&nbsp;  Park with Peace of Mind.</h4>
                                    </div>
                                </div> -->



                        <!-- <span style="position: absolute ; font-size:14px;right: 134px;bottom: 14px;"><i >This Week Only! </i></span><a routerLink="/parksafe-labour-day"  href="javascript:;" class="btn btn-link">GET YOUR SPOT <img class="arrowFix" style="width: 10px; margin-top: -2px;" [src]="arrow_right"/> </a> -->

                        <!-- <a href="javascript:;" routerLink="/business-parking-nyc" class="btn  btn-link">Contact a business specialist <img style="width: 10px; margin-top: -2px;" [src]="arrow_right"/> </a> -->
                        <!-- <progressbar customebar [value]="70"></progressbar> -
                            </div>
                        </div> -->
                        <div class="col-sm-12  col-md-4 mb-2" id="monthly_parking_special">
                            <div style="cursor: pointer;" id="monthly_parking_special_link"
                                (click)="triggerEvent('Shop Specials Now');" routerLink="/monthly-parking"
                                class="card slide noShadow " rounded>
                                <!-- <h2 class="text-primary text-uppercase mt-0 mb-1" style="font-size: 20px;">Enjoy a nyc staycation this<br class="visible-lg"> holiday season</h2> -->
                                <!-- <h4 style="font-size: 15px;padding-bottom: 5px;margin-bottom: 0;margin-top: 5px;">at our premier partners hotel destinations</h4> -->
                                <!-- <img src="../../assets/img/landing-page-hotels/all-logo.png" class="img-responsive" /> -->



                                <img src="../../assets/img/new-homepage/monthly-special-campaign.png" height="40" /><br>
                                <h4>Shop parking specials across the <br />neighborhoods of NYC</h4>
                                <!-- <div class="flex-row align-items-center no-gutters">
                                    <div class="col-auto">
                                          <img style="max-height: initial; max-width: 110px;" class="img-responsive mar-left-0" src=".../../assets/img/new-homepage/parksafe-logo.png" />
                                    </div>
                                    <div class="col-xs-7">
                                        <h4 style="font-size: 16px;">&nbsp;&nbsp;  Park with Peace of Mind.</h4>
                                    </div>
                                </div> -->



                                <a (click)="triggerEvent('Shop Specials Now');" routerLink="/monthly-parking"
                                    href="javascript:;" class="btn btn-link ">Shop Specials Now! <img class="arrowFix"
                                        style="width: 10px; margin-top: -2px;" [src]="arrow_right" /> </a>

                                <!-- <a href="javascript:;" routerLink="/business-parking-nyc" class="btn  btn-link">Contact a business specialist <img style="width: 10px; margin-top: -2px;" [src]="arrow_right"/> </a> -->
                                <!-- <progressbar customebar [value]="70"></progressbar> -->
                            </div>
                        </div>
                        <!--  old design -->
                        <div class="col-sm-12  col-md-4 mb-2 d-none " id="nyc_first_responder">
                            <div style="cursor:pointer;" id="nyc_first_responder_link"
                                (click)="triggerEvent('Iconic things to do NYC clicked')" routerLink="/things-to-do-NYC"
                                style="cursor:pointer" class="card slide things-bg" rounded>
                                <!-- <img [src]="thingstoDoimg" class=" customeImageSet" />   -->
                                <div class="wrappercustomeImageSet">
                                    <img [src]="thingsText" class="customeImageSet" />
                                </div>
                                <a href="javascript:;" (click)="triggerEvent('Iconic things to do NYC clicked')"
                                    routerLink="/things-to-do-NYC" class=" btn btn-link"><img style="width: 170px;"
                                        [src]="thingsFind" /> </a>

                                <!-- <a href="javascript:;" (click)="triggerEvent('Iconic things to do NYC clicked')" routerLink="/things-to-do-NYC" class=" btn  btn-link"></a> -->
                            </div>
                        </div>
                        <!--  design ends -->
                        <!--  for new-gift-page -->
                        <div class="col-sm-12  col-md-4 mb-2" id="new_gift_Page">
                            <div style="cursor:pointer;" id="new_gift_page_link"
                                (click)="triggerEvent('Iconic things to do NYC clicked')" routerLink="/things-to-do-NYC"
                                style="cursor:pointer" class="card slide jan-offer" rounded>
                                <div class="sideWrapArrow"><img [src]="sideWrapImg" width="100px"></div>
                                <div class="wrappercustomeImageSet" style="margin-left: 10px;margin-top: 45px;">
                                    <img style="max-width:300px" [src]="thingsText" class="customeImageSet mt-4 ml-4"
                                        style="max-width: 94%;" />
                                </div>
                                <!-- <a href="javascript:;" style="bottom: 0;" (click)="triggerEvent('12 Days of Gifting clicked')" routerLink="/12-days-of-gifting" class=" btn btn-link"><img style="width: 120px;" [src]="thingsFind"/> </a> -->

                                <a style="right:0; bottom:0;" href="javascript:;"
                                    (click)="triggerEvent('Iconic things to do NYC clicked')"
                                    routerLink="/things-to-do-NYC" class=" btn  btn-link"><img style="width: 120px;"
                                        [src]="thingsFind" /></a>
                            </div>
                        </div>

                        <!-- <div class="col-sm-12  col-md-4 mb-2 ">
                            <div (click)="triggerEvent('$15 PARKING RATE FOR FIRST RESPONDERS')" routerLink="/cares" style="cursor:pointer" class="card slide" rounded>
                                <div class="wrappercustomeImageSet">
                            <img src="../../assets/img/new-homepage/b2b-img/safty_n.png" class=" customeImageSet" />

                                </div>
                                <h4 class="mar-top-0">20% Off Daily Rates</h4>
                                <a href="javascript:;" (click)="triggerEvent('$15 PARKING RATE FOR FIRST RESPONDERS')" routerLink="/cares" class=" btn  btn-link">Learn More <img style="width: 10px; margin-top: -2px;" [src]="arrow_right"/> </a>
                            </div>
                        </div> -->
                    </div>

                </div>
            </div>

        </div>

    </section>



</div>
<section sourceSense class="getdown-b2b-home  ">

    <div class="flex-row">
        <div class="col-sm-12 col-md-6 col-lg-6 visible-xs imgWrapper ">
            <div style="min-height: 300px;"></div>

        </div>

        <div class="col-sm-12 col-md-5 col-lg-6  contentPad mob-fix  ">
            <div class="py-5">
                <img height="60" src="../../assets/img/new-homepage/b2b-img/business-logo-black.png" />
                <h1 clss="font-weight-bold ">
                    Let’s get down <br class="visible-xs" /> to business
                </h1>
                <p class="b2b-text ">Looking for the safest way for your team <br>to return to work? Icon can help.</p>
                <p class="b2b-text "><img class="mar-right-xs" width="8"
                        src="../../assets/img/new-homepage/b2b-img/dots.png"> Guaranteed parking for your company</p>
                <p class="b2b-text "><img class="mar-right-xs" width="8"
                        src="../../assets/img/new-homepage/b2b-img/dots.png"> Limited spots still available</p>
                <p class=" mar-top-sm "><a (click)="contactSeo();triggerEvent('click facity')"
                        class="btn main-btn theme-btn fixBtnMain " style="max-width: 100%;"
                        routerLink="/business-parking-nyc">Reserve your guaranteed spots now !</a></p>
                <a style="font-size: 14px;" class="pad-left-sm responsiveBtn"
                    href="mailto:iconforbusiness@iconparking.com">&nbsp; <i class="fa fa-envelope-o "
                        aria-hidden="true "></i>&nbsp; <span>iconforbusiness@iconparking.com</span></a>

            </div>
        </div>
        <!-- <div class="col-sm-12 col-md-6 col-lg-6 hidden-xs imgWrapper ">
            
           
        </div> -->
        <!-- <div class="col pr-0">
            <div class="row jocoSection">
                <div class="col-12 order-1 order-md0 col-sm bg-primary firstBlock">
                    <div class="py-5  my-5">


                        <h1 class="mb-0">
                            Introducing a true <br>
                            Park & Ride.
                        </h1>
                        <p class="my-5">
                            Icon Parking is proud to offer more mobility by<br>
                            teaming up with JOCO Premium E-bikes. Drop<br> off your car and pick up a bike at Icon<br>
                            locations citywide.
                        </p>
                        <a (click)="triggerEvent('click from home page to ridejoco')" href="/ridejoco" target="_blank"
                            class="btn btn-black px-5 btn-fill btn-lg rounded-0 ">Learn More</a>
                    </div>
                </div>
                <div class="col-12 col-sm order-0 order-md-1  p-0">
                    <div class="image-2">

                    </div>

                </div>
            </div>
        </div> -->
        <div class="col" style=" background: #ffffff;">
            <div class="trustPilotWidget mt-5 pt-3">
                <app-trustbox></app-trustbox>
            </div>
        </div>
    


    </div>
</section>








<section sourceSense class="monthly-section-hm bg-white sec-padding-tb-30 border-top ">
    <div class="container-fluid ">

        <div class="flex-row ">

            <div class="col-sm-12 col-md-6 hidden-xs  persion-and-cars ">
                <div class="car-persion-position ">
                    <img alt="image " class="person  animated  delay-250 fadeInRightShort   "
                        src="../../assets/img/new-homepage/person.png ">
                    <img alt="image " class="car  animated  delay-500 fadeInRightShort  "
                        src="../../assets/img/new-homepage/car.png ">
                </div>
            </div>
            <div class=" col-sm-12 col-md-6  ">
                <h1 class="font-weight-bold ">Safety from the street</h1>
                <p style="font-size: 16px;">Park monthly in a safe, secure location.</p>
                <ul class="list-unstyled mt-3 ">
                    <li>
                        <img height="25 " alt="image " src="../../assets/img/new-homepage/arrows.svg "> <span
                            class="py-4 ">Unlimited in/out privileges at your home garage, whenever you want</span>
                    </li>

                    <li>
                        <img height="25 " alt="image " src="../../assets/img/new-homepage/vehicle.svg "> <span
                            class="py-4 ">Online and text message vehicle request service</span>
                    </li>
                    <li>
                        <img height="25 " alt="image " src="../../assets/img/new-homepage/account.svg "> <span
                            class="py-4 ">Online account management</span>
                    </li>

                </ul>
                <div class="order-online-btn ">
                    <p class="mar-bottom-sm ">
                        <a class="hidden-xs" (click)="viewBenefit();formonthly();" href=" javascript:;">View All
                            Benefits</a>
                        <a class="visible-xs text-center" (click)="viewBenefit();formonthly();"
                            href="javascript:; ">View All Benefits</a>
                    </p>
                    <a rounded class="btn main-btn btn-fill btn-primary " href="javascript:;"
                        (click)="searchLocation('monthly');triggerEvent('book now')">Book Now! </a>



                    <p><small>Contact us to get started now and we can help you figure out the best parking deal for
                            your needs and budget.</small></p>
                </div>
            </div>

        </div>
    </div>


</section>



<section class="rewardSection" style="display: none;">
    <div class="container">
        <div class=" row  ">
            <div class="col-md-10 col-md-offset-1 ">
                <div class="  bg-white ">
                    <div class=" text-center">
                        <h3 font32 class="mar-bottom-md ">Parking has it’s perks</h3>

                        <div class="row mar-bottom-xs">
                            <div class="col-sm-4 px-1 col-xs-12 text-center">
                                <img width="54px" [src]="piggibank">
                                <h4>Immediate Parking Discounts</h4>
                                <p>Receive member discounts on most direct hourly or daily parking purchases.</p>
                            </div>
                            <div class="col-sm-4 px-1 col-xs-12 text-center">
                                <img width="54px" [src]="gift">
                                <h4>Annual Bonus
                                </h4>
                                <p>
                                    Be rewarded for parking based<br class="hidden-xs" /> on your tier level.
                                </p>
                            </div>
                            <div class="col-sm-4 px-1 col-xs-12 text-center">
                                <img width="54px" [src]="doller_tag">
                                <h4>
                                    Best Pricing
                                </h4>
                                <p>
                                    Always park for the lowest price available through Icon and Quik Park.
                                </p>
                            </div>
                        </div>
                        <div class="flex-row  justify-content-center">

                            <div class="col-sm-4"><a rounded style="cursor:pointer;" (click)="redirectToPage();"
                                    class="btn maxWidthBtn btn-primary btn-fill btn-block main-btn mar-bottom-sm rounded">Receive
                                    Instant Discounts</a> </div>

                        </div>
                    </div>

                </div>

            </div>


        </div>
    </div>

    <div class="reward-logo bg-dark " rounded>
        <div class="row">

        </div>

        <img width="120px" class="img-responsive" [src]="logo_reward"><br class="visible-xs">
        <img class="img-responsive " [src]="tier ">


    </div>


</section>

<section sourceSense class="app-section sec-padding-tb-30 ">
    <div class="row">
        <div class="col-xs-12 col-sm-10 col-lg-10 col-sm-offset-1 col-md-offset-1 col-lg-offset-1">
            <div class="container ">
                <div class="row ">

                    <div class="col-sm-12 col-md-6 col-md-offset-1">
                        <div class="row visible-xs">
                            <div class="col-xs-4">
                                <img alt="Google play " [src]="appicon" height="90">
                            </div>
                            <div class="col-xs-8">
                                <h1 class="section-title-1 font-weight-bold ">
                                    <span><b>You find it.</b> <b>You book it.</b><br class="hidden-xs" /> <b>We park
                                            it.</b></span>
                                </h1>
                            </div>
                        </div>
                        <h1 class="section-title-1 hidden-xs font-weight-bold ">
                            <img alt="Google play " appIcon [src]="appicon" height="90">
                            <span><b>You find it.</b> <b>You book it.</b><br class="hidden-xs" /> <b>We park
                                    it.</b></span>
                        </h1>
                        <h5 class="sub-section-title ">
                            <!-- Enter your phone number below and we will send you a link to download IconGo -->
                            Download IconGo for:
                        </h5>
                        <ul class="list-unstyled iconic-list-1 ">
                            <li><img width="30px" src="../../assets/img/dollar_icon.svg" /> Special discounts &
                                promotions</li>
                            <li><img width="30px" src="../../assets/img/discount_icon.svg" /> Direct booking discounts
                            </li>
                            <li><img width="30px" src="../../assets/img/new-homepage/lrLogo.png" /> Seamless check in
                                and check out</li>
                        </ul>
                        <p class="font-weight-bold  "><a routerLink="/app" (click)="triggerEvent('Benefits')">View All
                                Benefits</a></p>
                        <!-- <div class="input-group ">
                            <input class="form-control " value="" id="sendapp" placeholder="Enter your mobile number "
                                type="text ">
                            <span class="input-group-btn ">
                                <button class="btn theme-btn text-uppercase "
                                    (click)="sendApp();triggerEvent('sendApp')" type="submit ">SEND</button>
                            </span>
                        </div> -->


                        <div class="appstore-btn hidden-xs ">

                            <a target="_blank" [href]="appStoreUrl" class="appstore-img  "><img alt="App Store "
                                    [src]="appstore" height="57"></a>

                            <a target="_blank" class="mar-top-xs" [href]="googlePlayUrl"><img alt="Google play "
                                    [src]="gstore " height="56"></a>
                        </div>


                        <div class="flex-row no-gutters hidden ">
                            <div class="col-auto">
                                <div class="appstore-btn ">

                                    <a target="_blank" style="display: block;" [href]="appStoreUrl"
                                        class="appstore-img  "><img alt="App Store " [src]="appstore" height="57"></a>
                                    <br />

                                    <a target="_blank" style="display: block;" class="mar-top-xs"
                                        [href]="googlePlayUrl"><img alt="Google play " [src]="gstore " height="50"></a>
                                </div>
                            </div>
                            <div class="col-auto hidden">
                                <img class="qrImage" width="120" src="../../assets/img/new-homepage/qrScan.png" />
                            </div>
                        </div>
                        <div class=" mar-top-sm visible-xs">
                            <a target="_blank" [href]="appStoreUrl" class="appstore-img  "><img alt="App Store "
                                    [src]="appstore" height="45"></a> &nbsp;

                            <a target="_blank" [href]="googlePlayUrl"><img alt="Google play " [src]="gstore "
                                    height="48"></a>

                        </div>

                    </div>
                    <div class="col-sm-12 col-md-5 ">
                        <div class="imgappMobile ">

                            <!-- <img alt="Image " class="animated mobileAnimation delay-1000 screen1 fadeInUpShort " src="assets/images/screen_1.png ">
                <img alt="Image " class="animated mobileAnimation delay-500 screen2 fadeInUpShort " src="assets/images/screen_2.png "> -->
                            <img alt="Image " class="animated fadeInUpShort screen3 "
                                src="../../assets/img/new-homepage/screen_3.png " width="100% ">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section sourceSense class="takeNextLavel ">
    <div class="container ">
        <div class="row ">
            <div class="col-sm-8 col-md-6 col-xs-12 col-md-offset-3 col-sm-offset-2 ">
                <div class="text-center ">
                    <h1 font52 class="text-white mar-top-md ">Let’s take this to the next level</h1>
                    <p class="lead ">Don't miss out on News, Promotions and Special Offers</p>
                </div>
                <div class="flex-row no-gutters mar-bottom-md ">
                    <div class="col-12 mr-2 col-sm mb-2 ">
                        <input class="form-control " value="" id="addsubscription"
                            placeholder="Please enter your email address " type="text ">

                    </div>
                    <div class="col-sm-auto col-12 ">
                        <button class="btn theme-btn btn-primary btn-fill btn-block text-uppercase"
                            (click)="subscription();triggerEvent('subscribe')" type="submit ">SUBSCRIBE</button>

                    </div>
                </div>

            </div>
        </div>


    </div>
</section>
<section sourceSense class="stats-section-1 text-white text-center sec-padding-tb-30 ">
    <div class="container text-center  counter ">
        <div class="row">
            <div class="col-md-8 col-md-offset-2">
                <h1 class="text-uppercase">300+ Million <br class="visible-xs"> Cars Parked</h1>
                <p class="hidden-xs showOnClick" style="font-size: 16px;">
                    Just about anywhere you go in Manhattan, you’re not far from a safe and convenient Icon parking
                    facility. Icon Parking, is the largest parking operator in Manhattan with over 200
                    special locations and more than 50 years of experience. “PARKING IS JUST THE START<sup>TM</sup>”, a New York
                    company serving the premier needs of one of the best cities in the world."
                </p>
                <a (click)="showHideData()" href="javascript:;"
                    class="btn-300-text btn visible-xs btn-link btn-block text-white">Learn More</a>
            </div>
        </div>

        <div class="row hidden ">
            <div class="col-xs-12 col-sm-4 col-md-4 counter border-right ">
                <h1><span>200</span>+</h1>
                <h4>Locations</h4>
            </div>
            <div class="col-xs-12 col-sm-4 col-md-4 counter border-right ">
                <h1><span>40K</span>+</h1>
                <h4>Cars Parked Daily</h4>
            </div>
            <div class="col-xs-12 col-sm-4 col-md-4 counter ">
                <h1><span>50</span>+</h1>
                <h4>Years in business</h4>
            </div>
        </div>
    </div>
</section>

<a #baseballmodelbtn (click)="baseballPopup.show()" class="hide">openModel</a>
<div bsModal #baseballPopup="bs-modal" class="modal fade" tabindex="-1" role="dialog" [config]="{backdrop: 'static'}"
    aria-labelledby="mySmallModalLabel" aria-hidden="true">
    <div class="modal-dialog baseballPopup modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close pull-right" aria-label="Close" (click)="baseballPopuphide()">
                    <img [src]="crossSvg" alt="image" width="16">
                </button>
                <button style="display: none;" type="button" #hideBaseballPopup class="close" aria-label="Close hide"
                    (click)="baseballPopup.hide()"></button>
            </div>
            <div class="modal-body">
                <app-lazy-load [isLoaded]="isLoaded" [sectionName]></app-lazy-load>
                <div *ngIf="isLoaded">
                    <a *ngIf="baseballContent" (click)="redirctToBaseball()" style="cursor:pointer">
                        <img class="img-responsive" [src]="baseballContent" alt="bassball" />
                    </a>
                    <a *ngIf="baseballModel?.post_content" style="cursor:pointer">
                        <div style="padding: 20px;" class="mar-sm-top baseballModelcontent"
                            [innerHTML]="baseballModel?.post_content">
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>

<footer class="sec-padding-tb-40 ">
    <div class="container pt-4 pb-4 ">
        <div class="row ">
            <div class="col-sm-4 ">
                <div class="logo-part ">
                    <img [src]="icon_logo " width="100 " class="ftr-logo ">
                    <!-- <img [src]="qp_Logo " width="50 " class="ml-4 "> -->
                    <div class="trusted-rate-footer">
                        <!-- <div  class="trustpilot-widget ratingStar" data-locale="en-US" data-template-id="53aa8807dec7e10d38f59f32" data-businessunit-id="5feb84d1e2aa300001625337" data-style-height="115px" data-style-width="100%" data-theme="light" data-location="" data-text-color="#ffffff">
                            <a href="https://www.trustpilot.com/review/iconparkingsystems.com" style="display: none;" target="_blank" rel="noopener">Trustpilot</a>
                          </div> -->
                        <div id="trustboxOpenHome" class="trustpilot-widget ratingStar" data-locale="en-US"
                            data-template-id="53aa8807dec7e10d38f59f32" data-businessunit-id="5feb84d1e2aa300001625337"
                            data-style-height="115px" data-style-width="250px" data-theme="dark" data-location="">
                            <a href="https://www.trustpilot.com/review/iconparkingsystems.com" style="display: none;"
                                target="_blank" rel="noopener">Trustpilot</a>
                        </div>
                    </div>
                    <div class="footer-social ">
                        <p class=" ">
                            <a class="socialIcon " target="_blank" [href]="instagramUrl"><img [src]="instagramLogo "
                                    alt="icon "></a>
                            <a class="socialIcon " target="_blank" [href]="facebookUrl"><img [src]="fbLogo "
                                    alt="icon "></a>
                            <a class="socialIcon " target="_blank" [href]="twitterUrl"><img [src]="twiterLogo "
                                    alt="icon "></a>
                        </p>
                        <h4>PARKING IS JUST THE START<sup>TM</sup></h4>
                    </div>
                </div>
            </div>
            <div class="col-sm-8 ">
                <div class="row ">
                    <div class="col-md-7 ">
                        <div class="row ">
                            <div class="col-md-4 visible-xs mar-bottom-sm ">
                                <div class="footerAppButton">
                                    <div class="flex-row no-gutters">
                                        <div class="col-auto">
                                            <img class="alterImg"
                                                src="../../../assets/img/new-homepage/arrow-list-icongo-wt.svg">
                                        </div>
                                        <div class="col">
                                            <h4>
                                                PARK LIKE A VIP,<br>
                                                <span class="text-primary">DOWNLOAD THE APP</span>
                                            </h4>
                                        </div>

                                    </div>
                                    <p>
                                        Icon GO app offers a level of service
                                        you just won't find anywhere else.
                                    </p>
                                    <ul>
                                        <li>
                                            <img src="../../../assets/img/new-homepage/b2b-img/dots.png" height="6px">
                                            &nbsp; Direct booking discounts
                                        </li>
                                        <li>
                                            <img src="../../../assets/img/new-homepage/b2b-img/dots.png" height="6px">
                                            &nbsp; Seamless check in and check out
                                        </li>
                                        <li>
                                            <img src="../../../assets/img/new-homepage/b2b-img/dots.png" height="6px">
                                            &nbsp; Exclusive services and features
                                        </li>
                                    </ul>
                                    <p>
                                        Download the app
                                    </p>
                                    <div class="flex-row no-gutters">
                                        <div class="col">
                                            <a target="_blank" [href]="appStoreUrl" class="appstore-img  "><img
                                                    alt="App Store " [src]="appstore" class="img-responsive"></a>

                                        </div>
                                        <div class="col">
                                            <a target="_blank" [href]="googlePlayUrl"><img alt="Google play "
                                                    [src]="gstore " class="img-responsive"></a>

                                        </div>

                                    </div>
                                </div>

                                <!-- <ul>
                                    <li> <a (click)="callcustomer()" [href]="phone" class="text-white"> 1 (877) 727-5464</a></li>
                                </ul> -->
                            </div>
                            <div class="col-sm-6 col-md-6 ">
                                <ul>
                                    <!-- <li> <a routerLink="/business-parking-nyc">Business to Business Sales</a></li> -->
                                    <!-- <li> <a *ngIf="!isLoyaltyMember" routerLink="/rewards">icon rewards</a>
                                        <a *ngIf="isLoyaltyMember" routerLink="/my-account/icon-rewards">icon rewards</a> </li> -->
                                    <li> <a [href]="newsTips">news + tips</a> </li>
                                    <li> <a style="cursor: pointer;"
                                            (click)="triggerEvent('MONTHLY PARKING SPECIALS');formonthly();">monthly
                                            parking</a></li>
                                    <li> <a routerLink="/contact">contact us</a></li>
                                    <!-- <li> <a routerLink="/neighborhoods/central-park/monthly">neighborhoods</a></li> -->
                                    <li><a routerLink="/faq">faq</a></li>
                                </ul>
                            </div>

                            <div class="col-sm-6 col-md-6">
                                <ul>
                                    <li *ngIf="!isLoggedIn">
                                        <a [class.active]="logoutmenu()" data-toggle="modal" id="loginPopup"
                                            href='#login'>login</a>
                                    </li>
                                    <li *ngIf="isLoggedIn">
                                        <a routerLink="/my-account/account">my account</a>
                                    </li>
                                    <li>
                                        <a routerLink="/about-us">About Us</a>
                                    </li>
                                    <li>
                                        <a routerLink="/evcharging">EV Charging</a>
                                    </li>
                                    <li>
                                        <a routerLink="/careers">Careers/Jobs</a>
                                    </li>
                                    <!-- <li>
                                        <a routerLink="/faq">faq</a>
                                    </li> -->
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-5 hidden-xs ">
                        <div class="footerAppButton">
                            <div class="flex-row no-gutters">
                                <div class="col-auto">
                                    <img class="alterImg"
                                        src="../../../assets/img/new-homepage/arrow-list-icongo-wt.svg">
                                </div>
                                <div class="col">
                                    <h4>
                                        PARK LIKE A VIP,<br>
                                        <span class="text-primary">DOWNLOAD THE APP</span>
                                    </h4>
                                </div>

                            </div>
                            <p>
                                Icon GO app offers a level of service
                                you just won't find anywhere else.
                            </p>
                            <ul>
                                <li>
                                    <img src="../../../assets/img/new-homepage/b2b-img/dots.png" height="6px"> &nbsp;
                                    Direct booking discounts
                                </li>
                                <li>
                                    <img src="../../../assets/img/new-homepage/b2b-img/dots.png" height="6px"> &nbsp;
                                    Seamless check in and check out
                                </li>
                                <li>
                                    <img src="../../../assets/img/new-homepage/b2b-img/dots.png" height="6px"> &nbsp;
                                    Exclusive services and features
                                </li>
                            </ul>
                            <p>
                                Download the app
                            </p>
                            <div class="flex-row no-gutters">
                                <div class="col">
                                    <a target="_blank" [href]="appStoreUrl" class="appstore-img  "><img alt="App Store "
                                            [src]="appstore" class="img-responsive"></a>

                                </div>
                                <div class="col">
                                    <a target="_blank" [href]="googlePlayUrl"><img alt="Google play " [src]="gstore "
                                            class="img-responsive"></a>

                                </div>

                            </div>
                        </div>

                    </div>


                </div>
                <div class="row">
                    <div class="col-md-12 ">
                        <!-- <h3 class="visible-xs mob-ftr-bottom "><a (click)="callcustomer() " [href]="phone " class="text-white ">1 (877) 727-5464</a></h3> -->
                        <p class="ftr-copy "><small>© {{copyrightyear}} Icon Parking - All Rights Reserved. <br class="visible-xs"><a
                                    routerLink="/terms-of-service">Terms of Service</a> & <a
                                    routerLink="/terms-of-service">Privacy Policy</a></small></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>

<!-- <app-footer></app-footer> -->