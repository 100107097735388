
<!-- create account component html -->

  <app-header></app-header>

  <!-- content for sign up -->
  <section class="sineUpWrapper orangeBar pageWrapperLadning mar-top-lg pad-bottom-sm">
    <div class="container">
      <div class="row">
        <div class="col-xs-12 mar-lr-auto col-sm-10 col-md-6 col-lg-6">
          <div class="panel-body signupform">
            <form name="form" role="form" id="floatForm" (ngSubmit)="registerUser(formData, f)" #f="ngForm" novalidate>
              <div class="servererrors" id="signuperrors-container"></div>
              <h2 class="text-primary text-uppercase mar-top-0 mar-bottom-sm">Create Account</h2>
              <div class="form-group" [ngClass]="{ 'has-error' : f.submitted && !name.valid}">
                <label class="control-label" for="fname">Full name</label>
                <input class="form-control" type="text" name="fname" (paste)="onPaste($event)"  (keypress)="restChar($event)" [(ngModel)]="formData.name" #name="ngModel" required />
                <div *ngIf="f.submitted && !name.valid" class="help-block">Full name is required</div>
              </div>
              <div class="form-group" [ngClass]="{ 'has-error' : f.submitted && !email.valid}">
                  <label class="control-label" for="email">Email Address</label>
                  <input class="form-control" type="text" (keydown)="restSpace($event)" name="email" [(ngModel)]="formData.email" #email="ngModel"
                    required pattern="{{emailRegex}}" />
                  <div *ngIf="f.submitted && !email.valid" class="help-block">Email address is required</div>
              </div>
              <div class="form-group" [ngClass]="{ 'has-error' : f.submitted && !phone.valid}">
                <label class="control-label" for="phone">Phone Number</label>
                <input class="form-control" type="text" name="phone" minlength="10" [textMask]="{mask: phoneMask}" (keypress)="phoneFormat($event)" [(ngModel)]="formData.phone" #phone="ngModel" />
               <div *ngIf="f.submitted && !phone.valid" class="help-block">Not a valid phone number</div>
              </div>
              <div class="form-group" [ngClass]="{ 'has-error' : f.submitted && !password.valid}">
                <label class="control-label" for="password">Password</label>
                <input class="form-control" type="password" name="password" [(ngModel)]="formData.password" #password="ngModel" required validateEqual="cpassword" reverse="true"/>
                <div *ngIf="f.submitted && !password.valid" class="help-block">{{passwordErrMsg}}</div>
              </div>
              <div class="form-group" [ngClass]="{ 'has-error' : f.submitted && !cpassword.valid}">
                  <label class="control-label" for="cpassword">Confirm Password</label>
                  <input class="form-control" type="password" name="cpassword" [(ngModel)]="formData.cpassword" #cpassword="ngModel" required validateEqual="password" />
                  <div *ngIf="f.submitted && !cpassword.valid" class="help-block">Confirm Password and Password should be same</div>
                </div>
              <div class="form-group mar-top-md mar-bottom-0 col-xs-10 col-xs-offset-1">
                <button class="btn btn-primary theme-btn btn-lg btn-block active">CREATE ACCOUNT</button>
              </div>


            </form>

          </div>
        </div>
      </div>
    </div>

  </section>


<app-footer></app-footer>

