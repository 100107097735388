import { Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { FilterHelper } from '../../../classes/filter-helper';
import { Ng2DeviceService } from 'ng2-device-detector';
import { UserserviceService } from '../../../services/userservice.service'
import { CustomCode } from '../../../shared/CustomCode';
import * as $ from 'jquery';



@Component({
  selector: 'app-mobile-search-filter-nav',
  templateUrl: './mobile-search-filter-nav.component.html',
  styleUrls: ['./mobile-search-filter-nav.component.scss']
})
export class MobileSearchFilterNavComponent implements OnInit {

	@Output() updateMobileNavDailyFilters = new EventEmitter<boolean>();
	@Input() startTime: any;
  @Input() endTime: any;
  @Input() showTies:boolean = true;
	// startTime:any;
	// endTime:any;
	sign:any = '';
	is_landing_page:any=false;
	Couponmessage=''
    show_strip_search:any=false
    is_landing_page_save:any=true;
	minTime:any;
	showneighbour:any=true;
	is_show_landing_strip:any=false;
	isLoggedIn=false
	inactiveFacility:any=false;
	lengthOfStay:any;
	maxDate:any;
  sixMonthDates:any;
  is_home_page:any=0;
	filterData:any = {
		'currdate' : '',
		'length_of_stay' : '',
		'startTime' : '',
		'endTime' : ''
	};

	mobileNavStartTime = {
		display: 'bottom',
		theme: 'ios',
		dateFormat: 'M dd, D,',
		steps: {
			hour: 0,
			minute: 30,
			second: 0,
			zeroBased: true
		},
		dateWheels: '|D M d|',
		onSet: (event, inst) => {
			this.updateMobileNavStartTime(event, inst);
		}
	}

	mobileNavEndTime = {
		display: 'bottom',
		theme: 'ios',
		dateFormat: 'M dd, D, ',
		steps: {
			hour: 0,
			minute: 30,
			second: 0,
			zeroBased: true
		},
		dateWheels: '|D M d|',
		onSet: (event, inst) => {
			this.updateMobileNavEndTime(event, inst);
		}
	}
	deviceInfo=null
	isMonthly:boolean = false;
  filterBadge = 'filter-badge-loyalty';
  @Output() updateTierToNeighbour = new EventEmitter();
	constructor(private userServiceObj: UserserviceService,private deviceService:Ng2DeviceService,private activatedRouteObj:ActivatedRoute,private toaster: ToastrService, private router: Router) { }

myFunction() {
   $('.navbar,.menuToggle,.listBtn,footer,.listToggleFilter').hide();
   $('.searchFilter.hidden-xs').css('margin-top','0px');
   $('.SearchPanelMobile').css('top','15px');
   setTimeout(()=>{
  $('.mobile-search-filter-nav').addClass('widget');
   },1000);
  }


	ngOnInit() {
    
    if(window.self!==window.top)
    {
       this.myFunction();   
     }
    if (localStorage.getItem('access_token')) {
      this.isLoggedIn = true;
 }
		 if(!sessionStorage.getItem('promoApply'))
        {
          localStorage.removeItem('is_landing_page');
          localStorage.removeItem('routeUrl');
          localStorage.removeItem('template_slug');
          localStorage.removeItem('promotion_id');
          localStorage.removeItem('promocode');
          localStorage.removeItem('action_type');
          localStorage.removeItem('reservation_apply');
        }

    let getUrl = window.location;

		if(this.router.url.indexOf('neighborhoods')>0)
		{
			this.showneighbour=false;
		}else if(getUrl.pathname=='/' || this.router.url.indexOf('utm_expid')>0 || getUrl.pathname==='/variant/' || getUrl.pathname==='/variant')
    {
      this.showneighbour=0;
    }else{
			this.showneighbour=true;
    }
    if(this.router.url=='/' || this.router.url.indexOf('/?') > -1)
        {
          this.is_home_page=1
        }else{
          this.is_home_page=0;
        }
		if (this.router.url=='/' || this.router.url.indexOf('search') > 0 || this.router.url.indexOf('neighborhoods')>0 || this.router.url.indexOf('facility')>0)
        {
          this.show_strip_search=true;
        }
        if(localStorage.getItem('is_show_landing_strip')=='true')
        {
             this.is_show_landing_strip=true;
        }
        if(localStorage.getItem('is_landing_page')=='true' && localStorage.getItem('action_type')=='apply' )
        {
           if (localStorage.getItem('access_token')) {
               let data = {};
               let mode='apply'
               data = {
                      'promocode':localStorage.getItem('promocode'),
                       'user_id' :localStorage.getItem('userid')};
                 
                this.userServiceObj.validatePromoCode(data)
                 .then(
                 (data:any)=> {
                          if (data['data'].is_promocode_valid) {
                          if (mode == "save") {
                              //this.savePromocode(neighborhood);
                          } else {
                              //this.notifyReservationPromoCodeApply();
                          }
                          } else 
                          {
                              localStorage.setItem('couponMessage',data['data'].message)
                             localStorage.setItem('errorType','1')
                          }
                          this.promocodeApply();
                        },
               error => {

                        localStorage.setItem('couponMessage',error.errors.message)
                        localStorage.setItem('errorType','0')
                        if(error.errors.message == "This Promocode is only for guest user")
                          {
                            localStorage.setItem('errorType','4');
                          }
                          else if (error.errors.message == "Please log in or create an account to redeem this promo code.") 
                          {
                              localStorage.setItem('errorType','1')
                              //this.notifyAccountRequirement();
                                //this.launchLoginPopup();
                          }
                           else if (error.errors.message == "Please enter a valid Promotion Code. Please note that a prepaid voucher is not a Promo Code.") 
                          {
                              localStorage.setItem('errorType','1')
                          }
                          this.promocodeApply();
                        }
                          );
           }
        }
        this.promocodeApply()
         
		if(localStorage.getItem('inactiveFacility'))
        {
          this.inactiveFacility=true;
        }
        setTimeout(() => {
          localStorage.removeItem('inactiveFacility')
        }, 2000);  
		this.deviceInfo = this.deviceService.getDeviceInfo();
		// this.mobileSearchFilterNav.updateStartTimeManually(this.startDuration);
		// this.mobileSearchFilterNav.updateEndTimeManually(this.endDuration);
		this.isMonthly = false;
		if (this.router.url.indexOf('monthly') > -1) {
			this.isMonthly = true;
		}

    this.minTime = FilterHelper.defaultStartDuration();
    
		this.activatedRouteObj.params.subscribe((params: Params) => {
        if (params['lat'] && params['long'] && params['currdate'] && params['radius'] && params['label'] && params['type'] && params['device']) 
        {
          let currdate = FilterHelper.removeSpecialCharacters(params['currdate']);
          let urlDate:any = '';
          if(this.deviceInfo.browser!=='safari')
          {
            urlDate = new Date(currdate);
          }else
          {
            let tzones:any = new Date()
            let startDurati=tzones.toString()
            let locs = startDurati.search("GMT");
              
            let sbustring_timezone = tzones.toString().slice(parseInt(locs)+3,parseInt(locs)+8);
            let  txt2 = sbustring_timezone.slice(0, 3) + ":" + sbustring_timezone.slice(3)
		        //let  txt2 = sbustring_timezone.slice(0, 3) + ":" + sbustring_timezone.slice(3)
		        let dat = currdate+''+txt2
            urlDate = new Date(dat);
          }
          let currentDate = new Date();
          currentDate.setHours(currentDate.getHours() - 1);
          if(urlDate<currentDate)
          {
            
            currdate=CustomCode.getDateForMapSearchSlashFormat()
          //  this.router.navigate(['/search', params['lat'], params['long'], CustomCode.getDateForMapSearchSlashFormat(), params['radius'], params['label'], params['type'], params['device']]);
          }
          var searchType=localStorage.getItem('searchType')
          
          if(this.deviceInfo.browser!=='safari')
      		{
         		let timeZoneOffset = Intl.DateTimeFormat().resolvedOptions().timeZone
            this.startTime = FilterHelper.defaultStartDurationUrl(currdate);
            this.endTime = FilterHelper.defaultEndDurationUrl(currdate);
            currdate=FilterHelper.defaultChangeDate(currdate)
	        	if(searchType=='event') {
             let endTime=localStorage.getItem('endtime')
             currdate=FilterHelper.defaultChangeDate(currdate)
             this.endTime = FilterHelper.defaultEndDurationUrlEvent(endTime,currdate);
       			} 
             if(searchType=='widget') {
             let endTime=localStorage.getItem('endtime')
             this.endTime = FilterHelper.defaultEndDurationUrlWidget(endTime,currdate);
             }
      	    }
            else{
        		let timeZoneOffset = Intl.DateTimeFormat().resolvedOptions().timeZone
            this.startTime = FilterHelper.defaultStartDurationUrlForSafari(currdate);
             this.endTime = FilterHelper.defaultEndDurationUrlForSafari(currdate);
             currdate=FilterHelper.defaultChangeDate(currdate)
         		if(searchType=='event') {
               //this.startTime = FilterHelper.defaultStartDurationUrlForSafariEvents(currdate);
              
         			let endTime=localStorage.getItem('endtime')
         			this.endTime = FilterHelper.defaultEndDurationUrlEventSafari(endTime,this.startTime);
         			} 
              if(searchType=='widget') {
             let endTime=localStorage.getItem('endtime')
             this.endTime = FilterHelper.defaultEndDurationUrlWidgetSafari(endTime,currdate);
             } 
       			}
         	this.startTime.setSeconds(0);
			this.endTime.setSeconds(0);
			this.lengthOfStay = this.calculateHours(this.startTime, this.endTime);
			this.InvokeFilterAction();
       		
          
      }else{
      	this.startTime = FilterHelper.defaultStartDuration();
		this.endTime = FilterHelper.defaultEndDuration();
        this.startTime.setSeconds(0);
	    this.endTime.setSeconds(0);
		this.lengthOfStay = this.calculateHours(this.startTime, this.endTime);
      }
          
      });
		
		//this.endTime = FilterHelper.defaultEndDuration();

		
	}


	hideStripe()
    {
      this.is_landing_page=false;
      this.is_show_landing_strip=false;
      localStorage.setItem('is_show_landing_strip','false')
    }
    hidestripfacility()
    {
      this.inactiveFacility=false;
    }
    promocodeApply()
    {
      if(localStorage.getItem('is_landing_page')=='true' && localStorage.getItem('action_type')=='apply' )
        {
          this.is_landing_page =true;
           if (localStorage.getItem('access_token')) {
             if(localStorage.getItem('errorType')=='4')
             {
               this.sign = 'dollar';
               this.is_landing_page_save=false;
               localStorage.removeItem('is_landing_page')
               this.Couponmessage=localStorage.getItem('couponMessage')
             }
             else if(localStorage.getItem('errorType')=='1')
             {
               this.sign = 'dollar';
               this.is_landing_page_save=false;
               this.Couponmessage=localStorage.getItem('couponMessage')
               if(this.Couponmessage=='Please enter a valid Promotion Code. Please note that a prepaid voucher is not a Promo Code.')
               {
               	this.is_landing_page_save=false;
               }
             }else if(localStorage.getItem('errorType')=='0')
             {
               this.sign = 'dollar';
               this.Couponmessage='Promocode has been expired.'
               this.is_landing_page_save=false;
             }
             else{
               this.sign = 'dollar';
               this.Couponmessage='Great! We’ll automatically apply your promo code '+localStorage.getItem('promocode').toUpperCase()+' at checkout. Or,'
             }
          }else{
             this.sign = 'dollar';

            this.Couponmessage=localStorage.getItem('couponMessage')
            this.is_landing_page_save=false;
            if(localStorage.getItem('errorType')=='2')
             {
               this.is_landing_page_save=true;
               if(localStorage.getItem('couponMessage')=='Please log in or create an account to redeem this promo code.')
               {
                 this.is_landing_page_save=false;
                 this.Couponmessage='Please log in or create an account to redeem this promo code.'
               }
             }
          }
         // this.Couponmessage=localStorage.getItem('couponMessage')
        }
        if(localStorage.getItem('showEroor'))
        {
          if(localStorage.getItem('showEroor')=='1')
          {
            this.toaster.success(localStorage.getItem('showEroorMsg'), 'Great!');
          }else{
            this.toaster.error(localStorage.getItem('showEroorMsg'), 'Sorry!');
          }
          localStorage.removeItem('showEroor')
          localStorage.removeItem('showEroorMsg')
        }
    }
	launchLoginPopup() {
       if (localStorage.getItem('access_token')) {
          this.isLoggedIn = true;
          this.validatePromoCode('save');
       }else{
         localStorage.setItem('save', 'true');
         localStorage.setItem('savebylogin', 'true');
        //$("a#loginPopup")[0].click();
       }
      
    }
    

  validatePromoCode(mode) {
    let data = {
        'promocode': localStorage.getItem('promocode'),
        'user_id' : localStorage.getItem('userid')
      };
    this.userServiceObj.validatePromoCode(data)
    .then(
      (data:any)=> {
        if (data['data'].is_promocode_valid) {
          this.savePromocode();
        } else {
          this.toaster.error('Promocode has been expired', 'Sorry!');
          //window.location.reload();
        }
      },
      error => {
        if (error.errors.message == "Please log in or create an account to redeem this promo code.") {
          this.toaster.error('Please login to your existing account or create a new one in order to save and redeem this promotion.', 'Sorry!');
        } else {
          this.toaster.error(error.errors.message, 'Sorry!');
         // window.location.reload();
        }
      }
    );
  }

  savePromocode() {
    localStorage.removeItem('savebylogin')
    const data = {'promocode' : localStorage.getItem('promocode'), 'user_id' : localStorage.getItem('userid')};
    this.userServiceObj.addPromoCode(data)
    .then(
      (data:any)=> {
      	this.show_strip_search=false
      	localStorage.setItem('is_show_landing_strip','false')
        if (!data['errors']) {

            this.toaster.success('Promocode successfully added, Please make a reservation to redeem this offer.', 'Great!');
           // this.navidateToSearch();
        } else {
          this.toaster.error('Something wrong happened, try again later', 'Sorry!');
          //window.location.reload();
        }
      },
      error => {
      	this.show_strip_search=false
        localStorage.setItem('is_show_landing_strip','false')
        this.toaster.error(error.errors.message, 'Sorry!');
       // this.navidateToSearch();
        // window.location.reload();
      }
    );
  }
	updateStartTimeManually(startTime) {
		this.startTime = new Date(startTime);
	}

	updateEndTimeManually(endTime) {
		this.endTime = new Date(endTime);
	}

	updateDurationManually() {
		let startTime:any = new Date(this.startTime);
		let endTime:any = new Date(this.endTime);
		startTime.setSeconds(0);
		endTime.setSeconds(0);
		this.lengthOfStay = this.calculateHours(startTime, endTime);
	}


	arivalClick() {
		$('[for="mobileNavStartTime"]').click();


	}
	debugDates() {
	}

	displayableTime(mode) {
		let baseTime = "";
		if (mode === 'start') {
			baseTime = $('#mobileNavStartTime').val();
		} else {
			baseTime = $('#mobileNavEndTime').val();
		}
		let tempTime = baseTime.split(",");
		return tempTime[0] + ', ' + tempTime[1] + ' ' + tempTime[2];
	}

	calculateHours(startTime, endTime) {
		let bookingHours:any = endTime.getTime() - startTime.getTime();
    bookingHours = Math.round(bookingHours / 60000);
		return bookingHours / 60;
	}

	updateMobileNavStartTime(event, inst) {
		this.maxDate=  FilterHelper.defaultEndstartDate();
		this.maxDate.setHours(23);
		this.maxDate.setMinutes(59);
		this.maxDate.setSeconds(59);
		this.sixMonthDates =  FilterHelper.rangeMonthDate(this.startTime);
		if (this.startTime < this.minTime && this.isMonthly !== true) {
			this.startTime = new Date(this.minTime);
			this.toaster.error(FilterHelper.PAST_TIME_ERROR_MESSAGE, FilterHelper.MESSAGE_TITLE);
		} else {
      localStorage.setItem('widgetStartTime',FilterHelper.getDateInFormat(this.startTime));
			let lengthOfStay = this.calculateHours(this.startTime, this.endTime);
			if (lengthOfStay < 0.5) {
				let newEndDuration = new Date(this.startTime);
				newEndDuration.setMinutes(newEndDuration.getMinutes() + 30);
				this.endTime = new Date(newEndDuration);
				this.toaster.error(FilterHelper.TWO_HOUR_ERROR_MESSAGE, FilterHelper.MESSAGE_TITLE);
			}
			this.InvokeFilterAction();
		}
	}

	updateMobileNavEndTime(event, inst) {

		let lengthOfStay = this.calculateHours(this.startTime, this.endTime);
		this.sixMonthDates =  FilterHelper.rangeMonthDate(this.startTime);
		if (lengthOfStay < 0.5) {
			let currentStartDuration = new Date(this.startTime);
			this.endTime = new Date(currentStartDuration);
			this.endTime.setHours(currentStartDuration.getHours());
			this.endTime.setMinutes(currentStartDuration.getMinutes());
			this.endTime.setMinutes(this.endTime.getMinutes() + 30);
			this.toaster.error(FilterHelper.TWO_HOUR_ERROR_MESSAGE, FilterHelper.MESSAGE_TITLE);
		}
    
		this.InvokeFilterAction();
	}

	InvokeFilterAction() {
		let startTime:any = new Date(this.startTime);
		let endTime:any = new Date(this.endTime);
    if(localStorage.getItem('searchType')!='event'){
      localStorage.setItem('endtime',FilterHelper.getDateInFormat(this.endTime));
    }
      
		startTime.setSeconds(0);
		endTime.setSeconds(0);
		this.lengthOfStay = this.calculateHours(startTime, endTime);
		this.filterData.currdate = FilterHelper.generateArrivalTime(this.startTime);
		this.filterData.length_of_stay = this.lengthOfStay;
		this.filterData.startTime = startTime;
		this.filterData.endTime = endTime;
		this.updateMobileNavDailyFilters.emit(this.filterData);
  }
  
  updateTier(showBadge){
    this.updateTierToNeighbour.emit(showBadge);
  }
}