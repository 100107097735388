import { Component, OnInit,PipeTransform,Pipe, ViewEncapsulation } from '@angular/core';
import { CustomCode } from '../../shared/CustomCode';
import { StaticpagesService } from '../../services/staticpages.service';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-monthly-preferred',
  templateUrl: './monthly-preferred.component.html',
  styleUrls: ['./monthly-preferred.component.scss'],
  providers: [StaticpagesService],
  encapsulation: ViewEncapsulation.None,
})
export class MonthlyPreferredComponent implements OnInit {
  Y;
  pageId:string = 'preferred-customer-program'; 
  cmsPageContent:any = {};
  sanatizeContent;
  isLoaded = false;

  constructor( private StaticPageObj:StaticpagesService, private router:Router,private sanitized: DomSanitizer ) { }
  
  ngOnInit() {
      this.StaticPageObj.getCmsPageContent(this.pageId).subscribe(
        (data:any)=> {
          this.cmsPageContent = data.result[0];
          this.sanatizeContent = this.sanitized.bypassSecurityTrustHtml(this.cmsPageContent.post_content)
          this.isLoaded = true;
        }, (error) => { console.log('Error: ' + JSON.stringify(error)); 
        this.isLoaded = true;
      }
      )
  }
  scroll(el) {
    el.scrollIntoView();
}


getRoute(event) {
  var goRoute = event.target.getAttribute('routerlink');
  this.router.navigate([goRoute]);
}

}
