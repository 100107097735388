

  <app-header></app-header>

  <div class="main cms main cms commonPage orangeBar  mar-top-lg mar-bottom-lg">


  <div class="container commonPage orangeBar">
  		<div   [innerHTML]="staticPageContent.content"></div>
  </div>
  </div>
  <app-footer></app-footer>
