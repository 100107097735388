import {Injectable} from "@angular/core";
// declare var ga: Function;
@Injectable()
export class GoogleAnalyticsEventsService {
 
  public emitEvent(eventCategory: string,
                   eventAction: string,
                   eventLabel: string = null,
                   eventValue: number = null) {
                     /* commented for ga 16-6-2021 by seo team
    ga('send', 'event', {
      eventCategory: eventCategory,
      eventLabel: eventLabel,
      eventAction: eventAction,
      eventValue: eventValue
    });
    */
  }
}

