<app-header></app-header>
<div class="pageWrapper orangeBar">
<section class="topBarSection">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-12 col-lg-10">
<div class="clearfix visible-xs" style="margin-top: -20px;">
  <!-- <a onclick="javascript:history.go(-1)">check</a> -->

     <a  onclick="javascript:history.go(-1)" style=" font-size: 16px; cursor: pointer;"  class=" backBtn  pad-0 btn-link btn-primary mar-right-xs "><i class="fa  fa-angle-left"></i> BACK</a> 
           <!-- <a href="javascript:;" style=" font-size: 16px;" (click)="goBack()" routerLink="/" class=" backBtn  pad-0 btn-link btn-primary "><i class="fa fa-2x fa-home"></i></a> -->

</div>
       
       
      <div class="flex-row align-items-center">
          <div class="col-sm-12 col-md-6 text-white">
              <h1>
                Welcome Back to Work! You’re in great company.
              </h1>
              <p class="lead">
                Ask today how your company can benefit from Icon | For Business for corporate parking.
              </p>
          </div>
          <div class="col-sm-12 col-md-6 b2b-form-section-wrapper ">
           
            <div  class="form-wrapper">
              <div *ngIf="hideb2bForm" class=" panel panel-body" >
                <div style=" display: block; margin: 100px 0;" class="clearfix mar-top-md mar-bottom-md text-center">
                  
                  <h2 class="text-primary"><b>Thank you for your inquiry.</b></h2> 
                    
                  <p class="lead">An Icon | For Business representative will reach out to shortly to discuss your company’s parking needs.</p>
                </div>
            </div>
              <div *ngIf="!hideb2bForm" class="panel panel-body">
                  <iframe src = "../../../assets/b2b/index.html" class="iframeFame">
                    
                 </iframe>
                <!-- <h4 class="text-primary">
                  Looking for the safest way for your team to return to work? Icon can Help!
                </h4>
                <h5>
                  Limited spots still available, guarantee yours now! 
                </h5> -->
                <!-- <div class="clearfix mar-top-sm">
                  <p>
                    Just fill out the form below.
                  </p>
                  <form name="form" class="formFiller" role="form" id="floating-label-contact-us" (ngSubmit)="f.form.valid && b2bFormSubmit(formData, f)" #f="ngForm" novalidate>
                    <div id="errors-container">
                    </div>
                    <p id="successpara" class="submit-success"></p>
                    <div class="row">
                      <div class="col-sm-7 col-xs-6 p-right ">
                        <div class="form-group"  [ngClass]="{ 'has-error' : f.submitted && !company_name.valid}">
                          <input type="text" (change)="enter_valueCompany('company_name')" id="company_name" placeholder="Company Name" name="company_name" [(ngModel)]="formData.company_name" #company_name="ngModel" required class="form-control"/>
                          <div *ngIf="f.submitted && !company_name.valid" class="help-block">Company Name is required</div>
                        </div>
                      </div>
                      <div class="col-sm-5 col-xs-6 p-left ">
                        <div class="form-group" [ngClass]="{ 'has-error' : f.submitted && !number_of_employees.valid}" [ngClass]=" { 'focused' : f.submitted && number_of_employees.valid} ">
                       <select (change)="enter_valueEmployee('number_of_employees')" class="form-control number_of_employees" id="number_of_employees" name="number_of_employees"  title="No. of Employees"  [(ngModel)]="formData.number_of_employees" required #number_of_employees="ngModel">
                         <option selected disabled [ngValue]="title">  No. of Parking Spaces </option>
                         <option value="tfa_5" id="tfa_5" class="">25-50</option>
                          <option value="tfa_6" id="tfa_6" class="">51-75</option>
                          <option value="tfa_7" id="tfa_7" class="">76-100</option>
                          <option value="tfa_8" id="tfa_8" class="">100+</option>
                       </select>
                       <div *ngIf="f.submitted && !number_of_employees.valid" class="help-block">Please select  No. of Parking Spaces</div>
                       </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-sm-6 col-xs-6 p-right">
                        <div class="form-group" [ngClass]="{ 'has-error' : f.submitted && !first_name.valid}">
                        <input (change)="enter_valueFname('fname')" id="fname" type="text " placeholder="First Name" name="first_name" [(ngModel)]="formData.first_name" #first_name="ngModel" required  class="form-control"/>
                        <div *ngIf="f.submitted && !first_name.valid" class="help-block">First Name is required</div>
                      </div>
                      </div>
                      <div class="col-sm-6 col-xs-6 p-left">
                        <div class="form-group" [ngClass]="{ 'has-error' : f.submitted && !last_name.valid}">
                          <input (change)="enter_valueLname('lname')" id="lname" type="text " placeholder="Last Name" name="last_name" [(ngModel)]="formData.last_name" #last_name="ngModel" required  class="form-control"/>
                          <div *ngIf="f.submitted && !last_name.valid" class="help-block">Last Name is required</div>
                        </div>

                      </div>
                    </div>
                    <div class="row">
                      <div class="col-sm-6 p-right">
                        <div class="form-group" [ngClass]="{ 'has-error' : f.submitted && !last_name.valid}">
                          <input (change)="enter_valueEmail('emailc')" id="emailc" class="form-control" placeholder="Email" type="email" maxlength=100 name="email" [(ngModel)]="formData.email" #email="ngModel" required (keydown)="restSpace($event)" pattern="{{emailRegex}}"
                          />
                          <div *ngIf="f.submitted && !email.valid" class="help-block">Email is required</div>
                        </div>
                      </div>
                      <div class="col-sm-6 p-left">
                        <div class="form-group" [ngClass]="{ 'has-error' : f.submitted && !contact_number.valid}">
                        <input (change)="enter_valuePhone('phonec')" id="phonec" class="form-control" placeholder="Contact Number" minlength="10" [textMask]="{mask: phoneMask}" (keyup)="keyPress($event)" type="text" name="contact_number" [(ngModel)]="formData.contact_number" #contact_number="ngModel" required />
                        <div *ngIf="f.submitted && !contact_number.valid" class="help-block">Contact Number is required</div>
                          </div>
                      </div>
                    </div>
                    <div class="clearfix">
                        <div class=" form-group"  >                 
                      <button style="text-transform: capitalize;" class="btn btn-primary btn-block   btn-fill">
                        <b>Reserve your guaranteed spots now!</b>
                      </button>
                    </div>  
                    </div>
                    
                  </form>
                  
                </div> -->
              </div>
            </div>
          </div>
      </div>
    </div>
  </div>
    </div>

</section>

<section class="graySection">
<div class="container">
    <div class="clarfix text-center">
      <h2 class="mar-bottom-md">Join these great companies and enjoy all of the benefits of corporate parking with<br class="mobileVisible" /> Icon | For Business</h2>
<div class="brandLogoPage">
  <img class="brandLogo" src="../../../assets/img/new-homepage/b2b-img/logo/1.png">
  <img class="brandLogo" src="../../../assets/img/new-homepage/b2b-img/logo/2.png">
  <img class="brandLogo" src="../../../assets/img/new-homepage/b2b-img/logo/3.png">
  <img class="brandLogo" src="../../../assets/img/new-homepage/b2b-img/logo/4.png">
  <img class="brandLogo" src="../../../assets/img/new-homepage/b2b-img/logo/5.png">
  <img class="brandLogo" src="../../../assets/img/new-homepage/b2b-img/logo/6.png">
  <!-- <img class="brandLogo" src="../../../assets/img/new-homepage/b2b-img/logo/7.png"> -->
  <img class="brandLogo" src="../../../assets/img/new-homepage/b2b-img/logo/22.png">
   <img class="brandLogo" src="../../../assets/img/new-homepage/b2b-img/logo/23.png">
  <img class="brandLogo" src="../../../assets/img/new-homepage/b2b-img/logo/8.png">
  <img class="brandLogo" src="../../../assets/img/new-homepage/b2b-img/logo/9.png">
  <img class="brandLogo" src="../../../assets/img/new-homepage/b2b-img/logo/10.png">
  <img class="brandLogo" src="../../../assets/img/new-homepage/b2b-img/logo/11.png">
  <img class="brandLogo" src="../../../assets/img/new-homepage/b2b-img/logo/12.png">
  <img class="brandLogo" src="../../../assets/img/new-homepage/b2b-img/logo/13.png">
  <img class="brandLogo" src="../../../assets/img/new-homepage/b2b-img/logo/14.png">
  <img class="brandLogo" src="../../../assets/img/new-homepage/b2b-img/logo/15.png">
  <img class="brandLogo" src="../../../assets/img/new-homepage/b2b-img/logo/16.png">
  <img class="brandLogo" src="../../../assets/img/new-homepage/b2b-img/logo/17.png">
  <img class="brandLogo" src="../../../assets/img/new-homepage/b2b-img/logo/18.png">
   <img class="brandLogo" src="../../../assets/img/new-homepage/b2b-img/logo/19.png">
   <img class="brandLogo" src="../../../assets/img/new-homepage/b2b-img/logo/20.png">
   <img class="brandLogo" src="../../../assets/img/new-homepage/b2b-img/logo/21.png">
   


</div>
     
    </div>
</div>

</section>

<section class="whiteSection">
  <div class="container">
    <div class="clearfix visible-xs mar-bottom-md ">
      <h2 class="text-center">Whether it’s employee parking or convenient company parking for guests or VIP’s – <br class="hidden-xs"/>
        Icon | For Business has you covered.</h2>
  </div>
    <carousel class="visible-xs monSlider ">
      <slide class="text-center">
        <img icon-img class="img-responsive"  src="../../../assets/img/b2b/images/1.png">
          <h4>Guaranteed parking near your HQ</h4>
          <p>
            With over 250 locations across NYC, your office is not far from a safe and convenient Icon Parking facility. 
          </p>
      </slide>
      <slide class="text-center">
        <img icon-img class="img-responsive"  src="../../../assets/img/b2b/images/2.png">
        <h4>Enroll as many team members as you want</h4>
        <p>Shared spots to accommodate rotating work schedules.  
        </p>
      </slide>
      <slide class="text-center">
        <img icon-img class="img-responsive"  src="../../../assets/img/b2b/images/3.png">
          <h4>
            Fully contactless parking experience through the<br class="visible-md visible-lg"> IconGO App
          </h4>
          <p>
            Quick and easy contactless check in and check out process

          </p>
      </slide>
      <slide class="text-center">
        <img icon-img class="img-responsive"  src="../../../assets/img/b2b/images/5.png">
        <h4>
          Icon ParkSafe<sup>TM</sup> Pledge
        </h4>
        <p>
          The safest parking experience in NYC, with the highest standards of cleaning and hygiene protocols through a hospital-grade disinfection process. 
        </p>
      </slide>
      <slide class="text-center">
        <img icon-img class="img-responsive"  src="../../../assets/img/b2b/images/4.png">
          <h4>
            We're here to help
          </h4>
          <p>
            Dedicated 24/7 account management and support
          </p>
      </slide>
      <slide class="text-center">
        <img icon-img class="img-responsive"  src="../../../assets/img/b2b/images/6.png">
          <h4>
            Easy, convenient billing options
          </h4>
          <p>
            Streamlined, automated, and consolidated process ensures convenient and accurate billing.
          </p>
      </slide>
      
    </carousel>
    <div class="clearfix hidden-xs mar-bottom-md ">
      <h2 class="text-center">Whether it’s employee parking or convenient company parking for guests or VIP’s – <br class="hidden-xs">
        Icon | For Business has you covered.</h2>
  </div>


    <div class="row hidden-xs">

  
      <div class="col-sm-12 col-md-8 col-md-offset-2">

    
      
      <div class="row">
        <div class="col-sm-4 text-center">
          <img class="img-responsive" icon-img="" src="../../../assets/img/b2b/images/1.png">
          <h4 _ngcontent-c9="">Guaranteed parking near your HQ</h4>
          <p _ngcontent-c9="">
            With over 250 locations across NYC, your office is not far from a safe and convenient Icon Parking facility. 
          </p>
        </div>
        <div class="col-sm-4 text-center">
          <img class="img-responsive" icon-img="" src="../../../assets/img/b2b/images/2.png">
          <h4 _ngcontent-c9="">Enroll as many team members as you want</h4>
          <p _ngcontent-c9="">
            Shared spots to accommodate rotating work schedules.  
          </p>
        </div>
        <div class="col-sm-4 text-center">
          <img class="img-responsive" icon-img="" src="../../../assets/img/b2b/images/3.png">
          <h4 _ngcontent-c9="">
            Fully contactless parking experience through the IconGO App
          </h4>
          <p _ngcontent-c9="">
            Quick and easy contactless check in and check out process

          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-4 text-center">
          <img class="img-responsive" icon-img="" src="../../../assets/img/b2b/images/5.png">
          <h4 _ngcontent-c9="">
            Icon ParkSafe<sup _ngcontent-c9="">TM</sup> Pledge
          </h4>
          <p _ngcontent-c9="">
            The safest parking experience in NYC, with the highest standards of cleaning and hygiene protocols through a hospital-grade disinfection process. 
          </p>
        </div>
        <div class="col-sm-4 text-center">
          <img class="img-responsive" icon-img="" src="../../../assets/img/b2b/images/4.png">
          <h4 _ngcontent-c9="">
            We're here to help
          </h4>
          <p _ngcontent-c9="">
            Dedicated 24/7 account management and support
          </p>
        </div><div class="col-sm-4 text-center">
          <img class="img-responsive" icon-img="" src="../../../assets/img/b2b/images/6.png">
          <h4 _ngcontent-c9="">
            Easy, convenient billing options
          </h4>
          <p _ngcontent-c9="">
            Streamlined, automated, and consolidated process ensures convenient and accurate billing.
          </p>
        </div>
      </div>
    </div>
  </div>

  </div>
</section>

<section class="orangeBg">
  <div class="container">
    <h1 class="text-center text-white">Let's get back to business!</h1>
  </div>
</section>
</div>


<app-footer></app-footer>
