<!-- app-header -->
<app-header></app-header>

<!--Monthly Preferred  -->
			
<div class="page-header orangeBar" id="monthlyParking"></div>
	<div class="monthlyParking mar-bottom-sm preferred">
<div class="container mar-bottom-sm pad-bottom-xs">

	<app-lazy-load [isLoaded]="isLoaded" [sectionName]></app-lazy-load>

<ol class="breadcrumb pad-top-xs pad-bottom-xs">
<li class="active"><a onclick="history.go(-1);" class="btn btn-link pad-left-0"><fa name="angle-left"></fa> &nbsp; Back</a></li>
</ol>
<h2 *ngIf="isLoaded" class="text-primary text-uppercase mar-bottom-sm">{{cmsPageContent.post_title}}</h2>

<div class="page-content" *ngIf="isLoaded" [innerHTML]="cmsPageContent.page_detail_content"></div>
<div class="clearfix mar-top-sm">
<a class="btn btn-link pad-left-0" href="javascript:;" [scrollTo]="'#faq'" [scrollDuration]="1000" [scrollOffset]="-80">{{cmsPageContent.faq_button_text}} &nbsp;<fa name="chevron-right"></fa></a>
</div></div>
<div class="well mar-bottom-xs">
<div class="container mar-top-sm mar-bottom-sm">
<h4 class="text-primary text-uppercase mar-bottom-sm mar-top-0">{{cmsPageContent.enrolment_section_title}}</h4>
<div class="row contentSize">
<div class="col-xs-12 col-sm-4 col-md-4 col-lg-4 text-center">
<i class=" text-primary fa fa-4x ">&#61654;</i><br><br>
{{cmsPageContent.details_section1}}
</div>
<p class="visible-xs">&nbsp;</p>
<div class="col-xs-12 col-sm-4 col-md-4 col-lg-4 text-center">
<i class=" text-primary fa fa-4x ">&#61443;</i><br><br>
{{cmsPageContent.details_section2}}
</div>
<p class="visible-xs">&nbsp;</p>
<div *ngIf="isLoaded" class="col-xs-12 col-sm-4 col-md-4 col-lg-4 text-center">
<i class=" text-primary fa fa-4x ">&#61473;</i><br><br>
{{cmsPageContent.details_section3}}
</div>
</div>
</div>
</div>
<div class="container">
<div class="clearfix mar-top-sm">
	<div *ngIf="isLoaded" class="list-content" [innerHTML]="cmsPageContent.facilities_section" ></div>
</div>
<div class="clearfix mar-top-sm">
	<div *ngIf="isLoaded" class="list-content" [innerHTML]="cmsPageContent.payment_section"></div>
</div>
<div class="clearfix mar-top-sm">
<div *ngIf="isLoaded" class="page-content" [innerHTML]="cmsPageContent.eligibility_section"></div>
</div>
<div class="clearfix mar-top-sm mar-bottom-sm">
	<div *ngIf="isLoaded" class="list-content" [innerHTML]="cmsPageContent.term_condition_section" ></div>

</div>
</div>
<div class="clearfix mar-top-xs parallax-bg" parallax [config]="{axis: Y, speed: -0.3, initialValue:0}">
<!-- <img src="assets/img/preferred-parallax.jpg" class="img-responsive full-width preferred-parallax-img" alt="Image"> -->
</div>
<div class="container" id="faq">
	<div class="clearfix mar-top-sm pad-top-xs pad-bottom-xs">
<h2 class="text-primary text-uppercase mar-bottom-sm">{{cmsPageContent.preferred_customer_faq_title}}</h2>
<div class="list-content" [innerHTML]="sanatizeContent" (click)="getRoute($event)"></div>

</div>
</div>
</div>
<!-- app footer -->
<app-footer></app-footer>
