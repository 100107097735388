import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Router, NavigationEnd,ActivatedRoute, Params } from '@angular/router';
import { CustomCode } from '../../shared/CustomCode';
import { ToastrService } from 'ngx-toastr';
import * as $ from 'jquery';
declare var google: any;
import { ResetEmailService } from '../../services/resetemail.service';
@Component({
  selector: 'app-reset-email',
  templateUrl: './reset-email.component.html',
  styleUrls: ['./reset-email.component.scss'],
  providers: [ResetEmailService]
})
export class RestEmailComponent implements OnInit {
	
	formData: any = {};
	isProcess:boolean = false;
	private parseData;
	activeUser: any = [];
	loggedUserId;
	constructor(
	private routerObj: Router,
	private toasterObj: ToastrService,
	private resetemailObj: ResetEmailService)
	{ }
	  ngOnInit() {
		  //this.fetchUserDetails();
		  CustomCode.manageFormLabelsPosition();
	}
	
fetchUserDetails() {
	    this.resetemailObj.fetchUser()
	    .then(
	      (data:any)=> {
			   this.activeUser = data['data'];
			  
	      },
	      error => {
	        // alert(error.errors.message);
	      }
	    );
	  }
	cancelResetEmail(){
		
		this.routerObj.navigate(['/my-account/account']);
	}

	resetEmail(formValues, formObj: NgForm){
		this.loggedUserId = CustomCode.getloggedInUserId();
		//alert(this.loggedUserId);
		//this.fetchUserDetails();
		//if(!this.activeUser.id){ this.fetchUserDetails();}else{
		this.resetemailObj.resetuseremail(formValues,this.loggedUserId)
      .subscribe(
      (data:any)=> {
        this.parseData = data;
		
        if (this.parseData.status === 201) {
			this.routerObj.navigate(['/my-account/account']);
			this.toasterObj.success('Email change successfully', 'success!');
        }
      },
      error => {
		  const errorsList = error.errors;
		   if (errorsList.message !== 'Validation Errors') {
            this.toasterObj.error(errorsList.message, 'Sorry!');
          } else {
            for (const key in errorsList.detail) {
			if (errorsList.detail.hasOwnProperty(key)) {
                if (typeof errorsList.detail === 'undefined') {
                  this.toasterObj.error(errorsList.message, 'Sorry!');
                } else {
                  this.toasterObj.error(errorsList.detail[key], 'Sorry!');
                }
              }
            }
          }
      }
		);
		
		//}
	}
	
	keyDownFunction(event) {
	  if(event.keyCode == 13) {
		//alert('you just clicked enter');
		$('.btn-fill').click();
		return false;
		// rest of your code
	  }
	}
}
