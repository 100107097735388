import { Component, OnInit, Input} from '@angular/core';
import { Router } from '@angular/router';

import { MyAccountMonthlyParkingService } from '../../services/my-account-monthly-parking.service';

@Component({
  selector: 'app-current-balance',
  templateUrl: './current-balance.component.html',
  styleUrls: ['./current-balance.component.scss'],
  providers: [MyAccountMonthlyParkingService]
})
export class CurrentBalanceComponent implements OnInit {

    @Input() currentActive: string;
    @Input() accountDetails: any;

    constructor(private myAccountMonthlyParkingObj: MyAccountMonthlyParkingService, private router:Router) { }

    ngOnInit() {
      this.fetchUserStatusForTracking();
        this.justConsoleSomething();
    }

    justConsoleSomething() {
       
    }

    fetchUserStatusForTracking(){
      let getUserId = '0';
      let getUserEmail = '0';
      let user_login_type = '';
      let isLogin = false;
      if(localStorage.getItem('access_token')){
       isLogin = true;
         getUserId = localStorage.getItem('userid');
         getUserEmail = localStorage.getItem('email');
         user_login_type = localStorage.getItem('user_login_type');
      }
      const loginStatusTrack = {
        'event' : 'pageview',
        'loginStatus' : isLogin? 'logged in': 'Not Logged IN', // 'logged in/Not Logged IN',
        'authenticationMethod' : user_login_type,
        'userId' : getUserId,
        'pageType' : this.router.url,
        'email': getUserEmail,
       };
      (<any>window).dataLayer.push(loginStatusTrack);
      }

}
