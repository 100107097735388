
    <app-header></app-header>
    <div class="contactPage commonPage orangeBar pageHeight mar-top-lg">
      <div class="container mt-5" >
        <div class="row mar-top-md" >
          <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 col-md-offset-3 col-lg-offset-3 col-sm-offset-3">
            <div class="well well-lg text-center">
        <h2 class="text-primary ">Thank you for submitting your monthly parking request</h2>
          <p>We will be in touch shortly.</p>
         <p>Thank you!</p>  
         <a *ngIf="!isFrame" class="btn btn-black theme-btn  " routerLink="/"> <fa name="home"></fa> Return to home page</a>
         <a *ngIf="isFrame" class="btn btn-black theme-btn " routerLink="/search"> <fa name="home"></fa> Return to home page</a>
        </div>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>
  
  
 
