<app-header></app-header>
<app-lazy-load [isLoaded]="isLoaded" [sectionName]></app-lazy-load>
<div *ngIf="isLoaded" class="confirmation-details" id="confirmation-details-web">
<div class="payment-section orangeBar montConf  " style="min-height: 0vh !important;">
  <!-- <div *ngIf="!isFrame" class="hidden-xs " style="margin-top:170px"></div>
  <div *ngIf="!isFrame" class="visible-xs mar-top-lg"></div>
  <div *ngIf="isFrame" class="visible-xs"></div> -->

  <!-- <div *ngIf="isLoyaltyMember" class="hidden-xs 1" style="margin-top:60px"></div> -->
  <!-- <div *ngIf="!isLoyaltyMember" class="hidden-xs 1" style="margin-top:60px"></div> -->
  <!-- <div *ngIf="!isLoyaltyMember" class="hidden-xs 2" style="margin-top:50px"></div> -->
  <!-- <div *ngIf="isLoyaltyMember" class="visible-xs 3" style="margin-top:60px"></div> -->
  <!-- <div *ngIf="!isLoyaltyMember" class="visible-xs 4" style="margin-top:0px"></div> -->

  <div *ngIf="!isresponsive" class="bg-dark hidden-xs"  style="background-color: black;margin-top: 1px;" ><img [src]="buymonthlybannerimage" style="width:50%;"></div>

  <div *isIphone [class]='margin_height' ><img [src]="buymonthlybannerimage" style="width:100%;"></div>
  <div *isAndroidMobile [class]='margin_height' ><img [src]="buymonthlybannerimage" style="width:100%;"></div>
  
<div class="container">
  <div class="top-tab-in-payment">
      <div class="wizard">
          <!-- <div class="wizard-inner">
              <div class="connecting-line"></div>
              
              <div class="first-line-success"></div>
              <ul class="nav nav-tabs" role="tablist">

                  <li role="presentation" class="active1">
                      <a role="tab">
                          <span class="round-tab">
                              <i class="fa fa-chevron-left"></i>
                          </span>
                          <b>Search Results</b>
                      </a>
                  </li>

                  <li role="presentation" class="active1">
                      <a role="tab">
                          <span class="round-tab">
                              <i class="fa fa-check"></i>
                          </span>
                          <b style="left: -44px;">Payment & Checkout</b>
                      </a>
                  </li>
                  <li role="presentation" class="active1 active">
                      <a>
                      
                          <span class="round-tab">
                              <i class="fa fa-check"></i>
                          </span>
                          <b>Booking Confirmed</b>
                      </a>
                  </li>
              </ul>
          </div> -->

              <!-- <div class="tab-content">
                  <div class="tab-pane" role="tabpanel" id="dailysearch">
                      <h3>Step 1</h3>
                      <p>This is step 1</p>
                      <ul class="list-inline pull-right">
                          <li><button type="button" class="btn btn-primary next-step">Save and continue</button></li>
                      </ul>
                  </div> 
                  <div class="tab-pane active" role="tabpanel" id="dailypaymenttab"></div>
              </div> -->
          </div>
  </div>
</div></div></div>
<div *ngIf="isLoaded" id="confirmation-details-web" class="confirmation-details printData" [innerHTML]="cmsData | safeHtml"></div>

<!-- <div class="payment-section orangeBar  ">
  <div class="container">
		<ol class="breadcrumb pad-top-0 pad-bottom-0 pad-left-0"  >
				<li class="active"><a href="javascript:;" onclick="history.go(-1);" class="btn btn-link pad-left-0"><fa name="angle-left"></fa> &nbsp; Back</a></li>
	    </ol>
  <div class="hidden-xs mar-top-md clearfix"></div>
  <div class="white-box-sdw step-box-bg srink-box">
    <div class="head-title">
    <h2 class="mar-top-sm mar-bottom-xs text-center">You are confirmed and all set to park at Selected Parking LLC.</h2>
    <h3 class="mar-top-0 text-center font-weight-light">Here are the next Steps:</h3>
    </div>
    <div class="row">
            <div class="col-sm-6 col-md-3">
                <div class="step-box white-box-sdw text-center mar-bottom-sm">
                   <div class="number-1">
                       <span>1</span>
                   </div>
                   <div class="figure mar-top-sm mar-bottom-sm"><img width="60" src="../../../assets/img/stepemail.png" alt=""></div>
                   <div class="step-body">
                   <h4 class="mar-top-xs mar-bottom-xs">Check e-mail</h4>
                   <p class="mar-top-0 mar-bottom-xs">You will receive a welcome email with your account number.</p>
                   </div>
                </div>
            </div>
            <div class="col-sm-6 col-md-3">
                <div class="step-box white-box-sdw text-center mar-bottom-sm">
                   <div class="number-1">
                       <span>2</span>
                   </div>
                   <div class="figure mar-top-sm mar-bottom-sm"><img width="60" src="../../../assets/img/stepwelcome.png" alt=""></div>
                   <div class="step-body">
                   <h4 class="mar-top-xs mar-bottom-xs">Present welcome email</h4>
                   <p class="mar-top-0 mar-bottom-xs">On your first visit to the garage, please present the registration packet attached to this email. The packet Confirms your reservation and prepayment</p>
                   </div>
                </div>
            </div>
            <div class="col-sm-6 col-md-3">
                <div class="step-box white-box-sdw text-center mar-bottom-sm">
                   <div class="number-1">
                       <span>3</span>
                   </div>
                   <div class="figure mar-top-sm mar-bottom-sm"><img width="60" src="../../../assets/img/stepcar.png" alt=""></div>
                   <div class="step-body">
                   <h4 class="mar-top-xs mar-bottom-xs">Veriyfication and inspection</h4>
                   <p class="mar-top-0 mar-bottom-xs">The attendant will verify the account information and quickly inspect your vehicle for damages.</p>
                   </div>
                </div>
            </div>
            <div class="col-sm-6 col-md-3">
                <div class="step-box white-box-sdw text-center mar-bottom-sm">
                   <div class="number-1">
                       <span>4</span>
                   </div>
                   <div class="figure mar-top-sm mar-bottom-sm"><img width="60" src="../../../assets/img/stepnote.png" alt=""></div>
                   <div class="step-body">
                   <h4 class="mar-top-xs mar-bottom-xs">Confirmation</h4>
                   <p class="mar-top-0 mar-bottom-xs">You will be asked for a final confirmation of your agreement including terms and conditions.</p>
                   </div>
                </div>
            </div>
        </div>
    <div class="step-footer">
    <p class="text-center"><b>That's it! An official tenant number will be assigned to you.</b> <b class="text-primary font-weight-bold">You're done</b></p>
    <p class="text-center">If you have any questions concerning your monthly parking application, please email <span class="text-primary"> monthly@iconparking.com </span></p>
    </div>
  </div>
  <div class="white-box-sdw mar-top-sm srink-box gray-mob hidden-xs hidden-sm">
      <div class="black-head-bg">
      <div class="row">
          <div class="col-md-4">
          <div class="black-head-box white-box-sdw">
              <div class="black-header text-center bg-dark">
                  <h4 class="text-uppercase mar-top-0 mar-bottom-0">Member rewards discount</h4>
              </div>
              <div class="black-body">
                  <div class="blak-reward"><img src="../../../assets/img/reward_logo2.png"></div>
                  <div class="b-list-box">
                  <p><b>Imadiate parking discount</b></p>
                  <p>Receive discounts on parking purchases.</p>
                  </div>
                  <div class="b-list-box">
                    <p><b>Annual Bonus</b></p>
                    <p>Be rewarded based on tier level.</p>
                  </div>
                  <div class="b-list-box">
                    <p><b>Best Pricing</b></p>
                    <p>Park for the lowest price.</p>
                   </div>
              </div>
              <div class="black-footer">
              <p class="mar-top-sm"><a class="btn theme-btn btn-primary theme-btn btn-block outline-btn" href="#">Sign up for icon Reward</a></p></div>
          </div>
        </div>
        <div class="col-md-4">
            <div class="black-head-box white-box-sdw">
                <div class="black-header text-center bg-dark">
                    <h4 class="text-uppercase mar-top-0 mar-bottom-0">icon go app-free car sanitization</h4>
                </div>
                <div class="black-body">
                    <h2 class="mar-top-sm mar-bottom-xs">Download App and receive <span class="text-primary">Complimentary Car Sanitization</span></h2>
                    <div class="car-app-box">
                        <div class="app-icon-go"><img src ="../../../assets/img/new-homepage/icon-go.svg"></div>
                        <div class="app-text-l">
                       <p>Select your phone’s app store below to redirect you to the page where you can download IconGo</p>
                       </div>
                     </div>
                </div>
                <div class="black-footer">
                   <p class="mar-top-sm row app-icon-logo">
                    <span class="col-xs-6"><a href="javascript:;"><img src = "../../../assets/img/new-homepage/AppStore.svg"></a></span>
                    <span class="col-xs-6"><a href="javascript:;"><img src = "../../../assets/img/new-homepage/google-play.svg"></a></span>
                    </p>
                </div>
            </div>
          </div>
          <div class="col-md-4 hidden-xs">
            <div class="black-head-box white-box-sdw">
                <div class="black-header text-center bg-dark">
                    <h4 class="text-uppercase mar-top-0 mar-bottom-0">First-Responders offer</h4>
                </div>
                <div class="black-body">
                    <h2 class="mar-top-sm mar-bottom-xs">Up to 24 hours parking at only $15 at participating Icon Garages</h2>
                    <div class="car-app-box">
                        <div class="app-text-l">
                       <p>Icon would like to help all First Responders get into NYC Quickly and safely with 24-hour parking at substantially discounted rates. </p>
                       </div>
                     </div>
                </div>
                <div class="black-footer">
                <p class="mar-top-sm"><a class="btn theme-btn btn-primary theme-btn btn-block outline-btn" href="javascript:;">Learn More</a></p></div>
            </div>
          </div>
      </div>
      </div>
  </div>
  <div class="reservation-details">
    <div class="reservation-head-bg">
    <div class="row">
        <div class="col-md-8 col-sm-8">
        <div class="reservation-head-box white-box-sdw srink-box">
            <div class="reservation-header bg-primary">
                <h4 class="text-uppercase mar-top-0 mar-bottom-0">Reservation Details</h4>
            </div>
            <div class="reservation-body">
              <div class="reservation-tab visible-xs">
              <div class="reservation-rt">
                <h2 class="mar-top-0">Select Parking LLC</h2>
                <p class="mar-bottom-0"><b>31 east 57th Street</b></p>
                <p class="mar-top-0"><strong>Between:</strong> &nbsp;<span>East 57 St & 2nd Ave</span></p>
  
                <p class="mar-top-xs cc">
                  <fa name="phone" class="ismall text-primary"></fa> &nbsp; (212) 869-3543
                </p>
                <p class="cc">
                  <fa name="calendar" class="ismall text-primary"></fa> &nbsp; 7 days a week
                </p>
                <p class="cc">
                  <fa name="clock-o" class="ismall text-primary" ></fa> &nbsp; 24 hours a day
                </p>
              </div>
              <div class="devide-border"></div>
              </div>
                <div class="reservation-tab">
                <div class="row">
                 <div class="col-md-3"><p><strong>Account Number:</strong></p></div>
                 <div class="col-md-9"><p>10145895</p></div>
                </div>
                <div class="devide-border"></div>
                </div>
                <div class="reservation-tab">
                  <div class="row">
                   <div class="col-md-3"><p><strong>Reference ID:</strong></p></div>
                   <div class="col-md-9"><p>IQMP123458UI23446</p></div>
                  </div>
                  <div class="devide-border"></div>
                  </div>
                <div class="reservation-tab">
                <div class="row">
                    <div class="col-md-3"><p><strong>Term:</strong></p></div>
                    <div class="col-md-9">
                      <p>12 Months</p>
                      <p>Start Date: June 2, 2020</p>
                      <p>Add On: Monthly Plus</p>
                    </div>
                </div>
                <div class="devide-border"></div>
               </div>
               <div class="reservation-tab">
                <div class="row">
                    <div class="col-md-3"><p><strong>Applicant Info:</strong></p></div>
                    <div class="col-md-9">
                      <p>James Dun</p>
                      <p>james@morganstanley.com</p>
                      <p>(702) 222-3333</p>
                      <p>270 Madison Avenue, Suite 200 <br>New York, NY 10016</p>
                    </div>
                </div>
                <div class="devide-border"></div>
               </div>
               <div class="reservation-tab">
                <div class="row">
                    <div class="col-md-3"><p><strong>Vehicle Info:</strong></p></div>
                    <div class="col-md-9">
                      <p>BMW X2 Gray</p>
                      <p>License: WOW1234</p>
                      <p>Oversized Vehicle</p>
                    </div>
                </div>
                <div class="devide-border"></div>
               </div>
               <div class="reservation-tab">
                <div class="row">
                    <div class="col-md-3"><p><strong>Payment Method:</strong></p></div>
                    <div class="col-md-9"><p class="cc"><img src="assets/img/visa.svg" alt="" height="16"><span class="mar-left-xs">Card Ending in 7213 Expiring 06/21</span></p></div>
                </div>
                <div class="devide-border"></div>
               </div>
               <div class="reservation-tab">
                <div class="row">
                    <div class="col-md-3"><p><strong>Authorized Drivers:</strong></p></div>
                    <div class="col-md-9">
                      <p>Jane Dun</p>
                      <p>Chris James</p>
                    </div>
                </div>
                <div class="devide-border"></div>
               </div>
               <div class="reservation-tab">
                <div class="row">
                    <div class="col-md-12"><p><strong class="ammount-charge">Ammount charged to card</strong></p></div>
                    <div class="col-md-12"><p class="reservation-value"><strong>$624.50</strong> <span style="font-style: italic;" class="text-primary">You saved $25.00 a month!</span></p></div>
                    <div class="col-md-12"><a class="btn btn-primary btn-fill theme-btn mar-top-xs hidden-xs btn-radius" href="#">Print Reservation</a></div>
                </div>
               </div>
               <div class="reservation-policy hidden-xs">
                <div class="row">
                    <div class="col-md-12 mar-top-sm"><h4>Monthly Parking Terms and Condition</h4></div>
                    <div class="col-md-12 pad-top-xs">
                      <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                      <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                    </div>
                </div>
              </div>
            </div>
        </div>
        </div>
        <div class="col-xs-12 col-sm-4 col-md-4 reservation-plcy">
          <div class="webSection white-box-sdw hidden-xs">
            <div class="clearfix slideShow">
              <img class="img-responsive" src="../../../assets/img/park_garage.png" alt="image">
            </div>
            <div class="reservation-rt">
              <h2 class="mar-top-0">Select Parking LLC</h2>
              <p class="mar-bottom-0"><b>31 east 57th Street</b></p>
              <p class="mar-top-0"><strong>Between:</strong> &nbsp;<span>East 57 St & 2nd Ave</span></p>

              <p class="mar-top-xs cc">
                <fa name="phone" class="ismall text-primary"></fa> &nbsp; (212) 869-3543
              </p>
              <p class="cc">
                <fa name="calendar" class="ismall text-primary"></fa> &nbsp; 7 days a week
              </p>
              <p class="cc">
                <fa name="clock-o" class="ismall text-primary" ></fa> &nbsp; 24 hours a day
              </p>
              <div class="googleMap mar-top-xs" id="map-wrapper">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6044.279178532842!2d-73.98935742381136!3d40.75895446730697!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c25855b8fb3083%3A0xa0f9aef176042a5c!2sTheater%20District%2C%20New%20York%2C%20NY%2C%20USA!5e0!3m2!1sen!2sin!4v1597833360896!5m2!1sen!2sin" width="100%" height="210" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
              </div>
              <p class="mar-top-xs"><a target="_blank" href="https://maps.google.com?q={{respdata?.geolocations?.latitude}},{{respdata?.geolocations?.longitude}}" class="btn theme-btn btn-primary theme-btn btn-block outline-btn">get directions</a></p>
            </div>
           
          </div>
          <div class="white-box-sdw park-safe-box srink-box sadow-0">
            <div class=" hidden-xs">
                <p><img width="200" src="../../../assets/img/iconpark-safe.png" ></p>
                <ul class="list-unstyled month-list">
                    <li>White glove, mask and covered seat service</li>
                    <li>Designated sanitation stations at every location. </li>
                    <li>Icon VIP Interior Sanitation Service </li>
                    <li>Extensive staff safety training</li>
                </ul>
            </div>
<div class="visible-xs">
<button type="button" class="btn-block btn-trans" 
       ><img width="200" src="../../../assets/img/iconpark-safe.png" > <fa name="angle-down" class="text-primary pull-right"></fa>
</button>
<div id="collapseBasic" >
  <div class="card card-block card-header">
    <ul class="list-unstyled month-list">
      <li>White glove, mask and covered seat service</li>
      <li>Designated sanitation stations at every location. </li>
      <li>Icon VIP Interior Sanitation Service </li>
      <li>Extensive staff safety training</li>
  </ul>
  </div>
</div>
</div>
         </div>
          <div class="visible-xs reservation-policy mar-top-xs mar-bottom-xs">
            <p><b>Reservation Policy</b></p>
            <p>No in/out (One Entry/Exit) No Partial Refunds for Unused Time</p>
            <p>To cancel for a full refund click the CANCEL BUTTON in the email or on this page ANYTIME PRIOR TO THE ARRIVAL TIME indicated on the reservation.</p>
            <p>If you encounter any issues you can email us at <a href="mailto:customerservice@iconparking.com">customerservice@iconparking.com</a></p>
            <p>No refunds will be issued for unused reservations not canceled prior to the arrival time.</p>
            <p>Oversized vehicle surcharge ($15) will be collected at the location if the vehicle does not meet the size requirements, unless paid for at time of reservation.</p>
            <div class="clearfix mar-top-sm">
              <p class=" font-weight-bold">Thank you for choosing Icon Parking.</p>
              <p>Questions? Contact our customer service team Monday-Friday, 9am-5pm (877) 727-5464 or at <a href="mailto:customerservice@iconparking.com">customerservice@iconparking.com</a>
              </p>
            </div>
          </div>
        </div>
    </div>
</div>
</div>
<div class="white-box-sdw mar-top-sm srink-box gray-mob visible-xs visible-sm">
  <div class="black-head-bg">
  <div class="row">
      <div class="col-sm-6 col-md-4">
      <div class="black-head-box white-box-sdw">
          <div class="black-header text-center bg-dark">
              <h4 class="text-uppercase mar-top-0 mar-bottom-0">Member rewards discount</h4>
          </div>
          <div class="black-body">
              <div class="blak-reward"><img [src]="inconReward"></div>
              <div class="b-list-box">
              <p><b>Imadiate parking discount</b></p>
              <p>Receive discounts on parking purchases.</p>
              </div>
              <div class="b-list-box">
                <p><b>Annual Bonus</b></p>
                <p>Be rewarded based on tier level.</p>
              </div>
              <div class="b-list-box">
                <p><b>Best Pricing</b></p>
                <p>Park for the lowest price.</p>
               </div>
          </div>
          <div class="black-footer">
          <p class="mar-top-sm"><a class="btn theme-btn btn-primary theme-btn btn-block outline-btn" href="#">Sign up for icon Reward</a></p></div>
      </div>
    </div>
      <div class="col-sm-6 col-md-4">
        <div class="black-head-box white-box-sdw">
            <div class="black-header text-center bg-dark">
                <h4 class="text-uppercase mar-top-0 mar-bottom-0">First-Responder's Offer</h4>
            </div>
            <div class="black-body">
                <h2 class="mar-top-sm mar-bottom-xs">Up to 24 hours parking at only $15 at participating Icon Garages</h2>
                <div class="car-app-box">
                    <div class="app-text-l">
                   <p>Icon would like to help all First Responders get into NYC Quickly and safely with 24-hour parking at substantially discounted rates. </p>
                   </div>
                 </div>
            </div>
            <div class="black-footer">
            <p class="mar-top-sm"><a class="btn theme-btn btn-primary theme-btn btn-block outline-btn" href="#">Learn More</a></p></div>
        </div>
      </div>
  </div>
  </div>
</div>
  <div class="clearfix reserItem"></div>
</div>
</div> -->

<app-footer></app-footer>



