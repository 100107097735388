<div class="park10 campaignPage pad-top-lg">
	<div class="container"> 
		<div class="row">
			<div class="col-xs-12 col-sm-12 col-md-8 col-lg-8 col-md-offset-2">
				<div class="text-center">
					<h1 class=" text-primary mar-top-0">PARKING IS JUST THE START<sup>TM</sup></h1>
					<p style="font-size: 16px;" class="mar-top-sm mar-bottom-sm">
						With over 200 locations in New York City, you're never far from a safe and convenient Icon parking facility.
					</p>
					<div class="clearfix  mar-top-md mar-bottom-md">
						<h3 class="text-uppercase"> Use code <span class="text-primary">{{ promoCode }}</span> get <span sourceFont>$</span>10 off of your reservation</h3>
						<button type="button" class="btn mar-top-sm  theme-btn btn-primary text-white" (click)="savePromocode('times-square');" >save code</button>
					</div>
					<div class="clearfix mar-tom-md ">
						<small class=" text-white"><!-- Promotion ends on 12/31/2017.  -->Limit one use per individual. Promotion cannot be combined with other sales and/or discounts.</small><br>
						<small><span style="color:red">*</span> In order to use the promo code the customer must be a registered user and logged in to their account.</small>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<section class="mar-top-md mar-bottom-md">
	<div class="container cardWrap text-center">
		<h2 class="text-primary mar-top-0 mar-bottom-sm text-uppercase">where are you headed?</h2>
		<div class="row">
			<div class="col-xs-12 col-sm-6 col-md-3 col-lg-3">
				<div class="card text-center bg1">
					<h3 class="text-white text-uppercase">Times<br/>square</h3>
					<button type="button" class="btn theme-btn btn-primary text-white" (click)="savePromocode('timessquare');">Book Now</button>
				</div>
			</div>
			<div class="col-xs-12 col-sm-6 col-md-3 col-lg-3">
				<div class="card text-center bg2">
					<h3 class="text-white text-uppercase">Midtown<br/>west</h3>
					<button type="button" class="btn theme-btn btn-primary text-white" (click)="savePromocode('midtown-west');">Book Now</button>
				</div>
			</div>
			<div class="col-xs-12 col-sm-6 col-md-3 col-lg-3">
					<div class="card text-center bg3">
					<h3 class="text-white text-uppercase">Upper west<br/>side</h3>
					<button type="button" class="btn theme-btn btn-primary text-white" (click)="savePromocode('upper-west-side');">Book Now</button>
				</div>
			</div>
			<div class="col-xs-12 col-sm-6 col-md-3 col-lg-3">
					<div class="card text-center bg4">
					<h3 class="text-white text-uppercase">midtown<br/>east</h3>
					<button type="button" class="btn theme-btn btn-primary text-white" (click)="savePromocode('midtown-east')">Book Now</button>
				</div>
			</div>
		</div>
		<a href="javascript:;" class="mar-top-xs btn btn-primary theme-btn" (click)="savePromocode('all-nbh')" >See all neighborhoods</a>
	</div>
</section>