import { Component, OnInit,ViewEncapsulation } from '@angular/core';
import { StaticpagesService } from './../../services/staticpages.service'
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router'

@Component({
  selector: 'app-open-table',
  templateUrl: './open-table.component.html',
  styleUrls: ['./open-table.component.scss'],
  providers: [StaticpagesService],
  encapsulation: ViewEncapsulation.None
})
export class OpenTableComponent implements OnInit {
  bgImage = '../../assets/img/landing-pages/open-table/open-table-bg.jpg';
  
  isLoadeds = false;
  pageSlug = 'open-table-NYC';
  pageData:any = [];
  promocodeList = [];
  showList = false
  constructor( private staticPageService: StaticpagesService, private toastr : ToastrService, private router: Router ) { }

  ngOnInit() {
    this.staticPageService.getCMSpageContentWithFields(this.pageSlug).subscribe( (data:any)=>{
      this.pageData = data[0];
      let DataNew = this.pageData['acf'];
        if(DataNew['promocode_fields'] != null && DataNew['promocode_fields'] != false){
          this.promocodeList = DataNew['promocode_fields'];
          if(this.promocodeList.length>0){ this.showList = true }
        }
      this.isLoadeds = true;
     
    } ),(error)=> {
      this.isLoadeds = true;
      //console.log('Error'+ JSON.stringify(error) );
      this.toastr.error(JSON.stringify(error), 'Error!')
    }
  }
  
  redirctToSearch(promocode, type){
    if(promocode){
      promocode.trim();
      type.trim();
      if(type == 'monthly'){
        let url = 'specials-promo/'+promocode+'/apply'
        this.router.navigate([url], {queryParams: {coupontype:'monthly'}});
       }else {
        let url = 'specials-promo/'+promocode+'/apply/';
        this.router.navigate([url]);
       }
    }
   // 
   
   
  }

}

