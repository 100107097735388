import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { HomepageserviceService } from '../../services/homepageservice.service';
import { CustomCode } from '../../shared/CustomCode';
// declare var ga: Function;
@Component({
  selector: 'app-coupon-thanks',
  templateUrl: './coupon-thanks.component.html',
  styleUrls: ['./coupon-thanks.component.scss'],
  providers: [HomepageserviceService]
})
export class CouponThanksComponent implements OnInit {
  responseData: any = {};
  thanksPrint:number=0;
  thanksEmail:number=0;
  thanksSaved:number=0;
  app_icon = 'assets/img/app_icon.svg';
  dollar_icon = 'assets/img/dollar_icon.svg';
  discount_icon = 'assets/img/discount_icon.svg';
  ios_map_Icon = 'assets/img/ios_map.png';
  AppStoreIcon = 'assets/img/AppStore.svg';
  google_playIcon = 'assets/img/google-play.svg'
 ituneUrl =  'https://itunes.apple.com/us/app/icon-parking/id394377820?mt=8';
 googleplayUrl = 'https://play.google.com/store/apps/details?id=com.iconparking&hl=en';
  loggedUser;
  isFrame:any;
  constructor(private toasterObj: ToastrService,
    private homePageServiceObj: HomepageserviceService,
    private router: Router) {
   }
 
  ngOnInit() {
    this.loggedUser = CustomCode.getloggedInUserDetails();
    if(this.router.url.search("print")>0)
    {
      this.thanksPrint=1;
    }
    if(this.router.url.search("email")>0)
    {
      this.thanksEmail=1;
    }
    if(this.router.url.search("saved")>0)
    {
      this.thanksSaved=1;
    }
    if(window.self!==window.top)
		{
		  this.isFrame = true;
		}else{
		  this.isFrame = false;
		}


  }


  
  redirectToAccount()
  {
    if(this.loggedUser)
    {
      this.router.navigate(['/my-account','account']);
    }else{
      this.toasterObj.error('Please login to account', 'Sorry!');
    }
    
  }

  sendApp() {
    /* commented for ga 16-6-2021 by seo team
    ga('send', 'event', { eventCategory: 'app link', eventAction: 'click'});
    */
    const appText = parseInt((<HTMLInputElement>document.getElementById('sendapp')).value.trim());
    if (appText) {
		const phoneNum = ((<HTMLInputElement>document.getElementById('sendapp'))).value;
    
   
		const text = 'Welcome to Icon Parking. click on the link below Android App https://play.google.com/store/apps/details?id=com.iconparking&hl=en and Iphone App https://itunes.apple.com/us/app/icon-parking/id394377820?mt=8';
      this.homePageServiceObj.messageToMobile(phoneNum, text)
          .subscribe(
          (data:any)=> {
            this.responseData = data;
            this.toasterObj.success('App link sent successfully', 'success!');
          },
          error => {
            const errorsList = error.errors;
            if (errorsList.message !== 'Validation Errors') {
              this.toasterObj.error(errorsList.message, 'Sorry!');
            } else {
              for (const key in errorsList.detail) {
                if (errorsList.detail.hasOwnProperty(key)) {
                  if (typeof errorsList.detail === 'undefined') {
                    this.toasterObj.error(errorsList.message, 'Sorry!');
                  } else {
                    this.toasterObj.error(errorsList.detail[key], 'Sorry!');
                  }
                }
              }
            }
          }
          );
    } else {
      this.toasterObj.error('Number is required', 'Sorry!');
    }
    (<HTMLInputElement>document.getElementById('sendapp')).value = '';
  }

}
