import { Injectable } from '@angular/core';

//import { Http, Headers, RequestOptions, Response, ResponseContentType } from '@angular/http';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import {HttpService} from './http.service';

import { CustomCode } from '../shared/CustomCode';

// Import RxJs required methods
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';

@Injectable({
  providedIn: 'root'
})
export class GcouponService {
	
	constructor(private http: HttpClient) { }

	getCouponDetails(couponUrl)
	{	  
	  const headers = new HttpHeaders();
      const access_token = localStorage.getItem('access_token');
      const bigApple = localStorage.getItem('X-Big-Apple');
      headers.append('Authorization', 'Bearer ' + access_token);
      headers.append('X-Big-Apple',  bigApple);
      // const options = new RequestOptions({ headers: headers });
      //const options = new RequestOptions({ headers: headers, responseType: ResponseContentType.Blob });
      return  this.http.get(couponUrl,{ responseType: 'blob',headers: new HttpHeaders({'Authorization': 'Bearer ' + access_token,'X-Big-Apple':bigApple})}).map((res: any) => res);
  
	}
}