import { Component, OnInit,ViewChild, ElementRef  } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CustomCode } from '../shared/CustomCode';
import { UserserviceService } from '../services/userservice.service';

@Component({
  selector: 'app-digital-card',
  templateUrl: './digital-card.component.html',
  styleUrls: ['./digital-card.component.scss'],
  providers: [UserserviceService]
})
export class DigitalCardComponent implements OnInit {
  copyrightyear:any;
  isLoaded: boolean = false;
  isNewUser: boolean = false;
  reqPassword: boolean = false;
  isSuccess: boolean = false;
  partnerId = 0;
  formData: any = {};
  userMessage: string = '';
  QuikParkImg = 'assets/img/IconParkinglogowithtagline.jpg';
  PartnerData: any;
  corporateDetails: any;
  baseUrl:string;
  infoIcon = 'assets/img/info.svg';
  infoText = 'Please complete the form below to enroll in the discount parking program. All fields are required.';
  passwordErrMsg='Password is required'
  constructor(
    private activatedRouteObj: ActivatedRoute,
    private toasterObj: ToastrService,
    private userServiceObj: UserserviceService,
  ) { }
  @ViewChild('myDiv',{ static: true }) myDiv: ElementRef;
  ngOnInit() {
    this.copyrightyear=new Date();
    this.copyrightyear=this.copyrightyear.getFullYear();
    this.activatedRouteObj.params.subscribe((params: Params) => {
      this.partnerId = params.partner_id;
      this.userServiceObj.getCorporatePartner(this.partnerId).subscribe((data:any) => {
        this.PartnerData = data.data;
        let programs = this.PartnerData.corporate_partner_programs;
        programs.forEach(elm => {
          if(elm.corporate_program_type_id == 1 && programs.length > 1){
            this.corporateDetails = elm.program_detail;
          }else{
            this.corporateDetails = elm.program_detail;
          }
        });
        this.isLoaded = true;
      },
        error => {
          this.isLoaded = true;
          this.toasterObj.error("Something went wrong, please try again.", 'Sorry!');
        });
    });
    this.baseUrl = CustomCode.BASEURL;
  }

  addUserToPartner(formValue, formObj: NgForm) {
    const reg = /<(.|\n)*?>/g;
    if (formValue.fname === undefined || formValue.fname === '')
    {
      return false;
    }
    if (formValue.lname === undefined || formValue.lname === '')
    {
      return false;
    }
    if (formValue.email === undefined || formValue.email === '')
    {
      return false;
    }
    if (reg.test(formValue.name) == true) {
      this.toasterObj.error('Please enter valid Name.', 'Sorry!');
      return false;
    } else if (reg.test(formValue.password) == true) {
      this.toasterObj.error('Please enter valid Password.', 'Sorry!');
      return false;
    } else if (reg.test(formValue.cpassword) == true) {
      this.toasterObj.error('Please enter valid confirm Password.', 'Sorry!');
      return false;
    }

    if (this.isNewUser) {
      if (formValue.password === undefined || formValue.password === '') {
        this.passwordErrMsg = 'Password is required';
        formObj.form.controls['password'].setErrors({'invalid': true});
        return false;
      } else if (formValue.password.length < 8) {
        this.passwordErrMsg = 'Password must be atleast 8 characters long';
        formObj.form.controls['password'].setErrors({'invalid': true});
        return false;
      } else if(formValue.password !== formValue.cpassword){
        formObj.form.controls['cpassword'].setErrors({'invalid': true});
        return false;
      }
    }

    let postData = {
      first_name:(formValue.fname !== undefined)?formValue.fname:'',
      last_name: (formValue.lname !==undefined)?formValue.lname:'',
      email: formValue.email,
      corporate_partner_id: this.partnerId
    }

    if (this.isNewUser) { postData['password'] = formValue.password; }

    this.userServiceObj.AddCorporateUser(postData).subscribe((data:any) => {
      //console.log(data)
      if (data.status === 201) {
        //console.log(data)
        let userStatus = data.data.is_new_user;
        if (userStatus) {
          this.userMessage = data.data.message;
          this.isNewUser = true;
        } else {
          this.isSuccess = true;
        }
      }
    }, error => {
      const errorsList = error.errors;
      if (errorsList.message !== 'Validation Errors') {
        this.toasterObj.error(errorsList.message, 'Sorry!', { enableHtml: true, toastClass: 'toast register-toaster' });
      } else {
        for (const key in errorsList.detail) {
          if (errorsList.detail.hasOwnProperty(key)) {
            if (typeof errorsList.detail === 'undefined') {
              this.toasterObj.error(errorsList.message, 'Sorry!', { enableHtml: true, toastClass: 'toast register-toaster' });
            } else {
              this.toasterObj.error(errorsList.detail[key], 'Sorry!', { enableHtml: true, toastClass: 'toast register-toaster' });
            }
          }
        }
      }
    })
  }

  restChar(event: any) {
    if (!CustomCode.restricSpecialChar(event)) {
      event.preventDefault();
    }
  }

  onPaste(event: any) {
    if (!CustomCode.restricPaste(event)) {
      event.preventDefault();
    }
  }

  restSpace(event: any) {
    if (event.keyCode == 32) {
      event.preventDefault();
    }
  }
}
