<app-header></app-header>
<app-lazy-load [isLoaded]="isLoaded" [sectionName]></app-lazy-load>
<div *ngIf="isLoaded" class="page-header orangeBar ladingHotels">
<div class="container">
  <div class="bannerSectionLanding" [innerHTML]="fetchedData?.post_content">
    <!-- <h1>ENJOY NYC</h1>
    <h4 class="mb-4 mt-1">THIS HOLIDAY SEASON</h4>
    <h3>XX% OFF & FREE PARKING</h3>
    <p>at our premier partner hotel properties</p> -->

  </div>
</div>
</div>
<div class="pageWrapperLadning orangeBar">
  <div class="container-fluid p-0">
    <div class="row no-gutters">
      <div class=" col-sm-5 col-12  flaotSection  order-sm-1 order-2 ">
        <h4 class="mt-5 mb-5 headingTitle offset-2">
          {{fetchedData?.post_title}}
        </h4>
        <!--  list for markers location -->
        <ul class="listingPart">
          <li style="cursor: pointer;" *ngFor=" let hotel of fetchedData?.hotels_list; index as index " class="hotel-item index-{{index}} class_{{removeSpace(hotel?.location_latitude)}}" id="id_{{removeSpace(hotel?.location_latitude)}}_{{removeSpace(hotel?.location_longnitude)}}" (click)="redirectToLocation( hotel?.location_latitude, hotel?.location_longnitude, index )">
            <div class=" row align-items-center">
              <div class="col-4 text-right">
                <img width="120px" [src]="hotel?.logo_image?.url"/>
              </div>
              <div class="col-8 text-left">
                <h4 class="">
                  <a [scrollTo]="'#scrollSection'" [scrollDuration]="1000" [scrollOffset]="-100" *ngIf="hotel?.hotel_name" class="link"  >{{hotel?.hotel_name}}</a>
                </h4>
                <p *ngIf="hotel?.address"><img width="12px" [src]="mapicon"/> &nbsp;{{hotel?.address}}</p>
                <p *ngIf="hotel?.phone_number"><img width="12px" [src]="phoneicon"/>&nbsp; {{hotel?.phone_number}}</p>
                <a *ngIf="hotel?.website_link" target="_blank" [href]="hotel?.website_link" class="text-primary">Visit Website for Offer Details</a>
              </div>
            </div>
          </li>
          <!-- <li>
            <div class=" row align-items-center">
              <div class="col-4  text-right">
                <img width="120px" [src]="logotwo"/>
              </div>
              <div class="col-8 text-left">
                <h4 class="">
                  Steward Hotel
                </h4>
                <p><img width="12px" [src]="mapicon"/> &nbsp;Address </p>
                <p><img width="12px" [src]="phoneicon"/>&nbsp;(XXX) XXX-XXXX</p>
                <a href="javascript:;" class="text-primary">Visit Website</a>
              </div>
            </div>
          </li>
          <li>
            <div class=" row align-items-center">
              <div class="col-4 text-right">
                <img width="120px" [src]="logothree"/>
              </div>
              <div class="col-8 text-left">
                <h4 class="">
                  Courtyard by Marriott
                </h4>
                <p><img width="12px" [src]="mapicon"/> &nbsp;Address </p>
                <p><img width="12px" [src]="phoneicon"/>&nbsp;(XXX) XXX-XXXX</p>
                <a href="javascript:;" class="text-primary">Visit Website</a>
              </div>
            </div>
          </li>
          <li>
            <div class=" row align-items-center">
              <div class="col-4 text-right">
                <img width="120px" [src]="logothree"/>
              </div>
              <div class="col-8 text-left">
                <h4 class="">
                  Courtyard by Marriott
                </h4>
                <p><img width="12px" [src]="mapicon"/> &nbsp;Address </p>
                <p><img width="12px" [src]="phoneicon"/>&nbsp;(XXX) XXX-XXXX</p>
                <a href="javascript:;" class="text-primary">Visit Website</a>
              </div>
            </div>
          </li> -->
        </ul>
      </div>
      <div class="col-12 col-sm order-sm-2 order-1  mapWrapperHotels " id="scrollSection" style="position: relative;">
        <!-- <a href="javascriprt:;" class="btn hidden-xs floatBtnMaphotels"  (click)="showHide = !showHide"><i *ngIf="!showHide" class="fa fa-2x fa-angle-left"></i> <i *ngIf="showHide" class="fa fa-2x fa-angle-right"></i></a> -->
        <div id="hotelSmap" style="height: 100%; min-height: 500px;">

        </div>
        <!-- <iframe width="100%" height="100%" id="gmap_canvas" src="https://maps.google.com/maps?q=times%20squares&t=&z=11&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe> -->
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>