<div class="mar-bottom-md pad-left-0 pad-right-0">
    <div class="container ">
        <div class="table-responsive">
            <!-- <img class="icon-img-lt " style="margin: 0 auto;" [src]="tierImage" /> -->
            <table class="reward-table">
                <thead class="bg-dark">
                    <tr>
                        <th width="40%">
                            <img src="assets/img/icon-rewards-logo-wt.svg" height="100">
                        </th>
                        <th class="text-center" width="15%">
                            <img src="assets/img/basic.svg" height="60">
                        </th>
                        <th class="text-center" width="15%">
                            <img src="assets/img/elite.svg" height="60">
                        </th>
                        <th class="text-center" width="15%">
                            <img src="assets/img/elite_plus.svg" height="60">
                        </th>
                        <th class="text-center" width="15%">
                            <img src="assets/img/diamond.svg" height="60">
                        </th>
                    </tr>

                </thead>
                <tbody>
                    <tr>
                        <td>
                            Discount off online reservations
                        </td>
                        <td class="text-center ">
                            <b> 10%</b>
                        </td>
                        <td class="text-center ">
                            <b> 10%</b>
                        </td>
                        <td class="text-center ">
                            <b> 10%</b>
                        </td>
                        <td class="text-center ">
                            <b> 10%</b>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Discount off posted facility rates*
                        </td>
                        <td class="text-center">
                            <b> 10%</b>
                        </td>
                        <td class="text-center">
                            <b> 35%</b>
                        </td>
                        <td class="text-center">
                            <b> 50%</b>
                        </td>
                        <td class="text-center">
                            <b> 50%</b>
                        </td>
                    </tr>
                    <!-- <tr>
                        <td>
                            Annual bonus credit**
                        </td>
                        <td>
                            <b> </b>
                        </td>
                        <td class="text-center">
                            <b sourceFont> $50.00</b>
                        </td>
                        <td class="text-center">
                            <b sourceFont> $100.00</b>
                        </td>
                        <td class="text-center">
                            <b sourceFont> $250.00</b>
                        </td>
                    </tr> -->
                    <tr>
                        <td>
                            Main floor spot at non monthly garage (subject to availability)
                        </td>
                        <td>
                            <b> </b>
                        </td>
                        <td class="text-center">

                        </td>
                        <td class="text-center">

                        </td>
                        <td class="text-center">
                            <img src="assets/img/arrow-check.svg">
                        </td>
                    </tr>
                </tbody>
                <tfoot class="bg-dark">
                    <tr>
                        <td>
                            <h4 class="text-primary"><a href="javascript:void(0)" (click)="moveToFaq()">For complete program details click here.</a></h4>
                            <small>
                                *Posted facility rates include Early Bird, Day/Night/Weekend specials, and Event rates.
                                Coupons, reservations, and other discounted rates are excluded.
                            </small>
                            <!-- <br> -->
                            <!-- <br> -->
                            <!-- <small>
                                **Annual bonus credit valid towards online reservations only – the bonus will be
                                applied on January 1st of each year, based on Rewards tier level as of
                                December 31st of the prior year. The first bonus will be awarded on Jan 1, 2021.
                            </small> -->


                        </td>
                        <td>
                            <p class="text-center fontSize p-20" sourceFont>
                                Available to anyone who enrolls in Icon Rewards.
                            </p>
                        </td>
                        <td>
                            <p class="text-center fontSize p-20" sourceFont>
                                Available to monthly customers paying $1 to $624 or daily customers that spend $3,600 to
                                $7,499 per year.

                            </p>
                        </td>
                        <td>
                            <p class="text-center fontSize p-20" sourceFont>
                                Available to monthly customers paying $625 to $849 or daily customers that spend $7,500
                                to $9,999 per year.

                            </p>
                        </td>
                        <td>
                            <p class="text-center fontSize p-20" sourceFont>
                                Available to monthly customers paying $850 or higher or daily customers that spend
                                $10,000<br /> or more per year.
                            </p>
                        </td>
                    </tr>
                </tfoot>
            </table>
        </div>
    </div>
</div>