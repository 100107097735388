<div *ngIf="isCarReqLoaded" id='carreqstats'>
    <h2 class="text-primary text-upercase mar-top-0">Current Vehicle Requests</h2>
    <div class="table-responsive">
    <table class="table table-hover table-striped table-no-tb mar-bottom-0 accent">
        <thead>
            <tr>
                <th>Facility</th>
                <th>Monthly Number</th>
                <th>Request Type</th>
                <th>Pickup date/time</th>
                <th>Confirmed Status</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let carrequestst of carrequestststatus">
                <td>{{ carrequestst?.facility.full_name }}</td>
                <td>{{ carrequestst?.elimiwait_account.tenant_spot }}</td>
                <td *ngIf="carrequestst?.type === 'Requested'" class='text-primary'>{{ carrequestst?.type }}</td>
                <td *ngIf="carrequestst?.type === 'Cancelled'" class='text-danger'>{{ carrequestst?.type }}</td>
                <td>{{ carrequestst?.pick_up_time }}</td>
                <td>{{ carrequestst?.status }}</td>
            </tr>
        </tbody>
    </table>
    </div>
</div>
                    <h2 class="text-primary text-uppercase">Vehicle Request</h2>
                            <br/>
                            <div class="clearfix">
                            </div>
                        <div class='elimiwaitAccount'>
                            <div class="viewOne">
                                <div class="well" id="elimiwaitAccountsDetails" *ngIf="isLoaded">
                                <h4>{{ activeMpUser.facility.full_name }}</h4>
                                        <ADDRESS>{{ activeMpUser.facility.entrance_location }}</ADDRESS>
                                    <div class="row" *ngFor="let elimiwaitAccount of elimiwaitAccountsDetails">
                                        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                                            <div class="row">
                                            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                                                <p><small>Monthly Number</small><br>
                                                    {{ elimiwaitAccount?.tenant_spot }}
                                                </p>
                                            </div>
                                            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                                                <p><small>{{ elimiwaitAccount?.vehicle_make }}</small><br>
                                                    {{ elimiwaitAccount?.vehicle_model }} &amp; {{ elimiwaitAccount?.vehicle_color }}   
                                                </p>
                                            </div>
                                            
                                        </div>
                                        
                                    </div>
                                    <div id="{{'carRequestBox'+ elimiwaitAccount?.id }}" class="col-xs-12 col-sm-6 col-md-6 col-lg-6 mar-bottom-md text-right carRequestBox">
                                        <a (click)="requestCar( elimiwaitAccount?.id )"cancelRequestCar  class="btn btn-primary  btn-fill">Request Car</a>
                                    </div>
                                    <div id="{{'vehiclepickup'+ elimiwaitAccount?.id }}" class="col-xs-12 col-sm-12 col-md-6 col-lg-6 text-right vehiclepickup" style='display:none' >
                                        <form action="" method="" role="form">
                                            <div class="form-group">
                                                <table width="80%" class="tableTime">
                                                        <tr>
                                                            <td style="width: 95px; vertical-align: super;"><h4>Pickup Time</h4></td>
                                                            <td style="width: 30px;vertical-align: super;">
                                                                    <i class="fa text-primary fa-calendar-o"></i>
                                                                </td>
                                                                <td style="width: 185px; vertical-align: super;">
                                                                    <input type="text" id="{{'startdate'+ elimiwaitAccount?.id }}" name="{{'startdate'+ elimiwaitAccount?.id }}" [minDate]="minDate" [maxDate]="maxDate" [(ngModel)]="dSD[elimiwaitAccount?.id]" #dp="bsDatepicker"
                                                                            (ngModelChange)="getStartDate(elimiwaitAccount?.id)" bsDatepicker [(bsValue)]="dSD[elimiwaitAccount?.id]">
                                                                </td>
                                                                <td style="width: 20px;vertical-align: super;font-size: 15px;padding-left: 20px;">
                                                                    <i class="fa text-primary fa-clock-o"></i>
                                                                </td>
                                                        <td style="vertical-align: super;">
                                                        <div style="width: 70px;" class="dropdown filterBtn">    
                                                        <input class="dropdown-toggle sdate" id="{{'stime'+ elimiwaitAccount?.id }}" type="text" data-toggle="dropdown">
                                                        <ul class="dropdown-menu dropdown-menu-right">
                                                                <li>
                                                                        <timepicker (ngModelChange)="getStartTime(elimiwaitAccount?.id)" id="{{'starttime'+ elimiwaitAccount?.id }}" name="{{'starttime'+ elimiwaitAccount?.id }}" [(ngModel)]="startTime[elimiwaitAccount?.id]"></timepicker>
                                                                </li>
                                                        </ul>
                                                        </div>
                                                                </td>
                                                        </tr>
                                                        <tr><td colspan='3'><div class="text-right clearfix">
                                                <button type="cancel" class="btn btn-primary theme-btn" (click)="cancelRequestCar( elimiwaitAccount?.id )">Cancel</button>
                                                <button type="button" (click)="confirmPick(elimiwaitAccount?.id)" class="btn  btn-primary btn-fill theme-btn">Confirm pickup</button>
                                            </div></td></tr>
                                                </table>
                                        </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <button type="button" class="btn addMonthlyVehicle btn btn-fill btn-primary" (click)="addMonthlyVehicle()" >Add elimiwait account</button>
                            <div id="addMonthlyVehicleContainer" style="display: none;" >
                                <h4 class="text-primary">Monthly Account Registration</h4>
                                    <form class="p" name="form" role="form" id="floating-label-vehicle-req" (ngSubmit)="f.form.valid && addMonthly(formData, f)" #f="ngForm" novalidate>
                                    <div class="row">
                                        <div class="col-xs-12 col-sm-12 col-md-8">
                                            <div class="row">
                                                <div class="col-sm-6">
                                                    <div class="form-group" [ngClass]="{ 'has-error' : f.submitted && !tenant_spot.valid }">
                                                        <label class="control-label">Monthly Number *</label>
                                                        <input type="text" class="form-control" id="" name="tenant_spot" [(ngModel)]="formData.tenant_spot" ng-pattern="/^[0-9]*$/" #tenant_spot="ngModel" required>
                                                        <div *ngIf="f.submitted && !tenant_spot.valid" class="help-block">Monthly Number is required</div>
                                                    </div>
                                                    
                                                </div>
                                                <div class="col-sm-6">
                                                    <div class="form-group" [ngClass]="{ 'has-error' : f.submitted && !cell.valid }">
                                                        <label class="control-label">Cell Phone *</label>
                                                        <input type="text" class="form-control" id="" name="cell" (ngModelChange)="formData.cell = unmask($event)" [(ngModel)]="formData.cell" minlength="10" [textMask]="{mask: phoneMask}" (keypress)="phoneFormat($event)" #cell="ngModel" required>
                                                        <div *ngIf="f.submitted && !cell1.valid" class="help-block">Cell Phone is required</div>
                                                        <input type="hidden" class="form-control" id="" name="cell1"  [(ngModel)]="formData.cell1" minlength="10"   #cell1="ngModel" required>
                                                        
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-sm-6">
                                                    <div class="form-group" [ngClass]="{ 'has-error' : f.submitted && !vehicle_make.valid }">
                                                        <label class="control-label">Vehicle Make *</label>
                                                        <input type="text" class="form-control"  id="" name="vehicle_make" [(ngModel)]="formData.vehicle_make" #vehicle_make="ngModel" required>
                                                        <div *ngIf="f.submitted && !vehicle_make.valid" class="help-block">Vehicle Make is required</div>
                                                    </div>
                                                    
                                                </div>
                                                <div class="col-sm-6">
                                                    <div class="form-group" [ngClass]="{ 'has-error' : f.submitted && !vehicle_model.valid }">
                                                        <label class="control-label">Vehicle Model *</label>
                                                        <input type="text" class="form-control" id="" name="vehicle_model" [(ngModel)]="formData.vehicle_model" #vehicle_model="ngModel" required>
                                                        <div *ngIf="f.submitted && !vehicle_model.valid" class="help-block">Vehicle Model is required</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-sm-6">
                                                    <div class="form-group" [ngClass]="{ 'has-error' : f.submitted && !vehicle_plate.valid }">
                                                        <label class="control-label">License Plate (optional)</label>
                                                        <input type="text" class="form-control"  id="" name="vehicle_plate" [(ngModel)]="formData.vehicle_plate" #vehicle_plate="ngModel">
                                                        <div *ngIf="f.submitted && !vehicle_plate.valid" class="help-block">License Plate is required</div>
                                                    </div>
                                                </div>
                                                <div class="col-sm-6">
                                                    <div class="form-group" [ngClass]="{ 'has-error' : f.submitted && !vehicle_color.valid }">
                                                        <label class="control-label">Vehicle Color *</label>
                                                        <input type="text" class="form-control"  id="" name="vehicle_color" [(ngModel)]="formData.vehicle_color" #vehicle_color="ngModel" required>
                                                        <div *ngIf="f.submitted && !vehicle_color.valid" class="help-block">Vehicle Color is required</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-xs-12 col-sm-12 col-md-4">
                                            <small>* receive text notifications on requests</small>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <a type="cancel" (click)="addMonthlyVehiclecancel()"  class="btn btn-primary theme-btn">Cancel</a>
                                        <button class="btn btn-fill btn-primary theme-btn ml-3">Add elimiwait account</button>
                                    </div>
                                </form>
                                    <fa name="circle-o-notch" animation="spin" class="btn-loader-image" *ngIf="isProcess"></fa>
                            
                            </div>
                            </div>
                            <p></p>
                            <p>Not at your monthly location? Click below to see a list of all garages.</p>
                            </div>
                            <div id='AllGarages'>
                            <button type="button"(click)="seeGarage()" class="btn theme-btn  btn-primary">See All Garages</button>
                            </div>
                            <div class="viewgarages">
                                <ol class="breadcrumb pad-top-0 pad-bottom-0">
                                    <li class="active"><a (click)="vehiclerequest()" class="btn btn-link pad-left-0"><fa name="angle-left"></fa> &nbsp; Vehicle Request</a></li>
                                </ol>
                                <p>&nbsp;</p>
                                
                                <iframe class="elimiwait" style='width: 100%; height: 500px!important;overflow: scroll!important' scrolling="auto"  height="377"  id="iframePage" src="https://portal.elimiwait.com/site/275/locations" ></iframe>
                                <!-- <div id="iframeContent"></div> -->
                                <!--div class="clearfix vihecle-request-panel">
                                    <div class="panel panel-default">
                                        <div class="panel-heading">
                                            <h3 class="panel-title text-center">Search for your garage – enter the garage name, address or code.
                                            </h3>
                                        </div>
                                        <div class="panel-body">
                                            <div class="input-group input-group-lg">
                                                <input type="text" class="form-control text-center" id="exampleInputAmount" placeholder="Enter search criteria">
                                                <span class="input-group-btn">
                                                    <button type="button" class="btn btn-primary btn-fill"><fa name="search"></fa></button>
                                                </span>
                                            </div>
                                        </div>
                                        <div class="list-group">
                                              <div class="list-group-item"><span><b>Name</b></span><span><b>Address</b></span> <span><b>Code</b></span> </div>
                                              <a href="#" class="list-group-item"><span>input-group-lg</span><span>100 Riverside Boulevard New York, NY 10069 </span><span>9067</span></a>
                                            
                                        </div>
                                    </div>
                                    
                                </div-->

                            </div>
