import { Injectable } from '@angular/core';
//import { Http, Headers, RequestOptions, Response } from '@angular/http';
import { Observable } from 'rxjs/Rx';
import { CustomCode } from '../shared/CustomCode';
import {HttpService} from './http.service';
import { HttpClient,HttpHeaders  } from '@angular/common/http';

// Import RxJs required methods
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
@Injectable({
  providedIn: 'root'
})
export class HomepageserviceService {
  constructor(private http: HttpClient) { }
  private subscribe = CustomCode.APIURL + 'email-signup';
  private sendApp = CustomCode.APIURL + 'facility/text';
  private campaignURL = CustomCode.APIURL + 'getwordpressPost';
  private cmiApiUrl = CustomCode.cmsUrl + 'cms/api_loading_model.php?id=';
  subscribeToApplication(data) {
    // const uData = userData;
    // const fData = new FormData();
    // fData.append('email', uData);
    return this.http.post(this.subscribe, data)
      .map((res: any) => res)
      .catch((error: any) => Observable.throw(error.error));
  }
  messageToMobile(userData, text) {
    const token = localStorage.getItem('access_token');
    const bigApple = localStorage.getItem('X-Big-Apple');
    const details = {'to': userData, 'mobileapplink': text };
    return this.http.post(this.sendApp, details,{ headers: new HttpHeaders({'Authorization': 'Bearer ' + token,'X-Big-Apple':bigApple})})
      .map((res: Response) => res)
      .catch((error: any) => Observable.throw(error.error));
  }
  
  userEmailConfirmation(userId, encryptCode) {
    //const details = {'to': userData, 'mobileapplink': text };
    return this.http.post(CustomCode.APIURL +'user/'+ userId +'/email/'+encryptCode, {})
      .map((res: Response) => res.json())
      .catch((error: any) => Observable.throw(error.json()));
  }

  fetchCampaignTemplate(slug) {
    return new Promise((resolve, reject) => {
      this.http.get(this.campaignURL + '/' + slug).subscribe(
        (res:any) => {
          resolve(res.json());
        },
        error => {
          reject(JSON.parse(error['_body']));
        }
      );
    });
  }
  
  

  getCmsBaseballModelContent( pageId ){
    return this.http.get(this.cmiApiUrl+pageId).map(
      (res:Response) => res.json()
    );
  }


}

