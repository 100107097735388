
import { Component, OnInit, Input, TemplateRef } from '@angular/core';
import { MyAccountMonthlyParkingService } from '../../services/my-account-monthly-parking.service';
import { ToastrService } from 'ngx-toastr';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { timeInterval } from 'rxjs/operators';
import { Router } from '@angular/router';
import * as $ from 'jquery';

@Component({
  selector: 'app-payment-methods',
  templateUrl: './payment-methods.component.html',
  styleUrls: ['./payment-methods.component.scss'],
  providers: [MyAccountMonthlyParkingService]
})
export class PaymentMethodsComponent implements OnInit {

	@Input() currentActive: string;
  @Input() accountNumber: any;
  @Input() activeAccount: any;
  modalRef: BsModalRef;
  activeUser: any;
  isSubscriptionBasedUser :boolean = false;
  disclamerMsg = ''
  addCreditCard = false;
	addBankAccount = false;

	showAddCreditCardBtn = true;
	showAddBankAccountBtn = true;

	addingPaymentMethods = false;

  paymentMethods: any = { };

  isLoaded = false;
  deleteStatus = false;
  profile_id = '';
  constructor(
    private toastr: ToastrService,
    private modalService: BsModalService,
    private router:Router,
    private myAccountMonthlyParkingObj: MyAccountMonthlyParkingService) { }
    openModal(template: TemplateRef<any>) {
      this.modalRef = this.modalService.show(template, { class: 'modal-sm actionModal top-center-modal', backdrop: 'static' });
    }

  ngOnInit() {
    this.fetchUserStatusForTracking();
  	this.isLoaded = false;
    // this.fetchMonthlyParkingAccount();
    setInterval( ()=> {
      if(localStorage.getItem('is_subscription_based_user') == '1'){
        this.isSubscriptionBasedUser = true
      }else {
        this.isSubscriptionBasedUser = false
      }
    },10);
    
  	this.getpaymentMethods();
  }

  fetchMonthlyParkingAccount() {
    this.myAccountMonthlyParkingObj.getAccountDetails(this.accountNumber)
    .then(
      (data:any)=> {
        this.isLoaded = true;
        this.activeAccount = data['data'];
        if(this.activeAccount['subscription_details'] !==null && 'is_subscription_based_user' in this.activeAccount['subscription_details'] && this.activeAccount['subscription_details'].is_subscription_based_user ==1 ){
          this.isSubscriptionBasedUser = true;
        }else {
          this.isSubscriptionBasedUser = false;
        }
        this.getpaymentMethods();
      },
      error => {
        this.isLoaded = true;
        this.toastr.error(error.errors.message, 'Sorry!');
      }
    );
  }

  getpaymentMethods() {
    this.myAccountMonthlyParkingObj.paymentMethods(this.accountNumber)
    .then(
      (data:any)=> {
        this.isLoaded = true;
        this.paymentMethods = data['data'];
      },
      error => {
      	this.toastr.error(error.errors.message, 'Sorry!');
      	this.isLoaded = true;
      }
    );
  }

  showAddCreditCard() {
    this.fetchUserStatusForTracking();
  	this.addingPaymentMethods = true;
    this.addBankAccount = false;
    this.showAddBankAccountBtn = true;
    this.showAddCreditCardBtn = false;
    this.addCreditCard = true;
  }

  showAddBankAccount() {
    this.fetchUserStatusForTracking();
  	this.addingPaymentMethods = true;
    this.addCreditCard = false;
    this.addBankAccount = true;
    this.showAddCreditCardBtn = true;
    this.showAddBankAccountBtn = false;
  }

  receiveCancel($event) {
    this.addingPaymentMethods = $event;
    this.showAddBankAccountBtn = true;
    this.showAddCreditCardBtn = true;
  }

  afterAdd($event) {
    this.getpaymentMethods();
    // this.addingPaymentMethods = $event;
    // this.showAddBankAccountBtn = true;
    // this.showAddCreditCardBtn = true;
  }

  deleteMethod(profile_id) {
    this.fetchUserStatusForTracking();
    $('#submitDeleteButton').trigger('click');

    let currerntPaymentMethod;
    this.profile_id = profile_id
    for (var i = this.paymentMethods.payments.length - 1; i >= 0; i--) {
      if (profile_id == this.paymentMethods.payments[i].payment_profile_id) {
        currerntPaymentMethod = this.paymentMethods.payments[i];
      }
    }
    let methodText;
    if (currerntPaymentMethod.card == null) {
      methodText = "Bank ending in (" + currerntPaymentMethod.bank.bank_account + '), for ' + currerntPaymentMethod.bank.bank_name
    } else {
      methodText = "Card ending in (" + 
      currerntPaymentMethod.card.card_number + 
      ') for ' + currerntPaymentMethod.address.first_name + 
      ' ' + this.getRemoveNull(currerntPaymentMethod.address.last_name) +
      ', ' + 
      'Expires on ' + currerntPaymentMethod.expiration_month + '/' + currerntPaymentMethod.expiration_year;
    }
    if(this.isSubscriptionBasedUser){
      if(currerntPaymentMethod['disclaimer'] && currerntPaymentMethod.disclaimer){
        this.disclamerMsg = currerntPaymentMethod.disclaimer
      }else {
            this.disclamerMsg = 'Do you want to delete your payment method?';
      }
    }else {
         this.disclamerMsg = 'Are you sure you want to delete the payment method "' + methodText + '"?' + '\n' + ' PLEASE NOTE THAT DELETING THIS PAYMENT METHOD WILL DISABLE AUTOPAY ON YOUR ACCOUNT. PLEASE REMEMBER TO ACTIVATE AUTOPAY WITH YOUR NEW PAYMENT METHOD."';
      }
    

    //let res = confirm(this.disclamerMsg);
    // if (this.deleteStatus === true) {
    //     this.myAccountMonthlyParkingObj.deletePaymentMethod(this.accountNumber, this.profile_id)
    //     .then(
    //       (data:any)=> {
    //         this.getpaymentMethods();
    //         this.toastr.success('Payment Method Deleted Successfully', 'Great!');
    //       },
    //       error => {
    //         this.toastr.error(error.errors.message, 'Sorry!');
    //       }
    //     );
    // } 
  }

  confirm(): void {
    this.deleteStatus = true;
    this.modalRef.hide();
    this.myAccountMonthlyParkingObj.deletePaymentMethod(this.accountNumber, this.profile_id)
        .then(
          (data:any)=> {
            this.getpaymentMethods();
            this.toastr.success('Payment Method Deleted Successfully', 'Great!');
          },
          error => {
            //console.log(error);
            this.toastr.error(error.errors.message, 'Sorry!');
          }
        );
  }

  decline(): void {
    this.deleteStatus = false
    this.modalRef.hide();
  }

  getRemoveNull(data){
    if(data == null){
      return ''
    }else {
      return data;
    }
  }

  fetchUserStatusForTracking(){
    let getUserId = '0';
    let getUserEmail = '0';
    let user_login_type = '';
    let isLogin = false;
    if(localStorage.getItem('access_token')){
     isLogin = true;
       getUserId = localStorage.getItem('userid');
       getUserEmail = localStorage.getItem('email');
       user_login_type = localStorage.getItem('user_login_type');
    }
    const loginStatusTrack = {
      'event' : 'pageview',
      'loginStatus' : isLogin? 'logged in': 'Not Logged IN', // 'logged in/Not Logged IN',
      'authenticationMethod' : user_login_type,
      'userId' : getUserId,
      'pageType' : this.router.url,
      'email': getUserEmail,
     };
    (<any>window).dataLayer.push(loginStatusTrack);
    }

    displayNameOnCard(name){
      if( name != null && name !='null'){
        return name
      }else {
        return '';
      }
    }
}
