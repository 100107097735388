<app-header></app-header>

    <app-lazy-load [isLoaded]="isLoaded" [sectionName]></app-lazy-load>
    <ng-container *ngIf="is_career_page">
      <div *ngIf="isLoaded" class="faq-content" [innerHTML]="sanatizeContent" (click)="getRouteforBookBtn($event)"></div>
    </ng-container>
    <ng-container *ngIf="!is_career_page">
      <div *ngIf="isLoaded" class="faq-content career-pages" [innerHTML]="sanatizeContent"></div>
    </ng-container>



<app-footer></app-footer> 