import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { StaticpagesService } from '../../services/staticpages.service';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-frequently-asked-questions',
  templateUrl: './frequently-asked-questions.component.html',
  styleUrls: ['./frequently-asked-questions.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [StaticpagesService],
})

export class FrequentlyAskedQuestionsComponent implements OnInit {

  pageId:string = 'frequently-asked-questions'; 
  cmsPageContent:any = {};
  sanatizeContent;
  isLoaded = false;
  has_valet_manager = false;
  @Input() currentActive: string;
  @Input() accountNumber: any;

  constructor( private staticPageObj: StaticpagesService, private sanitized: DomSanitizer, private router:Router ) { }

  ngOnInit() {
    this.fetchUserStatusForTracking();
    if( localStorage.getItem('account_type') && localStorage.getItem('account_type') == 'VM' ){
      this.has_valet_manager = true
    } else {
      this.has_valet_manager = false;
    }
    this.staticPageObj.getCmsPageContent(this.pageId).subscribe(
      (data:any)=> {
        this.cmsPageContent = data.result[0];
        this.sanatizeContent = this.sanitized.bypassSecurityTrustHtml(this.cmsPageContent.post_content.toString().replace("accountNumber", this.accountNumber));
        
        this.isLoaded = true;
      }, error => console.log("e" + error.object),
      
    )

  }

  getRouteWithAccountNumber(event){
    var routeUrl = event.target.getAttribute('routerlink');
    if(routeUrl!=null){
    var goRoute = routeUrl.replace('accountNumber', this.accountNumber);
    
    if(this.has_valet_manager && goRoute.indexOf('account-cancellation-notice') > -1 ){
      alert('You are not authorized to view this page.');
      return false;
    }
    this.router.navigate([goRoute]);
    }
  }

  // getRouteWithAccountNumber(event){
  //   var routeUrl = event.target.getAttribute('routerlink');
  //   var goRoute = routeUrl.replace('accountNumber', this.accountNumber);
  //   this.router.navigate([goRoute]);
  // }

  fetchUserStatusForTracking(){
    let getUserId = '0';
    let getUserEmail = '0';
    let user_login_type = '';
    let isLogin = false;
    if(localStorage.getItem('access_token')){
     isLogin = true;
       getUserId = localStorage.getItem('userid');
       getUserEmail = localStorage.getItem('email');
       user_login_type = localStorage.getItem('user_login_type');
    }
    const loginStatusTrack = {
      'event' : 'pageview',
      'loginStatus' : isLogin? 'logged in': 'Not Logged IN', // 'logged in/Not Logged IN',
      'authenticationMethod' : user_login_type,
      'userId' : getUserId,
      'pageType' : this.router.url,
      'email': getUserEmail,
     };
    (<any>window).dataLayer.push(loginStatusTrack);
    }

}
