<div role="tabpanel" class="tab-pane fade active in" id="paymenthistory" *ngIf="isLoaded">
	<h4 class="text-primary">
	Online Payment History</h4>
	<div class="table-responsive">
	<table class="table table-hover">
		<thead>
			<tr>
				<th>Date</th>
				<th>Amount</th>
				<th >Status</th>
				<th>Payment Type</th>
				<th>Payment Method</th>
				<th>Action</th>
			</tr>
		</thead>
		<tbody>
			<tr *ngFor="let history of paymentHistory;index as i">
				<td>{{ history?.updated_at }}</td>
				<td sourceFont>${{ history?.transaction.total }}</td>
				<td *ngIf="history?.transaction?.auth_net_status?.category">
					{{ history?.transaction?.auth_net_status?.category }}
				</td>
				<td *ngIf="!history?.transaction?.auth_net_status?.category">
					{{ (history?.transaction?.status != '') ? history?.transaction?.status : 'pending' }}
				</td>
				<td *ngIf="history?.is_autopay=='1'">AutoPay</td>
				<td *ngIf="history?.is_autopay=='0'">One Time Payment</td>
				<td style="width: 25%;">{{ history?.transaction.payment_details }}</td>
				<td style="width: 20%;">
					<a (click)="printReceipt(i)" tooltip="Print Receipt" class="btn p-3 m-1  btn-primary btn-fill btn-sm"><i class="fa smallFix fa-2x fa-print"></i></a>
                    <a (click)="downloadRecepts(history?.transaction?.id,history?.created_at)" tooltip="Download" class="btn p-3 m-1  btn-primary btn-fill btn-sm"><i class="fa smallFix fa-2x fa-download"></i></a>
                    <a (click)="sendEmail(history?.transaction?.id);lgModal1.show()" tooltip="Email" class="btn p-3 m-1  btn-primary btn-fill btn-sm"><i class="fa smallFix fa-2x  fa-envelope"></i></a>
					<!-- <a (click)="printReceipt(i)" class="btn   pad-left-xs pad-right-xs btn-primary btn-fill btn-sm" ><i class="fa fa-print"></i>  <span class="hidden-xs">Print</span></a>
					<a (click)="downloadRecepts(history?.transaction?.id)" class="btn   pad-left-xs pad-right-xs btn-primary btn-fill btn-sm" ><i class="fa fa-print"></i>  <span class="hidden-xs">Download</span></a>
					<a (click)="sendEmail(history?.transaction?.id)" class="btn   pad-left-xs pad-right-xs btn-primary btn-fill btn-sm" ><i class="fa fa-print"></i>  <span class="hidden-xs">EMail</span></a> -->
					<!-- <a href="javascript:;" class="btn pad-left-xs pad-right-xs btn-primary btn-fill" (click)="printReceipt(i)">
					<fa name="print"></fa>&nbsp;
					Print Receipt
				  </a>-->
				</td>
				  <td class="hide">
					<div id="printableContent{{i}}">
						<h4 class="text-primary">Thank you for making your payment.</h4>
						<p>Please save the following information for your records.</p>
						<p><b>Total : </b> <span sourceFont>$</span> {{ history?.transaction.total }}</p>
						<p><b>Transaction ID : </b> {{ history?.transaction?.anet_trans_id }}</p>
						<p><b>Payment method : </b> {{ history?.transaction?.payment_details }} for {{ history?.transaction?.name }}</p>
						<p><b>Payment date : </b> {{ history?.created_at }} </p>
						<p *ngIf="history?.transaction?.auth_net_status?.category"><b>Payment status: </b>{{ capitalize(history?.transaction?.auth_net_status?.category) }} </p>
						<p *ngIf="!history?.transaction?.auth_net_status?.category"><b>Payment status: </b> {{ (history?.transaction?.status != '') ? capitalize(history?.transaction?.status) : 'Pending' }} </p>
						<p style="padding-top:10px">We appreciate your business and look forward to continuing to serve you.</p>
						
						</div>
				  </td>
				
			</tr>
		</tbody>
	</table>
	
</div>
</div>
<div class="A-B-coupon-1">
	<div bsModal #lgModal1="bs-modal" id="coupon-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel"
	  aria-hidden="true">
	  <div class="modal-dialog select-modal-dlg">
		<div class="modal-content">
		  <div class="modal-header">
			<button id="hidepopup"  type="button" class="close pull-right" (click)="lgModal1.hide();cancelEmailCross()" aria-label="Close">
			  <span aria-hidden="true">
				<i class="fa fa-times"></i>
			  </span>
			</button>
		  </div>
		  <div id="couponsErrorMessage" class="hidden couponsErrorMessage"></div>
		  <div class="modal-body coupons-other-areas" id="couponmail1">
			<div class="servererrors" id="signuperrors-container">
			</div>
			<div class="form-group">
	
			  <form id="floating-label-google-map1" name="form" role="form" (ngSubmit)="sendReceipt(mailFormData, cm)"
				#cm="ngForm" novalidate>
				<div class="form-group">
					<P>The receipt will be sent on the email id you are logged in with, once you click on 'Email Receipt' button. Please enter alternate email id in below text box if you want us to send it on any other email id.</P>
				</div>
				<div class="form-group">
	
				  <input id="email_data" (input)="checkvalidate($event.target.value)" placeholder="Enter Your Alternate Email" class="form-control"  type="mail" name="ucmail" [(ngModel)]="mailFormData.ucmail"
					#ucmail="ngModel" />
				  <div style="color:red" class="hide" id="email_validation">Please Enter Valid Email Address</div>
				</div>
				
				<div class="form-group">
				  <div class="row">
					<div class="col-sm-6">
					  <button class="btn btn-primary btn-block btn-fill" type="submit">EMAIL RECEIPT</button>
					  <div class="mar-bottom-xs visible-xs"></div>
					</div>
					<div class="col-sm-6">
					  <p *showItDevice="['mobile','tablet']"></p>
					  <button class="btn btn-primary btn-block btn-fill" type="button" (click)="cancelEmail(mailFormData, cm);lgModal1.hide()">CANCEL</button>
					</div>
				  </div>
				</div>
			  </form>
			</div>
		  </div>
		  
		</div>
	  </div>
	</div>
	</div>
<app-lazy-load [isLoaded]="isLoaded" [sectionName]="'payment history'"></app-lazy-load>