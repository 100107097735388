<div class="subscription-renewal">
    <div class="row">
        <div class="col-sm-8 mob-pad-hide">
            <div class="visible-xs">
                <img [src]="llcImage" alt="facilityImage" style="border-radius: 0px 0px 0 0;"
                    class="img-responsive full-width" onerror="delete img.onerror;" height="200px">
            </div>

            <div class="white-box-sdw srink-box">
                <h4 class="mar-top-sm mar-bottom-sm text-primary visible-xs title-resp-xs" style="font-weight: 600;">
                    LOCATION</h4>
                <h3 class="mar-top-0 font-weight-bold top-text-details-2">{{facility_name}}</h3>
                <div class="row">
                    <div class="col-md-6">
                        <h3 class="mar-top-0 font-weight-bold top-text-details-2">{{addresss}}</h3>
                        <p class="top-text-details-3" *ngIf="between"><b>Between:</b>{{between}}</p>
                    </div>
                    <div class="col-md-6">
                        <p class="mar-bottom-xs" *ngIf="hours?.length < 1">7 Days a Week: 24 Hours a Day</p>
                        <div class="dropdown  selectBox week-date-btn" *ngIf="hours?.length > 0">
                            <a class="btn btn-default btn-block dropdown-toggle linkLabel week-day"
                                data-toggle="dropdown">
                                <div> {{hours[0].day}} : {{hours[0].formatted_open_time}} -
                                    {{hours[0].formatted_close_time}} {{showtimefornxtornot(hours[0].close_time)}}
                                </div> <i *ngIf="hours?.length > 1" aria-hidden="true"
                                    class="fa fa-angle-down text-primary"></i>
                            </a>

                            <ul style="width:100%;" class="catValue dropdown-menu" *ngIf="hours?.length > 1">

                                <li *ngFor="let wh of hours; index as index">

                                    <a *ngIf="index != 0" id="{{index}}" href="javascript:;">
                                        {{wh.day}} : {{wh.formatted_open_time}} - {{wh.formatted_close_time}}
                                        {{showtimefornxtornot(wh.close_time)}}
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="row mar-top-sm month-tenant-number">
                    <div class="col-sm-12">
                        <h5 class="mar-top-0 font-weight-bold">Tenant Number(s)<span class="red">*</span></h5>
                    </div>
                    <div class="col-sm-12 tenant-details-wrapper">
                        <div>
                            <ul class="cancel-options">
                                <li class="tenant_number radio" *ngFor="let tenant of tenantData ; let i = index"
                                    [ngClass]="(tenant.is_renew_applicable=='0')?'disable-input-checkbox':'enable-input-checkbox'">
                                    <label>
                                        <span *ngIf="tenant.Status == '0' || tenant.Status == '1'"
                                            class="disable-input"></span>
                                        <input (change)="test(i)" [checked]="tenant.yeschecked"
                                            [ngClass]="(tenant.is_renew_applicable == '0')?'disable-input-checkbox':'enable-input-checkbox'"
                                            type="radio" class="radio" id="{{tenant.Tenat_ID}}-{{tenant.Account_ID}}"
                                            name="{{tenant.Account_Code}}" value="{{tenant.Tenant_Spot}}">
                                        &nbsp;<b> {{tenant.Tenant_Spot}} </b>
                                    </label>
                                </li>
                            </ul>

                        </div>
                        <!-- <app-lazy-load [isLoaded]="isLoaded" [sectionName]="'Tenant Numbers'"></app-lazy-load> -->
                    </div>
                </div>

                <table cellspacing="20" class="desired-park mar-bottm-sm mar-top-xs">
                    <tr class="hidden-xs">
                        <td><b>RENEWAL PARKING START DATE</b></td>
                        <td width="5%">&nbsp;</td>
                        <td class="posi-pencil">
                            <div class="date-pic">
                                <img width="12" src="/assets/img/v8-design/date-icon.svg"
                                    style="margin-top: -2px;">&nbsp; {{desired_start_date_format}}
                            </div>
                        </td>
                    </tr>
                    <tr class="visible-xs">
                        <td colspan="4">
                            <b>RENEWAL PARKING START DATE</b>
                        </td>
                    </tr>
                    <tr class="visible-xs mar-top-md">
                        <td class="posi-pencil" colspan="4">
                            <div class="date-pic ">
                                <img width="12" src="/assets/img/v8-design/date-icon.svg"
                                    style="margin-top: -2px;">&nbsp; {{desired_start_date_format}}
                            </div>
                        </td>
                    </tr>
                </table>

                <!--Rate Details-->
                <div class="rate-details mar-top-sm">
                    <h4 class=" mar-top-xs font-weight-bold pad-bottom-xs hidden-xs">SAVE WITH MULTI-MONTH PACKAGES! (where available) &nbsp;<img height="15"
                            (click)="openModal(monthlyRateDesc)" src="/assets/img/info-icon.png"
                            style="cursor: pointer;" alt="helpIcon" /></h4>
                    <h4 class=" mar-top-xs rate-details-ft hidden-xs"></h4>
                    <ng-template #monthlyRateDesc>
                        <div class="modal-header" style="margin-top: -10px;">
                            <h4 class="modal-title pull-left text-primary text-uppercase"></h4>
                            <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
                                <span aria-hidden="true" style="font-size: 50px; font-weight: 400;">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body" style="padding: 10px 20px">
                            <h3 class="text-uppercase text-primary mar-top-0 mar-bottom-xs">MONTHLY RATES</h3>
                            <p>Our rates vary by vehicle type and what service you require.</p>
                            <h5 class="text-uppercase">ACCESS HOURS</h5>
                            <div *ngIf="hours?.length <= 0">
                                <p>This facility is open 24 hours a day, 7 days a week.
                                </p>
                            </div>
                            <div *ngIf="hours?.length > 0">
                                <p>This facility is open
                                    <span class="pHours" *ngFor="let wh of hours;">
                                        {{wh.day}}: {{wh.formatted_open_time}} - {{wh.formatted_close_time}}
                                        <span class="comma">,</span>
                                    </span>
                                </p>
                            </div>

                            <h5 class="text-uppercase">VEHICLE CLASSIFICATIONS</h5>
                            <p>Oversized vehicles include SUV's, Trucks, and similar. You can find more info and a list
                                of vehicles <a href="javascript:;" (click)="redirecttoList()">here</a>.
                            </p>
                            <p>Exotic vehicles are considered luxury/high-performance/ sports cars.</p>
                            <p>For assistance with your vehicle, contact us at (888) 705-7845 from 9 AM to 7 PM EST </p>
                            <div class="btn-center-xs mar-top-sm mar-bottom-xs">
                                <a class="btn btn-primary theme-btn" href="javascript:;" (click)="modalRef.hide()">Got
                                    it</a>
                            </div>

                            <div class="mar-bottom-sm visible-xs"></div>
                        </div>
                    </ng-template>

                    
                </div>

                <div class="selected-mont-duration visible-xs">
                    <h4 class="mar-top-sm mar-bottom-sm text-primary visible-xs title-resp-xs" style="font-weight: 600;">SELECTED MONTHLY DURATION</h4>
                    <h3>{{selected_text}}</h3>
                    <p>Starting {{desired_start_date_format}}</p>

                </div>

                <div class="mar-top-xs mar-bottom-xs rate-table">
                    <div class class="hidden-xs">
                        <table class="table" *ngIf="rates?.facilityMonthlyTaxRates" sourceFont>
                            <tr *ngFor="let monthlyrats of rates?.facilityMonthlyTaxRates; index as zi">
                                <th>
                                    <div class="radio-boxx"><input class="radio radio_alt idsclass" name="rateDetails"
                                            (click)="updateText(zi);getRate(monthlyrats.rate,monthlyrats.net_rate,monthlyrats.tax_rate,monthlyrats.rate_description.name)" [checked]="monthlyrats.checkedData" type="radio"
                                            id="rate-{{monthlyrats.rate}}" value="1"> <span style="display:none;"
                                            class="mar-left-sm"
                                            id="srate{{zi}}">{{monthlyrats?.rate_description.name}}</span>
                                        <span class="mar-left-sm">{{monthlyrats?.rate_description.name_alias}}</span>
                                        <span *ngIf="showSavingFlexibilityMsg(monthlyrats?.rate_description.name)"
                                            class="ml-3" [class]="bgclr">{{orangestriptdata}} </span>
                                    </div>
                                </th>
                                <th *ngIf="rates?.monthlytaxFlag==0" class="text-right"> &nbsp;
                                    ${{checkFormatForList(monthlyrats?.net_rate)}}/mo</th>
                                <th *ngIf="rates?.monthlytaxFlag==1" class="text-right"> &nbsp;
                                    ${{checkFormatForList(monthlyrats?.rate)}}/mo</th>

                            </tr>
                        </table>
                    </div>

                    <div class="visible-xs">
                        <table class="table" *ngIf="rates?.facilityMonthlyTaxRates" sourceFont>
                            <tr *ngFor="let monthlyrats of rates?.facilityMonthlyTaxRates; index as zi">
                                <th>
                                    <div class="radio-boxx"><input class="radio radio_alt idsclass" name="rateDetails1"
                                            (click)="updateText(zi)" [checked]="monthlyrats.checkedData" type="radio"
                                            id="rate-{{monthlyrats.rate}}" value="{{zi}}"> <span class="mar-left-sm"
                                            id="srate{{zi}}">{{monthlyrats?.rate_description.name}}</span> <span
                                            *ngIf="showSavingFlexibilityMsg(monthlyrats?.rate_description.name)"
                                            [class]="bgclr">{{orangestriptdata}} </span> </div>
                                </th>
                                <th *ngIf="rates?.monthlytaxFlag==0" class="text-right"> &nbsp;
                                    ${{checkFormatForList(monthlyrats?.net_rate)}}/mo</th>
                                <th *ngIf="rates?.monthlytaxFlag==1" class="text-right"> &nbsp;
                                    ${{checkFormatForList(monthlyrats?.rate)}}/mo</th>

                            </tr>
                     </table>
                    </div>
                </div>

                <p class="text-primary special-renewal-text">{{renew_message}}           </p>
                <!-- <p class="text-primary special-renewal-text">if you don’t accept the renewal offer, it will remain month-to-month at the same rate            </p> -->
                <div class="currently-select-box hidden-xs">
                    <h4 class="text-uppercase text-primary font-weight-bold my-2">CURRENTLY SELECTED</h4>
                    <table sourceFont>
                        <tr>
                            <div class="row">
                                <td class="col-sm-7">
                                    <h4 style="margin-top: 5px;">
                                        <b sourceFont>${{selected_price}}</b>
                                        &nbsp;<span class="stand"> {{selected_text}}</span>
                                    </h4>

                                    <p class="small mt-1">{{selected_message}}</p>
                                </td>
                                <td class="col-sm-5">
                                    <p style="margin-top: 10px;">{{tenour}}</p>
                                    <span>{{rate_type}}</span>
                                </td>
                            </div>
                        </tr>
                    </table>
                </div>
                <div class="renewal-vehicle-info">
                    <h4 class="mar-top-sm mar-bottom-xs title-resp-xs">VEHICLE INFORMATION</h4>
                    <div class="table-responsive hidden-xs">  
                        <table>
                            <thead>
                                <tr>
                                    <th>Brand</th>
                                    <th>Modal</th>
                                    <th>Plat
                                        No.</th>
                                    <th>Color</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{{brand}}</td>
                                    <td>{{modal}}</td>
                                    <td>{{plate_number}}</td>
                                    <td>{{color}}</td>
                                </tr>
                            </tbody>
                        </table>
                        <p class="text-primary special-renewal-text">{{disclaimer}}</p>
                    </div>

                    <div class="visible-xs">
                        <table>
                            <thead>
                                <tr>
                                    <th>Brand</th>
                                    <th>Modal</th>
                                    <th>Plat
                                        No.</th>
                                    <th>Color</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{{brand}}</td>
                                    <td>{{modal}}</td>
                                    <td>{{plate_number}}</td>
                                    <td>{{color}}</td>
                                </tr>
                            </tbody>

                        </table>
                        <p class="text-primary special-renewal-text">{{disclaimer}}</p>
                    </div>
                    <!-- <div class="vehicle-info-text mar-top-sm mar-bottom-xs" *ngIf="extraCharge">
                    <p><img src="/assets/img/icon-alert.png" width="16"/><b>{{heading}}</b> {{des_surchage}}</p>
                    <p class="text-danger">{{price_surcharge}}</p>
                  </div>              -->
                </div>
                <div class="row" *ngIf="is_card_required">
                    <div class="col-sm-12 col-md-12 colpad-r">
                        <h4 class="mar-top-sm mar-bottom-xs title-resp-xs mat-top-xs">Payment Details</h4>
                        <div *ngIf="successPayment === false">
                            <div class="currentCarD radio mar-top-0"
                                *ngFor="let paymentMethod of paymentMethods?.payments">
                                <label *ngIf="paymentMethod?.card !== null">
                                    <input (change)="updatePaymentMethod(paymentMethod?.payment_profile_id)"
                                        type="radio" class="radio" name="selectedMethod"
                                        id="card-{{paymentMethod?.payment_profile_id}}"
                                        value="{{ paymentMethod?.payment_profile_id }}" />
                                    <fa name="credit-card" class="mar-right-xs"></fa>
                                    Card Ending In ({{ paymentMethod?.card?.card_number}}) for {{
                                    displayNameOnCard(paymentMethod?.address?.first_name) }} {{
                                    displayNameOnCard(paymentMethod?.address?.last_name) }}, Expires on
                                    <span *ngIf="paymentMethod?.expiration_month && paymentMethod?.expiration_year">
                                        {{ paymentMethod?.expiration_month }}/{{ paymentMethod?.expiration_year }}
                                    </span>
                                    <span *ngIf="!paymentMethod?.expiration_month && !paymentMethod?.expiration_year">
                                        --/--
                                    </span>
                                </label>
                                <label *ngIf="paymentMethod?.bank !== null">
                                    <input (change)="updatePaymentMethod(paymentMethod?.payment_profile_id)"
                                        type="radio" class="radio" name="selectedMethod"
                                        id="card-{{paymentMethod?.payment_profile_id}}"
                                        value="{{ paymentMethod?.payment_profile_id }}" />
                                    <fa name="bank" class="mar-right-xs"></fa>
                                    Bank Ending In ({{ paymentMethod?.bank?.bank_account}}) for {{
                                    displayNameOnCard(paymentMethod?.address?.first_name) }} {{
                                    displayNameOnCard(paymentMethod?.address?.last_name) }}
                                </label>
                            </div>
                            <div class="currentCarD radio mar-top-0">
                                <label>
                                    <input type="radio" class="radio" name="selectedMethod"
                                        (change)="updatePaymentMethod(-1)"
                                        id="card-{{paymentMethod?.payment_profile_id}}" value="" />
                                    <fa name="money " class="mar-right-xs"></fa>
                                    Use A New Payment Method
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div bsModal #lgModalovernightPopup="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true" [config]="{backdrop: 'static'}">
                    <div class="modal-dialog modal-lg">
                        <div class="modal-content">
                            <div class="modal-header">
                                <button type="button" style="margin-bottom: 20px"  class="close" aria-label="Close" [scrollTo]="'#testID'" [scrollDuration]="1000" [scrollOffset]="-200" (click)="lgModalovernightPopup.hide();closeTermsPopup()">
                                    <fa name="times"></fa>
                                </button>
                            </div>
                            <div class="modal-body" style="padding-top:0px;">
                                
                                <div class="scroll-container ps border" style="height:300px;overflow-y: scroll;" id="tcevnt">                
                                    <div class="scrollable-content" [innerHTML]="staticPageContent.post_content"></div>
                                </div>
                                <div class="form-group my-4">
                                    <span id="ModalovernightPopup" #myDiv (click)="lgModalovernightPopup.show()" >
                                        <input (click)="activate_term_btn()" class="form-check-input " type="checkbox" name="terms_check" id="terms_checkbox" [disabled]="disabled"  required>
                                        <label class="form-check-label ml-5 my-2" for="termsConditionCheck">By checking this box you have read, understood and agreed to the Terms & Conditions</label>
                                    </span>
                                </div>  
                                <div class="form-group mar-bottom-0 btn-center-xs">              
                                    
                                    <button (click)="acceptTermsCondition();lgModalovernightPopup.hide();" [scrollTo]="'#testID'" [scrollDuration]="1000" [scrollOffset]="-200" type="button" class="btn btn-primary btn-fill mb-2" [disabled]="disabled_btn" >ACCEPT</button>
                                    <span class="mar-left-xs"></span>
                                    <button (click)="lgModalovernightPopup.hide();closeTermsPopup()" [scrollTo]="'#testID'" [scrollDuration]="1000" [scrollOffset]="-200" type="button" class="btn btn-primary theme-btn mb-2">CANCEL</button>
                                    <span class="mar-left-xs"></span>
                                    <a routerLink="/monthly-terms-and-conditions/" target="_BLANK" style="white-space: break-spaces;" class="btn btn-link text-primary mb-2" >Open Icon Parking Monthly Agreement – Terms and Conditions</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <form *ngIf="is_creditcardshow" (ngSubmit)="f.form.valid && monthlyPayment(formData, f)" #f="ngForm"
                    novalidate method="POST" role="form" id="floating-label-buy-monthly" class="floating-label">

                    <div id="monthpayment" class="collapse payment-login-info in">
                        <h4 class="mar-top-sm mar-bottom-xs title-resp-xs mat-top-xs">Please enter credit card Details
                        </h4>
                        <hr class="my-3">

                        <div class="row ">
                            <div class="col-sm-6 col-md-4 colpad-r">
                                <div class="form-group mat-top-sm nameOnCardDiv mar-bottom-xs"
                                    [ngClass]="{ 'has-error' : f.submitted && !name_on_card.valid}">

                                    <input id="nameOnCard" class="form-control inputBoxStyling" type="text"
                                        autocomplete="off" name="name_on_card" placeholder="Name on Card"
                                        [(ngModel)]="formData.name_on_card" #name_on_card="ngModel"
                                        [required]="isCardReq" />

                                    <div *ngIf="f.submitted && !name_on_card.valid" class="help-block">Name on Card is
                                        required</div>

                                </div>

                            </div>

                            <div class="col-sm-6 col-md-4 colpad-r">
                                <div class="form-group paymentDiv mat-top-xs mar-bottom-xs"
                                    [ngClass]="{ 'has-error' : f.submitted && !card_number.valid}">

                                    <input type="tel" autocomplete="off" placeholder="Card Number"
                                        [textMask]="{mask: cardMask}" (keypress)="restrictNumeric($event)"
                                        class="form-control inputBoxStyling" name="card_number" id="cardNumber"
                                        [(ngModel)]="formData.card_number" #card_number="ngModel"
                                        (ngModelChange)="formData.card_number = unmask($event)"
                                        [required]="isCardReq" />

                                    <img height="22px" class="visible-xs"
                                        style="height: 22px;margin-top: 8px;position: absolute;right: 10px;top: 0;"
                                        src="../../../assets/img/card-list.png">

                                    <div *ngIf="f.submitted && !card_number.valid" class="help-block">Card Number is
                                        required</div>

                                </div>
                            </div>

                            <div class="col-sm-6 col-md-4 hidden-xs">
                                <div class="form-group paymentDiv mat-top-xs mar-bottom-xs ">
                                    <img height="22px" style="height: 22px;margin-top: 8px;"
                                        src="../../../assets/img/card-list.png">

                                </div>
                            </div>
                        </div>

                        <div class="row ">
                            <div class="col-sm-6 col-md-4 colpad-r">
                                <div class="form-group paymentDiv mat-top-xs mar-bottom-xs"
                                    [ngClass]="{ 'has-error' : f.submitted && !expiration_date.valid}">

                                    <input type="tel" autocomplete="off" placeholder="Expiration Date (mm/yy)"
                                        (keyup)="setToexpirationField(expiration_date)" id="standardCardExpiration"
                                        [textMask]="{mask: dateMask}" minlength="4" class="form-control inputBoxStyling"
                                        name="expiration_date" [(ngModel)]="formData.expiration_date"
                                        #expiration_date="ngModel"
                                        (ngModelChange)="formData.expiration_date = unmask($event)"
                                        [required]="isCardReq" />

                                    <input class="form-control" type="hidden" autocomplete="off"
                                        [(ngModel)]="formData.exp1" name="exp1" #exp1="ngModel"
                                        id="standardCardExpiration1">

                                    <div *ngIf="f.submitted && !expiration_date.valid" class="help-block">Expiration
                                        Date is required</div>

                                </div>

                            </div>

                            <div class="col-sm-6 col-md-4 colpad-r">
                                <div class="form-group paymentDiv mat-top-xs mar-bottom-xs"
                                    [ngClass]="{ 'has-error' : f.submitted && !security_code.valid}">

                                    <div class="input-group-1">
                                        <input type="tel" autocomplete="off" placeholder="CVV / Security Code"
                                            maxlength="4" class="form-control inputBoxStyling securityCodeInput"
                                            name="security_code" id="standardCardCvv"
                                            [(ngModel)]="formData.security_code" #security_code="ngModel"
                                            [required]="isCardReq" />

                                        <div class="input-group-append ml-2 d-none d-sm-block my-2" container="body"
                                            containerClass="PaymentCustome" placement="top" [tooltip]="popTemplate"><img
                                                [src]="infoIcon" width="19" alt="i"></div>
                                        <!-- fix for mobile device tooltip -->

                                        <div class="input-group-append ml-2 d-block d-sm-none my-2" container="body"
                                            containerClass="PaymentCustome" placement="left" [tooltip]="popTemplate">
                                            <img [src]="infoIcon" width="19" alt="i"></div>
                                        <!-- tooltip fif ended -->

                                    </div>
                                    <div *ngIf="f.submitted && !security_code.valid" class="help-block zip-error">
                                        Security Code is required</div>
                                </div>
                            </div>

                            <div class="col-sm-6 col-md-4">
                                <div class="form-group nameOnCardDiv mat-top-xs mar-bottom-xs"
                                    [ngClass]="{ 'has-error' : f.submitted && !pzipcode.valid}">

                                    <input class="form-control" maxlength="6" minlength="5" type="text" name="pzipcode"
                                        placeholder="Billing Zip Code" [(ngModel)]="formData.pzipcode"
                                        #pzipcode="ngModel" [required]="isCardReq" />
                                    <div *ngIf="f.submitted && !pzipcode.valid" class="help-block">Zip Code is required
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="row">
                        <div class="col-sm-6 col-md-5">
                            <div class="form-group mar-bottom-xs" [ngClass]="{ 'has-error' : f.submitted && !zip_code.valid}">

                                    <input autocomplete="off" maxlength="6" placeholder="Zip Code" minlength="5" class="form-control inputBoxStyling" type="text" name="zip_code" id="standardCardZip" [(ngModel)]="formData.zip_code" #zip_code="ngModel" [required]="isCardReq" />

                                    <div *ngIf="f.submitted && !zip_code.valid" class="help-block">Zip Code is required</div>

                            </div>
                        </div>
                    </div> -->
                    </div>
                     <div class="row">    
                        <div class="col-md-12 mt-2" id="testID">
                         <span id="ModalovernightPopup" #myDiv (click)="lgModalovernightPopup.show();openpopupsetup()">
                        <input id="termsConditionCheck" class="form-check-input" type="checkbox"  [(ngModel)]="formData.terms" #terms="ngModel" required>                                                                <label class="form-check-label mar-left-sm mar-bottom-xs mar-top-0" for="termsConditionCheck"  style="font-weight:normal ;">
                        <div [style.color]="colorstatus=='blue' ? 'blue': 'black'" [innerHTML]="contentApi"></div>
                       </label>
                       </span>
                        </div>
                    </div>
                    <button *ngIf="total_prices>0" type="submit" style="min-width: 200px;"
                        class="btn btn-primary btn-fill px-5 d-inline-block book-it mar-top-xs mar-bottom-xs">RENEW PLAN
                    </button>
                </form>
                <div class="row" *ngIf="!is_creditcardshow && total_prices>0">    
                    <div class="col-md-12 mt-2"  id="testID">
                        <span id="ModalovernightPopup" #myDiv (click)="lgModalovernightPopup.show();openpopupsetup()">
                            <input id="termsConditionCheck" class="form-check-input" type="checkbox" name="terms" [(ngModel)]="formData.terms" #terms="ngModel" required>            
                            <label class="form-check-label mar-left-sm mar-bottom-xs mar-top-0" for="termsConditionCheck" style="font-weight:normal ;">
                                <div [style.color]="colorstatus=='blue' ? 'blue': 'black'" [innerHTML]="contentApi"></div> 
                                            
                            </label>
                        </span> 
                    </div>    
                </div>
                <p *ngIf="!is_creditcardshow && total_prices>0"><a class="btn btn-primary btn-fill mar-top-sm caps-btn px-5" (click)="renewPlan(1)">RENEW PLAN </a></p>

            </div>

        </div>

        <div class="col-sm-4 col-xs-12 pull-right mob-sidemap hidden-xs">
            <!-- <img [src]="facilityImage" alt="facilityImage" style="border-radius: 0px 0px 0 0;" class="img-responsive full-width" onerror="delete img.onerror;" height="200px"> -->
            <img [src]="llcImage" alt="facilityImage" style="border-radius: 0px 0px 0 0;"  class="img-responsive full-width" onerror="delete img.onerror;" height="200px">
            <div class="white-box-sdw your-purchage-box">
                <p class="visible-xs">&nbsp;</p>
                <h3>Reservation Summary</h3>
                <!-- <p *ngIf='dateFound'>Starting {{changeTime(monthlyDate)}}</p> -->

                <p>Starting {{desired_start_date_format}}</p>
                <div class="">
                    <table width="100%" class="renewal-monthly-price-section" sourceFont>
                        <tr>
                            <td><b>{{tenour}}</b></td>
                            <td align="right"><b>${{selected_price}}</b></td>
                        </tr>
                        <!-- <tr>
                      <td><b>Price</b></td>
                      <td  align="right" *ngIf='!taxHideShow'><b>${{checkFormat(rateDetailsNetRate)}}</b></td>
                      <td  align="right" *ngIf='taxHideShow'><b>${{checkFormat(rateDetails)}}</b></td>
                  </tr>
                  <tr *ngIf='extraChargeCheck'>
                          <td><b>{{surchargeType}}</b></td>
                          <td  align="right"><b>${{checkFormat(price_surcharge_value)}}</b></td>
                      </tr>
                  <tr *ngIf='!taxHideShow'>
                      <td><b>Tax</b></td>
                      <td  align="right"><b>${{checkFormat(taxForThis)}}</b></td>
                  </tr> -->
                    </table>
                    <hr class="my-3" />

                    <table width="100%" sourceFont>
                        <tr>
                            <td class="font-weight-bolder pb-2" style="font-size: 17px;">Total</td>
                            <!-- <td align="right"><h2 style="font-size: 18px;" class="my-0 font-weight-bolder pb-4">${{checkFormatWithSurcharge(rateDetails)}}</h2></td> -->
                            <td align="right" style="font-size: 17px;" class="font-weight-bolder">
                                ${{total_price(selected_price,vehicle_surcharge)}}</td>

                        </tr>
                    </table>

                    <p class="w-100 text-center mar-top-xs hidden-xs"><a role="button" font-12
                            class="text-dark text-center" style="cursor: pointer;"><img width="15px" class="mar-right-xs" src="/assets/img/best_guaranteed_icon.png"> Our Best Deals Guaranteed
                        </a></p>

                </div>

                <p class="visible-xs">&nbsp;</p>
            </div>
        </div>
    </div>
</div>
<ng-template #popTemplate>
    <img [src]="cardSecurityIcon" alt="helpIcon">
</ng-template>
<ng-template #formopen>
    <div class="modal-header" style="margin-top: -10px;">
        <h4 class="modal-title pull-left text-primary text-uppercase"></h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true" style="font-size: 50px; font-weight: 400;">&times;</span>
        </button>
    </div>
    <div class="modal-body" style="padding: 10px 20px">
        <form *ngIf="is_creditcardshow" (ngSubmit)="f.form.valid && monthlyPayments(formData, f)" #f="ngForm" novalidate
            method="POST" role="form" id="floating-label-buy-monthly" class="floating-label">

            <div id="monthpayment" class="collapse payment-login-info in">
                <h4 class="mar-top-sm mar-bottom-xs title-resp-xs mat-top-xs">Please enter credit card Details</h4>
                <hr class="my-3">

                <div class="row ">
                    <div class="col-sm-6 col-md-4 colpad-r">
                        <div class="form-group mat-top-sm nameOnCardDiv mar-bottom-xs"
                            [ngClass]="{ 'has-error' : f.submitted && !name_on_card.valid}">

                            <input id="nameOnCard" class="form-control inputBoxStyling" type="text" autocomplete="off"
                                name="name_on_card" placeholder="Name on Card" [(ngModel)]="formData.name_on_card"
                                #name_on_card="ngModel" [required]="isCardReq" />

                            <div *ngIf="f.submitted && !name_on_card.valid" class="help-block">Name on Card is required
                            </div>
                        </div>
                    </div>

                    <div class="col-sm-6 col-md-4 colpad-r">
                        <div class="form-group paymentDiv mat-top-xs mar-bottom-xs"
                            [ngClass]="{ 'has-error' : f.submitted && !card_number.valid}">

                            <input type="tel" autocomplete="off" placeholder="Card Number" [textMask]="{mask: cardMask}"
                                (keypress)="restrictNumeric($event)" class="form-control inputBoxStyling"
                                name="card_number" id="cardNumber" [(ngModel)]="formData.card_number"
                                #card_number="ngModel" (ngModelChange)="formData.card_number = unmask($event)"
                                [required]="isCardReq" />

                            <img height="22px" class="visible-xs"
                                style="height: 22px;margin-top: 8px;position: absolute;right: 10px;top: 0;"
                                src="../../../assets/img/card-list.png">

                            <div *ngIf="f.submitted && !card_number.valid" class="help-block">Card Number is required
                            </div>
                        </div>
                    </div>

                    <div class="col-sm-6 col-md-4 hidden-xs">
                        <div class="form-group paymentDiv mat-top-xs mar-bottom-xs ">

                            <img height="22px" style="height: 22px;margin-top: 8px;" src="../../../assets/img/card-list.png">

                        </div>
                    </div>
                </div>

                <div class="row ">
                    <div class="col-sm-6 col-md-4 colpad-r">
                        <div class="form-group paymentDiv mat-top-xs mar-bottom-xs"
                            [ngClass]="{ 'has-error' : f.submitted && !expiration_date.valid}">

                            <input type="tel" autocomplete="off" placeholder="Expiration Date (mm/yy)"
                                (keyup)="setToexpirationField(expiration_date)" id="standardCardExpiration"
                                [textMask]="{mask: dateMask}" minlength="4" class="form-control inputBoxStyling"
                                name="expiration_date" [(ngModel)]="formData.expiration_date" #expiration_date="ngModel"
                                (ngModelChange)="formData.expiration_date = unmask($event)" [required]="isCardReq" />

                            <input class="form-control" type="hidden" autocomplete="off" [(ngModel)]="formData.exp1"
                                name="exp1" #exp1="ngModel" id="standardCardExpiration1">

                            <div *ngIf="f.submitted && !expiration_date.valid" class="help-block">Expiration Date is
                                required</div>

                        </div>
                    </div>

                    <div class="col-sm-6 col-md-4 colpad-r">
                        <div class="form-group paymentDiv mat-top-xs mar-bottom-xs"
                            [ngClass]="{ 'has-error' : f.submitted && !security_code.valid}">

                            <div class="input-group-1">
                                <input type="tel" autocomplete="off" placeholder="CVV / Security Code" maxlength="4"
                                    class="form-control inputBoxStyling securityCodeInput" name="security_code"
                                    id="standardCardCvv" [(ngModel)]="formData.security_code" #security_code="ngModel"
                                    [required]="isCardReq" />

                                <div class="input-group-append ml-2 d-none d-sm-block my-2" container="body"
                                    containerClass="PaymentCustome" placement="top" [tooltip]="popTemplate"><img
                                        [src]="infoIcon" width="19" alt="i"></div>

                                <!-- fix for mobile device tooltip -->

                                <div class="input-group-append ml-2 d-block d-sm-none my-2" container="body"
                                    containerClass="PaymentCustome" placement="left" [tooltip]="popTemplate"><img
                                        [src]="infoIcon" width="19" alt="i"></div>

                                <!-- tooltip fif ended -->

                            </div>
                            <div *ngIf="f.submitted && !security_code.valid" class="help-block zip-error">Security Code
                                is required</div>
                        </div>
                    </div>

                    <div class="col-sm-6 col-md-4">
                        <div class="form-group nameOnCardDiv mat-top-xs mar-bottom-xs"
                            [ngClass]="{ 'has-error' : f.submitted && !pzipcode.valid}">

                            <input class="form-control" maxlength="6" minlength="5" type="text" name="pzipcode"
                                placeholder="Billing Zip Code" [(ngModel)]="formData.pzipcode" #pzipcode="ngModel"
                                [required]="isCardReq" />
                            <div *ngIf="f.submitted && !pzipcode.valid" class="help-block">Zip Code is required</div>

                        </div>
                    </div>
                </div>
                <!-- <div class="row">
                        <div class="col-sm-6 col-md-5">
                            <div class="form-group mar-bottom-xs" [ngClass]="{ 'has-error' : f.submitted && !zip_code.valid}">

                                    <input autocomplete="off" maxlength="6" placeholder="Zip Code" minlength="5" class="form-control inputBoxStyling" type="text" name="zip_code" id="standardCardZip" [(ngModel)]="formData.zip_code" #zip_code="ngModel" [required]="isCardReq" />
                                    <div *ngIf="f.submitted && !zip_code.valid" class="help-block">Zip Code is required</div>

                            </div>
                        </div>
                    </div> -->
            </div>
            <button *ngIf="total_prices>0" type="submit" style="min-width: 200px;"
                class="btn btn-primary btn-fill px-5 d-inline-block book-it mar-top-xs mar-bottom-xs">RENEW PLAN
            </button>
        </form>
    </div>
</ng-template>