<!-- <app-header></app-header> -->
	<div class="email20 campaignPage pad-top-lg">
		<div class="container"> 
			<div class="row">
				<div class="col-xs-12 col-sm-12 col-md-10 col-lg-8 mar-lr-auto">
					<div class="text-center email20-content mar-lr-auto">
						<h1 class="text-center text-primary text-uppercase mar-bottom-0">
							Download now and get 20% off your reservation with promo code <span class="text-white">{{ promoCode }}</span>
						</h1>
						<button type="button" class="btn mar-top-sm btn-fill btn-primary text-white" (click)="savePromocode('times-square')">save code</button>				
						<div class="clearfix mar-top-sm pad-top-xs mar-bottom-sm pad-bottom-xs">
							<h3 class="text-uppercase text-primary">OVER 200 PARKING GARAGES. ALL IN ONE TINY LITTLE APP.</h3>
							<h3 class="text-uppercase mar-top-0">INTRODUCING THE ICON GO APP. BOOK, PAY, AND PLAY.</h3>
						</div>
						<div class="row">
							<div class="appPart">
								<div class="col-md-6 mar-top-sm hidden-xs hidden-sm">
									<p class="text-uppercase">
										<b>Enter your phone number below and we will send you a link to download Icon GO.</b>
									</p>
									<div class="input-group">
										<input type="text" class="form-control text-white" id="sendapp" placeholder="Enter phone number">
										<span class="input-group-btn pad-left-xs">
											<button type="submit" class="btn btn-primary theme-btn text-white" (click)="sendApp()">Send Link</button>
										</span>
									</div>
								</div>
								<div class="col-md-6 mar-top-sm">
									<img [src]="app_icon" alt="image" width="54" height="54">
									<span class="hidden-xs hidden-sm mar-left-xs"></span>
									<div class="visible-xs visible-sm mar-top-xs"></div>
									<a [href]="appleAppUrl" (click)="iconLink()">
										<img [src]="AppStore" alt="image">
									</a>
									<span class="mar-left-xs"></span>
									<a href="googleplatUrl" (click)="iconLink()">
										<img [src]="googleplayIcon" alt="image">
									</a>
								</div>
							</div>
						</div>
						<div class="clearfix mar-top-md">
							<small class=" text-white"><!-- Promotion ends on 3/31/2018.  -->Limit one use per individual. Promotion cannot be combined with other sales and/or discounts. Promotion valid only for logged-in users.</small>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="iconFeatures pad-bottom-md pad-top-md">
		<div class="container">
			<div class="row">
				<div class="col-xs-12 col-offset-1 col-sm-6 col-md-6 col-lg-6">
					<ul class="list-unstyled iconic-list mar-bottom-0 mar-top-0">
						<li>
							<img [src]="dollar_icon" alt="image"> <span class="lead">Unique coupons and promotions</span>
						</li>
						<li>
							<img [src]="discount_icon" alt="image"> <span class="lead">Direct booking discounts</span>
						</li>
					</ul>
					<p class="visible-xs pad-bottom-xs"></p>
				</div>
				<div class="col-xs-12 col-sm-6 col-md-6 col-lg-6 mar-bottom-xs">
					<ul class="list-unstyled iconic-list mar-bottom-0 mar-top-0">
						<li>
							<img [src]="arrowsIcon" alt="image"> <span class="lead">Seamless check-in and check-out</span>
						</li>
						<li>
							<img [src]="starICon" alt="image"> <span class="lead">Exclusive services and features</span>
						</li>
					</ul>
				</div>
			</div>
			<div class="text-center">
				<a routerLink="/app" class="btn">Read More <fa name="angle-right" class="mar-left-xs" style="font-size:19px;"></fa></a>
			</div>
		</div>
	</div>
<!-- <app-footer></app-footer> -->
<!-- <p>EMAIL LANDING PAGE</p> -->