import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

import { MyAccountMonthlyParkingService } from '../../services/my-account-monthly-parking.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-remove-account',
  templateUrl: './remove-account.component.html',
  styleUrls: ['./remove-account.component.scss'],
  providers: [MyAccountMonthlyParkingService]
})
export class RemoveAccountComponent implements OnInit {

  @Input() currentActive: string;
  @Input() accountNumber: any;

  constructor(
  private routeObj: Router,
  private toastr: ToastrService,
  private myAccountMonthlyParkingObj: MyAccountMonthlyParkingService) { }

  ngOnInit() {
    this.fetchUserStatusForTracking();

  }

  deleteAccount(event) {
    this.fetchUserStatusForTracking();
    this.myAccountMonthlyParkingObj.deleteAccount(this.accountNumber)
    .then(
      (data:any)=> {
        this.toastr.success('Account Successfully Removed!', 'Great!');
        this.routeObj.navigate(['/my-account/monthly-parking']);
      },
      error => {
        this.toastr.error(error.errors.message, 'Sorry!');
      }
    );
  }

  fetchUserStatusForTracking(){
    let getUserId = '0';
    let getUserEmail = '0';
    let user_login_type = '';
    let isLogin = false;
    if(localStorage.getItem('access_token')){
     isLogin = true;
       getUserId = localStorage.getItem('userid');
       getUserEmail = localStorage.getItem('email');
       user_login_type = localStorage.getItem('user_login_type');
    }
    const loginStatusTrack = {
      'event' : 'pageview',
      'loginStatus' : isLogin? 'logged in': 'Not Logged IN', // 'logged in/Not Logged IN',
      'authenticationMethod' : user_login_type,
      'userId' : getUserId,
      'pageType' : this.routeObj.url,
      'email': getUserEmail,
     };
    (<any>window).dataLayer.push(loginStatusTrack);
    }

}
