import { Component, OnInit } from '@angular/core';
import {GoogleAnalyticsEventsService} from "../../../shared/google-analytics-events.service";
// declare var ga: Function;
// declare var gtag: Function;

@Component({
  selector: 'app-winter-monthly-landing',
  templateUrl: './winter-monthly-landing.component.html',
  styleUrls: ['./winter-monthly-landing.component.scss']
})
export class WinterMonthlyLandingComponent implements OnInit {

  phoneNumber = 'tel:18887057845';
  back_chevron_white = 'assets/img/campaign/monthly-landing/back-chevron-white.svg';
  phoneIcon = 'assets/img/campaign/monthly-landing/phone.svg';
  mailIcon = 'assets/img/campaign/monthly-landing/mail.svg';
  arrowsIcon = 'assets/img/arrows.svg';
  map_markersIcon = 'assets/img/map_markers.svg';
  vehicleIcon = 'assets/img/vehicle.svg'

  constructor() { }
 
  callMonthlyCustomer()
  {
    /* commented for ga 16-6-2021 by seo team
   ga('send', 'event', { eventCategory: 'winter monthly call', eventAction: 'click'})
   */
    //(function(a,e,c,f,g,h,b,d){var k={ak:"876742851",cl:"oEyECNqX9nsQw5GIogM",autoreplace:"(888) 705-7845"};a[c]=a[c]||function(){(a[c].q=a[c].q||[]).push(arguments)};a[g]||(a[g]=k.ak);b=e.createElement(h);b.async=1;b.src="//www.gstatic.com/wcm/loader.js";d=e.getElementsByTagName(h)[0];d.parentNode.insertBefore(b,d);a[f]=function(b,d,e){a[c](2,b,k,d,null,new Date,e)};a[f]()})(window,document,"_googWcmImpl","_googWcmGet","_googWcmAk","script");
   /* commented for ga 16-6-2021 by seo team
    gtag('config', 'AW-876742851/dsFECMy1930Qw5GIogM', {
      'phone_conversion_number': '(888) 705-7845'
    });
    */
  }
  ngOnInit() {
  }

}
