

  <app-header></app-header>

  <div class="contactPage commonPage orangeBar
  pageHeight mar-top-lg">
    <div class="container">

      <div class="row mar-top-md">
        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 col-md-offset-3 col-lg-offset-3 col-sm-offset-3">
       <div class="well well-lg text-center">
          <h2 class="text-primary">Your account has been created!</h2>
          <p>Please check your email for confirmation.</p>
          <p>Thank you!</p>
          <a class="btn btn-black theme-btn" routerLink="/my-account/account">Proceed to My Account</a>
       </div>
        </div>
      </div>
    </div>
  </div>



  <app-footer></app-footer>


