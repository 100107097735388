<div class="collapse in active" id="dataAccount">
	<div class="row">
		<div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
			<form action="" method="POST" role="form" id="" class="floating-label2" (ngSubmit)="f.form.valid && submitAddBankAccount(formData, f)" #f="ngForm" novalidate>
				<div class="form-group focused" [ngClass]="{ 'has-error' : f.submitted && !name.valid }">
					<label class="control-label">Bank Account Name</label>
					<input autocomplete="off" type="text" class="form-control" name="name" [(ngModel)]="formData.name" #name="ngModel" required>
					<div *ngIf="f.submitted && !name.valid" class="help-block">Bank Account Name is required</div>
				</div>
				
				<div *ngIf="has_valet_manager == false">
					<div class="form-group focused" [ngClass]="{ 'has-error' : f.submitted && !account_type.valid }">
						<label class="control-label">Account Type</label>
						<select class="form-control" name="account_type" [(ngModel)]="formData.account_type" #account_type="ngModel" required>
							<option value="checking">Checking Account</option>
							<option value="savings">Savings Account</option>
							<option value="businessChecking">Business Checking Account</option>
						</select>
						<div *ngIf="f.submitted && !account_type.valid" class="help-block">Account Type is required</div>
					</div>
					<div class="form-group focused" [ngClass]="{ 'has-error' : f.submitted && !account_number.valid }">
						<label class="control-label">Account Number</label>
						<input autocomplete="off" type="text" class="form-control" [minlength]="5" [maxlength]="17" (keypress)="restrictNumeric($event)" name="account_number" [(ngModel)]="formData.account_number" #account_number="ngModel" required>
						<div *ngIf="f.submitted && !account_number.valid" class="help-block">Account Number is Required (5 - 17 Digits)</div>
					</div>
					<div class="form-group focused" [ngClass]="{ 'has-error' : f.submitted && !routing.valid }">
						<label class="control-label">Routing</label>
						<input autocomplete="off" type="text" class="form-control" [minlength]="9" [maxlength]="9" (keypress)="restrictNumeric($event)" name="routing" [(ngModel)]="formData.routing" #routing="ngModel" required>
						<div *ngIf="f.submitted && !routing.valid" class="help-block">Routing is Required (9 Digits)</div>
					</div>
				</div>
				<div *ngIf="has_valet_manager">
					<div class="form-group focused" [ngClass]="{ 'has-error' : f.submitted && !account_type.valid }">
						<label class="control-label">Account Type</label>
						<select class="form-control" name="account_type" [(ngModel)]="formData.account_type" #account_type="ngModel" required>
							<option value="checking">Checking Account</option>
							<option value="savings">Savings Account</option>
							<!-- <option value="businessChecking">Business Checking Account</option> -->
						</select>
						<div *ngIf="f.submitted && !account_type.valid" class="help-block">Account Type is required</div>
					</div>
					<div class="form-group focused" [ngClass]="{ 'has-error' : f.submitted && showAccountError }">
						<label class="control-label">Routing & Account Number ( Format - Routing/Account Number )</label>
						<!-- <input autocomplete="off" type="text" class="form-control" [minlength]="5" [maxlength]="17" (keypress)="restrictNumeric($event)" name="account_number" [(ngModel)]="formData.account_number" #account_number="ngModel" required>
						<div *ngIf="f.submitted && !account_number.valid" class="help-block">Account Number is Required (5 - 17 Digits)</div> -->
					
						<iframe id="tokenFrameAccount" height="40px" name="tokenFrameAccount" src="https://valetmanager.cardconnect.com/itoke/ajax-tokenizer.html?&invalidinputevent=true&placeholder=&invalidcreditcardevent=false&css=body%7Bmargin%3A0px%3Bpadding%3A0px%3Bfont-family%3Aarial%3B%7D+++input+%7Bborder-bottom-width%3A+2px%3Bborder-top%3A+0px%3Bborder-left%3A+0px%3Bborder-right%3A+0px%3Bborder-bottom-color%3A+%23ed8222%3Bborder-radius%3A+0%3Bbackground-color%3A+transparent%3B-webkit-box-shadow%3A+none%3Bbox-shadow%3A+none%3Bcolor%3A+%23000000%3Bheight%3A+36px%3Bwidth%3A+100.0%25%3Bpadding-left%3A15px%3Boutline%3A+none%3B%7D"  scrolling="no" width="100%"  frameborder="0"></iframe>
						<input style="float: left;visibility: hidden; padding: 0; height: 0; margin: 0; width: 0;" type="text" name="addNewBankAcToken" id="addNewBankAcToken"/>                            
						<div *ngIf="f.submitted && showAccountError" class="help-block">Routing Number (9 Digits) and Account Number (5 - 17 Digits) is Required</div>
					</div>
				</div>
				
				<div class="form-group focused visible-xs clearfix">
					<div class="col-xs-offset-1 col-xs-10">
						<button type="submit" class="btn btn-primary theme-btn full-width mar-bottom-xs pad-left-0 pad-right-0">Add Payment Method</button>
						<button type="button" class="btn btn-error btn-primary theme-btn full-width" (click)="hitCancel()">cancel</button>
					</div>
				</div>
				<div class="form-group focused hidden-xs">
					<button type="submit" class="btn btn-primary theme-btn">Add Payment Method</button><span class="mar-right-xs"></span><button type="button" class="btn btn-error btn-primary theme-btn" (click)="hitCancel()">cancel</button>
				</div>
			</form>
    </div>
    <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6 pull-right">
			<img [src]="cardguideIcon" alt="cardguideIcon"  class="img-responsive mar-lr-auto">
		</div>
	</div>
</div>