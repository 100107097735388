import { Component, OnInit } from '@angular/core';
import { StaticpagesService } from '../../services/staticpages.service';
import { Router, ActivatedRoute, Params} from '@angular/router';
import { UserserviceService } from '../../services/userservice.service';
import { Meta, MetaDefinition } from '@angular/platform-browser';

// declare var ga: Function;
// declare var gtag: Function;
// declare var gtag_report_conversion : Function;
declare var google: any;
import { CustomCode } from '../../shared/CustomCode';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-icon-rewards-benefit',
  templateUrl: './icon-rewards-benefit.component.html',
  styleUrls: ['./icon-rewards-benefit.component.scss'],
  providers: [StaticpagesService, UserserviceService]
})
export class IconRewardsBenefitComponent implements OnInit {
  pageSlug:string = 'icon-rewards-benefit';
  cmsPageContent : any = {};
  isLoaded = false;
  isLoggedIn:boolean = false;
  isLoyaltyMember:boolean = false;
  robotselement:any;
  metaTags: any;

  loginLink = '#login';
  rewarded_points:number = CustomCode.rewarded_points
  constructor(
    private staticObj: StaticpagesService,  
    private routerObj: Router, 
    private ActivatedRouteObj:ActivatedRoute,
    private userServiceObj: UserserviceService,
    private toasterObj: ToastrService,
    private metaService: Meta
  ) {
    this.ActivatedRouteObj.queryParams.subscribe(params => {
      if(params['_aiid']!==undefined){
        this.metaService.addTag({ name: 'robots', content: 'noindex' });
      }else{
        this.metaService.removeTag("name='robots'")
      }
    });
   }

  ngOnInit() {
    this.ActivatedRouteObj.queryParams.subscribe(params => {
      if(params['key']!==undefined){
        let key = { keyword:params['key'] }
        this.userServiceObj.EncrypInformation(key)
          .subscribe(
            (response:any) => {
              let data = response.data;
              let QueryData = {
                  "access_token": data['access_token'],
                  "refresh_token": data['refresh_token'],
                  "email":data['email'],
                  "userId":data['userId'],
                  "username":data['username'],
                  "req_url": data['req_url'],
                  "iq_code": data['iq_code']
              }
            
              if(QueryData.access_token!==undefined && QueryData.refresh_token!==undefined && QueryData.email!==undefined && QueryData.userId!==undefined && QueryData.username!==undefined && QueryData.req_url!==undefined){
                localStorage.setItem('username', QueryData.username);
                localStorage.setItem('email', QueryData.email);
                localStorage.setItem('access_token',  QueryData.access_token);
                localStorage.setItem('refresh_token',  QueryData.refresh_token);
                localStorage.setItem('userid',  QueryData.userId);
                sessionStorage.setItem('iq_code',  QueryData.iq_code);
                this.userServiceObj.GetLoyaltyProfile().subscribe(
                  (res:any) => {
                    let uData = res.data;
                    let userVal = {
                      user : {
                        name:QueryData.username,
                        email:QueryData.email,
                        id:QueryData.userId,
                        is_loyalty:uData.is_loyalty,
                        is_loyalty_active:uData.is_loyalty_active,
                      },
                      access_token: QueryData.access_token,
                      refresh_token: QueryData.refresh_token,
                    }
                    CustomCode.markLoginUser(userVal);
                    this.routerObj.navigate([QueryData.req_url]);
                  },
                  error => {
                    this.toasterObj.error(error.message,'Sorry!');
                  }
                );
              }
            }
        );      
      }else{
        if(localStorage.getItem('is_loyalty')==="1"){ 
          this.isLoyaltyMember = true; 
        }
      }
    });
   
    if (localStorage.getItem('access_token')) this.isLoggedIn = true;
    this.staticObj.getCmsPageContent(this.pageSlug).subscribe((data:any)=> {
        this.cmsPageContent = data.result[0];
        this.isLoaded = true;
      }, (error) => { console.log('Error: ' + JSON.stringify(error));
        this.isLoaded = true;
      }
    );
    if(localStorage.getItem('access_token')) {
      this.userServiceObj.GetLoyaltyWallet().subscribe(
        (response:any) => {
          this.rewarded_points = response.data.loyalty_config.POINTS_AT_REGISTRATION;
        }
      );
    }
  this.metaTags = Array.from(document.getElementsByTagName('meta'));  
  this.robotselement = this.getMetaElement('robots');
  this.updatePageMeta();
  }

  redirectToSignUp() {
    this.routerObj.navigate(['/create-account']);
  }

  funCheckLoyalityMember(){
    /* commented for ga 16-6-2021 by seo team
    ga('send', 'event', { eventCategory: 'enroll-in-icon-reward', eventAction: 'Click', eventLabel: 'Read more - enroll'});
    */
    if(this.isLoggedIn){
      if(this.isLoyaltyMember) {
        this.routerObj.navigate(['/my-account/account']);
        // this.routerObj.navigate(['/my-account/icon-rewards']);
      } else {
        this.routerObj.navigate(['/my-account/enroll-in-icon-rewards']);
        // this.routerObj.navigate(['/my-account/enroll-in-icon-rewards']);
      }
    }
     else{
      this.routerObj.navigate(['/icon-rewards-register']);
    }
  
}

moveToFaq(){
  localStorage.setItem('loyalty_faq', '1');
  this.routerObj.navigate(['/faq/Icon Rewards']);
}
getMetaElement(name){
  return this.metaTags.find((element) => { return element.getAttribute('name') === name; });
}
updatePageMeta() {
  this.metaService.addTag({ name: 'robots', content: 'noindex' });
  this.robotselement.setAttribute('content', 'noindex');
  
}



}
