import { Injectable } from '@angular/core';
//import { Http, Headers, RequestOptions, Response, ResponseContentType } from '@angular/http';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Rx';
import {HttpService} from './http.service';
import { CustomCode } from '../shared/CustomCode';

import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';

@Injectable({
	providedIn: 'root'
  })
export class PartnerService {

	constructor(private http: HttpService, private http2: HttpClient) { }

	private getPartnerDetailURL = CustomCode.APIURL + 'partner/';
	private getPartnerFacilitiesURL = CustomCode.APIURL + 'search/partner-rates';

	getPartnerDetails(partner_slug) {
    	return this.http.get(this.getPartnerDetailURL + partner_slug)
    	.map(
    		(res: any) => 
    			res
    	).catch(
    		(error: any) => 
    			Observable.throw(error)
    	);
  	}

  	getPartnerFacilities(data) {
    	return this.http.post(this.getPartnerFacilitiesURL, data)
    	.map(
    		(res: any) => 
    			res
    	).catch(
    		(error: any) =>
    			Observable.throw(error)
    	);
  	}

}

