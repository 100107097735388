import { Component, OnInit,ViewEncapsulation,PipeTransform, Pipe } from '@angular/core';
import { StaticpagesService } from '../../services/staticpages.service';
import { Observable } from 'rxjs/Rx';
import { Router, ActivatedRoute, Params } from '@angular/router';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { DomSanitizer } from '@angular/platform-browser';
@Pipe({ name: 'safeHtmlCoupon'})
export class safeHtmlCoupon implements PipeTransform  {
  constructor(public sanitizer: DomSanitizer) {}

  transform(value) {
    
    return this.sanitizer.bypassSecurityTrustHtml(value);
  }
}
@Component({
  selector: 'app-holiday-promocode-landing',
  templateUrl: './holiday-promocode-landing.component.html',
  styleUrls: ['./holiday-promocode-landing.component.scss'],
  providers: [StaticpagesService],
  encapsulation: ViewEncapsulation.None
})

export class holidayPromocodeLandingComponent implements OnInit {

  page: String = 'ourgifttoyou-w';
  staticPageContent: any = {};
  isLoadeds = false;
  isLoggedIn:boolean = false
isLoyality = false;
  constructor( private staticObj:StaticpagesService,private router: Router ) {
   
   }

  ngOnInit() {

    if(localStorage.getItem('is_loyalty_active') && localStorage.getItem('is_loyalty_active')=='1')
    {
      this.isLoyality = true
    }

    let access_token = localStorage.getItem('access_token');
    let refresh_token = localStorage.getItem('refresh_token');
if(access_token && refresh_token){
  this.isLoggedIn = true
}else {
  this.isLoggedIn = false
}
    this.isLoadeds=false;
    
    if(this.router.url.toLowerCase().includes('ourgifttoyou-w')){
      this.page = 'ourgifttoyou-w';
    }
    if(this.router.url.toLowerCase().includes('ourgifttoyou-e')){
      this.page = 'ourgifttoyou-e';
    }
    if(this.router.url.toLowerCase().includes('ourgifttoyou-s')){
      this.page = 'ourgifttoyou-s';
    }
   
    this.staticObj.getCmsPageContent(this.page).subscribe((data:any) => {
      this.staticPageContent = data.result[0];
      this.isLoadeds = true;
    }, (error) => { console.log('Error: ' + JSON.stringify(error));
      this.isLoadeds = true;
      }
  );
  }

}

