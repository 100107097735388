import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef , TemplateRef, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CustomCode } from '../../../shared/CustomCode';
import { FilterHelper } from '../../../classes/filter-helper';
import { Ng2DeviceService } from 'ng2-device-detector';
import { DeviceDetectorService } from 'ngx-device-detector';
import { UserserviceService } from '../../../services/userservice.service';
import { MyAccountMonthlyParkingService } from '../../../services/my-account-monthly-parking.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Location} from '@angular/common';
import { BsDatepickerModule} from 'ngx-bootstrap/datepicker';
import { FormBuilder, FormGroup, Validators,FormArray, FormControl } from '@angular/forms';
import { disablePageScroll, enablePageScroll } from 'scroll-lock';

import * as $ from 'jquery';
import { ThrowStmt } from '@angular/compiler';
//import { setTimeout } from 'timers';
@Component({
  selector: 'app-search-filters',
  templateUrl: './search-filters.component.html',
  styleUrls: ['./search-filters.component.scss'],
  providers: [UserserviceService, MyAccountMonthlyParkingService]
})
export class SearchFiltersComponent implements OnInit {
  public modalRef: BsModalRef;
  private _picker: BsDatepickerModule;
  bsValue = new Date();
  bsRangeValue: Date[];
    @ViewChild('myDiv',{ static: true }) myDiv: ElementRef;
    @ViewChild('dateTime2',{ static: true }) dateTime2: ElementRef;
    @Input() bookingView?: boolean;
    @Output() updateFilters = new EventEmitter<boolean>();
    @Output() updateFiltersNei = new EventEmitter<boolean>();
    @Output() updateFiltersOnSubmit = new EventEmitter<boolean>();
    @Output() updateFacilityFilters = new EventEmitter<boolean>();
    @Output() updateFiltersMobile = new EventEmitter<boolean>();
    @Output() searchtypedata = new EventEmitter<any>();
    applyfilterform:FormGroup;
    startDatechangedflag=false;
    sign:any = '';
    month = new Array("Jan","Feb","Feb","Mar","Apr","May", "Jun","Jul","Aug","Sep", "Oct","Nov","Dec")
    todayDate=this.month[new Date().getMonth() + 1]+" "+new Date().getDate()
    //todayDate=new Date();
    windowtype="pc";
    startDuration:any;
    startDurationMonthly:any;
    endDuration:any;
    monthlyDate:any;
    is_landing_page:any=false;
    startTime:any;
    show_strip_search:any=false
    is_landing_page_save:any=true;
    is_show_landing_strip:any=false;
    endTime:any;
    showonhomepage=1;
    minDate:any;
    maxDate:any;
    is_deal:boolean=false;
    monthlypark: boolean =false
    motorcycle: boolean =false
    maxEndDate:any;
    filterData:any;
    Couponmessage=''
    Couponmessages=''
    inactiveFacility:any=false;
    isSearch:boolean = false;
    isNeighborhoods:boolean = false;
    isPayment:boolean = false;
    isPartner:boolean = false;
    endMaxDuration:any;
    firstLoad = false;
    dealsBtn = false
    isLoggedIn=false
    oversized: boolean =false
    evcharging: boolean =false
    tesla: boolean =false
    outdoor: boolean =false
    indoor: boolean =false
    twentyhour:boolean=false
    typeOfSearch:any='daily';
    monthlyFilter:any = false;
    dailyFilter:any = true;
    dailyFilters:any=true;
    urlDate:any='';
    a:any = '';
    searchCriteria: any = {
      'type': CustomCode.defaultFacilityType,
      'device': 'web',
      'lat': CustomCode.timeSquareCoords.lat,
      'long': CustomCode.timeSquareCoords.long,
      'currdate': CustomCode.getDateForMapSearch(),
      'radius': CustomCode.facilityDefaultRadius,
      'label': CustomCode.timeSquareCoords.defaultLocation,
      'length_of_stay': 2,
      'firstLoad' : false
    };
    deviceInfo = null;
    timeSlots:any = FilterHelper.getObjectBasedTimeSlots();
    isFrame:boolean=false;
    logoIconDark='assets/img/icon_logo-dark.svg';
    crossSvg='assets/img/cross.svg'
    ev_charge_icon='assets/img/v8-design/icon-small-universalEV.svg';
    motorcycle_icon='assets/img/v8-design/icon-small-motocycle.svg'
    tesla_charger_icon='assets/img/v8-design/icon-small-tesla.svg'
    open_24hr_icon='assets/img/v8-design/icon-small-24hr.svg'
    monthly_icon='assets/img/v8-design/icon-small-monthly.svg'
    oversized_icon='assets/img/v8-design/icon-small-oversized.svg'
    outdoor_icon='assets/img/v8-design/icon-small-outdoorpark-v2.svg'
    indoor_icon='assets/img/v8-design/icon-small-indoorpark-v2.svg'
    filter_icon='assets/img/v8-design/filter_icon.svg'
    date_icon='assets/img/v8-design/date-icon.svg'
    time_icon='assets/img/v8-design/time-icon.svg'
    typeOfSearchDate:any='daily'
    changeOnClickType:any='daily';
    filterBadge = 'filter-badge-loyalty';
    yesdo:boolean=false
    isfacility:any=0
    is_home_page=0;
    isPartnerPage:boolean = false;
    c:any = '';
    ispartner:boolean = false;
    dealstoggle:boolean=false;
    isDedicatedFacility = false;
    showOnMothly:any=0;
    blueBar:boolean = false;
    navHeight:any;
    hideOnPayment = false;
    isLoyality = false;
    monthlyParkingNbCheck = false;
    @Input() showTies:boolean = true;
    @Output() updateTierToNeighbour = new EventEmitter();
    @ViewChild('searchFilterHeight',{ static: true }) searchFilterHeight1:ElementRef;
    searchFilterHeightDiv = null;
    searchFilterAllValues= new Array('is_twentyhour', 'is_indoor_parking', 'is_outdoor_parking', 'is_tesla_charging', 'is_generic_ev_charging', 'is_oversized', 'is_motorcycle_parking', 'is_monthlypark','is_deal');

    constructor(
        private location:Location,
        private userServiceObj: UserserviceService,
        private modalService: BsModalService,
        private myAccountMonthlyParkingObj: MyAccountMonthlyParkingService,
        private deviceService: Ng2DeviceService,
        private deviceService_new: DeviceDetectorService,
        private toastr: ToastrService,
        private activatedRouteObj: ActivatedRoute,
        private router: Router,
        private changeDetectorRef:ChangeDetectorRef,
        private formBuilder: FormBuilder) { 
    
    }
    public config = {
      animated: false,
      keyboard: true,
      backdrop: true,
      ignoreBackdropClick: false
    };
    ngAfterViewInit() {
      if($('#startDateWithID').val()==""){
        $('#startDateWithID').val(this.startDuration)
        $('#endDateWithID').val(this.endDuration)
        $('#startTimeWithID').val(this.startTime)
        $('#endTimeWithID').val(this.endTime)
      }
      //alert('ggg')
      setTimeout(()=>{
        clearInterval(this.c);
      },10000)
      // this.monthlyDate = this.a;
      setInterval(()=>{    //<<<---    using ()=> syntax
       if(sessionStorage.getItem('editTime'))
       {
        //let el: HTMLElement = this.dateTime2.nativeElement as HTMLElement;
        //this.openModal(this.dateTime2)
        let el: HTMLElement = this.myDiv.nativeElement as HTMLElement;
        el.click();
        
       }
       
        }, 100);   
      //this.startDuration = new Date();
      //this.endDuration = new Date(); 
     //alert(this.startDuration);
    this.maxEndDate=FilterHelper.defaultEndstartDate_new(this.startDuration)
     if(this.maxEndDate>FilterHelper.defaultEndstartDate()){
      this.maxEndDate=FilterHelper.defaultEndstartDate();
     }
     if(this.endDuration>this.maxEndDate){
      this.maxEndDate=this.endDuration;
     }
    //  if(localStorage.getItem('endttimehome')){
    //   this.maxEndDate = FilterHelper.defaultEndDurationUrlHome(localStorage.getItem('endttimehome'));
    //  }
     

     if(this.router.url.indexOf('payment')>-1)
      {//alert(this.calculateLengthOfStay());
        if (this.calculateLengthOfStay() < 2) {
          this.toastr.error(FilterHelper.TWO_HOUR_ERROR_MESSAGE, FilterHelper.MESSAGE_TITLE);
        }
      } 
    }
    
    todayDateFunction()
    {
     return  this.month[new Date(this.startDuration).getMonth() + 1]+" "+new Date(this.startDuration).getDate()
    }
    ngAfterViewChecked(){
      //alert('1')
      
      if(sessionStorage.getItem("enddatefromapi")){
        console.log("enddate from api",sessionStorage.getItem("enddatefromapi"))
        this.endDuration=new Date(sessionStorage.getItem("enddatefromapi"));
        if(this.endDuration>this.maxEndDate){
          this.maxEndDate=this.endDuration;
          
        }
        
        sessionStorage.removeItem("enddatefromapi")
      }
    }
    ngOnInit() {
      //alert('1');
      this.activatedRouteObj.params.subscribe((params: Params) => {
        if (params['currdate']){
          let urldatetime=new Date(FilterHelper.defaultStartDurationUrlAfterNewHomePage(params['currdate']));
          let currentdatetime=new Date(FilterHelper.defaultStartDuration());
          if(urldatetime.getTime()<currentdatetime.getTime()){
            if(currentdatetime.getHours()!=urldatetime.getHours()||currentdatetime.getMinutes()!=urldatetime.getMinutes()||currentdatetime.getSeconds()!=urldatetime.getSeconds()){
              let startTime=FilterHelper.defaultStartDuration();
              let startTimes = FilterHelper.getFormattedTime(startTime);
              let stime =CustomCode.getDateForMapSearchSlashFormatHome(startTime,startTimes);
              //alert('1')
             // alert(params['lat']+" "+params['long']+" "+params['radius']+" "+params['label']+" "+params['type']+" "+params['device'])
              //this.router.navigate(['/search']);
              this.router.navigate(['/search', params['lat'], params['long'], stime,params['radius'], params['label'], params['type'],params['device']]);
              //alert("/search/"+params['lat']+"/"+params['long']+"/"+stime+"/"+params['radius']+"/"+params['label']+"/"+params['type']+"/"+params['device'])
            }
            
          }
        }
      
      });


      this.applyfilterform = this.formBuilder.group({
        checkboxFilter: this.formBuilder.array([], [Validators.required])
      });
     let check =  setInterval(()=>{
        if( this.searchFilterHeight1 != undefined ){
          
          this.searchFilterHeightDiv = this.searchFilterHeight1.nativeElement.offsetHeight;
         // console.log(this.searchFilterHeight1);
          localStorage.setItem('searchFilterHeight',this.searchFilterHeightDiv.toString() );
          clearInterval(check);
        }
      },1000)
      setInterval(()=>{
        
        if( this.searchFilterHeight1 != undefined && this.searchFilterHeightDiv != this.searchFilterHeight1.nativeElement.offsetHeight){
          let searchFilterHeightNew = this.searchFilterHeight1.nativeElement.offsetHeight;
          if(searchFilterHeightNew>0){
            localStorage.setItem('searchFilterHeight',searchFilterHeightNew );
          }
        }
      },1000)
      localStorage.removeItem('mapDrag');
      localStorage.removeItem('mapDrag1');
      if(this.router.url.indexOf('payment') > -1){
        
        this.hideOnPayment = false;
        if(localStorage.getItem('is_loyalty_active') && localStorage.getItem('is_loyalty_active')=='1')
        {
        this.isLoyality = true
        } else{
          this.isLoyality = false
        }
      }else {
        this.hideOnPayment = true;
      }
      



      
      if(this.router.url.indexOf('payment') > -1 || this.router.url.indexOf('buy-monthly') > -1)
        {

       setInterval(()=>{
          this.navHeight = (document.getElementById('NavbarInner')!==null)? document.getElementById('NavbarInner').offsetHeight: 0;
          let getHeight = (document.getElementById('NavbarInner') !== null) ? document.getElementById('NavbarInner').offsetHeight : 0;
            if(this.navHeight > getHeight){
              this.navHeight = getHeight;
            }
             },100);
          // setInterval(()=>{
          //   
          // },100);

          this.blueBar=true;
        }else{
          this.blueBar=false;
            // let getHeight = (document.getElementById('NavbarInner') !== null) ? document.getElementById('NavbarInner').offsetHeight : 0;
            // if(this.navHeight > getHeight){
            //   this.navHeight = getHeight;
            // }
          setInterval(()=>{
            let getHeight = (document.getElementById('NavbarInner') !== null) ? document.getElementById('NavbarInner').offsetHeight : 0;
            if(this.navHeight > getHeight){
              this.navHeight = getHeight;
            }
          },100);
        }


        // this.barHeight = document.getElementById('NavbarInner').offsetHeight;

        // setInterval(()=>{
        //   let getHeight = document.getElementById('NavbarInner').offsetHeight;
        //   if(this.barHeight > getHeight){
        //     this.barHeight = getHeight;
        //   }
        // },100);


      let cp = setInterval(()=>{
        if(localStorage.getItem('navigatesearch'))
        {
          //alert('1')
          this.searchDate()
          localStorage.removeItem('navigatesearch')
          //clearInterval(cp)
        }
      },2000)

      this.Couponmessages='<b>Great!</b> We’ll automatically apply your promo code '+localStorage.getItem("promocode")+' at checkout.'
      
      //  if(this.router.url.indexOf('partners') > -1 || this.router.url.indexOf('facility') > -1 ) 
        if(this.router.url.indexOf('partners') > -1 ) 
        {

          this.ispartner=true;
        }
        
        
        if(this.router.url.indexOf('facility') > -1 ) 
        {
          this.isDedicatedFacility = true;
        
          $('.searchFilter ').addClass('facilitPageFix');
          $('.homeTopFix').css('margin-top','140px');
        }else {
          this.isDedicatedFacility = false
        }
        

        sessionStorage.removeItem('openByClick')
        sessionStorage.removeItem('searchTypeByMobile')
        let getUrl = window.location;
        if ($(window).width() < 768  ) {
           this.windowtype="mob";
          // setInterval(() => {
          //   if(sessionStorage.getItem('searchTypeByMobile') == 'daily'){
          //   // this.dealsBtn = false
          //   }
          //   if(sessionStorage.getItem('searchTypeByMobile') == 'monthly'){
          //     // this.dealsBtn = true
          //     }
          // }, 1000);	
        }
        

        if(getUrl.pathname=='/' || getUrl.pathname==='/variant/' || getUrl.pathname==='/variant')
        {
          this.showonhomepage=0;
        }else{
          this.showonhomepage=1;
        }
        
        if(!sessionStorage.getItem('promoApply'))
        {
          localStorage.removeItem('is_landing_page');
          localStorage.removeItem('routeUrl');
          localStorage.removeItem('template_slug');
          localStorage.removeItem('promotion_id');
          localStorage.removeItem('promocode');
          localStorage.removeItem('action_type');
          localStorage.removeItem('reservation_apply');
        }
        //alert(this.router.url);
        if(this.router.url=='/' ||this.router.url.indexOf('/?') > -1)
        {
          this.is_home_page=1
        }else{
          this.is_home_page=0;
        }
        if (this.router.url=='/' || this.router.url.indexOf('search') > 0 || this.router.url.indexOf('neighborhoods')>0 || this.router.url.indexOf('facility')>0)
        {
          this.show_strip_search=true;
        }
        if(this.router.url.indexOf('facility') > -1 || this.router.url.indexOf('partners') > -1)
        {
          this.yesdo=true;
        }else{
          this.yesdo=false;
        }
        if(localStorage.getItem("promocode") && localStorage.getItem('is_landing_page_monthly_stripe') && this.router.url.indexOf('search') > 0 && this.router.url.indexOf('monthly') > -1 && localStorage.getItem('is_landing_page_monthly'))
        {
          this.showOnMothly=1;
          this.sign = 'dollar';
        }else{
          this.showOnMothly=0;
        }
        //this.show_strip_search=true;
        if(localStorage.getItem('is_landing_page')=='true' && localStorage.getItem('action_type')=='apply' )
        {
           if(localStorage.getItem('is_show_landing_strip')=='true')
           {
             this.is_show_landing_strip=true;
           }
           if (localStorage.getItem('access_token')) {
               let data = {};
               let mode='apply'
               data = {
                      'promocode':localStorage.getItem('promocode'),
                       'user_id' :localStorage.getItem('userid')};
                 
                this.userServiceObj.validatePromoCode(data)
                 .then(
                 (data:any)=> {
                          if (data['data'].is_promocode_valid) {
                          if (mode == "save") {
                              //this.savePromocode(neighborhood);
                          } else {
                              //this.notifyReservationPromoCodeApply();
                          }
                          } else 
                          {
                             localStorage.setItem('couponMessage',data['data'].message)
                             localStorage.setItem('errorType','1')
                          }
                          this.promocodeApply();
                        },
               error => {

                        localStorage.setItem('couponMessage',error.errors.message)
                        localStorage.setItem('errorType','0')
                        if(error.errors.message == "This Promocode is only for guest user")
                          {
                            localStorage.setItem('errorType','4');
                          }
                          else if (error.errors.message == "Please create an account to avail this") 
                          {
                              localStorage.setItem('errorType','1')
                              //this.notifyAccountRequirement();
                                //this.launchLoginPopup();
                          }
                           else if (error.errors.message == "Please enter a valid Promotion Code. Please note that a prepaid voucher is not a Promo Code.") 
                          {
                              localStorage.setItem('errorType','1')
                          }
                          this.promocodeApply();
                        }
                          );
           }
        }
        this.promocodeApply();
        
        if(localStorage.getItem('inactiveFacility'))
        {
          this.inactiveFacility=true;
        }
        setTimeout(() => {
          localStorage.removeItem('inactiveFacility')
        }, 2000);  
        if(localStorage.getItem('monthlyDate') && localStorage.getItem('monthlyDate') !== null)
        {
          this.monthlyDate = new Date(localStorage.getItem('monthlyDate'));
          
        } else {
          this.monthlyDate = new Date();
        }
        this.minDate = FilterHelper.defaultStartDurationForMin();
        this.maxDate=  FilterHelper.defaultEndstartDate();
        //this.maxDate=  FilterHelper.defaultEndstartDate_new('02-12-2023 05:00:00');
        //this.maxEndDate=FilterHelper.defaultEndstartDate();
        this.activatedRouteObj.params.subscribe((params: Params) => {
          if (params['currdate']){
            this.maxEndDate=FilterHelper.defaultEndstartDate_new(params['currdate'].substring(0, 10)+' '+params['currdate'].substring(19,11))
            if(this.maxEndDate>FilterHelper.defaultEndstartDate()){
              this.maxEndDate=FilterHelper.defaultEndstartDate();
            } 
          }
        
        });
        
        
        this.initializeFilters();
        this.activateSearchType();
        this.changeDetectorRef.detectChanges();
        setTimeout(() => {
          this.firstLoad = true;
        }, 200);

        setInterval(()=>{ if(this.monthlyDate ===null) { this.monthlyDate = new Date(); } 
        if(localStorage.getItem('currentLoc'))
       {
         localStorage.removeItem('currentLoc')
         this.searchDates();
       }
      }, 1000);

      if(this.router.url.indexOf('monthly-parking')>-1 && localStorage.getItem('specialParking')){
        this.monthlyParkingNbCheck = true;
        if(localStorage.getItem('filterApplyDeal') && localStorage.getItem('monthly_campaign') && localStorage.getItem('ischecked')=='1'){
          this.dealstoggle = true
        }
      } else {
        this.monthlyParkingNbCheck = false
      } 
     // alert(this.maxEndDate);daily
     if(this.router.url.indexOf('monthly-parking') >-1 && this.router.url.indexOf('daily')<0){

     }
     else{
      this.maxEndDate.setMonth(this.startDuration.getMonth());
      this.maxEndDate.setDate(this.startDuration.getDate() + 30);
      if(this.maxEndDate>FilterHelper.defaultEndstartDate_new(this.startDuration)){
        this.maxEndDate=FilterHelper.defaultEndstartDate_new(this.startDuration);
      }  
     }
      
       
    }

    checkFilterFormValue(chkVal:string){
      if(this.applyfilterform.value.checkboxFilter.indexOf(chkVal) != -1){
        return true;
      }else{
        return false;
      }
    }

    onCheckboxChange(e) {
      const checkboxFilter: FormArray = this.applyfilterform.get('checkboxFilter') as FormArray;
  
      if (e.target.checked) {  
        checkboxFilter.push(new FormControl(e.target.value));
  
      } else {  
         const index = checkboxFilter.controls.findIndex(x => x.value === e.target.value);  
         checkboxFilter.removeAt(index);
      }  
    }

    iconClick(elementId) {
      document.getElementById(elementId).click();
    }

    hideStripe()
    {
    
      $('.negiMap').removeClass('blueBarOn');
     // $('aside.contentBar').css({'height':'calc(100% - 140px);'})
      this.is_landing_page=false;
      this.is_show_landing_strip=false;
      localStorage.setItem('is_show_landing_strip','false')
     // this.sidebarHeightUpdate();
      $('.payment-section').removeClass('bluebar');
    }
    hidestripfacility()
    {
      this.inactiveFacility=false;
     // this.sidebarHeightUpdate();
    }
    sidebarHeightUpdate(){
    setTimeout(function(){ 
      if($('.navbar-custom').length > 0){
        this.headerBarHeight = 120;
      }else{
        this.headerBarHeight = 0;
      }
      if($('.searchFilter').length > 0){
        this.searchFilterHeight  = 60;
      }else{
        this.searchFilterHeight  = 0;
      }
      if($('.infoBlock').length > 0){
        this.searchFilterHeight  = 121;
      }else{
        this.searchFilterHeight = this.searchFilterHeight;
      }
      
      this.sideBarValue =  this.headerBarHeight + this.searchFilterHeight ; 
      $('aside.contentBar').css({'height':'calc(100% - '+this.sideBarValue+'px)'});
     // $('.searchFilter').css({'margin-top': + this.headerBarHeight + 'px'});
      if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
        $('.negiMap').css({'height':'100vh'});
            $('.negiMap').css({'margin-top': '0px'});
            $('.nghMap').css({'top':'120px'});
      }else{
        $('.negiMap').css({'height':'calc(100vh - '+this.sideBarValue+'px)'});
            $('.negiMap').css({'margin-top': +this.sideBarValue+'px'});
            $('.nghMap').css({'top':+this.sideBarValue+'px'});
      }
    },0);
  }
    promocodeApply()
    {
      if(localStorage.getItem('is_landing_page')=='true' && localStorage.getItem('action_type')=='apply' )
        {
          this.is_landing_page =true;
           if (localStorage.getItem('access_token')) {
             if(localStorage.getItem('errorType')=='4')
             {
               this.sign = 'dollar';
               this.is_landing_page_save=false;
               localStorage.removeItem('is_landing_page')
               this.Couponmessage=localStorage.getItem('couponMessage')
             }
             else if(localStorage.getItem('errorType')=='1')
             {
               this.sign = 'dollar';
               this.is_landing_page_save=false;
               this.Couponmessage=localStorage.getItem('couponMessage')
               if(this.Couponmessage=='Please enter a valid Promotion Code. Please note that a prepaid voucher is not a Promo Code.')
               {
                 this.is_landing_page_save=false;
               }
             }else if(localStorage.getItem('errorType')=='0')
             {
               this.sign = 'dollar';
               this.Couponmessage='Promocode has been expired.'
               this.is_landing_page_save=false;
             }
             else{
               this.sign = 'dollar';
               this.Couponmessage="<b>Great!</b> We"+"'"+"ll automatically apply your promo code "+localStorage.getItem('promocode').toUpperCase()+" at checkout. Or,"
             }
          }else{
             this.sign = 'dollar';
             this.is_landing_page_save=false;
             this.Couponmessage=localStorage.getItem('couponMessage')
             if(localStorage.getItem('errorType')=='2')
             {
               this.is_landing_page_save=true;
               if(localStorage.getItem('couponMessage')=='Please create an account to avail this')
              {
                 this.is_landing_page_save=false;
                 this.Couponmessage='Please create an account or login to your existing account in order to use this promo code.'
               }
             }
            //this.Couponmessage=localStorage.getItem('couponMessage')
          }
         // this.Couponmessage=localStorage.getItem('couponMessage')
        }
        if(localStorage.getItem('showEroor'))
        {
          if(localStorage.getItem('showEroor')=='1')
          {
           this.toastr.success(localStorage.getItem('showEroorMsg'), 'Great!');
          }else{
            this.toastr.error(localStorage.getItem('showEroorMsg'), 'Sorry!');
          }
          localStorage.removeItem('showEroor')
          localStorage.removeItem('showEroorMsg')
        }
    }
    launchLoginPopup() {
       if (localStorage.getItem('access_token')) {
          this.isLoggedIn = true;
          this.validatePromoCode('save');
       }else{
         localStorage.setItem('save', 'true');
         localStorage.setItem('savebylogin', 'true');
      $("a#loginPopup")[0].click();
       }
      
    }
    

  validatePromoCode(mode) {
    let data = {
        'promocode': localStorage.getItem('promocode'),
        'user_id' : localStorage.getItem('userid')
      };
    this.userServiceObj.validatePromoCode(data)
    .then(
      (data:any)=> {
        if (data['data'].is_promocode_valid) {
          this.savePromocode();
        } else {
          this.toastr.error('Promocode has been expired', 'Sorry!');
          //window.location.reload();
        }
      },
      error => {
        if (error.errors.message == "Please create an account to avail this") {
          this.toastr.error('Please login to your existing account or create a new one in order to save and redeem this promotion.', 'Sorry!');
        } else {
          this.toastr.error(error.errors.message, 'Sorry!');
         // window.location.reload();
        }
      }
    );
  }
  close(){
    $('#featureFilter').removeClass('open');
  }
  savePromocode() {
    this.hideStripe();
    localStorage.removeItem('savebylogin')
    const data = {'promocode' : localStorage.getItem('promocode'), 'user_id' : localStorage.getItem('userid')};
    this.userServiceObj.addPromoCode(data)
    .then(
      (data:any)=> {
        this.show_strip_search=false
        localStorage.setItem('is_show_landing_strip','false')
        if (!data['errors']) {
           this.show_strip_search=false
            this.toastr.success('Promocode successfully added, Please make a reservation to redeem this offer.', 'Great!');
           // this.navidateToSearch();
        } else {
          this.toastr.error('Something wrong happened, try again later', 'Sorry!');
          //window.location.reload();
        }
      },
      error => {
        this.show_strip_search=false
        localStorage.setItem('is_show_landing_strip','false')
        this.toastr.error(error.errors.message, 'Sorry!');
       // this.navidateToSearch();
        // window.location.reload();
      }
    );
  }
  toTime(timeString){
    if(timeString!=undefined)
    {
      var timeTokens = timeString.split(':');
      //return 'December 17, 1995 ' +timeTokens[0]+':'+timeTokens[1]+':'+timeTokens[2]
      let filterDate= new Date('December 17, 1995 ' +timeTokens[0]+':'+timeTokens[1]+':'+timeTokens[2]);
      let hours = filterDate.getHours();
      let minutes:any = filterDate.getMinutes();
      var ampm = hours >= 12 ? 'PM' : 'AM';
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'
      minutes = minutes < 10 ? '0'+minutes : minutes;
      let  strTime = hours + ':' + minutes + ' ' + ampm;
      return strTime;
      //return new Date(1970,0,1, timeTokens[0], timeTokens[1], timeTokens[2]);
    }else{
      let filterDate= new Date('December 17, 1995 00:00:00');
      let hours = filterDate.getHours();
      let minutes:any = filterDate.getMinutes();
      var ampm = hours >= 12 ? 'PM' : 'AM';
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'
      minutes = minutes < 10 ? '0'+minutes : minutes;
      let  strTime = hours + ':' + minutes + ' ' + ampm;
      return strTime;
    }
    
  }
  toDate(timeString){
      let changeDateFormat=new Date(timeString)
      let afterChnageDate=this.month[changeDateFormat.getMonth() + 1]+" "+changeDateFormat.getDate() 
      return afterChnageDate 
  }
    initializeFilters() {
      //alert(3);
      if (this.router.url.indexOf('search') > 0) { 
        this.isSearch = true;
        this.initializeMapFilters();
      }
      if (this.router.url.indexOf('facility') > 0) { 
        this.isSearch = true;
        this.isfacility=1
        //this.initializeMapFilters();
      }else{
        this.isfacility=0
      }
      if (this.router.url.indexOf('neighborhoods') > 0 || (this.router.url.indexOf('monthly-parking') > 0) ) { 
        this.isNeighborhoods = true;
        this.initializeNeighborhoodsFilters();
        //this.getMonthlyDate();
        this.getNeighbourDetails();
      }

      if (this.router.url.indexOf('payment') > 0) {
        this.isPayment = true;
        this.initializePaymentFilters();
      }

      if (this.router.url.indexOf('facility') > 0) {
        this.isPayment = true;
        this.initializeFacilityFilters();
      }
      
      if (this.router.url.indexOf('partners') > 0) {
        this.isPartner = true;
        this.initializePartnerFilters();
      }

      setTimeout(()=>{
        //alert('2')
        this.searchDate();
      },1000)
    }
    onShowPicker(event) {
     // console.log('onShowPicker1')
      // $('body').addClass('calendarFixScroll');
     // const $scrollableElement = document.querySelector('body');
      //disablePageScroll($scrollableElement);

   
      const dayHoverHandler = event.dayHoverHandler;
      const hoverWrapper = (hoverEvent) => {
          const { cell, isHovered } = hoverEvent;
  
          if ((isHovered &&
            !!navigator.platform &&
            /iPad|iPhone|iPod/.test(navigator.platform)) &&
            'ontouchstart' in window
          ) {
              (this._picker as any)._datepickerRef.instance.daySelectHandler(cell);
          }
  
          return dayHoverHandler(hoverEvent);
      };
      event.dayHoverHandler = hoverWrapper;
  }
  

//   onHiddenPicker(buck) {
//     const $scrollableElement = document.querySelector('body');
//     enablePageScroll($scrollableElement);

//     // $('body').removeClass('calendarFixScroll');
// }
    initializeMapFilters() {
     //alert("66")
     // this.router.url
      this.deviceInfo = this.deviceService_new.getDeviceInfo();
      
      this.startDuration = FilterHelper.defaultStartDuration();
      this.startTime = FilterHelper.getFormattedTime(this.startDuration);
      //alert(localStorage.getItem('endttimehome'))
      if(localStorage.getItem('endttimehome'))
      {
        this.endDuration = FilterHelper.defaultEndDurationUrlHome(localStorage.getItem('endttimehome'));
      }else{
        this.endDuration = FilterHelper.defaultEndDuration();
      }
      //console.log(this.endDuration+" asasaasasasas");
      this.activatedRouteObj.params.subscribe((params: Params) => {
        if (params['lat'] && params['long'] && params['currdate'] && params['radius'] && params['label'] && params['type'] && params['device']) {
          this.typeOfSearch=this.searchCriteria.type = params['type'];
          this.changeOnClickType = params['type'];
          this.typeOfSearchDate = params['type'];
          this.changeOnClickType = params['type'];
          //alert(this.typeOfSearch)
          if(this.typeOfSearch=='monthly'){
            this.dailyFilters=false;
          }
          
          this.urlDate=params['currdate'];
          this.searchCriteria.device = params['device'];
          this.searchCriteria.lat = +params['lat'];
          this.searchCriteria.long = +params['long'];
          this.updateRecordsOnLabelChangeonLoad(params['type'])
          if(this.searchCriteria.type=='monthly'){
            this.monthlyDate = this.startDuration = FilterHelper.defaultStartDurationUrlAfterNewHomePage(params['currdate']);
          }else{
              
              this.monthlyDate =  FilterHelper.defaultStartDurationUrlAfterNewHomePage(params['currdate']);
          }
          this.searchCriteria.currdate = FilterHelper.removeSpecialCharacters(params['currdate']);
          this.searchCriteria.radius = params['radius'];
          this.searchCriteria.label = params['label'];
          localStorage.setItem('searchLocation',params['label'].trim())
          let urlDate:any = '';
          if(this.deviceInfo.browser!=='safari')
          {
            urlDate = new Date(this.searchCriteria.currdate);
          }else
          {
            let tzones:any = new Date()
            let startDurati=tzones.toString()
            let locs = startDurati.search("GMT");
              
            let sbustring_timezone = tzones.toString().slice(parseInt(locs)+3,parseInt(locs)+8);
            let  txt2 = sbustring_timezone.slice(0, 3) + ":" + sbustring_timezone.slice(3)
            //let  txt2 = sbustring_timezone.slice(0, 3) + ":" + sbustring_timezone.slice(3)
            let dat = this.searchCriteria.currdate+''+txt2
            urlDate = new Date(dat);
          }
         // let urlDate = new Date(this.searchCriteria.currdate);
         
          let currentDate = FilterHelper.changeNyTime()
          //alert(currentDate);
          if(this.searchCriteria.type=='monthly')
          {
            this.show_strip_search=false;
          }else{
            this.show_strip_search=true;
          }
          currentDate.setHours(currentDate.getHours() - 1);
          if(urlDate<currentDate)
          {
            this.searchCriteria.currdate=this.formonthly();
            //this.searchCriteria.currdate=FilterHelper.defaultStartDuration();
          //  this.router.navigate(['/search', params['lat'], params['long'], CustomCode.getDateForMapSearchSlashFormat(), params['radius'], params['label'], params['type'], params['device']]);
          }
          
          var searchType=localStorage.getItem('searchType')
      
          if(this.deviceInfo.browser!=='safari')
          {
             let timeZoneOffset = Intl.DateTimeFormat().resolvedOptions().timeZone
            // alert('h')
             if(this.searchCriteria.type=='monthly'){
              this.monthlyDate = FilterHelper.defaultStartDurationUrlAfterNewHomePage(this.searchCriteria.currdate);
             }
             else{
              this.monthlyDate = this.startDuration = FilterHelper.defaultStartDurationUrlAfterNewHomePage(this.searchCriteria.currdate);
             }
            
             
             //this.endDuration = FilterHelper.defaultEndDurationUrl(this.searchCriteria.currdate);
             if(localStorage.getItem('endttimehome'))
            {
              this.endDuration = FilterHelper.defaultEndDurationUrlHome(localStorage.getItem('endttimehome'));
            }else{
              this.endDuration = FilterHelper.defaultEndDurationUrl(this.searchCriteria.currdate);
            }
             if(searchType=='event') {
             let endTime=localStorage.getItem('endtime')
             this.endDuration = FilterHelper.defaultEndDurationUrlEvent(endTime,this.searchCriteria.currdate);
            } 
           if(searchType=='widget') {
             let endTime=localStorage.getItem('endtime')
             this.endDuration = FilterHelper.defaultEndDurationUrlWidget(endTime,this.searchCriteria.currdate);
           }
           if(this.endDuration<this.startDuration) 
           {
            this.endDuration = FilterHelper.defaultEndDurationUrlChnge(this.searchCriteria.currdate);
           }
           
          }
          else{
            //alert('else')
            let timeZoneOffset = Intl.DateTimeFormat().resolvedOptions().timeZone
            this.monthlyDate = this.startDuration = FilterHelper.defaultStartDurationUrlForSafariHome(this.searchCriteria.currdate);
             this.endDuration = FilterHelper.defaultEndDurationUrlForSafari(this.searchCriteria.currdate);
             if(localStorage.getItem('endttimehome'))
            {
              this.endDuration = FilterHelper.defaultEndDurationUrlHomeSfari(localStorage.getItem('endttimehome'));
            }else{
              this.endDuration = FilterHelper.defaultEndDurationUrlForSafari(this.searchCriteria.currdate);
            }
             if(searchType=='event') {
             this.monthlyDate = this.startDuration = FilterHelper.defaultStartDurationUrlForSafariEvents(this.searchCriteria.currdate);
             let endTime=localStorage.getItem('endtime')
             this.endDuration = FilterHelper.defaultEndDurationUrlEventSafari(endTime,this.searchCriteria.currdate);
             }
             if(searchType=='widget') {
             let endTime=localStorage.getItem('endtime')
             this.endDuration = FilterHelper.defaultEndDurationUrlWidgetSafari(endTime,this.searchCriteria.currdate);
           } 
           if(this.endDuration<this.startDuration) 
           {
            this.endDuration = FilterHelper.defaultEndDurationUrlForSafarichange(this.searchCriteria.currdate);
           }
           }
          //this.activateSearchType(2);
          this.startTime = FilterHelper.getFormattedTime(this.startDuration);
          this.endTime = FilterHelper.getFormattedTime(this.endDuration);
          this.updateStartDuration()
        }
      });
    }

    initializeNeighborhoodsFilters() {
     // alert('initializeNeighborhoodsFilters')
      // Do Something When Page is Neighborhoods
      this.startDuration = FilterHelper.defaultStartDuration();
      this.endDuration = FilterHelper.defaultEndDuration();

      this.startTime = FilterHelper.getFormattedTime(this.startDuration);
      this.endTime = FilterHelper.getFormattedTime(this.endDuration);
    }

    initializePaymentFilters() {
      this.deviceInfo = this.deviceService_new.getDeviceInfo();
      this.activatedRouteObj.params.subscribe((params: Params) => {
        if (params['facId'] && params['facType'] && params['facPay']) {
          let bookingDuration = params['facType'].split('&&');
          let startDuration = bookingDuration[0].split('&');
          let endDuration = bookingDuration[1].split('&');

          let startDate = startDuration[0];
          let startTime = FilterHelper.displayableTimeformat(startDuration[1]);
          let endDate = endDuration[0];
          let endTime = FilterHelper.displayableTimeformat(endDuration[1]);

          this.startTime = startDuration[1];
          this.endTime = endDuration[1];
          this.startDuration = FilterHelper.formatPaymentInitDate(startDate);
          this.endDuration = FilterHelper.formatPaymentInitDate(endDate);

          this.startDuration = FilterHelper.makeDurationDateObject(this.startDuration, startTime);
          let currentDate = FilterHelper.changeNyTime()
          //alert(currentDate);
          if(this.searchCriteria.type=='monthly')
          {
            this.show_strip_search=false;
          }else{
            this.show_strip_search=true;
          }
          currentDate.setHours(currentDate.getHours() - 1);
          if(this.startDuration<currentDate)
          {
            if(this.deviceInfo.browser!=='safari')
            {
              this.searchCriteria.currdate=FilterHelper.defaultStartDuration();
            //  alert(this.searchCriteria.currdate);
              this.startDuration = FilterHelper.defaultStartDurationUrlAfterNewHomePage(this.searchCriteria.currdate);
              this.startTime = FilterHelper.getFormattedTime(this.startDuration);
            }else{
              /* added by anshul for day time saving */
              this.searchCriteria.currdate=FilterHelper.defaultStartDuration();
              this.startDuration = FilterHelper.defaultStartDurationUrlForSafariHome(this.searchCriteria.currdate);
              this.startTime = FilterHelper.getFormattedTime(this.startDuration);
              
            }
          }
          //alert(this.startDuration);
          this.endDuration = FilterHelper.makeDurationDateObject(this.endDuration, endTime);
          if(this.endDuration<=this.startDuration)
          {
            
            this.endDuration = FilterHelper.makeDurationDateObjectSort(this.startDuration, startTime);
            this.endTime = FilterHelper.getFormattedTime(this.endDuration);
          }
          
          $('#startTime').val(this.startTime);
          
          $('#endTime').val(this.endTime);

          this.debugDates();

          setTimeout(() => {
            // this.searchCriteria.type = FilterHelper.getCurrentActiveSearchType();
            // this.searchCriteria.currdate = FilterHelper.getArrivalTime(this.startDuration, this.startTime);
            // this.searchCriteria.length_of_stay = this.calculateLengthOfStay();
            // this.firstLoad = true;
            // this.updateFilters.emit(this.searchCriteria);
            this.invokeFilterExecution();
            if(window.self!==window.top){this.isFrame = true;}
          }, 300);
          
        }
      });
    }

    initializeFacilityFilters() {
      //alert('initializeFacilityFilters');
      this.startDuration = FilterHelper.defaultStartDuration();
      this.endDuration = FilterHelper.defaultEndDuration();
      this.startTime = FilterHelper.getFormattedTime(this.startDuration);
      this.endTime = FilterHelper.getFormattedTime(this.endDuration);
      setTimeout(() => {
        this.invokeFilterExecution();
      }, 300);
    }

    initializePartnerFilters() {
      this.startDuration = FilterHelper.defaultStartDuration();
      this.endDuration = FilterHelper.defaultEndDuration();
      this.startTime = FilterHelper.getFormattedTime(this.startDuration);
      this.endTime = FilterHelper.getFormattedTime(this.endDuration);
      setTimeout(() => {
        this.invokeFilterExecution();
      }, 300);
    }

    activateSearchType() {
      if(this.router.url.indexOf('search') > -1 && this.router.url.indexOf('monthly')>=1)
      {
        this.searchCriteria.type='monthly'
      }
      if (this.searchCriteria.type == 'daily') {
        FilterHelper.activateDailySearchFilter();
      } else if(this.searchCriteria.type == 'monthly') {
        FilterHelper.activateMonthlySearchFilter();
      } else {
        FilterHelper.activateDailySearchFilter();
      }
    }

    debugDates() {
        
    }

    calculateLengthOfStay() {
      
      let bookingHours:any = FilterHelper.getBookingHours(this.startDuration, this.startTime, this.endDuration, this.endTime);
      console.log("calculateLengthOfStay",bookingHours,this.startDuration, this.startTime, this.endDuration, this.endTime)
      //alert(bookingHours)
      return bookingHours;
    }
    updateenddate(){
      //alert('updateenddate');
      setTimeout(() => {     
          this.endDuration = this.startDuration;   
          if (this.calculateLengthOfStay() < 2) {
         
            this.endDuration = new Date(this.startDuration.getTime());
            this.endDuration.setMinutes(this.endDuration.getMinutes() + 120);
            
            this.endTime = FilterHelper.getFormattedTime(this.endDuration);
            
            $('#endTime').val(this.endTime);
          }   
          if(this.endDuration>this.maxEndDate){
            this.maxEndDate=this.endDuration;
          }
          
          //alert(this.endDuration);
          //alert(this.maxEndDate);

      }, 300); 
      //this.endDuration = new Date(this.startDuration.getTime());
    }
    updateStartDuration() {
      //alert('updateStartDuration')
      if(this.router.url.indexOf('payment')>-1)
        {
        
          // let p= $('#enddate').val();
          // this.endDuration=new Date(p)
          this.endTime=$('#endTime').val();
        }
        //this.maxEndDate.setMonth(this.startDuration.getMonth());
        this.maxEndDate.setDate(this.startDuration.getDate() + 30);
        this.maxEndDate=FilterHelper.defaultEndstartDate_new(this.startDuration);
        // if(this.maxEndDate>FilterHelper.defaultEndstartDate_new(this.startDuration)){
        //   alert('h');
        //   this.maxEndDate=FilterHelper.defaultEndstartDate_new(this.startDuration);
        // } 
        if(this.maxEndDate>FilterHelper.defaultEndstartDate()){
          this.maxEndDate=FilterHelper.defaultEndstartDate();
        }
        setTimeout(() => {
         
          if(!localStorage.getItem('endttimehome'))
          {  //alert(localStorage.getItem('endttimehome'))
            this.endDuration = this.startDuration;  
          } 
           
        }, 300); 
        //alert(this.maxEndDate); 
     if(!sessionStorage.getItem('openByClickNeigh'))
     {
     //alert('called'); 
    //  this.endMaxDuration = new Date(this.startDuration);
    //  this.endMaxDuration.setMonth(this.endMaxDuration.getMonth()+1);
    //  this.endMaxDuration.setDate(this.endMaxDuration.getDate()-1);
    //  this.maxEndDate=this.endMaxDuration
    // let x=new Date(this.startDuration.getTime());
    // let y=new Date(this.startDuration.getTime());
    // this.maxEndDate.setMonth(x.getMonth()+1);
    // this.maxEndDate.setDate(y.getDate()-1);
    // if(this.maxEndDate>FilterHelper.defaultEndstartDate()){
    //   this.maxEndDate=FilterHelper.defaultEndstartDate();
    // } 

    

     
      if (this.firstLoad === false) {
        
        if(localStorage.getItem('searchType'))
        {

          this.invokeFilterExecution();
        }
        if(localStorage.getItem('endttimehome'))
        {
          this.invokeFilterExecution();
        }
        
        return;
      } else {
        let  rest = this.startDuration.toString().substring(0, this.startDuration.toString().lastIndexOf(":") + 1);
        let  last = this.startDuration.toString().substring(this.startDuration.toString().lastIndexOf(":") + 1, this.startDuration.toString().length);
        let  replaceRecord = rest.substring(0, rest.length - 6) +" "+this.startTime;
        this.startDuration = replaceRecord+""+last;
        this.startDuration=new Date(this.startDuration);
        let startDuration = FilterHelper.makeDurationDateObject(this.startDuration, this.startTime);
        if(startDuration<FilterHelper.changeNyTime())
        {
          this.toastr.error(FilterHelper.PAST_TIME_ERROR_MESSAGE, FilterHelper.MESSAGE_TITLE);
          //his.startTime = FilterHelper.getFormattedTime(FilterHelper.changeNyTime());
        }
        if (startDuration < this.minDate) {
          // if (this.calculateLengthOfStay() < 0.5) {
            this.startTime = FilterHelper.getFormattedTime(FilterHelper.defaultStartDuration());
            $('#startTime').val(this.startTime);
            if (this.calculateLengthOfStay() < 2) {
              this.toastr.error(FilterHelper.PAST_TIME_ERROR_MESSAGE, FilterHelper.MESSAGE_TITLE);
            }
        }
        //alert("1 "+this.calculateLengthOfStay())
        console.log("from updatestartDuration")
        if (this.calculateLengthOfStay() < 2) {
          this.startDatechangedflag=true;
          //alert('1')
          //alert('startDuration'+this.startDuration)
          this.endDuration = new Date(this.startDuration.getTime());
          
          this.endDuration.setMinutes(this.endDuration.getMinutes() + 120);
          if(this.endDuration>this.maxEndDate){
            this.maxEndDate=this.endDuration;
          }
          //this.endDuration = new Date(this.startDuration.getTime());
          //alert('endDuration'+this.endDuration+this.maxEndDate)
          this.endTime = FilterHelper.getFormattedTime(this.endDuration);
          
          $('#endTime').val(this.endTime);
        }
        //alert(this.startDuration+ this.startTime+ this.endDuration+ this.endTime)
       // alert(this.calculateLengthOfStay())
        //alert(this.maxEndDate);
        this.invokeFilterExecution();
      }
    }
    }

    updateStartTime() {
     // alert('updateStartTime');
     
      if(!sessionStorage.getItem('openByClickNeigh'))
     {
      if (this.firstLoad === false) {

        return;

      } else {
        this.debugDates();
        if(this.router.url.indexOf('payment')>-1)
        {
          //let p= $('#enddate').val();
          //alert(p)
          //this.endDuration=new Date(p)
          
          this.endTime=$('#endTime').val();
        }
        let startDuration = FilterHelper.makeDurationDateObject(this.startDuration, this.startTime);
        //alert(startDuration)
 
        if (startDuration < this.minDate) {
          this.startTime = FilterHelper.getFormattedTime(FilterHelper.defaultStartDuration());
          $('#startTime').val(this.startTime);
          this.toastr.error(FilterHelper.PAST_TIME_ERROR_MESSAGE, FilterHelper.MESSAGE_TITLE);
        } else {
          //alert(this.calculateLengthOfStay())
          console.log("from updatestartTime")
          if (this.calculateLengthOfStay() < 2) {
            setTimeout(() => {
              this.endDuration = new Date(startDuration);
              this.endDuration.setMinutes(this.endDuration.getMinutes() + 120);
              
              if(this.endDuration>this.maxEndDate){
                this.maxEndDate=this.endDuration;
                this.startDatechangedflag=true;
              }
             // alert(this.endDuration)
              let p= $('#enddate').val();
              if(!(window.navigator.userAgent.toLowerCase().indexOf('firefox') > -1)){
                this.endDuration=new Date(p)
              }
              if(window.navigator.userAgent.toLowerCase().indexOf('firefox') > -1){
                let test=new Date(this.endDuration);
                setTimeout(() => {
                  //alert(this.maxEndDate)
                  this.endDuration=test;
                 
                }, 300);
                
              }
          
              this.endTime = FilterHelper.getFormattedTime(this.endDuration);
              
              $('#endTime').val(this.endTime); 
              if(this.router.url.indexOf('payment')>-1)
              {
                if (this.calculateLengthOfStay() < 2) {
                   this.endDuration = new Date(startDuration);
                   this.endDuration.setMinutes(this.endDuration.getMinutes() + 120);
                   if(this.endDuration>this.maxEndDate){
                    this.maxEndDate=this.endDuration;
                   }
                   this.endTime = FilterHelper.getFormattedTime(this.endDuration);
                   $('#endTime').val(this.endTime); 
                }
              }
             
              
            }, 300);
            
            //alert(this.endDuration);
          }
        }
        this.invokeFilterExecution();
      }
    }
    }

    updateEndDuration() {
      
      if(!sessionStorage.getItem('openByClickNeigh'))
      {
      if (this.firstLoad === false) {
        return;
      } else {
        this.debugDates();
        //alert('updateEndDuration'+this.endDuration)
        if(this.router.url.indexOf('payment')>-1)
        {
          //alert(this.endDuration)
          //this code is used for payment page for last date 11:30 PM enduration updattion to next date. ie 91th day
          let p= $('#enddate').val();
          this.endDuration=new Date(p)
          this.endTime=$('#endTime').val();
        }
        // if(this.router.url.indexOf('search')>-1)
        // {
        //   //alert('1')
        //   setTimeout(() => {
        //     let mon=this.endDuration.getMonth()+1;
        //   let dt=this.endDuration.getDate();
        //   if(mon<10){
        //     mon="0"+mon;
        //   }
        //   if(dt<10){
        //     dt="0"+dt;
        //   }
        //   this.endDuration=new Date(mon+"/"+dt+"/"+this.endDuration.getFullYear())
        //   alert(this.endDuration)
        //   }, 400);
          
        // }
        //alert("2 "+this.calculateLengthOfStay())
        console.log("from updateEndDuration")
        if (this.calculateLengthOfStay() < 2) {
          
          let startDuration = FilterHelper.makeDurationDateObject(this.startDuration, this.startTime);
          this.endDuration = new Date(startDuration);
          this.endDuration.setMinutes(this.endDuration.getMinutes() + 120);
          if(this.endDuration>this.maxEndDate){
            this.maxEndDate=this.endDuration;
            
          }
          this.endTime = FilterHelper.getFormattedTime(this.endDuration);
          
          $('#endTime').val(this.endTime);
          //alert($('#startTime').val())
          //alert($('#endTime').val())
          if(this.router.url.indexOf('monthly')<0&&this.startDatechangedflag==false){
            //alert('1')
            this.toastr.error(FilterHelper.TWO_HOUR_ERROR_MESSAGE, FilterHelper.MESSAGE_TITLE);
          }
          if(this.router.url.indexOf('monthly-parking')>-1&&this.router.url.indexOf('daily')>-1&&this.startDatechangedflag==false){
            //alert('1')
            this.toastr.error(FilterHelper.TWO_HOUR_ERROR_MESSAGE, FilterHelper.MESSAGE_TITLE);
          }
          
          
        }
        this.startDatechangedflag=false;
        if(this.startDuration>this.endDuration){
          if (this.calculateLengthOfStay() < 2) {
            let startDuration = FilterHelper.makeDurationDateObject(this.startDuration, this.startTime);
            this.endDuration = new Date(startDuration);
            this.endDuration.setMinutes(this.endDuration.getMinutes() + 120);
            if(this.endDuration>this.maxEndDate){
              this.maxEndDate=this.endDuration;
            }
            this.endTime = FilterHelper.getFormattedTime(this.endDuration);
            
            $('#endTime').val(this.endTime);
            //alert($('#startTime').val())
            //alert($('#endTime').val())
            if(this.router.url.indexOf('monthly')<0){
              //alert('1')
              this.toastr.error(FilterHelper.TWO_HOUR_ERROR_MESSAGE, FilterHelper.MESSAGE_TITLE);
            }
            if(this.router.url.indexOf('monthly-parking')>-1&&this.router.url.indexOf('daily')>-1){
              //alert('1')
              this.toastr.error(FilterHelper.TWO_HOUR_ERROR_MESSAGE, FilterHelper.MESSAGE_TITLE);
            }
            
          }
        }
        
      }
      this.invokeFilterExecution();
    }
    }

    updateEndTime() {
      //alert(333333333)
      if(!sessionStorage.getItem('openByClickNeigh'))
     {
       //alert(3333)
      if (this.firstLoad === false) {
       // alert(2323232323)
        return;
      } else {
        this.debugDates();
        if(this.router.url.indexOf('payment')>-1)
        {
          // let p= $('#enddate').val();
          // this.endDuration=new Date(p)
          this.endTime=$('#endTime').val();
        }
        let endDuration = FilterHelper.makeDurationDateObject(this.endDuration, this.endTime);
        console.log("from updateEndTime")
        if (this.calculateLengthOfStay() < 2) {
          let startDuration = FilterHelper.makeDurationDateObject(this.startDuration, this.startTime);
          this.endDuration = new Date(startDuration);
          
          this.endDuration.setMinutes(this.endDuration.getMinutes() + 120);
          let tempendduration=this.endDuration;
          if(this.endDuration>this.maxEndDate){
            this.maxEndDate=this.endDuration;
          }
          this.endTime = FilterHelper.getFormattedTime(this.endDuration);
         // alert(tempendduration);
          setTimeout(()=>{
            
            //$('#enddate').val(tempendduration);
            //this.endDuration=startDuration;
            this.endDuration=new Date(tempendduration);
          },300);
          
          $('#endTime').val(this.endTime);
          
          this.toastr.error(FilterHelper.TWO_HOUR_ERROR_MESSAGE, FilterHelper.MESSAGE_TITLE);
        } 
        
        if (endDuration < this.endDuration) {
          if (this.calculateLengthOfStay() < 2) {
            let startDuration = FilterHelper.makeDurationDateObject(this.startDuration, this.startTime);
            this.endDuration = new Date(startDuration);
            this.endDuration.setMinutes(this.endDuration.getMinutes() + 120);
            if(this.endDuration>this.maxEndDate){
            this.maxEndDate=this.endDuration;
          }
            this.endTime = FilterHelper.getFormattedTime(this.endDuration);
            $('#endTime').val(this.endTime);
            //alert('2')
            this.toastr.error(FilterHelper.TWO_HOUR_ERROR_MESSAGE, FilterHelper.MESSAGE_TITLE);
          } 
        }
        this.invokeFilterExecution();
      }
    }
    }
    invokeFilterExecution() {
      // alert(this.startTime)
      this.searchCriteria.dats=this.startDuration
      this.searchCriteria.strattimes=this.startTime
      this.searchCriteria.enddats=this.endDuration
      this.searchCriteria.endtimes=this.endTime
      this.searchCriteria.type = FilterHelper.getCurrentActiveSearchType();
      this.searchCriteria.currdate = FilterHelper.getArrivalTime(this.startDuration, this.startTime);
      //alert(this.searchCriteria.type);
      if(this.router.url.indexOf('monthly')>-1){
        this.activatedRouteObj.params.subscribe((params: Params) => {
          let tstartdur=FilterHelper.defaultStartDurationUrlAfterNewHomePage(params['currdate']);
          //alert(FilterHelper.getArrivalTime(tstartdur, this.startTime))
          this.searchCriteria.currdate=FilterHelper.getArrivalTime(tstartdur, this.startTime);
        });
      }
      this.searchCriteria.length_of_stay = this.calculateLengthOfStay();
      this.firstLoad = true;
      this.updateFilters.emit(this.searchCriteria);
    }
 
    
    updateRecordsOnLabelChangeonLoad(val) {
      
      if(val=='monthly')
      {
        // this.dealsBtn = true;
        this.monthlyFilter = true;
        this.dailyFilter = false;
        this.show_strip_search=false;
      }else
      {
        // this.dealsBtn = false;
        this.monthlyFilter =false;
        this.dailyFilter = true;
        this.show_strip_search=true;
      }
      if(val=='daily')
      {
        this.dailyFilter = true;
        this.show_strip_search=false;
      }else
      {
        this.show_strip_search=true;
      }
      this.changeDetectorRef.detectChanges();
      //this.searchDate
    }
    updateRecordsOnLabelChangeMobile(val) {
      localStorage.removeItem('mapDrag');
      localStorage.setItem('searchType',val);
      if(localStorage.getItem('showNeighbordood')){
        localStorage.removeItem('showNeighbordood');
      }
      // console.log('removed')
      // if (this.router.url.indexOf('facility') > -1) { 
      //   return false;
      // }
     this.typeOfSearch=val
     this.changeOnClickType=val;
     //sessionStorage.setItem('openByClick','1');
     if(val=='monthly')
      {
        if(this.router.url.indexOf('facility') > 0 ){
          $('.dailymonthlybuttonsMobile').removeClass('active');
          $('#monthly').addClass('active');
          $('#dailyFilter').addClass('hidden');
          $('#monthlyFilter').removeClass('hidden');
        }
        this.dailyFilters = false;
        this.dailyFilter = false;
        this.monthlyFilter=true;
      } else {
        if(this.router.url.indexOf('facility') > 0 ){
          $('.dailymonthlybuttonsMobile').removeClass('active');
          $('#daily').addClass('active');
          $('#monthlylyFilter').addClass('hidden');
          $('#dailyFilter').removeClass('hidden');
        }
        this.dailyFilters = true;
        this.dailyFilter = true;
        this.monthlyFilter=false;
      }
      this.changeDetectorRef.detectChanges();
    }
    searchFacilityOnSearch()
    {
     localStorage.removeItem('mapDrag');
      this.typeOfSearchDate=this.changeOnClickType
      if (this.router.url.indexOf('neighborhoods') > 0) { 
        $('#closeButton').trigger('click')
        $('#closeButton1').trigger('click')
        sessionStorage.removeItem('openByClickNeigh')
        this.updateStartDuration();
      }if (this.router.url.indexOf('facility') > -1) { 
        $('#closeButton').trigger('click')
        $('#closeButton1').trigger('click')
        this.updateStartDuration();
      }else{
        sessionStorage.removeItem('openByClick')
        let device=this.searchCriteria.device 
        let lat=  this.searchCriteria.lat
        let long = this.searchCriteria.long
        let time= this.urlDate
        let label=this.searchCriteria.label
        if(localStorage.getItem('latNew')){ lat =  localStorage.getItem('latNew') }
        if(localStorage.getItem('longNew')){ long =  localStorage.getItem('longNew') }
        if(localStorage.getItem('locationNew')){ label =  localStorage.getItem('locationNew') }

        this.location.replaceState('/search/'+lat+'/'+long+'/'+time+'/2/'+label+'/'+this.typeOfSearch+'/'+device); 
        sessionStorage.setItem('searchTypeByMobile',this.typeOfSearch)
        $('#startDateWithID').val(this.startDuration)
      $('#endDateWithID').val(this.endDuration)
      $('#startTimeWithID').val(this.startTime)
      $('#endTimeWithID').val(this.endTime)
       this.invokeFilterExecutionMobile(this.typeOfSearch);
        $('#closeButton').trigger('click')
        $('#closeButton1').trigger('click')
        if(this.typeOfSearch == 'monthly'){  
          // this.dealsBtn = true;
        }
      }
      
    }
    invokeFilterExecutionMobile(typeOfSearch) {
      this.searchCriteria.type = FilterHelper.getCurrentActiveSearchType();
      if(typeOfSearch=='monthly')
      {
        this.searchCriteria.currdate = FilterHelper.getArrivalTime(this.monthlyDate, this.startTime);
        $('#dailyFilter').addClass('hidden');
				$('#monthlyFilter').removeClass('hidden');
        $('body').addClass('showChat');
					// let times_run= setInterval(function () {
					// 	$('body').addClass('showChat');
					// }, 4000);
					// setTimeout(function () {
					// 	clearInterval(times_run);
					// }, 10000);
      }else{
        this.searchCriteria.currdate = FilterHelper.getArrivalTime(this.startDuration, this.startTime);
        $('body').removeClass('showChat');
        // let times_runhide= setInterval(function () {
        //   $('body').removeClass('showChat');
        // }, 100);
        // setTimeout(function () {
        //   clearInterval(times_runhide);
        // }, 7000);
      }
      if(localStorage.getItem('latNew')){ this.searchCriteria.lat =  localStorage.getItem('latNew') }
        if(localStorage.getItem('longNew')){ this.searchCriteria.long =  localStorage.getItem('longNew') }
        if(localStorage.getItem('locationNew')){ this.searchCriteria.label =  localStorage.getItem('locationNew') }
      
      this.searchCriteria.length_of_stay = this.calculateLengthOfStay();
      this.firstLoad = true;
      ////1111111111111111
      this.updateFiltersMobile.emit(this.searchCriteria);

      
    }
    updateRecordsOnLabelChange(val) {
      localStorage.setItem('searchType',val);
      if(localStorage.getItem('showNeighbordood')){
        //localStorage.removeItem('showNeighbordood');
      }
      //alert(3333331111)
      this.typeOfSearch=val
      if(this.router.url.indexOf('partners') > 0 && val=='monthly')
      {
        
        return false
      }
      if(val=='monthly')
      {
        if(this.router.url.indexOf('facility') > 0 ){
          $('.dailymonthlybuttons').removeClass('active');
          $('#monthly').addClass('active');
          $('#dailyFilter').addClass('hidden');
          $('#monthlyFilter').removeClass('hidden');
        }
        this.monthlyFilter = true;
        this.dailyFilter = false;
        this.show_strip_search=false;
      }else
      {
        this.monthlyFilter =false;
        this.dailyFilter = true;
        this.show_strip_search=true;
      }
      if(val=='daily')
      { 
        if(this.router.url.indexOf('facility') > 0 ){
        $('.dailymonthlybuttons').removeClass('active');
        $('#daily').addClass('active');
        $('#dailyFilter').removeClass('hidden');
        $('#monthlyFilter').addClass('hidden');
      }
        this.dailyFilter = true;
        this.show_strip_search=false;
      } else
      {
        this.show_strip_search=true;
      }
      //alert(this.router.url.indexOf('search'))
      if (this.router.url.indexOf('search') > -1) { 
        this.isSearch = true;
        this.updateSearchTypeModule(val);
        //alert(5555555555)
        //alert('3')
        this.searchDate()
      }
      if (this.router.url.indexOf('neighborhoods') > 0 || this.router.url.indexOf('monthly-parking') > 0) { 
        this.isNeighborhoods = true;
       // alert(2)
        this.updateNeighborhoodTypeModule(val);
      }
      if (this.router.url.indexOf('payment') > 0) {
        this.isPayment = true;
        this.updatePaymentTypeModule(val);
      }
      if (this.router.url.indexOf('partners') > 0) {
        this.isPartner = true;
        this.updatePartnerTypeModule(val);
      }
      let xyz='monthly';
      this.searchtypedata.emit(val);
    }
    submitFilter(){
      if(this.applyfilterform.value.checkboxFilter.length==0){ //searhc all
        this.tryPrintScaleValue();
      }else{
        this.searchVehicleParking(this.applyfilterform.value.checkboxFilter);
       
      }

      //var mystr = this.applyfilterform.value.checkboxFilter;     

    }
    searchVehicleParking(searchData:string[]){
      var object = this.searchCriteria;
      localStorage.removeItem('mapDrag');
      this.searchFilterAllValues.forEach((item, index)=>{
       // console.log(item);
        if(searchData.indexOf(item) >=0 ){
           
            if(item=='is_deal')
            {
            object['monthly_campaign'] =true
            localStorage.setItem('showMonthlyDeal', '1')
            }else{
            object[item] = true;
            }         
          }else{
            if(item=='is_deal'){
            localStorage.removeItem('showMonthlyDeal');
            object['monthly_campaign'] =false;
            }else{
            object[item] = false;
            }

          };
       
       
        if(this.searchCriteria.is_monthlypark || this.searchCriteria.monthly_campaign || this.searchCriteria.is_indoor_parking || this.searchCriteria.is_outdoor_parking
         || this.searchCriteria.is_tesla_charging || this.searchCriteria.is_generic_ev_charging || this.searchCriteria.is_oversized
         || this.searchCriteria.is_motorcycle_parking ||  this.searchCriteria.is_monthlypark)
         {
           localStorage.setItem('filterApply','1')
         }else{
           //localStorage.removeItem('filterApply','1')
         }
         if(this.searchCriteria.monthly_campaign)
           {
             localStorage.setItem('filterApplyDeal','1')
           }
           if(this.searchCriteria.monthly_campaign && this.router.url.indexOf('/monthly-parking') >-1 && localStorage.getItem('specialParking' ) ){
             localStorage.setItem('enableDealsForNb', '1');
           }else {
             localStorage.removeItem('enableDealsForNb');
           }      
 
      });
      this.searchCriteria= object;
         console.log(this.searchCriteria);
      this.updateFacilityFilters.emit(this.searchCriteria);
        
    }
    tryPrintScaleValue() {
      //alert(1)
      localStorage.removeItem('mapDrag');
     if(this.monthlypark)
     this.searchCriteria.is_monthlypark =this.monthlypark
     else
     this.searchCriteria.is_monthlypark =false
     if(this.motorcycle)
     this.searchCriteria.is_motorcycle_parking =this.motorcycle
     else
     this.searchCriteria.is_motorcycle_parking =false
     if(this.oversized)
     this.searchCriteria.is_oversized =this.oversized
     else
     this.searchCriteria.is_oversized =false
     if(this.evcharging)
     this.searchCriteria.is_generic_ev_charging =this.evcharging
     else
     this.searchCriteria.is_generic_ev_charging =false
     if(this.tesla)
     this.searchCriteria.is_tesla_charging =this.tesla
     else
     this.searchCriteria.is_tesla_charging =false
     if(this.outdoor)
     this.searchCriteria.is_outdoor_parking = this.outdoor
     else
     this.searchCriteria.is_outdoor_parking = false
     if(this.indoor)
     this.searchCriteria.is_indoor_parking =this.indoor
     else
     this.searchCriteria.is_indoor_parking =false
     if(this.twentyhour)
     this.searchCriteria.is_twentyhour =this.twentyhour
     else
     this.searchCriteria.is_twentyhour =false
     //alert(this.is_deal)
     if(this.is_deal)
     {
      this.searchCriteria.monthly_campaign =true
      localStorage.setItem('showMonthlyDeal', '1')
      console.log('showMonthlyDeal',localStorage.getItem('showMonthlyDeal'))
     }
     else{
      localStorage.removeItem('showMonthlyDeal');
      this.searchCriteria.monthly_campaign =false;
      console.log('showMonthlyDeal',localStorage.getItem('showMonthlyDeal'))
     }
    
     if(this.searchCriteria.is_monthlypark || this.searchCriteria.monthly_campaign || this.searchCriteria.is_indoor_parking || this.searchCriteria.is_outdoor_parking
      || this.searchCriteria.is_tesla_charging || this.searchCriteria.is_generic_ev_charging || this.searchCriteria.is_oversized
      || this.searchCriteria.is_motorcycle_parking ||  this.searchCriteria.is_monthlypark)
      {
        localStorage.setItem('filterApply','1')
      }else{
        //localStorage.removeItem('filterApply','1')
      }
      if(this.searchCriteria.monthly_campaign)
        {
          localStorage.setItem('filterApplyDeal','1')
        }
        if(this.searchCriteria.monthly_campaign && this.router.url.indexOf('/monthly-parking') >-1 && localStorage.getItem('specialParking' ) ){
          localStorage.setItem('enableDealsForNb', '1');
        }else {
          localStorage.removeItem('enableDealsForNb');
        }
     this.updateFacilityFilters.emit(this.searchCriteria);
    }
    check()
    {
      //alert(this.dealstoggle)
      this.is_deal=this.dealstoggle;
      this.tryPrintScaleValue();
    }
    getMonthlyDate() {
      this.searchCriteria.type = "monthly";
      //this.monthlyDate = this.startDuration
      this.startDurationMonthly=this.monthlyDate;
      let currDate = FilterHelper.formatDate(this.monthlyDate) + ' ' + this.startTime;
      this.searchCriteria.currdate = currDate.toLocaleString().replace(/[^ -~]/g,'');
      //alert(this.searchCriteria.currdate)
      if (this.router.url.indexOf('monthly') > 0 || (this.router.url.indexOf('monthly-parking') > 0) ) { 
        this.searchCriteria.type = "monthly";
      }else{
        this.searchCriteria.type = "daily";
      }
      if( this.router.url.indexOf('daily') >0 ){
        this.searchCriteria.type = "daily";
      }

      if( this.router.url.indexOf('payment') > -1 ){

      }
      else if(this.router.url.indexOf('monthly-parking') > -1){

      }
      else{
        this.updateFilters.emit(this.searchCriteria);
      }
    }
    // getNeighbourDetails(){
    //   this.searchCriteria.type = "daily";
    //   //this.monthlyDate = this.startDuration
    //   this.startDurationMonthly=this.monthlyDate;
    //   let currDate = FilterHelper.formatDate(this.monthlyDate) + ' ' + this.startTime;
    //   this.searchCriteria.currdate = currDate.toLocaleString().replace(/[^ -~]/g,'');
    //   this.updateFilters.emit(this.searchCriteria);
    // }
    getNeighbourDetails(){
      if (this.router.url.indexOf('monthly') > 0 || (this.router.url.indexOf('monthly-parking') > 0) ) { 
        this.searchCriteria.type = "monthly";
      }else{
        this.searchCriteria.type = "daily";
      }
      if( this.router.url.indexOf('daily') >0 ){
        this.searchCriteria.type = "daily";
      }
      //this.searchCriteria.type = "daily";
      //this.monthlyDate = this.startDuration
      //alert(this.monthlyDate);
      
      this.a = this.startDurationMonthly=this.monthlyDate;
      this.monthlyDate=this.a;
    //  this.c = setInterval(()=>{
    //     this.monthlyDate=this.a;
    //   },100)
      let currDate = FilterHelper.formatDate(this.monthlyDate) + ' ' + this.startTime;
      this.searchCriteria.currdate = currDate.toLocaleString().replace(/[^ -~]/g,'');
      this.updateFilters.emit(this.searchCriteria);
    }
    getMonthlyDateMobile()
    {
      this.searchCriteria.type = "monthly";
      //this.monthlyDate = this.startDuration
      let currDate = FilterHelper.formatDate(this.monthlyDate) + ' ' + this.startTime;
      this.searchCriteria.currdate = currDate.toLocaleString().replace(/[^ -~]/g,'');
      //this.updateFilters.emit(this.searchCriteria);
    }
    updateEndDurationMobile() {
      sessionStorage.setItem('openByClick','1');
      if (this.router.url.indexOf('neighborhoods') > 0) { 
        sessionStorage.setItem('openByClickNeigh','1');
      }
      if (this.firstLoad === false) {
        return;
      } else {
        this.debugDates();
        if (this.calculateLengthOfStay() < 2) {
          let startDuration = FilterHelper.makeDurationDateObject(this.startDuration, this.startTime);
          this.endDuration = new Date(startDuration);
          this.endDuration.setMinutes(this.endDuration.getMinutes() + 120);
          this.endTime = FilterHelper.getFormattedTime(this.endDuration);
          
          $('#endTime_mob').val(this.endTime);
          //alert('3')
          this.toastr.error(FilterHelper.TWO_HOUR_ERROR_MESSAGE, FilterHelper.MESSAGE_TITLE);
        }
      }
      //this.invokeFilterExecution();
    }
    toTimestamp(strDate){
      var datum = Date.parse(strDate);
      return datum/1000;
   }
    updateStartDurationMobile() {
     // alert(this.maxEndDate);
      sessionStorage.setItem('openByClick','1');
      if (this.router.url.indexOf('neighborhoods') > 0) { 
        sessionStorage.setItem('openByClickNeigh','1');
      }
    //   this.endMaxDuration = new Date(this.startDuration);
    //   this.endMaxDuration.setMonth(this.endMaxDuration.getMonth()+2);
    //   //this.maxEndDate=this.endMaxDuration
    //   this.endMaxDuration.setMonth(this.endMaxDuration.getMonth()+1);
    //  this.endMaxDuration.setDate(this.endMaxDuration.getDate()-1);
    //  this.maxEndDate=this.endMaxDuration
    // let x=new Date(this.startDuration.getTime());
    // let y=new Date(this.startDuration.getTime());
    // this.maxEndDate.setMonth(x.getMonth()+1);
    // this.maxEndDate.setDate(y.getDate()-1);
    //  //this.endDuration = new Date(this.startDuration.getTime());
    //  if(this.maxEndDate>FilterHelper.defaultEndstartDate()){
    //   this.maxEndDate=FilterHelper.defaultEndstartDate();
    // }
    this.maxEndDate.setMonth(this.startDuration.getMonth());
    this.maxEndDate.setDate(this.startDuration.getDate() + 30);
    if(this.maxEndDate>FilterHelper.defaultEndstartDate_new(this.startDuration)){
      this.maxEndDate=FilterHelper.defaultEndstartDate_new(this.startDuration);
    }
    if(this.maxEndDate>FilterHelper.defaultEndstartDate()){
      this.maxEndDate=FilterHelper.defaultEndstartDate();
    }
    //alert(this.maxEndDate);
       if (this.firstLoad === false) {
         if(localStorage.getItem('searchType'))
         {
           //this.invokeFilterExecution();
         }
         
         return;
       } else {
         let startDuration = FilterHelper.makeDurationDateObject(this.startDuration, this.startTime);
         if (this.toTimestamp(startDuration) < this.toTimestamp(this.minDate)) {
           // if (this.calculateLengthOfStay() < 0.5) {
            this.startTime =FilterHelper.getFormattedTime(FilterHelper.defaultStartDuration());
            $('#startTime_mob').val(this.startTime);
            this.toastr.error(FilterHelper.PAST_TIME_ERROR_MESSAGE, FilterHelper.MESSAGE_TITLE);
         }
         if (this.calculateLengthOfStay() < 2) {
           
           //this.endDuration = new Date(this.startDuration.getTime());
           this.endDuration = new Date(FilterHelper.makeDurationDateObject(this.startDuration, this.startTime));
           this.endDuration.setMinutes(this.endDuration.getMinutes() + 120);
           //alert(this.endDuration)
           this.endTime = FilterHelper.getFormattedTime(this.endDuration);
           if(this.endDuration>this.maxEndDate){
            this.maxEndDate=this.endDuration;
           }
           let temp_endduration=new Date(this.endDuration);
             setTimeout(() => {
              this.endDuration =temp_endduration;
             }, 300);
             
           
           $('#endTime_mob').val(this.endTime);
         }
         
         //alert(this.maxEndDate);
         //this.invokeFilterExecution();
       }
     }
     updateStartTimeMobile() {
      sessionStorage.setItem('openByClick','1');
      if (this.router.url.indexOf('neighborhoods') > 0) { 
        sessionStorage.setItem('openByClickNeigh','1');
      }
      if (this.firstLoad === false) {

        return;

      } else {
        this.debugDates();
        let startDuration = FilterHelper.makeDurationDateObject(this.startDuration, this.startTime);
        if (this.toTimestamp(startDuration) < this.toTimestamp(this.minDate)) {

          this.startTime =FilterHelper.getFormattedTime(FilterHelper.defaultStartDuration());
          $('#startTime_mob').val(this.startTime);
          
          setTimeout(() => {
            //alert($('#startTime_mob').val())
            $('#startTime_mob').val(this.startTime);
          }, 1000);
          //
          this.toastr.error(FilterHelper.PAST_TIME_ERROR_MESSAGE, FilterHelper.MESSAGE_TITLE);
        } else {
          if (this.calculateLengthOfStay() < 2) {
            this.endDuration = new Date(startDuration);
            this.endDuration.setMinutes(this.endDuration.getMinutes() + 120);
            this.endTime = FilterHelper.getFormattedTime(this.endDuration);
            if(this.endDuration>this.maxEndDate){
              this.maxEndDate=this.endDuration;
             }
             let temp_endduration=new Date(this.endDuration);
             setTimeout(() => {
              this.endDuration =temp_endduration;
             }, 300);
             
            $('#endTime_mob').val(this.endTime);
          }
        }
       // this.invokeFilterExecution();
      }
    }
    updateEndTimeMobile() {
      sessionStorage.setItem('openByClick','1');
      if (this.router.url.indexOf('neighborhoods') > 0) { 
        sessionStorage.setItem('openByClickNeigh','1');
      }
      if (this.firstLoad === false) {
        return;
      } else {
        this.debugDates();
        let endDuration = FilterHelper.makeDurationDateObject(this.endDuration, this.endTime);
        if (this.calculateLengthOfStay() < 2) {
            
          let startDuration = FilterHelper.makeDurationDateObject(this.startDuration, this.startTime);
          this.endDuration = new Date(startDuration);
          this.endDuration.setMinutes(this.endDuration.getMinutes() + 120);
          this.endTime = FilterHelper.getFormattedTime(this.endDuration);
          
          $('#endTime_mob').val(this.endTime);
          //alert($('#endTime').val())
          this.toastr.error(FilterHelper.TWO_HOUR_ERROR_MESSAGE, FilterHelper.MESSAGE_TITLE);
       }
        if (endDuration < this.endDuration) {
          if (this.calculateLengthOfStay() < 2) {
            
             let startDuration = FilterHelper.makeDurationDateObject(this.startDuration, this.startTime);
             this.endDuration = new Date(startDuration);
             this.endDuration.setMinutes(this.endDuration.getMinutes() + 120);
             this.endTime = FilterHelper.getFormattedTime(this.endDuration);
             
             $('#endTime_mob').val(this.endTime);
             //alert($('#endTime').val())
             this.toastr.error(FilterHelper.TWO_HOUR_ERROR_MESSAGE, FilterHelper.MESSAGE_TITLE);
          } 
        }
        //this.invokeFilterExecution();
      }
    }
    updateSearchTypeModule(val) {
      let date_after_merge:any='';
      if(this.searchCriteria.currdate.indexOf("-")>0)
      {
        let date_monthly_daily =this.searchCriteria.currdate.replace(/-/g, "/").split('/')
        let date_time=date_monthly_daily[2].split(' ')
        date_after_merge = date_monthly_daily[1]+'/'+date_time[0]+'/'+date_monthly_daily[0]+' '+date_time[1] 
      }else{
        date_after_merge=this.searchCriteria.currdate
      }

       let currdate=CustomCode.getDateForMapSearchSlashFormatForUrl(date_after_merge)
        if (val === 'daily') {
          FilterHelper.activateDailySearchFilter();
         // this.router.navigate(['/search', this.searchCriteria.lat, this.searchCriteria.long, currdate, this.searchCriteria.radius, this.searchCriteria.label, val, CustomCode.searchMode()]);
        } else {
          FilterHelper.activateMonthlySearchFilter();
         // this.router.navigate(['/search', this.searchCriteria.lat, this.searchCriteria.long,currdate, this.searchCriteria.radius, this.searchCriteria.label, val, CustomCode.searchMode()]);
       }
    }

    updateNeighborhoodTypeModule(val) {

        if (val === 'daily') {
          FilterHelper.activateDailySearchFilter();
          this.searchCriteria.type = 'daily';
          this.searchCriteria.currdate = FilterHelper.getArrivalTime(this.startDuration, this.startTime);
          this.updateFiltersNei.emit(this.searchCriteria);
        } else {
          FilterHelper.activateMonthlySearchFilter();
          this.searchCriteria.type = 'monthly';
          this.searchCriteria.currdate = this.monthlyDate=new Date();
          this.updateFiltersNei.emit(this.searchCriteria);
        }
    }

    updatePartnerTypeModule(val) {
        if (val === 'daily') {
          FilterHelper.activateDailySearchFilter();
          this.searchCriteria.type = 'daily';
          this.searchCriteria.currdate = FilterHelper.getArrivalTime(this.startDuration, this.startTime);
          this.updateFilters.emit(this.searchCriteria);
        } else {
          FilterHelper.activateMonthlySearchFilter();
          this.searchCriteria.type = 'monthly';
          this.searchCriteria.currdate = this.monthlyDate;
          this.updateFilters.emit(this.searchCriteria);
        }
    }

    updatePaymentTypeModule(val) {
      // Code Goes Here ...
    }

    public openModal(template) {
      console.log('modal start');
      sessionStorage.removeItem('editTime')
      sessionStorage.setItem('openByClick','1');
      this.modalRef = this.modalService.show(template,this.config);
      console.log('modal end');
    }

    updateTier(showBadge){
      this.updateTierToNeighbour.emit(showBadge);
    }

    showDeals(){
      if(localStorage.getItem('showDeals')){
        localStorage.removeItem('showDeals');
      }else {
        localStorage.setItem('showDeals','1');
      }      
    }
    searchFacility()
    {
      //alert("sssss"+$('#startDateWithID').val());
      
        $('#startDateWithID').val(this.startDuration)
        $('#endDateWithID').val(this.endDuration)
        $('#startTimeWithID').val(this.startTime)
        $('#endTimeWithID').val(this.endTime)
      
      
      this.updateFiltersOnSubmit.emit(this.searchCriteria);
    }
    
    searchDate()
    {
      //alert(this.monthlyDate);
      
      if(this.router.url.indexOf('search') > -1)
      {
      setTimeout(() => {
        if(localStorage.getItem('endttimehome'))
        {
          this.endDuration = FilterHelper.defaultEndDurationUrlHome(localStorage.getItem('endttimehome'));
          this.endTime = FilterHelper.getFormattedTime(this.endDuration);
          $('#endTime').val(this.endTime);
          let temp_endDuration=this.endDuration;
          if(this.endDuration>this.maxEndDate){
            this.maxEndDate =this.endDuration;
           this.endDuration=temp_endDuration;
          }
          
          //alert(this.endDuration);
        }

       // let endTime=CustomCode.getDateForMapSearchSlashFormatHome(this.endDuration,this.endTime)
      //alert(this.endDuration);
     // localStorage.setItem('endttimehome',CustomCode.getDateForMapSearchSlashFormatHome(this.endDuration,this.endTime));
      }, 500);


      }
      


      localStorage.removeItem('mapDrag');
      localStorage.removeItem('filterApply')
    let startDurationUpdated = this.startDuration;
    if(this.typeOfSearch == 'monthly'){
      startDurationUpdated = this.startDurationMonthly;
      localStorage.setItem('dedicatedfacilitymonthlydate',this.startDurationMonthly)
      
    }else {
      startDurationUpdated = this.startDuration;
    }
    //this.setTime(startDurationUpdated);
   
    const autoSuggestInputField= $('#auto_suggest_input_field').val();
      let startTime=CustomCode.getDateForMapSearchSlashFormatHome(startDurationUpdated,this.startTime)
      // let startTime=CustomCode.getDateForMapSearchSlashFormatHome(this.startDuration,this.startTime)
      
      let lat:any =localStorage.getItem('latNew')
      let long:any =localStorage.getItem('longNew')
      let location:any =localStorage.getItem('locationNew') 
      //
      this.searchCriteria.lat=lat
      this.searchCriteria.long=long
      this.searchCriteria.location=location
      // console.log(this.searchCriteria);
      if(lat=='' || lat =='undefined' || lat ==undefined || lat == null || lat =='null' ||long=='' || long =='undefined' || long ==undefined || long == null || long =='null')
      {
         lat=40.7589
         long =-73.9851
         //location ='Times Square'
         if(autoSuggestInputField!=''){
          location =autoSuggestInputField;
         }
          startTime=CustomCode.getDateForMapSearchSlashFormatHomeForFindParking(FilterHelper.defaultStartDuration().toDateString())
         // console.log('startDurationUpdated',startDurationUpdated);
          console.log('this.startTime',startTime);
      }
      let search_user_id = '0';
      let search_user_email = '0';
      if (localStorage.getItem('access_token')) {
        search_user_id = localStorage.getItem('userid');
        search_user_email = localStorage.getItem('email');
      }
      if(location == null){ 
       // location ='Times Square'
       location=autoSuggestInputField;
      };
        let searchTrach = {
          'event' : 'search_Term',          
          'UserId' :search_user_id,
          'email': search_user_email,
          'Facility': [{
            'Category': this.typeOfSearch.trim().toLowerCase(),
            'searchTerm' : location, 
          }]
       };
       if (this.router.url.indexOf('payment') > -1) {} else{
         (<any>window).dataLayer.push(searchTrach);
        //  console.log(searchTrach);
        //  console.log('search-filter');
       }
       this.fetchUserStatusForTracking(); // trigger login status event
      this.searchCriteria.lat=lat
      let times=1000
      if(this.router.url.indexOf('monthly-parking') > -1)
      {
        times=1500;
      }
      if(this.router.url.indexOf('search') > -1)
      {
        let number =2;
        if(this.router.url.indexOf('/2/') > -1 && localStorage.getItem('mapDrag1'))
        {
          number=1;
        }
        else{
          number=2;
        }
        localStorage.removeItem('mapDrag1');
        //alert('1')
        
        localStorage.setItem('endttimehome',CustomCode.getDateForMapSearchSlashFormatHome(this.endDuration,this.endTime));
        //alert(this.endTime)
        this.router.navigate(['/search', lat, long, startTime, number, location,this.typeOfSearch,CustomCode.searchMode()]);
        
        //window.location.href='/search/'+lat+"/"+long+"/"+startTime+"/"+number+"/"+location+"/"+this.typeOfSearch+"/"+CustomCode.searchMode();
      }
      if(location=='new-york'){
        $('.selectAuto').val('');
      }else{
        $('.selectAuto').val(location);
      }
      setTimeout(() => {
        //alert('searchDate')
        this.searchFacility();
      }, times);

      // this.startDurationMonthly=this.monthlyDate;
      // let currDate = FilterHelper.formatDate(this.monthlyDate) + ' ' + this.startTime;
      // this.searchCriteria.currdate = currDate.toLocaleString().replace(/[^ -~]/g,'');
    //  alert(this.searchCriteria.currdate)
      //alert(this.endDuration);
      //
      //alert(startDurationUpdated);
      
      //$('#time_search').val(startDurationUpdated.toString())
    }
    // searchDate_new()
    // {
    //   //alert(this.monthlyDate);
      
    //   if(this.router.url.indexOf('search') > -1)
    //   {
    //   setTimeout(() => {
    //     if(localStorage.getItem('endttimehome'))
    //     {
    //       this.endDuration = FilterHelper.defaultEndDurationUrlHome(localStorage.getItem('endttimehome'));
    //       this.endTime = FilterHelper.getFormattedTime(this.endDuration);
    //       $('#endTime').val(this.endTime);
    //       let temp_endDuration=this.endDuration;
    //       if(this.endDuration>this.maxEndDate){
    //         this.maxEndDate =this.endDuration;
    //        this.endDuration=temp_endDuration;
    //       }
          
    //       //alert(this.endDuration);
    //     }

    //    // let endTime=CustomCode.getDateForMapSearchSlashFormatHome(this.endDuration,this.endTime)
    //   //alert(this.endDuration);
    //  // localStorage.setItem('endttimehome',CustomCode.getDateForMapSearchSlashFormatHome(this.endDuration,this.endTime));
    //   }, 500);


    //   }

    //   localStorage.removeItem('mapDrag');
    //   localStorage.removeItem('filterApply')
    // let startDurationUpdated = this.startDuration;
    // if(this.typeOfSearch == 'monthly'){
    //   startDurationUpdated = this.startDurationMonthly;
      
    // }else {
    //   startDurationUpdated = this.startDuration;
    // }
    // //this.setTime(startDurationUpdated);
    // const autoSuggestInputField= $('#auto_suggest_input_field').val();
    //   let startTime=CustomCode.getDateForMapSearchSlashFormatHome(startDurationUpdated,this.startTime)
    //   // let startTime=CustomCode.getDateForMapSearchSlashFormatHome(this.startDuration,this.startTime)
      
    //   let lat:any =localStorage.getItem('latNew')
    //   let long:any =localStorage.getItem('longNew')
    //   let location:any =localStorage.getItem('locationNew') 
    //   //
    //   this.searchCriteria.lat=lat
    //   this.searchCriteria.long=long
    //   this.searchCriteria.location=location
    //   // console.log(this.searchCriteria);
    //   if(lat=='' || lat =='undefined' || lat ==undefined || lat == null || lat =='null' ||long=='' || long =='undefined' || long ==undefined || long == null || long =='null')
    //   {
    //      lat=40.7589
    //      long =-73.9851
    //      //location ='Times Square'
    //      if(autoSuggestInputField!=''){
    //       location =autoSuggestInputField;
    //       }
    //   }
    //   let search_user_id = '0';
    //   let search_user_email = '0';
    //   if (localStorage.getItem('access_token')) {
    //     search_user_id = localStorage.getItem('userid');
    //     search_user_email = localStorage.getItem('email');
    //   }
    //   if(location == null){ 
    //    // location ='Times Square'
    //    location=autoSuggestInputField;
    //   };
    //     let searchTrach = {
    //       'event' : 'search_Term',          
    //       'UserId' :search_user_id,
    //       'email': search_user_email,
    //       'Facility': [{
    //         'Category': this.typeOfSearch.trim().toLowerCase(),
    //         'searchTerm' : location, 
    //       }]
    //    };
    //    if (this.router.url.indexOf('payment') > -1) {} else{
    //      (<any>window).dataLayer.push(searchTrach);
    //     //  console.log(searchTrach);
    //     //  console.log('search-filter');
    //    }
    //    this.fetchUserStatusForTracking(); // trigger login status event
    //   this.searchCriteria.lat=lat
    //   let times=1000
    //   if(this.router.url.indexOf('monthly-parking') > -1)
    //   {
    //     times=5500;
    //   }
    //   if(this.router.url.indexOf('search') > -1)
    //   {
    //     let number =2;
    //     if(this.router.url.indexOf('/2/') > -1 && localStorage.getItem('mapDrag1'))
    //     {
    //       number=1;
    //     }
    //     else{
    //       number=2;
    //     }
    //     localStorage.removeItem('mapDrag1');
    //     //alert('1')
        
    //     localStorage.setItem('endttimehome',CustomCode.getDateForMapSearchSlashFormatHome(this.endDuration,this.endTime));
    //     //alert(this.endTime)
    //     this.router.navigate(['/search', lat, long, startTime, number, location,this.typeOfSearch,CustomCode.searchMode()]);
        
    //     //window.location.href='/search/'+lat+"/"+long+"/"+startTime+"/"+number+"/"+location+"/"+this.typeOfSearch+"/"+CustomCode.searchMode();
    //   }
    //   if(location=='new-york'){
    //     $('.selectAuto').val('');
    //   }else{
    //     $('.selectAuto').val(location);
    //   }
    //   setTimeout(() => {
    //     this.searchFacility();
    //   }, times);
    //   //alert(this.endDuration);
    //   //
    //   //alert(startDurationUpdated);
      
    //   //$('#time_search').val(startDurationUpdated.toString())
    // }
    searchDates()
    {
      //alert('searchDates')
      localStorage.removeItem('filterApply');
      let startTime=CustomCode.getDateForMapSearchSlashFormatHome(this.startDuration,this.startTime)
      let endTime=CustomCode.getDateForMapSearchSlashFormatHome(this.endDuration,this.endTime)
      localStorage.setItem('endttimehome',CustomCode.getDateForMapSearchSlashFormatHome(this.endDuration,this.endTime));
      let lat:any =localStorage.getItem('latNew')
      let long:any =localStorage.getItem('longNew')
      let location:any =localStorage.getItem('locationNew') 
      //
      this.searchCriteria.lat=lat
      this.searchCriteria.long=long
      this.searchCriteria.location=location
      // console.log(this.searchCriteria);
      if(lat=='' || lat =='undefined' || lat ==undefined || lat == null || lat =='null' ||long=='' || long =='undefined' || long ==undefined || long == null || long =='null')
      {
         lat=40.7589
         long =-73.9851
         location ='Times Square'
      }
      this.searchCriteria.lat=lat
      $('.selectAuto').val(this.searchCriteria.location)
      //alert(this.searchCriteria.location);
      setTimeout(() => {
        //alert('searchDates')
        this.searchFacility();
      }, 500);
    }
    resetSidebarFilter(){
      if(this.router.url.indexOf('monthly-parking') > -1 ) 
      {
      }else{
        localStorage.removeItem('showNeighbordood');
      
      localStorage.removeItem('neighbourhoodActive')
      localStorage.removeItem('showNeighbordood');
     
     
      }
      localStorage.removeItem('sortBy')
      sessionStorage.removeItem('tabview');
      localStorage.setItem('resetSearch','1')
      
    }   
    
    hideStripeMonhthly()
    {
      localStorage.removeItem('is_landing_page_monthly_stripe')
     // $('aside.contentBar').css({'height':'calc(100% - 140px);'})
      this.showOnMothly=0;
      
      //this.sidebarHeightUpdate();
    }

    updateData()
    {
      //alert('4')
      this.searchDate();
    }  


    formonthly()
    {
      let startDuration = FilterHelper.defaultStartDuration();
      let startTime = FilterHelper.getFormattedTime(startDuration);
      let stime =CustomCode.getDateForMapSearchSlashFormatHome(startDuration,startTime)
      return stime;
      //this.router.navigate(['/search', '40.7782667', '-73.9698797', stime, 1,  'Central Park','daily', CustomCode.searchMode()]);
  } 

  fetchUserStatusForTracking(){
    let getUserId = '0';
    let getUserEmail = '0';
    let user_login_type = '';
    let isLogin = false;
    if(localStorage.getItem('access_token')){
     isLogin = true;
       getUserId = localStorage.getItem('userid');
       getUserEmail = localStorage.getItem('email');
       user_login_type = localStorage.getItem('user_login_type');
    }
    const loginStatusTrack = {
      'event' : 'pageview',
      'loginStatus' : isLogin? 'logged in': 'Not Logged IN', // 'logged in/Not Logged IN',
      'authenticationMethod' : user_login_type,
      'userId' : getUserId,
      'pageType' : this.router.url,
      'email': getUserEmail,
   };
  (<any>window).dataLayer.push(loginStatusTrack);
  
  }
  cancel(){
    $('modal-container').removeClass('show');

    $('modal-container').css('display','none');
  }

}


