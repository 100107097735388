import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import { Router, ActivatedRoute, Params } from '@angular/router';
import {GoogleAnalyticsEventsService} from "../../shared/google-analytics-events.service";
import { CustomCode } from '../../shared/CustomCode';
import {GcouponService} from '../../services/gcoupon.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { SearchpageserviceService } from '../../services/searchpageservice.service';
import { LogsService } from '../../services/logs.service';
import { FilterHelper } from '../../classes/filter-helper';
// declare var ga: Function;
// declare var gtag:Function;
//declare var kochava:any;
import { NgForm } from '@angular/forms';
// declare var gtag_report_conversion : Function;
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-globalcoupon',
  templateUrl: './globalcoupon.component.html',
  providers:[GcouponService, SearchpageserviceService, LogsService]
})
export class GlobalcouponComponent implements OnInit {
  imgSrc;
  couponType:any;
  gcouponUrl:any;
  mailFormData: any = {};
  gtmUrl:SafeResourceUrl = window.location.pathname;
  respdata:any;
  globalCouponData:any;
  globalCouponName;
  globalCouponUrl;
  cmsGlobalCoupon = false;
  promocodeCodeName ;
  globalCouponCode;
  isLoyaltyMember:boolean = false;
  isLoyality = false;
  constructor(
		   private router: Router,
      private activatedRouteObj: ActivatedRoute,
      private gcouponService:GcouponService,
      public sanitizer: DomSanitizer,
      private searchPageObj: SearchpageserviceService,
      private logsService: LogsService,
      private toasterObj: ToastrService,

	){}
  ngOnInit() {
      this.activatedRouteObj.params.subscribe((params: Params) => {
          if (params['coupon-type']) {
            this.couponType = params['coupon-type'];
          }else{
            this.couponType = "default"
          }
      });
       switch(this.couponType) {
        case 'aaa' : 
        this.gcouponUrl=CustomCode.APIURL+'globalcoupon/aaa/jpg';
        this.cmsGlobalCoupon = false;
        this.gcouponService.getCouponDetails(this.gcouponUrl).subscribe((data:any)=>{
          const urlCreator = window.URL;
          const blobData = this.sanitizer.bypassSecurityTrustUrl(urlCreator.createObjectURL(data));

           this.imgSrc= blobData['changingThisBreaksApplicationSecurity'];
           /* commented for ga 16-6-2021 by seo team
           ga('send', 'event', { eventCategory: 'Aaa Coupon print', eventAction: 'click'});
           */

           this.searchPageObj.getMobileCode().subscribe((data_coupon:any)=>{
            this.globalCouponData = data_coupon.data;
            this.globalCouponName = 'Aaa Coupon';
            this.promocodeCodeName = 'AAA10';
            this.globalCouponCode = '';
           (<any>window).dataLayer.push(
             {

                 'promoCode': this.promocodeCodeName,
                 'barCode':data_coupon.data.barcode,
                 'event': this.globalCouponName+" print",
                 'coupon_code':this.globalCouponCode,
                  
             });

            /* #Start Add GTM logged in db */
            let logData = {
              'iq_code': null,
              'coupon_code':null,
              'bar_code':data_coupon.data.barcode,
              'event_type':0
            }

            this.logsService.addLog(logData).subscribe( (result)=>{ }, (err) => { });
            /* #End GTM logged in db */

           },(error:any)=>{console.log(error);this.toasterObj.error(error.error.errors.message);});
           
        });
              
        break;


 case 'hilton-hotels' : 
        this.gcouponUrl=CustomCode.APIURL+'globalcoupon/hilton-hotels/jpg';
        this.cmsGlobalCoupon = false;
        this.gcouponService.getCouponDetails(this.gcouponUrl).subscribe((data:any)=>{
          const urlCreator = window.URL;
          const blobData = this.sanitizer.bypassSecurityTrustUrl(urlCreator.createObjectURL(data));
           this.imgSrc= blobData['changingThisBreaksApplicationSecurity'];
           /* commented for ga 16-6-2021 by seo team
           ga('send', 'event', { eventCategory: 'Hilton Hotels print', eventAction: 'click'});
          */
           this.searchPageObj.getMobileCode().subscribe((data_coupon:any)=>{
          this.globalCouponData = data_coupon.data;
          this.globalCouponName = 'Hilton Hotels Coupon';
          this.promocodeCodeName = 'H66';
          this.globalCouponCode = data_coupon.data.code;
           (<any>window).dataLayer.push(
             {

                 'promoCode': this.promocodeCodeName,
                 'barCode':data_coupon.data.barcode,
                 'couponCode':this.globalCouponCode,
                 'event': this.globalCouponName+" print",
               
             });

            /* #Start Add GTM logged in db */
            let logData = {
              'iq_code': null,
              'couponCode':this.globalCouponCode,
              'bar_code':data_coupon.data.barcode,
              'event_type':0
            }

            this.logsService.addLog(logData).subscribe( (result)=>{ }, (err) => { });
            /* #End GTM logged in db */

           });
           
        });
              
        break;



        case 'advance-parking' : this.imgSrc = CustomCode.imageUrl+'cms/wp-content/uploads/natgeogoupon.jpg';
        this.globalCouponUrl = this.imgSrc;
        this.cmsGlobalCoupon = true;
        /* commented for ga 16-6-2021 by seo team
        ga('send', 'event', { eventCategory: 'Advance Parking Coupon print', eventAction: 'click'});
        */
        this.globalCouponName = 'Advance Parking Coupon';
        this.promocodeCodeName = 'netgeo';
        this.globalCouponCode = '';
        (<any>window).dataLayer.push(
         {
            'promoCode': this.promocodeCodeName,
            'event': this.globalCouponName+' print',
            'couponCode':this.globalCouponCode,
         });
        break;
        case 'astor-parking' : this.imgSrc = CustomCode.imageUrl+'cms/wp-content/uploads/natgeogoupon.jpg';
        this.globalCouponUrl = this.imgSrc;
        this.cmsGlobalCoupon = true;
        /* commented for ga 16-6-2021 by seo team
        ga('send', 'event', { eventCategory: 'Astor Parking Coupon print', eventAction: 'click'});
        */
        this.globalCouponName = 'Astor Parking Coupon';
        this.promocodeCodeName = 'netgeo';
        this.globalCouponCode = '';
        (<any>window).dataLayer.push(
         {
            'promoCode': this.promocodeCodeName,
            'event': this.globalCouponName+" print",
            'couponCode':this.globalCouponCode,
         });
        break;
        case 'quik-park-theatre-lot' : this.imgSrc = CustomCode.imageUrl+'cms/wp-content/uploads/natgeogoupon.jpg';
        this.globalCouponUrl = this.imgSrc;
        this.cmsGlobalCoupon = true;
        /* commented for ga 16-6-2021 by seo team
        ga('send', 'event', { eventCategory: 'Quik Park Theature Coupon print', eventAction: 'click'});
        */
        this.globalCouponName = 'Quik Park Theature Coupon';
        this.promocodeCodeName = 'netgeo';
        this.globalCouponCode = '';
        (<any>window).dataLayer.push(
         {
            'promoCode': this.promocodeCodeName,
            'event': this.globalCouponName+" print",
            'couponCode':this.globalCouponCode,
         });
        break;
        case 'natgeo' : this.imgSrc = CustomCode.imageUrl+'cms/wp-content/uploads/natgeogoupon.jpg'; 
        this.globalCouponUrl = this.imgSrc;
        this.cmsGlobalCoupon = true;
        /* commented for ga 16-6-2021 by seo team
        ga('send', 'event', { eventCategory: 'NatGeo Global Coupon print', eventAction: 'click'});
        */
        this.globalCouponName = 'NatGeo Global Coupon';
        this.promocodeCodeName = 'natgeo';
        this.globalCouponCode = '';
        (<any>window).dataLayer.push(
         {
            'promoCode': this.promocodeCodeName,
            'event': this.globalCouponName+" print",
            'couponCode':this.globalCouponCode,
         });
        break;
        case 'marrakech-hotel' : this.imgSrc = CustomCode.imageUrl+'cms/wp-content/uploads/marrakech-hotel.jpg';
        this.globalCouponUrl = this.imgSrc;
        this.cmsGlobalCoupon = true;
        /* commented for ga 16-6-2021 by seo team
        ga('send', 'event', { eventCategory: 'Marrakech Hotel Coupon print', eventAction: 'click'});
        */
        this.globalCouponName = 'Marrakech Hotel Coupon';
        this.promocodeCodeName = 'MH934';
        this.globalCouponCode = '';
        (<any>window).dataLayer.push(
         {
            'promoCode': this.promocodeCodeName,
            'event': this.globalCouponName+" print",
            'couponCode':this.globalCouponCode,
         });
        break;
         case 'broadway-hotel' : this.imgSrc = CustomCode.imageUrl+'cms/wp-content/uploads/broadway-hotel.jpg';
         this.globalCouponUrl = this.imgSrc;
         this.cmsGlobalCoupon = true;
         /* commented for ga 16-6-2021 by seo team
         ga('send', 'event', { eventCategory: 'Broadway Hotel & Hostel Coupon print', eventAction: 'click'});
         */
         this.globalCouponName = 'Broadway Hotel & Hostel Coupon';
         this.promocodeCodeName = 'BH935';
         this.globalCouponCode = '';
       (<any>window).dataLayer.push(
         {
            'promoCode': this.promocodeCodeName,
            'event': this.globalCouponName+" print",
            'couponCode':this.globalCouponCode,
         });
        break;
         case 'royal-park-hotel' : this.imgSrc = CustomCode.imageUrl+'cms/wp-content/uploads/royal-park-hotel.jpg';
         this.globalCouponUrl = this.imgSrc;
         this.cmsGlobalCoupon = true;
         /* commented for ga 16-6-2021 by seo team
         ga('send', 'event', { eventCategory: 'Royal Park Hotel Coupon print', eventAction: 'click'});
         */
         this.globalCouponName = 'Royal Park Hotel Coupon';
         this.promocodeCodeName = 'RH936';
        (<any>window).dataLayer.push(
         {
            'promoCode': this.promocodeCodeName,
            'event': this.globalCouponName+" print",
         });
        break;
          case 'broadway-org-family' :
          this.gcouponUrl=CustomCode.APIURL+'globalcoupon/broadway-org-family/jpg';
          this.cmsGlobalCoupon = false;
          this.gcouponService.getCouponDetails(this.gcouponUrl).subscribe((data:any)=>{
           const urlCreator = window.URL;
           const blobData = this.sanitizer.bypassSecurityTrustUrl(urlCreator.createObjectURL(data));

            this.imgSrc= blobData['changingThisBreaksApplicationSecurity'];
            /* commented for ga 16-6-2021 by seo team
            ga('send', 'event', { eventCategory: 'Broadway Org Coupon print', eventAction: 'click'});
            */
            //to get barcode data
            this.searchPageObj.getMobileCode().subscribe((data_coupon:any)=>{
              this.globalCouponData = data_coupon.data;
              this.globalCouponName = 'Broadway Org Coupon';
              this.promocodeCodeName = 'BWY';
              this.globalCouponCode = 'null';
               (<any>window).dataLayer.push(
               {

                 'promoCode': this.promocodeCodeName,
                 'barCode':data_coupon.data.barcode,
                 'event': this.globalCouponName+" print",
                 'coupon_code': this.globalCouponCode,
                });

                /* #Start Add GTM logged in db */
                let logData = {
                  'iq_code': null,
                  'coupon_code':null,
                  'bar_code':data_coupon.data.barcode,
                  'event_type':0
                }
                
                this.logsService.addLog(logData).subscribe( (result)=>{ }, (err) => { });
                /* #End GTM logged in db */

              });
          });
          
          break;
         
          case 'trolls-experience' :
          this.gcouponUrl=CustomCode.APIURL+'globalcoupon/trolls-experience/jpg';
          this.cmsGlobalCoupon = false;
          this.gcouponService.getCouponDetails(this.gcouponUrl).subscribe((data:any)=>{
           const urlCreator = window.URL;
           const blobData = this.sanitizer.bypassSecurityTrustUrl(urlCreator.createObjectURL(data));

            this.imgSrc= blobData['changingThisBreaksApplicationSecurity'];
            /* commented for ga 16-6-2021 by seo team
            ga('send', 'event', { eventCategory: 'Trolls Experience Coupon print', eventAction: 'click'});
            */
            //to get barcode data
            this.searchPageObj.getMobileCode().subscribe((data_coupon:any)=>{
              this.globalCouponData = data_coupon.data;
              this.globalCouponName = 'Trolls Experience Coupon';
              this.promocodeCodeName = '4275';
              //this.promocodeCodeName = '4275';  for production
              this.globalCouponCode = 'null';
               (<any>window).dataLayer.push(
               {

                 'promoCode': this.promocodeCodeName,
                 'barCode':data_coupon.data.barcode,
                 'event': this.globalCouponName+" print",
                 'coupon_code': this.globalCouponCode,
                });

                /* #Start Add GTM logged in db */
                let logData = {
                  'iq_code': null,
                  'coupon_code':null,
                  'bar_code':data_coupon.data.barcode,
                  'event_type':0
                }
                
                this.logsService.addLog(logData).subscribe( (result)=>{ }, (err) => { });
                /* #End GTM logged in db */

              });
          });
          
          break;

          case 'local-dc37' :
          this.gcouponUrl=CustomCode.APIURL+'globalcoupon/local-dc37/jpg';
          this.cmsGlobalCoupon = false;
          this.gcouponService.getCouponDetails(this.gcouponUrl).subscribe((data:any)=>{
           const urlCreator = window.URL;
           const blobData = this.sanitizer.bypassSecurityTrustUrl(urlCreator.createObjectURL(data));

            this.imgSrc= blobData['changingThisBreaksApplicationSecurity'];
            /* commented for ga 16-6-2021 by seo team
            ga('send', 'event', { eventCategory: 'local-dc37 Coupon print', eventAction: 'click'});
            */
            
            //to get barcode data
            this.searchPageObj.getMobileCode().subscribe((data_coupon:any)=>{
              this.globalCouponData = data_coupon.data;
              this.globalCouponName = 'local-dc37 Coupon';
              this.promocodeCodeName = '5770';
              //this.promocodeCodeName = '4275';  for production
              this.globalCouponCode = 'null';
               (<any>window).dataLayer.push(
               {

                 'promoCode': this.promocodeCodeName,
                 'barCode':data_coupon.data.barcode,
                 'event': this.globalCouponName+" print",
                 'coupon_code': this.globalCouponCode,
                });

                /* #Start Add GTM logged in db */
                let logData = {
                  'iq_code': null,
                  'coupon_code':null,
                  'bar_code':data_coupon.data.barcode,
                  'event_type':0
                }
                
                this.logsService.addLog(logData).subscribe( (result)=>{ }, (err) => { });
                /* #End GTM logged in db */

              });
          });
          
          break;

          case 'morgan-stanley' : 
         this.gcouponUrl=CustomCode.APIURL+'globalcoupon/morgan-stanley/jpg';

          this.gcouponService.getCouponDetails(this.gcouponUrl).subscribe((data:any)=>{
           const urlCreator = window.URL;
           const blobData = this.sanitizer.bypassSecurityTrustUrl(urlCreator.createObjectURL(data));

            this.imgSrc= blobData['changingThisBreaksApplicationSecurity'];
            /* commented for ga 16-6-2021 by seo team
             ga('send', 'event', { eventCategory: 'Morgan Stanley Coupon print', eventAction: 'click'});
             */
             
            //to get barcode data
            this.searchPageObj.getMobileCode().subscribe((data_coupon:any)=>{
              this.globalCouponData = data_coupon.data;
              this.promocodeCodeName = 'MS10',
              this.globalCouponName = 'Morgan Stanley Coupon';
              this.globalCouponCode = 'null';
            (<any>window).dataLayer.push(
               {

                 'promoCode': this.promocodeCodeName,
                 'barCode':data_coupon.data.barcode,
                 'event': this.globalCouponName+ " print",
                 'coupon_code':this.globalCouponCode,
                });

                /* #Start Add GTM logged in db */
                let logData = {
                  'iq_code': null,
                  'coupon_code':null,
                  'bar_code':data_coupon.data.barcode,
                  'event_type':0
                }
                
                this.logsService.addLog(logData).subscribe( (result)=>{ }, (err) => { });
                /* #End GTM logged in db */

              }); 

          });
        break;
        default: 
        // this.router.navigate(['/search']);
        let startDuration = FilterHelper.defaultStartDuration();
      let startTime = FilterHelper.getFormattedTime(startDuration);
      localStorage.setItem('monthlyDate', startDuration.toString())
      let endDuration = FilterHelper.defaultEndDuration();
      let endTime = FilterHelper.getFormattedTime(endDuration);
      let endTimeDataIfLess = FilterHelper.makeDurationDateObject(endDuration, endTime);
      localStorage.setItem('endttimehome',CustomCode.getDateForMapSearchSlashFormatHome(endDuration, endTime));
      localStorage.setItem('startimehome',CustomCode.getDateForMapSearchSlashFormatHome(startDuration, startTime));
      let stime =localStorage.getItem('startimehome');
      this.router.navigate(['/search', '40.7589', '-73.9851', stime, 1,  'Times Square','monthly', CustomCode.searchMode()]);
        break;
      }
      if(localStorage.getItem('is_loyalty')==="1" && localStorage.getItem('is_loyalty_active')=='1'){
        this.isLoyaltyMember = true;
      }
  }
  sendCoupon(formData, formObj: NgForm) {
    if(!this.cmsGlobalCoupon){
      /* commented for ga 16-6-2021 by seo team
      ga('send', 'event', { eventCategory: this.couponType+ ' email', eventAction: 'click'});
      */
     if(this.globalCouponData){
     //console.log(this.globalCouponData);
      const cId = this.globalCouponData.coupon_id;
     // const cId = 11;
      
      this.searchPageObj.globalEmailLandingCoupon(formData, cId)
      .subscribe(
        (data:any)=> {
          this.respdata = data;
          (<any>window).dataLayer.push(
            {

              'promoCode': this.promocodeCodeName,
              'barCode': this.globalCouponData.barcode,
              'event': this.globalCouponName + " Email",
              'couponCode': this.globalCouponCode,
            
             });

             /* #Start Add GTM logged in db */
             let logData = {
               'iq_code': null,
               'coupon_code':null,
               'bar_code': this.globalCouponData.barcode,
               'event_type':0
             }

            this.logsService.addLog(logData).subscribe( (result)=>{ }, (err) => { });
          if (this.respdata.status === 201) {
            // kochava.activity('email_coupon', {
            //   coupon_id : cId
            // });
            this.router.navigate(['/thanks-email']);
          }
        },
        error => {
          const errorsList = error.errors;
          if (errorsList.message !== 'Validation Errors') {
            this.toasterObj.error(errorsList.message, 'Sorry!');
          } else {
            for (const key in errorsList.detail) {
              if (errorsList.detail.hasOwnProperty(key)) {
                if (typeof errorsList.detail === 'undefined') {
                  this.toasterObj.error(errorsList.message, 'Sorry!');
                } else {
                  this.toasterObj.error(errorsList.detail[key], 'Sorry!');
                }
              }
            }
          }
        }
      );
     }
     else{
      this.toasterObj.error("Coupon ID not defined",'Sorry!');
     }
    }  // not cms coupon
    else{
      /* commented for ga 16-6-2021 by seo team
     ga('send', 'event', { eventCategory: 'Spring Coupon', eventAction: 'click'});
     */
     const ImageUrl = this.globalCouponUrl
   
     this.searchPageObj.globalEmailLandingCouponImage(formData, ImageUrl)
     .subscribe(
       (data:any)=> {
         this.respdata = data;

         (<any>window).dataLayer.push(
           {

             'promoCode': this.promocodeCodeName,
             'barCode': '',
             'event': this.globalCouponName,
           
            });

            /* #Start Add GTM logged in db */
            let logData = {
              'iq_code': null,
              'coupon_code':null,
              'bar_code': null,
              'event_type':0
            }

           this.logsService.addLog(logData).subscribe( (result)=>{ }, (err) => { });
         if (this.respdata.status === 201) {
           this.router.navigate(['/thanks-email']);
         }
       },
       error => {
         const errorsList = error.errors;
         if (errorsList.message !== 'Validation Errors') {
           this.toasterObj.error(errorsList.message, 'Sorry!');
         } else {
           for (const key in errorsList.detail) {
             if (errorsList.detail.hasOwnProperty(key)) {
               if (typeof errorsList.detail === 'undefined') {
                 this.toasterObj.error(errorsList.message, 'Sorry!');
               } else {
                 this.toasterObj.error(errorsList.detail[key], 'Sorry!');
               }
             }
           }
         }
       }
     );

    }


    }
  printCoupon(){
/* commented for ga 16-6-2021 by seo team
    gtag_report_conversion(this.gtmUrl);

    gtag('event', 'conversion', {
      'send_to': 'AW-876742851/dPx-CP3G9X0Qw5GIogM',
      'eventCallback':window['callbackgtm']
    });
    */
  	$('div#printable').html($('div#printableContent').html()).show();
	$('div#content-Wrapper').hide();
  /* commented for ga 16-6-2021 by seo team
   ga('send', 'event', { eventCategory: this.couponType +' Coupon print', eventAction: 'click'});
   */
    window.print();

  setTimeout(()=>{
  	$('div#printable').html('').hide();
	  $('div#content-Wrapper').show();
	  this.router.navigate(['/thanks-print']);
	},2000);
  }

  resetForm(formObj: NgForm) {
		formObj.resetForm();
  }
  


}
