import { Component, OnInit } from '@angular/core';


import { Router, ActivatedRoute, Params } from '@angular/router';
import { NgForm } from '@angular/forms';
import * as $ from 'jquery';

import { CustomCode } from '../shared/CustomCode';
import { MyAccountMonthlyParkingService } from '../services/my-account-monthly-parking.service';
import { ToastrService } from 'ngx-toastr';
import { UserserviceService } from '../services/userservice.service';

@Component({
  selector: 'app-monthlyparkingaccount',
  templateUrl: './monthlyparkingaccount.component.html',
  styleUrls: ['./monthlyparkingaccount.component.scss'],
  providers: [MyAccountMonthlyParkingService, UserserviceService]
})
export class MonthlyparkingaccountComponent implements OnInit {

  // Input Parameters for monthly parking user account number and module

  isLoaded = false;
 

  formData: any = {
  };
  loggedUser:any;

  accountNumber: number;
  profileModule: string;

  monthlyParkingAccounts: any = {};
  activeUser: any = {};

  activeAccount: any = {};

  showMonthlyParking = true;
  showInnerPages = false;

  noMonthlyAccount = false;
  myaccountLinksArr:any;
  showClassColorActive=0;
  showClassColorInActive=0;
  showornot:boolean=true;
  isTierLoaded:boolean=false;
  dropDownText='Current Balance'
  types='usd';
  is_subscription=false
  isSubscriptionBasedUser:any=false;
  isLoyaltyMember:boolean = false;
  account_type_VM = false;
  barHeight:any;
  barHeightFix:any;
  constructor(
    private router: Router,
    private activatedRouteObj: ActivatedRoute,
    private toastr: ToastrService,
    private myAccountMonthlyParkingObj: MyAccountMonthlyParkingService,
    private userserviceServiceObj: UserserviceService) {
  }
  
 
  ngOnInit() {

    sessionStorage.removeItem('monthlyXyz');
    if(localStorage.getItem('is_loyalty')==="1" && localStorage.getItem('is_loyalty_active')=='1'){
      this.isLoyaltyMember = true;
    }

    this.myaccountLinksArr = [
      "my-account/monthly-parking",
      "current-balance", "account-subscription-renewal","auto-pay","current-invoice","previous-invoices","make-payment",
      "payment-methods","payment-history","billing-information","frequently-asked-questions",
      "loyalty-club","delete-account","account-cancellation-notice"
    ]

    if(window.location.href.indexOf('monthly-parking/account') > -1){
      let sectionSlug = window.location.href.split('/').slice(-1)[0];
      let sectionSlug_Account = window.location.href.split('/').slice(-2)[0];
      if(this.myaccountLinksArr.indexOf(sectionSlug) > -1){
        //return true;
      }else{
        this.router.navigate(['/my-account','monthly-parking','account',sectionSlug_Account,'current-balance'])        
      }
      
    }

    $(".catValue li a").click(function(){
      $(".linkLabel div").html($(this).html());
      $(this).parent().addClass('active').siblings().removeClass('active');
      $(".catValue").parent('.selectBox').removeClass('open');
    });
    var fn = function(){ $(".catSub li a").click(function(){       
       $(".linkSub div").html($(this).html());
       $(this).parent().addClass('active').siblings().removeClass('active');
       //$(".catValue").hide();
       $(this).parents('.catSub').parent('.selectBox.open').removeClass('open');
    });};
    window.onload = function() { setInterval(fn,500)};
    this.isLoaded = false;
    this.noMonthlyAccount = false;
    this.activatedRouteObj.params.subscribe((params: Params) => {
      if (params['account'] && params['module']) {
        this.accountNumber = params['account'];
        this.profileModule = params['module'];
        sessionStorage.setItem('monthlyXyz',this.accountNumber.toString())
      }
      if (this.accountNumber && this.profileModule) {
        this.showMonthlyParking = false;
        this.showInnerPages = true;
        this.fetchMonthlyParkingAccount();
      } else {
        this.fetchUserDetails();
      }
    });
    this.loggedUser = CustomCode.getloggedInUserDetails();
    
  }

  ngOnDestroy() {
    localStorage.removeItem('account_type');
}
  setMenu()
  {
    if(this.router.url.indexOf('current-balance') > -1){
      this.textChahge('Account Details',1,'')
    }else if(this.router.url.indexOf('auto-pay') > -1){
      this.textChahge('Manage Autopay',2,'calendar-check-o')
    }else if(this.router.url.indexOf('current-invoice') > -1){
      this.textChahge('Current Invoice',3,'file-text-o')
    }else if(this.router.url.indexOf('previous-invoices') > -1){
      this.textChahge('Previous Invoice',4,'files-o')
    }else if(this.router.url.indexOf('make-payment') > -1){
      this.textChahge('Make Payment',5,'credit-card')
    }else if(this.router.url.indexOf('payment-methods') > -1){
      this.textChahge('Payment Methods',6,'money')
    }else if(this.router.url.indexOf('payment-history') > -1){
      this.textChahge('Payment History',7,'history')
    }else if(this.router.url.indexOf('billing-information') > -1){
      this.textChahge('Billing Information',8,'university')
    }else if(this.router.url.indexOf('frequently-asked-questions') > -1){
      this.textChahge('FAQ',9,'question-circle')
    }else if(this.router.url.indexOf('loyalty-club') > -1){
      this.textChahge('Preferred Customer Program',10,'star')
    }else if(this.router.url.indexOf('delete-account') > -1){
      this.textChahge('Unlink Account',11,'trash-o')
    }else if(this.router.url.indexOf('subscription-renewal') > -1){
      this.textChahge('Renew My Subscription',13,'star')
    }else{
      this.textChahge('Cancellation Notice',12,'ban')
    }
  }
  ngAfterViewInit() {
    this.barHeight = (document.getElementById('NavbarInner') !=null) ? document.getElementById('NavbarInner').offsetHeight : 0;

    setInterval(()=>{
      let getHeight = (document.getElementById('NavbarInner') != null) ? document.getElementById('NavbarInner').offsetHeight : 0;
      if(this.barHeight > getHeight){
        this.barHeight = getHeight;
        this.barHeightFix = getHeight - 70;
        //console.log(getHeight);
      }
    },100);

    this.setMenu();
    setInterval(()=>{
       if(this.router.url.indexOf('subscription-renewal') > -1 && localStorage.getItem('menuSet'))
       {
        this.textChahge('Renew My Subscription',13,'star')
       }
    },1000)
	}
  logOut() {
    CustomCode.userLogout();
    this.router.navigate(['/']);
  }
  fetchUserDetails() {
    this.showornot=false
    this.myAccountMonthlyParkingObj.fetchUser_new()
    .then(
      (data:any)=> {
        this.activeUser = data['data'];
        let inactive:any=0;
        let active:any=0;
        let activenumber=0;
        let inactivenumber=0;
        let acvtiveInc=0;
        let inActiveInc=0
        this.showClassColorActive=1
        this.showClassColorInActive=1
        setTimeout(()=>{
          this.showornot=true;
          for(let j=0;j<this.activeUser.monthly_parking_facilities.length;j++)
        {
          inactive=0;
          active=0;
          for(let p=0;p<this.activeUser.monthly_parking_facilities[j].accounts.length;p++)
          {
            if(this.activeUser.monthly_parking_facilities[j].accounts[p].active==1)
            {
              active=1
              acvtiveInc++;
              activenumber=this.activeUser.monthly_parking_facilities[j].accounts[p].account_number
            }
            if(this.activeUser.monthly_parking_facilities[j].accounts[p].active==0)
            {
              inactive=1;
              inActiveInc++;
              inactivenumber=this.activeUser.monthly_parking_facilities[j].accounts[p].account_number
            }
          }
          $('#accountD'+activenumber).removeClass('dualAccount');
          $('#accountM'+activenumber).removeClass('dualAccount');
          $('#inaccountD'+inactivenumber).removeClass('dualAccount');
          $('#inaccountM'+inactivenumber).removeClass('dualAccount');
          if(inactive==0)
          {
            
            //alert('#active'+this.activeUser.monthly_parking_facilities[j].id)
            $('#inactive'+this.activeUser.monthly_parking_facilities[j].id).addClass('hide');
          }
          if(active==0)
          {
            $('#active'+this.activeUser.monthly_parking_facilities[j].id).addClass('hide');
          }
          if(inActiveInc==0)
          {
            this.showClassColorInActive=0;
          }else{
            this.showClassColorInActive=1;
          }
          if(acvtiveInc==0)
          {
            this.showClassColorActive=0;
          }else{
            this.showClassColorActive=1;
          }
        //  alert("inactive"+inactive+"active"+active+""+this.activeUser.monthly_parking_facilities[j].id)
        }
        if(inActiveInc==0)
        {
          this.showClassColorInActive=0;
        }else{
          this.showClassColorInActive=1;
        }
        if(acvtiveInc==0)
        {
          this.showClassColorActive=0;
        }else{
          this.showClassColorActive=1;
        }
      //  a
          },300)
          if(inactive==0)
          {
            this.showClassColorInActive=0;
          }
          if(active==0)
          {
            this.showClassColorActive=0
          }
        
        this.monthlyParkingAccounts = data;
        
          if (this.monthlyParkingAccounts['data'].monthly_parking_facilities.length === 0) {
            this.noMonthlyAccount = true;
          }
        
        
        this.isLoaded = true;
      },
      error => {
        this.isLoaded = true;
        this.toastr.error(error.errors.message, 'Sorry!');
        // alert(error.errors.message);
      }
    );
  }
  

  fetchMonthlyParkingAccount() {
    this.myAccountMonthlyParkingObj.getAccountDetails(this.accountNumber)
    .then(
      (data:any)=> {
        //console.log("fetchMonthlyParkingAccount",data);
        this.isLoaded = true;
        this.activeAccount = data['data'];
        if(this.activeAccount.hasOwnProperty('account_type')){
          localStorage.setItem('account_type', this.activeAccount.account_type);
        }else {
          localStorage.removeItem('account_type');
        }
        
        if(this.activeAccount.account_type  == 'VM'){
          this.account_type_VM = true;
         }else {
          this.account_type_VM = false;
         }
        if(this.activeAccount['subscription_details'] !==undefined && this.activeAccount['subscription_details'] !==null && 'is_subscription_based_user' in this.activeAccount['subscription_details'] && this.activeAccount['subscription_details'].is_subscription_based_user ==1 ){
          localStorage.setItem('is_subscription_based_user', '1');
          this.isSubscriptionBasedUser = true;
        }else {
          this.isSubscriptionBasedUser = false;
          localStorage.removeItem('is_subscription_based_user');
        }
        if(this.activeAccount.show_renewal_button ==1 ){
          this.is_subscription=true;
          sessionStorage.setItem('is_subs','1')
        }else {
          this.is_subscription=false;
          sessionStorage.removeItem('is_subs')
          sessionStorage.removeItem('monthlyXyz')
        }
        // if(this.activeAccount['subscription_details'] !==null && 'show_renewal_button' in this.activeAccount['subscription_details'] && this.activeAccount['subscription_details'].show_renewal_button ==1 ){
        //   this.is_subscription=true;
        //   sessionStorage.setItem('is_subs','1')
        // }else {
        //   this.is_subscription=false;
        //   sessionStorage.removeItem('is_subs')
        //   sessionStorage.removeItem('monthlyXyz')
        // }
       // alert(this.activeAccount.subscription_details.is_subscription_based_user);
       
        
      },
      error => {
        this.isLoaded = true;
        // alert(error.errors.message);
        this.toastr.error(error.errors.message, 'Sorry!');
      }
    );
  }

  showAddMonthlyAccount() {
    this.fetchUserStatusForTracking();
    this.router.navigate(['/my-account/monthly-parking/create-online-account']);
  }
  updateTier(tier){
    this.isTierLoaded = tier;
  }
  removeHeader()
  {
    sessionStorage.removeItem('menuActive')
  }
  addMenuactive()
  {
    sessionStorage.setItem('menuActive','2')
  }
  textChahge(text,id,type)
  {
    localStorage.removeItem('menuSet')
    if(id==13)
    {
      setTimeout(()=>{
        $('.drp').removeClass('active')
        $('#sub'+id).addClass('active')
        $('#drptext').text(text)
        this.types=type
      },4000)

    }else{
      $('.drp').removeClass('active')
      $('#sub'+id).addClass('active')
      $('#drptext').text(text)
      this.types=type
    }
    
  }

  fetchUserStatusForTracking(){
		let getUserId = '0';
		let getUserEmail = '0';
		let user_login_type = '';
		let isLogin = false;
		if(localStorage.getItem('access_token')){
		 isLogin = true;
		   getUserId = localStorage.getItem('userid');
		   getUserEmail = localStorage.getItem('email');
		   user_login_type = localStorage.getItem('user_login_type');
		}
		const loginStatusTrack = {
		  'event' : 'pageview',
		  'loginStatus' : isLogin? 'logged in': 'Not Logged IN', // 'logged in/Not Logged IN',
		  'authenticationMethod' : user_login_type,
		  'userId' : getUserId,
		  'pageType' : this.router.url,
		  'email': getUserEmail,
	   };
	  (<any>window).dataLayer.push(loginStatusTrack);
	  }

}
