import { Injectable } from '@angular/core';
//import { Response } from '@angular/http';
import { HttpService } from './http.service';
import { Observable } from 'rxjs/Rx';
import { CustomCode } from '../shared/CustomCode';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
@Injectable({
  providedIn: 'root'
})
export class UtilityService {
  
  constructor(private http: HttpService) { }
  private getFacilityUrl = CustomCode.APIURL + 'facility_llc';
  private versionUrl = CustomCode.APIURL + 'mobile-device-version/web';

  writeFacilities() {
    const options = CustomCode.returnHeaders();
    return this.http.get(this.getFacilityUrl, options)
      .map((res: any) =>res)
      .catch((error: any) => Observable.throw(error));
  }

  public loadFacilities(){
    const options = CustomCode.returnHeaders();

    // new Promise((resolve, reject) => {
    //   const options = CustomCode.returnHeaders();
    //   this.http.get(this.versionUrl, options).map( (res:any)=> res.json() ).catch((error: any):any => {
    //     console.log(this.versionUrl);
    //     resolve(true);
    //     return Observable.throw( 'Server error dibya 1');
    //   }).subscribe( (response) => {
    //     let res=response['data'].web_version
    //     //console.log(res+'323232323232');
    //     //localStorage.setItem('firsttest','1')
    //     // this.setCookie('versions',res,10000)
    //     //alert(res)
    //   });
    // })

    new Promise((resolve, reject) => {
      const options = CustomCode.returnHeaders();
      this.http.get(this.versionUrl, options).subscribe( (response) => {
        let res=response['data'].web_version
        //console.log(res+'323232323232');
        //localStorage.setItem('firsttest','1')
        // this.setCookie('versions',res,10000)
        //alert(res)
      });
    })
    // return new Promise((resolve, reject) => {
    //   const options = CustomCode.returnHeaders();
    //   this.http.get(this.getFacilityUrl, options).map( (res:any)=> res.json() ).catch((error: any):any => {
    //     resolve(true);
    //     //return Observable.throw(error.json().error || 'Server error');
    //     return Observable.throw( 'Server error dibya 2');
    //   }).subscribe( (response) => {
    //     let facilities = response.data.facilities;
    //     let tmp = [];
    //     facilities.forEach(element => {
    //       if(element.active == '1'){
    //         let fc = {
    //           full_name:element.full_name.toLowerCase(),
    //           longitude:element.geolocations.longitude,
    //           latitude:element.geolocations.latitude
    //         }
    //         tmp.push(fc);
    //       }
    //     });
    //     CustomCode.facilityListSearch = tmp;
    //     resolve(true);
    //   });
    // });
    return new Promise((resolve, reject) => {
      const options = CustomCode.returnHeaders();
      this.http.get(this.getFacilityUrl, options).subscribe( (response) => {
        let facilities = response.data.facilities;
        let tmp = [];
        facilities.forEach(element => {
          if(element.active == '1'){
            let fc = {
              full_name:element.full_name.toLowerCase(),
              longitude:element.geolocations.longitude,
              latitude:element.geolocations.latitude
            }
            tmp.push(fc);
          }
        });
        CustomCode.facilityListSearch = tmp;
        resolve(true);
      });
    });
  }
  setCookie(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + (exdays*24*60*60*1000));
    var expires = "expires="+ d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }

}
