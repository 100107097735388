import { Component, OnInit,ViewEncapsulation } from '@angular/core';
import { StaticpagesService } from '../../services/staticpages.service';
import { Observable } from 'rxjs/Rx';
import { Router, ActivatedRoute, Params } from '@angular/router';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
@Component({
  selector: 'app-parksafe-landing',
  templateUrl: './parksafe-landing.component.html',
  styleUrls: ['./parksafe-landing.component.scss'],
  encapsulation:ViewEncapsulation.None,
  providers: [StaticpagesService]
})
export class ParksafeLandingComponent implements OnInit {

  page: String = 'parksafe';
  staticPageContent: any = {};
  isLoadeds = false;

  constructor( private staticObj:StaticpagesService,private router: Router ) {
   
   }

  ngOnInit() {
    this.isLoadeds=false;   
    this.staticObj.getCmsPageContent(this.page).subscribe((data:any)=> {
      this.staticPageContent = data.result[0];
      this.isLoadeds = true;
    }, (error) => { console.log('Error: ' + JSON.stringify(error));
      this.isLoadeds = true;
      }
  ); 
}

}
