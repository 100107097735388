<h2 class="text-primary text-uppercase">Brand Partnerships</h2>
<p class="text-uppercase">We've teamed up with exciting partners to get you the best of new york</p>
<br>
<div class="spaceRows clearfix" app-range-slider>
	<div class="article">
		<div class="partner-pic-wrap">
			<!-- <img class="img-responsive full-width" [src]="partner_ratioIcon" alt="image"> -->
			<img class="img-responsive full-width partner-pic" [src]="flyCleanersIcon" alt="image">
		</div>
		<div class="partner-meta-wrap">
			<div class="partner-meta">
				<p sourceFont>
					$15 off on-demand laundry
				</p>
			</div>
			<fa name="angle-right" size="2x"></fa>
		</div>

		<div class="ip-details">
			<div class="row">
				<div class="col-xs-12">
					<h2 class="text-uppercase text-primary mar-top-0">FlyCleaners</h2>
					<h4 class="text-uppercase mar-bottom-sm">About FlyCleaners</h4>
					<p class="text-uppercase" sourceFont>
						Get a one-time $15 dollar off discount from FlyCleaners by using 
						<strong> promo code <span class="text-primary">“ICON15”</span> in the app.</strong>
						The FlyCleaners app is available on the
						<a [href]="ItuneUrl" target="_blank" class="btn btn-black btn-fill btn-xs "><fa name="apple"></fa>  iOS App Store</a> or the <a [href]="googlePlayUrl" target="_blank" class="btn btn-black btn-fill btn-xs" ><fa name="play"></fa> Google Play store</a> See terms for exclusions.
					</p>
				</div>
			</div>
		</div>
	</div>
	<div class="article">
		<div class="partner-pic-wrap">
			<!-- <img class="img-responsive full-width" [src]="partner_ratioIcon" alt="image"> -->
			<img class="img-responsive full-width partner-pic" [src]="shareBiteIcon" alt="image">
		</div>
		<div class="partner-meta-wrap">
			<div class="partner-meta">
				<p>
					25% off first order of take-out-and-donate
				</p>
			</div>
			<fa name="angle-right" size="2x"></fa>
		</div>

		<div class="ip-details">
			<div class="row">
				<div class="col-xs-12">
					<h2 class="text-uppercase text-primary mar-top-0">Sharebite</h2>
					<h4 class="text-uppercase mar-bottom-sm">About Sharebite</h4>
						<p class="text-uppercase">Get a one-time 25% off discount from Sharebite by using <strong> promo code <span class="text-primary">“ICON25”</span></strong>
						at <a [href]="sharebite" target="_blank"> sharebite.com</a>  during checkout. See terms for exclusions.</p>
						<hr>
					<p>
						Offer valid online at <a [href]="sharebite" target="_blank"> sharebite.com</a> or on the Sharebite mobile app. See
						merchant website for delivery availability in your area. Offer available for
						Icon Monthly Parking customers only. Offer is non-transferable and may not
						be combined with other offers/promotions. Not valid for prior purchases.
						This offer may be modified or revoked at any time. Fulfillment of the offer
						is the sole responsibility of Sharebite, Inc.
					</p>
				</div>
			</div>
		</div>
	</div>
	<div class="article">
		<div class="partner-pic-wrap">
			<!-- <img class="img-responsive full-width" [src]="partner_ratioIcon" alt="image"> -->
			<img class="img-responsive full-width partner-pic" [src]="handyIcon" alt="image">
		</div>
		<div class="partner-meta-wrap">
			<div class="partner-meta">
				<p>
					50% off on one cleaning/furniture assembly
				</p>
			</div>
			<fa name="angle-right" size="2x"></fa>
		</div>

		<div class="ip-details">
			<div class="row">
				<div class="col-xs-12">
					<h2 class="text-uppercase text-primary mar-top-0">Handy.com</h2>
					<h4 class="text-uppercase mar-bottom-sm">About Handy.com</h4>
					<p class="text-uppercase">
						Get a one-time 50% off discount on your first cleaning from Handy by using
						<strong>promo code <span class="text-primary"> “ICON50” </span> at handy.com during checkout.</strong> See terms for exclusions.
					</p>
					<hr>
					<p>
						Offer valid online at <a [href]="handyUrl" target="_blank"> handy.com</a> or on the Handy mobile app. Offer available
						for Icon Monthly Parking customers only. Offer is non-transferable and may
						not be combined with other offers/promotions. Not valid for prior purchases.
						This offer may be modified or revoked at any time. Fulfillment of the offer
						is the sole responsibility of Handy.
					</p>
					
				</div>
			</div>
		</div>
	</div>


</div>
