import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
//import { Http, Headers, RequestOptions, Response } from '@angular/http';

import { Observable } from 'rxjs/Rx';

declare var google: any;
declare var routerObj: Router;

import * as $ from 'jquery';
//declare var kochava:any;
import { CustomCode } from '../shared/CustomCode';
import { SearchPageScript } from '../shared/searchPageScript';

import { ToastrService } from 'ngx-toastr';

// Import RxJs required methods
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';

import { HomepageserviceService } from '../services/homepageservice.service';
import { SearchpageserviceService } from '../services/searchpageservice.service';
import { MyAccountMonthlyParkingService } from '../services/my-account-monthly-parking.service';
import {GoogleAnalyticsEventsService} from "../shared/google-analytics-events.service";
// declare var ga: Function;

@Component({
  selector: 'app-home',
  template: '<p><p>',
  providers: [HomepageserviceService, SearchpageserviceService, MyAccountMonthlyParkingService]
})

export class LogoutComponent implements OnInit {
  contactTelNo = CustomCode.contactTelephoneNumber;
  
   POSuserLat=0;
   POSuserLong=0;
   iosDevice:any;
   message :any;

   locationTrack = 'assets/img/location-track.svg';
   qp_logo = 'assets/img/qp_logo.svg';
   scroller= 'assets/img/scroller.svg';
   screen_1 = 'assets/img/screen_1.png';
   screen_2 = 'assets/img/screen_2.png';
   screen_3 = 'assets/img/screen_3.png';
   dollar_icon= 'assets/img/dollar_icon.svg';
   discount_icon= 'assets/img/discount_icon.svg';
   AppStore ='assets/img/AppStore.svg';
   googleplayIcon = 'assets/img/google-play.svg';
   personIcon = '/assets/img/person.png';
   arrowsIcon ='assets/img/arrows.svg'
   carIcon = '/assets/img/car.png';
   map_markers = 'assets/img/map_markers.svg'
   vehicleIcon = 'assets/img/vehicle.svg';
   accountIcon = 'assets/img/account.svg'
   cardIcon = 'assets/img/card.svg'

   appStoreUrl = 'https://itunes.apple.com/us/app/icon-parking/id394377820?mt=8';
   googlePlayUrl = 'https://play.google.com/store/apps/details?id=com.iconparking&hl=en'
  
  mapProp;
  findValue: String;
  locationDetails: any = {};
  userLocation: any = {};
  options = {};
  responseData: any = {};
  latLongResponse: any = {};
  facilityRadius = 1;
  facilityLabel = 'New York';
  userLoc: any = {};
  initialSearch = {
    label: 'Times Square',
    latitude: 40.7589,
    longitude: -73.9851
  };
  activeUser:any = {};

  newYorkCenter = {
    latitude: 40.729295,
    longitude: -73.999392
  };
  Y;
  currDate = CustomCode.getDateForMapSearch();
  settypedata:any='daily';
  facilityType = CustomCode.defaultFacilityType;

  constructor(private router: Router,
    private homePageServiceObj: HomepageserviceService,
    private searchPageServObj: SearchpageserviceService,
    private toasterObj: ToastrService,
	  private activatedRouteObj: ActivatedRoute,
    private myAccountMonthlyParkingObj: MyAccountMonthlyParkingService,
    public googleAnalyticsEventsService: GoogleAnalyticsEventsService
  ) {

    this.iosDevice = false;
   }
  ngOnInit() {
  CustomCode.userLogout();
  this.router.navigate(['/']);
  }

}
